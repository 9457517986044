import { Box } from "@chakra-ui/react"
import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import Button from "@pathwright/ui/src/components/button/Button"
import IconButton from "@pathwright/ui/src/components/button/IconButton"
import SearchInput from "@pathwright/ui/src/components/form/form-text-input/SearchInput"
import useDebouncedValue from "@pathwright/ui/src/components/hooks/useDebouncedValue"
import useIntersection from "@pathwright/ui/src/components/hooks/useIntersection"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import LoadingBlock from "@pathwright/ui/src/components/loading/LoadingBlock"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import ScrollView, {
  useScrollContext
} from "@pathwright/ui/src/components/scroll/ScrollView"
import Tooltip from "@pathwright/ui/src/components/tooltip/Tooltip"
import { useScreensizeContext } from "@pathwright/ui/src/components/ui/Screensize"
import View from "@pathwright/ui/src/components/ui/View"
import { media } from "@pathwright/ui/src/components/utils/styles"
import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import get from "lodash/get"
import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import TreeView from "react-treeview"
import styled from "styled-components"
import { ScopedBackgroundTaskRefetchQuery } from "../../../bg-task/BackgroundTaskContext"
import { usePathwrightContext } from "../../../pathwright/PathwrightContext"
import { flattenEdges, usePaginator } from "../../../utils/apollo"
import MENTOR_GROUP_QUERY from "../../graphql/mentor-group-query"
import MENTOR_GROUPS_QUERY from "../../graphql/mentor-groups-query"
import MentorGroupShareBackgroundTaskContextProvider from "../../members/list/MentorGroupShareBackgroundTaskContextProvider"
import { canAddMentorGroup } from "../../permissions"
import { getToGroupAdd } from "../../utils"
import AddMentorGroupCard from "../add/AddMentorGroupCard"
import MentorGroupListItem from "./MentorGroupListItem"

const Controls = styled(View.Primary)`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &:empty {
    display: none;
  }
`

// Matching styles of the MentorGroupMemberList ListContainer.
const StyleScrollView = styled(ScrollView)`
  height: calc(100vh - 100px);
  ${media.min.phone`height: calc(100vh - 400px);`};
`

const TreeStyles = styled(View.Secondary)`
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .tree-view_item {
    position: relative;
    &:hover {
      z-index: 9001;
    }
    & > div:last-child {
      /* make room for arrow  */
      padding-left: 30px;
    }
  }

  .tree-view_children {
    margin-left: 15px;
  }

  .tree-view_arrow {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 50%;
    padding: 10px;
    transform: translateY(-50%);
    display: inline-block;
    user-select: none;
    cursor: pointer;
    font-size: 10px;
    opacity: 0.5;
  }

  .tree-view_arrow:after {
    content: "▼";
    font-family: "pathicons";
  }

  .tree-view_arrow-collapsed:after {
    content: "▶";
    font-family: "pathicons";
  }
`

// handle tree collapsed state
const ExpandableTreeView = ({
  children,
  search,
  collapsed: collapsedProp,
  ...rest
}) => {
  // handle collapsing individual tree
  const [collapsed, setCollapsed] = useState(true)

  useEffect(() => {
    if (collapsedProp !== collapsed) setCollapsed(collapsedProp)
  }, [collapsedProp])

  return (
    <TreeView
      {...rest}
      search={search}
      collapsed={collapsed}
      onClick={() => setCollapsed(!collapsed)} // handle collapsing the individual tree
    >
      {children}
    </TreeView>
  )
}

const Node = (props) => {
  const { topParentId, parentId } = props
  const [collapsed, setCollapsed] = useState(true)
  const handleCollapse = () => setCollapsed(true)
  const handleExpand = () => setCollapsed(false)

  const [search, debouncedSearch, debouncingSearch, handleDebounceSearch] =
    useDebouncedValue(300)

  const { t } = useTranslate()
  const pwContext = usePathwrightContext()
  const screensize = useScreensizeContext()
  const isMobile = screensize === "sm"

  const groupQuery = useQuery(MENTOR_GROUP_QUERY, {
    variables: {
      id: topParentId
    },
    skip: !topParentId
  })
  const group = groupQuery.data?.school?.mentor_group

  const canAddGroup = canAddMentorGroup(pwContext, group)

  const { scrollNode } = useScrollContext()
  // we'll check if the LoadingBlock placeholder element has entered the containing ScrollView
  const [setIntersectionNode, intersection, hasIntersected] = useIntersection({
    root: scrollNode
  })

  const variables = {
    seatLimitFilter: { neq: 1 }
  }
  if (search) {
    variables.search = search
    // search all descendent groups of the current parent group
    if (parentId) {
      variables.descendentOfParentId = { eq: parentId }
    }
  } else {
    // when not searching, simply query for all direct child groups of the current parent group
    variables.parentId = { eq: parentId }
  }

  const mentorGroupsQuery = useQuery(MENTOR_GROUPS_QUERY, {
    variables
  })

  const mentorGroups = flattenEdges(
    get(mentorGroupsQuery, "data.school.mentor_groups")
  )

  const { loadMore, hasMore, loadingMore } = usePaginator({
    data: mentorGroupsQuery, // hacky
    path: "school.mentor_groups"
  })

  // paginate when the LoadingBlock placeholder element has intersected the containing ScrollView
  useEffect(() => {
    if (hasMore && !loadingMore && hasIntersected) loadMore()
  }, [hasIntersected])

  // if top-level querying, provide some blank slates
  if (parentId === topParentId && !mentorGroups?.length && !search) {
    if (mentorGroupsQuery.loading) {
      return (
        <Box minH="400px">
          <LoadingCircle center />
        </Box>
      )
    }

    if (mentorGroupsQuery.error) {
      return (
        <BlankSlate
          icons={null}
          heading="An unexpected error occurred."
          body={mentorGroupsQuery.error}
          primaryAction={{
            children: "Reload",
            onClick: () => mentorGroupsQuery.refetch()
          }}
        />
      )
    }

    if (mentorGroups && !mentorGroups.length) {
      if (canAddGroup) {
        const tPrefix = group
          ? "mentor_group.sub_group_blank_slate"
          : "mentor_group.blank_slate"
        const link = group
          ? "http://help.pathwright.com/en/articles/5596450-mentor-groups-provide-personal-mentoring-across-all-courses#h_2384c135a5"
          : "http://help.pathwright.com/en/articles/5596450-mentor-groups-provide-personal-mentoring-across-all-courses"
        return (
          <BlankSlate
            icons={null}
            icon="group-2"
            heading={t(`${tPrefix}.heading`)}
            body={t(`${tPrefix}.body`, { group: group?.name })}
            primaryAction={{
              children: t(`${tPrefix}.button`),
              to: {
                pathname: getToGroupAdd(parentId),
                card: {
                  component: AddMentorGroupCard,
                  props: {
                    parentGroupId: parentId
                  }
                }
              }
            }}
            secondaryAction={{
              children: t(`${tPrefix}.link`),
              href: link,
              target: "_blank"
            }}
            stack
          />
        )
      }

      // TODO: show blank slate for users who cannot add group?
      // return null
    }
  }

  // Only enabling expand/collapse when there are groups with child groups.
  const shouldEnableExpandCollapse = mentorGroups?.some(
    (group) => group.has_child_groups
  )

  // Only enabling search when we have a min number or when at least one of the groups has
  // child groups, which is an unknown number to the client at this point, so we should enable
  // search just in case that number is large.
  const shouldEnableSearch =
    !!search || mentorGroups?.length > 7 || shouldEnableExpandCollapse

  const renderItem = (node) => {
    return (
      <MentorGroupShareBackgroundTaskContextProvider mentorGroupId={node.id}>
        <ScopedBackgroundTaskRefetchQuery
          backbroundTaskRefetchQueries={[
            {
              queryName: "MentorGroups",
              variables
            }
          ]}
        />
        <MentorGroupListItem group={node} />
      </MentorGroupShareBackgroundTaskContextProvider>
    )
  }

  const nodes = mentorGroups?.map((node) =>
    // disabling tree when searching
    node.has_child_groups && !search ? (
      <ExpandableTreeView
        key={node.id}
        nodeLabel={renderItem(node)}
        collapsed={collapsed}
      >
        <Node {...props} parentId={node.id} />
      </ExpandableTreeView>
    ) : (
      <div key={node.id} className="tree-view_item">
        {renderItem(node)}
      </div>
    )
  )

  // the LoadingBlock tree item doubles as a pagination state indicator and as a pagination initiator
  const loader = hasMore ? (
    <div
      className="tree-view_item"
      // reset hasIntersected by removing ref when loading more
      ref={loadingMore ? null : setIntersectionNode}
    >
      <LoadingBlock
        style={{
          // 50px being about the correct size of the a MentorGroupListItem; could observer size of a MentorGroupListItem to be more accurate, but hey
          // multiplying item height times the number of remaining items or page size, whichever is less
          height: `${
            50 * Math.min(mentorGroups?.total - mentorGroups?.length, 25)
          }px`
        }}
      />
    </div>
  ) : null

  return parentId === topParentId ? (
    <div>
      <Controls>
        {shouldEnableSearch && (
          <div style={{ flexGrow: 1 }}>
            <SearchInput
              value={search}
              onChange={handleDebounceSearch}
              onClear={() => handleDebounceSearch("")}
              autoFocus={!isMobile}
              searching={debouncingSearch}
            />
          </div>
        )}
        {!isMobile && shouldEnableExpandCollapse && (
          <Tooltip title="Collapse all" style={{ marginLeft: 7 }}>
            <IconButton
              icon="collapse-2"
              styleType="secondary"
              onClick={handleCollapse}
              rounded={false}
            />
          </Tooltip>
        )}
        {!isMobile && shouldEnableExpandCollapse && (
          <Tooltip title="Expand all" style={{ marginLeft: 7 }}>
            <IconButton
              icon="expand-2"
              styleType="secondary"
              onClick={handleExpand}
              rounded={false}
            />
          </Tooltip>
        )}
        {canAddGroup && (
          <Button
            style={{ marginLeft: 7 }}
            styleType="secondary"
            to={{
              pathname: getToGroupAdd(parentId),
              card: {
                component: AddMentorGroupCard,
                props: {
                  parentGroupId: parentId
                }
              }
            }}
          >
            {isMobile
              ? t("mentor_group.new")
              : parentId
              ? t("mentor_group.new_sub_group")
              : t("mentor_group.new_group")}
          </Button>
        )}
      </Controls>
      <StyleScrollView>
        <TreeStyles>
          {nodes}
          {loader}
        </TreeStyles>
      </StyleScrollView>
    </div>
  ) : (
    <>
      {nodes}
      {loader}
    </>
  )
}

const MentorGroupList = ({
  // groups
  parentId
}) => {
  return <Node topParentId={parentId} parentId={parentId} />
}

MentorGroupList.displayName = "MentorGroupList"

MentorGroupList.propTypes = {
  parentId: PropTypes.number
}

MentorGroupList.defaultProps = {
  parentId: null
}

export default MentorGroupList
