import {
  Box,
  HStack,
  IconButton,
  Spacer,
  StackDivider,
  Text,
  VStack
} from "@chakra-ui/react"
import { XIcon } from "@pathwright/pathicons"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import { Virtuoso } from "react-virtuoso"
import PeopleItem from "./PeopleItem"
import SelectItemRole from "./SelectItemRole"
import { useSelectPeopleContext } from "./SelectPeopleContext"
import SelectRole from "./SelectRole"

const tPrefix = "share_card.add_tab"

const Item = ({ index, item, onClick }) => (
  <HStack
    key={item.meta.key}
    width="100%"
    justifyContent="space-between"
    pt={1}
    pb={1}
    data-testid={`selected-people-item-${index}`}
  >
    <PeopleItem item={item} showMeta={false} />
    <SelectItemRole item={item} />
    <IconButton
      variant="ghost"
      aria-label={`Remove ${item.meta.title}`}
      size="sm"
      cursor={"pointer"}
      icon={<XIcon size="1em" color="inhert" />}
      onClick={() => onClick(item)}
    />
  </HStack>
)

const List = ({ selected, handleClickItem }) => (
  <Box
    w="100%"
    sx={{
      // Fix scroll jank when interacting with an open MenuList near bottom of scroll container.
      ["[data-virtuoso-scroller='true'], [data-viewport-type='element']"]: {
        position: "static !important"
      }
    }}
  >
    <Virtuoso
      style={{
        height: "300px",
        width: "100%"
      }}
      totalCount={selected.length}
      itemContent={(index) => (
        <Item index={index} item={selected[index]} onClick={handleClickItem} />
      )}
    />
  </Box>
)

const SelectedPeople = () => {
  const {
    peopleSelection: { selected, queued },
    removeFromPeopleSelection
  } = useSelectPeopleContext()

  const { tc } = useTranslate()

  const handleClickItem = (item) => {
    removeFromPeopleSelection(item)
  }

  if (!selected.length) {
    return (
      <VStack minH="200px" justifyContent="center">
        <Text size="xs" color="gray.400" textAlign="center" mx={5}>
          {tc(
            `${tPrefix}.Type above to add existing members or invite new members by entering an email address.`
          )}
        </Text>
      </VStack>
    )
  }

  return (
    <VStack spacing={2} width="100%" alignItems={"flex-end"}>
      <Spacer />
      {!!selected.length && <SelectRole />}
      <VStack
        spacing={6}
        width="100%"
        divider={<StackDivider borderColor="gray.100" />}
      >
        <List selected={selected} handleClickItem={handleClickItem} />
      </VStack>
    </VStack>
  )
}

export default SelectedPeople
