import { useBlocker } from "react-router-dom"
import { useSignOut } from "../../sign-out/api/action"
import SignedIn, { SignedInProps } from "./SignedIn"

export type SignedInContainerProps = {
  onSignOut?: () => void
} & SignedInProps

const SignedInContainer = ({
  onSignOut,
  ...passProps
}: SignedInContainerProps): JSX.Element | null => {
  const [state, action] = useSignOut()

  useBlocker(({ nextLocation }) => {
    if (nextLocation.pathname === "/auth/sign-out") {
      action().then(onSignOut)
      return true
    }
    return false
  })

  return <SignedIn {...passProps} />
}

export default SignedInContainer
