import gql from "graphql-tag"

export default gql`
  query MediaContextQuery {
    mediaContext {
      accountID
      user {
        id
        is_superuser
      }
      mediaStoragePath
    }
  }
`
