import Avatar from "@pathwright/ui/src/components/avatar/Avatar"
import ThemeBackground from "@pathwright/ui/src/components/background/ThemeBackground"
import Button from "@pathwright/ui/src/components/button/Button"
import IconButton from "@pathwright/ui/src/components/button/IconButton"
import SwitchButton from "@pathwright/ui/src/components/button/SwitchButton"
import useWindowSize from "@pathwright/ui/src/components/hooks/useWindowSize"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import Portal from "@pathwright/ui/src/components/portal/Portal"
import { modalRoute } from "@pathwright/ui/src/components/utils/urls"
import PropTypes from "prop-types"
import { useEffect, useMemo, useState } from "react"
import styled from "styled-components"
import CalendarButton from "../calendar/CalendarButton"
import CreateCohort from "../cohort/create/CreateCohort"
import { useModal } from "../modal"
import { usePathwrightContext } from "../pathwright/PathwrightContext"
import { canEditLibrary, canViewHomePeople } from "../user/permissions"
import Section from "./Section"
import SectionNav, {
  NAV_SECTION_DESIGN,
  NAV_SECTION_LEARN,
  NAV_SECTION_TEACH,
  useSections
} from "./SectionNav"
import { DASHBOARD_MOBILE_WIDTH } from "./constants"
import { CreateResourceModal } from "./design/CreateResource"
import PasteResource from "./design/PasteResource"
import People from "./people/People"
import SearchInput from "./ui/SearchInput"
import useHomeActiveEntries from "./useHomeActiveEntries"

const Wrap = styled.div`
  width: 100%;
  flex: 1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;

  .BackgroundImage {
    min-height: 100%;
    height: 100%;
  }

  section {
    > h1 {
      color: #fff;
      font-size: 1.3em;
    }
  }
`

const Container = styled.div`
  display: flex;
  max-width: 1200px;
  width: 100%;
  position: relative;
  flex-direction: row;
  flex: 1;
  z-index: 1;

  @media (max-width: ${DASHBOARD_MOBILE_WIDTH}px) {
    flex-direction: column;
    overflow: auto;
    padding-top: 20px;
  }
`

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  max-width: calc(100% - 20px);
  min-width: 280px;
  color: #fff;

  h1 {
    margin-left: 10px;
    margin-right: 5px;
    font-size: 2em;
  }
  .Avatar {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  }
  @media (max-width: ${DASHBOARD_MOBILE_WIDTH}px) {
    max-width: 100%;
  }
`

const NavContainer = styled.nav`
  display: flex;
  width: 100%;
  height: 60px;
  padding: 10px 0px;
  padding-right: 10px;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 10;

  .SwitchButton,
  .SwitchButton__background {
    flex-shrink: 0;
  }

  @media (max-width: ${DASHBOARD_MOBILE_WIDTH}px) {
    padding-left: 10px;
  }
`

export const DashboardContent = styled.div`
  flex-direction: column;
  height: 100%;
  width: 100%;
`

const SectionControlsContainer = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  .Button {
    margin-left: 10px;
  }
`

const StyledSectionControls = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`

const SectionControls = ({ selectedSection }) => {
  const pwContext = usePathwrightContext()
  const { tca } = useTranslate()
  const { showModal, closeModal } = useModal()
  const { getSection } = useSections()
  const { width } = useWindowSize()

  const section = getSection(selectedSection)

  const renderSectionControls = () => {
    const showLabel = width > 400

    switch (selectedSection) {
      case NAV_SECTION_LEARN:
        return (
          <>
            <CalendarButton
              styleType="primary"
              label={showLabel ? tca("Calendar") : null}
              to={{ pathname: "/calendar/", state: { modal: true } }}
            />
            {canEditLibrary(pwContext) && (
              <Button
                styleType="primary"
                icon="plus"
                label={showLabel ? tca("New Path") : null}
                onClick={() =>
                  showModal(CreateCohort, {
                    registerCreatorAsRole: section.cohortCreatorRole,
                    onClose: closeModal
                  })
                }
              />
            )}
          </>
        )
      case NAV_SECTION_TEACH:
        if (canEditLibrary(pwContext))
          return (
            <Button
              styleType="primary"
              icon="plus"
              label={showLabel ? tca("New Path") : null}
              onClick={() =>
                showModal(CreateCohort, {
                  registerCreatorAsRole: section.cohortCreatorRole,
                  onClose: closeModal
                })
              }
            />
          )
      case NAV_SECTION_DESIGN:
        if (canEditLibrary(pwContext))
          return (
            <>
              <PasteResource />
              <Button
                styleType="primary"
                icon="plus"
                label={showLabel ? tca("New Path") : null}
                onClick={() => showModal(CreateResourceModal)}
              />
            </>
          )

      default:
        return null
    }
  }
  return (
    <StyledSectionControls>{renderSectionControls()}</StyledSectionControls>
  )
}

const NavBar = ({ onSelect, selectedSection, onSearch, onOpenCardStack }) => {
  const [showSearch, setShowSearch] = useState(false)
  const { tca } = useTranslate()

  const closeSearch = () => {
    setShowSearch(false)
    onSearch("")
  }

  const searchPlaceholder =
    selectedSection === "teach"
      ? `${tca("Search Cohorts")}...`
      : `${tca("Search Paths")}...`

  return (
    <>
      <NavContainer>
        <SectionNav
          selectedSection={selectedSection}
          onSelect={(op) => {
            onSelect(op.section)
          }}
        />
        <SectionControlsContainer>
          <SectionControls
            selectedSection={selectedSection}
            onOpenCardStack={onOpenCardStack}
          />
          <IconButton
            styleType={!showSearch ? "primary" : "primary-alt"}
            icon="search"
            onClick={() => (showSearch ? closeSearch() : setShowSearch(true))}
          />
        </SectionControlsContainer>
      </NavContainer>
      {showSearch ? (
        <SearchInput
          placeholder={searchPlaceholder}
          className="SearchInput"
          type="text"
          onClose={closeSearch}
          onChange={(e) => onSearch(e.target.value)}
        />
      ) : null}
    </>
  )
}

const AvatarContianer = styled.div`
  position: relative;
  .ProfilePrompt {
    z-index: 10;
    width: 120px;
    height: 140px;
    position: absolute;
    left: 0px;
    top: 0px;

    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
`

const Profile = () => {
  const translate = useTranslate()
  const { tc } = translate
  const { me } = usePathwrightContext()

  const hasProfileImage =
    !!me.profile.image && me.profile.image.indexOf("generic_defaults") === -1

  return (
    <ProfileContainer>
      <AvatarContianer>
        {!hasProfileImage ? (
          <div className="ProfilePrompt">
            <Button
              styleType="secondary"
              inverted
              to={modalRoute("/manage/profile/")}
            >
              <Pathicon icon="image" style={{ marginRight: 5 }} />
              {tc("Add Image")}
            </Button>
          </div>
        ) : null}
        <Avatar size={120} user={me} />
      </AvatarContianer>
      <h1 className="theme-heading theme-heading-bold">{me.full_name}</h1>
      <IconButton icon="gear" inverted to={modalRoute("/manage/profile/")} />
      {/* <Button
        styleType="primary-alt"
        inverted
        to={modalRoute("/manage/profile/")}
      >
        <Pathicon icon="gear" style={{ marginRight: 5 }} />
        {tc(["dashboard.header.your_settings", "Your settings"])}
      </Button> */}
    </ProfileContainer>
  )
}

const Header = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.333);
  z-index: 10;
  position: relative;
  width: 100%;
  padding: 10px;
  padding-top: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);

  > nav {
    position: relative;
    top: 27px;
  }
`

const Paths = ({ section: sectionProp }) => {
  const { getSection } = useSections()
  const [searchTerm, setSearchTerm] = useState()

  const [selectedSection, setSelectedSection] = useState(
    sectionProp || DASHBOARD_SECTIONS.LEARN
  )

  useEffect(() => {
    if (sectionProp && sectionProp !== selectedSection)
      setSelectedSection(sectionProp)
  }, [sectionProp])

  const section = getSection(selectedSection)

  if (!section) return null

  const handleSearch = (value) => {
    setSearchTerm(value)
  }

  return (
    <DashboardContent>
      <NavBar
        selectedSection={selectedSection}
        onSearch={handleSearch}
        onSelect={setSelectedSection}
      />
      <Section
        key={section.section}
        section={section}
        searchTerm={searchTerm}
      />
    </DashboardContent>
  )
}

// TODO: possibly merge DASHBOARD_SECTIONS and DASHBOARD_SUB_SECTIONS
// into areas
export const DASHBOARD_SECTIONS = {
  LEARN: "learn",
  TEACH: "teach",
  DESIGN: "design",
  PEOPLE: "people"
}

export const DASHBOARD_SUB_SECTIONS = {
  ACTIVE: "active",
  GROUPS: "groups"
}

const areas = [
  { key: "paths", label: "Paths", to: `/home/${DASHBOARD_SECTIONS.LEARN}/` },
  { key: "people", label: "People", to: `/home/${DASHBOARD_SECTIONS.PEOPLE}/` }
]

const validEntryPaths = [
  "paths.learn",
  "paths.teach",
  "paths.design",
  "people.groups",
  "people.active"
]

const getSelectedAreaIndex = (section) => () => {
  return section === DASHBOARD_SECTIONS.PEOPLE ? 1 : 0
}

const Dashboard = ({ section, subSection, nodeKey }) => {
  const [selectedAreaIndex, setSelectedAreaIndex] = useState(
    getSelectedAreaIndex(section)
  )
  const pwContext = usePathwrightContext()

  const { t } = useTranslate()

  let entryPath =
    selectedAreaIndex === 0
      ? `paths.${section}`
      : `people.${subSection || "groups"}`

  // Unfortunately the `section` can be momentarily out of sync with the
  // `selectedAreaIndex`, so must rule out any invalid `entryPath`s.
  if (!validEntryPaths.includes(entryPath)) {
    entryPath = null
  }

  // We can use `getPath` to get the active route for a given entry key.
  const { getPath } = useHomeActiveEntries(entryPath)

  // Get the last active paths for the various Home/(Dashboard) sections.
  const sectionToPaths = useMemo(
    () => ({
      0: getPath("paths"),
      1: getPath("people")
    }),
    [entryPath]
  )

  const handleSelectArea = (index) => {
    if (window.App) window.App.navigate(sectionToPaths[index])
    setSelectedAreaIndex(index)
  }

  // Keep `selectedAreaIndex` in sync with `section` prop.
  useEffect(() => {
    setSelectedAreaIndex(getSelectedAreaIndex(section))
  }, [section])

  if (!pwContext.me) return null

  return (
    <Wrap className="Dashboard__wrap">
      {/* Portaling the ThemeBackground to resolve background glitch when 
      hovering over Collection near the bottomo of the screen. */}
      <Portal portalElementID="theme-portal">
        <ThemeBackground />
      </Portal>
      <Header>
        <Profile />
        {canViewHomePeople(pwContext) && (
          <nav>
            <SwitchButton
              options={areas.map((area) => ({ ...area, label: t(area.label) }))}
              onChange={handleSelectArea}
              activeIndex={selectedAreaIndex}
            />
          </nav>
        )}
      </Header>
      <Container>
        {selectedAreaIndex === 0 ? (
          <Paths section={section} />
        ) : (
          <People section={subSection} nodeKey={nodeKey} />
        )}
      </Container>
    </Wrap>
  )
}

Dashboard.displayName = "Dashboard"

Dashboard.propTypes = {
  section: PropTypes.oneOf(Object.values(DASHBOARD_SECTIONS)),
  // applies to the "people" section
  subSection: PropTypes.oneOf(Object.values(DASHBOARD_SUB_SECTIONS))
}

export default Dashboard
