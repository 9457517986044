import defaults from "lodash/defaults"
import omit from "lodash/omit"
import pick from "lodash/pick"
import { useMemo } from "react"

// Util hook for splitting out picked and omitted props
const usePropsPicker = (keys, props, defaultProps) => {
  const pickedProps = useMemo(() => {
    const filteredProps = pick(props, keys)
    return defaults(filteredProps, defaultProps)
  }, [keys, props, defaultProps])

  const omittedProps = useMemo(() => omit(props, keys), [keys, props])

  return [pickedProps, omittedProps]
}

export default usePropsPicker
