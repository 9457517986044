import Link from "@pathwright/ui/src/components/link/Link"
import styled from "styled-components"
import { DISCUSSION_TYPE_FEEDBACK } from "../discussion/constants"
import {
  getCommunityAreaUrl,
  getDiscussionUrl,
  getResponseUrl,
  getStepUrl
} from "../utils/urls"
import ResourceThumbnail from "./ResourceThumbnail"

const StyledContainer = styled.div`
  position: relative;
  border-radius: 8px;
  font-size: 16px;
  color: black;
  background-color: rgba(255, 255, 255, 0.9);
  &:hover {
    background-color: white;
  }
  p {
    margin: 0;
  }
`

const StyledLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const StyledWrapper = styled.div`
  position: relative;
  width: 100%;
  pointer-events: none;
`

const StyledDiscussion = styled.div`
  padding: 10px 20px;
  padding-left: 30px;

  &:not(:last-child) {
    color: #888;
    font-size: 13px;
  }
`

const StyledResponse = styled.div`
  overflow: hidden;
  padding: 10px 20px;
  border-radius: 0 0 8px 8px;
  background-color: rgba(0, 0, 0, 0.05);
  img {
    max-width: 100%;
  }
`

const ellipsify = (str, limit) => {
  return str.length > limit ? str.slice(0, limit) + "..." : str
}

const DiscussionActivity = ({
  resource,
  group,
  step,
  discussion,
  discussion_response,
  isCourseActivity,
  isStepActivity
}) => {
  if (!resource || !group || !discussion) {
    return null
  }
  let toDiscussion
  if (discussion_response) {
    toDiscussion = getResponseUrl({
      resourceSlug: resource.slug,
      groupId: group.id,
      stepId: step ? step.source_id : null,
      discussionId: discussion.id,
      responseId: discussion_response.id
    })
  } else {
    toDiscussion = getDiscussionUrl({
      resourceSlug: resource.slug,
      groupId: group.id,
      stepId: step ? step.source_id : null,
      discussionId: discussion.id
    })
  }

  if (discussion.type === DISCUSSION_TYPE_FEEDBACK) {
    // Oddities have been detected where the step does not exist on the item.
    if (step) {
      const urlParams = new URLSearchParams({
        cohort_id: group.id,
        step_id: step.id,
        ui_selected: step.id,
        ui_selected_parent: group.id,
        filter: "all",
        ui_panel: "discuss"
      })

      if (discussion_response) {
        urlParams.set("ui_panel_selected", discussion_response.id)
      }

      if (step.completion && step.completion.user) {
        urlParams.set("user_id", step.completion.user.id)
      }

      toDiscussion = `/inbox/?${urlParams.toString()}`
    } else {
      console.warn("Discussion activity with missing step", {
        resource,
        group,
        step,
        discussion,
        discussion_response
      })
    }
  }

  const handleOpenDiscussion = (e) => {
    if (discussion.type === DISCUSSION_TYPE_FEEDBACK) {
      // window.App.getStore("layout").action.showIn(
      //   "panel",
      //   CompletionFeedbackPanel, // DockPanel component
      //   {
      //     // CompletionFeedbackPanel props
      //     stepId: step.id
      //   },
      //   {
      //     // DockPanel props
      //     allowOrient: false,
      //     panelWidthPerc: 0.8,
      //     title: `Feedback for ${step.name}`,
      //     link: getStepUrl(resource.slug, group.id, step.id),
      //     onClose: () => {
      //       window.App.getStore("layout").action.closeIn("panel")
      //     }
      //   }
      // )
    } else {
      e.preventDefault()
      if (!window.App) {
        return
      }
      window.App.stores
        .requestStore("discussion")
        .action.selectDiscussionResponse(
          discussion.id,
          discussion_response ? discussion_response.id : null
        )
      window.App.request("show:discussion:panel", discussion.id, {
        view: "show",
        discussion_id: discussion.id,
        context_label: step ? step.name : group.name,
        context: step
          ? {
              step: step.source_id,
              course: resource.id,
              class: group.id,
              url: getStepUrl(resource.slug, group.id, step.id)
            }
          : {
              course: resource.id,
              class: group.id,
              url: getCommunityAreaUrl(resource.slug, group.id, "discussion")
            }
      })
    }
  }

  return (
    <StyledContainer>
      <StyledLink to={toDiscussion} onClick={handleOpenDiscussion} />
      <StyledWrapper>
        {!(isCourseActivity || isStepActivity) && (
          <ResourceThumbnail resource={resource} />
        )}
        {!discussion_response && (
          <StyledDiscussion>
            <p
              dangerouslySetInnerHTML={{
                __html: ellipsify(discussion.title, 100)
              }}
            />
          </StyledDiscussion>
        )}
        {discussion_response && (
          <StyledDiscussion>
            {discussion_response.parent_response ? (
              <p
                dangerouslySetInnerHTML={{
                  __html: ellipsify(
                    discussion_response.parent_response.body,
                    100
                  )
                }}
              />
            ) : (
              <p>{discussion.title}</p>
            )}
          </StyledDiscussion>
        )}
        {discussion_response && (
          <StyledResponse>
            <p
              dangerouslySetInnerHTML={{
                __html: ellipsify(discussion_response.body, 100)
              }}
            />
          </StyledResponse>
        )}
      </StyledWrapper>
    </StyledContainer>
  )
}

DiscussionActivity.displayName = "DiscussionActivity"

export default DiscussionActivity
