import { Box, BoxProps } from "@chakra-ui/react"
import { motion } from "framer-motion"
import { useRef } from "react"

// Autofocus the first input _after_ animation completes.
// This avoids a situation where browser auto-complete suggestions
// (lke in Safari) get positioned in relation to the focused input's
// initial position versus the input's final position at the end of
// the animation.
const autoFocusNextElement = (element?: HTMLDivElement) => {
  // Only focus first input if no other element has focus.
  if (!document.hasFocus() || document.activeElement?.tagName === "BODY") {
    // NOTE: ignoring focusing checkbox inputs, as it seems slightly odd UX to do so.
    const inputs = element?.querySelectorAll(`
      input:not([readonly]):not([type='checkbox'])
    `) as HTMLInputElement[] | undefined

    // Attempt to find first input with no value.
    const input = [...(inputs || [])].find((input) => input.value === "")
    if (input) {
      input.focus()
    } else if (inputs?.length) {
      // Otherwise, focus first input.
      inputs[0].focus()
    }
  }
}

const AuthMotion = (props: BoxProps) => {
  const boxRef = useRef<HTMLDivElement>()
  const onAnimationComplete = () => autoFocusNextElement(boxRef.current)

  return (
    <Box
      as={motion.div}
      ref={boxRef}
      initial={{ opacity: 0, y: -30 }}
      animate={{
        opacity: 1,
        y: 0
      }}
      onAnimationComplete={onAnimationComplete}
      w="100%"
      {...props}
    />
  )
}

export default AuthMotion
