// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
let CategoryCollection
export default CategoryCollection = (function () {
  CategoryCollection = class CategoryCollection extends (
    require("lib/static-shim").default(require("lib/core/collection").default)
  ) {
    static initClass() {
      this.prototype.model = require("./category").default

      this.prototype.comparator = "order"
    }

    initialize(models, options) {
      this.options = options
      return (this.loadAll =
        this.options != null ? this.options.loadAll : undefined)
    }

    url() {
      let url = this.urlRoot(`/api/private/library/category/`)
      if (this.loadAll) {
        url += "?manage=1"
      }
      return url
    }
  }
  CategoryCollection.initClass()
  return CategoryCollection
})()
