import { HStack } from "@chakra-ui/react"
import { InfoCircleIcon } from "@pathwright/pathicons"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import RoleDescriptorsDrawer from "../shared/RoleDescriptorsDrawer"

const SelectRoleDescriptorsDrawerOption = ({ children }) => {
  const { tc } = useTranslate()

  return (
    <RoleDescriptorsDrawer>
      {({ ref, onOpen }) => {
        const option = {
          label: tc(`share_card.add_tab.Learn more`),
          render: (node) => (
            <HStack ref={ref} color="gray.500">
              <InfoCircleIcon size={"1.2em"} />
              {node}
            </HStack>
          )
        }

        return children({
          option,
          onOpen
        })
      }}
    </RoleDescriptorsDrawer>
  )
}

export default SelectRoleDescriptorsDrawerOption
