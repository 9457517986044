import gql from "graphql-tag"
import USER_FRAGMENT from "./user-fragment"

export default gql`
  query UserQuery($id: Int!) {
    user(id: $id) {
      ...User
    }
  }
  ${USER_FRAGMENT}
`
