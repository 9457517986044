import React from "react"

const QuillEditorContext = React.createContext()

export const useQuillEditorContext = () => React.useContext(QuillEditorContext)

const QuillEditorProvider = ({ children, quill, quillRef }) => {
  return (
    <QuillEditorContext.Provider
      value={{
        quill,
        quillRef
      }}
    >
      {children}
    </QuillEditorContext.Provider>
  )
}

export default QuillEditorProvider
