import Button from "@pathwright/ui/src/components/button/Button"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import gql from "graphql-tag"
import get from "lodash/get"
import { useEffect, useState } from "react"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import { canAdministrate, canViewMentorGroups } from "../../user/permissions"

const ManageMembers = () => {
  const { t } = useTranslate()

  return (
    <Button
      to="/community/members/"
      icon="people"
      label={t("Manage")}
      styleType="primary"
    />
  )
}

// Filtering by mentor group memberships, with a minimum role
// of mentor.
const MENTOR_GROUP_MEMBERSHIPS_QUERY = gql`
  query MentorGroupMemberships {
    me {
      id
      mentorGroupMemberships(
        first: 1
        role: { gte: moderator }
        mentor_group_seat_limit_filter: { neq: 1 }
      ) {
        total
        edges {
          node {
            id
            role
            group_id
          }
        }
      }
    }
  }
`

const ManageMentorGroups = () => {
  const { t } = useTranslate()
  const [groupId, setGroupId] = useState()
  const query = useQuery(MENTOR_GROUP_MEMBERSHIPS_QUERY)

  const total = get(query.data, "me.mentorGroupMemberships.total")

  // Check if the user has only 1, admin membership and if so,
  // link directly to the related mentor group.
  useEffect(() => {
    if (total === 1) {
      const cgm = query.data.me.mentorGroupMemberships.edges[0].node
      if (cgm.role === 15) {
        setGroupId(cgm.group_id)
      }
    }
  }, [total])

  const pathname = groupId
    ? `/community/groups/${groupId}/`
    : "/community/groups/"

  return total >= 1 ? (
    <Button
      to={pathname}
      icon="people"
      label={t("Manage")}
      styleType="primary"
    />
  ) : null
}

const ManageNavItem = () => {
  const pwContext = usePathwrightContext()

  if (canAdministrate(pwContext)) {
    return <ManageMembers />
  }

  if (canViewMentorGroups(pwContext)) {
    return <ManageMentorGroups />
  }

  return null
}

export default ManageNavItem
