import { withTranslate } from "@pathwright/ui/src/components/"
import Avatar from "@pathwright/ui/src/components/avatar/Avatar"
import Button from "@pathwright/ui/src/components/button/Button"
import DropdownItem from "@pathwright/ui/src/components/dropdown/DropdownItem"
import DropdownMenu from "@pathwright/ui/src/components/dropdown/DropdownMenu"
import DropdownTrigger from "@pathwright/ui/src/components/dropdown/DropdownTrigger"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import useHomeActiveEntries from "@pathwright/web/src/modules/home/useHomeActiveEntries"
import InboxNavigatorIndicator from "@pathwright/web/src/modules/inbox/navigator/InboxNavigatorIndicator"
import NotificationsIndicator from "@pathwright/web/src/modules/notifications/NotificationsIndicator"
import getStripePaymentPortalHref from "@pathwright/web/src/modules/stripe/utils/getStripePaymentPortalHref"
import { withUserCommunity } from "@pathwright/web/src/modules/user/UserCommunityContext"
import { urls } from "lib/core/utils"
import PropTypes from "prop-types"
import ChangelogToggle from "../../../../changelog/ChangelogToggle"
import "./styles/NavbarUser.css"

const HomeDropdownItem = ({ user, tc }) => {
  const { path: homePath } = useHomeActiveEntries()

  return (
    <DropdownItem icon="homepage-learn" to={homePath}>
      {!!user && (
        <Avatar
          style={{ marginRight: 2.3 }}
          user={user}
          size={16}
          sizeUnit="px"
        />
      )}
      {`${tc("Home")}`}
    </DropdownItem>
  )
}

const NavbarUser = function ({
  isAuthenticated,
  user,
  school,
  showTeach,
  showDesign,
  buttonStyle,
  isCommunityGroupOwner,
  communityGroupSubscriptions,
  communityGroupWithActiveSubscription,
  t,
  tc
}) {
  if (isAuthenticated) {
    const hasInactiveGroupSubscription =
      communityGroupSubscriptions.length > 0 &&
      !communityGroupWithActiveSubscription
    return (
      <div className="NavbarUser">
        <DropdownTrigger
          className="NavbarUser__dropdown"
          renderDropdown={() => {
            return (
              <DropdownMenu closeOnClick={true}>
                <HomeDropdownItem user={user} tc={tc} />
                <DropdownItem
                  icon="gear"
                  label={t(["dashboard.header.your_settings", "Your settings"])}
                  to={urls.modalRoute("/manage/profile/")}
                />
                {communityGroupSubscriptions.length > 0 ? (
                  isCommunityGroupOwner ? (
                    <DropdownItem
                      icon="credit-card"
                      label={tc("Manage subscription")}
                      href={getStripePaymentPortalHref(school)}
                    />
                  ) : (
                    <DropdownItem
                      icon="credit-card"
                      label={tc("Manage subscription")}
                      to="/subscribe"
                    />
                  )
                ) : null}
                <DropdownItem
                  icon="external-link"
                  label={t("nav.navbar_user.sign_out")}
                  to="/auth/sign-out/"
                />
              </DropdownMenu>
            )
          }}
        >
          <Button {...Object.assign({}, buttonStyle)}>
            <Avatar user={user} size="25px" /> <Pathicon icon="chevron-down" />
            {/* {hasInactiveGroupSubscription ? <StyledAvatarDot /> : null} */}
          </Button>
        </DropdownTrigger>
        <InboxNavigatorIndicator />
        <NotificationsIndicator />
        <ChangelogToggle />
        {/* <NotificationIndicator buttonStyle={buttonStyle} /> */}
      </div>
    )
  } else {
    return (
      <Button
        {...Object.assign(
          { className: "NavbarUser__signin NavbarUser__signin" },
          buttonStyle,
          {
            to: `/auth/sign-in?next=${
              window.location.pathname + window.location.search
            }`
          }
        )}
      >
        <span>{t("nav.navbar_user.sign_in")}</span>
      </Button>
    )
  }
}

NavbarUser.displayName = "NavbarUser"

NavbarUser.contextTypes = { school: PropTypes.object }

export default withTranslate(withUserCommunity(NavbarUser))
