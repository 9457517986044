import gql from "graphql-tag"

export default gql`
  mutation DeleteDiscussionResponse(
    $context: DiscussionContextInput!
    $discussion_id: Int!
    $response_id: Int!
  ) {
    deleteDiscussionResponse(
      context: $context
      discussion_id: $discussion_id
      response_id: $response_id
    )
  }
`
