import gql from "graphql-tag"

export default gql`
  query SSOLinkQuery($schoolId: Int!) {
    context(school_id: $schoolId) {
      school {
        id
        sso_link
      }
    }
  }
`
