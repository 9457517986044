import { useMutation } from "@apollo/client"
import useDebouncedValue from "@pathwright/ui/src/components/hooks/useDebouncedValue"
import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import produce from "immer"
import React from "react"
import { usePaginator } from "../../../utils/apollo"
import {
  MENTOR_GROUP_MEMBERSHIP_ROLE_INPUTS,
  MENTOR_GROUP_MEMBERSHIP_ROLE_INTS
} from "../../constants"
import DELETE_MENTOR_GROUP_MEMBERSHIP_MUTATION from "../../graphql/delete-mentor-group-membership-mutation"
import MENTOR_GROUP_FRAGMENT from "../../graphql/mentor-group-fragment"
import MENTOR_GROUP_MEMBERSHIPS_QUERY from "../../graphql/mentor-group-memberships-query"
import MENTOR_GROUP_QUERY from "../../graphql/mentor-group-query"
import MENTOR_GROUP_STAFF_MEMBERSHIPS_QUERY from "../../graphql/mentor-group-staff-memberships-query"
import MENTOR_GROUP_SUBSCRIPTION_QUERY from "../../graphql/mentor-group-subscription-query"
import UPDATE_MENTOR_GROUP_MEMBERSHIP_MUTATION from "../../graphql/update-mentor-group-membership-mutation"

export const MentorGroupListContext = React.createContext({})

export const useMentorGroupListContext = () =>
  React.useContext(MentorGroupListContext)

const MentorGroupListContextProvider = ({ children, groupId }) => {
  const [search, debouncedSearch, debouncingSeach, setSearch] =
    useDebouncedValue()

  const mentorGroupQuery = useQuery(MENTOR_GROUP_QUERY, {
    variables: {
      id: groupId
    }
  })
  const mentorGroup = mentorGroupQuery.data?.school?.mentor_group

  const staffQuery = useQuery(MENTOR_GROUP_STAFF_MEMBERSHIPS_QUERY, {
    variables: {
      group_id: groupId
    }
  })
  const staff =
    staffQuery.data?.school?.mentor_group?.memberships?.edges?.map(
      (e) => e.node
    ) || []

  const membersQuery = useQuery(MENTOR_GROUP_MEMBERSHIPS_QUERY, {
    variables: {
      group_id: groupId,
      search: null
    }
  })
  const members =
    membersQuery.data?.school?.mentor_group?.memberships?.edges?.map(
      (e) => e.node
    ) || []

  const searchedMembersQuery = useQuery(MENTOR_GROUP_MEMBERSHIPS_QUERY, {
    variables: {
      group_id: groupId,
      search: debouncedSearch
    },
    // Unsure if we should...
    fetchPolicy: "cache-and-network"
  })
  const searchedMembers =
    searchedMembersQuery.data?.school?.mentor_group?.memberships?.edges?.map(
      (e) => e.node
    ) || []

  const searchedMembersPaginator = usePaginator({
    data: searchedMembersQuery, // hacky
    path: "school.mentor_group.memberships"
  })

  const groupSubscriptionQuery = useQuery(MENTOR_GROUP_SUBSCRIPTION_QUERY, {
    variables: {
      group_id: groupId
    },
    skip: mentorGroup?.type !== 15
  })
  const groupSubscription =
    groupSubscriptionQuery.data?.school.mentor_group.group_subscriptions
      .edges?.[0]?.node

  const permissions = mentorGroup?.permissions || {}

  const seatLmitReached =
    typeof mentorGroup?.seats_available === "number" &&
    mentorGroup?.seats_available <= 0

  const [deleteMembershipMutation, deleteMembershipMutationState] = useMutation(
    DELETE_MENTOR_GROUP_MEMBERSHIP_MUTATION
  )

  const deleteMembership = (membership) => {
    return deleteMembershipMutation({
      variables: {
        id: membership.id,
        group: mentorGroup.id
      },
      update: (cache, response) => {
        function updateMembersCache(options) {
          options.forEach(({ query, variables }) => {
            const data = cache.readQuery({ query, variables })
            const nextData = produce(data, (draft) => {
              const { edges } = draft.school.mentor_group.memberships
              const i = edges.findIndex((e) => e.node.id === membership.id)
              if (i !== -1) {
                edges.splice(i, 1)
              }
            })
            cache.writeQuery({ query, variables, data: nextData })
          })
        }

        // Remove user from either the members list or staff list.
        const cacheOptions = [
          {
            query: MENTOR_GROUP_MEMBERSHIPS_QUERY,
            variables: {
              group_id: mentorGroup.id,
              search: debouncedSearch
            }
          },
          {
            query: MENTOR_GROUP_STAFF_MEMBERSHIPS_QUERY,
            variables: {
              group_id: mentorGroup.id
            }
          }
        ]

        updateMembersCache(cacheOptions)

        // Update group seats info.
        if (!membership.access_revoked_time) {
          const fragment = {
            id: `MentorGroup:${mentorGroup.id}`,
            fragment: MENTOR_GROUP_FRAGMENT,
            fragmentName: "MentorGroup"
          }
          const data = cache.readFragment(fragment)
          if (data) {
            cache.writeFragment({
              ...fragment,
              data: {
                ...data,
                seats_available: data.seats_available + 1,
                seats_filled: data.seats_filled - 1
              }
            })
          }
        }
      },
      refetchQueries: ["UserGroupSubscriptions"]
    })
  }

  const [updateMembershipMutation, updateMembershipMutationState] = useMutation(
    UPDATE_MENTOR_GROUP_MEMBERSHIP_MUTATION
  )

  const updateMembership = (membership) => (variables) => {
    // Ensure role matches expected input.
    if (variables.role && typeof variables.role === "number") {
      variables.role = MENTOR_GROUP_MEMBERSHIP_ROLE_INPUTS[variables.role]
    }

    return updateMembershipMutation({
      variables: {
        id: membership.id,
        group: mentorGroup.id,
        ...variables
      },
      optimisticResponse: {
        __typename: "Mutations",
        updateMentorGroupMembership: {
          ...membership,
          role:
            MENTOR_GROUP_MEMBERSHIP_ROLE_INTS[variables.role] ||
            membership.role,
          can_review: variables.canReview || membership.can_review,
          access_revoked_time:
            typeof variables.hasAccess === "boolean"
              ? variables.hasAccess
                ? null
                : new Date().toISOString()
              : membership.access_revoked_time,
          __typename: "MentorGroupMembership"
        }
      },
      // cache can be quite complicated to update
      // (active search query, moving between staff/members)
      // so we just refetch the query for simplicity.
      // NOTE: refetching "MentorGroup" because user's own permissions may have changed after update (i.e. due to role change)
      refetchQueries: [
        "MentorGroup",
        "MentorGroupStaffMemberships",
        "MentorGroupMemberships"
      ],
      update: (cache, result, { variables: { hasAccess } }) => {
        if (mentorGroup.seat_limit >= 0) {
          const query = MENTOR_GROUP_QUERY
          const variables = { id: mentorGroup.id }
          const data = cache.readQuery({ query, variables })

          const nextData = produce(data, (draft) => {
            if (hasAccess) {
              draft.school.mentor_group.seats_available--
              draft.school.mentor_group.seats_filled++
            } else {
              draft.school.mentor_group.seats_available++
              draft.school.mentor_group.seats_filled--
            }
          })

          cache.writeQuery({
            query,
            variables,
            data: nextData
          })
        }
      }
    })
  }

  return (
    <MentorGroupListContext.Provider
      value={{
        mentorGroupQuery,
        mentorGroup,
        staffQuery,
        staff,
        membersQuery,
        members,
        groupSubscriptionQuery,
        groupSubscription,
        permissions,
        seatLmitReached,
        searchedMembersQuery,
        searchedMembers,
        searchedMembersPaginator,
        search,
        debouncedSearch,
        debouncingSeach,
        setSearch,
        deleteMembership,
        deleteMembershipMutationState,
        updateMembership,
        updateMembershipMutationState
      }}
    >
      {children}
    </MentorGroupListContext.Provider>
  )
}

export default MentorGroupListContextProvider
