import { createContext, useContext, useState } from "react"
import styled from "styled-components"
import PersonaCard from "../../../home/people/PersonaCard"

// State

const ContextCardsContext = createContext()

const contextCards = {
  profile: {
    title: "Profile",
    component: ({ user, onClose }) => (
      <PersonaCard node={user} onClose={onClose} />
    )
  }
}

const _useContextCards = () => {
  const [cards, setCards] = useState([])
  const open = (key, props) => {
    setCards(cards.concat({ key, props }))
  }

  const close = (key) => {
    setCards(cards.filter((c) => c.key !== key))
  }

  const pop = () => {
    const [card, ...rest] = cards
    setCards(rest)
    return card
  }

  return { cards, open, close, pop }
}

export const useContextCards = () => useContext(ContextCardsContext)

export const ContextCardsProvider = ({ children }) => {
  const value = _useContextCards()
  return (
    <ContextCardsContext.Provider value={value}>
      {children}
    </ContextCardsContext.Provider>
  )
}

// UI

const ContextCardContainer = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  background-color: #fff;
  border-radius: 20px;

  .IconButton {
    position: absolute;
    top: 10px;
    right: 10px;
  }
`

const ContextCard = ({ cardKey, onClose, ...props }) => {
  const { cards, open, close, pop } = useContextCards()

  const handleClose = () => {
    close(cardKey)
    onClose && onClose()
  }

  const Component = contextCards[cardKey].component

  return (
    <ContextCardContainer>
      <Component {...props} onClose={onClose} />
      {/* <IconButton icon="x" onClick={handleClose} /> */}
    </ContextCardContainer>
  )
}

export const ContextCardsStackContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  z-index: 1000000000;
  background-color: white;
  width: 100%;
  max-height: calc(var(--vh) - 70px);
  border-radius: 20px;
  overflow: auto;

  .Card__Nav ~ * {
    max-height: calc(100% - 40px) !important;
  }

  .ProfileCard .ProfileCard__header {
    padding: 0 10px;
  }

  .ProfileCard .ProfileCard__header > div {
    margin-right: 10px !important;
  }

  .ProfileCard .ProfileCard__header .Avatar,
  .ProfileCard .ProfileCard__header .Avatar img {
    width: 50px !important;
    height: 50px !important;
    line-height: 50px !important;
  }
`

export const ContextCardsStack = () => {
  const { cards, open, close, pop } = useContextCards()

  if (!cards.length) return null

  return (
    <ContextCardsStackContainer>
      {cards.map(({ key: cardKey, props }) => (
        <ContextCard
          key={cardKey}
          cardKey={cardKey}
          {...props}
          onClose={() => close(cardKey)}
        />
      ))}
    </ContextCardsStackContainer>
  )
}
