import { Suspense } from "react"
import lazy from "react-lazy-with-preload"
import LoadingBlock from "../loading/LoadingBlock"

export const LazyQuillEditor = lazy(() =>
  import("./index" /* webpackChunkName: "Quill" */)
)

export const SuspendedQuillEditor = ({ LoadingComponent, ...props }) => (
  <Suspense
    fallback={LoadingComponent ? <LoadingComponent /> : <LoadingBlock />}
  >
    <LazyQuillEditor
      LoadingComponent={LoadingComponent || LoadingBlock}
      {...props}
    />
  </Suspense>
)

export default SuspendedQuillEditor
