import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import CardBlock from "@pathwright/ui/src/components/card/CardBlock"
import CurrencyFormatter from "@pathwright/web/src/modules/currency/CurrencyFormatter"
import ProductCheckboxes, {
  useProducts
} from "@pathwright/web/src/modules/stripe/product/ProductCheckboxes"
import SchoolSubscriptionPlansCheckboxes, {
  useSchoolSubscriptionPlans
} from "@pathwright/web/src/modules/subscription/manage/SchoolSubscriptionPlansCheckboxes"
import {
  ConnectForm,
  RadioButton,
  RadioGroup,
  TextInput,
  validate
} from "lib/core/components/forms"

const hasCost = (fields) => fields.nonsubpricing.value > 0

let fieldsData = (props) => ({
  nonsubpricing: {
    label: "Pricing",
    validations: [
      validate.validateIf(hasCost, validate.isNumeric),
      validate.validateIf(
        hasCost,
        validate.isGreaterThanOrEqualTo(
          0.5,
          (n) => parseFloat(n).toFixed(2) /* parser */
        )
      ),
      validate.validateIf(hasCost, (values, value) => {
        const isValid = props.canAcceptPayments || value <= 0
        if (!isValid) {
          return "You must set up a Stripe account first."
        }
      })
    ]
  },
  subpricing: {
    label: "Pricing for subscribers"
  },
  products: {
    label: "Products",
    includeInactive: true
  }
})

const CommerceForm = function ({
  fieldsData,
  isValid,
  handleSubmit,
  group,
  submitFailure,
  submitSuccess,
  submitting
}) {
  const { schoolSubscriptionPlans } = useSchoolSubscriptionPlans()
  const { products } = useProducts({ includeInactive: true })
  const hasSubscriptionPlans =
    schoolSubscriptionPlans && schoolSubscriptionPlans.length > 0
  const hasProducts = products.length > 0

  // run subpricing validations whenever nonsubpricing changes
  const handleChange = (value) => {
    const { nonsubpricing, subpricing } = fieldsData
    nonsubpricing.onChange(value)
    return subpricing.onChange(subpricing.value)
  }

  let isSubOnlyValid = true
  // require at least 1 sub plan or 1 product to be selected if making unavailable to non-subscribers
  if (
    fieldsData.nonsubpricing.value === -1 &&
    (!hasSubscriptionPlans ||
      (hasSubscriptionPlans && !fieldsData.subpricing.value.length)) &&
    (!hasProducts || (hasProducts && !fieldsData.products.value.length))
  ) {
    isSubOnlyValid = false
  }

  return (
    <form onSubmit={handleSubmit}>
      <SubmitButton
        submitting={submitting}
        submitSuccess={submitSuccess}
        submitFailure={submitFailure}
        disabled={!isValid || !isSubOnlyValid}
        styleType={"primary"}
      >
        Save
      </SubmitButton>
      <RadioGroup {...fieldsData.nonsubpricing} onChange={handleChange}>
        {(hasSubscriptionPlans || hasProducts) && (
          <RadioButton value={-1}>
            Not available for non-subscribers.
          </RadioButton>
        )}
        <RadioButton value={0}>Free</RadioButton>
        <RadioButton
          value={
            (group != null ? group.price : undefined) > 0 ? group.price : null
          }
        >
          {({ value, onChange }) => {
            return (
              <div>
                Charge{" "}
                <CurrencyFormatter>
                  <TextInput
                    value={value}
                    onChange={onChange}
                    type="number"
                    step="any"
                    minWidth={30}
                    autoSize={true}
                    hideStatus={true}
                    inline={true}
                  />
                </CurrencyFormatter>
              </div>
            )
          }}
        </RadioButton>
      </RadioGroup>
      {hasSubscriptionPlans && (
        <CardBlock>
          <SchoolSubscriptionPlansCheckboxes {...fieldsData.subpricing} />
        </CardBlock>
      )}
      {hasProducts && (
        <CardBlock>
          <ProductCheckboxes
            {...fieldsData.products}
            selectedProductIds={fieldsData.products.value}
          />
        </CardBlock>
      )}
    </form>
  )
}

CommerceForm.displayName = "CommerceForm"

export default ConnectForm(CommerceForm, fieldsData)
