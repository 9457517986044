// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
let OfferingRegistrations
export default OfferingRegistrations = class OfferingRegistrations extends (
  require("lib/static-shim").default(require("lib/utils/model").default)
) {
  url() {
    const { type, id, expand } = this.toJSON()

    const params = { type }
    if (expand) {
      params.expand = expand
    }

    const url = this.urlRoot(`api/private/offering/${this.id}/registrations/`)

    return `${url}?${$.param(params)}`
  }
}
