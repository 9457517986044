import gql from "graphql-tag"
import AUTHOR_FRAGMENT from "../../author/graphql/author-fragment"

export default gql`
  query LibraryAuthors(
    $search: String
    $resourceSearch: String
    $resourceAuthorNameFilter: StringFilter
    $resourceCategoryNameFilter: StringFilter
    $cursor: String
  ) {
    authors(
      first: 15
      after: $cursor
      search: $search
      resource_search: $resourceSearch
      resource_author_names: $resourceAuthorNameFilter
      resource_category_names: $resourceCategoryNameFilter
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...Author
        }
      }
    }
  }
  ${AUTHOR_FRAGMENT}
`
