import { useState } from "react"
import useDebounce from "./useDebounce"

const useDebouncedValue = (delay = 300, initialValue = null) => {
  const [value, setValue] = useState(initialValue)

  const debouncedValue = useDebounce(value, delay)

  const debouncing = debouncedValue !== value

  return [value, debouncedValue, debouncing, setValue]
}

export default useDebouncedValue
