import gql from "graphql-tag"

export default gql`
  fragment StripePrice on StripePrice {
    id
    active
    billing_scheme
    currency
    nickname
    recurring_interval
    recurring_interval_count
    stripe_price_id
    tiers_mode
    unit_amount_decimal
    tiers {
      up_to
      flat_amount
      flat_amount_decimal
      unit_amount
      unit_amount_decimal
    }
    product {
      id
      min_quantity
      max_quantity
    }
  }
`
