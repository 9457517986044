import getNextUrl from "./getNextUrl"

// Disabling signup when signing in through global /account/sign-in.
export const getAllowSignup = (): boolean | undefined => {
  const nextUrl = getNextUrl()

  if (nextUrl) {
    try {
      if (new URL(nextUrl!).pathname.startsWith("/account/sign-in")) {
        return false
      }
    } catch {}
  }
  return undefined
}

export const extendWithAllowSignup = <
  T extends Record<string, any> & { allowSignup?: boolean }
>(
  data: T,
  allowSignup?: boolean
): T => {
  if (typeof allowSignup === "undefined") {
    allowSignup = getAllowSignup()
  }

  if (typeof allowSignup === "undefined") {
    allowSignup = data.allowSignup
  }

  return {
    ...data,
    allowSignup
  }
}
