// In-state actions
export const ERROR = "ERROR"
export const GET_STATE = "GET_STATE"
export const UPDATE_USER_BLOCK = "UPDATE_USER_BLOCK"
export const RESET = "RESET"

// Syncer actions
export const SYNCED_USER_BLOCK = "SYNCED_USER_BLOCK"
export const START_SYNCING = "START_SYNCING"
export const STOP_SYNCING = "STOP_SYNCING"
