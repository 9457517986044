import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import { AudioButtonUI } from "./ui"

const PlayButtonUI = styled(AudioButtonUI)`
  margin: 0 1rem;
`

export const PlayButton = ({ onTogglePlaying, playing }) => {
  return (
    <PlayButtonUI
      className="play-button"
      icon={playing ? "pause" : "play"}
      onClick={onTogglePlaying}
    />
  )
}

PlayButton.propTypes = {
  onTogglePlaying: PropTypes.func.isRequired,
  playing: PropTypes.bool
}

export default PlayButton
