import PropTypes from "prop-types"
import React, { useEffect } from "react"
import styled from "styled-components"
import Button from "../../button/Button"
import SubmitButton from "../../button/SubmitButton"
import usePropsPicker from "../../hooks/usePropsPicker"
import { useTranslate } from "../../lng/withTranslate"
import { SUBMIT_STATUS } from "../../submit/SubmitHandler"
import Tooltip from "../../tooltip/Tooltip"
import { useScreensizeContext } from "../../ui/Screensize"
import { useDiscardFormStateContext } from "./DiscardFormStateContext"
import { useFormStateFormContext } from "./FormStateForm"

const StyledTooltip = styled(Tooltip)`
  .Tooltip {
    display: flex;
    flex-direction: column;
    align-items: center;

    .Button {
      margin-top: 1em;
    }
  }
`

const DiscardTooltip = ({
  discardCount,
  discardFormState,
  children,
  ...rest
}) => {
  const { t } = useTranslate()
  const screensize = useScreensizeContext()

  // You could provide a discard count less than 0 to indicate changes exist,
  // but the number is unknown.
  const label =
    discardCount > 0
      ? t("{{ count }} unsaved change", {
          defaultValue_plural: "{{ count }} unsaved changes",
          count: discardCount
        })
      : "Unsaved changes"

  // Positioning assumes the SubmitButton is in the top right corner
  // of the container/viewport. If the caret of the Tooltip can be
  // better positioned, we could use one position setting to handle
  // all screensizes (TODO).
  const tooltipPosition =
    screensize === "sm" || screensize === "md"
      ? {
          placement: "left",
          alignment: "top"
        }
      : {
          placement: "bottom",
          alignment: "center"
        }

  return (
    <StyledTooltip
      title={
        <React.Fragment>
          {label}
          <Button
            styleType="primary"
            size="small"
            inverted
            label={t("Discard")}
            icon="trash"
            onClick={discardFormState}
          />
        </React.Fragment>
      }
      delayShow={500}
      {...tooltipPosition}
      {...rest}
    >
      {children}
    </StyledTooltip>
  )
}

// Convenient SubmitButton container that integrates with DiscardFormStateContext
// and handles discarding stored form state.
const FormStateSubmitButton = (props) => {
  // Keys for props and context.
  const keys = ["discardCount", "discardFormState", "tooltipProps"]

  // Get values for keys, either from props or context.
  const [{ discardCount, discardFormState, tooltipProps }, submitButtonProps] =
    usePropsPicker(
      keys,
      props,
      // Optionally retrieve disard props via context.
      useDiscardFormStateContext()
    )

  // Potentially acquire the current form submission state
  // which can be passed on to the SubmitButton for responding
  // to loading/success/failure status.
  const formStateFormContext = useFormStateFormContext()
  const submitState = formStateFormContext
    ? formStateFormContext.submitState
    : {}

  const handleSubmitSuccess = () => {
    // Discard stored form state but preserve current form state
    // after successful submission.
    discardFormState(true /* preserve */)
    props.onSubmitSuccess?.()
  }

  useEffect(() => {
    if (submitState?.submitSuccess) handleSubmitSuccess()
  }, [submitState?.status])

  // NOTE: wrapping div only needed to correctly position SubmitButton
  // when rendered in a Card. There probably should is a better method...
  return (
    <div className="FormStateSubmitButton">
      <SubmitButton
        // Allow disabled prop to override disabled state based on discardCount.
        disabled={!discardCount}
        {...submitButtonProps}
        {...submitState}
        indicator={!!discardCount}
        render={(button, { status }) =>
          // Hide tooltip if submit status is anythinng but default
          // (i.e. don't want tooltip showing after clicking the SubmitButton).
          Boolean(discardCount && status === SUBMIT_STATUS.DEFAULT) ? (
            <DiscardTooltip
              discardCount={discardCount}
              discardFormState={discardFormState}
              {...tooltipProps}
            >
              {button}
            </DiscardTooltip>
          ) : (
            button
          )
        }
      />
    </div>
  )
}

FormStateSubmitButton.displayName = "FormStateSubmitButton"

FormStateSubmitButton.propTypes = {
  discardCount: PropTypes.number,
  discardFormState: PropTypes.func,
  tooltipProps: PropTypes.object
  // TODO: tooltipProps not quite handled correctly, some required fields that shouldn't be.
  // tooltipProps: PropTypes.shape(Tooltip.propTypes)
}

export default FormStateSubmitButton
