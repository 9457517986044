import PropTypes from "prop-types"
import styled from "styled-components"

// Responsible for rendering actiity item in left or right column.
export const ActivityItem = styled.div`
  margin-bottom: 20px;
  position: relative;
  display: flex;
  width: 100%;
  padding-right: 65px;

  /* && only applies to the instance of the component based on conditionals */
  .ActivityTimeline:not(.ActivityTimeline__single-column) && {
    ${(p) =>
      p.columnSide === "left"
        ? `
      width: 50%;
      margin-right: 50%;
      padding-right: 65px;
    `
        : `
      width: 50%;
      margin-left: 50%;
      padding-left: 65px;
      padding-right: 0;
    `}
  }
`

ActivityItem.propTypes = {
  columnSide: PropTypes.oneOf(["left", "right"])
}

// Responsible for adding a vertical line in between columns.
export const ActivityColumnDivider = styled.div`
  .ActivityTimeline:not(.ActivityTimeline__single-column) &:before {
    content: "";
    position: absolute;
    top: 45px;
    left: 50%;
    width: 1px;
    height: calc(100% - 45px);
    background-color: rgba(255, 255, 255, 0.2);
  }
`
