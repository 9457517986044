import { useMutation } from "@apollo/client"
import * as Sentry from "@sentry/browser"
import { useBackgroundTaskContext } from "../../bg-task/BackgroundTaskContext"
import USE_ADD_PEOPLE_TO_MUTATION from "../graphql/add-people-to-mutation"
import { useShareUIContext } from "../shared/ShareUIContextProvider"
import { getInvitationsContext } from "../tabs/TrackTab"
import { getOutcomeCounts } from "./utils"

const useAddPeopleTo = ({ peopleSelection, roleSelections, addToKey }) => {
  const { context } = useShareUIContext()

  const selection = peopleSelection.selected.map((item) => {
    const [type, key] = item.meta.key.split(":")
    return {
      type,
      key,
      add_to: Object.entries(roleSelections).map(([itemKey, roleSelection]) => {
        const [type, key] = itemKey.split(":")
        return {
          type,
          key,
          role: roleSelection.selected[item.meta.key]
        }
      })
    }
  })

  const [mutate, mutateState] = useMutation(USE_ADD_PEOPLE_TO_MUTATION, {
    variables: {
      selection
      // sendFromSchool,
      // sendNotification
    },
    onCompleted: (data) => {
      // Log to Sentry if share resulted in outcome errors
      if (
        getOutcomeCounts(data.addPeopleTo.result.selection, context, "error") >
        0
      ) {
        Sentry.withScope((scope) => {
          scope.setExtra(
            `share:${addToKey}:outcom-errors`,
            data.addPeopleTo.result.selection
          )
          Sentry.captureMessage("Share resulted in outcome errors.")
        })
      }
    }
  })

  useBackgroundTaskContext().useBackgroundTask(mutateState, {
    refetchQueries: [
      {
        type: "apollo",
        queryName: "InvitationsQuery",
        variables: {
          first: 300,
          context: getInvitationsContext(context)
        }
      }
    ]
  })

  return [mutate, mutateState]
}

export default useAddPeopleTo
