import Alert from "@pathwright/ui/src/components/alert/Alert"
import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import Card from "@pathwright/ui/src/components/card/Card"
import CardHeader from "@pathwright/ui/src/components/card/CardHeader"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import Text from "@pathwright/ui/src/components/ui/Text"
import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import capitalize from "lodash/capitalize"
import get from "lodash/get"
import { useEffect } from "react"
import styled from "styled-components"
import { usePathwrightContext } from "../pathwright/PathwrightContext"
import USER_SCHOOL_SUBSCRIPTION_QUERY from "../user/graphql/user-school-subscription-query"
import GroupSubscriptionOrderConfirmationCard from "./GroupSubscriptionOrderConfirmationCard"
import useOrderPollingQuery from "./useOrderPollingQuery"
import { getIntervalDisplayAdverb } from "./utils/subscription"

const NarrowCard = styled(Card)`
  & > .View {
    max-width: 500px;
  }
`

const CenteredContent = styled.div`
  padding: 40px 25px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
`

const SubscriptionConfirmationCard = ({ card, children }) => {
  const { school } = usePathwrightContext()
  const userSubscriptionQuery = useQuery(USER_SCHOOL_SUBSCRIPTION_QUERY, {
    fetchPolicy: "network-only"
  })
  const { t } = useTranslate()
  const userSubscription = get(userSubscriptionQuery, "data.me.school_subscription") // prettier-ignore

  return (
    <NarrowCard card={card} navless>
      {!!userSubscription && (
        <CardHeader
          card={card}
          title={userSubscription.subscription_plan.name}
          meta={`${capitalize(
            getIntervalDisplayAdverb(
              userSubscription.subscription_plan.interval,
              t
            )
          )} subscription to ${school.name}`}
        />
      )}
      {children}
    </NarrowCard>
  )
}

const SubscriptionConfirmation = ({ redirectTo }) => {
  const userSubscriptionQuery = useQuery(USER_SCHOOL_SUBSCRIPTION_QUERY, {
    fetchPolicy: "network-only"
  })
  const userSubscription = get(userSubscriptionQuery, "data.me.school_subscription") // prettier-ignore

  useEffect(() => {
    if (userSubscription) {
      // force reload user subscription in subscribe store for legacy UIs
      window.App &&
        window.App.getStore("subscribe").action.loadUserSubscription(
          true /* forceReload */
        )
    }
  }, [userSubscription])

  return userSubscription ? (
    <BlankSlate
      animateIcon
      stack
      icons={null}
      icon="check"
      heading="You’re Subscribed!"
      body={
        <Text.Body style={{ textAlign: "left", maxWidth: 350 }}>
          <ul style={{ margin: "1rem 0", listStyle: "disc" }}>
            <li>You now have 24/7 access to courses.</li>
            <li>
              We've sent you an email receipt with your subscription information
              for your records.
            </li>
          </ul>
        </Text.Body>
      }
      primaryAction={{
        to: redirectTo ? redirectTo : "/library/",
        children: redirectTo ? "Go back to course" : "Go back to library"
      }}
    />
  ) : (
    <LoadingCircle />
  )
}

export const SubscriptionRedeemptionConfirmationCard = ({
  card,
  redirectTo
}) => (
  <SubscriptionConfirmationCard card={card}>
    <SubscriptionConfirmation redirectTo={redirectTo} />
  </SubscriptionConfirmationCard>
)

export default function SubscriptionOrderConfirmationCard({ card, orderId }) {
  const orderQuery = useOrderPollingQuery(orderId)
  const order = get(orderQuery, "data.order")
  const redirectTo = get(order, "order_data.redirect_to")

  if (orderQuery.loading && !order) {
    return (
      <NarrowCard card={card}>
        <LoadingCircle />
      </NarrowCard>
    )
  }

  if (order && order.order_type === "group_subscription") {
    return (
      <GroupSubscriptionOrderConfirmationCard card={card} orderId={orderId} />
    )
  }

  return (
    <SubscriptionConfirmationCard card={card}>
      {orderQuery.error ? (
        <CenteredContent>
          <Alert error={orderQuery.error} />
        </CenteredContent>
      ) : order && order.order_status === "fulfilled" ? (
        <SubscriptionConfirmation redirectTo={redirectTo} />
      ) : (
        <BlankSlate
          icons={null}
          heading="Processing order..."
          primaryAction={{
            brand: false,
            submitting: true
          }}
        />
      )}
    </SubscriptionConfirmationCard>
  )
}
