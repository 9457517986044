import { i18n } from "@pathwright/ui/src/components/lng/I18nextProvider"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import { useMemo } from "react"
import { useShareDataContext } from "./ShareDataContextProvider"

// NOTE: these roles are listed in order of preference as to which role should beused as defualt.
// Some cohorts (i.e. Child Cohorts of a Collection) do not permit inviting learners
// or observers directly. To work around this, let us include all roles, ordering them according to which
// role we prefer to select as default.

// Note: these keys match up with the GQL field defined here:
// https://github.com/duointeractive/pathwright/blob/bc309d718fddff65c3058e4d52eabcd22bfde550/packages/pathwright-graphql/graphql/models/group/types.js#L101
const defaultRolesConfig = {
  group_member: {
    color: "blue"
  },
  group_moderator: {
    color: "green"
  },
  group_admin: {
    color: "orange"
  },
  // Cohort viewer is never displayed, no color
  cohort_viewer: {},
  cohort_learner: {
    color: "blue"
  },
  cohort_moderator: {
    color: "green"
  },
  cohort_teacher: {
    color: "orange"
  },
  // We don't currently make the course editor available in the ui.
  // cohort_course_editor: {
  //   color: "gray"
  // },
  cohort_observer: {
    color: "gray"
  },
  school_member: {
    color: "gray"
  },
  school_admin: {
    color: "gray"
  },
  school_staff: {
    color: "gray"
  }
}

const defaultRoles = Object.keys(defaultRolesConfig)

export function getDefaultRole(rolePermissions) {
  return defaultRoles.find((role) => rolePermissions[role])
}

export function getRolesConfig(rolePermissions) {
  const defaultRole = getDefaultRole(rolePermissions)

  return Object.entries(defaultRolesConfig).reduce((acc, [key, value]) => {
    if (rolePermissions[key]) {
      acc[key] = value
      acc[key].isDefault = key === defaultRole
    }
    return acc
  }, {})
}

// Merges in translated labels for each role.
export function useRolesConfigWithLabels() {
  const { permissions } = useShareDataContext()
  const { t, tc, conditionalT } = useTranslate()

  const rolesConfigWithLabels = useMemo(() => {
    let config = []
    if (permissions.invite_to_roles) {
      // Get base config.
      const rolesConfig = getRolesConfig(permissions.invite_to_roles)
      // Merge in translated labels.
      config = Object.entries(rolesConfig).reduce((acc, [key, value]) => {
        const tPrefix = `share_card.link_tab.roles.${key}`
        acc[key] = {
          ...value,
          label: tc(`${tPrefix}.label`),
          includes: conditionalT(`${tPrefix}.includes`, {}, tc),
          // Map over feature keys (which i18next transforms into an object with the item's index as it's key).
          features: i18n.exists(`${tPrefix}.features`)
            ? Object.keys(
                t(`${tPrefix}.features`, {
                  returnObjects: true
                }) || {}
              ).map((featureKey) => ({
                label: tc(`${tPrefix}.features.${featureKey}.label`),
                description: tc(`${tPrefix}.features.${featureKey}.description`)
              }))
            : []
        }

        return acc
      }, {})
      return config
    }
  }, [permissions])

  return rolesConfigWithLabels
}
