import get from "lodash/get"

// tooltip for replies covers many cases
export const getDiscussionResponsesTooltip = (
  { totalResponses, totalResponders, userLastRespondTime },
  t
) => {
  // current user is responsible for all reply(s)
  if (totalResponses && totalResponders === 1 && !!userLastRespondTime) {
    // 1 reply from you
    // 2 replies from you
    return t(`$t(numResponse, { "count": {{ responseCount }} }) from you`, {
      responseCount: totalResponses,
      personCount: totalResponders,
      // for i18next-scanner translation extraction
      foo: t("numResponse", {
        defaultValue: "{{ count }} reply",
        defaultValue_plural: "{{ count }} replies",
        count: Infinity
      })
    })
  }

  // current user is responsible for some of reply(s)
  if (totalResponses && totalResponders > 1 && !!userLastRespondTime) {
    // 3 replies from you and 1 other
    // 4 replies from you and 2 others
    return t(
      `$t(numResponse, { "count": {{ responseCount }} }) from you and $t(numOther, { "count": {{ otherCount }} })`,
      {
        responseCount: totalResponses,
        otherCount: totalResponders - 1,
        // for i18next-scanner translation extraction
        foo: t("numResponse", {
          defaultValue: "{{ count }} reply",
          defaultValue_plural: "{{ count }} replies",
          count: Infinity
        }),
        foo: t("numOther", {
          defaultValue: "{{ count }} other",
          defaultValue_plural: "{{ count }} others",
          count: Infinity
        })
      }
    )
  }

  // current user is not responsible for any of the reply(s)
  if (totalResponses && !!totalResponders) {
    // 1 reply from 1 person
    // 2 replies from 1 person
    // 3 replies from 2 people
    return t(
      `$t(numResponse, { "count": {{ responseCount }} }) from $t(numPerson, { "count": {{ personCount }} })`,
      {
        responseCount: totalResponses,
        personCount: totalResponders,
        // for i18next-scanner translation extraction
        foo: t("numResponse", {
          defaultValue: "{{ count }} reply",
          defaultValue_plural: "{{ count }} replies",
          count: Infinity
        }),
        foo: t("numPerson", {
          defaultValue: "{{ count }} person",
          defaultValue_plural: "{{ count }} people",
          count: Infinity
        })
      }
    )
  }

  // default, there are no replies
  // replies
  return t("reply", {
    defaultValue_plural: "replies",
    count: Infinity
  })
}

// Simple util for getting expected discussion context off of the discussion.
export const getDiscussionContext = (discussion) => {
  const context = {}
  if (discussion) {
    context.resource_id =
      discussion.resource_id || get(discussion, "resource.id")
    context.cohort_id = discussion.cohort_id || get(discussion, "cohort.id")
    context.step_id = discussion.step_id
  }
  return context
}
