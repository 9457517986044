import gql from "graphql-tag"
import USER_FRAGMENT from "../../user/graphql/user-fragment"
import SCHOOL_SUBSCRIPTION_PLAN from "./school-subscription-plan-fragment"
import SUBSCRIPTION from "./subscription-fragment"
import SUBSCRIPTION_PLAN from "./subscription-plan-fragment"

export default gql`
  fragment SchoolSubscription on SchoolSubscription {
    id
    user {
      ...User
    }
    subscription {
      ...Subscription
    }
    subscription_plan {
      ...SubscriptionPlan
    }
    school_subscription_plan {
      ...SchoolSubscriptionPlan
    }
  }
  ${USER_FRAGMENT}
  ${SUBSCRIPTION}
  ${SUBSCRIPTION_PLAN}
  ${SCHOOL_SUBSCRIPTION_PLAN}
`
