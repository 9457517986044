import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import FeatureBlockUI from "../../feature/block/FeatureBlockUI"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"

const PathwrightSignInFeatureBlock = (props) => {
  const { t } = useTranslate()
  const { school } = usePathwrightContext()

  const featureInfo = {
    icon: "avatar-lock",
    title: "Pathwright Sign-In",
    desc: "Configure the default Pathwright Sign In.",
    tooltip:
      "The Pathwright Sign In can only be disabled if an SSO solution is provided.",
    link: "https://help.pathwright.com/en/articles/8288560-instant-sign-up-sign-in-new"
  }

  const featurePermissionInfo = school.pathwright_login_enabled
    ? {
        icon: "eye",
        title: t("Enabled"),
        titleAbbr: t("Enabled"),
        titleVerbose: t("Enabled")
      }
    : {
        icon: "eye-off",
        title: t("Disabled"),
        titleAbbr: t("Disabled"),
        titleVerbose: t("Disabled")
      }

  return (
    <FeatureBlockUI
      featureInfo={featureInfo}
      featurePermissionInfo={featurePermissionInfo}
      to={`${window.location.pathname}pathwright-signin/`}
      {...props}
    />
  )
}

PathwrightSignInFeatureBlock.displayName = "PathwrightSignInFeatureBlock"

export default PathwrightSignInFeatureBlock
