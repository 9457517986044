// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
// USAGE:

// <Row gutter={15} align="center">
//   <Col xs={12} sm={6} md={4} lg={3} xl={2}>
//     content...
//   </Col>
//   <Col xs={12} sm={6} md={4} lg={3} xl={2}>
//     content...
//   </Col>
//   <Col xs={12} sm={6} md={4} lg={3} xl={2}>
//     content...
//   </Col>
// </Row>

export const Col = require("./Col").default
export const Row = require("./Row").default
