import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import Tooltip from "@pathwright/ui/src/components/tooltip/Tooltip"
import styled from "styled-components"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import { constructUrl } from "../../utils/urls"
import ReportPathStep from "./ReportPathStep"
import UserReportCard from "./UserReportCard"

const Container = styled.div`
  .Tooltip__trigger {
    width: 100%;
  }
`

const ReportPathResource = (props) => {
  const { t } = useTranslate()
  const { me } = usePathwrightContext()
  const { pathItem } = props

  let tooltip = null
  if (!pathItem.linked_registration) {
    if (
      !pathItem.completion.user ||
      (me && me.id === pathItem.completion.user.id)
    ) {
      tooltip = t("You have not started yet")
    } else {
      tooltip = t("{{ userDisplayName }} has not started yet", {
        userDisplayName: pathItem.completion.user.display_name
      })
    }
  }

  return (
    <Container>
      <Tooltip title={tooltip}>
        <ReportPathStep
          {...props}
          to={
            pathItem.linked_registration?.path
              ? {
                  pathname: constructUrl(
                    window.location.pathname,
                    pathItem.linked_registration.path.id
                  ),
                  card: {
                    component: UserReportCard,
                    props: {
                      userId: pathItem.linked_registration.user.id,
                      pathId: pathItem.linked_registration.path.id
                    }
                  }
                }
              : pathItem.linked_cohort
              ? // currently we don't support completion review for resource steps (for example, backend prevenst discussing a resource step)
                // these limitations may be easily overcome, but we have not looked into this yet
                null
              : null
          }
        />
      </Tooltip>
    </Container>
  )
}

ReportPathResource.displayName = "ReportPathResource"

export default ReportPathResource
