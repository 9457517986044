import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import styled from "styled-components"
import { getSchoolMembershipRoleLabel } from "../membership/constants"
import ProfileAvatarCardTrigger from "../profile/ProfileAvatarCardTrigger"

const StyledContainer = styled.div`
  position: relative;
  align-self: center;
  padding: 10px;
`

const StyledList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`

const StyledRow = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
`

const StyledItem = styled.li`
  padding: 2px;

  .AvatarCardTrigger > .Avatar {
    transition: transform 100ms ease-out;
  }

  &:hover .AvatarCardTrigger > .Avatar {
    transform: scale(1.5);
  }
`

const CommunityPreview = ({ members, onLaunch }) => {
  const { tca } = useTranslate()
  const rows = [
    members.slice(0, 7),
    members.slice(7, 15),
    members.slice(15, 24),
    members.slice(24, 32)
  ]
  return (
    <StyledContainer>
      <StyledList>
        {rows.map((row, index) => (
          <StyledRow
            key={index}
            style={{ zIndex: Math.abs(index - rows.length) }}
          >
            {row.map((user) => (
              <StyledItem key={user.id} onClick={(e) => e.stopPropagation()}>
                <ProfileAvatarCardTrigger
                  size="20px"
                  user={user}
                  meta={getSchoolMembershipRoleLabel(user.membership.role, tca)}
                />
              </StyledItem>
            ))}
          </StyledRow>
        ))}
      </StyledList>
    </StyledContainer>
  )
}

CommunityPreview.displayName = "CommunityPreview"

export default CommunityPreview
