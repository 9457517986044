import classnames from "classnames"
import isFunction from "lodash/isFunction"
import PropTypes from "prop-types"
import { Component } from "react"
import styled, { css } from "styled-components"
import LoadingCircle from "../../loading/LoadingCircle"
import Pathicon from "../../pathicon/Pathicon"
import Tooltip from "../../tooltip/Tooltip"
import Text from "../../ui/Text"
import {
  ALERT_YELLOW,
  BORDER_GRAY,
  BORDER_WHITE,
  FOCUS_BLUE,
  PRIMARY_GRAY,
  PRIMARY_WHITE,
  SECONDARY_GRAY,
  SECONDARY_WHITE,
  TERTIARY_GRAY,
  TERTIARY_WHITE
} from "../../utils/colors"
import { media } from "../../utils/styles"

// - Handles styling
//   - can be configured for nested or inline display

// - Handles focus
//   - focus state handled internally
//   - provides update callbacks to connect child

// - Handles status
//   - shows error messages, success updates
//   - can display a custom status (e.g. chars remaining)

const getBorderColor = (props) => {
  if (props.readOnly) {
    return "transparent"
  }
  if (props.inverted) {
    if (props.focus) {
      return "transparent"
    }
    return BORDER_WHITE
  }
  if (props.focus) {
    return FOCUS_BLUE
  }
  return BORDER_GRAY
}

const getBackgroundColor = (props) => {
  if (props.inverted) {
    if (props.error) {
      return BORDER_GRAY
    }
    if (props.focus) {
      return BORDER_WHITE
    }
  } else if (props.error) {
    return ALERT_YELLOW
  }
  return "transparent"
}

const getTextColor = (props) => {
  if (props.inverted) {
    return PRIMARY_WHITE
  }
  return PRIMARY_GRAY
}

const StyledFieldContainer = styled.div`
  position: relative;
  font-size: 16px;
  text-align: left;
  padding: 5px 0;
  padding-top: ${(props) => (props.topLabel ? 30 : 5)}px;
`

const StyledFieldLabelContainer = styled.div`
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  align-items: center;
  width: 85px;
  margin: 0;
  padding-right: 10px;
  text-align: ${(props) => (props.flushLabel ? "right" : "left")};
  color: ${(props) =>
    props.inverted
      ? SECONDARY_WHITE
      : props.focus
      ? FOCUS_BLUE
      : SECONDARY_GRAY};
  ${(props) =>
    props.topLabel
      ? `
    position: absolute;
    top: 5px;
    `
      : ""};
  ${media.max.phone`
		margin-bottom: 5px;
	`}
`

const StyledFieldWrapper = styled.div`
  margin: 2px 0;
  display: flex;
  align-items: center;
  ${(props) => (props.collapse ? media.max.phone`flex-direction: column;` : "")}
`

// const StyledInputBase = css`
//   position: relative;
//   flex-grow: ${props => (props.stretch ? 1 : 0)};
//   ${props => props.alignCenter && css`
//     display: flex;
//     justify-content: center;
//   `}
//   ${props => props.alignRight && css`
//     display: flex;
//     justify-content: flex-end;
//   `}
//   input,
//   textarea {
//     display: block;
//     font-size: 16px;
//     line-height: 25px;
//     border: none;
//     outline: none;
//     color: #222;
//     background-color: transparent;
//     &::placeholder {
//       color: ${props => (props.inverted ? BORDER_WHITE : BORDER_GRAY)};
//     }
//     &[type=number]::-webkit-inner-spin-button,
//     &[type=number]::-webkit-outer-spin-button {
//       -webkit-appearance: none;
//       margin: 0;
//     }
//   }
// `

const StyledFieldInput = styled.div`
  position: relative;
  flex-grow: ${(props) => (props.stretch ? 1 : 0)};
  ${(props) =>
    props.alignCenter &&
    css`
      display: flex;
      justify-content: center;
    `} ${(props) =>
    props.alignRight &&
    css`
      display: flex;
      justify-content: flex-end;
    `}


  input:not([type="checkbox"]):not([role="combobox"]):not([readonly]),
  textarea,
  select,
  .quill {
    display: block;
    font-size: 1em;
    min-height: 2em;
    line-height: 1.5em;
    color: #222;
    background-color: transparent;
    color: ${(props) => getTextColor(props)};
    background-color: ${(props) => getBackgroundColor(props)};
    &::placeholder {
      color: ${(props) => (props.inverted ? TERTIARY_WHITE : BORDER_GRAY)};
    }
    &[type="number"]::-webkit-inner-spin-button,
    &[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .ql-container.ql-bubble .ql-tooltip-editor > input {
    color: #ccc;
    &::placeholder {
      color: #aaa;
    }
  }

  input,
  textarea,
  select {
    border: none;
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    border-color: ${(props) => getBorderColor(props)};
  }

  input:not([type="checkbox"]):not([role="combobox"]):not([readonly]),
  textarea,
  select,
  .ql-editor {
    padding: 5px;
    outline: none;
  }

  .ql-toolbar.ql-snow {
    margin-top: 0.4em;
  }

  /* extra importance to ql-editor padding */
  .ql-editor {
    padding: 5px !important;
  }

  /* extra importance to ql-editor placeholder positioning */
  .ql-editor.ql-blank::before {
    left: 5px !important;
  }
`

export const StyledFieldMinimal = styled.div`
  position: relative;
  flex-grow: ${(props) => (props.stretch ? 1 : 0)};
  ${(props) =>
    props.alignCenter &&
    css`
      display: flex;
      justify-content: center;
    `}
  ${(props) =>
    props.alignRight &&
    css`
      display: flex;
      justify-content: flex-end;
    `}

  
  input:not([type="checkbox"]):not([role="combobox"]):not([readonly]),
  textarea,
  select,
  .quill {
    display: block;
    font-size: 1em;
    min-height: 2em;
    line-height: 1.5em;
    outline: none;
    color: #222;
    background-color: transparent;
    &.ql-blank,
    &::placeholder {
      color: ${(props) => (props.inverted ? BORDER_WHITE : BORDER_GRAY)};
    }
    &[type="number"]::-webkit-inner-spin-button,
    &[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    color: ${(props) => getTextColor(props)};
  }
  .ql-container.ql-bubble .ql-tooltip-editor > input {
    color: #ccc;
    &::placeholder {
      color: #aaa;
    }
  }
  input,
  textarea,
  select {
    border: none;
    border-bottom-width: 1px;
    border-bottom-style: ${(props) =>
      props.error || props.focus ? "solid" : "dotted"};
    border-bottom-color: ${(props) => getBorderColor(props)};
  }
  textarea::-webkit-input-placeholder {
    color: #aaa;
  }
  textarea:-ms-input-placeholder {
    color: #aaa;
  }
  textarea::-moz-placeholder {
    color: #aaa;
  }
  textarea:-moz-placeholder {
    color: #aaa;
  }
  input::-webkit-input-placeholder {
    color: #aaa;
  }
  input:-ms-input-placeholder {
    color: #aaa;
  }
  input::-moz-placeholder {
    color: #aaa;
  }
  input:-moz-placeholder {
    color: #aaa;
  }
`

const StyledFieldCustom = styled.div`
  position: relative;
  color: ${SECONDARY_GRAY};
  white-space: pre;
`

const StyledFieldStatus = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${(props) => (props.inverted ? TERTIARY_WHITE : TERTIARY_GRAY)};
`

const StyledFieldTooltip = styled(Tooltip)`
  display: inline-block;
  padding-left: 3px;
  line-height: 0;
  .Pathicon {
    font-size: 16px;
    line-height: 10px;
  }
  .Tooltip {
    z-index: 100;
    min-width: 200px;
    max-width: 250px;
    white-space: normal;
    span {
      // Override form label style
      font-weight: normal;
    }
  }
`

const StyledFieldInstructions = styled(Text.Body)`
  margin: 5px 0;
  color: ${(props) => (props.inverted ? TERTIARY_WHITE : TERTIARY_GRAY)};
  a {
    text-decoration: underline;
  }
`

const StyledFieldHelperText = styled(Text.Meta)`
  a {
    text-decoration: underline;
  }
`

const StyledFieldCounter = styled(Text.Meta)`
  margin-left: auto;
`

const StyledFieldStatusIcon = styled(Pathicon)`
  color: #ffdc00;
  position: absolute;
  top: 50%;
  right: 7px;
  transform: translateY(-50%);
`

const FieldLabel = ({
  flushLabel,
  topLabel,
  inverted,
  id,
  labelWidth,
  label,
  tooltip
}) => (
  <StyledFieldLabelContainer
    className="UIFieldWrapper__label-wrapper"
    flushLabel={flushLabel}
    topLabel={topLabel}
    inverted={inverted}
    style={{ width: topLabel ? "auto" : labelWidth }}
  >
    <label className="UIFieldWrapper__label" htmlFor={id}>
      {label}
    </label>
    {tooltip && (
      <StyledFieldTooltip
        transition="transition.fadeIn"
        placement="right"
        alignment="center"
        title={tooltip}
      >
        <Pathicon icon="info-circle" />
      </StyledFieldTooltip>
    )}
  </StyledFieldLabelContainer>
)

const FieldStatus = ({
  inverted,
  validating,
  hasError,
  label,
  topLabel,
  labelWidth,
  errorMessage,
  helperText,
  counter
}) => (
  <StyledFieldStatus inverted={inverted} className="styled-field">
    {validating ? (
      <LoadingCircle inverted={inverted} center={false} size={10} />
    ) : hasError ? (
      <Text.Meta
        inverted={inverted}
        style={{
          marginLeft: label && !topLabel ? labelWidth : 0
        }}
      >
        {errorMessage}
      </Text.Meta>
    ) : helperText ? (
      <StyledFieldHelperText inverted={inverted}>
        {helperText}
      </StyledFieldHelperText>
    ) : null}
    {counter && <StyledFieldCounter>{counter}</StyledFieldCounter>}
  </StyledFieldStatus>
)

class FieldStyler extends Component {
  constructor(props) {
    super(props)
    this.state = {
      focus: props.autoFocus
    }
  }

  componentDidMount() {
    setTimeout(this.handleAutofill, 10)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.pristine && nextProps.pristine !== this.props.pristine) {
      this.setState({
        focus: this.props.autoFocus
      })
    }

    if (nextProps.autoFocus !== this.props.autoFocus) {
      this.setState({
        focus: nextProps.autoFocus
      })
    }
  }

  handleAutofill = () => {
    const value = this.inputRef && this.inputRef.value
    if (value && !this.props.value) {
      this.props.onChange(value)
    }
  }

  handleFocus = (e) => {
    if (isFunction(this.props.onFocus)) {
      this.props.onFocus(this.props.value, e)
    }
    if (!this.state.focus) {
      this.setState({ focus: true })
    }
  }

  handleBlur = (e) => {
    if (isFunction(this.props.onBlur)) {
      this.props.onBlur(this.props.value, e)
    }
    if (this.state.focus) {
      this.setState({ focus: false })
    }
  }

  registerInput = (node) => {
    this.inputRef = node
    if (this.props.innerRef) {
      if (typeof this.props.innerRef === "function") {
        this.props.innerRef(node)
      } else {
        this.props.innerRef.current = node
      }
    }
  }

  render() {
    const {
      label,
      focus: focusProp,
      autoFocus,
      required,
      children,
      errors,
      status,
      counter,
      validated,
      validating,
      stretch,
      hideStatus,
      hideCaution,
      tooltip,
      labelWidth,
      collapse,
      topLabel,
      flushLabel,
      readOnly,
      inverted,
      alignCenter,
      alignRight,
      layoutAsColumns,
      pristine,
      inline,
      minimal,
      instructions,
      helperText,
      custom,
      value,
      innerRef,
      onChange,
      onFocus,
      onBlur,
      style,
      className,
      id,
      ...rest
    } = this.props

    const { focus } = this.state
    const errorsList = [].concat(errors).filter(Boolean)
    const hasError = errorsList.length > 0
    const errorMessage = hasError && errorsList[0]
    const passProps = {
      ...rest,
      id,
      value,
      focus,
      autoFocus,
      // naming inconsistency in child components
      autofocus: autoFocus,
      onChange,
      onBlur: this.handleBlur,
      onFocus: this.handleFocus,
      registerInput: this.registerInput
    }

    const StyledInput = minimal ? StyledFieldMinimal : StyledFieldInput
    return (
      <StyledFieldContainer
        className={classnames(
          "UIFieldWrapper",
          className,
          custom ? "has-custom" : ""
        )}
        topLabel={topLabel}
        inline={inline}
        style={style}
      >
        {instructions && (
          <StyledFieldInstructions inverted={inverted}>
            {instructions}
          </StyledFieldInstructions>
        )}
        <StyledFieldWrapper collapse={collapse}>
          {label && !layoutAsColumns && <FieldLabel {...this.props} />}
          {label && layoutAsColumns && (
            <div>
              <FieldLabel {...this.props} />
              <FieldStatus
                {...this.props}
                hasError={hasError}
                errorMessage={errorMessage}
              />
            </div>
          )}
          <StyledInput
            className="UIFieldWrapper__input"
            alignCenter={alignCenter}
            alignRight={alignRight}
            inverted={inverted}
            readOnly={readOnly}
            stretch={stretch}
            error={hasError}
            focus={focus}
          >
            {isFunction(children) ? children(passProps) : children}
            {!validating && hasError && !hideCaution && (
              <StyledFieldStatusIcon icon="caution-triangle" />
            )}
          </StyledInput>
          {custom && (!hasError || layoutAsColumns) && (
            <StyledFieldCustom>{custom}</StyledFieldCustom>
          )}
        </StyledFieldWrapper>
        {!hideStatus && !layoutAsColumns && (
          <FieldStatus
            {...this.props}
            hasError={hasError}
            errorMessage={errorMessage}
          />
        )}
      </StyledFieldContainer>
    )
  }
}

FieldStyler.displayName = "FieldStyler"

FieldStyler.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  focus: PropTypes.bool,
  autoFocus: PropTypes.bool,
  required: PropTypes.bool,
  children: PropTypes.any,
  errors: PropTypes.array,
  status: PropTypes.any,
  counter: PropTypes.any,
  validated: PropTypes.bool,
  validating: PropTypes.bool,
  // stretch field fill space
  stretch: PropTypes.bool,
  // force hide any status
  hideStatus: PropTypes.bool,
  hideCaution: PropTypes.bool,
  tooltip: Tooltip.propTypes.title,
  // width of left aligned label.
  // doesn't apply on mobile or topLabel.
  labelWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  // whether the label, field, and field status should display in column on mobile
  collapse: PropTypes.bool,
  topLabel: PropTypes.bool,
  flushLabel: PropTypes.bool,
  readOnly: PropTypes.bool,
  inverted: PropTypes.bool,
  alignCenter: PropTypes.bool,
  alignRight: PropTypes.bool,
  // For inline fields; label/status text are together, input/custom together
  layoutAsColumns: PropTypes.bool,
  pristine: PropTypes.bool,
  inline: PropTypes.bool,
  minimal: PropTypes.bool,
  placeholder: PropTypes.any,
  instructions: PropTypes.any,
  helperText: PropTypes.any,
  custom: PropTypes.any,
  value: PropTypes.any,
  innerRef: PropTypes.any,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  style: PropTypes.object
}

FieldStyler.defaultProps = {
  labelWidth: 100,
  stretch: true,
  layoutAsColumns: false
}

export default FieldStyler
