// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
let StateBehavior
export default StateBehavior = (function () {
  StateBehavior = class StateBehavior extends (
    require("lib/static-shim").default(Marionette.Behavior)
  ) {
    static initClass() {
      ;`\
Allows a view to perisist a state model\
`

      this.prototype.defaults = {
        StateClass: require("lib/state/state").default,
        viewProperty: null,
        autoSave: true,
        getStateKey: null
      }
    }

    onBeforeRender() {
      if (!this.state) {
        return this.loadState()
      }
    }

    loadState() {
      let options
      if (!_.isFunction(this.options.getStateKey)) {
        throw new Error(
          "Function getStateKey must be defined to persist/load view state"
        )
      }
      const stateKey = _.bind(this.options.getStateKey, this.view)()
      this.state = new this.options.StateClass(
        { id: stateKey },
        (options = { autoSave: this.options.autoSave })
      )
      this.state.fetch()
      if (this.options.viewProperty) {
        this.view[this.options.viewProperty] = this.state
      }
      return this.view.triggerMethod("state:loaded", this.state)
    }

    onDestroy() {
      return delete this.state
    }
  }
  StateBehavior.initClass()
  return StateBehavior
})()
