import gql from "graphql-tag"

export default gql`
  fragment SubscriptionPlan on SubscriptionPlan {
    id
    name
    amount
    interval
    trial_period_days
    trial_type
    plan_type
  }
`
