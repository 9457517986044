import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import Card from "@pathwright/ui/src/components/card/Card"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import FeatureLock from "@pathwright/web/src/modules/lib/FeatureLock"
import { useHasEnterpriseSSO } from "./EnterpriseSSOFeatureBlock"

const featureKey = "single-sign-on-sso"

const ManagePathwrightSignInFeature = ({ card }) => {
  const hasEnterpriseSSO = useHasEnterpriseSSO() && false

  return (
    <Card card={card} emphasis="secondary">
      <FeatureLock has={featureKey} loadingElement={<LoadingCircle />}>
        {({
          hasFeatures,
          activationLocked,
          lockedFeatures,
          getPlansNameList
        }) =>
          hasFeatures ? (
            hasEnterpriseSSO ? (
              <BlankSlate
                icon="group-lock"
                heading="SSO is enabled"
                body="Your Pathwright account includes the option for members to use their organization credentials to sign-in or sign-up."
                primaryAction={{
                  children: "Update SSO",
                  href: `mailto:hello@pathwright.com?subject=Update Single Sign In for our Pathwright account&body=Please include your organization name, Pathwright URL, and the contact information for the technical person on your team who will be configuring SSO`
                }}
                meta="Contact us to update or remove SSO"
              />
            ) : (
              <BlankSlate
                icon="group-lock"
                heading="Streamline Access with Single Sign-On (SSO)"
                body="Give your members hassle-free, secure access by enabling them to use their existing organization credentials to sign-up and sign-in to Pathwright."
                primaryAction={{
                  children: "Setup SSO",
                  href: `mailto:hello@pathwright.com?subject=Setup Single Sign In for our Pathwright account&body=Please include your organization name, Pathwright URL, and the contact information for the technical person on your team who will be configuring SSO`
                }}
                meta="Contact us to setup SSO"
              />
            )
          ) : (
            <BlankSlate
              icon="group-lock"
              heading="Streamline Access with Single Sign-On (SSO)"
              body="Give your members hassle-free, secure access by enabling them to use their existing organization credentials to sign-up and sign-in to Pathwright."
              primaryAction={{
                children: "Learn more",
                href: "https://help.pathwright.com/en/articles/1296482-integrate-sso-single-sign-on-with-your-pathwright-account"
              }}
              meta={`Included in the ${getPlansNameList(
                lockedFeatures[featureKey]
              )}`}
            />
          )
        }
      </FeatureLock>
    </Card>
  )
}

ManagePathwrightSignInFeature.displayName = "ManagePathwrightSignInFeature"

export default ManagePathwrightSignInFeature
