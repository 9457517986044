import React, { Suspense } from "react"
import LoadingBlock from "../../loading/LoadingBlock"

const CodeEditor = React.lazy(() =>
  import("./CodeEditor" /* webpackChunkName: "CodeEditor" */)
)

const LazyCodeEditor = (props) => {
  return (
    <Suspense fallback={<LoadingBlock />}>
      <CodeEditor {...props} />
    </Suspense>
  )
}

export default LazyCodeEditor
