import { withTranslate } from "@pathwright/ui/src/components/"
import Button from "@pathwright/ui/src/components/button/Button"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import SchoolIdentity from "@pathwright/web/src/modules/lib/SchoolIdentity"
import { withUserCommunity } from "@pathwright/web/src/modules/user/UserCommunityContext"
import classnames from "classnames"
import { ConnectStores } from "lib/core/store"
import NavbarUser from "./NavbarUser"
import "./styles/AppNavbar.css"

const AppNavbar = function (props) {
  const {
    user,
    media,
    school,
    minimal,
    visibility,
    isAuthenticated,
    showActivateIndicator,
    resource_launched_from,
    user_is_school_admin,
    showSubscribe,
    showDesign,
    showTeach,
    openPanel,
    signOut,
    signIn,
    goBack,
    homeRoute,
    inverted,
    t
  } = props

  const className = classnames("AppNavbar", {
    "AppNavbar--minimal": minimal,
    "AppNavbar--inverted": inverted
  })

  if (!visibility.bar) {
    return null
  }

  const buttonStyle = {
    styleType: "tertiary",
    inverted,
    rounded: false
  }

  return (
    <nav className={className}>
      <div className="AppNavbar__nav">
        {visibility.appMenu ? (
          <Button
            {...buttonStyle}
            className="AppNavbar__panel"
            onClick={() => openPanel()}
            icon="list"
            label={t("nav.navbar.menu")}
            countIndicatorProps={
              user_is_school_admin &&
              school.is_activated === false &&
              school.trial_days_remaining
                ? {
                    count: school.trial_days_remaining,
                    inverted: true
                  }
                : null
            }
          />
        ) : null}
        {resource_launched_from ? (
          <Button
            {...buttonStyle}
            className="AppNavbar__back"
            to={resource_launched_from}
          >
            <Pathicon icon="chevron-left" />
            <span>{t("nav.navbar.back")}</span>
          </Button>
        ) : null}
      </div>
      {!!visibility.brand && <SchoolIdentity to={homeRoute} width="100%" />}
      {!visibility.proxyView ? (
        <div className="AppNavbar__user">
          {visibility.authMenu ? (
            <NavbarUser
              school={school}
              buttonStyle={buttonStyle}
              isAuthenticated={isAuthenticated}
              showDesign={showDesign}
              showTeach={showTeach}
              onSignOut={signOut}
              onSignIn={signIn}
              user={user}
            />
          ) : // else if visibility.accountMenu
          //   <Button  {...buttonStyle}>
          //     Adjust account settings...
          //   </Button>
          isAuthenticated ? (
            <Button {...buttonStyle} to="/auth/sign-out/">
              {t("auth.sign_out")}
            </Button>
          ) : null}
        </div>
      ) : undefined}
    </nav>
  )
}

AppNavbar.displayName = "AppNavbar"

AppNavbar.defaultProps = { inverted: true }

const mapStateToProps = function (state, ownProps) {
  const { user, inverted } = ownProps
  const { signIn, signOut } = state.authStore.action
  const { toggleNavPanelOpen, goBack } = state.navigationStore.action
  const { school, permissions, integrations, media } = state.schoolStore.state
  const {
    user_is_school_admin,
    user_is_school_staff,
    user_is_teaching,
    user_is_editing
  } = permissions
  const { resource_launched_from, location, is_showing_modal } =
    state.navigationStore.state

  const isAuthenticated = (user != null ? user.id : undefined) != null
  const showTeach = user_is_school_staff || user_is_teaching
  const showDesign =
    (user_is_school_admin || user_is_editing) && school.is_curriculum_enabled
  const homeRoute = state.navigationStore.request.getHomeLocation()

  const showActivateIndicator = !school.is_activated

  const openPanel = () => {
    toggleNavPanelOpen(true)
  }

  let minimal = false
  let visibility = {
    bar: true,
    appMenu: true,
    authMenu: true,
    brand: false,
    proxyView: false
  }

  const routesCheck = (routes) => {
    if (location) {
      return routes.find((r) => {
        if (typeof r === "string") {
          return location.pathname.indexOf(r) > -1
        } else {
          return r.test(location.pathname)
        }
      })
    }
  }

  if (routesCheck(["/dashboard/"])) {
    minimal = true
  }

  if (routesCheck(["/sign-up/", "/pricing/plan/", "/account/sign-in/"])) {
    visibility = {
      ...visibility,
      bar: false,
      appMenu: false,
      authMenu: false
    }
  }

  if (routesCheck(["/inactive/"])) {
    visibility = {
      ...visibility,
      appMenu: false,
      authMenu: false
    }
  }

  if (routesCheck(["/auth/"]) && !is_showing_modal) {
    visibility = {
      ...visibility,
      brand: false,
      authMenu: false
    }
    minimal = true
  }

  if (routesCheck(["/activate/"]) || school.trial_has_expired === true) {
    visibility.appMenu = false
  }

  if (
    // Note: we still show "brand" on Dashboard, but the Dashboard component handles
    // the branding itself.
    routesCheck([
      /^\/library\/?$/,
      /^\/library\/[a-zA-Z0-9_-]*-\d+(\/(\d+|_))?\/about/
    ])
  ) {
    visibility.brand = true
  }

  // TODO: FIXE ME!
  // if (message) {
  //   // TODO: should be more specific
  //   visibility.proxyView = true
  // }
  if (minimal && isAuthenticated) {
    visibility.brand = false
  }
  if (user_is_school_admin) {
    visibility.homeMenu = true
  }

  return _.assign(
    {},
    {
      user,
      school,
      minimal,
      inverted,
      visibility,
      media,
      signIn,
      signOut,
      goBack,
      openPanel,
      homeRoute,
      showTeach,
      showDesign,
      showActivateIndicator,
      isAuthenticated,
      resource_launched_from,
      user_is_school_admin
    },
    ownProps
  )
}

const connectedStores = [
  { store: "navigation", watch: ["resource_launched_from", "location"] },
  { store: "school", watch: ["permissions"] },
  { store: "auth", watch: false }
]

export default withUserCommunity(
  withTranslate(ConnectStores(AppNavbar, connectedStores, {}, mapStateToProps))
)
