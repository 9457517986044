import Button from "@pathwright/ui/src/components/button/Button"
import DropdownItem from "@pathwright/ui/src/components/dropdown/DropdownItem"
import DropdownMenu from "@pathwright/ui/src/components/dropdown/DropdownMenu"
import DropdownTrigger from "@pathwright/ui/src/components/dropdown/DropdownTrigger"
import SearchInput from "@pathwright/ui/src/components/form/form-text-input/SearchInput"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import Screensize from "@pathwright/ui/src/components/ui/Screensize"
import capitalize from "lodash/capitalize"
import { Component } from "react"
import styled from "styled-components"
import { MEMBERSHIP_FILTERS } from "./constants"

const StyledContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
`

class MembershipSearch extends Component {
  state = {
    value: ""
  }

  handleChange = (value) => {
    this.setState({ value }, () => {
      this.props.onSearch(value)
    })
  }

  handleClear = () => {
    this.handleChange("")
  }

  handleFilter = (membershipFilter) => () => {
    this.props.onFilter(membershipFilter)
  }

  render() {
    return (
      <Screensize.Consumer>
        {(screensize) => (
          <StyledContainer>
            <div style={{ flexGrow: 1 }}>
              <SearchInput
                emphasis="secondary"
                placeholder="Search..."
                onChange={this.handleChange}
                onClear={this.handleClear}
                value={this.state.value}
                autoFocus={screensize !== "sm"}
                searching={this.props.searching}
              />
            </div>
            {screensize !== "sm" && (
              <DropdownTrigger
                style={{ marginLeft: 10 }}
                alignment="left"
                renderDropdown={() => (
                  <DropdownMenu closeOnClick>
                    {Object.keys(MEMBERSHIP_FILTERS).map((label, index) => (
                      <DropdownItem
                        key={index}
                        label={capitalize(label)}
                        onClick={this.handleFilter(label)}
                      />
                    ))}
                  </DropdownMenu>
                )}
              >
                <Button styleType="secondary">
                  Show{" "}
                  <strong>{capitalize(this.props.membershipFilter)}</strong>
                  <span> </span>
                  <Pathicon icon="chevron-down" />
                </Button>
              </DropdownTrigger>
            )}
            <Button
              style={{ marginLeft: 7 }}
              styleType="secondary"
              to="/community/members/invite/"
            >
              {screensize === "sm" ? "Invite" : "Invite members"}
            </Button>
          </StyledContainer>
        )}
      </Screensize.Consumer>
    )
  }
}

MembershipSearch.displayName = "MembershipSearch"

export default MembershipSearch
