import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import usePreviousEffect from "@pathwright/ui/src/components/hooks/usePreviousEffect"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import { useEffect, useState } from "react"
import styled from "styled-components"
import { DASHBOARD_MOBILE_WIDTH } from "./constants"
import useSectionData from "./useSectionData"
import withSectionQueries from "./withSectionQueries"

const StyledSectionContainer = styled.div`
  padding: 10px;

  @media (max-width: ${DASHBOARD_MOBILE_WIDTH}px) {
    overflow: auto;
    height: auto;
    overflow: visible;
  }
`

const StyledSection = styled.div`
  width: 100%;
  height: 100%;
  section {
    position: relative;
  }
  @media (max-width: ${DASHBOARD_MOBILE_WIDTH}px) {
    section {
      text-align: center;
    }
    .Section__ListItemContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      > div {
        width: 100%;
      }
    }
  }

  /* max-width: 800px; */
`

const ShowMoreButton = styled.button`
  border: 1px solid rgba(255, 255, 255, 0.333);
  border-radius: 20px;
  text-align: center;
  width: 100%;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.222);
  margin: 10px 0px;
  grid-column: 1/-1;
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.333);
  }
`

// Watches for when a new item is added to the list and scrolls to that item.
// NOTE: the use of this hook does assume that the target item is wrapped with React.forwardRef.
const useScrollToNewItem = (items, searchTerm) => {
  const [scrollToItemId, setScrollToItemId] = useState()
  const [scrollToItemNode, setScrollToItemNode] = useState()

  usePreviousEffect(
    ([prevItems]) => {
      // Find if a new item as been added to list.
      if (
        Math.abs(prevItems.length - items.length) === 1 &&
        // Prevent always scrolling to first item in list after items are loaded
        // when there is only a total of 1 items. This does mean copying a resource
        // where there are 0 items will not trigger the scroll event, but that trade-off
        // is OK.
        items.length !== 1 &&
        // The presence of a searchTerm indicates that the items list has been filtered
        // and may not accurately reflect the addition of an item when the items length changes.
        // We'll simply not handle the scroll event when the items list is filtered.
        !searchTerm
      ) {
        const newItem = items.find(
          (item) => !prevItems.find((prevItem) => item.id === prevItem.id)
        )
        if (newItem) setScrollToItemId(newItem.id)
      }
    },
    [items]
  )

  useEffect(() => {
    if (scrollToItemNode) {
      scrollToItemNode.scrollIntoView({ behavior: "smooth", block: "center" })
      setScrollToItemId(null)
      setScrollToItemNode(null)
    }
  }, [scrollToItemNode])

  // Method passed to ref prop to set the scroll to node.
  const getRefFn = (item) =>
    item.id === scrollToItemId ? setScrollToItemNode : null

  return getRefFn
}

const SectionBlankSlate = ({ section }) => {
  const tPrefix = `${section.section}.blank_slate`
  const { t } = useTranslate()
  return (
    <BlankSlate
      heading={t(`${tPrefix}.heading`, section.blankSlate.heading)}
      body={t(`${tPrefix}.body`, section.blankSlate.body)}
      primaryAction={section.blankSlate.primaryAction}
      inverted
    />
  )
}

const SectionItemList = ({ title, items, section, pageSize, searchTerm }) => {
  const { t } = useTranslate()
  const [page, setPage] = useState(1)
  const { ListContainer, ListItem } = section
  const visibleItems = items.slice(0, page * pageSize)
  const hasMore = items.length / (pageSize * page) > 1

  const getSrollToItemRefFn = useScrollToNewItem(items, searchTerm)

  useEffect(() => {
    if (searchTerm) {
      setPage(1)
    }
  }, [searchTerm])

  const handleShowMore = () => {
    setPage((p) => p + 1)
  }

  if (!visibleItems.length) return <SectionBlankSlate section={section} />
  return (
    <>
      <h1>{title}</h1>
      <ListContainer className="Section__ListItemContainer">
        {visibleItems.map((item, index) => {
          const props = { item, index, itemCount: items.length }
          return (
            <ListItem
              key={item.id}
              ref={getSrollToItemRefFn(item)}
              {...props}
            />
          )
        })}
        {hasMore ? (
          <ShowMoreButton onClick={handleShowMore}>
            {t("Show more")}
          </ShowMoreButton>
        ) : null}
      </ListContainer>
    </>
  )
}

SectionItemList.defaultProps = {
  pageSize: 15
}

const Section = ({ section, loading, data, searchTerm }) => {
  return (
    <StyledSection>
      {!loading ? (
        data.map((sectionData, i) => {
          // only show the last section if there are items in it
          const showSection = sectionData.data.length > 0 || i === 0
          return showSection ? (
            <section
              key={`section-${i}`}
              style={{ zIndex: data.length - i, marginBottom: 40 }}
            >
              <SectionItemList
                searchTerm={searchTerm}
                title={sectionData.title}
                items={sectionData.data}
                section={section}
              />
            </section>
          ) : null
        })
      ) : (
        <LoadingCircle inverted />
      )}
    </StyledSection>
  )
}

const SectionContainer = withSectionQueries(
  ({ section, searchTerm, sectionQuery }) => {
    const { data } = sectionQuery
    const { sections, search } = useSectionData(data, section)

    // HACK ALERT: need to delay loading state by one tick to allow for
    // useSectionData to populate the sections data before removing the
    // the loading spinner.
    const [loading, setLoading] = useState(sectionQuery.loading)
    useEffect(() => {
      if (sectionQuery.loading !== loading) setLoading(sectionQuery.loading)
    }, [sectionQuery.loading])

    useEffect(() => {
      search(searchTerm)
    }, [searchTerm])

    return (
      <StyledSectionContainer>
        {loading ? (
          <LoadingCircle />
        ) : (
          <Section
            section={section}
            searchTerm={searchTerm}
            data={sections}
            loading={loading}
          />
        )}
      </StyledSectionContainer>
    )
  }
)

SectionContainer.displayName = "SectionContainer"

export default SectionContainer
