import gql from "graphql-tag"
import { useMemo } from "react"
import userFragment from "../../user/graphql/user-fragment"
import { usePaginator } from "../../utils/apollo"
import useAuthAwareQuery from "../../utils/useAuthAwareQuery"

export const INBOX_QUERY = gql`
  query InboxQuery($search: String, $cursor: String) {
    inboxGroupConnections(first: 10, after: $cursor, search: $search) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          mentor_group {
            id
            name
            memberships(first: 3, order_by: "-role") {
              edges {
                node {
                  id
                  role
                  user {
                    ...User
                  }
                }
              }
            }
          }
          cohort {
            id
            name
            resource {
              id
              name
            }
            registrations(first: 3, orderBy: "-role") {
              edges {
                node {
                  id
                  role
                  user {
                    ...User
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${userFragment}
`

const parseGroupNodes = (nodes) => {
  return nodes.map((n) => {
    const node = {
      type: n.mentor_group ? "group" : "path",
      // meta: { count: n.pending_review_count },
      meta: {},
      users: {
        staff: [],
        members: []
      },
      data: { ...(n.mentor_group || n.cohort) }
    }
    if (n.cohort) {
      node.id = n.cohort.id
      node.meta.title = n.cohort.resource.name
      node.meta.subtitle = n.cohort.name
      node.users = {
        staff: n.cohort.registrations.edges
          .filter((edge) => edge.node.role > 5)
          .map((edge) => edge.node.user),
        members: n.cohort.registrations.edges
          .filter((edge) => edge.node.role <= 5)
          .map((edge) => edge.node.user)
      }
    } else if (n.mentor_group) {
      node.id = n.mentor_group.id
      node.meta.title = n.mentor_group.name
      node.users = {
        staff: n.mentor_group.memberships.edges
          .filter((edge) => edge.node.role > 5)
          .map((edge) => edge.node.user),
        members: n.mentor_group.memberships.edges
          .filter((edge) => edge.node.role <= 5)
          .map((edge) => edge.node.user)
      }
    }

    return node
  })
}

const useInboxItems = ({ search = null } = {}) => {
  const query = useAuthAwareQuery(INBOX_QUERY, {
    variables: {
      search
    }
  })
  const { data, loading, error } = query

  const items = useMemo(() => {
    if (data) {
      return parseGroupNodes(
        data.inboxGroupConnections.edges.map((edge) => edge.node)
      )
    }
    return []
  }, [data])

  const { loadMore, hasMore, loadingMore } = usePaginator({
    data: query, // hacky
    path: "inboxGroupConnections"
  })

  return { items, loading, loadMore, hasMore, loadingMore, error }
}

export default useInboxItems
