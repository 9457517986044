import { ZodError, ZodSchema } from "zod"

// Takes a list of actions that must have a zod-form-data `schema` as property of
// the function which is used to validate the `formData`, selecting whichever
// action can process the provided `formData`.

interface Action {
  (formData: FormData): any
  schema: ZodSchema
}

export const resolveAction = <Fns extends Action>(...actions: Action[]) => {
  return (formData: FormData): ReturnType<Fns> => {
    let error: ZodError | null = null

    for (let i = 0; i < actions.length; i++) {
      const fn = actions[i]
      // Attempt to call the first valid action.
      const parseResult = fn.schema.safeParse(formData)
      if (parseResult.success) {
        return fn(formData)
      } else {
        // Store the error of the invalid action to throw later if no valid
        // action is found.
        error = parseResult.error
      }
    }

    // If we've made it here, there will be an error, so throw it.
    throw error
  }
}

// import { ActionFunctionArgs } from "react-router-dom"
// import { ZodError, ZodSchema } from "zod"

// // Takes a list of actions that must have a zod-form-data `schema` as property of
// // the function which is used to validate the `formData`, selecting whichever
// // action can process the provided `formData`.

// export interface GenericResolveAction {
//   (...args: any[]): any
//   schema: ZodSchema
// }

// export interface ResolveActionFn {
//   (formData: FormData, ...args: any[]): any
// }
// export interface ResolveAction extends GenericResolveAction, ResolveActionFn {}

// export interface RouterResolveActionFn {
//   (formData: FormData, actionFnArgs: ActionFunctionArgs): any
// }

// export interface RouterResolveAction
//   extends GenericResolveAction,
//     RouterResolveActionFn {}

// const resolver = <ResolveActions extends GenericResolveAction[]>(
//   actions: ResolveActions,
//   formData: FormData,
//   actionFnArgs?: ActionFunctionArgs
// ) => {
//   let error: ZodError | null = null

//   for (let i = 0; i < actions.length; i++) {
//     const fn = actions[i]
//     // Attempt to call the first valid action.
//     const parseResult = fn.schema.safeParse(formData)
//     if (parseResult.success) {
//       return fn(formData, actionFnArgs)
//     } else {
//       // Store the error of the invalid action to throw later if no valid
//       // action is found.
//       error = parseResult.error
//     }
//   }

//   // If we've made it here, there will be an error, so throw it.
//   throw error
// }

// export const resolveRouterAction = <Fns extends RouterResolveAction>(
//   ...actions: RouterResolveAction[]
// ): RouterResolveActionFn => {
//   return (formData, actionFnArgs): ReturnType<Fns> => {
//     return resolver(actions, formData, actionFnArgs)
//   }
// }

// export const resolveAction = <Fns extends ResolveAction>(
//   ...actions: ResolveAction[]
// ): ResolveActionFn => {
//   return (formData, ...args): ReturnType<Fns> => {
//     return resolver(actions, formData, ...args)
//   }
// }
