import PropTypes from "prop-types"
import ManageDiscountCodeForm from "./ManageDiscountCodeForm"
import ManageDiscountResourcesForm from "./ManageDiscountResourcesForm"
import ManageDiscountUsageForm from "./ManageDiscountUsageForm"
import ManageDiscountsForm from "./ManageDiscountsForm"

const ManageResourceDiscountsForm = ({ card }) => (
  <ManageDiscountsForm
    card={card}
    title="Course Discount Code Creator"
    forms={[
      { Form: ManageDiscountCodeForm, props: { showDiscount: true } },
      { Form: ManageDiscountResourcesForm },
      { Form: ManageDiscountUsageForm, props: { showSeatsLimit: true } }
    ]}
  />
)

ManageResourceDiscountsForm.displayName = "ManageResourceDiscountsForm"

ManageResourceDiscountsForm.propTypes = {
  card: PropTypes.object.isRequired
}

export default ManageResourceDiscountsForm
