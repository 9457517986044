import Avatar from "@pathwright/ui/src/components/avatar/Avatar"
import styled from "styled-components"
import { usePathwrightContext } from "../../../pathwright/PathwrightContext"

const WhoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  .Avatar {
    margin-right: 10px;
  }

  > label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

const Who = ({ user }) => {
  const { me } = usePathwrightContext()
  const isUser = me && me.id === user.id

  return (
    <WhoContainer className="Completion--Who">
      <Avatar user={user} size={24} />
      <label>{isUser ? "You" : user.display_name}</label>
    </WhoContainer>
  )
}

export default Who
