import Alert from "@pathwright/ui/src/components/alert/Alert"
import Card from "@pathwright/ui/src/components/card/Card"
import CardBlock from "@pathwright/ui/src/components/card/CardBlock"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import PropTypes from "prop-types"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import FeatureBlockItem from "../block/FeatureBlockItem"
import FeaturePermissionsAuthLoginForm from "../form/FeaturePermissionsAuthLoginForm"
import { CONTEXTUAL_FEATURE_PERMISSION_QUERY } from "../graphql"

const ManageFeaturesAuthLoginFeature = ({ card, ...variables }) => {
  const { t } = useTranslate()
  const { school } = usePathwrightContext()

  const cfpQuery = useQuery(CONTEXTUAL_FEATURE_PERMISSION_QUERY, {
    variables
  })

  const feature = cfpQuery.data && cfpQuery.data.contextualFeaturePermission

  return (
    <Card card={card} emphasis="secondary">
      {cfpQuery.loading ? (
        <LoadingCircle center />
      ) : cfpQuery.error ? (
        <Alert error={cfpQuery.error} />
      ) : (
        <CardBlock paddingTop={false} paddingBottom={false}>
          <FeatureBlockItem feature={feature} />
          <FeaturePermissionsAuthLoginForm featureKey={feature.feature_key} />
        </CardBlock>
      )}
    </Card>
  )
}

ManageFeaturesAuthLoginFeature.displayName = "ManageFeaturesAuthLoginFeature"

ManageFeaturesAuthLoginFeature.propTypes = {
  // provided by CardStack > CardStackCard
  card: PropTypes.object.isRequired,
  ...CONTEXTUAL_FEATURE_PERMISSION_QUERY._propTypes
}

export default ManageFeaturesAuthLoginFeature
