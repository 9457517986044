import TagSelector from "@pathwright/ui/src/components/tag/selector/TagSelector"
import { useTagManagerCard } from "./TagManagerContainer"
import TagManagerPrompt from "./TagManagerPrompt"
import { tagAttachmentContextPropType } from "./propTypes"
import useTagPermissions from "./useTagPermissions"
import { getTagsContext } from "./utils"

const TagSelectorWithTagManager = ({
  tags,
  context,
  useContextLabels,
  CustomTagManager,
  ...passProps
}) => {
  const { tagManagerCardStack, openTagManagerCard } = useTagManagerCard({
    // Essentially removing any tagLink context. This directs updateUn/SelectTags
    // methods on how to un/select tags from/for context.
    context: getTagsContext(context),
    useContextLabels,
    CustomTagManager,
    ...passProps
  })

  const { managePrompt, selectLabel, tagFormLabels } = useContextLabels(context)
  const tagPermissions = useTagPermissions({ context })

  const postMenuList =
    tagPermissions.canEdit && !!tags.all.length && managePrompt ? (
      <TagManagerPrompt
        label={managePrompt}
        onClick={() => openTagManagerCard()}
      />
    ) : null

  return (
    <>
      <TagSelector
        tags={tags}
        tagPermissions={tagPermissions}
        postMenuList={postMenuList}
        selectLabel={selectLabel}
        tagFormLabels={tagFormLabels}
        {...passProps}
      />
      {tagManagerCardStack}
    </>
  )
}

TagSelectorWithTagManager.displayName = "TagSelectorWithTagManager"

TagSelectorWithTagManager.propTypes = {
  context: tagAttachmentContextPropType
}

export default TagSelectorWithTagManager
