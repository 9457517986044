import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import IconButton from "@pathwright/ui/src/components/button/IconButton"
import SearchInput from "@pathwright/ui/src/components/form/form-text-input/SearchInput"
import { useSessionStorage } from "@pathwright/ui/src/components/hooks/useLocalStorage"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import Tab from "@pathwright/ui/src/components/tab/Tab"
import { media } from "@pathwright/ui/src/components/utils/styles"
import { useEffect, useMemo, useState } from "react"
import styled from "styled-components"
import useFuse from "../../utils/useFuse"

const FeedItems = styled.div`
  padding: 10px;
`

const FeedContainer = styled.nav`
  width: 100%;
  overflow: hidden;
  min-width: 76px;
  display: flex;
  flex-direction: column;
  /* height: 100%; */

  .inner {
    padding: 0px 10px;

    > header {
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: row;
      padding: 4px 10px;

      h1 {
        font-size: 1.1em;
        margin: 0px;
      }

      .FeedControls {
        margin-left: auto;
        flex-shrink: 0;
      }
    }
  }

  .SearchInput {
    margin-top: 10px;
    /* background-color: #fff; */
    /* margin-bottom: 10px; */
  }

  ul {
    padding: 0px;
  }
`

const FeedScrollContainer = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  max-height: calc(100vh - 200px);
  ${media.max.phone`
    overflow: unset;
    max-height: unset;
  `}
`

const FeedFiltersContainer = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 2em;
  margin: 0;
  list-style: none;

  li[role="tab"] {
    flex-grow: 0;
    margin: 0.2em 0.5em;
  }

  .Button__content-wrapper {
    color: rgba(0, 0, 0, 0.6);
  }

  .Button {
    margin-right: 10px;
  }

  .IconButton {
    margin-left: auto;
    margin-right: 0;
  }

  .FeedFilters__count {
    margin-left: 0.2em;
    ${media.max.phone`display: none;`}
  }
`

const FeedFilters = ({
  items,
  filters,
  filterKey,
  showSearch,
  hideSearch,
  toggleSearch,
  onFilter
}) => {
  const { t } = useTranslate()

  const handleFilter = (filter) => () => onFilter(filter)

  // Count all the items for each filter.
  const filtersWithCounts = useMemo(
    () =>
      filters.reduce(
        (filters, filter) => [
          ...filters,
          {
            ...filter,
            count: items.filter(filter.filter).length
          }
        ],
        []
      ),
    [filters, items]
  )

  return (
    <FeedFiltersContainer>
      {filtersWithCounts.map((filter) => (
        <Tab
          key={filter.key}
          onClick={handleFilter(filter)}
          active={filterKey === filter.key}
          underline={false}
          tab={{
            label: filter.getLabel(t),
            count: filter.showCount ? filter.count : null
          }}
        />
      ))}
      {!hideSearch && (
        <IconButton
          styleType={showSearch ? "secondary" : "tertiary"}
          icon="search"
          onClick={toggleSearch}
          style={{ borderBottom: 0 }}
        />
      )}
    </FeedFiltersContainer>
  )
}

const fuseOptions = {
  ignoreLocation: true,
  shouldSort: false,
  keys: ["meta.title", "meta.user"]
}

const Feed = ({
  items,
  filters,
  onSelect,
  feedItemComponent: FeedItem,
  emptyFeedCompoent: EmptyFeed,
  paginateComponent: PaginateItems,
  onFilter
}) => {
  const { t } = useTranslate()
  const [showSearch, setShowSearch] = useState(false)
  const [search, setSearch] = useState("")
  const [filterKey, setFilterKey] = useSessionStorage(
    "feed-filters-key",
    filters[0].key
  )
  const filter = filters.find((filter) => filter.key === filterKey)

  // Sync key with notifications store.
  useEffect(() => {
    onFilter(filterKey)
  }, [filterKey])

  // Apply filter to items.
  const filteredItems = useMemo(
    () => (items ? items.filter(filter.filter) : items),
    [items, filter]
  )

  // Those items that have been filtered by filter.filter and searchTerm.
  const feedItems = useFuse(filteredItems, search, fuseOptions)

  const handleSearchItems = (value) => setSearch(value)
  // Hide search icon if there are no filteredItems to search.
  const hideSearch = Boolean(
    !search && (!filteredItems || !filteredItems.length)
  )

  const handleFilter = (filter) => {
    setSearch("")
    setShowSearch(false)
    setFilterKey(filter.key)
  }

  const renderFeedItems = () => {
    // Feed is only empty when there are no filteredItems or when
    // searching and there are no feedItems.
    const feedIsEmpty = Boolean(
      !filteredItems ||
        !filteredItems.length ||
        (search && (!feedItems || !feedItems.length))
    )

    if (feedIsEmpty)
      return EmptyFeed ? (
        <EmptyFeed filter={filter} search={search} />
      ) : search ? (
        <BlankSlate
          icons={false}
          heading={t(`No results matching "{{ search }}"`, { search })}
        />
      ) : (
        <BlankSlate icons={false} heading={t("No results")} />
      )
    return (
      <FeedItems>
        {feedItems.map((item) => {
          return <FeedItem key={item.id} item={item} onSelect={onSelect} />
        })}
      </FeedItems>
    )
  }

  return (
    <FeedContainer>
      <div className="inner">
        <FeedFilters
          items={items}
          filters={filters}
          filterKey={filterKey}
          showSearch={showSearch}
          hideSearch={hideSearch}
          onFilter={handleFilter}
          toggleSearch={() => setShowSearch(!showSearch)}
        />
        {showSearch && (
          <SearchInput
            placeholder="Search..."
            onChange={handleSearchItems}
            value={search}
          />
        )}
      </div>
      <FeedScrollContainer className="FeedItemsContainer">
        {renderFeedItems()}
        <PaginateItems />
      </FeedScrollContainer>
    </FeedContainer>
  )
}

export default Feed
