import { useEffect, useRef, useState } from "react"

// executes useEffect callback if inputs changed after mount OR if no inputs, then executes
// useEffect callback by forcing a rerender
export const useDidMountEffect = (fn, inputs = []) => {
  const didMountRef = useRef(false)
  const [mounted, setMounted] = useState(false)

  useEffect(
    () => {
      if (didMountRef.current) {
        return fn()
      } else {
        didMountRef.current = true
        if (!inputs.length) setMounted(true)
      }
    },
    inputs.length ? inputs : [mounted]
  )
}

export default useDidMountEffect
