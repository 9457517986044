import { useEffect, useState } from "react"
import { components } from "react-select"
import useDidMountEffect from "../../hooks/useDidMountEffect"
import OutsideClickWrapper from "../../overlay/OutsideClickWrapper"
import Pathicon from "../../pathicon/Pathicon"
import TagStack from "../TagStack"
import CreateTag from "../form/CreateTag"

export const ClearIndicator = (props) => (
  <components.ClearIndicator {...props}>
    <Pathicon icon="x" />
  </components.ClearIndicator>
)

export const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <Pathicon icon="chevron-down" />
  </components.DropdownIndicator>
)

export const MultiValueRemove = (props) => (
  <components.MultiValueRemove {...props}>
    <Pathicon icon="x" />
  </components.MultiValueRemove>
)

// Rendering null for NoOptionsMessage as we don't want to show the default
// "No options" message, rather than using the noOptionsMessage, which is
// more hacky and adds extra padding to Menu.
export const NoOptionsMessage = (props) => {
  const {
    inputValue,
    options,
    customProps: { tagPermissions }
  } = props.selectProps

  // Hide the NoOptionsMessage when user is attempting to add a tag.
  if (inputValue && tagPermissions.canAdd) return null

  // Hide the NoOptionsMessage when no options have been provided.
  if (!options.length) return null

  return <components.NoOptionsMessage {...props} />
}

const InteractionWrapper = ({ children, setMenuIsOpen }) => {
  const handleInteraction = (e) => {
    // Prevent focusing ReactSelect Input, which requires stopping
    // the mousedown event from bubbling up to ReactSelect's onMenuMouseDown.
    // NOTE: simply stopping the propagation of the event for any mousedown
    // occurring in CreateTag causes functionality in the ColorPicker to break,
    // specifically those react-color components relying on a mousedown event
    // set on the window.
    if (
      e.target.tagName === "INPUT" ||
      e.target.tagName === "TEXTAREA" ||
      e.target.tagName === "BUTTON"
    ) {
      e.stopPropagation()
    }

    // Ensure menu remains open.
    // Setting regardless of current state as othersise the Menu gets closed.... Why?
    setMenuIsOpen(true)
  }

  return (
    <div
      onMouseDown={handleInteraction}
      onTouchStart={handleInteraction}
      style={{ borderRadius: "inherit" }}
    >
      {children}
    </div>
  )
}

export const Menu = (props) => {
  const {
    value,
    onChange,
    inputValue,
    onInputChange,
    options,
    isMulti,
    customProps: {
      tags,
      tagPermissions,
      tagFormLabels,
      postMenuList,
      setMenuIsOpen,
      setInputValue,
      selectRef
    }
  } = props.selectProps

  // Per convenience, show CreateTag as expanded when there are no
  // more options to select from.
  const getCreateTagExpanded = (createTagExpanded) => {
    const nextCreateTagExpanded = Boolean(
      !inputValue &&
        options.length === value.length &&
        tagPermissions.canAdd &&
        !postMenuList
    )

    if (nextCreateTagExpanded && nextCreateTagExpanded !== createTagExpanded) {
      return nextCreateTagExpanded
    }

    return createTagExpanded || false
  }

  // Preferred not to track expanded state but necessary for ensuring
  // we retain CreateTag even if inputValue becomes falsey.
  const [createTagExpanded, setCreateTagExpanded] = useState(() =>
    getCreateTagExpanded(false)
  )

  useEffect(() => {
    setCreateTagExpanded(getCreateTagExpanded(createTagExpanded))
  }, [inputValue])

  // Collapse CreateTag after value changes, indication potentially created tag.
  useDidMountEffect(() => {
    setCreateTagExpanded(false)
  }, [value.length])

  const hanleOnChange = (tag) => {
    // update ReactSelect inputValue when CreateTag name changes
    if (tag.name !== inputValue) {
      onInputChange(tag.name, {
        action: "input-change"
      })
    }
  }

  const handleCreateTag = (tag) => {
    onChange(isMulti ? [...value, tag] : tag)
    setMenuIsOpen(false)
    setInputValue("")
  }

  const handleToggleExpanded = (expanded) => setCreateTagExpanded(expanded)

  // Ensure we aren't keeping the menu open after input is cleared.
  useEffect(() => {
    setMenuIsOpen(createTagExpanded)
    // After CreateTag is collapsed...
    if (!createTagExpanded) {
      // ...if it makes sense for the user to still interact with the selector input...
      if (options.length || inputValue) {
        // ...forcing the Menu to stay open by focusing selector input.
        selectRef.current.focus()
      } else {
        // ...otherwise blurring the selector input.
        selectRef.current.blur()
      }
    }
  }, [createTagExpanded])

  const preMenuList = tagPermissions.canAdd &&
    (!!inputValue || createTagExpanded) && (
      <CreateTag
        tag={{ name: inputValue }}
        tags={tags}
        tagFormLabels={tagFormLabels}
        expanded={createTagExpanded}
        onChange={hanleOnChange}
        onSubmit={handleCreateTag}
        onToggleExpaneded={handleToggleExpanded}
      />
    )

  return (
    // Collapse when clicking outside of the Menu.
    <OutsideClickWrapper
      onOutsideClick={(e) => {
        const tagSelectorNode = e.target.closest(".TagSelector")
        // Will remain expanded if click occurred within the containing
        // TagSelector, otherwise collapses.
        setMenuIsOpen(!!tagSelectorNode)
      }}
    >
      <components.Menu {...props}>
        <InteractionWrapper setMenuIsOpen={setMenuIsOpen}>
          {preMenuList}
        </InteractionWrapper>
        {!createTagExpanded && props.children}
        {!createTagExpanded && !inputValue && (
          <InteractionWrapper setMenuIsOpen={setMenuIsOpen}>
            {postMenuList}
          </InteractionWrapper>
        )}
      </components.Menu>
    </OutsideClickWrapper>
  )
}

// Customizing SelectContainer for showing a TagStack when no
// tags are selected. The TagStack gives a "sneak peek" into
// what tags are available in the selector.
export const SelectContainer = (props) => {
  const {
    value,
    options,
    customProps: { inverted }
  } = props.selectProps

  return (
    <components.SelectContainer {...props}>
      {props.children}
      {inverted && (
        <TagStack
          tags={options}
          limit={3}
          tagSize="1em"
          // Align TagStack to SelectContainer edge, matching border.
          style={{
            // Left padding so that box shadow on first item isn't clipped.
            paddingLeft: "3px",
            // Right margin to push TagSelector to the very edge of SelectContainer.
            marginRight: "-3px",
            width: !value.length ? "100%" : 0,
            // Border radius to match that of the SelectContainer.
            borderTopRightRadius: "inherit",
            borderBottomRightRadius: "inherit",
            transition: !value.length
              ? "width .5s ease, margin .5s ease"
              : "none"
          }}
        />
      )}
    </components.SelectContainer>
  )
}

export default {
  ClearIndicator,
  DropdownIndicator,
  MultiValueRemove,
  NoOptionsMessage,
  Menu,
  SelectContainer
}
