export const IN_PROGRESS_FILTER = JSON.stringify({ lt: 1.0 })
export const COMPLETED_FILTER = JSON.stringify({ gte: 1.0 })

export const PROGRESS_FILTER_LABEL_MAP = {
  [IN_PROGRESS_FILTER]: "In progress",
  [COMPLETED_FILTER]: "Completed"
}

export const PROGRESS_FILTER_HEADING_MAP = {
  [IN_PROGRESS_FILTER]: "Here's everything you're learning",
  [COMPLETED_FILTER]: "Here's everything you've completed"
}

export const ENROLLMENT_ROLE_STUDENT = 5
