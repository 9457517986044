import gql from "graphql-tag"

export default gql`
  mutation SendGiftSubscriptionEmail(
    $gift_id: Int!
    $recipient_email: String!
  ) {
    sendGiftSubscriptionEmail(
      gift_id: $gift_id
      recipient_email: $recipient_email
    )
  }
`
