let APIUtils
export default APIUtils = (function () {
  APIUtils = class APIUtils {
    static initClass() {
      this.prototype.promiseCache = {}
    }

    getErrorDetails(jqxhr) {
      if (jqxhr.message) {
        return [{ message: jqxhr.message }]
      }

      // Parses an error response from the server and
      // attempts to give helpful error message(s) back.
      if (jqxhr.status === 500) {
        // TODO: give a better message here
        return [{ message: "Internal server error." }]
      } else {
        try {
          if (jqxhr.message != null) {
            return [{ message: jqxhr.message }]
          }

          const respData = $.parseJSON(jqxhr.responseText)
          if (respData.detail != null) {
            let { detail } = respData
            if (_.isString(detail)) {
              detail = [{ message: detail }]
            }
            return detail
          } else {
            const messages = []
            // Rest framwork validation error parsing
            for (let key in respData) {
              let v = respData[key]
              if (_.isArray(v)) {
                v = v[0]
              }
              messages.push({
                field: key,
                message: v
              })
            }
            return messages
          }
        } catch (e) {
          console.log("non-json response: ", jqxhr)
          return [
            {
              message: `Oops! Something went wrong! ${jqxhr.status}: ${jqxhr.statusText}`
            }
          ]
        }
      }
    }

    req(url, type, data) {
      if (data == null) {
        data = {}
      }
      const params = {
        type,
        data,
        dataType: "json"
      }
      const p = $.ajax(url, params)
      return p
    }
  }
  APIUtils.initClass()
  return APIUtils
})()
