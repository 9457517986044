import { useQuery } from "@apollo/client"
import throttle from "lodash/throttle"
import { useEffect, useMemo, useRef, useState } from "react"
import { CERTIFICATE_PREVIEW_QUERY } from "../graphql"
import { getQueryVariables } from "../utils"

const useCertificatePreview = ({
  template,
  textObjects,
  baseVariables,
  customVariables
} = {}) => {
  // Merge baseVariables and customVariables into one variables object.
  const variables = useMemo(
    () => ({
      template,
      textObjects: getQueryVariables(textObjects),
      variables: {
        ...(baseVariables || {}),
        ...(customVariables || {})
      }
    }),
    [template, textObjects, baseVariables, customVariables]
  )

  const [queryVariables, setQueryVariables] = useState(variables)

  const throttleQueryVariables = useRef(
    throttle((variables) => setQueryVariables(variables), 600, {
      leading: true
    })
  ).current

  useEffect(() => {
    throttleQueryVariables(variables)
  }, [variables])

  const certificateQuery = useQuery(CERTIFICATE_PREVIEW_QUERY, {
    variables: queryVariables,
    skip: !textObjects
  })

  return certificateQuery.data ? certificateQuery.data.certificatePreview : null
}

export default useCertificatePreview
