import uniqBy from "lodash/uniqBy"

// tag: string
// returns string
export const normalizeTag = (tag = "") => {
  return tag.toLowerCase().trim()
}

// Sorting function: sorts case insensitve; supports strings or objects (tag.name)
// Use in MediaState and AccountTags.getRenderedTagNames
// tags: array of [string || { name: string }]
// returns string
export const tagsSortFunction = (a, b) => {
  a = a.name || a
  b = b.name || b

  return a.toLowerCase() > b.toLowerCase() ? 1 : -1
}

// Check if a tag is included in either an array or another tag (ignores case)
// source: string[] || string
// tag: string
// returns boolean
export const includesNormalized = (source, tag) => {
  return Array.isArray(source)
    ? source.map((t) => normalizeTag(t)).includes(normalizeTag(tag))
    : normalizeTag(source).includes(normalizeTag(tag))
}

// tags: array of strings
// returns array of strings
export const normalizedUnique = (tags: string[]) => {
  return uniqBy(tags, (t) => normalizeTag(t))
}
