import debounce from "lodash/debounce"
import { useRef, useState } from "react"

const useDebouncedState = (delay = 300, initialValue = null) => {
  const [debouncedValue, setDebouncedValue] = useState(initialValue)

  const debouncedSetDebouncedValue = useRef(
    debounce(setDebouncedValue, delay, {
      leading: false,
      trailing: true
    })
  ).current

  return [debouncedValue, debouncedSetDebouncedValue]
}

export default useDebouncedState
