import Card from "@pathwright/ui/src/components/card/Card"
import Profile from "./Profile"

const ProfileCard = ({ card, ...passProps }) => {
  return (
    <Card card={card} style={{ paddingBottom: 0 }}>
      <Profile {...passProps} />
    </Card>
  )
}

ProfileCard.displayName = "ProfileCard"

ProfileCard.propTypes = Profile.propTypes

export default ProfileCard
