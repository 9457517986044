import styled from "styled-components"
import SchoolIdentity from "../lib/SchoolIdentity"

const StyledContainer = styled.div`
  z-index: 9001;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px;
  color: white;
`

const AccountLogo = () => {
  return (
    <StyledContainer>
      <SchoolIdentity />
    </StyledContainer>
  )
}

AccountLogo.displayName = "AccountLogo"

export default AccountLogo
