// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
const SchoolModel = require("./models/school").default

export default Pathwright.module(
  "School",
  function (School, App, Backbone, Marionette, $, _) {
    this.startWithParent = false

    this.school = new SchoolModel(bootstrappedData.school)
    window.App.version = this.school.get("platform_version")
    window.App.school = this.school

    const API = {
      getSchool: () => {
        return this.school
      }
    }

    window.App.reqres.setHandler("get:school", () => API.getSchool())
  }
)
