import Text from "@pathwright/ui/src/components/ui/Text"
import styled from "styled-components"
import ReportActions from "./ReportActions"

const Container = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
  text-align: center;
  &:last-child {
    margin-bottom: 0;
  }

  > div:first-child {
    display: flex;
  }
`

const Lesson = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .ReportActions {
    position: absolute !important;
    top: 0;
    right: 8px;
  }

  html.no-touch &:not(:hover) .ReportActions i.pathicon-ellipses-horizontal {
    visibility: hidden;
  }
`

const ReportPathGroup = ({ pathItem }) => {
  return (
    <Container>
      <Lesson>
        <Text.H5>{pathItem.name}</Text.H5>
        <ReportActions pathItem={pathItem} />
      </Lesson>
    </Container>
  )
}

ReportPathGroup.displayName = "ReportPathGroup"

export default ReportPathGroup
