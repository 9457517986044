import PropTypes from "prop-types"
import styled from "styled-components"
import ProfileAvatarCardTrigger from "../profile/ProfileAvatarCardTrigger"
import ActivityActions from "./ActivityActions"
import ActivityDescription from "./ActivityDescription"
import { ActivityItem } from "./ui"
import { getActingUser } from "./utils"

const StyledWho = styled.div`
  flex-shrink: 0;
  min-height: 30px;
  margin-right: 15px;
`

const StyledWhat = styled.div`
  width: 50%;
  flex-grow: 1;
  align-self: center;
`

const ActivityEntry = (props) => {
  const {
    actions,
    columnSide,
    isCourseActivity,
    isStepActivity,
    isUserActivity,
    canViewAvatarCard,
    canViewAvatarProfile
  } = props
  const actingUser = getActingUser(actions[0])

  return (
    <ActivityItem columnSide={columnSide}>
      <StyledWho>
        {!isUserActivity && (
          <ProfileAvatarCardTrigger
            user={actingUser}
            size="30px"
            canViewCard={canViewAvatarCard}
            canViewProfile={canViewAvatarProfile}
          />
        )}
      </StyledWho>
      <StyledWhat>
        <ActivityDescription actions={actions} />
        <ActivityActions
          actions={actions}
          columnSide={columnSide}
          isCourseActivity={isCourseActivity}
          isStepActivity={isStepActivity}
          isUserActivity={isUserActivity}
        />
      </StyledWhat>
    </ActivityItem>
  )
}

ActivityEntry.displayName = "ActivityEntry"

ActivityEntry.propTypes = {
  actions: PropTypes.array.isRequired,
  columnSide: PropTypes.oneOf(["left", "right"])
}

export default ActivityEntry
