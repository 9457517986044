import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import AnimatedIcon from "@pathwright/ui/src/components/pathicon/AnimatedIcon"
import Text from "@pathwright/ui/src/components/ui/Text"
import React from "react"
import styled from "styled-components"

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const SignupFauxLoading = ({
  createdSchool,
  linkedSchool,
  inverted,
  onNext
}) => {
  const { t } = useTranslate()

  React.useEffect(() => {
    setTimeout(() => {
      onNext && onNext()
    }, 2000)
  }, [])

  // wrapping div get's animated styling which otherwise would mess with icon positioning
  return (
    <div>
      <StyledContainer>
        <AnimatedIcon
          icon="sparkles"
          iconSize={40}
          circleSize={100}
          inverted={inverted}
        />
        <Text.Body style={{ marginTop: 10 }} inverted={inverted}>
          {createdSchool
            ? t("Creating {{ school_name }}...", {
                school_name: createdSchool.name
              })
            : t("Linking {{ school_name }}...", {
                school_name: linkedSchool.name
              })}
        </Text.Body>
      </StyledContainer>
    </div>
  )
}

SignupFauxLoading.displayName = "SignupFauxLoading"

export default SignupFauxLoading
