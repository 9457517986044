import { useUIThemeContext } from "@pathwright/ui/src/components/ui/PathwrightUI"
import { useEffect, useState } from "react"
import { createGlobalStyle } from "styled-components"
import { usePathwrightContext } from "./PathwrightContext"

const useViewportHeight = () => {
  const [viewportWidth, setViewportWidth] = useState()

  useEffect(() => {
    const handleResize = () => setViewportWidth(window.innerHeight)
    handleResize()
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  })

  return viewportWidth
}

const _GlobalThemeStyles = createGlobalStyle`
  :root {
    --school-main-color: ${(props) => props.$theme.primary_color};
    --school-heading-font: ${(props) => props.$theme.heading_font}; 
  }

  #root {
    .theme-heading {
      font-family: var(--school-heading-font), "ProximaNova-Regular", Helvetica,
        sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
    }

    .theme-heading.theme-heading-regular {
      font-weight: 400;
    }

    .theme-heading.theme-heading-bold {
      font-weight: 700;
    }
  }

  /*
    Hide loading splash once client is loaded.
    This ensures these items won't be seen on devices when scrolling top UI when at the top or bottom of the screen.
  */
  .loading-splash {
    opacity: 0 !important;
  }
`

export function GlobalThemeStyles() {
  const { school } = usePathwrightContext()
  return (
    <_GlobalThemeStyles
      $theme={{
        primary_color: school.theme?.primary_color
          ? `#${school.theme?.primary_color}`
          : "#0d71fb",
        heading_font: school.theme?.heading_font
      }}
    />
  )
}

// most of these are for legacy styles. once we drop legacy
// this can simply be a default color for a tag.
const LegacyGlobalSchoolThemeStyles = createGlobalStyle`
  :root {
    --vh: ${(props) => props.$viewportWidth}px;
  }

  a,
  .Text--theme a {
    color: var(--school-main-color);
  }
  ${"" /* Color and background-color set so Button- can choose which to keep */}
  /*
  button {
    color: var(--school-main-color);
    background-color: var(--school-main-color);
  }
  */

  button.ButtonLegacy {
    color: var(--school-main-color);
    background-color: var(--school-main-color);
  }

  button.ButtonLegacy.btn-danger {
    color: white;
  }

  ${"" /* Class Overrides */}
  ${"" /* Brand classes available for html tags that aren't defaulted */}
  ${"" /* Adds specificness for overriding */}

  ${"" /* In future theme may support more than one custom font */}
  h1.brand-heading,
  h2.brand-heading,
  h3.brand-heading {
    font-family: var(--school-heading-font), "ProximaNova-Regular", Helvetica, sans-serif;
  }
  ${"" /* TODO: may not need brand-links because of defaults */}
  .brand-color,
  a.brand-color,
  .ButtonLegacy.brand-color {
    color: var(--school-main-color) !important;
  }
  .ButtonLegacy.brand-color:hover,
  .ButtonLegacy.brand-color:focus,
  .ButtonLegacy.brand-color:active,
  button.brand-color:hover,
  button.brand-color:focus,
  button.brand-color:active,
  a.brand-color:hover,
  a.brand-color:focus,
  a.brand-color:active {
    color: var(--school-main-color);
  }
  ${"" /* Tab border when acted upon — TODO: just use brand-color? */}
  .brand-tab a {
    border-bottom: 2px solid transparent;
  }
  .brand-tab > a:hover,
  .brand-tab > a:focus,
  .brand-tab.active > a,
  .tab.brand-tab > a:hover,
  .tab.brand-tab > a:focus,
  .tab.brand-tab.active > a {
    border-bottom: 2px solid var(--school-main-color);
  }
  ${"" /* TODO: may not need brand-button because of defaults */}
  .brand-button,
  a.brand-button,
  .ButtonLegacy.brand-button {
    border: none;
    color: white;
    background-color: var(--school-main-color);
  }
  .brand-button:hover,
  .brand-button:focus,
  a.brand-button:hover,
  a.brand-button:focus,
  .ButtonLegacy.brand-button:hover,
  .ButtonLegacy.brand-button:focus {
    color: white;
    opacity: 0.9;
    background-color: var(--school-main-color);
  }
  ${"" /* Use border-width shorthand to apply color to correct side */}
  .brand-border {
    border-color: var(--school-main-color);
  }
  .brand-background {
    background-color: var(--school-main-color);
  }
  ${"" /* Use for rich text content areas */}
  .brand-content a {
    color: var(--school-main-color);
  }
  ${"" /* Use when brand color needs to be applied to :before or :after */}
  .brand-pseudo:before,
  .brand-pseudo:after {
    color: var(--school-main-color);
    background-color: var(--school-main-color);
  }


  :where(.chakra-ui-reset-css), :where(.chakra-portal) {
    a,
    .Text--theme a {
      color: initial;
    }
    ${
      "" /* Color and background-color set so Button- can choose which to keep */
    }
    button {
      color: initial;
      background-color: initial;
    }

    button.ButtonLegacy {
      color: initial;
      background-color: initial;
    }

    ${"" /* Class Overrides */}
    ${"" /* Brand classes available for html tags that aren't defaulted */}
    ${"" /* Adds specificness for overriding */}

    ${"" /* In future theme may support more than one custom font */}
    h1.brand-heading,
    h2.brand-heading,
    h3.brand-heading {
      font-family: initial
    }
    ${"" /* TODO: may not need brand-links because of defaults */}
    .brand-color,
    a.brand-color,
    .ButtonLegacy.brand-color {
      color: initial;
    }
    .ButtonLegacy.brand-color:hover,
    .ButtonLegacy.brand-color:focus,
    .ButtonLegacy.brand-color:active,
    button.brand-color:hover,
    button.brand-color:focus,
    button.brand-color:active,
    a.brand-color:hover,
    a.brand-color:focus,
    a.brand-color:active {
      color: initial;
    }
    ${"" /* Tab border when acted upon — TODO: just use brand-color? */}
    .brand-tab a {
      border-bottom: initial;
    }
    .brand-tab > a:hover,
    .brand-tab > a:focus,
    .brand-tab.active > a,
    .tab.brand-tab > a:hover,
    .tab.brand-tab > a:focus,
    .tab.brand-tab.active > a {
      border-bottom: initial;
    }
    ${"" /* TODO: may not need brand-button because of defaults */}
    .brand-button,
    a.brand-button,
    .ButtonLegacy.brand-button {
      border: initial;
      color: initial;
      background-color: initial;
    }
    .brand-button:hover,
    .brand-button:focus,
    a.brand-button:hover,
    a.brand-button:focus,
    .ButtonLegacy.brand-button:hover,
    .ButtonLegacy.brand-button:focus {
      color: initial;
      opacity: initial;
      background-color: initial;
    }
    ${"" /* Use border-width shorthand to apply color to correct side */}
    .brand-border {
      border-color: initial;
    }
    .brand-background {
      background-color: initial;
    }
    ${"" /* Use for rich text content areas */}
    .brand-content a {
      color: initial;
    }
    ${"" /* Use when brand color needs to be applied to :before or :after */}
    .brand-pseudo:before,
    .brand-pseudo:after {
      color: initial;
      background-color: initial;
    }
  }

  /*
    Hide loading splash once client is loaded.
    This ensures these items won't be seen on devices when scrolling top UI when at the top or bottom of the screen.
  */
  .loading-splash {
    opacity: 1;
  }
  .loading-splash-info,
  .loading-splash-spinner {
    opacity: 0;
    transition: opacity 0.5s;
  }
`

export default () => {
  const theme = useUIThemeContext()
  const viewportWidth = useViewportHeight()
  return (
    <>
      <GlobalThemeStyles />
      <LegacyGlobalSchoolThemeStyles
        $schoolTheme={theme}
        $viewportWidth={viewportWidth}
      />
    </>
  )
}
