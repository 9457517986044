import gql from "graphql-tag"
import MENTOR_GROUP_MEMBERSHIP_FRAGMENT from "./mentor-group-membership-fragment"

export default gql`
  mutation UpdateMentorGroupMembership(
    $id: Int!
    $group: Int!
    $role: MentorGroupMembershipRole
    $canReview: Boolean
    $hasAccess: Boolean
    $sendMentorDigest: Boolean
  ) {
    updateMentorGroupMembership(
      id: $id
      group: $group
      role: $role
      can_review: $canReview
      has_access: $hasAccess
      send_mentor_digest: $sendMentorDigest
    ) {
      ...MentorGroupMembership
    }
  }
  ${MENTOR_GROUP_MEMBERSHIP_FRAGMENT}
`
