import { Button, HStack } from "@chakra-ui/react"
import { GearIcon } from "@pathwright/pathicons"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import moment from "moment"
import { Link as ReactRouterLink } from "react-router-dom"
import { useShareDataContext } from "../shared/ShareDataContextProvider"
import Gate from "./Gate"
import useGatedShareAccessKey from "./useGatedShareAccessKey"

const CohortShareGate = () => {
  const { meta, permissions } = useShareDataContext()
  const { tc, tca } = useTranslate()
  const gatedReason = permissions[useGatedShareAccessKey()]?.gated_reason

  switch (gatedReason) {
    case "COHORT_COMPLETED":
      return (
        <Gate
          title={tca("Cohort Completed")}
          description={tc(
            "This Cohort is marked as Complete and is no longer available."
          )}
        />
      )
    case "COHORT_REGISTRATION_ENDED":
      return (
        <Gate
          title={tca("Registration Closed")}
          description={tc(
            `Registration for {{cohortName}} closed on {{cohortRegistrationEnds}}`,
            {
              cohortName: meta.title,
              cohortRegistrationEnds: moment(meta.registrationEnds).format(
                "MMM Do, YYYY"
              )
            }
          )}
        />
      )
    case "COHORT_ENDED":
      return (
        <Gate
          title={tca("Access Expired")}
          description={tc(
            `Access to {{cohortName}} expired on {{cohortAccessEnds}}`,
            {
              cohortName: meta.title,
              cohortAccessEnds: moment(meta.accessEnds).format("MMM Do, YYYY")
            }
          )}
        />
      )
    case "COHORT_SEATS_FILLED":
    case "COHORT_LICENSED_SEATS_FILLED":
      return (
        <Gate
          title={tca("Seat Count Reached")}
          description={tc(`{{cohortName}} has no more remaining seats`, {
            cohortName: meta.title
          })}
        >
          {permissions?.allow_manage && (
            <Button
              as={ReactRouterLink}
              to={`${permissions.manage_url}access/`}
              variant={"link"}
              colorScheme="gray"
              textDecoration="underline"
            >
              <HStack spacing={1}>
                <GearIcon size="1em" />
                <span>{tc("Configure cohort settings")}</span>
              </HStack>
            </Button>
          )}
        </Gate>
      )
  }

  // If typescript, we should ensure the switch case is exhausted.
  return null
}

export default CohortShareGate
