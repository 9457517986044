import gql from "graphql-tag"

export default gql`
  fragment Order on Order {
    id
    order_type
    order_status
    order_data
    checkout_session_id
    fulfilled_object_id
    school_id
    processor_id
  }
`
