import { graphql } from "@apollo/client/react/hoc"
import compose from "lodash/flowRight"
import get from "lodash/get"
import { createPaginator, flattenEdges } from "../../utils/apollo"
import { withSearch } from "../../utils/search"
import GROUPS_QUERY from "../graphql/groups-query"

export const withManageGroupsQuery = compose(
  withSearch,
  graphql(GROUPS_QUERY, {
    options: ({ first, resourceId, debouncedSearch, parentGroupIdFilter }) => ({
      notifyOnNetworkStatusChange: true,
      variables: {
        first: first || 25,
        resource_id: resourceId,
        search: debouncedSearch || "",
        parentGroupIdFilter
      }
    }),
    props: ({ data, ownProps }) => ({
      error: data.error,
      loading: data.loading || ownProps.debouncedSearch !== ownProps.search,
      groups: flattenEdges(get(data, "groups")),
      total: get(data, "groups.total", 0),
      hasMore: get(data, "groups.pageInfo.hasNextPage"),
      loadMore: createPaginator(data, "groups")
    })
  })
)

const ManageGroupsQuery = withManageGroupsQuery(
  ({ children, ...rest }) => children(rest) || null
)

ManageGroupsQuery.displayName = "ManageGroupsQuery"

export default ManageGroupsQuery
