import { API } from "lib/core/api/request"
import { encodeURLParams } from "lib/core/api/urls"
import { createStore } from "lib/mobx/mobx-store"
import _ from "lodash"
import { action, observable } from "mobx"
import {
  BACKGROUND_TYPE_BLUR,
  STAGING_STRIPE_REDIRECT_URI,
  TESTING_STRIPE_REDIRECT_URI
} from "school/constants"

const state = {
  details: observable.map(),
  support: observable.map(),
  theme: observable.map(),
  themes: observable.map(),
  integrations: observable.map(),
  commerce: observable.map(),
  snippets: observable.map(),
  invoices: observable.map(),
  next_invoice: observable.map(),
  next_invoice_data: observable.map(),
  payment: observable.map(),

  get stripe_activation_url() {
    const { user } = window.App.getStore("auth").getState()

    const stripe_auth_params = {
      response_type: "code",
      client_id: this.commerce.pathwright_stripe_client_id,
      scope: "read_write",
      state: this.school.id,
      stripe_landing: "register",
      "stripe_user[url]": this.school.url,
      "stripe_user[email]": user.email,
      "stripe_user[first_name]": user.first_name,
      "stripe_user[last_name]": user.last_name,
      // We can un-hardcode this when we support someone other than the US.
      "stripe_user[country]": "US",
      "stripe_user[currency]": "usd"
    }
    // redirect_uri has to be configured on our Stripe account settings and can only accept https (so no dev redirect)
    if (window.location.hostname.indexOf("pathwrightstaging") > -1) {
      stripe_auth_params.redirect_uri = STAGING_STRIPE_REDIRECT_URI
    } else if (window.location.hostname.indexOf("pantswright") > -1) {
      stripe_auth_params.redirect_uri = TESTING_STRIPE_REDIRECT_URI
    }
    return `https://connect.stripe.com/oauth/authorize?${encodeURLParams(
      stripe_auth_params
    )}`
  },

  get school() {
    return window.bootstrappedData.school
  },

  get is_on_legacy_plan() {
    return window.bootstrappedData.pathwright_account.is_on_legacy_plan
  },

  get can_view_billing() {
    return (
      this.school.is_activated &&
      (!!this.subscription || this.is_on_legacy_plan)
    )
  },

  get can_view_invoices() {
    return this.can_view_billing
  },

  get can_view_upcoming_invoice() {
    return (
      this.can_view_invoices &&
      // School either isn't a curriculum subscriber, or at least they aren't a paying curriculum subscriber
      !(
        this.school.is_curriculum_subscriber &&
        this.paid_curriculum_subscription
      )
    )
  },

  // This is filtering out schools who may still be able to update their card, but don't have a subscription
  get can_update_payment() {
    return !!this.publishable_key
  },

  get can_manage_billing() {
    return this.can_view_billing
  },

  get pathwright2Subscription() {
    if (this.payment.subscription) {
      return this.payment
    } else {
      const { subscription } =
        window.App.getStore("pathwrightAccount").getState()
      return subscription ? subscription : null
    }
  },

  // a bit hacky, but dealing with multiple APIs
  get subscription() {
    if (this.paid_curriculum_subscription) {
      if (this.payment.card_last_4) {
        return {
          ...this.paid_curriculum_subscription,
          subscription: {
            ...this.paid_curriculum_subscription.subscription,
            card_last_4: this.payment.card_last_4
          }
        }
      }
      // only expecting one subscription for now
      return this.paid_curriculum_subscription
    }

    return this.pathwright2Subscription
  },

  get paid_curriculum_subscription() {
    let paid_curriculum_subscription = null
    if (this.school.is_curriculum_subscriber) {
      paid_curriculum_subscription = this.school.curriculum_licensee_subs.find(
        (sub) => !!sub.curriculum_plan
      )
    }
    return paid_curriculum_subscription || null
  },

  // get school_billing_plan() {
  //   return this.subscription
  //     ? this.subscription[
  //         this.school.is_curriculum_subscriber ? "curriculum_plan" : "plan"
  //       ]
  //     : this.next_invoice_data && this.next_invoice_data.school_billing_plan
  // },

  get publishable_key() {
    if (!!this.paid_curriculum_subscription) {
      return this.paid_curriculum_subscription.licensor
        .provider_school_stripe_key
    } else {
      return window.App.getStore("pathwrightAccount").getState().payment_key
    }
  },

  get has_custom_domain() {
    // this check doesn't work on dev and staging since custom domains only apply on prod
    // TODO: use a better check
    return (
      [
        "pathwright",
        "pathwrightstaging",
        "pantswright",
        "pathwrightdev"
      ].filter((domain) => {
        return this.school.url.indexOf(`.${domain}.com`) > -1
      }).length == 0
    )
  },

  get has_pages() {
    return window.bootstrappedData.media.pages.length > 0
  }
}

const actions = {
  loadDetails() {
    return API.get("manage/details/")
  },
  saveDetails(details) {
    return API.put("manage/details/", {
      ...this.details,
      ...details
    }).then(this.action.updateSchoolDetails)
  },
  loadSupport() {
    return API.get("manage/support/")
  },
  saveSupport(support) {
    return API.put("manage/support/", {
      support_email: support.support_email,
      support_phone: support.support_phone,
      support_policy: support.support_policy
    })
  },
  loadThemes() {
    return API.get("theme/")
  },
  loadTheme() {
    return API.get("theme/school-theme")
  },
  // TODO: need to reload theme css after updating so changes are evident in UI
  saveTheme(theme) {
    theme = _.assign(
      this.theme,
      theme,
      // defaults/overrides
      {
        theme_type: theme.id
          ? theme.theme_type
          : 50 /* GQL ThemeType.Legacy equivalent */,
        // always send the raw image as the background_image
        background_image:
          typeof theme.background_image != "undefined"
            ? theme.background_image
            : this.theme.background_image_raw,
        background_type:
          theme.background_type ||
          this.theme.background_type ||
          BACKGROUND_TYPE_BLUR,
        primary_color:
          theme.primary_color && theme.primary_color.replace("#", ""),
        // Faking theme name for now until school can manage multiple themes
        name:
          this.theme.name ||
          `${this.school.name} - ${(_.random(1, 1000) + "0000").slice(0, 4)}`
      }
    )

    if (theme.id) {
      return API.put(`theme/${theme.id}/`, theme).then(
        this.action.updateSchoolMedia
      )
    } else {
      return API.post(`theme/`, theme).then(this.action.updateSchoolMedia)
    }
  },
  loadIntegrations() {
    return API.get("manage/integrations/")
  },
  saveIntegrations(integrations) {
    // prevent clearing fb ids
    integrations = _.assign({}, this.integrations, integrations)
    return API.put("manage/integrations/", integrations)
  },
  loadCommerce() {
    return API.get("manage/commerce/")
  },
  disconnectStripe() {
    return API.post("manage/commerce/disconnect-stripe/")
  },
  loadSnippets() {
    return API.get("manage/snippets/")
  },
  saveSnippets(snippets) {
    return API.put("manage/snippets/", snippets)
  },
  loadCurriculumSubscriberInvoices() {
    this.invoices_loading = true
    return API.legacy
      .get(
        `school-licensing/api/v1/school/subscription/${this.subscription.id}/invoices/`
      )
      .then(
        action((response) => {
          this.invoices = response.map((invoice) => ({
            // massaging invoice data
            id: invoice.id,
            total_amount_billed: invoice.amount_due,
            invoiced_date: invoice.created
          }))
        })
      )
      .catch(
        action((err) => {
          this.invoices_error = err
          Promise.reject(err)
        })
      )
      .then(
        action(() => {
          this.invoices_loading = false
        })
      )
  },
  savePayment(payment_token) {
    // defaults: ->
    //   id: "foo"
    //   card_last_4: "????"
    if (this.paid_curriculum_subscription) {
      return API.legacy.put(
        `school-licensing/api/v1/school/${this.subscription.licensor.id}/subscription/payment_details/`,
        { payment_token }
      )
    } else {
      return API.post("billing/subscription/payment/", { payment_token })
    }
  },
  updateSchoolMedia(theme) {
    const schoolStore = window.App.getStore("school")
    const media = schoolStore.get("media")
    const updatedTheme = {
      ...media.theme,
      ...theme
    }

    this.theme = updatedTheme

    schoolStore._mutate({
      media: {
        ...media,
        theme: updatedTheme
      },
      background: {
        image: theme.background_image,
        overlay: theme.background_overlay
      },
      listen_hack: !schoolStore.getState().listen_hack
    })
    return theme
  },
  updateSchoolDetails(details) {
    const schoolStore = window.App.getStore("school")
    const school = schoolStore.get("school")
    const media = schoolStore.get("media")
    school.set({ name: details.name, subdomain: details.subdomain })
    schoolStore._mutate({
      school,
      media: {
        ...media,
        logo: details.logo_image,
        image: details.image
      },
      listen_hack: !schoolStore.getState().listen_hack
    })
    this.action.updateSchoolMedia(this.theme)
    return details
  },
  // TODO: implement me
  // deleteSubscription(subscription) {
  //   const params = {
  //     id: subscription.id,
  //     plan_id: subscription.plan.id,
  //   }
  //   return API.delete(`billing/plan/${subscription.plan.id}/subscription/`, params)
  // }
  clearErrors() {
    this.details_error = null
    this.support_error = null
    this.theme_error = null
    this.integrations_error = null
    this.commerce_error = null
    this.snippets_error = null
    this.invoices_error = null
    this.next_invoice_error = null
    this.payment_error = null
  }
}

export default createStore(state, actions)
