import ConnectStores from "./components/ConnectStores"

const connect = function (Component, store, mapStateToProps, watch) {
  // Shortcut for connecting a single store to a component. Defaults
  // to no watch events unless specified
  if (watch == null) {
    watch = false
  }
  const stores = [{ store, watch }]
  return ConnectStores(Component, stores, {}, mapStateToProps)
}

export default connect
