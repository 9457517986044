export const INVITE_METHOD_EMAIL = 1
export const INVITE_METHOD_LINK = 2

// TODO: move to member/constants?
export const MEMBERSHIP_TYPE_STUDENT = 0
export const MEMBERSHIP_TYPE_FACILITATOR = 10
export const MEMBERSHIP_TYPE_EDITOR = 20
export const MEMBERSHIP_TYPE_ADMIN = 30

// TODO: move to enroll/constants?
export const ENROLLMENT_ROLE_OBSERVER = -1
export const ENROLLMENT_ROLE_STUDENT = 5
export const ENROLLMENT_ROLE_FACILITATOR = 10
export const ENROLLMENT_ROLE_TEACHER = 15
export const ENROLLMENT_ROLE_EDITOR = 20

export const INVITATION_TYPE_OFFERING = 1
export const INVITATION_TYPE_SCHOOL = 2

export const INVITATION_BATCH_ERROR_USER_EXISTS = 1
export const INVITATION_BATCH_ERROR_INVALID_EMAIL = 2
export const INVITATION_BATCH_ERROR_INVALID_ROLE = 3

export const INVITATION_STATUS_ALL = "all"
export const INVITATION_STATUS_FAILED = "failed"
export const INVITATION_STATUS_PENDING = "pending"
export const INVITATION_STATUS_ACCEPTED = "accepted"

export const EMAIL_DELIVERY_STATUS_SENT = "sent"
export const EMAIL_DELIVERY_STATUS_REJECTED = "rejected"
export const EMAIL_DELIVERY_STATUS_DEFERRED = "deferred"
export const EMAIL_DELIVERY_STATUS_BOUNCED = "bounced"
export const EMAIL_DELIVERY_STATUS_OPENED = "opened"
export const EMAIL_DELIVERY_STATUS_CLICKED = "clicked"
export const EMAIL_DELIVERY_STATUS_COMPLAINED = "complained"
export const EMAIL_DELIVERY_STATUS_UNSUBSCRIBED = "unsubscribed"

export const INVITED_SCHOOL_MEMBER = "invited-school-member"
export const INVITED_SCHOOL_ADMIN = "invited-school-admin"
export const INVITED_GROUP_LEARNER = "invited-group-learner"
export const INVITED_GROUP_MODERATOR = "invited-group-moderator"
export const INVITED_GROUP_TEACHER = "invited-group-teacher"
export const INVITED_RESOURCE_EDITOR = "invited-resource-editor"

export const INTERCOM_INVITE_EVENTS = {
  [INVITATION_TYPE_OFFERING]: {
    [ENROLLMENT_ROLE_STUDENT]: INVITED_GROUP_LEARNER,
    [ENROLLMENT_ROLE_FACILITATOR]: INVITED_GROUP_MODERATOR,
    [ENROLLMENT_ROLE_TEACHER]: INVITED_GROUP_TEACHER,
    [ENROLLMENT_ROLE_EDITOR]: INVITED_RESOURCE_EDITOR
  },
  [INVITATION_TYPE_SCHOOL]: {
    [MEMBERSHIP_TYPE_STUDENT]: INVITED_SCHOOL_MEMBER,
    [MEMBERSHIP_TYPE_ADMIN]: INVITED_SCHOOL_ADMIN
  }
}

export const INVITATION_STATES = [
  INVITATION_STATUS_ALL,
  INVITATION_STATUS_FAILED,
  INVITATION_STATUS_PENDING,
  INVITATION_STATUS_ACCEPTED
]

export const INVITATION_ERROR_MESSAGES = {
  [INVITATION_BATCH_ERROR_USER_EXISTS]:
    "A member with this email already exists",
  [INVITATION_BATCH_ERROR_INVALID_EMAIL]: "Not a valid email address",
  [INVITATION_BATCH_ERROR_INVALID_ROLE]: "Not a valid role"
}
