import gql from "graphql-tag"
import USER_FRAGMENT from "../../user/graphql/user-fragment"

export default gql`
  fragment Registration on Registration {
    id
    role
    completion_state
    send_due_notifications
    send_discussion_notifications
    last_activity_time
    expiration_dtime
    created
    user {
      ...User
    }
  }
  ${USER_FRAGMENT}
`
