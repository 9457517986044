export default {
  type: "Title",
  helpLink:
    "https://help.pathwright.com/create-a-course/adding-content-with-blocks/title-blocks-new",
  menu: {
    category: "title",
    order: 1
  },
  layouts: {
    title: {
      key: "title",
      label: "Title",
      data: {
        title: "Large title"
      }
    },
    titleImage: {
      key: "titleImage",
      label: "Title with image",
      data: {
        title: "Title with image",
        image: "https://cdn.filestackcontent.com/fEaAvEy8RKqiJTH2Lg7j"
      }
    }
  },
  schema: `
    type Title implements Block {
        id: ID!
        title: String!
        image: String @media
    }
  `
}
