import Alert from "@pathwright/ui/src/components/alert/Alert"
import Card from "@pathwright/ui/src/components/card/Card"
import CardBlock from "@pathwright/ui/src/components/card/CardBlock"
import CardLink from "@pathwright/ui/src/components/card/CardLink"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import PropTypes from "prop-types"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import FeatureBlockItem from "../block/FeatureBlockItem"
import { CONTEXTUAL_FEATURE_PERMISSION_QUERY } from "../graphql"
import { getFeatureInfo } from "../utils"

const ManageFeature = ({ card, ...variables }) => {
  const { t } = useTranslate()
  const { school } = usePathwrightContext()

  const cfpQuery = useQuery(CONTEXTUAL_FEATURE_PERMISSION_QUERY, {
    variables
  })

  const feature = cfpQuery.data && cfpQuery.data.contextualFeaturePermission

  return (
    <Card card={card} emphasis="secondary">
      {cfpQuery.loading ? (
        <LoadingCircle center />
      ) : cfpQuery.error ? (
        <Alert error={cfpQuery.error} />
      ) : (
        <CardBlock paddingTop={false} paddingBottom={false}>
          <FeatureBlockItem feature={feature} />
          <CardLink
            icon="eye"
            label={t("Adjust visibility for all cohorts")}
            meta={t(
              "Configure who can see the {{ feature }} by default for all cohorts in {{ school }}",
              {
                feature: getFeatureInfo(feature.feature_key, t).title,
                school: school.name
              }
            )}
            to={`${window.location.pathname}settings/`}
          />
        </CardBlock>
      )}
    </Card>
  )
}

ManageFeature.displayName = "ManageFeature"

ManageFeature.propTypes = {
  // provided by CardStack > CardStackCard
  card: PropTypes.object.isRequired,
  ...CONTEXTUAL_FEATURE_PERMISSION_QUERY._propTypes
}

export default ManageFeature
