import Button from "@pathwright/ui/src/components/button/Button"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import View from "@pathwright/ui/src/components/ui/View"
import PropTypes from "prop-types"
import BrowseBannerContainer from "./banner/BrowseBannerContainer"
import BrowseGridContainer from "./grid/BrowseGridContainer"
import BrowseFacetsContainer from "./query/BrowseFacetsContainer"
import BrowseResultContainer from "./result/BrowseResultContainer"

function Browse({
  resourcesQueryOptions,
  activeFacets,
  schoolId,
  structure,
  children,
  browseTo,
  onChangeFacets,
  hideBannerAction
}) {
  if (activeFacets) {
    return (
      <View.Secondary roundedTop style={{ maxWidth: "100vw" }}>
        <BrowseBannerContainer
          schoolId={schoolId}
          hideBannerAction={hideBannerAction}
        />
        <Button
          styleType="blank"
          style={{ margin: 5 }}
          onClick={() => onChangeFacets(null)}
        >
          <Pathicon icon="arrow-left" /> Back to Browse
        </Button>
        <BrowseFacetsContainer
          schoolId={schoolId}
          activeFacets={activeFacets}
          onChangeFacets={onChangeFacets}
        />
        <BrowseResultContainer
          browseTo={browseTo}
          onChangeFacets={onChangeFacets}
          resourcesQueryOptions={{
            ...resourcesQueryOptions,
            ...activeFacets
          }}
        />
      </View.Secondary>
    )
  }
  return (
    <View.Secondary roundedTop style={{ maxWidth: "100vw" }}>
      <BrowseBannerContainer
        schoolId={schoolId}
        hideBannerAction={hideBannerAction}
      />
      <BrowseFacetsContainer
        schoolId={schoolId}
        activeFacets={activeFacets}
        onChangeFacets={onChangeFacets}
      />
      {structure.map((item, index) => (
        <BrowseGridContainer
          key={index}
          browseTo={browseTo}
          heading={item.heading}
          onChangeFacets={onChangeFacets}
          resourcesQueryOptions={{
            ...resourcesQueryOptions,
            ...item.queryOptions
          }}
        />
      ))}
      {children}
    </View.Secondary>
  )
}

Browse.displayName = "Browse"

Browse.propTypes = {
  // callback for selecting resource
  browseTo: PropTypes.func
}

export default Browse
