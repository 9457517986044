import { getConstMap } from "@pathwright/ui/src/components/utils/constants"

export const ENROLLMENT_ROLE_OBSERVER = -1
export const ENROLLMENT_ROLE_STUDENT = 5
export const ENROLLMENT_ROLE_FACILITATOR = 10
export const ENROLLMENT_ROLE_TEACHER = 15
export const ENROLLMENT_ROLE_EDITOR = 20

export const ENROLLMENT_ROLE_SCHEMA = {
  [ENROLLMENT_ROLE_OBSERVER]: "observer",
  [ENROLLMENT_ROLE_STUDENT]: "student",
  [ENROLLMENT_ROLE_FACILITATOR]: "moderator",
  [ENROLLMENT_ROLE_TEACHER]: "teacher",
  [ENROLLMENT_ROLE_EDITOR]: "editor"
}

export const ENROLLMENT_COMPLETION_STATE_UNSTARTED = 1
export const ENROLLMENT_COMPLETION_STATE_IN_PROGRESS = 2
export const ENROLLMENT_COMPLETION_STATE_COMPLETED = 3

export const REGISTRATION_ROLES = getConstMap({
  OBSERVER: [-1, "observer"],
  LEARNER: [5, "student"],
  MODERATOR: [10, "moderator"],
  TEACHER: [15, "teacher"],
  COURSE_EDITOR: [20, "editor"]
})

// NOTE: completion states do not actually have string values ATM
export const REGISTRATION_COMPLETION_STATES = getConstMap({
  UNSTARTED: [1, "unstarted"],
  IN_PROGRESS: [2, "in_progress"],
  COMPLETED: [3, "completed"]
})
