import IconButton from "@pathwright/ui/src/components/button/IconButton"
import { useFullscreenConfirmContext } from "@pathwright/ui/src/components/confirm/FullscreenConfirm"
import DropdownItem from "@pathwright/ui/src/components/dropdown/DropdownItem"
import DropdownMenu from "@pathwright/ui/src/components/dropdown/DropdownMenu"
import DropdownTrigger from "@pathwright/ui/src/components/dropdown/DropdownTrigger"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import PropTypes from "prop-types"
import { usePathwrightContext } from "../../../pathwright/PathwrightContext"
import { getToGroupManageMember } from "../../utils"
import { useMentorGroupListContext } from "./MentorGroupListContext"
import MentorGroupMembershipSettingsCard, {
  getCanUpdateCanReview,
  getRoleUpdateData
} from "./MentorGroupMembershipSettingsCard"

export const MentorGroupMemberControlsMenu = ({ membership }) => {
  const { t, tc, Trans } = useTranslate()
  const pwContext = usePathwrightContext()
  const { handleConfirm } = useFullscreenConfirmContext()

  const { permissions, mentorGroup, groupSubscription, deleteMembership } =
    useMentorGroupListContext()

  const roleData = getRoleUpdateData(pwContext, membership, permissions)
  const canUpdatedCanReview = getCanUpdateCanReview(
    pwContext,
    membership,
    permissions
  )
  // User could remove member's access from Group Subscription.
  const canModifyMembership =
    // User can modify their personal settings (mentor group digest email)
    membership.user.id === pwContext.me.id ||
    // User can modify membership role.
    !!roleData.length ||
    // User can modify `can_review` perm
    canUpdatedCanReview ||
    // User can probably remove access.
    mentorGroup.type === 15

  // We don't want to permit the current user to remove themselves form a Group Subscription.
  const canRemoveMembership =
    // User can modify membership role.
    !!roleData.length &&
    // User isn't removing themselves from a Group Subscription.
    (membership.user.id !== pwContext.me.id || mentorGroup.type !== 15)

  // function handleLostAccessConfirm() {
  //   if (
  //     membership.user.id === pwContext.me.id &&
  //     (membership.role <= 5 || membership.access_revoked_time)
  //   ) {
  //     handleConfirm({
  //       icon: "group-lock",
  //       heading: "Access removed.",
  //       body: `You no longer have staff access to "${mentorGroup.name}"`,
  //       confirmPrompt: t("Go Home"),
  //       confirmTo: "/home/",
  //       hideCancel: true,
  //       callback: () => handleConfirm(null)
  //     })
  //   } else {
  //     handleConfirm(null)
  //   }
  // }

  return (
    <DropdownMenu>
      <DropdownItem
        label={membership.user.email}
        icon="envelope"
        href={`mailto:${membership.user.email}`}
        closeOnClick
      />
      {canModifyMembership && (
        <DropdownItem
          icon="gear"
          label="Configure membership"
          closeOnClick
          to={{
            pathname: getToGroupManageMember(mentorGroup.id, membership.id),
            card: {
              component: MentorGroupMembershipSettingsCard,
              props: {
                groupId: mentorGroup.id,
                membershipId: membership.id
              }
            }
          }}
        />
      )}
      {canRemoveMembership && (
        <DropdownItem
          icon="minus-circle"
          label={tc(`Remove`)}
          closeOnClick
          onClick={() => {
            if (groupSubscription) {
              handleConfirm({
                icon: "caution-triangle",
                heading: t("mentor_group.member.confirm.remove.heading", {
                  user: membership.user.full_name,
                  group: mentorGroup.name
                }),
                body: (
                  <Trans
                    i18nKey="mentor_group.member.group_subscription.confirm.remove.body"
                    defaults="Removing {{user}} will <strong>disable access to {{subscription}}</strong>. Additionally, {{user}}'s progress, completions, and activity will on longer be visible to Group Admins and Mentors of {{group}}."
                    values={{
                      user: membership.user.first_name,
                      subscription: groupSubscription.price.product.name,
                      group: mentorGroup.name
                    }}
                  />
                ),
                confirmPrompt: t(
                  "mentor_group.member.group_subscription.confirm.remove.confirm_prompt",
                  {
                    user: membership.user.first_name
                  }
                ),
                callback: () =>
                  deleteMembership(membership).then(() => handleConfirm(null))
              })
            } else {
              handleConfirm({
                heading: t("mentor_group.member.confirm.remove.heading", {
                  user: membership.user.full_name,
                  group: mentorGroup.name
                }),
                body: t("mentor_group.member.confirm.remove.body", {
                  user: membership.user.first_name,
                  group: mentorGroup.name
                }),
                confirmPrompt: t(
                  "mentor_group.member.confirm.remove.confirm_prompt"
                ),
                callback: () =>
                  deleteMembership(membership).then(() => handleConfirm(null))
              })
            }
          }}
        />
      )}
    </DropdownMenu>
  )
}

const MentorGroupMemberControls = (props) => {
  return (
    <DropdownTrigger
      renderDropdown={() => (
        <MentorGroupMemberControlsMenu closeOnClick {...props} />
      )}
    >
      <IconButton icon="ellipses-horizontal" styleType="secondary" />
    </DropdownTrigger>
  )
}

MentorGroupMemberControls.propTypes = {
  membership: PropTypes.object.isRequired
}

export default MentorGroupMemberControls
