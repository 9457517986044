import { Flex } from "@chakra-ui/react"
import BlockContentContainer from "@pathwright/blocks/src/main/BlockContentContainer"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import PropTypes from "prop-types"
import { Suspense } from "react"
import { getGraphQLEndpoint } from "../pathwright/PathwrightClient"
import { usePathwrightContext } from "../pathwright/PathwrightContext"

const BlockContent = ({ showLoading, ...props }) => {
  const context = usePathwrightContext()
  const graphQLEndpoint = `${getGraphQLEndpoint()}blocks/graphql?school_id=${
    context.school.id
  }`
  const authToken = localStorage.auth_token

  return (
    <Suspense fallback={showLoading ? <LoadingCircle /> : null}>
      <BlockContentContainer
        graphQLEndpoint={graphQLEndpoint}
        authToken={authToken}
        renderLoading={() => (
          <Flex minH="300px">
            <LoadingCircle />
          </Flex>
        )}
        {...props}
      />
    </Suspense>
  )
}

BlockContent.propTypes = {
  showLoading: PropTypes.bool
}

BlockContent.defaults = {
  showLoading: true
}

export default BlockContent
