import { ChakraProvider, extendTheme } from "@chakra-ui/react"
import { usePathwrightContext } from "../pathwright/PathwrightContext"
import { chakraThemePW } from "./chakraTheme"
import platformChakraTheme from "./platformChakraTheme.json"

const getPrimaryPalette = (colorPalette) => {
  let palette = { ...colorPalette }
  delete palette.__typename
  // Remove the preceeding "_" from keys.
  palette = Object.entries(palette).reduce(
    (acc, [key, value]) =>
      Object.assign(acc, {
        [key.slice(1)]: value
      }),
    {}
  )

  return palette
}

const getColors = (school) => (theme) => {
  let brand = theme.colors.blue

  const colorPalette = school.theme ? school.theme.color_palette : null

  if (colorPalette) {
    brand = getPrimaryPalette(colorPalette)
  }

  const pathwrightColors = {
    PRIMARY_GRAY: "rgba(42,46,51,1)",
    SECONDARY_GRAY: "rgba(0,0,0,0.5)",
    TERTIARY_GRAY: "rgba(0,0,0,0.4)",
    SHADOW_GRAY: "rgba(0,0,0,0.15)",
    BORDER_GRAY: "rgba(0,0,0,0.1)",
    OVERLAY_GRAY: "rgba(0,0,0,0.05)",
    PRIMARY_WHITE: "rgba(255,255,255,1)",
    SECONDARY_WHITE: "rgba(255,255,255,0.8)",
    TERTIARY_WHITE: "rgba(255,255,255,0.6)",
    BORDER_WHITE: "rgba(255,255,255,0.3)",
    OVERLAY_WHITE: "rgba(255,255,255,0.1)",
    PRIMARY_WHITE_BACKGROUND: "rgba(255,255,255,1)",
    SECONDARY_WHITE_BACKGROUND: "rgba(245,245,245,1)",
    TERTIARY_WHITE_BACKGROUND: "rgba(234,234,234,1)",
    FOCUS_BLUE: "rgba(106,159,203,1)",
    EDITED_ORANGE: "rgb(206 150 101);",
    ALERT_YELLOW: "rgba(255,252,230,1)",
    WARNING_ORANGE: "rgba(255,133,27,1)",
    DANGEROUS_RED: "rgba(255,0,0,1)",
    SUCCESS_GREEN: "rgba(34,139,34,1)"
  }

  return {
    colors: {
      brand,
      ...pathwrightColors
    }
  }
}

const getPrimaryBrandColor = (theme) => ({
  colors: {
    primaryBrandColor: {
      main: theme.colors.brand[500]
    }
  }
})

export const getChakraTheme = (school) => {
  if (!school) return chakraThemePW

  let theme = {
    ...platformChakraTheme
  }

  if (school.theme) {
    if (school.theme.heading_font) {
      theme.fonts = {
        theme: {
          heading: `var(--school-heading-font), "ProximaNova-Regular", Helvetica, sans-serif`
        }
      }
    }
  }

  // Increasing Chakra's zIndices by our base fullscreen zIndex value
  // so that Chakra components will actually layer on top of ours.
  const baseZIndex = 999999999

  theme.zIndices = {
    hide: -1,
    auto: "auto",
    base: baseZIndex + 0,
    docked: baseZIndex + 10,
    dropdown: baseZIndex + 1000,
    sticky: baseZIndex + 1100,
    banner: baseZIndex + 1200,
    overlay: baseZIndex + 1300,
    modal: baseZIndex + 1400,
    popover: baseZIndex + 1500,
    skipLink: baseZIndex + 1600,
    toast: baseZIndex + 1700,
    tooltip: baseZIndex + 1800
  }

  theme = extendTheme(getColors(school), theme)
  // Now extend with brand color now that defaults have been set.
  return extendTheme(theme, getPrimaryBrandColor(theme))
}

const ChakraThemeProvider = ({
  children,
  usePathwrightTheme = false,
  resetCSS = false
}) => {
  const { school } = usePathwrightContext()
  const theme = getChakraTheme(usePathwrightTheme ? null : school)

  return (
    <>
      <ChakraProvider theme={theme} resetCSS={resetCSS}>
        {children}
      </ChakraProvider>
    </>
  )
}

export default ChakraThemeProvider
