import Card from "@pathwright/ui/src/components/card/Card"
import CardLink from "@pathwright/ui/src/components/card/CardLink"
import PropTypes from "prop-types"
import SchoolIcon from "../../lib/SchoolIcon"
import SchoolLogo from "../../lib/SchoolLogo"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"

const ManageAccountIcon = ({ card, logo }) => {
  const { school } = usePathwrightContext()
  return (
    <Card card={card} title={`${school.name} Icon`}>
      <CardLink
        label="Customize your account icon"
        meta="A square icon that represents your account (like an app icon)"
        preview={<SchoolIcon size={40} />}
        to={`${card.path}custom-icon/`}
      />
      <CardLink
        label={`${logo ? "Change" : "Add"} your logo`}
        meta="Optionally add an image-based logo to your main menu bar"
        preview={<SchoolLogo size={40} background />}
        to={`${card.path}logo/`}
      />
    </Card>
  )
}

ManageAccountIcon.displayName = "ManageAccountIcon"

ManageAccountIcon.propTypes = {
  // provided by CardStack > CardStackCard
  card: PropTypes.object.isRequired
}

export default ManageAccountIcon
