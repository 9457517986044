import { useUIThemeContext } from "@pathwright/ui/src/components/ui/PathwrightUI"
import { media } from "@pathwright/ui/src/components/utils/styles"
import { usePathwrightContext } from "@pathwright/web/src/modules/pathwright/PathwrightContext"
import styled from "styled-components"

import Link from "@pathwright/ui/src/components/link/Link"

const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledContainer = styled.div`
  position: relative;
  overflow: hidden;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  ${media.max.desktop`width: 250px;`}
  ${media.max.tablet`width: 200px;`}
  ${(props) => (!props.forceShow ? `${media.max.phone`display: none;`}` : "")}
`

const StyledLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const StyledText = styled.span`
  color: white;
  max-height: 40px;
  overflow: hidden;
  line-height: 20px;
`

const SchoolIdentity = ({ height, width, to, className, forceShow }) => {
  const { school } = usePathwrightContext()
  const { logo } = useUIThemeContext()
  const backgroundImage = logo ? `url(${logo})` : undefined

  return (
    <StyledWrapper>
      <StyledContainer
        className={className}
        style={{ width, height, backgroundImage }}
        forceShow={forceShow}
      >
        {to && <StyledLink to={to} />}
        {!logo && (
          <StyledText
          // className="theme-heading"
          >
            {school.name}
          </StyledText>
        )}
      </StyledContainer>
    </StyledWrapper>
  )
}

SchoolIdentity.displayName = "SchoolIdentity"

// SchoolIdentity.propTypes = {
//   to: PropTypes.string,
//   width: PropTypes.number,
//   height: PropTypes.number,
//   forceShow: PropTypes.bool
// }

SchoolIdentity.defaultProps = {
  height: 40,
  width: 300,
  forceShow: false
}

export default SchoolIdentity
