export const PAGE_TYPE_GENERIC = 0
export const PAGE_TYPE_TERMS = 5
export const PAGE_TYPE_HOME = 10
export const PAGE_TYPES = {
  [PAGE_TYPE_GENERIC]: "Generic",
  [PAGE_TYPE_HOME]: "Home",
  [PAGE_TYPE_TERMS]: "Privacy Policy"
}

export const PAGE_MODE_MODAL = "modal"
export const PAGE_MODE_OPEN = "open"
export const PAGE_MODE_CONTAINED = "contained"
export const PAGE_MODE_BLANK = "blank"
export const PAGE_MODE_MAP = {
  [PAGE_MODE_MODAL]: {
    label: "Modal",
    description: "Opens the page in a modal."
  },
  [PAGE_MODE_OPEN]: {
    label: "Open",
    description: "Opens the page with navigation UI, full screen."
  },
  [PAGE_MODE_CONTAINED]: {
    label: "Contained",
    description:
      "Opens the page with navigation UI, contained within school theme."
  },
  [PAGE_MODE_BLANK]: {
    label: "Blank",
    description: "Opens the page without navigation UI or scripts."
  }
}

export const PAGE_CONTENT_TYPE_LINK = 1
export const PAGE_CONTENT_TYPE_BLOCKS = 2
export const PAGE_CONTENT_TYPE_TEMPLATE = 3
export const PAGE_CONTENT_TYPES = {
  [PAGE_CONTENT_TYPE_LINK]: "Link",
  [PAGE_CONTENT_TYPE_BLOCKS]: "Blocks",
  [PAGE_CONTENT_TYPE_TEMPLATE]: "HTML"
}
