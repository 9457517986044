import { i18n } from "@pathwright/ui/src/components/lng/I18nextProvider"
import flow from "lodash/flow"

const capitalize = (str) =>
  str ? `${str[0].toUpperCase()}${str.slice(1)}` : ""

const capitalizeAllWords = (str) =>
  str ? str.split(" ").map(capitalize).join(" ") : str

// format a translation based on the format option provided to the
// translate function
export const addTranslationFormatters = (i18next) => {
  i18next.services.formatter.add("lowercase", (value, lng, options) => {
    return value.toLowerCase()
  })

  i18next.services.formatter.add("uppercase", (value, lng, options) => {
    return value.toUpperCase()
  })

  i18next.services.formatter.add("capitalize", (value, lng, options) => {
    return capitalize(value)
  })
}

// These keys correspod directly to keys used in the translation json files. Update with care!
export const getTranslateMethods = (translator) => ({
  t: translator,
  tc: flow([translator, capitalize]),
  tca: flow([translator, capitalizeAllWords]),
  conditionalT: (key, options, customTranslator) =>
    i18n.exists(key) ? (customTranslator || translator)(key, options) : ""
})

/**
 * translateWrapper enables interpolating translation keys within translation values, i.e.:
 *
 * "cohort": {
 *   "label": "cohort",
 *   "new": "New t('cohort.label')"
 * }
 */

// DEPRECATED: using a preprocessor to transform these custom translate methods to proper i18next nested keys.
// export const customTranslate = translator => {
//   const translateMethods = getTranslateMethods(translator)
//   const translateKeys = Object.keys(translateMethods)
//   // group the (translator key) and (translation key)
//   const re = new RegExp(
//     `(${translateKeys.join("|")})\\('([a-zA-Z0-9._-]+)'\\)`,
//     "g"
//   )

//   return (translationStr, ...rest) => {
//     try {
//       return translator(translationStr, ...rest).replace(
//         re,
//         (matchedSubstr, translatorKey, translationKey) =>
//           translateMethods[translatorKey](translationKey)
//       )
//     } catch (error) {
//       console.error(`Failed to translate "${translationStr}": ${error}`)
//     }
//   }
// }

// Handles processing legacy custom translator methods to proper i18next nested keys.
export const preProcessTranslation = (() => {
  // group the (translator key) and (translation key)
  const re = new RegExp(
    `(?<translationMethod>t|tc|tca)\\('(?<translationKey>[a-zA-Z0-9._-]+)'\\)`,
    "g"
  )

  const process = (...args) => {
    // Get grouped match
    const { translationMethod, translationKey } = args.slice(-1)[0]
    switch (translationMethod) {
      case "t":
        return `$t(${translationKey})`
      case "tc":
        return `$t(${translationKey}, capitalize)`
      case "tca":
        return `$t(${translationKey}, capitalize_all)`
    }
  }

  const fn = (translationJSON) => {
    if (!translationJSON) return {}

    return Object.entries(translationJSON).reduce((json, [key, value]) => {
      // if value is a string, record the path/value
      if (typeof value === "string") {
        return {
          ...json,
          [key]: value.replace(re, process)
        }
      }

      // otherwise, continue to flatten the json
      return {
        ...json,
        [key]: fn(value)
      }
    }, {})
  }

  return fn
})()

// For local testing
export const getTranslation = async (language, namespace) => {
  if (!language) {
    return null
  }

  const translation = await import(
    `@pathwright/ui/src/components/lng/translations/${language}/${namespace}.json`
    /* webpackChunkName: "translation-json" */
  )

  return {
    translation,
    language,
    id: "foo"
  }
}
