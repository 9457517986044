import { createContext, useContext } from "react"

import { _useReviewContext } from "./useReviewContext"

const ReviewContext = createContext({})

export const ReviewContextProvider = ({ context, children }) => {
  let value = _useReviewContext(context, context.items)
  return (
    <ReviewContext.Provider value={value}>{children}</ReviewContext.Provider>
  )
}

export const useReviewContext = () => {
  // TODO: create a new context if context is provided?
  return useContext(ReviewContext)
}
