import Button from "../button/Button"
import { useTranslate } from "../lng/withTranslate"
import Text from "../ui/Text"

const InvalidVariable = ({ variable }) => {
  const { t } = useTranslate()

  return (
    <Text.Body color="whitesmoke">
      <div>
        <strong style={{ whiteSpace: "nowrap" }}>
          {t("Invalid variable")}:
        </strong>
      </div>
      {t("this variable isn’t supported or may be spelled incorrectly.")}{" "}
      <Button
        styleType="text"
        brand
        href="https://help.pathwright.com/en/articles/4428159-use-variables-in-custom-reminders"
        target="_blank"
        color="whitesmoke"
      >
        {t("Here’s a list of supported variables.")}
      </Button>
    </Text.Body>
  )
}

InvalidVariable.displayName = "InvalidVariable"

export default InvalidVariable
