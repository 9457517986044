import { BELLTOWER_SCHOOL_ID } from "@pathwright/web/src/modules/store/constants"

export const canGoHome = () => {
  const { school, permissions } = window.App.getStore("school").getState()
  const { is_authenticated } = window.App.getStore("auth").getState()
  return (
    is_authenticated &&
    (school.id != BELLTOWER_SCHOOL_ID || permissions.user_is_school_admin)
  )
}
