import { Box } from "@chakra-ui/react"
import { useShareDataContext } from "./ShareDataContextProvider"

// Concept for customizable components.
const Container = (props) => <Box {...props} />

Container._props = {
  height: "100%",
  bg: "gray.50",
  borderRadius: "var(--card-border-radius-sm)",
  position: "relative",
  overflow: "hidden"
}

// TODO: don't render children, rather use as a sibling.
const AtmosphericImage = ({ children, components, componentStyles }) => {
  const {
    meta: { image }
  } = useShareDataContext()
  const ContainerComponent = components?.Container || Container

  return (
    <ContainerComponent {...Container._props}>
      <Box
        filter="auto"
        blur="15px"
        position="absolute"
        top={0}
        left={0}
        right={0}
        width="100%"
        height="100%"
        overflow="hidden"
      >
        <Box
          bgImage={`url(${image})`}
          bgSize="cover"
          bgPosition="center bottom"
          bgRepeat="no-repeat"
          width="100%"
          height="100%"
        />
        <Box
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          bg="PRIMARY_WHITE_BACKGROUND"
          opacity="0.8"
        />
      </Box>
      <Box position="relative" h="100%" w="100%">
        {children}
      </Box>
    </ContainerComponent>
  )
}

export default AtmosphericImage
