class MediaBehaviors extends require("lib/static-shim").default(
  Marionette.Behavior
) {
  static initClass() {
    this.prototype.defaults = { stateModelKey: "state" }
  }

  onShow() {
    // Handle bootstrap tooltips
    if (!Pathwright.request("ui:is:touch")) {
      this.$("[data-toggle='tooltip']").tooltip()
    }
    return this.$("[data-toggle='dropdown']").dropdown()
  }
}
MediaBehaviors.initClass()
export default MediaBehaviors
