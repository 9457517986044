import { Button, IconButton, Text, VStack } from "@chakra-ui/react"

const LinkButton = ({ label, icon, onClick }) => (
  <Button
    mx={5}
    onClick={onClick}
    variant="link"
    textDecoration="none" // (except on hover, seems ok?)
    aria-label={label}
    borderRadius="0" // so that tab selection doesn't look weird
  >
    <VStack spacing={2}>
      <IconButton
        as="div"
        variant="solid"
        icon={icon}
        color="gray.500"
        bgColor="white"
        _hover={{
          bgColor: "rgba(255, 255, 255, .8)"
        }}
        borderRadius="50%"
        p={4}
      />
      <Text size="xs" color="gray.500">
        {label}
      </Text>
    </VStack>
  </Button>
)

export default LinkButton
