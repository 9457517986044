import FieldFooter from "./FieldFooter"
import FieldHeader from "./FieldHeader"
import "./Fieldset.css"

const Fieldset = ({ title, inverted, header, footer, children, style }) => (
  <div className="Fieldset" style={style}>
    {(header || title) && (
      <FieldHeader title={title} inverted={inverted}>
        {header}
      </FieldHeader>
    )}
    {children}
    {footer && <FieldFooter inverted={inverted}>{footer}</FieldFooter>}
  </div>
)

Fieldset.displayName = "Fieldset"

export default Fieldset
