import classnames from "classnames"
import PropTypes from "prop-types"
import { useEffect, useRef, useState } from "react"
import ReactDOM from "react-dom"
import styled from "styled-components"
import SubmitHandler, { SUBMIT_STATUS } from "./SubmitHandler"

/**
 * Use in conjunction with SubmitHandler. Handles submit UI state for children.
 */

const StyledContainer = styled.div`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
`

const SubmitSizer = ({
  className,
  children,
  status,
  error,
  result,
  handleSubmit,
  render
}) => {
  const sizerRef = useRef(null)
  const [defaultRectValue, setDefaultRectValue] = useState(null)
  // delayed status gives height and width settings a chance to "stick" before being immediately changed
  const [delayedStatus, setDelayedStatus] = useState(status)

  useEffect(() => {
    if (status === SUBMIT_STATUS.LOADING) {
      const node = ReactDOM.findDOMNode(sizerRef.current)
      const rect = node.getBoundingClientRect()
      // NOTE: a DOMRect does have a toJSON method but this seems
      // to not be present in jsdom for testing.
      setDefaultRectValue({
        width: rect.width,
        height: rect.height
      })
    } else if (status === SUBMIT_STATUS.DEFAULT) {
      setDefaultRectValue(null)
    }
  }, [status])

  useEffect(() => {
    // Allow for change in state to take effect (getting default rect and updating children width & height styles)
    setTimeout(() => setDelayedStatus(status), 1)
  }, [status])

  let style = {}

  if (defaultRectValue) {
    const { width, height } = defaultRectValue
    style = {
      height: `${height}px`,
      width:
        delayedStatus === SUBMIT_STATUS.LOADING ? `${height}px` : `${width}px`
    }
  }
  // Makes for ugly sized LoadingCircle =/
  // const size = height

  const sizer = (
    <StyledContainer
      className={classnames("SubmitSizer", `SubmitSizer--${status}`, className)}
    >
      {children({
        sizerRef,
        status: delayedStatus,
        style,
        // size,
        error,
        result,
        handleSubmit
      })}
    </StyledContainer>
  )

  return render ? render(sizer, { status }) : sizer
}

SubmitSizer.displayName = "SubmitSizer"

const SubmitSizerContainer = ({
  className,
  children,
  duration,
  onClick,
  onSubmit,
  submitting,
  submitSuccess,
  submitFailure,
  onSubmitFailure,
  onSubmitSuccess,
  resetStatus,
  hideSuccess,
  render
}) => (
  <SubmitHandler
    duration={duration}
    onClick={onClick}
    onSubmit={onSubmit}
    submitting={submitting}
    submitSuccess={submitSuccess}
    submitFailure={submitFailure}
    onSubmitFailure={onSubmitFailure}
    onSubmitSuccess={onSubmitSuccess}
    resetStatus={resetStatus}
    hideSuccess={hideSuccess}
  >
    {({ status, error, result, handleSubmit }) => (
      <SubmitSizer
        className={className}
        children={children}
        status={status}
        error={error}
        result={result}
        handleSubmit={handleSubmit}
        render={render}
      />
    )}
  </SubmitHandler>
)

SubmitSizerContainer.displayName = "SubmitSizerContainer"

SubmitSizerContainer.propTypes = {
  children: PropTypes.func.isRequired,
  inverted: PropTypes.bool,
  // Receives status props and sizer component.
  render: PropTypes.func
}

SubmitSizerContainer.defaultProps = {
  inverted: false
}

export default SubmitSizerContainer
