export default class DiscussionVote extends require("lib/static-shim").default(
  require("lib/utils/model").default
) {
  url() {
    let url = this.urlRoot(
      `api/private/discussion/${this.get("discussion_id")}/`
    )
    if (this.get("response_id")) {
      // Use the response vote API
      url = `${url}response/${this.get("response_id")}/vote/`
    } else {
      // Use the discussion vote API
      url = `${url}vote/`
    }

    if (this.id) {
      // Add a query param for context since this might
      // be a DELETE
      url = `${url}?${$.param({ context: this.get("context") })}`
    }

    return url
  }
}
