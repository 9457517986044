export default {
  type: "Divider",
  icon: "text-cursor", // TODO: pathicon text => text-cursor
  helpLink:
    "https://help.pathwright.com/create-a-course/adding-content-with-blocks/divider-blocks-new",
  menu: {
    category: "divider",
    order: 1
  },
  layouts: {
    spacer: {
      key: "spacer",
      label: "Spacer",
      data: {
        type: "SPACER"
      }
    },
    dots: {
      key: "dots",
      label: "Dots",
      data: {
        type: "DOTS"
      }
    },
    rule: {
      key: "rule",
      label: "Line",
      data: {
        type: "RULE"
      }
    }
  },
  schema: `
    enum DividerType {
      RULE
      DOTS
      SPACER
    }

    type Divider implements Block {
      id: ID!
      type: DividerType!
    }
  `
}
