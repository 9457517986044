import pluralize from "pluralize"
import EnterKeyPrompt from "./EnterKeyPrompt"
import InviteLookupResult from "./InviteLookupResult"
import "./InviteLookupResults.css"

const InviteLookupResults = ({
  value,
  results,
  emailCount,
  onSelect,
  focusedEmail
}) => {
  if (
    emailCount < 1 ||
    value.length <= 3 ||
    (results.length < 1 && emailCount < 2)
  ) {
    return null
  }
  const newEmailResult = _.find(results, (result) => !result.user)
  const searchResults = results.filter((result) => result.user)
  return (
    <div className="InviteLookupResults">
      {emailCount > 1 && (
        <div
          className="InviteLookupResults__multiple"
          onClick={() => onSelect(value)}
        >
          <h4>Add {pluralize("emails", emailCount, true)}</h4>
          <EnterKeyPrompt />
        </div>
      )}
      {newEmailResult && (
        <div className="InviteLookupResults__new">
          <InviteLookupResult
            highlight={newEmailResult.value === focusedEmail}
            email={newEmailResult.value}
            onSelect={onSelect}
          />
        </div>
      )}
      {searchResults.length > 0 && (
        <div className="InviteLookupResults__existing">
          <h6 className="InviteLookupResults__heading">
            <span>Invite an existing member</span>
            <span>&nbsp;of {school.name}</span>
          </h6>
          {searchResults.map((hit) => (
            <InviteLookupResult
              onSelect={onSelect}
              highlight={hit.user.email === focusedEmail}
              email={hit.user.email}
              user={hit.user}
              key={hit.user.id}
            />
          ))}
        </div>
      )}
    </div>
  )
}

InviteLookupResults.displayName = "InviteLookupResults"

export default InviteLookupResults
