// import startCase from "lodash/startCase"

// const roleLabels = {
//   noun: {
//     // School roles:
//     school_member: "member",
//     school_staff: "staff",
//     school_library_editor: "library editor",
//     school_admin: "admin",
//     // Group roles:
//     group_member: "member",
//     group_moderator: "mentor",
//     group_admin: "group admin",
//     // Cohort roles:
//     cohort_observer: "observer",
//     cohort_learner: "learner",
//     cohort_moderator: "moderator",
//     cohort_teacher: "teacher",
//     cohort_course_editor: "course editor"
//   },
//   verb: {
//     // TODO...
//     // School roles:
//     school_member: "member",
//     school_staff: "staff",
//     school_library_editor: "library editor",
//     school_admin: "admin",
//     // Group roles:
//     group_member: "member",
//     group_moderator: "mentor",
//     group_admin: "group admin",
//     // ...END TODO.
//     // Cohort roles:
//     cohort_observer: "observe",
//     cohort_learner: "learn",
//     cohort_moderator: "moderate",
//     cohort_teacher: "teach",
//     cohort_course_editor: "edit"
//   },
//   activeVerb: {
//     // TODO...
//     // School roles:
//     school_member: "member",
//     school_staff: "staff",
//     school_library_editor: "library editor",
//     school_admin: "admin",
//     // Group roles:
//     group_member: "member",
//     group_moderator: "mentor",
//     group_admin: "group admin",
//     // ...END TODO.
//     // Cohort roles:
//     cohort_observer: "observing",
//     cohort_learner: "learning",
//     cohort_moderator: "moderating",
//     cohort_teacher: "teaching",
//     cohort_course_editor: "editing"
//   }
// }

export const getRoleLabel = (partOfSpeech, role, t) => {
  // startCase converts moderador(a) to Moderador A
  // instead, pass our "tca" function to capitalize the first letter of each word
  // return startCase(t(`role_labels.${partOfSpeech}.${role}`))
  return t(`role_labels.${partOfSpeech}.${role}`)
}

export const getUserRoleLabel = (item, partOfSpeech, t) => {
  return getRoleLabel(partOfSpeech, item.role, t)
}

export const getOutcomeCounts = (selection, context, outcomeTypes = []) => {
  outcomeTypes = [].concat(outcomeTypes)
  let getOutcomeCount = () => 0

  if (selection) {
    getOutcomeCount = (outcomeType) =>
      selection.filter((item) =>
        item.add_to.reduce((acc, item) => {
          if (
            item.type === context.type &&
            parseInt(item.key) === context.typeId &&
            item.outcome.type === outcomeType
          ) {
            return acc + 1
          }
          return acc
        }, 0)
      ).length
  }

  return outcomeTypes.reduce(
    (acc, outcomeType) => ({
      ...acc,
      [outcomeType]: getOutcomeCount(outcomeType)
    }),
    {}
  )
}

export const getUserItemAlreadyAdded = (item) =>
  item?.type === "user" && !!item?.role_in

export const getIsValidItem = (item) => !getUserItemAlreadyAdded(item)

export const getUserItemAlreadyAddedLabel = (item, t) => {
  const type = item?.role_in?.split("_")[0]

  switch (type) {
    case "cohort":
      return t("share_card.add_tab.Already Registered")
    case "group":
      return t("share_card.add_tab.Already a member")
    default:
      return ""
  }
}
