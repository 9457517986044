import { createContext, useContext, useEffect, useMemo, useState } from "react"
import { getDefaultRole } from "../shared/rolesConfig"
import useAddPeopleTo from "./useAddPeopleTo"
import usePeopleAndGroupConnections from "./usePeopleAndGroupConnections"
import { getIsValidItem } from "./utils"

export const SelectPeopleContext = createContext()

export const useSelectPeopleContext = () => useContext(SelectPeopleContext)

// Simple grouping of item types for filtering items into proper lists.
// Only "user" and "email" types can be selected.
const _selectTypes = ["user", "email"]
// Only "cohort" and "group" types can be queued (from which only "user" items are extracted).
const _queueTypes = ["cohort", "group"]
// Only "user" items can be pending (their correspond to "user" items scoped to a queued "cohort" or "group").
const _pendingTypes = ["user"]

const getUniqueItems = (items) =>
  items.reduce(
    (acc, item) =>
      acc.find((_item) => _item.meta.key === item.meta.key)
        ? acc
        : acc.concat(item),
    []
  )

const filterItem = (items) => (ofItem) =>
  !items.find((item) => item.meta.key === ofItem.meta.key)

const getFilteredItems = (items, ofItems) => ofItems.filter(filterItem(items))

const filterIntersectingItem = (items) => (ofItem) =>
  items.find((item) => item.meta.key === ofItem.meta.key)

const getIntersectingItems = (items, ofItems) =>
  ofItems.filter(filterIntersectingItem(items))

const getNonNullRole = (roles) =>
  Object.entries(roles).reduce(
    (acc, [k, v]) => (v === null ? acc : Object.assign(acc, { [k]: v })),
    {}
  )

const getNulledRoles = (items) =>
  items.reduce(
    (acc, item) =>
      Object.assign(acc, {
        [item.meta.key]: null
      }),
    {}
  )

const getRoles = (items, roleUpdate, rolePermissions) => {
  const defaultRole = getDefaultRole(rolePermissions)

  return items.reduce(
    (acc, item) => {
      // Simple validation of the inital role the "user" item has in the queued item.
      // Attempt to use that initial role, as long as that role exists in the current
      // user's role permissions. Otherwise provide default role applicable to assign target.
      acc[item.meta.key] ||=
        item.role in rolePermissions ? item.role : defaultRole

      return acc
    },
    { ...roleUpdate }
  )
}

// Only keep those roles found in the provided items that for which
// a role is recorded in the roleUpdate.
const getApplicableRoles = (items, roleUpdate) =>
  items.reduce(
    (acc, item) =>
      roleUpdate[item.meta.key]
        ? Object.assign(acc, { [item.meta.key]: roleUpdate[item.meta.key] })
        : acc,
    {}
  )

const getContext = (peopleSelection) => {
  const { queued } = peopleSelection

  if (queued.length) {
    return {
      [`${queued[0].type}_id`]: queued[0].id
    }
  }
  return null
}

const useRolePermissions = (permissions) =>
  useMemo(
    () =>
      // Remove falsey perms.
      Object.entries(permissions.invite_to_roles).reduce((acc, [k, v]) => {
        if (!["__typename", "cohort_viewer"].includes(k) && v) acc[k] = v
        return acc
      }, {}),
    [permissions]
  )

export const SelectPeopleProvider = ({ children, addToKey, permissions }) => {
  const [peopleSelection, setPeopleSelection] = useState({
    // The "user" and "email" items that are ready for adding to the assignable targets.
    selected: [],
    // Some types must be queued for selection before the final items can be selected.
    // For instance, a "cohort" type cannot be directly added to the selection but the user
    // must first select all people from that cohort they wish to add to the selection.
    queued: [],
    // Any "user" items pending selection during the phase in which the user resolves any queued items.
    pending: []
  })

  // NOTE: there is no `queued` state for role selection. Also, using objects rather than
  // arrays for simple key value paring: { [item.meta.key]: role }.
  // Roles are tracked separately from people, though have some overlap.
  const [roleSelection, setRoleSelection] = useState({
    // The selected roles that correspond to the selected "user" and "email" items.
    selected: {},
    // The pending roles that correspond to the pending "user" items.
    pending: {}
  })

  const [search, setSearch] = useState("")
  const [message, setMessage] = useState("")

  const context = getContext(peopleSelection)
  const [roleInType, roleInTypeId] = addToKey.split(":")
  const { items, ...itemsState } = usePeopleAndGroupConnections({
    first: 50,
    roleInType,
    roleInTypeId: parseInt(roleInTypeId),
    search
  })
  const { items: itemsOfQueued, ...itemsOfQueuedState } =
    usePeopleAndGroupConnections({
      first: 100000,
      context,
      roleInType,
      roleInTypeId: parseInt(roleInTypeId),
      skip: !context
    })
  const [addPeopleTo, addPeopleToState] = useAddPeopleTo({
    peopleSelection,
    roleSelections: { [addToKey]: roleSelection },
    addToKey
  })
  const rolePermissions = useRolePermissions(permissions)

  const addToPeopleSelection = (_items) => {
    const items = [].concat(_items).filter(Boolean).filter(getIsValidItem)
    const {
      selected: selectedPeople,
      queued: queuedPeople,
      pending: pendingPeople
    } = peopleSelection
    const { selected: selectedRole, pending: pendingRole } = roleSelection

    let nextSelectedPeople = [...selectedPeople]
    let nextQueuedPeople = [...queuedPeople]
    let nextPendingPeople = [...pendingPeople]

    // Adding anyone will require updating the role selection.
    let updateRole

    // When items are queued, we simply add the new items to pending.
    if (queuedPeople.length) {
      nextPendingPeople.push(
        ...items.filter((item) => _pendingTypes.includes(item.type))
      )
      updateRole = getRoles(nextPendingPeople, pendingRole, rolePermissions)
      // When an item is queued, adding the current pending list to selection moves all pending
      // items into selected and clears out the pending items and removes the first queued item.
      if (_items === pendingPeople) {
        // Remove selected items that have been removed from pending. This allows for removing previously
        // selected items when scoped to the queued item.
        const filtereNextSelectedPeople = nextSelectedPeople.filter(
          filterItem(
            nextSelectedPeople
              .filter(filterIntersectingItem(itemsOfQueued))
              .filter(filterItem(items))
          )
        )

        nextSelectedPeople = [...filtereNextSelectedPeople, ...items]

        // NOTE: we no longer have a record of this queuable item having been processed, so it will
        // again be selectable. We could shift this item into the nextSelectedPeople and then make sure
        // to filter it out in the UI and mutation, but that is hacky. We could also just allow it
        // to be selected again, which is the current UX, and allows for updating the selection for that queued item.
        nextQueuedPeople.shift()
        nextPendingPeople = []
        updateRole = pendingRole
      }
    } else {
      nextSelectedPeople.push(
        ...items.filter((item) => _selectTypes.includes(item.type))
      )
      nextQueuedPeople.push(
        ...items.filter((item) => _queueTypes.includes(item.type))
      )
      updateRole = getRoles(nextSelectedPeople, selectedRole, rolePermissions)
    }

    const nextPeopleSelection = {
      selected: getUniqueItems(nextSelectedPeople),
      queued: getUniqueItems(nextQueuedPeople),
      pending: getUniqueItems(nextPendingPeople)
    }

    _updateRoleSelection(nextPeopleSelection, updateRole)
    setPeopleSelection(nextPeopleSelection)
    setSearch("")
  }

  const removeFromPeopleSelection = (_items) => {
    const items = [].concat(_items).filter(Boolean)
    const {
      selected: selectedPeople,
      queued: queuedPeople,
      pending: pendingPeople
    } = peopleSelection

    let nextSelectedPeople = selectedPeople.filter(filterItem(items))
    let nextQueuedPeople = queuedPeople.filter(filterItem(items))
    let nextPendingPeople = pendingPeople.filter(filterItem(items))

    // Removing anyone will require updating the role selection.
    let updateRole

    if (queuedPeople.length) {
      // Can't remove selected items when removing from the queue.
      nextSelectedPeople = selectedPeople
      // Currently clear all pending when clearing any queued, with the assumption that
      // the queued item is responsible for all the pending ones.
      if (nextQueuedPeople.length < queuedPeople.length) {
        nextPendingPeople = []
      }

      // Clear out the roles for the pending items that were removed.
      updateRole = getNulledRoles(
        getFilteredItems(nextPendingPeople, itemsOfQueued)
      )
    } else {
      // Clear out the roles for the selected items that were removed.
      updateRole = getNulledRoles(
        getFilteredItems(nextSelectedPeople, itemsOfQueued)
      )
    }

    const nextPeopleSelection = {
      selected: nextSelectedPeople,
      queued: nextQueuedPeople,
      pending: nextPendingPeople
    }

    _updateRoleSelection(nextPeopleSelection, updateRole)
    setPeopleSelection(nextPeopleSelection)
  }

  // updateRoleSelection can be used in two ways:
  // 1) () => {} : clears out the role selection for selected or pending
  // 2) (updateRole) => {} : assigns the update role to either selected or pending
  // 3) (items, role) => {} : assigns the update role contrived from item(s) and role to either selected or pending
  const _updateRoleSelection = (peopleSelection, roleUpdateOrItems, role) => {
    const { selected: selectedPeole, queued: queuedPeople } = peopleSelection
    const { selected: selectedRole, pending: pendingRole } = roleSelection

    let nextSelectedRole = { ...selectedRole }
    let nextPendingRole = { ...pendingRole }

    let roleUpdate = roleUpdateOrItems

    // You can optionally pass the item and role to update just the role for the provided item.
    // This is just a convenience.
    if (typeof role !== "undefined") {
      const items = [].concat(roleUpdateOrItems)
      roleUpdate = items.reduce(
        (acc, item) => ({
          ...acc,
          [item.meta.key]: role
        }),
        {}
      )
    }

    // When items are queued, we simply update the pending role selection.
    if (queuedPeople.length || Object.keys(pendingRole).length) {
      // if (queuedPeople.length) {
      if (roleUpdate) {
        Object.assign(nextPendingRole, roleUpdate)
        // When an item is queued, updating the current pending role selection moves all pending roles
        // into selected and clears out the pending role selection.
        if (roleUpdate === pendingRole) {
          nextSelectedRole = { ...nextSelectedRole, ...roleUpdate }
          nextPendingRole = {}
        }
      } else {
        nextPendingRole = {}
      }
    } else {
      if (roleUpdate) {
        Object.assign(nextSelectedRole, roleUpdate)
      } else {
        nextSelectedRole = {}
      }
    }

    setRoleSelection({
      selected: getApplicableRoles(selectedPeole, nextSelectedRole),
      pending: getNonNullRole(nextPendingRole)
    })
  }

  // Encapsulate latest peopleSelection.
  const updateRoleSelection = (roleUpdateOrItems, role) =>
    _updateRoleSelection(peopleSelection, roleUpdateOrItems, role)

  // Auto-update pending items that have already been selected that match
  // the items for the queued item, + carry over their roles to pending roles.
  useEffect(() => {
    if (itemsOfQueued.length) {
      const nextSelectedPeople = getIntersectingItems(
        itemsOfQueued,
        peopleSelection.selected
      )
      const nextPendingRoles = getRoles(
        itemsOfQueued,
        roleSelection.selected,
        rolePermissions
      )
      addToPeopleSelection(nextSelectedPeople)
      updateRoleSelection(nextPendingRoles)
    }
  }, [itemsOfQueued])

  // Automatically load more when there are no more "selectable" items
  // or there are fewer x number of unselected items.
  useEffect(() => {
    const hasLimitedSelectableItems =
      items.length -
        getIntersectingItems(items, peopleSelection.selected).length <
      10

    const hasNoSelectableItems = !items
      .filter((item) => _selectTypes.includes(item.type))
      .filter(filterItem(peopleSelection.selected)).length

    if (
      (hasLimitedSelectableItems || hasNoSelectableItems) &&
      itemsState.hasMore
    ) {
      itemsState.loadMore()
    }
  }, [peopleSelection])

  return (
    <SelectPeopleContext.Provider
      value={{
        items,
        itemsState,
        itemsOfQueued,
        itemsOfQueuedState,
        rolePermissions,
        search,
        setSearch,
        peopleSelection,
        roleSelection,
        addToPeopleSelection,
        removeFromPeopleSelection,
        updateRoleSelection,
        addPeopleTo,
        addPeopleToState,
        message,
        setMessage
      }}
    >
      {children}
    </SelectPeopleContext.Provider>
  )
}
