export type UserScopeType = "user:none" | "user:one" | "user:all"

export const UserScope: { [key: string]: UserScopeType } = {
  NONE: "user:none",
  ONE: "user:one",
  ALL: "user:all"
}

export type BlockScopeType =
  | "block:usage"
  | "block:definition"
  | "block:type"
  | "block:context"
  | "block:all"

export const BlockScope: { [key: string]: BlockScopeType } = {
  USAGE: "block:usage",
  DEFINITION: "block:definition",
  TYPE: "block:type",
  CONTEXT: "block:context",
  ALL: "block:all"
}

export type ScopeType = `${BlockScopeType}+${UserScopeType}`

export const Scope: {
  [key: string]: ScopeType
} = {
  CONTENT: `${BlockScope.DEFINITION}+${UserScope.NONE}`,
  USER: `${BlockScope.USAGE}+${UserScope.ONE}`,
  USER_SUMMARY: `${BlockScope.USAGE}+${UserScope.ALL}`,
  PREFERENCES: `${BlockScope.TYPE}+${UserScope.ONE}`,
  SETTINGS: `${BlockScope.USAGE}+${UserScope.NONE}`,
  USER_INFO: `${BlockScope.ALL}+${UserScope.ONE}`,
  CONTEXT_SETTINGS: `${BlockScope.CONTEXT}+${UserScope.NONE}`,
  CONTEXT_PREFERENCES: `${BlockScope.CONTEXT}+${UserScope.ONE}`
}
