// const centeredBigBuck = "http://www.streambox.fr/playlists/x36xhzz/x36xhzz.m3u8"
// const centeredBigBuckDownload = "http://download.blender.org/peach/bigbuckbunny_movies/BigBuckBunny_320x180.mp4"
// const fullBigBuck = "http://www.streambox.fr/playlists/x36xhzz/x36xhzz.m3u8"
// const fullBigBuckDownload = "http://distribution.bbb3d.renderfarming.net/video/mp4/bbb_sunflower_1080p_60fps_normal.mp4"
// const stockCoverImage = "https://www.sample-videos.com/img/Sample-png-image-500kb.png"

// const coverImage = "https://cdn.filestackcontent.com/5XRJw18LQBSlndfWPdQ9"
const streamURL =
  "https://process.filestackapi.com/PKIcKga_QLKv1rkAdPQB/video_playlist/mlCC2FKSVSRIasjQUNAH"
const downloadURL = "https://cdn.filestackcontent.com/3uZPe7VtQNe9EIFBmzGC"
const originalThumb = "https://cdn.filestackcontent.com/5XRJw18LQBSlndfWPdQ9"

const sampleData = {
  coverImage: "",
  originalThumb,
  type: "mp4",
  height: 720,
  width: 1280
}

export default {
  type: "Video",
  helpLink:
    "https://help.pathwright.com/create-a-course/adding-content-with-blocks/video-upload-blocks-new",
  menu: {
    category: "media",
    order: 2
  },
  layouts: {
    center: {
      key: "center",
      label: "Video centered",
      data: {
        ...sampleData,
        streamURL,
        downloadURL,
        allowDownload: false
      }
    },
    fullwidth: {
      key: "fullwidth",
      label: "Upload Video",
      data: {
        ...sampleData,
        streamURL,
        downloadURL,
        allowDownload: false
      }
    }
  },
  schema: `
    type StreamingMediaNotes {
      html: String!
      timestamp: Int!
    }

    type VideoCaption {
      html: String!
    }

    enum EncodingStatus {
      ready
      processing
      error
    }

    type Video implements Block {
      id: ID!
      downloadURL: String @media
      coverImage: String
      originalThumb: String
      caption: VideoCaption
      type: String
      width: Int
      height: Int
      length: Float
      notes: [StreamingMediaNotes]
      streamURL: String
      status: EncodingStatus
      processingJobID: String
      allowDownload: Boolean
      muxVideoID: String
    }
  `
}
