import OptionsButton from "@pathwright/ui/src/components/button/OptionsButton"
import FullscreenConfirm from "@pathwright/ui/src/components/confirm/FullscreenConfirm"
import DropdownItem from "@pathwright/ui/src/components/dropdown/DropdownItem"
import DropdownMenu from "@pathwright/ui/src/components/dropdown/DropdownMenu"
import DropdownTrigger from "@pathwright/ui/src/components/dropdown/DropdownTrigger"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import produce from "immer"
import moment from "moment"
import { Component } from "react"
import styled from "styled-components"
import AssignDropdownItem from "../assign/AssignDropdownItem"
import {
  ROLES,
  ROLE_ICONS,
  ROLE_LABELS,
  ROLE_TYPE_SCHOOL,
  ROLE_VALUES
} from "../invitation/constants"
import { getApolloClient } from "../pathwright/PathwrightClient"
import { PathwrightContext } from "../pathwright/PathwrightContext"
import CANCEL_SCHOOL_SUBSCRIPTION from "../subscription/graphql/cancel-school-subscription-mutation"
import { HIGHEST_MINIMUM_SCHOOL_ROLE } from "./constants"
import MEMBERSHIP_FRAGMENT from "./graphql/membership-fragment"

const StyledDropdownTrigger = styled(DropdownTrigger)`
  min-height: 30px;
  min-width: 30px;
`

const TruncatedWrapper = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
`

// Cancel the user's subscription and remove the user's subscription if canceled immediately.
function cancelSchoolSubscription(membershipId, for_user, cancel_immediately) {
  const client = getApolloClient()
  return client.mutate({
    mutation: CANCEL_SCHOOL_SUBSCRIPTION,
    variables: { for_user, cancel_immediately },
    update: (cache) => {
      // Simply removing the user's school subscription if it was canceled immediately,
      // otherwise, no update (for now)
      if (!cancel_immediately) {
        return
      }

      const fragment = {
        fragment: MEMBERSHIP_FRAGMENT,
        fragmentName: "Membership",
        id: "Membership:" + membershipId
      }
      const data = cache.readFragment(fragment)
      const nextFragment = produce(data, (draft) => {
        draft.user.school_subscription = null
      })
      cache.writeFragment({
        ...fragment,
        data: nextFragment
      })
    }
  })
}

class MembershipControls extends Component {
  state = {
    loading: false,
    success: false,
    error: false,
    mutationKey: null // currently only handling "resetPassword"
  }

  handleSuccess = () => {
    this.setState({ success: true }, () =>
      setTimeout(() => this.setState({ success: false }), 1000)
    )
  }

  handleError = () => {
    this.setState({ error: true }, () =>
      setTimeout(() => this.setState({ error: false }), 1000)
    )
  }

  // TODO: use SubmitHandler?
  handleMutation = async (mutation) => {
    this.setState({ loading: true })
    try {
      await mutation()
      this.handleSuccess()
    } catch (error) {
      // TODO: handle error
      this.handleError()
    } finally {
      this.setState({ loading: false })
    }
  }

  render() {
    const { loading, success, error } = this.state
    const { user, role, has_access, access_revoked_time, archived_time } =
      this.props

    const disableSubscriptionActions =
      this.context.platformConfig.payments_maintenance_mode

    return (
      <FullscreenConfirm
        heading="Confirm cancelation"
        body={`Are you sure you want to cancel ${this.props.user.first_name}'s subscription?`}
        cancelPrompt={"No, nevermind"}
        confirmPrompt={`Yes, cancel subscription`}
        confirmedHeading="Success!"
        confirmedBody={`${this.props.user.first_name}'s subscription has been canceled.`}
        confirmOptions={[
          {
            label: `At end of subscription cycle (on ${moment(
              this.props.user.school_subscription &&
                this.props.user.school_subscription.subscription.cycle_end_dtime
            ).format("MMM DD, YYYY")})`,
            action: () =>
              cancelSchoolSubscription(
                this.props.id,
                this.props.user.id,
                false /* cancel immediately */
              )
          },
          {
            label: `Immediately`,
            action: () =>
              cancelSchoolSubscription(
                this.props.id,
                this.props.user.id,
                true /* cancel immediately */
              )
          }
        ]}
      >
        {({ confirm }) => (
          <StyledDropdownTrigger
            renderDropdown={() => (
              <DropdownMenu>
                <AssignDropdownItem userId={user.id} disabled={!has_access} />
                <DropdownItem
                  icon="envelope"
                  label={user.email}
                  href={`mailto:${user.email}`}
                />
                {ROLES[ROLE_TYPE_SCHOOL].map((school_role, key) => {
                  return school_role !== role &&
                    !(
                      role === HIGHEST_MINIMUM_SCHOOL_ROLE &&
                      school_role < HIGHEST_MINIMUM_SCHOOL_ROLE
                    ) ? (
                    <DropdownItem
                      disabled={!has_access}
                      key={key}
                      icon={ROLE_ICONS[ROLE_TYPE_SCHOOL][school_role]}
                      label={`Make ${ROLE_LABELS[ROLE_TYPE_SCHOOL][school_role]}`}
                      onClick={() =>
                        this.handleMutation(() => {
                          this.props.setMemberRole(
                            this.props.id,
                            ROLE_VALUES[ROLE_TYPE_SCHOOL][school_role]
                          )
                        })
                      }
                    />
                  ) : null
                })}
                {access_revoked_time ? (
                  <DropdownItem
                    disabled={!!archived_time}
                    icon="avatar-lock"
                    label="Give access"
                    onClick={() =>
                      this.handleMutation(() =>
                        this.props.setMemberAccess(this.props.id, true)
                      )
                    }
                  />
                ) : (
                  <DropdownItem
                    disabled={!!archived_time}
                    icon="minus-circle"
                    label="Remove access"
                    onClick={() =>
                      this.handleMutation(() =>
                        this.props.setMemberAccess(this.props.id, false)
                      )
                    }
                  />
                )}
                {archived_time ? (
                  <DropdownItem
                    icon="trash"
                    label="Unarchive member"
                    onClick={() =>
                      this.handleMutation(() =>
                        this.props.setMemberArchived(this.props.id, false)
                      )
                    }
                  />
                ) : (
                  <DropdownItem
                    icon="trash"
                    label="Archive member"
                    onClick={() =>
                      this.handleMutation(() =>
                        this.props.setMemberArchived(this.props.id, true)
                      )
                    }
                  />
                )}
                <DropdownItem
                  disabled={!!archived_time}
                  icon="password"
                  label="Send password reset link"
                  onClick={() => {
                    if (!this.state.loading) {
                      this.handleMutation(async () => {
                        await this.props.resetPassword(this.props.user.id)
                        this.props.toast({
                          title: "Password reset link sent.",
                          description: `${user.display_name} will receive an email with password reset instructions.`,
                          status: "success",
                          duration: 3000
                        })
                      })
                    }
                  }}
                />
                {!!this.props.user.school_subscription && (
                  <DropdownItem
                    icon="recur-x"
                    label={
                      <TruncatedWrapper>
                        Cancel{" "}
                        {
                          this.props.user.school_subscription.subscription_plan
                            .name
                        }
                      </TruncatedWrapper>
                    }
                    onClick={confirm}
                    disabled={disableSubscriptionActions}
                  />
                )}
              </DropdownMenu>
            )}
          >
            {loading ? (
              <LoadingCircle size={20} />
            ) : (
              <OptionsButton
                icon={success ? "check" : error ? "x" : "ellipses-horizontal"}
                color={success ? "forestgreen" : error ? "red" : "#888"}
              />
            )}
          </StyledDropdownTrigger>
        )}
      </FullscreenConfirm>
    )
  }
}

MembershipControls.displayName = "MembershipControls"

MembershipControls.contextType = PathwrightContext

export default MembershipControls
