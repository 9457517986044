import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import PropTypes from "prop-types"
import { useAssignRoles } from "./hooks"

const AssignGate = ({ children, blankSlate }) => {
  const { assignRolesQuery, assignRoles } = useAssignRoles()

  return assignRolesQuery.loading ? (
    <LoadingCircle />
  ) : assignRoles.length ? (
    children
  ) : typeof blankSlate !== "undefined" ? (
    blankSlate
  ) : (
    <BlankSlate
      icon="badge-key"
      heading="No Available Paths"
      body="You do not have permissions to Add to Path."
    />
  )
}

AssignGate.displayName = "AssignGate"

AssignGate.propTypes = {
  showBlankSlate: PropTypes.node
}

export default AssignGate
