import CardBlock from "@pathwright/ui/src/components/card/CardBlock"
import Checkbox from "@pathwright/ui/src/components/form/form-checkbox/Checkbox"
import TextInput from "@pathwright/ui/src/components/form/form-text-input/TextInput"
import Fieldset from "@pathwright/ui/src/components/form/form-utils/Fieldset"
import View from "@pathwright/ui/src/components/ui/View"
import SchoolSubscriptionPlansCheckboxes from "@pathwright/web/src/modules/subscription/manage/SchoolSubscriptionPlansCheckboxes"
import { Field, validators } from "react-connect-form-forked"
import GenerateCouponCodes from "./GenerateCouponCodes"
const { isNumeric } = validators

const GenerateCouponCodesForSubscriptionPlans = ({ card }) => (
  <GenerateCouponCodes
    card={card}
    title="Generate Coupon Codes For Subscription Plans"
  >
    {(formState) => (
      <View.Secondary>
        <CardBlock>
          <Fieldset
            title="All subscription plans"
            header="Apply coupon(s) to all subscription plans."
          >
            <Field
              name="allPlans"
              type="checkbox"
              component={Checkbox}
              hideStatus
            ></Field>
          </Fieldset>
        </CardBlock>
        {!formState.value.allPlans && (
          <CardBlock>
            <Fieldset
              title="Plan"
              header="Apply coupon(s) to selected subscription plans."
            >
              <Field
                name="plans"
                type="custom"
                component={SchoolSubscriptionPlansCheckboxes}
              />
            </Fieldset>
          </CardBlock>
        )}
        <CardBlock>
          <Fieldset
            title="Number of cycles to discount"
            header="Number of school subscription plan cycles the discount applies toward."
          >
            <Field
              name="numCyclesToDiscount"
              type="number"
              autoSize
              component={TextInput}
              validators={[isNumeric]}
              required
            />
          </Fieldset>
        </CardBlock>
      </View.Secondary>
    )}
  </GenerateCouponCodes>
)

GenerateCouponCodesForSubscriptionPlans.displayName =
  "GenerateCouponCodesForSubscriptionPlans"

export default GenerateCouponCodesForSubscriptionPlans
