import styled, { css } from "styled-components"

export const sizes = {
  desktop: 992,
  tablet: 768,
  phone: 480
}

// Iterate through the sizes and create a media template
export const media = Object.keys(sizes).reduce(
  (acc, label) => {
    // media.max.tablet``
    acc.max[label] = (...args) => `
  		@media (max-width: ${sizes[label]}px) {
  			${css(...args)}
  		}
  	`
    // media.min.tablet``
    acc.min[label] = (...args) => `
      @media (min-width: ${sizes[label] + 1}px) {
        ${css(...args)}
      }
    `
    return acc
  },
  { min: {}, max: {} }
)

// Sortof an emotion cx replacement for styled-components
export const cssCX = (obj) => {
  let styles = ``
  for (const [key, value] of Object.entries(obj)) {
    if (value) {
      styles = `${styles}${key}`
    }
  }

  return styles
}

// Stop-gap for using babel-plugin-styled-components and should be easility replaceable
// if/when we enable the babel plugin https://www.styled-components.com/docs/tooling)
export const WithCSS = styled.div`
  ${(props) => props.css}
`

export const componentWithCSS = (C) => styled(C)`
  ${(props) => props.css}
`

/**
 * getSelectors`html.no-touch ${["&", ".Button"]}.interactive:hover`
 * ...would generate...
 * html.no-touch &.interactive:hover,
 * html.no-touch .Button.interactive:hover
 */
export const getSelectors = ([preSelector, postSelector], selectors) =>
  selectors
    .map((selector) => `${preSelector}${selector}${postSelector}`)
    .join(", ")
