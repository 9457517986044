import { media } from "@pathwright/ui/src/components/utils/styles"
import styled from "styled-components"

export const List = styled.ul`
  --item-background-color: rgba(0, 0, 0, 0.05);
  margin: 0;
  padding: 0;
  list-style-type: none;

  ${(p) =>
    !!p.gridColumns &&
    `
    display: grid;
    grid-template-columns: ${new Array(p.gridColumns).fill("1fr").join(" ")};
  `}

  .Button {
    width: 100%;
  }
`

export const Item = styled.li`
  display: inline-block;
  width: 100%;
  height: 40px;
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-radius: 5px;
  > .Link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  ${media.min.phone`
    &:not(:hover) {
      & > .Pathicon {
        display: none;
      }
    }
    &:hover {
      background-color: var(--item-background-color);
    }
  `};
`

export const Row = styled.div`
  max-width: 100%;
  display: flex;
  align-items: center;
  padding: 2px;
  border-radius: 5px;
  flex-grow: 1;
  > &:last-child:not(:only-child) {
    flex-grow: 0;
    flex-shrink: 0;
  }
  > h5 {
    line-height: 30px !important;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  > .Pathicon {
    margin-left: auto;
  }
`

export const Icon = styled.div`
  flex-shrink: 0;
  width: 40px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  position: relative;
  img,
  .BackgroundImage {
    width: auto;
    height: 30px;
    border-radius: 4px;
  }
`

export const Checkbox = styled.div`
  flex-shrink: 0;
  width: 35px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`
