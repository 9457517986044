
        
    const runtime = require("../../../../../../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
    __nunjucks_module_dependencies__.templates = {};
    __nunjucks_module_dependencies__.globals = {};
    __nunjucks_module_dependencies__.extensions = {};
    __nunjucks_module_dependencies__.filters = {};
    __nunjucks_module_dependencies__.assets = {};
    
    
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["discussion/views/templates/discussion-response-delete.html"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "<h1>Are you sure?</h1>\n<p>This action cannot be undone</p>\n<div class=\"archive-action\">\n  <button\n    type=\"ButtonLegacy medium rounded\"\n    class=\"ButtonLegacy medium rounded btn-danger delete-response\"\n  >\n    Yes, Delete\n  </button>\n  or\n  <a href=\"#\" class=\"cancel-delete-response\">Cancel</a>\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
            const templateContext = {
                __nunjucks_loader_assets__: __nunjucks_module_dependencies__.assets,
                ...ctx
            };

            var nunjucks = (runtime && runtime.default || runtime)(
                {"dev":false,"jinjaCompat":false,"isAsyncTemplate":false},
                __nunjucks_module_dependencies__
            );

            if (nunjucks.isAsync()) {
                return nunjucks.renderAsync(
                    "discussion/views/templates/discussion-response-delete.html",
                    templateContext
                );
            }
        
            return nunjucks.render(
                "discussion/views/templates/discussion-response-delete.html",
                templateContext
            );
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["discussion/views/templates/discussion-response-delete.html"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
    