import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import "./GlobalSchoolLink.css"

const getRoleName = function (num) {
  switch (num) {
    case 30:
      return "admin"
    case 20:
      return "editor"
    case 10:
      return "staff"
    default:
      return "member"
  }
}

const SchoolLink = ({ thumb, name, role, redir_url }) => {
  const { t } = useTranslate()

  return (
    <div className="SchoolLink">
      <a className="SchoolLink__link" href={redir_url} />
      <img className="SchoolLink__img" src={thumb} />
      <div className="SchoolLink__wrapper">
        <div className="SchoolLink__label">
          <h4>{name}</h4>
          <span>{t(getRoleName(role))}</span>
        </div>
        <Pathicon className="SchoolLink__arrow" icon="chevron-right" />
      </div>
    </div>
  )
}

SchoolLink.displayName = "SchoolLink"

export default SchoolLink
