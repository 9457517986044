import classnames from "classnames"
import isObject from "lodash/isObject"
import marked from "marked"
import { Component } from "react"
import ReactDOM from "react-dom"
import { executeScripts } from "../embed/utils"
import { getSchoolURL, isRelativeUrl, routeToRelativeURL } from "../utils/urls"
import "./HtmlText.css"
import renderMathInElement from "./katex-auto-render"
import { isHTMLEmpty, responsifyKatex } from "./utils"

function handleRelativeLink(e) {
  e.preventDefault()
  e.stopPropagation()
  const url = e.currentTarget.getAttribute("href")
  return routeToRelativeURL(url)
}

function getRelativeLinks(el) {
  const links = el && el.querySelectorAll("a")
  return [...links].reduce((links, link) => {
    const url = link.getAttribute("href")
    const targetBlank = link.getAttribute("target") === "_blank"
    if (
      url &&
      !targetBlank &&
      url.indexOf("mailto:") === -1 &&
      (isRelativeUrl(url) || url.indexOf(getSchoolURL()) > -1)
    ) {
      return links.concat(link)
    }
    return links
  }, [])
}

class HtmlText extends Component {
  static displayName = "HtmlText"

  componentDidMount() {
    const el = ReactDOM.findDOMNode(this)
    if (this.props.latex === true) {
      renderMathInElement(el, false)
      responsifyKatex(el)
    }
    if (this.props.executeScripts) {
      executeScripts(el)
    }
    // Enable relative school URLs
    if (this.props.interceptLink) {
      this.relativeLinks = getRelativeLinks(el)
      this.relativeLinks.forEach((link) => {
        link.addEventListener("click", handleRelativeLink)
      })
    }
  }

  componentWillUnmount() {
    this.relativeLinks &&
      this.relativeLinks.forEach((link) => {
        link.removeEventListener("click", handleRelativeLink)
      })
  }

  getHTML = () => {
    let { html, markdown, templateContext } = this.props
    if (markdown) {
      html = marked(html)
    }
    // TODO: drop nunjucks dependency? Use something smaller/lighter like underscore
    if (templateContext != null && isObject(templateContext)) {
      try {
        // TODO: nunjucks or nunjucks alternative
        // html = App.utils.renderString(html, templateContext)
      } catch (error) {
        console.error("Error rendering string from template", error)
      }
    }
    return html
  }

  render() {
    const { markdown, latex, quill, theme, truncateAt, el } = this.props
    const className = classnames(
      "Text",
      {
        "Text--markdown": markdown,
        "Text--latex": latex,
        "Text--quill": quill,
        "Text--theme": theme,
        "Text-truncated": (truncateAt != null) > 0
      },
      this.props.className
    )
    const El = el ? el : "div"

    return (
      <El
        className={className}
        dangerouslySetInnerHTML={{ __html: this.getHTML() }}
      />
    )
  }
}

// Static helpers
HtmlText.isHTMLEmpty = isHTMLEmpty

HtmlText.displayName = "Text"

HtmlText.defaultProps = {
  latex: false,
  markdown: false,
  quill: false,
  theme: false,
  templateContext: null,
  executeScripts: true,
  truncateAt: null,
  interceptLink: false
}

export default HtmlText
