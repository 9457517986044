import Success from "@pathwright/ui/src/components/success/Success"
import pluralize from "pluralize"
import { ROLE_ACTIONS, ROLE_TYPE_OFFERING } from "../invitation/constants"
import AssignErrors from "./AssignErrors"

const AssignComplete = ({
  role,
  user,
  communityGroup,
  resourceCohortMap,
  resources,
  cohorts,
  sendNotification,
  cohortRegistrationMap,
  onClose
}) => {
  const assignedCohorts = Object.keys(cohortRegistrationMap).filter(
    (oId) => !isNaN(cohortRegistrationMap[oId])
  )

  return (
    <AssignErrors
      resourceCohortMap={resourceCohortMap}
      cohortRegistrationMap={cohortRegistrationMap}
      user={user}
      resources={resources}
      cohorts={cohorts}
    >
      {({ confirm, assignedCohortsFailed }) => {
        const actions = {
          primaryAction: {
            children: "Done",
            onClick: onClose
          }
        }

        if (assignedCohortsFailed.length) {
          actions.secondaryAction = {
            children: "View issues",
            onClick: confirm
          }
        }

        const resourcesLabel = pluralize(
          "resource",
          assignedCohorts.length,
          true
        )

        return user ? (
          <Success
            icon="check"
            heading={`Added ${resourcesLabel} to ${user.full_name} to ${ROLE_ACTIONS[ROLE_TYPE_OFFERING][role]}.`}
            body={`Visit ${user.first_name}'s profile to check progress anytime.`}
            onBackgroundClick={onClose}
            {...actions}
          />
        ) : communityGroup ? (
          <Success
            icon="check"
            heading={`Added ${resourcesLabel} to ${communityGroup.name} to ${ROLE_ACTIONS[ROLE_TYPE_OFFERING][role]}.`}
            body={`Visit the Group to check progress anytime.`}
            onBackgroundClick={onClose}
            {...actions}
          />
        ) : null
      }}
    </AssignErrors>
  )
}

AssignComplete.displayName = AssignComplete

export default AssignComplete
