import gql from "graphql-tag"
import ORDER_FRAGMENT from "./order-fragment"

export default gql`
  mutation CreateCurriculumLicenseOrder(
    $license_offering_id: Int!
    $licensee_school_id: Int!
    $licensor_school_id: Int!
    $name: String
    $seat_count: Int
    $role: RegistrationRoleEnum
    $coupon_code: String
  ) {
    createCurriculumLicenseOrder(
      license_offering_id: $license_offering_id
      licensee_school_id: $licensee_school_id
      licensor_school_id: $licensor_school_id
      name: $name
      seat_count: $seat_count
      role: $role
      coupon_code: $coupon_code
    ) {
      ...Order
    }
  }
  ${ORDER_FRAGMENT}
`
