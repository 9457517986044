import { useMutation } from "@apollo/client"
import gql from "graphql-tag"
import { usePathwrightClient } from "../../../pathwright/PathwrightClient"
import { RESOURCE_TYPE_LABELS } from "../../constants"
import UPDATE_RESOURCE_MUTATION from "./update-resource-mutation"

const RESOURCE_QUERY = gql`
  query ResourceQuery($id: Int!) {
    resource(id: $id) {
      id
      name
      resource_type
    }
  }
`

const useUpdateResourceMutation = (resource) => {
  const client = usePathwrightClient()

  const [updateResourceMutation] = useMutation(UPDATE_RESOURCE_MUTATION)

  const handler = async (values = {}, options = {}) => {
    let id = resource?.id || values?.id
    let name = resource?.name || values?.name
    let resource_type =
      RESOURCE_TYPE_LABELS[resource?.resource_type] || values?.resource_type

    if (!name || !resource_type) {
      const result = await client.query({
        query: RESOURCE_QUERY,
        variables: { id: values.id }
      })
      const { resource } = result.data
      name = resource.name
      resource_type = RESOURCE_TYPE_LABELS[resource.resource_type]
    }

    await updateResourceMutation({
      ...options,
      variables: {
        // required fields
        id,
        name,
        resource_type,
        ...values
      }
    })
  }

  return handler
}

export default useUpdateResourceMutation
