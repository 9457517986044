import Card from "@pathwright/ui/src/components/card/Card"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import PropTypes from "prop-types"
import EnterpriseSSOFeatureBlock from "../auth/EnterpriseSSOFeatureBlock"
import PathwrightSignInFeatureBlock from "../auth/PathwrightSignInFeatureBlock"
import FeatureBlockList, { Container } from "../block/FeatureBlockList"
import {
  FEATURE_ACTION_AUTH_LOGIN,
  FEATURE_ACTION_VIEW,
  FEATURE_KEY_ACTIVITY_FEED,
  FEATURE_KEY_AUTH_LOGIN_OPTION_GLOBAL_SSO_APPLE,
  FEATURE_KEY_AUTH_LOGIN_OPTION_GLOBAL_SSO_GOOGLE,
  FEATURE_KEY_MEMBER_DIRECTORY
} from "../constants"

const ManageFeatures = ({ card }) => {
  const { t } = useTranslate()

  return (
    <Card card={card} title={t("Configure Features")} emphasis="secondary">
      <Container>
        <PathwrightSignInFeatureBlock />
      </Container>
      <FeatureBlockList
        key={FEATURE_ACTION_AUTH_LOGIN}
        featureAction={FEATURE_ACTION_AUTH_LOGIN}
        featureKeys={[
          FEATURE_KEY_AUTH_LOGIN_OPTION_GLOBAL_SSO_APPLE,
          FEATURE_KEY_AUTH_LOGIN_OPTION_GLOBAL_SSO_GOOGLE
        ]}
        showTagFeatureBlock={false}
      />
      <Container>
        <EnterpriseSSOFeatureBlock />
      </Container>
      <FeatureBlockList
        key={FEATURE_ACTION_VIEW}
        featureAction={FEATURE_ACTION_VIEW}
        featureKeys={[FEATURE_KEY_ACTIVITY_FEED, FEATURE_KEY_MEMBER_DIRECTORY]}
      />
    </Card>
  )
}

ManageFeatures.displayName = "ManageFeatures"

ManageFeatures.propTypes = {
  // provided by CardStack > CardStackCard
  card: PropTypes.object.isRequired
}

export default ManageFeatures
