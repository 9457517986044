import classnames from "classnames"
import isFunction from "lodash/isFunction"
import PropTypes from "prop-types"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { InPortal, OutPortal, createHtmlPortalNode } from "react-reverse-portal"

export const PortableContext = React.createContext()

export const usePortableContext = () => useContext(PortableContext)

const usePortable = (renderPortal, attributes = {}) => {
  const [porting, setPorting] = useState(false)

  const portalNode = useMemo(
    () =>
      createHtmlPortalNode({
        attributes
      }),
    []
  )

  // Updating the portal node after the component mounts will cause
  // the portaled component to be remounted, losing internal state.
  // Any properties on the portal node that should be updated after
  // mount should be handled manually.
  useEffect(() => {
    portalNode.element.classList = classnames(
      "Portable",
      { ["Portable__portaling"]: porting },
      attributes.class
    )
  }, [porting, attributes.class])

  const inPortal = (
    <InPortal node={portalNode}>
      {isFunction(renderPortal)
        ? renderPortal({
            porting,
            setPorting
          })
        : renderPortal}
    </InPortal>
  )
  const outPortal = <OutPortal node={portalNode} />

  return {
    inPortal,
    outPortal,
    porting,
    setPorting
  }
}

const Portable = ({
  children,
  renderPortal,
  portalAttributes,
  manageInPortal
}) => {
  const { inPortal, outPortal, porting, setPorting } = usePortable(
    renderPortal,
    portalAttributes
  )

  const values = {
    porting,
    setPorting,
    inPortal,
    outPortal
  }

  return (
    <PortableContext.Provider value={values}>
      {!manageInPortal && inPortal}
      {isFunction(children) ? children(values) : children}
    </PortableContext.Provider>
  )
}

Portable.displayName = "Portable"

Portable.propTypes = {
  renderPortal: PropTypes.oneOfType([PropTypes.func, PropTypes.element])
    .isRequired,
  manageInPortal: PropTypes.bool
}

Portable.defaultProps = {
  manageInPortal: false
}

export default Portable
