import { HStack } from "@chakra-ui/react"
import { InfoCircleIcon, XIcon } from "@pathwright/pathicons"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import RoleDescriptorsDrawer from "../shared/RoleDescriptorsDrawer"
import ShareDropdown from "../shared/ShareDropdown"
import { useSelectPeopleContext } from "./SelectPeopleContext"
import { getRoleLabel } from "./utils"

const tPrefix = "share_card.add_tab"

const SelectRoleDescriptorsDrawerOption = ({ children }) => {
  const { tc } = useTranslate()

  return (
    <RoleDescriptorsDrawer>
      {({ ref, onOpen }) => {
        const option = {
          label: tc(`${tPrefix}.Learn more`),
          render: (node) => (
            <HStack ref={ref} color="gray.500">
              <InfoCircleIcon size={"1.2em"} />
              {node}
            </HStack>
          )
        }

        return children({
          option,
          onOpen
        })
      }}
    </RoleDescriptorsDrawer>
  )
}

const SelectRole = ({ onOpenRoleDescriptorsDrawer }) => {
  const {
    rolePermissions,
    peopleSelection: { selected },
    removeFromPeopleSelection,
    updateRoleSelection
  } = useSelectPeopleContext()
  const { t, tc, tca } = useTranslate()

  const roleOptions = Object.keys(rolePermissions).reduce(
    (acc, key) => [
      ...acc,
      {
        label: getRoleLabel("noun", key, tca),
        value: key
      }
    ],
    []
  )

  roleOptions.push({
    label: tc(`${tPrefix}.Clear selection`),
    value: null,
    render: (node) => (
      <HStack color="gray.500">
        <XIcon size={"1.2em"} />
        {node}
      </HStack>
    )
  })

  return (
    <SelectRoleDescriptorsDrawerOption>
      {({ option, onOpen }) => {
        const handleChange = (menuItem) => {
          if (menuItem.value) {
            updateRoleSelection(selected, menuItem.value)
          } else if (menuItem.value === null) {
            removeFromPeopleSelection(selected)
          } else {
            onOpen()
          }
        }

        return (
          <ShareDropdown
            name="role"
            variant="border"
            label={`${tc(`${tPrefix}.Update all`)}...`}
            options={[...roleOptions, option]}
            onChange={handleChange}
          />
        )
      }}
    </SelectRoleDescriptorsDrawerOption>
  )
}

export default SelectRole
