import StoreResourcesQuery from "../../graphql/StoreResourcesQuery"
import BrowseResult from "./BrowseResult"

function BrowseResultContainer({
  heading,
  onQuery,
  browseTo,
  resourcesQueryOptions
}) {
  return (
    <StoreResourcesQuery {...resourcesQueryOptions}>
      {(data) => (
        <BrowseResult
          heading={heading}
          total={data.total}
          loading={data.loading}
          resources={data.resources}
          onClear={() => onQuery(null)}
          browseTo={browseTo}
          hasMore={data.hasMore}
          loadMore={data.loadMore}
        />
      )}
    </StoreResourcesQuery>
  )
}

BrowseResultContainer.displayName = "BrowseResultContainer"

export default BrowseResultContainer
