import moment from "moment"
import PropTypes from "prop-types"
import styled from "styled-components"

const StyledContainer = styled.div`
  pointer-events: none;
  position: absolute;
  top: 0;
  right: -65px;
  padding: 0 5px;
  font-size: 12px;

  /* && only applies to the instance of the component based on conditionals */
  .ActivityTimeline:not(.ActivityTimeline__single-column) && {
    ${(p) => (p.columnSide === "left" ? `right: -65px;` : `left: -110px;`)}
  }
`

const ActivityTime = ({ action, columnSide }) => {
  return (
    <StyledContainer columnSide={columnSide}>
      {moment(action.action_dtime).format("h:mma")}
    </StyledContainer>
  )
}

ActivityTime.displayName = "ActivityTime"

ActivityTime.propTypes = {
  action: PropTypes.object.isRequired,
  columnSide: PropTypes.oneOf(["left", "right"])
}

export default ActivityTime
