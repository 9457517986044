import { ThemeType } from "../api/generated"

export type PresetHeadingFontKey =
  | "General Sans"
  | "Inter"
  | "Newsreader"
  | "Montserrat"
  | "Pally"
  | "ProximaNova-Regular"

export const presetHeadingFontCss: Record<PresetHeadingFontKey, string> = {
  Inter: `
    @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
  `,
  Newsreader: `
    @import url('https://fonts.googleapis.com/css2?family=Newsreader:ital,opsz,wght@0,6..72,200..800;1,6..72,200..800&display=swap');
  `,
  Montserrat: `
    @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100..900&display=swap');
  `,
  ["General Sans"]: `
    @font-face {
      font-family: "General Sans";
      src: url("https://cdn.filestackcontent.com/PKIcKga_QLKv1rkAdPQB/content=t:inline,f:%22GeneralSans-Variable.ttf%22/q7tNPBZrSQWkBJ0UjneQ");
    }
  `,
  Pally: `
    @font-face {
      font-family: "Pally";
      src: url("https://cdn.filestackcontent.com/PKIcKga_QLKv1rkAdPQB/content=t:inline,f:%22Pally-Variable.ttf%22/0M3YxmvMS16sanwo185D");
    }
  `,
  // ProximaNova is provided by default.
  ["ProximaNova-Regular"]: ""
}

export const presetThemes = {
  default: {
    background_image:
      "https://cdn.filestackcontent.com/PKIcKga_QLKv1rkAdPQB/content=t:inline,f:%22Pathwright_abstract-logo-extrudes.png%22/1YmgpntrRIG1fjrRsb02",
    background_overlay: "51, 49, 50, 1",
    background_type: 5,
    heading_font: "Inter",
    primary_color: "0097DC",
    theme_type: ThemeType.Default
  },
  minimal_modern: {
    background_image:
      "https://cdn.filestackcontent.com/PKIcKga_QLKv1rkAdPQB/content=t:inline,f:%22codioful-formerly-gradienta-LeG68PrXA6Y-unsplash.jpg%22/veN7EwNaT62DGfWWhfop",
    background_type: 5,
    heading_font: "Montserrat",
    primary_color: "F36C42",
    theme_type: ThemeType.Modern
  },
  friendly: {
    background_image:
      "https://cdn.filestackcontent.com/PKIcKga_QLKv1rkAdPQB/content=t:inline/w9keAePsTSixWYsi9pAp",
    background_type: 5,
    heading_font: "ProximaNova-Regular",
    primary_color: "0D71FB",
    theme_type: ThemeType.Legacy
  },
  creative_organic: {
    background_image:
      "https://cdn.filestackcontent.com/PKIcKga_QLKv1rkAdPQB/content=t:inline,f:%22steve-johnson-5Oe8KFH5998-unsplash.jpg%22/S7V2aqUVSWCf3625oGeH",
    background_type: 5,
    heading_font: "Pally",
    primary_color: "FDBE13",
    theme_type: ThemeType.Creative
  },
  classic_academic: {
    background_image:
      "https://cdn.filestackcontent.com/PKIcKga_QLKv1rkAdPQB/content=t:inline,f:%22kevin-mccutcheon-xP7xcbX7Rf4-unsplash.jpg%22/VZbCQUZRG2dsiKqv04NR",
    background_type: 5,
    heading_font: "Newsreader",
    primary_color: "C99C28",
    theme_type: ThemeType.Classic
  }
} as const satisfies Record<string, PresetTheme>

export type PresetThemeKey = keyof typeof presetThemes
export type PresetTheme = {
  background_image: string
  background_type: 1 | 5 // Pristine | Blur
  background_overlay?: string
  heading_font: PresetHeadingFontKey
  primary_color: string
  theme_type: ThemeType
}

export const presetThemePalettes = {
  "0097DC": {
    "50": "#e4f7ff",
    "100": "#c0ebff",
    "200": "#7ed6ff",
    "300": "#42c4ff",
    "400": "#0cb3ff",
    "500": "#0097dc",
    "600": "#007bb3",
    "700": "#006491",
    "800": "#005176",
    "900": "#004260"
  },
  F36C42: {
    "50": "#fef8f5",
    "100": "#fde9e2",
    "200": "#fbcabb",
    "300": "#f8ab93",
    "400": "#f68c6b",
    "500": "#f36c42",
    "600": "#f04c18",
    "700": "#cf3b0d",
    "800": "#a42f0a",
    "900": "#792208"
  },
  FDBE13: {
    "50": "#fffaee",
    "100": "#fff3d3",
    "200": "#fee5a0",
    "300": "#fed76f",
    "400": "#fdca40",
    "500": "#fdbe13",
    "600": "#e3a702",
    "700": "#bb8902",
    "800": "#966e01",
    "900": "#725401"
  },
  C99C28: {
    "50": "#fbf7ec",
    "100": "#f6ecd1",
    "200": "#ecd69e",
    "300": "#e3c270",
    "400": "#dab045",
    "500": "#c99c28",
    "600": "#a68121",
    "700": "#88691b",
    "800": "#6d5516",
    "900": "#574311"
  },
  "0D71FB": {
    "50": "#edf4ff",
    "100": "#d1e4fe",
    "200": "#9cc5fd",
    "300": "#69a7fd",
    "400": "#3a8bfc",
    "500": "#0d71fb",
    "600": "#045eda",
    "700": "#034db3",
    "800": "#023e8f",
    "900": "#022f6e"
  }
}

// Wrap font css with comments that can later be matched against for removing/replacing later on.
export function formatPresetHeadingFontCss(
  headingFont: PresetHeadingFontKey
): string {
  return `
    /* ==== begin theme heading font: "${headingFont}" ==== */
    ${presetHeadingFontCss[headingFont]}
    /* ==== end theme heading font: "${headingFont}" ==== */
  `
}

// Parse out the font css beginning and ending with the inserted comments which were applied
// when formatting the css.
export function parsePresetHeadingFontCss(
  headingFont: PresetHeadingFontKey,
  css: string,
  replaceCss = ""
): string {
  const literalHeadingFontChars = headingFont
    .split("")
    .map((char) => `[${char}]`)
    .join("")
  const re = new RegExp(
    `\/\\* ={4,} begin theme heading font: "${literalHeadingFontChars}" ={4,} \\*\/\[\\s\\S]+\/\\* ={4,} end theme heading font: "${literalHeadingFontChars}" ={4,} \\*\/`
  )
  return css.replace(re, replaceCss)
}
