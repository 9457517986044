import { graphql } from "@apollo/client/react/hoc"
import compose from "lodash/flowRight"
import get from "lodash/get"
import PropTypes from "prop-types"

import Card from "@pathwright/ui/src/components/card/Card"
import { flattenEdges } from "../../utils/apollo"
import SCHOOL_BILLING_INVOICE_QUERY from "../graphql/school-billing-invoice-query"
import ManageBillingInvoiceItemDetails from "./ManageBillingInvoiceItemDetails"

const ManageBillingInvoiceItem = ({ card, invoice }) => {
  return (
    <Card card={card} title="Invoice">
      <ManageBillingInvoiceItemDetails invoice={invoice} />
    </Card>
  )
}

ManageBillingInvoiceItem.displayName = "ManageBillingInvoiceItem"

ManageBillingInvoiceItem.propTypes = {
  card: PropTypes.object,
  id: PropTypes.number.isRequired
}

export default compose(
  graphql(SCHOOL_BILLING_INVOICE_QUERY, {
    options: ({ id }) => ({
      variables: {
        id
      }
    }),
    props: ({ data }) => {
      const { error, loading, school } = data
      return {
        error,
        loading,
        invoice: flattenEdges(get(school, "school_billing_invoice"))
      }
    }
  })
)(ManageBillingInvoiceItem)
