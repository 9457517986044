import gql from "graphql-tag"
import GROUP_FRAGMENT from "../../group/graphql/group-fragment"
import RESOURCE_FRAGMENT from "../../resource/graphql/resource-fragment"
import USER_FRAGMENT from "../../user/graphql/user-fragment"
import REGISTRATION from "./registration-fragment"

export default gql`
  query UserRegistrationsQuery(
    $cursor: String
    $first: Int = 50
    $id: Int
    $role_filter: RoleFilter
    $progress_filter: ProgressFilter
    $parent_cohort_filter: IDFilter
    $hasParentRegistration: Boolean
    $orderBy: [String]
  ) {
    user(id: $id) {
      ...User
      registrations(
        first: $first
        after: $cursor
        role_filter: $role_filter
        progress_filter: $progress_filter
        parent_cohort_filter: $parent_cohort_filter
        has_parent_registration: $hasParentRegistration
        orderBy: $orderBy
      ) {
        total
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            ...Registration
            resource {
              ...Resource
            }
            group {
              ...Group
            }
            path {
              id
              progress
              completion_date
            }
          }
        }
      }
    }
  }
  ${USER_FRAGMENT}
  ${REGISTRATION}
  ${RESOURCE_FRAGMENT}
  ${GROUP_FRAGMENT}
`
