const { createResourceStore } = require("lib/core/store/resource-store")

const AuthorCollection = require("../models/author-collection").default
const Author = require("../models/author").default

const options = {
  list_params: { loadAll: true },
  load_params: { loadAll: true }
}

const AuthorAdminStore = createResourceStore(
  "author",
  AuthorCollection,
  Author,
  options
)

export default window.App.stores.registerStore("authorAdmin", AuthorAdminStore)
