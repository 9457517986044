import { graphql } from "@apollo/client/react/hoc"
import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import Card from "@pathwright/ui/src/components/card/Card"
import CardBlock from "@pathwright/ui/src/components/card/CardBlock"
import RadioButton from "@pathwright/ui/src/components/form/form-radio/RadioButton"
import RadioGroup from "@pathwright/ui/src/components/form/form-radio/RadioGroup"
import TextInput from "@pathwright/ui/src/components/form/form-text-input/TextInput"
import useDebouncedState from "@pathwright/ui/src/components/hooks/useDebouncedState"
import withTranslate from "@pathwright/ui/src/components/lng/withTranslate"
import Text from "@pathwright/ui/src/components/ui/Text"
import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import compose from "lodash/flowRight"
import get from "lodash/get"
import { Field, Form, Submit } from "react-connect-form-forked"
import SelectInput from "../../../lib/SelectInput/SelectInput"
import { flattenEdges, usePaginator } from "../../../utils/apollo"
import CREATE_MENTOR_GROUP_MUTATION from "../../graphql/create-mentor-group-mutation"
import MENTOR_GROUPS_QUERY from "../../graphql/mentor-groups-query"
import UpgradeMentorGroupsGate from "./UpgradeMentorGroupsGate"

export let MentorGroupForm = ({ parentGroupId, createGroup, onSave, t }) => {
  const [search, setSearch] = useDebouncedState(null)

  const mentorGroupsQuery = useQuery(MENTOR_GROUPS_QUERY, {
    variables: {
      seatLimitFilter: { neq: 1 },
      search
    }
  })

  const groups = mentorGroupsQuery.data
    ? flattenEdges(get(mentorGroupsQuery, "data.school.mentor_groups"))
    : []

  const { loadMore, hasMore, loadingMore } = usePaginator({
    data: mentorGroupsQuery, // hacky
    path: "school.mentor_groups"
  })

  return (
    <UpgradeMentorGroupsGate>
      <Form
        initialValue={{
          parent: parentGroupId || null
        }}
        onSubmit={createGroup}
        onSubmitSuccess={onSave}
      >
        <CardBlock>
          <Field
            name="name"
            type="text"
            label={t("mentor_group.form.fields.name.label")}
            placeholder={t("mentor_group.form.fields.name.placeholder")}
            topLabel
            hideStatus
            component={TextInput}
            style={{ flexGrow: 1 }}
            required
          />
          {groups.length > 0 && (
            <Field
              name="parent"
              type="select"
              label={t("mentor_group.form.fields.parent.label")}
              instructions={t("mentor_group.form.fields.parent.instructions")}
              topLabel
              hideStatus
              simpleValue
              searchable
              component={SelectInput}
              placeholder={t("mentor_group.form.fields.select")}
              options={groups}
              labelKey="name"
              valueKey="id"
              onMenuScrollToBottom={() => !loadingMore && hasMore && loadMore()}
              onInputChange={(val) => {
                setSearch(val)
                // return val for react-select to handle
                return val
              }}
            />
          )}
        </CardBlock>
        <Submit styleType="primary" component={SubmitButton}>
          {t("mentor_group.form.create")}
        </Submit>
      </Form>
    </UpgradeMentorGroupsGate>
  )
}

MentorGroupForm = compose(
  withTranslate,
  graphql(CREATE_MENTOR_GROUP_MUTATION, {
    props: ({ mutate }) => ({
      createGroup: ({ name, parent, type }) =>
        mutate({
          variables: {
            name,
            parent: parent ? parseInt(parent) : null,
            type
          },
          refetchQueries: [
            {
              query: MENTOR_GROUPS_QUERY,
              variables: {
                parentId: { eq: parseInt(parent) || null },
                seatLimitFilter: { neq: 1 }
              }
            }
          ]
        })
    })
  })
)(MentorGroupForm)

const AddMentorGroupCard = ({ card, parentGroupId, createGroup, t }) => {
  const [search, setSearch] = useDebouncedState(null)

  const mentorGroupsQuery = useQuery(MENTOR_GROUPS_QUERY, {
    variables: {
      seatLimitFilter: { neq: 1 },
      search
    }
  })

  const groups =
    mentorGroupsQuery.data && mentorGroupsQuery.data.school
      ? flattenEdges(get(mentorGroupsQuery, "data.school.mentor_groups"))
      : []

  const { loadMore, hasMore, loadingMore } = usePaginator({
    data: mentorGroupsQuery, // hacky
    path: "school.mentor_groups"
  })

  const title = parentGroupId
    ? t("mentor_group.new_sub_group")
    : t("mentor_group.new_group")

  return (
    <Card card={card} title={title}>
      <UpgradeMentorGroupsGate>
        {groups && (
          <Form
            initialValue={{
              parent: parentGroupId || null
            }}
            onSubmit={createGroup}
            onSubmitSuccess={card.onClose}
          >
            <CardBlock>
              <Field
                name="name"
                type="text"
                label={t("mentor_group.form.fields.name.label")}
                placeholder={t("mentor_group.form.fields.name.placeholder")}
                topLabel
                hideStatus
                component={TextInput}
                style={{ flexGrow: 1 }}
                required
              />
              {groups.length > 0 && (
                <Field
                  name="parent"
                  type="select"
                  label={t("mentor_group.form.fields.parent.label")}
                  instructions={t(
                    "mentor_group.form.fields.parent.instructions"
                  )}
                  topLabel
                  hideStatus
                  simpleValue
                  searchable
                  component={SelectInput}
                  placeholder={t("mentor_group.form.fields.select")}
                  options={groups}
                  labelKey="name"
                  valueKey="id"
                  onMenuScrollToBottom={() =>
                    !loadingMore && hasMore && loadMore()
                  }
                  onInputChange={(val) => {
                    setSearch(val)
                    // return val for react-select to handle
                    return val
                  }}
                />
              )}
              {false && (
                <RadioGroup
                  label={t("mentor_group.form.fields.group_type")}
                  topLabel
                  hideStatus
                >
                  <Field
                    name="type"
                    type="radio"
                    component={RadioButton}
                    value="community"
                    initialChecked
                  >
                    <div>
                      <Text.H5>
                        {t("mentor_group.form.fields.community")}
                      </Text.H5>
                      <Text.Body>
                        {t("mentor_group.form.fields.set_up_as_community")}.
                      </Text.Body>
                    </div>
                  </Field>
                  <Field
                    name="type"
                    type="radio"
                    component={RadioButton}
                    value="personal"
                  >
                    <div>
                      <Text.H5>
                        {t("mentor_group.form.fields.personal")}
                      </Text.H5>
                      <Text.Body>
                        {t("mentor_group.form.fields.set_up_as_personal")}.
                      </Text.Body>
                    </div>
                  </Field>
                </RadioGroup>
              )}
            </CardBlock>
            <Submit styleType="primary" component={SubmitButton}>
              {t("mentor_group.form.create")}
            </Submit>
          </Form>
        )}
      </UpgradeMentorGroupsGate>
    </Card>
  )
}

export default compose(
  withTranslate,
  graphql(CREATE_MENTOR_GROUP_MUTATION, {
    props: ({ mutate }) => ({
      createGroup: ({ name, parent, type }) =>
        mutate({
          variables: {
            name,
            parent: parent ? parseInt(parent) : null,
            type
          },
          refetchQueries: [
            {
              query: MENTOR_GROUPS_QUERY,
              variables: {
                parentId: { eq: parseInt(parent) || null },
                seatLimitFilter: { neq: 1 }
              }
            }
          ]
        })
    })
  })
)(AddMentorGroupCard)
