import { Box } from "@chakra-ui/react"
import LinkTab from "../tabs/LinkTab"

const LearnerLink = () => {
  return (
    <Box pt={3}>
      <LinkTab />
    </Box>
  )
}

export default LearnerLink
