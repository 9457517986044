import { Box, useEventListener } from "@chakra-ui/react"
import classnames from "classnames"
import { forwardRef, useCallback, useEffect, useRef, useState } from "react"
import { usePrevious } from "react-use"
import styled from "styled-components"
import { TEXT_OBJECT_PADDING, getDefaultTextObject } from "../constants"
import { useCertificateState } from "../context/CertificateState"
import { textObjectsPropType } from "../propTypes"
import DesignObject from "./DesignObject"
import MultiObject from "./MultiObject"
import ProximityLines from "./ProximityLines"
import TextObject from "./TextObject"

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

export const getDefaultTextObjectRect = (textObject, templateDimensions) => ({
  position: {
    top:
      Math.round(textObject.position.top * templateDimensions.height) +
      TEXT_OBJECT_PADDING,
    left:
      Math.round(textObject.position.left * templateDimensions.width) +
      TEXT_OBJECT_PADDING
  },
  dimensions: {
    height:
      Math.round(textObject.dimensions.height * templateDimensions.height) -
      TEXT_OBJECT_PADDING * 2,
    width:
      Math.round(textObject.dimensions.width * templateDimensions.width) -
      TEXT_OBJECT_PADDING * 2
  }
})

export const createDefaultTextObject = (templateDimensions) => {
  const textObject = getDefaultTextObject()
  return {
    ...textObject,
    ...getDefaultTextObjectRect(textObject, templateDimensions)
  }
}

const ResizeProximityLines = forwardRef((_, ref) => (
  <Box ref={ref} pos="absolute" inset={0}></Box>
))

const CertificateText = () => {
  const {
    certificateState: { textObjects },
    handleChangeTextObjects,
    handleChangeTextObject,
    selectedTextObjectIndexes,
    handleSelectTextObjectIndex,
    handleSelectTextObjectIndexes,
    templateDimensions,
    isUsingProximityLines
  } = useCertificateState()
  const certificateTextRef = useRef(null)
  const resizeProximityLinesRef = useRef(null)
  // Since we can't access the drag data in all drag events (only in drop)
  // we'll track the data in state instead.
  const [dragData, setDragData] = useState(null)
  const [dragObjectRect, setDragObjectRect] = useState(null)

  // Store the proximity offset callback for use when handling onDrop.
  const getProximityOffsetCallbackRef = useRef(null)

  // Track if shift is held down. When held down, we restrict the lines
  // to the target, basically an inverse of the default behavior.
  const [isHoldingShift, setIsHoldingShift] = useState(false)
  const handleIsHoldingShift = (e) => setIsHoldingShift(e.shiftKey)
  useEventListener("keydown", handleIsHoldingShift, document, [])
  useEventListener("keyup", handleIsHoldingShift, document, [])

  // handle inserting default textObject if no textObjects exist
  useEffect(() => {
    if (!textObjects.length) {
      handleChangeTextObjects([createDefaultTextObject(templateDimensions)])
    }
  }, [])

  // Select a new text object, either after 1) the default text object is added,
  // 2) a text object is copied or 3) a text object is added from Design tab.
  const prevTextObjectsLength = usePrevious(textObjects.length)
  const autoSelectTextObjectIndexes =
    textObjects.length > prevTextObjectsLength
      ? new Array(textObjects.length - prevTextObjectsLength)
          .fill(0)
          .map((_, i) => prevTextObjectsLength + i)
      : []
  useEffect(() => {
    if (autoSelectTextObjectIndexes.length) {
      handleSelectTextObjectIndexes(autoSelectTextObjectIndexes)
    }
  }, [autoSelectTextObjectIndexes.join("")])

  // handle the change of a specific key/value pair for a given textObject
  // based on textObject's index in the textObjects array
  const handleChange = useCallback(
    (index, key, value) => {
      handleChangeTextObject(index, { [key]: value })
    },
    [textObjects]
  )

  // We need a current reference to the selected text objects since
  // some functions depending on getIndexes are cached.
  const selectedTextObjectIndexesRef = useRef([])
  selectedTextObjectIndexesRef.current = selectedTextObjectIndexes
  // Get indexes for the objects we're acting on.
  function getIndexes(index) {
    const isMulti = index === -1
    // Ensure selected indexes are sorted according to text object indexes.
    return isMulti
      ? textObjects.reduce((acc, _, i) => {
          if (selectedTextObjectIndexesRef.current.includes(i)) acc.push(i)
          return acc
        }, [])
      : [index]
  }

  // Each object is classed by their index.
  function getObjectElement(index) {
    return certificateTextRef.current.querySelector(`.DesignObject-${index}`)
  }

  // Gets the object's position using an index and calculations based on the
  // difference between the top and left values of the certificate's and object's
  // bounding client rects. We use index to derive the data rather than textObject.position
  // because the index could be -1 (multi-select index), in which case we don't have
  // a textObject (though we could then in turn calculate the position using the getPosition util...).
  function getObjectPosition(index) {
    const certificateRect = certificateTextRef.current.getBoundingClientRect()
    const objectRect = getObjectElement(index).getBoundingClientRect()

    return {
      top: objectRect.top - certificateRect.top,
      left: objectRect.left - certificateRect.left
    }
  }

  // Gets the new positions for applicable text objects, handling both single- and mult-select.
  // The second are supplies the base position which the new position will be relative to.
  // The positions are constrained to the dimensions of the certificate.
  function getNewObjectRects(index, { top, left }) {
    // Allows either a number or array as index.
    // An array simply overrides the default behavior, providing explicitly the indexes to check.
    const indexes = typeof index === "number" ? getIndexes(index) : index

    // Get fresh bounding client rect for certificate.
    const certificateRect = certificateTextRef.current.getBoundingClientRect()

    // Get the bounding client rect for the object we're moving which will be used
    // for ensuring the object's new position is contained to the certificate.
    const boundingClientRect = getObjectElement(index).getBoundingClientRect()

    // Get the current position of the object we're moving.
    const currentPosition = getObjectPosition(index)

    function getPosition(index) {
      // Get the rect value for the text object at current index
      const currentBoundingClientRect =
        getObjectElement(index).getBoundingClientRect()

      // Get the offset for the object when dragged within a mult-object.
      const multiInsetRect = {
        top:
          currentBoundingClientRect.top -
          currentPosition.top -
          certificateRect.top,
        left:
          currentBoundingClientRect.left -
          currentPosition.left -
          certificateRect.left
      }

      // get the new abosolute position of the element relative to its parent
      const newPosition = {
        top: top + multiInsetRect.top,
        left: left + multiInsetRect.left
      }

      // ensure the element(s) is not placed outside the bounds of its parent
      // Note that we're targeting the object we're moving, which could be the
      // multi-object which ensures the whole set of dragged objects collectively
      // keep their relative positions within the multi-object, yet are bound to
      // the parent.
      const maxHeight =
        certificateRect.height - boundingClientRect.height + multiInsetRect.top
      const maxWidth =
        certificateRect.width - boundingClientRect.width + multiInsetRect.left

      // the new absolute position of the element relative to its parent
      const position = {
        top: Math.max(0, Math.min(maxHeight, newPosition.top)),
        left: Math.max(0, Math.min(maxWidth, newPosition.left))
      }

      // Include right and bottom positions for completeness.
      return {
        ...position,
        right: position.left + currentBoundingClientRect.width,
        bottom: position.top + currentBoundingClientRect.height
      }
    }

    return indexes.map((index) => getPosition(index))
  }

  const handleCopy = useCallback(
    (index) => {
      const indexes = getIndexes(index)
      const objectRect = getObjectElement(index).getBoundingClientRect()
      const currentPosition = getObjectPosition(index)

      // Push the copied textObject below the source.
      const newPosition = {
        top: currentPosition.top + objectRect.height,
        left: currentPosition.left
      }

      // Get the new positions for the copied text objects.
      // Using the newPositions util to ensure the objects won't be placed beyond
      // the container's bottom edge.
      const positions = getNewObjectRects(index, newPosition).map((rect) => ({
        top: rect.top,
        left: rect.left
      }))

      // Copy all at once.
      handleChangeTextObjects([
        ...textObjects,
        ...indexes.map((i) => ({
          ...textObjects[i],
          position: positions.shift()
        }))
      ])
    },
    [textObjects]
  )

  const handleDelete = useCallback(
    (index) => {
      handleChangeTextObjects(
        textObjects.filter((_, i) => !getIndexes(index).includes(i))
      )
    },
    [textObjects]
  )

  function getDragObjectRects(e, { index, insetRect }) {
    // Get fresh bounding client rect for certificate.
    const certificateRect = e.currentTarget.getBoundingClientRect()
    // get the new abosolute position of the element relative to its parent
    const newPosition = {
      top: e.clientY - certificateRect.top - insetRect.top,
      left: e.clientX - certificateRect.left - insetRect.left
    }
    return getNewObjectRects(index, newPosition)
  }

  const handleDrop = (e) => {
    e.preventDefault()
    const dropData = JSON.parse(e.dataTransfer.getData("plain/text"))
    const { index, insetRect } = dropData
    const indexes = getIndexes(index)

    // Apply the proximity offset.
    if (getProximityOffsetCallbackRef.current) {
      const proximityOffset = getProximityOffsetCallbackRef.current()
      insetRect.top += proximityOffset.top
      insetRect.left += proximityOffset.left
    }

    // Get the new positions for the moved text objects.
    const positions = getDragObjectRects(e, { index, insetRect }).map(
      (rect) => ({
        top: rect.top,
        left: rect.left
      })
    )

    // Have to batch update all position changes at once since otherwise only the
    // chanage for the last text object in the list sticks.
    handleChangeTextObjects(
      textObjects.map((textObject, index) =>
        indexes.includes(index)
          ? {
              ...textObject,
              position: positions.shift()
            }
          : textObject
      )
    )
  }

  const handleDrag = (e) => {
    e.preventDefault()

    // Keyevents are ignored when dragging, but we can detect modifier keys
    // in the drag over event.
    handleIsHoldingShift(e)

    if (dragData) {
      const { index, insetRect } = dragData
      // Pass the explicit [index] so as to ensure we only respond to the
      // dragged object rect (in the case of mult-drag).
      const dragObjectRect = getDragObjectRects(e, {
        index: [index],
        insetRect
      })[0]

      // We'll track this drag object rect in order to display proximity lines.
      setDragObjectRect(dragObjectRect)
    }
  }

  const handleDragStart = (dragData) => {
    setDragData(dragData)
  }
  const handleDragEnd = () => {
    setDragData(null)
    setDragObjectRect(null)
  }

  const handleSelect = (i) => (e) =>
    handleSelectTextObjectIndex(i, e.getModifierState("Meta"))
  const handleDeselect = (e) =>
    !e.getModifierState("Meta") && handleSelectTextObjectIndex()

  return (
    <Container
      className={classnames("CertificateText", {
        CertificateText__hasSelection: !!selectedTextObjectIndexes.length
      })}
      ref={certificateTextRef}
      onDragEnter={(e) => e.preventDefault()}
      onDragOver={handleDrag}
      onDrop={handleDrop}
    >
      {isUsingProximityLines && (
        <>
          <ResizeProximityLines ref={resizeProximityLinesRef} />
          <ProximityLines
            ref={getProximityOffsetCallbackRef}
            targetRect={dragObjectRect}
            targetIndex={dragData?.index}
            // Restrict the proximity lines to the target when holding shift.
            restrictToTarget={isHoldingShift}
          />
        </>
      )}
      {textObjects.map((textObject, i) =>
        selectedTextObjectIndexes.length <= 1 ||
        !selectedTextObjectIndexes.includes(i) ? (
          <DesignObject
            key={i}
            index={i}
            isSelected={selectedTextObjectIndexes.includes(i)}
            onChange={(key, value) => handleChange(i, key, value)}
            onCopy={() => handleCopy(i)}
            onDelete={() => handleDelete(i)}
            onSelect={handleSelect(i)}
            onDeselect={handleDeselect}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            position={textObject.position}
          >
            <TextObject
              key={i}
              index={i}
              textObject={textObject}
              isSelected={selectedTextObjectIndexes.includes(i)}
              autoFocus={i === autoSelectTextObjectIndexes[0]}
              onChange={(key, value) => handleChange(i, key, value)}
              isHoldingShift={isHoldingShift}
              resizeProximityLinesRef={resizeProximityLinesRef}
            />
          </DesignObject>
        ) : null
      )}
      {selectedTextObjectIndexes.length > 1 && (
        <MultiObject
          textObjects={textObjects}
          selectedTextObjectIndexes={selectedTextObjectIndexes}
          onChange={(i, key, value) => handleChange(i, key, value)}
          onCopy={() => handleCopy(-1)}
          onDelete={() => handleDelete(-1)}
          onSelect={(i) => handleSelect(i)}
          onDeselect={handleDeselect}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        />
      )}
    </Container>
  )
}

CertificateText.displayName = "CertificateText"

CertificateText.propTypes = {
  textObjects: textObjectsPropType
}

export default CertificateText
