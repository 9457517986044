import CustomEvent from "custom-event"
import moment from "moment"
import PropTypes from "prop-types"
import { ROLE_LABELS, ROLE_TYPE_OFFERING } from "../invitation/constants"

export const TRANSACTION_EVENT = "transaction"
export const REGISTRATION_CREATED_EVENT = "registration:created"
export const SUBSCRIPTION_CREATED_EVENT = "subscription:created"
export const MEMBER_COHORT_CREATED_EVENT = "member-cohort:created"
export const PATH_ITEM_COMPLETED_EVENT = "path:item:completed"
export const PATHWRIGHT_READY_EVENT = "pathwright:ready"
export const USER_SIGNED_UP_EVENT = "user:signed:up"
export const USER_SIGNED_IN_EVENT = "user:signed:in"
export const USER_SIGNED_OUT_EVENT = "user:signed:out"
export const ORDER_CREATED_EVENT = "order:created"
export const ORDER_FULFILLED_EVENT = "order:fulfilled"

const utm_parameters = {
  utm_campaign: PropTypes.string,
  utm_source: PropTypes.string,
  utm_medium: PropTypes.string,
  utm_term: PropTypes.string,
  utm_content: PropTypes.string
}

const transaction = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  method: PropTypes.oneOf(["payment", "subscription", "free"]).isRequired,
  amount: PropTypes.number.isRequired,
  discount: PropTypes.shape({
    code: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired,
    cycles: PropTypes.number, // only present if product is subscription plan
    maxSeatDiscount: PropTypes.number // only present if product is member created cohort
  })
}

const user = {
  id: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired
}

const userAlt = {
  id: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
  first_name: PropTypes.string.isRequired,
  last_name: PropTypes.string.isRequired,
  full_name: PropTypes.string.isRequired
}

const registration = {
  id: PropTypes.number.isRequired,
  role: PropTypes.oneOf(Object.values(ROLE_LABELS[ROLE_TYPE_OFFERING]))
    .isRequired
}

const cohort = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired
}

const resource = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired
}

const resourceLicense = {
  id: PropTypes.number.isRequired,
  seatsCount: PropTypes.number.isRequired,
  seatsFilled: PropTypes.number.isRequired,
  seatsAvailable: PropTypes.number.isRequired,
  expirationDate: PropTypes.string.isRequired
}

const licenseOffering = {
  id: PropTypes.number.isRequired,
  costPerSeat: PropTypes.number.isRequired,
  includeCreatorSeat: PropTypes.bool.isRequired,
  includedInUserSubscription: PropTypes.bool.isRequired,
  minimumSeatCount: PropTypes.number.isRequired
}

const plan = {
  id: PropTypes.number.isRequired,
  amount: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["monthly", "yearly", "lifetime"]).isRequired
}

const subscription = {
  id: PropTypes.number.isRequired,
  isCanceledAtCycleEnd: PropTypes.bool.isRequired,
  cycleEndDate: PropTypes.string.isRequired,
  cycleStartDate: PropTypes.string.isRequired
}

const pathItem = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  sourceId: PropTypes.number.isRequired,
  points: PropTypes.number.isRequired,
  pointsAvailable: PropTypes.number.isRequired
}

const order = {
  id: PropTypes.number.isRequired,
  order_type: PropTypes.oneOf([
    "registration",
    "school_subscription",
    "gift_subscription",
    "curriculum_subscription",
    "curriculum_license",
    "group_license",
    "platform_subscription",
    "lifetime_school_subscription",
    "group_subscription"
  ]).isRequired,
  order_status: PropTypes.oneOf([
    "awaiting_payment",
    "payment_pending",
    "payment_failed",
    "fulfillment_pending",
    "fulfillment_in_progress",
    "fulfillment_failed",
    "fulfilled",
    "voided"
  ]).isRequired,
  order_data: PropTypes.object.isRequired,
  checkout_session_id: PropTypes.string,
  fulfilled_object_id: PropTypes.number
}

const EVENTS = {
  [TRANSACTION_EVENT]: {
    ...transaction,
    user: PropTypes.shape(user),
    product: PropTypes.oneOfType([
      PropTypes.shape({
        cohort: PropTypes.shape(cohort),
        resource: PropTypes.shape(resource)
      }),
      PropTypes.shape({
        subscription: PropTypes.shape(subscription)
      }),
      PropTypes.shape({
        cohort: PropTypes.shape(cohort),
        resource: PropTypes.shape(resource),
        resourceLicense: PropTypes.shape(resourceLicense),
        licenseOffering: PropTypes.shape(resourceLicense)
      })
    ]),
    utm_parameters: PropTypes.shape(utm_parameters)
  },
  [REGISTRATION_CREATED_EVENT]: {
    ...registration,
    transaction: PropTypes.shape(transaction),
    user: PropTypes.shape(user),
    cohort: PropTypes.shape(cohort),
    resource: PropTypes.shape(resource),
    utm_parameters: PropTypes.shape(utm_parameters)
  },
  [SUBSCRIPTION_CREATED_EVENT]: {
    ...subscription,
    transaction: PropTypes.shape(transaction),
    user: PropTypes.shape(user),
    plan: PropTypes.shape(plan),
    utm_parameters: PropTypes.shape(utm_parameters)
  },
  [MEMBER_COHORT_CREATED_EVENT]: {
    ...cohort,
    transaction: PropTypes.shape(transaction),
    user: PropTypes.shape(user),
    resourceLicense: PropTypes.shape(resourceLicense),
    licenseOffering: PropTypes.shape(licenseOffering),
    utm_parameters: PropTypes.shape(utm_parameters)
  },
  [PATH_ITEM_COMPLETED_EVENT]: {
    user: PropTypes.shape(user),
    resource: PropTypes.shape(resource),
    cohort: PropTypes.shape(cohort),
    pathItem: PropTypes.shape(pathItem),
    utm_parameters: PropTypes.shape(utm_parameters)
  },
  [ORDER_CREATED_EVENT]: {
    user: PropTypes.shape(userAlt),
    order: PropTypes.shape(order),
    utm_parameters: PropTypes.shape(utm_parameters)
  },
  [ORDER_FULFILLED_EVENT]: {
    user: PropTypes.shape(userAlt),
    order: PropTypes.shape(order),
    utm_parameters: PropTypes.shape(utm_parameters)
  }
}

const samplePaymentTransaction = {
  id: "ch_456",
  method: "payment",
  amount: 4,
  discount: {
    code: "60%OFF",
    amount: 6,
    percent: 0.6,
    cycles: null
  }
}

const sampleSubscriptionTransaction = {
  id: "cus_123",
  method: "subscription",
  amount: 9,
  discount: null
}

const sampleFreeTransaction = {
  id: null,
  method: "free",
  amount: 0,
  discount: {
    code: "100%OFF",
    amount: 10,
    percent: 1,
    cycles: null
  }
}

const sampleUser = {
  id: 123,
  email: "sample-user@pathwright.com",
  firstName: "Sample",
  lastName: "User",
  fullName: "Sample User"
}

const sampleRegistration = {
  id: 444,
  role: "learner"
}

const sampleCohort = {
  id: 987,
  name: "Sample Cohort"
}

const sampleResource = {
  id: 999,
  name: "Sample Resource"
}

const sampleResourceLicese = {
  id: 1001,
  seatsCount: 5,
  seatsFilled: 1,
  seatsAvailable: 4,
  expirationDate: moment()
}

const sampleLicenseOffering = {
  id: 1201,
  costPerSeat: 18,
  includeCreatorSeat: false,
  includedInUserSubscription: true,
  minimumSeatCount: 5
}

const samplePlan = {
  id: 222,
  amount: 21,
  name: "Sample Plan",
  type: "monthly"
}

const sampleSubscription = {
  id: 789,
  isCanceledAtCycleEnd: true,
  cycleEndDate: new Date(
    new Date().setDate(new Date().getDate() + 30)
  ).toISOString(),
  cycleStartDate: new Date().toISOString()
}

const samplePathItem = {
  id: 345,
  name: "Sample Path Item",
  sourceId: 345,
  points: 10,
  pointsAvailable: 10
}

// _types: the _types[key] can have any of the _types[key][keys] as it's value.
// i.e. the transation["method"] can be "payment" or "subscription". See the TRANSACTION_EVENT in the sampleEventData.
export const sampleEventData = {
  [TRANSACTION_EVENT]: {
    _types: {
      method: {
        payment: {
          ...samplePaymentTransaction,
          user: sampleUser,
          product: {
            cohort: sampleCohort,
            resorce: sampleResource,
            resourceLicense: sampleResourceLicese,
            licenseOffering: sampleLicenseOffering
          }
        },
        subscription: {
          ...sampleSubscriptionTransaction,
          user: sampleUser,
          product: {
            plan: samplePlan
          }
        }
      }
    }
  },
  [REGISTRATION_CREATED_EVENT]: {
    _types: {
      ["transaction.method"]: {
        payment: {
          ...sampleRegistration,
          transaction: samplePaymentTransaction,
          user: sampleUser,
          cohort: sampleCohort,
          resource: sampleResource
        },
        subscription: {
          ...sampleRegistration,
          transaction: sampleSubscriptionTransaction,
          user: sampleUser,
          cohort: sampleCohort,
          resource: sampleResource
        },
        free: {
          ...sampleRegistration,
          transaction: sampleFreeTransaction,
          user: sampleUser,
          cohort: sampleCohort,
          resource: sampleResource
        }
      }
    }
  },
  [SUBSCRIPTION_CREATED_EVENT]: {
    ...sampleSubscription,
    transaction: sampleSubscriptionTransaction,
    user: sampleUser,
    plan: samplePlan
  },
  [MEMBER_COHORT_CREATED_EVENT]: {
    _types: {
      ["transaction.method"]: {
        payment: {
          ...sampleCohort,
          transaction: samplePaymentTransaction,
          user: sampleUser,
          resourceLicense: sampleResourceLicese,
          licenseOffering: sampleLicenseOffering
        },
        subscription: {
          ...sampleCohort,
          resource: sampleResource,
          transaction: sampleSubscriptionTransaction,
          user: sampleUser,
          resourceLicense: sampleResourceLicese,
          licenseOffering: sampleLicenseOffering
        },
        free: {
          ...sampleCohort,
          transaction: sampleFreeTransaction,
          user: sampleUser,
          resource: sampleResource,
          resourceLicense: sampleResourceLicese,
          licenseOffering: sampleLicenseOffering
        }
      }
    }
  },
  [PATH_ITEM_COMPLETED_EVENT]: {
    user: sampleUser,
    resource: sampleResource,
    cohort: sampleCohort,
    pathItem: samplePathItem
  },
  [USER_SIGNED_UP_EVENT]: {
    user: { id: sampleUser.id }
  },
  [USER_SIGNED_IN_EVENT]: {
    user: sampleUser
  },
  [USER_SIGNED_OUT_EVENT]: {},
  [PATHWRIGHT_READY_EVENT]: {
    user: sampleUser
  }
}

/**
 * Stores standard utm parameters for the dispatcher to provide
 * in event data.
 */
export const captureUTMParameters = () => {
  const searchParams = new URLSearchParams(window.location.search)

  // grab all search param keys starting with "utm_"
  const utmParameters = Array.from(searchParams.entries()).reduce(
    (params, [key, value]) => {
      if (key.startsWith("utm_")) {
        params[key] = value
      }
      return params
    },
    {}
  )

  // TODO: should utm parameters be exired after a duration?
  if (Object.keys(utmParameters).length) {
    localStorage.setItem("utm_parameters", JSON.stringify(utmParameters))
  }
}

export const getUTMParameters = () => {
  return JSON.parse(localStorage.getItem("utm_parameters") || "{}")
}

// Clear utm_parameters if event is completion of a flow
const clearUTMParameters = (event) => {
  switch (event) {
    case ORDER_FULFILLED_EVENT:
    case REGISTRATION_CREATED_EVENT:
    case SUBSCRIPTION_CREATED_EVENT:
    case MEMBER_COHORT_CREATED_EVENT:
      localStorage.removeItem("utm_parameters")
  }
}

// Create and dispatch an event.
export const dispatchEvent = (key, data = {}, options = {}) => {
  var event = new CustomEvent(key, {
    detail: data,
    ...options
  })
  document.dispatchEvent(event)
}

// dispatchEvent(TRANSACTION_EVENT, {})
export const dispatchGlobalEvent = (event, data = {}) => {
  // grab utm parameters to pass in with event data
  const eventData = { ...data, utm_parameters: getUTMParameters() }

  PropTypes.checkPropTypes(EVENTS[event], eventData, "prop", "dispatchEvent")
  dispatchEvent(event, eventData)
  clearUTMParameters(event)
}
