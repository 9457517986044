import moment from "moment"
import { useEffect, useMemo, useRef, useState } from "react"

const useCountDown = (date, onComplete) => {
  const intervalRef = useRef(null)
  const timeoutRef = useRef(null)
  const [iterator, setIterator] = useState(0)

  const { momentDate, hours } = useMemo(() => {
    return date
      ? {
          momentDate: moment(date),
          hours: Math.abs(moment().diff(moment(date), "hours"))
        }
      : {
          momentDate: null,
          hours: null
        }
  }, [iterator, date])

  const tick = () => {
    setIterator((iterator) => iterator + 1)
    if (moment(momentDate).isBefore()) {
      // momentDate is now in the past, let's not monitor it anymore
      onComplete && onComplete()
      cleanup()
    }
  }

  const cleanup = () => {
    clearInterval(intervalRef.current)
    clearInterval(timeoutRef.current)
  }

  useEffect(() => {
    if (hours !== null) {
      if (hours < 1) {
        intervalRef.current = setInterval(tick, 5000)
      } else {
        timeoutRef.current = setTimeout(tick, 1000 * 60 * 59)
      }

      return cleanup
    }
  }, [hours])

  // used as a forceUpdate
  return iterator
}

export default useCountDown
