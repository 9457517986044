import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import Button from "@pathwright/ui/src/components/button/Button"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import gql from "graphql-tag"
import get from "lodash/get"
import PropTypes from "prop-types"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import useUpgradePrompt, {
  UPGRADE_PROMPT_KEYS,
  UPGRADE_PROMPT_TYPES
} from "../../subscription/manage/useUpgradePrompt"

const COHORTS_STATUS_QUERY = gql`
  query CohortsStatusQuery($resourceId: Int!) {
    resource(id: $resourceId) {
      id
      licensed_from_school_id
      cohorts_status_meta {
        in_progress
      }
    }
  }
`

const UpgradeCohortsGate = ({ resourceId, children }) => {
  // only show loading circle if querying for the resource on mount
  const { school } = usePathwrightContext()
  const { t } = useTranslate()

  const cohortsStatusQuery = useQuery(COHORTS_STATUS_QUERY, {
    variables: { resourceId },
    fetchPolicy: "cache-and-network",
    skip: !resourceId
  })

  const inProgressCount = get(
    cohortsStatusQuery.data,
    "resource.cohorts_status_meta.in_progress"
  )

  const { prompt, totalIncluded } = useUpgradePrompt({
    key: UPGRADE_PROMPT_KEYS.COHORTS,
    totalUsed: inProgressCount
  })

  if (resourceId && !cohortsStatusQuery.loading) {
    if (!cohortsStatusQuery.data || prompt === null)
      return <LoadingCircle center />

    switch (prompt) {
      case UPGRADE_PROMPT_TYPES.ACTIVATE:
        return (
          <BlankSlate
            icon="rocket"
            heading={t("Upgrade to Cohorts")}
            body={t(
              "Choose a plan to support one or more cohorts per course while keeping everything in sync."
            )}
            primaryAction={{
              label: t("View Plans"),
              to: "/pricing/plan/"
            }}
            secondaryAction={{
              label: `${t("Learn more")}...`,
              href: "https://help.pathwright.com/en/articles/5180927-how-to-pick-the-right-plan-based-on-cohorts-you-plan-to-offer"
            }}
            stack
          />
        )
      case UPGRADE_PROMPT_TYPES.UPGRADE:
        // ensure that the resource is either not licensed or is
        // licensed from this same school
        if (
          !cohortsStatusQuery.data.resource.licensed_from_school_id ||
          cohortsStatusQuery.data.resource.licensed_from_school_id === school.id
        ) {
          // prompt upgrade
          return (
            <BlankSlate
              icon="rocket"
              heading={t("Upgrade Cohorts")}
              body={
                <>
                  {t(
                    "Your plan includes {{ count }} in-progress cohort per course",
                    {
                      defaultValue_plural:
                        "Your plan includes {{ count }} in-progress cohorts per course",
                      count: totalIncluded
                    }
                  )}
                  .
                  <br />
                  <br />
                  You can{" "}
                  <Button
                    styleType="text"
                    href="https://help.pathwright.com/en/articles/5163383-mark-a-cohort-complete"
                  >
                    mark an existing cohort as complete
                  </Button>{" "}
                  to create another one that's in progress or upgrade your plan
                  to include more in-progress Cohorts per course.
                </>
              }
              primaryAction={{
                label: t("Upgrade"),
                to: "/pricing/plan/"
              }}
              secondaryAction={{
                label: `${t("Learn more")}...`,
                href: "https://help.pathwright.com/en/articles/5180927-how-to-pick-the-right-plan-based-on-cohorts-you-plan-to-offer"
              }}
              stack
            />
          )
        }
      // NOTE: otherwise falling through to the NONE case
      case UPGRADE_PROMPT_TYPES.NONE:
        return children
    }
  }

  return children
}

UpgradeCohortsGate.displayName = "UpgradeCohortsGate"

UpgradeCohortsGate.propTypes = {
  resourceId: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired
}

export default UpgradeCohortsGate
