import { usePdfDimensions } from "@pathwright/ui/src/components/filestack/utils"
import useDiscardFormState from "@pathwright/ui/src/components/form/form-state/useDiscardFormState"
import useDidMountEffect from "@pathwright/ui/src/components/hooks/useDidMountEffect"
import useLocalStorage from "@pathwright/ui/src/components/hooks/useLocalStorage"
import get from "lodash/get"
import pick from "lodash/pick"
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState
} from "react"
import { usePreviousDistinct } from "react-use"
import { useCertificateContext } from "../context/CertificateContext"
import {
  getVariables,
  isCustomVariable,
  useBaseVariableData
} from "../variables/variables"

const Context = createContext()
export const useCertificateState = () => useContext(Context)

// The customVariables can change independently of the certificate either
// when a variable has been merged or deleted. The goal is to set the value
// of the merged variable to the target variable.
const useMergedCustomVariables = () => {
  const { certificateScope, customVariables } = useCertificateContext()

  // NOTE: usePreviousDistinct seems to be "slow" in actually setting the value.
  // const prevCustomVariables = usePreviousDistinct(customVariables)
  const prevCustomVariables = usePreviousDistinct(customVariables)

  // return certificateState => certificateState

  const getMergedCustomVariables = useCallback(
    (certificateState) => {
      if (
        certificateScope &&
        prevCustomVariables &&
        certificateState.customVariables &&
        prevCustomVariables !== customVariables
      ) {
        const nextCustomVariables = Object.keys(
          certificateState.customVariables
        ).reduce((acc, key) => {
          // Changed at the API level, override event local changes in certificateState.
          if (prevCustomVariables[key] !== customVariables[key]) {
            return {
              ...acc,
              [key]: customVariables[key] || ""
            }
          }

          // Retaining current local state.
          return {
            ...acc,
            [key]: certificateState.customVariables[key]
          }
        }, {})

        const nextCertificateState = {
          ...certificateState,
          customVariables: nextCustomVariables
        }

        return nextCertificateState
      }

      return certificateState
    },
    [customVariables]
  )

  return getMergedCustomVariables
}

// The stored state of the certificate which can be discarded and which persists
// across browser sessions.
const useCertificateStoredState = ({
  initialCertificateState,
  certificateState,
  setCertificateState
}) => {
  const { certificate, certificateScope, customVariables } =
    useCertificateContext()

  // Only handle form state storage for those fields that can be saved.
  // The "unsaveable" changes to baseVariables and customVariables are
  // stored on separate keys that will not be cleared after the certificate
  // is saved and reloaded (by a browser refresh) due to mismatched initialValues.
  const getFormState = (data) =>
    certificateScope
      ? // NOTE: keeping template and textObjects when scoped to resource for preview purposes.
        pick(data, ["template", "textObjects", "customVariables"])
      : pick(data, ["template", "textObjects"])

  const getMergedCustomVariables = useMergedCustomVariables()

  // TODO: this should NEVER contain current state modifications.
  const initialCertificateData = useMemo(
    () => getFormState(getMergedCustomVariables(initialCertificateState)),
    [initialCertificateState]
  )

  const currentCertificateData = useMemo(
    () => getFormState(certificateState),
    [certificateState]
  )

  const formState = {
    values: currentCertificateData,
    initialValues: initialCertificateData
  }
  const storageKey = certificate
    ? ["certificate", certificate.id, certificateScope]
    : []

  const setFormState = (nextState) => {
    setCertificateState(nextState.values)
  }

  // Handling form state.
  const { discardCount, discardFormState, mergeFormState } =
    useDiscardFormState({
      key: storageKey,
      formState,
      setFormState
    })

  // Handle merging the custom variable changes into the form state and form state storage values.
  // Plus, merge that same state into the current certificateState.
  useDidMountEffect(() => {
    mergeFormState(getMergedCustomVariables)
    setCertificateState(getMergedCustomVariables(certificateState))
  }, [customVariables])

  return { discardCount, discardFormState }
}

const CertificateState = ({ children }) => {
  const { certificate, customVariables, handleSubmit } = useCertificateContext()
  const [selectedTextObjectIndexes, setSelectedTextObjectIndexes] = useState([])
  const baseVariableData = useBaseVariableData()

  const deriveVariableData = (nextCertificateState, prevCertificateState) => {
    // Default prevCertificateState to the nextCertificateState, necessary when
    // initializing certificateState.
    prevCertificateState = prevCertificateState || nextCertificateState

    // Only derive the variables data when the textObjects have changed.
    if (
      nextCertificateState.textObjects &&
      !nextCertificateState.baseVariables &&
      !nextCertificateState.customVariables
    ) {
      // Map of variable keys to their filters. The filter will filter the list of all
      // variables to achieve a subset.
      const filters = {
        baseVariables: (variable) => !isCustomVariable(variable),
        customVariables: isCustomVariable
      }

      // Assign a value to each variable. The baseVariables will be assigned a default
      // value based on baseVariableData.
      const assignDefaultValue = (variablesKey) => {
        const filter = filters[variablesKey]

        const nextVariables = getVariables(
          nextCertificateState.textObjects,
          filter
        )

        const previousVariables = getVariables(
          prevCertificateState.textObjects,
          filter
        )

        const prevVariablesData = prevCertificateState[variablesKey]

        return nextVariables.reduce((acc, key, i) => {
          // Attempt to get the default value from the prevVariablesData when the
          // number of variables hasn't changed, indicating that the variable name
          // key may have changed. This way we can still match up the previous variable
          // key's value to the new variable key.
          const accessKey =
            prevVariablesData &&
            nextVariables.length === previousVariables.length
              ? previousVariables[i]
              : key

          // Get the next value for the variable key.
          const value =
            get(prevVariablesData, accessKey) ||
            get(baseVariableData, accessKey) ||
            get(customVariables, accessKey) ||
            ""

          return {
            ...acc,
            [key]: value
          }
        }, {})
      }

      return Object.keys(filters).reduce(
        (acc, key) => ({
          ...acc,
          [key]: assignDefaultValue(key)
        }),
        nextCertificateState
      )
    }
    return nextCertificateState
  }

  // Gets the data off the certificate for initial certificateState state
  // and for after when certificate changes.
  const getCertificateState = (certificate) => {
    if (certificate) {
      return deriveVariableData({
        textObjects: certificate.text_objects,
        template: certificate.template
      })
    } else {
      return deriveVariableData({
        textObjects: [],
        template: null
      })
    }
  }

  const initialCertificateState = useMemo(
    () => getCertificateState(certificate),
    [certificate, customVariables]
  )

  // useState avoids possible duplicate executions that can come from useReducer.
  const [certificateState, _setCertificateState] = useState(
    initialCertificateState
  )
  const { template, textObjects } = certificateState

  const setCertificateState = (certificateState) =>
    _setCertificateState((state) => ({
      ...state,
      ...deriveVariableData(certificateState, state)
    }))

  // Update certificateState in state after certificate changes.
  useDidMountEffect(() => {
    setCertificateState(getCertificateState(certificate))
  }, [certificate])

  // Get indexes for the objects we're acting on.
  function getIndexes(index) {
    const isMulti = index === -1
    return isMulti ? selectedTextObjectIndexes : [index]
  }

  const selectedTextObjects = textObjects.filter((_, i) =>
    selectedTextObjectIndexes.includes(i)
  )
  const handleSelectTextObjectIndex = (index, multi) =>
    setSelectedTextObjectIndexes((indexes) => {
      return multi
        ? // When multi-select, either select or unselect the object depending
          // on if it is already selected.
          indexes.includes(index)
          ? indexes.filter((i) => i !== index)
          : [...indexes, index]
        : // Allow for retaining multi-select when selecting item in selected list.
        // This may not be the right UX, but it does permit other UX possibilities.
        indexes.includes(index)
        ? indexes
        : // index can be 0, so can't check for falsey
        typeof index === "number"
        ? // At this point we're either setting a single index or clearing all.
          [index]
        : []
    })

  // For directly setting the selected indexes.
  const handleSelectTextObjectIndexes = (indexes) => {
    const nextIndexes = indexes.reduce((acc, index) => {
      acc.push(...getIndexes(index))
      return acc
    }, [])

    setSelectedTextObjectIndexes(nextIndexes)
  }

  const handleChangeTemplate = (template) => {
    if (certificate) {
      setCertificateState({ template })
    } else {
      // Selecting a template when the certificate does not yet exists results
      // in automatically creating teh certificate.
      return handleSubmit(getCertificateState({ template }))
    }
  }

  const handleChangeTextObjects = (textObjects) =>
    setCertificateState({ textObjects })

  // handle the change of a specific key/value pair for a given textObject
  // based on textObject's index in the textObjects array
  const handleChangeTextObject = useCallback(
    (index, data) => {
      const nextTextObjects = textObjects.map((textObject, i) =>
        i === index
          ? {
              ...textObject,
              ...data
            }
          : textObject
      )

      setCertificateState({ textObjects: nextTextObjects })
    },
    [textObjects]
  )

  const handleAddTextObject = (textObject) =>
    setCertificateState({ textObjects: [...textObjects, textObject] })

  const templateDimensions = usePdfDimensions(template)

  const certificateStoredState = useCertificateStoredState({
    certificateState,
    initialCertificateState,
    setCertificateState
  })

  // Allow user to toggle proximity lines on/off.
  const [isUsingProximityLines, toggleProximityLines] = useLocalStorage(
    "isUsingCertificateProximityLines",
    true
  )

  const value = {
    certificateState,
    certificateStoredState,
    setCertificateState,
    templateDimensions,
    handleChangeTemplate,
    handleChangeTextObjects,
    handleChangeTextObject,
    handleAddTextObject,
    selectedTextObjects,
    selectedTextObjectIndexes,
    handleSelectTextObjectIndex,
    handleSelectTextObjectIndexes,
    isUsingProximityLines,
    toggleProximityLines
  }

  return (
    <Context.Provider value={value}>
      {typeof children === "function" ? children(value) : children}
    </Context.Provider>
  )
}

CertificateState.displayName = "CertificateState"

export default CertificateState
