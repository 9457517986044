import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import styled from "styled-components"
import { usePathwrightContext } from "../pathwright/PathwrightContext"
import RequestResetPasswordForm from "./RequestResetPasswordForm"
import SignInForm from "./SignInForm"
import SignUpForm from "./SignUpForm"
import SignedIn from "./SignedIn"
import { RESET_PW, SIGNED_IN, SIGN_IN, SIGN_UP } from "./constants"

const Container = styled.div`
  position: relative;
`

const SignUpOrIn = ({
  initialView,
  onViewChange,
  onContinue,
  onCompleted,
  inviteToken,
  providedEmail,
  providedFirstName,
  providedLastName,
  inverted,
  hideSignOut,
  onAuthChange,
  continueLabel,
  ...props
}) => {
  const [view, setView] = useState(props.view || initialView)
  const { me } = usePathwrightContext()

  const currentView = me
    ? SIGNED_IN // SIGNED_IN always takes priority
    : view || props.view

  useEffect(() => {
    onViewChange && onViewChange(currentView)

    if (currentView === SIGNED_IN) {
      onCompleted && onCompleted()
    }
  }, [currentView])

  const handleView = (view) => {
    setView(view)
    onViewChange && onViewChange(view)
  }

  const renderHeader = ({ header }) =>
    props.renderHeader
      ? props.renderHeader({ view: currentView, header })
      : header

  const renderSubmit = ({ submit, ...submitProps }) =>
    props.renderSubmit
      ? props.renderSubmit({
          view: currentView,
          submit,
          ...submitProps
        })
      : submit

  let form = null
  const onSignIn = () => handleView(SIGN_IN)
  const onSignUp = () => handleView(SIGN_UP)
  const onResetPw = () => handleView(RESET_PW)

  if (me) {
    form = (
      <SignedIn
        renderHeader={renderHeader}
        renderSubmit={renderSubmit}
        inverted={inverted}
        onContinue={onContinue}
        continueLabel={continueLabel}
        hideSignOut={hideSignOut}
      />
    )
  } else {
    switch (currentView) {
      case SIGN_UP:
        form = (
          <SignUpForm
            onSignIn={!props.view ? onSignIn : null}
            renderHeader={renderHeader}
            renderSubmit={renderSubmit}
            inverted={inverted}
            inviteToken={inviteToken}
            providedEmail={providedEmail}
            providedFirstName={providedFirstName}
            providedLastName={providedLastName}
            onAuthChange={onAuthChange}
          />
        )
        break
      case SIGN_IN:
        form = (
          <SignInForm
            onSignUp={!props.view ? onSignUp : null}
            onResetPw={onResetPw}
            renderHeader={renderHeader}
            renderSubmit={renderSubmit}
            inverted={inverted}
            inviteToken={inviteToken}
            onAuthChange={onAuthChange}
          />
        )
        break
      case RESET_PW:
        form = (
          <RequestResetPasswordForm
            onSignIn={onSignIn}
            renderHeader={renderHeader}
            renderSubmit={renderSubmit}
            inverted={inverted}
          />
        )
        break
      default:
        form = null
    }
  }

  return <Container>{form}</Container>
}

SignUpOrIn.displayName = "SignUpOrIn"

SignUpOrIn.propTypes = {
  initialView: PropTypes.oneOf([SIGN_UP, SIGN_IN, RESET_PW]),
  inverted: PropTypes.bool,
  // render custom header and/or submit button
  renderHeader: PropTypes.func,
  renderSubmit: PropTypes.func,
  // signed in and continue
  onContinue: PropTypes.func,
  // these props make it possible to use SignUpOrIn as a
  // controlled component. Useful for syncing w/ routes.
  view: PropTypes.oneOf([SIGN_UP, SIGN_IN, RESET_PW]),
  onViewChange: PropTypes.func,
  onCompleted: PropTypes.func,
  inviteToken: PropTypes.string, // membership invite token
  providedEmail: PropTypes.string,
  providedFirstName: PropTypes.string,
  providedLastName: PropTypes.string,
  hideSignOut: PropTypes.bool // hide sign out button
}

SignUpOrIn.defaultProps = {
  initialView: SIGN_IN
}

export default SignUpOrIn
