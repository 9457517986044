import classnames from "classnames"
import first from "lodash/first"
import get from "lodash/get"
import PropTypes from "prop-types"
import { useCallback } from "react"
import styled from "styled-components"
import { getSeedRandomColor } from "../utils/colors"
import { sizePropType } from "../utils/props"
import "./Avatar.css"

const highlightSize = 1
const Highlight = styled.div`
  display: none;
  position: absolute;
  top: -${highlightSize + 1}px;
  left: -${highlightSize + 1}px;
  width: calc(100% + ${highlightSize * 2 + 2}px);
  height: calc(100% + ${highlightSize * 2 + 2}px);
  border-radius: 50%;
  background: white;
  border: ${highlightSize}px solid var(--avatar-highlight);
`

// Chrome doesn't allow an em/rem size that evaluates to less than 9px, so to protect against odd sizing, enforcing a min of 9px
const MIN_PX_SIZE = 9

// gets size for the CSS key based on the sizeUnit plus enforces a minimum size for non-pixel units
const getMinSize = (key, sizeNumber, sizeUnit, minSize) => {
  const sizeMap = {
    [key]: `${sizeNumber}${sizeUnit}`
  }

  if (sizeUnit !== "px") {
    // force a minimum pixel size
    sizeMap[key] = `max(${sizeNumber}${sizeUnit}, ${minSize}px)`
  }

  return sizeMap
}

// Firebase data shape
const getAvatarImage = (user, sizeNumber, sizeUnit) => {
  const multiplier = {
    px: 1,
    em: 16
  }[sizeUnit]

  const totalSize = sizeNumber * multiplier

  if (user && user.avatar) {
    if (totalSize <= 80) {
      return user.avatar.small
    } else if (totalSize > 80 && totalSize <= 250) {
      return user.avatar.medium
    } else if (totalSize > 250) {
      return user.avatar.large
    }
  }

  return null
}

export const getAvatarInitials = (user, initials) => {
  if (!initials && user) {
    const firstInitial = first(user.first_name || "?")
    const lastInitial = first(user.last_name || "?")
    initials = `${firstInitial}${lastInitial}`.toUpperCase()
  }

  return initials
}

export const getAvatarFallbackBG = ({ user, userId, initials }) => {
  initials = getAvatarInitials(user, initials)
  const seed = user?.id || userId || initials?.toLowerCase() || "?"
  return getSeedRandomColor(seed)
}

const Avatar = ({
  user,
  userId,
  initials,
  image,
  size,
  style,
  className,
  onClick,
  highlight
}) => {
  const { number: sizeNumber, unit: sizeUnit } = sizePropType._serialize(size)

  initials = getAvatarInitials(user, initials)
  const color = getAvatarFallbackBG({ user, userId, initials })

  // TODO: possibly refetch larger image if provided image is too small (/ too big?)
  image =
    image ||
    get(user, "profile.image") ||
    getAvatarImage(user, sizeNumber, sizeUnit)
  // don't show default profile image
  if (image && image.indexOf("profile-default") > -1) {
    image = null
  }

  className = classnames("Avatar", { "Avatar--with-img": !!image }, className)

  // get fontSize based on size
  const fontSize = sizeNumber * 0.45

  style = {
    ...style,
    ...getMinSize("width", sizeNumber, sizeUnit, MIN_PX_SIZE * 2),
    ...getMinSize("height", sizeNumber, sizeUnit, MIN_PX_SIZE * 2),
    ...getMinSize("lineHeight", sizeNumber, sizeUnit, MIN_PX_SIZE * 2)
  }

  // adding size var
  style["--avatar-size"] = style.width

  const flexStyle = {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center"
  }

  // only add flex styling to parent div if no image (initials are used)
  if (!image) {
    style = {
      ...style,
      ...flexStyle
    }
  }

  const initialialsStyle = {
    ...flexStyle,
    ...getMinSize("fontSize", fontSize, sizeUnit, MIN_PX_SIZE),
    height: "100%",
    width: "100%",
    lineHeight: "100%",
    backgroundColor: image ? "none" : color
  }

  const handleClick = useCallback(
    () => user && onClick && onClick(user.id),
    [user, onClick]
  )

  return (
    <div className={className} style={style} onClick={handleClick}>
      <Highlight className="Avatar--highlight" />
      {image ? (
        <img className="Avatar--img" style={style} src={image} alt="Avatar" />
      ) : (
        <div className="Avatar--initials" style={initialialsStyle}>
          {initials}
        </div>
      )}
    </div>
  )
}

Avatar.displayName = "Avatar"

Avatar.propTypes = {
  user: PropTypes.object,
  // NOTE: relative sizing (i.e. "em") can produce unexpected font-sizes in Chrome (maybe other browsers) that result in disproportionate initials/circle due to some enforcement of a minium font-size when using relative units!
  // Basically, if you want small Avatar, you should probably use pixels =/
  size: sizePropType.isRequired,
  onClick: PropTypes.func
}

Avatar.defaultProps = {
  size: "60px"
}

export default Avatar
