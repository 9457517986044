import gql from "graphql-tag"

export default gql`
  fragment MembershipMeta on MembershipMeta {
    editor
    teacher
    moderator
    learner
    observer
    lead_mentor
    mentor
  }
`
