import classnames from "classnames"
import IconButton from "../../button/IconButton"
import FieldWrapper from "../form-utils/FieldWrapper"
import "./CounterInput.css"

const CounterInput = (props) => {
  const { onChange, className, autoFocus, ...rest } = props
  const getValue = (value) => Math.max(Math.min(rest.max, value), rest.min)
  const num = rest.value || 0
  return (
    <FieldWrapper {...props} className={classnames("CounterInput", className)}>
      {({ focus, onFocus, onBlur, registerInput }) => {
        return (
          <div className="CounterInput__wrapper">
            <IconButton
              icon="minus-circle"
              onClick={(e) => onChange(getValue(num - 1), e)}
            />
            <input
              {...rest}
              type="number"
              ref={registerInput}
              autoFocus={autoFocus || focus}
              onChange={(e) => {
                if (typeof onChange === "function") {
                  const value = parseInt(e.target.value)
                  if (isNaN(value)) {
                    onChange(null, e)
                  } else {
                    onChange(getValue(value), e)
                  }
                }
              }}
              onFocus={onFocus}
              onBlur={onBlur}
            />
            <IconButton
              icon="plus-circle"
              onClick={(e) => onChange(getValue(num + 1), e)}
            />
          </div>
        )
      }}
    </FieldWrapper>
  )
}

CounterInput.displayName = "CounterInput"

CounterInput.defaultProps = {
  min: 1,
  max: Infinity
}

export default CounterInput
