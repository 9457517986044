import ResourceBackground from "@pathwright/ui/src/components/background/ResourceBackground"
import ThemeBackground from "@pathwright/ui/src/components/background/ThemeBackground"
import IconButton from "@pathwright/ui/src/components/button/IconButton"
import { withScreensize } from "@pathwright/ui/src/components/ui/Screensize"
import { observer } from "mobx-react"
import invitationStore from "../store/invitation-store"
import "./InvitationHeader.css"

const InvitationHeader = ({ card, screensize }) => {
  const { context } = invitationStore
  const { offering, resource } = context || {}
  const roundedTop = screensize !== "sm"
  return (
    <header className="InvitationHeader">
      {resource ? (
        <ResourceBackground resource={resource} roundedTop={roundedTop} />
      ) : (
        <ThemeBackground roundedTop={roundedTop} />
      )}
      <div className="InvitationHeader__content">
        <IconButton
          icon={card.isIndex ? "x" : "arrow-left"}
          onClick={() => card.onClose()}
          inverted
        />
        <h2>
          Invitations to{" "}
          {offering && !offering.is_curriculum
            ? offering.name
            : resource
            ? resource.name
            : school.name}
        </h2>
      </div>
    </header>
  )
}

InvitationHeader.displayName = "InvitationHeader"

export default observer(withScreensize(InvitationHeader))
