import { graphql } from "@apollo/client/react/hoc"
import Alert from "@pathwright/ui/src/components/alert/Alert"
import Card from "@pathwright/ui/src/components/card/Card"
import CardHeader from "@pathwright/ui/src/components/card/CardHeader"
import { CardStackContext } from "@pathwright/ui/src/components/card/CardStack"
import FullscreenConfirm from "@pathwright/ui/src/components/confirm/FullscreenConfirm"
import withTranslate from "@pathwright/ui/src/components/lng/withTranslate"
import compose from "lodash/flowRight"
import get from "lodash/get"
import { Component } from "react"
import CREATE_INVITATIONS_MUTATION from "../../../invitation/graphql/create-invitations-mutation"
import Lookup from "../../../lookup/Lookup"
import {
  MENTOR_GROUP_MEMBERSHIP_ROLE_INPUTS,
  MENTOR_GROUP_MEMBERSHIP_ROLE_MEMBER
} from "../../constants"
import BATCH_CREATE_MENTOR_GROUP_MEMBERSHIP_MUTATION from "../../graphql/batch-create-mentor-group-membership-mutation"
import MENTOR_GROUP_QUERY from "../../graphql/mentor-group-query"
import AddMentorGroupMembersAction from "./AddMentorGroupMembersAction"
import AddMentorGroupMembersList from "./AddMentorGroupMembersList"

class AddMentorGroupMembersCard extends Component {
  state = {
    inputValue: "",
    role: MENTOR_GROUP_MEMBERSHIP_ROLE_MEMBER,
    items: [], // [{ type: "email", email: "..." }, { type: "user", user: {...} }]
    error: null,
    submitting: false
  }

  handleAdd = (item) => {
    this.setState((state) => ({
      inputValue: "",
      items: state.items.concat(item)
    }))
  }

  handleRemove = (index) => {
    this.setState((state) => ({
      items: state.items.filter((_, i) => i !== index)
    }))
  }

  handleRole = (role) => {
    this.setState({ role })
  }

  handleSubmit = async (e) => {
    const { t } = this.props
    let role = MENTOR_GROUP_MEMBERSHIP_ROLE_INPUTS[this.state.role]
    let userIds = []
    let inviteEmails = []
    this.state.items.forEach((item) => {
      if (item.type === "user") {
        userIds.push(item.user.id)
      }
      if (item.type === "email") {
        inviteEmails.push(item.email)
      }
    })
    try {
      let membersCreatedCount = 0
      if (userIds.length) {
        this.setState({ submittingStatus: t("Creating memberships...") })
        const membershipsResult = await this.props.createMemberships({
          user_ids: userIds,
          role
        })
        membersCreatedCount = get(membershipsResult, "data.batchCreateMentorGroupMembership.group_memberships_created_count", 0) // prettier-ignore
      }
      let successfulInvitesCount = 0
      let unsuccessfulInvitesCount = 0
      if (inviteEmails.length) {
        this.setState({ submittingStatus: t("Creating invitations...") })
        const invitationsResult = await this.props.createInvitations({
          invite_emails: inviteEmails,
          role
        })
        unsuccessfulInvitesCount = get(invitationsResult, "data.createInvitations.unsuccessful_invitations.length", 0) // prettier-ignore
        successfulInvitesCount = get(invitationsResult, "data.createInvitations.invitations_sent_count", 0) // prettier-ignore
      }
      const messages = []
      if (membersCreatedCount) {
        messages.push(
          t("{{ count }} member added.", {
            defaultValue_plural: "{{ count }} members added.",
            count: membersCreatedCount
          })
        )
      }
      if (successfulInvitesCount) {
        messages.push(
          t("{{ count }} invite email sent.", {
            defaultValue_plural: "{{ count }} invite emails sent.",
            count: successfulInvitesCount
          })
        )
      }
      if (unsuccessfulInvitesCount) {
        messages.push(
          t("({{ count }} invite email could not be sent.)", {
            defaultValue_plural:
              "({{ count }} invite emails could not be sent.)",
            count: successfulInvitesCount
          })
        )
      }
      let noSuccesses = false
      let message = messages.join(" ")
      if (
        membersCreatedCount < 1 &&
        successfulInvitesCount < 1 &&
        unsuccessfulInvitesCount > 0
      ) {
        noSuccesses = true
        // override message when not successful
        message = t(
          "No invites could be sent. The user may already be a part of this group or have an invite."
        )
      }
      this.setState({
        items: [],
        confirm: {
          icon: noSuccesses ? "caution-triangle" : "check",
          body: message,
          hideCancel: true,
          confirmPrompt: t("Done")
        }
      })
    } catch (error) {
      alert(error.message)
      this.setState({ error })
    } finally {
      this.setState({ submittingStatus: "" })
    }
  }

  render() {
    const { items, inputValue, confirm } = this.state
    const { card, mentorGroup, t } = this.props

    const seatsAvailable = Math.max(
      0,
      mentorGroup ? mentorGroup.seats_available : 0
    )

    if (!mentorGroup) {
      return <Card card={card} navless noaction />
    }

    return (
      <CardStackContext.Consumer>
        {(cardStackContext) => (
          <Card card={card} navless noaction style={{ paddingBottom: 0 }}>
            {confirm && (
              <FullscreenConfirm
                {...confirm}
                onConfirm={async () => {
                  this.setState({ confirm: null })
                  if (this.props.onSubmitSuccess) this.props.onSubmitSuccess()
                  if (cardStackContext && cardStackContext.popCard)
                    cardStackContext.popCard()
                }}
              />
            )}
            <CardHeader
              card={card}
              title={`Add to ${mentorGroup.name}`}
              meta=""
            />
            <div style={{ padding: "10px 25px 0" }}>
              {mentorGroup.type == 15 ? (
                <Alert
                  info={t(
                    "There is {{ count }} more subscription seat available",
                    {
                      defaultValue_plural:
                        "There are {{ count }} more subscription seats available",
                      count: seatsAvailable
                    }
                  )}
                />
              ) : null}
              <Lookup
                placeholder={t("Enter email or search members...")}
                inputValue={inputValue}
                onSelect={(item) => {
                  this.handleAdd(item)
                  this.setState({ inputValue: "" })
                }}
                onInputValueChange={(inputValue) => {
                  this.setState({ inputValue })
                }}
              />
            </div>
            <AddMentorGroupMembersList
              items={items}
              onRemove={this.handleRemove}
              mentorGroup={mentorGroup}
            />
            {items.length > 0 && (
              <AddMentorGroupMembersAction
                permissions={mentorGroup.permissions}
                addingCount={items.length}
                selectedRole={this.state.role}
                onRoleChange={this.handleRole}
                onSubmit={this.handleSubmit}
                submittingStatus={this.state.submittingStatus}
              />
            )}
          </Card>
        )}
      </CardStackContext.Consumer>
    )
  }
}

export default compose(
  withTranslate,
  graphql(MENTOR_GROUP_QUERY, {
    options: ({ id }) => ({
      variables: { id }
    }),
    props: ({ data }) => ({
      mentorGroup: get(data, "school.mentor_group")
    })
  }),
  graphql(CREATE_INVITATIONS_MUTATION, {
    props: ({ mutate, ownProps }) => ({
      createInvitations: ({ role, invite_emails }) =>
        mutate({
          variables: {
            context: { community_group_id: ownProps.id },
            invite_to_community_group_role: role,
            type: "community_group",
            invite_emails
          },
          refetchQueries: ["MentorGroups", "MentorGroupInvitations"]
        })
    })
  }),
  graphql(BATCH_CREATE_MENTOR_GROUP_MEMBERSHIP_MUTATION, {
    props: ({ mutate, ownProps }) => ({
      createMemberships: ({ role, user_ids }) =>
        mutate({
          variables: {
            group: ownProps.id,
            user_ids,
            role
          },
          refetchQueries: [
            "MentorGroups",
            "MentorGroupStaffMemberships",
            "MentorGroupMemberships"
          ]
        })
    })
  })
)(AddMentorGroupMembersCard)
