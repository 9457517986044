import PropTypes from "prop-types"
import { Component } from "react"
import { PathwrightContext } from "../../pathwright/PathwrightContext"
import Browse from "./Browse"

class BrowseContainer extends Component {
  state = {
    activeFacets: null
  }

  handleChangeFacets = (activeFacets) => {
    this.setState({ activeFacets })
  }

  render() {
    return (
      <Browse
        schoolId={this.props.schoolId}
        browseTo={this.props.browseTo}
        structure={this.props.structure}
        activeFacets={this.state.activeFacets}
        onChangeFacets={this.handleChangeFacets}
        hideBannerAction={!!this.props.schoolId} // hide banner action button if scoped to another school
        resourcesQueryOptions={{
          fetchPolicy: "network-only",
          includeSchoolLicense: !!this.props.schoolId, // load own school licenses if scoped to another school
          licenseeSchoolId: this.context.school.id,
          schoolId: this.props.schoolId, // if set, scopes resources to another school
          licensingMode: this.props.licensingMode
        }}
      >
        {this.props.children}
      </Browse>
    )
  }
}

BrowseContainer.displayName = "BrowseContainer"

BrowseContainer.contextType = PathwrightContext

BrowseContainer.propTypes = {
  // optional, render something additional (like a back button)
  children: PropTypes.any,
  // block strucutre
  structure: PropTypes.array.isRequired,
  // configure resource link
  browseTo: PropTypes.func,
  // scope queries to another school
  schoolId: PropTypes.number
}

export default BrowseContainer
