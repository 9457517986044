import { removeHTML } from "./general"

export type MuxVideoUpload = {
  url: string
  id: string
  status: string
}

export type CreateMuxVideoResponse = {
  id: string
  name: string
  upload: MuxVideoUpload
}

export type MuxFileInput = MuxFileData & {
  muxVideoID: string | null
}

export type MuxFileData = {
  filename: string
  lastModified: number
  lastModifiedDate: number
  size: number
  type: string
}

export const createMuxDownloadURL = (url?: string, name?: string): string => {
  if (!url) return ""
  if (!name) return url

  // Replace Mux's filename with the media item's name
  const re = /download=(.+?)&/gims
  const match = re.exec(url)
  if (match && match[1]) {
    return url.replace(match[1], encodeURIComponent(removeHTML(name)))
  }

  return url // fallback
}
