import { Box, Button, VStack } from "@chakra-ui/react"
import Link from "@pathwright/ui/src/components/link/Link"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import ChakraTooltip from "@pathwright/ui/src/components/tooltip/ChakraTooltip"
import { forwardRef } from "react"
import { usePathwrightContext } from "../../../pathwright/PathwrightContext"
import getStripePaymentPortalHref from "../../../stripe/utils/getStripePaymentPortalHref"

const MentorGroupSeats = forwardRef(function MentorGroupSeats(
  { mentorGroup, children, ...props },
  ref
) {
  const { t } = useTranslate()
  const { school } = usePathwrightContext()

  return mentorGroup?.seat_limit ? (
    <ChakraTooltip
      title={
        mentorGroup.type === 15 ? (
          <VStack spacing={0} alignItems="flex-start">
            <span>
              {t("share_card.meta.group.seats.subscription_seats_remaining", {
                count: mentorGroup.seats_available
              })}
            </span>

            <Button
              variant="link"
              as={Link}
              href={getStripePaymentPortalHref(school)}
              target="_blank"
              color="inherit"
              fontSize="1em"
              fontWeight="normal"
              whiteSpace="normal"
              lineHeight="1.5"
            >
              {t(
                "share_card.meta.group.seats.subscription_seats_purchase_more"
              )}
            </Button>
          </VStack>
        ) : (
          <VStack alignItems="flex-start">
            <span>
              {t("share_card.meta.group.seats.seats_remaining", {
                count: mentorGroup.seats_available
              })}
            </span>
          </VStack>
        )
      }
    >
      <Button
        ref={ref}
        as={Link}
        href={getStripePaymentPortalHref(school)}
        variant="solid"
        colorScheme="gray"
        size="sm"
        fontWeight="normal"
        {...props}
      >
        {children || <span>{t("mentor_group.seats", mentorGroup)}</span>}
        {mentorGroup.seats_available < 0 && (
          <Box
            pos="absolute"
            right="-.1em"
            top="-.1em"
            w=".6em"
            h=".6em"
            bg="red"
            borderRadius="50%"
          />
        )}
      </Button>
    </ChakraTooltip>
  ) : null
})

export default MentorGroupSeats
