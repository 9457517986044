import classnames from "classnames"
import PropTypes from "prop-types"
import styled from "styled-components"
import Stack from "../stack/Stack"
import { sizePropType } from "../utils/props"
import Tag from "./Tag"
import { tagPropType } from "./propTypes"

const Container = styled.div`
  /* Contain the stack to the max-width of the container */
  overflow: hidden;

  .Stack {
    /* Default is "center" but items should start left-aligned */
    justify-content: flex-start;

    > li > div {
      /* Tags have a semitransparent background, so to properly stack
        Tags, need to supply a underlying opaque background. */
      background-color: white;
      box-shadow: rgb(0 0 0 / 50%) 0px 0px 3px 0px;

      .Tag {
        margin: 0 !important;
      }
    }
  }
`

const TagStack = ({ className, style = {}, tags, tagSize, limit }) => {
  if (!tags || !tags.length) return null

  // Get max stack of tags based on limit.
  const stackedTags = tags.slice(0, limit)

  // Serialize the number and unit off of the tagSize.
  const { number, unit } = sizePropType._serialize(tagSize)
  // Obtain the maxWidth style of the containing element based
  // on the number of stacked tags times the tagSize width.
  const maxWidth = sizePropType._serialize({
    number: stackedTags.length * number,
    unit
  }).style

  return (
    <Container
      className={classnames("TagStack", className)}
      style={{ maxWidth, ...style }}
    >
      <Stack items={stackedTags} itemOffset={tagSize}>
        {({ item, itemWidth }) => (
          <div
            style={{
              width: itemWidth,
              // Since the div has an opaque background, must match the child border-radius.
              borderRadius: Tag._getStyles({}, { data: item }).borderRadius
            }}
          >
            <Tag tag={item} />
          </div>
        )}
      </Stack>
    </Container>
  )
}

TagStack.displayName = "TagStack"

TagStack.propTypes = {
  tags: PropTypes.arrayOf(tagPropType),
  tagSize: sizePropType,
  limit: PropTypes.number
}

TagStack.defaultProps = {
  tagSize: "20px",
  limit: 3
}

export default TagStack
