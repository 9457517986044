import PropTypes from "prop-types"
import { featurePropType } from "../propTypes"
import FeatureBlock from "./FeatureBlock"

const FeatureBlockItem = ({ feature, to }) => (
  <FeatureBlock
    featureKey={feature.feature_key}
    featurePermission={feature.permission_in_context}
    to={to}
  />
)

FeatureBlockItem.displayName = "FeatureBlockItem"

FeatureBlockItem.propTypes = {
  feature: PropTypes.shape(featurePropType).isRequired
}

export default FeatureBlockItem
