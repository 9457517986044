import ChakraTooltip from "@pathwright/ui/src/components/tooltip/ChakraTooltip"
import BulletSeparation from "@pathwright/ui/src/components/ui/BulletSeparation"
import moment from "moment"
import styled from "styled-components"
import { timeago } from "../../utils"
import DueStatus from "./DueStatus"

const WhenContainer = styled(BulletSeparation)`
  /* prevent text wrapping */
  /* white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; */

  /* font-size: 0.9em; */

  /* .Completion--When--Status {
    font-size: 0.8em;
    margin-left: 5px;
  } */
`

// TODO: add tooltips here to show full due/completion times
const When = ({ item, showDueStatus }) => {
  const {
    data: {
      completion: { completion_date },
      due
    }
  } = item

  // we still need to show the badge for incomplete but late items
  if (!completion_date && !due) return null

  // tooltip should show full date/time
  const timeFormat = "MMM D, YYYY h:mm A"
  const completionTooltip = completion_date
    ? moment(completion_date).format(timeFormat)
    : null

  return (
    <WhenContainer className="Completion--When">
      {!!completion_date && (
        <ChakraTooltip title={completionTooltip}>
          <span>{timeago(completion_date)}</span>
        </ChakraTooltip>
      )}
      {showDueStatus && <DueStatus item={item} />}
    </WhenContainer>
  )
}

When.defaultProps = {
  showDueStatus: false
}

export default When
