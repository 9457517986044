import gql from "graphql-tag"
import SCHOOL_FRAGMENT from "../../school/graphql/school-fragment"
import USER_FRAGMENT from "../../user/graphql/user-fragment"
import MENTOR_GROUP_FRAGMENT from "./mentor-group-fragment"

export default gql`
  query MentorGroups(
    $cursor: String
    $id: IDFilter
    $parentId: IDFilter
    $descendentOfParentId: EquivalenceIDFilter
    $seatLimitFilter: IntFilter
    $search: String
  ) {
    school {
      ...School
      mentor_groups(
        after: $cursor
        first: 25
        id: $id
        parent_id: $parentId
        descendant_of_parent_id: $descendentOfParentId
        seat_limit_filter: $seatLimitFilter
        search: $search
      ) {
        total
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            ...MentorGroup
            memberships(first: 5) {
              total
              edges {
                node {
                  id
                  user {
                    ...User
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${SCHOOL_FRAGMENT}
  ${MENTOR_GROUP_FRAGMENT}
  ${USER_FRAGMENT}
`
