import Avatar from "@pathwright/ui/src/components/avatar/Avatar"
import Indicator from "@pathwright/ui/src/components/indicator/Indicator"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import BulletSeparation from "@pathwright/ui/src/components/ui/BulletSeparation"
import { capitalCase } from "change-case"
import moment from "moment"
import { useRef } from "react"
import styled from "styled-components"
import { getCompletionAction } from "../../completion/utils"
import { actionIconColors } from "../../notifications/utils"
import { useReviewContext } from "../state/ReviewContextProvider"
import FeedbackResponses from "../ui/meta/FeedbackResponses"
import { DeferredFeedbackReviewers } from "../ui/meta/FeedbackReviewers"
import { ReviewStatusBadge } from "../ui/meta/ReviewAction"
import "./InboxFocusCompletionItem.css"

const Container = styled.div`
  --border-radius: 16px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  /* border-radius: 6px; */

  color: #000;
  background-color: #fff;
  /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); */
  /* margin-bottom: 10px; */
  border-bottom: 1px solid #dde4ed;
  cursor: pointer;

  &:last-child {
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
  }

  &:first-child {
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
  }

  &:hover {
    background-color: #fff;
    .CompletionItemControls {
      visibility: visible;
    }
  }

  h1 {
    font-size: 16px;
    margin: 0px;
    margin-right: 10px;
  }

  > span {
    margin-left: 4px;
  }

  > main {
    margin-left: 10px;
    flex-direction: column;

    > .meta,
    > .meta .Button {
      display: flex;
      font-size: 14px;
      line-height: 20px;
      color: #868e9b;
      > span {
        margin-right: 4px;
      }
    }
  }

  @media (max-width: 600px) {
    > main {
      display: flex;
      flex-direction: column;
    }
  }

  .CompletionItemControls {
    position: absolute;
    right: 0px;
    top: 0px;
    visibility: hidden;
  }

  .Pathicon {
    color: #868e9b;
  }
`

const CompletionAvatarContainer = styled.div`
  position: relative;
  .Pathicon {
    position: absolute;
    top: -2px;
    right: 0px;
    background-color: ${(props) => props.color};
    border-radius: 50%;
    width: 14px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    svg {
      z-index: 1;
      color: #fff;
      width: 10px;
      height: 10px;
    }
  }

  .Avatar {
    margin-right: 10px;
    flex-shrink: 0;
  }
`

const StyledIndicator = styled(Indicator)`
  position: absolute;
  left: -0.4em;
`

export const CompletionAvatar = ({ item, size }) => {
  const { user, data: step } = item
  const completion = step.completion
  const icon = completion.is_complete ? "check" : "x"
  const color = completion.is_complete ? actionIconColors.green : "#999"

  return (
    <CompletionAvatarContainer color={color} className="CompletionAvatar">
      <Avatar user={user} size={size} />
      <Pathicon icon={icon} color={color} />
    </CompletionAvatarContainer>
  )
}

CompletionAvatar.defaultProps = {
  size: 36
}

// should take up any available space in a flex container
const Spacer = styled.div`
  flex-grow: 1;
`

const InboxFocusCompletionItem = ({ item }) => {
  const ref = useRef(null)
  const {
    selected,
    actions: { select, getItem, markReviewed }
  } = useReviewContext()

  // TODO: figure out a workaround for this, this is to make sure the item is
  // actually up-to-date w/ any changes to the item in the context store
  let node = getItem(item.id)
  if (!node) return null

  const handleSelect = () => {
    select(node)
  }

  const handleSelectControl = (item, action) => {
    if (action === "markAsReviewed") {
      markReviewed(item)
    }
  }

  const {
    data: { completion },
    user
  } = node

  const isSelected = selected && selected.id === node.id

  let metaLabel = `${capitalCase(getCompletionAction(completion))}`
  if (completion.is_complete) {
    metaLabel = `${metaLabel} ${moment(completion.completion_date).fromNow()}`
  }

  return (
    <Container
      ref={ref}
      className={"InboxFocusCompletionItem" + (isSelected ? ` selected` : ``)}
      onClick={handleSelect}
    >
      <CompletionAvatar item={node} />
      <main>
        <strong>{user.full_name}</strong>
        <BulletSeparation className="meta">
          <span>{metaLabel}</span>
          <BulletSeparation className="meta-reviewers">
            <DeferredFeedbackReviewers
              item={item}
              parentNode={ref.current}
              getLabel={(t, { count, limit }) =>
                count - limit > 0 ? `+${count - limit}` : ""
              }
              highlightResponders
            />
            <FeedbackResponses item={item} />
          </BulletSeparation>
        </BulletSeparation>
      </main>
      <Spacer />
      <ReviewStatusBadge item={node} />
      <Pathicon icon="chevron-right" />
    </Container>
  )
}

export default InboxFocusCompletionItem
