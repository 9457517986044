import gql from "graphql-tag"
import USER_FRAGMENT from "../../user/graphql/user-fragment"
import SCHOOL_SUBSCRIPTION_PLAN from "./school-subscription-plan-fragment"

export default gql`
  fragment SchoolGiftSubscription on SchoolGiftSubscription {
    id
    redeemed
    recipient_email
    recipient_email_sent_time
    recipient_first_name
    recipient_last_name
    gifted_subscription_cycles
    gift_message
    gift_code
    redemption_link
    sender {
      ...User
    }
    school_subscription_plan {
      ...SchoolSubscriptionPlan
    }
  }
  ${USER_FRAGMENT}
  ${SCHOOL_SUBSCRIPTION_PLAN}
`
