import gql from "graphql-tag"

export default gql`
  query ReportQuery(
    $community_groups: [ReportInput]
    $community_memberships: [ReportInput]
    $group_registrations: [ReportInput]
    $group_performance_points: [ReportInput]
    $group_performance_completion: [ReportInput]
    $school_resources: [ReportInput]
    $school_cohorts: [ReportInput]
    $school_members: [ReportInput]
    $school_member_progress: [ReportInput]
    $school_registrations: [ReportInput]
    $school_coupon_summary: [ReportInput]
    $school_coupon_redemptions: [ReportInput]
    $licensing_store_connected_accounts: [ReportInput]
    $licensing_store_resource_usage: [ReportInput]
    $licensing_store_member_progress: [ReportInput]
    $licensing_member_created_cohorts: [ReportInput]
  ) {
    report(
      community_groups: $community_groups
      community_memberships: $community_memberships
      group_registrations: $group_registrations
      group_performance_points: $group_performance_points
      group_performance_completion: $group_performance_completion
      school_resources: $school_resources
      school_cohorts: $school_cohorts
      school_members: $school_members
      school_member_progress: $school_member_progress
      school_registrations: $school_registrations
      school_coupon_summary: $school_coupon_summary
      school_coupon_redemptions: $school_coupon_redemptions
      licensing_store_connected_accounts: $licensing_store_connected_accounts
      licensing_store_resource_usage: $licensing_store_resource_usage
      licensing_store_member_progress: $licensing_store_member_progress
      licensing_member_created_cohorts: $licensing_member_created_cohorts
    )
  }
`
