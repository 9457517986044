import { useMutation } from "@apollo/client"
import PopupAlert from "@pathwright/ui/src/components/alert/PopupAlert"
import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import Card from "@pathwright/ui/src/components/card/Card"
import CardBlock from "@pathwright/ui/src/components/card/CardBlock"
import CodeEditor from "@pathwright/ui/src/components/form/form-code-editor/LazyCodeEditor"
import PropTypes from "prop-types"
import React from "react"
import { Field, Form, Submit } from "react-connect-form-forked"
import UPDATE_SCHOOL_CUSTOM_CSS_MUTATION from "../../school/graphql/update-school-custom-css-mutation"

const ManageCustomCSS = ({ card, initialValue, onSubmit, theme = {} }) => {
  const [updateSchoolCustomCSSMutation] = useMutation(
    UPDATE_SCHOOL_CUSTOM_CSS_MUTATION
  )

  const [error, setError] = React.useState(null)

  const replaceCustomCSS = (nextCSS = "") => {
    const styleTag = document.querySelector("style[data-custom-css]")
    if (styleTag) {
      styleTag.innerHTML = nextCSS
    } else {
      const style = document.createElement("style")
      style.setAttribute("data-custom-css", "")
      style.innerHTML = nextCSS
      document.head.appendChild(style)
    }
  }

  const handleSubmit = async ({ css }) => {
    const { id, name } = theme
    // For now, continue to use backend for creating themes
    if (!id) return onSubmit({ css })
    let result = {}

    try {
      result = await updateSchoolCustomCSSMutation({
        variables: {
          id,
          customCss: css,
          name // required by backend model
        }
      })
    } catch (error) {
      return setError(error)
    }

    const { data } = result
    const { updateSchoolCustomCSS = {} } = data

    replaceCustomCSS(updateSchoolCustomCSS.processed_custom_css)

    // onSubmit({
    //   css: updateSchoolCustomCSS.custom_css
    // })
  }

  return (
    <Card card={card} title="Custom CSS">
      <Form initialValue={initialValue} onSubmit={handleSubmit}>
        <CardBlock>
          <Field
            name="css"
            label="Override default styles with custom CSS"
            instructions="An advanced option for more deeply customizing the look and feel of your Account. Keep in mind that class names, IDs, and default styles may change in the future without warning."
            component={CodeEditor}
            mode="css"
            labelWidth={320}
            topLabel
          />
        </CardBlock>
        <Submit styleType="primary" component={SubmitButton}>
          Save
        </Submit>
      </Form>
      {error && (
        <PopupAlert
          error={error}
          onClear={() => setError(null)}
          clearAfter={null}
        />
      )}
    </Card>
  )
}

ManageCustomCSS.displayName = "ManageCustomCSS"

ManageCustomCSS.propTypes = {
  // provided by CardStack > CardStackCard
  card: PropTypes.object.isRequired,
  // handler to save field values
  onSubmit: PropTypes.func.isRequired,
  // field values
  initialValue: PropTypes.shape({
    css: PropTypes.string
  })
}

export default ManageCustomCSS
