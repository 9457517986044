import capitalize from "lodash/capitalize"

const fonts = [
  {
    category: "Sans Serif",
    fonts: [
      {
        name: "Nimbus Sans",
        key: "NimbusSans",
        variants: ["regular", "bold", "italic"]
      },
      {
        name: "Montserrat",
        key: "Montserrat",
        variants: ["regular", "bold", "italic"]
      }
    ]
  },
  {
    category: "Serif",
    fonts: [
      {
        name: "Newsreader",
        key: "Newsreader",
        variants: ["regular", "bold", "italic"]
      },
      {
        name: "EB Garamond",
        key: "EBGaramond",
        variants: ["regular", "bold", "italic"]
      }
    ]
  },
  {
    category: "Script",
    fonts: [
      { name: "Seaweed Script", key: "SeaweedScript", variants: ["regular"] },
      { name: "Vujahday Script", key: "VujahdayScript", variants: ["regular"] }
    ]
  }
]

export const getFont = (fontKey) => {
  const font = fonts.reduce(
    (font, fontGroup) =>
      font ||
      fontGroup.fonts.reduce(
        (matchingFont, font) => (font.key === fontKey ? font : matchingFont),
        null
      ),
    null
  )

  return font
}

export const getFontByFontName = (fontName) => {
  const { fontKey } = parseFontName(fontName)
  return getFont(fontKey)
}

export const getFontName = (fontKey, fontVariant) => {
  if (!fontKey || !fontVariant) return ""
  return `${fontKey}-${capitalize(fontVariant)}`
}

export const parseFontName = (fontName) => {
  if (!fontName) fontName = ""
  const [fontKey, fontVariant] = fontName.split("-")
  return {
    fontKey,
    fontVariant: (fontVariant || "regular").toLowerCase()
  }
}

export default fonts
