
        
    const runtime = require("../../../../../../../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
    __nunjucks_module_dependencies__.templates = {};
    __nunjucks_module_dependencies__.globals = {};
    __nunjucks_module_dependencies__.extensions = {};
    __nunjucks_module_dependencies__.filters = {};
    __nunjucks_module_dependencies__.assets = {};
    
    
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["lib/uniform/views/templates/uniform-macros.html"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
var macro_t_1 = runtime.makeMacro(
["name", "validation", "config", "attrs"], 
[], 
function (l_name, l_validation, l_config, l_attrs, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("name", l_name);
frame.set("validation", l_validation);
frame.set("config", l_config);
frame.set("attrs", l_attrs);
var t_2 = "";t_2 += "\n";
if(!runtime.memberLookup((l_config),"nowrap")) {
t_2 += "\n<div id=\"field-";
t_2 += runtime.suppressValue(l_name, env.opts.autoescape);
t_2 += "-container\" class=\"pw-field text";
if(runtime.memberLookup((l_config),"class")) {
t_2 += " ";
t_2 += runtime.suppressValue(runtime.memberLookup((l_config),"class"), env.opts.autoescape);
;
}
t_2 += "\">\n";
;
}
t_2 += "\n";
if(runtime.memberLookup((l_config),"label")) {
t_2 += "\n<label for=\"id_";
t_2 += runtime.suppressValue(l_name, env.opts.autoescape);
t_2 += "\">";
t_2 += runtime.suppressValue(runtime.memberLookup((l_config),"label"), env.opts.autoescape);
t_2 += "</label>\n";
;
}
t_2 += "\n<input type=\"";
t_2 += runtime.suppressValue(env.getFilter("default").call(context, runtime.memberLookup((l_config),"type"),"text",true), env.opts.autoescape);
t_2 += "\" name=\"";
t_2 += runtime.suppressValue(l_name, env.opts.autoescape);
t_2 += "\" id=\"id_";
t_2 += runtime.suppressValue(l_name, env.opts.autoescape);
t_2 += "\"\n    class=\"";
if(runtime.memberLookup((l_config),"class")) {
t_2 += " ";
t_2 += runtime.suppressValue(runtime.memberLookup((l_config),"class"), env.opts.autoescape);
;
}
t_2 += "\"\n    autocomplete=\"off\"\n    ";
if(runtime.memberLookup((l_config),"bind")) {
t_2 += "rv-value=\"";
t_2 += runtime.suppressValue(runtime.memberLookup((l_config),"bind"), env.opts.autoescape);
t_2 += "\"";
;
}
t_2 += "\n    ";
if(runtime.memberLookup((l_config),"bind_exp")) {
t_2 += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((l_config),"bind_exp")), env.opts.autoescape);
;
}
t_2 += "\n    ";
if(runtime.memberLookup((l_config),"placeholder")) {
t_2 += "placeholder=\"";
t_2 += runtime.suppressValue(runtime.memberLookup((l_config),"placeholder"), env.opts.autoescape);
t_2 += "\"";
;
}
t_2 += "\n    ";
frame = frame.push();
var t_5 = l_validation;
if(t_5) {var t_3;
if(runtime.isArray(t_5)) {
var t_4 = t_5.length;
for(t_3=0; t_3 < t_5.length; t_3++) {
var t_6 = t_5[t_3][0]
frame.set("key", t_5[t_3][0]);
var t_7 = t_5[t_3][1]
frame.set("v", t_5[t_3][1]);
frame.set("loop.index", t_3 + 1);
frame.set("loop.index0", t_3);
frame.set("loop.revindex", t_4 - t_3);
frame.set("loop.revindex0", t_4 - t_3 - 1);
frame.set("loop.first", t_3 === 0);
frame.set("loop.last", t_3 === t_4 - 1);
frame.set("loop.length", t_4);
t_2 += "\n    data-parsley-";
t_2 += runtime.suppressValue(t_6, env.opts.autoescape);
t_2 += "=\"";
t_2 += runtime.suppressValue(t_7, env.opts.autoescape);
t_2 += "\"\n    ";
;
}
} else {
t_3 = -1;
var t_4 = runtime.keys(t_5).length;
for(var t_8 in t_5) {
t_3++;
var t_9 = t_5[t_8];
frame.set("key", t_8);
frame.set("v", t_9);
frame.set("loop.index", t_3 + 1);
frame.set("loop.index0", t_3);
frame.set("loop.revindex", t_4 - t_3);
frame.set("loop.revindex0", t_4 - t_3 - 1);
frame.set("loop.first", t_3 === 0);
frame.set("loop.last", t_3 === t_4 - 1);
frame.set("loop.length", t_4);
t_2 += "\n    data-parsley-";
t_2 += runtime.suppressValue(t_8, env.opts.autoescape);
t_2 += "=\"";
t_2 += runtime.suppressValue(t_9, env.opts.autoescape);
t_2 += "\"\n    ";
;
}
}
}
frame = frame.pop();
t_2 += "\n    ";
frame = frame.push();
var t_12 = l_attrs;
if(t_12) {var t_10;
if(runtime.isArray(t_12)) {
var t_11 = t_12.length;
for(t_10=0; t_10 < t_12.length; t_10++) {
var t_13 = t_12[t_10][0]
frame.set("key", t_12[t_10][0]);
var t_14 = t_12[t_10][1]
frame.set("attr", t_12[t_10][1]);
frame.set("loop.index", t_10 + 1);
frame.set("loop.index0", t_10);
frame.set("loop.revindex", t_11 - t_10);
frame.set("loop.revindex0", t_11 - t_10 - 1);
frame.set("loop.first", t_10 === 0);
frame.set("loop.last", t_10 === t_11 - 1);
frame.set("loop.length", t_11);
t_2 += "\n    ";
t_2 += runtime.suppressValue(t_13, env.opts.autoescape);
t_2 += "=\"";
t_2 += runtime.suppressValue(t_14, env.opts.autoescape);
t_2 += "\"\n    ";
;
}
} else {
t_10 = -1;
var t_11 = runtime.keys(t_12).length;
for(var t_15 in t_12) {
t_10++;
var t_16 = t_12[t_15];
frame.set("key", t_15);
frame.set("attr", t_16);
frame.set("loop.index", t_10 + 1);
frame.set("loop.index0", t_10);
frame.set("loop.revindex", t_11 - t_10);
frame.set("loop.revindex0", t_11 - t_10 - 1);
frame.set("loop.first", t_10 === 0);
frame.set("loop.last", t_10 === t_11 - 1);
frame.set("loop.length", t_11);
t_2 += "\n    ";
t_2 += runtime.suppressValue(t_15, env.opts.autoescape);
t_2 += "=\"";
t_2 += runtime.suppressValue(t_16, env.opts.autoescape);
t_2 += "\"\n    ";
;
}
}
}
frame = frame.pop();
t_2 += ">\n";
if(!runtime.memberLookup((l_config),"nowrap")) {
t_2 += "</div>";
;
}
t_2 += "\n";
;
frame = callerFrame;
return new runtime.SafeString(t_2);
});
context.addExport("text");
context.setVariable("text", macro_t_1);
output += "\n\n";
var macro_t_17 = runtime.makeMacro(
["name", "validation", "config"], 
[], 
function (l_name, l_validation, l_config, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("name", l_name);
frame.set("validation", l_validation);
frame.set("config", l_config);
var t_18 = "";t_18 += "\n";
if(!runtime.memberLookup((l_config),"nowrap")) {
t_18 += "\n<div id=\"field-";
t_18 += runtime.suppressValue(l_name, env.opts.autoescape);
t_18 += "-container\" class=\"pw-field richtext";
if(runtime.memberLookup((l_config),"class")) {
t_18 += " ";
t_18 += runtime.suppressValue(runtime.memberLookup((l_config),"class"), env.opts.autoescape);
;
}
t_18 += "\">\n";
;
}
t_18 += "\n";
if(runtime.memberLookup((l_config),"label")) {
t_18 += "\n<label for=\"id_";
t_18 += runtime.suppressValue(l_name, env.opts.autoescape);
t_18 += "\">";
t_18 += runtime.suppressValue(runtime.memberLookup((l_config),"label"), env.opts.autoescape);
t_18 += "</label>\n";
;
}
t_18 += "\n<textarea name=\"";
t_18 += runtime.suppressValue(l_name, env.opts.autoescape);
t_18 += "\" id=\"id_";
t_18 += runtime.suppressValue(l_name, env.opts.autoescape);
t_18 += "\"\n    class=\" redactor";
if(runtime.memberLookup((l_config),"class")) {
t_18 += " ";
t_18 += runtime.suppressValue(runtime.memberLookup((l_config),"class"), env.opts.autoescape);
;
}
t_18 += "\"\n    ";
if(runtime.memberLookup((l_config),"bind")) {
t_18 += "rv-richtext=\"";
t_18 += runtime.suppressValue(runtime.memberLookup((l_config),"bind"), env.opts.autoescape);
t_18 += "\"";
;
}
t_18 += "\n    ";
if(runtime.memberLookup((l_config),"rt_config")) {
t_18 += "data-rt-config=\"";
t_18 += runtime.suppressValue(runtime.memberLookup((l_config),"rt_config"), env.opts.autoescape);
t_18 += "\"";
;
}
t_18 += "\n    ";
if(runtime.memberLookup((l_config),"rt_target")) {
t_18 += "data-rt-target=\"";
t_18 += runtime.suppressValue(runtime.memberLookup((l_config),"rt_target"), env.opts.autoescape);
t_18 += "\"";
;
}
t_18 += "\n    ";
if(runtime.memberLookup((l_config),"bind_exp")) {
t_18 += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((l_config),"bind_exp")), env.opts.autoescape);
;
}
t_18 += "\n    ";
if(runtime.memberLookup((l_config),"placeholder")) {
t_18 += "placeholder=\"";
t_18 += runtime.suppressValue(runtime.memberLookup((l_config),"placeholder"), env.opts.autoescape);
t_18 += "\"";
;
}
t_18 += "\n    ";
frame = frame.push();
var t_21 = l_validation;
if(t_21) {var t_19;
if(runtime.isArray(t_21)) {
var t_20 = t_21.length;
for(t_19=0; t_19 < t_21.length; t_19++) {
var t_22 = t_21[t_19][0]
frame.set("key", t_21[t_19][0]);
var t_23 = t_21[t_19][1]
frame.set("v", t_21[t_19][1]);
frame.set("loop.index", t_19 + 1);
frame.set("loop.index0", t_19);
frame.set("loop.revindex", t_20 - t_19);
frame.set("loop.revindex0", t_20 - t_19 - 1);
frame.set("loop.first", t_19 === 0);
frame.set("loop.last", t_19 === t_20 - 1);
frame.set("loop.length", t_20);
t_18 += "\n    data-parsley-";
t_18 += runtime.suppressValue(t_22, env.opts.autoescape);
t_18 += "=\"";
t_18 += runtime.suppressValue(t_23, env.opts.autoescape);
t_18 += "\"\n    ";
;
}
} else {
t_19 = -1;
var t_20 = runtime.keys(t_21).length;
for(var t_24 in t_21) {
t_19++;
var t_25 = t_21[t_24];
frame.set("key", t_24);
frame.set("v", t_25);
frame.set("loop.index", t_19 + 1);
frame.set("loop.index0", t_19);
frame.set("loop.revindex", t_20 - t_19);
frame.set("loop.revindex0", t_20 - t_19 - 1);
frame.set("loop.first", t_19 === 0);
frame.set("loop.last", t_19 === t_20 - 1);
frame.set("loop.length", t_20);
t_18 += "\n    data-parsley-";
t_18 += runtime.suppressValue(t_24, env.opts.autoescape);
t_18 += "=\"";
t_18 += runtime.suppressValue(t_25, env.opts.autoescape);
t_18 += "\"\n    ";
;
}
}
}
frame = frame.pop();
t_18 += "></textarea>\n";
if(!runtime.memberLookup((l_config),"nowrap")) {
t_18 += "</div>";
;
}
t_18 += "\n";
;
frame = callerFrame;
return new runtime.SafeString(t_18);
});
context.addExport("richtext");
context.setVariable("richtext", macro_t_17);
output += "\n\n";
var macro_t_26 = runtime.makeMacro(
["name", "validation", "config"], 
[], 
function (l_name, l_validation, l_config, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("name", l_name);
frame.set("validation", l_validation);
frame.set("config", l_config);
var t_27 = "";t_27 += "\n";
if(!runtime.memberLookup((l_config),"nowrap")) {
t_27 += "\n<div id=\"field-";
t_27 += runtime.suppressValue(l_name, env.opts.autoescape);
t_27 += "-container\" class=\"pw-field textarea";
if(runtime.memberLookup((l_config),"class")) {
t_27 += " ";
t_27 += runtime.suppressValue(runtime.memberLookup((l_config),"class"), env.opts.autoescape);
;
}
t_27 += "\">\n";
;
}
t_27 += "\n";
if(runtime.memberLookup((l_config),"label")) {
t_27 += "\n<label for=\"id_";
t_27 += runtime.suppressValue(l_name, env.opts.autoescape);
t_27 += "\">";
t_27 += runtime.suppressValue(runtime.memberLookup((l_config),"label"), env.opts.autoescape);
t_27 += "</label>\n";
;
}
t_27 += "\n<textarea name=\"";
t_27 += runtime.suppressValue(l_name, env.opts.autoescape);
t_27 += "\" id=\"id_";
t_27 += runtime.suppressValue(l_name, env.opts.autoescape);
t_27 += "\"\n    class=\"";
if(runtime.memberLookup((l_config),"class")) {
t_27 += " ";
t_27 += runtime.suppressValue(runtime.memberLookup((l_config),"class"), env.opts.autoescape);
;
}
t_27 += "\"\n    ";
if(runtime.memberLookup((l_config),"bind")) {
t_27 += "rv-value=\"";
t_27 += runtime.suppressValue(runtime.memberLookup((l_config),"bind"), env.opts.autoescape);
t_27 += "\"";
;
}
t_27 += "\n    ";
if(runtime.memberLookup((l_config),"bind_exp")) {
t_27 += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((l_config),"bind_exp")), env.opts.autoescape);
;
}
t_27 += "\n    ";
if(runtime.memberLookup((l_config),"placeholder")) {
t_27 += "placeholder=\"";
t_27 += runtime.suppressValue(runtime.memberLookup((l_config),"placeholder"), env.opts.autoescape);
t_27 += "\"";
;
}
t_27 += "\n    ";
if(runtime.memberLookup((l_validation),"required")) {
t_27 += "required=\"true\"";
;
}
t_27 += "\n    ";
frame = frame.push();
var t_30 = l_validation;
if(t_30) {var t_28;
if(runtime.isArray(t_30)) {
var t_29 = t_30.length;
for(t_28=0; t_28 < t_30.length; t_28++) {
var t_31 = t_30[t_28][0]
frame.set("key", t_30[t_28][0]);
var t_32 = t_30[t_28][1]
frame.set("v", t_30[t_28][1]);
frame.set("loop.index", t_28 + 1);
frame.set("loop.index0", t_28);
frame.set("loop.revindex", t_29 - t_28);
frame.set("loop.revindex0", t_29 - t_28 - 1);
frame.set("loop.first", t_28 === 0);
frame.set("loop.last", t_28 === t_29 - 1);
frame.set("loop.length", t_29);
t_27 += "\n    data-parsley-";
t_27 += runtime.suppressValue(t_31, env.opts.autoescape);
t_27 += "=\"";
t_27 += runtime.suppressValue(t_32, env.opts.autoescape);
t_27 += "\"\n    ";
;
}
} else {
t_28 = -1;
var t_29 = runtime.keys(t_30).length;
for(var t_33 in t_30) {
t_28++;
var t_34 = t_30[t_33];
frame.set("key", t_33);
frame.set("v", t_34);
frame.set("loop.index", t_28 + 1);
frame.set("loop.index0", t_28);
frame.set("loop.revindex", t_29 - t_28);
frame.set("loop.revindex0", t_29 - t_28 - 1);
frame.set("loop.first", t_28 === 0);
frame.set("loop.last", t_28 === t_29 - 1);
frame.set("loop.length", t_29);
t_27 += "\n    data-parsley-";
t_27 += runtime.suppressValue(t_33, env.opts.autoescape);
t_27 += "=\"";
t_27 += runtime.suppressValue(t_34, env.opts.autoescape);
t_27 += "\"\n    ";
;
}
}
}
frame = frame.pop();
t_27 += "></textarea>\n";
if(!runtime.memberLookup((l_config),"nowrap")) {
t_27 += "</div>";
;
}
t_27 += "\n";
;
frame = callerFrame;
return new runtime.SafeString(t_27);
});
context.addExport("textarea");
context.setVariable("textarea", macro_t_26);
output += "\n\n";
var macro_t_35 = runtime.makeMacro(
["name", "options", "validation", "config"], 
[], 
function (l_name, l_options, l_validation, l_config, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("name", l_name);
frame.set("options", l_options);
frame.set("validation", l_validation);
frame.set("config", l_config);
var t_36 = "";t_36 += "\n";
if(!runtime.memberLookup((l_config),"nowrap")) {
t_36 += "\n<div id=\"field-";
t_36 += runtime.suppressValue(l_name, env.opts.autoescape);
t_36 += "-container\" class=\"pw-field select";
if(runtime.memberLookup((l_config),"class")) {
t_36 += " ";
t_36 += runtime.suppressValue(runtime.memberLookup((l_config),"class"), env.opts.autoescape);
;
}
t_36 += "\">\n";
;
}
t_36 += "\n";
if(runtime.memberLookup((l_config),"label")) {
t_36 += "\n<label for=\"id_";
t_36 += runtime.suppressValue(l_name, env.opts.autoescape);
t_36 += "\">";
t_36 += runtime.suppressValue(runtime.memberLookup((l_config),"label"), env.opts.autoescape);
t_36 += "</label>\n";
;
}
t_36 += "\n<select name=\"";
t_36 += runtime.suppressValue(l_name, env.opts.autoescape);
t_36 += "\" id=\"id_";
t_36 += runtime.suppressValue(l_name, env.opts.autoescape);
t_36 += "\"\n    class=\"form-control ";
if(runtime.memberLookup((l_config),"class")) {
t_36 += " ";
t_36 += runtime.suppressValue(runtime.memberLookup((l_config),"class"), env.opts.autoescape);
;
}
t_36 += "\"\n    ";
if(runtime.memberLookup((l_config),"bind")) {
t_36 += "rv-value=\"";
t_36 += runtime.suppressValue(runtime.memberLookup((l_config),"bind"), env.opts.autoescape);
t_36 += "\"";
;
}
t_36 += "\n    ";
if(runtime.memberLookup((l_config),"bind_exp")) {
t_36 += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((l_config),"bind_exp")), env.opts.autoescape);
;
}
t_36 += "\n    ";
frame = frame.push();
var t_39 = l_validation;
if(t_39) {var t_37;
if(runtime.isArray(t_39)) {
var t_38 = t_39.length;
for(t_37=0; t_37 < t_39.length; t_37++) {
var t_40 = t_39[t_37][0]
frame.set("key", t_39[t_37][0]);
var t_41 = t_39[t_37][1]
frame.set("v", t_39[t_37][1]);
frame.set("loop.index", t_37 + 1);
frame.set("loop.index0", t_37);
frame.set("loop.revindex", t_38 - t_37);
frame.set("loop.revindex0", t_38 - t_37 - 1);
frame.set("loop.first", t_37 === 0);
frame.set("loop.last", t_37 === t_38 - 1);
frame.set("loop.length", t_38);
t_36 += "\n    data-parsley-";
t_36 += runtime.suppressValue(t_40, env.opts.autoescape);
t_36 += "=\"";
t_36 += runtime.suppressValue(t_41, env.opts.autoescape);
t_36 += "\"\n    ";
;
}
} else {
t_37 = -1;
var t_38 = runtime.keys(t_39).length;
for(var t_42 in t_39) {
t_37++;
var t_43 = t_39[t_42];
frame.set("key", t_42);
frame.set("v", t_43);
frame.set("loop.index", t_37 + 1);
frame.set("loop.index0", t_37);
frame.set("loop.revindex", t_38 - t_37);
frame.set("loop.revindex0", t_38 - t_37 - 1);
frame.set("loop.first", t_37 === 0);
frame.set("loop.last", t_37 === t_38 - 1);
frame.set("loop.length", t_38);
t_36 += "\n    data-parsley-";
t_36 += runtime.suppressValue(t_42, env.opts.autoescape);
t_36 += "=\"";
t_36 += runtime.suppressValue(t_43, env.opts.autoescape);
t_36 += "\"\n    ";
;
}
}
}
frame = frame.pop();
t_36 += ">\n    ";
frame = frame.push();
var t_46 = l_options;
if(t_46) {var t_45 = t_46.length;
for(var t_44=0; t_44 < t_46.length; t_44++) {
var t_47 = t_46[t_44];
frame.set("op", t_47);
frame.set("loop.index", t_44 + 1);
frame.set("loop.index0", t_44);
frame.set("loop.revindex", t_45 - t_44);
frame.set("loop.revindex0", t_45 - t_44 - 1);
frame.set("loop.first", t_44 === 0);
frame.set("loop.last", t_44 === t_45 - 1);
frame.set("loop.length", t_45);
t_36 += "\n    <option value=\"";
t_36 += runtime.suppressValue(runtime.memberLookup((t_47),"value"), env.opts.autoescape);
t_36 += "\">\n        ";
t_36 += runtime.suppressValue(runtime.memberLookup((t_47),"name"), env.opts.autoescape);
t_36 += "\n    </option>\n    ";
;
}
}
frame = frame.pop();
t_36 += "\n</select>\n";
if(!runtime.memberLookup((l_config),"nowrap")) {
t_36 += "</div>";
;
}
t_36 += "\n";
;
frame = callerFrame;
return new runtime.SafeString(t_36);
});
context.addExport("select");
context.setVariable("select", macro_t_35);
output += "\n\n";
var macro_t_48 = runtime.makeMacro(
["name", "options"], 
[], 
function (l_name, l_options, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("name", l_name);
frame.set("options", l_options);
var t_49 = "";t_49 += "\n<input name=\"";
t_49 += runtime.suppressValue(l_name, env.opts.autoescape);
t_49 += "\" type=\"hidden\" ";
if(runtime.memberLookup((l_options),"bind")) {
t_49 += "rv-value=\"";
t_49 += runtime.suppressValue(runtime.memberLookup((l_options),"bind"), env.opts.autoescape);
t_49 += "\"";
;
}
t_49 += ">\n";
;
frame = callerFrame;
return new runtime.SafeString(t_49);
});
context.addExport("hidden");
context.setVariable("hidden", macro_t_48);
output += "\n\n";
var macro_t_50 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
var t_51 = "";t_51 += "\n<div class=\"pw-flash-message-container\" style=\"display: none\"></div>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_51);
});
context.addExport("flash_message");
context.setVariable("flash_message", macro_t_50);
output += "\n\n";
var macro_t_52 = runtime.makeMacro(
["label", "type", "attrs"], 
[], 
function (l_label, l_type, l_attrs, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("label", l_label);
frame.set("type", l_type);
frame.set("attrs", l_attrs);
var t_53 = "";t_53 += "\n<button class=\"pw-form-submit ButtonLegacy medium rounded primary interactive ";
t_53 += runtime.suppressValue(env.getFilter("default").call(context, runtime.memberLookup((l_attrs),"class"),"",true), env.opts.autoescape);
t_53 += "\" type=\"";
t_53 += runtime.suppressValue(env.getFilter("default").call(context, l_type,"submit",true), env.opts.autoescape);
t_53 += "\">\n    ";
t_53 += runtime.suppressValue(env.getFilter("default").call(context, l_label,"Submit",true), env.opts.autoescape);
t_53 += "\n</button>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_53);
});
context.addExport("submit");
context.setVariable("submit", macro_t_52);
output += "\n\n\n    ";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
            const templateContext = {
                __nunjucks_loader_assets__: __nunjucks_module_dependencies__.assets,
                ...ctx
            };

            var nunjucks = (runtime && runtime.default || runtime)(
                {"dev":false,"jinjaCompat":false,"isAsyncTemplate":false},
                __nunjucks_module_dependencies__
            );

            if (nunjucks.isAsync()) {
                return nunjucks.renderAsync(
                    "lib/uniform/views/templates/uniform-macros.html",
                    templateContext
                );
            }
        
            return nunjucks.render(
                "lib/uniform/views/templates/uniform-macros.html",
                templateContext
            );
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["lib/uniform/views/templates/uniform-macros.html"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
    