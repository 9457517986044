import { Alert, AlertDescription, AlertTitle, Box } from "@chakra-ui/react"
import Pathicon from "@pathwright/web/src/modules/pathicon/Pathicon"
import { useScopedTranslation } from "../../../lib/lng/TranslationsProvider"

const AuthFormAlert = ({ error }: { error?: string }): JSX.Element | null => {
  const { t } = useScopedTranslation()

  return error ? (
    <Alert status="error" variant="subtle" wordBreak="break-word">
      <Pathicon icon="caution-octagon" mr={4} />
      <Box>
        <AlertTitle>{t("auth.form_alert.an_error_occurred")}:</AlertTitle>
        <AlertDescription>{t(error)}</AlertDescription>
      </Box>
    </Alert>
  ) : null
}

export default AuthFormAlert
