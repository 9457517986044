import { media } from "@pathwright/ui/src/components/utils/styles"
import PropTypes from "prop-types"
import styled from "styled-components"

import { withTranslate } from "@pathwright/ui/src/components/"
import Checkbox from "@pathwright/ui/src/components/form/form-checkbox/Checkbox"
import TextInput from "@pathwright/ui/src/components/form/form-text-input/TextInput"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import { useUIThemeContext } from "@pathwright/ui/src/components/ui/PathwrightUI"
import Text from "@pathwright/ui/src/components/ui/Text"
import { Field, Form, Submit } from "react-connect-form-forked"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import { userpointsPerc } from "../utils"
import useSaveCompletionFeedbackMutation from "./useSaveCompletionFeedbackMutation"

const Container = styled.div`
  flex-shrink: 0;
  display: flex !important;
  flex-direction: column;
  .TextInput {
    padding: 0;
  }
  input[type="text"] {
    line-height: 1em;
  }
  input[type="checkbox"] {
    width: 20px;
  }
  ${media.max.phone`
    display:none;
  `};
`
const Row = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`
const LoadingIndicator = styled.div`
  position: relative;
  margin-left: 2px;
`

const CompletionFeedbackForm = ({ step, completion, permissions, t }) => {
  const { me } = usePathwrightContext()
  const theme = useUIThemeContext()

  const [saveCompletionFeedbackMutation] =
    useSaveCompletionFeedbackMutation(step)

  return (
    <Container onClick={(e) => e.stopPropagation()}>
      <Text.Body el="div">
        {completion.needs_grading ? (
          permissions.canGradeCompletion ? (
            completion.has_points ? (
              <Text.Meta
                style={{ fontStyle: "italic", color: theme.primaryBrandColor }}
              >
                {t("completion.feedback.form.needs_review")}
              </Text.Meta>
            ) : (
              <Form
                onSubmit={() => saveCompletionFeedbackMutation()}
                render={({ submitting }) => (
                  <Submit
                    render={({ onSubmit }) => (
                      <Field
                        name="foo"
                        type="checkbox"
                        onChange={onSubmit}
                        component={Checkbox}
                        hideStatus
                      >
                        <Text.Meta>
                          {t("completion.feedback.form.mark_reviewed")}
                        </Text.Meta>
                      </Field>
                    )}
                  />
                )}
              />
            )
          ) : null
        ) : completion.checked_by ? (
          completion.checked_by.id === me.id ? (
            <Text.Meta style={{ fontStyle: "italic" }}>
              {t("completion.feedback.form.you_reviewed")}{" "}
            </Text.Meta>
          ) : (
            <Text.Meta style={{ fontStyle: "italic" }}>
              {t("completion.feedback.form.reviewed_by", {
                reviewer: completion.checked_by.display_name
              })}{" "}
            </Text.Meta>
          )
        ) : completion.is_auto_graded ? (
          <Text.Meta style={{ fontStyle: "italic" }}>
            {t("completion.feedback.form.auto_graded")}{" "}
          </Text.Meta>
        ) : null}
        {completion.has_points && (
          <Form
            initialValue={{ userpoints_earned: completion.userpoints_earned }}
            onSubmit={({ userpoints_earned }) =>
              saveCompletionFeedbackMutation({
                variables: {
                  userpointsEarned: parseInt(userpoints_earned) || 0
                }
              })
            }
            render={({ value, submitting }) =>
              permissions.canGradeCompletion ? (
                <Row>
                  <Submit
                    render={({ onSubmit }) => (
                      <Field
                        name="userpoints_earned"
                        type="input"
                        component={TextInput}
                        onBlur={onSubmit}
                        hideStatus
                        autoSize
                      />
                    )}
                  />
                  /{completion.userpoints_value}{" "}
                  {userpointsPerc(
                    value.userpoints_earned || completion.userpoints_earned,
                    completion.userpoints_value
                  )}
                  <LoadingIndicator>
                    {submitting && <LoadingCircle size={14} />}
                  </LoadingIndicator>
                </Row>
              ) : (
                <Row>
                  {completion.userpoints_earned}/{completion.userpoints_value}{" "}
                  {userpointsPerc(
                    value.userpoints_earned || completion.userpoints_earned,
                    completion.userpoints_value
                  )}
                </Row>
              )
            }
          />
        )}
      </Text.Body>
    </Container>
  )
}

CompletionFeedbackForm.propTypes = {
  step: PropTypes.object.isRequired,
  completion: PropTypes.object.isRequired,
  permissions: PropTypes.object.isRequired
}

CompletionFeedbackForm.defaultProps = {
  permissions: {
    canGradeCompletion: false
  }
}

export default withTranslate(CompletionFeedbackForm)
