import Alert from "@pathwright/ui/src/components/alert/Alert"
import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import { CONTEXTUAL_FEATURE_PERMISSION_QUERY } from "../graphql"
import FeatureBlock from "./FeatureBlock"

const FeatureBlockContainer = ({ featureKey, featureAction, context, to }) => {
  const cfpQuery = useQuery(CONTEXTUAL_FEATURE_PERMISSION_QUERY, {
    variables: {
      featureKey,
      featureAction,
      context
    }
  })

  if (cfpQuery.loading) return null
  if (cfpQuery.error) return <Alert error={cfpQuery.error} />

  const feature = cfpQuery.data.contextualFeaturePermission

  return (
    <FeatureBlock
      featureKey={feature.feature_key}
      featurePermission={feature.permission_in_context}
      to={to}
    />
  )
}

FeatureBlockContainer.displayName = "FeatureBlockContainer"

FeatureBlockContainer.propTypes = {
  ...CONTEXTUAL_FEATURE_PERMISSION_QUERY._propTypes
}

export default FeatureBlockContainer
