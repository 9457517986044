import PropTypes from "prop-types"
import styled from "styled-components"
import DropdownTrigger from "../../dropdown/DropdownTrigger"
import { BORDER_GRAY } from "../../utils/colors"
import FieldWrapper from "../form-utils/FieldWrapper"
import ColorPicker from "./ColorPicker"

const BASE_64_CHECKBOARD =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAAK0lEQVQ4y2P8//8/A25w7949PLJMDBSAUc0jQzML/jSkpKQ0GmCjminRDADJNQjBr5nbigAAAABJRU5ErkJggg=="

const StyledColorPicker = styled(ColorPicker)`
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid ${BORDER_GRAY};
  background-color: white;
`

const StyledToggle = styled.div`
  overflow: hidden;
  position: relative;
  height: 20px;
  width: 40px;
  border-radius: 4px;
  background-image: url(${BASE_64_CHECKBOARD});
  background-size: 10px;
  background-repeat: repeat;
`

const StyledSwatch = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 4px;
`

const ColorPickerToggle = (props) => {
  const {
    placement,
    alignment,
    value,
    format,
    renderToggle,
    onShow,
    onHide,
    onChange,
    onChangeComplete,
    swatches,
    enableSaturation,
    enableHue,
    enableAlpha,
    enableHex,
    enableRgb,
    enableRgba,
    showInputs,
    ...fieldWrapperProps
  } = props

  const handleChange = (changeHandler) => (color) => {
    let formattedColor = null

    switch (format) {
      case "hex":
        formattedColor = color.hex
        break
      case "rgb": {
        const { r, g, b } = color.rgb
        const rgb = `rgb(${r},${g},${b})`
        formattedColor = rgb
        break
      }
      case "rgba":
        {
          const { r, g, b, a } = color.rgb
          const rgba = `rgba(${r},${g},${b},${a})`
          formattedColor = rgba
        }
        break
    }

    if (changeHandler) changeHandler(formattedColor, color)
  }

  return (
    <FieldWrapper className="ColorPickerToggle" {...fieldWrapperProps}>
      <DropdownTrigger
        placement={placement}
        alignment={alignment}
        onShow={onShow}
        onHide={onHide}
        renderDropdown={() => (
          <StyledColorPicker
            color={value || ""}
            swatches={swatches}
            enableSaturation={enableSaturation}
            enableHue={enableHue}
            enableAlpha={enableAlpha}
            enableHex={enableHex}
            enableRgb={enableRgb}
            enableRgba={enableRgba}
            onChange={handleChange(onChange)}
            onChangeComplete={handleChange(onChangeComplete)}
            showInputs={showInputs}
          />
        )}
      >
        {renderToggle ? (
          renderToggle({
            value
          })
        ) : (
          <StyledToggle className="ColorPickerToggle__toggle">
            <StyledSwatch style={{ backgroundColor: value }} />
          </StyledToggle>
        )}
      </DropdownTrigger>
    </FieldWrapper>
  )
}

ColorPickerToggle.displayName = "ColorPickerToggle"

ColorPickerToggle.propTypes = {
  format: PropTypes.oneOf(["hex", "rgb", "rgba"]),
  renderToggle: PropTypes.func
}

ColorPickerToggle.defaultProps = {
  format: "rgba"
}

export default ColorPickerToggle
