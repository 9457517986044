import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import Error from "@pathwright/ui/src/components/error/Error"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import { useCertificateContext } from "../context/CertificateContext"
import useCertificatePreviewParam from "../hooks/useCertificatePreviewParam"
import CertificatePreview from "../preview/CertificatePreview"
import CertificateManager from "./CertificateManager"

const CertificateContainer = () => {
  const { t } = useTranslate()
  const [previewMode] = useCertificatePreviewParam()
  const { certificate, certificateId, loading, error } = useCertificateContext()

  if (loading) {
    return <LoadingCircle center />
  }

  if (error) {
    return (
      <Error
        errorTitle={t("An unexpected error occurred.")}
        errorDetails={error.toString()}
      />
    )
  }

  if (!certificate && certificateId) {
    return <BlankSlate heading={t("No certificate found")} />
  }

  return previewMode ? <CertificatePreview /> : <CertificateManager />
}

CertificateContainer.displayName = "CertificateContainer"

export default CertificateContainer
