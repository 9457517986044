import gql from "graphql-tag"

export default gql`
  fragment SchoolBillingInvoice on SchoolBillingInvoice {
    cycle_end
    cycle_start
    id
    invoice_json
    invoiced_date
    stripe_invoice_id
    stripe_invoice_status
    stripe_next_payment_attempt
    total_amount_billed
    url
  }
`
