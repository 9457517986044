import gql from "graphql-tag"
import SCHOOL_FRAGMENT from "./school-fragment"
import THEME_FRAGMENT from "./theme-fragment"

export default gql`
  query SchoolQuery($id: Int!) {
    context(school_id: $id) {
      school {
        ...School
        theme {
          ...Theme
        }
      }
    }
  }
  ${SCHOOL_FRAGMENT}
  ${THEME_FRAGMENT}
`
