import { createContext, useContext, useEffect, useMemo, useState } from "react"
import { usePathwrightContext } from "../pathwright/PathwrightContext"

export const initializeFirebase = (firebaseConfig) => {
  // console.log("initializeFirebase: ", firebaseConfig)
  if (
    !firebaseConfig ||
    !firebaseConfig.url ||
    !firebaseConfig.client_api_key
  ) {
    if (process.env.NODE_ENV !== "test") {
      console.warn("Could not initialize Firebase: no Firebase config provided")
    }
    return
  }
  if (window.firebase && !window.firebase.apps.length) {
    // console.log("inititalizing firebase!", firebaseConfig)
    return window.firebase.initializeApp({
      apiKey: firebaseConfig.client_api_key, // apiKey necessary for auth
      databaseURL: firebaseConfig.url
    })
  }
}

const FirebaseContext = createContext()

export const FirebaseProvider = ({ children }) => {
  const { firebase, intialized, isAuthenticated } = _useFB()
  return (
    <FirebaseContext.Provider value={{ firebase, intialized, isAuthenticated }}>
      {children}
    </FirebaseContext.Provider>
  )
}

const _useFB = () => {
  const { integrations } = usePathwrightContext()
  const [firebase, setFirebase] = useState()
  const [intialized, setInitialized] = useState(!!window.firebase)
  // Necessary to trigger state update after auth for useFirebaseValue to
  // recheck authentication state for getting ref.
  const [isAuthenticated, setIsAuthenticated] = useState()

  const firebaseAuthToken = integrations.firebase.auth_token

  useEffect(() => {
    if (initializeFirebase(integrations.firebase)) {
      setFirebase(window.firebase)
    }
  }, [])

  useEffect(() => {
    const handleAuth = async () => {
      const { currentUser } = firebase.auth()

      if (!currentUser && firebaseAuthToken) {
        await firebase.auth().signInWithCustomToken(firebaseAuthToken)
      } else if (!firebaseAuthToken && currentUser) {
        await firebase.auth().signOut()
      }
      setIsAuthenticated(!!firebase.auth().currentUser)
      setInitialized(true)
    }

    if (firebase) handleAuth()
  }, [firebase, firebaseAuthToken])

  return { firebase, intialized, isAuthenticated }
}

export const useFirebaseContext = () => useContext(FirebaseContext)

export const useFirebaseValue = (path, limit = null) => {
  const [value, setValue] = useState()
  const { firebase, isAuthenticated } = useFirebaseContext()

  const ref = useMemo(() => {
    if (
      !firebase ||
      // Currently all refs require authentication. Wait for auth.
      !isAuthenticated ||
      !path
    ) {
      return null
    }

    const db = firebase.database()
    return db.ref(path)
  }, [firebase, path, isAuthenticated])

  useEffect(() => {
    if (ref) {
      let q = ref
      if (limit) {
        q = ref.limitToLast(limit)
      }

      const listener = q.on(
        "value",
        (snap) => {
          let value = snap.val()
          setValue(value)
        },
        (error) => {
          console.log("got error: ", error)
          console.log(error)
        }
      )
      // Clear listener when ref changes.
      return () => q.off("value", listener)
    } else {
      // Reset value to initial state.
      setValue(undefined)
    }
  }, [ref])

  return [value, ref]
}
