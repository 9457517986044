import { useEffect, useState } from "react"
import useCleanupInterval from "./useCleanupInterval"

const useDebounce = (value, delay) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)
  const cleanupInterval = useCleanupInterval()
  useEffect(
    () => {
      // Update debounced value after delay
      cleanupInterval(
        setTimeout(() => {
          setDebouncedValue(value)
        }, delay)
      )
    },
    [value, delay] // Only re-call effect if value or delay changes
  )
  return debouncedValue
}

export default useDebounce
