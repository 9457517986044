// const SchoolSubscriptionPlanCollection = require("account/subscription/models/school-subscription-plan-collection").default

class Offering extends require("lib/static-shim").default(
  require("lib/utils/model").default
) {
  static initClass() {
    this.prototype.defaults = {
      access_ends: null,
      include_in_subscription_plans: [],
      access_starts: null,
      visibility: 5,
      discussion_enabled: true
    }
  }

  url() {
    let resource_id = this.get("resource_id")
    if (this.get("resource")) {
      if (resource_id == null) {
        resource_id = this.get("resource")
      }
    }
    let offering_url = this.urlRoot(
      `/api/private/library/resource/${resource_id}/offering/`
    )
    if (this.id) {
      offering_url += `${this.id}/`
    }

    if (this.get("invite_token")) {
      offering_url = `${offering_url}?ic=${this.get("invite_token")}`
    }
    return offering_url
  }
}

Offering.initClass()

export default Offering
