let Utils
export default Utils = class Utils {
  renderString(string, data) {
    return nunjucks.renderString(string, data)
  }

  oxfordCommatize(list, conjuction) {
    list[list.length - 1] = `${conjuction} ${list[list.length - 1]}`
    return list.join(", ")
  }

  urljoin() {
    const paths = _.map(arguments, (i) => i.replace(/(^\/|\/$)/, ""))
    const path = paths.join("/").replace(/\/{2,}/, "/")
    // If path fragment contained http(s):// the // has been reduced to /
    // Restore the //
    return path.replace(/(http|https)(:\/)/, "$1://")
  }

  shuffleArray(array, randFunc) {
    // Returns a shuffled copy of the give array
    // using the randFunc if provided (defaults to Math.random)
    if (randFunc == null) {
      randFunc = null
    }
    const shuffled = array.slice(0)
    if (!randFunc) {
      randFunc = Math.random
    }
    let i = shuffled.length
    if (i === 0) {
      return false
    }
    while (--i) {
      const j = Math.floor(randFunc() * (i + 1))
      const tempi = shuffled[i]
      const tempj = shuffled[j]
      shuffled[i] = tempj
      shuffled[j] = tempi
    }
    return shuffled
  }

  isCodeBlank(code) {
    let $code
    if (code == null) {
      return true
    }
    try {
      // Wrap in div to let jquery know we're dealing w/ html here
      $code = $("<div></div>").html(code)
    } catch (e) {
      return false
    }
    // Check for "media"
    const hasMedia = $code.find("img, iframe, video, object").length > 0
    if (hasMedia) {
      return false
    }

    // If no media, check for text
    return $code.is(":empty")
  }

  getSpinnerConfigSmall(overrides) {
    if (overrides == null) {
      overrides = null
    }
    let opts = {
      lines: 13, // The number of lines to draw
      length: 4, // The length of each line
      width: 2, // The line thickness
      radius: 4, // The radius of the inner circle
      corners: 1, // Corner roundness (0..1)
      rotate: 0, // The rotation offset
      direction: 1, // 1: clockwise, -1: counterclockwise
      color: "#000", // #rgb or #rrggbb
      speed: 1.4, // Rounds per second
      trail: 60, // Afterglow percentage
      shadow: false, // Whether to render a shadow
      hwaccel: false, // Whether to use hardware acceleration
      className: "spinner", // The CSS class to assign to the spinner
      zIndex: 2e9 // The z-index (defaults to 2000000000)
    }
    if (overrides != null) {
      opts = $.extend(opts, overrides)
    }
    return opts
  }
}
