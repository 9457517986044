import gql from "graphql-tag"
import PATH_ITEM_FRAGMENT from "../../path/graphql/path-item-fragment"

export default gql`
  query Completions(
    $first: Int = 50
    $user_id: Int
    $cohort_id: Int
    $group_id: Int
    $path_id: Int
    $step_id: Int
    $lesson_source_id: Int
    $source_id: Int
    $needs_review: Boolean
    $is_reviewed: Boolean
    $is_complete: Boolean
    $order_by: [String]
    $cursor: String
  ) {
    pathItems(
      first: $first
      type: step
      after: $cursor
      user_id: $user_id
      cohort_id: $cohort_id
      group_id: $group_id
      step_id: $step_id
      path_id: $path_id
      lesson_source_id: $lesson_source_id
      source_id: $source_id
      needs_review: $needs_review
      is_reviewed: $is_reviewed
      is_complete: $is_complete
      reviewable: true
      order_by: $order_by
      registration_role_filter: { in: [student, moderator] }
    ) {
      total
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...PathItem
          cohort {
            id
            name
          }
          resource {
            id
            name
            image(width: 100, height: 100, fit: crop)
            blurred_background: background_image(
              blur: 500
              width: 250
              height: 250
            )
            background_overlay
            background_image
            image_raw
          }
          parent {
            id
            source_id
            order
            name
          }
          analytics {
            need_feedback
            total_users
          }
        }
      }
    }
  }
  ${PATH_ITEM_FRAGMENT}
`
