import { graphql } from "@apollo/client/react/hoc"
import { withTranslate } from "@pathwright/ui/src/components/"
import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import Card from "@pathwright/ui/src/components/card/Card"
import CardBlock from "@pathwright/ui/src/components/card/CardBlock"
import Checkbox from "@pathwright/ui/src/components/form/form-checkbox/Checkbox"
import Fieldset from "@pathwright/ui/src/components/form/form-utils/Fieldset"
import Text from "@pathwright/ui/src/components/ui/Text"
import compose from "lodash/flowRight"
import get from "lodash/get"
import moment from "moment"
import { Field, Form, Submit } from "react-connect-form-forked"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import UPDATE_USER_MEMBERSHIP_MUTATION from "../graphql/update-user-membership-mutation"
import USER_MEMBERSHIP_QUERY from "../graphql/user-membership-query"

const tPrefix = "notifications.manage_card"

const ManageNotificationSettingsCard = ({
  card,
  updateConsent,
  consent_snapshot,
  consent_dtime,
  t
}) => {
  const { school } = usePathwrightContext()
  const schoolConsentDescription = school.consent_description
  const consents = !!consent_snapshot || undefined // checkbox bug
  const consentsToCurrent =
    !schoolConsentDescription ||
    schoolConsentDescription === consent_snapshot ||
    undefined // checkboxbug
  return (
    <Card card={card} title="Notification Settings">
      <Form
        initialValue={{
          consents,
          consentsToCurrent
        }}
        onSubmit={({ consents, consentsToCurrent }) =>
          updateConsent(
            consentsToCurrent
              ? true
              : consents
              ? consents
              : !!consent_snapshot
              ? false
              : null // no change
          )
        }
        render={({ onSubmit, ...formState }) => (
          <form onSubmit={onSubmit}>
            {consents && !consentsToCurrent && (
              <CardBlock>
                <Fieldset title={t(`${tPrefix}.updates`)}>
                  <Field
                    name="consentsToCurrent"
                    type="checkbox"
                    children={schoolConsentDescription}
                    component={Checkbox}
                  />
                </Fieldset>
              </CardBlock>
            )}
            {!formState.value.consentsToCurrent && (
              <CardBlock>
                <Field
                  name="consents"
                  type="checkbox"
                  children={
                    <span>
                      {consent_snapshot || schoolConsentDescription}
                      {consent_dtime && (
                        <span>
                          <br />
                          <Text.Meta>
                            {moment(consent_dtime).fromNow()}
                          </Text.Meta>
                        </span>
                      )}
                    </span>
                  }
                  component={Checkbox}
                />
              </CardBlock>
            )}
            <Submit styleType="primary" component={SubmitButton}>
              {t(`${tPrefix}.save`)}
            </Submit>
          </form>
        )}
      />
    </Card>
  )
}

export default withTranslate(
  compose(
    graphql(USER_MEMBERSHIP_QUERY, {
      props: ({ data }) => ({
        consent_snapshot: get(data, "me.membership.consent_snapshot"),
        consent_dtime: get(data, "me.membership.consent_dtime")
      })
    }),
    graphql(UPDATE_USER_MEMBERSHIP_MUTATION, {
      props: ({ mutate }) => ({
        updateConsent: (consents) =>
          mutate({
            variables: { consents }
          })
      })
    })
  )(ManageNotificationSettingsCard)
)
