import { Center, Divider, VStack } from "@chakra-ui/react"
import { useCertificateContext } from "../context/CertificateContext"
import CertificateResources from "../manager/CertificateResources"
import CertificateResourceUpdatePrompt from "./CertificateResourceUpdatePrompt"
import CertificateUpdatePrompt from "./CertificateUpdatePrompt"

function CertificateResourcesManager() {
  const { resource } = useCertificateContext()
  return (
    <VStack spacing={8} px={8} pb={8}>
      {resource ? (
        <CertificateResourceUpdatePrompt resource={resource} />
      ) : (
        <>
          <CertificateUpdatePrompt />
          <Center height="50px">
            <Divider orientation="vertical" />
          </Center>
          <CertificateResources />
        </>
      )}
    </VStack>
  )
}

export default CertificateResourcesManager
