import BannerFormCard from "@pathwright/web/src/modules/banner/manage/BannerFormCard"
import BannerListCard from "@pathwright/web/src/modules/banner/manage/BannerListCard"
import { createCardRoutes } from "lib/core/routing/card-routing"
import FallbackComponent from "store/StoreOrLibraryBrowse"
import { getStoreUrl } from "../store/utils"

export const cardRoutes = {
  banner: {
    component: BannerListCard,
    getDefaultLaunchedFrom: () => getStoreUrl(),
    childCards: {
      item: {
        path: ":banner_id(\\d+|new)/",
        component: (nextState) => (
          <BannerFormCard
            card={nextState.card}
            id={parseInt(nextState.match.params.banner_id)}
          />
        )
      }
    }
  }
}

export default createCardRoutes("/manage/", cardRoutes, FallbackComponent)
