class ViewStatesBehavior extends require("lib/static-shim").default(
  Marionette.Behavior
) {
  static initClass() {
    this.prototype.ui = { stateTriggers: "[data-trigger-state]" }

    this.prototype.defaults = {
      default: "",
      bind: false,
      states: [],
      force_state_change: false // force a state change even if new state is same as old state
    }

    this.prototype.events = {
      "click @ui.stateTriggers"(e) {
        e.preventDefault()
        e.stopPropagation()
        const state = $(e.currentTarget).data("trigger-state")
        return this.switchState(state)
      }
    }
  }

  initialize() {
    this._view_state = new Backbone.Model({ state: this.options.default })
    this.listenTo(this._view_state, "change:state", this._onStateChanged)
    this.listenTo(this.view, "change:state", this.switchState)
    return (this.view._view_state = this._view_state)
  }

  replaceView(view) {
    if (this._currentView) {
      this._currentView.destroy()
    }
    this.view.unbindUIElements()
    view.triggerMethod("before:show")
    this.$el.html(view.render().el)
    this.view.bindUIElements()
    view.triggerMethod("show")
    return (this._currentView = view)
  }

  replaceElement(el, view) {
    if (this._currentView) {
      this._currentView.destroy()
    }
    this.$(el).replaceWith(view.render().el)
    return (this._currentView = view)
  }

  appendView(view) {
    if (this._currentView) {
      this._currentView.destroy()
    }
    this.$el.append(view.render().el)
    return (this._currentView = view)
  }

  renderView() {
    if (this._currentView) {
      this._currentView.destroy()
    }
    return this.view.render()
  }

  _onStateChanged() {
    const stateKey = this._view_state.get("state")
    const handler = this.options.states[stateKey]
    this.$el.alterClass("view-state-*", `view-state-${stateKey}`)
    if (_.isFunction(handler)) {
      this.triggerMethod(`before:state:${stateKey}`)
      // TODO: check for returned promise here?
      _.bind(handler, this)()
      return this.triggerMethod(`state:${stateKey}`)
    }
  }

  switchState(state) {
    if (state === this._view_state.get("state")) {
      // Force state change
      if (this.options.force_state_change) {
        return this._view_state.trigger("change:state")
      }
    } else {
      return this._view_state.set({ state })
    }
  }

  onSwitchState(state) {
    return this.switchState(state)
  }

  onDestroy() {
    if (this.view != null) {
      delete this.view._view_state
    }
    return delete this._view_state
  }
}
ViewStatesBehavior.initClass()
export default ViewStatesBehavior
