import { useEffect, useRef, useState } from "react"

export const useIsMountedRef = () => {
  // Initial value of null so that a === false check can be used
  // to explicitly check for unmount.
  const isMountedRef = useRef(null)
  const [_, setIsMounted] = useState(null)

  useEffect(() => {
    isMountedRef.current = true
    setIsMounted(true)
    return () => {
      isMountedRef.current = false
      setIsMounted(false)
    }
  }, [])

  return isMountedRef
}

export default useIsMountedRef
