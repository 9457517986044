import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import get from "lodash/get"
import useCohortPermissions from "../cohort/useCohortPermissions"
import { usePathwrightContext } from "../pathwright/PathwrightContext"
import { canAdministrate } from "../user/permissions"
import {
  FEATURE_ACTION_AUTH_LOGIN,
  FEATURE_ACTION_UPDATE_VISIBILITY,
  FEATURE_ACTION_VIEW,
  FEATURE_PERMISSION_ADMIN_ONLY,
  FEATURE_PERMISSION_DISABLED,
  FEATURE_PERMISSION_EVERYONE,
  FEATURE_PERMISSION_STAFF_ONLY
} from "./constants"
import { CONTEXTUAL_FEATURE_PERMISSION_QUERY } from "./graphql"

// matching a feature action to either another feature action that it depends on
// or a feature permission
const CFP_UPDATE_PERMISSIONS = {
  [FEATURE_ACTION_VIEW]: {
    // updating the FEATURE_ACTION_VIEW depends on the FEATURE_ACTION_UPDATE_VISIBILITY permission
    featureAction: FEATURE_ACTION_UPDATE_VISIBILITY,
    // FEATURE_ACTION_VIEW can be updated at all context levels
    contexts: [
      null,
      { resource_id: null },
      { resource_id: null, cohort_id: null }
    ]
  },
  [FEATURE_ACTION_UPDATE_VISIBILITY]: {
    // NOTE: currently not needing to query for FEATURE_ACTION_UPDATE_VISIBILITY as
    // permission is limited to admins, therefore non-contextual
    featurePermission: FEATURE_PERMISSION_ADMIN_ONLY,
    // NOTE: currently FEATURE_ACTION_UPDATE_VISIBILITY can only be updated at the school level
    // though possibly might allow Library Editors and Course Editors to update at the resource level
    contexts: [null]
  },
  [FEATURE_ACTION_AUTH_LOGIN]: {
    // NOTE: currently not needing to query for FEATURE_ACTION_AUTH_LOGIN as
    // permission is limited to admins, therefore non-contextual
    featurePermission: FEATURE_PERMISSION_ADMIN_ONLY,
    // can only update FEATURE_ACTION_AUTH_LOGIN at school level
    contexts: [null]
  }
}

const CFP_VIEW_PERMISSIONS = {
  [FEATURE_ACTION_VIEW]: {
    featureAction: FEATURE_ACTION_VIEW,
    contexts: [
      // NOTE: no support for viewing activity at school level (handled separately by admin Dashbaord)
      // null,
      { resource_id: null },
      { resource_id: null, cohort_id: null }
    ]
  },
  [FEATURE_ACTION_UPDATE_VISIBILITY]: {
    featurePermission: FEATURE_PERMISSION_ADMIN_ONLY,
    contexts: [null]
  }
}

// actionPermissionMap matches a feature action to either another
// feature action that it depends on or a feature permission

// returns { featurePermission: FeaturePermission, hasPermission: Boolean }
const useFeaturePermission = ({
  featureKey,
  featureAction,
  context,
  actionPermissionMap
}) => {
  const pwContext = usePathwrightContext()
  // get the action that this feature depends on

  const {
    featureAction: dependentFeatureAction,
    featurePermission: dependentFeaturePermission,
    contexts: permissableContexts
  } = actionPermissionMap[featureAction]

  // optionally query for the permission on the dependent feature
  const cfpPermissionQuery = useQuery(CONTEXTUAL_FEATURE_PERMISSION_QUERY, {
    variables: {
      featureKey,
      featureAction: dependentFeatureAction,
      context
    },
    skip: !dependentFeatureAction
  })

  const permissionInContext = get(
    cfpPermissionQuery,
    "data.contextualFeaturePermission.permission_in_context"
  )

  const cohortPerms = useCohortPermissions({
    resourceId: context && context.resource_id,
    cohortId: context && context.cohort_id
  })

  // get the applicable feature permission
  let featurePermission =
    dependentFeaturePermission || permissionInContext || null
  let hasPermission = null

  if (featurePermission) {
    // NOTE: no break statements means first matching case will fall through to the rest
    // hence ordering of broadest permission to narrowest
    switch (featurePermission) {
      case FEATURE_PERMISSION_EVERYONE:
        if (cohortPerms.hasObserverLevelAccess) hasPermission = true
      case FEATURE_PERMISSION_STAFF_ONLY:
        if (cohortPerms.hasModeratorLevelAccess) hasPermission = true
        if (cohortPerms.hasEditorLevelAccess) hasPermission = true
      case FEATURE_PERMISSION_ADMIN_ONLY:
      case FEATURE_PERMISSION_DISABLED:
        if (canAdministrate(pwContext)) hasPermission = true
    }

    // ensure permission can be updated for the given context
    const canUpdateForContext =
      permissableContexts.findIndex(
        (permissableContext) =>
          // both options are nullish
          context == permissableContext ||
          // both options are object, and share same keys
          (context &&
            permissableContext &&
            Object.keys(context).every(
              (key) => Object.keys(permissableContext).indexOf(key) > -1
            ))
      ) > -1

    hasPermission = hasPermission && canUpdateForContext

    if (!hasPermission) {
      // permission check is complete, user does not have permission
      hasPermission = false
    }
  }

  // returning null can be used as an indication that the permission check
  // is still loading and the permission is yet to be determined
  return { featurePermission, hasPermission }
}

export const useFeatureUpdatePermission = ({
  featureKey,
  featureAction,
  context
}) =>
  useFeaturePermission({
    featureKey,
    featureAction,
    context,
    actionPermissionMap: CFP_UPDATE_PERMISSIONS
  })

export const useFeatureViewPermission = ({
  featureKey,
  featureAction,
  context
}) =>
  useFeaturePermission({
    featureKey,
    featureAction,
    context,
    actionPermissionMap: CFP_VIEW_PERMISSIONS
  })

export const useFeatureAuthLoginPermission = ({
  featureKey,
  featureAction,
  context
}) =>
  useFeaturePermission({
    featureKey,
    featureAction,
    context,
    actionPermissionMap: CFP_AUTH_LOGIN_PERMISSIONS
  })
