import withLauncher from "@pathwright/ui/src/components/launcher/withLauncher"
import Stack from "@pathwright/ui/src/components/stack/Stack"
import { sizePropType } from "@pathwright/ui/src/components/utils/props"
import PropTypes from "prop-types"
import styled from "styled-components"
import PathProgressContainer from "../path/legacy/analytics/PathProgressContainer"
import { getGroupUrl } from "../utils/urls"

const StyledContainer = styled.div`
  width: 100%;
  padding: 5px;
`

const StyledCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: white;
  color: black;
  min-height: 120px;
  transition: all 100ms ease-out;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  &:hover {
    transform: scale(1.05);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }
`

const StyledName = styled.h4`
  margin: 0;
  font-size: 12px;
  font-weight: 700;
`

const StyledMeta = styled.p`
  font-size: 10px;
  color: #888;
`

const StyledWrapper = styled.div`
  flex-grow: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  pointer-events: none;
`

const StyledInfo = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 10px;
`

const StyledProgress = styled.div`
  padding: 10px;
  min-height: 45px;
  background-color: whitesmoke;
  border-radius: 0 0 10px 10px;
`

const UserPreview = ({ groups, onLaunch }, context) => {
  const handleLaunch = (to) => (e) => {
    e.preventDefault()
    e.stopPropagation()
    onLaunch(to)
  }
  return (
    <StyledContainer onClick={(e) => e.stopPropagation()}>
      <Stack items={groups} itemWidth="170px" hoverable>
        {({ item, itemWidth, offset }) => (
          <StyledCard
            style={{ width: sizePropType._serialize(itemWidth).style }}
            onClick={handleLaunch(getGroupUrl(item.resource.slug, item.id))}
          >
            <StyledWrapper>
              <StyledInfo>
                <StyledName>{item.name}</StyledName>
                <StyledMeta>{item.seats_filled} members</StyledMeta>
              </StyledInfo>
              <StyledProgress>
                <PathProgressContainer cohort={item} />
              </StyledProgress>
            </StyledWrapper>
          </StyledCard>
        )}
      </Stack>
    </StyledContainer>
  )
}

UserPreview.displayName = "UserPreview"

UserPreview.contextTypes = {
  integrations: PropTypes.object
}

export default withLauncher({ Component: UserPreview })
