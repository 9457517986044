import { HStack } from "@chakra-ui/react"
import Avatar from "@pathwright/ui/src/components/avatar/Avatar"
import Text from "@pathwright/ui/src/components/ui/Text"
import classnames from "classnames"
import PropTypes from "prop-types"
import styled from "styled-components"
import IconButton from "../button/IconButton"
import DropdownTrigger from "../dropdown/DropdownTrigger"
import { BulletSeparationInline } from "../ui/BulletSeparation"
import { getDisplayName } from "./utils"

const Card = styled.div`
  background: white;
  min-width: 10em;
  max-width: 20em;
  box-shadow: 0px 7px 40px rgba(0, 0, 0, 0.1);
  text-align: left;
  position: relative;

  .AvatarCard__dropdown {
    align-self: flex-start;
    margin-left: auto;
  }

  h4 + span {
    display: inline-block;
  }

  > p {
    margin-top: 0.4em;
  }
`

const AvatarCard = ({
  user,
  meta,
  className,
  renderDropdown,
  onClickAvatar,
  dropdownTriggerProps
}) => {
  meta = [
    ...[].concat(meta),
    user.profile ? user.profile.location : null
  ].filter((meta) => meta)

  return (
    <Card className={classnames("AvatarCard", className)}>
      <HStack>
        <Avatar user={user} size="3.5em" onClick={onClickAvatar} />
        <div>
          <Text.H4>{getDisplayName(user)}</Text.H4>
          {!!meta.length && (
            <Text.Meta>
              <BulletSeparationInline>
                {meta.map((metaItem, index) => (
                  <span key={index}>{metaItem}</span>
                ))}
              </BulletSeparationInline>
            </Text.Meta>
          )}
        </div>
        {!!(renderDropdown || dropdownTriggerProps?.renderDropdown) && (
          <DropdownTrigger
            className="AvatarCard__dropdown"
            renderDropdown={renderDropdown}
            {...dropdownTriggerProps}
          >
            <IconButton icon="ellipses-horizontal" styleType="secondary" />
          </DropdownTrigger>
        )}
      </HStack>
      {!!user.profile && !!user.profile.bio && (
        <Text.Body>{user.profile.bio}</Text.Body>
      )}
    </Card>
  )
}

AvatarCard.displayName = "AvatarCard"

AvatarCard.propTypes = {
  user: PropTypes.object,
  meta: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  renderDropdown: PropTypes.func,
  onClickAvatar: Avatar.propTypes.onClick
}

AvatarCard.defaultProps = {
  meta: [],
  renderDropdown: null
}

export default AvatarCard
