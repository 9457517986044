class BaseCollection extends require("lib/static-shim").default(
  Backbone.Collection
) {
  urlRoot(url) {
    return Pathwright.getAPIUrl(url)
  }

  getViewContext() {
    return Array.from(this.models).map((m) => m.getViewContext())
  }
}

export default BaseCollection
