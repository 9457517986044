import AlertBannerSpacer from "@pathwright/ui/src/components/alert/AlertBannerSpacer"
import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import { useEffect } from "react"
import styled, { keyframes } from "styled-components"
import { CompletionPreview } from "../../completion/feedback/CompletionFeedbackPreview"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import ContextPanel, {
  CollapsableContextPanel,
  useOverlayMode
} from "../context/panel/ContextPanel"
import useKeyboardShortcuts from "../hooks/useKeyboardShortcuts"
import { useReviewContext } from "../state/ReviewContextProvider"
import {
  INBOX_CONTEXT_PANEL_MIN_WIDTH,
  INBOX_CONTEXT_PANEL_WIDTH,
  INBOX_LAYOUT_NAV_HEIGHT
} from "../ui/constants"
import InboxFocusItems from "./InboxFocusItems"
import { InboxFocusNavigationBar } from "./NavigationBar"

const contentIn = keyframes`
from {
  opacity: 0;
  transform: translateY(10px) scale(.5);
}
to {
  opacity: 1;
  transform: translateY(0) scale(1);
}
`

const navBarIn = keyframes`
from {
  opacity: 0.5;
  transform: translateY(-20px);
}
to {
  opacity: 1;
  transform: translateY(0);
}
`

const InboxContentContainer = styled.div`
  /* min-width: 400px; */
  margin: 0 auto;
  width: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  /* max-width: 800px; */
  overflow-x: hidden;
  /* align-items: center; */

  .CompletionPreview {
    background-color: transparent;
    padding: 0px;
    overflow-x: hidden;

    .roundedTop {
      overflow: hidden;
      border-radius: 20px !important;
    }
  }
`

const FocusItemViewContainer = styled(AlertBannerSpacer)`
  width: 100%;
  min-height: 500px;
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  z-index: 100;
  overflow-y: auto;
  overflow-x: hidden;

  > .sticky-context-wrapper {
    position: sticky;
    top: ${INBOX_LAYOUT_NAV_HEIGHT + 10}px;
    margin-left: auto;
    width: 320px;
    max-width: min(320px, calc(100vw - 50px));
    height: 0px;
    z-index: 1000000001;

    > .ContextPanel {
      position: absolute;
      z-index: 100002;
      width: 100%;
      max-width: ${INBOX_CONTEXT_PANEL_WIDTH}px;
      min-width: min(${INBOX_CONTEXT_PANEL_MIN_WIDTH}px, calc(100vw - 50px));
      top: 0px;
      height: calc(var(--vh) - ${INBOX_LAYOUT_NAV_HEIGHT + 20}px);
    }
  }

  .InboxFocusNavigationBar {
    animation: ${navBarIn} 0.33s;
    position: sticky;

    background-color: rgba(0, 0, 0, 0.5);
  }

  --content-min-height: calc(var(--vh) - ${INBOX_LAYOUT_NAV_HEIGHT}px - 10px);
  &.loaded {
    --content-min-height: 300px;
  }
  > main {
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;

    > .ContextPanel {
      height: calc(var(--content-min-height) - 10px);
      margin-top: 10px;
      margin-bottom: 10px;
      animation: ${contentIn} 0.3s;
      animation-timing-function: ease-out;
      min-width: ${INBOX_CONTEXT_PANEL_WIDTH}px;
    }

    .CompletionPreview {
      min-height: calc(var(--content-min-height) - 10px);
      margin-top: 10px;
      margin-bottom: 10px;
      animation: ${contentIn} 0.25s;
      animation-timing-function: ease-out;
      background-color: transparent;
      > div.roundedTop {
        padding: 0px !important;
        flex-grow: 1;
      }

      .ProgressBar {
        display: none;
      }
    }
  }
`

const FocusItemView = ({ item, ...props }) => {
  const { me } = usePathwrightContext()

  // TODO: if the context panel should be in overlay mode then we need to render it outside of <main>
  // will that lose state though?
  const contextPanelOverlayMode = useOverlayMode()

  return (
    <FocusItemViewContainer {...props}>
      <InboxFocusNavigationBar />
      {contextPanelOverlayMode && (
        <div className="sticky-context-wrapper">
          <CollapsableContextPanel />
        </div>
      )}
      <main>
        <CompletionPreview
          // key={item.id}
          step={item.data}
          stepId={item.id}
          me={me}
        />
        {!contextPanelOverlayMode && <ContextPanel />}
      </main>
    </FocusItemViewContainer>
  )
}

const InboxFocus = () => {
  const {
    selected,
    selectedParent,
    context,
    actions: { getItem }
  } = useReviewContext()

  // disable body scroll when selected item is visible
  useEffect(() => {
    const body = document.querySelector("body")
    body.style.overflow = !!selected ? "hidden" : "auto"
  }, [selected])

  useKeyboardShortcuts()

  return (
    <InboxContentContainer>
      {context.error ? (
        <BlankSlate
          icons={null}
          heading="An unexpected error occurred."
          inverted
          // TODO: provide option to clear selection.
        />
      ) : (
        <InboxFocusItems item={selectedParent} />
      )}
      {selected && <FocusItemView item={selected} />}
    </InboxContentContainer>
  )
}

export default InboxFocus
