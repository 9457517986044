import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import classnames from "classnames"
import React from "react"
import "./AskToAnswerActions.css"

class AskToAnswerActions extends React.Component {
  static displayName = "AskToAnswerActions"
  state = { asked: [] }

  onSelect = (e, registration) => {
    e.preventDefault()
    $(e.currentTarget)
      .addClass("notified")
      .closest(".discussion-admin-menu")
      .addClass("open")
    this.props.onSelect(registration)
    return this.setState({ asked: this.state.asked.concat(registration.id) })
  }

  getClass = (registration) => {
    const selected = this.state.asked.indexOf(registration.id) > -1
    return classnames("AskToAnswerAction", {
      "AskToAnswerAction--selected": selected
    })
  }

  render() {
    const data = this.props.discussionStore.state
    if (data.moderators_for_context) {
      return (
        <ul className="AskToAnswerActions">
          {data.moderators_for_context.results.map((registration) => {
            return (
              <li className={this.getClass(registration)} key={registration.id}>
                <a
                  className="brand-pseudo"
                  href="javascript:void(0)"
                  onClick={(e) => this.onSelect(e, registration)}
                >
                  <img src={registration.user.profile.image_circle} />
                  {registration.user.display_name}
                </a>
              </li>
            )
          })}
        </ul>
      )
    } else {
      return <LoadingCircle />
    }
  }
}

export default AskToAnswerActions
