import Avatar from "@pathwright/ui/src/components/avatar/Avatar"
import Button from "@pathwright/ui/src/components/button/Button"
import IconButton from "@pathwright/ui/src/components/button/IconButton"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import { Component } from "react"
import styled from "styled-components"
import PlaceBlank from "./PlaceBlank"

const StyledContainer = styled.div`
  transform: translateZ(0);
  user-select: none;
`

const StyledWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: 320px;
  min-height: 320px;
  border-radius: 20px;
  transform: scale(0.97);
  transform-origin: top center;
  background-color: rgba(255, 255, 255, 0.1);
  transition: all 100ms ease-in;
  cursor: pointer;
  &:hover {
    transform: scale(1);
    background-color: rgba(255, 255, 255, 0.2);
  }
`

const StyledName = styled.h3`
  margin: 15px 0 0;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  .Avatar {
    position: relative;
    top: 2px;
  }
`

const StyledMeta = styled.div`
  margin-top: 5px;
  color: rgba(255, 255, 255, 0.6);
  p {
    margin: 0;
  }
`

const StyledPreview = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const StyledButton = styled(Button)`
  width: 100%;
  border-radius: 5px;
`

const StyledTooltip = styled(IconButton)`
  position: absolute !important;
  top: 10px;
  right: 10px;
`

class Place extends Component {
  state = {
    tooltip: false
  }

  componentWillUnmount() {
    this.delayedHide && clearTimeout(this.delayedHide)
    this.delayedShow && clearTimeout(this.delayedShow)
  }

  handleHideTooltip = () => {
    // Cancel delayed show
    if (this.delayedShow != null) {
      clearTimeout(this.delayedShow)
      this.delayedShow = null
    }
    // Avoid unnecessary calls
    if (!this.state.tooltip || this.delayedHide != null) {
      return
    }
    // Start delayed hide
    this.delayedHide = setTimeout(() => {
      this.delayedHide = null
      this.setState({ tooltip: false })
    }, 200)
  }

  handleShowTooltip = () => {
    // Cancel delayed hide
    if (this.delayedHide != null) {
      clearTimeout(this.delayedHide)
      this.delayedHide = null
    }
    // Avoid unnecessary calls
    if (this.state.tooltip || this.delayedShow != null) {
      return
    }
    // Start delayed show
    this.delayedShow = setTimeout(() => {
      this.delayedShow = null
      this.setState({ tooltip: true })
    }, 200)
  }

  render() {
    const {
      to,
      icon,
      name,
      action,
      footer,
      children,
      onLaunch,
      isBlank,
      blankImage,
      blankDescription
    } = this.props
    return (
      <StyledContainer>
        <StyledWrapper onClick={(e) => onLaunch(to)}>
          <div>
            <StyledName>
              {typeof icon === "string" ? (
                <Pathicon icon={icon} />
              ) : (
                <Avatar user={icon} size="21px" />
              )}
              &nbsp;
              {name}
            </StyledName>
            <StyledMeta>{footer}</StyledMeta>
          </div>
          <StyledPreview>
            {isBlank || this.state.tooltip ? (
              <PlaceBlank image={blankImage} description={blankDescription} />
            ) : (
              children
            )}
          </StyledPreview>
          <StyledButton styleType="blank" color="blank" size="large" inverted>
            <span>{action}</span>
            <Pathicon icon="chevron-right" />
          </StyledButton>
          {!isBlank && (
            <StyledTooltip
              onMouseOver={this.handleShowTooltip}
              onMouseOut={this.handleHideTooltip}
              icon="info-circle"
              color={this.state.tooltip ? "white" : "rgba(255,255,255,0.5)"}
            />
          )}
        </StyledWrapper>
      </StyledContainer>
    )
  }
}

Place.displayName = "Place"

export default Place
