import OptionsButton from "@pathwright/ui/src/components/button/OptionsButton"
import DropdownItem from "@pathwright/ui/src/components/dropdown/DropdownItem"
import DropdownMenu from "@pathwright/ui/src/components/dropdown/DropdownMenu"
import DropdownTrigger from "@pathwright/ui/src/components/dropdown/DropdownTrigger"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import get from "lodash/get"
import moment from "moment"
import { forwardRef } from "react"
import { usePathwrightContext } from "../../../pathwright/PathwrightContext"
import getStripePaymentPortalHref from "../../../stripe/utils/getStripePaymentPortalHref"
import ManageMentorGroupCard from "../../manage/ManageMentorGroupCard"
import { canReceiveWeeklyDigest } from "../../permissions"
import { getToGroupManage } from "../../utils"

export const MentorGroupControlsMenu = forwardRef(
  ({ mentorGroup, groupSubscription }, ref) => {
    const { school } = usePathwrightContext()
    const { tc, tca } = useTranslate()
    const permissions = get(mentorGroup, "permissions", {})
    const isOverfull = mentorGroup && mentorGroup.seats_available < 0

    return (
      <DropdownMenu ref={ref}>
        {permissions.can_edit_group && (
          <DropdownItem
            icon="gear"
            label={tca("Edit group settings")}
            to={{
              pathname: getToGroupManage(mentorGroup.id),
              card: {
                component: ManageMentorGroupCard,
                props: {
                  id: mentorGroup.id
                }
              }
            }}
            closeOnClick
          />
        )}
        {permissions.can_edit_subscription && groupSubscription && (
          <DropdownItem
            icon={
              groupSubscription.subscription.canceled_dtime ||
              groupSubscription.subscription.is_delinquent
                ? "recur-x"
                : groupSubscription.subscription.paused_until_dtime
                ? "recur-pause"
                : "recur"
            }
            label={tca("Manage subscription")}
            href={getStripePaymentPortalHref(school)}
            target="_blank"
            indicator={isOverfull}
            meta={
              isOverfull
                ? tc(`Overfull. Reduce seats or increase subscription`)
                : groupSubscription.subscription.canceled_dtime
                ? tc("Inactive. Restart anytime")
                : groupSubscription.subscription.is_delinquent
                ? tc("Suspended. Update card to activate")
                : groupSubscription.subscription.paused_until_dtime &&
                  moment().isBefore(
                    groupSubscription.subscription.paused_until_dtime
                  )
                ? tc(`Paused until {{ date }}`, {
                    date: moment(
                      groupSubscription.subscription.paused_until_dtime
                    ).format("LLL")
                  })
                : tc(`Active with {{ filled }}/{{ limit }} seats filled`, {
                    filled: groupSubscription.community_group.seats_filled,
                    limit: groupSubscription.community_group.seat_limit
                  })
            }
            closeOnClick
          />
        )}
      </DropdownMenu>
    )
  }
)

function MentorGroupControls({ children, mentorGroup, groupSubscription }) {
  const permissions = get(mentorGroup, "permissions", {})
  const isOverfull = mentorGroup && mentorGroup.seats_available < 0
  const buttonProps = {
    indicator: !!groupSubscription && isOverfull
  }

  if (
    permissions.can_add_member ||
    permissions.can_edit_group ||
    permissions.can_edit_subscription ||
    canReceiveWeeklyDigest(mentorGroup)
  ) {
    return (
      <DropdownTrigger
        renderDropdown={() => (
          <MentorGroupControlsMenu
            mentorGroup={mentorGroup}
            groupSubscription={groupSubscription}
          />
        )}
      >
        {children ? children(buttonProps) : <OptionsButton {...buttonProps} />}
      </DropdownTrigger>
    )
  }

  return null
}

MentorGroupControls.displayName = "MentorGroupControls"

export default MentorGroupControls
