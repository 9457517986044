// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
let DiscussionSubscription
export default DiscussionSubscription = class DiscussionSubscription extends (
  require("lib/static-shim").default(require("lib/utils/model").default)
) {
  url() {
    const url = this.urlRoot(
      `api/private/discussion/${this.get("discussion_id")}/subscription/`
    )
    // Add a query param for context since this might be a DELETE
    return `${url}?${$.param({ context: this.get("context") })}`
  }
}
