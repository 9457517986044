import gql from "graphql-tag"
import SCHOOL_FRAGMENT from "../../school/graphql/school-fragment"
import MENTOR_GROUP_MEMBERSHIP_FRAGMENT from "./mentor-group-membership-fragment"

export default gql`
  query MentorGroupStaffMemberships($group_id: Int!) {
    school {
      ...School
      mentor_group(id: $group_id) {
        id
        memberships(first: 1042, role: { gte: moderator }, order_by: "-role") {
          total
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              ...MentorGroupMembership
            }
          }
        }
      }
    }
  }
  ${SCHOOL_FRAGMENT}
  ${MENTOR_GROUP_MEMBERSHIP_FRAGMENT}
`
