import useDebouncedValue from "@pathwright/ui/src/components/hooks/useDebouncedValue"
import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import get from "lodash/get"
import { flattenEdges, usePaginator } from "../../utils/apollo"
import LIBRARY_RESOURCES_QUERY from "./library-resources-query"

export const useLibraryResourcesQuery = ({
  idFilter,
  licensedFromSchoolIdFilter,
  resourceTypeFilter,
  roleFilter,
  assignConfig,
  first = 24,
  orderBy,
  search: searchProp,
  fetchPolicy = "network-only"
}) => {
  const [search, debouncedSearch, debouncingSearch, handleDebounceSearch] =
    useDebouncedValue(300)

  const libraryResourcesQuery = useQuery(LIBRARY_RESOURCES_QUERY, {
    variables: {
      idFilter,
      licensedFromSchoolIdFilter,
      resourceTypeFilter,
      roleFilter,
      assignConfig,
      search: searchProp || debouncedSearch,
      first,
      orderBy
    },
    fetchPolicy,
    notifyOnNetworkStatusChange: true
  })

  const { data, error, loading, refetch } = libraryResourcesQuery

  const { loadMore, hasMore, loadingMore } = usePaginator({
    data: libraryResourcesQuery, // hacky
    path: "resources"
  })
  const total = get(data, "resources.total", 0)
  const resources = flattenEdges(get(data, "resources"))

  return {
    libraryResourcesQuery,
    error,
    loading,
    refetch,
    loadMore,
    hasMore,
    loadingMore,
    total,
    resources,
    search,
    debouncedSearch,
    debouncingSearch,
    handleDebounceSearch
  }
}

export const withLibraryResources = (ComposedComponent) => (props) => {
  const passProps = useLibraryResourcesQuery(props)
  return <ComposedComponent {...props} {...passProps} />
}

const LibraryResourcesQuery = withLibraryResources(
  ({ children, ...rest }) => children(rest) || null
)

LibraryResourcesQuery.displayName = "LibraryResourcesQuery"

export default LibraryResourcesQuery
