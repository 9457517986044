import { useCallback, useEffect, useReducer, useRef } from "react"

export type SyncedReducerReturnType<State, Action> = [
  state: State,
  dispatchAndSync: (action: Action) => void
]

export type ReducerActionType = {
  type: string
  payload?: any
}

// use reducer + custom dispatch and sync
export const useSyncedReducer = <
  State extends Record<string, any>,
  Action extends ReducerActionType,
  Props extends Record<string, any>
>(
  reducer: (state: State, action: Action) => any,
  sync: (
    action: Action,
    state: State,
    dispatch: (action: Action) => any
  ) => void,
  initialState: Props | ((props: Props) => State),
  init: (props: Props) => State
): SyncedReducerReturnType<State, Action> => {
  const [state, dispatch] = useReducer(reducer, initialState, init)
  const unsyncedAction = useRef<Action | null>(null)

  const dispatchAndSync = useCallback((action: Action) => {
    unsyncedAction.current = action
    dispatch(action)
  }, [])

  useEffect(() => {
    if (unsyncedAction.current) {
      const action = unsyncedAction.current
      unsyncedAction.current = null
      sync(action, state, dispatch)
    }
  }, [state])

  return [state, dispatchAndSync]
}

export default useSyncedReducer
