const isEmptyHTML = (html) => {
  return (
    !html ||
    !html.length ||
    html === "<p><br></p>" ||
    html === "<p><br /></p>" ||
    html === "<p></p>"
  )
}

// Remove HTML w/ a regex (can't use DOM methods here)
const normalizeHTMLText = (label = "") => {
  return label
    .trim()
    .replace(/(<([^>]+)>)/gi, "")
    .toLowerCase()
}

export const scoreMultipleChoice = (data, layout) => {
  const { options, answer } = data

  if (!options || !answer) return null

  const possibleCorrect = options.filter((op) => op.isCorrectAnswer === true)

  // if no correct answers, this is a survey
  if (possibleCorrect.length === 0) return null

  // Filter out any selected options that are not in the list of options
  // This can happen if the question is edited after a user has answered it
  const selectedOptions: any[] =
    answer.selectedOptions?.filter((s: any) =>
      options.find((o: any) => o.label === s.label)
    ) || []

  // Check for a wrong answer (in chekboxes/multiple response block)
  //  by ensuring there are no more answers than possible correct,
  //  since below we will ensure the number of correct and possible answers match up.
  if (
    layout === "checkBoxes" &&
    selectedOptions.length > possibleCorrect.length
  )
    return 0

  const correctAnswers = selectedOptions.filter(
    (op) => op.isCorrectAnswer === true
  )

  // Handle legacy data that might have multiple MC answers marked as correct
  if (layout === "multipleChoice") return correctAnswers.length ? 1 : 0

  // figure out if the user selected the right option(s)
  return correctAnswers.length >= possibleCorrect.length ? 1 : 0
}

// Do not display blank matching options or options with no match
export const getValidMatchingOptions = (options, matches) => {
  return options.filter((o) => {
    // 1) isn't empty
    if (isEmptyHTML(o.label)) return false

    // 2) has a corresponding, non-empty match (matched by order value)
    const match = matches.find((m) => m.order === o.order)
    return !!match && !isEmptyHTML(match.label)
  })
}

export const scoreMatching = (data) => {
  const { options, matches, answer = {} } = data

  const { selectedOptions: selectedMatches } = answer
  const validOptions = getValidMatchingOptions(options, matches)

  // Cannot score unless there are some valid matching rows and an answer for each row
  if (
    !validOptions?.length ||
    !selectedMatches?.length ||
    selectedMatches.length < validOptions.length
  )
    return null

  let score: number | null = 1

  // There are 2 methods for matching the learner's selected match to the correct match:
  // 1) (legacy) The label is the same and the order is the same
  // 2) The value is `${option.value}:${correctMatch.value}`

  // First, check if we should apply legacy scoring
  // Every option, match, and selected match must have a value
  // and every selected match must have a value that includes a colon
  const isLegacy =
    !validOptions.every((o) => !!o.value) ||
    !selectedMatches.every((m) => !!m.value && m.value.includes(":")) ||
    !matches.every((m) => !!m.value)

  // For every (valid) matching option (left column), score its corresponding answer
  // The matching block currently follows a simple all or nothing scoring scheme
  validOptions.forEach((option) => {
    // Find the match chosen by the Designer for this option
    const correctMatch = matches.find((m) => m.order === option.order)

    let correctSelectedMatch = null

    // Legacy score is determined by order comparison.
    // Correct if learner's selected match for this option matches the correct match by text and order.
    // Note: score may be 0 if designer has reordered after a learner has answered.
    if (isLegacy) {
      correctSelectedMatch = selectedMatches.find(
        (m) =>
          m.order === correctMatch.order &&
          normalizeHTMLText(m.label) === normalizeHTMLText(correctMatch.label)
      )
    } else {
      // New score is determined by option/match value comparison.
      correctSelectedMatch = selectedMatches.find((m) => {
        // This check allows the Designer to set multiple matches with the same value;
        // the answer dropdown will filter out duplicates by label.
        // any match that has the same label (not value) as the match in the correct answer
        // and has the correct value (option.value:correctMatch.value)
        // will be considered correct
        const selectedMatchIncludesOptionValue = m.value.includes(
          `${option.value}:`
        )
        const selectedMatchIncludesCorrectMatchText =
          normalizeHTMLText(m.label) === normalizeHTMLText(correctMatch.label)

        return (
          selectedMatchIncludesOptionValue &&
          selectedMatchIncludesCorrectMatchText
        )
      })
    }

    if (!correctSelectedMatch) {
      score = 0
    }
  })

  return score
}

export const isParagraphComplete = (data) => {
  // check the auto submit case
  const isAutoSubmit = !data.feedback
  const { answer } = data
  const input = answer ? answer.answerHTML || answer.answerText : ""
  const isEmpty = isEmptyHTML(input)
  if (isAutoSubmit && !isEmpty) {
    return true
  }

  // check the bool flag (may not be present for legacy)
  return answer.hasSubmitted === true
}

export const isAnswerComplete = (data, layout = "") => {
  const { answer } = data
  if (!answer) return false

  if (!layout) {
    // console.warn("No layout provided to isAnswerComplete", data)
  }

  if (layout.toLowerCase() === "paragraph") return isParagraphComplete(data)

  return !!answer.hasSubmitted
}

const getScore = (data, layout) => {
  layout = layout.toLowerCase()
  if (layout === "matching") {
    return scoreMatching(data)
  } else if (layout === "multiplechoice" || layout === "checkboxes") {
    return scoreMultipleChoice(data, layout)
  } else {
    return null
  }
}

export const resolveUserCompletion = (context, data, layout) => {
  const progress = isAnswerComplete(data, layout) ? 1 : 0

  const score = progress === 1 ? getScore(data, layout) : null
  return Promise.resolve({
    progress,
    score
  })
}
