import Card from "@pathwright/ui/src/components/card/Card"
import FeatureBlockList from "../../feature/block/FeatureBlockList"
import {
  FEATURE_ACTION_VIEW,
  FEATURE_KEY_ACTIVITY_FEED,
  FEATURE_KEY_MEMBER_DIRECTORY
} from "../../feature/constants"

const ManageAccountFeatures = ({ card }) => {
  return (
    <Card card={card} title="Configure Cohort communities">
      <FeatureBlockList
        key={FEATURE_ACTION_VIEW}
        featureAction={FEATURE_ACTION_VIEW}
        featureKeys={[FEATURE_KEY_ACTIVITY_FEED, FEATURE_KEY_MEMBER_DIRECTORY]}
      />
    </Card>
  )
}

ManageAccountFeatures.displayName = "ManageAccountFeatures"

export default ManageAccountFeatures
