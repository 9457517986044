import { Heading, HeadingProps } from "@chakra-ui/react"

const AuthFormHeading = (props: HeadingProps): JSX.Element => (
  <Heading
    as="h1"
    size="md"
    textAlign="center"
    // TODO: use fontFamily="theme.heading" once the parent ChakraProvider uses correct heading theme font
    className="theme-heading theme-heading-bold"
    {...props}
  />
)

export default AuthFormHeading
