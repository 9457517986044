import PropTypes from "prop-types"
import React, { Component, useContext } from "react"

const SCREENSIZES = ["sm", "md", "lg", "xl"]
const SCREENSIZES_REVERSED = SCREENSIZES.slice().reverse()

export const ScreensizeContext = React.createContext()

export class Provider extends Component {
  static displayName = "ScreensizeProvider"

  static getScreensize() {
    if (window.innerWidth <= 480) return "sm"
    if (window.innerWidth <= 768) return "md"
    if (window.innerWidth <= 992) return "lg"
    return "xl"
  }

  static propTypes = {
    screensize: PropTypes.oneOf(SCREENSIZES)
  }

  static childContextTypes = {
    screensize: PropTypes.string.isRequired
  }

  state = {
    screensize: Provider.getScreensize()
  }

  get screensize() {
    return this.props.screensize || this.state.screensize
  }

  getChildContext() {
    return {
      screensize: this.screensize
    }
  }

  UNSAFE_componentWillMount() {
    if (!this.props.screensize) {
      window.addEventListener("resize", this.handleResize)
    }
  }

  componentWillUnmount() {
    if (!this.props.screensize) {
      window.removeEventListener("resize", this.handleResize)
    }
  }

  handleResize = (e) => {
    const nextScreensize = Provider.getScreensize()
    if (nextScreensize !== this.state.screensize) {
      this.setState({ screensize: nextScreensize })
    }
  }

  render() {
    return (
      <ScreensizeContext.Provider value={this.screensize}>
        {this.props.children}
      </ScreensizeContext.Provider>
    )
  }
}

// pass screensize context as prop to children
export class Consumer extends React.Component {
  static displayName = "Screensize.Consumer"

  static contextTypes = {
    screensize: PropTypes.string
  }

  render() {
    return React.Children.only(this.props.children(this.context.screensize))
  }
}

export const useScreensizeContext = () => {
  return useContext(ScreensizeContext)
}

export const withScreensize = (Component) => (props) =>
  (
    <Consumer>
      {(screensize) => <Component screensize={screensize} {...props} />}
    </Consumer>
  )

// helper method
export function select(obj) {
  return function (props) {
    if (typeof obj === "function") obj = obj(props)
    if (!obj) return undefined
    // Attempt to select the largest screensize setting available for the current screensize
    const screesizesCascade = SCREENSIZES_REVERSED.map((_, i) =>
      SCREENSIZES_REVERSED.slice(0, i + 1)
    )
    for (let i = 0; i < screesizesCascade.length; i++) {
      const screensize = SCREENSIZES_REVERSED[i]
      const cascade = screesizesCascade[i]
      if (cascade.includes(props.screensize)) {
        if (obj.hasOwnProperty(screensize)) return obj[screensize]
      }
    }
  }
}

// const style = Screensize.select({ lg: 100, sm: 50 })
// style({ screensize: "xl" }) // => 100
// style({ screensize: "lg" }) // => 100
// style({ screensize: "md" }) // => 50
// style({ screensize: "sm" }) // => 50
// style({ screensize: null }) // => undefined

export default { Provider, Consumer, select }
