// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
let Form
require("./styles/form.css")

// Have to load these to make them available
require("lib/uniform/views/templates/uniform-macros.html").default

export default Form = (function () {
  Form = class Form extends (
    require("lib/static-shim").default(require("lib/utils/view").default)
  ) {
    static initClass() {
      this.prototype.tagName = "form"
      this.prototype.className = "pw-uniform"
      this.prototype.template = false
      this.prototype.promise = null
      this.prototype.settings = {
        focusFirstField: false,
        buttonProcessingLabel: "Processing..."
      }
      // State model is used to keep track of valid state and
      // form error messages
      this.prototype.state = new window.BackboneUtils.Model({ valid: false })
      this.prototypefieldStates = {}

      this.prototype.events = {
        "click div[data-field-span]"(e) {
          // all inputs are wrapped w/ data-field-span and
          // this extends require("lib/static-shim") focus area beyond label and input
          // blocks alone.
          return $(e.currentTarget).find("input").focus()
        }
      }
    }

    behaviors() {
      return {
        FocusFirstField: {
          focusEvent: null
        },
        Radio: {},
        FlashMessage: {},
        Tooltip: {},
        // DatePicker:
        //   initEvent: "before:bind"
        RichText: {},
        GridForm: {},
        Bind: {
          bindEvent: null,
          serializeBindData() {
            return {
              model: this.model,
              state: this.state
            }
          },
          options: {
            // Overriding templateDelimeters (which default to ["{", "}"])
            // Otherwise brackets are removed from bound model attributes and +
            // we don't use rivets for templating on forms (or anywhere?), just for binding

            // UPDATE: instead of an empty array, need to provide opening and closing delimiter strings as these are EXPECTED by rivets
            templateDelimiters: ["{{{{{", "}}}}}"]
          }
        },
        Parsley: {}
      }
    }

    constructor() {
      super(...arguments)

      this.onSave = this.onSave.bind(this)
      this.onSaveFailed = this.onSaveFailed.bind(this)

      this.listenTo(this.state, "change:valid", () =>
        this.triggerMethod("form:validated")
      )

      // Bind to events rather than overriding methods
      this.on("render", this.initForm)
      this.on("before:destroy", this._onBeforeDestroy)

      // Have to call this after super to ensure @el
      // For reverse form lookup from @el
      this.$el.data("pw-form", this)
    }

    initForm() {
      this.triggerMethod("before:form:init")
      this.initButton()
      this._bindSubmitEvent()
      if (this.settings.focusFirstField) {
        this.triggerMethod("focus:first:field")
      }

      // HACK ALERT: deferring riveta binding to RichText behavior since it is using React which delays the redactor editor from mounting
      const deferBinding = Object.keys(this.behaviors()).includes("RichText")
      if (!deferBinding) {
        this.triggerMethod("apply:binding")
        this.triggerMethod("after:form:init")
      } else {
        setTimeout(() => {
          this.triggerMethod("apply:binding")
          this.triggerMethod("after:form:init")
        }, 20)
      }
    }

    onDestroy() {
      return this.$el.off("submit")
    }

    // Preventing the default stops the form on submission
    // from refreshing the page
    _bindSubmitEvent() {
      return this.$el.on("submit", (e) => {
        this.triggerMethod("form:submitted")
        return e.preventDefault()
      })
    }

    // Manually submit the form. This is for forms that
    // do not have models
    onSubmitForm() {
      this.$el.off("submit")
      this.$el.submit()
      return this._bindSubmitEvent()
    }

    initButton() {
      const submitButton = this.$("*[type=submit]")
      if (submitButton.length) {
        return submitButton.on("click", (e) => {
          e.preventDefault()
          return this.submit()
        })
      }
    }

    disableButton() {
      const $btn = this.$(".pw-form-submit")
      if (!$btn.length) {
        return
      }
      $btn.attr("disabled", true).addClass("disabled")
      $btn.data("label", $btn.text())
      return $btn.text(this.settings.buttonProcessingLabel)
    }

    enableButton() {
      const $btn = this.$(".pw-form-submit")
      if (!$btn.length) {
        return
      }
      $btn.removeAttr("disabled").removeClass("disabled")
      return $btn.text($btn.data("label"))
    }

    // May not be the best solution, but trying to have a consistent approach to
    // trimming certain attribute values before saving
    trimAttributeValues() {
      let val
      const list = ["username", "email"]
      return Array.from(list)
        .filter((attr) => (val = this.model.get(attr)))
        .map((attr) => this.model.set(attr, val.trim()))
    }

    submit() {
      this.triggerMethod("before:model:save", this.model)
      if (this.promise == null) {
        this.promise = new $.Deferred()
      }
      const hasPlaceholders = this.$(".placeholdersjs").length > 0
      if (hasPlaceholders) {
        Placeholders.disable(this.el)
      }
      this.triggerMethod("validate")
      // Trigger form:submitted if form does not have a model
      if (!this.model) {
        this.triggerMethod("form:submitted", this.isValid())
      }
      if (hasPlaceholders) {
        Placeholders.enable(this.el)
      }
      return this.promise
    }

    onSubmit() {
      const isValid = this.isValid()
      if (!isValid) {
        if (this.promise) {
          this.promise.reject("Invalid field values")
        }
        return false
      }
      this.disableButton()
      if (isValid) {
        return this.saveModel()
      }
    }

    saveModel() {
      let promise
      if (!this.model) {
        return
      }
      this.isNew = this.model.isNew()
      this.trimAttributeValues()
      this.triggerMethod("before:model:save", this.model)
      this.promise = new $.Deferred()
      const validationErrors = this.model.validate()
      if (!validationErrors) {
        promise = this.model.save()
        promise.done(this.onSave).fail(this.onSaveFailed)
      } else {
        this.enableButton()
        this.triggerMethod("flash", validationErrors, "error")
        this.promise.reject(validationErrors)
        this.triggerMethod("model:save:error", validationErrors)
      }
      return this.promise
    }

    onSave(result) {
      this.enableButton()
      const successMessage = this.successMessage || "Saved!"
      this.triggerMethod("flash", successMessage, "success")
      this.promise.resolve(result)
      return this.triggerMethod("model:save", this.model, this.isNew)
    }

    onSaveFailed(result) {
      this.enableButton()
      result = this.errorMessage || result
      this.triggerMethod("flash:api:error", result)
      this.promise.reject(result)
      this.promise = null
      return this.triggerMethod("model:save:error", result)
    }

    _onBeforeDestroy() {
      this.$el.removeData("pw-form")
      const hasPlaceholders = this.$(".placeholdersjs").length
      if (hasPlaceholders) {
        Placeholders.disable(this.el)
      }
      this.triggerMethod("reset:rich:text")
      return this.triggerMethod("reset:parsley")
    }

    reset() {
      this.triggerMethod("clear:flash:messages")
      this.el.reset()
      this.enableButton()
      this.triggerMethod("reset:rich:text")
      return this.triggerMethod("reset:parsley")
    }

    isValid() {
      return this.state.get("valid")
    }

    validate(silent) {
      if (silent == null) {
        silent = false
      }
      return this.triggerMethod("validate", silent)
    }

    // TODO: remove this once it's in the app module
    serializeGlobal() {
      return {
        school: window.App.request("get:school").toJSON(),
        user: window.App.request("get:user").toJSON()
      }
    }
  }
  Form.initClass()
  return Form
})()
