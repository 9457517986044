import gql from "graphql-tag"

export default gql`
  mutation (
    $name: String!
    $subdomain: String!
    $licensor_id: Int
    $copy: [String]
    $utm_source: String
    $utm_medium: String
    $utm_campaign: String
  ) {
    createSchool(
      name: $name
      subdomain: $subdomain
      licensor_id: $licensor_id
      copy: $copy
      utm_source: $utm_source
      utm_medium: $utm_medium
      utm_campaign: $utm_campaign
    ) {
      id
      name
      sso_link(pathname: "/home/learn/")
      profile {
        id
      }
    }
  }
`
