import { ApolloQueryResult, QueryResult } from "@apollo/client"
import partial from "lodash/partial"
import { useEffect, useMemo } from "react"
import { LoaderFunctionArgs, redirect } from "react-router-dom"
import { useAsync } from "react-use"
import { getClient } from "../../../api/client"
import {
  AuthContextDocument,
  AuthContextQuery,
  AuthContextQueryVariables,
  AuthMethodsDocument,
  AuthMethodsQuery,
  AuthMethodsQueryVariables,
  useAuthContextQuery
} from "../../../api/generated"
import { hasAuthenticationToken } from "../../../lib/utils/auth-token"
import { UserPropertyDefaults } from "../types"
import { AuthIntent, getAuthIntent } from "../utils/authIntent"
import getAuthRoute from "../utils/getAuthRoute"
import getInviteTokenParam from "../utils/getInviteTokenParam"
import {
  hasPreferredAuthMethod,
  setPreferredAuthMethod
} from "../utils/preferredAuthMethod"

export const loadAuthMethods = async () => {
  const client = getClient()
  const { data } = await client.query<
    AuthMethodsQuery,
    AuthMethodsQueryVariables
  >({
    query: AuthMethodsDocument
  })

  const authMethods = data?.space?.authMethods || []

  return { authMethods }
}

export const useAuthMethods = partial(useAsync, loadAuthMethods)

export type AuthContextType = {
  authMethods: AuthContextQuery["space"]["authMethods"]
  allowSignup: AuthContextQuery["space"]["allow_signup"]
  alwaysPromptForPassword: AuthContextQuery["space"]["always_prompt_for_password"]
  consentDescription: AuthContextQuery["space"]["consent_description"]
  inviteToken: AuthContextQueryVariables["inviteToken"]
  userPropertyDefaults: UserPropertyDefaults | null
  authIntent: AuthIntent | null
  createMembership?: boolean
}

// TODO: convert to util for generating route loaders and hook loaders.
type ApolloAuthContextQueryResult =
  | QueryResult<AuthContextQuery, AuthContextQueryVariables>
  | ApolloQueryResult<AuthContextQuery>

type ExtraAuthContext = Pick<AuthContextType, "inviteToken" | "authIntent">

// Process the query value.
const loaderValue = (
  query: ApolloAuthContextQueryResult,
  extraAuthContext: ExtraAuthContext
): AuthContextType => {
  return {
    authMethods: query.data?.space.authMethods || [],
    allowSignup: query.data?.space.allow_signup || false,
    alwaysPromptForPassword:
      query.data?.space.always_prompt_for_password || false,
    consentDescription: query.data?.space.consent_description || "",
    // TODO: get this from invite token?
    userPropertyDefaults: null,
    ...extraAuthContext
  }
}

// Like React.useEffect.
const loaderEffect = (query: ApolloAuthContextQueryResult): void => {
  // Automatically set the user's preferred method to "password" if the space
  // chooses to always prompt the user for a password.
  // This essentially ensures the user bypasses the
  if (
    query.data &&
    query.data?.space.always_prompt_for_password &&
    !hasPreferredAuthMethod()
  ) {
    setPreferredAuthMethod("password")
  }
}

export const loadAuthContext = async (
  variables: AuthContextQueryVariables
): Promise<AuthContextType> => {
  const client = getClient()
  const result = await client.query<
    AuthContextQuery,
    AuthContextQueryVariables
  >({
    query: AuthContextDocument,
    variables
  })

  const extraAuthContext = {
    inviteToken: variables.inviteToken,
    authIntent: getAuthIntent()
  }

  loaderEffect(result)
  return loaderValue(result, extraAuthContext)
}

// Electing to use the useAuthContextQuery as react-use's useAsync hook
// doesn't have a way to set an initial value, which can result in a brief UI "hiccup"
// while we have to wait for the hook to set the cached (if cached) query result in state.
export const useAuthContextValue = (variables: AuthContextQueryVariables) => {
  const query = useAuthContextQuery({ variables })

  useEffect(() => {
    loaderEffect(query)
  }, [query])

  return useMemo(() => {
    const extraAuthContext = {
      inviteToken: variables.inviteToken,
      authIntent: getAuthIntent()
    }

    return loaderValue(query, extraAuthContext)
  }, [query, variables.inviteToken])
}

const loader = ({
  request
}: LoaderFunctionArgs): ReturnType<typeof loadAuthContext> => {
  const { pathname } = new URL(request.url)

  if (!hasAuthenticationToken()) {
    // Redirect unauthenticated users to sign-in.
    if (/^\/auth\/?$/.test(pathname))
      throw redirect(getAuthRoute("/auth/sign-in"))
  }

  return loadAuthContext(getInviteTokenParam(request.url))
}

export default loader
