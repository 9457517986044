import { Box } from "@chakra-ui/react"

export const getIsExternalUrl = (href = "") => {
  return href.startsWith("http") || href.startsWith("mailto")
}

export const isFirefox = (userAgent = null) => {
  const windowObj = typeof window === "undefined" ? null : window
  const agent = userAgent || windowObj ? windowObj.navigator.userAgent : null

  if (!agent) return null

  return agent && /Firefox/.test(agent)
}

export const PLAN_INTERVALS = {
  ANNUAL: {
    id: 10,
    label: "Annually",
    iteration: "year",
    iteration_plural: "years"
  },
  MONTHLY: {
    id: 5,
    label: "Monthly",
    iteration: "month",
    iteration_plural: "months"
  }
}

const getDiscountedAmount = (amount = 0, planInterval) => {
  return planInterval.id === PLAN_INTERVALS.ANNUAL.id
    ? Math.round(amount * 0.9)
    : amount
}

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD"
})

export const getAmountLabel = (
  originalAmount = 0,
  planInterval,
  discountedAmount = 0
) => {
  const amount = discountedAmount || originalAmount
  let amountLabel = discountedAmount
    ? amount
    : getDiscountedAmount(amount, planInterval)
  // let amountLabel = getDiscountedAmount(amount, planInterval)
  // amountLabel = amountLabel.toLocaleString("en")
  // return `$${amountLabel}`

  const asCurrency = currencyFormatter.format(amountLabel.toFixed(2))

  const [dollars, cents] = asCurrency.split(".")

  if (cents === "00") return dollars

  return (
    <>
      <Box as="span">{dollars}</Box>
      <Box as="span" fontSize="0.4em">
        .{cents}
      </Box>
    </>
  )
}
