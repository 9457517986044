type NumberFormatOptions = Partial<
  ReturnType<Intl.NumberFormat["resolvedOptions"]>
> & { currency: string }

class CurrencyFormat extends Intl.NumberFormat {
  currencyLabel: string
  locale: string
  options: NumberFormatOptions

  constructor(locale: string, options: NumberFormatOptions) {
    options.style = "currency"
    super(locale, options)
    this.currencyLabel = options.currency.toUpperCase()
    this.locale = locale
    this.options = options
  }

  // Ensure we have a value that we can format, otherwise return the provided value.
  // Stringifying the value, which may not be the correct approach.
  processValue(
    value: string | number | bigint,
    callback: (value: number) => string
  ) {
    if (isNaN(parseInt(value + ""))) {
      return value + ""
    } else {
      return callback(Number(value))
    }
  }

  // Format currency, stripping out .00 decimal figures for clearner format
  // when value is integer.
  cleanFormat(value: number | bigint) {
    return this.processValue(value, (value) =>
      value % 1 == 0
        ? new CurrencyFormat(this.locale, {
            ...this.options,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }).format(value)
        : super.format(value)
    )
  }

  get customFormat() {
    const format: Intl.NumberFormat["format"] = (value) => {
      return this.processValue(value, (value) => {
        let formattedValue = this.cleanFormat(value)

        // Handle some specific cases related to the dollar currencies.
        if (formattedValue.indexOf("$") > -1 && this.currencyLabel != "USD") {
          // To avoid redundancy, only append currency code for dollar currencies that are not USD.
          formattedValue = `${formattedValue} ${this.currencyLabel}`
        }

        return formattedValue
      })
    }

    return format
  }

  get customFormatRange() {
    const formatRange: Intl.NumberFormat["formatRange"] = (
      startValue,
      endValue
    ) => {
      return this.processValue(startValue, (startValue) => {
        return this.processValue(endValue, (endValue) => {
          return `${this.cleanFormat(startValue)}-${this.customFormat(
            endValue
          )}`
        })
      })
    }

    return formatRange
  }
}

export default CurrencyFormat
