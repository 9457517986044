import Card from "@pathwright/ui/src/components/card/Card"
import useCohortPermissions from "@pathwright/web/src/modules/cohort/useCohortPermissions"
import GroupAccessCard from "group/components/manage/access/GroupAccessCard"
import GroupArchiveCard from "group/components/manage/archive/GroupArchiveCard"
import GroupCommerceCard from "group/components/manage/commerce/GroupCommerceCard"
import GroupDetailsCard from "group/components/manage/details/GroupDetailsCard"
import GroupFeaturesCard from "group/components/manage/features/GroupFeaturesCard"
import { createCardRoutes } from "lib/core/routing/card-routing"
import { getDefaultLaunchedFromHome } from "lib/core/routing/utils"
import { useEffect } from "react"
import { cohortFeaturesCardRoutes } from "./features-routes"

// redirects user to /home/ if user does not have permissions to manage cohort
const PermissionsRedirectContainer = (props) => {
  const { hasEditorLevelAccess } = useCohortPermissions({
    cohortId: parseInt(props.params.group_id)
  })

  useEffect(() => {
    if (hasEditorLevelAccess === false) {
      App.getStore("navigation").action.goHome()
    }
  }, [hasEditorLevelAccess])

  // HACK: rendering Card because null causes an error in CardStack
  // TODO: fix error
  if (!hasEditorLevelAccess) return <Card card={props.card} />
  return props.children
}

export const groupCardRoutes = {
  path: ":resource_id(\\d+)/:group_id(\\d+)/",
  component: ({ match, card }) => (
    <PermissionsRedirectContainer {...{ params: match.params, card }}>
      <GroupDetailsCard
        card={card}
        resourceId={parseInt(match.params.resource_id)}
      />
    </PermissionsRedirectContainer>
  ),
  middleware: ({ match }) => {
    window.App.getStore("groupAdmin")
      .action.selectGroup(match.params.resource_id, match.params.group_id)
      .promise.then((group) => {
        if (group.parent_offering_id) {
          // Group is generated for Collection so it's treated as a pass-through group.
          // Any access settings should be configured from Collection Group, so we just
          // hide and redirect from this Group's settings.
          window.App.navigate(group.url)
        }
      })
  },
  getDefaultLaunchedFrom: getDefaultLaunchedFromHome,
  childCards: {
    features: {
      component: GroupFeaturesCard
    },
    access: {
      component: GroupAccessCard
    },
    commerce: {
      component: GroupCommerceCard
    },
    archive: {
      component: GroupArchiveCard
    },
    ...cohortFeaturesCardRoutes
  }
}

export default createCardRoutes(
  "/manage/group/",
  { group: groupCardRoutes },
  require("home/components/HomeOrLibraryBrowse").default
)
