// Not working: Cannot read properties of undefined (reading 'source')
// import { ThemeFragmentDoc } from "../../api/generated"
// export default ThemeFragmentDoc

import gql from "graphql-tag"

export default gql`
  fragment Theme on Theme {
    id
    name
    theme_type
    background_image
    background_overlay
    background_type
    heading_font
    primary_color
    typekit_id
    custom_css
    processed_custom_css
    color_palette {
      _50
      _100
      _200
      _300
      _400
      _500
      _600
      _700
      _800
      _900
    }
  }
`
