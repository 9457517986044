import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import gql from "graphql-tag"
import get from "lodash/get"

const SCHOOL_SSO_LINK = gql`
  query SchoolSSOLink($schoolId: Int!, $pathname: String!) {
    context(school_id: $schoolId) {
      school {
        id
        url(pathname: $pathname)
        sso_link(pathname: $pathname)
      }
    }
  }
`

const useSchoolSSOLink = ({ schoolId, pathname }) => {
  const schoolSSOLinkQuery = useQuery(SCHOOL_SSO_LINK, {
    variables: {
      schoolId,
      pathname
    }
  })

  const ssoLink = get(schoolSSOLinkQuery, "data.context.school.sso_link", "#")
  const url = get(schoolSSOLinkQuery, "data.context.school.url", "#")

  // if user is not signed in, the ssoLink will be null
  return ssoLink || url
}

export default useSchoolSSOLink
