import partial from "lodash/partial"
import { redirect } from "react-router-dom"
import { useAsyncFn } from "react-use"
import { getClient } from "../../../../api/client"
import {
  SignOutDocument,
  SignOutMutation,
  SignOutMutationVariables
} from "../../../../api/generated"
import { clearAuthenticationToken } from "../../../../lib/utils/auth-token"

const signOut = async (): Promise<void> => {
  const client = getClient()
  await client.mutate<SignOutMutation, SignOutMutationVariables>({
    mutation: SignOutDocument
  })

  await clearAuthenticationToken()
}

const signOutAction = async (): Promise<Response> => {
  await signOut()
  return redirect("/")
}

export const useSignOut = partial(useAsyncFn<typeof signOut>, signOut)

export default signOutAction
