import { withTranslate } from "@pathwright/ui/src/components/"
import AvatarStack from "@pathwright/ui/src/components/avatar/AvatarStack"
import Button from "@pathwright/ui/src/components/button/Button"
import CountIndicator from "@pathwright/ui/src/components/indicator/CountIndicator"
import Link from "@pathwright/ui/src/components/link/Link"
import Screensize from "@pathwright/ui/src/components/ui/Screensize"
import Text from "@pathwright/ui/src/components/ui/Text"
import styled, { css } from "styled-components"
import { ShareDumb } from "../../../share/Share"
import MentorGroupControls from "../../members/list/MentorGroupControls"
import MentorGroupMembersCard from "../../members/list/MentorGroupMembersCard"
import { getToGroup } from "../../utils"

const Row = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;
  padding: 10px;
  padding-left: 20px;
  margin-bottom: 1px;
  background-color: white;
  cursor: pointer;
  text-decoration: none;
  ${(p) =>
    p.condensed &&
    css`
      align-items: stretch;
      flex-direction: column;
    `};

  > .Link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`

const Info = styled.div`
  flex-grow: 1;
  ${(p) =>
    p.condensed &&
    css`
      padding-bottom: 10px;
      padding-right: 30px;
    `};
`

const Preview = styled.div`
  width: 150;
  padding-right: 10px;
  text-align: right;
  ${(p) =>
    p.condensed &&
    css`
      text-align: left;
      margin-left: -2px;
    `};
`

const MemberBadge = styled.div`
  padding: 2px;
  height: 16px;
  min-width: 16px;
  border-radius: 8px;
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
`

const Controls = styled.div`
  html.no-touch ${Row}:not(:hover) & {
    opacity: 0;
  }
  ${(p) =>
    p.condensed &&
    css`
      z-index: 100;
      position: absolute;
      top: 5px;
      right: 5px;
      opacity: 1 !important;
    `};
`

const MentorGroupListItem = ({ group, condensed, t }) => {
  return (
    <Screensize.Consumer>
      {(screensize) => (
        <Row condensed={screensize === "sm" || condensed}>
          <Link
            to={{
              pathname: getToGroup(group.id),
              card: {
                component: MentorGroupMembersCard,
                props: {
                  groupId: group.id
                }
              }
            }}
          />
          <Info condensed={screensize === "sm" || condensed}>
            <Text.H5>{group.name}</Text.H5>
          </Info>
          <Preview condensed={screensize === "sm" || condensed}>
            {group.memberships.length > 0 ? (
              <Button
                to={{
                  pathname: getToGroup(group.id),
                  card: {
                    component: MentorGroupMembersCard,
                    props: {
                      groupId: group.id
                    }
                  }
                }}
                styleType="secondary"
                style={{ padding: 5 }}
              >
                <AvatarStack
                  users={group.memberships.map((m) => m.user)}
                  size="16px"
                  itemOffset="0px"
                />
                {group.memberships.total > 5 && (
                  <MemberBadge>+{group.memberships.total - 5}</MemberBadge>
                )}
              </Button>
            ) : group.permissions.can_add_member ? (
              <ShareDumb
                context={{ type: "group", typeId: group.id }}
                displayType="modal"
              >
                {({ renderTrigger }) =>
                  renderTrigger((props) => (
                    <Button
                      {...props}
                      onClick={props.onToggle}
                      styleType="secondary"
                      size="small"
                    >
                      {t("mentor_group.add_members")}
                    </Button>
                  ))
                }
              </ShareDumb>
            ) : null}
          </Preview>
          <Controls condensed={screensize === "sm" || condensed}>
            <MentorGroupControls
              mentorGroup={group}
              // NOTE: currently don't have groupSubscription data in state
              // groupSubscription={?}
            />
            {group.seats_available < 0 ? <CountIndicator /> : null}
          </Controls>
        </Row>
      )}
    </Screensize.Consumer>
  )
}

MentorGroupListItem.displayName = "MentorGroupListItem"

export default withTranslate(MentorGroupListItem)
