import { graphql } from "@apollo/client/react/hoc"
import compose from "lodash/flowRight"
import get from "lodash/get"
import PropTypes from "prop-types"
import { PathwrightContext } from "../../pathwright/PathwrightContext"
import { withContextAsProp } from "../../utils/component"
import SCHOOL_QUERY from "./school-query"

const withSchoolQuery = compose(
  withContextAsProp(PathwrightContext, "schoolId", "school.id"),
  graphql(SCHOOL_QUERY, {
    options: ({ id, schoolId }) => ({
      variables: {
        id: id || schoolId
      }
    }),
    props: ({ data }) => ({
      error: data.error,
      loading: data.loading,
      school: get(data, "context.school")
    })
  })
)

const SchoolQuery = withSchoolQuery(({ error, loading, school, children }) => {
  return children({ error, loading, school }) || null
})

SchoolQuery.propTypes = {
  id: PropTypes.number,
  schoolId: PropTypes.number
}

export default SchoolQuery
