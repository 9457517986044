import { useCallback, useMemo } from "react"
import { usePathwrightContext } from "../pathwright/PathwrightContext"
import {
  canViewHomeDesignTab,
  canViewHomePeople,
  canViewHomeTeachTab
} from "../user/permissions"
import {
  EntriesType,
  GetEntriesOptionsType,
  NestedPathOf,
  getEntries,
  getNextEntries,
  getStoredActiveEntries,
  getStoredActiveEntriesHook
} from "./getStoredActiveEntries"

const baseEntries: EntriesType = {
  paths: {
    entries: {
      learn: {},
      teach: {},
      design: {}
    }
  },
  people: {
    entries: {
      groups: {},
      active: {}
    }
  }
}

// List of paths the user has permissions to view. Order matters.
const getPermissions = (pwContext: any): NestedPathOf<typeof baseEntries>[] => {
  let paths: NestedPathOf<typeof baseEntries>[] = ["paths.learn"]
  if (canViewHomeTeachTab(pwContext)) paths.push("paths.teach")
  if (canViewHomeDesignTab(pwContext)) paths.push("paths.design")

  let people: NestedPathOf<typeof baseEntries>[] = []
  if (canViewHomePeople(pwContext))
    people.push("people.groups", "people.active")

  const permissions = [...paths, ...people]

  return permissions
}

const usePermissions = (): NestedPathOf<typeof baseEntries>[] => {
  const pwContext = usePathwrightContext()
  return useMemo(() => getPermissions(pwContext), [pwContext])
}

// Get the default Home path for the user, which will be the path with the greatest perms.
// Order matters.
const getDefaultPermission = (
  permissions: NestedPathOf<typeof baseEntries>[]
) => permissions.filter((perm) => perm.startsWith("paths")).slice(-1)[0]

const getPathFromEntries = (
  entryPath: NestedPathOf<typeof baseEntries> | null,
  entries: EntriesType,
  options?: GetEntriesOptionsType
) => {
  const activeEntries = getEntries<typeof baseEntries>(
    entryPath,
    entries,
    options
  )

  let path = ["/home"]
  path.push(
    ...activeEntries
      .map((entry) => (entry.key === "paths" ? "" : entry.key))
      .filter(Boolean)
  )

  return path.join("/")
}

export const getHomeActivePath = (pwContext: any) => {
  const permissions = getPermissions(pwContext)
  const defaultEntries = getNextEntries<typeof baseEntries>(
    getDefaultPermission(permissions),
    baseEntries
  )

  const getHomeActiveEntries = getStoredActiveEntries<typeof baseEntries>({
    key: "home",
    entries: defaultEntries
  })
  const entries = getHomeActiveEntries(permissions)
  return getPathFromEntries(null, entries)
}

const useHomeActiveEntries = (entryPath?: NestedPathOf<typeof baseEntries>) => {
  const permissions = usePermissions()

  const defaultEntries = useMemo(
    () =>
      getNextEntries<typeof baseEntries>(
        getDefaultPermission(permissions),
        baseEntries
      ),
    [permissions]
  )

  const useStoredActiveEntries = getStoredActiveEntriesHook<typeof baseEntries>(
    {
      key: "home",
      entries: defaultEntries
    }
  )

  const [entries, onChangeEntries] = useStoredActiveEntries({
    path: entryPath,
    permissions: permissions
  })

  const path = useMemo(() => getPathFromEntries(null, entries), [entries])

  const getPath = useCallback(
    (entryPath: NestedPathOf<typeof baseEntries> | null) =>
      getPathFromEntries(entryPath, entries, { deep: true }),
    [entries]
  )

  return {
    path,
    getPath,
    entries,
    onChangeEntries
  }
}

export default useHomeActiveEntries
