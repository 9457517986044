import gql from "graphql-tag"
import SCHOOL_FRAGMENT from "../../school/graphql/school-fragment"

export default gql`
  query TotalMembersWithFilters(
    $search: String
    $role_filter: [MembershipRoleFilter]
    $archived_date_filter: [DateFilter]
    $daysInactive: Int
    $hasAccess: Boolean
  ) {
    school {
      ...School
      members(
        search: $search
        role_filter: $role_filter
        archived_date_filter: $archived_date_filter
        first: 1
        daysInactive: $daysInactive
        hasAccess: $hasAccess
      ) {
        total
      }
    }
  }
  ${SCHOOL_FRAGMENT}
`
