import gql from "graphql-tag"

export default gql`
  fragment Resource on Resource {
    id
    name
    slug
    created
    resource_type
    visibility
    description
    short_description
    background_overlay
    background_image
    image_raw
    has_certificate
    discussion_enabled
    lowest_public_offering_price
    highest_public_offering_price
    licensed_from_resource_id
    licensed_from_school_id
    created_by_id
    image(width: 500, height: 375, fit: crop)
  }
`
