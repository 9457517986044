import { FormEvent, useEffect } from "react"
import SignInAuthRequestConfirmView, {
  SignInAuthRequestConfirmViewProps
} from "./SignInAuthRequestConfirmView"
import { useConfirmAuthRequest } from "./api/action"

interface SignInAuthRequestConfirmViewContainerProps
  extends Pick<SignInAuthRequestConfirmViewProps, "authRequestId"> {
  onSuccess?: () => void
}

const SignInAuthRequestConfirmViewContainer = ({
  authRequestId,
  onSuccess
}: SignInAuthRequestConfirmViewContainerProps) => {
  const [state, action] = useConfirmAuthRequest()

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const formData = new FormData(e.currentTarget)
    await action(formData)
  }

  useEffect(() => {
    if (state.value?.success) onSuccess?.()
  }, [state.value])

  return (
    <SignInAuthRequestConfirmView
      authRequestId={authRequestId}
      loading={state.loading}
      success={!!state.value}
      error={state.error?.message}
      onSubmit={handleSubmit}
    />
  )
}

export default SignInAuthRequestConfirmViewContainer
