import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import gql from "graphql-tag"
import PropTypes from "prop-types"
import { usePathwrightContext } from "../../../pathwright/PathwrightContext"
import useUpgradePrompt, {
  UPGRADE_PROMPT_KEYS,
  UPGRADE_PROMPT_TYPES
} from "../../../subscription/manage/useUpgradePrompt"
import { canAdministrate } from "../../../user/permissions"

const MENTOR_GROUP_STATS_QUERY = gql`
  query MentorGroupsStatsQuery {
    school {
      id
      mentor_group_stats {
        community
        personal
      }
    }
  }
`

const UpgradeMentorGroupsGate = ({ children }) => {
  // only show loading circle if querying for the resource on mount
  const { t } = useTranslate()
  const pwContext = usePathwrightContext()

  const mentorGroupsStatsQuery = useQuery(MENTOR_GROUP_STATS_QUERY, {
    fetchPolicy: "cache-and-network"
  })

  let total = 0
  if (mentorGroupsStatsQuery.data) {
    const { community, personal } =
      mentorGroupsStatsQuery.data.school.mentor_group_stats
    total = community + personal
  }

  const { prompt, totalIncluded } = useUpgradePrompt({
    key: UPGRADE_PROMPT_KEYS.MENTOR_GROUPS,
    totalUsed: total
  })

  if (!mentorGroupsStatsQuery.data || prompt === null) {
    return <LoadingCircle center />
  }

  switch (prompt) {
    case UPGRADE_PROMPT_TYPES.ACTIVATE:
      if (canAdministrate(pwContext))
        return (
          <BlankSlate
            icon="rocket"
            heading={t("Upgrade to Groups")}
            body={t("Choose a plan to support more Groups.")}
            primaryAction={{
              label: t("View Plans"),
              to: "/pricing/plan/"
            }}
            secondaryAction={{
              label: t("Learn more..."),
              href: "#"
            }}
            stack
          />
        )
    case UPGRADE_PROMPT_TYPES.UPGRADE:
      if (canAdministrate(pwContext))
        return (
          <BlankSlate
            icon="rocket"
            heading={t("Upgrade Groups")}
            body={
              <>
                {t("Your plan includes {{ count, number }} Group", {
                  defaultValue_plural:
                    "Your plan includes {{ count, number }} Groups",
                  count: totalIncluded
                })}
                .
                <br />
                <br />
                {t(
                  "To create more Groups with their own mentors, please select a plan."
                )}
              </>
            }
            primaryAction={{
              label: t("View plans"),
              to: "/pricing/plan/"
            }}
            secondaryAction={{
              label: t("Learn more..."),
              href: "#"
            }}
            stack
          />
        )

      // lowly non-admin cannot upgrade plan
      return (
        <BlankSlate
          icon="rocket"
          heading={t("Upgrade Groups")}
          body={t(
            "To create more Groups, please contact an account Administrator to upgrade your plan."
          )}
        />
      )
    case UPGRADE_PROMPT_TYPES.NONE:
      return children || null
  }

  // should not reach this point
  return children || null
}

UpgradeMentorGroupsGate.displayName = "UpgradeMentorGroupsGate"

UpgradeMentorGroupsGate.propTypes = {
  children: PropTypes.node
}

export default UpgradeMentorGroupsGate
