import PropTypes from "prop-types"
import {
  ENROLLMENT_ROLE_EDITOR,
  ENROLLMENT_ROLE_FACILITATOR,
  ENROLLMENT_ROLE_OBSERVER,
  ENROLLMENT_ROLE_STUDENT,
  ENROLLMENT_ROLE_TEACHER
} from "./constants"

export const RegistrationRoleType = PropTypes.oneOf([
  ENROLLMENT_ROLE_OBSERVER,
  ENROLLMENT_ROLE_STUDENT,
  ENROLLMENT_ROLE_FACILITATOR,
  ENROLLMENT_ROLE_TEACHER,
  ENROLLMENT_ROLE_EDITOR
])
