import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import { useMemo } from "react"
import { flattenEdges, usePaginator } from "../utils/apollo"
import { convertObjectKeysToSnakeCase } from "../utils/convertObjectKeys"
import { NOTES_QUERY } from "./graphql"

const useNotes = ({ contextInput, visibility }) => {
  const notesQuery = useQuery(NOTES_QUERY, {
    variables: {
      context: convertObjectKeysToSnakeCase(contextInput),
      visibility
    }
  })

  const notes = useMemo(() => {
    if (notesQuery.data) {
      return flattenEdges(notesQuery.data.notes)
    }
  }, [notesQuery.data])

  const { loadMore, hasMore, loadingMore } = usePaginator({
    data: notesQuery, // hacky
    path: "notes"
  })

  return {
    notes,
    loadMore,
    hasMore,
    loadingMore,
    query: notesQuery
  }
}

export default useNotes
