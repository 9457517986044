// Attempt to determine the meeting software used from the link
export const getMeetingTypeLabel = (
  link: string | undefined
): string | undefined => {
  let type = ""

  if (link) {
    if (link.includes("zoom.us")) {
      // Sample: https://zoom.us/j/4569429554?pwd=YjdlUW9yMXBzbUtTNjZSY0VXZjlBQT09
      type = "Zoom"
    } else if (link.includes("gotomeeting.com")) {
      // Sample: https://global.gotomeeting.com/join/167958853
      type = "GoToMeeting"
    } else if (link.includes("meet.google.com")) {
      // Sample: https://meet.google.com/mgd-ewyq-qjk
      type = "Google Meet"
    } else if (link.includes("join.me")) {
      // Sample: https://secure.join.me/817-407-335
      type = "join.me"
    } else if (link.includes("webex.com")) {
      // Sample: https://meetingsamer26.webex.com/join/pr1267060330
      type = "Cisco WebEx"
    }
  }

  if (!type) return

  return `Hosted by ${type}`
}
