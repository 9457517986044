import { jwtDecode } from "jwt-decode"

export const getDecodedAuthToken = () => {
  const authToken = localStorage.getItem("auth_token")
  if (authToken) {
    // Potentially the auth token is not in the correct format,
    // most likely due to mocked data.
    try {
      return jwtDecode(authToken)
    } catch (error) {
      return {}
    }
  }
  return null
}

export const isUserProxied = () => {
  const decodedAuthToken = getDecodedAuthToken()
  return decodedAuthToken ? Boolean(decodedAuthToken.proxier) : false
}
