import gql from "graphql-tag"
import SCHOOL_FRAGMENT from "../../school/graphql/school-fragment"
import MEMBERSHIP_FRAGMENT from "./membership-fragment"

export default gql`
  query Members(
    $search: String
    $role_filter: [MembershipRoleFilter]
    $archived_date_filter: [DateFilter]
    $access_revoked_date_filter: [DateFilter]
    $cursor: String
    $daysInactive: Int
    $hasAccess: Boolean
  ) {
    school {
      ...School
      members(
        search: $search
        role_filter: $role_filter
        archived_date_filter: $archived_date_filter
        access_revoked_date_filter: $access_revoked_date_filter
        first: 10
        after: $cursor
        daysInactive: $daysInactive
        hasAccess: $hasAccess
      ) {
        total
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            ...Membership
          }
        }
      }
    }
  }
  ${SCHOOL_FRAGMENT}
  ${MEMBERSHIP_FRAGMENT}
`
