import useEventListener from "@pathwright/ui/src/components/hooks/useEventListener"
import { useEffect, useLayoutEffect, useState } from "react"
import { useQueryParam } from "use-query-params"
import { useReviewContext } from "../state/ReviewContextProvider"
import useReviewStack from "../state/useReviewStack"

const useKeyboardShortcuts = () => {
  const [elementFocused, setElementFocused] = useState(false)
  const [gradeWasAutofocused, setGradeWasAutofocused] = useState(false)
  const {
    actions: { previous, next }
  } = useReviewStack()
  const {
    actions: { clearSelection }
  } = useReviewContext()
  const [selectedPanel] = useQueryParam("ui_panel")

  // Handle focusing the grade input
  useLayoutEffect(() => {
    if (selectedPanel === "review" && gradeWasAutofocused && !elementFocused) {
      const gradeInput = document.querySelector(
        ".ScoreFieldRow > input[name='userpoints_earned'"
      )

      if (!gradeInput) return

      setElementFocused(true)
      gradeInput.focus()
    }
  }, [selectedPanel, gradeWasAutofocused])

  // Reset grade focused state when changing element focus (ex: next/previous)
  useEffect(() => {
    if (!elementFocused) setGradeWasAutofocused(false)
  }, [elementFocused])

  const handleKeyPress = (e) => {
    // Ensure no element is currently focused.
    if (!elementFocused) {
      switch (e.key) {
        case "ArrowLeft":
          return previous && previous()
        case "ArrowRight":
          return next && next()
        case "Escape":
          return clearSelection()
        case "Tab":
          if (selectedPanel === "discuss") {
            // TODO: possibly navigate to discussion input, thoug likely will not
            // do this; bad UX.
          }

          // Set flag so that we can focus the grade input on the next render
          if (selectedPanel === "review" && !gradeWasAutofocused) {
            e.preventDefault()
            setGradeWasAutofocused(true)
          }
          break
      }
    }
  }
  // TODO: make sure these are only attached once.
  useEventListener("focusin", () => setElementFocused(true), [])
  useEventListener("focusout", () => setElementFocused(false), [])
  useEventListener("keydown", handleKeyPress, [
    previous,
    next,
    clearSelection,
    elementFocused
  ])
}

export default useKeyboardShortcuts
