import classnames from "classnames"
import PropTypes from "prop-types"
import { forwardRef } from "react"
import ErrorBoundary from "../error/ErrorBoundary"
import { useOverlayTriggerContext } from "../overlay/OverlayTrigger"
import "./DropdownMenu.css"

const DropdownMenu = forwardRef(
  ({ children, className, showDivot, closeOnClick, ...rest }, ref) => {
    const overlayContext = useOverlayTriggerContext()
    const hideOverlay = overlayContext ? overlayContext.hideOverlay : null

    return (
      <ErrorBoundary>
        <ul
          ref={ref}
          className={classnames(
            "Dropdown__Menu",
            {
              Dropdown__Menu__Divot: showDivot
            },
            className
          )}
          onClick={closeOnClick ? hideOverlay : undefined}
          {...rest}
        >
          {children}
        </ul>
      </ErrorBoundary>
    )
  }
)

DropdownMenu.displayName = "DropdownMenu"

DropdownMenu.propTypes = {
  closeOnClick: PropTypes.bool,
  showDivot: PropTypes.bool
}

export default DropdownMenu
