import gql from "graphql-tag"
import SCHOOL_FRAGMENT from "../../school/graphql/school-fragment"
import SUBSCRIPTION_FRAGMENT from "../../subscription/graphql/subscription-fragment"
import SCHOOL_BILLING_PLAN_FRAGMENT from "./school-billing-plan-fragment"

export default gql`
  fragment SchoolBillingSubscription on SchoolBillingSubscription {
    id
    interval
    charge_type
    parent_subscription_id
    subscription {
      ...Subscription
    }
    plan {
      ...SchoolBillingPlan
    }
    school {
      ...School
    }
  }
  ${SUBSCRIPTION_FRAGMENT}
  ${SCHOOL_BILLING_PLAN_FRAGMENT}
  ${SCHOOL_FRAGMENT}
`
