export const loadCategories = function ({ match }) {
  window.App.getStore("categoryAdmin").action.loadCategories()
  // aka onLeave childCard route
  if (!match.params.category_id) {
    return window.App.getStore("categoryAdmin").action.unselectCategory()
  }
}

export const selectCategory = function ({ match }) {
  let { category_id } = match.params
  category_id = category_id !== "new" ? category_id : null
  return window.App.getStore("categoryAdmin").action.selectCategory(category_id)
}
