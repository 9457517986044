import gql from "graphql-tag"
import COMPLETION_FRAGMENT from "../../completion/graphql/completion-fragment"
import REGISTRATION_FRAGMENT from "../../registration/graphql/registration-fragment"
import RESOURCE_FRAGMENT from "../../resource/graphql/resource-fragment"

export default gql`
  fragment PathItem on PathItem {
    id
    order
    verb
    name
    type
    verb
    path_id
    source_id
    parent_id
    parent_source_id
    description
    assignment_type
    content_id
    content_type
    item_content_type_id
    is_required
    is_previewable
    due
    passive_due
    starts
    passive_starts
    has_started
    points
    item_needs_grading
    minimum_passing_score
    grading_type
    time_to_complete
    lock_password
    is_locked
    lock_unlocked_time
    allow_resets
    show_grading_feedback
    completion_comment_required_status
    last_published_dtime
    # only include for learner?
    progress
    context {
      resource_id
      cohort_id
    }
    permissions {
      can_complete
      can_discuss
      can_edit
      can_edit_content
      can_note
      can_view
      can_review
      can_view_content
    }
    completion {
      ...Completion
    }
    linked_resource {
      ...Resource
      curriculum_group {
        id
      }
    }
    linked_cohort {
      id
      # current user's linked registration
      user_registration {
        ...Registration
        path {
          id
        }
      }
    }
    # path's linked registration
    linked_registration {
      ...Registration
      path {
        id
      }
    }
  }
  ${COMPLETION_FRAGMENT}
  ${RESOURCE_FRAGMENT}
  ${REGISTRATION_FRAGMENT}
`
