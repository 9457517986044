import PropTypes from "prop-types"
import React, { Component } from "react"
import styled from "styled-components"
import Alert from "../../alert/Alert"
import BackgroundImage from "../../background/BackgroundImage"
import ThemeBackground from "../../background/ThemeBackground"
import Button from "../../button/Button"
import { MEDIA_TYPES } from "../../filestack/constants"
import { getOptions, pick } from "../../filestack/utils"
import Pathicon from "../../pathicon/Pathicon"
import Text from "../../ui/Text"
import {
  BORDER_GRAY,
  BORDER_WHITE,
  TERTIARY_GRAY,
  TERTIARY_WHITE
} from "../../utils/colors"
import FieldWrapper from "../form-utils/FieldWrapper"

const StyledWrapper = styled.div`
  position: relative;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px dashed
    ${(props) =>
      props.filled
        ? "transparent"
        : props.inverted
        ? BORDER_WHITE
        : BORDER_GRAY};
  &,
  .BackgroundImage__image,
  .BackgroundImage__overlay,
  .BackgroundImage__empty {
    border-radius: 10px;
  }
`

const StyledIcon = styled(Pathicon)`
  margin-bottom: 5px;
  font-size: 21px;
  color: ${(props) => (props.inverted ? TERTIARY_WHITE : TERTIARY_GRAY)};
`

const StyledButton = styled(Button)`
  position: absolute;
  top: 10px;
  right: 10px;
`

class ImagePicker extends Component {
  state = {
    error: null
  }

  handleUpload = async () => {
    try {
      const result = await pick(getOptions(MEDIA_TYPES.IMAGE, { maxFiles: 1 }))
      this.props.onChange(result.filesUploaded[0].url)
    } catch (error) {
      this.setState({ error })
    }
  }

  render() {
    const {
      apiKey,
      value,
      preview,
      onChange,
      overlay,
      inverted,
      headline,
      subline,
      options,
      width,
      height,
      background,
      imageSize,
      imagePosition,
      renderPicker,
      required
    } = this.props

    return (
      <React.Fragment>
        {this.state.error && (
          <Alert
            error={this.state.error}
            onClear={() => this.setState({ error: null })}
          />
        )}
        <FieldWrapper {...this.props}>
          {value ? (
            <StyledWrapper style={{ width, height }} filled>
              {background && <ThemeBackground />}
              <BackgroundImage
                image={preview || value}
                overlay={overlay}
                size={imageSize}
                position={imagePosition}
              />
              {required ? (
                renderPicker({
                  onChange,
                  renderButton: ({ onClick }) => (
                    <StyledButton
                      onClick={onClick}
                      styleType="secondary"
                      size="small"
                      inverted
                    >
                      Replace
                    </StyledButton>
                  )
                })
              ) : (
                <StyledButton
                  onClick={(e) => onChange(null)}
                  styleType="secondary"
                  size="small"
                  inverted
                >
                  Remove
                </StyledButton>
              )}
            </StyledWrapper>
          ) : renderPicker ? (
            renderPicker({
              onChange,
              renderButton: ({ onClick }) => (
                <StyledWrapper
                  onClick={onClick}
                  style={{ width, height }}
                  inverted={inverted}
                >
                  <StyledIcon icon="arrow-up-circle" inverted={inverted} />
                  {headline && (
                    <Text.Body inverted={inverted}>{headline}</Text.Body>
                  )}
                  {subline && (
                    <Text.Meta inverted={inverted}>{subline}</Text.Meta>
                  )}
                </StyledWrapper>
              )
            })
          ) : (
            <StyledWrapper
              onClick={this.handleUpload}
              style={{ width, height }}
              inverted={inverted}
            >
              <StyledIcon icon="arrow-up-circle" inverted={inverted} />
              {headline && (
                <Text.Body inverted={inverted}>{headline}</Text.Body>
              )}
              {subline && <Text.Meta inverted={inverted}>{subline}</Text.Meta>}
            </StyledWrapper>
          )}
        </FieldWrapper>
      </React.Fragment>
    )
  }
}

ImagePicker.displayName = "ImagePicker"

ImagePicker.propTypes = {
  apiKey: PropTypes.string,
  accept: PropTypes.string,
  multiple: PropTypes.bool,
  headline: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  options: PropTypes.object,
  renderPicker: PropTypes.func,
  required: PropTypes.bool
}

ImagePicker.defaultProps = {
  multiple: false,
  width: "100%",
  height: 100,
  headline: "Upload image",
  subline: "",
  renderPicker: null,
  required: false
}

export default ImagePicker
