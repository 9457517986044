// map of pathwright domains to environment
export const PATHWRIGHT_DOMAIN_RES = new Map([
  [/\.(pathwright)\.com/, "production"],
  [/\.(pathwrightstaging)\.com/, "staging"],
  [/\.(pathwrightdev)\.com/, "development"],
  [/\.(pantswright)\.com/, "testing"]
])

// find the matching pathwright domain re entry, replacing the re with the match
export const getPathwrightDomainMatch = (url) => {
  url = url || window.location.hostname
  return [...PATHWRIGHT_DOMAIN_RES.entries()].reduce(
    (result, [re, env]) =>
      result ? result : re.test(url) ? { match: url.match(re), env } : null,
    null
  )
}

// get the environment that matches the window.location.hostname
export const getDomainEnv = (url) => {
  return getPathwrightDomainMatch(url)?.env
}

// get the pathwright domain from the window.location.hostname
export const getPathwrightDomain = (url) => {
  return getPathwrightDomainMatch(url)?.match[1]
}

export const getEnv = (targetEnv) => {
  // get environment based on domain
  let env = getDomainEnv()

  switch (process.env.NODE_ENV) {
    // only allow production environment when set via NODE_ENV
    case "production":
      // and when not overridden by domain
      if (!env) env = "production"
      break
    case "test":
      // Ensure test env is not overridden.
      env = "test"
      break
  }

  // default to development environment
  if (!env) env = "development"

  if (targetEnv) {
    // targetEnv may include env as item in array
    if (Array.isArray(targetEnv)) return targetEnv.includes(env)
    // targetEnv may include env as key in object, or default to _ key
    if (typeof targetEnv === "object")
      return targetEnv.hasOwnProperty(env) ? targetEnv[env] : targetEnv._
    // targetEnv may match env as string
    if (typeof targetEnv === "string") return env === targetEnv
  }

  return env
}

export const getIsLocalHost = () => !getDomainEnv() && !getEnv("production")
export const getIsLocalDev = () => getIsLocalHost() || getEnv("development")
