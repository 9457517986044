import { Media } from "@pathwright/media-utils"
import Fuse from "fuse.js"
import memoize from "lodash/memoize"

export const fuseOptions: Fuse.IFuseOptions<Media> = {
  keys: ["name", "description", "tags"],
  threshold: 0.3,
  shouldSort: false,
  minMatchCharLength: 2
}

const fuseSearch = (
  list: Media[],
  term: string,
  options: Fuse.IFuseOptions<Media>
): Media[] => {
  const initFuse: () => Fuse<Media> = memoize(() => {
    return new Fuse<Media>(list, options)
  })

  const getResults: (
    list: Media[],
    term: string,
    options: Fuse.IFuseOptions<Media>
  ) => Fuse.FuseResult<Media>[] = memoize(() => initFuse().search(term))

  return getResults(list, term, options).map((r) =>
    r.item ? r.item : r
  ) as Media[]
}

export default fuseSearch
