import { i18n } from "@pathwright/ui/src/components/lng/I18nextProvider"

// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
const isExisty = (value) => value !== null && value !== undefined

const isEmpty = (value) => value === ""

const matchRegexp = (values, value, regexp) =>
  !isExisty(value) || isEmpty(value) || regexp.test(value)

const validations = {
  isExisty(values, value) {
    if (!isExisty(value)) {
      return i18n.t("validations.required")
    }
  },

  isRequired(values, value) {
    const valid = isExisty(value) && !isEmpty(value)
    if (!valid) {
      i18n.t("validations.required")
    }
  },

  isUndefined(values, value) {
    if (!value === undefined) {
      return i18n.t("validations.should_be_undefined")
    }
  },

  isEmptyString(values, value) {
    if (!isEmpty(value)) {
      return i18n.t("validations.should_be_empty")
    }
  },

  isEmail(values, value) {
    const valid = matchRegexp(
      values,
      value,
      /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i
    )
    if (!valid) {
      return i18n.t("validations.valid_email")
    }
  },

  isUrl(values, value) {
    const valid = matchRegexp(
      values,
      value,
      /^(https?|s?ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i
    )
    if (!valid) {
      return i18n.t("validations.valid_url")
    }
  },

  isValidDate(values, value) {
    // allow blank values
    if (value === ("" || null || undefined)) {
      return
    }
    const dateValue = new Date(value)
    const valid = moment(dateValue).isValid()
    if (!valid) {
      return i18n.t("validations.valid_date")
    }
  },

  // deprecated - see isValidDate
  isDate(values, value) {
    if (value === "") {
      return
    }
    const dateValue = new Date(value)
    const valid = moment(dateValue).isValid()
    if (!valid) {
      return i18n.t("validations.valid_date")
    }
  },

  isFutureDate(values, value) {
    if (value === "") {
      return
    }
    const dateValue = new Date(value)
    const valid = moment(dateValue).isValid()
    if (!valid) {
      return i18n.t("validations.valid_date")
    }
    const future = moment().isBefore(dateValue)
    if (!future) {
      return i18n.t("validations.future_date")
    }
  },

  isTrue(values, value) {
    const valid = value === true
    if (!valid) {
      return i18n.t("validations.should_be_true")
    }
  },

  isFalse(values, value) {
    const valid = value === false
    if (!valid) {
      return i18n.t("validations.should_be_false")
    }
  },

  isNumeric(values, value) {
    const valid = !isNaN(value)
    // matchRegexp values, value, /^[-+]?(?:\d*[.])?\d+$/
    if (!valid) {
      return i18n.t("validations.use_numbers_only")
    }
  },

  isAlpha(values, value) {
    const valid = matchRegexp(values, value, /^[A-Z ]+$/i)
    if (!valid) {
      return i18n.t("validations.use_letters_only")
    }
  },

  isAlphanumeric(values, value) {
    const valid = matchRegexp(values, value, /^[0-9A-Z]+$/i)
    if (!valid) {
      return i18n.t("validations.letters_and_numbers")
    }
  },

  isUsername(values, value) {
    const valid = matchRegexp(values, value, /^[\w.@+-]+$/i)
    if (!valid) {
      return i18n.t("validations.may_contain")
    }
  },

  isInt(values, value) {
    const valid = matchRegexp(values, value, /^(?:[-+]?(?:0|[1-9]\d*))$/)
    if (!valid) {
      return i18n.t("validations.use_integers")
    }
  },

  isFloat(values, value) {
    const valid = matchRegexp(
      values,
      value,
      /^(?:[-+]?(?:\d+))?(?:\.\d*)?(?:[eE][\+\-]?(?:\d+))?$/
    )
    if (!valid) {
      return ""
    }
  },

  isWords(values, value) {
    const valid = matchRegexp(values, value, /^[A-Z\s]+$/i)
    if (!valid) {
      return i18n.t("validations.use_words")
    }
  },

  isSpecialWords(values, value) {
    const valid = matchRegexp(values, value, /^[A-Z\s\u00C0-\u017F]+$/i)
    if (!valid) {
      return i18n.t("validations.use_words")
    }
  },

  isLength(length) {
    return function (values, value) {
      const valid =
        !isExisty(value) || isEmpty(value) || value.length === length
      if (!valid) {
        return i18n.t("validations.requires_length", {
          length
        })
      }
    }
  },

  equals(eql) {
    return function (values, value) {
      const valid = !isExisty(value) || isEmpty(value) || value === eql
      if (!valid) {
        return i18n.t("validations.should_eql", { eql })
      }
    }
  },

  equalsField(field) {
    return function (values, value) {
      const valid = value === values[field].value
      if (!valid) {
        return i18n.t("validations.doesnt_match")
      }
    }
  },

  maxLength(length) {
    return function (values, value) {
      const valid = !isExisty(value) || value.length <= length
      if (!valid) {
        return i18n.t("validations.max_length", { length })
      }
    }
  },

  minLength(length) {
    return function (values, value) {
      const valid = !isExisty(value) || isEmpty(value) || value.length >= length
      if (!valid) {
        return i18n.t("validations.min_length", { length })
      }
    }
  },

  isLessThanOrEqualTo(number, parser = parseInt) {
    return function (values, value) {
      const valid = parser(value) <= number
      if (!valid) {
        return i18n.t("validations.max_number", { number: parser(number) })
      }
    }
  },

  isGreaterThanOrEqualTo(number, parser = parseInt) {
    return function (values, value) {
      const valid = parser(value) >= number
      if (!valid) {
        return i18n.t("validations.min_number", { number: parser(number) })
      }
    }
  },

  dateIsBefore(date) {
    return function (values, value) {
      const valid = !date || moment(value).isBefore(date)
      if (!valid) {
        return i18n.t("validations.be_before", { date })
      }
    }
  },

  dateIsAfter(date) {
    return function (values, value) {
      const valid = !date || moment(value).isAfter(date)
      if (!valid) {
        return i18n.t("validations.be_after", { date })
      }
    }
  },

  // Validate agaisnt another field with any validator
  // Ex. validate.againstField("points", validate.isLessThanOrEqualTo)
  againstField(field, validator) {
    return function (values, value) {
      try {
        // try running a returned function from the validator
        return validator(values[field].value)(values, value)
      } catch (e) {
        // catch if the validator does not return a function
        return validator(values, values[field].value)
      }
    }
  },

  // Run validator only if shouldValidate returns truthy
  validateIf(shouldValidate, validator) {
    return function (values, value) {
      if (shouldValidate(values, value)) {
        return validator(values, value)
      }
    }
  }
}

validations.isEmailOrUsername = function (values, value) {
  const valid =
    validations.isEmail(value) == null || validations.isUsername(value) == null
  if (!valid) {
    return i18n.t("validations.enter_valid_email")
  }
}

export const findErrorMessage =
  (...validators) =>
  (values, value) =>
    validators.reduce(
      (errorMessage, validator) => errorMessage || validator(values, value),
      null
    )

export default validations
