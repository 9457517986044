import { graphql } from "@apollo/client/react/hoc"
import Alert from "@pathwright/ui/src/components/alert/Alert"
import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import Button from "@pathwright/ui/src/components/button/Button"
import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import Card from "@pathwright/ui/src/components/card/Card"
import CardBlock from "@pathwright/ui/src/components/card/CardBlock"
import ColorPickerToggle from "@pathwright/ui/src/components/form/form-color/ColorPickerToggle"
import TextInput from "@pathwright/ui/src/components/form/form-text-input/TextInput"
import ToggleInput from "@pathwright/ui/src/components/form/form-toggle/ToggleInput"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import {
  cleanHex,
  formatHex,
  objectifyRgba,
  stringifyRgba
} from "@pathwright/ui/src/components/utils/colors"
import compose from "lodash/flowRight"
import get from "lodash/get"
import { useState } from "react"
import { Field, Form, Submit, validators } from "react-connect-form-forked"
import styled from "styled-components"
import MediaQuery from "../../lib/MediaQuery"
import MediaManager from "../../media-manager/MediaManager"
import { PathwrightContext } from "../../pathwright/PathwrightContext"
import ProductCheckboxes, {
  useProducts
} from "../../stripe/product/ProductCheckboxes"
import SchoolSubscriptionPlansCheckboxes, {
  useSchoolSubscriptionPlans
} from "../../subscription/manage/SchoolSubscriptionPlansCheckboxes"
import { withContextAsProp, withStateAsProps } from "../../utils/component"
import Banner from "../display/Banner"
import BANNER_QUERY from "../graphql/banner-query"
import BANNERS_QUERY from "../graphql/banners-query"
import CREATE_BANNER_MUTATION from "../graphql/create-banner-mutation"
import DELETE_BANNER_MUTATION from "../graphql/delete-banner-mutation"
import UPDATE_BANNER_MUTATION from "../graphql/update-banner-mutation"

const LABEL_WIDTH = 150

const StyledBanner = styled(Banner)`
  position: relative;
  min-height: 150px;
`

const BannerContainer = styled.div`
  position: relative;
`

const UploadOverlay = styled.div`
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  width: 100%;

  &:hover {
    opacity: 1;
  }

  button > * {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;

    i {
      margin-bottom: 5px;
    }
  }
`

const StyledColorPickerToggle = styled(ColorPickerToggle)`
  .OverlayTrigger {
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.3);
    border-radius: 3px;
  }
`

const BannerPreview = ({
  background_image_raw,
  background_overlay,
  button_text,
  button_func,
  button_url,
  heading,
  subhead,
  font_color,
  blur,
  btnshow,
  onChange,
  createdBannerId
}) => (
  <MediaQuery
    url={background_image_raw}
    blur={blur ? 500 : null}
    width={1600}
    fit="crop"
  >
    {({ image }) => (
      <BannerContainer>
        <StyledBanner
          background_image={image}
          font_color={cleanHex(font_color)}
          hideBannerAction={!btnshow}
          background_overlay={background_overlay}
          button_text={button_text}
          button_func={button_func}
          button_url={button_url}
          heading={heading}
          subhead={subhead}
        />
        <UploadOverlay>
          <MediaManager
            contextKey={`banner-*`}
            onChooseMedia={(media) => onChange(media.url)}
            renderButton={({ onClick }) => (
              <Button
                onClick={onClick}
                styleType="blank"
                rounded={false}
                stretch={true}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Pathicon
                    icon="arrow-up-circle"
                    style={{ marginBottom: 0, marginRight: 5 }}
                  />
                  <span>Upload background image</span>
                </div>
              </Button>
            )}
          />
        </UploadOverlay>
      </BannerContainer>
    )}
  </MediaQuery>
)

BannerPreview.displayName = "BannerPreview"

const BannerFormCard = ({
  card,
  loading,
  banner,
  bannerNotFound,
  updateBanner,
  createBanner,
  withStateAsProps,
  deleteBanner,
  createdBannerId
}) => {
  const [error, setError] = useState(null)
  const [archiving, setArchiving] = useState(false)
  const { schoolSubscriptionPlans } = useSchoolSubscriptionPlans()
  const { products } = useProducts({ includeInactive: true })

  const hasPlans = schoolSubscriptionPlans && !!schoolSubscriptionPlans.length
  const hasProducts = !!products.length

  const handleSubmit = async (formValue) => {
    try {
      const changes = {
        background_image: formValue.background_image_raw,
        background_type: formValue.blur ? 5 : 1,
        background_overlay: formValue.background_overlay
          ? stringifyRgba(formValue.background_overlay)
          : "",
        heading: formValue.heading,
        subhead: formValue.subhead,
        font_color: cleanHex(formValue.font_color),
        button_text: formValue.btnshow ? formValue.button_text : "",
        button_url: formValue.btnshow ? formValue.button_url : "",
        button_func: "url",
        is_visible: formValue.is_visible,
        hide_for_subscription_plans: formValue.hide_for_subscription_plans,
        show_for_subscription_plans: formValue.show_for_subscription_plans,
        hide_for_products: formValue.hide_for_products,
        show_for_products: formValue.show_for_products
      }

      const result = await (!!banner
        ? updateBanner(changes)
        : createBanner(changes))

      // force query of created banner
      !banner &&
        withStateAsProps({
          createdBannerId: result.data.createBanner.id
        })
    } catch (error) {
      setError(error.message)
    }
  }

  const handleArchive = async () => {
    await deleteBanner()
    card.onClose()
  }

  const initialValue = banner
    ? {
        ...banner,
        blur: banner.background_type === 5 ? true : false,
        background_overlay: objectifyRgba(banner.background_overlay),
        font_color: formatHex(banner.font_color || "fff"),
        btnshow: !!banner.button_text,
        button_url:
          !banner.button_text && banner.button_func === "subscribe"
            ? "/subscribe/"
            : banner.button_url
      }
    : {
        blur: false,
        btnshow: false,
        font_color: "#ffffff",
        is_visible: false
      }

  const cardTitle = loading
    ? ""
    : banner
    ? banner.heading
    : bannerNotFound
    ? "Not Found"
    : "New Banner"

  return (
    <Card card={card} title={cardTitle}>
      {error && <Alert error={error} />}
      {loading && !createdBannerId ? (
        <LoadingCircle />
      ) : archiving ? (
        <BlankSlate
          icons={null}
          heading="Are you sure you want to delete this banner?"
          primaryAction={{
            children: "Yes, delete.",
            dangerous: true,
            onClick: handleArchive
          }}
          secondaryAction={{
            children: "Cancel",
            onClick: () => setArchiving(false)
          }}
        />
      ) : bannerNotFound ? (
        <BlankSlate
          icons={null}
          heading="This banner may have been deleted or does not exist."
          primaryAction={{
            children: "Ok.",
            onClick: card.onClose
          }}
        />
      ) : (
        <Form
          onSubmit={handleSubmit}
          initialValue={initialValue}
          onDirty={() => !!banner && card.setCardDirty(true)}
          onPristine={() => !!banner && card.setCardDirty(false)}
          render={({ onSubmit, ...formState }) => (
            <form onSubmit={onSubmit}>
              <Submit styleType="primary" component={SubmitButton}>
                Save
              </Submit>
              <Field
                name="background_image_raw"
                label="Banner image"
                labelWidth={LABEL_WIDTH}
                render={({ onChange }) => (
                  // <Filestack
                  //   apikey="PKIcKga_QLKv1rkAdPQB"
                  //   options={{
                  //     maxFiles: 1
                  //   }}
                  //   onSuccess={filesUploaded => onChange(filesUploaded[0].url)}
                  //   tag="div"
                  // >
                  <BannerPreview
                    createdBannerId={createdBannerId}
                    onChange={onChange}
                    {...formState.value}
                  />
                  // </Filestack>
                )}
              />
              <CardBlock>
                <Field
                  name="heading"
                  label="Heading"
                  placeholder="Discover something new"
                  validators={[validators.isMaxLength(255)]}
                  labelWidth={LABEL_WIDTH}
                  component={TextInput}
                />
                <Field
                  name="subhead"
                  label="Subhead"
                  placeholder="Take courses anytime, anywhere"
                  validators={[validators.isMaxLength(255)]}
                  labelWidth={LABEL_WIDTH}
                  component={TextInput}
                />
                <Field
                  name="font_color"
                  label="Text color"
                  enableHue
                  enableAlpha={false}
                  format="hex"
                  validators={[validators.isMaxLength(255)]}
                  labelWidth={LABEL_WIDTH}
                  alignment="left"
                  component={StyledColorPickerToggle}
                />
                <Field
                  name="background_overlay"
                  label="Color overlay"
                  enableHue
                  enableSaturation
                  enableAlpha
                  format="rgba"
                  validators={[validators.isMaxLength(255)]}
                  labelWidth={LABEL_WIDTH}
                  alignment="left"
                  component={StyledColorPickerToggle}
                />
                {!!formState.value.background_image_raw && (
                  <Field
                    name="blur"
                    label="Blur image"
                    labelWidth={LABEL_WIDTH}
                    component={ToggleInput}
                  />
                )}
                <Field
                  name="btnshow"
                  label="Show button link"
                  labelWidth={LABEL_WIDTH}
                  component={ToggleInput}
                />
                {formState.value.btnshow && (
                  <Field
                    name="button_text"
                    label="Button text"
                    placeholder="Learn more"
                    labelWidth={LABEL_WIDTH}
                    component={TextInput}
                    validators={[validators.isMaxLength(15)]}
                    required={formState.value.btnshow}
                  />
                )}
                {formState.value.btnshow && (
                  <Field
                    name="button_url"
                    label="Button link"
                    placeholder="https://..."
                    labelWidth={LABEL_WIDTH}
                    component={TextInput}
                    required={formState.value.btnshow}
                  />
                )}

                {!hasPlans && !hasProducts && (
                  <Field
                    name="is_visible"
                    label="Is visible"
                    labelWidth={LABEL_WIDTH}
                    component={ToggleInput}
                  />
                )}
              </CardBlock>

              {(hasPlans || hasProducts) && (
                <CardBlock>
                  <Field
                    name="is_visible"
                    label="Is visible"
                    labelWidth={LABEL_WIDTH}
                    component={ToggleInput}
                  />
                  {formState.value.is_visible && hasPlans && (
                    <Field
                      name="show_for_subscription_plans"
                      label="Show for subscribers"
                      labelWidth={LABEL_WIDTH}
                      tooltip="Optionally only show this banner to subscribers."
                      type="custom"
                      component={SchoolSubscriptionPlansCheckboxes}
                      value={(
                        formState.value.show_for_subscription_plans || []
                      ).map((plan) => plan.id)}
                    />
                  )}

                  {formState.value.is_visible && hasPlans && (
                    <Field
                      name="hide_for_subscription_plans"
                      label="Hide for subscribers"
                      labelWidth={LABEL_WIDTH}
                      tooltip="Optionally only hide this banner for subscribers."
                      type="custom"
                      component={SchoolSubscriptionPlansCheckboxes}
                      value={(
                        formState.value.hide_for_subscription_plans || []
                      ).map((plan) => plan.id)}
                    />
                  )}

                  {formState.value.is_visible && hasProducts && (
                    <Field
                      name="show_for_products"
                      label="Show for products"
                      labelWidth={LABEL_WIDTH}
                      type="custom"
                      component={ProductCheckboxes}
                      selectedProductIds={formState.value.show_for_products}
                      includeInactive
                    />
                  )}

                  {formState.value.is_visible && hasProducts && (
                    <Field
                      name="hide_for_products"
                      label="Hide for products"
                      labelWidth={LABEL_WIDTH}
                      type="custom"
                      component={ProductCheckboxes}
                      selectedProductIds={formState.value.hide_for_products}
                      includeInactive
                    />
                  )}
                </CardBlock>
              )}

              {!!banner && (
                <CardBlock>
                  <Button
                    onClick={() => setArchiving(true)}
                    styleType="card"
                    color="red"
                  >
                    Delete banner
                  </Button>
                </CardBlock>
              )}
            </form>
          )}
        />
      )}
    </Card>
  )
}

BannerFormCard.displayName = "BannerFormCard"

export default compose(
  withContextAsProp(PathwrightContext, "schoolId", "school.id"),
  withStateAsProps({ createdBannerId: null }),
  graphql(BANNER_QUERY, {
    options: ({ id, schoolId, createdBannerId }) => ({
      variables: {
        id: id || createdBannerId,
        school_id: schoolId
      }
    }),
    skip: ({ id, createdBannerId }) => !id && !createdBannerId,
    props: ({ data }) => ({
      banner: get(data, "context.school.banner"),
      loading: data.loading,
      bannerNotFound: !data.loading && !get(data, "context.school.banner")
    })
  }),
  graphql(CREATE_BANNER_MUTATION, {
    props: ({ mutate, ownProps }) => ({
      createBanner: (changes) =>
        mutate({
          variables: changes,
          update: (cache, response) => {
            const queries = [
              {
                query: BANNERS_QUERY,
                variables: {
                  first: 50,
                  school_id: ownProps.schoolId,
                  manage: true
                }
              }
            ]

            if (response.data.createBanner.is_visible) {
              queries.push({
                query: BANNERS_QUERY,
                variables: {
                  first: 50,
                  school_id: ownProps.schoolId,
                  manage: false
                }
              })
            }

            queries.forEach(({ query, variables }) => {
              try {
                const data = cache.readQuery({ query, variables })
                const nextData = {
                  context: {
                    ...data.context,
                    school: {
                      ...data.context.school,
                      banners: {
                        ...data.context.school.banners,
                        edges: [
                          {
                            node: response.data.createBanner,
                            __typename: "BannerEdge"
                          }
                        ].concat(data.context.school.banners.edges)
                      }
                    }
                  }
                }
                cache.writeQuery({ query, variables, data: nextData })
              } catch (error) {
                // Possible that the query is not present in the cache, and that's OK
                console.log(
                  "Error updating cache after CREATE_BANNER_MUTATION",
                  error
                )
              }
            })
          }
        })
    })
  }),
  graphql(UPDATE_BANNER_MUTATION, {
    props: ({ mutate, ownProps }) => ({
      updateBanner: (changes) =>
        mutate({
          variables: {
            id: ownProps.banner.id,
            ...changes
          },
          update: (cache, response) => {
            const query = BANNERS_QUERY
            const variables = {
              first: 50,
              school_id: ownProps.schoolId,
              manage: false
            }

            try {
              const data = cache.readQuery({ query, variables })
              const nextData = {
                context: {
                  ...data.context,
                  school: {
                    ...data.context.school,
                    banners: {
                      ...data.context.school.banners,
                      edges: response.data.updateBanner.is_visible
                        ? data.context.school.banners.edges
                            .concat({
                              node: response.data.updateBanner,
                              __typename: "BannerEdge"
                            })
                            .sort(({ node: bannerA }, { node: bannerB }) => {
                              return bannerA.order - bannerB.order
                            })
                        : data.context.school.banners.edges.filter(
                            (edge) =>
                              edge.node.id !== response.data.updateBanner.id
                          )
                    }
                  }
                }
              }
              cache.writeQuery({ query, variables, data: nextData })
            } catch (error) {
              // Possible that the query is not present in the cache, and that's OK
              console.log(
                "Error updating cache after CREATE_BANNER_MUTATION",
                error
              )
            }
          }
        })
    })
  }),
  graphql(DELETE_BANNER_MUTATION, {
    props: ({ mutate, ownProps }) => ({
      deleteBanner: () =>
        mutate({
          variables: {
            id: ownProps.banner.id
          },
          update: (cache) => {
            const queries = [
              {
                query: BANNERS_QUERY,
                variables: {
                  first: 50,
                  school_id: ownProps.schoolId,
                  manage: true
                }
              },
              {
                query: BANNERS_QUERY,
                variables: {
                  first: 50,
                  school_id: ownProps.schoolId,
                  manage: false
                }
              }
            ]

            queries.forEach(({ query, variables }) => {
              try {
                const data = cache.readQuery({ query, variables })
                const nextData = {
                  context: {
                    ...data.context,
                    school: {
                      ...data.context.school,
                      banners: {
                        ...data.context.school.banners,
                        edges: data.context.school.banners.edges.filter(
                          (edge) => edge.node.id !== ownProps.banner.id
                        )
                      }
                    }
                  }
                }
                cache.writeQuery({ query, variables, data: nextData })
              } catch (error) {
                // Possible that the query is not present in the cache, and that's OK
                console.log(
                  "Error updating cache after DELETE_BANNER_MUTATION",
                  error
                )
              }
            })
          }
        })
    })
  })
)(BannerFormCard)
