import RouterAdapter from "@pathwright/web-new/src/routes/RouterAdapterForLegacy"
import AuthWrapper from "@pathwright/web-new/src/views/auth/components/AuthWrapper"
import SignedInContainer, {
  SignedInContainerProps
} from "@pathwright/web-new/src/views/auth/signed-in/components/SignedInContainer"
import { ConnectedSpaceProvider } from "@pathwright/web-new/src/views/space/api/context"

const RouteElement = (props: SignedInContainerProps) => {
  return (
    <ConnectedSpaceProvider>
      <div className="chakra-ui-reset-css" style={{ width: "100%" }}>
        <AuthWrapper>
          <SignedInContainer size="lg" {...props} />
        </AuthWrapper>
      </div>
    </ConnectedSpaceProvider>
  )
}

// Wrapping with RouterProvider in order for the `useBlocker` hook to work.
const AdaptedSignedInContainer = (props: SignedInContainerProps) => {
  return <RouterAdapter element={<RouteElement {...props} />} />
}

export default AdaptedSignedInContainer
