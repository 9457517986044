// const sampleFile = {
// mediaID: "0g8a7Mqwpa6lL3Knoox1",
// url: "https://cdn.filestackcontent.com/r2VLdacgQzaNByfYtqgA",
// thumb: "https://cdn.filestackcontent.com/r2VLdacgQzaNByfYtqgA",
// name: "Course Image",
// mimetype: "image/jpg",
// filestackHandle: "r2VLdacgQzaNByfYtqgA",
// mediaType: "IMAGE"
// }

const sampleData = {
  files: [
    {
      mediaID: "",
      url: "",
      thumb: "",
      name: "empty",
      mimetype: "",
      filestackHandle: "",
      mediaType: "IMAGE"
    }
  ]
}

export default {
  helpLink:
    "https://help.pathwright.com/create-a-course/adding-content-with-blocks/attachments-block-new",
  type: "Attachments",
  menu: {
    category: "media",
    order: 4
  },
  layouts: {
    attachments: {
      key: "attachments",
      label: "Files",
      data: {
        ...sampleData
      }
    }
  },
  schema: `
    type Attachment {
      id: ID!
      url: String! @media
      name: String!
      filestackHandle: String
      mediaType: MediaType!
      mimetype: String!
      thumb: String @media
      fileSize: Int
      createdDate: Float
      mediaID: String!
      muxVideoID: String
    }

    type Attachments implements Block {
      id: ID!
      files: [Attachment]
    }
  `
}
