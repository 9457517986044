import Avatar from "@pathwright/ui/src/components/avatar/Avatar"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import Text from "@pathwright/ui/src/components/ui/Text"
import { ROLE_LABELS } from "@pathwright/web/src/modules/invitation/constants"
import { observer } from "mobx-react"
import invitationStore from "../store/invitation-store"
import InvitationControls from "./InvitationControls"
import "./InvitationRow.css"

const InvitationRow = ({ invitation }) => {
  const {
    id,
    to_email,
    to_user,
    invitation_role,
    invitation_type,
    invitation_email,
    invitation_accepted_dtime
  } = invitation

  const isResending = id === invitationStore.resending
  const isRevoking = id === invitationStore.revoking
  const isLoading = isResending || isRevoking

  const user = invitation.redeemer || invitation.to_user

  return (
    <li className="InvitationRow">
      <Avatar initials={to_email[0]} user={user} size="36px" />
      <div className="InvitationRow__recipient">
        <Text.H4>{user ? user.full_name : to_email}</Text.H4>
        <Text.Meta>{ROLE_LABELS[(invitation_type, invitation_role)]}</Text.Meta>
      </div>
      <div className="InvitationRow__status">
        {invitation_accepted_dtime ? (
          <Text.H4 className="InvitationRow__status--accepted">
            Accepted
          </Text.H4>
        ) : invitation_email ? (
          <Text.H4
            className={`InvitationRow__status--${invitation_email.delivery_status}`}
          >
            {invitation_email.delivery_status}
          </Text.H4>
        ) : (
          <Text.H4>Sending</Text.H4>
        )}
        {invitation_email ? (
          <Text.Meta>
            {moment(invitation_email.delivery_status_updated).fromNow()}
          </Text.Meta>
        ) : (
          <Text.Meta>{moment().fromNow()}</Text.Meta>
        )}
      </div>
      {!invitation_accepted_dtime && (
        <InvitationControls
          invitation={invitation}
          onResend={() => invitationStore.action.resendInvitation(id)}
          onRevoke={() => invitationStore.action.revokeInvitation(id)}
        />
      )}
      {isLoading && (
        <LoadingCircle
          className="InvitationRow__loader"
          center={false}
          size={16}
        />
      )}
    </li>
  )
}

export default observer(InvitationRow)
