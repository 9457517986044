import { jwtDecode } from "jwt-decode"
import { createDownloader } from "../download/utils"

const getDefaultReportFilename = (reportUrl) => {
  const token = new URL(reportUrl).searchParams.get("token")
  const { k } = jwtDecode(token)
  return k.split(":").join("_")
}

export const downloadReport = async (reportUrl) => {
  const response = await fetch(reportUrl)

  if (response.status === 200) {
    const downloader = await createDownloader(response, {
      // a default filename that hopefull will be replaced by the response.headers content-disposition
      filename: getDefaultReportFilename(reportUrl)
    })
    // call the downloader immediately
    downloader()
  } else if (response.status === 400) {
    const { error } = await response.json()
    throw new Error(error)
  } else {
    throw new Error("Failed to download report.")
  }
}
