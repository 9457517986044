import gql from "graphql-tag"

export default gql`
  fragment InvitationFragment on Invitation {
    id
    created_dtime # analogous to email "sent" date
    to_email
    invitation_role
    invitation_type
    invitation_status
    invitation_url
    invitation_hash
    invitation_email {
      id
      created_dtime # analogous to email "sent" date, prefer over invitation.created_dtime
      delivery_status
      delivery_status_updated
    }
  }
`
