import gql from "graphql-tag"
import COMPLETION_FRAGMENT from "./completion-fragment"

export default gql`
  mutation SaveCompletionFeedback(
    $id: Int!
    $pathId: Int!
    $userpointsEarned: Int
    $userpointsExplanation: String
    $notify: Boolean
  ) {
    saveCompletionFeedback(
      id: $id
      path_id: $pathId
      userpoints_earned: $userpointsEarned
      userpoints_explanation: $userpointsExplanation
      notify: $notify
    ) {
      ...Completion
    }
  }
  ${COMPLETION_FRAGMENT}
`
