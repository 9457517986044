import Button from "@pathwright/ui/src/components/button/Button"
import DropdownItem from "@pathwright/ui/src/components/dropdown/DropdownItem"
import DropdownMenu from "@pathwright/ui/src/components/dropdown/DropdownMenu"
import DropdownTrigger from "@pathwright/ui/src/components/dropdown/DropdownTrigger"
import SearchInput from "@pathwright/ui/src/components/form/form-text-input/SearchInput"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import Screensize from "@pathwright/ui/src/components/ui/Screensize"
import View from "@pathwright/ui/src/components/ui/View"
import get from "lodash/get"
import styled from "styled-components"
import BrowseBlock from "../BrowseBlock"

const Wrapper = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding-top: 0.5em;
`

const Search = styled(SearchInput)`
  width: 150px;
`

function FilterMenu({ items, label, onSelect, renderItem }) {
  return (
    <DropdownTrigger
      alignment="left"
      renderDropdown={() =>
        items && (
          <DropdownMenu closeOnClick>
            {items.map((item) => (
              <DropdownItem onClick={() => onSelect(item.id)} key={item.id}>
                {renderItem(item)}
              </DropdownItem>
            ))}
          </DropdownMenu>
        )
      }
    >
      <Button styleType="blank" style={{ padding: 0, marginRight: 10 }}>
        {label} <Pathicon icon="chevron-down" />
      </Button>
    </DropdownTrigger>
  )
}

function Filter({
  authors,
  categories,
  publishers,
  activeFacets,
  onChangeFacets
}) {
  const getActiveFacet = (facets, activeFacetFilter) =>
    activeFacetFilter &&
    facets.find((facet) => facet.id === activeFacetFilter.eq)

  return (
    <Screensize.Consumer>
      {(screensize) =>
        screensize === "sm" && categories ? (
          <FilterMenu
            items={[{ id: null, name: "All" }, ...categories]}
            label={get(
              getActiveFacet(categories, activeFacets.categoryIdFilter),
              "name",
              "Filter"
            )}
            renderItem={(c) => c.name}
            onSelect={(id) => onChangeFacets({ categoryIdFilter: { eq: id } })}
          />
        ) : (
          <View>
            {authors && (
              <FilterMenu
                items={[{ id: null, full_name: "All" }, ...authors]}
                label={get(
                  getActiveFacet(authors, activeFacets.authorIdFilter),
                  "full_name",
                  "Author"
                )}
                renderItem={(a) => a.full_name}
                onSelect={(id) =>
                  onChangeFacets({ authorIdFilter: { eq: id } })
                }
              />
            )}
            {categories && (
              <FilterMenu
                items={[{ id: null, name: "All" }, ...categories]}
                label={get(
                  getActiveFacet(categories, activeFacets.categoryIdFilter),
                  "name",
                  "Category"
                )}
                renderItem={(c) => c.name}
                onSelect={(id) =>
                  onChangeFacets({ categoryIdFilter: { eq: id } })
                }
              />
            )}
            {publishers && (
              <FilterMenu
                items={[{ id: null, name: "All" }, ...publishers]}
                label={get(
                  getActiveFacet(publishers, activeFacets.publisherIdFilter),
                  "name",
                  "Publisher"
                )}
                renderItem={(c) => c.name}
                onSelect={(id) =>
                  onChangeFacets({ publisherIdFilter: { eq: id } })
                }
              />
            )}
          </View>
        )
      }
    </Screensize.Consumer>
  )
}

Filter.displayName = "Filter"

function BrowseFacets({
  authors,
  categories,
  publishers,
  activeFacets,
  onChangeFacets
}) {
  return (
    <BrowseBlock>
      <Wrapper>
        <Filter
          authors={authors}
          categories={categories}
          publishers={publishers}
          activeFacets={activeFacets}
          onChangeFacets={onChangeFacets}
        />
        <Search
          onEnter={(search) => onChangeFacets({ search })}
          onClear={
            activeFacets && activeFacets.search
              ? () => onChangeFacets({ ...activeFacets, search: null })
              : null
          }
        />
      </Wrapper>
    </BrowseBlock>
  )
}

BrowseFacets.displayName = "BrowseFacets"

export default BrowseFacets
