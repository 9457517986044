import Card from "@pathwright/ui/src/components/card/Card"
import CertificateResourceAdd from "../manager/CertificateResourceAdd"

function CertificateAddResourceCard({ card }) {
  return (
    <Card
      className="chakra-ui-reset-css"
      card={card}
      title="Add Certificate to..."
      noaction
    >
      <CertificateResourceAdd />
    </Card>
  )
}

export default CertificateAddResourceCard
