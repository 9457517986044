export default {
  type: "Embed",
  icon: "code-square",
  helpLink:
    "https://help.pathwright.com/create-a-course/adding-content-with-blocks/embed-block-new",
  menu: {
    category: "media",
    order: 1
  },
  layouts: {
    center: {
      key: "center",
      label: "Embed centered",
      data: {
        url: `https://youtu.be/-1XTHgs1SNo`,
        embed_code: `<div><div style="left: 0; width: 100%; height: 0; position: relative; padding-bottom: 56.25%;"><iframe src="//cdn.iframe.ly/api/iframe?url=https%3A%2F%2Fyoutu.be%2F-1XTHgs1SNo&amp;key=0d1aa8566d7e33954a7d93f9d9788b00" style="border: 0; top: 0; left: 0; width: 100%; height: 100%; position: absolute;" allowfullscreen scrolling="no" allow="encrypted-media *; accelerometer; clipboard-write; gyroscope; picture-in-picture"></iframe></div></div>`
      }
    },
    fullwidth: {
      key: "fullwidth",
      label: "Embed anything",
      data: {
        url: `https://cdn.filestackcontent.com/VqT89YFDRkGQZeUr7aMs`,
        embed_code: `<div style="max-width: 1440px;"><div style="left: 0; width: 100%; height: 0; position: relative; padding-bottom: 52.3333%;"><iframe src="//cdn.iframe.ly/api/iframe?url=https%3A%2F%2Fcdn.filestackcontent.com%2FVqT89YFDRkGQZeUr7aMs&key=0d1aa8566d7e33954a7d93f9d9788b00" style="top: 0; left: 0; width: 100%; height: 100%; position: absolute; border: 0;" allowfullscreen></iframe></div></div>`
      }
    },
    video: {
      key: "video",
      label: "Embed Video",
      helpLink:
        "http://help.pathwright.com/en/articles/6577407-embed-video-block",
      data: {
        url: `https://youtu.be/PNjGJ9aIZ7g`,
        embed_code: `<iframe width="560" height="315" src="https://www.youtube.com/embed/PNjGJ9aIZ7g?mute=1" title="YouTube video player" frameborder="0" mute="1" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
      }
    }
  },
  schema: `type Embed implements Block {
    id: ID!
    embed_code: String!
    url: String
  }`
}
