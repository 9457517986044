import gql from "graphql-tag"
import SCHOOL_FRAGMENT from "../../school/graphql/school-fragment"
import SCHOOL_GIFT_SUBSCRIPTION_FRAGMENT from "./school-gift-subscription-fragment"

export default gql`
  query SchoolGiftSubscriptionQuery($code: String, $id: Int) {
    school {
      ...School
      gift_subscription(code: $code, id: $id) {
        ...SchoolGiftSubscription
      }
    }
  }
  ${SCHOOL_FRAGMENT}
  ${SCHOOL_GIFT_SUBSCRIPTION_FRAGMENT}
`
