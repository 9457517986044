export const TEXT_OBJECT_ALIGNMENT_LEFT = "left"
export const TEXT_OBJECT_ALIGNMENT_RIGHT = "right"
export const TEXT_OBJECT_ALIGNMENT_CENTER = "center"
export const TEXT_OBJECT_ALIGNMENTS = [
  TEXT_OBJECT_ALIGNMENT_LEFT,
  TEXT_OBJECT_ALIGNMENT_CENTER,
  TEXT_OBJECT_ALIGNMENT_RIGHT
]

export const TEXT_OBJECT_PADDING = 15

// Getter instead of const object to avoid changes to actualy default object.
export const getDefaultTextObject = () => ({
  text: "",
  // initiallly a percentage, to get an initially centered textObject
  position: {
    top: 0.45, // %
    left: 0.05 // %
  },
  // initiallly a percentage, to get an initially centered textObject
  dimensions: {
    height: 0.075, // %
    width: 0.9 // %
  },
  gutter: TEXT_OBJECT_PADDING,
  alignment: TEXT_OBJECT_ALIGNMENT_CENTER,
  font_size: 30, // px
  font_name: "NimbusSans-Regular",
  font_color: "0,0,0,100", // string
  line_height: null, // px
  letter_spacing: 0 // px
})
