import { DocumentNode } from "@apollo/client"
import {
  BlockTypeFieldType,
  ServerBlockTypeType
} from "@pathwright/blocks-core"
import gql from "graphql-tag"
import upperFirst from "lodash/upperFirst"
import { Scope } from "./scopes"

// hack to fix an import error
export { parse } from "graphql/language"

// ** Blocks Content ** //

export const CONTENT_BLOCK_FIELDS_FRAGMENT = gql`
  fragment contentBlockFields on ContentBlock {
    id
    contentID
    createdBy
    createdDateTime
    lastModifiedDateTime
    lastModifiedBy
    order
    type
    data
    layout
    style {
      selectedSettings
      styleAttributes
    }
    completion {
      progress
      score
    }
  }
`

export const CONTENT_FIELDS_FRAGMENT = gql`
  fragment contentFields on Content {
    id
    createdBy
    accountID
    copiedFromAccountID
    createdDateTime
    lastPublishedDateTime
    lastModifiedDateTime
    accessToken
    completion {
      completionToken
      progress
      score
    }
    blocks {
      ...contentBlockFields
    }
  }
  ${CONTENT_BLOCK_FIELDS_FRAGMENT}
`

export const BLOCKS_CONTEXT_FRAGMENT = gql`
  fragment contextFields on BlocksContext {
    accountID
    userID
    accessToken
    mediaStoragePath
    blockTypes {
      type
      layouts
      menu
      helpLink
      fields
      defaults
    }
  }
`

export const blocksContentQuery = gql`
  query BlocksContent(
    $id: ID
    $mode: MODE!
    $contextKey: String!
    $template: String
    $draft: Boolean!
    $upsert: Boolean
    $userID: ID
  ) {
    blocksContext: BlocksContext {
      ...contextFields
    }
    content: Content(
      id: $id
      mode: $mode
      contextKey: $contextKey
      template: $template
      draft: $draft
      upsert: $upsert
      userID: $userID
    ) {
      ...contentFields
    }
  }
  ${BLOCKS_CONTEXT_FRAGMENT}
  ${CONTENT_FIELDS_FRAGMENT}
`

export const blocksContextQuery = gql`
  query BlocksContext {
    blocksContext: BlocksContext {
      ...contextFields
    }
  }
  ${BLOCKS_CONTEXT_FRAGMENT}
`

export const blocksContentOnlyQuery = gql`
  query BlocksContentOnly(
    $id: ID
    $mode: MODE!
    $contextKey: String!
    $template: String
    $draft: Boolean!
    $upsert: Boolean
    $userID: ID
  ) {
    content: Content(
      id: $id
      mode: $mode
      contextKey: $contextKey
      template: $template
      draft: $draft
      upsert: $upsert
      userID: $userID
    ) {
      ...contentFields
    }
  }
  ${CONTENT_FIELDS_FRAGMENT}
`

export const contentPublishMutation = gql`
  mutation publishContent($id: ID!, $contextKey: String!) {
    content: publishContent(id: $id, contextKey: $contextKey) {
      ...contentFields
    }
  }
  ${CONTENT_FIELDS_FRAGMENT}
`

export const discardDraftContentMutation = gql`
  mutation discardDraftContent($contentID: ID!, $contextKey: String!) {
    content: discardDraftContent(
      contentID: $contentID
      contextKey: $contextKey
    ) {
      ...contentFields
    }
  }
  ${CONTENT_FIELDS_FRAGMENT}
`

// Move, Delete, Paste Block Mutations
export const getRootBlockMutations = (): {
  moveBlock: DocumentNode
  deleteBlock: DocumentNode
  pasteBlock: DocumentNode
} => {
  // Move Block
  const moveBlockGQL: DocumentNode = gql`
    mutation moveBlock(
      $contentID: ID!
      $id: ID!
      $contextKey: String!
      $order: Int!
    ) {
      content: moveBlock(
        contentID: $contentID
        id: $id
        contextKey: $contextKey
        order: $order
      ) {
        ...contentFields
      }
    }
    ${CONTENT_FIELDS_FRAGMENT}
  `

  // Delete Block Mutation
  const deleteBlockGQL: DocumentNode = gql`
    mutation deleteBlock($contentID: ID!, $contextKey: String!, $id: ID!) {
      content: deleteBlock(
        contentID: $contentID
        contextKey: $contextKey
        id: $id
      ) {
        ...contentFields
      }
    }
    ${CONTENT_FIELDS_FRAGMENT}
  `

  const pasteBlockGQL: DocumentNode = gql`
    mutation pasteBlock(
      $sourceContentID: ID!
      $sourceBlockID: ID!
      $destinationContentID: ID!
      $order: Int
      $contextKey: String!
    ) {
      pasteBlock(
        sourceContentID: $sourceContentID
        sourceBlockID: $sourceBlockID
        destinationContentID: $destinationContentID
        order: $order
        contextKey: $contextKey
      ) {
        ...contentBlockFields
      }
    }
    ${CONTENT_BLOCK_FIELDS_FRAGMENT}
  `

  return {
    moveBlock: moveBlockGQL,
    deleteBlock: deleteBlockGQL,
    pasteBlock: pasteBlockGQL
  }
}

export type BlockTypeMutationWithNameType = {
  name: string
  gql: DocumentNode
}

// Auto-generated saveBlock mutations for each block type
export const getBlockMutationsForType = (
  blockType: ServerBlockTypeType
): BlockTypeMutationWithNameType[] => {
  const mutations: BlockTypeMutationWithNameType[] = []

  const typeName: string = upperFirst(blockType.type)

  // Content scoped block mutations
  const saveBlockMutationName = `save${typeName}Block`
  const saveBlockGQL: DocumentNode = gql`
    mutation ${saveBlockMutationName}($contentID: ID!, $id: ID!, $contextKey: String!, $order: Int, $layout: String!, $data: ${typeName}Input, $style: BlockStyleInput) {
      block: ${saveBlockMutationName}(contentID: $contentID, id: $id, contextKey: $contextKey, order: $order, data: $data, layout: $layout, style: $style) {
        ...contentBlockFields
      }
    }
    ${CONTENT_BLOCK_FIELDS_FRAGMENT}
  `

  mutations.push({
    name: saveBlockMutationName,
    gql: saveBlockGQL
  })

  // User scoped block mutations
  const userScopedFields: BlockTypeFieldType[] = Object.values(
    blockType.fields
  ).filter((field: BlockTypeFieldType) => field.scope === Scope.USER)
  if (userScopedFields.length > 0) {
    const saveUserDataMutationName = `save${typeName}BlockUserData`
    const saveUserDataGQL: DocumentNode = gql`
      mutation ${saveUserDataMutationName}($contentID: ID!, $contextKey: String!, $id: ID!, $userID: ID!, $noProgress: Boolean!, $data: ${typeName}UserDataInput, $draft: Boolean) {
        block: ${saveUserDataMutationName}(contentID: $contentID, id: $id, contextKey: $contextKey, noProgress: $noProgress,  data: $data, userID: $userID, draft: $draft) {
          ...contentBlockFields

          contentCompletion @skip(if: $noProgress) {
            completionToken
            progress
            score
          }
        }
      }
      ${CONTENT_BLOCK_FIELDS_FRAGMENT}
    `

    mutations.push({
      name: saveUserDataMutationName,
      gql: saveUserDataGQL
    })
  }

  return mutations
}

// ** MUX Video ** //
export const MUX_VIDEOS_QUERY = gql`
  query Videos {
    muxVideos {
      id
      name
      asset {
        id
        status
      }
      upload {
        id
        status
        url
      }
    }
  }
`

export const MUX_VIDEO_EMBED_QUERY = gql`
  query Embed($id: ID!) {
    muxVideoEmbed(id: $id) {
      status
      duration
      download {
        status
        url
      }
      thumb {
        src
        gifSrc
      }
      stream {
        url
        status
        size {
          maxWidth
          maxHeight
          aspectRatio
        }
      }
      audio {
        duration
        status
        maxChannelLayout
        maxChannels
      }
      subtitles
    }
  }
`

export const CREATE_VIDEO_MUTATION = gql`
  mutation CreateVideo($name: String) {
    createMuxVideo(name: $name) {
      id
      name
      upload {
        url
        id
        status
      }
    }
  }
`
export const DELETE_VIDEO_MUTATION = gql`
  mutation DeleteVideo($id: ID!) {
    deleteMuxVideo(id: $id)
  }
`

export const CHECK_AND_UPDATE_VIDEO_STATUS_MUTATION = gql`
  mutation VideoStatus($assetID: ID!) {
    checkAndUpdateVideoStatus(assetID: $assetID) {
      encoding {
        status
      }
    }
  }
`
