import isEqual from "react-fast-compare"

const StateStorage = require("lib/utils/store").default

class State extends require("lib/static-shim").default(Backbone.Model) {
  static initClass() {
    ;`\
A base state model that handles module/view state and
can be persisted to localstorage (with fallback to cookies)\
`
    this.prototype.persistAttrs = []
  }

  constructor() {
    super(...arguments)
    this.storage = new StateStorage() //new MemoryStorage()
  }

  initialize(attrs, options) {
    if (options == null) {
      options = {}
    }
    this.options = options
    _.extend(this.options, { autoSave: true })
    if (this.options.autoSave) {
      let changeEvent = "change"
      if (this.persistAttrs.length) {
        changeEvent = ""
        for (let attr of Array.from(this.persistAttrs)) {
          changeEvent = `${changeEvent}change:${attr} `
        }
      }
      this.on(changeEvent, this.save)
    }
    return super.initialize(...arguments)
  }

  save() {
    // TODO: make sure this is actually efficient
    const freezeJSON = JSON.stringify(this.toJSON())
    const json = this.storage.get(this.id)
    if (!isEqual(json, freezeJSON)) {
      return this.storage.set(this.id, freezeJSON)
    }
  }

  fetch() {
    const json = this.storage.get(this.id)
    if (json) {
      this.set(JSON.parse(json), { silent: true })
    }
    return this
  }

  destroy() {
    this.storage.remove(this.id)
    return delete this.storage
  }

  toJSON() {
    if (this.persistAttrs.length) {
      return _.pick(super.toJSON(), this.persistAttrs)
    } else {
      return super.toJSON()
    }
  }
}
State.initClass()
export default State
