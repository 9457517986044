import { TERTIARY_GRAY } from "@pathwright/ui/src/components/utils/colors"
import styled from "styled-components"

import BlankImage from "@pathwright/ui/src/components/blank/BlankImage"
import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

const StyledIllustration = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

const StyledItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 5px 10px 5px 20px;
  font-style: italic;
  text-align: left;
  color: ${TERTIARY_GRAY};
`

const StyledAvatar = styled(BlankImage)`
  width: 30px;
  height: 30px;
  margin-right: 10px;
  path {
    fill: ${TERTIARY_GRAY};
  }
`

const StyledInfo = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const StyledName = styled.h4`
  margin: 0;
  font-weight: 400;
`

const StyledMeta = styled.div`
  display: flex;
  align-items: baseline;
  height: 15px;
  line-height: 15px;
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const MembershipBlankItem = ({
  avatarImg,
  displayName,
  memberRole,
  lastActivity
}) => {
  return (
    <StyledItem>
      <StyledAvatar image={avatarImg} />
      <StyledInfo>
        <StyledName>{displayName}</StyledName>
        <StyledMeta>
          {memberRole} • Active {lastActivity}
        </StyledMeta>
      </StyledInfo>
    </StyledItem>
  )
}

MembershipBlankItem.displayName = "MembershipBlankItem"

const MembershipBlank = () => {
  return (
    <StyledContainer>
      <BlankSlate
        illustration={
          <StyledIllustration>
            <MembershipBlankItem
              avatarImg="avatar2"
              displayName="Gabriel Woods"
              memberRole="Staff"
              lastActivity="1 day ago"
            />
            <MembershipBlankItem
              avatarImg="avatar1"
              displayName="Lisa Poulsen"
              memberRole="Member"
              lastActivity="2 hrs ago"
            />
          </StyledIllustration>
        }
        body="See what everyone's up to and configure their access all in one place."
        primaryAction={{
          children: (
            <>
              <Pathicon icon="group-plus" />
              <span> </span>Invite someone
            </>
          ),
          to: "/community/members/invite/"
        }}
      />
    </StyledContainer>
  )
}

MembershipBlank.displayName = "MembershipBlank"

export default MembershipBlank
