import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import { useMemo } from "react"
import { TAGS_QUERY } from "./graphql"
import useReorderTag from "./useReorderTag"
import useSelectTags from "./useSelectTags"
import useUnselectTags from "./useUnselectTags"
import useUpdateSelectedTags from "./useUpdateSelectedTags"
import useUpdateTag from "./useUpdateTag"
import { getTagAttachment, getTagsContext, sortTags } from "./utils"

// Responsible for querying and processing the tags data.
const useTagsData = (context) => {
  const tagsContext = getTagsContext(context)
  const tagsContextVariable = tagsContext ? { context: tagsContext } : {}

  // NOTE: the necessity of querying the TAGS_QUERY twice arises from a
  // bug in join-monster when querying aliased paginated fields. See the
  // issue here: https://github.com/join-monster/join-monster/issues/126.
  // So, instead of including tagAttachments under tags twice, with one
  // instance accepting the context, we must break this effort into two
  // queries, then merge the results.

  // Tags with non-contextualized tagAttachments.
  const tagsQuery = useQuery(TAGS_QUERY, {
    variables: {
      withContextualTagAttachment: false
    }
  })

  // Tags with contextualized tagAttachments.
  const tagsContextQuery = useQuery(TAGS_QUERY, {
    variables: {
      ...tagsContextVariable,
      withContextualTagAttachment: !!tagsContext
    }
  })

  const tagsData = useMemo(() => {
    if (tagsQuery.data && tagsContextQuery.data) {
      // Merge tags from separate TAGS_QUERY results to form a cohesive
      // tags data that includes contextualized tagAttachments (which are
      // retrieved using the getTagAttachment util).
      const mergedTags = tagsQuery.data.tags.edges.map((edge, i) => {
        let tag = edge.node

        if (tagsContext) {
          // Find the matchingEdge from the tagsContextQuery, which may not
          // exist at first due to race conditions after TAGS_QUERYs are refetched
          // (like after a tag is created).
          const matchingEdge = tagsContextQuery.data.tags.edges.find(
            (edge) => edge.node.id === tag.id
          )
          if (matchingEdge) {
            // Merge in the contextualTagAttachments.
            tag = {
              ...tag,
              contextualTagAttachments: matchingEdge.node.tagAttachments
            }
          }
        }
        return tag
      })

      // Sort the mergedTags by the contextualTagAttachments (if applicable).
      return sortTags(mergedTags)
    }
  }, [tagsQuery.data, tagsContextQuery.data])

  return tagsData
}

const useTags = ({ context, selectedTags }) => {
  const tagsContext = getTagsContext(context)
  const tagsData = useTagsData(context)

  const tags = useMemo(() => {
    const tags = {
      all: [],
      filtered: [],
      selected: []
    }

    if (tagsData) {
      // All tags.
      tags.all = tagsData

      // Filtered subset of tags–those tags attached to tags matching
      // provided context.
      tags.filtered = tagsData.filter(
        (tag) =>
          // If context has been provided, ensure that the tag has been attached to provided context.
          !tagsContext || getTagAttachment(tag)
      )

      if (selectedTags) {
        // Only those tags which are selected, a subset of the filtered tags.
        // Forming new list based on tags.filtered to ensure proper data shape.
        tags.selected = tags.filtered.filter((tag) =>
          selectedTags.find((selectedTag) => selectedTag.name === tag.name)
        )
      } else {
        // If not provided selectedTags, we'll bump tags.filtered down to tags.selected.
        tags.selected = tags.filtered
        tags.filtered = tags.all
      }
    }

    return tags
  }, [tagsData, selectedTags])

  const updateTag = useUpdateTag()
  const reorderTag = useReorderTag({ tags, context })
  const updateSelectedTags = useUpdateSelectedTags({ context, tags })
  const selectTags = useSelectTags({ context, tags })
  const unselectTags = useUnselectTags({ context, tags })

  return {
    tags,
    tagsData,
    updateTag,
    reorderTag,
    updateSelectedTags,
    selectTags,
    unselectTags
  }
}

export default useTags
