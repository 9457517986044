import gql from "graphql-tag"

export default gql`
  mutation ($id: Int!, $role: MembershipRole!) {
    setMemberRole(id: $id, role: $role) {
      id
      role
    }
  }
`
