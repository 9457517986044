import gql from "graphql-tag"

export default gql`
  mutation AddPeopleToMutation(
    $selection: [AddPeopleSelection]!
    $notificationMessage: String
    $sendFromSchool: Boolean
    $sendNotification: Boolean
  ) {
    addPeopleTo(
      selection: $selection
      notification_message: $notificationMessage
      send_from_school: $sendFromSchool
      send_notification: $sendNotification
    ) {
      id
      result
    }
  }
`
