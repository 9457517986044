import gql from "graphql-tag"
import DISCUSSION_POST from "./discussion-post-fragment"

export default gql`
  mutation CreateDiscussion(
    $context: DiscussionContextInput!
    $post: DiscussionPostInput!
    $initialResponse: DiscussionResponseInput
    $notify: Boolean = false
  ) {
    createDiscussion(
      context: $context
      post: $post
      initialResponse: $initialResponse
      notify: $notify
    ) {
      ...DiscussionPost
    }
  }
  ${DISCUSSION_POST}
`
