import PropTypes from "prop-types"
import ManageDiscountCodeForm from "./ManageDiscountCodeForm"
import ManageDiscountSubscriptionPlansForm from "./ManageDiscountSubscriptionPlansForm"
import ManageDiscountUsageForm from "./ManageDiscountUsageForm"
import ManageDiscountsForm from "./ManageDiscountsForm"

const ManageSubscriptionPlanDiscountsForm = ({ card }) => (
  <ManageDiscountsForm
    card={card}
    title="Subscription Discount Code Creator"
    forms={[
      {
        Form: ManageDiscountCodeForm,
        initialValues: { discountPercentage: 100 }
      },
      { Form: ManageDiscountSubscriptionPlansForm },
      { Form: ManageDiscountUsageForm }
    ]}
  />
)

ManageSubscriptionPlanDiscountsForm.displayName =
  "ManageSubscriptionPlanDiscountsForm"

ManageSubscriptionPlanDiscountsForm.propTypes = {
  card: PropTypes.object.isRequired
}

export default ManageSubscriptionPlanDiscountsForm
