// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
let ResponseListItemArchivedView
require("./styles/response-list-item-archived.css")

export default ResponseListItemArchivedView = (function () {
  ResponseListItemArchivedView = class ResponseListItemArchivedView extends (
    require("lib/static-shim").default(require("lib/core/item-view").default)
  ) {
    static initClass() {
      this.prototype.template = require("./templates/response-list-item-archived.html")
      this.prototype.className = "response-list-item-archived-view"
    }

    _hideArchivedResponses() {
      // TODO: handle this server side? Use num_responses once it's set.
      // For now hide archived responses that have no replies (children)
      const canManageSchool =
        !!App.getStore("auth").getState().user?.membership?.can_manage_school

      const { collection } = this.model
      // Find if any non-archived child responses exists, no matter the depth.
      const hasNonArchivedChildResponses = !!collection.find((model) => {
        function hasUnarchivedChild(parent, child) {
          return (
            parent.id === child.get("reply_parent") &&
            (!child.get("archived_time") ||
              // This child is archived, but this child's children may not be.
              collection.find((model) => hasUnarchivedChild(child, model)))
          )
        }

        return hasUnarchivedChild(this.model, model)
      })

      // Admin can see all archived responses and anyone can see archived responses that
      // have child responses.
      const shouldRender = canManageSchool || hasNonArchivedChildResponses
      this.$el.toggle(shouldRender)
    }

    onBeforeRender() {
      this._hideArchivedResponses()
    }

    onRender() {
      return this.$el
        .addClass(`response-depth-${this.model.get("depth")}`)
        .data("depth", this.model.get("depth"))
    }
  }
  ResponseListItemArchivedView.initClass()
  return ResponseListItemArchivedView
})()
