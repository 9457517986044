import { withApollo } from "@apollo/client/react/hoc"
import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import Card from "@pathwright/ui/src/components/card/Card"
import CardBlock from "@pathwright/ui/src/components/card/CardBlock"
import CardLink from "@pathwright/ui/src/components/card/CardLink"
import TextInput from "@pathwright/ui/src/components/form/form-text-input/TextInput"
import TextArea from "@pathwright/ui/src/components/form/form-textarea/TextArea"
import PropTypes from "prop-types"
import { Component } from "react"
import { Field, Form, Submit, validators } from "react-connect-form-forked"
import { HELP_LINK_DOMAIN } from "../../help/constants"
import { PathwrightContext } from "../../pathwright/PathwrightContext"
import SUBDOMAIN_QUERY from "../../signup/graphql/subdomain-query"
const { isMaxLength } = validators

class ManageAccount extends Component {
  isUniqueSubdomain = (subdomain) => {
    return new Promise((resolve, reject) => {
      this.props.client
        .query({
          query: SUBDOMAIN_QUERY,
          variables: { subdomain }
        })
        .then(({ data }) => {
          if (data.subdomain) {
            reject("Sorry, that url already exists. Please choose another.")
          } else {
            resolve()
          }
        })
    })
  }
  render() {
    const { school, translation } = this.context

    return (
      <Card card={this.props.card} title={`${school.name} Information`}>
        <Form
          initialValue={this.props.initialValue}
          onSubmit={this.props.onSubmit}
        >
          <CardBlock>
            <Field
              name="name"
              label="Account name"
              type="text"
              helperText="Your organization or brand name that helps your members know where they're at."
              placeholder={`"Initech training", "Space Academy", ...`}
              component={TextInput}
              labelWidth={120}
              // Think we're trying to limit school account names to 30 characters, but the backend allows for 255 characters
              // and there are older schools with longer account names, so we need to allow for that.
              validators={[
                isMaxLength(Math.min(Math.max(school.name.length, 30), 255))
              ]}
              required
              topLabel
            />
          </CardBlock>
          {!this.props.hasCustomDomain && (
            <CardBlock>
              <Field
                name="subdomain"
                label="Subdomain"
                type="text"
                disabled // TODO: allow for updating subdomain
                helperText={
                  <span>
                    Your account's unique web address.{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={HELP_LINK_DOMAIN}
                      style={{ textDecoration: "underline" }}
                    >
                      Want to change your subdomain?
                    </a>
                  </span>
                }
                placeholder="mysubdomain"
                component={TextInput}
                labelWidth={120}
                minWidth={75}
                stretch={false}
                autoSize
                custom=".pathwright.com"
                topLabel
              />
            </CardBlock>
          )}
          {
            <CardBlock>
              <Field
                name="short_description"
                label="Description"
                type="text"
                helperText="Improve SEO by providing a description of your school in less than 300 characters. This will be used in the meta description HTML tag indexed by web crawlers."
                placeholder={`Online courses from ${school.name}`}
                component={TextArea}
                labelWidth={120}
                validators={[isMaxLength(300)]}
                topLabel
              />
            </CardBlock>
          }
          <Submit styleType="primary" component={SubmitButton}>
            Save
          </Submit>
        </Form>
        {this.props.hasCustomDomain ? (
          <CardLink
            icon="ellipses-horizontal"
            label="Configure your custom domain"
            meta="Make changes to your custom domain."
            to={`${this.props.card.path}domain/`}
          />
        ) : (
          <CardLink
            icon="ellipses-horizontal"
            label="Setup a custom domain"
            meta="Use your own domain instead of a subdomain."
            to={`${this.props.card.path}domain/`}
          />
        )}
        <CardLink
          icon="sparkles"
          label="Customize your brand"
          meta="Make it yours with a custom logo, color, and more."
          to={`${this.props.card.path}brand/`}
        />
        <CardLink
          icon="chat-bubble-plus"
          label="Support contact information"
          meta="Let your members know where & when they can get help."
          to={`${this.props.card.path}support/`}
        />
        <CardLink
          icon="file-text"
          label="Configure privacy policy"
          meta="Include your own privacy policy and enable opt-in field."
          to={`${this.props.card.path}privacy/`}
        />
        {!!translation && !translation.is_default && (
          <CardLink
            icon="file-text"
            label="Update translation"
            meta="Update the translated labels your learners see."
            to={`${this.props.card.path}translation/`}
          />
        )}
        {/* {this.props.hasPages && (
          <CardLink
            icon="file-text"
            label="School pages"
            meta="Manage school pages."
            to={`${this.props.card.path}pages/`}
          />
        )} */}
      </Card>
    )
  }
}

ManageAccount.displayName = "ManageAccount"

ManageAccount.contextType = PathwrightContext

ManageAccount.propTypes = {
  // provided by CardStack > CardStackCard
  card: PropTypes.object.isRequired,
  // handler to save field values
  onSubmit: PropTypes.func.isRequired,
  // field values
  initialValue: PropTypes.shape({
    name: PropTypes.string.isRequired,
    subdomain: PropTypes.string.isRequired,
    short_description: PropTypes.string.isRequired
  }).isRequired,
  hasCustomDomain: PropTypes.bool.isRequired,
  hasPages: PropTypes.bool.isRequired
}

export default withApollo(ManageAccount)
