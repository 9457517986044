import gql from "graphql-tag"
import SCHOOL_FRAGMENT from "../../school/graphql/school-fragment"
import STORE_FRAGMENT from "./store-fragment"

export default gql`
  fragment StoreLink on StoreLink {
    id
    licensee {
      ...School
    }
    store {
      ...Store
    }
  }
  ${SCHOOL_FRAGMENT}
  ${STORE_FRAGMENT}
`
