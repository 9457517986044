import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import SearchInput from "@pathwright/ui/src/components/form/form-text-input/SearchInput"
import InfiniteList from "@pathwright/ui/src/components/infinite/InfiniteList"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import { useObserveSize } from "@pathwright/ui/src/components/observers/ObserveSizeContext"
import View from "@pathwright/ui/src/components/ui/View"
import { media } from "@pathwright/ui/src/components/utils/styles"
import styled from "styled-components"
import { ShareDumb } from "../../../share/Share"
import MentorGroupInvitationListItem from "./MentorGroupInvitationListItem"
import { useMentorGroupListContext } from "./MentorGroupListContext"
import MentorGroupMemberListItem, {
  MentorGroupMemberListItemAdd
} from "./MentorGroupMemberListItem"

const StyledHeader = styled(View.Primary)`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const ListContainer = styled.div`
  position: relative;
  padding-top: 10px;
  width: 100%;
  height: calc(100vh - 100px);
  ${media.min.phone`height: calc(100vh - 400px);`};
`

const MentorGroupMemberList = () => {
  const [listContainerRectValue, _, setListContainerRef] = useObserveSize()
  const { t } = useTranslate()
  const {
    mentorGroup,
    groupSubscription,
    permissions,
    searchedMembersQuery,
    searchedMembers,
    searchedMembersPaginator,
    search,
    debouncingSeach,
    setSearch
  } = useMentorGroupListContext()

  const loading = searchedMembersQuery.loading || debouncingSeach
  const error = searchedMembersQuery.error

  const showSearch =
    // hacky check for if loading after clearing search value (initial search value is null)
    (loading && typeof search === "") ||
    search ||
    debouncingSeach ||
    searchedMembers.length > 7

  const tPrefix = "mentor_group.members.list"

  if (error) {
    return (
      <BlankSlate
        icons={null}
        heading="An unexpected error occurred."
        body={(error && error.message) || error}
        primaryAction={{
          children: t(`${tPrefix}.refresh`),
          onClick: () => searchedMembersQuery.refetch()
        }}
      />
    )
  }

  let listData = searchedMembers

  const rowHeight = 50
  // The virtual list is tricky to deal with. We're conditionally inlining the
  // add member row depending on if the height of the items + 1 is less than
  // the list container, which indicates that there is room within the list to show
  // the add member row, otherwise put the add member row after the list.
  const shouldInlineAddMember =
    !!listData.length &&
    listContainerRectValue?.height > (listData.length + 1) * rowHeight

  // Push a phony item that as a placeholder for the MentorGroupMemberListItemAdd.
  if (shouldInlineAddMember) {
    listData = [...listData, null]
  }

  const shouldShowAddMember = permissions.can_add_member && !!listData?.length // && !search

  return (
    <div>
      {showSearch && (
        <StyledHeader>
          <SearchInput
            value={search}
            onChange={(search) => setSearch(search)}
            onClear={() => setSearch("")}
            placeholder={`${t(`Search`)}...`}
          />
        </StyledHeader>
      )}
      <ListContainer ref={setListContainerRef}>
        {
          // noRowsRenderer doesn't appear to update after initial render
          // so awaiting mentorGroup so as to have accurate permissions data
          !mentorGroup ? (
            <LoadingCircle center />
          ) : (
            <InfiniteList
              rowHeight={rowHeight}
              overscanRowCount={5}
              rowCount={listData ? listData.length : 0}
              items={listData}
              rowRenderer={(data) => {
                const item = listData[data.index]
                if (item) {
                  return (
                    <div style={data.style} key={listData[data.index].id}>
                      {searchedMembers[data.index] ? (
                        <MentorGroupMemberListItem
                          mentorGroup={mentorGroup}
                          groupSubscription={groupSubscription}
                          permissions={permissions}
                          membership={listData[data.index]}
                          search={search}
                        />
                      ) : (
                        <MentorGroupInvitationListItem
                          invitation={listData[data.index]}
                        />
                      )}
                    </div>
                  )
                } else if (
                  item === null &&
                  shouldInlineAddMember &&
                  shouldShowAddMember
                ) {
                  return (
                    <div style={data.style} key="add-member">
                      <MentorGroupMemberListItemAdd>
                        Add member
                      </MentorGroupMemberListItemAdd>
                    </div>
                  )
                }
                return null
              }}
              noRowsRenderer={() =>
                mentorGroup.permissions.can_add_member ? (
                  <ShareDumb
                    context={{ type: "group", typeId: mentorGroup.id }}
                    displayType="modal"
                  >
                    {({ renderTrigger }) =>
                      renderTrigger((props) => (
                        <BlankSlate
                          icons={null}
                          heading={t("No members here yet")}
                          body={
                            groupSubscription
                              ? t(
                                  "Add someone to your group to get access to {{ product }}, share progress, and more.",
                                  {
                                    product:
                                      groupSubscription.price.product.name
                                  }
                                )
                              : t(
                                  "Add someone to your group to share progress and more."
                                )
                          }
                          primaryAction={{
                            ...props,
                            onClick: props.onToggle,
                            children: t("Add members")
                          }}
                          meta={
                            mentorGroup.seat_limit != null
                              ? // if we were to add a _plural translation, which number would it be based on?
                                t(
                                  "{{ filled }}/{{ limit }} subscription seats filled",
                                  {
                                    filled: mentorGroup.seats_filled,
                                    limit: mentorGroup.seat_limit
                                  }
                                )
                              : null
                          }
                        />
                      ))
                    }
                  </ShareDumb>
                ) : (
                  <BlankSlate
                    heading={t("mentor_group.member.5.blank_slate.heading")}
                  />
                )
              }
              loadingRenderer={() => <LoadingCircle />}
              hasNextPage={searchedMembersPaginator.hasMore}
              loadNextPage={searchedMembersPaginator.loadMore}
              isNextPageLoading={
                loading || searchedMembersPaginator.loadingMore
              }
            />
          )
        }
      </ListContainer>
      {!shouldInlineAddMember && shouldShowAddMember && (
        <MentorGroupMemberListItemAdd>Add member</MentorGroupMemberListItemAdd>
      )}
    </div>
  )
}

export default MentorGroupMemberList
