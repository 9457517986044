// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
let DiscussionResponseForm
require("./styles/discussion-response-form.css")

const Response = require("discussion/models/response").default
import { i18n } from "@pathwright/ui/src/components/lng/I18nextProvider"

export default DiscussionResponseForm = (function () {
  DiscussionResponseForm = class DiscussionResponseForm extends (
    require("lib/static-shim").default(
      require("lib/uniform/views/form").default
    )
  ) {
    static initClass() {
      this.prototype.template = require("./templates/discussion-response-form.html")
      this.prototype.className =
        "pw-uniform white-focus discussion-response-form-view"

      this.prototype.ui = {
        prompt: ".response-form-prompt",
        input: ".reponse-form-input"
      }

      this.prototype.triggers = {
        "click @ui.prompt": "select:to:respond",
        "click .cancel-response": "cancel:response"
      }
    }

    behaviors() {
      const behaviors = super.behaviors()
      return _.extend(behaviors, {
        RichText: {
          container: ".discussion-panel-view"
        }
      })
    }

    initialize() {
      ;({ discussion: this.discussion } = this.options)
      if (
        !this.discussion &&
        (this.model != null ? this.model.discussion : undefined)
      ) {
        this.discussion = this.model.discussion
      }
      if (!this.discussion) {
        throw new Error("discussion must be set on DiscussionResponseForm")
      }
      if (this.model != null ? this.model.id : undefined) {
        this.model.snapshot()
      }
      return this.model != null
        ? this.model
        : (this.model = this._getNewResponse())
    }

    _getNewResponse() {
      return new Response({
        discussion: this.discussion.id,
        context_str: this.discussion.get("context_str"),
        author: Pathwright.request("get:user").id
      })
    }

    // Ensure the "body" value is exactly the same as the value of
    // the Redactor editor. There is a bug in the Redactor editor
    // that doesn't register text changes that occur when user on iOS
    // (and Android?) taps autocomplete prompts on the keyboard.
    // Any other text change occurring afterwards resolves the issue,
    // but if none occur, all immediately preceding autocomplete prompts
    // will not be included in the "body" (hence the workaround.)
    onBeforeModelSave() {
      try {
        const html = this.$el
          .find("textarea.redactor")
          .redactor("core.getEditor")
          .html()
        this.model.set("body", html)
      } catch (error) {
        console.warn(
          `Failed to ensure response.body (but may be OK). The error: ${error}`
        )
      }
    }

    onModelSave(model, isNew) {
      this.model.set("highlight", true)
      const isReply = this.model.get("reply_parent")
      if (!isReply) {
        this.model = this._getNewResponse()
        this.triggerMethod("rebind")
        this.onCancelResponse()
        if (isNew) {
          this.discussion.set(
            "num_responses",
            this.discussion.get("context").num_responses + 1
          )
        }
      } else {
        this.triggerMethod("response:saved")
      }
      this.options.onResponseSaved && this.options.onResponseSaved(model)
    }

    onShow() {
      // If this is a new model or a reply, open to edit automatically
      if (!this.model.isNew() || this.model.get("reply_parent")) {
        return this.onSelectToRespond()
      }

      // Focus the DiscussionResponseForm if query param "intent" === "reply".
      if (this.model.isNew() && !this.model.get("reply_parent")) {
        const searchParms = new URLSearchParams(window.location.search)
        if (searchParms.get("intent") === "reply") {
          // Without setTimeout, focusing the input breaks the DockPanel animation.
          setTimeout(() => {
            this.ui.prompt.get(0).scrollIntoView()
            this.ui.prompt.click()
          })
        }
      }
    }

    onSelectToRespond() {
      this.ui.prompt.hide()
      this.ui.input.show()
      this.triggerMethod("reset:parsley")
      if (this.model.isNew()) {
        // Attempt to execute a func until it succeeds.
        const attemptUntilSuccess = (func, interval) => {
          const intervalId = setInterval(() => {
            try {
              func()
              clearInterval(intervalId)
            } catch {}
          }, [interval])
        }

        // Focusing the redactor editor does not always succeed at first,
        // likely due to some async code.
        attemptUntilSuccess(
          () => this.$("#id_response_body").redactor("focus.setStart"),
          50
        )
      }
    }

    onCancelResponse() {
      this.model.rollback()
      if (this.model.isNew()) {
        this.ui.input.hide()
        this.ui.prompt.show()
        this.reset()
      }
      return this.triggerMethod("response:canceled")
    }

    serializeData() {
      const data = super.serializeData()
      data.is_new_response = this.model.isNew()
      let noun = i18n.t("discussion.responses.answer")
      if (data.depth > 1) {
        noun = i18n.t("discussion.responses.reply")
      }

      // Set the reply to label
      let reply_to_label = ""
      if (this.options.reply_parent) {
        const replyToName = this.options.reply_parent.get("author").first_name
        if (data.depth > 1) {
          reply_to_label = ` ${i18n.t(
            "discussion.responses.to"
          )} ${replyToName}`
        } else {
          reply_to_label = ` ${replyToName}`
        }
        noun = `${noun}${reply_to_label}`
      }

      const global = this.serializeGlobal()
      if (this.model.isNew() || this.model.get("author") === global.user.id) {
        // TODO: if disucssion, show school!
        data.author = global.user
      }

      data.response_action_label = `${noun}`
      if (!data.is_new_response) {
        data.response_action_label = `${i18n.t(
          "discussion.responses.update"
        )} ${noun}`
      }

      data.show_prompt = !data.is_new_response

      data.rich_text_enabled = true
      data.form_options = {
        nowrap: true,
        rt_config: "discussion",
        bind: "model:body"
      }
      data.form_options.placeholder = `${data.response_action_label}...`
      return data
    }
  }
  DiscussionResponseForm.initClass()
  return DiscussionResponseForm
})()
