import { graphql } from "@apollo/client/react/hoc"
import compose from "lodash/flowRight"
import get from "lodash/get"
import PropTypes from "prop-types"
import AUTHORS_QUERY from "../../../author/graphql/authors-query"
import CATEGORIES_QUERY from "../../../category/graphql/categories-query"
import { PathwrightContext } from "../../../pathwright/PathwrightContext"
import PUBLISHERS_QUERY from "../../../publisher/graphql/publishers-query"
import { flattenEdges } from "../../../utils/apollo"
import { withContextAsProp } from "../../../utils/component"
import BrowseFacets from "./BrowseFacets"

function BrowseFacetsContainer({
  authors,
  categories,
  publishers,
  activeFacets,
  onChangeFacets
}) {
  return (
    <BrowseFacets
      authors={authors}
      categories={categories}
      publishers={publishers}
      onChangeFacets={onChangeFacets}
      activeFacets={activeFacets || {}}
    />
  )
}

BrowseFacetsContainer.displayName = "BrowseFacetsContainer"

BrowseFacetsContainer.propTypes = {
  // active facets query variables
  activeFacets: PropTypes.object,
  // callback to change active facets
  onChangeFacets: PropTypes.func.isRequired,
  // scope to another school
  schoolId: PropTypes.number
}

export default compose(
  withContextAsProp(PathwrightContext, "schoolId", "school.id"),
  graphql(AUTHORS_QUERY, {
    options: ({ schoolId }) => ({
      variables: {
        school_id: schoolId,
        first: 50
      }
    }),
    props: ({ data }) => ({
      authors: flattenEdges(get(data, "context.school.authors"))
    })
  }),
  graphql(CATEGORIES_QUERY, {
    options: ({ schoolId }) => ({
      variables: {
        school_id: schoolId,
        first: 50
      }
    }),
    props: ({ data }) => ({
      categories: flattenEdges(get(data, "context.school.categories"))
    })
  }),
  graphql(PUBLISHERS_QUERY, {
    options: ({ schoolId }) => ({
      variables: {
        school_id: schoolId,
        first: 50
      }
    }),
    props: ({ data }) => ({
      publishers: flattenEdges(get(data, "context.school.publishers"))
    })
  })
)(BrowseFacetsContainer)
