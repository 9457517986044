import { Trans, useTranslation } from "react-i18next"
import { I18_DEFAULT_NAME_SPACE } from "./constants"
import { getTranslateMethods } from "./utils"

// We can handle updating the translate namespace later
export const useTranslate = () => {
  const { t, i18n, ready } = useTranslation(I18_DEFAULT_NAME_SPACE, {
    useSuspense: false
  })
  const translateMethods = getTranslateMethods(t)

  return {
    ...translateMethods,
    i18n,
    Trans,
    ready
  }
}

const withTranslate = (PassedComponent) => (props) => {
  const { ready, ...rest } = useTranslate()

  if (!ready) {
    return null
  }

  return <PassedComponent {...props} {...rest} />
}

export default withTranslate
