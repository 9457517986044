import { HStack } from "@chakra-ui/react"
import MentorGroupSeats from "../../mentor-group/members/list/MentorGroupSeats"
import InteractiveTooltip from "../shared/InteractiveTooltip"
import { useShareDataContext } from "../shared/ShareDataContextProvider"
import MetaButton from "./MetaButton"

function GroupMeta() {
  const { group } = useShareDataContext()

  return (
    <HStack>
      <MentorGroupSeats mentorGroup={group} py="1.5" px="4" size="md" />
      <InteractiveTooltip label="Groups are only visible to Group Admins & Mentors.">
        <MetaButton icon="avatar-lock" label="Private" />
      </InteractiveTooltip>
    </HStack>
  )
}

export default GroupMeta
