import { HStack } from "@chakra-ui/react"
import ReactSelectInput from "@pathwright/ui/src/components/form/form-select/ReactSelectInput"
import ReactSelectInputOption from "@pathwright/ui/src/components/form/form-select/ReactSelectInputOption"
import ReactSelectInputOptionLabel from "@pathwright/ui/src/components/form/form-select/ReactSelectInputOptionLabel"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import Text from "@pathwright/ui/src/components/ui/Text"
import PropTypes from "prop-types"
import styled from "styled-components"
import { ROLE_TYPE_HELP_ARTICLES } from "./constants"
import { getRoleOptions } from "./utils"

const StyledReactSelectInput = ReactSelectInput
const StyledReactSelectInput1 = styled(ReactSelectInput)`
  padding: 0;
  .Select-menu,
  .Select-menu-outer {
    max-height: 500px !important;
  }
`

const StyledRoleArrow = styled.span`
  position: relative;
  width: 25px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  &:before {
    margin-bottom: 4px;
    content: "";
    width: 0;
    height: 0;
    border-bottom: 5px solid #aaa;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  }
  &:after {
    content: "";
    width: 0;
    height: 0;
    border-top: 5px solid #aaa;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  }
`

const LearnMore = ({ roleType, options }) => {
  const { t } = useTranslate()
  const { url, label } = ROLE_TYPE_HELP_ARTICLES[roleType] || {}

  return url ? (
    <div style={{ padding: "10px" }}>
      <Text.Body>
        <a href={url} target="_blank">
          <HStack whiteSpace="pre">
            <Pathicon icon="external-link" />
            {label ? (
              <span>{t(label)}</span>
            ) : (
              <span>
                Learn more about{" "}
                {options.length == 1 ? "this role" : "these roles"}
              </span>
            )}
          </HStack>
        </a>
      </Text.Body>
    </div>
  ) : null
}

const Option = (props) => {
  const {
    value,
    icon,
    label,
    // label_plural,
    descriptor,
    roleType
  } = props.data

  if (value === null) {
    // HACK: inserting learn more link as last "option"
    return <LearnMore roleType={roleType} options={props.options} />
  }

  return (
    <ReactSelectInputOption
      {...props}
      icon={icon}
      label={label}
      descriptor={descriptor}
    />
  )
}

const OptionLabel = ({ option, prompt }) => <div>{prompt(option)}</div>

const SelectRole = ({ prompt, roleType, roleFilter, ...passProps }) => {
  const translateProps = useTranslate()
  const options = [
    ...getRoleOptions(roleType, roleFilter, translateProps),
    {
      value: null,
      roleType
    }
  ]

  return (
    <StyledReactSelectInput
      {...passProps}
      className="SelectRole"
      // TODO: react-select components: DownChevron?
      // arrowRenderer={props => <StyledRoleArrow {...props} />}
      value={options.find((option) => option.value === passProps.value)}
      options={options}
      components={{
        Option
      }}
      formatOptionLabel={(props) => (
        <OptionLabel option={props} prompt={prompt} />
      )}
      isSearchable={false}
    />
  )
}

SelectRole.propTypes = {
  prompt: PropTypes.func.isRequired
}

SelectRole.defaultProps = {
  prompt: ({ icon, label }) => (
    <ReactSelectInputOptionLabel icon={icon} label={label} />
  )
}

export default SelectRole
