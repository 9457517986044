import { Formik, FormikValues, useFormikContext } from "formik"
import { ReactNode } from "react"
import { toFormikValidate } from "zod-formik-adapter"
import { SchemaInputFieldProps } from "../../../../components/form/Field"
import PasswordInput from "../components/PasswordInput"
import {
  PasswordsSchema,
  passwordsSchema,
  refinePasswordsSchema
} from "./api/action"

const schema = refinePasswordsSchema(passwordsSchema)
const validate = toFormikValidate(schema)

export type PasswordsFormProps = {
  isSubmitting: boolean
  // Allow PasswordsForm to provide Formik context to children.
  children?: ReactNode
} & SchemaInputFieldProps<PasswordsSchema>

const PasswordsForm = ({
  isSubmitting,
  password1Config,
  password2Config,
  children
}: PasswordsFormProps) => {
  const formik = useFormikContext()

  // Handle updating updating upstream Formik context.
  const handleChange = (values: FormikValues) => {
    if (formik?.values) {
      formik.setValues(
        {
          ...formik.values,
          ...values
        },
        true
      )
    }
  }

  return (
    <Formik
      initialValues={{
        password1: "",
        password2: ""
      }}
      validate={(values) => {
        handleChange(values)
        return validate(values)
      }}
      onSubmit={() => {}} // noop
    >
      <>
        <PasswordInput
          id="password1"
          name="password1"
          placeholder="Enter new password (6+ characters)..."
          isDisabled={isSubmitting}
          {...password1Config}
        />
        <PasswordInput
          id="password2"
          name="password2"
          placeholder="Enter new password again..."
          isDisabled={isSubmitting}
          {...password2Config}
        />
        {children}
      </>
    </Formik>
  )
}

export default PasswordsForm
