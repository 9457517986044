import { withTranslate } from "@pathwright/ui/src/components/"
import LoadingBlock from "@pathwright/ui/src/components/loading/LoadingBlock"
import styled from "styled-components"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import BannersQuery from "../graphql/BannersQuery"
import BannerSlider from "./BannerSlider"

const LoadingBanner = styled(LoadingBlock)`
  width: 100%;
  height: 250px;
  background-size: 1400px;
`

const BannerSliderContainer = (props) => {
  const { school } = usePathwrightContext()
  const { t } = props

  const defaultBanner = {
    heading: t("library.store_labels.library"),
    subhead: t("library.browse.from_school", { school_name: school.name })
  }

  return (
    <BannersQuery>
      {({ loading, banners }) =>
        loading ? (
          <LoadingBanner />
        ) : (
          <BannerSlider
            {...props}
            banners={banners.length ? banners : [defaultBanner]}
          />
        )
      }
    </BannersQuery>
  )
}

BannerSliderContainer.displayName = "BannerSliderContainer"

export default withTranslate(BannerSliderContainer)
