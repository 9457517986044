import gql from "graphql-tag"
import SCHOOL_FRAGMENT from "./school-fragment"

export default gql`
  mutation (
    $consent_description: String
    $grant_access_upon_authentication: Boolean
  ) {
    updateSchool(
      consent_description: $consent_description
      grant_access_upon_authentication: $grant_access_upon_authentication
    ) {
      ...School
    }
  }
  ${SCHOOL_FRAGMENT}
`

/**
 * NOTE: since react-apollo is sending null values for any variable that is
 * not assigned a value, having to include variables dynamically. Otherwise, any variable
 * not assigned a value will post a null value for that field to our backend and that's not good.
 */

const mutationFields = {
  consent_description: "String",
  grant_access_upon_authentication: "Boolean"
}

export const getSchoolMutation = (fields) => gql`
  mutation(
    ${fields.map((field) => `$${field}: ${mutationFields[field]}\n`)}
  ) {
    updateSchool(
      ${fields.map((field) => `${field}: $${field}\n`)}
    ) {
      ...School
    }
  }
  ${SCHOOL_FRAGMENT}
`
