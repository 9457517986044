import ManageDiscounts from "@pathwright/web/src/modules/commerce/manage/discounts/ManageDiscounts"
import ManageResourceDiscountsForm from "@pathwright/web/src/modules/commerce/manage/discounts/ManageResourceDiscountsForm"
import ManageSubscriptionPlanDiscountsForm from "@pathwright/web/src/modules/commerce/manage/discounts/ManageSubscriptionPlanDiscountsForm"
import ManageCommerce from "@pathwright/web/src/modules/commerce/manage/ManageCommerce"
import ManageSubscriptions from "@pathwright/web/src/modules/commerce/manage/ManageSubscriptions"
import ManageStripe from "@pathwright/web/src/modules/commerce/manage/stripe/ManageStripe"
import { observer } from "mobx-react"
import store from "school/manage/school-manage-store"

const ManageCommerceContainer = observer(({ card }) => (
  <ManageCommerce
    card={card}
    stripe_activation_url={store.stripe_activation_url}
    stripe_access_token={store.commerce.stripe_access_token}
    loading={store.commerce_loading}
  />
))

const ManageStripeContainer = observer(({ card, location }) => (
  <ManageStripe
    card={card}
    disconnectStripe={() => {
      store.action
        // clear the stripe_access_token on the backend
        .disconnectStripe()
        .then(() => {
          card.onClose()
        })
    }}
    stripe_access_token={store.commerce.stripe_access_token}
    send_stripe_receipts={store.commerce.send_stripe_receipts}
    stripe_reconnect_url={store.stripe_activation_url}
    stripeConnectError={location.query.error}
  />
))

const ManageDiscountsContainer = observer(({ card }) => (
  <ManageDiscounts
    card={card}
    stripe_activation_url={store.stripe_activation_url}
  />
))

const ManageSubscriptionsContainer = observer(({ card }) => (
  <ManageSubscriptions card={card} subscription_plans={null} />
))

export default {
  commerce: {
    component: ManageCommerceContainer,
    middleware: store.action.loadCommerce,
    childCards: {
      stripe: {
        component: ManageStripeContainer
      },
      "discount-codes": {
        component: ManageDiscounts,
        // middleware: store.action.loadDiscounts
        childCards: {
          resources: {
            component: ManageResourceDiscountsForm
          },
          subscriptions: {
            component: ManageSubscriptionPlanDiscountsForm
          }
        }
      },
      "subscription-plans": {
        component: ManageSubscriptionsContainer
        // middleware: store.action.loadSubscriptionPlans
      }
    }
  }
}
