import AvatarStack from "@pathwright/ui/src/components/avatar/AvatarStack"
import ProgressBar from "@pathwright/ui/src/components/progress/ProgressBar"
import { sizePropType } from "@pathwright/ui/src/components/utils/props"
import { WithCSS } from "@pathwright/ui/src/components/utils/styles"
import memoize from "lodash/memoize"
import PropTypes from "prop-types"

// NOTE: this is HIGHLY inaccurate (or at least undependable for accuracy) as the items are ordered lexicographically and not as they appear on the path!
// in order to workaround this, we'd have to load a copy of the path and determine ordering by matching sourc IDs. Likely not feasible.
const mapAvatarsToSlots = memoize((id, { items, path }) => {
  const totalHere = path.complete + path.incomplete
  if (!items || !totalHere) return []

  // merging path into items
  const mergedItems = Object.keys(items).reduce(
    (mergedItems, key) => [
      ...mergedItems,
      {
        ...items[key],
        id: parseInt(key)
      }
    ],
    []
  )

  // adding path at end
  mergedItems.push({ ...path, next_count: Infinity })
  // cache already added users so we don't have duplicates due to user showing up in, say, a lesson item and step item
  const alreadyUsed = {}

  return mergedItems.reduce((slots, item, i, keys) => {
    const { next_count, students_here } = item
    const nextSlot = []
    nextSlot._itemId = item.id

    if (next_count && students_here) {
      const ratioHere = next_count / totalHere
      const avatarCount = Math.round(ratioHere * keys.length)
      const usersHere = students_here.slice(0, avatarCount)

      for (let i = 0; i < usersHere.length; i++) {
        const user = usersHere[i]

        // check if we've already used this user
        if (alreadyUsed[user.id]) {
          // attempt to move the already used user to a different slot IF the item for this slot has a higher ID (will possibly advance the user to appropriate slot)
          const slotIndex = slots.findIndex((slot) =>
            slot.find((u) => u.id === user.id)
          )
          const userIndex = slots[slotIndex].findIndex((u) => u.id === user.id)
          const slot = slots[slotIndex]
          const slotItem = mergedItems.find((item) => item.id === slot._itemId)

          if (item.id > slotItem.id) {
            // remove user from other slot
            slot.splice(userIndex, 1)
            // add user to this slot
            nextSlot.push(user)
          }
        } else {
          // not duped, simply add user
          nextSlot.push(user)
        }

        alreadyUsed[user.id] = true
      }
    }

    return [...slots, nextSlot]
  }, [])
})

// derive the path completion rate from the overall completion rate of all path items (vs. the path.completion_rate which only concerns completed paths)
const getPathCompletionRate = memoize((id, { items, path }) => {
  if (!items) return 0

  // items is an object
  const itemsArr = Object.values(items)

  const [complete, incomplete] = itemsArr.reduce(
    ([complete, incomplete], item) => [
      complete + (item.complete || 0),
      incomplete + (item.incomplete || 0)
    ],
    [0, 0]
  )

  return complete / (complete + incomplete)
})

const PathProgress = ({ analytics, avatarSize, inverted }) => {
  const avatarLimit = 4
  const avatarSizeObj = sizePropType._serialize(avatarSize)
  const avatarSizeDoubledObj = sizePropType._serialize({
    ...avatarSizeObj,
    number: avatarSizeObj.number * 2
  })

  if (!analytics) {
    return (
      <WithCSS
        css={styles.container}
        style={{
          height: `calc(${avatarSizeDoubledObj.style} + 2px)`,
          padding: `0 ${avatarSizeObj.style}`
        }}
      >
        <WithCSS
          as={ProgressBar}
          css={styles.progressBar}
          style={{ top: `calc(${avatarSizeObj.style} + 1px` }}
          progress={0}
          fillColor="#7ED321"
          inverted={inverted}
          height={2}
        />
      </WithCSS>
    )
  }

  // in some cases, item analytics data is wildly inaccurate, and could lead to a wildly inaccurate progress value
  // so, ensuring we arrive at no more than 1 and the greater of path.completion_rate and the dreived completion rate from items
  if (!analytics.path) return null

  const progress = Math.min(
    1,
    Math.max(
      analytics.path.completion_rate,
      getPathCompletionRate(analytics.path.id, analytics)
    )
  )

  return (
    <WithCSS
      css={styles.container}
      style={{
        height: `calc(${avatarSizeDoubledObj.style} + 2px)`,
        padding: `0 ${avatarSizeObj.style}`
      }}
    >
      <WithCSS
        as={ProgressBar}
        css={styles.progressBar}
        style={{ top: `calc(${avatarSizeObj.style} + 1px` }}
        progress={progress}
        fillColor="#7ED321"
        height={2}
      />
      {mapAvatarsToSlots(analytics.path.id, analytics).map(
        (avatars, index, items) =>
          !!avatars.length && (
            <WithCSS
              css={styles.item}
              key={index}
              style={getSlotPositionStyle(index, items.length - 1, avatarSize)}
            >
              <AvatarStack
                limit={avatarLimit}
                users={avatars}
                size={avatarSize}
                itemOffset={{
                  ...avatarSizeObj,
                  number:
                    avatarSizeObj.number *
                    (1 / avatarLimit) *
                    -(avatarLimit - 1)
                }}
                reverse
              />
            </WithCSS>
          )
      )}
    </WithCSS>
  )
}

PathProgress.propTypes = {
  analytics: PropTypes.object,
  avatarSize: sizePropType
}

PathProgress.defaultProps = {
  avatarSize: "1em"
}

PathProgress.displayName = "PathProgress"

export default PathProgress

// fill slots with avatars, adjusted to show learner placement
// on path with maximum of 20 avatar positions.
const getSlotPositionStyle = (slotPosition, slotsCount, avatarSize) => {
  return {
    zIndex: 100 - slotPosition,
    top: slotPosition % 2 ? 0 : "auto",
    bottom: slotPosition % 2 ? "auto" : 0,
    marginLeft: `calc(${(slotPosition / slotsCount) * 100}% - ${
      slotPosition ? 2 : 1
    } * ${sizePropType._serialize(avatarSize).style})`
  }
}

const styles = {
  container: `
    border-radius: 4px;
    position: relative;
    display: flex;
    align-items: center;
  `,
  item: `
    position: absolute;
    .Avatar {
      border: none;
    }
  `,
  progressBar: `
    position: absolute;
    left: 0;
    right: 0;
    border-radius: 4px;
  `
}
