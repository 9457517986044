import gql from "graphql-tag"
import CURRICULUM_SUBSCRIPTION_PLAN_FRAGMENT from "./curriculum-subscription-plan-fragment"

export default gql`
  query CurriculumSubscriptionPlansQuery(
    $visibilityCode: String
    $visibilityCodeFilter: StringFilter
  ) {
    school {
      id
      curriculum_plans(
        first: 50
        visibility_code: $visibilityCode
        visibility_code_filter: $visibilityCodeFilter
      ) {
        edges {
          node {
            ...SchoolCurriculumPlan
          }
        }
      }
    }
  }
  ${CURRICULUM_SUBSCRIPTION_PLAN_FRAGMENT}
`
