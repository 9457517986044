import classnames from "classnames"
import isFunction from "lodash/isFunction"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import SubmitHandler, { SUBMIT_STATUS } from "../submit/SubmitHandler"
import SubmitSizer from "../submit/SubmitSizer"
import SubmitStatus from "../submit/SubmitStatus"
import { DANGEROUS_RED } from "../utils/colors"
import Button from "./Button"

// fixes at least one case where the > span was vertically off-center
// TODO: can this style be added to the Button component itself?
const StyledButton = styled(Button)`
  > span {
    height: 100%;
  }
`

const SubmitButton = React.forwardRef(function SubmitButton(
  {
    onClick,
    onSubmit,
    hideSuccess,
    duration,
    submitting,
    submitSuccess,
    submitFailure,
    onSubmitFailure,
    onSubmitSuccess,
    onSubmitProcessing,
    resetStatus,
    showStatusLabel,
    inverted,
    children,
    buttonRef,
    render,
    ...passProps
  },
  ref
) {
  return (
    <SubmitSizer
      className="SubmitStatusButton"
      hideSuccess={hideSuccess}
      duration={duration}
      onClick={onClick}
      onSubmit={onSubmit}
      submitting={submitting}
      submitSuccess={submitSuccess}
      submitFailure={submitFailure}
      onSubmitFailure={onSubmitFailure}
      onSubmitSuccess={onSubmitSuccess}
      onSubmitProcessing={onSubmitProcessing}
      resetStatus={resetStatus}
      render={render}
    >
      {({ status, handleSubmit, style: statusStyle, size, sizerRef }) => {
        const className = classnames(
          "SubmitButton",
          `SubmitButton--${status}`,
          passProps.className
        )

        const style = {
          ...(statusStyle || {}),
          ...(passProps.style || {})
        }

        const handleRef = (ref, refProp) => {
          if (isFunction(refProp)) {
            refProp(ref)
          } else if (refProp && typeof refProp.current !== "undefined") {
            refProp.current = ref
          }
        }

        const assignRef = (_ref) => {
          handleRef(_ref, ref)
          handleRef(_ref, sizerRef)
          // handle buttonRef (only used in StripeElementsForm, likely due to ref being eaten by the Submit component)
          handleRef(_ref, buttonRef)
        }

        // fade out the button background if we're showing a status label or showing loading status
        const fade =
          (!!showStatusLabel && status !== SUBMIT_STATUS.DEFAULT) ||
          status === SUBMIT_STATUS.LOADING
        const color =
          status === SUBMIT_STATUS.FAILURE ? DANGEROUS_RED : passProps.color
        const onClick = status === SUBMIT_STATUS.DEFAULT ? handleSubmit : null

        return (
          <StyledButton
            {...passProps}
            className={className}
            ref={assignRef}
            style={style}
            color={color}
            fade={fade}
            inverted={inverted}
            onClick={onClick}
          >
            {(props) => (
              <SubmitStatus
                status={status}
                inverted={inverted}
                spinnerColor={props.backgroundColor}
                iconColor={showStatusLabel ? props.backgroundColor : null}
                size={size}
                showLabel={showStatusLabel}
              >
                {typeof children === "function"
                  ? children({
                      ...props,
                      status
                    })
                  : props.renderChildren(children)}
              </SubmitStatus>
            )}
          </StyledButton>
        )
      }}
    </SubmitSizer>
  )
})

SubmitButton.displayName = "SubmitButton"

SubmitButton.propTypes = {
  duration: PropTypes.number,
  hideSuccess: PropTypes.bool,
  onClick: PropTypes.func, // used interchangeably with onSubmit
  onSubmit: PropTypes.func,
  showStatusLabel: SubmitStatus.propTypes.showLabel, // show the SubmitStatus label
  resetStatus: SubmitHandler.propTypes.resetStatus // reset the SubmitHandler status
}

SubmitButton.defaultProps = {
  duration: 1000,
  type: "submit",
  brand: true
}

export default SubmitButton
