import BlankImage from "@pathwright/ui/src/components/blank/BlankImage"
import seedrandom from "seedrandom"
import styled from "styled-components"
import { usePathwrightContext } from "../pathwright/PathwrightContext"
import getBlanks from "./blanks"
import { LEARNING_ACTION_FILTER_NONE } from "./constants"
import { ActivityColumnDivider, ActivityItem } from "./ui"

const StyledBlankList = styled(ActivityColumnDivider)`
  list-style-type: none;
  position: relative;
  max-width: 1000px;
  width: 100%;
  min-height: 280px;
  margin: 0 auto;
  padding: 30px 20px;
  color: white;
`

const StyledBlankWho = styled(BlankImage)`
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  margin-right: 15px;
`

const StyledBlankWhat = styled.div`
  width: 50%;
  flex-grow: 1;
  align-self: center;
`

const StyledHeading = styled.h4`
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  font-family: "Kalam";
`

const StyledContent = styled.div`
  margin-top: 10px;
  padding: 10px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  font-family: "Kalam";
`

const ActivityBlankEntry = ({
  columnSide,
  name,
  avatar,
  content,
  description
}) => {
  return (
    <ActivityItem as="li" columnSide={columnSide}>
      <StyledBlankWho image={`avatar${avatar}`} inverted />
      <StyledBlankWhat>
        <StyledHeading>
          {name} {description}
        </StyledHeading>
        {content && <StyledContent>{content}</StyledContent>}
      </StyledBlankWhat>
    </ActivityItem>
  )
}

const ActivityBlank = ({ activity_user, actionType }) => {
  const { me, school } = usePathwrightContext()

  if (me) {
    const blanks = getBlanks({
      userName: me.first_name,
      accountName: school.name
    })
    const random = seedrandom(actionType)
    return (
      <StyledBlankList as="ul">
        {blanks
          .filter(
            (blank) =>
              actionType === LEARNING_ACTION_FILTER_NONE ||
              blank.type === actionType
          )
          .slice(0, 3)
          .map((blank, index) => (
            <ActivityBlankEntry
              key={index}
              columnSide={index % 2 ? "right" : "left"}
              avatar={activity_user ? 2 : Math.round(random(index) * 2) + 1}
              name={activity_user ? activity_user.first_name : blank.name}
              description={blank.description}
              content={blank.content}
            />
          ))}
      </StyledBlankList>
    )
  }

  return null
}

ActivityBlank.displayName = "ActivityBlank"

export default ActivityBlank
