import { StackProps, VStack } from "@chakra-ui/react"

export type AuthWrapperProps = StackProps

const AuthWrapper = (props: AuthWrapperProps) => {
  let className = "AuthWrapper"
  if (props.className) className += ` ${props.className}`

  return (
    <VStack
      rounded="2xl"
      p={[6, 8]}
      minW="min(500px, 100%)"
      {...props}
      className={className}
    />
  )
}

export default AuthWrapper
