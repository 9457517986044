import { HStack, Spacer, VStack } from "@chakra-ui/react"
import Link from "../../../../components/link/Link"
import { useScopedTranslation } from "../../../../lib/lng/TranslationsProvider"
import { ActionType } from "../../../../lib/types"
import { useAuthContext } from "../../api/context"
import AuthRequestForm from "../../auth-request/AuthRequestForm"
import AuthFormIntentErrorAlert from "../../components/AuthFormIntentErrorAlert"
import AuthMotion from "../../components/AuthMotion"
import AuthOptionsDivider from "../../components/AuthOptionsDivider"
import AuthSpaceHeader from "../../components/AuthSpaceHeader"
import ExternalAuthMethods from "../../components/ExternalAuthMethods"
import { AuthIntentKeyWithError } from "../../utils/authIntent"
import { useAuthMethods } from "../../utils/authMethods"
import getAuthRoute from "../../utils/getAuthRoute"
import useColorModeBranding from "../../utils/useColorModeBranding"
import { SignInAuthRequestLoaderReturn } from "./api/loader"

const authIntentErrorKey: AuthIntentKeyWithError = "sign_in:without_password"

export type SignInAuthRequestViewProps = ActionType &
  SignInAuthRequestLoaderReturn

const SignInAuthRequestView = ({
  showPasswordOption,
  error,
  loading,
  onSubmit
}: SignInAuthRequestViewProps) => {
  const { t } = useScopedTranslation("auth.signin_request_view")
  const { allowSignup, userPropertyDefaults } = useAuthContext()
  const { externalAuthMethods } = useAuthMethods()
  const colorScheme = useColorModeBranding()

  return (
    <AuthMotion>
      <VStack spacing={4} width="100%">
        <AuthSpaceHeader />
        <AuthFormIntentErrorAlert authIntentKey={authIntentErrorKey} />
        <AuthRequestForm
          userPropertyDefaults={userPropertyDefaults}
          error={error}
          loading={loading}
          onSubmit={onSubmit}
        />
        {(showPasswordOption ||
          !!externalAuthMethods.length ||
          allowSignup) && <AuthOptionsDivider />}
        {showPasswordOption && (
          <Link
            variant="solid"
            colorScheme={colorScheme}
            width="full"
            to={getAuthRoute("../password")}
          >
            {t("sign_in_with_password")}
          </Link>
        )}
        {!!externalAuthMethods.length && (
          <ExternalAuthMethods authIntent={authIntentErrorKey} />
        )}
        {!!externalAuthMethods.length && allowSignup && <Spacer />}
        {allowSignup && (
          <HStack justifyContent="center">
            <Link
              colorScheme={colorScheme}
              textDecor="underline"
              whiteSpace="normal"
              display="inline"
              to={getAuthRoute("../../sign-up")}
            >
              {t("create_account")}
            </Link>
          </HStack>
        )}
      </VStack>
    </AuthMotion>
  )
}

export default SignInAuthRequestView
