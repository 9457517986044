import gql from "graphql-tag"
import SCHOOL_SUBSCRIPTION_FRAGMENT from "../../subscription/graphql/school-subscription-fragment"

export default gql`
  query UserSchoolSubscriptionQuery {
    me {
      id
      school_subscription {
        ...SchoolSubscription
      }
    }
  }
  ${SCHOOL_SUBSCRIPTION_FRAGMENT}
`
