import PropTypes from "prop-types"
import { useEffect, useRef } from "react"
import styled from "styled-components"
import Button from "../button/Button"
import useScrollToNode from "../hooks/useScrollToNode"
import { useTranslate } from "../lng/withTranslate"
import Text from "../ui/Text"
import withConfirm from "./withConfirm"

const StyledInlineConfirmOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10001;
  border-radius: inherit;
`

const StyledInlineConfirm = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  pointer-events: auto;
  ${(p) => `opacity: ${p.$loading ? 0.5 : 1};`}

  ${(props) =>
    props.$stack
      ? `
    flex-direction: column;
    justify-content: center;
  `
      : ""}

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;

    .Button + .Button {
      margin-left: 10px;
    }
  }
`

// Wrap this around content that will be overlayed by a confirmation
const InlineConfirm = withConfirm(
  ({ stack, primaryAction, secondaryAction, loading, heading, overlay }) => {
    const { tc } = useTranslate()
    const secondaryActionRef = useRef(secondaryAction)
    const setScrollNode = useScrollToNode()

    // HACK ALERT: keeping ref to last truthy secondaryAction
    useEffect(() => {
      if (secondaryAction) secondaryActionRef.current = secondaryAction
    }, [secondaryAction])

    const confirm = (
      <StyledInlineConfirm
        ref={setScrollNode}
        className="InlineConfirm"
        $stack={stack}
        $loading={loading}
      >
        <Text.Body>{heading || tc("are you sure?")}</Text.Body>
        <div>
          <Button {...primaryAction} styleType="text" dangerous>
            <strong>{primaryAction.children}</strong>
          </Button>
          <Button {...secondaryActionRef.current} styleType="text">
            <strong>{secondaryActionRef.current.children}</strong>
          </Button>
        </div>
      </StyledInlineConfirm>
    )

    if (overlay) {
      return (
        <StyledInlineConfirmOverlay onClick={(e) => e.stopPropagation()}>
          {confirm}
        </StyledInlineConfirmOverlay>
      )
    }

    return confirm
  }
)

InlineConfirm.propTypes = {
  stack: PropTypes.bool
}

InlineConfirm.defaultProps = {
  stack: false
}

const InlineConfirmContainer = ({ children, overlay, ...passProps }) => (
  <InlineConfirm
    hideSuccess // default to hide success
    overlay={overlay}
    {...passProps}
    children={({ confirming, ...passProps }) =>
      confirming && !overlay ? null : children({ confirming, ...passProps })
    }
  />
)

InlineConfirmContainer.defaultProps = {
  overlay: false
}

export default InlineConfirmContainer
