import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import { useObserveSize } from "@pathwright/ui/src/components/observers/ObserveSizeContext"
import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import defaults from "lodash/defaults"
import { useEffect } from "react"
import styled from "styled-components"
import DiscussionInput from "../../discussion/DiscussionInput"
import DiscussionProvider, {
  useDiscussionContext
} from "../../discussion/DiscussionProvider"
import DiscussionResponses from "../../discussion/DiscussionResponses"
import DISCUSSION_QUERY from "../../discussion/graphql/discussion-query"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import { useReviewContext } from "../state/ReviewContextProvider"
import { useUserAskedToRespondNotificationsForItem } from "../utils"
import { updateFeedbackDiscussionNumResponses } from "./discuss/utils"

const InboxDiscussionContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  /* overflow: hidden; */
  flex: 1;
  width: 100%;
  height: 100%;
  header {
    display: flex;
    align-items: center;
    padding: 10px;
    h1 {
      font-size: 1.5em;
      font-weight: bold;
      margin: 0px;
    }
  }

  .discussion-responses {
    overflow: hidden;
    overflow-y: auto;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;

    > ul {
      display: flex;
      /* Reverses the responses list so that the items are visually
      ordered from oldest to newest AND results in the .discussion-responses 
      container being intially scrolled to bottom, no JS needed!
      https://stackoverflow.com/a/44051405/6362803 */
      flex-direction: column-reverse;
    }

    > .BlankSlate {
      /* margin: auto !important; */
      margin-bottom: auto;
    }

    .DiscussionResponse {
      max-width: 100%;
    }
  }

  .disussion-input {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .ql-editor {
      max-height: 128px;
      overflow: auto !important;
    }
  }
`

// Derives the discussion contextInput either from props or
// current review context.
const useDiscussionContextInput = (contextInput) => {
  const reviewContext = useReviewContext()
  const step = reviewContext.selected ? reviewContext.selected.data : null
  const reviewNoteContext = step
    ? {
        completion_id: step.id,
        resource_id: step.context.resource_id,
        cohort_id: step.context.cohort_id
      }
    : null

  return defaults(contextInput, reviewNoteContext)
}

const useEventListener = (event, handler, node) => {
  useEffect(() => {
    const target = node || document
    target.addEventListener(event, handler)
    return () => target.removeEventListener(event, handler)
  }, [event, handler, node])
}

export const useInboxDiscussion = (discussionId) => {
  const context = useDiscussionContextInput()
  const query = useQuery(DISCUSSION_QUERY, {
    variables: {
      id: discussionId,
      context: context,
      orderResponsesBy: "-created_time"
    }
  })

  useEventListener("notification:received", async (e) => {
    const { action, context_data } = e.detail.data

    if (context_data) {
      const { discussion, response } = context_data
      if (response && parseInt(discussion) === query.data.discussion.id) {
        // What about pagination?
        const result = await query.refetch()
        if (result?.data) {
          updateFeedbackDiscussionNumResponses(result?.data.discussion)
        }
        // Update num resposnes.
        // if (["replied to", "answered"].includes(action)) {
        //   this._mutate({ new_responses_available: true })
        //   return this.storeTrigger("response:received", response)
        // }
      }
    }
  })

  const { notifications } = useUserAskedToRespondNotificationsForItem(
    context.completion_id
  )
  // HACK ALERT
  query.askToAnswerNotification = notifications?.[0]

  return query
}

const InboxDiscussionBlankSlate = () => {
  const { t } = useTranslate()
  const { me } = usePathwrightContext()
  const { data: step } = useReviewContext().selected

  return (
    <BlankSlate
      heading={t("No discussion so far.")}
      body={
        me.id === step.completion.user.id
          ? t("Share private conversation around your completion.")
          : t("Share private conversation around {{ user }}'s completion.", {
              user: step.completion.user.first_name
            })
      }
    />
  )
}

const InboxDiscussionInput = () => {
  const { t } = useTranslate()
  const { me } = usePathwrightContext()
  const { data: step } = useReviewContext().selected
  const { postLoading, post } = useDiscussionContext()

  // TODO: this currently only function to prevent intializing the form state storage
  // for the discussion input with an incorrect key (missing post id). Rather need to
  // optimize form state storage for handling the storage key change, but there are some
  // moving parts in other branches related to how the storage key is handled, so postponing
  // the better fix.
  if (postLoading && post?.step_id !== step.id) return null

  return (
    <DiscussionInput
      title={t("completion.feedback.panel.feedback_for_step", {
        step: step.name
      }).slice(0, 200)} // limit to 200 chars to avoid hitting backend char limit of 255 (sanitization may add chars when escaping value on the backend)
      placeholder={
        t("Discuss...")
        // me.id === step.completion.user.id
        //   ? t("Discuss your completion.")
        //   : t("Discuss {{ user }}'s completion.", {
        //       user: step.completion.user.first_name
        //     })
      }
    />
  )
}

const InboxDiscussion = ({ selectedItem, setSelectedItem }) => {
  const { selected } = useReviewContext()
  // const { updateItemData } = useNotificationsState()
  const discussionContainerSize = useObserveSize()
  const discussionInputSize = useObserveSize()
  // Deriving the max height the discussion responses list should reach
  // based on the diff of the container height and the discussion input height.
  // The UI is complex and fitting both the discussion responoses and the
  // discussion input within the container without overflowing it seems to require
  // some JS finagling, though maybe a CSS-only solution is within reach (couldn't find it).
  const maxDiscussionResponsesHeight =
    discussionContainerSize.rectValue.height -
    discussionInputSize.rectValue.height

  const discussionContext = useDiscussionContextInput()

  const handleDiscussionEvent = (event, data) => {
    if (data && data.user_last_responded_time !== undefined) {
      // TODO: update last_responded_time
      // updateItemData(
      //   { last_responded_time: data.user_last_responded_time },
      //   n => {
      //     return getItemContextData(n) === stepId
      //   }
      // )
    }

    switch (event) {
      case "discussion-loaded":
        if (data) updateFeedbackDiscussionNumResponses(data)
        break
    }
  }

  const { notifications, clearAll } =
    useUserAskedToRespondNotificationsForItem(selected)

  function onDiscussionCreated(discussion) {
    updateFeedbackDiscussionNumResponses(discussion)
  }

  function onDiscussionResponseCreated(discussion) {
    clearAll(notifications)
    updateFeedbackDiscussionNumResponses(discussion, (v) => v + 1)
  }

  function onDiscussionResponseDeleted(discussion) {
    updateFeedbackDiscussionNumResponses(discussion, (v) => v - 1)
  }

  return (
    <InboxDiscussionContainer
      ref={discussionContainerSize.setNode}
      maxDiscussionResponsesHeight={maxDiscussionResponsesHeight}
    >
      <DiscussionProvider
        style={{ height: "100%" }}
        context={discussionContext}
        orderResponsesBy="-created_time"
        response_id={selectedItem}
        insertNewResponseEdge={(edges, edge) => [edge, ...edges]}
        // initialResponse={getInitialResponse(props.completion)}
        onViewResponse={(response) => setSelectedItem(response.id)}
        onViewAllResponses={() => setSelectedItem(null)}
        onDiscussionCreated={onDiscussionCreated}
        onDiscussionResponseCreated={onDiscussionResponseCreated}
        onDiscussionResponseDeleted={onDiscussionResponseDeleted}
        onDiscussionEvent={handleDiscussionEvent}
      >
        <div className="discussion-responses">
          <DiscussionResponses>
            <InboxDiscussionBlankSlate />
          </DiscussionResponses>
        </div>
        <div className="disussion-input" ref={discussionInputSize.setNode}>
          <InboxDiscussionInput />
        </div>
      </DiscussionProvider>
    </InboxDiscussionContainer>
  )
}

export default InboxDiscussion
