import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import { HELP_LINK_DISCOUNTS } from "../../../help/constants"
import FeatureLock from "../../../lib/FeatureLock"

const ManageDiscountsFeatureLock = ({ children }) => (
  <FeatureLock has="sell-courses-with-commerce">
    {({ hasFeatures, lockedFeatures, getPlansNameList, activationLocked }) =>
      hasFeatures ? (
        children
      ) : (
        <BlankSlate
          icon="tag-dollar-sign"
          heading="Attract new learners"
          body={
            <span>
              Add discount codes to attract new learners, incentivize partners,
              or grant free access to a lucky few.{" "}
              <a href={HELP_LINK_DISCOUNTS} target="_blank">
                Learn more about using discount codes to increase your sales{" "}
                <Pathicon icon="chevron-right" />
              </a>
            </span>
          }
          meta={`Included in the ${getPlansNameList(
            lockedFeatures["sell-courses-with-commerce"]
          )}`}
          // TODO: route for if featureLocked (i.e. /billing/upgrage/)?
          primaryAction={{
            children: activationLocked ? "Activate" : "Upgrade Plan",
            to: "/pricing/plan/"
          }}
        />
      )
    }
  </FeatureLock>
)

ManageDiscountsFeatureLock.displayName = "ManageDiscountsFeatureLock"

export default ManageDiscountsFeatureLock
