import useDidMountEffect from "@pathwright/ui/src/components/hooks/useDidMountEffect"
import union from "lodash/union"
import { useEffect, useReducer, useState } from "react"

// TODO: generalize for use with selecting other types of objects
export const useSelectMultiplePickerItems = () => {
  // handle filtering selected pickerItems
  const [hoistedSelectedPickerItemIds, setHoistedSelectedPickerItemIds] =
    useState([])
  const [filteringSelectedPickerItems, setFilteringSelectedPickerItems] =
    useState(false)

  const useUpdateSelectedPickerItems = ({
    pickerItemIds,
    selectedPickerItemIdsProp,
    search
  }) => {
    const [
      { selectingAllPickerItems, selectedPickerItemIds },
      updateMultiplePickerItems
    ] = useReducer(
      (state, { type, pickerItemId, pickerItemIds }) => {
        switch (type) {
          case "select-all":
            return {
              selectedPickerItemIds: pickerItemIds,
              excludedPickerItemIds: [],
              selectingAllPickerItems: true
            }
          case "unselect-all":
            return {
              selectedPickerItemIds: [],
              excludedPickerItemIds: [],
              selectingAllPickerItems: false
            }
          case "clear-selecting-all":
            return {
              ...state,
              selectingAllPickerItems: false
            }
          case "select":
            if (pickerItemIds) {
              return {
                ...state,
                selectedPickerItemIds: union(
                  pickerItemIds.filter(
                    (id) => !state.excludedPickerItemIds.includes(id)
                  ),
                  state.selectedPickerItemIds
                )
              }
            } else if (!state.selectedPickerItemIds.includes(pickerItemId)) {
              return {
                ...state,
                excludedPickerItemIds: state.selectingAllPickerItems
                  ? state.excludedPickerItemIds.filter(
                      (id) => pickerItemId !== id
                    )
                  : state.excludedPickerItemIds,
                selectedPickerItemIds: [
                  ...state.selectedPickerItemIds,
                  pickerItemId
                ]
              }
            }
          case "unselect":
            const index = state.selectedPickerItemIds.indexOf(pickerItemId)
            if (index > -1) {
              return {
                ...state,
                excludedPickerItemIds: state.selectingAllPickerItems
                  ? [...state.excludedPickerItemIds, pickerItemId]
                  : [],
                selectedPickerItemIds: state.selectedPickerItemIds.filter(
                  (id) => pickerItemId !== id
                )
              }
            }
        }
        return state
      },
      {
        selectingAllPickerItems: null,
        selectedPickerItemIds: [],
        excludedPickerItemIds: []
      }
    )

    // select upstream pickerItems - for controlled state
    useEffect(() => {
      if (
        selectedPickerItemIdsProp &&
        selectedPickerItemIdsProp.length !== selectedPickerItemIds.length
      )
        updateMultiplePickerItems({
          type: "select",
          pickerItemIds: selectedPickerItemIdsProp
        })
    }, [selectedPickerItemIdsProp && selectedPickerItemIdsProp.length])

    useDidMountEffect(() => {
      // search has changed, let's make sure we're no longer selecting all
      updateMultiplePickerItems({
        type: "clear-selecting-all"
      })
    }, [search])

    // if selecting all and more pickerItems have been added, select them as well
    useDidMountEffect(() => {
      if (selectingAllPickerItems) {
        updateMultiplePickerItems({
          type: "select",
          pickerItemIds
        })
      }
    }, [pickerItemIds && pickerItemIds.length])

    // handle selecting and unselecting all
    const handleSelectAllPickerItems = (selectAll) => {
      if (selectAll) {
        updateMultiplePickerItems({
          type: "select-all",
          pickerItemIds
        })
      } else {
        updateMultiplePickerItems({
          type: "unselect-all"
        })
      }
    }

    // select pickerItem
    const handleSelectPickerItem = (pickerItemId) => {
      updateMultiplePickerItems({
        type: "select",
        pickerItemId
      })
    }

    // unselect pickerItem
    const handleUnselectPickerItem = (pickerItemId) => {
      updateMultiplePickerItems({
        type: "unselect",
        pickerItemId
      })
    }

    // hoist selectedPickerItemIds in order to pass them down to the library pickerItems query when filtering by selected pickerItems
    useEffect(() => {
      setHoistedSelectedPickerItemIds(selectedPickerItemIds)
    }, [selectedPickerItemIds.length])

    // clearing all selected also clears filter
    useEffect(() => {
      if (
        !selectingAllPickerItems &&
        hoistedSelectedPickerItemIds.length !== selectedPickerItemIds.length
      ) {
        setFilteringSelectedPickerItems(false)
      }
    }, [selectingAllPickerItems])

    return {
      selectedPickerItemIds,
      handleSelectPickerItem,
      handleUnselectPickerItem,
      selectingAllPickerItems,
      handleSelectAllPickerItems,
      filteringSelectedPickerItems
    }
  }

  return {
    hoistedSelectedPickerItemIds, // for passing to query if filtering by selected pickerItems
    setFilteringSelectedPickerItems,
    filteringSelectedPickerItems,
    useUpdateSelectedPickerItems
  }
}
