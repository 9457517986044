// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
let ChangePassword
export default ChangePassword = class ChangePassword extends (
  require("lib/static-shim").default(require("lib/utils/model").default)
) {
  url() {
    return this.urlRoot(`/api/private/user/password/`)
  }

  defaults() {
    return {
      current_password: "",
      password1: "",
      password2: ""
    }
  }
}
