import gql from "graphql-tag"

export default gql`
  mutation (
    $offerings: [Int]!
    $assign_to_user: Int!
    $role: RegistrationRoleEnum!
    $send_notification: Boolean = true
    $assignment_message: String
  ) {
    assign(
      offerings: $offerings
      assign_to_user: $assign_to_user
      role: $role
      send_notification: $send_notification
      assignment_message: $assignment_message
    )
  }
`
