import React from "react"
import ReactDOM from "react-dom"
import { executeScripts } from "./utils"

class ExecuteScripts extends React.Component {
  componentDidMount() {
    const el = ReactDOM.findDOMNode(this)
    return executeScripts(el)
  }

  render() {
    const { html } = this.props
    return <div dangerouslySetInnerHTML={{ __html: html }} />
  }
}

ExecuteScripts.displayName = "ExecuteScripts"

export default ExecuteScripts
