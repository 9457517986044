// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
var SocialShareLinkGenerator = (function () {
  let generateSocialUrls = undefined
  let generateUrl = undefined
  let cleanUrl = undefined
  let doPopup = undefined
  SocialShareLinkGenerator = class SocialShareLinkGenerator {
    static initClass() {
      this.networks = [
        {
          name: "Facebook",
          class: "facebook",
          url: "http://www.facebook.com/sharer.php?s=100&p[url]={url}&p[images][0]={img}&p[title]={title}&p[summary]={desc}"
        },
        {
          name: "Facebook (share dialog)",
          class: "facebook",
          url: "https://www.facebook.com/dialog/share?app_id={app_id}&display=page&href={url}&redirect_uri={redirect_url}"
        },
        {
          name: "Twitter",
          class: "twitter",
          url: "https://twitter.com/share?url={url}&text={title}&via={via}&hashtags={hashtags}"
        },
        {
          name: "Google+",
          class: "google",
          url: "https://plus.google.com/share?url={url}"
        },
        {
          name: "Pinterest",
          class: "pinterest",
          url: "https://pinterest.com/pin/create/bookmarklet/?media={img}&url={url}&is_video={is_video}&description={title}"
        },
        {
          name: "Linked In",
          class: "linkedin",
          url: "http://www.linkedin.com/shareArticle?url={url}&title={title}"
        },
        {
          name: "Buffer",
          class: "buffer",
          url: "http://bufferapp.com/add?text={title}&url={url}"
        },
        {
          name: "Digg",
          class: "digg",
          url: "http://digg.com/submit?url={url}&title={title}"
        },
        {
          name: "Tumblr",
          class: "tumblr",
          url: "http://www.tumblr.com/share/link?url={url}&name={title}&description={desc}"
        },
        {
          name: "Reddit",
          class: "reddit",
          url: "http://reddit.com/submit?url={url}&title={title}"
        },
        {
          name: "StumbleUpon",
          class: "stumbleupon",
          url: "http://www.stumbleupon.com/submit?url={url}&title={title}"
        },
        {
          name: "Delicious",
          class: "delicious",
          url: "https://delicious.com/save?v=5&provider={provider}&noui&jump=close&url={url}&title={title}"
        }
      ]

      generateSocialUrls = function (opt) {
        if (typeof opt !== "object") {
          return false
        }
        const links = []
        let network = undefined
        let i = 0
        while (i < this.networks.length) {
          network = this.networks[i]
          links.push({
            name: network.name,
            class: network.class,
            url: this.generateUrl(network.url, opt)
          })
          i++
        }
        return links
      }

      generateUrl = function (url, opt) {
        let prop = undefined
        let arg = undefined
        let arg_ne = undefined
        for (prop in opt) {
          prop = prop
          arg = `{${prop}}`
          if (url.indexOf(arg) !== -1) {
            url = url.replace(
              new RegExp(arg, "g"),
              encodeURIComponent(opt[prop])
            )
          }
          arg_ne = `{${prop}-ne}`
          if (url.indexOf(arg_ne) !== -1) {
            url = url.replace(new RegExp(arg_ne, "g"), opt[prop])
          }
        }
        return this.cleanUrl(url)
      }

      cleanUrl = function (fullUrl) {
        //firstly, remove any expressions we may have left in the url
        fullUrl = fullUrl.replace(/\{([^{}]*)}/g, "")
        //then remove any empty parameters left in the url
        const params = fullUrl.match(/[^\=\&\?]+=[^\=\&\?]+/g)
        let url = fullUrl.split("?")[0]
        if (params && params.length > 0) {
          url += `?${params.join("&")}`
        }
        return url
      }

      doPopup = function (e) {
        e = e ? e : window.event
        const t = e.target ? e.target : e.srcElement
        var width = t.data - width || 800
        var height = t.data - height || 500
        // popup position
        const px = Math.floor(((window.screen.availWidth || 1024) - width) / 2)
        const py = Math.floor(((window.screen.availHeight || 700) - height) / 2)
        // open popup
        const popup = window.open(
          t.href,
          "social",
          `width=${width},height=${height},left=${px},top=${py},location=0,menubar=0,toolbar=0,status=0,scrollbars=1,resizable=1`
        )
        if (popup) {
          popup.focus()
          if (e.preventDefault) {
            e.preventDefault()
          }
          e.returnValue = false
        }
        return !!popup
      }
    }
  }
  SocialShareLinkGenerator.initClass()
  return SocialShareLinkGenerator
})()

export default SocialShareLinkGenerator
