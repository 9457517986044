import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import { TransitionMotion, spring } from "react-motion"
import styled from "styled-components"
import Fullscreen, { FULLSCREEN_LAYER_OVERLAY } from "../fullscreen/Fullscreen"
import useCleanupInterval from "../hooks/useCleanupInterval"
import View from "../ui/View"
import Alert from "./Alert"

const Container = styled.div`
  display: flex;
  justify-content: center;

  & > .View {
    max-width: 600px;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .Alert {
    margin: 0;
  }
`

const PopupAlert = ({
  success,
  error,
  loading,
  onClear,
  children,
  inverted,
  clearAfter
}) => {
  const [animate, setAnimate] = useState(false)
  const [animateOut, setAnimateOut] = useState(false)
  const cleanupInterval = useCleanupInterval()

  useEffect(() => {
    // transition only works visually if we wait to animate until
    // the Alert has children to render
    setAnimate(!!success || !!error || !!loading)
    setAnimateOut(false)
  }, [success, error, loading])

  useEffect(() => {
    if (animate && clearAfter) {
      cleanupInterval(setTimeout(() => setAnimateOut(true), clearAfter))
    }
  }, [animate, clearAfter])

  useEffect(
    () => () => {
      if (onClear && !animateOut) onClear()
    },
    []
  )

  const config = {
    key: "alert",
    style: {
      x: 0
    }
  }

  const alertSpring = {
    stiffness: 300,
    damping: 20
  }

  // transition only works visually if we wait to animate until
  // the Alert has children to render
  if (!animate) return null

  return (
    <Fullscreen
      background={null}
      allowScroll={false}
      layer={FULLSCREEN_LAYER_OVERLAY}
    >
      <TransitionMotion
        defaultStyles={[config]}
        styles={
          animateOut
            ? []
            : [
                {
                  ...config,
                  style: {
                    x: spring(1, alertSpring)
                  }
                }
              ]
        }
        willLeave={() => ({
          x: spring(0, alertSpring)
        })}
        didLeave={() => onClear && onClear()}
      >
        {(interpolated) => {
          if (!interpolated.length) return null

          return (
            <Container>
              {interpolated.map(({ key, style: { x } }) => {
                const style = {
                  position: "absolute",
                  top: "100%",
                  opacity: x,
                  transform: `translateY(calc(${x * -100}% - 20px))`
                }

                return (
                  <View.Primary
                    padding
                    border
                    shadow
                    rounded
                    style={style}
                    key={key}
                  >
                    <Alert
                      success={success}
                      error={error}
                      inverted={inverted}
                      onClear={onClear ? () => setAnimateOut(true) : null}
                      loading={loading}
                    >
                      {children}
                    </Alert>
                  </View.Primary>
                )
              })}
            </Container>
          )
        }}
      </TransitionMotion>
    </Fullscreen>
  )
}

PopupAlert.displayName = "PopupAlert"

PopupAlert.propTypes = {
  ...Alert.propTypes,
  clearAfter: PropTypes.number // milliseconds, null if animateOut should be controlled by Alert
}

PopupAlert.defaultProps = {
  clearAfter: 5000
}

export default PopupAlert
