import gql from "graphql-tag"
import numeral from "numeral"
import pluralize from "pluralize"
import { usePathwrightContext } from "../pathwright/PathwrightContext"
import CommunityPreview from "./CommunityPreview"
import Place from "./Place"

const CommunityPlace = ({ recentMembers, membershipStats, mentorGroups }) => {
  const { school } = usePathwrightContext()
  if (!recentMembers) return null
  const hasMembers = recentMembers.edges.length > 0
  return (
    <Place
      name="Community"
      icon="multi-user"
      color="#6A9FCB"
      onLaunch={(to) => window.App && window.App.navigate(to)}
      to="/community/members/"
      action="Manage members & groups"
      isBlank={!hasMembers}
      blankImage="community"
      blankDescription={`See everyone learning & teaching on ${school.name}`}
      footer={
        hasMembers &&
        membershipStats && (
          <p>
            {numeral(membershipStats.total).format("0,0")}{" "}
            {pluralize("member", membershipStats.total)}
            {" • "}
            {numeral(mentorGroups?.total).format("0,0")}{" "}
            {pluralize("group", mentorGroups?.total)}
          </p>
        )
      }
    >
      {hasMembers && (
        <CommunityPreview
          members={recentMembers.edges.map((edge) => edge.node.user)}
        />
      )}
    </Place>
  )
}

CommunityPlace.displayName = "CommunityPlace"

CommunityPlace.fragments = {
  membershipStats: gql`
    fragment MembershipStats on School {
      membershipStats: membership_stats {
        total
        active
      }
    }
  `,
  recentMembers: gql`
    fragment RecentMembers on School {
      recentMembers: members(first: 32) {
        total
        edges {
          node {
            id
            user {
              id
              display_name
              first_name
              last_name
              profile {
                id
                image(width: 50, height: 50, fit: clamp)
                bio
                location
              }
              membership {
                id
                role
              }
            }
          }
        }
      }
    }
  `,
  mentorGroups: gql`
    fragment MentorGroups on School {
      mentorGroups: mentor_groups(first: 1) {
        total
      }
    }
  `
}

export default CommunityPlace
