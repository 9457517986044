class GridFormBehavior extends Marionette.Behavior {
  static initClass() {
    // events: ->
    //   "focus input, textarea, select, [data-field-span]": (e)=>
    //     @focusField($(e.currentTarget))
    //   "blur input, textarea, select, [data-field-span]": =>
    //     @removeFieldFocus()

    this.prototype.behaviors = {
      Vents: {
        "grid:form:resize"() {
          return this.triggerMethod("equalize:field:heights")
        }
      }
    }
  }

  initialize() {
    this.removeFieldFocus = this.removeFieldFocus.bind(this)
  }

  onRender() {
    return _.defer(() => {
      this.setClickEvents()
      this.focusField(this.focusableFields().filter(":focus"))
      this.triggerMethod("equalize:field:heights")
      // Not running these in favor of triggering equalize
      // via Pathwright vents
      // @checkIfVisible()
      // @equalizeIfVisible()
      return this.windowEvents()
    })
  }

  onFocusRichText(redactor) {
    this.removeFieldFocus()
    return this.focusField(redactor.$box)
  }

  setClickEvents() {
    this.focusableFields().on("focus", (e) => {
      return this.focusField($(e.currentTarget))
    })
    return this.blurableFields().on("blur", this.removeFieldFocus)
  }

  clearClickEvents() {
    this.focusableFields().off()
    return this.blurableFields().off()
  }

  checkIfVisible() {
    return (this.interval = setInterval(() => {
      return this.equalizeIfVisible()
    }, 1000))
  }

  // The gridform can't equalize the field heights if
  // the form is display: none
  equalizeIfVisible() {
    if (this.$el.is(":visible")) {
      this.triggerMethod("equalize:field:heights")
      return clearInterval(this.interval)
    }
  }

  fieldsRows() {
    return this.$("[data-row-span]")
  }
  fieldsContainers() {
    return this.$("[data-field-span]")
  }
  focusableFields() {
    return this.$("input, textarea, select", "[data-field-span]")
  }
  blurableFields() {
    return this.$("input, textarea:not(.redactor), select, [data-field-span]")
  }

  focusField(currentField) {
    this.removeFieldFocus()
    return currentField.closest("[data-field-span]").addClass("focus")
  }

  removeFieldFocus() {
    return this.fieldsContainers().removeClass("focus")
  }

  windowEvents() {
    window.addEventListener("resize", () => {
      return this.triggerMethod("equalize:field:heights")
    })
  }

  onParsleyFieldValidated() {
    return this.triggerMethod("equalize:field:heights")
  }

  onEqualizeFieldHeights() {
    return _.defer(() => {
      this.fieldsContainers().css("height", "auto")

      const fieldsRows = this.fieldsRows()
      const fieldsContainers = this.fieldsContainers()

      // Make sure that the fields aren't stacked
      if (!this.areFieldsStacked()) {
        return fieldsRows.each(function () {
          // Get the height of the row (thus the tallest element's height)
          const fieldRow = $(this)
          const rowHeight = fieldRow.css("height")

          // Set the height for each field in the row...
          return fieldRow.find(fieldsContainers).css({ height: rowHeight })
        })
      }
    })
  }

  areFieldsStacked() {
    // Get the first row
    // which does not only contain one field
    const firstRow = this.fieldsRows().not('[data-row-span="1"]').first()

    // Get to the total width
    // of each field witin the row
    let totalWidth = 0
    firstRow.children().each(function () {
      return (totalWidth += $(this).width())
    })

    // Determine whether fields are stacked or not
    return firstRow.width() <= totalWidth
  }

  onDestroy() {
    this.clearClickEvents()
    return clearInterval(this.interval)
  }
}
GridFormBehavior.initClass()
export default GridFormBehavior
