import gql from "graphql-tag"

export default gql`
  query SubscriptionDiscountQuery(
    $discount_code: String!
    $school_plan_id: Int!
  ) {
    schoolSubscriptionDiscount(
      discount_code: $discount_code
      school_plan_id: $school_plan_id
    ) {
      discount_percent
      discounted_price
      discounted_subscription_cycles
      currency_display_discount_price
      currency_display_discount_amount
    }
  }
`
