import "./FieldFooter.css"

const FieldFooter = ({ children, offset }) => (
  <footer className="FieldFooter" style={{ marginLeft: offset }}>
    {children}
  </footer>
)

FieldFooter.displayName = "FieldFooter"

export default FieldFooter
