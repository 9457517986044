import gql from "graphql-tag"
import STORE_LINK_FRAGMENT from "./store-link-fragment"

export default gql`
  query StoreLinksQuery($licensorId: IDFilter) {
    storeLinks(first: 10, licensor_id: $licensorId) {
      total
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...StoreLink
        }
      }
    }
  }
  ${STORE_LINK_FRAGMENT}
`
