import IconButton from "@pathwright/ui/src/components/button/IconButton"
import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import get from "lodash/get"
import USER_COMPLETIONS_NEEDING_REVIEW from "../graphql/user-completions-needing-review"

const UserCompletionsNeedingReview = ({ userId }) => {
  // NOTE: attempted to use useIntersection for query optimization but element never intersects,
  // possibly to do with the react-virtuallized
  // const { scrollNode } = useScrollContext()
  // const [setIntersectionNode, intersection, hasIntersected] = useIntersection({
  //   root: scrollNode
  // })

  const userCompletionsNeedingReviewQuery = useQuery(
    USER_COMPLETIONS_NEEDING_REVIEW,
    {
      variables: {
        user_id: userId
      }
      // skip: !hasIntersected
    }
  )

  const { loading } = userCompletionsNeedingReviewQuery
  const count = get(
    userCompletionsNeedingReviewQuery,
    "data.pathItemStats.path_items_needing_review"
  )

  return (
    <IconButton
      // ref={setIntersectionNode}
      interactive={!loading}
      to={{
        pathname: `/completion/`,
        query: {
          user_id: userId,
          ...(count > 0 ? { filter: "needs_review" } : {})
        },
        state: { setLaunchedFrom: true }
      }}
      styleType="tertiary"
      icon="inbox"
      countIndicatorProps={
        count && {
          count
        }
      }
    />
  )
}

UserCompletionsNeedingReview.displayName = "UserCompletionsNeedingReview"

export default UserCompletionsNeedingReview
