export const SUBSCRIPTION_INTERVAL_MONTHLY = 5
export const SUBSCRIPTION_INTERVAL_BIANNUALLY = 7
export const SUBSCRIPTION_INTERVAL_YEARLY = 10
export const SUBSCRIPTION_INTERVAL_LIFETIME = 20

export const SUBSCRIPTION_INTERVAL_LABELS = {
  [SUBSCRIPTION_INTERVAL_MONTHLY]: "month",
  [SUBSCRIPTION_INTERVAL_BIANNUALLY]: "bi-annual",
  [SUBSCRIPTION_INTERVAL_YEARLY]: "year",
  [SUBSCRIPTION_INTERVAL_LIFETIME]: "lifetime"
}

export const SUBSCRIPTION_PLAN_TRIAL_TYPE_NONE = 0
export const SUBSCRIPTION_PLAN_TRIAL_TYPE_WITHOUT_CARD = 5
export const SUBSCRIPTION_PLAN_TRIAL_TYPE_GIFT_SUBSCRIPTION = 7
export const SUBSCRIPTION_PLAN_TRIAL_TYPE_WITH_CARD = 10

// Numbers in comments are the values as stored on the server
export const SUBSCRIPTION_CHARGE_TYPE_STRIPE = "stripe" // 1
export const SUBSCRIPTION_CHARGE_TYPE_INVOICE = "invoice" // 2
export const SUBSCRIPTION_CHARGE_TYPE_NO_CHARGE = "no_charge" // 3
export const SUBSCRIPTION_CHARGE_TYPE_PATHWRIGHT_TEAM = "pathwright_team" // 4
