import { removeHTML } from "@pathwright/media-utils"

export const embedIsReady = (embed) =>
  !!embed &&
  embed.status === "ready" &&
  !!embed.download &&
  embed.download.status === "ready"

export const embedNeedsPolling = (embed) =>
  !embed ||
  embed.status === "preparing" ||
  !embed.download ||
  embed.download.status === "preparing"

export const createMuxDownloadURL = (url, name) => {
  if (!url) return ""
  if (!name) return url

  // Replace Mux's filename with the media item's name
  const re = /download=(.+?)&/gims
  const match = re.exec(url)
  if (match && match[1]) {
    return url.replace(match[1], encodeURIComponent(removeHTML(name)))
  }

  return url // fallback
}
