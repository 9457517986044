import ConfirmResetPassword from "./ConfirmResetPassword"

export default [
  {
    path: "/auth/reset-confirm/:resetHash/",
    component: ConfirmResetPassword
  },
  {
    path: "/auth/sign-in/password/reset/confirm/:resetHash/",
    component: ConfirmResetPassword
  }
]
