import { i18n } from "@pathwright/ui/src/components/lng/I18nextProvider"
import { RESPONSE_ORDER_FILTER_NEWEST } from "discussion/constants"

const OfferingRegistrations =
  require("library/models/offering-registrations").default
const Discussion = require("discussion/models/discussion").default
const ResponseCollection = require("./models/response-collection").default

class DiscussionStore extends require("lib/static-shim").default(
  require("lib/core/store/backbone-store").default
) {
  static initClass() {
    this.prototype.resources = {
      discussion: {
        model: Discussion
      },
      responses: {
        collection: ResponseCollection
      },
      moderators_for_context: {
        collection: OfferingRegistrations
      }
    }

    this.prototype.storeTriggers = {
      "response:received"(resp) {
        return resp
      }
    }

    this.prototype.actions = {
      triggerResponseRecieved(notification) {
        const { action, context_data } = notification.data

        if (context_data) {
          const { discussion, response } = context_data
          if (
            response &&
            parseInt(discussion) === parseInt(this.get("discussion_id"))
          ) {
            if (["replied to", "answered"].includes(action)) {
              this._mutate({ new_responses_available: true })
              return this.storeTrigger("response:received", response)
            }
          }
        }
      },

      getDiscussionByID(id, context) {
        // TODO: get discussion form discussions?
        return this.resource.discussion.load({
          id,
          context: context || this.request.getContext()
        })
      },

      selectDiscussionResponse(discussionID, responseID) {
        if (!discussionID) {
          return
        }

        if (responseID == null) {
          responseID = null
        } else {
          responseID = parseInt(responseID)
        }

        discussionID = parseInt(discussionID)

        this._mutate({ discussion_id: discussionID, response_id: responseID })
      },

      clearSelectedDiscussion() {
        this._mutate({ discussion: null, discussion_id: null })
        this.action.clearSelectedResponse()
        App.getStore("layout").action.closeIn("panel")
      },

      clearSelectedResponse() {
        this._mutate({ response_id: null })
      },

      loadResponses(params, forceReload) {
        if (params == null) {
          params = {}
        }
        if (forceReload == null) {
          forceReload = false
        }
        params = _.defaults(params, {
          discussion_id: this.get("discussion_id")
        })
        return this.resource.responses.list(params, forceReload)
      },

      reloadResponses(params) {
        if (params == null) {
          params = {}
        }
        params = _.defaults(params, {
          discussion_id: this.get("discussion_id")
        })
        return this.action
          .loadResponses(params, true)
          .promise.then((responses) => {
            this._mutate({ new_responses_available: false })
            return responses
          })
      },

      setDefaultResponse(filter) {
        return this._mutate({ response_default_ordering: filter })
      },

      askToAnswer(item, moderatorRegistration) {
        const contextKey = this.request.getContextStr()
        const notificationStore = window.App.getStore("notification")
        const { user } = moderatorRegistration
        return notificationStore.action.sendAskToAnswerNotification(
          item,
          user,
          contextKey
        )
      },

      loadModeratorsForOffering(offering_id) {
        if (offering_id) {
          if (
            __guard__(
              window.App.getStore("resource").request.permissions &&
                window.App.getStore("resource").request.permissions(),
              (x) => x.can_facilitate
            )
          ) {
            const data = {
              id: offering_id,
              type: "moderator,teacher",
              expand: "user"
            }
            return this.resource.moderators_for_context.list(data)
          }
        } else {
          return console.error(
            "Could not load moderators because context is not set."
          )
        }
      }
    }

    this.prototype.requests = {
      canAskToAnswer() {
        const { discussion } = this.getState()
        const resourceStore = window.App.getStore("resource")
        const perms =
          resourceStore.request.permissions &&
          resourceStore.request.permissions()
        // legacy UI
        const canEdit =
          discussion != null ? discussion.user_permissions.can_edit : undefined
        return canEdit && (perms != null ? perms.can_facilitate : undefined)
      },

      permissions() {
        // Shortcut to get permissions for the current discussion context
        const discussion = this.get("discussion")
        let permissions = {}
        if (discussion) {
          // Use the API data
          permissions = discussion.get("user_permissions")
        } else {
          // No discussion, use context permissions
          const resourceStore = window.App.getStore("resource")
          permissions =
            resourceStore.request.permissions &&
            resourceStore.request.permissions()
          if (permissions) {
            permissions.can_post_discussion_question =
              permissions.can_facilitate
          } else {
            console.warn(
              "permissions not found for discussion from resourceStore",
              resourceStore.getState()
            )
          }
        }
        return permissions
      },

      // TODO: maybe get context for discussion here?
      getContext() {
        const resourceStore = window.App.getStore("resource")
        const section = resourceStore.get("section")

        switch (section) {
          case "path":
            return _.omit(App.getStore("path").request.getContext(), "path")
          case "community":
            return {
              resource: __guard__(resourceStore.get("resource"), (x1) => x1.id),
              offering: __guard__(resourceStore.get("offering"), (x2) => x2.id)
            }
          case "about":
          default:
            return {
              resource: __guard__(resourceStore.get("resource"), (x) => x.id)
            }
        }
      },

      getLegacyContext(ctx) {
        if (ctx == null) {
          ctx = this.request.getContext()
        }
        return {
          step: ctx.step_source_id || ctx.step,
          course: ctx.resource_id || ctx.course || ctx.resource,
          class: ctx.offering_id || ctx.class || ctx.offering
        }
      },

      getContextStr(ctx) {
        if (!ctx) {
          ctx = this.request.getLegacyContext()
        } else {
          ctx = this.request.getLegacyContext(ctx)
        }
        const keys = [
          (() => {
            const result = []
            for (let k in ctx) {
              const v = ctx[k]
              if (v != null) {
                result.push(`${k}_${v}`)
              }
            }
            return result
          })()
        ]
        return keys.join(",")
      },

      getURLForDiscussionContext() {
        let url = null
        let { discussion } = this.getState()
        if (discussion) {
          let offering, resource, step
          ;({
            context: { resource, offering, step, discussion }
          } = discussion)
          // Only getting URL for discussion if we are on a resource (so no URL if on school-wide activity feed)
          const _resource = this.getStore("resource").getState().resource
          // Every discussion should have a step nowadays
          if (_resource && _resource.id === resource && step) {
            url = `/library/${_resource.slug}/${offering}/path/step/${step}/`
          }
        }
        return url
      },

      getTitleForDiscussionContext() {
        let title = ""
        const { step } = __guard__(this.getState().discussion, (x) => x.context)
        if (step) {
          if (this.getStore("path").getState().items) {
            const item = this.getStore("path").request.findStep(step)

            // TODO: Fetch the path item to get the title if no path item
            if (item) {
              const tPath = `step.verbs.${item.attributes.step_verb}`
              const tExists = i18n.exists(tPath)

              const { step_verb, name } = item.attributes
              title = `${tExists ? i18n.t(tPath) : step_verb}: ${name}`
            }
          } else {
            title = i18n.t("step.view_step")
          }
        }
        return title
      }
    }
  }

  defaults() {
    return {
      discussion_id: null, // the selected discussion id (used for routing)
      response_id: null, // the selected response id (used for routing)
      context: null, // the context in which the discussion is being viewed
      filters: null, // used to order the api req results

      // UI state
      response_default_ordering: RESPONSE_ORDER_FILTER_NEWEST,
      new_responses_available: false
    }
  }

  initializeStore() {
    // Legacy integration! TODO: convert to store/actions
    this.listenTo(App.vent, "discussion:panel:shown", function (discussion) {
      if (discussion != null ? discussion.id : undefined) {
        let responseID = this.get("response_id") // why? this ID would likely be null anyway, right?
        this.action.selectDiscussionResponse(discussion.id, responseID).setURL()
        discussion.set({
          // Setting super far out date.
          // Triggers UI to reflect that the user has viewed the discussion
          user_last_viewed_dtime: moment("2100-01-01").format()
        })
      }
    })

    document.addEventListener("notification:received", (e) =>
      this.action.triggerResponseRecieved(e.detail)
    )
  }
}
DiscussionStore.initClass()

export default window.App.stores.registerStore("discussion", DiscussionStore)

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null
    ? transform(value)
    : undefined
}
