import gql from "graphql-tag"

export default gql`
  fragment PathSyncPlanChange on PathSyncPlanChange {
    item_source_id
    parent_source_id
    item_name
    parent_name
    item_type
    assignment_type
    forced
    forced_reasons
    change_details
    last_changed
  }
`
