import { useUIThemeContext } from "@pathwright/ui/src/components/ui/PathwrightUI"
import classnames from "classnames"
import PropTypes from "prop-types"
import styled from "styled-components"

const Container = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%) scale(1.1); /* scale seems to resolve some odd ovaling issues at smaller sizes */

  &:after {
    content: "";
    background-color: ${(p) => p.color};
    position: absolute;
    left: -0.25em;
    right: -0.25em;
    top: -0.25em;
    bottom: -0.25em;
    border-radius: 100%;
  }
`

export const getDiscussionUnread = (discussion, unreadType) => {
  if (!discussion) return false
  // Discussion is considered "read" if the user has viewed the discussion at some point.
  if (unreadType === "discussion") {
    if (discussion.user_last_viewed_time) return false
  }
  // Discussion responses are considered "read" if the user has viewed the discussion
  // since the last response was made.
  if (unreadType === "responses") {
    // To avoid showing multiple unread indicators, we'll show at most 1, preferring "discussion",
    // doing so by checking discussion.user_last_viewed_time.
    if (
      !discussion.user_last_viewed_time ||
      discussion.user_has_viewed_recent ||
      !discussion.total_responses
    )
      return false
  }

  return true
}

const DiscussionUnreadIndicator = ({ className, discussion, unreadType }) => {
  const theme = useUIThemeContext()

  return getDiscussionUnread(discussion, unreadType) ? (
    <Container
      className={classnames("DiscussionUnreadIndicator", className)}
      color={theme.primaryBrandColor}
    />
  ) : null
}

DiscussionUnreadIndicator.displayName = "DiscussionUnreadIndicator"

DiscussionUnreadIndicator.propTypes = {
  unreadType: PropTypes.oneOf(["discussion", "responses"]).isRequired
}

export default DiscussionUnreadIndicator
