import DropdownItem from "@pathwright/ui/src/components/dropdown/DropdownItem"
import DropdownMenu from "@pathwright/ui/src/components/dropdown/DropdownMenu"
import DropdownTrigger from "@pathwright/ui/src/components/dropdown/DropdownTrigger"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import SubmitHandler from "@pathwright/ui/src/components/submit/SubmitHandler"
import SubmitStatus from "@pathwright/ui/src/components/submit/SubmitStatus"
import {
  DANGEROUS_RED,
  SUCCESS_GREEN
} from "@pathwright/ui/src/components/utils/colors"
import styled from "styled-components"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import { canAdministrate } from "../../user/permissions"
import useResetPathItemForUser from "../graphql/useResetPathItemForUser"
import useSkipPathItemForUser from "../graphql/useSkipPathItemForUser"
import { getPathItemLabel } from "../utils"

const SubmitStatusContainer = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;

  .SubmitStatus__success {
    color: ${SUCCESS_GREEN};
  }
  .SubmitStatus__failure {
    color: ${DANGEROUS_RED};
  }
`

const ReportActions = ({ pathItem }) => {
  const { user } = pathItem.completion
  const pwContext = usePathwrightContext()
  const [resetPathItemForUser, { loading: reseting }] =
    useResetPathItemForUser(pathItem)
  const [skipPathItemForUser, { loading: skipping }] =
    useSkipPathItemForUser(pathItem)

  const handleMutation = async (mutation) => mutation()

  let canReset = false
  let canSkip = false

  // Base exceptions that apply to both reset/skip but are also all or nothing
  // exceptions for an lesson's child items.
  const getCanResetSkipItem = (item) => {
    // Little hacky here: prevent Collection item resets/skips.
    if (item.type === "resource") return false

    // Only admins can reset/skip item that requires API completion.
    if (item.completion.requires_api_completion && !canAdministrate(pwContext))
      return false

    return true
  }

  const getCanResetItem = (item) => {
    if (!getCanResetSkipItem(item)) return false

    // Cannot reset an incomplete item that also has no previous completion attempts
    if (item.progress <= 0 && !item.completion.last_completion_attempt_date)
      return false

    return true
  }

  const getCanSkipItem = (item) => {
    if (!getCanResetSkipItem(item)) return false

    // Cannot skip completed item
    if (item.progress >= 1) return false

    return true
  }

  if (pathItem.items) {
    // Reset/skip for lesson requires is possible if at least 1 item can be reset/skipped
    // but requires that all items pass the base exceptions.
    canReset =
      pathItem.items.some(getCanResetItem) &&
      pathItem.items.every(getCanResetSkipItem)
    canSkip =
      pathItem.items.some(getCanSkipItem) &&
      pathItem.items.every(getCanResetSkipItem)
  } else {
    canReset = getCanResetItem(pathItem)
    canSkip = getCanSkipItem(pathItem)
  }

  if (!canReset && !canSkip) return null

  // simple perms check + handle no user (like when viewing the default path)
  if (!user || user.id === pwContext.me.id) return null

  const pathItemLabel = getPathItemLabel(pathItem.type)

  return (
    <SubmitHandler onSubmit={handleMutation}>
      {({ status, handleSubmit }) => (
        <DropdownTrigger
          className="ReportActions"
          renderDropdown={() => (
            <DropdownMenu>
              {canReset && (
                <DropdownItem
                  icon="skip-back-2"
                  label={
                    pathItem.items
                      ? "Reset completions"
                      : pathItem.completion.is_skipped
                      ? "Reset skip"
                      : pathItem.completion.is_complete
                      ? "Reset completion"
                      : "Reset completion attempt"
                  }
                  meta={
                    pathItem.progress > 0
                      ? `Mark as incomplete for ${user.first_name}.`
                      : `Enable ${user.first_name} to retake this step.` // failed attempt
                  }
                  onClick={() =>
                    !reseting && handleSubmit(resetPathItemForUser)
                  }
                />
              )}
              {canSkip && (
                <DropdownItem
                  icon="skip-circle"
                  label="Skip"
                  meta={`Don't require ${user.first_name} to complete this ${pathItemLabel}.`}
                  onClick={() => !skipping && handleSubmit(skipPathItemForUser)}
                />
              )}
            </DropdownMenu>
          )}
        >
          <SubmitStatusContainer>
            <SubmitStatus status={status} size={14}>
              <Pathicon icon="ellipses-horizontal" />
            </SubmitStatus>
          </SubmitStatusContainer>
        </DropdownTrigger>
      )}
    </SubmitHandler>
  )
}

ReportActions.displayName = "ReportActions"

export default ReportActions
