import classnames from "classnames"
import { Circle } from "rc-progress"
import Pathicon from "../pathicon/Pathicon"
import { useUIThemeContext } from "../ui/PathwrightUI"
import "./ProgressCircle.css"

const ProgressCircle = (props) => {
  const {
    strokeWidth,
    strokeColor,
    trailWidth,
    trailColor,
    strokeLinecap,
    prefixCls,
    className,
    children,
    progress,
    inverted,
    style,
    size,
    icon
  } = props

  const trail = inverted ? "rgba(0,0,0,0.15)" : trailColor || "rgba(0,0,0,0.1)"

  const theme = useUIThemeContext()
  const color = inverted
    ? "rgba(255,255,255,1)"
    : strokeColor || theme.primaryBrandColor

  const circleStyle = {
    width: `${size}px`,
    height: `${size}px`
  }

  let kiddos = children
  if (icon) {
    kiddos = (
      <Pathicon
        icon={icon}
        style={{
          fontSize: `${size * 0.5}px`,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          color
        }}
      />
    )
  }

  return (
    <div
      className={classnames("ProgressCircle", className)}
      style={circleStyle}
    >
      <Circle
        percent={progress * 100 || 0}
        strokeColor={color}
        trailColor={trail}
        trailWidth={trailWidth || strokeWidth}
        strokeWidth={strokeWidth}
        strokeLinecap={strokeLinecap}
        className="ProgressCircle__progress"
      />
      {kiddos && (
        <div
          className="ProgressCircle__inner"
          style={{
            width: "100%",
            height: "100%"
          }}
        >
          {kiddos}
        </div>
      )}
    </div>
  )
}

ProgressCircle.displayName = "ProgressCircle"

ProgressCircle.defaultProps = {
  size: 100,
  progress: 0,
  strokeWidth: 3
}

export default ProgressCircle
