import CardBlock from "@pathwright/ui/src/components/card/CardBlock"
import RadioButton from "@pathwright/ui/src/components/form/form-radio/RadioButton"
import RadioGroup from "@pathwright/ui/src/components/form/form-radio/RadioGroup"
import FieldHeader from "@pathwright/ui/src/components/form/form-utils/FieldHeader"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import Text from "@pathwright/ui/src/components/ui/Text"
import { Field } from "react-connect-form-forked"
import styled from "styled-components"
import {
  GROUP_VISIBILITY_MEMBERS,
  GROUP_VISIBILITY_PRIVATE,
  GROUP_VISIBILITY_PUBLIC
} from "../group/constants"

const RadioButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const CohortVisibilityPrompt = ({ formState, highestOfferingVisibility }) => {
  const { t } = useTranslate()
  return (
    <CardBlock>
      <FieldHeader title={t("Who can discover & join this cohort?")} />
      <RadioGroup {...formState.fields.visibility}>
        {GROUP_VISIBILITY_PRIVATE <= highestOfferingVisibility && (
          <Field
            name="visibility"
            type="radio"
            value={GROUP_VISIBILITY_PRIVATE}
            component={RadioButton}
            initialChecked
            required
          >
            <RadioButtonWrapper>
              <Text.H5>
                <Pathicon icon="avatar-lock" />
                <span> </span>
                {t("Invite only")}
              </Text.H5>
              <Text.Meta>{t("Only people you invite.")}</Text.Meta>
            </RadioButtonWrapper>
          </Field>
        )}
        {GROUP_VISIBILITY_MEMBERS <= highestOfferingVisibility && (
          <Field
            name="visibility"
            type="radio"
            value={GROUP_VISIBILITY_MEMBERS}
            component={RadioButton}
            required
          >
            <RadioButtonWrapper>
              <Text.H5>
                <Pathicon icon="group" />
                <span> </span>
                {t("Members")}
              </Text.H5>
              <Text.Meta>{t("Any member of your school.")}</Text.Meta>
            </RadioButtonWrapper>
          </Field>
        )}
        {GROUP_VISIBILITY_PUBLIC <= highestOfferingVisibility && (
          <Field
            name="visibility"
            type="radio"
            value={GROUP_VISIBILITY_PUBLIC}
            component={RadioButton}
            required
          >
            <RadioButtonWrapper>
              <Text.H5>
                <Pathicon icon="globe" />
                <span> </span>
                {t("Public")}
              </Text.H5>
              <Text.Meta>{t("Anyone in the world.")}</Text.Meta>
            </RadioButtonWrapper>
          </Field>
        )}
      </RadioGroup>
    </CardBlock>
  )
}

CohortVisibilityPrompt.displayName = "CohortVisibilityPrompt"

export default CohortVisibilityPrompt
