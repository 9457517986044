import Button from "@pathwright/ui/src/components/button/Button"
import Checkbox from "@pathwright/ui/src/components/form/form-checkbox/Checkbox"
import RichTextInput from "@pathwright/ui/src/components/form/form-richtext/RichTextInput"
import FormStateSubmitButton from "@pathwright/ui/src/components/form/form-state/FormStateSubmitButton"
import { FormikWithDiscardFormState } from "@pathwright/ui/src/components/form/form-state/FormikDiscardFormState"
import { getFormStateStorageKey } from "@pathwright/ui/src/components/form/form-state/useFormStateStorage"
import { StyledFieldMinimal } from "@pathwright/ui/src/components/form/form-utils/FieldWrapper"
import KeyboardFormSubmit from "@pathwright/ui/src/components/form/form-utils/KeyboardFormSubmit"
import { validate } from "@pathwright/ui/src/components/form/utils"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import LoadingBlock from "@pathwright/ui/src/components/loading/LoadingBlock"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import Tooltip from "@pathwright/ui/src/components/tooltip/Tooltip"
import { Form } from "formik"
import { useEffect, useState } from "react"
import styled from "styled-components"
import { useReviewContext } from "../../state/ReviewContextProvider"
import { useInboxReviewers } from "../Reviewers"
import useReviewNotify from "./useReviewNotify"
// import RecordVideo from "../../ui/RecordVideo"

const ReviewCompletionScoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.2em;
  input {
    width: 60px;
  }
  .ScoreFieldRow {
    margin-bottom: 10px;
  }
  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`

const isNumeric = (n) => {
  return !isNaN(parseFloat(n)) && isFinite(n)
}

const isReviewed = (item) => {
  return !!item.completion.checked_date
}

const ReviewCompletionScore = ({ step, formik }) => {
  const { t } = useTranslate()
  const completion = step.completion

  let [score, setScore] = useState(
    isReviewed(step) ? completion.userpoints_earned : undefined
  )

  const handleChange = (e) => {
    if (isNumeric(e.target.value)) {
      setScore(parseInt(e.target.value))
    } else {
      // Allows submitting an empty value as a 0.
      setScore(0)
    }
  }

  useEffect(() => {
    if (
      score !== step.completion.userpoints_earned ||
      score !== formik.values.userpoints_earned
    ) {
      formik.setFieldValue("userpoints_earned", score)
      formik.setFieldTouched("userpoints_earned", true)
    }
  }, [step, score])

  const { values, touched, errors, handleBlur } = formik

  if (completion.userpoints_value <= 0) return null

  const minPassingScore = completion.minimum_passing_score

  const showNotPassedMessage =
    typeof minPassingScore == "number" &&
    isNumeric(score) &&
    score < minPassingScore &&
    touched.userpoints_earned

  const perc = isNumeric(score)
    ? Math.round((score / completion.userpoints_value) * 100)
    : undefined

  return (
    <ReviewCompletionScoreContainer>
      <StyledFieldMinimal className="ScoreFieldRow">
        <input
          name="userpoints_earned"
          defaultValue={values.userpoints_earned}
          type="number"
          required
          onChange={handleChange}
          onBlur={handleBlur}
          tabIndex={0}
        />
        <b>
          {" "}
          /{" "}
          {t("{{ count }} pt", {
            defaultValue_plural: "{{ count }} pts",
            count: completion.userpoints_value
          })}
        </b>
        {perc !== undefined && <span>&nbsp;({perc}%)</span>}
      </StyledFieldMinimal>
      {showNotPassedMessage && (
        <span style={{ color: "red" }}>
          <Pathicon name="warning" />
          <em>
            This step is not passing. {step.completion.user.display_name} needs
            at least {minPassingScore} points to pass this step.
          </em>
        </span>
      )}
    </ReviewCompletionScoreContainer>
  )
}

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  max-height: 100%;

  .ql-editor {
    /* Need a min height so that toolbar isn't clippedd by overflow hidden styles */
    min-height: 200px;
  }

  .notify {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;

    label {
      font-weight: normal;
    }
  }

  > footer {
    display: flex;
    align-items: center;
    margin-top: 10px;

    > span {
      display: inline-block;
      /* font-size: 13px; */
      color: #666;
      margin: 0 6px;
    }
  }
`

const ReviewCompletionForm = ({ onCancel }) => {
  const { t } = useTranslate()
  const {
    selected,
    actions: { reviewSelected }
  } = useReviewContext()

  const step = selected.data

  const { reviewers } = useInboxReviewers(selected.data.path_id)

  let initialValues = {
    userpoints_earned: step.completion.userpoints_earned,
    userpoints_explanation: step.completion.userpoints_explanation,
    notify: useReviewNotify()[0]
  }

  const isEditing =
    !!step.completion.checked_date && !step.completion.needs_grading

  const handleSubmit = (values) => reviewSelected(values)

  const handleChangeExplanation = (formik, value) => {
    // Cleaning empty userpoints_explanation value.
    // Doing this onChange prevents unwanted discrepancy with
    // form state storage if user deletes all content value.
    if (value === "<p><br></p>") {
      value = null
    }

    formik.setFieldValue("userpoints_explanation", value)
  }

  const validator = validate((key, value, values) => {
    switch (key) {
      case "userpoints_explanation":
        // NOTE: currently treating userpoints_explanation as always optional.
        // if (!value && !values.hasOwnProperty("userpoints_earned"))
        //   return "Required"
        break
    }
  })

  const storageKey = getFormStateStorageKey(["review", step.id])

  return (
    <FormikWithDiscardFormState
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validator}
      storageKey={storageKey}
    >
      {(formik) => (
        <StyledForm
          // onSubmit={formik.submitForm}
          style={{ width: "100%" }}
        >
          <KeyboardFormSubmit />
          <ReviewCompletionScore step={step} formik={formik} />
          <RichTextInput
            // innerRef={ref => (this.inputRef = ref)}
            placeholder={"Review notes (optional)..."}
            value={formik.values.userpoints_explanation}
            onChange={(value) => handleChangeExplanation(formik, value)}
            tabIndex={1}
            // maxRows={5}
            hideStatus
            hideLabel
            // errors={isValid === false ? [true] : null}
            errors={null}
            stretch={true}
            // controlled={false}
            LoadingComponent={() => <LoadingBlock style={{ height: "2em" }} />}
            style={{ width: "100%", overflow: "hidden auto" }}
          />

          {/* <RecordVideo /> */}
          <div className="notify">
            <Checkbox
              name={"notify"}
              checked={formik.values.notify}
              onChange={(e) => formik.setFieldValue("notify", e.target.checked)}
            />
            <Tooltip
              title={t(
                "Send {{ user }} an in-app and email notification with your review. (Reviewers will be copied on the email.)",
                {
                  user: step.completion.user.first_name
                }
              )}
              delayShow={300}
              placement="top"
            >
              <label for="notify">
                {t("Notify {{ user }}", {
                  user: step.completion.user.first_name
                })}
              </label>
            </Tooltip>
          </div>
          {isEditing ? (
            <footer>
              <FormStateSubmitButton
                brand
                styleType="primary"
                disabled={!formik.isValid || formik.isSubmitting}
                submitting={formik.isSubmitting}
                label="Update Review"
              />
              <span>or</span>
              <Button styleType="text" label="cancel" onClick={onCancel} />
            </footer>
          ) : (
            <footer>
              <FormStateSubmitButton
                brand
                styleType="primary"
                disabled={!formik.isValid || formik.isSubmitting}
                submitting={formik.isSubmitting}
                label="Complete Review"
                tooltipProps={{
                  placement: "right",
                  alignment: "bottom"
                }}
              />
              {reviewers.length > 1 && <span>for all reviewers</span>}
            </footer>
          )}
        </StyledForm>
      )}
    </FormikWithDiscardFormState>
  )
}

export default ReviewCompletionForm
