import PropTypes from "prop-types"
import React from "react"
const { get } = require("lib/core/api/request")
const { getAPIUrl } = require("lib/core/api/urls")

const getImgixURL = (url, params) => {
  if (params == null) {
    params = {}
  }
  params["image"] = url
  const previewURL = getAPIUrl("/theme/preview")
  return get(`${previewURL}?${$.param(params)}`)
}

class ImgixPreviewProvider extends React.Component {
  state = { preview: this.props.preview }

  refreshPreview = (url, params) => {
    return getImgixURL(url, params).then((response) => {
      return this.setState({ preview: response.url })
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { url, blur } = nextProps
    if ((url && url !== this.props.url) || blur !== this.props.blur) {
      const params = blur ? { blur: true } : {}
      return this.refreshPreview(url, params)
    }
  }

  render() {
    const { preview } = this.state
    return this.props.children({ preview })
  }
}

ImgixPreviewProvider.displayName = "ImgixPreviewProvider"

ImgixPreviewProvider.propTypes = {
  children: PropTypes.func.isRequired,
  preview: PropTypes.string,
  blur: PropTypes.bool,
  url: PropTypes.string
}

export default ImgixPreviewProvider
