import { resolveUserCompletion } from "./type/completion"
import layouts from "./type/layouts"
import schema from "./type/schema"

export default {
  type: "Question",
  icon: "question",
  // helpLink: "https://help.pathwright.com/create-a-course/adding-content-with-blocks/checkboxes-question-block-new",
  menu: {
    category: "question",
    order: 1
  },
  layouts,
  resolveUserCompletion,
  schema
}
