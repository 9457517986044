import FullscreenBlankSlate from "@pathwright/ui/src/components/blank/FullscreenBlankSlate"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import get from "lodash/get"
import { useState } from "react"
import { usePathwrightContext } from "../pathwright/PathwrightContext"
import RESOURCE_QUERY from "../resource/graphql/resource-query"
import { getTagAttachment } from "./utils"

// Just one confirmation when updating tag.
export const ConfirmUpdate = ({ tag, onConfirm, onCancel }) => {
  const { t } = useTranslate()

  return (
    <FullscreenBlankSlate
      icon="caution-triangle"
      heading={t(`Update "{{ tag }}" everywhere it's used?`, {
        tag: tag.name
      })}
      body={t(
        `"{{ tag }}" is shared with {{ count }} other course. Are you sure you want to change it everywhere it's used?`,
        {
          defaultValue_plural: `"{{ tag }}" is shared with {{ count }} other courses. Are you sure you want to change it everywhere it's used?`,
          tag: tag.name,
          count: tag.tagAttachments.total - 1
        }
      )}
      primaryAction={{
        label: t("Yes, update everywhere"),
        onClick: onConfirm
      }}
      secondaryAction={{
        label: t("No, go back"),
        onClick: onCancel
      }}
      stack
      onClickBackground={onCancel}
    />
  )
}

// Remove tag from top-level context, where the tag is used at most in only one lower-level context.
export const ConfirmRemove = ({ tag, onConfirm, onCancel }) => {
  const { t } = useTranslate()

  return (
    <FullscreenBlankSlate
      icon="caution-triangle"
      heading={t(`Delete "{{ tag }}"?`, {
        tag: tag.name
      })}
      body={
        tag.tagAttachments.total === 1
          ? t(
              `"{{ tag }}" is only used in 1 course and will no longer show up anywhere once deleted.`,
              {
                tag: tag.name
              }
            )
          : t(
              `"{{ tag }}" is only used here and will no longer show up anywhere once deleted.`,
              {
                tag: tag.name
              }
            )
      }
      primaryAction={{
        label: t("Yes, delete"),
        onClick: onConfirm,
        dangerous: true
      }}
      secondaryAction={{
        label: t("No, go back"),
        onClick: onCancel
      }}
      stack
      onClickBackground={onCancel}
    />
  )
}

// Remove tag from every context or just the current context.
export const ConfirmRemoveVarious = ({
  tag,
  onConfirm,
  onConfirmEverywhere,
  onCancel
}) => {
  const { t } = useTranslate()
  const [confirmEverywhere, setConfirmEverywhere] = useState(false)
  const { school } = usePathwrightContext()

  // Assuming tag has a tagAttachment, which really must be true.
  const resourceQuery = useQuery(RESOURCE_QUERY, {
    variables: { id: getTagAttachment(tag).resource_id }
  })

  if (confirmEverywhere) {
    return (
      <ConfirmRemoveEverywhere
        tag={tag}
        onConfirm={onConfirmEverywhere}
        onCancel={onCancel}
      />
    )
  }

  if (tag.tagAttachments.total > 1) {
    return (
      <FullscreenBlankSlate
        icon="caution-triangle"
        heading={t(`Remove or delete "{{ tag }}"?`, { tag: tag.name })}
        body={t(
          `"{{ tag }}" is used in this course and {{ count }} other course. How would you like to remove it?`,
          {
            defaultValue_plural: `"{{ tag }}" is used in this course and {{ count }} other courses.
            How would you like to remove it?`,
            tag: tag.name,
            count: tag.tagAttachments.total - 1
          }
        )}
        primaryActions={[
          {
            label: t("Remove from this course"),
            onClick: onConfirm
          },
          {
            label: t("Delete from all courses"),
            onClick: () => setConfirmEverywhere(true),
            dangerous: true
          }
        ]}
        secondaryActions={[
          {
            label: t("Neither, go back"),
            onClick: onCancel
          }
        ]}
        stack
        onClickBackground={onCancel}
      />
    )
  }

  return (
    <FullscreenBlankSlate
      icon="caution-triangle"
      heading={t(`Remove or delete "{{ tag }}"?`, {
        tag: tag.name
      })}
      body={t(
        `"{{ tag }}" is currently only used in this course, {{ course }}. It's also available to use in other courses on {{ school }}. How would you like to remove it?`,
        {
          tag: tag.name,
          course: get(resourceQuery, "data.resource.name"),
          school: school.name
        }
      )}
      primaryActions={[
        {
          label: t("Remove from this course"),
          onClick: onConfirm
        },
        {
          label: t("Delete entirely"),
          onClick: () => setConfirmEverywhere(true),
          dangerous: true
        }
      ]}
      secondaryActions={[
        {
          label: t("Neither, go back"),
          onClick: onCancel
        }
      ]}
      stack
      onClickBackground={onCancel}
    />
  )
}

// Remove tag from all contexts.
export const ConfirmRemoveEverywhere = ({ tag, onConfirm, onCancel }) => {
  const { t } = useTranslate()

  return (
    <FullscreenBlankSlate
      icon="caution-triangle"
      heading={t(`Delete "{{ tag }}" everywhere it's used?`, {
        tag: tag.name
      })}
      body={t(
        `"{{ tag }}" is shared with {{ count }} other course. Are you sure you want to delete it everywhere it's used?`,
        {
          defaultValue_plural: `"{{ tag }}" is shared with {{ count }} other courses. Are you sure you want to delete it everywhere it's used?`,
          tag: tag.name,
          count: tag.tagAttachments.total - 1
        }
      )}
      primaryAction={{
        label: t("Yes, delete everywhere"),
        onClick: onConfirm,
        dangerous: true
      }}
      secondaryAction={{
        label: t("No, go back"),
        onClick: onCancel
      }}
      stack
      onClickBackground={onCancel}
    />
  )
}
