import classnames from "classnames"
import isFunction from "lodash/isFunction"
import mapValues from "lodash/mapValues"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { TransitionMotion, spring } from "react-motion"
import styled from "styled-components"
import Fullscreen, { FULLSCREEN_LAYER_OVERLAY } from "../fullscreen/Fullscreen"
import useControlledState from "../hooks/useControlledState"
import useWindowSize from "../hooks/useWindowSize"
import { useObserveSizeContext } from "../observers/ObserveSizeContext"
import ScrollView from "../scroll/ScrollView"
import Screensize from "../ui/Screensize"
import "./DockPanel.css"
import DockPanelHeader from "./DockPanelHeader"

// Account for alert banner offset.
const StyledDockPanel = styled.div`
  padding-top: ${(p) => 50 + p.$offsetTop}px;
`
const StyledDockPanelHeader = styled.div`
  padding-top: ${(p) => p.$offsetTop}px;
`

export const DockPanelContext = React.createContext()

const DockPanel = ({
  title,
  link,
  children,
  onChangeLayout,
  isOpen: isOpenProp,
  onWillClose,
  onClose,
  maxWidth,
  maxHeight,
  closeRoute,
  allowOrient,
  HeaderComponent,
  defaultDocked,
  defaultBottom,
  orientSpring,
  dockSpring,
  dockOffset,
  panelWidthPerc,
  panelHeightPerc,
  className: klassName
}) => {
  const [isMobile, setIsMobile] = useState(false)
  const [isOpen, setIsOpen] = useControlledState(isOpenProp || false)
  const [isDocked, setIsDocked] = useState(defaultDocked)
  const [orientBottom, setOrientBottom] = useState(defaultBottom)
  const [header, setHeader] = useState({
    title,
    link
  })
  const { getRectValue } = useObserveSizeContext()
  // Only account for aleart banner offset when oriented top.
  const offsetTop = orientBottom ? 0 : getRectValue("alert.height")

  // Determine if the DockPanel isOpen state is controlled.
  const isOpenControlled = isOpenProp === undefined

  useEffect(() => {
    onChangeLayout &&
      onChangeLayout({
        open: isOpen,
        docked: isDocked,
        orientation: orientBottom ? "bottom" : "side"
      })
  }, [isOpen, isDocked, orientBottom])

  // ensure DockPanel rerenders whenever screensize changes since
  // the width and height of the panel are set absolutely based on screensize
  const windowSize = useWindowSize()

  useEffect(() => {
    const nextIsMobile = Screensize.Provider.getScreensize() === "sm"
    if (nextIsMobile !== isMobile) {
      setIsMobile(nextIsMobile)
    }
  }, [windowSize])

  useEffect(() => {
    // Only handle state as result of children changing
    // if DockPanel isOpen is not controlled.
    if (isOpenControlled) {
      // Automatically open when new children are set
      if (children) {
        setIsDocked(false)
        setIsOpen(true)
      }

      // The panel has been closed via some other method than clicking the close button, so let's attempt to execute any closing cleanup logic
      if (!children && isOpen) {
        handleOnWillClose()
      }

      if (!children) {
        setIsOpen(false)
      }
    }
  }, [children])

  const handleOnWillClose = () => {
    isFunction(onWillClose) && onWillClose()
  }
  const toggleBottom = () => setOrientBottom(!orientBottom)
  const toggleDocked = () => setIsDocked(!isDocked)
  const handleClose = () => {
    setIsOpen(false)
    handleOnWillClose()
  }
  const updateHeader = ({ title, link }) => {
    setHeader({
      title,
      link
    })
  }

  // Update header when title or link changes
  useEffect(() => {
    updateHeader({
      title,
      link
    })
  }, [title, link])

  const calculateStyle = (offscreen, plainStyle) => {
    const widthPerc = orientBottom || isMobile ? 1 : panelWidthPerc
    const heightPerc = isMobile || !orientBottom ? 1 : panelHeightPerc

    let panelWidth = window.innerWidth * widthPerc
    let panelHeight = window.innerHeight * heightPerc
    // open position
    let transformX = 0
    let transformY = 0

    if (!orientBottom && panelWidth > maxWidth) {
      panelWidth = maxWidth
    }

    if (orientBottom && panelHeight > maxHeight) {
      panelHeight = maxHeight
    }

    if (isDocked) {
      if (orientBottom) {
        // push panel down to reveal only top edge
        transformY = panelHeight - dockOffset
      } else {
        // push panel to the right to reveal only side edge
        transformX = panelWidth - dockOffset
      }
    }

    if (offscreen) {
      if (orientBottom) {
        // push panel completely down
        transformY = panelHeight
      } else {
        // push panel completely to the right
        transformX = panelWidth
      }
    }

    const style = {
      w: spring(panelWidth, orientSpring),
      h: spring(panelHeight, orientSpring),
      x: spring(transformX, dockSpring),
      y: spring(transformY, dockSpring)
    }

    // plainStyle necessary to set initial position on willEnter
    if (plainStyle) {
      return mapValues(style, (k) => k.val)
    } else {
      return style
    }
  }

  const className = classnames(
    "DockPanel",
    {
      "DockPanel--open": isOpen,
      "DockPanel--docked": isDocked,
      "DockPanel--mobile": isMobile,
      "DockPanel--bottom": orientBottom
    },
    klassName
  )

  const openCofig = {
    key: "DockPanel",
    style: calculateStyle()
  }

  const closedConfig = {
    key: "DockPanel",
    style: calculateStyle(true)
  }

  return (
    <DockPanelContext.Provider
      value={{
        setHeader: updateHeader
      }}
    >
      <TransitionMotion
        willEnter={() => calculateStyle(true, true)}
        willLeave={() => calculateStyle(true)}
        didLeave={() => isFunction(onClose) && onClose()}
        styles={isOpen ? [openCofig] : [closedConfig]}
      >
        {(interpolated) => {
          if (!interpolated.length) return null

          const { key, style } = interpolated[0]
          const { w, h, x, y } = style

          const interpolatedStyle = {
            width: `${w}px`,
            height: `${h}px`,
            transform: `translate3d(${x}px,${y}px,0)`
          }

          return (
            <Fullscreen
              key={key}
              layer={FULLSCREEN_LAYER_OVERLAY}
              background={
                !isDocked && isOpen
                  ? {
                      overlay: "rgba(0, 0, 0, 0.3)"
                    }
                  : null
              }
              onClickBackground={!isDocked && toggleDocked}
              allowScroll={!isDocked && isOpen}
              offset={{ top: offsetTop }}
            >
              <StyledDockPanel
                className={className}
                style={interpolatedStyle}
                $offsetTop={offsetTop}
              >
                <StyledDockPanelHeader
                  className="DockPanel__header"
                  $offsetTop={offsetTop}
                >
                  <DockPanelHeader
                    isDocked={isDocked}
                    isBottom={orientBottom}
                    onDock={toggleDocked}
                    onClose={handleClose}
                    closeRoute={closeRoute}
                    onOrientation={toggleBottom}
                    allowOrient={allowOrient}
                    HeaderComponent={HeaderComponent}
                    title={header.title}
                    link={header.link}
                  />
                </StyledDockPanelHeader>
                <ScrollView className="DockPanel__content">
                  {children}
                </ScrollView>
                {isDocked && (
                  <div className="DockPanel__undock" onClick={toggleDocked} />
                )}
              </StyledDockPanel>
            </Fullscreen>
          )
        }}
      </TransitionMotion>
    </DockPanelContext.Provider>
  )
}

DockPanel.displayName = "DockPanel"

DockPanel.propTypes = {
  // default docked state when children provided
  defaultDocked: PropTypes.bool,
  // default orientation when children provided
  defaultBottom: PropTypes.bool,
  // % width when in side orientation
  panelWidthPerc: PropTypes.number,
  // % height when in bottom orientation
  panelHeightPerc: PropTypes.number,
  // px to reveal when panel is docked
  dockOffset: PropTypes.number,
  // docking transition
  dockSpring: PropTypes.shape({
    stiffness: PropTypes.number,
    damping: PropTypes.number
  }),
  // switch orientation transition
  orientSpring: PropTypes.shape({
    stiffness: PropTypes.number,
    damping: PropTypes.number
  }),
  onChangeLayout: PropTypes.func,
  onWillClose: PropTypes.func,
  onClose: PropTypes.func,
  closeRoute: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  renderContentOnTransition: PropTypes.bool,
  HeaderComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  link: PropTypes.string
}

DockPanel.defaultProps = {
  defaultDocked: false,
  defaultActive: true,
  defaultBottom: false,
  allowOrient: true,
  panelWidthPerc: 0.5, // .85
  panelHeightPerc: 0.5, // .4
  maxWidth: 1000,
  maxHeight: 500,
  dockOffset: 50,
  renderContentOnTransition: true,
  dockSpring: {
    stiffness: 220,
    damping: 25
  },
  orientSpring: {
    stiffness: 200,
    damping: 20
  }
}

export default DockPanel
