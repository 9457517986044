import IconButton from "@pathwright/ui/src/components/button/IconButton"
import Link from "@pathwright/ui/src/components/link/Link"
import Text from "@pathwright/ui/src/components/ui/Text"
import View from "@pathwright/ui/src/components/ui/View"
import styled from "styled-components"

const Wrapper = styled(View.Primary)`
  position: relative;
  overflow: hidden;
`
const BackgroundLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  & ~ * {
    pointer-events: none;
  }
`
const Cover = styled.div`
  overflow: hidden;
  position: relative;
  height: 0;
  width: 100%;
  padding-top: 33.3333%;
  background-image: url(${(p) => p.image});
  background-size: cover;
  background-position: center center;
`
const Info = styled.div`
  overflow: hidden;
  position: relative;
  min-height: 100px;
  padding: 5px 10px;
`
const SettingsLink = styled(IconButton)`
  position: absolute;
  right: 0px;
  bottom: 0px;
  pointer-events: auto;
  html.no-touch ${Wrapper}:not(:hover) & {
    visibility: hidden;
  }
`

const Collection = ({ collection, to, settingsTo }) => {
  return (
    <Wrapper shadow rounded>
      <BackgroundLink to={to} />
      <Cover image={collection.image} />
      <Info>
        <Text.H5 lines={2}>{collection.name}</Text.H5>
        <Text.Meta lines={2}>{collection.short_description}</Text.Meta>
        {!!settingsTo && <SettingsLink icon="gear" to={settingsTo} />}
      </Info>
    </Wrapper>
  )
}

Collection.displayName = "Collection"

export default Collection
