import { i18n } from "@pathwright/ui/src/components/lng/I18nextProvider"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import React from "react"
import AskToAnswerActions from "./AskToAnswerActions"

class ActionMenu extends React.Component {
  static displayName = "ActionMenu"

  onSelectModerator = (moderatorRegistration) => {
    return this.props.discussionStore.action.askToAnswer(
      this.props.item,
      moderatorRegistration
    )
  }

  render() {
    let moderators_for_context, shouldShowAskToAnswer
    return (
      <div className="discussion-admin-menu">
        <a className="dropdown-toggle" data-toggle="dropdown">
          <Pathicon icon="ellipses-horizontal" />
        </a>
        <ul className="dropdown-menu">
          <li>
            <a data-trigger-state="edit" className="edit-discussion">
              {i18n.t("discussion.actions.menu.edit")}
            </a>
          </li>
          <li>
            <a data-trigger-state="delete">
              {i18n.t("discussion.actions.menu.delete")}
            </a>
          </li>
          {
            (({ moderators_for_context } = this.props.discussionStore.state),
            (shouldShowAskToAnswer =
              this.props.item.user_permissions.can_ask_to_answer),
            (shouldShowAskToAnswer =
              shouldShowAskToAnswer &&
              (moderators_for_context != null
                ? moderators_for_context.results.length
                : undefined) > 0),
            shouldShowAskToAnswer ? (
              <li className="ask-to-answer">
                <label>
                  {i18n.t("discussion.actions.menu.ask_to_answer")}:
                </label>
                <AskToAnswerActions
                  {...Object.assign(
                    { onSelect: this.onSelectModerator },
                    this.props
                  )}
                />
              </li>
            ) : undefined)
          }
        </ul>
      </div>
    )
  }
}

export default ActionMenu
