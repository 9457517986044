import gql from "graphql-tag"

export default gql`
  fragment SchoolBillingBlock on SchoolBillingBlock {
    id
    block_type
    block_size
    price
  }
`
