import { useToast } from "@chakra-ui/react"
import Avatar from "@pathwright/ui/src/components/avatar/Avatar"
import Link from "@pathwright/ui/src/components/link/Link"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import BulletSeparation from "@pathwright/ui/src/components/ui/BulletSeparation"
import Text from "@pathwright/ui/src/components/ui/Text"
import capitalize from "lodash/capitalize"
import path from "path-browserify"
import styled from "styled-components"
import { ROLE_LABELS, ROLE_TYPE_SCHOOL } from "../invitation/constants"
import { usePathwrightContext } from "../pathwright/PathwrightContext"
import MaxLastSeen from "../user/MaxLastSeen"
import MembershipControls from "./MembershipControls"
import MembershipMeta from "./MembershipMeta"
import {
  MEMBERSHIP_ROLE_ADMIN,
  MEMBERSHIP_ROLE_EDITOR,
  MEMBERSHIP_ROLE_FACILITATOR
} from "./constants"

const StyledContainer = styled.div`
  position: relative;
  html.no-touch &:not(:hover) .OptionsButton {
    display: none;
  }
`

const StyledWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 10px 8px 20px;
  .Avatar {
    margin-right: 10px;
  }
`

const StyledLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  ${StyledContainer}:hover & {
    background-color: rgba(0, 0, 0, 0.05);
  }
`

const StyledInfo = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  min-width: 0;
`

const StyledTitle = styled.div`
  display: flex;
  align-items: center;
`

const StyledMeta = styled.div`
  padding-right: 10px;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:first-letter {
      display: block;
      text-transform: capitalize;
    }
  }
`

const StyledAccessMeta = styled(Text.Meta)`
  ul {
    list-style: none;
    padding: 0;
    display: flex;

    li {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    li:nth-last-of-type(1) {
      flex-shrink: 1;
    }
    li:nth-last-of-type(2) {
      flex-shrink: 100;
    }
    li:nth-last-of-type(3) {
      flex-shrink: 1000;
    }

    li:empty {
      display: none;
    }
  }

  .Tooltip__trigger {
    line-height: 0;
  }
`

const MembershipListItem = (props) => {
  const {
    id,
    role,
    user,
    membership,
    has_access,
    access_revoked_time,
    archived_time,
    setMemberRole,
    setMemberAccess,
    setMemberArchived,
    resetPassword
  } = props
  const { me, school } = usePathwrightContext()
  // For toasting certain actions. Passing down for one since MembershipControls
  // is a class component and for two, the controls can be closed while the toast
  // can remain mounted.
  const toast = useToast()
  return (
    <StyledContainer>
      <StyledWrapper>
        <StyledLink to={path.join(window.location.pathname, `/${user.id}/`)} />
        <Avatar user={user} size="30px" />
        <StyledInfo>
          <StyledTitle>
            <Text.H5 style={{ opacity: has_access ? 1 : 0.4 }}>
              {user.full_name}
            </Text.H5>{" "}
            {role >= MEMBERSHIP_ROLE_FACILITATOR && (
              <div style={{ fontSize: "11px", marginLeft: "5px" }}>
                <MembershipMeta
                  userId={user.id}
                  selectedMeta={[
                    school.is_curriculum_enabled ? "editor" : null,
                    "lead_mentor"
                  ].filter((meta) => meta)}
                  // button props
                  style={{ display: "block" }}
                  disabled={!has_access}
                >
                  Staff
                </MembershipMeta>
              </div>
            )}
          </StyledTitle>
          <StyledMeta>
            {has_access ? (
              <StyledAccessMeta>
                <BulletSeparation as="ul">
                  {[MEMBERSHIP_ROLE_ADMIN, MEMBERSHIP_ROLE_EDITOR].includes(
                    role
                  ) && (
                    <li>{capitalize(ROLE_LABELS[ROLE_TYPE_SCHOOL][role])}</li>
                  )}
                  {!!user.school_subscription && (
                    <li>
                      <Pathicon
                        icon="recur"
                        style={{
                          marginRight: ".2em",
                          position: "relative",
                          top: "1px"
                        }}
                      />
                      {user.school_subscription.subscription_plan.name}
                    </li>
                  )}
                  <MaxLastSeen user={user} membership={membership}>
                    {(node) => <li>{node}</li>}
                  </MaxLastSeen>
                </BulletSeparation>
              </StyledAccessMeta>
            ) : archived_time ? (
              <Text.Meta>Archived</Text.Meta>
            ) : access_revoked_time ? (
              <Text.Meta>No access</Text.Meta>
            ) : null}
          </StyledMeta>
        </StyledInfo>
        {me && me.id !== user.id && (
          <MembershipControls
            id={id}
            role={role}
            user={user}
            has_access={has_access}
            access_revoked_time={access_revoked_time}
            archived_time={archived_time}
            setMemberRole={setMemberRole}
            resetPassword={resetPassword}
            setMemberAccess={setMemberAccess}
            setMemberArchived={setMemberArchived}
            toast={toast}
          />
        )}
      </StyledWrapper>
    </StyledContainer>
  )
}

MembershipListItem.displayName = "MembershipListItem"

export default MembershipListItem
