import ResourceBackground from "@pathwright/ui/src/components/background/ResourceBackground"
import Button from "@pathwright/ui/src/components/button/Button"
import IconButton from "@pathwright/ui/src/components/button/IconButton"
import Link from "@pathwright/ui/src/components/link/Link"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import { media } from "@pathwright/ui/src/components/utils/styles"
import path from "path-browserify"
import styled from "styled-components"

const StyledHeader = styled.header`
  position: relative;
  margin-top: -10px;
  ${media.min.phone`
		.BackgroundImage,
		.BackgroundImage__image,
		.BackgroundImage__overlay {
			border-radius: 10px 10px 0 0;
		}
	`}
`

const StyledWrapper = styled.div`
  position: relative;
  color: white;
  padding: 30px 50px 15px;
  text-align: center;
  ${media.max.phone`
		padding: 50px 50px 15px;
	`}
`

const StyledHeading = styled.h2`
  margin: 0;
  font-weight: 700;
`

const StyledMeta = styled.p`
  margin: 0;
  span + span:before {
    content: "•";
    margin: 0 3px;
  }
`

const StyledLink = styled(Link)`
  color: white;
  border-bottom: 1px solid white;
  cursor: pointer;
`

const StyledBack = styled(IconButton)`
  position: absolute;
  top: 6px;
  left: 10px;
`

const StyledAction = styled(Button)`
  position: absolute;
  top: 6px;
  right: 10px;
`

const ResourceEditorsHeader = ({ resource, card }) => {
  return (
    <StyledHeader>
      {resource && <ResourceBackground resource={resource} roundedTop />}
      <StyledWrapper>
        <StyledBack
          onClick={card.onClose}
          icon={card.isIndex ? "x" : "arrow-left"}
          inverted
        />
        <StyledHeading>Course Editors</StyledHeading>
        <StyledMeta>
          {resource &&
            resource.registration_meta.editor_registration_count > 0 && (
              <span>
                <Pathicon icon="group-2" />
                &nbsp;
                {resource.registration_meta.editor_registration_count}
              </span>
            )}
          <span>
            <StyledLink
              to={path.join(window.location.pathname, "/invite/invitation/")}
            >
              View sent invites
            </StyledLink>
          </span>
        </StyledMeta>
        <StyledAction
          to={path.join(window.location.pathname, "/invite/")}
          styleType="primary"
          inverted
          brand
        >
          Invite
        </StyledAction>
      </StyledWrapper>
    </StyledHeader>
  )
}

ResourceEditorsHeader.displayName = "ResourceEditorsHeader"

export default ResourceEditorsHeader
