import Card from "@pathwright/ui/src/components/card/Card"
import get from "lodash/get"
import PropTypes from "prop-types"
import { usePathwrightContext } from "../pathwright/PathwrightContext"
import "./Support.css"
import { LicensorComponents } from "./SupportLicensorSchool"
import SupportPathwright from "./SupportPathwright"
import SupportSchool from "./SupportSchool"

const getSupportComponent = (mode) => {
  switch (mode) {
    case "pathwright":
      return SupportPathwright
    case "school":
      return SupportSchool
    case "licensor":
      const licensorId = get(
        window.school,
        "curriculum_licensee_subs[0].licensor.id"
      )
      // Assuming school only has one licensor
      const LicensorSupportComponent = LicensorComponents[licensorId]
      if (LicensorSupportComponent) {
        return LicensorSupportComponent
      } else {
        console.warn(`No component found for licensor: ${licensorId}`)
      }
    default:
      return SupportPathwright
  }
}

const Support = (props) => {
  const { school, me } = usePathwrightContext()

  const userIsSchoolAdmin = get(me, "permissions.can_administrate")
  const userIsSuperUser = get(me, "permissions.has_pathwright_staff_access")

  if (!props.mode) return null

  const SupportComponet = getSupportComponent(props.mode)

  return (
    <Card className={`Support Support__${props.mode}`} card={props.card}>
      <div className="Support__container">
        <SupportComponet
          {...props}
          userIsSchoolAdmin={userIsSchoolAdmin}
          userIsSuperUser={userIsSuperUser}
          school={school}
        />
      </div>
    </Card>
  )
}

Support.displayName = "Support"

Support.propTypes = {
  mode: PropTypes.oneOf(["pathwright", "school", "licensor"]),
  exitModal: PropTypes.func,
  support_email: PropTypes.string,
  support_phone: PropTypes.string,
  support_faqs: PropTypes.string,
  support_link: PropTypes.string,
  support_policy: PropTypes.string,
  has_faqs: PropTypes.bool
}

export default Support
