import ThemeBackground from "@pathwright/ui/src/components/background/ThemeBackground"
import Text from "@pathwright/ui/src/components/ui/Text"
import ConfirmResetPasswordForm from "@pathwright/web/src/modules/auth/ConfirmResetPasswordForm"
import "./ConfirmResetPassword.css"

const ConfirmResetPassword = ({ match }) => {
  return (
    <div className="ConfirmResetPassword">
      <ThemeBackground />
      <ConfirmResetPasswordForm
        inverted
        resetHash={match.params.resetHash}
        renderHeader={() => (
          <header>
            <Text.H2 inverted>Reset your password</Text.H2>
          </header>
        )}
      />
    </div>
  )
}

ConfirmResetPassword.displayName = "ConfirmResetPassword"

export default ConfirmResetPassword
