import { i18n } from "@pathwright/ui/src/components/lng/I18nextProvider"

let ResponseListView
require("./styles/response-list.css")

const Response = require("discussion/models/response").default
const ResponseListItemArchived =
  require("./response-list-item-archived").default

export default ResponseListView = (function () {
  ResponseListView = class ResponseListView extends (
    require("lib/static-shim").default(
      require("lib/core/composite-view").default
    )
  ) {
    static initClass() {
      this.prototype.template = require("./templates/response-list.html")
      this.prototype.className = "response-list-view"

      this.prototype.childView = require("./response-list-item").default
      this.prototype.childViewContainer = ".response-list-item-container"

      this.prototype.emptyView = require("./discussion-empty").default
      this.prototype.emptyViewOptions = () => {
        const discussion = window.App.getStore("discussion").get("discussion")
        return discussion.get("user_permissions").can_respond
          ? {
              message: i18n.t("discussion.responses.empty.can_respond")
            }
          : {
              message: i18n.t("discussion.responses.empty.cant_respond")
            }
      }

      this.prototype.ui = { actionViewAll: ".response-action-view-all" }

      this.prototype.triggers = {
        "click @ui.actionViewAll": "view:all:responses"
      }

      this.prototype.behaviors = {
        ConnectStore: {
          store: "discussion"
        },
        Bind: {
          init() {
            // Bindings for the paginate behavior
            return (this.state = new Backbone.Model({
              next_count: 0,
              previous_count: 0
            }))
          },
          serializeBindData() {
            return { state: this.state }
          },
          getBindEl() {
            return this.$(".paginate")
          }
        }, // Avoid overriding the bind on the item view

        PaginatedCollection: {},
        FilteredCollection: {
          filterMenu: {
            filters() {
              return window.App.request("get:response:filters")
            },
            uiContainer: ".response-filter-container"
          }
        }
      }

      this.prototype.childEvents = {
        reply: "createResponse",
        "response:canceled": "toggleOffResponding",
        "response:added": "toggleOffResponding"
      }

      this.prototype.collectionEvents = {
        "response:archived": "onResponseArchived"
      }
    }

    onViewAllResponses() {
      return this.discussionStore.action.clearSelectedResponse().setURL()
    }

    onResponseArchived(response, index) {
      response.set("archived_time", new Date().toISOString())
      return this.collection.add(response, { at: index })
    }

    toggleOffResponding() {
      return this.$el.removeClass("responding")
    }

    childViewOptions(model, index) {
      model.collection = this.collection
      const options = {
        context: this.model.context,
        discussion: this.model,
        baseDepth: this._getBaseDepth(),
        onResponseSaved: this.options.onResponseAdded,
        onResponseArchived: this.options.onResponseArchived
      }
      return options
    }

    getChildView(item) {
      // TODO: return ArchivedResponesItem view
      if (!item.isArchived()) {
        return super.getChildView(item)
      } else {
        return ResponseListItemArchived
      }
    }

    createResponse(toResponseView) {
      this.$el.addClass("responding")
      const parent = toResponseView.model
      const response = new Response(
        {
          reply_parent: parent.id,
          discussion: parent.get("discussion"),
          author: window.App.request("get:user").attributes,
          depth: parent.get("depth") + 1,
          context_str: parent.get("context_str")
        },
        {
          context: this.model.context_str,
          discussion: this.model,
          reply_parent: parent
        }
      )
      const index = this.collection.indexOf(parent) + 1
      return this.collection.add(response, { at: index })
    }

    onBeforeShow() {
      return this.$(".response-list-item-view").velocity({ opacity: 0 }, 1)
    }

    onShow() {
      return this.transitionChildViews()
    }

    onBeforeFilter() {
      return this.$(".response-list-item-view").velocity({ opacity: 0 }, 1)
    }

    onFilter() {
      this.transitionChildViews()
      this.toggleOffResponding()
    }

    serializeData() {
      const data = super.serializeData()
      data.isShowingResponse = this.discussionStore.get("response_id") !== null
      return data
    }

    _getBaseDepth() {
      // returns the lowest depth being show in the reply chain (depth of 1 is root)
      let baseDepth
      if (this.collection.length) {
        baseDepth = __guard__(this.collection.first(), (x) => x.get("depth"))
      } else {
        baseDepth = 1
      }
      return baseDepth
    }

    transitionChildViews() {
      if (this._inTransition) {
        return
      }
      this._inTransition = true
      $.Velocity.animate(
        this.$(".response-list-item-view"),
        "transition.slideRightIn",
        { stagger: 80, duration: 350 }
      )
      return _.delay(() => {
        return (this._inTransition = false)
      }, 350)
    }
  }
  ResponseListView.initClass()
  return ResponseListView
})()

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null
    ? transform(value)
    : undefined
}
