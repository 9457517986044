import Button from "@pathwright/ui/src/components/button/Button"
import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import { ConnectForm, TextArea, TextInput } from "lib/core/components/forms"
import CategoryDelete from "./CategoryDelete"

let fieldsData = {
  categoryname: {
    label: "Category name",
    placeholder: "Enter a category name",
    labelWidth: 150,
    autoFocus: true
  },
  description: {
    label: "Category description",
    placeholder: "About the category...",
    optional: true,
    labelWidth: 150,
    maxRows: 3
  }
}

let CategoryForm = function (props) {
  let canDelete,
    category,
    handleDelete,
    handleSubmit,
    isDeleting,
    isValid,
    selectCategoryForDeletion,
    unselectCategoryForDeletion
  ;({
    category,
    fieldsData,
    isValid,
    handleSubmit,
    canDelete,
    isDeleting,
    handleDelete,
    selectCategoryForDeletion,
    unselectCategoryForDeletion
  } = props)

  if (canDelete && isDeleting) {
    return (
      <CategoryDelete
        id={category.id}
        name={category.name}
        count={category.resources_count || 0}
        onCancel={unselectCategoryForDeletion}
        onDelete={handleDelete}
      />
    )
  } else {
    return (
      <form onSubmit={handleSubmit} autoComplete="off">
        <SubmitButton
          onSubmit={handleSubmit}
          disabled={!isValid}
          styleType={"primary"}
        >
          Save
        </SubmitButton>
        <TextInput {...Object.assign({}, fieldsData.categoryname)} />
        <TextArea {...Object.assign({}, fieldsData.description)} />
        {canDelete ? (
          <Button
            onClick={selectCategoryForDeletion}
            styleType="card"
            color="red"
          >
            Delete category
          </Button>
        ) : undefined}
      </form>
    )
  }
}

CategoryForm = ConnectForm(CategoryForm, fieldsData)
CategoryForm.displayName = "CategoryForm"

export default CategoryForm
