import pluralize from "pluralize"

export default function getStripePriceIntervalLabel(
  stripePrice,
  adverb = false,
  t
) {
  if (!stripePrice.recurring_interval) {
    return ""
  }

  if (stripePrice.recurring_interval_count > 1) {
    return t(`every {{ intervalCount }} {{ recurringInterval }}`, {
      intervalCount: stripePrice.recurring_interval_count,
      recurringInterval: t(
        pluralize(
          stripePrice.recurring_interval,
          stripePrice.recurring_interval_count
        )
      )
    })
  }

  if (adverb) {
    if (stripePrice.recurring_interval === "month") {
      return t("monthly")
    }
    if (stripePrice.recurring_interval === "year") {
      return t("yearly")
    }
  }

  return `${t(stripePrice.recurring_interval)}`
}
