import Iframe from "react-iframe"
import PricingOverlay from "./PricingOverlay"

const PricingFaqsOverlay = (props) => {
  const { backTo } = props

  return (
    <PricingOverlay className="PricingFaqsOverlay" inverted>
      <Iframe
        url="https://pathwright.com/core-features"
        width="100%"
        height="100%"
        frameBorder="0"
      />
    </PricingOverlay>
  )
}

PricingFaqsOverlay.displayName = "PricingFaqsOverlay"

export default PricingFaqsOverlay
