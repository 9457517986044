// USAGE: via ImageUpload

import "./_Atmosphere.css"

import { withTranslate } from "@pathwright/ui/src/components/"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"

const AtmosphereUploader = ({ uploading, error, mediaType, pickFile, t }) => (
  <div className="AtmosphereUploader">
    {uploading ? (
      <LoadingCircle />
    ) : error ? (
      { error }
    ) : (
      <label onClick={pickFile}>
        <Pathicon icon="arrow-up-circle" />
        {t("img_uploader.label")}
      </label>
    )}
  </div>
)

AtmosphereUploader.displayName = "AtmosphereUploader"

export default withTranslate(AtmosphereUploader)
