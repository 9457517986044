import { getEnv, getIsLocalHost } from "@pathwright/ui/src/components/utils/env"
import defaults from "lodash/defaults"
import url from "url"
import { COMPLETION_FILTERS } from "../inbox/context/completion-filters"
import { PLATFORM_URLS } from "../pathwright/constants"

// platform routes

// NOTE: running into Babel transpile bug with named capture groups:
// SyntaxError: Expected atom at position
// export const COHORT_SECTION_RE = /\/(?<library>library)\/(?<resourceSlug>[a-zA-Z0-9_-]+)\/(?<cohortId>\d+)\/(?<section>[a-z]+)\//
// export const STEP_RE = /\/(?<library>library)\/(?<resourceSlug>[a-zA-Z0-9_-]+)\/(?<cohortId>\d+)\/(?<section>[a-z]+)\/(?<step>step)\/(?<stepSourceId>\d+)\//

const intRe = /^\d+$/

// NOTE: using RegExp constructor to avoid Babel transpile bug due to named groups.
export const RESOURCE_SLUG_ID = new RegExp("-(?<resourceId>\\d+(?=$))")

export const COHORT_SECTION_RE = new RegExp(
  "\\/(?<library>library)\\/(?<resourceSlug>[a-zA-Z0-9_-]*-(?<resourceId>\\d+))\\/(?<cohortId>(\\d+|_))\\/(?<section>[a-z]+)\\/"
)
export const STEP_RE = new RegExp(
  "\\/(?<library>library)\\/(?<resourceSlug>[a-zA-Z0-9_-]*-(?<resourceId>\\d+))\\/(?<cohortId>(\\d+|_))\\/(?<section>[a-z]+)\\/(?<step>step)\\/(?<stepSourceId>\\d+)\\/"
)
export const COMMUNITY_AREA_RE = new RegExp(
  "\\/(?<library>library)\\/(?<resourceSlug>[a-zA-Z0-9_-]*-(?<resourceId>\\d+))\\/(?<cohortId>(\\d+|_))\\/(?<section>[a-z]+)\\/(?<communityArea>[a-z]+)\\/"
)

export const getUrlValues = (re, pathname) => {
  pathname = pathname || window.location.pathname
  const match = pathname.match(re)
  const values = match ? match.groups : {}
  // Process the values, expecting either string or number.
  const processedValues = Object.entries(values).reduce(
    (values, [key, value]) => ({
      ...values,
      // Parse the int if value matches int regex, otherwise use default value.
      [key]: intRe.test(value) ? parseInt(value) : value
    }),
    {}
  )
  return processedValues
}

// NOTE: expects only the resource slug, not a full pathname.
export const getResourceSlugId = (slug) => {
  const { resourceId } = getUrlValues(RESOURCE_SLUG_ID, slug)
  return resourceId
}

export const getCohortSectionUrlValues = (pathname) => {
  const values = getUrlValues(COHORT_SECTION_RE, pathname)
  // returns { library, resourceSlug, resourceId, cohortId, section}
  return {
    ...values,
    // support groupId =(
    groupId: values.cohortId
  }
}

export const getCommunityAreaUrlValues = (pathname) =>
  getUrlValues(COMMUNITY_AREA_RE, pathname)

export const getCohortSectionBaseUrl = (options = {}) => {
  const { library, resourceSlug, cohortId, section } = defaults(
    options,
    getCohortSectionUrlValues()
  )
  const baseUrl = `/${library}/${resourceSlug}/${cohortId}/${section}/`
  return baseUrl
}

export const getCohortSectionUrl = (fragment) => {
  const baseUrl = getCohortSectionBaseUrl()
  return constructUrl(baseUrl, fragment)
}

export const getStoreUrl = () => {
  if (window.school && window.school.platform_version >= 2.8) {
    return `/store/`
  }
  return `/library/`
}

export const getStoreResourceUrl = (resourceSlug) => {
  if (window.school && window.school.platform_version >= 2.8) {
    return `/store/${resourceSlug}/`
  }
  return `/library/${resourceSlug}/about/`
}

// can also be used to get curriculum (base group id) path
export const getGroupUrl = (resourceSlug, groupId, section = "path") => {
  return `/library/${resourceSlug}/${groupId}/${section}/`
}

export const getCommunityAreaUrl = (resourceSlug, groupId, communityArea) => {
  return constructUrl(
    getGroupUrl(resourceSlug, groupId, "community"),
    communityArea
  )
}

export const getStepUrl = (resourceSlug, groupId, stepId) => {
  return `/library/${resourceSlug}/${groupId}/path/step/${stepId}/`
}

export const getDiscussionUrl = ({
  resourceSlug,
  groupId,
  stepId,
  discussionId
}) => {
  if (stepId) {
    return `/library/${resourceSlug}/${groupId}/path/step/${stepId}/discussion/${discussionId}/`
  }

  return `/library/${resourceSlug}/${groupId}/community/discussion/${discussionId}/`
}

export const getResponseUrl = ({
  resourceSlug,
  groupId,
  stepId,
  discussionId,
  responseId
}) =>
  constructUrl(
    getDiscussionUrl({
      resourceSlug,
      groupId,
      stepId,
      discussionId
    }),
    `/response/${responseId}/`
  )

export const getGroupInviteUrl = (resourceSlug, groupId, section) => {
  section = section || getCohortSectionUrlValues().section || "community"
  return `/library/${resourceSlug}/${groupId}/${section}/invite/`
}

export const getGroupMessageUrl = (resourceSlug, groupId, section) => {
  section = section || getCohortSectionUrlValues().section || "community"
  return `/library/${resourceSlug}/${groupId}/${section}/message/`
}

export const getGroupMembersUrl = (resourceSlug, groupId, section) => {
  section = section || getCohortSectionUrlValues().section || "community"
  return `/library/${resourceSlug}/${groupId}/${section}/member/`
}

export const getGroupCommunityUrl = ({ resourceSlug, groupId, section }) => {
  section = section || getCohortSectionUrlValues().section || "community"
  return `/library/${resourceSlug}/${groupId}/${section}/community/`
}

export const getGroupAnalyticsUrl = (resourceSlug, groupId, section) => {
  section = section || getCohortSectionUrlValues().section || "path"
  return `/library/${resourceSlug}/${groupId}/${section}/analytics/`
}

export const getPathItemAnalyticsUrl = (resourceSlug, groupId, sourceId) => {
  return `/library/${resourceSlug}/${groupId}/path/analytics/${sourceId}/`
}

export const getPathItemLessonScheduleUrl = (
  resourceSlug,
  groupId,
  sourceId
) => {
  return `/library/${resourceSlug}/${groupId}/path/schedule/${sourceId}/`
}

export const getPathItemScheduleUrl = (resourceSlug, groupId, sourceId) => {
  return `/library/${resourceSlug}/${groupId}/path/schedule/${sourceId}/`
}

// TODO: step different from lesson path item url?
export const getPathItemNotesUrl = (resourceSlug, groupId, sourceId) => {
  return `/library/${resourceSlug}/${groupId}/path/notes/${sourceId}/`
}

export const getPathItemSettingsUrl = (resourceSlug, groupId, stepId) => {
  return `/library/${resourceSlug}/${groupId}/path/step/${stepId}/settings/`
}

export const getPathItemCompletionsUrl = (
  resourceSlug,
  groupId,
  pathId,
  sourceId,
  filter = "review"
) => {
  const params = new URLSearchParams({ step: sourceId, filter })
  return `/library/${resourceSlug}/${groupId}/path/${pathId}/completion/?${params}`
}

export const getPathStepUrl = (resourceSlug, groupId, stepId) => {
  return `/library/${resourceSlug}/${groupId}/path/step/${stepId}/`
}

export const getPathCompletionsUrl = () => {
  return `/completion/`
}

export const getManageResourceUrl = (resourceId) => {
  return `/manage/resource/${resourceId}/`
}

export const getManageLicenseOfferingUrl = (resourceId) => {
  return `/manage/resource/${resourceId}/advanced/license/`
}

export const getManageGroupUrl = (resourceId, groupId) => {
  return `/manage/group/${resourceId}/${groupId}/`
}

export const getManageGroupCommunityUrl = (resourceId, groupId) => {
  return `/manage/group/${resourceId}/${groupId}/community/`
}

export const getManageNewGroupUrl = (resourceId) => {
  return `/manage/group/${resourceId}/new/`
}

export const getManageGroupsUrl = (resourceId) => {
  return `/manage/resource/${resourceId}/group/`
}

export const getInboxUrl = (options = {}) => {
  let url = "/inbox/"

  // NOTE: step_id forces Inbox to be opend in single item mode since
  // it scopes the data to that single item.
  const paramMap = {
    cohortId: "cohort_id",
    groupId: "group_id",
    stepId: "step_id",
    userId: "user_id",
    selected: "ui_selected",
    selectedParent: "ui_selected_parent",
    panel: "ui_panel",
    filter: "filter"
  }

  // When linking directly to a step, always open the step in the content|panel view,
  // rather than showing it in the list view.
  if (options.stepId && !options.selected) {
    options.selected = options.stepId
  }

  options = defaults(options, {
    filter: COMPLETION_FILTERS.all.key
  })

  const paramData = Object.entries(paramMap).reduce(
    (acc, [optionKey, paramKey]) =>
      options[optionKey]
        ? {
            ...acc,
            [paramKey]: options[optionKey]
          }
        : acc,
    {}
  )

  const params = new URLSearchParams(paramData).toString()

  if (params) {
    url += `?${params}`
  }

  return url
}

// helpers

const ENSURE_INITIAL_FORWARDSLASH_RE = /(^\/|^)/
const INITIAL_FORWARDSLASH_RE = /^\//
const FINAL_FORWARDSLASH_RE = /(^[^?](?!(.*\/$|.*\?)).*)/

export const constructUrl = (...fragments) =>
  fragments
    // "filter" out falsey values and transform to string
    .map((fragment) => (fragment || "").toString())
    .reduce((base, fragment, i) => {
      if (i !== 0) {
        // remove initial "/" for joining base with fragment
        fragment = fragment.replace(INITIAL_FORWARDSLASH_RE, "")
      } else if (isRelativeUrl(fragment)) {
        // ensure initial "/"
        fragment = fragment.replace(ENSURE_INITIAL_FORWARDSLASH_RE, "/")
      }

      // append final "/" if initial is not "?" and initial isn't followed by a final "/" or any "?"
      fragment = fragment.replace(FINAL_FORWARDSLASH_RE, "$1/")

      return `${base}${fragment}`
    }, "")

export const getSchoolUrl = (...fragments) => {
  let url = `${window.location.protocol}//${window.location.hostname}`
  if (getIsLocalHost()) {
    url = window.school && window.school.url
  }

  return constructUrl(url, ...fragments)
}

export const isSchoolUrl = (fullUrl) => {
  try {
    const host = url.parse(fullUrl).hostname
    const schoolHost = url.parse(getSchoolUrl()).hostname
    return host === schoolHost
  } catch (error) {
    console.warn("Error parsing url:", error)
    return false
  }
}

export const getRelativeUrl = (absUrl) => {
  const route = getRelativeRoute(absUrl)
  return `${route.pathname}${route.search}${route.hash}`
}

export const getRelativeRoute = (url) => {
  if (isRelativeUrl(url)) {
    // URL constructor expects base fragment to be the origin, fake it
    url = constructUrl("https://foo.com/", url)
  } else if (typeof url === "object") {
    url = constructUrl("https://foo.com/", url.pathname, url.search)
  }

  // Get url parts to form route.
  const { pathname, search, hash } = new URL(url)
  return { pathname, search, hash }
}

export const isRelativeUrl = (url) =>
  Boolean(typeof url === "string" && url.indexOf("http") !== 0)

export const getRoute = (url, fragment, search, hash) => {
  const route = getRelativeRoute(isRelativeUrl(url) ? getSchoolUrl(url) : url)
  const pathname = constructUrl(route.pathname, fragment)
  const queryObject = {
    ...getQueryObject(route.search),
    ...getQueryObject(search)
  }
  const filteredQueryObject = Object.keys(queryObject).reduce((obj, key) => {
    // only include non-null search params. This enables clearing a search param by setting it to null
    if (decodeURI(queryObject[key]) != null) {
      obj[key] = queryObject[key]
    }
    return obj
  }, {})
  search = new URLSearchParams(filteredQueryObject)
  const searchString = search.toString()

  return {
    ...route,
    pathname,
    search: searchString ? `?${searchString}` : ""
  }
}

export const getQueryObject = (search) => {
  if (search) {
    const searchParams = new URLSearchParams(search)
    return Array.from(searchParams.keys()).reduce((m, n) => {
      m[n] = searchParams.get(n)
      return m
    }, {})
  }
  return {}
}

// localize the given url - convenient for local testing
export const getLocalizedUrl = (url) => {
  if (isRelativeUrl(url)) return url
  if (!getIsLocalHost()) return url

  const relativeUrl = getRelativeUrl(url)
  const { origin } = window.location
  return constructUrl(origin, relativeUrl)
}

// Returns an object since you may want to have access to the matched key
// in cases where a regex patter is provided.
export const getParam = (search, pattern) => {
  // Allow for location to be a url string
  const params = new URLSearchParams(search)

  // Just returning the first key that matches the regular expression.
  // Could return a list of all keys that match, maybe later.
  if (pattern instanceof RegExp) {
    for (const [key, value] of params.entries()) {
      const match = key.match(pattern)
      if (match) {
        // Optionally set first matched group as key.
        if (match.groups) return { [Object.values(match.groups)[0]]: value }
        return { [key]: value }
      }
    }
  }

  if (typeof pattern === "string") {
    return { [pattern]: params.get(pattern) }
  }

  // Let caller handle params directly.
  if (!pattern) return getQueryObject(search)

  // Otherwise empty object as result.
  return {}
}

export const getPlatformUrl = (pathname) => {
  const env = getEnv()
  const baseUrl = PLATFORM_URLS[env]
  return constructUrl(baseUrl, pathname)
}
