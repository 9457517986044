import { Spacer, Text, VStack } from "@chakra-ui/react"
import { useMemo } from "react"
import { Trans } from "react-i18next"
import { z } from "zod"
import { useScopedTranslation } from "../../../../../lib/lng/TranslationsProvider"
import { ActionType } from "../../../../../lib/types"
import AuthRequestConfirmForm from "../../../auth-request/confirm/AuthRequestConfirmForm"
import AuthFormFooterBox from "../../../components/AuthFormFooterBox"
import AuthFormFooterBoxLink from "../../../components/AuthFormFooterBoxLink"
import AuthFormHeading from "../../../components/AuthFormHeading"
import AuthMotion from "../../../components/AuthMotion"
import { getAuthEmailFormStateEmail } from "../../../utils/authEmailFormStateStorage"
import getAuthRoute from "../../../utils/getAuthRoute"
import loader from "./api/loader"

// Use either the stored email address if it is valid, otherwise the
// truncated email if it exists.
const useConfirmationEmailAddress = (truncatedEmail?: string) => {
  const email = useMemo(() => {
    const emailSchema = z.string().email()
    const storedEmail = getAuthEmailFormStateEmail()
    return emailSchema.safeParse(storedEmail).success
      ? storedEmail
      : truncatedEmail
  }, [])

  return email
}

export type SignInAuthRequestConfirmViewProps = ActionType &
  Awaited<ReturnType<typeof loader>>

const tPrefix = "auth.signin_request_confirm"

const SignInAuthRequestConfirmView = ({
  authRequestId,
  truncatedEmail,
  error,
  loading,
  onSubmit
}: SignInAuthRequestConfirmViewProps) => {
  const { t } = useScopedTranslation(tPrefix)
  const email = useConfirmationEmailAddress(truncatedEmail)

  return (
    <AuthMotion>
      <VStack spacing={4} width="100%" alignItems="stretch">
        <AuthFormHeading>{t(`check_your_email`)}</AuthFormHeading>
        <Spacer />
        {email ? (
          <>
            <Trans
              parent={Text}
              i18nKey={`${tPrefix}.login_with_email`}
              values={{ email }}
              components={[<b key="0">{email}</b>]}
            />
            <Text>{t(`enter_code_in_email`)}</Text>
          </>
        ) : (
          <Text>{t(`enter_code_from_email`)}</Text>
        )}
        <AuthRequestConfirmForm
          authRequestId={authRequestId}
          error={error}
          loading={loading}
          onSubmit={onSubmit}
        />
        <Spacer />
        <AuthFormFooterBox>
          {/* TODO: check for if pathwright login is available? Or do we not do OTP in that case anyhow? */}
          <Trans
            parent={Text}
            i18nKey={`${tPrefix}.sign_in_alternative`}
            values={{
              sign_in_with_email_and_password: t(
                "sign_in_with_email_and_password"
              )
            }}
            components={[
              <AuthFormFooterBoxLink to={getAuthRoute("../password")} key="0">
                {t("sign_in_with_email_and_password")}
              </AuthFormFooterBoxLink>
            ]}
          />
        </AuthFormFooterBox>
      </VStack>
    </AuthMotion>
  )
}

export default SignInAuthRequestConfirmView
