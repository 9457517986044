import {
  dispatchGlobalEvent,
  ORDER_CREATED_EVENT,
  ORDER_FULFILLED_EVENT
} from "../../utils/dispatcher"

/**
 *
 * @param {user} Object the current user placing the order
 * @param {order} Object the order
 *
 * Dispatches either an ORDER_CREATED_EVENT or an ORDER_FULFILLED_EVENT
 * depending on whether an order ID for that order type exists in
 * localStorage.
 *
 * Dispatching the ORDER_CREATED_EVENT requires the orderId to
 * not yet exist in localStorage
 *
 * Dispatching the ORDER_FULFILLED_EVENT requires the existing order ID
 * to match the order.id.
 *
 * The order ID is cleared from localStorage after fulfillment.
 *
 * Using localStorage to ensure we are only sending order events once
 * per event per order.
 */

export const dispatchOrderEvent = (orderEvent, { user, order }) => {
  const orderKey = `order_id_for_order_type_${order.order_type}`
  const orderId = parseInt(localStorage.getItem(orderKey))

  switch (orderEvent) {
    case ORDER_CREATED_EVENT:
      if (!orderId) {
        dispatchGlobalEvent(ORDER_CREATED_EVENT, {
          user,
          order
        })
        // set the orderKey
        localStorage.setItem(orderKey, order.id)
      }
      break
    case ORDER_FULFILLED_EVENT:
      // order.id must match existing order ID
      if (orderId === order.id) {
        dispatchGlobalEvent(ORDER_FULFILLED_EVENT, {
          user,
          order
        })
        // clear the orderKey
        localStorage.removeItem(orderKey)
      }
      break

    default:
      throw new Error(`Order event not recognized. Received "${orderEvent}"`)
  }
}
