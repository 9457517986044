import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import get from "lodash/get"
import { useEffect } from "react"
import { usePathwrightContext } from "../pathwright/PathwrightContext"
import { ORDER_FULFILLED_EVENT } from "../utils/dispatcher"
import ORDER_QUERY from "./graphql/order-query"
import { dispatchOrderEvent } from "./utils/order-events"

export const useOnOrderFullfiled = ({ order, onOrderFulfilled }) => {
  const { me } = usePathwrightContext()
  const orderFullfilled = !!order && order.order_status === "fulfilled"

  useEffect(() => {
    if (orderFullfilled) {
      onOrderFulfilled && onOrderFulfilled(order)
      dispatchOrderEvent(ORDER_FULFILLED_EVENT, { user: me, order })
    }
  }, [orderFullfilled])
}

export default function useOrderPollingQuery(orderId) {
  const { me } = usePathwrightContext()

  const orderQuery = useQuery(ORDER_QUERY, {
    skip: !orderId,
    pollInterval: 3000,
    variables: {
      id: orderId
    }
  })

  const orderStatus = get(orderQuery, "data.order.order_status")

  useEffect(() => {
    if (orderStatus === "fulfilled") {
      orderQuery.stopPolling()
      dispatchOrderEvent(ORDER_FULFILLED_EVENT, {
        user: me,
        order: orderQuery.data.order
      })
    }
  }, [orderStatus])

  return orderQuery
}
