import { graphql } from "@apollo/client/react/hoc"
import Link from "@pathwright/ui/src/components/link/Link"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import Text from "@pathwright/ui/src/components/ui/Text"
import compose from "lodash/flowRight"
import get from "lodash/get"
import moment from "moment"
import styled from "styled-components"

import ACCOUNT_QUERY from "../../account/graphql/account-query"
import { flattenEdges } from "../../utils/apollo"
import SCHOOL_BILLING_INVOICES_QUERY from "../graphql/school-billing-invoices-query"
import { InvoiceType, SubscriptionType } from "../types"
import { usdNoDec } from "../utils"

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 10px 7px;
  background-color: #fff;

  .Pathicon {
    margin-right: 6px;
    color: rgba(0, 0, 0, 0.4);
  }
`

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 2rem 20px;
  background-color: #fff;
`

const LinkItem = styled.div`
  display: flex;
  padding: 5px 0;

  a.Link {
    font-size: 0.9em;
    text-decoration: underline;
  }
`

const StyledBodyText = styled(Text.Body)`
  font-size: 0.875em;
  color: rgba(0, 0, 0, 0.4);
  line-height: 1.5em;
  font-weight: 400;
  margin-bottom: 0.5rem;
`

const OverdueBill = ({ invoice, subscription }) => (
  <>
    <StyledHeader>
      <div style={{ display: "flex" }}>
        <Pathicon icon="statuscaution" />
        <Text.H4>Overdue Invoice</Text.H4>
      </div>
      <Text.Meta style={{ fontSize: "0.9em", color: "#333" }}>
        {usdNoDec(invoice.total_amount_billed)}
      </Text.Meta>
    </StyledHeader>
    <StyledContent>
      <StyledBodyText>
        {`We were unable to charge your card ${
          subscription.card_last_4
            ? `ending in ${subscription.card_last_4}`
            : ``
        } for your bill due on ${moment(invoice.cycle_end).format(
          "MMM Do, YYYY"
        )}. To keep your account active, please update your card and pay the overdue amount as soon as possible.`}
      </StyledBodyText>
      {window.location.pathname.includes("billing/payment") ? null : (
        <LinkItem>
          <Link target="_blank" to={"/manage/school/billing/payment/"}>
            Update your card to pay now
          </Link>
        </LinkItem>
      )}
      <LinkItem>
        <Link
          target="_blank"
          to={invoice.url}
          style={{ color: "rgba(0, 0, 0, 0.4)" }}
        >
          View overdue invoice
        </Link>
      </LinkItem>
    </StyledContent>
  </>
)

const ManageBillingOverdueBill = ({
  subscription,
  invoice,
  loading,
  error,
  children
}) => {
  if (loading || error || !subscription || !invoice) return null

  const OverdueComponent = (
    <OverdueBill invoice={invoice} subscription={subscription} />
  )
  if (typeof children === "function") {
    return children(OverdueComponent)
  }

  return OverdueComponent
}

ManageBillingOverdueBill.propTypes = {
  subscription: SubscriptionType,
  invoice: InvoiceType
}

export default compose(
  graphql(ACCOUNT_QUERY, {
    skip: (props) => props.hasOwnProperty("subscription"),
    props: ({ data }) => {
      const { error, loading, account } = data
      return {
        error,
        loading,
        subscription: get(account, "billing_subscription.subscription", null)
      }
    }
  }),
  graphql(SCHOOL_BILLING_INVOICES_QUERY, {
    skip: (props) => props.hasOwnProperty("invoice"),
    props: ({ data }) => {
      const delinquentInvoices = flattenEdges(
        get(data, "school.school_billing_invoices")
      )

      if (!delinquentInvoices) return { invoice: null }

      // For now, we'll only concern ourselves with the first (latest) delinquent invoice.
      // Having more than one at a time is extremely rare.
      return {
        invoice: delinquentInvoices.filter((i) => i.is_overdue)[0]
      }
    }
  })
)(ManageBillingOverdueBill)
