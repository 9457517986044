import { useEffect, useState } from "react"

const useControlledState = (valueProp, setValueProp) => {
  const [value, setValue] = useState(valueProp)

  // external state changed, send change downstream
  useEffect(() => {
    if (value !== valueProp) setValue(valueProp)
  }, [valueProp])

  // internal state changed, send change upstream
  useEffect(() => {
    if (value !== valueProp) setValueProp && setValueProp(value)
  }, [value])

  return [value, setValue]
}

export default useControlledState
