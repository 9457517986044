import styled from "styled-components"
import Text from "../../ui/Text"
import { components } from "./ReactSelectInput"
import ReactSelectInputOptionLabel from "./ReactSelectInputOptionLabel"

const Container = styled.div`
  padding: 10px;
  cursor: pointer;
  /*
  background-color: ${(p) =>
    p.selected
      ? "rgba(0, 0, 0, 0.05)"
      : p.focused
      ? "rgba(0, 0, 0, 0.03)"
      : "transparent"};
  */

  /*
  &:hover {
    ${(p) => (!p.selected ? "background-color: rgba(0, 0, 0, 0.03);" : "")}
  }
  */

  > p:last-child {
    margin-top: 5px;
  }
`

const ReactSelectInputOption = ({
  icon,
  label,
  descriptor,
  ...optionProps
}) => {
  const { data, isFocused, isSelected, selectOption } = optionProps

  return (
    <components.Option {...optionProps}>
      <Container
        onClick={() => selectOption(data)}
        focused={isFocused}
        selected={isSelected}
      >
        <ReactSelectInputOptionLabel
          icon={icon}
          label={label}
          inverted={isSelected}
        />
        <Text.Body
          dangerouslySetInnerHTML={{ __html: descriptor }}
          inverted={isSelected}
        />
      </Container>
    </components.Option>
  )
}

export default ReactSelectInputOption
