import * as c from "./constants"
import { MediaFilter, MediaProviderType } from "./types"
import { ReducerActionType } from "./useSyncedReducer"

// Saved filter in Media Filters dropdown
export const LOCAL_STORAGE_FILTER_KEY: string = "currentBlocksFilter"

// Media Manager filter dropdown
export const MEDIA_FILTERS: MediaFilter.Options = {
  all: {
    label: "all media",
    value: ""
  },
  tagged: {
    label: "tagged media",
    value: "tagged"
  },
  recent: {
    label: "recent media",
    value: "recent"
  },
  uploads: {
    label: "your uploads",
    value: "uploads"
  }
}

export const getFilterFromLocalStorage = (): MediaFilter.Type | null => {
  return window.localStorage.getItem(
    LOCAL_STORAGE_FILTER_KEY
  ) as MediaFilter.Type
}

export const setFilterInLocalStorage = (
  currentFilter: MediaFilter.Type
): void => {
  window.localStorage.setItem(LOCAL_STORAGE_FILTER_KEY, currentFilter)
}

// Results to show per page in Media Manager
const HITS_PER_PAGE = 9

export const defaultState: MediaProviderType.State = {
  renderedMedia: [],
  renderedTags: [],
  isUploading: false,
  uploadingVideo: false,
  uploadCount: 0,
  isEditing: false,
  isLoading: false,
  currentSearch: "",
  currentFilter: MediaFilter.Type.ALL,
  selectedMediaItem: null,
  selectedBatch: [],
  currentPageCount: HITS_PER_PAGE,
  currentPage: 1,
  error: null
}

export const getInitialState = (props: MediaProviderType.Props) => {
  return {
    ...defaultState,
    currentFilter: getFilterFromLocalStorage() || MediaFilter.Type.ALL
  }
}

export const reducer = (
  state: MediaProviderType.State,
  action: ReducerActionType
) => {
  const { type, payload } = action

  switch (type) {
    case c.RESET:
      return {
        ...state,
        selectedMediaItem: null,
        isEditing: false,
        isUploading: false,
        uploadingVideo: false,
        selectedBatch: [],
        isLoading: false,
        ...(payload || {})
      }
    case c.ERROR:
      return {
        ...state,
        error: payload
      }
    case c.SET_RENDERED_MEDIA:
      return {
        ...state,
        renderedMedia: payload
      }
    case c.SET_RENDERED_TAGS:
      return {
        ...state,
        renderedTags: payload
      }
    case c.SET_SELECTED_BATCH:
      return {
        ...state,
        selectedBatch: payload
      }
    case c.CLEAR_SELECTED_BATCH:
      return {
        ...state,
        selectedBatch: []
      }
    case c.DESELECT_MEDIA:
      return {
        ...state,
        selectedMediaItem: null
      }
    case c.START_UPLOADING:
      return {
        ...state,
        isUploading: true,
        uploadingVideo: payload.isVideo || false
      }
    case c.STOP_UPLOADING:
      return {
        ...state,
        isUploading: false,
        uploadingVideo: false
      }
    case c.SET_UPLOAD_COUNT:
      return {
        ...state,
        uploadCount: payload
      }
    case c.PAGINATE_MEDIA:
      return {
        ...state,
        currentPage: payload
      }
    case c.START_EDITING:
      return {
        ...state,
        isEditing: true,
        selectedMediaItem: payload
      }
    case c.STOP_EDITING:
      return {
        ...state,
        isEditing: false,
        selectedMediaItem: null
      }
    case c.SET_FILTER:
      return {
        ...state,
        currentFilter: payload,
        currentPage: 1
      }
    case c.SET_SEARCH:
      return {
        ...state,
        currentSearch: payload
        // currentPage: 1 // ?
      }
    case c.START_LOADING:
      return {
        ...state,
        isLoading: true
      }
    case c.STOP_LOADING:
      return {
        ...state,
        isLoading: false
      }
    case c.SAVE_MEDIA_BATCH:
    case c.DELETE_BATCH:
    case c.DELETE_MEDIA:
      return {
        ...state
      }
    case c.START_SAVING_MEDIA:
      return {
        ...state,
        isLoading: true
      }
    default:
      console.log(
        "unrecognized action type: ",
        type,
        "payload: ",
        payload,
        "state: ",
        state,
        ""
      )
      return state
  }
}
