import { Box, Spinner } from "@chakra-ui/react"
import ThemeBackground from "@pathwright/ui/src/components/background/ThemeBackground"
import PortableFullscreen from "@pathwright/ui/src/components/fullscreen/PortableFullscreen"
import useWindowSize from "@pathwright/ui/src/components/hooks/useWindowSize"
import { usePortableContext } from "@pathwright/ui/src/components/portable/Portable"
import { ToggleIntercomVisibility } from "@pathwright/web/src/modules/intercom/IntercomProvider"
import { usePathwrightContext } from "@pathwright/web/src/modules/pathwright/PathwrightContext"
import { AnimatePresence, motion } from "framer-motion"
import { useEffect, useMemo } from "react"
import { createGlobalStyle } from "styled-components"
import { getChangeLogEmbedUrl } from "./utils"

const GlobalChangelogStyles = createGlobalStyle`
  .loading-splash {
    display: none;
  }
`

const transition = {
  type: "spring",
  stiffness: 180, // stiffneess is how much it resists the spring
  damping: 24 // damping is how much it wobbles
}

const useWidth = () => {
  const size = useWindowSize()
  const minW = size.width >= 1000 ? Math.min(400, size.width) : size.width
  const width = Math.max(minW, 400)
  return width
}

function Changelog({ closing, onClose, onCloseEnd, ...rest }) {
  const { me } = usePathwrightContext()
  let padding = 10

  const { setPorting } = usePortableContext()
  const width = useWidth()
  const fullscreen = width === window.innerWidth

  // Avoid animating the width property when in fullscreen.
  const motionProps = fullscreen
    ? {
        initial: { opacity: 0 },
        animate: { opacity: 1, transition },
        exit: { opacity: 0, transition }
      }
    : {
        initial: { opacity: 0, width: 0 },
        animate: { opacity: 1, width, transition },
        exit: { opacity: 0, width: 0, transition }
      }

  const iframeSrcURL = useMemo(() => new URL(getChangeLogEmbedUrl(me)), [me])

  useEffect(() => {
    setPorting(fullscreen)
  }, [fullscreen])

  // Close when the user clicks the "x" button on the Path App path.
  useEffect(() => {
    const handler = (event) => {
      if (event.origin !== iframeSrcURL.origin) return
      if (event.data?.action === "close") {
        onClose()
      }
    }

    window.addEventListener("message", handler)
    return () => {
      window.removeEventListener("message", handler)
    }
  }, [iframeSrcURL])

  return (
    <>
      <GlobalChangelogStyles />
      <AnimatePresence>
        {!closing && (
          <ToggleIntercomVisibility>
            <Box
              as={motion.div}
              key="changelog-augmentation"
              h="100vh"
              // Overriding framer motions animtation on width when in fullscreen.
              w={fullscreen ? `${width}px !important` : `${width}px`}
              flexGrow="1"
              flexShrink="0"
              position="relative"
              onAnimationComplete={onCloseEnd}
              {...motionProps}
              {...rest}
            >
              {fullscreen && <ThemeBackground />}
              <Box
                pos="absolute"
                inset={`${padding}px`}
                rounded="xl"
                overflow="hidden"
              >
                <Box
                  pos="absolute"
                  inset={0}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  h="full"
                  w="full"
                >
                  <Spinner color="white" size="xl" />
                </Box>
                <Box
                  as="iframe"
                  src={iframeSrcURL}
                  pos="relative"
                  h="full"
                  w={`${width - padding * 2}px`}
                ></Box>
              </Box>
            </Box>
          </ToggleIntercomVisibility>
        )}
      </AnimatePresence>
    </>
  )
}

function ChangelogContainer(props) {
  // The basic idea behind using the reverse portal is that changing screen width sizes
  // won't result in a unmount/remount, but there is an inherent quality in iframes
  // the will always result in a reload when moved in the DOM, so not much gained.
  // See: https://github.com/httptoolkit/react-reverse-portal/issues/14
  return (
    <PortableFullscreen showIcons={false}>
      <Changelog {...props} />
    </PortableFullscreen>
  )
}

export default ChangelogContainer
