import AvatarCardTrigger from "@pathwright/ui/src/components/avatar/AvatarCardTrigger"
import withTranslate from "@pathwright/ui/src/components/lng/withTranslate"
import Text from "@pathwright/ui/src/components/ui/Text"
import PropTypes from "prop-types"
import styled from "styled-components"
import { MentorGroupMemberControlsMenu } from "./MentorGroupMemberControls"

const Col = styled.li`
  padding: 0 10px;
  h5 {
    white-space: nowrap;
  }
  select,
  option {
    text-transform: capitalize;
  }
`

const MentorGroupStaffListItem = ({ membership, tca }) => {
  return (
    <Col key={membership.id}>
      <AvatarCardTrigger
        className="MentorGroupStaffListItemAvatarCardTrigger"
        user={membership.user}
        size="42px"
        renderDropdown={() => (
          <MentorGroupMemberControlsMenu membership={membership} />
        )}
      >
        {
          <>
            <Text.H6 inverted style={{ fontSize: "0.875em" }}>
              {membership.user.display_name}
            </Text.H6>
            <Text.Meta inverted>
              {tca(`mentor_group.member.${membership.role}.label`)}
            </Text.Meta>
          </>
        }
      </AvatarCardTrigger>
    </Col>
  )
}

MentorGroupStaffListItem.propTypes = {
  membership: PropTypes.object.isRequired
}

export default withTranslate(MentorGroupStaffListItem)
