import { snakeCase } from "change-case"
import pick from "lodash/pick"
import { useMemo } from "react"
import { NumberParam, useQueryParam } from "use-query-params"

const keys = ["resourceId"]

// Util for getting the expected certificateScope keys to pass to tag queries/mutations.
export const getCertificateScope = (certificateScope) => {
  if (certificateScope) {
    const pickedContext = Object.entries(certificateScope).reduce(
      (acc, [k, v]) =>
        keys.includes(k) && v
          ? {
              ...acc,
              [k]: v
            }
          : acc,
      {}
    )

    if (Object.keys(pickedContext).length) {
      return pickedContext
    }
  }

  return null
}

export const getCertificateContextParams = (certificateScope) => {
  if (certificateScope) {
    return Object.entries(pick(certificateScope, keys)).reduce(
      (acc, [k, v]) => ({
        ...acc,
        [snakeCase(k)]: v
      }),
      {}
    )
  }

  return {}
}

const useCertificateScope = (certificateScope) => {
  const [resourceId] = useQueryParam("resource_id", NumberParam)
  return useMemo(() => {
    const _certificateScope = getCertificateScope({
      resourceId,
      ...(certificateScope || {})
    })
    return _certificateScope
  }, [resourceId])
}

export default useCertificateScope
