// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
// Configure any UI based settings/variables
// const MobileDetect = require("mobile-detect")

$(function () {
  // Add classes to body based on browser
  // if $.browser.msie
  //   version = "#{$.browser.version}"
  //   version = version.replace(".", "-")
  //   $('body')
  //     .addClass("ie ie-#{version}")

  // Touch/no-touch detection
  var isTouch = "ontouchstart" in window || navigator.msMaxTouchPoints > 0
  $("html").toggleClass("touch", isTouch)
  $("html").toggleClass("no-touch", !isTouch)

  Pathwright.reqres.setHandler("ui:is:touch", () => isTouch)

  return Pathwright.reqres.setHandler("ui:is:mobile", function () {
    return isTouch
  })
})
;(($) =>
  ($.fn.alterClass = function (removals, additions) {
    const self = this
    if (removals.indexOf("*") === -1) {
      // Use native jQuery methods if there is no wildcard matching
      self.removeClass(removals)
      return !additions ? self : self.addClass(additions)
    }
    const patt = new RegExp(
      `\\s${removals
        .replace(/\*/g, "[A-Za-z0-9-_]+")
        .split(" ")
        .join("\\s|\\s")}\\s`,
      "g"
    )
    self.each(function (i, it) {
      let cn = ` ${it.className} `
      while (patt.test(cn)) {
        cn = cn.replace(patt, " ")
      }
      return (it.className = $.trim(cn))
    })

    if (!additions) {
      return self
    } else {
      return self.addClass(additions)
    }
  }))(jQuery)
