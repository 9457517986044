import { useMutation } from "@apollo/client"
import Button from "@pathwright/ui/src/components/button/Button"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import Tooltip from "@pathwright/ui/src/components/tooltip/Tooltip"
import styled from "styled-components"
import { usePathwrightContext } from "../../../pathwright/PathwrightContext"
import CREATE_DISCUSSION_SUBSCRIPTION_MUTATION from "../../graphql/create-discussion-subscription-mutation"
import DELETE_DISCUSSION_SUBSCRIPTION_MUTATION from "../../graphql/delete-discussion-subscription-mutation"
import { canSubscribeToDiscussion } from "../../permissions"
import { getDiscussionContext } from "./utils"

const StyledTooltip = styled(Tooltip)`
  .Tooltip {
    min-width: 140px;
    white-space: normal;
    text-align: left;
  }
`

const DiscussionSubscription = ({ discussion }) => {
  const { t } = useTranslate()
  const pwContext = usePathwrightContext()
  const discussionContext = getDiscussionContext(discussion)

  const [createDiscussionSubscription] = useMutation(
    CREATE_DISCUSSION_SUBSCRIPTION_MUTATION,
    {
      variables: {
        context: discussionContext,
        discussion_id: discussion.id
      }
    }
  )

  const [deleteDiscussionSubscription] = useMutation(
    DELETE_DISCUSSION_SUBSCRIPTION_MUTATION,
    {
      variables: {
        context: discussionContext,
        discussion_id: discussion.id
      }
    }
  )

  const handleToggleSubscription = (e) => {
    e.stopPropagation()

    if (discussion.user_is_subscribed) return deleteDiscussionSubscription()
    if (canSubscribeToDiscussion(pwContext, discussion))
      return createDiscussionSubscription()
  }

  const tooltip = discussion.user_is_subscribed
    ? t("You'll be notified when someone replies to this post.")
    : t("Get notified when someone replies to this post.")
  const icon = discussion.user_is_subscribed ? t("bell-fill") : t("bell")

  return (
    <StyledTooltip title={tooltip}>
      <Button
        styleType="tertiary"
        onClick={handleToggleSubscription}
        brand={discussion.user_is_subscribed}
        interactive={canSubscribeToDiscussion(pwContext, discussion)}
        style={{ padding: ".5em", borderRadius: "8px" }}
        icon={icon}
        size="large"
      />
    </StyledTooltip>
  )
}

DiscussionSubscription.displayName = "DiscussionSubscription"

export default DiscussionSubscription
