import Card from "@pathwright/ui/src/components/card/Card"
import CardHeader from "@pathwright/ui/src/components/card/CardHeader"
import CardLink from "@pathwright/ui/src/components/card/CardLink"
import PropTypes from "prop-types"
import PathwrightAdminCardLink from "../../pathwright-admin/PathwrightAdminCardLink"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"

const ManageAccountMenu = ({
  card,
  activated,
  billingAlert,
  can_manage_billing
}) => {
  const { school, me } = usePathwrightContext()

  return (
    <Card card={card} navless>
      <CardHeader
        card={card}
        title={school.name}
        meta="Account settings & customization."
      />
      <CardLink
        icon="info"
        label="Update account info & branding"
        meta="Setup your domain, brand, support information and more."
        to={`${card.path}account/`}
      />
      <CardLink
        icon="shapes"
        label="Configure features & integrations"
        meta="Manage commerce, sign-in options, certificates, and more."
        to={`${card.path}features/`}
      />
      <CardLink
        icon="grid-large"
        label="Configure library & media"
        meta="Organize your course library and more."
        to={`${card.path}library/`}
      />
      <CardLink
        icon="download"
        label="Download reports"
        meta="Download all your important data as CSV reports"
        to={`${card.path}reports/`}
      />
      {billingAlert ? (
        <CardLink
          icon="credit-card"
          label="Update billing information ⚠️"
          meta="Please update your card to continue using your account"
          to={`${card.path}billing/`}
        />
      ) : can_manage_billing ? (
        <CardLink
          icon="credit-card"
          label="Manage billing"
          // Currently curriculum subscribers can't update their plan (also their plan isn't a "Pathwright" plan)
          meta={`Update your ${
            school.is_curriculum_subscriber ? "" : "Pathwright plan, "
          }card on file, and review your billing history.`}
          to={`${card.path}billing/`}
        />
      ) : !activated ? (
        <CardLink
          icon="rocket"
          label="Activate your account"
          meta="Pick a Pathwright plan and start teaching"
          to={{
            pathname: "/pricing/",
            query: { utm_source: "Admin Billing Card" },
            state: { setResourceLaunchedFrom: true }
          }}
        />
      ) : null}
      {me && me.permissions.has_pathwright_staff_access && (
        <PathwrightAdminCardLink card={card} />
      )}
    </Card>
  )
}

ManageAccountMenu.displayName = "ManageAccountMenu"

ManageAccountMenu.propTypes = {
  // provided by CardStack > CardStackCard
  card: PropTypes.object.isRequired,
  // is account on an active plan?
  activated: PropTypes.bool.isRequired,
  // is account overdue, can't charge to card, etc?
  billingAlert: PropTypes.bool.isRequired
}

export default ManageAccountMenu
