import gql from "graphql-tag"
import SCHOOL_FRAGMENT from "../../school/graphql/school-fragment"
import SCHOOL_BILLING_INVOICE_FRAGMENT from "./school-billing-invoice-fragment"

export default gql`
  query UpcomingSchoolBillingInvoiceQuery {
    school {
      ...School
      upcoming_school_billing_invoice {
        ...SchoolBillingInvoice
        # these fields only present on the upcoming invoice
        parent_subscription
        child_subscriptions
      }
    }
  }
  ${SCHOOL_FRAGMENT}
  ${SCHOOL_BILLING_INVOICE_FRAGMENT}
`
