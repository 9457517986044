import {
  Box,
  Checkbox,
  CheckboxGroup,
  HStack,
  Text,
  VStack
} from "@chakra-ui/react"
import { CheckIcon, MinusIcon } from "@pathwright/pathicons"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import { Fragment, forwardRef, useMemo } from "react"
import { Virtuoso } from "react-virtuoso"
import PeopleItem from "./PeopleItem"
import SelectItemRole from "./SelectItemRole"
import { useSelectPeopleContext } from "./SelectPeopleContext"
import {
  getIsValidItem,
  getUserItemAlreadyAdded,
  getUserItemAlreadyAddedLabel
} from "./utils"

const tPrefix = "share_card.add_tab"

const SelectAllItems = () => {
  const {
    itemsOfQueued: items,
    peopleSelection: { pending },
    addToPeopleSelection,
    removeFromPeopleSelection
  } = useSelectPeopleContext()

  const { tc } = useTranslate()

  const handleCheckAll = (isChecked) => {
    if (isChecked) {
      addToPeopleSelection(items)
    } else {
      removeFromPeopleSelection(items)
    }
  }

  const validCount = useMemo(() => items.filter(getIsValidItem).length, [items])
  const isValid = validCount > 0
  const allChecked = isValid && validCount === pending.length
  const isIndeterminate = !!pending.length && !allChecked

  return (
    <Checkbox
      // For some reason the disabled checkbox shows a checkmark even though isChecked is false??
      icon={
        isValid ? isIndeterminate ? <MinusIcon /> : <CheckIcon /> : <Fragment />
      }
      iconColor={"primaryBrandColor.main"}
      onChange={(e) => handleCheckAll(e.target.checked)}
      onKeyUp={(e) => {
        if (e.keyCode === 13) {
          handleCheckAll(!e.target.checked)
        }
      }}
      isChecked={allChecked}
      isIndeterminate={isIndeterminate}
      role="checkbox"
      aria-checked={allChecked}
      isDisabled={!isValid}
      w="100%"
      pl={4}
      py={4}
    >
      <Text size="xs" fontWeight={600}>
        {tc(`${tPrefix}.Select all`)}
      </Text>
    </Checkbox>
  )
}

const Item = ({ index, item, isChecked }) => {
  const { addToPeopleSelection, removeFromPeopleSelection } =
    useSelectPeopleContext()

  const { tc } = useTranslate()

  const isValid = getIsValidItem(item)

  const handleCheck = (item, isChecked) => {
    if (isChecked) {
      addToPeopleSelection(item)
    } else {
      removeFromPeopleSelection(item)
    }
  }

  return (
    <HStack
      key={item.meta.key}
      width="100%"
      justifyContent="space-between"
      px={4}
      data-testId={`selected-queued-people-item-${index}`}
    >
      <Checkbox
        value={item.id}
        // For some reason the disabled checkbox shows a checkmark even though isChecked is false??
        icon={isValid ? <CheckIcon /> : <Fragment />}
        // iconColor={"brand.500"}
        flexGrow={1}
        minW={0}
        onChange={(e) => handleCheck(item, e.target.checked)}
        onKeyUp={(e) => {
          if (e.keyCode === 13) {
            handleCheck(item, !e.target.checked)
          }
        }}
        isChecked={isChecked}
        isDisabled={!isValid}
        role="checkbox"
        aria-checked={isChecked}
      >
        <PeopleItem item={item} />
      </Checkbox>
      {isValid && <SelectItemRole item={item} />}
      {getUserItemAlreadyAdded(item) && (
        <Text color="gray.500" opacity={0.4}>
          {getUserItemAlreadyAddedLabel(item, tc)}
        </Text>
      )}
    </HStack>
  )
}

// Sole goal of overriding the Scroller is to move the groupContent component
// to render before the itemContent. This results in better Accessibility as
// tabbing into the Virtuoso items will first go to the "Select all" checkbox
// rather than the first item.
const Scroller = forwardRef((props, ref) => (
  <Box
    ref={ref}
    {...props}
    borderRadius="inherit"
    // Styling the Group component, though technically a parent of the Group and
    // unfortunately unable to style the parent otherwise.
    sx={{
      // Layer Group over items.
      ["> div:first-child"]: {
        backgroundColor: "white",
        position: "relative",
        zIndex: 10
      }
    }}
  >
    {props.children.slice().reverse()}
  </Box>
))

const List = ({ items, pending }) => (
  <Box
    w="100%"
    sx={{
      // Fix scroll jank when interacting with an open MenuList near bottom of scroll container.
      ["[data-virtuoso-scroller='true'], [data-viewport-type='element']"]: {
        position: "static !important"
      },
      ["[data-viewport-type='element'] > div"]: {
        // Counteract the padding applied to the list which is meant to accomodate the groupContent
        // but with static positioning, results in unwanted space. Unfortunately must hard-code. Resetting
        // paddingTop to 0 results in space at the bottom of the list, and Virtuosos updates paddign top and bottom
        // when accounting for non-rendered items in the list.
        marginTop: "-51px !important"
      }
    }}
  >
    <Virtuoso
      style={{
        height: "300px",
        width: "100%"
      }}
      components={{ Scroller }}
      groupCounts={[items.length]}
      groupContent={(index) => <SelectAllItems />}
      itemContent={(index) => (
        <Item
          index={index}
          item={items[index]}
          isChecked={pending.some(
            (pendingItem) => pendingItem.id === items[index].id
          )}
        />
      )}
    />
  </Box>
)

const SelectQueuedPeople = () => {
  const {
    itemsOfQueued: items,
    peopleSelection: { pending }
  } = useSelectPeopleContext()

  return (
    <VStack spacing={2} alignItems="flex-start" borderRadius="inherit">
      <CheckboxGroup value={pending.map((item) => item.id)}>
        {!!items.length && <List items={items} pending={pending} />}
      </CheckboxGroup>
    </VStack>
  )
}

export default SelectQueuedPeople
