import { graphql } from "@apollo/client/react/hoc"
import { useScrollContext } from "@pathwright/ui/src/components/scroll/ScrollView"
import compose from "lodash/flowRight"
import get from "lodash/get"
import PropTypes from "prop-types"
import { useEffect } from "react"
import { flattenEdges, withPaginator } from "../utils/apollo"
import ActivityTimeline from "./ActivityTimeline"
import { LEARNING_ACTION_QUERY_FILTERS } from "./constants"
import ACTIVITY_QUERY from "./graphql/activity-query"

// component concerns:
// - describe query and set query variables from props
// - map query data to props
// - abstract and contain pagination logic

const ActivityContainer = (props) => {
  const { hasMore, loadingMore, activity, pageInfo, refetch } = props

  useEffect(() => {
    // force refetch if mounting w/ cached data
    if (activity && pageInfo) {
      refetch()
    }
  }, [])

  const loadMore = () => !loadingMore && hasMore && props.loadMore()

  const scrollContext = useScrollContext()
  scrollContext &&
    scrollContext.useScroll({
      onScrollBottom: loadMore
    })

  return <ActivityTimeline {...props} loadMore={loadMore} />
}

ActivityContainer.displayName = "ActivityContainer"

ActivityContainer.propTypes = {
  actionType: PropTypes.string,
  resourceId: PropTypes.number,
  groupId: PropTypes.number,
  stepId: PropTypes.number,
  userId: PropTypes.number
}

export default compose(
  graphql(ACTIVITY_QUERY, {
    options: (props) => ({
      fetchPolicy: "network-only", //"cache-and-network",
      variables: {
        action_type: LEARNING_ACTION_QUERY_FILTERS[props.actionType]
          ? JSON.parse(LEARNING_ACTION_QUERY_FILTERS[props.actionType])
          : null,
        resource_id: props.resourceId,
        group_id: props.groupId,
        step_id: props.stepId,
        user_id: props.userId,
        include_user: !!props.userId
      },
      notifyOnNetworkStatusChange: true
    }),
    props: ({ data, ownProps }) => {
      return {
        data, // for withPaginator
        error: data.error,
        loading: data.loading,
        refetch: data.refetch,
        activity_user: data.activity_user,
        activity: flattenEdges(get(data, "activity")),
        pageInfo: get(data, "activity.pageInfo")
      }
    }
  }),
  withPaginator({ path: "activity" })
)(ActivityContainer)
