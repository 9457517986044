const { BILLING_INTERVAL_MONTH, PRICING_STEPS } = require("./constants")
const { PATHWRIGHT_SCHOOL_ID } = require("lib/core/constants")

class BillingStore extends require("lib/static-shim").default(
  require("lib/core/store/backbone-store").default
) {
  static initClass() {
    // controls where the back button goes, options are school, dotcom or a relative URL

    this.prototype.resources = {
      plans: {
        collection: require("./models/pathwright-plan-collection").default
      },
      subscription: {
        model: require("./models/pathwright-subscription").default
      }
    }

    this.prototype.actions = {
      loadPlans(forceReload) {
        if (forceReload == null) {
          forceReload = false
        }
        return this.resource.plans
          .list({}, forceReload)
          .then(this.action.selectDefaultPlan)
      },

      selectPlan(plan) {
        if (plan.id !== __guard__(this.get("selected_plan"), (x) => x.id)) {
          const update = {
            selected_plan: plan,
            // billing_interval: 1
            learner_count: plan.get("learners_included")
          }
          return this._mutate(update)
        }
      },

      selectDefaultPlan() {
        const plans = this.get("plans")
        const defaultPlan = plans.find(
          (model) => model.get("name") === "Essentials"
        )
        if (defaultPlan) {
          return this.action.selectPlanById(defaultPlan.id)
        } else {
          this.action.selectPlanById(plans.first().id)
          return console.warn("No default plan found")
        }
      },

      selectPlanById(id) {
        const plans = this.get("plans")
        const plan = plans.get(id)
        if (plan) {
          return this.action.selectPlan(plan)
        } else {
          return console.warn("No pricing plan with id ", id)
        }
      },

      selectPlanByIndex(index) {
        const plans = this.get("plans")
        const plan = plans.at(index)
        if (plan) {
          return this.action.selectPlan(plan)
        } else {
          return console.warn("No pricing plan at index ", index)
        }
      },

      setIsSubscribing(is_subscribing) {
        return this._mutate({ is_subscribing })
      },

      selectStepByKey(key) {
        return this._mutate({ current_step: key })
      },

      setBillingInterval(billing_interval) {
        return this._mutate({ billing_interval })
      }
    }

    this.prototype.requests = {
      getPrevStep() {
        const current_step = this.get("current_step")
        const current_index = PRICING_STEPS.indexOf(current_step)
        return PRICING_STEPS[current_index - 1]
      },

      getNextStep() {
        const current_step = this.get("current_step")
        const current_index = PRICING_STEPS.indexOf(current_step)
        return PRICING_STEPS[current_index + 1]
      },

      getFromUrl() {
        const { school } = this.getStore("school").getState()

        // if (
        //   document.referrer &&
        //   new URL(document.referrer).hostname !== document.hostname
        // ) {
        //   return document.referrer
        // }

        // if (school.id === PATHWRIGHT_SCHOOL_ID) {
        //   return "http://www.pathwright.com"
        // }

        // Avoid navigating to home location if we can find a resource_launched_from location
        const resourceLaunchedFrom = this.getStore("navigation").get(
          "resource_launched_from"
        )
        if (resourceLaunchedFrom) {
          return resourceLaunchedFrom
        } else {
          return this.getStore("navigation").request.getHomeLocation()
        }
      },

      toCurrentStep() {
        const current_step = this.get("current_step")
        if (current_step) {
          return `/pricing/${current_step}/`
        } else {
          return "/pricing/plan/"
        }
      },

      getSelectedPlanIndex() {
        const plans = this.get("plans")
        const selected_plan = this.get("selected_plan")
        if (plans && selected_plan) {
          return plans.indexOf(selected_plan)
        }
      },

      getPlanIndexById(id) {
        const plans = this.get("plans")
        const plan = plans.get(id)
        if (plans && plan) {
          return plans.indexOf(plan)
        }
      },

      getPlanByIndex(index) {
        const plans = this.get("plans")
        if (plans) {
          return plans.at(index)
        }
      },

      getPlanById(id) {
        const plans = this.get("plans")
        const plan = plans.get(id)
        if (plan) {
          return plan.toJSON()
        }
      },

      getPrevPlanById(id) {
        const plans = this.get("plans")
        const plan = plans.get(id)
        const index = plans.indexOf(plan)
        if (index > 0) {
          const prev = plans.at(index - 1)
          return prev.toJSON()
        }
      },

      getPricingUrl(step) {
        return {
          pathname: `/pricing/${step}/`
        }
      },

      getBackUrl() {
        const prevStep = this.request.getPrevStep()
        if (prevStep) {
          return this.request.getPricingUrl(prevStep)
        } else {
          return this.request.getFromUrl()
        }
      },

      canUserActivate() {
        const isAuthenticated = this.getStore("auth").request.isAuthenticated()
        const { school } = this.getStore("school").getState()
        return isAuthenticated && school.id !== PATHWRIGHT_SCHOOL_ID
      },

      isAlreadySubscribed() {
        const isPathwrightGrow =
          this.getStore("school").get("school").id === PATHWRIGHT_SCHOOL_ID
        if (isPathwrightGrow) {
          return false // always allow view pricing on pwg
        } else {
          return window.App.getStore(
            "pathwrightAccount"
          ).request.hasActivePathwrightSubscription()
        }
      }
    }
  }

  defaults() {
    return {
      current_step: null,
      selected_plan: null,
      billing_interval: BILLING_INTERVAL_MONTH
    }
  }

  bootstrapStore(bd) {
    if (
      bd.pathwright_account != null
        ? bd.pathwright_account.subscription
        : undefined
    ) {
      return this._mutate({ subscription: bd.pathwright_account.subscription })
    }
  }
}
BillingStore.initClass()

export default window.App.stores.registerStore("billing", BillingStore)

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null
    ? transform(value)
    : undefined
}
