import { getConstMap } from "@pathwright/ui/src/components/utils/constants"

export const MEMBERSHIP_ROLE_STUDENT = 0
export const MEMBERSHIP_ROLE_FACILITATOR = 10
export const MEMBERSHIP_ROLE_EDITOR = 20
export const MEMBERSHIP_ROLE_ADMIN = 30
// Highest minimum membership type a user's school membership can be set by default
export const HIGHEST_MINIMUM_SCHOOL_ROLE = MEMBERSHIP_ROLE_FACILITATOR

export const MEMBERSHIP_ROLES = getConstMap({
  STUDENT: [0, "student"],
  FACILITATOR: [10, "facilitator"],
  EDITOR: [20, "editor"],
  ADMIN: [30, "administrator"]
})

export const MEMBERSHIP_ROLE_SCHEMA = {
  [MEMBERSHIP_ROLE_STUDENT]: "student",
  [MEMBERSHIP_ROLE_FACILITATOR]: "facilitator",
  [MEMBERSHIP_ROLE_EDITOR]: "editor",
  [MEMBERSHIP_ROLE_ADMIN]: "administrator"
}

export const MEMBERSHIP_STAFF_ROLE_FILTER = {
  gte: MEMBERSHIP_ROLE_SCHEMA[MEMBERSHIP_ROLE_FACILITATOR]
}
export const MEMBERSHIP_STUDENT_ROLE_FILTER = {
  eq: MEMBERSHIP_ROLE_SCHEMA[MEMBERSHIP_ROLE_STUDENT]
}

export const MEMBERSHIP_ARCHIVED_DATE_FILTER = { neq: null }
export const MEMBERSHIP_NOT_ARCHIVED_DATE_FILTER = { eq: null }
export const MEMBERSHIP_ACCESS_REVOKED_DATE_FILTER = { neq: null }

export const REVERSED_MEMBERSHIP_ARCHIVED_DATE_FILTER_MAP = {
  [JSON.stringify(MEMBERSHIP_ARCHIVED_DATE_FILTER)]:
    MEMBERSHIP_NOT_ARCHIVED_DATE_FILTER,
  [JSON.stringify(MEMBERSHIP_NOT_ARCHIVED_DATE_FILTER)]:
    MEMBERSHIP_ARCHIVED_DATE_FILTER
}

export const MEMBERSHIP_FILTER_ALL = "all"
export const MEMBERSHIP_FILTER_STAFF = "staff"
export const MEMBERSHIP_FILTER_MEMBERS = "members"
export const MEMBERSHIP_FILTER_ACCESS_REVOKED = "access removed"
export const MEMBERSHIP_FILTER_ARCHIVED = "archived"

export const MEMBERSHIP_FILTERS = {
  [MEMBERSHIP_FILTER_ALL]: {
    roleFilter: null,
    archivedDateFilter: MEMBERSHIP_NOT_ARCHIVED_DATE_FILTER
  },
  [MEMBERSHIP_FILTER_STAFF]: {
    roleFilter: MEMBERSHIP_STAFF_ROLE_FILTER,
    archivedDateFilter: MEMBERSHIP_NOT_ARCHIVED_DATE_FILTER
  },
  [MEMBERSHIP_FILTER_MEMBERS]: {
    roleFilter: MEMBERSHIP_STUDENT_ROLE_FILTER,
    archivedDateFilter: MEMBERSHIP_NOT_ARCHIVED_DATE_FILTER
  },
  [MEMBERSHIP_FILTER_ACCESS_REVOKED]: {
    roleFilter: null,
    archivedDateFilter: MEMBERSHIP_NOT_ARCHIVED_DATE_FILTER,
    accessRevokedDateFilter: MEMBERSHIP_ACCESS_REVOKED_DATE_FILTER
  },
  [MEMBERSHIP_FILTER_ARCHIVED]: {
    roleFilter: null,
    archivedDateFilter: MEMBERSHIP_ARCHIVED_DATE_FILTER
  }
}

export const getSchoolMembershipRoleLabel = (role, translator) => {
  switch (role) {
    case MEMBERSHIP_ROLE_STUDENT:
      return translator("learner")
    case MEMBERSHIP_ROLE_FACILITATOR:
      return translator("staff")
    case MEMBERSHIP_ROLE_EDITOR:
      return translator("library editor")
    case MEMBERSHIP_ROLE_ADMIN:
      return translator("admin")
  }
}
