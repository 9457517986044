import gql from "graphql-tag"

export default gql`
  fragment PeopleAndGroupConnection on PeopleAndGroupConnection {
    id
    type
    role
    role_in
    people_count
    meta {
      key
      type
      image {
        src
      }
      title
      subtitle
      _search
    }
  }
`
