import { useOutsideClick } from "@chakra-ui/react"
import classnames from "classnames"
import PropTypes from "prop-types"
import React, { useRef, useState } from "react"
import styled from "styled-components"
import { useCertificateContext } from "../context/CertificateContext"
import "../fonts/fonts.css"
import { textObjectPropType } from "../propTypes"
import ObjectControls from "./ObjectControls"
// import { useHotkeys } from "react-hotkeys-hook"

const selectedStyles = `
  /* NOTE: this prevents ugly HTML DnD image when dragging */
  z-index: 10;
  /* NOTE: using outline as it doesn't affect element position */
  outline-style: dashed;
  outline-width: 2px;
  /* NOTE: inner offset required for getting the outline to appear in drag image
  though not ideal as it slightly covers the textarea resize handle. */
  outline-offset: -2px;
  outline-color: rgb(100, 100, 100);
`

const unselectedStyles = `
  z-index: 5;
  /* NOTE: using outline as it doesn't affect element position */
  outline-style: dashed;
  outline-width: 1px;
  /* NOTE: inner offset required for getting the outline to appear in drag image
  though not ideal as it slightly covers the textarea resize handle. */
  outline-offset: -1px;
  outline-color: transparent;
`

const Container = styled.div`
  ${(p) => (p.$isSelected ? selectedStyles : unselectedStyles)}

  .CertificateMultiSelect.isSelecting + * & {
    /* Reset emphasis selection styles when not performing multi-select */
    outline-width: 1px;

    /* Hide controls */
    .ObjectControls {
      opacity: 0 !important;
    }
  }

  /* Emphasize selection when performing multi-select on object */
  .CertificateMultiSelect.isSelecting__${(p) => p.$index}
    + *
    &.DesignObject-${(p) => p.$index} {
    outline-width: 2px;
  }

  /* Ensure objects are visible when interacting with editor */
  .CertificateText:hover &,
  .CertificateText__hasSelection &,
  .CertificateMultiSelect.isSelecting + * & {
    outline-color: rgb(100, 100, 100);
  }

  .CertificateEditor.locked & {
    outline: none;

    .ObjectControls {
      display: none;
    }
  }

  &.DesignObject .DesignObject .ObjectControls {
    display: none;
  }

  /* Hide original text object while dragging the screenshot */
  &.isDragging {
    opacity: 0;
  }

  &:hover .ObjectControls {
    opacity: 1 !important;
  }

  textarea {
    visibility: ${(p) => (p.$isDragging ? "hidden" : "visible")};
  }
`

// NOTE: after removing internal isFocused state, noticing a slight
// delay in hiding the text box after drag start. Just a slightly undesirable UX.
const DesignObject = React.memo(
  ({
    index,
    isSelected,
    onSelect,
    onDeselect,
    onChange,
    onCopy,
    onDelete,
    onDragStart,
    onDragEnd,
    position,
    dimensions,
    children
  }) => {
    const { certificateScope } = useCertificateContext()
    const [isDragging, setIsDragging] = useState(false)
    const [dragEnabled, setDragEnabled] = useState(false)
    const containerRef = useRef()

    useOutsideClick({
      ref: containerRef,
      handler: (e) => {
        // Only track outslide click for top-level DesignObject.
        if (!e.target.closest(".DesignObject")) {
          // Deselect text object when clicking outside of selected text object
          // yet within the certificate editor.
          if (
            isSelected &&
            (e.target.closest(".CertificateEditor") ||
              // User may be clicking on the multi-select element which get's z-indexed
              // over the CertificateEditor when selecting (which is triggered by a mousedown event).
              e.target.closest(".CertificateMultiSelect"))
          ) {
            onDeselect(e)
          }
        }
      }
    })

    // TODO: update index via hotkeys "[" & "]"
    // useEffect(()=> {
    //   useHotkeys("meta+]", )
    // }, [isSelected])

    const handleDragStart = (e) => {
      if (dragEnabled) {
        e.dataTransfer.dropEffect = "move"
        // get the inset top and left values of the mouse position
        // relative to the top and left values of the target bounding client
        // which will be used for getting the absolute position when dropping element
        const boundingClientRect = e.currentTarget.getBoundingClientRect()
        const top = e.clientY - boundingClientRect.top
        const left = e.clientX - boundingClientRect.left
        const insetRect = { top, left }
        const dragData = { insetRect, index }

        // set data to be transferred to drop target
        e.dataTransfer.setData("plain/text", JSON.stringify(dragData))

        onDragStart(dragData)

        // must delay updating the element's visibility as otherwise the screenshot
        // will be taken after the visibility changes and we don't want a screenshot of
        // a hidden element
        requestAnimationFrame(() => setIsDragging(true))
      }
    }

    const handleDragEnd = (e) => {
      setIsDragging(false)
      setDragEnabled(false)
      onDragEnd()
    }

    const handleSelect = (e) => {
      // Focusing on mousedown at least keeps the textarea focused when resizing outside of the editor.
      onSelect?.(e)
    }

    const containerStyle = {
      position: "absolute",
      top: `${position.top}px`,
      left: `${position.left}px`,
      display: "flex" // More closesly fixes size to size of child.
    }

    if (dimensions) {
      containerStyle.height = `${dimensions.height}px`
      containerStyle.width = `${dimensions.width}px`
    }

    // Render dumb UI when user cannot edit certificate.
    if (certificateScope) {
      return (
        <Container
          ref={containerRef}
          className={"DesignObject"}
          style={containerStyle}
        >
          {children}
        </Container>
      )
    }

    return (
      <Container
        ref={containerRef}
        className={classnames(`DesignObject DesignObject-${index}`, {
          isDragging: isDragging
        })}
        style={containerStyle}
        onMouseDown={handleSelect}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        draggable={dragEnabled}
        $isDragging={isDragging}
        $isSelected={isSelected}
        $index={index}
      >
        {children}
        <ObjectControls
          isFocused={isSelected}
          isDragging={isDragging}
          dragEnabled={dragEnabled}
          onToggleDragEnabled={setDragEnabled}
          onChange={onChange}
          onCopy={(...args) => {
            onCopy(...args)
          }}
          onDelete={onDelete}
        />
      </Container>
    )
  }
)

DesignObject.displayName = "DesignObject"

DesignObject.propTypes = {
  index: PropTypes.number, // for identification
  textObject: textObjectPropType,
  onChange: PropTypes.func
}

export default DesignObject
