import gql from "graphql-tag"
import DISCUSSION_RESPONSE from "./discussion-response-fragment"

export default gql`
  mutation UpdateDiscussionResponse(
    $context: DiscussionContextInput!
    $response: DiscussionResponseInput!
    $response_id: Int!
  ) {
    updateDiscussionResponse(
      context: $context
      response: $response
      response_id: $response_id
    ) {
      ...DiscussionResponse
    }
  }
  ${DISCUSSION_RESPONSE}
`
