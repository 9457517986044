import { graphql } from "@apollo/client/react/hoc"
import { withTranslate } from "@pathwright/ui/src/components/"
import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import Tooltip from "@pathwright/ui/src/components/tooltip/Tooltip"
import Text from "@pathwright/ui/src/components/ui/Text"
import { media } from "@pathwright/ui/src/components/utils/styles"
import get from "lodash/get"
import styled from "styled-components"
import PATH_PERFORMANCE_QUERY from "../graphql/path-performance-query"

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 80px;
  padding: 20px 50px;
  display: flex;
  justify-content: space-around;
  ${media.max.phone`padding: 20px;`};
`
const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const Stat = styled.div`
  margin-left: 5px;
  text-align: left;
  h4 span {
    font-size: 12px;
  }
  h6 {
    text-transform: uppercase;
  }
`

const MemberPerformanceStat = ({ stat, icon, title, tooltip }) => {
  return (
    <Tooltip title={tooltip}>
      <Center>
        <Pathicon icon={icon} />
        <Stat>
          <Text.H4>
            {stat}
            <span>%</span>
          </Text.H4>
          <Text.H6>{title}</Text.H6>
        </Stat>
      </Center>
    </Tooltip>
  )
}

const ReportPathStats = ({ performance, loading, t }) => {
  if (loading) {
    return (
      <Container>
        <LoadingCircle />
      </Container>
    )
  }

  if (!performance) {
    return <BlankSlate heading={t("path.report.nothing_to_show")} />
  }

  const {
    percentage,
    total_steps,
    steps_completed,
    total_required_steps,
    required_steps_completed
  } = performance.progress
  const { applicable_score, applicable_score_percentage } = performance.points
  const [earned_points, available_points] = applicable_score

  return (
    <Container>
      <MemberPerformanceStat
        icon="gauge-circle"
        title={t("path.report.complete")}
        stat={Math.floor(percentage * 100)}
        tooltip={`${steps_completed}/${total_steps} ${t(
          "path.report.steps_completed"
        )}.`}
      />
      {available_points > 0 && (
        <MemberPerformanceStat
          icon="award"
          title={t("path.report.score")}
          stat={Math.floor(applicable_score_percentage * 100)}
          tooltip={`${earned_points}/${available_points} ${t(
            "path.report.total_points"
          )}.`}
        />
      )}
    </Container>
  )
}

ReportPathStats.displayName = "ReportPathStats"

export default withTranslate(
  graphql(PATH_PERFORMANCE_QUERY, {
    options: ({ id }) => ({
      fetchPolicy: "network-only",
      variables: { id }
    }),
    props: ({ data }) => ({
      error: data.error,
      loading: data.loading,
      performance: get(data, "path.performance")
    })
  })(ReportPathStats)
)
