import { getConstMap } from "@pathwright/ui/src/components/utils/constants"

export const GROUP_VISIBILITY_PRIVATE = 5
export const GROUP_VISIBILITY_MEMBERS = 10
export const GROUP_VISIBILITY_PUBLIC = 15

export const GROUP_VISIBILITY_LABELS = {
  [GROUP_VISIBILITY_PRIVATE]: "private",
  [GROUP_VISIBILITY_MEMBERS]: "members",
  [GROUP_VISIBILITY_PUBLIC]: "public"
}

export const GROUP_STATUS_IN_PROGRESS = "in_progress"
export const GROUP_STATUS_COMPLETED = "completed"

export const COHORT_STATUS_IN_PROGRESS = 1
export const COHORT_STATUS_COMPLETED = 3

export const GROUP_STATUSES = getConstMap({
  IN_PROGRESS: [COHORT_STATUS_IN_PROGRESS, "in_progress"],
  COMPLETED: [COHORT_STATUS_COMPLETED, "completed"]
})
