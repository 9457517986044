import classnames from "classnames"
import isFunction from "lodash/isFunction"
import PropTypes from "prop-types"
import TextareaAutosize from "react-textarea-autosize"
import FieldWrapper from "../form-utils/FieldWrapper"
import "./TextArea.css"

const handleEnterKey = (e, preventEnter, onKeyDown) => {
  if (isFunction(onKeyDown)) {
    onKeyDown(e.target.value, e)
  }
  if (preventEnter && e.keyCode === 13) {
    // stop new line behavior but allow propagation
    // so form can listen to enter and possibly submit
    e.preventDefault()
  } else {
    // stop propagation so form won't submit
    e.stopPropagation()
  }
}

const TextArea = (props) => {
  const {
    id,
    rows,
    limit,
    name,
    label,
    value,
    controlled,
    minRows,
    maxRows,
    disabled,
    readOnly,
    className,
    autoFocus,
    onChange,
    onKeyDown,
    placeholder,
    autoCapitalize,
    autoCorrect,
    preventEnter
  } = props
  let charRemainingLabel = null
  if (limit) {
    charRemainingLabel = `${
      (value != null ? value.length : undefined) || 0
    }/${limit}`
  }
  return (
    <FieldWrapper
      {...props}
      className={classnames("TextArea", className)}
      counter={charRemainingLabel}
    >
      {({ focus, onFocus, onBlur }) => {
        const guardedValue = value != null ? value : ""

        return (
          <div className="TextArea__input">
            <TextareaAutosize
              id={id}
              name={name || label}
              minRows={minRows}
              maxRows={maxRows || rows}
              disabled={disabled}
              readOnly={readOnly}
              autoFocus={autoFocus}
              placeholder={placeholder}
              defaultValue={!controlled ? guardedValue : undefined}
              value={controlled ? guardedValue : undefined}
              onKeyDown={(e) => handleEnterKey(e, preventEnter, onKeyDown)}
              onChange={(e) => isFunction(onChange) && onChange(e.target.value)}
              onFocus={onFocus}
              onBlur={onBlur}
              autoCapitalize={autoCapitalize}
              autoCorrect={autoCorrect}
            />
          </div>
        )
      }}
    </FieldWrapper>
  )
}

TextArea.displayName = "TextArea"

TextArea.defaultProps = {
  autoCapitalize: "none",
  autoCorrect: "on",
  controlled: true
}

TextArea.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  autoFocus: PropTypes.bool,
  autoCapitalize: PropTypes.string,
  autoCorrect: PropTypes.string,
  rows: PropTypes.number,
  limit: PropTypes.number,
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
  onHeightChange: PropTypes.func,
  controlled: PropTypes.bool
}

export default TextArea
