import { useEffect, useState } from "react"
import { useUpdateReferralMutation } from "../api/generated"
import { usePathwrightContext } from "../pathwright/PathwrightContext"

declare global {
  interface Window {
    Rewardful: {
      referral: string
    }
  }
}

export const lsKey = "rewardful-affiliate-id"

const maybeSaveReferralIdInLS = async () => {
  const url = new URL(window.location.href)
  const referralId = url.searchParams.get("via")

  if (referralId) {
    localStorage.setItem(lsKey, referralId)
  }
}

const useReferral = () => {
  const { me } = usePathwrightContext()
  const [updateReferralMutation] = useUpdateReferralMutation()
  const [referral, setReferral] = useState<string>()

  // const referral = localStorage.getItem(lsKey)

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    window.addEventListener("Rewardful.initialized", function () {
      setReferral(window.Rewardful?.referral)
    })
  }, [])

  useEffect(() => {
    if (window.Rewardful?.referral !== referral) {
      setReferral(window.Rewardful?.referral)
    }
  }, [window.Rewardful?.referral])

  // useEffect(() => {
  //   // check for Rewardful url param (via) and save referral id in local storage
  //   maybeSaveReferralIdInLS()
  // }, [])

  useEffect(() => {
    if (me && referral) {
      updateReferralMutation({
        variables: {
          referral: referral
        }
      })
    }
  }, [me, referral])
}

// For convenience to use as child component.
export function Referral() {
  useReferral()
  return null
}

export default useReferral
