import Plausible from "plausible-tracker"

const initialize = ({ domain = "", ...options }) =>
  Plausible({
    domain: domain || "paths.pathwright.com",
    ...options
  })

export const trackEvent = async ({
  event,
  props = null,
  domain = "",
  trackLocalhost = true,
  hashMode = false
}) => {
  try {
    const plausible = initialize({ domain, trackLocalhost, hashMode })

    return new Promise((resolve, reject) => {
      // Can optionally add 3rd arg with: { url, referrer, deviceWidth }
      // https://plausible-tracker.netlify.app/globals#plausibleoptions
      plausible.trackEvent(event, { props, callback: resolve })
    })
  } catch (error) {
    console.error(error)
  }
}
