import {
  Avatar,
  Heading,
  HStack,
  Text,
  ThemingProps,
  VStack
} from "@chakra-ui/react"
import { useSpaceContext } from "../../../space/api/context"
import AuthFormFooterBoxLink from "../../components/AuthFormFooterBoxLink"
import useColorModeBranding from "../../utils/useColorModeBranding"

type size = Extract<
  ThemingProps<"Avatar">["size"],
  ThemingProps<"Heading">["size"]
>

export type SignedInProps = {
  size?: size
  allowSignOut?: boolean
}

const SignedIn = ({ size = "sm", allowSignOut = true }: SignedInProps) => {
  const colorScheme = useColorModeBranding()
  const spContext = useSpaceContext()
  const { user } = spContext

  return user ? (
    <HStack className="SignedIn" spacing={4} w="100%" p="1em 0" pos="relative">
      <Avatar
        size={size}
        name={user.full_name!}
        src={user.profile?.image ? user.profile.image : undefined}
      />
      <VStack alignItems="flex-start" spacing={2}>
        {/* TODO: use fontFamily="theme.heading" once the parent ChakraProvider uses correct heading theme font */}
        <Heading
          as="h1"
          className="theme-heading theme-heading-bold"
          size={size}
          m={0}
        >
          {user.full_name}
        </Heading>
        <Text>{user.email}</Text>
      </VStack>
      {allowSignOut && (
        <AuthFormFooterBoxLink
          to="/auth/sign-out"
          m={0}
          pos="absolute"
          top={0}
          right={0}
          colorScheme={colorScheme}
        >
          Sign out
        </AuthFormFooterBoxLink>
      )}
    </HStack>
  ) : null
}

export default SignedIn
