import Text from "@pathwright/ui/src/components/ui/Text"
import View from "@pathwright/ui/src/components/ui/View"
import styled from "styled-components"
import { useMentorGroupListContext } from "./MentorGroupListContext"
import MentorGroupStaffListItem from "./MentorGroupStaffListItem"

const Container = styled(View)`
  position: relative;
  text-align: center;
  max-width: 100%;
  overflow: auto;
`

const RowContainer = styled.div`
  position: relative;
  overflow: hidden;
  overflow-x: auto;
  display: flex;
  margin: 0 auto;
  padding: 5px 0 20px;
  max-width: 1400px;
`

const Row = styled.ul`
  display: flex;
  align-items: flex-start;
  list-style: none;
  padding: 0;
  margin: 0 auto;
`

const MentorGroupStaffList = () => {
  const { staff, staffQuery } = useMentorGroupListContext()
  return (
    <Container>
      <RowContainer>
        <Row>
          {staffQuery.error ? (
            <Text>An unexpected error occurred.</Text>
          ) : staff.length > 0 ? (
            staff.map((membership) => (
              <MentorGroupStaffListItem
                key={membership.id}
                membership={membership}
              />
            ))
          ) : null}
        </Row>
      </RowContainer>
    </Container>
  )
}

MentorGroupStaffList.displayName = "MentorGroupStaffList"

export default MentorGroupStaffList
