import { ArrowUpIcon } from "@pathwright/pathicons"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import "react-select/dist/react-select.css"
import LinkButton from "./LinkButton"

const NativeLink = ({ url }) => {
  const { tc } = useTranslate()

  if (
    !navigator ||
    typeof navigator.canShare !== "function" ||
    !navigator.canShare({
      url: url
    })
  )
    return null

  const nativeShare = () => {
    navigator
      .share({
        url: url
      })
      .catch((error) => console.error("Error sharing", error))
  }

  return (
    <LinkButton
      label={tc("share_card.link_tab.actions.Share via.buttonLabel")}
      icon={<ArrowUpIcon />}
      onClick={() => nativeShare()}
    />
  )
}

export default NativeLink
