import { HStack, Icon, Text } from "@chakra-ui/react"
import { InfoCircleIcon } from "@pathwright/pathicons"
import InteractiveTooltip from "../shared/InteractiveTooltip"

const Limit = ({ text, tooltip }) => (
  <HStack
    alignItems={"center"}
    bg="gray.200"
    borderRadius={"10px"}
    px={4}
    py={2}
  >
    <Text color="gray.500">{text}</Text>
    <InteractiveTooltip label={tooltip}>
      <Icon as={InfoCircleIcon} color="gray.500" boxSize={"1em"} />
    </InteractiveTooltip>
  </HStack>
)

export default Limit
