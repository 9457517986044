import { useEffect, useState } from "react"
import ReactDOM from "react-dom"
import useAsyncNode from "../hooks/useAsyncNode"

const Portal = ({ portalElementID, portalElementNode, children }) => {
  const [defaultNode, setDefaultNode] = useState(null)

  const asyncNode = useAsyncNode(portalElementID ? `#${portalElementID}` : null)

  // Handle a default portal node in the case where neither a
  // portalElementID nor a portalElementNode have been provided.
  useEffect(() => {
    if (defaultNode) {
      if (!defaultNode.parentNode) {
        document.body.appendChild(defaultNode)
        return () => document.body.removeChild(defaultNode)
      }
    } else {
      if (!portalElementID && !portalElementNode) {
        setDefaultNode(document.createElement("div"))
      }
    }
  }, [defaultNode])

  // Get the portalNode.
  const portalNode = portalElementNode || defaultNode || asyncNode

  return portalNode ? ReactDOM.createPortal(children, portalNode) : null
}

Portal.displayName = "Portal"

export default Portal
