import gql from "graphql-tag"
import DISCUSSION_RESPONSE from "./discussion-response-fragment"

export default gql`
  mutation CreateDiscussionResponse(
    $context: DiscussionContextInput!
    $response: DiscussionResponseInput!
  ) {
    createDiscussionResponse(context: $context, response: $response) {
      ...DiscussionResponse
    }
  }
  ${DISCUSSION_RESPONSE}
`
