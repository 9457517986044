import { useApolloClient, useMutation } from "@apollo/client"
import {
  Box,
  Button,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Text,
  VStack,
  useToast
} from "@chakra-ui/react"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import get from "lodash/get"
import moment from "moment"
import React from "react"
import DELETE_INVITATION_MUTATION from "../graphql/delete-invitation-mutation.js"
import RESEND_INVITATION_MUTATION from "../graphql/resend-invitation-mutation.js"
import { useCopyLink } from "../link/CopyLink.js"
import { getRoleLabel } from "../select/utils"
import { INVITATION_ROLE_TYPE_MAP } from "./constants"

const tPrefix = "share_card.track_tab"

const InviteDropdown = ({ options }) => {
  const { tc } = useTranslate()

  return (
    <Menu flip={false} placement="bottom-end">
      <MenuButton
        as={Button}
        _hover={{ bgColor: "gray.100" }}
        _active={{ bgColor: "gray.100" }}
        bgColor="gray.50"
        variant="ghost"
        color="gray.900"
        minWidth="30px"
        width="30px"
        height="30px"
        borderRadius="50%"
      >
        <Flex alignItems="center" justifyContent="center">
          <Pathicon icon="ellipsesHorizontal"></Pathicon>
        </Flex>
      </MenuButton>
      <MenuList
        border="solid 1px"
        borderColor="gray.100"
        borderRadius="4px"
        outline="none"
        boxShadow="none"
        overflow="hidden"
        p={0}
        minW={0}
      >
        {options.map((menuItem, index) => (
          <MenuItem
            _hover={{
              backgroundColor: "gray.50"
            }}
            _focus={{
              backgroundColor: "gray.50"
            }}
            onClick={menuItem.onClick}
            key={index}
            backgroundColor="transparent"
            border="none"
            px={6}
            py={2}
          >
            <Flex alignItems="center" justifyContent="center" color="gray.900">
              <Pathicon icon={menuItem.icon} />
              <Text size="xs" fontWeight="bold" my={0} ml={2}>
                {tc(`${tPrefix}.${menuItem.label}`)}
              </Text>
            </Flex>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  )
}

const renderErrorToast = ({ message, onClose }) => (
  <Box bg="TERTIARY_WHITE_BACKGROUND" borderRadius="8px" p={5}>
    <Flex alignItems="center" justifyContent="space-between">
      <Flex alignItems="center">
        <Pathicon icon="exclamation-circle" />
        <Text size="xxs" ml={2}>
          {message}
        </Text>
      </Flex>
      <Button
        onClick={onClose}
        type="button"
        variant="ghost"
        p={0}
        minWidth="auto"
      >
        <Pathicon icon="x" />
      </Button>
    </Flex>
  </Box>
)

const Invitation = ({ invitation, onRevoke, onResend }) => {
  const handleCopy = useCopyLink(invitation.invitation_url)

  const { t, tca } = useTranslate()

  const toast = useToast()

  const client = useApolloClient()._serverClient
  const [revokeInvitation, { loading: revokeLoading, error: revokeError }] =
    useMutation(DELETE_INVITATION_MUTATION, { client })
  const [resendInvitation, { loading: resendLoading, error: resendError }] =
    useMutation(RESEND_INVITATION_MUTATION, { client })

  const handleRevoke = async (invitation) => {
    await revokeInvitation({
      variables: {
        deleteInvitationId: invitation.id
      }
    })

    if (onRevoke) onRevoke()
  }

  const handleResend = async (invitation) => {
    await resendInvitation({
      variables: {
        resendInvitationId: invitation.id
      }
    })

    if (onResend) onResend()
  }

  const sentTimeDate = get(
    invitation,
    "invitation_email.created_dtime",
    invitation.created_dtime
  )

  const roleKey =
    INVITATION_ROLE_TYPE_MAP[invitation.invitation_type][
      invitation.invitation_role
    ]
  const role = getRoleLabel("verb", roleKey, tca)
  const sentTime = moment(sentTimeDate).fromNow()

  const sent = t(`${tPrefix}.{{role}} invite sent {{sentTime}}`, {
    role,
    sentTime
  })

  const inviteOptions = [
    {
      label: "Revoke",
      icon: "recur",
      onClick: () => handleRevoke(invitation)
    },
    {
      label: "Resend",
      icon: "minusCircle",
      onClick: () => handleResend(invitation)
    },
    {
      label: "Copy Link",
      icon: "copy",
      onClick: handleCopy
    }
  ]

  React.useEffect(() => {
    const error = revokeError || resendError
    if (error) {
      toast({
        position: "bottom",
        duration: 2000,
        isClosable: true,
        render: () =>
          renderErrorToast({ message: error.message, onClose: toast.closeAll })
      })
    }
  }, [revokeError, resendError])

  return (
    <HStack alignItems="center" my={3}>
      <Flex
        bgColor="gray.50"
        color="gray.900"
        borderRadius="50%"
        w="40px"
        h="40px"
        alignItems="center"
        justifyContent="center"
        mr={2}
      >
        <Pathicon icon="envelope" />
      </Flex>
      <VStack alignItems="flex-start" spacing={0} noOfLines={1} flex={1}>
        <Text
          size="xs"
          fontWeight="bold"
          lineHeight="24px"
          color="gray.900"
          m={0}
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {invitation.to_email}
        </Text>
        <Text size="xxs" m={0} color="gray.400" noOfLines={1}>
          {sent}
        </Text>
      </VStack>
      <HStack>
        <Text size="xs" fontWeight={600} color="gray.900" m={0}>
          {invitation.status}
        </Text>
        {revokeLoading || resendLoading ? (
          <Flex
            ml="2"
            alignItems="center"
            justifyContent="center"
            h="30px"
            w="30px"
          >
            <Spinner />
          </Flex>
        ) : (
          <InviteDropdown options={inviteOptions} />
        )}
      </HStack>
    </HStack>
  )
}

export default Invitation
