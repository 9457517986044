export const createSampleCard = (num = 1) => ({
  order: num,
  title: `Title ${num}`,
  html: `<p>Reinforced concrete was invented in 1849 by Joseph Monier.</p>`
})

export default {
  type: "Cards",
  icon: "cards", // TODO
  helpLink:
    "https://help.pathwright.com/create-a-course/adding-content-with-blocks/tabs-and-accordion-blocks-new",
  menu: {
    category: "interactive",
    order: 1
  },
  layouts: {
    accordion: {
      key: "accordion",
      label: "Accordion",
      data: {
        cards: [createSampleCard(1), createSampleCard(2)]
      }
    },
    tabs: {
      key: "tabs",
      label: "Tabs",
      data: {
        cards: [createSampleCard(1), createSampleCard(2)]
      }
    }
  },
  schema: `
    type Card {
      order: Int!
      title: String!
      html: String!
    }

    type Cards implements Block {
      id: ID!
      cards: [Card]
    }
  `
}
