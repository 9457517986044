import gql from "graphql-tag"
import SCHOOL_FRAGMENT from "../../school/graphql/school-fragment"
import STORE_LINK_FRAGMENT from "../../store/graphql/store-link-fragment"
import USER_FRAGMENT from "../../user/graphql/user-fragment"

export default gql`
  query UserSchoolLicenseQuery($cursor: String, $licensorId: IDFilter) {
    me {
      ...User
      memberships(
        first: 50
        after: $cursor
        role_filter: { eq: administrator }
        global: true
      ) {
        total
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            role
            school {
              ...School
              storeLinks(first: 10, licensor_id: $licensorId) {
                total
                pageInfo {
                  endCursor
                  hasNextPage
                }
                edges {
                  node {
                    ...StoreLink
                  }
                }
              }
              curriculum_subscriptions(first: 10, licensor_id: $licensorId) {
                total
                pageInfo {
                  endCursor
                  hasNextPage
                }
                edges {
                  node {
                    id
                    licensor {
                      ...School
                    }
                    curriculum_plan {
                      id
                      plan_id
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${USER_FRAGMENT}
  ${SCHOOL_FRAGMENT}
  ${STORE_LINK_FRAGMENT}
`
