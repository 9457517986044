import classnames from "classnames"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useTranslate } from "../lng/withTranslate"
import LoadingCircle from "../loading/LoadingCircle"
import Pathicon from "../pathicon/Pathicon"
import { SECONDARY_GRAY } from "../utils/colors"
import { SUBMIT_STATUS } from "./SubmitHandler"

const Container = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${(p) => p.color};
  font-size: ${(p) => (p.showLabel ? "0.8em" : "1em")};
  font-weight: normal;

  .LoadingCircle {
    position: static;
  }
`

const getSubmitStatusLabel = (status, t) => {
  switch (status) {
    case SUBMIT_STATUS.DEFAULT:
      return null
    case SUBMIT_STATUS.LOADING:
      return t("saving")
    case SUBMIT_STATUS.SUCCESS:
      return t("saved")
    case SUBMIT_STATUS.FAILURE:
      return t("failed")
    default:
      return null
  }
}

const getShowStatusLabel = (showLabel, status) => {
  if (!showLabel) return false
  if (showLabel === true) return true
  return !!showLabel[status]
}

const SubmitStatus = ({
  children,
  status,
  inverted,
  size,
  spinnerColor,
  iconColor,
  brand,
  showLabel,
  className
}) => {
  const { t } = useTranslate()
  if (!status) return children || null
  if (status === SUBMIT_STATUS.DEFAULT) return children || null

  return (
    <Container
      size={size}
      className={classnames(
        "SubmitStatus",
        `SubmitStatus__${status}`,
        className
      )}
      color={iconColor}
      showLabel={getShowStatusLabel(showLabel, status)}
    >
      {status === SUBMIT_STATUS.SUCCESS && <Pathicon icon="check" />}
      {status === SUBMIT_STATUS.FAILURE && <Pathicon icon="x" />}
      {status === SUBMIT_STATUS.LOADING && (
        <LoadingCircle
          inverted={inverted}
          size={size}
          color={spinnerColor}
          brand={brand}
          center
        />
      )}
      {getShowStatusLabel(showLabel, status) && (
        <span>{getSubmitStatusLabel(status, t)}</span>
      )}
    </Container>
  )
}

SubmitStatus.displayName = "SubmitStatus"

SubmitStatus.propTypes = {
  inverted: PropTypes.bool,
  showLabel: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      [SUBMIT_STATUS.DEFAULT]: PropTypes.bool,
      [SUBMIT_STATUS.LOADING]: PropTypes.bool,
      [SUBMIT_STATUS.SUCCESS]: PropTypes.bool,
      [SUBMIT_STATUS.FAILURE]: PropTypes.bool
    })
  ]),
  success: PropTypes.oneOf(Object.values(SUBMIT_STATUS)),
  spinnerColor: PropTypes.string,
  iconColor: PropTypes.string
}

SubmitStatus.defaultProps = {
  inverted: false,
  showLabel: false,
  size: LoadingCircle.defaultProps.size,
  spinnerColor: SECONDARY_GRAY,
  iconColor: SECONDARY_GRAY
}

export default SubmitStatus
