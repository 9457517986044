import gql from "graphql-tag"
import GROUP_FRAGMENT from "../../group/graphql/group-fragment"
import RESOURCE_FRAGMENT from "../../resource/graphql/resource-fragment"
import { TAG_FRAGMENT, TAG_LINK_FRAGMENT } from "../../tag/graphql"
import USER_FRAGMENT from "../../user/graphql/user-fragment"

export default gql`
  fragment DiscussionPost on Discussion {
    id
    title
    body
    type
    likes
    total_responses
    created_time
    is_discussion_question
    allow_replies
    path_item_name
    last_activity_time
    user_last_viewed_time
    user_last_responded_time
    user_has_viewed_recent
    user_is_subscribed
    user_liked
    context_id
    resource_id
    cohort_id
    step_id
    user {
      ...User
    }
    cohort {
      ...Group
      user_registration {
        id
        role
      }
      user_source_registration {
        id
        role
      }
    }
    resource {
      ...Resource
    }
    tagLinks(first: 50) {
      edges {
        node {
          ...TagLink
          tag {
            ...Tag
          }
        }
      }
    }
  }
  ${USER_FRAGMENT}
  ${GROUP_FRAGMENT}
  ${RESOURCE_FRAGMENT}
  ${TAG_LINK_FRAGMENT}
  ${TAG_FRAGMENT}
`
