import { useReviewContext } from "./ReviewContextProvider"

const useHasInboxItems = () => {
  const {
    selectedParent,
    actions: { getItem }
  } = useReviewContext()

  const childItems = selectedParent
    ? [...getItem(selectedParent.id).children.values()]
    : []

  const hasItems =
    childItems.length > 0 ||
    (selectedParent && selectedParent.children.size > 0)

  return hasItems
}

export default useHasInboxItems
