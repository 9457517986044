import { Box, Spinner } from "@chakra-ui/react"
import AtmosphericImage from "../shared/AtmosphericImage"

const LoadingOverlay = () => {
  return (
    <Box
      position="absolute"
      inset="0"
      m={"0 !important"}
      borderRadius="var(--card-border-radius-lg)"
      opacity={0.6}
    >
      <AtmosphericImage />
      <Box
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
      >
        <Spinner
          thickness="2px"
          speed="0.65s"
          emptyColor="gray.200"
          color="inhert"
          size="md"
        />
      </Box>
    </Box>
  )
}

export default LoadingOverlay
