const Score = ({ item }) => {
  const {
    data: { completion }
  } = item

  const {
    checked_by,
    userpoints_earned: earned,
    userpoints_value: value
  } = completion

  if (!checked_by || !value) return null

  const perc = value ? Math.round((earned / value) * 100) : 0

  return (
    <span className="Completion--Score">
      {earned}/{value} ({perc}%)
    </span>
  )
}

export default Score
