import debounce from "lodash/debounce"
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react"
import ReactDOM from "react-dom"
import isEqual from "react-fast-compare"
import ResizeObserver from "resize-observer-polyfill"
import useIsMountedRef from "../hooks/useIsMountedRef"

const ObserveSizeContext = React.createContext()

export const useObserveSizeContext = () => useContext(ObserveSizeContext)

// TODO: move to props?
// TODO: track other keys?
const RECT_KEYS = ["width", "height", "top", "left", "right", "bottom"]

// React Hook for observing the size of a DOM node
export const useObserveSize = (getTarget) => {
  const isMountedRef = useIsMountedRef()
  const nodeRef = useRef(null)
  const [node, setNode] = useState()
  const [rectValue, setRectValue] = useState({})
  const rectValueRef = useRef(rectValue)
  const attemptToGetTarget = (node) => {
    if (getTarget) {
      try {
        getTarget(node)
      } catch (error) {
        return node
      }
    }
    return node
  }

  const updateRectValue = useCallback((target) => {
    if (target) {
      const boundingClientRect = target.getBoundingClientRect()
      const nextRectValue = RECT_KEYS.reduce((obj, key) => {
        obj[key] = boundingClientRect[key]
        return obj
      }, {})

      if (!isEqual(nextRectValue, rectValueRef.current)) {
        rectValueRef.current = nextRectValue
        setRectValue(nextRectValue)
        debouncedUpdateRectValue(target)
      }
    }
  }, [])

  // NOTE: in some edge cases, the resize observer (whether pollyfilled or not)
  // does not observe the target element's final size when multiple resize events
  // occur immediately after the component mounts. This debounced fn should
  // hopefully correctly set the latest rect values for the target element.
  const debouncedUpdateRectValue = useCallback(
    debounce(updateRectValue, 100),
    []
  )

  const observer = useRef(
    new ResizeObserver((entries) => {
      const target = entries[0].target
      // Explicitly check for unmount.
      if (isMountedRef.current !== false) updateRectValue(target)
    })
  )

  useEffect(() => {
    if (node) {
      const nodeEl = ReactDOM.findDOMNode(node)
      const target = attemptToGetTarget(nodeEl)
      if (target === window) {
        // Call updateRectValue immediately as it doesn't not get called on
        // initial hookup of "resize" listener.
        updateRectValue(nodeEl)
        const onResize = () => updateRectValue(nodeEl)
        target.addEventListener("resize", onResize)
        return () => target.removeEventListener("resize", onResize)
      } else {
        observer.current.observe(target)
        return () => observer.current.disconnect()
      }
    }
  }, [node])

  // Supporting ref API...
  nodeRef.current = node
  const goodies = [rectValue, nodeRef, setNode]
  // convenience
  goodies.rectValue = rectValue
  goodies.nodeRef = nodeRef
  goodies.setNode = setNode

  return goodies
}

export const withObserveSize = (Component) => (props) => {
  const observeSize = useObserveSize()
  let observeSizes = [observeSize]
  if (props.observeSizes) {
    observeSizes = [...props.observeSizes, ...observeSizes]
  }
  return (
    <Component
      {...props}
      observeSize={observeSize}
      observeSizes={observeSizes}
    />
  )
}

// Handle observing resizing of nodes and provide context for other components to read the dimensions of observed nodes
// One example is the Fullscreen component listening to the height of a "message" component and offsetting it's top based on the height of the "message" component
export const ObserveSizeProvider = ({ children }) => {
  // track any number of sizes for nodes per size key
  const [sizes, updateSizes] = useState({})

  const getSize = (key, nodeRef) =>
    sizes[key] ? sizes[key].find((size) => size.nodeRef === nodeRef) : null

  // Handle adding, updating, removing sizes in state
  const useSizeRef = (key, getTarget) => {
    const [rectValue, nodeRef, setNodeRef] = useObserveSize(getTarget)
    const size = getSize(key, nodeRef)

    useEffect(() => {
      if (
        size &&
        nodeRef.current &&
        RECT_KEYS.find((key) => size[key] !== rectValue[key])
      ) {
        // update size
        updateSizes({
          ...sizes,
          [key]: sizes[key].map((size) =>
            getSize(key, size.nodeRef)
              ? {
                  nodeRef,
                  ...rectValue
                }
              : size
          )
        })
      } else if (!size && nodeRef.current) {
        // add size
        updateSizes({
          ...sizes,
          [key]: (sizes[key] || []).concat({ nodeRef, ...rectValue })
        })
      } else if (size && !nodeRef.current) {
        // remove size
        updateSizes({
          ...sizes,
          [key]: sizes[key].filter((size) => !getSize(key, size.nodeRef))
        })
      }
    }, [key, size, rectValue, nodeRef.current])

    useEffect(
      () => () => {
        // final cleanup: remove size
        updateSizes({
          ...sizes,
          [key]:
            sizes[key] &&
            sizes[key].filter((size) => !getSize(key, size.nodeRef))
        })
      },
      []
    )

    return setNodeRef
  }

  // Returns null if no keys with this attribute are found, otherwise returns the cummulative value for each key.attribute pairing for all requested pairings
  const getRectValue = (...keyAttributeParings) =>
    keyAttributeParings
      // convert pairings into key / value pairs
      .map((keyAttributeParing) => keyAttributeParing.split("."))
      .reduce(
        (values, [key, attribute]) =>
          values.concat(
            sizes[key]
              ? sizes[key].reduce(
                  (values, size) => values.concat(size[attribute]),
                  []
                )
              : []
          ),
        []
      )
      .filter((value) => typeof value === "number")
      .reduce((total, value) => total + value, null)

  return (
    <ObserveSizeContext.Provider
      value={{
        useSizeRef,
        getRectValue
      }}
    >
      {children}
    </ObserveSizeContext.Provider>
  )
}

ObserveSizeProvider.displayName = "ObserveSizeProvider"

export default ObserveSizeContext
