import PropTypes from "prop-types"
import React from "react"
import { css } from "styled-components"
import { WithCSS } from "../utils/styles"
import PathwrightUI from "./PathwrightUI"
import Screensize from "./Screensize"
import { colorStyles, getTextColor, invertForBackground } from "./colors"
import { overflowStyles, textStyles } from "./typography"

const Text = React.forwardRef(function Text(props, ref) {
  const {
    lines,
    inverted,
    emphasis,
    typography,
    fade,
    el,
    style,
    brand,
    color,
    size,
    font,
    ...rest
  } = props
  // TODO: skip css if missing typography/emphasis props
  return (
    <Screensize.Consumer>
      {(screensize) => (
        <PathwrightUI.Consumer>
          {({ backgroundColor, primaryBrandColor }) => (
            <WithCSS
              as={el}
              {...rest}
              ref={ref}
              css={css`
                ${textStyles({ typography, font, size, screensize })}
                ${overflowStyles({
                  typography,
                  lines,
                  backgroundColor,
                  fade
                })}
              ${colorStyles({
                  emphasis,
                  inverted,
                  backgroundColor,
                  baseColor: color ? color : brand ? primaryBrandColor : null
                })}
              ${style || {}}
              `}
            />
          )}
        </PathwrightUI.Consumer>
      )}
    </Screensize.Consumer>
  )
})

Text.propTypes = {
  el: PropTypes.string.isRequired,
  typography: PropTypes.string,
  emphasis: PropTypes.string,
  inverted: PropTypes.bool,
  lines: PropTypes.number,
  brand: PropTypes.bool,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  fade: PropTypes.bool
}

Text.defaultProps = {
  el: "span",
  brand: null,
  color: null,
  size: "medium",
  fade: false
}

// helper methods

Text.getColor = getTextColor
Text.colorStyles = colorStyles
Text.invertForBackground = invertForBackground

// helper components

Text.H1 = React.forwardRef((props, ref) => (
  <Text emphasis="primary" ref={ref} {...props} el="h1" typography="heading1" />
))

Text.H1.displayName = "TextH1"

Text.H2 = React.forwardRef((props, ref) => (
  <Text emphasis="primary" ref={ref} {...props} el="h2" typography="heading2" />
))

Text.H2.displayName = "TextH2"

Text.H3 = React.forwardRef((props, ref) => (
  <Text emphasis="primary" ref={ref} {...props} el="h3" typography="heading3" />
))

Text.H3.displayName = "TextH3"

Text.H4 = React.forwardRef((props, ref) => (
  <Text emphasis="primary" ref={ref} {...props} el="h4" typography="heading4" />
))

Text.H4.displayName = "TextH4"

Text.H5 = React.forwardRef((props, ref) => (
  <Text emphasis="primary" ref={ref} {...props} el="h5" typography="heading5" />
))

Text.H5.displayName = "TextH5"

Text.H6 = React.forwardRef((props, ref) => (
  <Text emphasis="primary" ref={ref} {...props} el="h6" typography="heading6" />
))

Text.H6.displayName = "TextH6"

Text.Body = React.forwardRef((props, ref) => (
  <Text emphasis="secondary" ref={ref} el="p" {...props} typography="body" />
))

Text.Body.displayName = "TextBody"

Text.Meta = React.forwardRef((props, ref) => (
  <Text emphasis="tertiary" ref={ref} el="span" {...props} typography="meta" />
))

Text.Meta.displayName = "TextMeta"

export default Text
