import ConnectStores from "lib/core/store/components/ConnectStores"
import GroupCommerceForm from "./GroupCommerceForm"

const mapStateToProps = function (state, ownProps) {
  const { group } = state.groupAdminStore.state
  const { saveGroup } = state.groupAdminStore.action
  const { integrations, commerce, school } = state.schoolStore.state
  const { currency_code } = commerce
  const { ietf_language_tag } = school

  const canAcceptPayments = integrations.stripe.publishable_key != null

  const fieldValues = {
    nonsubpricing: (group != null ? group.is_subscription_only : undefined)
      ? -1
      : group != null
      ? group.price
      : undefined,
    subpricing:
      (group != null ? group.include_in_subscription_plans : undefined) || [],
    products: (group != null ? group.include_with_products : undefined) || []
  }

  const onSubmit = function (fieldsData, resetForm) {
    const is_subscription_only = fieldsData.nonsubpricing.value === -1
    const groupData = {
      price: is_subscription_only ? 0 : fieldsData.nonsubpricing.value,
      is_subscription_only,
      include_in_subscription_plans: fieldsData.subpricing.value.length
        ? fieldsData.subpricing.value
        : [],
      include_with_products: fieldsData.products.value.length
        ? fieldsData.products.value
        : []
    }
    return saveGroup(groupData)
  }

  return _.assign(
    {},
    {
      fieldValues,
      onSubmit,
      group,
      canAcceptPayments,
      currency_code,
      ietf_language_tag
    },
    ownProps
  )
}

const stores = [
  { store: "school", watch: false },
  { store: "groupAdmin", watch: ["group"] }
]

export default ConnectStores(GroupCommerceForm, stores, {}, mapStateToProps)
