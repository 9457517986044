import React, { Suspense } from "react"

const LazyPathicon = React.lazy(() =>
  import("./PathiconExpensive" /* webpackChunkName: "Pathicon" */)
)

const SuspendedLazyPathicon = (props) => (
  <Suspense fallback={null}>
    <LazyPathicon {...props} />
  </Suspense>
)

export default SuspendedLazyPathicon
