// Not working: Cannot read properties of undefined (reading 'source')
// import { SchoolFragmentDoc } from "../../api/generated"
// export default SchoolFragmentDoc

import gql from "graphql-tag"

export default gql`
  fragment School on School {
    id
    url
    name
    about
    email
    website
    subdomain
    created
    billing_model
    platform_version
    currency_code
    ietf_language_tag
    has_certificate
    is_activated
    is_curriculum_enabled
    is_curriculum_subscriber
    stripe_access_token
    stripe_publishable_api_key
    show_stripe_reconnect
    payment_requires_zip_code
    consent_description
    image(width: 150, height: 150)
    grant_access_upon_authentication
    pathwright_login_enabled
  }
`
