import gql from "graphql-tag"
import SCHOOL_BILLING_BLOCK_FRAGMENT from "./school-billing-block-fragment"
import SCHOOL_BILLING_PLAN_FEATURE_FRAGMENT from "./school-billing-plan-feature-fragment"

export default gql`
  fragment SchoolBillingPlan on SchoolBillingPlan {
    id
    name
    visibility
    amount
    description
    learner_counting_method
    fee_per_additional_learner
    learners_included
    staff_included
    color
    order
    should_contact_for_pricing
    features {
      ...SchoolBillingPlanFeature
    }
    billing_blocks(first: 50) {
      total
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...SchoolBillingBlock
        }
      }
    }
    plan_derived_from {
      id
      name
      description
      amount
      order
    }
  }
  ${SCHOOL_BILLING_PLAN_FEATURE_FRAGMENT}
  ${SCHOOL_BILLING_BLOCK_FRAGMENT}
`
