import { Box } from "@chakra-ui/react"
import useWindowSize from "@pathwright/ui/src/components/hooks/useWindowSize"
import classnames from "classnames"
import React, { useEffect, useMemo, useState } from "react"
import styled from "styled-components"
import { StringParam, useQueryParam } from "use-query-params"
import {
  INBOX_CONTEXT_PANEL_MIN_WIDTH,
  INBOX_CONTEXT_PANEL_THRESHOLD,
  INBOX_CONTEXT_PANEL_WIDTH,
  INBOX_LAYOUT_NAV_HEIGHT
} from "../../ui/constants"
import ContextPanelNav from "./ContextPanelNav"
import InboxContextPanel from "./InboxContextPanel"
import usePanels from "./usePanels"

const PanelContainer = styled.div`
  display: flex;
  max-width: min(${INBOX_CONTEXT_PANEL_WIDTH}px, calc(100% - 50px));
  width: unset;
  max-height: calc(var(--vh) - ${INBOX_LAYOUT_NAV_HEIGHT + 0}px);
  flex-shrink: 0;
  margin-left: 10px;
  position: sticky;
  top: ${INBOX_LAYOUT_NAV_HEIGHT + 10}px;
  border-radius: 20px;
  transition: all 0.2s ease-in-out;

  > .IconButton {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 100000;
  }

  &.overlay {
    border: 1px solid rgba(0, 0, 0, 0.05);
  }

  /* &.overlay {
    &.open {
      top: ${INBOX_LAYOUT_NAV_HEIGHT + 30}px;
    }
    .StyledPanel {
      background: rgba(255, 255, 255, 0.9);
    }
  } */

  &.closed {
    width: 0px;
    right: calc(-100vw - ${INBOX_CONTEXT_PANEL_WIDTH}px);
  }

  &.open {
    width: 100%;
    max-width: ${INBOX_CONTEXT_PANEL_WIDTH}px;
    min-width: min(${INBOX_CONTEXT_PANEL_MIN_WIDTH}px, calc(100vw - 50px));
    right: 0px;
  }
`

const StyledPanel = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
  flex-grow: 1;
  width: 100%;
  height: inherit;
  /* padding: 0px 20px; */
  /* padding-top: 20px; */
  /* padding-bottom: 40px; */
  /* border-radius: 20px; */

  ${PanelContainer}.overlay & {
    background: rgba(255, 255, 255, 0.95);
  }
`

const getDefaultOpenState = (selectedPanel) => {
  if (selectedPanel) return true
  if (window.innerWidth >= INBOX_CONTEXT_PANEL_THRESHOLD) return true
  return false
}

export const useOverlayMode = () => {
  const size = useWindowSize()
  const isOverlayMode = size.width < INBOX_CONTEXT_PANEL_THRESHOLD
  return isOverlayMode
}

const useOpen = (selectedPanel) => {
  const size = useWindowSize()
  const [open, setOpen] = useState(getDefaultOpenState(selectedPanel))

  useEffect(() => {
    // Ignoring selectedPanel.
    if (!open && getDefaultOpenState()) {
      setOpen(true)
    }
  }, [open, size])

  return [open, setOpen]
}

const useOpenPanel = (panels) => {
  const [selectedPanel, setSelectedPanel] = useQueryParam(
    "ui_panel",
    StringParam
  )

  const [open, setOpen] = useOpen(selectedPanel)

  // Select the first visible panel if the current panel should be hidden.
  useEffect(() => {
    // Get first panel that is visible.
    const selectDefaultPanel = () => {
      const panel = panels[0]
      if (panel) {
        setSelectedPanel(panel.key, "replaceIn")
      }
    }

    if (selectedPanel) {
      if (open) {
        const currentPanel = panels.find((panel) => panel.key === selectedPanel)
        if (!currentPanel) {
          selectDefaultPanel()
        }
      } else {
        // Panel should always be open if there is a selected panel.
        setOpen(true)
      }
    } else if (open) {
      selectDefaultPanel()
    }
  }, [panels, selectedPanel, open])

  return { selectedPanel, setSelectedPanel, open, setOpen }
}

const useVisiblePanels = () => {
  const panels = usePanels()
  // Select the visible panels.
  const visiblePanels = useMemo(
    () => panels.filter((panel) => panel.visible),
    [panels]
  )
  return visiblePanels
}

export const CollapsableContextPanel = () => {
  const visiblePanels = useVisiblePanels()

  const isOverlayMode = useOverlayMode()
  const { selectedPanel, setSelectedPanel, open, setOpen } =
    useOpenPanel(visiblePanels)

  const className = classnames("ContextPanel", {
    overlay: isOverlayMode,
    open,
    closed: !open
  })

  const handleSelect = (panelKey) => {
    if (panelKey) {
      setSelectedPanel(panelKey)
    } else {
      setOpen(false)
      setSelectedPanel(/* clearing */)
    }
  }

  var showPanelNav = isOverlayMode || !open

  return (
    <React.Fragment>
      {showPanelNav && (
        <ContextPanelNav
          panels={visiblePanels}
          open={open}
          onSelect={handleSelect}
        />
      )}
      <PanelContainer className={className}>
        <StyledPanel className="StyledPanel">
          <InboxContextPanel
            panels={visiblePanels}
            onSelect={handleSelect}
            selectedPanel={selectedPanel}
          />
        </StyledPanel>
      </PanelContainer>
    </React.Fragment>
  )
}

const ContextPanel = () => {
  const visiblePanels = useVisiblePanels()
  const { selectedPanel, setSelectedPanel } = useOpenPanel(visiblePanels)

  const className = "ContextPanel"

  const handleSelect = (panelKey) => {
    if (panelKey) {
      setSelectedPanel(panelKey)
    } else {
      setSelectedPanel(/* clearing */)
    }
  }

  return (
    <PanelContainer className={className}>
      <StyledPanel className="StyledPanel">
        <Box
          pos="absolute"
          inset={0}
          backdropFilter="blur(10px)"
          borderRadius="20px"
          bg="rgba(255, 255, 255, 0.8)"
        />
        <InboxContextPanel
          panels={visiblePanels}
          onSelect={handleSelect}
          selectedPanel={selectedPanel}
        />
      </StyledPanel>
    </PanelContainer>
  )
}

export default ContextPanel
