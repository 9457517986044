import {
  PRIMARY_GRAY,
  PRIMARY_WHITE,
  TERTIARY_GRAY,
  TERTIARY_WHITE
} from "@pathwright/ui/src/components/utils/colors"
import styled from "styled-components"
import { usePathwrightContext } from "../pathwright/PathwrightContext"

const StyledRow = styled.ul`
  z-index: 100;
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
  display: flex !important;
  align-items: center;
  justify-content: center;
`

const StyledDot = styled.li`
  display: block;
  width: 10px;
  height: 10px;
  margin: 0 3px;
  border-radius: 5px;
  border: 1px solid
    ${(props) => (props.inverted ? PRIMARY_WHITE : PRIMARY_GRAY)};
  list-style-type: none;
  cursor: pointer;
  transition: background-color 100ms ease-in;
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  background-color: ${(props) =>
    props.active
      ? props.inverted
        ? PRIMARY_WHITE
        : PRIMARY_GRAY
      : props.filled
      ? props.inverted
        ? TERTIARY_WHITE
        : TERTIARY_GRAY
      : "transparent"};
  &:hover {
    background-color: ${(props) =>
      props.inverted ? PRIMARY_WHITE : PRIMARY_GRAY};
  }
`

const SignupDots = ({ inverted, activeStep, onChange }) => {
  const { me } = usePathwrightContext()
  if (activeStep > 2) {
    return null
  }
  return (
    <StyledRow>
      {[1, 2].map((step) => (
        <StyledDot
          key={step}
          onClick={(e) => onChange(step)}
          filled={step <= activeStep}
          active={step === activeStep}
          inverted={inverted}
          disabled={!me}
        />
      ))}
    </StyledRow>
  )
}

SignupDots.displayName = "SignupDots"

export default SignupDots
