import { useMutation } from "@apollo/client"
import RESET_PATH_ITEM_FOR_USER from "../graphql/reset-path-item-for-user-mutation"

const useResetPathItemForUser = (pathItem) => {
  const [resetPathItemForUser, { data, loading, error }] = useMutation(
    RESET_PATH_ITEM_FOR_USER,
    {
      variables: {
        id: pathItem.id,
        path_id: pathItem.path_id
      },
      refetchQueries: ["PathQuery", "PathPerformanceQuery"]
    }
  )

  return [resetPathItemForUser, { data, loading, error }]
}

export default useResetPathItemForUser
