import { Box, Heading, Image, Text } from "@chakra-ui/react"
import { useShareDataContext } from "./shared/ShareDataContextProvider"

const ShareCard = () => {
  const {
    meta: { image, title, description }
  } = useShareDataContext()

  return (
    <Box
      borderRadius="5px"
      border="solid 1px"
      borderColor="gray.100"
      shadow="lg"
      bg="white"
      maxWidth="min(80%, 250px)"
      mx="auto"
      overflow="hidden"
    >
      <Box display="flex" overflow="hidden" sx={{ aspectRatio: "16/9" }}>
        <Image objectFit="cover" width="100%" src={image} />
      </Box>
      <Box p={3}>
        <Heading
          size="h7"
          color="gray.900"
          fontWeight="bold"
          textAlign="left"
          m={0}
          pb={1}
        >
          {title}
        </Heading>
        <Text
          size="xxs"
          noOfLines={2}
          maxH="2.4em" // Text not truncating dependably in Safari, so straight up clipping at 2 lines.
          color="gray.500"
          textAlign="left"
          m={0}
        >
          {description}
        </Text>
      </Box>
    </Box>
  )
}

export default ShareCard
