import { Box, Skeleton } from "@chakra-ui/react"
import {
  getFilestackHandle,
  preview,
  usePdfInfo
} from "@pathwright/ui/src/components/filestack/utils"
import classnames from "classnames"
import PropTypes from "prop-types"
import { useEffect, useMemo, useRef } from "react"
import styled from "styled-components"
import { useCertificateState } from "../context/CertificateState"

const DimensionsContainer = styled.div`
  height: ${(p) => (p.$dimensions ? p.$dimensions.height : 0)}px;
  width: ${(p) => (p.$dimensions ? p.$dimensions.width : 0)}px;
`

export const CertificateTemplateDimensions = (props) => {
  const { templateDimensions } = useCertificateState()
  return <DimensionsContainer {...props} $dimensions={templateDimensions} />
}

const Container = styled(DimensionsContainer)`
  iframe {
    border: none;
    position: relative;
    /* otherwise drop event doesn't fire on Certificate */
    pointer-events: none;
  }
`

export const CertificateTemplate = ({
  className,
  hash,
  template,
  templateDimensions,
  scaleSize
}) => {
  // Optionally provide certain props via context.
  const state = useCertificateState()
  if (state) {
    template ||= state.certificateState.template
    templateDimensions ||= state.templateDimensions
  }

  const pdfInfo = usePdfInfo(template)
  const handle = useMemo(() => getFilestackHandle(template), [template])
  // ID of element to insert preview iframe into. Ensuring the container
  // ID is likely unique per instance of CertificateTemplate + handle.
  const containerId = useMemo(() => {
    let base = `certificate-template-${handle}`
    // Establish uniqueness.
    if (hash) base += `-${hash}`
    return base
  }, [handle])
  const scalePropsRef = useRef({
    style: {},
    // used to dynamically scale the CertificateTemplate to fit the
    // containing form
    scale: 0,
    // account for scaling of the CertificateTemplate since scale doesn't
    // affect page layout
    margin: 0
  })

  // The combination of memo and ref ensure that if the template's scaleSize prop
  // reverted to values of 0, the previous scale props would be retained.
  // This can happen when rendered within the chakra Drawer since the size of the
  // parent must be displayed none when the tab becomes inactive, losing it's dimensions.
  // We just don't want to remount the PDF.
  const { style, scale, margin } = useMemo(() => {
    let { style, scale, margin } = scalePropsRef.current

    if (scaleSize && pdfInfo) {
      if (scaleSize.width && pdfInfo.pagesize.width) {
        scale = scaleSize.width / pdfInfo.pagesize.width
        margin = pdfInfo.pagesize.height * -(1 - scale)
      }

      style = {
        transform: `scale(${scale})`,
        transformOrigin: "top",
        marginBottom: margin
      }
    }

    return (scalePropsRef.current = {
      style,
      scale,
      margin
    })
  }, [scaleSize, pdfInfo])

  // wait to render until the scale is known, if used
  const shouldRender = !scaleSize || (!!scale && !!margin)

  useEffect(() => {
    if (!shouldRender) return
    // remove any pre-existing iframe nodes from the DOM that do
    // not match the current handle
    const iframeNodes = [...document.querySelectorAll(`#${containerId} iframe`)]
    iframeNodes
      .filter((node) => node.src.indexOf(handle) == -1)
      .forEach((node) => node.remove())

    // Injects iframed PDF into container element.
    preview(
      handle,
      containerId,
      "https://duointeractive.github.io/filestack-pdf-preview-styles.css"
    )
  }, [containerId, shouldRender])

  return shouldRender ? (
    <Container
      className={classnames("CertificateTemplate", className)}
      style={style}
      $dimensions={templateDimensions}
    >
      <Skeleton
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        startColor="gray.50"
        endColor="gray.300"
      />
      <Box pos="absolute" inset={0} id={containerId} />
    </Container>
  ) : null
}

CertificateTemplate.displayName = "CertificateTemplate"

CertificateTemplate.propTypes = {
  hash: PropTypes.string
}

CertificateTemplate.defaultProps = {
  hash: ""
}

export default CertificateTemplate
