import React from "react"
import CurrencyFormatterProvider from "../currency/CurrencyFormatterProvider"
import { usePathwrightContext } from "../pathwright/PathwrightContext"
import SchoolQuery from "./graphql/SchoolQuery"

const SchoolContext = React.createContext()

const SchoolContextProvider = ({ schoolId, children }) => (
  <SchoolQuery id={schoolId}>
    {({ school }) => (
      <SchoolContext.Provider value={{ school }}>
        <CurrencyFormatterProvider school={school}>
          {children}
        </CurrencyFormatterProvider>
      </SchoolContext.Provider>
    )}
  </SchoolQuery>
)

export const useSchoolContext = () => React.useContext(SchoolContext)

// get the nearest school in context
export const useSchool = () => {
  const { school: baseSchool } = usePathwrightContext()
  const { school } = useSchoolContext() || {}

  return school || baseSchool
}

export default {
  Provider: SchoolContextProvider,
  Consumer: SchoolContext.Consumer
}
