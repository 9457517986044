import gql from "graphql-tag"
import SCHOOL_BILLING_PLAN_FRAGMENT from "./school-billing-plan-fragment"

export default gql`
  fragment SchoolBillingPlanSet on SchoolBillingPlanSet {
    id
    name
    is_default
    plans {
      ...SchoolBillingPlan
    }
  }
  ${SCHOOL_BILLING_PLAN_FRAGMENT}
`
