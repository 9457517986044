import { Input } from "@chakra-ui/react"
import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import Card from "@pathwright/ui/src/components/card/Card"
import KeyboardFormSubmit from "@pathwright/ui/src/components/form/form-utils/KeyboardFormSubmit"
import withTranslate from "@pathwright/ui/src/components/lng/withTranslate"
import { useTranslatedResourceTypeLabel } from "@pathwright/web/src/modules/resource/utils"
import { Field, Form, Formik } from "formik"
import { ConnectStores } from "lib/core/store"
import PropTypes from "prop-types"
import { useState } from "react"

const ResourceArchiveCard = ({
  card,
  cardProps,
  resource,
  deleteResource,
  preventModalClose,
  toContinue,
  continueLabel,
  t
}) => {
  const [deleted, setDeleted] = useState(false)
  const [resourceName, setResourceName] = useState(null)
  const resourceTypeLabel = useTranslatedResourceTypeLabel(resource)

  const handleArchive = () => {
    const resourceName = resource.name
    const resourceId = resource != null ? resource.id : undefined
    preventModalClose()
    return deleteResource(resourceId).promise.then(() => {
      // library
      window.apolloClientStoreBridge.refetchLibraryResources()
      // teach tab
      window.apolloClientStoreBridge.refetchTeachingResources()
      // design tab
      window.apolloClientStoreBridge.refetchResources({})
      // learn tab
      window.apolloClientStoreBridge.refetchRegistrations({})

      setDeleted(true)
      setResourceName(resourceName)
    })
  }

  return (
    <Card {...cardProps} card={card} hideClose={deleted} noaction>
      {resource ? (
        <Formik
          initialValues={{ confirm: "" }}
          validate={(values) => {
            if (values.confirm !== "delete") return { confirm: "required" }
          }}
          validateOnMount
          onSubmit={handleArchive}
        >
          {(form) => (
            <Form>
              <KeyboardFormSubmit />
              <BlankSlate
                icons={null}
                icon="caution-triangle"
                heading={t("resource.cards.archive.blank_slate.heading", {
                  path: resource.name
                })}
                body={t("resource.cards.archive.blank_slate.body", {
                  resourceType: resourceTypeLabel
                })}
                preActionContent={
                  <Field
                    as={Input}
                    name="confirm"
                    placeholder={t(
                      "resource.cards.archive.blank_slate.confirm_prompt"
                    )}
                    autocomplete="off"
                    mt={4}
                    mb="0 !important"
                  />
                }
                primaryAction={{
                  children: t("resource.cards.archive.blank_slate.button", {
                    resourceType: resourceTypeLabel,
                    path: resource.name
                  }),
                  dangerous: true,
                  disabled: !form.isValid,
                  submitting: form.isSubmitting
                }}
                secondaryAction={{
                  children: t("resource.cards.archive.blank_slate.cancel"),
                  onClick: card.onClose
                }}
                stack
              />
            </Form>
          )}
        </Formik>
      ) : deleted ? (
        <BlankSlate
          icons={null}
          heading={`"${resourceName}" has been deleted`}
          primaryAction={{
            children: continueLabel,
            to: toContinue
          }}
        />
      ) : null}
    </Card>
  )
}

ResourceArchiveCard.displayName = "ResourceArchiveCard"
ResourceArchiveCard.contextTypes = {
  me: PropTypes.object.isRequired
}

const mapStateToProps = function (state, ownProps) {
  const { resource, resource_loading, is_deleting_resource } =
    state.resourceAdminStore.state
  const { deleteResource } = state.resourceAdminStore.action
  const { getHomeLocation } = state.navigationStore.request
  const { preventModalClose } = state.navigationStore.action
  const { modal_launched_from_url } = state.navigationStore.state

  const cardProps = {
    title: resource ? `Delete ${resource.name}` : "",
    showStatus: resource_loading || is_deleting_resource,
    statusMessage: (() => {
      if (resource_loading) {
        return "Loading..."
      } else if (is_deleting_resource) {
        return "Deleting..."
      }
    })()
  }

  let displayType = "course"
  if (resource && resource.resource_type === 30) displayType = "collection"
  if (resource && resource.resource_type === 20) displayType = "lesson"

  const shouldNavigateHome = window.location.pathname.startsWith("/library/")

  const toContinue = shouldNavigateHome
    ? getHomeLocation()
    : modal_launched_from_url
  const continueLabel = shouldNavigateHome ? "Continue to Home" : "Close"

  return _.assign(
    {},
    {
      resource,
      cardProps,
      displayType,
      deleteResource,
      toContinue,
      continueLabel,
      preventModalClose
    },
    ownProps
  )
}

const connectStores = [
  { store: "navigation", watch: false },
  {
    store: "resourceAdmin",
    watch: ["resource", "resource_loading", "is_deleting_resource"]
  },
  {
    store: "school",
    watch: false
  }
]

export default withTranslate(
  ConnectStores(ResourceArchiveCard, connectStores, {}, mapStateToProps)
)
