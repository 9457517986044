import { dispatchEvent } from "../utils/dispatcher"

// Import this file at the beginning of script execution.

// Tracks a list of event listeners in order to inspect
// and act upon the presence/lack of expected listeners.

// Using let for dev friendliness, but be cautious not to modify value.
export let eventListeners = {}

// key: name of the event listener
// action: description of action, either "added" or "removed"
const dispatchEventListenerEvent = (key, action) => {
  dispatchEvent("event-listener", {
    key,
    action
  })
}

// NOTE: not factoring in useCapture bool for matching listeners
// for removal. May need to support that, but may not.
const addListener = (key, method) => {
  eventListeners[key] = eventListeners[key]
    ? [...eventListeners[key], method]
    : [method]
  dispatchEventListenerEvent(key, "added")
}

// NOTE: not factoring in useCapture bool for matching listeners
// for removal. May need to support that, but may not.
const removeListener = (key, method) => {
  if (eventListeners[key] && method) {
    eventListeners[key] = eventListeners[key].filter(
      (_method) => _method !== method
    )

    // Simply remove the key entirely if no more listeners exist.
    if (!eventListeners[key].length) {
      delete eventListeners[key]
    }
  }
  dispatchEventListenerEvent(key, "removed")
}

const extendListeners = () => {
  if (!window._eventListenersExtended) {
    // Preventing extending these handlers more than once
    // which likely only happens on local dev hot reloads.
    window._eventListenersExtended = true

    // Cache default addEventListener.
    Document.prototype._addEventListener = Document.prototype.addEventListener

    Document.prototype.addEventListener = function (...args) {
      addListener(...args)
      // Call default addEventListener.
      this._addEventListener(...args)
    }

    // Cache default removeEventListener.
    Document.prototype._removeEventListener =
      Document.prototype.removeEventListener

    Document.prototype.removeEventListener = function (...args) {
      removeListener(...args)
      // Call default removeEventListener.
      this._removeEventListener(...args)
    }
  }
}

// By default, importing this module will extend event listeners.
extendListeners()

export default extendListeners
