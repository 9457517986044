import { LoaderFunction } from "react-router-dom"
import { zfd } from "zod-form-data"
import {
  applyLoaderRedirects,
  authTokenDisallowedRedirect,
  pathwrightAuthRequiredRedirect
} from "../../utils/loader-redirects"
import startAuthRequestAction, { authRequestFormSchema } from "./action"

const urlSearchParamsSchema = zfd.formData(authRequestFormSchema)

const startAuthRequestLoader: LoaderFunction = async ({ request }) => {
  const searchStr = new URL(request.url).search
  const searchParams = new URLSearchParams(searchStr)
  const parsed = urlSearchParamsSchema.safeParse(searchParams)

  if (parsed.success) {
    const formData = new FormData()
    formData.set("email", parsed.data.email)
    await startAuthRequestAction(formData)
  }

  return null
}

export default applyLoaderRedirects(
  pathwrightAuthRequiredRedirect,
  authTokenDisallowedRedirect
)(startAuthRequestLoader)
