import classnames from "classnames"
import styled from "styled-components"

import BackgroundImage from "@pathwright/ui/src/components/background/BackgroundImage"
import ThemeBackground from "@pathwright/ui/src/components/background/ThemeBackground"
import Button from "@pathwright/ui/src/components/button/Button"
import HtmlText from "@pathwright/ui/src/components/text/HtmlText"
import Text from "@pathwright/ui/src/components/ui/Text"
import { getRelativeUrl, isRelativeUrl, isSchoolUrl } from "../../utils/urls"

const StyledBanner = styled.div`
  padding: 30px;

  .Banner__content {
    position: relative;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
    text-align: center;

    h2 {
      font-weight: bold;
      margin-bottom: 10px;
    }

    .Button {
      margin-top: 10px;
    }
  }
`

const getButtonURL = (func, url) => {
  if (func === "subscribe") {
    return "/subscribe/"
  }
  if (url) {
    if (isSchoolUrl(url)) {
      return getRelativeUrl(url)
    }
    return url
  }
  return null
}

const Banner = function (props) {
  const {
    background_image,
    background_overlay,
    button_text,
    button_func,
    button_url,
    heading,
    subhead,
    font_color,
    userSubscribed,
    hideBannerAction
  } = props

  const url = getButtonURL(button_func, button_url)

  const showButton = hideBannerAction
    ? false
    : url && url.indexOf("/subscribe/") > -1
    ? !userSubscribed
    : button_text && button_text.length > 0

  const linkProps = url
    ? isRelativeUrl(url)
      ? { to: url }
      : { href: url }
    : {}

  const styleProps = {
    style: { color: font_color ? `#${font_color}` : "white" }
  }

  return (
    <StyledBanner className={classnames("Banner", props.className)}>
      {background_image || background_overlay ? (
        <BackgroundImage
          image={background_image}
          overlay={background_overlay}
        />
      ) : (
        <ThemeBackground />
      )}
      <div className="Banner__content">
        <Text.H2 {...styleProps} className="theme-heading theme-heading-bold">
          {heading}
        </Text.H2>
        {subhead && (
          <Text.Body {...styleProps}>
            <HtmlText html={subhead} el="span" />
          </Text.Body>
        )}
        {showButton && (
          <Button {...linkProps} styleType="primary" inverted brand>
            {button_text}
          </Button>
        )}
      </div>
    </StyledBanner>
  )
}

Banner.displayName = "Banner"
Banner.defaultProps = {
  userSubscribed: false,
  hideBannerAction: false
}

export default Banner
