import * as Apollo from "@apollo/client"
import { DocumentNode } from "graphql"
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never
    }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  AppPeopleSelectionKey: { input: any; output: any }
  DateTime: { input: any; output: any }
  EmailAddress: { input: any; output: any }
  JSON: { input: any; output: any }
  JWT: { input: any; output: any }
  NonEmptyString: { input: any; output: any }
  URL: { input: any; output: any }
  UUID: { input: any; output: any }
}

/** Result of updating a completion via API */
export type ApiCompletionUpdate = {
  __typename?: "APICompletionUpdate"
  completion_id?: Maybe<Scalars["Int"]["output"]>
  /** Reason, if any, for why the update was ignored. */
  ignored_reason?: Maybe<ApiCompletionUpdateIgnoredReason>
  new_path_progress?: Maybe<Scalars["Float"]["output"]>
  new_points_assigned?: Maybe<Scalars["Float"]["output"]>
  new_step_progress?: Maybe<Scalars["Float"]["output"]>
  prev_path_progress?: Maybe<Scalars["Float"]["output"]>
  prev_points_assigned?: Maybe<Scalars["Float"]["output"]>
  prev_step_progress?: Maybe<Scalars["Float"]["output"]>
  success?: Maybe<Scalars["Boolean"]["output"]>
}

export enum ApiCompletionUpdateAction {
  Complete = "complete",
  Reset = "reset",
  Skip = "skip"
}

export enum ApiCompletionUpdateIgnoredReason {
  /** Completion has already been completed and no there was points change. */
  AlreadyCompleted = "already_completed",
  /** Completion has already been reset. */
  AlreadyReset = "already_reset",
  /** Completion has already been skipped. */
  AlreadySkipped = "already_skipped"
}

/** The context of a Pathwright 'session' in a school. */
export type AccountContext = {
  __typename?: "AccountContext"
  membership?: Maybe<Membership>
  permissions?: Maybe<ContextPermissions>
  school?: Maybe<School>
  theme?: Maybe<Theme>
  user?: Maybe<User>
}

export enum ActionType {
  CanceledPausedSubscription = "canceled_paused_subscription",
  CanceledSubscription = "canceled_subscription",
  CompleteCourse = "complete_course",
  CompleteStep = "complete_step",
  CreateDiscussion = "create_discussion",
  CreateGroup = "create_group",
  CreateMembership = "create_membership",
  CreateRegistration = "create_registration",
  CreateResponse = "create_response",
  CreateSubscription = "create_subscription",
  PausedSubscription = "paused_subscription",
  SkipStep = "skip_step",
  UnpausedSubscription = "unpaused_subscription"
}

export type ActionTypeFilter = {
  eq?: InputMaybe<ActionType>
  gt?: InputMaybe<ActionType>
  gte?: InputMaybe<ActionType>
  in?: InputMaybe<Array<InputMaybe<ActionType>>>
  lt?: InputMaybe<ActionType>
  lte?: InputMaybe<ActionType>
  neq?: InputMaybe<ActionType>
  nin?: InputMaybe<Array<InputMaybe<ActionType>>>
  op?: InputMaybe<Scalars["String"]["input"]>
}

/** A Pathwright Activity */
export type Activity = {
  __typename?: "Activity"
  action?: Maybe<ActionType>
  action_dtime?: Maybe<Scalars["DateTime"]["output"]>
  action_type?: Maybe<Scalars["Int"]["output"]>
  discussion?: Maybe<Discussion>
  discussion_response?: Maybe<Response>
  group?: Maybe<Group>
  id?: Maybe<Scalars["Int"]["output"]>
  interest_level?: Maybe<Scalars["Int"]["output"]>
  offering_id?: Maybe<Scalars["Int"]["output"]>
  registration?: Maybe<Registration>
  resource?: Maybe<Resource>
  step?: Maybe<PathItem>
  triggered_by?: Maybe<User>
  user?: Maybe<User>
}

/** A connection to a list of items. */
export type ActivityConnection = {
  __typename?: "ActivityConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ActivityEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total?: Maybe<Scalars["Int"]["output"]>
}

/** An edge in a connection. */
export type ActivityEdge = {
  __typename?: "ActivityEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<Activity>
}

export type ActivityStats = {
  __typename?: "ActivityStats"
  discussion_responses?: Maybe<Scalars["Int"]["output"]>
  discussions?: Maybe<Scalars["Int"]["output"]>
  registrations?: Maybe<Scalars["Int"]["output"]>
  steps_completed?: Maybe<Scalars["Int"]["output"]>
  /** @deprecated `total` no longer provided. Insteaed, query the `total` on activity. */
  total?: Maybe<Scalars["Int"]["output"]>
}

export type AddPeopleSelection = {
  add_to: Array<InputMaybe<AddPeopleSelectionTo>>
  key: Scalars["AppPeopleSelectionKey"]["input"]
  type: AddPeopleSelectionType
}

export type AddPeopleSelectionTo = {
  key: Scalars["String"]["input"]
  role: AddPeopleSelectionToRole
  type: AddPeopleSelectionToType
}

export enum AddPeopleSelectionToRole {
  CohortCourseEditor = "cohort_course_editor",
  CohortLearner = "cohort_learner",
  CohortModerator = "cohort_moderator",
  CohortObserver = "cohort_observer",
  CohortTeacher = "cohort_teacher",
  GroupAdmin = "group_admin",
  GroupMember = "group_member",
  GroupModerator = "group_moderator",
  SchoolAdmin = "school_admin",
  SchoolMember = "school_member",
  SchoolStaff = "school_staff"
}

export enum AddPeopleSelectionToType {
  Cohort = "cohort",
  Group = "group"
}

export enum AddPeopleSelectionType {
  Cohort = "cohort",
  Email = "email",
  Group = "group",
  User = "user"
}

export type AssignConfig = {
  target_role?: InputMaybe<RegistrationRoleEnum>
}

/** Methods for authenticating a user in a space */
export type AuthMethod = {
  __typename?: "AuthMethod"
  /** Whether this auth method is enabled for the Space */
  enabled: Scalars["Boolean"]["output"]
  id: Scalars["String"]["output"]
  meta: NodeMeta
}

/** A request made by the user to authenticate by some other means than username/password. */
export type AuthRequest = {
  __typename?: "AuthRequest"
  /** How many times this request has attempted confirmation, for preventing brute-force attacks. */
  confirmation_attempts: Scalars["Int"]["output"]
  /** When the authentication request was confirmed and an auth token was issued. */
  confirmed_dtime?: Maybe<Scalars["DateTime"]["output"]>
  /** A place for storing extra information about the request. May include information such as the user's IP address, invite tokens, etc. */
  context?: Maybe<Scalars["JSON"]["output"]>
  /** If set, after this time, the authentication request will be considered invalid. */
  expiration_dtime?: Maybe<Scalars["DateTime"]["output"]>
  /** If this authentication request was successful, this is the auth token that was issued. */
  issued_auth_token?: Maybe<JsonWebToken>
  /** When the authentication request was initially started. */
  request_dtime: Scalars["DateTime"]["output"]
  /** A 'secret key' that must be provided by the client in order to confirm the request. Always null as this key cannot be exposed. */
  request_key?: Maybe<Scalars["String"]["output"]>
  /** The authentication method being used, such as an Email-based OTP (magic link). */
  request_method: AuthRequestMethod
  school?: Maybe<School>
  truncated_email?: Maybe<Scalars["String"]["output"]>
  /** A customer-viewable ID for the authentication request. Must be used for all API calls to prevent sequentially enumerating auth requests. */
  uuid: Scalars["UUID"]["output"]
}

export enum AuthRequestMethod {
  EmailOtp = "email_otp"
}

/** A Pathwright Author */
export type Author = {
  __typename?: "Author"
  bio?: Maybe<Scalars["String"]["output"]>
  display_name?: Maybe<Scalars["String"]["output"]>
  first_name?: Maybe<Scalars["String"]["output"]>
  full_name?: Maybe<Scalars["String"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  image?: Maybe<Scalars["String"]["output"]>
  last_name?: Maybe<Scalars["String"]["output"]>
  meta: NodeMeta
  order?: Maybe<Scalars["Int"]["output"]>
  salutation?: Maybe<Scalars["String"]["output"]>
  slug?: Maybe<Scalars["String"]["output"]>
}

/** A Pathwright Author */
export type AuthorImageArgs = {
  blur?: InputMaybe<Scalars["Int"]["input"]>
  crop?: InputMaybe<Crop>
  dpr?: InputMaybe<Scalars["Int"]["input"]>
  fit?: InputMaybe<Fit>
  height?: InputMaybe<Scalars["Int"]["input"]>
  width?: InputMaybe<Scalars["Int"]["input"]>
}

/** A connection to a list of items. */
export type AuthorConnection = {
  __typename?: "AuthorConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AuthorEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total?: Maybe<Scalars["Int"]["output"]>
}

/** An edge in a connection. */
export type AuthorEdge = {
  __typename?: "AuthorEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<Author>
}

/** A background task. */
export type BackgroundTask = {
  __typename?: "BackgroundTask"
  id: Scalars["UUID"]["output"]
  result?: Maybe<Scalars["JSON"]["output"]>
  status: BackgroundTaskStatus
}

export enum BackgroundTaskStatus {
  Failure = "failure",
  Pending = "pending",
  Retry = "retry",
  Revoked = "revoked",
  Started = "started",
  Success = "success"
}

/** A Pathwright Banner */
export type Banner = {
  __typename?: "Banner"
  background_image?: Maybe<Scalars["String"]["output"]>
  background_image_raw?: Maybe<Scalars["String"]["output"]>
  background_overlay?: Maybe<Scalars["String"]["output"]>
  background_type?: Maybe<Scalars["Int"]["output"]>
  button_func?: Maybe<Scalars["String"]["output"]>
  button_text?: Maybe<Scalars["String"]["output"]>
  button_url?: Maybe<Scalars["String"]["output"]>
  font_color?: Maybe<Scalars["String"]["output"]>
  heading?: Maybe<Scalars["String"]["output"]>
  hide_for_products?: Maybe<Array<Maybe<Scalars["Int"]["output"]>>>
  hide_for_subscription_plans?: Maybe<Array<Maybe<Scalars["Int"]["output"]>>>
  id?: Maybe<Scalars["Int"]["output"]>
  is_visible?: Maybe<Scalars["Boolean"]["output"]>
  order?: Maybe<Scalars["Int"]["output"]>
  readability?: Maybe<Scalars["Boolean"]["output"]>
  show_for_products?: Maybe<Array<Maybe<Scalars["Int"]["output"]>>>
  show_for_subscription_plans?: Maybe<Array<Maybe<Scalars["Int"]["output"]>>>
  subhead?: Maybe<Scalars["String"]["output"]>
}

/** A Pathwright Banner */
export type BannerBackground_ImageArgs = {
  blur?: InputMaybe<Scalars["Int"]["input"]>
  crop?: InputMaybe<Crop>
  dpr?: InputMaybe<Scalars["Int"]["input"]>
  fit?: InputMaybe<Fit>
  height?: InputMaybe<Scalars["Int"]["input"]>
  width?: InputMaybe<Scalars["Int"]["input"]>
}

/** A connection to a list of items. */
export type BannerConnection = {
  __typename?: "BannerConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BannerEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total?: Maybe<Scalars["Int"]["output"]>
}

/** An edge in a connection. */
export type BannerEdge = {
  __typename?: "BannerEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<Banner>
}

export type BatchMentorGroupMembershipResult = {
  __typename?: "BatchMentorGroupMembershipResult"
  group_memberships_created_count?: Maybe<Scalars["Int"]["output"]>
  success?: Maybe<Scalars["Boolean"]["output"]>
}

export type Calendar = {
  __typename?: "Calendar"
  events?: Maybe<Array<Maybe<CalendarEvent>>>
  id?: Maybe<Scalars["ID"]["output"]>
  name?: Maybe<Scalars["String"]["output"]>
  timezone?: Maybe<Scalars["String"]["output"]>
  url?: Maybe<Scalars["String"]["output"]>
}

export type CalendarEvent = {
  __typename?: "CalendarEvent"
  completion_status?: Maybe<Scalars["String"]["output"]>
  description?: Maybe<Scalars["String"]["output"]>
  end?: Maybe<Scalars["DateTime"]["output"]>
  ical_url?: Maybe<Scalars["String"]["output"]>
  id?: Maybe<Scalars["ID"]["output"]>
  name?: Maybe<Scalars["String"]["output"]>
  relative_url?: Maybe<Scalars["String"]["output"]>
  start?: Maybe<Scalars["DateTime"]["output"]>
  summary?: Maybe<Scalars["String"]["output"]>
  type?: Maybe<Scalars["String"]["output"]>
  url?: Maybe<Scalars["String"]["output"]>
}

export type CalendarStats = {
  __typename?: "CalendarStats"
  due?: Maybe<Scalars["Int"]["output"]>
  late?: Maybe<Scalars["Int"]["output"]>
  total?: Maybe<Scalars["Int"]["output"]>
  upcoming?: Maybe<Scalars["Int"]["output"]>
}

/** A Course Category */
export type Category = {
  __typename?: "Category"
  description?: Maybe<Scalars["String"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  name?: Maybe<Scalars["String"]["output"]>
  order?: Maybe<Scalars["Int"]["output"]>
  slug?: Maybe<Scalars["String"]["output"]>
}

/** A connection to a list of items. */
export type CategoryConnection = {
  __typename?: "CategoryConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CategoryEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total?: Maybe<Scalars["Int"]["output"]>
}

/** An edge in a connection. */
export type CategoryEdge = {
  __typename?: "CategoryEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<Category>
}

export type Certificate = {
  __typename?: "Certificate"
  created_time?: Maybe<Scalars["DateTime"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  /** Whether the certificate is currently active. */
  is_active?: Maybe<Scalars["Boolean"]["output"]>
  /** Name of certificate for visual identification. */
  name?: Maybe<Scalars["String"]["output"]>
  school?: Maybe<School>
  /** The PDF template URL. */
  template?: Maybe<Scalars["String"]["output"]>
  /** List of text objects to be merged with PDF template. */
  text_objects?: Maybe<Scalars["JSON"]["output"]>
}

/** A connection to a list of items. */
export type CertificateConnection = {
  __typename?: "CertificateConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CertificateEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total?: Maybe<Scalars["Int"]["output"]>
}

/** An edge in a connection. */
export type CertificateEdge = {
  __typename?: "CertificateEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<Certificate>
}

export type CertificateVariables = {
  __typename?: "CertificateVariables"
  id?: Maybe<Scalars["Int"]["output"]>
  /** Key/value pairs of variable paths and the number of times they are used by all courses. */
  stats?: Maybe<Scalars["JSON"]["output"]>
  /** Key/value pairs of variable paths and their values. Only applicable when scoping to a resource. */
  variables?: Maybe<Scalars["JSON"]["output"]>
}

export type CertificateVariablesContextInput = {
  resource_id?: InputMaybe<Scalars["Int"]["input"]>
}

export type CertificateVariablesMergeInput = {
  /** Old name of a key that should be transitioned to new_key. */
  current_key?: InputMaybe<Scalars["String"]["input"]>
  /** New name of a key that should be transitioned from current_key. Omitting this arg will result in deleting current_key. */
  new_key?: InputMaybe<Scalars["String"]["input"]>
  /** New value of the new_key. Omitting this arg will result in the current value of current_key to be used by default. */
  new_value?: InputMaybe<Scalars["String"]["input"]>
}

export type Codex = {
  __typename?: "Codex"
  config?: Maybe<CodexConfig>
  space?: Maybe<CodexSpace>
}

export type CodexSpaceArgs = {
  join?: InputMaybe<Scalars["Boolean"]["input"]>
  key: Scalars["String"]["input"]
}

export type CodexAccount = {
  __typename?: "CodexAccount"
  id: Scalars["ID"]["output"]
  name?: Maybe<Scalars["String"]["output"]>
}

export type CodexConfig = {
  __typename?: "CodexConfig"
  account?: Maybe<CodexAccount>
  firebase?: Maybe<FirebaseConfig>
  user?: Maybe<CodexUser>
}

export type CodexNode = {
  __typename?: "CodexNode"
  created?: Maybe<Scalars["DateTime"]["output"]>
  data: Scalars["JSON"]["output"]
  fromId?: Maybe<Scalars["ID"]["output"]>
  graphId?: Maybe<Scalars["ID"]["output"]>
  id: Scalars["ID"]["output"]
  key?: Maybe<Scalars["String"]["output"]>
  parentId?: Maybe<Scalars["ID"]["output"]>
  rootId?: Maybe<Scalars["ID"]["output"]>
  toId?: Maybe<Scalars["ID"]["output"]>
  type: Scalars["String"]["output"]
  updated?: Maybe<Scalars["DateTime"]["output"]>
  userId?: Maybe<Scalars["ID"]["output"]>
}

/** A publish to Paths (codex data structure) */
export type CodexPathPublish = {
  __typename?: "CodexPathPublish"
  node?: Maybe<PathsCodexNode>
}

export type CodexSpace = {
  __typename?: "CodexSpace"
  id: Scalars["ID"]["output"]
  membership?: Maybe<CodexNode>
  nodes?: Maybe<Array<Maybe<CodexNode>>>
}

export type CodexSpaceNodesArgs = {
  last_x_days?: InputMaybe<Scalars["Int"]["input"]>
  type?: InputMaybe<Scalars["String"]["input"]>
}

export type CodexUser = {
  __typename?: "CodexUser"
  authToken: Scalars["String"]["output"]
  displayName: Scalars["String"]["output"]
  id: Scalars["ID"]["output"]
  image?: Maybe<Scalars["String"]["output"]>
}

/** Whether user can use Cohort share UI. */
export type CohortShareAccess = {
  __typename?: "CohortShareAccess"
  /** A user's access level to share UI. */
  access?: Maybe<ShareAccessPermission>
  /** When share UI is gated, this will be the reason for why. */
  gated_reason?: Maybe<CohortShareAccessGatedReasonType>
}

export enum CohortShareAccessGatedReasonType {
  CohortCompleted = "COHORT_COMPLETED",
  CohortEnded = "COHORT_ENDED",
  CohortLicensedSeatsFilled = "COHORT_LICENSED_SEATS_FILLED",
  CohortRegistrationEnded = "COHORT_REGISTRATION_ENDED",
  CohortSeatsFilled = "COHORT_SEATS_FILLED"
}

/** Whether user can use Cohort share > add UI. */
export type CohortShareAddAccess = {
  __typename?: "CohortShareAddAccess"
  /** A user's access level to share > add UI. */
  access?: Maybe<ShareAccessPermission>
  /** When share > add UI is gated, this will be the reason for why. */
  gated_reason?: Maybe<CohortShareAddAccessGatedReasonType>
}

export enum CohortShareAddAccessGatedReasonType {
  SchoolInactive = "SCHOOL_INACTIVE"
}

/** Whether user can use Cohort share > link UI. */
export type CohortShareLinkAccess = {
  __typename?: "CohortShareLinkAccess"
  /** A user's access level to share > link UI. */
  access?: Maybe<ShareAccessPermission>
  /** When share > link UI is gated, this will be the reason for why. */
  gated_reason?: Maybe<CohortShareLinkAccessGatedReasonType>
}

export enum CohortShareLinkAccessGatedReasonType {
  SchoolInactive = "SCHOOL_INACTIVE"
}

/** Whether user can use Cohort share > track UI. */
export type CohortShareTrackAccess = {
  __typename?: "CohortShareTrackAccess"
  /** A user's access level to share > track UI. */
  access?: Maybe<ShareAccessPermission>
  /** When share > track UI is gated, this will be the reason for why. */
  gated_reason?: Maybe<CohortShareTrackAccessGatedReasonType>
}

export enum CohortShareTrackAccessGatedReasonType {
  Placeholder = "PLACEHOLDER"
}

export type CohortsStatusMeta = {
  __typename?: "CohortsStatusMeta"
  completed?: Maybe<Scalars["Int"]["output"]>
  in_progress?: Maybe<Scalars["Int"]["output"]>
}

/** Computed fields on path item */
export type Completion = {
  __typename?: "Completion"
  checked_by?: Maybe<User>
  checked_by_id?: Maybe<Scalars["Int"]["output"]>
  checked_date?: Maybe<Scalars["DateTime"]["output"]>
  completion_attempts?: Maybe<Scalars["Int"]["output"]>
  completion_comment?: Maybe<Scalars["String"]["output"]>
  completion_date?: Maybe<Scalars["DateTime"]["output"]>
  completion_due?: Maybe<Scalars["DateTime"]["output"]>
  feedback_discussion_context?: Maybe<CompletionFeedbackDiscussionContext>
  grading_type?: Maybe<Scalars["String"]["output"]>
  has_failed_passing_attempt?: Maybe<Scalars["Boolean"]["output"]>
  has_points?: Maybe<Scalars["Boolean"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  is_auto_graded?: Maybe<Scalars["Boolean"]["output"]>
  is_complete?: Maybe<Scalars["Boolean"]["output"]>
  is_forced_completion?: Maybe<Scalars["Boolean"]["output"]>
  is_graded?: Maybe<Scalars["Boolean"]["output"]>
  is_late?: Maybe<Scalars["Boolean"]["output"]>
  is_skipped?: Maybe<Scalars["Boolean"]["output"]>
  last_completion_attempt_date?: Maybe<Scalars["DateTime"]["output"]>
  minimum_passing_score?: Maybe<Scalars["Int"]["output"]>
  needs_grading?: Maybe<Scalars["Boolean"]["output"]>
  overridden_by?: Maybe<User>
  overridden_by_api?: Maybe<Scalars["Boolean"]["output"]>
  requires_api_completion?: Maybe<Scalars["Boolean"]["output"]>
  submission?: Maybe<UserSubmission>
  user?: Maybe<User>
  userpoints_earned?: Maybe<Scalars["Int"]["output"]>
  userpoints_explanation?: Maybe<Scalars["String"]["output"]>
  userpoints_value?: Maybe<Scalars["Int"]["output"]>
}

/** Link to the feedback discussion context for a completion */
export type CompletionFeedbackDiscussionContext = {
  __typename?: "CompletionFeedbackDiscussionContext"
  discussion_id?: Maybe<Scalars["Int"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  num_responses?: Maybe<Scalars["Int"]["output"]>
}

export enum CompletionStateFilter {
  Completed = "completed",
  InProgress = "in_progress",
  Unstarted = "unstarted"
}

/** Computed discount values for an enrollment */
export type ComputedDiscount = {
  __typename?: "ComputedDiscount"
  discount_amount?: Maybe<Scalars["Float"]["output"]>
  discount_percent?: Maybe<Scalars["Float"]["output"]>
  discounted_total?: Maybe<Scalars["Float"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  max_seat_discount?: Maybe<Scalars["Int"]["output"]>
}

export type ContextModelInput = {
  cohort_id?: InputMaybe<Scalars["Int"]["input"]>
  path_item_id?: InputMaybe<Scalars["Int"]["input"]>
  path_item_source_id?: InputMaybe<Scalars["Int"]["input"]>
  resource_id?: InputMaybe<Scalars["Int"]["input"]>
}

export type ContextPermissions = {
  __typename?: "ContextPermissions"
  can_administrate?: Maybe<Scalars["Boolean"]["output"]>
  can_edit_library?: Maybe<Scalars["Boolean"]["output"]>
  has_member_access?: Maybe<Scalars["Boolean"]["output"]>
  has_pathwright_staff_access?: Maybe<Scalars["Boolean"]["output"]>
  is_school_owner?: Maybe<Scalars["Boolean"]["output"]>
}

/** Contextual permissions for a PathwrightFeature */
export type ContextualFeaturePermission = {
  __typename?: "ContextualFeaturePermission"
  cohort_id?: Maybe<Scalars["Int"]["output"]>
  feature_action?: Maybe<FeatureActionType>
  feature_key?: Maybe<FeatureKeyType>
  id?: Maybe<Scalars["Int"]["output"]>
  permission_in_context?: Maybe<FeaturePermissionType>
  resource_id?: Maybe<Scalars["Int"]["output"]>
  school_id?: Maybe<Scalars["Int"]["output"]>
}

/** A coupon applicable to a course or school subscription plan */
export type Coupon = {
  __typename?: "Coupon"
  /** The total before discount */
  amount?: Maybe<Scalars["Float"]["output"]>
  code?: Maybe<Scalars["String"]["output"]>
  /** How much the user will save */
  discount_amount?: Maybe<Scalars["Float"]["output"]>
  discount_percent?: Maybe<Scalars["Float"]["output"]>
  discounted_subscription_cycles?: Maybe<Scalars["Int"]["output"]>
  /** How much the user will pay */
  discounted_total?: Maybe<Scalars["Float"]["output"]>
  end_date?: Maybe<Scalars["DateTime"]["output"]>
  has_ended?: Maybe<Scalars["Boolean"]["output"]>
  has_started?: Maybe<Scalars["Boolean"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  is_one_per_user?: Maybe<Scalars["Boolean"]["output"]>
  max_num_redemptions?: Maybe<Scalars["Int"]["output"]>
  max_seat_discount?: Maybe<Scalars["Int"]["output"]>
  num_redemptions?: Maybe<Scalars["Int"]["output"]>
  start_date?: Maybe<Scalars["DateTime"]["output"]>
  user_can_redeem?: Maybe<Scalars["Boolean"]["output"]>
  user_redeemed?: Maybe<Scalars["Boolean"]["output"]>
}

export type CreateInvitationsSuccess = {
  __typename?: "CreateInvitationsSuccess"
  invitations_attempted_count?: Maybe<Scalars["Int"]["output"]>
  invitations_sent_count?: Maybe<Scalars["Int"]["output"]>
  /** An array of unsuccessful invitations containing email, role, type, and error. */
  unsuccessful_invitations?: Maybe<Scalars["JSON"]["output"]>
}

/** Curriculum license order data */
export type CurriculumLicenseOrderData = {
  coupon_code?: InputMaybe<Scalars["String"]["input"]>
  license_offering_id: Scalars["Int"]["input"]
  licensee_school_id: Scalars["Int"]["input"]
  licensor_school_id: Scalars["Int"]["input"]
  name?: InputMaybe<Scalars["String"]["input"]>
  role?: InputMaybe<RegistrationRoleEnum>
  seat_count?: InputMaybe<Scalars["Int"]["input"]>
}

/** A school licensing school curriculum subscription */
export type CurriculumSubscription = {
  __typename?: "CurriculumSubscription"
  curriculum_plan?: Maybe<SchoolCurriculumPlan>
  id?: Maybe<Scalars["Int"]["output"]>
  licensee?: Maybe<School>
  licensor?: Maybe<School>
  subscription?: Maybe<Subscription>
}

/** A connection to a list of items. */
export type CurriculumSubscriptionConnection = {
  __typename?: "CurriculumSubscriptionConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CurriculumSubscriptionEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total?: Maybe<Scalars["Int"]["output"]>
}

/** An edge in a connection. */
export type CurriculumSubscriptionEdge = {
  __typename?: "CurriculumSubscriptionEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<CurriculumSubscription>
}

/** Curriculum subscription order data */
export type CurriculumSubscriptionOrderData = {
  curriculum_plan_id: Scalars["Int"]["input"]
  licensee_id: Scalars["Int"]["input"]
  plan_visibility_code?: InputMaybe<Scalars["String"]["input"]>
}

export enum CurriculumType {
  /** The source curriculum for this group (what the teacher edits) */
  Source = "source",
  /** The current logged in user's curriculum */
  User = "user"
}

export type DateFilter = {
  eq?: InputMaybe<Scalars["DateTime"]["input"]>
  gt?: InputMaybe<Scalars["DateTime"]["input"]>
  gte?: InputMaybe<Scalars["DateTime"]["input"]>
  in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>
  lt?: InputMaybe<Scalars["DateTime"]["input"]>
  lte?: InputMaybe<Scalars["DateTime"]["input"]>
  neq?: InputMaybe<Scalars["DateTime"]["input"]>
  nin?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>
  op?: InputMaybe<Scalars["String"]["input"]>
}

/** Pathwright discussion */
export type Discussion = {
  __typename?: "Discussion"
  allow_replies?: Maybe<Scalars["Boolean"]["output"]>
  body?: Maybe<Scalars["String"]["output"]>
  cohort?: Maybe<Group>
  cohort_id?: Maybe<Scalars["Int"]["output"]>
  context_id?: Maybe<Scalars["Int"]["output"]>
  created_time?: Maybe<Scalars["DateTime"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  is_discussion_question?: Maybe<Scalars["Boolean"]["output"]>
  last_activity_time?: Maybe<Scalars["DateTime"]["output"]>
  likes?: Maybe<Scalars["Int"]["output"]>
  modified_time?: Maybe<Scalars["DateTime"]["output"]>
  order?: Maybe<Scalars["Int"]["output"]>
  path_item?: Maybe<PathItem>
  /** @deprecated No longer used. */
  path_item_name?: Maybe<Scalars["String"]["output"]>
  resource?: Maybe<Resource>
  resource_id?: Maybe<Scalars["Int"]["output"]>
  response?: Maybe<Response>
  responses?: Maybe<ResponseConnection>
  step_id?: Maybe<Scalars["Int"]["output"]>
  tagLinks?: Maybe<TagLinkConnection>
  title?: Maybe<Scalars["String"]["output"]>
  total_responses?: Maybe<Scalars["Int"]["output"]>
  type?: Maybe<Scalars["Int"]["output"]>
  user?: Maybe<User>
  user_has_viewed_recent?: Maybe<Scalars["Boolean"]["output"]>
  user_is_subscribed?: Maybe<Scalars["Boolean"]["output"]>
  user_last_responded_time?: Maybe<Scalars["DateTime"]["output"]>
  user_last_viewed_time?: Maybe<Scalars["DateTime"]["output"]>
  user_liked?: Maybe<Scalars["Boolean"]["output"]>
}

/** Pathwright discussion */
export type DiscussionResponseArgs = {
  response_id?: InputMaybe<Scalars["Int"]["input"]>
}

/** Pathwright discussion */
export type DiscussionResponsesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  order_by?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
}

/** Pathwright discussion */
export type DiscussionTagLinksArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  discussion_context_id?: InputMaybe<Scalars["Int"]["input"]>
  first: Scalars["Int"]["input"]
  tag_id?: InputMaybe<Scalars["Int"]["input"]>
}

/** A connection to a list of items. */
export type DiscussionConnection = {
  __typename?: "DiscussionConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DiscussionEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total?: Maybe<Scalars["Int"]["output"]>
}

export type DiscussionContextInput = {
  cohort_id?: InputMaybe<Scalars["Int"]["input"]>
  completion_id?: InputMaybe<Scalars["Int"]["input"]>
  resource_id?: InputMaybe<Scalars["Int"]["input"]>
  step_id?: InputMaybe<Scalars["Int"]["input"]>
}

/** An edge in a connection. */
export type DiscussionEdge = {
  __typename?: "DiscussionEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<Discussion>
}

/** Pathwright user discussion history */
export type DiscussionParticipants = {
  __typename?: "DiscussionParticipants"
  display_name?: Maybe<Scalars["String"]["output"]>
  email?: Maybe<Scalars["String"]["output"]>
  external_account_link?: Maybe<ExternalAccountLink>
  first_name?: Maybe<Scalars["String"]["output"]>
  full_name?: Maybe<Scalars["String"]["output"]>
  gravatar?: Maybe<Gravatar>
  group_role_stats?: Maybe<Array<Maybe<GroupRoleStats>>>
  id?: Maybe<Scalars["Int"]["output"]>
  /** A user account setup is onl considered 'finalized' when the `first_name` and `last_name` fields have been set. */
  is_finalized?: Maybe<Scalars["Boolean"]["output"]>
  is_staff?: Maybe<Scalars["Boolean"]["output"]>
  is_superuser?: Maybe<Scalars["Boolean"]["output"]>
  last_name?: Maybe<Scalars["String"]["output"]>
  membership?: Maybe<Membership>
  memberships?: Maybe<MembershipConnection>
  mentorGroupMemberships?: Maybe<MentorGroupMembershipConnection>
  no_password_set?: Maybe<Scalars["Boolean"]["output"]>
  permissions?: Maybe<ContextPermissions>
  profile?: Maybe<Profile>
  registrations?: Maybe<RegistrationConnection>
  school_group_subscriptions?: Maybe<SchoolGroupSubscriptionConnection>
  school_subscription?: Maybe<SchoolSubscription>
  token?: Maybe<Token>
  username?: Maybe<Scalars["String"]["output"]>
}

/** Pathwright user discussion history */
export type DiscussionParticipantsMembershipsArgs = {
  access_revoked_date_filter?: InputMaybe<Array<InputMaybe<DateFilter>>>
  after?: InputMaybe<Scalars["String"]["input"]>
  archived_date_filter?: InputMaybe<Array<InputMaybe<DateFilter>>>
  daysInactive?: InputMaybe<Scalars["Int"]["input"]>
  first: Scalars["Int"]["input"]
  global?: InputMaybe<Scalars["Boolean"]["input"]>
  hasAccess?: InputMaybe<Scalars["Boolean"]["input"]>
  orderBy?: InputMaybe<Scalars["String"]["input"]>
  role_filter?: InputMaybe<Array<InputMaybe<MembershipRoleFilter>>>
  search?: InputMaybe<Scalars["String"]["input"]>
}

/** Pathwright user discussion history */
export type DiscussionParticipantsMentorGroupMembershipsArgs = {
  _or?: InputMaybe<MentorGroupMembershipOr>
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  group_type?: InputMaybe<MentorGroupType>
  group_types?: InputMaybe<MentorGroupTypeFilter>
  mentor_group_seat_limit?: InputMaybe<Scalars["Int"]["input"]>
  mentor_group_seat_limit_filter?: InputMaybe<IntFilter>
  mentor_group_seats_filled?: InputMaybe<Scalars["Int"]["input"]>
  mentor_group_seats_filled_filter?: InputMaybe<IntFilter>
  order_by?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
  role?: InputMaybe<MentorGroupMembershipRoleFilter>
  search?: InputMaybe<Scalars["String"]["input"]>
}

/** Pathwright user discussion history */
export type DiscussionParticipantsRegistrationsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  completion_state?: InputMaybe<CompletionStateFilter>
  created?: InputMaybe<DateFilter>
  first: Scalars["Int"]["input"]
  has_parent_registration?: InputMaybe<Scalars["Boolean"]["input"]>
  last_active?: InputMaybe<DateFilter>
  orderBy?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
  parent_cohort_filter?: InputMaybe<IdFilter>
  progress_filter?: InputMaybe<ProgressFilter>
  role?: InputMaybe<RegistrationRoleEnum>
  role_filter?: InputMaybe<RoleFilter>
  search?: InputMaybe<Scalars["String"]["input"]>
  send_discussion_notifications?: InputMaybe<Scalars["Boolean"]["input"]>
  send_due_notifications?: InputMaybe<Scalars["Boolean"]["input"]>
}

/** Pathwright user discussion history */
export type DiscussionParticipantsSchool_Group_SubscriptionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
}

/** A connection to a list of items. */
export type DiscussionParticipantsConnection = {
  __typename?: "DiscussionParticipantsConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DiscussionParticipantsEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total?: Maybe<Scalars["Int"]["output"]>
}

/** An edge in a connection. */
export type DiscussionParticipantsEdge = {
  __typename?: "DiscussionParticipantsEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<DiscussionParticipants>
}

export type DiscussionPostInput = {
  body?: InputMaybe<Scalars["String"]["input"]>
  is_discussion_question?: InputMaybe<Scalars["Boolean"]["input"]>
  title?: InputMaybe<Scalars["String"]["input"]>
  type?: InputMaybe<Scalars["Int"]["input"]>
}

export type DiscussionResponseContextInput = {
  cohort_id?: InputMaybe<Scalars["Int"]["input"]>
  completion_id?: InputMaybe<Scalars["Int"]["input"]>
  discussion_id?: InputMaybe<Scalars["Int"]["input"]>
  resource_id?: InputMaybe<Scalars["Int"]["input"]>
  step_id?: InputMaybe<Scalars["Int"]["input"]>
}

export type DiscussionResponseInput = {
  attachments?: InputMaybe<Scalars["JSON"]["input"]>
  body: Scalars["String"]["input"]
  depth?: InputMaybe<Scalars["Int"]["input"]>
  discussion_id?: InputMaybe<Scalars["Int"]["input"]>
  reply_parent_id?: InputMaybe<Scalars["Int"]["input"]>
}

export enum DiscussionTypeInput {
  Faq = "faq",
  Feedback = "feedback",
  Post = "post",
  Question = "question",
  Review = "review"
}

export enum EmailDeliveryStatus {
  Bounced = "bounced",
  Clicked = "clicked",
  Complained = "complained",
  Deferred = "deferred",
  Opened = "opened",
  Queued = "queued",
  Rejected = "rejected",
  Sent = "sent",
  Unsubscribed = "unsubscribed"
}

/** Related data regarding an email */
export type EmailMessageData = {
  __typename?: "EmailMessageData"
  created_dtime?: Maybe<Scalars["DateTime"]["output"]>
  delivery_status?: Maybe<EmailDeliveryStatus>
  delivery_status_updated?: Maybe<Scalars["DateTime"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  message_id?: Maybe<Scalars["Int"]["output"]>
  recipient?: Maybe<Scalars["String"]["output"]>
  reject_reason?: Maybe<Scalars["String"]["output"]>
}

export type EquivalenceIdFilter = {
  eq?: InputMaybe<Scalars["ID"]["input"]>
  neq?: InputMaybe<Scalars["ID"]["input"]>
}

/** An SSO ExternalAccountLink */
export type ExternalAccountLink = {
  __typename?: "ExternalAccountLink"
  external_id?: Maybe<Scalars["String"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  last_external_update?: Maybe<Scalars["DateTime"]["output"]>
  link_type?: Maybe<ExternalAccountType>
  linked_oidc_idp_id?: Maybe<Scalars["Int"]["output"]>
  linked_saml2_idp_id?: Maybe<Scalars["Int"]["output"]>
  user?: Maybe<User>
}

export enum ExternalAccountType {
  Generic = "generic",
  Oidc = "oidc",
  Saml2 = "saml2"
}

export enum FeatureActionType {
  AuthLogin = "auth__login",
  UpdateVisibility = "update__visibility",
  View = "view"
}

export type FeatureContextInput = {
  cohort_id?: InputMaybe<Scalars["Int"]["input"]>
  resource_id?: InputMaybe<Scalars["Int"]["input"]>
}

export type FeatureKeyFilter = {
  eq?: InputMaybe<FeatureKeyType>
  gt?: InputMaybe<FeatureKeyType>
  gte?: InputMaybe<FeatureKeyType>
  in?: InputMaybe<Array<InputMaybe<FeatureKeyType>>>
  lt?: InputMaybe<FeatureKeyType>
  lte?: InputMaybe<FeatureKeyType>
  neq?: InputMaybe<FeatureKeyType>
  nin?: InputMaybe<Array<InputMaybe<FeatureKeyType>>>
  op?: InputMaybe<Scalars["String"]["input"]>
}

export enum FeatureKeyType {
  AuthLoginOptionEmailLink = "auth__login_option__email_link",
  AuthLoginOptionGlobalSsoApple = "auth__login_option__global_sso__apple",
  AuthLoginOptionGlobalSsoGoogle = "auth__login_option__global_sso__google",
  AuthLoginOptionPassword = "auth__login_option__password",
  AuthPlatform = "auth__platform",
  CohortActivityFeed = "cohort__activity_feed",
  CohortMemberDirectory = "cohort__member_directory",
  PathsAppPublish = "paths_app__publish"
}

export enum FeaturePermissionType {
  AdminOnly = "admin_only",
  Disabled = "disabled",
  Everyone = "everyone",
  StaffOnly = "staff_only"
}

/** List of reviewers that have left a discussion, ordered by earliest response. */
export type FeedbackReviewers = {
  __typename?: "FeedbackReviewers"
  id?: Maybe<Scalars["Int"]["output"]>
  reviewers?: Maybe<Array<Maybe<Scalars["JSON"]["output"]>>>
}

/** Filestack keys */
export type FilestackInfo = {
  __typename?: "FilestackInfo"
  api_key?: Maybe<Scalars["String"]["output"]>
  policy?: Maybe<Scalars["String"]["output"]>
  signature?: Maybe<Scalars["String"]["output"]>
}

export type FirebaseConfig = {
  __typename?: "FirebaseConfig"
  apiKey: Scalars["String"]["output"]
  databaseURL: Scalars["String"]["output"]
  projectId: Scalars["String"]["output"]
  userToken?: Maybe<Scalars["String"]["output"]>
}

export type FirebaseIntegration = {
  __typename?: "FirebaseIntegration"
  auth_token?: Maybe<Scalars["String"]["output"]>
  client_api_key?: Maybe<Scalars["String"]["output"]>
  url?: Maybe<Scalars["String"]["output"]>
}

/** Gift subscription order data */
export type GiftSubscriptionOrderData = {
  gift_message?: InputMaybe<Scalars["String"]["input"]>
  num_cycles: Scalars["Int"]["input"]
  plan_id: Scalars["Int"]["input"]
  plan_visibility_code?: InputMaybe<Scalars["String"]["input"]>
  recipient_first_name: Scalars["String"]["input"]
  recipient_last_name: Scalars["String"]["input"]
}

/** A Gravatar profile. See https://en.gravatar.com/site/implement/profiles/ */
export type Gravatar = {
  __typename?: "Gravatar"
  /** The user's Gravatar Profile first name */
  first_name: Scalars["String"]["output"]
  /** The user's Gravatar Profile last name */
  last_name: Scalars["String"]["output"]
  meta: NodeMeta
}

/** A Pathwright Group */
export type Group = {
  __typename?: "Group"
  access_ended?: Maybe<Scalars["Boolean"]["output"]>
  access_ends?: Maybe<Scalars["DateTime"]["output"]>
  access_started?: Maybe<Scalars["Boolean"]["output"]>
  access_starts?: Maybe<Scalars["DateTime"]["output"]>
  analytics_disabled?: Maybe<Scalars["Boolean"]["output"]>
  created?: Maybe<Scalars["DateTime"]["output"]>
  created_by_id?: Maybe<Scalars["Int"]["output"]>
  description?: Maybe<Scalars["String"]["output"]>
  discussion_enabled?: Maybe<Scalars["Boolean"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  include_in_plans?: Maybe<SchoolSubscriptionPlanConnection>
  include_with_products?: Maybe<StripeProductConnection>
  invite_urls?: Maybe<GroupInviteUrls>
  is_collection_linked?: Maybe<Scalars["Boolean"]["output"]>
  is_master?: Maybe<Scalars["Boolean"]["output"]>
  is_source_cohort?: Maybe<Scalars["Boolean"]["output"]>
  is_subscription_only?: Maybe<Scalars["Boolean"]["output"]>
  member_stats?: Maybe<GroupMemberStats>
  meta: NodeMeta
  name?: Maybe<Scalars["String"]["output"]>
  parent?: Maybe<Group>
  parent_section_id?: Maybe<Scalars["Int"]["output"]>
  path?: Maybe<Path>
  permissions?: Maybe<GroupPermissions>
  price?: Maybe<Scalars["Float"]["output"]>
  registration?: Maybe<Registration>
  registration_ended?: Maybe<Scalars["Boolean"]["output"]>
  registration_ends?: Maybe<Scalars["DateTime"]["output"]>
  registration_started?: Maybe<Scalars["Boolean"]["output"]>
  registration_starts?: Maybe<Scalars["DateTime"]["output"]>
  registrations?: Maybe<RegistrationConnection>
  resource?: Maybe<Resource>
  resource_id?: Maybe<Scalars["Int"]["output"]>
  resource_license?: Maybe<ResourceLicense>
  seats?: Maybe<Scalars["Int"]["output"]>
  seats_available?: Maybe<Scalars["Int"]["output"]>
  seats_filled?: Maybe<Scalars["Int"]["output"]>
  source?: Maybe<Group>
  source_cohort_id?: Maybe<Scalars["Int"]["output"]>
  stats?: Maybe<GroupStats>
  status?: Maybe<GroupStatus>
  /** The current user's (or queried user's) registration in the parent cohort. */
  user_parent_registration?: Maybe<Registration>
  /** The current user (or queried user) registration in the cohort. */
  user_registration?: Maybe<Registration>
  /** The current user's (or queried user's) registration in the source cohort. */
  user_source_registration?: Maybe<Registration>
  visibility?: Maybe<Scalars["Int"]["output"]>
}

/** A Pathwright Group */
export type GroupInclude_In_PlansArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
}

/** A Pathwright Group */
export type GroupInclude_With_ProductsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
}

/** A Pathwright Group */
export type GroupPathArgs = {
  id?: InputMaybe<Scalars["Int"]["input"]>
  type?: InputMaybe<CurriculumType>
}

/** A Pathwright Group */
export type GroupRegistrationArgs = {
  id?: InputMaybe<Scalars["Int"]["input"]>
  user_id?: InputMaybe<Scalars["Int"]["input"]>
}

/** A Pathwright Group */
export type GroupRegistrationsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  completion_state?: InputMaybe<CompletionStateFilter>
  created?: InputMaybe<DateFilter>
  first: Scalars["Int"]["input"]
  has_parent_registration?: InputMaybe<Scalars["Boolean"]["input"]>
  last_active?: InputMaybe<DateFilter>
  orderBy?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
  parent_cohort_filter?: InputMaybe<IdFilter>
  progress_filter?: InputMaybe<ProgressFilter>
  role?: InputMaybe<RegistrationRoleEnum>
  role_filter?: InputMaybe<RoleFilter>
  search?: InputMaybe<Scalars["String"]["input"]>
  send_discussion_notifications?: InputMaybe<Scalars["Boolean"]["input"]>
  send_due_notifications?: InputMaybe<Scalars["Boolean"]["input"]>
}

/** A Pathwright Group */
export type GroupUser_Parent_RegistrationArgs = {
  id?: InputMaybe<Scalars["Int"]["input"]>
  user_id?: InputMaybe<Scalars["Int"]["input"]>
}

/** A Pathwright Group */
export type GroupUser_RegistrationArgs = {
  id?: InputMaybe<Scalars["Int"]["input"]>
  user_id?: InputMaybe<Scalars["Int"]["input"]>
}

/** A Pathwright Group */
export type GroupUser_Source_RegistrationArgs = {
  id?: InputMaybe<Scalars["Int"]["input"]>
  user_id?: InputMaybe<Scalars["Int"]["input"]>
}

/** A connection to a list of items. */
export type GroupConnection = {
  __typename?: "GroupConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<GroupEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total?: Maybe<Scalars["Int"]["output"]>
}

/** An edge in a connection. */
export type GroupEdge = {
  __typename?: "GroupEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<Group>
}

export type GroupInviteUrls = {
  __typename?: "GroupInviteUrls"
  editor?: Maybe<Scalars["String"]["output"]>
  moderator?: Maybe<Scalars["String"]["output"]>
  observer?: Maybe<Scalars["String"]["output"]>
  student?: Maybe<Scalars["String"]["output"]>
  teacher?: Maybe<Scalars["String"]["output"]>
  viewer?: Maybe<Scalars["String"]["output"]>
}

/** Group license order data */
export type GroupLicenseOrderData = {
  coupon_code?: InputMaybe<Scalars["String"]["input"]>
  license_offering_id: Scalars["Int"]["input"]
  name: Scalars["String"]["input"]
  role?: InputMaybe<RegistrationRoleEnum>
  seat_count?: InputMaybe<Scalars["Int"]["input"]>
}

export type GroupMemberStats = {
  __typename?: "GroupMemberStats"
  send_discussion_notifications?: Maybe<Scalars["Int"]["output"]>
  staff?: Maybe<Scalars["Int"]["output"]>
  total?: Maybe<Scalars["Int"]["output"]>
}

export type GroupPermissions = {
  __typename?: "GroupPermissions"
  can_invite_editor?: Maybe<Scalars["Boolean"]["output"]>
  can_invite_moderator?: Maybe<Scalars["Boolean"]["output"]>
  can_invite_observer?: Maybe<Scalars["Boolean"]["output"]>
  can_invite_student?: Maybe<Scalars["Boolean"]["output"]>
  can_invite_teacher?: Maybe<Scalars["Boolean"]["output"]>
  can_make_public?: Maybe<Scalars["Boolean"]["output"]>
  can_publish?: Maybe<Scalars["Boolean"]["output"]>
}

export type GroupRoleStats = {
  __typename?: "GroupRoleStats"
  role?: Maybe<Scalars["Int"]["output"]>
  total?: Maybe<Scalars["Int"]["output"]>
  type?: Maybe<Scalars["String"]["output"]>
}

/** Whether user can use Group share UI. */
export type GroupShareAccess = {
  __typename?: "GroupShareAccess"
  /** A user's access level to share UI. */
  access?: Maybe<ShareAccessPermission>
  /** When share UI is gated, this will be the reason for why. */
  gated_reason?: Maybe<GroupShareAccessGatedReasonType>
}

export enum GroupShareAccessGatedReasonType {
  Placeholder = "PLACEHOLDER"
}

/** Whether user can use Group share > add UI. */
export type GroupShareAddAccess = {
  __typename?: "GroupShareAddAccess"
  /** A user's access level to share > add UI. */
  access?: Maybe<ShareAccessPermission>
  /** When share > add UI is gated, this will be the reason for why. */
  gated_reason?: Maybe<GroupShareAddAccessGatedReasonType>
}

export enum GroupShareAddAccessGatedReasonType {
  GroupSeatsFilled = "GROUP_SEATS_FILLED",
  GroupSubscriptionInactive = "GROUP_SUBSCRIPTION_INACTIVE"
}

/** Whether user can use Group share > link UI. */
export type GroupShareLinkAccess = {
  __typename?: "GroupShareLinkAccess"
  /** A user's access level to share > link UI. */
  access?: Maybe<ShareAccessPermission>
  /** When share > link UI is gated, this will be the reason for why. */
  gated_reason?: Maybe<GroupShareLinkAccessGatedReasonType>
}

export enum GroupShareLinkAccessGatedReasonType {
  GroupSeatsFilled = "GROUP_SEATS_FILLED",
  GroupSubscriptionInactive = "GROUP_SUBSCRIPTION_INACTIVE"
}

/** Whether user can use Group share > track UI. */
export type GroupShareTrackAccess = {
  __typename?: "GroupShareTrackAccess"
  /** A user's access level to share > track UI. */
  access?: Maybe<ShareAccessPermission>
  /** When share > track UI is gated, this will be the reason for why. */
  gated_reason?: Maybe<GroupShareTrackAccessGatedReasonType>
}

export enum GroupShareTrackAccessGatedReasonType {
  Placeholder = "PLACEHOLDER"
}

export type GroupStats = {
  __typename?: "GroupStats"
  completion?: Maybe<Scalars["Float"]["output"]>
  points?: Maybe<Scalars["Int"]["output"]>
  total_points?: Maybe<Scalars["Int"]["output"]>
}

export enum GroupStatus {
  /** The cohort has been completed */
  Completed = "completed",
  /** The cohort is in progress */
  InProgress = "in_progress"
}

/** Group subscription order data */
export type GroupSubscriptionOrderData = {
  group_name: Scalars["String"]["input"]
  price_id: Scalars["Int"]["input"]
  quantity: Scalars["Int"]["input"]
  redirect_to?: InputMaybe<Scalars["String"]["input"]>
}

export enum GroupType {
  /** The curriculum group */
  Curriculum = "curriculum"
}

export enum GroupVisibility {
  MembersOnly = "members_only",
  Private = "private",
  Public = "public"
}

export type GroupVisibilityFilter = {
  eq?: InputMaybe<GroupVisibility>
  gt?: InputMaybe<GroupVisibility>
  gte?: InputMaybe<GroupVisibility>
  in?: InputMaybe<Array<InputMaybe<GroupVisibility>>>
  lt?: InputMaybe<GroupVisibility>
  lte?: InputMaybe<GroupVisibility>
  neq?: InputMaybe<GroupVisibility>
  nin?: InputMaybe<Array<InputMaybe<GroupVisibility>>>
  op?: InputMaybe<Scalars["String"]["input"]>
}

export type IdFilter = {
  eq?: InputMaybe<Scalars["ID"]["input"]>
  gt?: InputMaybe<Scalars["ID"]["input"]>
  gte?: InputMaybe<Scalars["ID"]["input"]>
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>
  lt?: InputMaybe<Scalars["ID"]["input"]>
  lte?: InputMaybe<Scalars["ID"]["input"]>
  neq?: InputMaybe<Scalars["ID"]["input"]>
  nin?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>
  op?: InputMaybe<Scalars["String"]["input"]>
}

/** Provides context data for the inbox. A group, cohort, or user. */
export type InboxContext = {
  __typename?: "InboxContext"
  /** A cohort of students */
  cohort?: Maybe<Group>
  /** A group of students */
  group?: Maybe<MentorGroup>
  id?: Maybe<Scalars["String"]["output"]>
}

/** A combo query of inbox groups */
export type InboxGroupConnection = {
  __typename?: "InboxGroupConnection"
  cohort?: Maybe<Group>
  mentor_group?: Maybe<MentorGroup>
}

/** A connection to a list of items. */
export type InboxGroupConnectionConnection = {
  __typename?: "InboxGroupConnectionConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InboxGroupConnectionEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total?: Maybe<Scalars["Int"]["output"]>
}

/** An edge in a connection. */
export type InboxGroupConnectionEdge = {
  __typename?: "InboxGroupConnectionEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<InboxGroupConnection>
}

/** A mentor group with reviewable items */
export type InboxMentorGroupConnection = {
  __typename?: "InboxMentorGroupConnection"
  group?: Maybe<MentorGroup>
  id?: Maybe<Scalars["Int"]["output"]>
  pending_review_count?: Maybe<Scalars["Int"]["output"]>
}

/** A path with reviewable items (pending_review_count) */
export type InboxPath = {
  __typename?: "InboxPath"
  group?: Maybe<Group>
  id?: Maybe<Scalars["Int"]["output"]>
  pending_review_count?: Maybe<Scalars["Int"]["output"]>
  registration?: Maybe<Registration>
  resource?: Maybe<Resource>
}

/** A connection to a list of items. */
export type InboxPathConnection = {
  __typename?: "InboxPathConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InboxPathEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total?: Maybe<Scalars["Int"]["output"]>
}

/** An edge in a connection. */
export type InboxPathEdge = {
  __typename?: "InboxPathEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<InboxPath>
}

/** A pending review count of reviewable items for target (InboxPendingReviewTarget) */
export type InboxPendingReview = {
  __typename?: "InboxPendingReview"
  count?: Maybe<Scalars["Int"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
}

export enum InboxPendingReviewTarget {
  Group = "group",
  Path = "path"
}

/** A count of completion items for people within a cohort or mentor group */
export type InboxPeopleCompletionCounts = {
  __typename?: "InboxPeopleCompletionCounts"
  count?: Maybe<Scalars["Int"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  user?: Maybe<User>
}

/** A connection to a list of items. */
export type InboxPeopleCompletionCountsConnection = {
  __typename?: "InboxPeopleCompletionCountsConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InboxPeopleCompletionCountsEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total?: Maybe<Scalars["Int"]["output"]>
}

/** An edge in a connection. */
export type InboxPeopleCompletionCountsEdge = {
  __typename?: "InboxPeopleCompletionCountsEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<InboxPeopleCompletionCounts>
}

export enum InboxPeopleCompletionCountsTarget {
  Cohort = "cohort",
  Group = "group"
}

/** A reviewer of a reviewable path item */
export type InboxReviwer = {
  __typename?: "InboxReviwer"
  can_review?: Maybe<Scalars["Boolean"]["output"]>
  cohort_role?: Maybe<RegistrationRoleEnum>
  group_role?: Maybe<MentorGroupMembershipRole>
  id?: Maybe<Scalars["Int"]["output"]>
  user?: Maybe<User>
}

/** A connection to a list of items. */
export type InboxReviwerConnection = {
  __typename?: "InboxReviwerConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InboxReviwerEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total?: Maybe<Scalars["Int"]["output"]>
}

/** An edge in a connection. */
export type InboxReviwerEdge = {
  __typename?: "InboxReviwerEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<InboxReviwer>
}

export type IntFilter = {
  eq?: InputMaybe<Scalars["Int"]["input"]>
  gt?: InputMaybe<Scalars["Int"]["input"]>
  gte?: InputMaybe<Scalars["Int"]["input"]>
  in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>
  lt?: InputMaybe<Scalars["Int"]["input"]>
  lte?: InputMaybe<Scalars["Int"]["input"]>
  neq?: InputMaybe<Scalars["Int"]["input"]>
  nin?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>
  op?: InputMaybe<Scalars["String"]["input"]>
}

export type Integrations = {
  __typename?: "Integrations"
  firebase?: Maybe<FirebaseIntegration>
  intercom?: Maybe<IntercomIntegration>
}

export type IntercomIntegration = {
  __typename?: "IntercomIntegration"
  /** The Intercom app ID that will be used by the client. */
  app_id?: Maybe<Scalars["String"]["output"]>
  /** Pathwright's Intercom app ID. */
  pathwright_app_id?: Maybe<Scalars["String"]["output"]>
  /** Intercom access token, used for tracking events, only exposed to school admin. */
  school_access_token?: Maybe<Scalars["String"]["output"]>
  /** The school's Intercom app ID. */
  school_app_id?: Maybe<Scalars["String"]["output"]>
  /** Intercom secret key, used for Identification Verification, only exposed to school admin. */
  school_secret_key?: Maybe<Scalars["String"]["output"]>
  /** If an Intercom secret key is available, a user_hash will be generated for the current user which will be passed to Intercom for Identification Verification. */
  user_hash?: Maybe<Scalars["String"]["output"]>
}

export enum InterestLevel {
  High = "high",
  Low = "low",
  Medium = "medium"
}

/** An invitation for a user to join a school, an offering, or a community group as a given role. */
export type Invitation = {
  __typename?: "Invitation"
  auth_create_user?: Maybe<Scalars["Boolean"]["output"]>
  community_group?: Maybe<MentorGroup>
  community_group_membership?: Maybe<MentorGroupMembership>
  created_dtime?: Maybe<Scalars["DateTime"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  invitation_accepted_dtime?: Maybe<Scalars["DateTime"]["output"]>
  invitation_email?: Maybe<EmailMessageData>
  invitation_hash?: Maybe<Scalars["String"]["output"]>
  invitation_message?: Maybe<Scalars["String"]["output"]>
  /** Specific to which type of invitation this is (see invitation_type) */
  invitation_role?: Maybe<Scalars["Int"]["output"]>
  invitation_status?: Maybe<InvitationStatus>
  invitation_subject?: Maybe<Scalars["String"]["output"]>
  /** 1: Invitation to offering 2: Invitation to school 3: Invitation to community group */
  invitation_type?: Maybe<Scalars["Int"]["output"]>
  invitation_url?: Maybe<Scalars["String"]["output"]>
  offering?: Maybe<Group>
  offering_registration?: Maybe<Registration>
  school?: Maybe<School>
  school_membership?: Maybe<Membership>
  to_email?: Maybe<Scalars["String"]["output"]>
  to_first_name?: Maybe<Scalars["String"]["output"]>
  to_last_name?: Maybe<Scalars["String"]["output"]>
  to_user?: Maybe<User>
}

/** A connection to a list of items. */
export type InvitationConnection = {
  __typename?: "InvitationConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InvitationEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total?: Maybe<Scalars["Int"]["output"]>
}

export type InvitationContext = {
  /** Scope invitations to cohort */
  cohort_id?: InputMaybe<Scalars["Int"]["input"]>
  /** Scope invitations to community group */
  community_group_id?: InputMaybe<Scalars["Int"]["input"]>
}

/** An edge in a connection. */
export type InvitationEdge = {
  __typename?: "InvitationEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<Invitation>
}

export enum InvitationStatus {
  Accepted = "accepted",
  NotAccepted = "not_accepted"
}

/** The data embedded in an invitation token. */
export type InvitationToken = {
  __typename?: "InvitationToken"
  cohort?: Maybe<Group>
  group?: Maybe<MentorGroup>
  id: Scalars["String"]["output"]
  invite_type: InvitationType
  role: Scalars["Int"]["output"]
  sender?: Maybe<User>
}

export enum InvitationType {
  Cohort = "cohort",
  CommunityGroup = "community_group",
  School = "school"
}

/** Analytics data for a path or path item */
export type ItemAnalytics = {
  __typename?: "ItemAnalytics"
  avg_score?: Maybe<Scalars["Float"]["output"]>
  /** The number of users who have completed the whole path */
  complete?: Maybe<Scalars["Int"]["output"]>
  completion_rate?: Maybe<Scalars["Float"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  /** The number of users who still are working on this path */
  incomplete?: Maybe<Scalars["Int"]["output"]>
  item?: Maybe<PathItem>
  items?: Maybe<Scalars["JSON"]["output"]>
  /** The number of step completions in the path that need grading */
  need_feedback?: Maybe<Scalars["Int"]["output"]>
  next_count?: Maybe<Scalars["Int"]["output"]>
  path_id?: Maybe<Scalars["Int"]["output"]>
  /** This is a list of up to 10 users who have completed this path (used to render avatar stacks).  */
  students_here?: Maybe<Scalars["JSON"]["output"]>
  /** The number of earned points across all steps in this path (Note: this excludes steps that aren't due if due dates are in use) */
  total_points?: Maybe<Scalars["Int"]["output"]>
  /** The number of available points across all completed and past due student steps in this path */
  total_points_available?: Maybe<Scalars["Int"]["output"]>
  total_users?: Maybe<Scalars["Int"]["output"]>
}

/** A JSONWebToken. */
export type JsonWebToken = {
  __typename?: "JSONWebToken"
  token: Scalars["String"]["output"]
}

/** A resource license offering */
export type LicenseOffering = {
  __typename?: "LicenseOffering"
  /** @deprecated Deprecated in favor of `licensing_mode`. */
  allow_private_group_licensing?: Maybe<Scalars["Boolean"]["output"]>
  /** @deprecated Deprecated in favor of `licensing_mode`. */
  allow_school_licensing?: Maybe<Scalars["Boolean"]["output"]>
  cost_per_seat?: Maybe<Scalars["Float"]["output"]>
  description?: Maybe<Scalars["String"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  include_creator_seat?: Maybe<Scalars["Boolean"]["output"]>
  include_in_curriculum_plans?: Maybe<SchoolCurriculumPlanConnection>
  include_in_school_curriculum_subscriptions?: Maybe<CurriculumSubscriptionConnection>
  include_in_subscription_plans?: Maybe<SchoolSubscriptionPlanConnection>
  included_in_user_subscription?: Maybe<Scalars["Boolean"]["output"]>
  licensing_mode?: Maybe<LicenseOfferingLicensingMode>
  licensor?: Maybe<School>
  minimum_license_duration?: Maybe<Scalars["Int"]["output"]>
  minimum_seat_count?: Maybe<Scalars["Int"]["output"]>
  minimum_seat_count_for_private_group_licensing?: Maybe<
    Scalars["Int"]["output"]
  >
  minimum_seat_count_for_school_licensing?: Maybe<Scalars["Int"]["output"]>
  register_creator_as?: Maybe<RegistrationRoleEnum>
  resource?: Maybe<Resource>
  school_license?: Maybe<ResourceLicense>
}

/** A resource license offering */
export type LicenseOfferingInclude_In_Curriculum_PlansArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
}

/** A resource license offering */
export type LicenseOfferingInclude_In_School_Curriculum_SubscriptionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  licensee_id?: InputMaybe<IdFilter>
  licensor_id?: InputMaybe<IdFilter>
}

/** A resource license offering */
export type LicenseOfferingInclude_In_Subscription_PlansArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
}

/** A resource license offering */
export type LicenseOfferingSchool_LicenseArgs = {
  licensee_school_id: Scalars["Int"]["input"]
}

/** A connection to a list of items. */
export type LicenseOfferingConnection = {
  __typename?: "LicenseOfferingConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LicenseOfferingEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total?: Maybe<Scalars["Int"]["output"]>
}

/** An edge in a connection. */
export type LicenseOfferingEdge = {
  __typename?: "LicenseOfferingEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<LicenseOffering>
}

export enum LicenseOfferingFor {
  /** This license is for private groups */
  PrivateGroup = "private_group",
  /** This license is for other schools */
  School = "school"
}

export enum LicenseOfferingLicensingMode {
  /** Resource: Group subscriptions */
  GroupSubscription = "group_subscription",
  /** Resource: Private cohorts */
  PrivateCohorts = "private_cohorts",
  /** Resource: School licensing */
  SchoolLicensing = "school_licensing"
}

export enum LicenseRoleEnum {
  Moderator = "moderator",
  Student = "student",
  Teacher = "teacher"
}

/** A Licensee School */
export type LicenseeSchool = {
  __typename?: "LicenseeSchool"
  about?: Maybe<Scalars["String"]["output"]>
  created?: Maybe<Scalars["DateTime"]["output"]>
  currency_code?: Maybe<Scalars["String"]["output"]>
  email?: Maybe<Scalars["String"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  ietf_language_tag?: Maybe<Scalars["String"]["output"]>
  name?: Maybe<Scalars["String"]["output"]>
  subdomain?: Maybe<Scalars["String"]["output"]>
  website?: Maybe<Scalars["String"]["output"]>
}

/** Lifetime school subscription order data */
export type LifetimeSchoolSubscriptionOrderData = {
  coupon_code?: InputMaybe<Scalars["String"]["input"]>
  plan_id: Scalars["Int"]["input"]
  plan_visibility_code?: InputMaybe<Scalars["String"]["input"]>
}

export type ListOrderInput = {
  id: Scalars["Int"]["input"]
  order: Scalars["Int"]["input"]
}

/** Info about a user's login options */
export type LoginInfo = {
  __typename?: "LoginInfo"
  auth_url?: Maybe<Scalars["String"]["output"]>
  is_external?: Maybe<Scalars["Boolean"]["output"]>
  primary_color?: Maybe<Scalars["String"]["output"]>
  prompt?: Maybe<Scalars["String"]["output"]>
}

/** Not-CSS Media Queries */
export type Media = {
  __typename?: "Media"
  filestack?: Maybe<FilestackInfo>
  image?: Maybe<Scalars["String"]["output"]>
}

/** Not-CSS Media Queries */
export type MediaImageArgs = {
  blur?: InputMaybe<Scalars["Int"]["input"]>
  crop?: InputMaybe<Crop>
  dpr?: InputMaybe<Scalars["Int"]["input"]>
  fit?: InputMaybe<Fit>
  height?: InputMaybe<Scalars["Int"]["input"]>
  url: Scalars["String"]["input"]
  width?: InputMaybe<Scalars["Int"]["input"]>
}

/** Contextual data needed for the Media Manager */
export type MediaContext = {
  __typename?: "MediaContext"
  accountID?: Maybe<Scalars["String"]["output"]>
  mediaStoragePath?: Maybe<Scalars["String"]["output"]>
  user?: Maybe<User>
}

/** A Pathwright User's Membership to a School */
export type Membership = {
  __typename?: "Membership"
  access_revoked_time?: Maybe<Scalars["DateTime"]["output"]>
  archived_time?: Maybe<Scalars["DateTime"]["output"]>
  consent_dtime?: Maybe<Scalars["DateTime"]["output"]>
  consent_snapshot?: Maybe<Scalars["String"]["output"]>
  created?: Maybe<Scalars["DateTime"]["output"]>
  has_access?: Maybe<Scalars["Boolean"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  last_active?: Maybe<Scalars["DateTime"]["output"]>
  last_seen?: Maybe<Scalars["DateTime"]["output"]>
  role?: Maybe<Scalars["Int"]["output"]>
  school?: Maybe<School>
  school_id?: Maybe<Scalars["Int"]["output"]>
  user?: Maybe<User>
}

/** A connection to a list of items. */
export type MembershipConnection = {
  __typename?: "MembershipConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MembershipEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total?: Maybe<Scalars["Int"]["output"]>
}

/** An edge in a connection. */
export type MembershipEdge = {
  __typename?: "MembershipEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<Membership>
}

export type MembershipMeta = {
  __typename?: "MembershipMeta"
  editor?: Maybe<Scalars["Int"]["output"]>
  lead_mentor?: Maybe<Scalars["Int"]["output"]>
  learner?: Maybe<Scalars["Int"]["output"]>
  mentor?: Maybe<Scalars["Int"]["output"]>
  moderator?: Maybe<Scalars["Int"]["output"]>
  observer?: Maybe<Scalars["Int"]["output"]>
  teacher?: Maybe<Scalars["Int"]["output"]>
}

export enum MembershipRole {
  Administrator = "administrator",
  Editor = "editor",
  Facilitator = "facilitator",
  Student = "student"
}

export type MembershipRoleFilter = {
  eq?: InputMaybe<MembershipRole>
  gt?: InputMaybe<MembershipRole>
  gte?: InputMaybe<MembershipRole>
  in?: InputMaybe<Array<InputMaybe<MembershipRole>>>
  lt?: InputMaybe<MembershipRole>
  lte?: InputMaybe<MembershipRole>
  neq?: InputMaybe<MembershipRole>
  nin?: InputMaybe<Array<InputMaybe<MembershipRole>>>
  op?: InputMaybe<Scalars["String"]["input"]>
}

export type MembershipStats = {
  __typename?: "MembershipStats"
  /** Active within the last 30 days */
  active?: Maybe<Scalars["Int"]["output"]>
  has_access?: Maybe<Scalars["Int"]["output"]>
  /** Inactive for over 60 days */
  inactive?: Maybe<Scalars["Int"]["output"]>
  no_access?: Maybe<Scalars["Int"]["output"]>
  /** Inactive within the last 60 days  */
  slipping?: Maybe<Scalars["Int"]["output"]>
  total?: Maybe<Scalars["Int"]["output"]>
}

export type MentorGroup = {
  __typename?: "MentorGroup"
  child_groups?: Maybe<MentorGroupConnection>
  created?: Maybe<Scalars["DateTime"]["output"]>
  description?: Maybe<Scalars["String"]["output"]>
  flattened_child_groups?: Maybe<MentorGroupConnection>
  group_subscription?: Maybe<SchoolGroupSubscription>
  group_subscriptions?: Maybe<SchoolGroupSubscriptionConnection>
  has_child_groups?: Maybe<Scalars["Boolean"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  invitations?: Maybe<InvitationConnection>
  memberships?: Maybe<MentorGroupMembershipConnection>
  modified?: Maybe<Scalars["DateTime"]["output"]>
  name?: Maybe<Scalars["String"]["output"]>
  owner?: Maybe<User>
  parent?: Maybe<MentorGroup>
  parent_id?: Maybe<Scalars["Int"]["output"]>
  permissions?: Maybe<MentorGroupPermissions>
  school?: Maybe<School>
  seat_limit?: Maybe<Scalars["Int"]["output"]>
  seats_available?: Maybe<Scalars["Int"]["output"]>
  seats_filled?: Maybe<Scalars["Int"]["output"]>
  slug?: Maybe<Scalars["String"]["output"]>
  type?: Maybe<Scalars["Int"]["output"]>
  user_membership?: Maybe<MentorGroupMembership>
}

export type MentorGroupChild_GroupsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  descendant_of_parent_id?: InputMaybe<EquivalenceIdFilter>
  first: Scalars["Int"]["input"]
  id?: InputMaybe<IdFilter>
  parent_id?: InputMaybe<IdFilter>
  search?: InputMaybe<Scalars["String"]["input"]>
  seat_limit?: InputMaybe<Scalars["Int"]["input"]>
  seat_limit_filter?: InputMaybe<IntFilter>
  type?: InputMaybe<MentorGroupType>
}

export type MentorGroupFlattened_Child_GroupsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  descendant_of_parent_id?: InputMaybe<EquivalenceIdFilter>
  first: Scalars["Int"]["input"]
  id?: InputMaybe<IdFilter>
  parent_id?: InputMaybe<IdFilter>
  search?: InputMaybe<Scalars["String"]["input"]>
  seat_limit?: InputMaybe<Scalars["Int"]["input"]>
  seat_limit_filter?: InputMaybe<IntFilter>
  type?: InputMaybe<MentorGroupType>
}

export type MentorGroupGroup_SubscriptionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
}

export type MentorGroupInvitationsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  context?: InputMaybe<InvitationContext>
  delivery_status?: InputMaybe<EmailDeliveryStatus>
  first: Scalars["Int"]["input"]
  order_by?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
  search?: InputMaybe<Scalars["String"]["input"]>
  status?: InputMaybe<InvitationStatus>
  type?: InputMaybe<InvitationType>
}

export type MentorGroupMembershipsArgs = {
  _or?: InputMaybe<MentorGroupMembershipOr>
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  group_type?: InputMaybe<MentorGroupType>
  group_types?: InputMaybe<MentorGroupTypeFilter>
  mentor_group_seat_limit?: InputMaybe<Scalars["Int"]["input"]>
  mentor_group_seat_limit_filter?: InputMaybe<IntFilter>
  mentor_group_seats_filled?: InputMaybe<Scalars["Int"]["input"]>
  mentor_group_seats_filled_filter?: InputMaybe<IntFilter>
  order_by?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
  role?: InputMaybe<MentorGroupMembershipRoleFilter>
  search?: InputMaybe<Scalars["String"]["input"]>
}

/** A connection to a list of items. */
export type MentorGroupConnection = {
  __typename?: "MentorGroupConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MentorGroupEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total?: Maybe<Scalars["Int"]["output"]>
}

/** An edge in a connection. */
export type MentorGroupEdge = {
  __typename?: "MentorGroupEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<MentorGroup>
}

export type MentorGroupMembership = {
  __typename?: "MentorGroupMembership"
  access_revoked_time?: Maybe<Scalars["DateTime"]["output"]>
  can_review?: Maybe<Scalars["Boolean"]["output"]>
  created?: Maybe<Scalars["DateTime"]["output"]>
  group?: Maybe<MentorGroup>
  group_id?: Maybe<Scalars["Int"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  role?: Maybe<Scalars["Int"]["output"]>
  school?: Maybe<School>
  send_mentor_digest?: Maybe<Scalars["Boolean"]["output"]>
  user?: Maybe<User>
}

/** A connection to a list of items. */
export type MentorGroupMembershipConnection = {
  __typename?: "MentorGroupMembershipConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MentorGroupMembershipEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total?: Maybe<Scalars["Int"]["output"]>
}

/** An edge in a connection. */
export type MentorGroupMembershipEdge = {
  __typename?: "MentorGroupMembershipEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<MentorGroupMembership>
}

export type MentorGroupMembershipOr = {
  group_type?: InputMaybe<MentorGroupType>
  group_types?: InputMaybe<MentorGroupTypeFilter>
  mentor_group_seat_limit?: InputMaybe<Scalars["Int"]["input"]>
  mentor_group_seat_limit_filter?: InputMaybe<IntFilter>
  mentor_group_seats_filled?: InputMaybe<Scalars["Int"]["input"]>
  mentor_group_seats_filled_filter?: InputMaybe<IntFilter>
  role?: InputMaybe<MentorGroupMembershipRoleFilter>
  search?: InputMaybe<Scalars["String"]["input"]>
}

export enum MentorGroupMembershipRole {
  Admin = "admin",
  Member = "member",
  Moderator = "moderator"
}

export type MentorGroupMembershipRoleFilter = {
  eq?: InputMaybe<MentorGroupMembershipRole>
  gt?: InputMaybe<MentorGroupMembershipRole>
  gte?: InputMaybe<MentorGroupMembershipRole>
  in?: InputMaybe<Array<InputMaybe<MentorGroupMembershipRole>>>
  lt?: InputMaybe<MentorGroupMembershipRole>
  lte?: InputMaybe<MentorGroupMembershipRole>
  neq?: InputMaybe<MentorGroupMembershipRole>
  nin?: InputMaybe<Array<InputMaybe<MentorGroupMembershipRole>>>
  op?: InputMaybe<Scalars["String"]["input"]>
}

export type MentorGroupPermissions = {
  __typename?: "MentorGroupPermissions"
  can_add_admin?: Maybe<Scalars["Boolean"]["output"]>
  can_add_group?: Maybe<Scalars["Boolean"]["output"]>
  can_add_member?: Maybe<Scalars["Boolean"]["output"]>
  can_add_moderator?: Maybe<Scalars["Boolean"]["output"]>
  can_change_parent?: Maybe<Scalars["Boolean"]["output"]>
  can_delete_group?: Maybe<Scalars["Boolean"]["output"]>
  can_edit_group?: Maybe<Scalars["Boolean"]["output"]>
  can_edit_subscription?: Maybe<Scalars["Boolean"]["output"]>
  can_update_admin_review_permission?: Maybe<Scalars["Boolean"]["output"]>
  can_update_member_review_permission?: Maybe<Scalars["Boolean"]["output"]>
  can_update_moderator_review_permission?: Maybe<Scalars["Boolean"]["output"]>
}

export type MentorGroupStats = {
  __typename?: "MentorGroupStats"
  community?: Maybe<Scalars["Int"]["output"]>
  group_subscription?: Maybe<Scalars["Int"]["output"]>
  personal?: Maybe<Scalars["Int"]["output"]>
  total?: Maybe<Scalars["Int"]["output"]>
}

export enum MentorGroupType {
  Community = "community",
  GroupSubscription = "group_subscription",
  Personal = "personal"
}

export type MentorGroupTypeFilter = {
  eq?: InputMaybe<MentorGroupType>
  gt?: InputMaybe<MentorGroupType>
  gte?: InputMaybe<MentorGroupType>
  in?: InputMaybe<Array<InputMaybe<MentorGroupType>>>
  lt?: InputMaybe<MentorGroupType>
  lte?: InputMaybe<MentorGroupType>
  neq?: InputMaybe<MentorGroupType>
  nin?: InputMaybe<Array<InputMaybe<MentorGroupType>>>
  op?: InputMaybe<Scalars["String"]["input"]>
}

/** A scheduled message */
export type Message = {
  __typename?: "Message"
  based_on_step_date?: Maybe<MessageStepDateField>
  body?: Maybe<Scalars["String"]["output"]>
  channels?: Maybe<Array<Maybe<MessageChannel>>>
  id?: Maybe<Scalars["Int"]["output"]>
  is_active?: Maybe<Scalars["Boolean"]["output"]>
  path_id?: Maybe<Scalars["Int"]["output"]>
  recipient_filter?: Maybe<MessageRecipientFilter>
  send_date?: Maybe<Scalars["DateTime"]["output"]>
  sent_date?: Maybe<Scalars["DateTime"]["output"]>
  step_source_id?: Maybe<Scalars["Int"]["output"]>
  subject?: Maybe<Scalars["String"]["output"]>
  threshold_minutes?: Maybe<Scalars["Int"]["output"]>
  type?: Maybe<MessageType>
}

export enum MessageChannel {
  Email = "email",
  Notification = "notification"
}

/** A connection to a list of items. */
export type MessageConnection = {
  __typename?: "MessageConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MessageEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total?: Maybe<Scalars["Int"]["output"]>
}

export type MessageContext = {
  cohort_id?: InputMaybe<Scalars["Int"]["input"]>
  path_id?: InputMaybe<Scalars["Int"]["input"]>
  source_id: Scalars["Int"]["input"]
}

/** An edge in a connection. */
export type MessageEdge = {
  __typename?: "MessageEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<Message>
}

export type MessageInput = {
  based_on_step_date?: InputMaybe<MessageStepDateField>
  body: Scalars["String"]["input"]
  channels: Array<InputMaybe<MessageChannel>>
  recipient_filter?: InputMaybe<MessageRecipientFilter>
  subject: Scalars["String"]["input"]
  threshold_minutes: Scalars["Int"]["input"]
  type: MessageType
}

export enum MessageRecipientFilter {
  /** All learners */
  All = "all",
  /** Learners with completed steps */
  StepComplete = "step_complete",
  /** Learners with incomplete steps */
  StepIncomplete = "step_incomplete"
}

export enum MessageStepDateField {
  Due = "due",
  Starts = "starts"
}

export enum MessageType {
  Reminder = "reminder"
}

export type MessageTypeFilter = {
  eq?: InputMaybe<MessageType>
  gt?: InputMaybe<MessageType>
  gte?: InputMaybe<MessageType>
  in?: InputMaybe<Array<InputMaybe<MessageType>>>
  lt?: InputMaybe<MessageType>
  lte?: InputMaybe<MessageType>
  neq?: InputMaybe<MessageType>
  nin?: InputMaybe<Array<InputMaybe<MessageType>>>
  op?: InputMaybe<Scalars["String"]["input"]>
}

export type Mutations = {
  __typename?: "Mutations"
  /** Accepts a community group invitation */
  acceptCommunityGroupInvitation?: Maybe<Scalars["Boolean"]["output"]>
  /** Add people to group or cohort */
  addPeopleTo?: Maybe<BackgroundTask>
  apiCompletionUpdate?: Maybe<ApiCompletionUpdate>
  /** Creates a registration for the user */
  assign?: Maybe<Scalars["JSON"]["output"]>
  /** Creates multiple new mentor group memberships */
  batchCreateMentorGroupMembership?: Maybe<BatchMentorGroupMembershipResult>
  /** Cancels a new subscription for the user. */
  cancelSchoolSubscription?: Maybe<SchoolSubscription>
  /** Changes a user's password */
  changePassword?: Maybe<Scalars["Boolean"]["output"]>
  /** Marks a path item as completed */
  completePathItem?: Maybe<PathItem>
  /** Allows a user to attempt to confirm an AuthRequest */
  confirmAuthRequest?: Maybe<AuthRequest>
  /** Copies an existing resource */
  copyResource?: Maybe<Resource>
  /** Creat a banner */
  createBanner?: Maybe<Banner>
  /** Creates a certificate for a school */
  createCertificate?: Maybe<Certificate>
  /** Creates a contextualFeaturePermission for a given context */
  createContextualFeaturePermission?: Maybe<ContextualFeaturePermission>
  /** Create a curriculum license order */
  createCurriculumLicenseOrder?: Maybe<Order>
  /** Create a curriculum subscription order */
  createCurriculumSubscriptionOrder?: Maybe<Order>
  createDiscussion?: Maybe<Discussion>
  createDiscussionLike?: Maybe<Discussion>
  createDiscussionResponse?: Maybe<Response>
  createDiscussionResponseLike?: Maybe<Response>
  createDiscussionSubscription?: Maybe<Discussion>
  /** Creates an external account link for a user in a school, associating them with an external ID. */
  createExternalAccountLink?: Maybe<ExternalAccountLink>
  /** Create a gift subscription order */
  createGiftSubscriptionOrder?: Maybe<Order>
  /** Creates a new cohort for a resource */
  createGroup?: Maybe<Group>
  /** Create a group license order */
  createGroupLicenseOrder?: Maybe<Order>
  /** Create a group subscription order */
  createGroupSubscriptionOrder?: Maybe<Order>
  /** Creates invitations for the given invite emails */
  createInvitations?: Maybe<CreateInvitationsSuccess>
  /** Create a lifetime school subscription order */
  createLifetimeSchoolSubscriptionOrder?: Maybe<Order>
  /** Creates a school membership for a user who already has an account on Pathwright, but is not yet a member of the school. Allowed only for schools who have API access enabled. */
  createMembershipForExistingUser?: Maybe<Membership>
  /** Creates a new mentor group */
  createMentorGroup?: Maybe<MentorGroup>
  /** Creates a new mentor group membership */
  createMentorGroupMembership?: Maybe<MentorGroupMembership>
  /** Creates a message. */
  createMessage?: Maybe<Message>
  /** Create an order. Only one order may be created at a time. */
  createOrder?: Maybe<Order>
  /** Create a page. */
  createPage?: Maybe<Page>
  /** Creates a user's password, only in cases where a user does not already have a password. */
  createPassword?: Maybe<Scalars["Boolean"]["output"]>
  /** creates a path divider, lesson, step, or resource. */
  createPathItem?: Maybe<PathItem>
  /** Create a platform subscription order */
  createPlatformSubscriptionOrder?: Maybe<Order>
  /** Create a registration order */
  createRegistrationOrder?: Maybe<Order>
  /** Potentially creates a new cohort within a Collection (if a cohort does not already exist for the for the provided resource_id). Optionally registers the user to that cohort. */
  createRegistrationUpsertGroup?: Maybe<Group>
  /** Creates a new resource */
  createResource?: Maybe<Resource>
  /** Create a school with a name and domain. Returns the school if successful. */
  createSchool?: Maybe<School>
  /** Create a school subscription order */
  createSchoolSubscriptionOrder?: Maybe<Order>
  /** Creates a store link for a school. */
  createStoreLink?: Maybe<StoreLink>
  /** Create a storefront */
  createStorefront?: Maybe<Storefront>
  /** Creates a new, unattached tag. */
  createTag?: Maybe<Tag>
  /** Creates a new tagAttachment. */
  createTagAttachment?: Maybe<TagAttachment>
  /** Creates a new tagAttachment. */
  createTagLink?: Maybe<TagLink>
  /** Creates a new translation */
  createTranslation?: Maybe<Translation>
  /** Creat a banner */
  deleteBanner?: Maybe<Scalars["Boolean"]["output"]>
  /** Archives a certificate */
  deleteCertificate?: Maybe<Scalars["Boolean"]["output"]>
  /** Permanently deletes a contextualFeaturePermission */
  deleteContextualFeaturePermission?: Maybe<Scalars["Boolean"]["output"]>
  deleteDiscussion?: Maybe<Scalars["Boolean"]["output"]>
  deleteDiscussionLike?: Maybe<Discussion>
  deleteDiscussionResponse?: Maybe<Scalars["Boolean"]["output"]>
  deleteDiscussionResponseLike?: Maybe<Scalars["Boolean"]["output"]>
  deleteDiscussionSubscription?: Maybe<Discussion>
  /** Deletes an invitation */
  deleteInvitation?: Maybe<Scalars["Boolean"]["output"]>
  /** Deletes a mentor group */
  deleteMentorGroup?: Maybe<Scalars["Boolean"]["output"]>
  /** Deletes a mentor group membership */
  deleteMentorGroupMembership?: Maybe<Scalars["Boolean"]["output"]>
  /** Deletes a message. */
  deleteMessage?: Maybe<Scalars["Boolean"]["output"]>
  /** deletes a path item */
  deletePathItem?: Maybe<PathItem>
  /** (Currently) permanently deletes a user's registration and all child registrations (in the case of Collections). */
  deleteRegistration?: Maybe<Scalars["Boolean"]["output"]>
  /** Deletes an existing tag. */
  deleteTag?: Maybe<Scalars["Boolean"]["output"]>
  /** Deletes an existing tagAttachment, permanently. */
  deleteTagAttachment?: Maybe<Scalars["Boolean"]["output"]>
  /** Deletes an existing tagAttachment, permanently. */
  deleteTagLink?: Maybe<Scalars["Boolean"]["output"]>
  /** Hard delete a WebhookEvent. */
  deleteWebhookEvent?: Maybe<Scalars["Boolean"]["output"]>
  executePathSyncPlan?: Maybe<Scalars["Boolean"]["output"]>
  /** Updates user with require first_name and last_name fields, plus creates school membership and fulfills any pending orders. */
  finalizeAccount?: Maybe<User>
  /** Finalizes an external account link for a user in a school, associating them with an external ID after they complete the account linking process. */
  finalizeExternalAccountLink?: Maybe<ExternalAccountLink>
  /** Get a token with a username/email and password. Returns an auth token if successful. */
  getToken?: Maybe<Token>
  /** Update the last time this user was active */
  heartbeat?: Maybe<Scalars["Boolean"]["output"]>
  /** Handles recording consent for a user-space membership, and creating that membership */
  joinSpace?: Maybe<Membership>
  /** Creates a new license for a resource */
  licenseResource?: Maybe<ResourceLicense>
  /** Pastes a path step or lesson from to the given path and location. */
  pastePathItem?: Maybe<PathItem>
  publishPath?: Maybe<Path>
  /** Allows for updating the Space's Pathwright authentication settings. */
  publishPathToCodex?: Maybe<CodexPathPublish>
  /** Rdeems a subscription for the user. */
  redeemSchoolGiftSubscription?: Maybe<SchoolSubscription>
  /** Issues a new token to the currrent user */
  refreshToken?: Maybe<Scalars["String"]["output"]>
  /** Register a push token for user. */
  registerPushToken?: Maybe<Scalars["Boolean"]["output"]>
  /** Revokes access for all members inactive for the the last x days */
  removeAccessForInactiveMembers?: Maybe<Scalars["Boolean"]["output"]>
  /** Remove a push token for user. */
  removePushToken?: Maybe<Scalars["Boolean"]["output"]>
  /** reorders two or more path items */
  reorderPathItems?: Maybe<Scalars["Boolean"]["output"]>
  /** Requests a password reset for a user (admin only) */
  requestPasswordReset?: Maybe<Scalars["Boolean"]["output"]>
  /** Resend an invitation email */
  resendInvitation?: Maybe<Invitation>
  resetCompletionFeedback?: Maybe<Completion>
  /** Resets a user's password based on a valid reset hash token (sent as part of a password reset email link) */
  resetPassword?: Maybe<Scalars["Boolean"]["output"]>
  /** Resets a previously completed or skipped path item */
  resetPathItem?: Maybe<PathItem>
  /** Resets a path item for a user */
  resetPathItemForUser?: Maybe<PathItem>
  saveCompletionFeedback?: Maybe<Completion>
  /** Schedules path item. */
  scheduleItem?: Maybe<PathItem>
  /** Sends email with gifted subscription details to gift recipient. */
  sendGiftSubscriptionEmail?: Maybe<Scalars["Boolean"]["output"]>
  /** Sets the member's access to the school */
  setMemberAccess?: Maybe<Membership>
  /** Archives/unarchives the user's membership to the school */
  setMemberArchived?: Maybe<Membership>
  /** Sets the member's role within the school */
  setMemberRole?: Maybe<Membership>
  /** Sign out the current user */
  signOut?: Maybe<Scalars["Boolean"]["output"]>
  /** Sign up with an email and password. Returns the user if successful. */
  signUp?: Maybe<Token>
  /** Skips a path item for a user */
  skipPathItemForUser?: Maybe<PathItem>
  /** Allows a user to start a request to authenticate to their account outside normal username/password authentication. */
  startAuthRequest?: Maybe<AuthRequest>
  /** Sends a password reset email for a user  */
  startPasswordRetrieval?: Maybe<Scalars["Boolean"]["output"]>
  syncPath?: Maybe<Path>
  /** Unlockss a path item with a password lock */
  unlockPathItem?: Maybe<PathItem>
  /** Update a banner */
  updateBanner?: Maybe<Banner>
  /** Update a banner's order */
  updateBannerOrder?: Maybe<Scalars["Boolean"]["output"]>
  /** Updates a certificate */
  updateCertificate?: Maybe<Certificate>
  /** Updates the certificate_json keys of all resources */
  updateCertificateVariables?: Maybe<CertificateVariables>
  /** Creates a new contextualFeaturePermission */
  updateContextualFeaturePermission?: Maybe<ContextualFeaturePermission>
  updateDiscussion?: Maybe<Discussion>
  updateDiscussionResponse?: Maybe<Response>
  /** Creates a new cohort for a resource */
  updateGroup?: Maybe<Group>
  updateGroupDiscussionEnabled?: Maybe<Group>
  updateGroupStatus?: Maybe<Group>
  /** Sets the member's profile data */
  updateMemberConsent?: Maybe<Membership>
  /** Updates an existing mentor group */
  updateMentorGroup?: Maybe<MentorGroup>
  /** Updates a mentor group membership */
  updateMentorGroupMembership?: Maybe<MentorGroupMembership>
  /** Updates a message. */
  updateMessage?: Maybe<Message>
  /** Update a user's notifciation digest interval. */
  updateNotificationDigestInterval?: Maybe<NotificationDigestInterval>
  /** Update a page. */
  updatePage?: Maybe<Page>
  /** Update page orders. */
  updatePageOrders?: Maybe<Scalars["Boolean"]["output"]>
  /** updates a path item */
  updatePathItem?: Maybe<PathItem>
  /** Allows for updating the Space's Pathwright authentication settings. */
  updatePathwrightAuth?: Maybe<Space>
  /** Updates the referral code for the current user */
  updateReferral?: Maybe<Referral>
  /** Creates a new resources */
  updateRegistration?: Maybe<Registration>
  /** Updates an existing resource */
  updateResource?: Maybe<Resource>
  /** Update fields on the school */
  updateSchool?: Maybe<School>
  /** Processes and updates the custom CSS for a school */
  updateSchoolCustomCSS?: Maybe<Theme>
  /** Create a school with a name and domain. Returns the school if successful. */
  updateSchoolProfile?: Maybe<SchoolProfile>
  /** Updates an existing tag. */
  updateTag?: Maybe<Tag>
  /** Updates a new tagAttachment. Currently only updates the position of the tagAttachment. */
  updateTagAttachment?: Maybe<TagAttachment>
  /** Updates an existing translation */
  updateTranslation?: Maybe<Translation>
  updateUserDiscussionHistory?: Maybe<Discussion>
}

export type MutationsAcceptCommunityGroupInvitationArgs = {
  token: Scalars["String"]["input"]
}

export type MutationsAddPeopleToArgs = {
  notification_message?: InputMaybe<Scalars["String"]["input"]>
  selection: Array<InputMaybe<AddPeopleSelection>>
  send_from_school?: InputMaybe<Scalars["Boolean"]["input"]>
  send_notification?: InputMaybe<Scalars["Boolean"]["input"]>
}

export type MutationsApiCompletionUpdateArgs = {
  action: ApiCompletionUpdateAction
  assign_points?: InputMaybe<Scalars["Float"]["input"]>
  cohort_id?: InputMaybe<Scalars["Int"]["input"]>
  step_source_id: Scalars["Int"]["input"]
  user_email?: InputMaybe<Scalars["String"]["input"]>
  user_id?: InputMaybe<Scalars["Int"]["input"]>
}

export type MutationsAssignArgs = {
  assign_to_user: Scalars["Int"]["input"]
  assignment_message?: InputMaybe<Scalars["String"]["input"]>
  offerings: Array<InputMaybe<Scalars["Int"]["input"]>>
  role: RegistrationRoleEnum
  send_from_school?: InputMaybe<Scalars["Boolean"]["input"]>
  send_notification?: InputMaybe<Scalars["Boolean"]["input"]>
}

export type MutationsBatchCreateMentorGroupMembershipArgs = {
  group: Scalars["Int"]["input"]
  role: MentorGroupMembershipRole
  user_ids?: InputMaybe<Array<Scalars["Int"]["input"]>>
}

export type MutationsCancelSchoolSubscriptionArgs = {
  cancel_immediately?: InputMaybe<Scalars["Boolean"]["input"]>
  for_user?: InputMaybe<Scalars["ID"]["input"]>
}

export type MutationsChangePasswordArgs = {
  current_password: Scalars["String"]["input"]
  password1: Scalars["String"]["input"]
  password2: Scalars["String"]["input"]
}

export type MutationsCompletePathItemArgs = {
  completion_data?: InputMaybe<Scalars["JSON"]["input"]>
  id: Scalars["Int"]["input"]
  path_id: Scalars["Int"]["input"]
  skip?: InputMaybe<Scalars["Boolean"]["input"]>
}

export type MutationsConfirmAuthRequestArgs = {
  key: Scalars["String"]["input"]
  uuid: Scalars["UUID"]["input"]
}

export type MutationsCopyResourceArgs = {
  create_offering?: InputMaybe<Scalars["Boolean"]["input"]>
  create_registration_with_role?: InputMaybe<RegistrationRoleEnum>
  id: Scalars["Int"]["input"]
  to_school?: InputMaybe<Scalars["Int"]["input"]>
}

export type MutationsCreateBannerArgs = {
  background_image?: InputMaybe<Scalars["String"]["input"]>
  background_image_raw?: InputMaybe<Scalars["String"]["input"]>
  background_overlay?: InputMaybe<Scalars["String"]["input"]>
  background_type?: InputMaybe<Scalars["Int"]["input"]>
  button_func?: InputMaybe<Scalars["String"]["input"]>
  button_text?: InputMaybe<Scalars["String"]["input"]>
  button_url?: InputMaybe<Scalars["String"]["input"]>
  font_color?: InputMaybe<Scalars["String"]["input"]>
  heading?: InputMaybe<Scalars["String"]["input"]>
  hide_for_products?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>
  hide_for_subscription_plans?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]["input"]>>
  >
  is_visible?: InputMaybe<Scalars["Boolean"]["input"]>
  show_for_products?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>
  show_for_subscription_plans?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]["input"]>>
  >
  subhead?: InputMaybe<Scalars["String"]["input"]>
}

export type MutationsCreateCertificateArgs = {
  is_active?: InputMaybe<Scalars["Boolean"]["input"]>
  name?: InputMaybe<Scalars["String"]["input"]>
  template: Scalars["String"]["input"]
  text_objects?: InputMaybe<Array<InputMaybe<TextObject>>>
}

export type MutationsCreateContextualFeaturePermissionArgs = {
  context?: InputMaybe<FeatureContextInput>
  feature_action: FeatureActionType
  feature_key: FeatureKeyType
  permission_in_context: FeaturePermissionType
}

export type MutationsCreateCurriculumLicenseOrderArgs = {
  coupon_code?: InputMaybe<Scalars["String"]["input"]>
  license_offering_id: Scalars["Int"]["input"]
  licensee_school_id: Scalars["Int"]["input"]
  licensor_school_id: Scalars["Int"]["input"]
  name?: InputMaybe<Scalars["String"]["input"]>
  role?: InputMaybe<RegistrationRoleEnum>
  seat_count?: InputMaybe<Scalars["Int"]["input"]>
}

export type MutationsCreateCurriculumSubscriptionOrderArgs = {
  curriculum_plan_id: Scalars["Int"]["input"]
  licensee_id: Scalars["Int"]["input"]
  plan_visibility_code?: InputMaybe<Scalars["String"]["input"]>
}

export type MutationsCreateDiscussionArgs = {
  context: DiscussionContextInput
  initialResponse?: InputMaybe<DiscussionResponseInput>
  notify?: InputMaybe<Scalars["Boolean"]["input"]>
  post: DiscussionPostInput
}

export type MutationsCreateDiscussionLikeArgs = {
  context: DiscussionContextInput
  discussion_id: Scalars["Int"]["input"]
}

export type MutationsCreateDiscussionResponseArgs = {
  context: DiscussionContextInput
  response: DiscussionResponseInput
}

export type MutationsCreateDiscussionResponseLikeArgs = {
  context: DiscussionContextInput
  discussion_id: Scalars["Int"]["input"]
  response_id: Scalars["Int"]["input"]
}

export type MutationsCreateDiscussionSubscriptionArgs = {
  context: DiscussionContextInput
  discussion_id: Scalars["Int"]["input"]
}

export type MutationsCreateExternalAccountLinkArgs = {
  external_id: Scalars["String"]["input"]
  link_type?: InputMaybe<ExternalAccountType>
  linked_oidc_idp_id?: InputMaybe<Scalars["Int"]["input"]>
  linked_saml2_idp_id?: InputMaybe<Scalars["Int"]["input"]>
  user_id: Scalars["Int"]["input"]
}

export type MutationsCreateGiftSubscriptionOrderArgs = {
  gift_message?: InputMaybe<Scalars["String"]["input"]>
  num_cycles: Scalars["Int"]["input"]
  plan_id: Scalars["Int"]["input"]
  plan_visibility_code?: InputMaybe<Scalars["String"]["input"]>
  recipient_first_name: Scalars["String"]["input"]
  recipient_last_name: Scalars["String"]["input"]
}

export type MutationsCreateGroupArgs = {
  base_resource_id: Scalars["Int"]["input"]
  name: Scalars["String"]["input"]
  parent_offering_id?: InputMaybe<Scalars["Int"]["input"]>
  role?: InputMaybe<RegistrationRoleEnum>
  visibility: Scalars["Int"]["input"]
}

export type MutationsCreateGroupLicenseOrderArgs = {
  coupon_code?: InputMaybe<Scalars["String"]["input"]>
  license_offering_id: Scalars["Int"]["input"]
  name: Scalars["String"]["input"]
  role?: InputMaybe<RegistrationRoleEnum>
  seat_count?: InputMaybe<Scalars["Int"]["input"]>
}

export type MutationsCreateGroupSubscriptionOrderArgs = {
  group_name: Scalars["String"]["input"]
  price_id: Scalars["Int"]["input"]
  quantity: Scalars["Int"]["input"]
  redirect_to?: InputMaybe<Scalars["String"]["input"]>
}

export type MutationsCreateInvitationsArgs = {
  context?: InputMaybe<InvitationContext>
  invitation_message?: InputMaybe<Scalars["String"]["input"]>
  invitation_subject?: InputMaybe<Scalars["String"]["input"]>
  invite_emails: Array<InputMaybe<Scalars["String"]["input"]>>
  invite_to_cohort_role?: InputMaybe<RegistrationRoleEnum>
  invite_to_community_group_role?: InputMaybe<MentorGroupMembershipRole>
  invite_to_school_role?: InputMaybe<MembershipRole>
  send_from_school?: InputMaybe<Scalars["Boolean"]["input"]>
  type?: InputMaybe<InvitationType>
}

export type MutationsCreateLifetimeSchoolSubscriptionOrderArgs = {
  coupon_code?: InputMaybe<Scalars["String"]["input"]>
  plan_id: Scalars["Int"]["input"]
  plan_visibility_code?: InputMaybe<Scalars["String"]["input"]>
}

export type MutationsCreateMembershipForExistingUserArgs = {
  role?: InputMaybe<MembershipRole>
  user_email: Scalars["String"]["input"]
}

export type MutationsCreateMentorGroupArgs = {
  name: Scalars["String"]["input"]
  parent?: InputMaybe<Scalars["Int"]["input"]>
  type?: InputMaybe<MentorGroupType>
}

export type MutationsCreateMentorGroupMembershipArgs = {
  group: Scalars["Int"]["input"]
  role: MentorGroupMembershipRole
  user: Scalars["Int"]["input"]
}

export type MutationsCreateMessageArgs = {
  message: MessageInput
  message_context: MessageContext
}

export type MutationsCreateOrderArgs = {
  curriculum_license?: InputMaybe<CurriculumLicenseOrderData>
  curriculum_subscription?: InputMaybe<CurriculumSubscriptionOrderData>
  gift_subscription?: InputMaybe<GiftSubscriptionOrderData>
  group_license?: InputMaybe<GroupLicenseOrderData>
  group_subscription?: InputMaybe<GroupSubscriptionOrderData>
  lifetime_school_subscription?: InputMaybe<LifetimeSchoolSubscriptionOrderData>
  platform_subscription?: InputMaybe<PlatformSubscriptionOrderData>
  registration?: InputMaybe<RegistrationOrderData>
  school_subscription?: InputMaybe<SchoolSubscriptionOrderData>
}

export type MutationsCreatePageArgs = {
  mode?: InputMaybe<Scalars["String"]["input"]>
  name?: InputMaybe<Scalars["String"]["input"]>
  page_type?: InputMaybe<Scalars["Int"]["input"]>
  show_in_nav?: InputMaybe<Scalars["Boolean"]["input"]>
  title?: InputMaybe<Scalars["String"]["input"]>
}

export type MutationsCreatePasswordArgs = {
  password1: Scalars["String"]["input"]
  password2: Scalars["String"]["input"]
}

export type MutationsCreatePathItemArgs = {
  assignment_type?: InputMaybe<PathItemAssignmentType>
  content_resource?: InputMaybe<Scalars["Int"]["input"]>
  content_type?: InputMaybe<Scalars["String"]["input"]>
  description?: InputMaybe<Scalars["String"]["input"]>
  name: Scalars["String"]["input"]
  order: Scalars["Int"]["input"]
  parent?: InputMaybe<Scalars["Int"]["input"]>
  path_id: Scalars["Int"]["input"]
  type: PathItemType
}

export type MutationsCreatePlatformSubscriptionOrderArgs = {
  billing_plan_id: Scalars["Int"]["input"]
  interval: Scalars["Int"]["input"]
  school_id?: InputMaybe<Scalars["Int"]["input"]>
}

export type MutationsCreateRegistrationOrderArgs = {
  coupon_code?: InputMaybe<Scalars["String"]["input"]>
  invitation_code?: InputMaybe<Scalars["String"]["input"]>
  offering_id: Scalars["Int"]["input"]
  use_group_subscription_id?: InputMaybe<Scalars["Int"]["input"]>
  use_subscription_plan_id?: InputMaybe<Scalars["Int"]["input"]>
}

export type MutationsCreateRegistrationUpsertGroupArgs = {
  create_registration?: InputMaybe<Scalars["Boolean"]["input"]>
  parent_offering_id: Scalars["Int"]["input"]
  resource_id: Scalars["Int"]["input"]
  step_source_id: Scalars["Int"]["input"]
}

export type MutationsCreateResourceArgs = {
  cover_image: Scalars["String"]["input"]
  group_name?: InputMaybe<Scalars["String"]["input"]>
  group_role?: InputMaybe<RegistrationRoleEnum>
  name: Scalars["String"]["input"]
  resource_type?: InputMaybe<ResourceType>
}

export type MutationsCreateSchoolArgs = {
  copy?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
  licensor_id?: InputMaybe<Scalars["Int"]["input"]>
  name: Scalars["String"]["input"]
  subdomain: Scalars["String"]["input"]
  utm_campaign?: InputMaybe<Scalars["String"]["input"]>
  utm_medium?: InputMaybe<Scalars["String"]["input"]>
  utm_source?: InputMaybe<Scalars["String"]["input"]>
}

export type MutationsCreateSchoolSubscriptionOrderArgs = {
  coupon_code?: InputMaybe<Scalars["String"]["input"]>
  plan_id: Scalars["Int"]["input"]
  plan_visibility_code?: InputMaybe<Scalars["String"]["input"]>
  redirect_to?: InputMaybe<Scalars["String"]["input"]>
  start_free_trial?: InputMaybe<Scalars["Boolean"]["input"]>
}

export type MutationsCreateStoreLinkArgs = {
  copy?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
  school_id: Scalars["Int"]["input"]
}

export type MutationsCreateStorefrontArgs = {
  autoAddPublicResources?: InputMaybe<Scalars["Boolean"]["input"]>
}

export type MutationsCreateTagArgs = {
  tag: TagCreateInput
}

export type MutationsCreateTagAttachmentArgs = {
  context: TagAttachmentContextInput
  position?: InputMaybe<Scalars["String"]["input"]>
  tag_id: Scalars["Int"]["input"]
}

export type MutationsCreateTagLinkArgs = {
  context: TagLinkContextInput
  tag_id: Scalars["Int"]["input"]
}

export type MutationsCreateTranslationArgs = {
  translation: Scalars["JSON"]["input"]
}

export type MutationsDeleteBannerArgs = {
  id: Scalars["Int"]["input"]
}

export type MutationsDeleteCertificateArgs = {
  id: Scalars["Int"]["input"]
}

export type MutationsDeleteContextualFeaturePermissionArgs = {
  context?: InputMaybe<FeatureContextInput>
  feature_action: FeatureActionType
  feature_key: FeatureKeyType
}

export type MutationsDeleteDiscussionArgs = {
  context: DiscussionContextInput
  id: Scalars["Int"]["input"]
}

export type MutationsDeleteDiscussionLikeArgs = {
  context: DiscussionContextInput
  discussion_id: Scalars["Int"]["input"]
}

export type MutationsDeleteDiscussionResponseArgs = {
  context: DiscussionContextInput
  discussion_id: Scalars["Int"]["input"]
  response_id: Scalars["Int"]["input"]
}

export type MutationsDeleteDiscussionResponseLikeArgs = {
  context: DiscussionContextInput
  discussion_id: Scalars["Int"]["input"]
  response_id: Scalars["Int"]["input"]
}

export type MutationsDeleteDiscussionSubscriptionArgs = {
  context: DiscussionContextInput
  discussion_id: Scalars["Int"]["input"]
}

export type MutationsDeleteInvitationArgs = {
  id: Scalars["Int"]["input"]
}

export type MutationsDeleteMentorGroupArgs = {
  id: Scalars["Int"]["input"]
}

export type MutationsDeleteMentorGroupMembershipArgs = {
  group: Scalars["Int"]["input"]
  id: Scalars["Int"]["input"]
}

export type MutationsDeleteMessageArgs = {
  id: Scalars["Int"]["input"]
  message_context: MessageContext
}

export type MutationsDeletePathItemArgs = {
  id: Scalars["Int"]["input"]
  path_id: Scalars["Int"]["input"]
}

export type MutationsDeleteRegistrationArgs = {
  group_id: Scalars["Int"]["input"]
  registration_id: Scalars["Int"]["input"]
}

export type MutationsDeleteTagArgs = {
  tag: TagDeleteInput
}

export type MutationsDeleteTagAttachmentArgs = {
  context: TagAttachmentContextInput
  tag_id: Scalars["Int"]["input"]
}

export type MutationsDeleteTagLinkArgs = {
  context: TagLinkContextInput
  tag_id: Scalars["Int"]["input"]
}

export type MutationsDeleteWebhookEventArgs = {
  uuid: Scalars["UUID"]["input"]
}

export type MutationsExecutePathSyncPlanArgs = {
  sync_plan?: InputMaybe<PathSyncPlanInput>
}

export type MutationsFinalizeAccountArgs = {
  avatar?: InputMaybe<Scalars["String"]["input"]>
  consent?: InputMaybe<Scalars["Boolean"]["input"]>
  first_name: Scalars["String"]["input"]
  last_name: Scalars["String"]["input"]
  password1?: InputMaybe<Scalars["String"]["input"]>
  password2?: InputMaybe<Scalars["String"]["input"]>
}

export type MutationsFinalizeExternalAccountLinkArgs = {
  token: Scalars["String"]["input"]
}

export type MutationsGetTokenArgs = {
  invite_token?: InputMaybe<Scalars["String"]["input"]>
  password: Scalars["String"]["input"]
  username: Scalars["String"]["input"]
}

export type MutationsJoinSpaceArgs = {
  consent: Scalars["Boolean"]["input"]
  invite_token?: InputMaybe<Scalars["String"]["input"]>
}

export type MutationsLicenseResourceArgs = {
  coupon_code?: InputMaybe<Scalars["String"]["input"]>
  license_offering_id: Scalars["Int"]["input"]
  licensee_school_id?: InputMaybe<Scalars["Int"]["input"]>
  name?: InputMaybe<Scalars["String"]["input"]>
  payment_token?: InputMaybe<Scalars["String"]["input"]>
  resource_id: Scalars["Int"]["input"]
  role?: InputMaybe<LicenseRoleEnum>
  seat_count?: InputMaybe<Scalars["Int"]["input"]>
  use_group_subscription_id?: InputMaybe<Scalars["Int"]["input"]>
}

export type MutationsPastePathItemArgs = {
  item_id: Scalars["Int"]["input"]
  order: Scalars["Int"]["input"]
  parent_id?: InputMaybe<Scalars["Int"]["input"]>
  path_id: Scalars["Int"]["input"]
}

export type MutationsPublishPathArgs = {
  id: Scalars["Int"]["input"]
}

export type MutationsPublishPathToCodexArgs = {
  override_id?: InputMaybe<Scalars["String"]["input"]>
  path_id: Scalars["Int"]["input"]
  suffix?: InputMaybe<Scalars["String"]["input"]>
  upsert?: InputMaybe<Scalars["Boolean"]["input"]>
}

export type MutationsRedeemSchoolGiftSubscriptionArgs = {
  code?: InputMaybe<Scalars["String"]["input"]>
  school_subscription_plan_id: Scalars["ID"]["input"]
}

export type MutationsRefreshTokenArgs = {
  school_id?: InputMaybe<Scalars["ID"]["input"]>
}

export type MutationsRegisterPushTokenArgs = {
  token: Scalars["String"]["input"]
}

export type MutationsRemoveAccessForInactiveMembersArgs = {
  daysInactive?: InputMaybe<Scalars["Int"]["input"]>
}

export type MutationsRemovePushTokenArgs = {
  token: Scalars["String"]["input"]
}

export type MutationsReorderPathItemsArgs = {
  items: Array<InputMaybe<PathItemOrder>>
  path_id: Scalars["Int"]["input"]
}

export type MutationsRequestPasswordResetArgs = {
  id: Scalars["Int"]["input"]
}

export type MutationsResendInvitationArgs = {
  id: Scalars["Int"]["input"]
}

export type MutationsResetCompletionFeedbackArgs = {
  id: Scalars["Int"]["input"]
  path_id: Scalars["Int"]["input"]
}

export type MutationsResetPasswordArgs = {
  password1: Scalars["String"]["input"]
  password2: Scalars["String"]["input"]
  reset_hash: Scalars["String"]["input"]
}

export type MutationsResetPathItemArgs = {
  id: Scalars["Int"]["input"]
  path_id: Scalars["Int"]["input"]
}

export type MutationsResetPathItemForUserArgs = {
  id: Scalars["Int"]["input"]
  path_id: Scalars["Int"]["input"]
}

export type MutationsSaveCompletionFeedbackArgs = {
  id: Scalars["Int"]["input"]
  notify?: InputMaybe<Scalars["Boolean"]["input"]>
  path_id: Scalars["Int"]["input"]
  userpoints_earned?: InputMaybe<Scalars["Int"]["input"]>
  userpoints_explanation?: InputMaybe<Scalars["String"]["input"]>
}

export type MutationsScheduleItemArgs = {
  due?: InputMaybe<Scalars["DateTime"]["input"]>
  passive_due?: InputMaybe<Scalars["Boolean"]["input"]>
  passive_starts?: InputMaybe<Scalars["Boolean"]["input"]>
  schedule_context: ScheduleItemContext
  starts?: InputMaybe<Scalars["DateTime"]["input"]>
}

export type MutationsSendGiftSubscriptionEmailArgs = {
  gift_id: Scalars["Int"]["input"]
  recipient_email: Scalars["String"]["input"]
}

export type MutationsSetMemberAccessArgs = {
  has_access: Scalars["Boolean"]["input"]
  id: Scalars["Int"]["input"]
}

export type MutationsSetMemberArchivedArgs = {
  id: Scalars["Int"]["input"]
  is_archived: Scalars["Boolean"]["input"]
}

export type MutationsSetMemberRoleArgs = {
  id: Scalars["Int"]["input"]
  role: MembershipRole
}

export type MutationsSignUpArgs = {
  consent?: InputMaybe<Scalars["Boolean"]["input"]>
  create_membership?: InputMaybe<Scalars["Boolean"]["input"]>
  email: Scalars["String"]["input"]
  first_name: Scalars["String"]["input"]
  invite_token?: InputMaybe<Scalars["String"]["input"]>
  last_name: Scalars["String"]["input"]
  password?: InputMaybe<Scalars["String"]["input"]>
}

export type MutationsSkipPathItemForUserArgs = {
  id: Scalars["Int"]["input"]
  path_id: Scalars["Int"]["input"]
}

export type MutationsStartAuthRequestArgs = {
  email?: InputMaybe<Scalars["EmailAddress"]["input"]>
  method: AuthRequestMethod
  next_url?: InputMaybe<Scalars["String"]["input"]>
  username?: InputMaybe<Scalars["String"]["input"]>
}

export type MutationsStartPasswordRetrievalArgs = {
  email: Scalars["String"]["input"]
  next_url?: InputMaybe<Scalars["String"]["input"]>
}

export type MutationsSyncPathArgs = {
  id: Scalars["Int"]["input"]
}

export type MutationsUnlockPathItemArgs = {
  id: Scalars["Int"]["input"]
  path_id: Scalars["Int"]["input"]
  step_lock_password: Scalars["String"]["input"]
}

export type MutationsUpdateBannerArgs = {
  background_image?: InputMaybe<Scalars["String"]["input"]>
  background_image_raw?: InputMaybe<Scalars["String"]["input"]>
  background_overlay?: InputMaybe<Scalars["String"]["input"]>
  background_type?: InputMaybe<Scalars["Int"]["input"]>
  button_func?: InputMaybe<Scalars["String"]["input"]>
  button_text?: InputMaybe<Scalars["String"]["input"]>
  button_url?: InputMaybe<Scalars["String"]["input"]>
  font_color?: InputMaybe<Scalars["String"]["input"]>
  heading?: InputMaybe<Scalars["String"]["input"]>
  hide_for_products?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>
  hide_for_subscription_plans?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]["input"]>>
  >
  id: Scalars["Int"]["input"]
  is_visible?: InputMaybe<Scalars["Boolean"]["input"]>
  show_for_products?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>
  show_for_subscription_plans?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]["input"]>>
  >
  subhead?: InputMaybe<Scalars["String"]["input"]>
}

export type MutationsUpdateBannerOrderArgs = {
  banner_orders: Array<InputMaybe<ListOrderInput>>
}

export type MutationsUpdateCertificateArgs = {
  id: Scalars["Int"]["input"]
  name?: InputMaybe<Scalars["String"]["input"]>
  template?: InputMaybe<Scalars["String"]["input"]>
  text_objects?: InputMaybe<Array<InputMaybe<TextObject>>>
}

export type MutationsUpdateCertificateVariablesArgs = {
  context?: InputMaybe<CertificateVariablesContextInput>
  merge: Array<InputMaybe<CertificateVariablesMergeInput>>
}

export type MutationsUpdateContextualFeaturePermissionArgs = {
  context?: InputMaybe<FeatureContextInput>
  feature_action: FeatureActionType
  feature_key: FeatureKeyType
  permission_in_context: FeaturePermissionType
}

export type MutationsUpdateDiscussionArgs = {
  context: DiscussionContextInput
  id: Scalars["Int"]["input"]
  post: DiscussionPostInput
}

export type MutationsUpdateDiscussionResponseArgs = {
  context: DiscussionContextInput
  response: DiscussionResponseInput
  response_id: Scalars["Int"]["input"]
}

export type MutationsUpdateGroupArgs = {
  discussion_enabled?: InputMaybe<Scalars["Boolean"]["input"]>
  group_id: Scalars["Int"]["input"]
  name?: InputMaybe<Scalars["String"]["input"]>
  resource_id?: InputMaybe<Scalars["Int"]["input"]>
  visibility?: InputMaybe<GroupVisibility>
}

export type MutationsUpdateGroupDiscussionEnabledArgs = {
  discussion_enabled?: InputMaybe<Scalars["Boolean"]["input"]>
  id: Scalars["ID"]["input"]
}

export type MutationsUpdateGroupStatusArgs = {
  id: Scalars["ID"]["input"]
  status?: InputMaybe<GroupStatus>
}

export type MutationsUpdateMemberConsentArgs = {
  consents?: InputMaybe<Scalars["Boolean"]["input"]>
}

export type MutationsUpdateMentorGroupArgs = {
  description?: InputMaybe<Scalars["String"]["input"]>
  id: Scalars["Int"]["input"]
  name?: InputMaybe<Scalars["String"]["input"]>
  parent?: InputMaybe<Scalars["Int"]["input"]>
  type?: InputMaybe<MentorGroupType>
}

export type MutationsUpdateMentorGroupMembershipArgs = {
  can_review?: InputMaybe<Scalars["Boolean"]["input"]>
  group: Scalars["Int"]["input"]
  has_access?: InputMaybe<Scalars["Boolean"]["input"]>
  id: Scalars["Int"]["input"]
  role?: InputMaybe<MentorGroupMembershipRole>
  send_mentor_digest?: InputMaybe<Scalars["Boolean"]["input"]>
}

export type MutationsUpdateMessageArgs = {
  id: Scalars["Int"]["input"]
  message: MessageInput
  message_context: MessageContext
}

export type MutationsUpdateNotificationDigestIntervalArgs = {
  interval?: InputMaybe<NotificationDigestInterval>
}

export type MutationsUpdatePageArgs = {
  content_type?: InputMaybe<Scalars["Int"]["input"]>
  description?: InputMaybe<Scalars["String"]["input"]>
  external_url?: InputMaybe<Scalars["String"]["input"]>
  id?: InputMaybe<Scalars["Int"]["input"]>
  iframe_url?: InputMaybe<Scalars["String"]["input"]>
  is_live?: InputMaybe<Scalars["Boolean"]["input"]>
  mode?: InputMaybe<Scalars["String"]["input"]>
  name?: InputMaybe<Scalars["String"]["input"]>
  order?: InputMaybe<Scalars["Int"]["input"]>
  page_type?: InputMaybe<Scalars["Int"]["input"]>
  scripts?: InputMaybe<Scalars["String"]["input"]>
  show_in_nav?: InputMaybe<Scalars["Boolean"]["input"]>
  slug?: InputMaybe<Scalars["String"]["input"]>
  template_content?: InputMaybe<Scalars["String"]["input"]>
  title?: InputMaybe<Scalars["String"]["input"]>
}

export type MutationsUpdatePageOrdersArgs = {
  page_orders?: InputMaybe<Array<InputMaybe<ListOrderInput>>>
}

export type MutationsUpdatePathItemArgs = {
  allow_resets?: InputMaybe<Scalars["Int"]["input"]>
  assignment_type?: InputMaybe<PathItemAssignmentType>
  completion_comment_required_status?: InputMaybe<Scalars["String"]["input"]>
  description?: InputMaybe<Scalars["String"]["input"]>
  grading_type?: InputMaybe<Scalars["String"]["input"]>
  is_previewable?: InputMaybe<Scalars["Boolean"]["input"]>
  is_required?: InputMaybe<Scalars["Boolean"]["input"]>
  lock_password?: InputMaybe<Scalars["String"]["input"]>
  minimum_passing_score?: InputMaybe<Scalars["Int"]["input"]>
  name?: InputMaybe<Scalars["String"]["input"]>
  order: Scalars["Int"]["input"]
  path_id: Scalars["Int"]["input"]
  points?: InputMaybe<Scalars["Int"]["input"]>
  requires_api_completion?: InputMaybe<Scalars["Boolean"]["input"]>
  show_grading_feedback?: InputMaybe<Scalars["Int"]["input"]>
  source_id: Scalars["Int"]["input"]
  time_to_complete?: InputMaybe<Scalars["Int"]["input"]>
  type: PathItemType
}

export type MutationsUpdatePathwrightAuthArgs = {
  allow_public_signup: Scalars["Boolean"]["input"]
  always_prompt_for_password: Scalars["Boolean"]["input"]
  pathwright_login_enabled: Scalars["Boolean"]["input"]
}

export type MutationsUpdateReferralArgs = {
  referral: Scalars["String"]["input"]
}

export type MutationsUpdateRegistrationArgs = {
  completion_state?: InputMaybe<CompletionStateFilter>
  expiration_dtime?: InputMaybe<Scalars["DateTime"]["input"]>
  id: Scalars["Int"]["input"]
  role?: InputMaybe<RegistrationRoleEnum>
  send_discussion_notifications?: InputMaybe<Scalars["Boolean"]["input"]>
  send_due_notifications?: InputMaybe<Scalars["Boolean"]["input"]>
}

export type MutationsUpdateResourceArgs = {
  authors?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>
  categories?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>
  certificate_generator_class?: InputMaybe<Scalars["String"]["input"]>
  certificate_json?: InputMaybe<Scalars["JSON"]["input"]>
  cover_image?: InputMaybe<Scalars["String"]["input"]>
  description?: InputMaybe<Scalars["String"]["input"]>
  discussion_enabled?: InputMaybe<Scalars["Boolean"]["input"]>
  id: Scalars["Int"]["input"]
  name: Scalars["String"]["input"]
  resource_type: ResourceType
  short_description?: InputMaybe<Scalars["String"]["input"]>
  trailer_embed_code?: InputMaybe<Scalars["String"]["input"]>
  visibility_level_override?: InputMaybe<Scalars["Int"]["input"]>
}

export type MutationsUpdateSchoolArgs = {
  analytics_account_id?: InputMaybe<Scalars["String"]["input"]>
  consent_description?: InputMaybe<Scalars["String"]["input"]>
  grant_access_upon_authentication?: InputMaybe<Scalars["Boolean"]["input"]>
  has_certificate?: InputMaybe<Scalars["Boolean"]["input"]>
  head_markup?: InputMaybe<Scalars["String"]["input"]>
  image?: InputMaybe<Scalars["String"]["input"]>
  intercom_access_token?: InputMaybe<Scalars["String"]["input"]>
  intercom_app_id?: InputMaybe<Scalars["String"]["input"]>
  intercom_secret_key?: InputMaybe<Scalars["String"]["input"]>
  logo_image?: InputMaybe<Scalars["String"]["input"]>
  name?: InputMaybe<Scalars["String"]["input"]>
  pathwright_login_enabled?: InputMaybe<Scalars["Boolean"]["input"]>
  script_markup?: InputMaybe<Scalars["String"]["input"]>
  short_description?: InputMaybe<Scalars["String"]["input"]>
  stripe_access_token?: InputMaybe<Scalars["String"]["input"]>
  subdomain?: InputMaybe<Scalars["String"]["input"]>
  support_email?: InputMaybe<Scalars["String"]["input"]>
  support_phone?: InputMaybe<Scalars["String"]["input"]>
  support_policy?: InputMaybe<Scalars["String"]["input"]>
}

export type MutationsUpdateSchoolCustomCssArgs = {
  custom_css: Scalars["String"]["input"]
  id: Scalars["Int"]["input"]
  name: Scalars["String"]["input"]
}

export type MutationsUpdateSchoolProfileArgs = {
  coach_or_tutor?: InputMaybe<Scalars["Boolean"]["input"]>
  id: Scalars["Int"]["input"]
  other?: InputMaybe<Scalars["String"]["input"]>
  plan_to_sell?: InputMaybe<Scalars["Boolean"]["input"]>
  publish_multiple_courses?: InputMaybe<Scalars["Boolean"]["input"]>
  publish_one_course?: InputMaybe<Scalars["Boolean"]["input"]>
  school_type?: InputMaybe<SchoolType>
  teach_formal_classes?: InputMaybe<Scalars["Boolean"]["input"]>
  train_team_customers?: InputMaybe<Scalars["Boolean"]["input"]>
}

export type MutationsUpdateTagArgs = {
  tag: TagUpdateInput
}

export type MutationsUpdateTagAttachmentArgs = {
  context: TagAttachmentContextInput
  position?: InputMaybe<Scalars["String"]["input"]>
  tag_id: Scalars["Int"]["input"]
}

export type MutationsUpdateTranslationArgs = {
  id: Scalars["Int"]["input"]
  translation: Scalars["JSON"]["input"]
}

export type MutationsUpdateUserDiscussionHistoryArgs = {
  context: DiscussionContextInput
  discussion_id: Scalars["Int"]["input"]
}

export enum NodeColorMode {
  Dark = "dark",
  Light = "light"
}

export enum NodeColorTone {
  Muted = "muted",
  Vibrant = "vibrant"
}

export type NodeImage = {
  __typename?: "NodeImage"
  color?: Maybe<Scalars["String"]["output"]>
  height?: Maybe<Scalars["Int"]["output"]>
  raw_src?: Maybe<Scalars["String"]["output"]>
  src?: Maybe<Scalars["String"]["output"]>
  width?: Maybe<Scalars["Int"]["output"]>
}

export type NodeImageColorArgs = {
  alpah?: InputMaybe<Scalars["Int"]["input"]>
  mode?: InputMaybe<NodeColorMode>
  tone?: InputMaybe<NodeColorTone>
}

export enum NodeImageFit {
  Clamp = "clamp",
  Clip = "clip",
  Crop = "crop",
  Facearea = "facearea",
  Fill = "fill"
}

export enum NodeImageFormat {
  Blurhash = "blurhash",
  Default = "default",
  Gif = "gif",
  Jpg = "jpg",
  Png = "png",
  Webp = "webp"
}

export enum NodeImageMask {
  BannerCover = "banner_cover"
}

export enum NodeImageSize {
  Lg = "lg",
  Md = "md",
  Sm = "sm",
  Xl = "xl"
}

export type NodeMeta = {
  __typename?: "NodeMeta"
  _search?: Maybe<Scalars["String"]["output"]>
  description?: Maybe<Scalars["String"]["output"]>
  icon?: Maybe<Scalars["String"]["output"]>
  id: Scalars["String"]["output"]
  image?: Maybe<NodeImage>
  key: Scalars["String"]["output"]
  keywords?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>
  subtitle?: Maybe<Scalars["String"]["output"]>
  title?: Maybe<Scalars["String"]["output"]>
  type: Scalars["String"]["output"]
  url?: Maybe<Scalars["String"]["output"]>
}

export type NodeMetaImageArgs = {
  ar?: InputMaybe<Scalars["String"]["input"]>
  blur?: InputMaybe<Scalars["Int"]["input"]>
  brightness?: InputMaybe<Scalars["Int"]["input"]>
  fit?: InputMaybe<NodeImageFit>
  format?: InputMaybe<NodeImageFormat>
  height?: InputMaybe<Scalars["Int"]["input"]>
  mask?: InputMaybe<NodeImageMask>
  size?: InputMaybe<NodeImageSize>
  vibrance?: InputMaybe<Scalars["Int"]["input"]>
  width?: InputMaybe<Scalars["Int"]["input"]>
}

export type NodeTheme = {
  __typename?: "NodeTheme"
  background?: Maybe<NodeThemeBackground>
  color: Scalars["String"]["output"]
  color_palette?: Maybe<NodeThemeColorPalette>
  fonts?: Maybe<NodeThemeFonts>
}

export type NodeThemeBackground = {
  __typename?: "NodeThemeBackground"
  color?: Maybe<Scalars["String"]["output"]>
  contrast_color?: Maybe<Scalars["String"]["output"]>
  image?: Maybe<NodeImage>
  luminence?: Maybe<Scalars["Float"]["output"]>
}

export type NodeThemeBackgroundImageArgs = {
  ar?: InputMaybe<Scalars["String"]["input"]>
  blur?: InputMaybe<Scalars["Int"]["input"]>
  brightness?: InputMaybe<Scalars["Int"]["input"]>
  fit?: InputMaybe<NodeImageFit>
  format?: InputMaybe<NodeImageFormat>
  height?: InputMaybe<Scalars["Int"]["input"]>
  mask?: InputMaybe<NodeImageMask>
  size?: InputMaybe<NodeImageSize>
  vibrance?: InputMaybe<Scalars["Int"]["input"]>
  width?: InputMaybe<Scalars["Int"]["input"]>
}

export type NodeThemeColorPalette = {
  __typename?: "NodeThemeColorPalette"
  _50?: Maybe<Scalars["String"]["output"]>
  _100?: Maybe<Scalars["String"]["output"]>
  _200?: Maybe<Scalars["String"]["output"]>
  _300?: Maybe<Scalars["String"]["output"]>
  _400?: Maybe<Scalars["String"]["output"]>
  _500?: Maybe<Scalars["String"]["output"]>
  _600?: Maybe<Scalars["String"]["output"]>
  _700?: Maybe<Scalars["String"]["output"]>
  _800?: Maybe<Scalars["String"]["output"]>
  _900?: Maybe<Scalars["String"]["output"]>
}

export type NodeThemeFonts = {
  __typename?: "NodeThemeFonts"
  body?: Maybe<Scalars["String"]["output"]>
  subtitle?: Maybe<Scalars["String"]["output"]>
  title?: Maybe<Scalars["String"]["output"]>
}

/** Pathwright note */
export type Note = {
  __typename?: "Note"
  body?: Maybe<Scalars["String"]["output"]>
  cohort_id?: Maybe<Scalars["Int"]["output"]>
  context_id?: Maybe<Scalars["Int"]["output"]>
  created_time?: Maybe<Scalars["DateTime"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  modified_time?: Maybe<Scalars["DateTime"]["output"]>
  resource_id?: Maybe<Scalars["Int"]["output"]>
  step_id?: Maybe<Scalars["Int"]["output"]>
  title?: Maybe<Scalars["String"]["output"]>
  user?: Maybe<User>
  visibility?: Maybe<NoteVisibility>
}

/** A connection to a list of items. */
export type NoteConnection = {
  __typename?: "NoteConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<NoteEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total?: Maybe<Scalars["Int"]["output"]>
}

/** An edge in a connection. */
export type NoteEdge = {
  __typename?: "NoteEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<Note>
}

export enum NoteVisibility {
  Author = "author",
  Everyone = "everyone",
  Staff = "staff"
}

export enum NotificationDigestInterval {
  Daily = "daily",
  Never = "never",
  Weekly = "weekly"
}

/** A Pathwright Order */
export type Order = {
  __typename?: "Order"
  checkout_session_id?: Maybe<Scalars["String"]["output"]>
  fulfilled_object_id?: Maybe<Scalars["Int"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  order_data?: Maybe<Scalars["JSON"]["output"]>
  order_status?: Maybe<OrderStatus>
  order_type?: Maybe<OrderType>
  processor_id?: Maybe<Scalars["Int"]["output"]>
  school_id?: Maybe<Scalars["Int"]["output"]>
}

/** Order query results by 'asc' or 'desc'. */
export enum OrderByDir {
  Asc = "ASC",
  Desc = "DESC"
}

/** A connection to a list of items. */
export type OrderConnection = {
  __typename?: "OrderConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OrderEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total?: Maybe<Scalars["Int"]["output"]>
}

/** An edge in a connection. */
export type OrderEdge = {
  __typename?: "OrderEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<Order>
}

export enum OrderStatus {
  AwaitingPayment = "awaiting_payment",
  Fulfilled = "fulfilled",
  FulfillmentFailed = "fulfillment_failed",
  FulfillmentInProgress = "fulfillment_in_progress",
  FulfillmentPending = "fulfillment_pending",
  PaymentFailed = "payment_failed",
  PaymentPending = "payment_pending",
  Voided = "voided"
}

export enum OrderType {
  CurriculumLicense = "curriculum_license",
  CurriculumSubscription = "curriculum_subscription",
  GiftSubscription = "gift_subscription",
  GroupLicense = "group_license",
  GroupSubscription = "group_subscription",
  LifetimeSchoolSubscription = "lifetime_school_subscription",
  PlatformSubscription = "platform_subscription",
  Registration = "registration",
  SchoolSubscription = "school_subscription"
}

/** A Page */
export type Page = {
  __typename?: "Page"
  content_type?: Maybe<Scalars["Int"]["output"]>
  description?: Maybe<Scalars["String"]["output"]>
  external_url?: Maybe<Scalars["String"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  iframe_url?: Maybe<Scalars["String"]["output"]>
  is_live?: Maybe<Scalars["Boolean"]["output"]>
  mode?: Maybe<Scalars["String"]["output"]>
  name?: Maybe<Scalars["String"]["output"]>
  order?: Maybe<Scalars["Int"]["output"]>
  page_type?: Maybe<Scalars["Int"]["output"]>
  scripts?: Maybe<Scalars["String"]["output"]>
  show_in_nav?: Maybe<Scalars["Boolean"]["output"]>
  slug?: Maybe<Scalars["String"]["output"]>
  template_content?: Maybe<Scalars["String"]["output"]>
  title?: Maybe<Scalars["String"]["output"]>
}

/** A connection to a list of items. */
export type PageConnection = {
  __typename?: "PageConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PageEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total?: Maybe<Scalars["Int"]["output"]>
}

/** An edge in a connection. */
export type PageEdge = {
  __typename?: "PageEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<Page>
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: "PageInfo"
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]["output"]>
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"]["output"]
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"]["output"]
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]["output"]>
}

/** A path */
export type Path = {
  __typename?: "Path"
  analytics?: Maybe<PathAnalytics>
  cohort?: Maybe<Group>
  completion_date?: Maybe<Scalars["DateTime"]["output"]>
  created_date?: Maybe<Scalars["DateTime"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  items?: Maybe<PathItemConnection>
  /** @deprecated Deprecated in favor of sync.last_synced_date */
  last_synced_dtime?: Maybe<Scalars["DateTime"]["output"]>
  meta: NodeMeta
  next_step_id?: Maybe<Scalars["Int"]["output"]>
  performance?: Maybe<PathPerformance>
  points?: Maybe<Points>
  previewable_steps?: Maybe<PathItemConnection>
  progress?: Maybe<Scalars["Float"]["output"]>
  published_changes?: Maybe<PathChangePublishConnection>
  schedule?: Maybe<PathNodeSchedule>
  sync?: Maybe<PathSync>
  theme: NodeTheme
  user?: Maybe<User>
}

/** A path */
export type PathItemsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  flatten?: InputMaybe<Scalars["Boolean"]["input"]>
  type?: InputMaybe<PathItemType>
}

/** A path */
export type PathPreviewable_StepsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
}

/** A path */
export type PathPublished_ChangesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
}

/** Analytics data for a path or path item */
export type PathAnalytics = {
  __typename?: "PathAnalytics"
  avg_score?: Maybe<Scalars["Float"]["output"]>
  /** The number of users who have completed the whole path */
  complete?: Maybe<Scalars["Int"]["output"]>
  completion_rate?: Maybe<Scalars["Float"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  /** The number of users who still are working on this path */
  incomplete?: Maybe<Scalars["Int"]["output"]>
  item?: Maybe<PathItem>
  items?: Maybe<Scalars["JSON"]["output"]>
  /** The number of step completions in the path that need grading */
  need_feedback?: Maybe<Scalars["Int"]["output"]>
  next_count?: Maybe<Scalars["Int"]["output"]>
  path_id?: Maybe<Scalars["Int"]["output"]>
  /** This is a list of up to 10 users who have completed this path (used to render avatar stacks).  */
  students_here?: Maybe<Scalars["JSON"]["output"]>
  /** The number of earned points across all steps in this path (Note: this excludes steps that aren't due if due dates are in use) */
  total_points?: Maybe<Scalars["Int"]["output"]>
  /** The number of available points across all completed and past due student steps in this path */
  total_points_available?: Maybe<Scalars["Int"]["output"]>
  total_users?: Maybe<Scalars["Int"]["output"]>
}

/** A published set of changes made to a path */
export type PathChangePublish = {
  __typename?: "PathChangePublish"
  id?: Maybe<Scalars["Int"]["output"]>
  message?: Maybe<Scalars["String"]["output"]>
  path_id?: Maybe<Scalars["Int"]["output"]>
  published_by_id?: Maybe<Scalars["Int"]["output"]>
  published_dtime?: Maybe<Scalars["DateTime"]["output"]>
}

/** A connection to a list of items. */
export type PathChangePublishConnection = {
  __typename?: "PathChangePublishConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PathChangePublishEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total?: Maybe<Scalars["Int"]["output"]>
}

/** An edge in a connection. */
export type PathChangePublishEdge = {
  __typename?: "PathChangePublishEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<PathChangePublish>
}

/** An item in a path (Lesson, Topic, Step) */
export type PathItem = {
  __typename?: "PathItem"
  allow_resets?: Maybe<Scalars["Int"]["output"]>
  analytics?: Maybe<ItemAnalytics>
  assignment_type?: Maybe<PathItemAssignmentType>
  /** This dynamically calcuates progress for lessons. Avoid for long result sets as it's an N+1 query! */
  calculated_progress?: Maybe<Scalars["Float"]["output"]>
  cohort?: Maybe<Group>
  cohort_id?: Maybe<Scalars["Int"]["output"]>
  cohort_name?: Maybe<Scalars["String"]["output"]>
  completion?: Maybe<Completion>
  completion_comment_required_status?: Maybe<Scalars["String"]["output"]>
  /** @deprecated It's inefficient to load content for steps in a loop (N+2). Instead, check the content_id and item_content_type_id and load on demand */
  content?: Maybe<StepContent>
  content_id?: Maybe<Scalars["Int"]["output"]>
  content_type?: Maybe<Scalars["String"]["output"]>
  context?: Maybe<StepContext>
  description?: Maybe<Scalars["String"]["output"]>
  due?: Maybe<Scalars["DateTime"]["output"]>
  grading_type?: Maybe<Scalars["String"]["output"]>
  has_started?: Maybe<Scalars["Boolean"]["output"]>
  id: Scalars["Int"]["output"]
  is_locked?: Maybe<Scalars["Boolean"]["output"]>
  is_previewable?: Maybe<Scalars["Boolean"]["output"]>
  is_required?: Maybe<Scalars["Boolean"]["output"]>
  item_content_type_id?: Maybe<Scalars["Int"]["output"]>
  item_needs_grading?: Maybe<Scalars["Boolean"]["output"]>
  items?: Maybe<PathItemConnection>
  last_published_dtime?: Maybe<Scalars["DateTime"]["output"]>
  linked_cohort?: Maybe<Group>
  linked_registration?: Maybe<Registration>
  linked_resource?: Maybe<Resource>
  lock_password?: Maybe<Scalars["String"]["output"]>
  lock_unlocked_time?: Maybe<Scalars["DateTime"]["output"]>
  meta: NodeMeta
  minimum_passing_score?: Maybe<Scalars["Int"]["output"]>
  name: Scalars["String"]["output"]
  order: Scalars["Int"]["output"]
  parent?: Maybe<PathItem>
  parent_id?: Maybe<Scalars["Int"]["output"]>
  parent_name?: Maybe<Scalars["String"]["output"]>
  parent_source_id?: Maybe<Scalars["Int"]["output"]>
  passive_due?: Maybe<Scalars["Boolean"]["output"]>
  passive_starts?: Maybe<Scalars["Boolean"]["output"]>
  path?: Maybe<Path>
  path_id: Scalars["Int"]["output"]
  permissions?: Maybe<PathItemPermissions>
  points?: Maybe<Scalars["Int"]["output"]>
  progress: Scalars["Float"]["output"]
  reminders?: Maybe<MessageConnection>
  resource?: Maybe<Resource>
  resource_name?: Maybe<Scalars["String"]["output"]>
  show_grading_feedback?: Maybe<Scalars["Int"]["output"]>
  source_id: Scalars["Int"]["output"]
  starts?: Maybe<Scalars["DateTime"]["output"]>
  time_to_complete?: Maybe<Scalars["Int"]["output"]>
  type: Scalars["String"]["output"]
  url?: Maybe<Scalars["String"]["output"]>
  verb?: Maybe<Scalars["String"]["output"]>
}

/** An item in a path (Lesson, Topic, Step) */
export type PathItemItemsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
}

/** An item in a path (Lesson, Topic, Step) */
export type PathItemRemindersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
}

export enum PathItemAssignmentType {
  Assessment = "assessment",
  Audio = "audio",
  Completion = "completion",
  Event = "event",
  Reading = "reading",
  Submission = "submission",
  Video = "video"
}

/** A connection to a list of items. */
export type PathItemConnection = {
  __typename?: "PathItemConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PathItemEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total?: Maybe<Scalars["Int"]["output"]>
}

/** An edge in a connection. */
export type PathItemEdge = {
  __typename?: "PathItemEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<PathItem>
}

export type PathItemOrder = {
  id?: InputMaybe<Scalars["Int"]["input"]>
  order: Scalars["Int"]["input"]
  parent_id?: InputMaybe<Scalars["Int"]["input"]>
  source_id: Scalars["Int"]["input"]
}

export type PathItemPermissions = {
  __typename?: "PathItemPermissions"
  can_complete?: Maybe<Scalars["Boolean"]["output"]>
  can_discuss?: Maybe<Scalars["Boolean"]["output"]>
  can_edit?: Maybe<Scalars["Boolean"]["output"]>
  can_edit_content?: Maybe<Scalars["Boolean"]["output"]>
  can_note?: Maybe<Scalars["Boolean"]["output"]>
  can_review?: Maybe<Scalars["Boolean"]["output"]>
  can_view?: Maybe<Scalars["Boolean"]["output"]>
  can_view_content?: Maybe<Scalars["Boolean"]["output"]>
}

/** Status for path items */
export type PathItemStats = {
  __typename?: "PathItemStats"
  path_items_needing_review?: Maybe<Scalars["Int"]["output"]>
}

export enum PathItemType {
  Divider = "divider",
  Lesson = "lesson",
  Resource = "resource",
  Step = "step"
}

export enum PathItemTypes {
  Divider = "divider",
  Lesson = "lesson",
  Resource = "resource",
  Step = "step"
}

export type PathNodeSchedule = {
  __typename?: "PathNodeSchedule"
  end?: Maybe<ScheduleDate>
  start?: Maybe<ScheduleDate>
}

/** A path's performance data */
export type PathPerformance = {
  __typename?: "PathPerformance"
  id?: Maybe<Scalars["Int"]["output"]>
  points?: Maybe<PathPerformancePoints>
  progress?: Maybe<PathPerformanceProgress>
}

export type PathPerformancePoints = {
  __typename?: "PathPerformancePoints"
  applicable_score?: Maybe<Scalars["JSON"]["output"]>
  applicable_score_percentage?: Maybe<Scalars["Float"]["output"]>
  earned?: Maybe<Scalars["Int"]["output"]>
  percentage?: Maybe<Scalars["Float"]["output"]>
  possible?: Maybe<Scalars["Int"]["output"]>
  total?: Maybe<Scalars["Int"]["output"]>
}

export type PathPerformanceProgress = {
  __typename?: "PathPerformanceProgress"
  percentage?: Maybe<Scalars["Float"]["output"]>
  required_steps_completed?: Maybe<Scalars["Int"]["output"]>
  steps_completed?: Maybe<Scalars["Int"]["output"]>
  total_required_steps?: Maybe<Scalars["Int"]["output"]>
  total_steps?: Maybe<Scalars["Int"]["output"]>
}

/** Status of whether a path needs to be synced with its source path */
export type PathSync = {
  __typename?: "PathSync"
  last_synced_date?: Maybe<Scalars["DateTime"]["output"]>
  path_id?: Maybe<Scalars["Int"]["output"]>
  sync_source_path_id?: Maybe<Scalars["Int"]["output"]>
  synced?: Maybe<Scalars["Boolean"]["output"]>
}

/** Sync plan for a path */
export type PathSyncPlan = {
  __typename?: "PathSyncPlan"
  excluded_changes?: Maybe<Array<Maybe<PathSyncPlanChange>>>
  from_path_id?: Maybe<Scalars["Int"]["output"]>
  hash?: Maybe<Scalars["String"]["output"]>
  included_changes?: Maybe<Array<Maybe<PathSyncPlanChange>>>
  to_path_id?: Maybe<Scalars["Int"]["output"]>
}

/** A single change of a PathSyncPlan */
export type PathSyncPlanChange = {
  __typename?: "PathSyncPlanChange"
  assignment_type?: Maybe<PathItemAssignmentType>
  change_details?: Maybe<Scalars["JSON"]["output"]>
  forced?: Maybe<PathSyncPlanForceType>
  forced_reasons?: Maybe<Array<Maybe<PathSyncPlanForceReasonType>>>
  item_name?: Maybe<Scalars["String"]["output"]>
  item_source_id?: Maybe<Scalars["Int"]["output"]>
  item_type?: Maybe<PathItemType>
  last_changed?: Maybe<Scalars["DateTime"]["output"]>
  parent_name?: Maybe<Scalars["String"]["output"]>
  parent_source_id?: Maybe<Scalars["Int"]["output"]>
}

export enum PathSyncPlanForceReasonType {
  /** Excluded due to lesson not containing any steps with content */
  ExcludeLessonEmpty = "exclude_lesson_empty",
  /** Excluded due to step having no content */
  ExcludeStepNoContent = "exclude_step_no_content",
  /** Excluded upon user request */
  ExcludeUserRequest = "exclude_user_request",
  /** Included upon user request */
  IncludeUserRequest = "include_user_request"
}

export enum PathSyncPlanForceType {
  /** The path item change is force included and force excluded requiring a conflict to be resolved */
  Conflict = "conflict",
  /** The path item change cannot be included in sync */
  Exclude = "exclude",
  /** The path item change cannot be excluded from sync */
  Include = "include"
}

export type PathSyncPlanInput = {
  /** The source IDs of the path items to exclude from the sync plan. */
  exclude_source_ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>
  /** The ID of the path from which to generate the sync plan. */
  from_path_id: Scalars["Int"]["input"]
  /** When executing a sync plan, the generated sync plan hash is required to ensure no changes have occurred between generating and executing the sync plan. */
  hash?: InputMaybe<Scalars["String"]["input"]>
  /** The source IDs of the path items to include from the sync plan. */
  include_source_ids?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>
  /** The ID of the path against which to generate the sync plan. If not provided, the sync plan for the path will be derived from the diff of its current state and unpublished changes. */
  to_path_id?: InputMaybe<Scalars["Int"]["input"]>
}

/** A node in the Paths codex data structure */
export type PathsCodexNode = {
  __typename?: "PathsCodexNode"
  key?: Maybe<Scalars["String"]["output"]>
  type?: Maybe<Scalars["String"]["output"]>
  version?: Maybe<Scalars["Int"]["output"]>
}

/** A Pathwright admin scope for cool data */
export type PathwrightAdmin = {
  __typename?: "PathwrightAdmin"
  schools?: Maybe<SchoolConnection>
}

/** A Pathwright admin scope for cool data */
export type PathwrightAdminSchoolsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  licensing_from?: InputMaybe<Scalars["Int"]["input"]>
  school_subscription_plan_id?: InputMaybe<Scalars["Int"]["input"]>
  search?: InputMaybe<Scalars["String"]["input"]>
}

/** A Pathwright Payment */
export type Payment = {
  __typename?: "Payment"
  amount?: Maybe<Scalars["Float"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
}

export type PeopleAndGroupConnection = {
  __typename?: "PeopleAndGroupConnection"
  id?: Maybe<Scalars["Int"]["output"]>
  /** The meta data for this 'type'. */
  meta: NodeMeta
  /** The number of people attached to this 'type' */
  people_count?: Maybe<Scalars["Int"]["output"]>
  /** The role of the 'user' type in the queried context. Will be null for all other types. */
  role?: Maybe<Role>
  role_in?: Maybe<Role>
  /** The type of the people source. */
  type?: Maybe<PeopleAndGroupConnectionType>
}

/** A connection to a list of items. */
export type PeopleAndGroupConnectionConnection = {
  __typename?: "PeopleAndGroupConnectionConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PeopleAndGroupConnectionEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total?: Maybe<Scalars["Int"]["output"]>
}

export type PeopleAndGroupConnectionContext = {
  cohort_id?: InputMaybe<Scalars["Int"]["input"]>
  group_id?: InputMaybe<Scalars["Int"]["input"]>
}

/** An edge in a connection. */
export type PeopleAndGroupConnectionEdge = {
  __typename?: "PeopleAndGroupConnectionEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<PeopleAndGroupConnection>
}

export enum PeopleAndGroupConnectionType {
  Cohort = "cohort",
  Group = "group",
  User = "user"
}

/** Platform Config */
export type PlatformConfig = {
  __typename?: "PlatformConfig"
  id?: Maybe<Scalars["Int"]["output"]>
  payments_maintenance_mode?: Maybe<Scalars["Boolean"]["output"]>
}

/** Platform subscription order data */
export type PlatformSubscriptionOrderData = {
  billing_plan_id: Scalars["Int"]["input"]
  interval: Scalars["Int"]["input"]
  /** Optionally provide the school ID of the school creating the order. */
  school_id?: InputMaybe<Scalars["Int"]["input"]>
}

export type Points = {
  __typename?: "Points"
  /** Sum of the `userpoints_earned` for each "scoreable" path item completion */
  earned?: Maybe<Scalars["Int"]["output"]>
  /** Whether any path item on the path has `userpoints_available` */
  has_points?: Maybe<Scalars["Boolean"]["output"]>
  path_id?: Maybe<Scalars["Int"]["output"]>
  /** Percentage of earned / total */
  percentage?: Maybe<Scalars["Float"]["output"]>
  /** Sum of the `userpoints_available` for each "scoreable" path item completion */
  total?: Maybe<Scalars["Int"]["output"]>
  /** Sum of `userpoints_available` for each path item */
  total_points_possible?: Maybe<Scalars["Int"]["output"]>
}

/** A Pathwright User Profile */
export type Profile = {
  __typename?: "Profile"
  bio?: Maybe<Scalars["String"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  image?: Maybe<Scalars["String"]["output"]>
  last_activity?: Maybe<Scalars["DateTime"]["output"]>
  location?: Maybe<Scalars["String"]["output"]>
  notification_digest_interval?: Maybe<NotificationDigestInterval>
  rewardful_referral_id?: Maybe<Scalars["String"]["output"]>
}

/** A Pathwright User Profile */
export type ProfileImageArgs = {
  blur?: InputMaybe<Scalars["Int"]["input"]>
  crop?: InputMaybe<Crop>
  dpr?: InputMaybe<Scalars["Int"]["input"]>
  fit?: InputMaybe<Fit>
  height?: InputMaybe<Scalars["Int"]["input"]>
  width?: InputMaybe<Scalars["Int"]["input"]>
}

export type ProgressFilter = {
  eq?: InputMaybe<Scalars["Float"]["input"]>
  gt?: InputMaybe<Scalars["Float"]["input"]>
  gte?: InputMaybe<Scalars["Float"]["input"]>
  in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>
  lt?: InputMaybe<Scalars["Float"]["input"]>
  lte?: InputMaybe<Scalars["Float"]["input"]>
  neq?: InputMaybe<Scalars["Float"]["input"]>
  nin?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>
  op?: InputMaybe<Scalars["String"]["input"]>
}

/** A Pathwright School Publisher */
export type Publisher = {
  __typename?: "Publisher"
  about?: Maybe<Scalars["String"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  image?: Maybe<Scalars["String"]["output"]>
  logo?: Maybe<Scalars["String"]["output"]>
  name?: Maybe<Scalars["String"]["output"]>
  theme?: Maybe<Theme>
  website?: Maybe<Scalars["String"]["output"]>
}

/** A Pathwright School Publisher */
export type PublisherImageArgs = {
  blur?: InputMaybe<Scalars["Int"]["input"]>
  crop?: InputMaybe<Crop>
  dpr?: InputMaybe<Scalars["Int"]["input"]>
  fit?: InputMaybe<Fit>
  height?: InputMaybe<Scalars["Int"]["input"]>
  width?: InputMaybe<Scalars["Int"]["input"]>
}

/** A Pathwright School Publisher */
export type PublisherLogoArgs = {
  blur?: InputMaybe<Scalars["Int"]["input"]>
  crop?: InputMaybe<Crop>
  dpr?: InputMaybe<Scalars["Int"]["input"]>
  fit?: InputMaybe<Fit>
  height?: InputMaybe<Scalars["Int"]["input"]>
  width?: InputMaybe<Scalars["Int"]["input"]>
}

/** A connection to a list of items. */
export type PublisherConnection = {
  __typename?: "PublisherConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PublisherEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total?: Maybe<Scalars["Int"]["output"]>
}

/** An edge in a connection. */
export type PublisherEdge = {
  __typename?: "PublisherEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<Publisher>
}

/** Pathwright GraphQL Endpoint */
export type Query = {
  __typename?: "Query"
  account?: Maybe<SchoolAcount>
  activity?: Maybe<ActivityConnection>
  activityStats?: Maybe<ActivityStats>
  admin?: Maybe<PathwrightAdmin>
  assignRoles: Array<Maybe<RegistrationRoleEnum>>
  authRequest?: Maybe<AuthRequest>
  /** The auth token resulting from a confirmed auth request made by the user to authenticate by some other means than username/password. */
  authRequestToken?: Maybe<Scalars["JWT"]["output"]>
  author?: Maybe<Author>
  authors?: Maybe<AuthorConnection>
  backgroundTask?: Maybe<BackgroundTask>
  calendar?: Maybe<Calendar>
  calendarStats?: Maybe<CalendarStats>
  canReview?: Maybe<Scalars["Boolean"]["output"]>
  categories?: Maybe<CategoryConnection>
  category?: Maybe<Category>
  certificate?: Maybe<Certificate>
  certificatePreview?: Maybe<Scalars["String"]["output"]>
  certificateVariables?: Maybe<CertificateVariables>
  certificates?: Maybe<CertificateConnection>
  codex?: Maybe<Codex>
  computedDiscount?: Maybe<ComputedDiscount>
  context?: Maybe<AccountContext>
  contextualFeaturePermission?: Maybe<ContextualFeaturePermission>
  contextualFeaturePermissions: Array<Maybe<ContextualFeaturePermission>>
  curriculumSubscription?: Maybe<CurriculumSubscription>
  discussion?: Maybe<Discussion>
  discussionParticipants?: Maybe<DiscussionParticipantsConnection>
  discussions?: Maybe<DiscussionConnection>
  feedbackReviewers?: Maybe<FeedbackReviewers>
  gravatar?: Maybe<Gravatar>
  group?: Maybe<Group>
  groups?: Maybe<GroupConnection>
  inboxContext?: Maybe<InboxContext>
  inboxGroupConnections?: Maybe<InboxGroupConnectionConnection>
  inboxGroups?: Maybe<InboxMentorGroupConnection>
  inboxPaths?: Maybe<InboxPathConnection>
  inboxPendingReview?: Maybe<InboxPendingReview>
  inboxPeopleCompletionCounts?: Maybe<InboxPeopleCompletionCountsConnection>
  inboxReviewers?: Maybe<InboxReviwerConnection>
  integrations?: Maybe<Integrations>
  invitationToken?: Maybe<InvitationToken>
  invitations?: Maybe<InvitationConnection>
  license_offering?: Maybe<LicenseOffering>
  loginMethod?: Maybe<LoginInfo>
  loginMethods?: Maybe<Array<LoginInfo>>
  me?: Maybe<User>
  media?: Maybe<Media>
  mediaContext?: Maybe<MediaContext>
  membership_meta?: Maybe<MembershipMeta>
  mentorGroupMembership?: Maybe<MentorGroupMembership>
  message?: Maybe<Message>
  messages?: Maybe<MessageConnection>
  note?: Maybe<Note>
  notes?: Maybe<NoteConnection>
  order?: Maybe<Order>
  orders?: Maybe<OrderConnection>
  path?: Maybe<Path>
  pathItem?: Maybe<PathItem>
  pathItemStats?: Maybe<PathItemStats>
  pathItems?: Maybe<PathItemConnection>
  pathSyncPlan?: Maybe<PathSyncPlan>
  peopleAndGroupConnection?: Maybe<PeopleAndGroupConnection>
  peopleAndGroupConnections?: Maybe<PeopleAndGroupConnectionConnection>
  platformConfig?: Maybe<PlatformConfig>
  report?: Maybe<Scalars["JSON"]["output"]>
  resource?: Maybe<Resource>
  resource_license?: Maybe<ResourceLicense>
  resource_licenses?: Maybe<ResourceLicenseConnection>
  resources?: Maybe<ResourceConnection>
  responses?: Maybe<ResponseConnection>
  restAPIGet?: Maybe<Scalars["JSON"]["output"]>
  school?: Maybe<School>
  schoolGroupSubscription?: Maybe<SchoolGroupSubscription>
  schoolGroupSubscriptions?: Maybe<SchoolGroupSubscriptionConnection>
  schoolSubscriptionDiscount?: Maybe<SchoolSubscriptionDiscount>
  sharePermissions?: Maybe<SharePermissions>
  /** Returns the current space (from request header */
  space: Space
  /** A list of spaces the user has access to */
  spaceConnections?: Maybe<SpaceConnectionConnection>
  store?: Maybe<Store>
  storeBanners?: Maybe<Array<Maybe<StoreBanner>>>
  storeCollection?: Maybe<StoreCollection>
  storeCollections?: Maybe<Array<Maybe<StoreCollection>>>
  storeItem?: Maybe<StoreItem>
  storeLink?: Maybe<StoreLink>
  storeLinks?: Maybe<StoreLinkConnection>
  stripeProduct?: Maybe<StripeProduct>
  stripeProducts?: Maybe<StripeProductConnection>
  subdomain?: Maybe<Subdomain>
  subscription?: Maybe<Subscription>
  subscriptions?: Maybe<SubscriptionConnection>
  tag?: Maybe<Tag>
  tagAttachment?: Maybe<TagAttachment>
  tagAttachments?: Maybe<TagAttachmentConnection>
  tags?: Maybe<TagConnection>
  theme?: Maybe<Theme>
  translation?: Maybe<Translation>
  user?: Maybe<User>
  userStatus?: Maybe<UserStatus>
  webhookEventConnections?: Maybe<WebhookEventConnection>
}

/** Pathwright GraphQL Endpoint */
export type QueryActivityArgs = {
  action_type?: InputMaybe<ActionTypeFilter>
  after?: InputMaybe<Scalars["String"]["input"]>
  date?: InputMaybe<DateFilter>
  first: Scalars["Int"]["input"]
  group_id?: InputMaybe<Scalars["Int"]["input"]>
  interest_level?: InputMaybe<InterestLevel>
  resource_id?: InputMaybe<Scalars["Int"]["input"]>
  step_id?: InputMaybe<Scalars["Int"]["input"]>
  user_id?: InputMaybe<Scalars["Int"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryAuthRequestArgs = {
  uuid: Scalars["String"]["input"]
}

/** Pathwright GraphQL Endpoint */
export type QueryAuthRequestTokenArgs = {
  uuid: Scalars["String"]["input"]
}

/** Pathwright GraphQL Endpoint */
export type QueryAuthorArgs = {
  id?: InputMaybe<Scalars["Int"]["input"]>
  slug?: InputMaybe<Scalars["String"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryAuthorsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  ids?: InputMaybe<IdFilter>
  manage?: InputMaybe<Scalars["Boolean"]["input"]>
  resource_author_ids?: InputMaybe<IdFilter>
  resource_author_names?: InputMaybe<StringFilter>
  resource_category_ids?: InputMaybe<IdFilter>
  resource_category_names?: InputMaybe<StringFilter>
  resource_search?: InputMaybe<Scalars["String"]["input"]>
  search?: InputMaybe<Scalars["String"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryBackgroundTaskArgs = {
  id: Scalars["String"]["input"]
}

/** Pathwright GraphQL Endpoint */
export type QueryCalendarArgs = {
  path_id?: InputMaybe<Scalars["ID"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryCalendarStatsArgs = {
  path_id?: InputMaybe<Scalars["ID"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryCanReviewArgs = {
  for_user_id: Scalars["Int"]["input"]
}

/** Pathwright GraphQL Endpoint */
export type QueryCategoriesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  ids?: InputMaybe<IdFilter>
  manage?: InputMaybe<Scalars["Boolean"]["input"]>
  resource_author_ids?: InputMaybe<IdFilter>
  resource_author_names?: InputMaybe<StringFilter>
  resource_category_ids?: InputMaybe<IdFilter>
  resource_category_names?: InputMaybe<StringFilter>
  resource_search?: InputMaybe<Scalars["String"]["input"]>
  search?: InputMaybe<Scalars["String"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryCategoryArgs = {
  id?: InputMaybe<Scalars["Int"]["input"]>
  slug?: InputMaybe<Scalars["String"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryCertificateArgs = {
  id?: InputMaybe<Scalars["Int"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryCertificatePreviewArgs = {
  template: Scalars["String"]["input"]
  text_objects: Array<InputMaybe<TextObject>>
  variables?: InputMaybe<Scalars["JSON"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryCertificateVariablesArgs = {
  context?: InputMaybe<CertificateVariablesContextInput>
}

/** Pathwright GraphQL Endpoint */
export type QueryCertificatesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
}

/** Pathwright GraphQL Endpoint */
export type QueryComputedDiscountArgs = {
  discount_code: Scalars["String"]["input"]
  license_offering_id?: InputMaybe<Scalars["Int"]["input"]>
  offering_id?: InputMaybe<Scalars["Int"]["input"]>
  resource_id: Scalars["Int"]["input"]
  seat_count?: InputMaybe<Scalars["Int"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryContextArgs = {
  auth_token_id?: InputMaybe<Scalars["String"]["input"]>
  school_id: Scalars["Int"]["input"]
}

/** Pathwright GraphQL Endpoint */
export type QueryContextualFeaturePermissionArgs = {
  context?: InputMaybe<FeatureContextInput>
  feature_action: FeatureActionType
  feature_key: FeatureKeyType
}

/** Pathwright GraphQL Endpoint */
export type QueryContextualFeaturePermissionsArgs = {
  context?: InputMaybe<FeatureContextInput>
  feature_action?: InputMaybe<FeatureActionType>
  feature_key?: InputMaybe<FeatureKeyType>
  feature_keys?: InputMaybe<FeatureKeyFilter>
}

/** Pathwright GraphQL Endpoint */
export type QueryCurriculumSubscriptionArgs = {
  id: Scalars["Int"]["input"]
}

/** Pathwright GraphQL Endpoint */
export type QueryDiscussionArgs = {
  context: DiscussionContextInput
  id?: InputMaybe<Scalars["Int"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryDiscussionParticipantsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  context: DiscussionContextInput
  discussion_id: Scalars["Int"]["input"]
  first: Scalars["Int"]["input"]
}

/** Pathwright GraphQL Endpoint */
export type QueryDiscussionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  context?: InputMaybe<DiscussionContextInput>
  first: Scalars["Int"]["input"]
  is_discussion_question?: InputMaybe<Scalars["Boolean"]["input"]>
  order_by?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
  tag_id_filter?: InputMaybe<IntFilter>
  total_responses?: InputMaybe<IntFilter>
  type?: InputMaybe<DiscussionTypeInput>
  user_id?: InputMaybe<Scalars["Int"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryFeedbackReviewersArgs = {
  completion_id: Scalars["Int"]["input"]
}

/** Pathwright GraphQL Endpoint */
export type QueryGravatarArgs = {
  email: Scalars["String"]["input"]
}

/** Pathwright GraphQL Endpoint */
export type QueryGroupArgs = {
  id: Scalars["Int"]["input"]
}

/** Pathwright GraphQL Endpoint */
export type QueryGroupsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  assign_config?: InputMaybe<AssignConfig>
  first: Scalars["Int"]["input"]
  include_curriculum?: InputMaybe<Scalars["Boolean"]["input"]>
  is_master?: InputMaybe<Scalars["Boolean"]["input"]>
  parent_group_id?: InputMaybe<IdFilter>
  resource_id?: InputMaybe<Scalars["Int"]["input"]>
  role?: InputMaybe<RoleFilter>
  search?: InputMaybe<Scalars["String"]["input"]>
  status?: InputMaybe<GroupStatus>
  visibility?: InputMaybe<GroupVisibility>
  visibility_filter?: InputMaybe<GroupVisibilityFilter>
}

/** Pathwright GraphQL Endpoint */
export type QueryInboxContextArgs = {
  cohort_id?: InputMaybe<Scalars["Int"]["input"]>
  group_id?: InputMaybe<Scalars["Int"]["input"]>
  user_id?: InputMaybe<Scalars["Int"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryInboxGroupConnectionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  search?: InputMaybe<Scalars["String"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryInboxGroupsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  status?: InputMaybe<Scalars["String"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryInboxPathsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
}

/** Pathwright GraphQL Endpoint */
export type QueryInboxPendingReviewArgs = {
  target_id: Scalars["Int"]["input"]
  target_type: InboxPendingReviewTarget
}

/** Pathwright GraphQL Endpoint */
export type QueryInboxPeopleCompletionCountsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  is_complete?: InputMaybe<Scalars["Boolean"]["input"]>
  needs_review?: InputMaybe<Scalars["Boolean"]["input"]>
  parent_source_id?: InputMaybe<Scalars["Int"]["input"]>
  step_source_id?: InputMaybe<Scalars["Int"]["input"]>
  target_id: Scalars["Int"]["input"]
  target_type: InboxPeopleCompletionCountsTarget
}

/** Pathwright GraphQL Endpoint */
export type QueryInboxReviewersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  path_id?: InputMaybe<Scalars["Int"]["input"]>
  step_id?: InputMaybe<Scalars["Int"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryInvitationTokenArgs = {
  token: Scalars["NonEmptyString"]["input"]
}

/** Pathwright GraphQL Endpoint */
export type QueryInvitationsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  context?: InputMaybe<InvitationContext>
  delivery_status?: InputMaybe<EmailDeliveryStatus>
  first: Scalars["Int"]["input"]
  order_by?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
  search?: InputMaybe<Scalars["String"]["input"]>
  status?: InputMaybe<InvitationStatus>
  type?: InputMaybe<InvitationType>
}

/** Pathwright GraphQL Endpoint */
export type QueryLicense_OfferingArgs = {
  id: Scalars["Int"]["input"]
  licensing_mode?: InputMaybe<LicenseOfferingLicensingMode>
}

/** Pathwright GraphQL Endpoint */
export type QueryLoginMethodArgs = {
  email: Scalars["String"]["input"]
}

/** Pathwright GraphQL Endpoint */
export type QueryLoginMethodsArgs = {
  email: Scalars["String"]["input"]
}

/** Pathwright GraphQL Endpoint */
export type QueryMembership_MetaArgs = {
  user_id: Scalars["Int"]["input"]
}

/** Pathwright GraphQL Endpoint */
export type QueryMentorGroupMembershipArgs = {
  id: Scalars["Int"]["input"]
}

/** Pathwright GraphQL Endpoint */
export type QueryMessageArgs = {
  id: Scalars["Int"]["input"]
  message_context: MessageContext
}

/** Pathwright GraphQL Endpoint */
export type QueryMessagesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  message_context: MessageContext
  type?: InputMaybe<MessageTypeFilter>
}

/** Pathwright GraphQL Endpoint */
export type QueryNoteArgs = {
  context: ContextModelInput
  id?: InputMaybe<Scalars["Int"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryNotesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  context: ContextModelInput
  first: Scalars["Int"]["input"]
  order_by?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
  visibility?: InputMaybe<NoteVisibility>
}

/** Pathwright GraphQL Endpoint */
export type QueryOrderArgs = {
  id: Scalars["Int"]["input"]
}

/** Pathwright GraphQL Endpoint */
export type QueryOrdersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
}

/** Pathwright GraphQL Endpoint */
export type QueryPathArgs = {
  cohort_id?: InputMaybe<Scalars["Int"]["input"]>
  id?: InputMaybe<Scalars["Int"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryPathItemArgs = {
  cohort_id?: InputMaybe<Scalars["Int"]["input"]>
  id?: InputMaybe<Scalars["Int"]["input"]>
  path_id?: InputMaybe<Scalars["Int"]["input"]>
  source_id?: InputMaybe<Scalars["Int"]["input"]>
  user_id?: InputMaybe<Scalars["Int"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryPathItemStatsArgs = {
  user_id: Scalars["Int"]["input"]
}

/** Pathwright GraphQL Endpoint */
export type QueryPathItemsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  cohort_id?: InputMaybe<Scalars["Int"]["input"]>
  due?: InputMaybe<DateFilter>
  exclude_default?: InputMaybe<Scalars["Boolean"]["input"]>
  first: Scalars["Int"]["input"]
  group_id?: InputMaybe<Scalars["Int"]["input"]>
  is_complete?: InputMaybe<Scalars["Boolean"]["input"]>
  is_required?: InputMaybe<Scalars["Boolean"]["input"]>
  is_reviewed?: InputMaybe<Scalars["Boolean"]["input"]>
  is_skipped?: InputMaybe<Scalars["Boolean"]["input"]>
  lesson_source_id?: InputMaybe<Scalars["Int"]["input"]>
  needs_attention?: InputMaybe<Scalars["Boolean"]["input"]>
  needs_review?: InputMaybe<Scalars["Boolean"]["input"]>
  next?: InputMaybe<Scalars["Boolean"]["input"]>
  order_by?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
  path_id?: InputMaybe<Scalars["Int"]["input"]>
  registration_role_filter?: InputMaybe<RoleFilter>
  resource_id?: InputMaybe<Scalars["Int"]["input"]>
  reviewable?: InputMaybe<Scalars["Boolean"]["input"]>
  root_level?: InputMaybe<Scalars["Boolean"]["input"]>
  source_id?: InputMaybe<Scalars["Int"]["input"]>
  starts?: InputMaybe<DateFilter>
  step_id?: InputMaybe<Scalars["Int"]["input"]>
  type?: InputMaybe<PathItemTypes>
  upcoming?: InputMaybe<Scalars["Boolean"]["input"]>
  user_id?: InputMaybe<Scalars["Int"]["input"]>
  with_dates?: InputMaybe<Scalars["Boolean"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryPathSyncPlanArgs = {
  sync_plan?: InputMaybe<PathSyncPlanInput>
}

/** Pathwright GraphQL Endpoint */
export type QueryPeopleAndGroupConnectionArgs = {
  cohort_id?: InputMaybe<Scalars["Int"]["input"]>
  group_id?: InputMaybe<Scalars["Int"]["input"]>
  role_in_context?: InputMaybe<RoleInContext>
  user_id?: InputMaybe<Scalars["Int"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryPeopleAndGroupConnectionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  context?: InputMaybe<PeopleAndGroupConnectionContext>
  first: Scalars["Int"]["input"]
  people_count_filter?: InputMaybe<IntFilter>
  role_in_context?: InputMaybe<RoleInContext>
  search_term?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
  type?: InputMaybe<PeopleAndGroupConnectionType>
}

/** Pathwright GraphQL Endpoint */
export type QueryReportArgs = {
  community_groups?: InputMaybe<Array<InputMaybe<ReportInput>>>
  community_memberships?: InputMaybe<Array<InputMaybe<ReportInput>>>
  group_performance_completion?: InputMaybe<Array<InputMaybe<ReportInput>>>
  group_performance_points?: InputMaybe<Array<InputMaybe<ReportInput>>>
  group_registrations?: InputMaybe<Array<InputMaybe<ReportInput>>>
  licensing_member_created_cohorts?: InputMaybe<Array<InputMaybe<ReportInput>>>
  licensing_store_connected_accounts?: InputMaybe<
    Array<InputMaybe<ReportInput>>
  >
  licensing_store_member_progress?: InputMaybe<Array<InputMaybe<ReportInput>>>
  licensing_store_resource_usage?: InputMaybe<Array<InputMaybe<ReportInput>>>
  school_cohorts?: InputMaybe<Array<InputMaybe<ReportInput>>>
  school_coupon_redemptions?: InputMaybe<Array<InputMaybe<ReportInput>>>
  school_coupon_summary?: InputMaybe<Array<InputMaybe<ReportInput>>>
  school_external_ids?: InputMaybe<Array<InputMaybe<ReportInput>>>
  school_member_progress?: InputMaybe<Array<InputMaybe<ReportInput>>>
  school_members?: InputMaybe<Array<InputMaybe<ReportInput>>>
  school_registrations?: InputMaybe<Array<InputMaybe<ReportInput>>>
  school_resources?: InputMaybe<Array<InputMaybe<ReportInput>>>
}

/** Pathwright GraphQL Endpoint */
export type QueryResourceArgs = {
  id?: InputMaybe<Scalars["Int"]["input"]>
  name?: InputMaybe<Scalars["String"]["input"]>
  slug?: InputMaybe<Scalars["String"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryResource_LicenseArgs = {
  id?: InputMaybe<Scalars["Int"]["input"]>
  license_offering_id?: InputMaybe<Scalars["Int"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryResource_LicensesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
}

/** Pathwright GraphQL Endpoint */
export type QueryResourcesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  assign_config?: InputMaybe<AssignConfig>
  author_id?: InputMaybe<Scalars["Int"]["input"]>
  author_ids?: InputMaybe<IdFilter>
  author_names?: InputMaybe<StringFilter>
  author_search?: InputMaybe<Scalars["String"]["input"]>
  category_id?: InputMaybe<Scalars["Int"]["input"]>
  category_ids?: InputMaybe<IdFilter>
  category_names?: InputMaybe<StringFilter>
  category_search?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  has_certificate?: InputMaybe<Scalars["Boolean"]["input"]>
  id?: InputMaybe<IdFilter>
  license_offering_licensing_mode?: InputMaybe<LicenseOfferingLicensingMode>
  licensed_from_school?: InputMaybe<IdFilter>
  orderBy?: InputMaybe<Scalars["String"]["input"]>
  publisher_id?: InputMaybe<Scalars["Int"]["input"]>
  publisher_ids?: InputMaybe<IdFilter>
  resource_types?: InputMaybe<ResourceTypeFilter>
  role?: InputMaybe<RoleFilter>
  search?: InputMaybe<Scalars["String"]["input"]>
  user_registered?: InputMaybe<IdFilter>
  visibility?: InputMaybe<ResourceVisibility>
}

/** Pathwright GraphQL Endpoint */
export type QueryResponsesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  context: DiscussionResponseContextInput
  first: Scalars["Int"]["input"]
  modified_time?: InputMaybe<DateFilter>
  order_by?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
}

/** Pathwright GraphQL Endpoint */
export type QueryRestApiGetArgs = {
  endpoint: Scalars["String"]["input"]
}

/** Pathwright GraphQL Endpoint */
export type QuerySchoolGroupSubscriptionArgs = {
  id?: InputMaybe<Scalars["Int"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QuerySchoolGroupSubscriptionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  license_offering_id?: InputMaybe<Scalars["Int"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QuerySchoolSubscriptionDiscountArgs = {
  discount_code: Scalars["String"]["input"]
  school_plan_id: Scalars["Int"]["input"]
}

/** Pathwright GraphQL Endpoint */
export type QuerySharePermissionsArgs = {
  context?: InputMaybe<ShareContext>
}

/** Pathwright GraphQL Endpoint */
export type QuerySpaceConnectionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  search?: InputMaybe<Scalars["String"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryStoreCollectionArgs = {
  id: Scalars["Int"]["input"]
}

/** Pathwright GraphQL Endpoint */
export type QueryStoreCollectionsArgs = {
  author_id?: InputMaybe<Scalars["Int"]["input"]>
  author_ids?: InputMaybe<IdFilter>
  author_search?: InputMaybe<Scalars["String"]["input"]>
  category_id?: InputMaybe<Scalars["Int"]["input"]>
  category_ids?: InputMaybe<IdFilter>
  category_search?: InputMaybe<Scalars["String"]["input"]>
  search?: InputMaybe<Scalars["String"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryStoreItemArgs = {
  id: Scalars["Int"]["input"]
}

/** Pathwright GraphQL Endpoint */
export type QueryStoreLinksArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  licensor_id?: InputMaybe<IdFilter>
}

/** Pathwright GraphQL Endpoint */
export type QueryStripeProductArgs = {
  id?: InputMaybe<Scalars["Int"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryStripeProductsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  ids?: InputMaybe<IdFilter>
  include_inactive?: InputMaybe<Scalars["Boolean"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QuerySubdomainArgs = {
  subdomain?: InputMaybe<Scalars["String"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QuerySubscriptionArgs = {
  id: Scalars["Int"]["input"]
}

/** Pathwright GraphQL Endpoint */
export type QuerySubscriptionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  payment_intent_needing_action_id?: InputMaybe<IdFilter>
}

/** Pathwright GraphQL Endpoint */
export type QueryTagArgs = {
  id?: InputMaybe<Scalars["Int"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryTagAttachmentArgs = {
  context?: InputMaybe<TagAttachmentContextInput>
  id?: InputMaybe<Scalars["Int"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryTagAttachmentsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  context?: InputMaybe<TagAttachmentContextInput>
  first: Scalars["Int"]["input"]
  tag_id?: InputMaybe<Scalars["Int"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryTagsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  context?: InputMaybe<TagAttachmentContextInput>
  first: Scalars["Int"]["input"]
}

/** Pathwright GraphQL Endpoint */
export type QueryTranslationArgs = {
  is_master?: InputMaybe<Scalars["Boolean"]["input"]>
  language?: InputMaybe<Scalars["String"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryUserArgs = {
  email?: InputMaybe<Scalars["String"]["input"]>
  id?: InputMaybe<Scalars["Int"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryUserStatusArgs = {
  input?: InputMaybe<Scalars["String"]["input"]>
}

/** Pathwright GraphQL Endpoint */
export type QueryWebhookEventConnectionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  event?: InputMaybe<WebhookEventType>
  first: Scalars["Int"]["input"]
  last_attempt_dtime_filter?: InputMaybe<DateFilter>
  order_by?: InputMaybe<WebhookEventOrderBy>
  status?: InputMaybe<WebhookEventStatus>
}

export type Referral = {
  __typename?: "Referral"
  referral?: Maybe<Scalars["String"]["output"]>
}

/** A Pathwright Registration */
export type Registration = {
  __typename?: "Registration"
  /**
   * The download URL for the course certificate
   * @deprecated Optimization. `certificate_download_url` field has been moved to its own schema that can be queried separately, `RegistrationCertificate`
   */
  certificate_download_url?: Maybe<Scalars["String"]["output"]>
  completion_state?: Maybe<Scalars["Int"]["output"]>
  created?: Maybe<Scalars["DateTime"]["output"]>
  expiration_dtime?: Maybe<Scalars["DateTime"]["output"]>
  group?: Maybe<Group>
  group_sub_community_membership?: Maybe<MentorGroupMembership>
  group_sub_used?: Maybe<SchoolGroupSubscription>
  id?: Maybe<Scalars["Int"]["output"]>
  last_activity_time?: Maybe<Scalars["DateTime"]["output"]>
  meta: NodeMeta
  path?: Maybe<Path>
  path_id?: Maybe<Scalars["Int"]["output"]>
  registration_payment?: Maybe<RegistrationPayment>
  resource?: Maybe<Resource>
  role?: Maybe<Scalars["Int"]["output"]>
  school_sub_used?: Maybe<SchoolSubscription>
  send_discussion_notifications?: Maybe<Scalars["Boolean"]["output"]>
  send_due_notifications?: Maybe<Scalars["Boolean"]["output"]>
  user?: Maybe<User>
  user_id?: Maybe<Scalars["Int"]["output"]>
}

/** A connection to a list of items. */
export type RegistrationConnection = {
  __typename?: "RegistrationConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RegistrationEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total?: Maybe<Scalars["Int"]["output"]>
}

/** An edge in a connection. */
export type RegistrationEdge = {
  __typename?: "RegistrationEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<Registration>
}

export type RegistrationMeta = {
  __typename?: "RegistrationMeta"
  editor_registration_count?: Maybe<Scalars["Int"]["output"]>
  facilitator_registration_count?: Maybe<Scalars["Int"]["output"]>
  student_registration_count?: Maybe<Scalars["Int"]["output"]>
  teacher_registration_count?: Maybe<Scalars["Int"]["output"]>
  total_registration_count?: Maybe<Scalars["Int"]["output"]>
}

/** Registration order data */
export type RegistrationOrderData = {
  coupon_code?: InputMaybe<Scalars["String"]["input"]>
  invitation_code?: InputMaybe<Scalars["String"]["input"]>
  offering_id: Scalars["Int"]["input"]
  use_group_subscription_id?: InputMaybe<Scalars["Int"]["input"]>
  use_subscription_plan_id?: InputMaybe<Scalars["Int"]["input"]>
}

/** A Pathwright Registration Payment */
export type RegistrationPayment = {
  __typename?: "RegistrationPayment"
  group_sub_used?: Maybe<SchoolGroupSubscription>
  id?: Maybe<Scalars["Int"]["output"]>
  payment?: Maybe<Payment>
  school_sub_used?: Maybe<SchoolSubscription>
}

export enum RegistrationRoleEnum {
  Editor = "editor",
  Moderator = "moderator",
  Observer = "observer",
  Student = "student",
  Teacher = "teacher"
}

export type RelativeTime = {
  days?: InputMaybe<Scalars["Float"]["input"]>
  hours?: InputMaybe<Scalars["Float"]["input"]>
  minutes?: InputMaybe<Scalars["Float"]["input"]>
}

export type ReportInput = {
  /** Issue a non-default expiry report link. Pass null for non-expiring. By default a report link is set to expire 1 day from date issued. NOTE: only available to Pathwright admin. */
  exp?: InputMaybe<RelativeTime>
  id_type?: InputMaybe<Scalars["Int"]["input"]>
  offering_id?: InputMaybe<Scalars["Int"]["input"]>
  resource_id?: InputMaybe<Scalars["Int"]["input"]>
  schedule_id?: InputMaybe<Scalars["Int"]["input"]>
  store_id?: InputMaybe<Scalars["Int"]["input"]>
}

/** A Pathwright Resource */
export type Resource = {
  __typename?: "Resource"
  authors?: Maybe<AuthorConnection>
  background_image?: Maybe<Scalars["String"]["output"]>
  background_luminance?: Maybe<Scalars["Float"]["output"]>
  background_overlay?: Maybe<Scalars["String"]["output"]>
  categories?: Maybe<CategoryConnection>
  certificate_generator_class?: Maybe<Scalars["String"]["output"]>
  certificate_json?: Maybe<Scalars["JSON"]["output"]>
  cohorts_status_meta?: Maybe<CohortsStatusMeta>
  created?: Maybe<Scalars["DateTime"]["output"]>
  created_by_id?: Maybe<Scalars["Int"]["output"]>
  curriculum_group?: Maybe<Group>
  description?: Maybe<Scalars["String"]["output"]>
  discussion_enabled?: Maybe<Scalars["Boolean"]["output"]>
  group?: Maybe<Group>
  groups?: Maybe<GroupConnection>
  has_certificate?: Maybe<Scalars["Boolean"]["output"]>
  highest_public_offering_price?: Maybe<Scalars["Float"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  image?: Maybe<Scalars["String"]["output"]>
  image_raw?: Maybe<Scalars["String"]["output"]>
  last_published_dtime?: Maybe<Scalars["DateTime"]["output"]>
  license_offerings?: Maybe<LicenseOfferingConnection>
  licensed_from_resource_id?: Maybe<Scalars["Int"]["output"]>
  licensed_from_school_id?: Maybe<Scalars["Int"]["output"]>
  lowest_public_offering_price?: Maybe<Scalars["Float"]["output"]>
  /** In the future, ResourceMeta will simply be NodeMeta, only consisting of NodeMeta fields. */
  meta?: Maybe<ResourceMeta>
  name?: Maybe<Scalars["String"]["output"]>
  publisher?: Maybe<Publisher>
  registration_meta?: Maybe<RegistrationMeta>
  resource_type?: Maybe<Scalars["Int"]["output"]>
  school?: Maybe<School>
  school_licenses?: Maybe<ResourceLicenseConnection>
  short_description?: Maybe<Scalars["String"]["output"]>
  slug?: Maybe<Scalars["String"]["output"]>
  trailer_embed_code?: Maybe<Scalars["String"]["output"]>
  visibility?: Maybe<Scalars["Int"]["output"]>
  visibility_level_override?: Maybe<Scalars["Int"]["output"]>
}

/** A Pathwright Resource */
export type ResourceAuthorsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
}

/** A Pathwright Resource */
export type ResourceBackground_ImageArgs = {
  blur?: InputMaybe<Scalars["Int"]["input"]>
  crop?: InputMaybe<Crop>
  dpr?: InputMaybe<Scalars["Int"]["input"]>
  fit?: InputMaybe<Fit>
  height?: InputMaybe<Scalars["Int"]["input"]>
  width?: InputMaybe<Scalars["Int"]["input"]>
}

/** A Pathwright Resource */
export type ResourceCategoriesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
}

/** A Pathwright Resource */
export type ResourceGroupArgs = {
  id?: InputMaybe<Scalars["Int"]["input"]>
  is_source?: InputMaybe<Scalars["Boolean"]["input"]>
  parent_group_id?: InputMaybe<Scalars["Int"]["input"]>
  type?: InputMaybe<GroupType>
}

/** A Pathwright Resource */
export type ResourceGroupsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  assign_config?: InputMaybe<AssignConfig>
  first: Scalars["Int"]["input"]
  include_curriculum?: InputMaybe<Scalars["Boolean"]["input"]>
  is_master?: InputMaybe<Scalars["Boolean"]["input"]>
  parent_group_id?: InputMaybe<IdFilter>
  resource_id?: InputMaybe<Scalars["Int"]["input"]>
  role?: InputMaybe<RoleFilter>
  search?: InputMaybe<Scalars["String"]["input"]>
  status?: InputMaybe<GroupStatus>
  visibility?: InputMaybe<GroupVisibility>
  visibility_filter?: InputMaybe<GroupVisibilityFilter>
}

/** A Pathwright Resource */
export type ResourceImageArgs = {
  blur?: InputMaybe<Scalars["Int"]["input"]>
  crop?: InputMaybe<Crop>
  dpr?: InputMaybe<Scalars["Int"]["input"]>
  fit?: InputMaybe<Fit>
  height?: InputMaybe<Scalars["Int"]["input"]>
  width?: InputMaybe<Scalars["Int"]["input"]>
}

/** A Pathwright Resource */
export type ResourceLicense_OfferingsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  licensing_mode?: InputMaybe<LicenseOfferingLicensingMode>
}

/** A Pathwright Resource */
export type ResourceSchool_LicensesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  licensee_school_id: Scalars["Int"]["input"]
}

/** A connection to a list of items. */
export type ResourceConnection = {
  __typename?: "ResourceConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ResourceEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total?: Maybe<Scalars["Int"]["output"]>
}

/** An edge in a connection. */
export type ResourceEdge = {
  __typename?: "ResourceEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<Resource>
}

/** A resource license */
export type ResourceLicense = {
  __typename?: "ResourceLicense"
  expiration_dtime?: Maybe<Scalars["DateTime"]["output"]>
  group?: Maybe<Group>
  id?: Maybe<Scalars["Int"]["output"]>
  license_offering?: Maybe<LicenseOffering>
  school?: Maybe<LicenseeSchool>
  seat_count?: Maybe<Scalars["Int"]["output"]>
  seats_available?: Maybe<Scalars["Int"]["output"]>
  seats_filled?: Maybe<Scalars["Int"]["output"]>
}

/** A connection to a list of items. */
export type ResourceLicenseConnection = {
  __typename?: "ResourceLicenseConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ResourceLicenseEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total?: Maybe<Scalars["Int"]["output"]>
}

/** An edge in a connection. */
export type ResourceLicenseEdge = {
  __typename?: "ResourceLicenseEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<ResourceLicense>
}

export type ResourceMeta = {
  __typename?: "ResourceMeta"
  description?: Maybe<Scalars["String"]["output"]>
  /** @deprecated Use Resource.registration_meta instead! */
  editor_registration_count?: Maybe<Scalars["Int"]["output"]>
  /** @deprecated Use Resource.registration_meta instead! */
  facilitator_registration_count?: Maybe<Scalars["Int"]["output"]>
  icon?: Maybe<Scalars["String"]["output"]>
  image?: Maybe<Scalars["String"]["output"]>
  key: Scalars["String"]["output"]
  /** @deprecated Use Resource.registration_meta instead! */
  student_registration_count?: Maybe<Scalars["Int"]["output"]>
  subtitle?: Maybe<Scalars["String"]["output"]>
  /** @deprecated Use Resource.registration_meta instead! */
  teacher_registration_count?: Maybe<Scalars["Int"]["output"]>
  title?: Maybe<Scalars["String"]["output"]>
  /** @deprecated Use Resource.registration_meta instead! */
  total_registration_count?: Maybe<Scalars["Int"]["output"]>
  type: Scalars["String"]["output"]
  url?: Maybe<Scalars["String"]["output"]>
}

export type ResourceStats = {
  __typename?: "ResourceStats"
  active?: Maybe<Scalars["Int"]["output"]>
  never_active?: Maybe<Scalars["Int"]["output"]>
  private?: Maybe<Scalars["Int"]["output"]>
  public?: Maybe<Scalars["Int"]["output"]>
  total?: Maybe<Scalars["Int"]["output"]>
}

export enum ResourceType {
  Collection = "collection",
  Course = "course",
  Lesson = "lesson"
}

export type ResourceTypeFilter = {
  eq?: InputMaybe<ResourceType>
  gt?: InputMaybe<ResourceType>
  gte?: InputMaybe<ResourceType>
  in?: InputMaybe<Array<InputMaybe<ResourceType>>>
  lt?: InputMaybe<ResourceType>
  lte?: InputMaybe<ResourceType>
  neq?: InputMaybe<ResourceType>
  nin?: InputMaybe<Array<InputMaybe<ResourceType>>>
  op?: InputMaybe<Scalars["String"]["input"]>
}

export type ResourceVisibility = {
  eq?: InputMaybe<ResourceVisibilityEnum>
  gt?: InputMaybe<ResourceVisibilityEnum>
  gte?: InputMaybe<ResourceVisibilityEnum>
  in?: InputMaybe<Array<InputMaybe<ResourceVisibilityEnum>>>
  lt?: InputMaybe<ResourceVisibilityEnum>
  lte?: InputMaybe<ResourceVisibilityEnum>
  neq?: InputMaybe<ResourceVisibilityEnum>
  nin?: InputMaybe<Array<InputMaybe<ResourceVisibilityEnum>>>
  op?: InputMaybe<Scalars["String"]["input"]>
}

export enum ResourceVisibilityEnum {
  Inactive = "inactive",
  /** Resources visible to members */
  Members = "members",
  Private = "private",
  /** Resources visible to anyone */
  Public = "public"
}

/** Pathwright discussion response */
export type Response = {
  __typename?: "Response"
  attachments?: Maybe<Scalars["JSON"]["output"]>
  body?: Maybe<Scalars["String"]["output"]>
  created_time?: Maybe<Scalars["DateTime"]["output"]>
  depth?: Maybe<Scalars["Int"]["output"]>
  discussion?: Maybe<Discussion>
  id?: Maybe<Scalars["Int"]["output"]>
  likes?: Maybe<Scalars["Int"]["output"]>
  modified_time?: Maybe<Scalars["DateTime"]["output"]>
  parent_id?: Maybe<Scalars["Int"]["output"]>
  parent_response?: Maybe<Response>
  responses?: Maybe<ResponseConnection>
  user?: Maybe<User>
  user_liked?: Maybe<Scalars["Boolean"]["output"]>
}

/** Pathwright discussion response */
export type ResponseResponsesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
}

/** A connection to a list of items. */
export type ResponseConnection = {
  __typename?: "ResponseConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ResponseEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total?: Maybe<Scalars["Int"]["output"]>
}

/** An edge in a connection. */
export type ResponseEdge = {
  __typename?: "ResponseEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<Response>
}

export enum Role {
  CohortCourseEditor = "cohort_course_editor",
  CohortLearner = "cohort_learner",
  CohortModerator = "cohort_moderator",
  CohortObserver = "cohort_observer",
  CohortTeacher = "cohort_teacher",
  GroupAdmin = "group_admin",
  GroupMember = "group_member",
  GroupModerator = "group_moderator",
  SchoolAdmin = "school_admin",
  SchoolLibraryEditor = "school_library_editor",
  SchoolMember = "school_member",
  SchoolStaff = "school_staff"
}

export type RoleFilter = {
  eq?: InputMaybe<RegistrationRoleEnum>
  gt?: InputMaybe<RegistrationRoleEnum>
  gte?: InputMaybe<RegistrationRoleEnum>
  in?: InputMaybe<Array<InputMaybe<RegistrationRoleEnum>>>
  lt?: InputMaybe<RegistrationRoleEnum>
  lte?: InputMaybe<RegistrationRoleEnum>
  neq?: InputMaybe<RegistrationRoleEnum>
  nin?: InputMaybe<Array<InputMaybe<RegistrationRoleEnum>>>
  op?: InputMaybe<Scalars["String"]["input"]>
}

export type RoleInContext = {
  /** Whether an item with this type of role should be excluded from results. */
  exclude?: InputMaybe<Scalars["Boolean"]["input"]>
  /** The type of the context in which the user has this role (defaults to school). */
  type: RoleInType
  /** The id of the type. */
  type_id?: InputMaybe<Scalars["Int"]["input"]>
}

export enum RoleInType {
  Cohort = "cohort",
  Group = "group",
  School = "school"
}

export type ScheduleDate = {
  __typename?: "ScheduleDate"
  behavior: ScheduleDateBehavior
  date?: Maybe<Scalars["DateTime"]["output"]>
}

export enum ScheduleDateBehavior {
  Lock = "lock",
  Passthrough = "passthrough"
}

export type ScheduleItemContext = {
  cohort_id?: InputMaybe<Scalars["Int"]["input"]>
  path_id?: InputMaybe<Scalars["Int"]["input"]>
  /** The id source ID of the path item to be scheduled. */
  source_id: Scalars["Int"]["input"]
}

/** A Pathwright School */
export type School = {
  __typename?: "School"
  about?: Maybe<Scalars["String"]["output"]>
  /** @deprecated Activity should be queried through root for performance reasons */
  activity?: Maybe<ActivityConnection>
  /** @deprecated Activity stats should be queried through root for performance reasons */
  activity_stats?: Maybe<ActivityStats>
  /** Wheter to prompt users to enter a password when signing up. */
  always_prompt_for_password?: Maybe<Scalars["Boolean"]["output"]>
  author?: Maybe<Author>
  authors?: Maybe<AuthorConnection>
  banner?: Maybe<Banner>
  banners?: Maybe<BannerConnection>
  billing_model?: Maybe<Scalars["Int"]["output"]>
  billing_subscription?: Maybe<SchoolBillingSubscription>
  categories?: Maybe<CategoryConnection>
  category?: Maybe<Category>
  consent_description?: Maybe<Scalars["String"]["output"]>
  coupon?: Maybe<Coupon>
  created?: Maybe<Scalars["DateTime"]["output"]>
  currency_code?: Maybe<Scalars["String"]["output"]>
  curriculum_plans?: Maybe<SchoolCurriculumPlanConnection>
  curriculum_subscription?: Maybe<CurriculumSubscription>
  curriculum_subscriptions?: Maybe<CurriculumSubscriptionConnection>
  email?: Maybe<Scalars["String"]["output"]>
  features: SchoolFeatures
  gift_subscription?: Maybe<SchoolGiftSubscription>
  grant_access_upon_authentication?: Maybe<Scalars["Boolean"]["output"]>
  has_certificate?: Maybe<Scalars["Boolean"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  ietf_language_tag?: Maybe<Scalars["String"]["output"]>
  image?: Maybe<Scalars["String"]["output"]>
  is_activated?: Maybe<Scalars["Boolean"]["output"]>
  is_curriculum_enabled?: Maybe<Scalars["Boolean"]["output"]>
  is_curriculum_subscriber?: Maybe<Scalars["Boolean"]["output"]>
  license_offering?: Maybe<LicenseOffering>
  logo?: Maybe<Scalars["String"]["output"]>
  members?: Maybe<MembershipConnection>
  membership_stats?: Maybe<MembershipStats>
  mentor_group?: Maybe<MentorGroup>
  mentor_group_stats?: Maybe<MentorGroupStats>
  mentor_groups?: Maybe<MentorGroupConnection>
  name?: Maybe<Scalars["String"]["output"]>
  owner?: Maybe<User>
  owner_id?: Maybe<Scalars["Int"]["output"]>
  page?: Maybe<Page>
  pages?: Maybe<PageConnection>
  pathItems?: Maybe<PathItemConnection>
  pathwright_login_enabled?: Maybe<Scalars["Boolean"]["output"]>
  payment_requires_zip_code?: Maybe<Scalars["Boolean"]["output"]>
  platform_version?: Maybe<Scalars["Float"]["output"]>
  primary_domain?: Maybe<Scalars["String"]["output"]>
  profile?: Maybe<SchoolProfile>
  publishers?: Maybe<PublisherConnection>
  registrations?: Maybe<RegistrationConnection>
  resource?: Maybe<Resource>
  resource_license?: Maybe<ResourceLicense>
  resource_licensing_enabled?: Maybe<Scalars["Boolean"]["output"]>
  resource_stats?: Maybe<ResourceStats>
  resources?: Maybe<ResourceConnection>
  school_billing_invoice?: Maybe<SchoolBillingInvoice>
  school_billing_invoices?: Maybe<SchoolBillingInvoiceConnection>
  show_stripe_reconnect?: Maybe<Scalars["Boolean"]["output"]>
  sso_link?: Maybe<Scalars["String"]["output"]>
  storeLinks?: Maybe<StoreLinkConnection>
  stripe_access_token?: Maybe<Scalars["String"]["output"]>
  stripe_publishable_api_key?: Maybe<Scalars["String"]["output"]>
  subdomain?: Maybe<Scalars["String"]["output"]>
  subscription?: Maybe<Subscription>
  subscription_plans?: Maybe<SchoolSubscriptionPlanConnection>
  subscriptions?: Maybe<SubscriptionConnection>
  theme?: Maybe<Theme>
  /** Fakes a school invoice create. */
  upcoming_school_billing_invoice?: Maybe<SchoolBillingInvoice>
  url?: Maybe<Scalars["String"]["output"]>
  website?: Maybe<Scalars["String"]["output"]>
}

/** A Pathwright School */
export type SchoolActivityArgs = {
  action_type?: InputMaybe<ActionTypeFilter>
  after?: InputMaybe<Scalars["String"]["input"]>
  date?: InputMaybe<DateFilter>
  first: Scalars["Int"]["input"]
  group_id?: InputMaybe<Scalars["Int"]["input"]>
  interest_level?: InputMaybe<InterestLevel>
  resource_id?: InputMaybe<Scalars["Int"]["input"]>
  step_id?: InputMaybe<Scalars["Int"]["input"]>
  user_id?: InputMaybe<Scalars["Int"]["input"]>
}

/** A Pathwright School */
export type SchoolAuthorArgs = {
  id?: InputMaybe<Scalars["Int"]["input"]>
  slug?: InputMaybe<Scalars["String"]["input"]>
}

/** A Pathwright School */
export type SchoolAuthorsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  ids?: InputMaybe<IdFilter>
  manage?: InputMaybe<Scalars["Boolean"]["input"]>
  resource_author_ids?: InputMaybe<IdFilter>
  resource_author_names?: InputMaybe<StringFilter>
  resource_category_ids?: InputMaybe<IdFilter>
  resource_category_names?: InputMaybe<StringFilter>
  resource_search?: InputMaybe<Scalars["String"]["input"]>
  search?: InputMaybe<Scalars["String"]["input"]>
}

/** A Pathwright School */
export type SchoolBannerArgs = {
  id?: InputMaybe<Scalars["Int"]["input"]>
}

/** A Pathwright School */
export type SchoolBannersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  manage?: InputMaybe<Scalars["Boolean"]["input"]>
}

/** A Pathwright School */
export type SchoolCategoriesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  ids?: InputMaybe<IdFilter>
  manage?: InputMaybe<Scalars["Boolean"]["input"]>
  resource_author_ids?: InputMaybe<IdFilter>
  resource_author_names?: InputMaybe<StringFilter>
  resource_category_ids?: InputMaybe<IdFilter>
  resource_category_names?: InputMaybe<StringFilter>
  resource_search?: InputMaybe<Scalars["String"]["input"]>
  search?: InputMaybe<Scalars["String"]["input"]>
}

/** A Pathwright School */
export type SchoolCategoryArgs = {
  id?: InputMaybe<Scalars["Int"]["input"]>
  slug?: InputMaybe<Scalars["String"]["input"]>
}

/** A Pathwright School */
export type SchoolCouponArgs = {
  code: Scalars["String"]["input"]
  cohort_id?: InputMaybe<Scalars["Int"]["input"]>
  resource_id?: InputMaybe<Scalars["Int"]["input"]>
  resource_license_offering_id?: InputMaybe<Scalars["Int"]["input"]>
  school_subscription_plan_id?: InputMaybe<Scalars["Int"]["input"]>
  seat_count?: InputMaybe<Scalars["Int"]["input"]>
}

/** A Pathwright School */
export type SchoolCurriculum_PlansArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  visibility_code?: InputMaybe<Scalars["String"]["input"]>
  visibility_code_filter?: InputMaybe<StringFilter>
}

/** A Pathwright School */
export type SchoolCurriculum_SubscriptionArgs = {
  id: Scalars["Int"]["input"]
}

/** A Pathwright School */
export type SchoolCurriculum_SubscriptionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  licensee_id?: InputMaybe<IdFilter>
  licensor_id?: InputMaybe<IdFilter>
}

/** A Pathwright School */
export type SchoolGift_SubscriptionArgs = {
  code?: InputMaybe<Scalars["String"]["input"]>
  id?: InputMaybe<Scalars["Int"]["input"]>
}

/** A Pathwright School */
export type SchoolImageArgs = {
  blur?: InputMaybe<Scalars["Int"]["input"]>
  crop?: InputMaybe<Crop>
  dpr?: InputMaybe<Scalars["Int"]["input"]>
  fit?: InputMaybe<Fit>
  height?: InputMaybe<Scalars["Int"]["input"]>
  width?: InputMaybe<Scalars["Int"]["input"]>
}

/** A Pathwright School */
export type SchoolLicense_OfferingArgs = {
  id: Scalars["Int"]["input"]
  licensing_mode?: InputMaybe<LicenseOfferingLicensingMode>
}

/** A Pathwright School */
export type SchoolLogoArgs = {
  blur?: InputMaybe<Scalars["Int"]["input"]>
  crop?: InputMaybe<Crop>
  dpr?: InputMaybe<Scalars["Int"]["input"]>
  fit?: InputMaybe<Fit>
  height?: InputMaybe<Scalars["Int"]["input"]>
  width?: InputMaybe<Scalars["Int"]["input"]>
}

/** A Pathwright School */
export type SchoolMembersArgs = {
  access_revoked_date_filter?: InputMaybe<Array<InputMaybe<DateFilter>>>
  after?: InputMaybe<Scalars["String"]["input"]>
  archived_date_filter?: InputMaybe<Array<InputMaybe<DateFilter>>>
  daysInactive?: InputMaybe<Scalars["Int"]["input"]>
  first: Scalars["Int"]["input"]
  global?: InputMaybe<Scalars["Boolean"]["input"]>
  hasAccess?: InputMaybe<Scalars["Boolean"]["input"]>
  orderBy?: InputMaybe<Scalars["String"]["input"]>
  role_filter?: InputMaybe<Array<InputMaybe<MembershipRoleFilter>>>
  search?: InputMaybe<Scalars["String"]["input"]>
}

/** A Pathwright School */
export type SchoolMentor_GroupArgs = {
  id?: InputMaybe<Scalars["Int"]["input"]>
  slug?: InputMaybe<Scalars["String"]["input"]>
}

/** A Pathwright School */
export type SchoolMentor_GroupsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  descendant_of_parent_id?: InputMaybe<EquivalenceIdFilter>
  first: Scalars["Int"]["input"]
  id?: InputMaybe<IdFilter>
  parent_id?: InputMaybe<IdFilter>
  search?: InputMaybe<Scalars["String"]["input"]>
  seat_limit?: InputMaybe<Scalars["Int"]["input"]>
  seat_limit_filter?: InputMaybe<IntFilter>
  type?: InputMaybe<MentorGroupType>
}

/** A Pathwright School */
export type SchoolPageArgs = {
  id?: InputMaybe<Scalars["Int"]["input"]>
  is_live?: InputMaybe<Scalars["Boolean"]["input"]>
  page_type?: InputMaybe<Scalars["Int"]["input"]>
  slug?: InputMaybe<Scalars["String"]["input"]>
}

/** A Pathwright School */
export type SchoolPagesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
}

/** A Pathwright School */
export type SchoolPathItemsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  cohort_id?: InputMaybe<Scalars["Int"]["input"]>
  due?: InputMaybe<DateFilter>
  exclude_default?: InputMaybe<Scalars["Boolean"]["input"]>
  first: Scalars["Int"]["input"]
  group_id?: InputMaybe<Scalars["Int"]["input"]>
  is_complete?: InputMaybe<Scalars["Boolean"]["input"]>
  is_required?: InputMaybe<Scalars["Boolean"]["input"]>
  is_reviewed?: InputMaybe<Scalars["Boolean"]["input"]>
  is_skipped?: InputMaybe<Scalars["Boolean"]["input"]>
  lesson_source_id?: InputMaybe<Scalars["Int"]["input"]>
  needs_attention?: InputMaybe<Scalars["Boolean"]["input"]>
  needs_review?: InputMaybe<Scalars["Boolean"]["input"]>
  next?: InputMaybe<Scalars["Boolean"]["input"]>
  order_by?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
  path_id?: InputMaybe<Scalars["Int"]["input"]>
  registration_role_filter?: InputMaybe<RoleFilter>
  resource_id?: InputMaybe<Scalars["Int"]["input"]>
  reviewable?: InputMaybe<Scalars["Boolean"]["input"]>
  root_level?: InputMaybe<Scalars["Boolean"]["input"]>
  source_id?: InputMaybe<Scalars["Int"]["input"]>
  starts?: InputMaybe<DateFilter>
  step_id?: InputMaybe<Scalars["Int"]["input"]>
  type?: InputMaybe<PathItemTypes>
  upcoming?: InputMaybe<Scalars["Boolean"]["input"]>
  user_id?: InputMaybe<Scalars["Int"]["input"]>
  with_dates?: InputMaybe<Scalars["Boolean"]["input"]>
}

/** A Pathwright School */
export type SchoolPublishersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
}

/** A Pathwright School */
export type SchoolRegistrationsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  completion_state?: InputMaybe<CompletionStateFilter>
  created?: InputMaybe<DateFilter>
  first: Scalars["Int"]["input"]
  has_parent_registration?: InputMaybe<Scalars["Boolean"]["input"]>
  last_active?: InputMaybe<DateFilter>
  orderBy?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
  parent_cohort_filter?: InputMaybe<IdFilter>
  progress_filter?: InputMaybe<ProgressFilter>
  role?: InputMaybe<RegistrationRoleEnum>
  role_filter?: InputMaybe<RoleFilter>
  search?: InputMaybe<Scalars["String"]["input"]>
  send_discussion_notifications?: InputMaybe<Scalars["Boolean"]["input"]>
  send_due_notifications?: InputMaybe<Scalars["Boolean"]["input"]>
}

/** A Pathwright School */
export type SchoolResourceArgs = {
  id?: InputMaybe<Scalars["Int"]["input"]>
  name?: InputMaybe<Scalars["String"]["input"]>
  slug?: InputMaybe<Scalars["String"]["input"]>
}

/** A Pathwright School */
export type SchoolResource_LicenseArgs = {
  id?: InputMaybe<Scalars["Int"]["input"]>
  license_offering_id?: InputMaybe<Scalars["Int"]["input"]>
}

/** A Pathwright School */
export type SchoolResourcesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  assign_config?: InputMaybe<AssignConfig>
  author_id?: InputMaybe<Scalars["Int"]["input"]>
  author_ids?: InputMaybe<IdFilter>
  author_names?: InputMaybe<StringFilter>
  author_search?: InputMaybe<Scalars["String"]["input"]>
  category_id?: InputMaybe<Scalars["Int"]["input"]>
  category_ids?: InputMaybe<IdFilter>
  category_names?: InputMaybe<StringFilter>
  category_search?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  has_certificate?: InputMaybe<Scalars["Boolean"]["input"]>
  id?: InputMaybe<IdFilter>
  license_offering_licensing_mode?: InputMaybe<LicenseOfferingLicensingMode>
  licensed_from_school?: InputMaybe<IdFilter>
  orderBy?: InputMaybe<Scalars["String"]["input"]>
  publisher_id?: InputMaybe<Scalars["Int"]["input"]>
  publisher_ids?: InputMaybe<IdFilter>
  resource_types?: InputMaybe<ResourceTypeFilter>
  role?: InputMaybe<RoleFilter>
  search?: InputMaybe<Scalars["String"]["input"]>
  user_registered?: InputMaybe<IdFilter>
  visibility?: InputMaybe<ResourceVisibility>
}

/** A Pathwright School */
export type SchoolSchool_Billing_InvoiceArgs = {
  id: Scalars["Int"]["input"]
}

/** A Pathwright School */
export type SchoolSchool_Billing_InvoicesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
}

/** A Pathwright School */
export type SchoolSso_LinkArgs = {
  pathname?: InputMaybe<Scalars["String"]["input"]>
}

/** A Pathwright School */
export type SchoolStoreLinksArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  licensor_id?: InputMaybe<IdFilter>
}

/** A Pathwright School */
export type SchoolSubscriptionArgs = {
  id: Scalars["Int"]["input"]
}

/** A Pathwright School */
export type SchoolSubscription_PlansArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  giftable?: InputMaybe<Scalars["Boolean"]["input"]>
  ids?: InputMaybe<IdFilter>
  ignore_visibility?: InputMaybe<Scalars["Boolean"]["input"]>
  interval?: InputMaybe<SubscriptionIntervalType>
  interval_filter?: InputMaybe<SubscriptionIntervalFilter>
  visibility_code?: InputMaybe<Scalars["String"]["input"]>
  visibility_code_filter?: InputMaybe<StringFilter>
}

/** A Pathwright School */
export type SchoolSubscriptionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  payment_intent_needing_action_id?: InputMaybe<IdFilter>
}

/** A Pathwright School */
export type SchoolUrlArgs = {
  pathname?: InputMaybe<Scalars["String"]["input"]>
}

/** Pathwright School Account data */
export type SchoolAcount = {
  __typename?: "SchoolAcount"
  billing_model?: Maybe<Scalars["Int"]["output"]>
  billing_plan_set?: Maybe<SchoolBillingPlanSet>
  billing_subscription?: Maybe<SchoolBillingSubscription>
  curriculum_subscription?: Maybe<CurriculumSubscription>
  id?: Maybe<Scalars["Int"]["output"]>
  is_activated?: Maybe<Scalars["Boolean"]["output"]>
  is_activated_via_override?: Maybe<Scalars["Boolean"]["output"]>
  is_on_legacy_plan?: Maybe<Scalars["Boolean"]["output"]>
  scholarship?: Maybe<SchoolBillingScholarship>
}

/** Pathwright School Account data */
export type SchoolAcountBilling_Plan_SetArgs = {
  default?: InputMaybe<Scalars["Boolean"]["input"]>
}

/** School Billing Block used for invoicing */
export type SchoolBillingBlock = {
  __typename?: "SchoolBillingBlock"
  block_size?: Maybe<Scalars["Int"]["output"]>
  block_type?: Maybe<Scalars["Int"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  price?: Maybe<Scalars["Float"]["output"]>
}

/** A connection to a list of items. */
export type SchoolBillingBlockConnection = {
  __typename?: "SchoolBillingBlockConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SchoolBillingBlockEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total?: Maybe<Scalars["Int"]["output"]>
}

/** An edge in a connection. */
export type SchoolBillingBlockEdge = {
  __typename?: "SchoolBillingBlockEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<SchoolBillingBlock>
}

/** Pathwright School Billing Invoice */
export type SchoolBillingInvoice = {
  __typename?: "SchoolBillingInvoice"
  child_subscriptions?: Maybe<Scalars["JSON"]["output"]>
  cycle_end?: Maybe<Scalars["DateTime"]["output"]>
  cycle_start?: Maybe<Scalars["DateTime"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  invoice_json?: Maybe<Scalars["JSON"]["output"]>
  invoiced_date?: Maybe<Scalars["DateTime"]["output"]>
  is_initial_prepaid_invoice?: Maybe<Scalars["Boolean"]["output"]>
  is_overdue?: Maybe<Scalars["Boolean"]["output"]>
  parent_subscription?: Maybe<Scalars["JSON"]["output"]>
  payment?: Maybe<Scalars["JSON"]["output"]>
  stripe_invoice_id?: Maybe<Scalars["String"]["output"]>
  stripe_invoice_status?: Maybe<Scalars["String"]["output"]>
  stripe_next_payment_attempt?: Maybe<Scalars["DateTime"]["output"]>
  total_amount_billed?: Maybe<Scalars["Float"]["output"]>
  url?: Maybe<Scalars["String"]["output"]>
}

/** A connection to a list of items. */
export type SchoolBillingInvoiceConnection = {
  __typename?: "SchoolBillingInvoiceConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SchoolBillingInvoiceEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total?: Maybe<Scalars["Int"]["output"]>
}

/** An edge in a connection. */
export type SchoolBillingInvoiceEdge = {
  __typename?: "SchoolBillingInvoiceEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<SchoolBillingInvoice>
}

/** Pathwright School Subscription Plans Data */
export type SchoolBillingPlan = {
  __typename?: "SchoolBillingPlan"
  amount?: Maybe<Scalars["Float"]["output"]>
  billing_blocks?: Maybe<SchoolBillingBlockConnection>
  cohorts_included?: Maybe<Scalars["Int"]["output"]>
  color?: Maybe<Scalars["String"]["output"]>
  description?: Maybe<Scalars["String"]["output"]>
  features?: Maybe<Array<Maybe<SchoolBillingPlanFeature>>>
  fee_per_additional_learner?: Maybe<Scalars["Float"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  image?: Maybe<Scalars["String"]["output"]>
  learner_counting_method?: Maybe<Scalars["Int"]["output"]>
  learners_included?: Maybe<Scalars["Int"]["output"]>
  mentor_groups_included?: Maybe<Scalars["Int"]["output"]>
  name?: Maybe<Scalars["String"]["output"]>
  order?: Maybe<Scalars["Int"]["output"]>
  plan_derived_from?: Maybe<SchoolBillingPlan>
  scholarships_available?: Maybe<Scalars["Boolean"]["output"]>
  should_contact_for_pricing?: Maybe<Scalars["Boolean"]["output"]>
  staff_included?: Maybe<Scalars["Int"]["output"]>
  visibility?: Maybe<Visibility>
}

/** Pathwright School Subscription Plans Data */
export type SchoolBillingPlanBilling_BlocksArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  block_type?: InputMaybe<Scalars["Int"]["input"]>
  first: Scalars["Int"]["input"]
}

/** School Billing Plan Feature Data */
export type SchoolBillingPlanFeature = {
  __typename?: "SchoolBillingPlanFeature"
  description?: Maybe<Scalars["String"]["output"]>
  feature_type?: Maybe<Scalars["Int"]["output"]>
  icon?: Maybe<Scalars["String"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  key?: Maybe<Scalars["String"]["output"]>
  link?: Maybe<Scalars["String"]["output"]>
  name?: Maybe<Scalars["String"]["output"]>
  order?: Maybe<Scalars["Int"]["output"]>
  platform_version?: Maybe<Scalars["Float"]["output"]>
}

/** School Billing Plan Set Data */
export type SchoolBillingPlanSet = {
  __typename?: "SchoolBillingPlanSet"
  id?: Maybe<Scalars["Int"]["output"]>
  is_default?: Maybe<Scalars["Boolean"]["output"]>
  name?: Maybe<Scalars["String"]["output"]>
  plans?: Maybe<Array<Maybe<SchoolBillingPlan>>>
}

/** Pathwright School Billing Scholarship */
export type SchoolBillingScholarship = {
  __typename?: "SchoolBillingScholarship"
  coupon?: Maybe<StripeCoupon>
  ends?: Maybe<Scalars["DateTime"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  interval?: Maybe<Interval>
  iterations?: Maybe<Scalars["Int"]["output"]>
  order?: Maybe<Scalars["Int"]["output"]>
  plan_id?: Maybe<Scalars["Int"]["output"]>
  starts?: Maybe<Scalars["DateTime"]["output"]>
}

/** Pathwright School Subscription Data */
export type SchoolBillingSubscription = {
  __typename?: "SchoolBillingSubscription"
  charge_type?: Maybe<SubscriptionChargeType>
  child_subscriptions?: Maybe<SchoolBillingSubscriptionConnection>
  extra_cohorts?: Maybe<Scalars["Int"]["output"]>
  extra_mentor_groups?: Maybe<Scalars["Int"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  interval?: Maybe<SubscriptionIntervalType>
  parent_subscription_id?: Maybe<Scalars["Int"]["output"]>
  plan?: Maybe<SchoolBillingPlan>
  school?: Maybe<School>
  school_id?: Maybe<Scalars["Int"]["output"]>
  subscription?: Maybe<Subscription>
}

/** Pathwright School Subscription Data */
export type SchoolBillingSubscriptionChild_SubscriptionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
}

/** A connection to a list of items. */
export type SchoolBillingSubscriptionConnection = {
  __typename?: "SchoolBillingSubscriptionConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SchoolBillingSubscriptionEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total?: Maybe<Scalars["Int"]["output"]>
}

/** An edge in a connection. */
export type SchoolBillingSubscriptionEdge = {
  __typename?: "SchoolBillingSubscriptionEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<SchoolBillingSubscription>
}

/** A connection to a list of items. */
export type SchoolConnection = {
  __typename?: "SchoolConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SchoolEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total?: Maybe<Scalars["Int"]["output"]>
}

/** A school licensing school curriculum subscription plan */
export type SchoolCurriculumPlan = {
  __typename?: "SchoolCurriculumPlan"
  amount?: Maybe<Scalars["Float"]["output"]>
  fee_boundary?: Maybe<Scalars["Int"]["output"]>
  fee_per_user?: Maybe<Scalars["Int"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  is_visible?: Maybe<Scalars["Boolean"]["output"]>
  licensor?: Maybe<School>
  lower_boundary?: Maybe<Scalars["Int"]["output"]>
  name?: Maybe<Scalars["String"]["output"]>
  plan_id?: Maybe<Scalars["Int"]["output"]>
  transaction_fee_percent?: Maybe<Scalars["Int"]["output"]>
  upper_boundary?: Maybe<Scalars["Int"]["output"]>
  visibility_code?: Maybe<Scalars["String"]["output"]>
}

/** A connection to a list of items. */
export type SchoolCurriculumPlanConnection = {
  __typename?: "SchoolCurriculumPlanConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SchoolCurriculumPlanEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total?: Maybe<Scalars["Int"]["output"]>
}

/** An edge in a connection. */
export type SchoolCurriculumPlanEdge = {
  __typename?: "SchoolCurriculumPlanEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<SchoolCurriculumPlan>
}

/** An edge in a connection. */
export type SchoolEdge = {
  __typename?: "SchoolEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<School>
}

/** A list of all features that the space may or may not have access to, with a value indicating whether or not the space has access */
export type SchoolFeatures = {
  __typename?: "SchoolFeatures"
  /** Allows users of a Space to use the Auth Request (via email) feature. */
  auth__login_option__email_link: Scalars["Boolean"]["output"]
  /** Allows users of a Space to use the Apple SSO feature. */
  auth__login_option__global_sso__apple: Scalars["Boolean"]["output"]
  /** Allows users of a Space to use the Google SSO feature. */
  auth__login_option__global_sso__google: Scalars["Boolean"]["output"]
  /** Allows users of a Space to use the Password login feature. */
  auth__login_option__password: Scalars["Boolean"]["output"]
  /** Allows a Space to use the Paths Auth feature. */
  auth__platform: Scalars["Boolean"]["output"]
  /** Allows cohort members to view activity of other members. */
  cohort__activity_feed: Scalars["Boolean"]["output"]
  /** Allows cohort members to view other members in the cohort community. */
  cohort__member_directory: Scalars["Boolean"]["output"]
  /** Allows a Space to use the Community Groups feature. */
  community__groups: Scalars["Boolean"]["output"]
  /** Allows a Space to use the Add to Path feature. */
  library__add_to_path: Scalars["Boolean"]["output"]
  /** Allows a Space to use the Collections feature. */
  library__collections: Scalars["Boolean"]["output"]
  /** Allows a Space to use sync a Path to paths.app. */
  paths_app__publish: Scalars["Boolean"]["output"]
  /** Allows a Space to use the Share Card feature. */
  school__share_card: Scalars["Boolean"]["output"]
}

export type SchoolGiftSubscription = {
  __typename?: "SchoolGiftSubscription"
  gift_code?: Maybe<Scalars["String"]["output"]>
  gift_message?: Maybe<Scalars["String"]["output"]>
  gifted_subscription_cycles?: Maybe<Scalars["Int"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  recipient_email?: Maybe<Scalars["String"]["output"]>
  recipient_email_sent_time?: Maybe<Scalars["DateTime"]["output"]>
  recipient_first_name?: Maybe<Scalars["String"]["output"]>
  recipient_last_name?: Maybe<Scalars["String"]["output"]>
  redeemed?: Maybe<Scalars["Boolean"]["output"]>
  redemption_link?: Maybe<Scalars["String"]["output"]>
  school_subscription_plan?: Maybe<SchoolSubscriptionPlan>
  sender?: Maybe<User>
}

/** A School Group Subscription */
export type SchoolGroupSubscription = {
  __typename?: "SchoolGroupSubscription"
  community_group?: Maybe<MentorGroup>
  id?: Maybe<Scalars["Int"]["output"]>
  price?: Maybe<StripePrice>
  school?: Maybe<School>
  subscription?: Maybe<Subscription>
  user?: Maybe<User>
}

/** A connection to a list of items. */
export type SchoolGroupSubscriptionConnection = {
  __typename?: "SchoolGroupSubscriptionConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SchoolGroupSubscriptionEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total?: Maybe<Scalars["Int"]["output"]>
}

/** An edge in a connection. */
export type SchoolGroupSubscriptionEdge = {
  __typename?: "SchoolGroupSubscriptionEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<SchoolGroupSubscription>
}

export type SchoolProfile = {
  __typename?: "SchoolProfile"
  id?: Maybe<Scalars["Int"]["output"]>
  plan_to_sell?: Maybe<Scalars["Boolean"]["output"]>
  school_type?: Maybe<SchoolType>
}

/** A School Subscription */
export type SchoolSubscription = {
  __typename?: "SchoolSubscription"
  id?: Maybe<Scalars["Int"]["output"]>
  school_subscription_plan?: Maybe<SchoolSubscriptionPlan>
  subscription?: Maybe<Subscription>
  subscription_plan?: Maybe<SubscriptionPlan>
  user?: Maybe<User>
}

export type SchoolSubscriptionDiscount = {
  __typename?: "SchoolSubscriptionDiscount"
  currency_display_discount_amount?: Maybe<Scalars["String"]["output"]>
  currency_display_discount_price?: Maybe<Scalars["String"]["output"]>
  discount_percent?: Maybe<Scalars["Float"]["output"]>
  discounted_price?: Maybe<Scalars["Float"]["output"]>
  discounted_subscription_cycles?: Maybe<Scalars["Int"]["output"]>
}

/** School subscription order data */
export type SchoolSubscriptionOrderData = {
  coupon_code?: InputMaybe<Scalars["String"]["input"]>
  plan_id: Scalars["Int"]["input"]
  plan_visibility_code?: InputMaybe<Scalars["String"]["input"]>
  redirect_to?: InputMaybe<Scalars["String"]["input"]>
  start_free_trial?: InputMaybe<Scalars["Boolean"]["input"]>
}

/** A School Subscription Plan */
export type SchoolSubscriptionPlan = {
  __typename?: "SchoolSubscriptionPlan"
  catalog_header?: Maybe<Scalars["String"]["output"]>
  details_html?: Maybe<Scalars["String"]["output"]>
  giftable_cycles?: Maybe<Array<Maybe<Scalars["Int"]["output"]>>>
  id?: Maybe<Scalars["Int"]["output"]>
  image?: Maybe<Scalars["String"]["output"]>
  is_visible?: Maybe<Scalars["Boolean"]["output"]>
  subscribe_complete_html?: Maybe<Scalars["String"]["output"]>
  subscription_plan?: Maybe<SubscriptionPlan>
}

/** A connection to a list of items. */
export type SchoolSubscriptionPlanConnection = {
  __typename?: "SchoolSubscriptionPlanConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SchoolSubscriptionPlanEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total?: Maybe<Scalars["Int"]["output"]>
}

/** An edge in a connection. */
export type SchoolSubscriptionPlanEdge = {
  __typename?: "SchoolSubscriptionPlanEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<SchoolSubscriptionPlan>
}

export enum SchoolType {
  CoachTutor = "coach_tutor",
  CurriculumPublisher = "curriculum_publisher",
  CustomerTraining = "customer_training",
  HigherEd = "higher_ed",
  K12 = "k12",
  Ministry = "ministry",
  NonProfit = "non_profit",
  Other = "other",
  ProfessionalDevelopment = "professional_development",
  Training = "training",
  Unspecified = "unspecified"
}

export enum ShareAccessPermission {
  Full = "FULL",
  Gated = "GATED",
  None = "NONE"
}

/** The current user's share permissions pertaining to provided context. */
export type ShareCohortPermissions = {
  __typename?: "ShareCohortPermissions"
  /** The email of the user to contact for activating school on a Pathwright plan. */
  activate_contact_email?: Maybe<Scalars["EmailAddress"]["output"]>
  /** The maximum number, if any, of users that may be added. */
  add_limit?: Maybe<Scalars["Int"]["output"]>
  /** Whether user can activate school on a Pathwright plan. */
  allow_activate?: Maybe<Scalars["Boolean"]["output"]>
  /** Whether user can manage settings for the [type/type_id] item. */
  allow_manage?: Maybe<Scalars["Boolean"]["output"]>
  invite_to_roles?: Maybe<ShareCohortPermissionsInviteToRoles>
  /** The settings URL for the [type/type_id] item. */
  manage_url?: Maybe<Scalars["String"]["output"]>
  /** Whether user can use share UI. */
  share_access?: Maybe<CohortShareAccess>
  /** Whether user can use share > add UI. */
  share_add_access?: Maybe<CohortShareAddAccess>
  /** Whether user can use share > link UI. */
  share_link_access?: Maybe<CohortShareLinkAccess>
  /** Whether user can use share > track UI. */
  share_track_access?: Maybe<CohortShareTrackAccess>
  type?: Maybe<ShareContextType>
  type_id?: Maybe<Scalars["Int"]["output"]>
}

export type ShareCohortPermissionsInviteToRoles = {
  __typename?: "ShareCohortPermissionsInviteToRoles"
  course_editor?: Maybe<Scalars["String"]["output"]>
  learner?: Maybe<Scalars["String"]["output"]>
  moderator?: Maybe<Scalars["String"]["output"]>
  observer?: Maybe<Scalars["String"]["output"]>
  teacher?: Maybe<Scalars["String"]["output"]>
  viewer?: Maybe<Scalars["String"]["output"]>
}

export type ShareContext = {
  /** The type of the context in which the user has the permission (defaults to school). */
  type: ShareContextType
  /** The id of the type. */
  type_id?: InputMaybe<Scalars["Int"]["input"]>
}

export enum ShareContextType {
  Cohort = "cohort",
  Group = "group",
  School = "school"
}

/** The current user's share permissions pertaining to provided context. */
export type ShareGroupPermissions = {
  __typename?: "ShareGroupPermissions"
  /** The email of the user to contact for activating school on a Pathwright plan. */
  activate_contact_email?: Maybe<Scalars["EmailAddress"]["output"]>
  /** The maximum number, if any, of users that may be added. */
  add_limit?: Maybe<Scalars["Int"]["output"]>
  /** Whether user can activate school on a Pathwright plan. */
  allow_activate?: Maybe<Scalars["Boolean"]["output"]>
  /** Whether user can manage settings for the [type/type_id] item. */
  allow_manage?: Maybe<Scalars["Boolean"]["output"]>
  invite_to_roles?: Maybe<ShareGroupPermissionsInviteToRoles>
  /** The settings URL for the [type/type_id] item. */
  manage_url?: Maybe<Scalars["String"]["output"]>
  /** Whether user can use share UI. */
  share_access?: Maybe<GroupShareAccess>
  /** Whether user can use share > add UI. */
  share_add_access?: Maybe<GroupShareAddAccess>
  /** Whether user can use share > link UI. */
  share_link_access?: Maybe<GroupShareLinkAccess>
  /** Whether user can use share > track UI. */
  share_track_access?: Maybe<GroupShareTrackAccess>
  type?: Maybe<ShareContextType>
  type_id?: Maybe<Scalars["Int"]["output"]>
}

export type ShareGroupPermissionsInviteToRoles = {
  __typename?: "ShareGroupPermissionsInviteToRoles"
  admin?: Maybe<Scalars["String"]["output"]>
  member?: Maybe<Scalars["String"]["output"]>
  moderator?: Maybe<Scalars["String"]["output"]>
}

/** The current user's share permissions pertaining to provided context. */
export type SharePermissions = ShareCohortPermissions | ShareGroupPermissions

export type Space = {
  __typename?: "Space"
  /** Whether new users can sign up without an invitation. */
  allow_public_signup?: Maybe<Scalars["Boolean"]["output"]>
  /** Signifies whether a Space allows a user to join as a member */
  allow_signup: Scalars["Boolean"]["output"]
  /** Whether to prompt users to enter a password when signing up. */
  always_prompt_for_password?: Maybe<Scalars["Boolean"]["output"]>
  authMethods: Array<AuthMethod>
  /** Description of consent from user for space to do xy and z. */
  consent_description?: Maybe<Scalars["String"]["output"]>
  created: Scalars["String"]["output"]
  features: SpaceFeatures
  id: Scalars["Int"]["output"]
  ietf_language_tag?: Maybe<Scalars["String"]["output"]>
  image: Scalars["String"]["output"]
  is_activated: Scalars["Boolean"]["output"]
  is_owner: Scalars["Boolean"]["output"]
  logo_image?: Maybe<Scalars["String"]["output"]>
  media?: Maybe<Array<Maybe<SpaceMedia>>>
  meta: NodeMeta
  name: Scalars["String"]["output"]
  /** Whether Pathwright Sign-in options (currently Password and Magic Link) are available. */
  pathwright_login_enabled?: Maybe<Scalars["Boolean"]["output"]>
  primary_domain?: Maybe<Scalars["String"]["output"]>
  subdomain: Scalars["String"]["output"]
  theme: NodeTheme
}

export type SpaceAllow_SignupArgs = {
  invite_token?: InputMaybe<Scalars["String"]["input"]>
}

export type SpaceImageArgs = {
  blur?: InputMaybe<Scalars["Int"]["input"]>
  crop?: InputMaybe<Crop>
  dpr?: InputMaybe<Scalars["Int"]["input"]>
  fit?: InputMaybe<Fit>
  height?: InputMaybe<Scalars["Int"]["input"]>
  width?: InputMaybe<Scalars["Int"]["input"]>
}

export type SpaceConnection = {
  __typename?: "SpaceConnection"
  last_activity_date?: Maybe<Scalars["DateTime"]["output"]>
  role: Scalars["Int"]["output"]
  space: Space
}

/** A connection to a list of items. */
export type SpaceConnectionConnection = {
  __typename?: "SpaceConnectionConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SpaceConnectionEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total?: Maybe<Scalars["Int"]["output"]>
}

/** An edge in a connection. */
export type SpaceConnectionEdge = {
  __typename?: "SpaceConnectionEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<SpaceConnection>
}

/** A list of all features that the space may or may not have access to, with a value indicating whether or not the space has access */
export type SpaceFeatures = {
  __typename?: "SpaceFeatures"
  /** Allows users of a Space to use the Auth Request (via email) feature. */
  auth__login_option__email_link: Scalars["Boolean"]["output"]
  /** Allows users of a Space to use the Apple SSO feature. */
  auth__login_option__global_sso__apple: Scalars["Boolean"]["output"]
  /** Allows users of a Space to use the Google SSO feature. */
  auth__login_option__global_sso__google: Scalars["Boolean"]["output"]
  /** Allows users of a Space to use the Password login feature. */
  auth__login_option__password: Scalars["Boolean"]["output"]
  /** Allows a Space to use the Paths Auth feature. */
  auth__platform: Scalars["Boolean"]["output"]
  /** Allows a Space to use sync a Path to paths.app. */
  paths_app__publish: Scalars["Boolean"]["output"]
}

export type SpaceMedia = {
  __typename?: "SpaceMedia"
  meta: NodeMeta
}

export type StepContent = {
  __typename?: "StepContent"
  data?: Maybe<Scalars["JSON"]["output"]>
  id?: Maybe<Scalars["String"]["output"]>
  method?: Maybe<Scalars["String"]["output"]>
  type?: Maybe<Scalars["String"]["output"]>
}

/** The context this step belongs to (resource and cohort) */
export type StepContext = {
  __typename?: "StepContext"
  cohort_id?: Maybe<Scalars["Int"]["output"]>
  resource_id?: Maybe<Scalars["Int"]["output"]>
  school_id?: Maybe<Scalars["Int"]["output"]>
}

export type Store = {
  __typename?: "Store"
  id?: Maybe<Scalars["Int"]["output"]>
  /** The school providing the curriculum */
  licensor?: Maybe<School>
}

export type StoreBanner = {
  __typename?: "StoreBanner"
  button_text?: Maybe<Scalars["String"]["output"]>
  button_url?: Maybe<Scalars["String"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  meta: NodeMeta
  theme: NodeTheme
}

export type StoreCollection = {
  __typename?: "StoreCollection"
  id?: Maybe<Scalars["Int"]["output"]>
  items?: Maybe<StoreItemConnection>
  meta: NodeMeta
  theme: NodeTheme
}

export type StoreCollectionItemsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  author_id?: InputMaybe<Scalars["Int"]["input"]>
  category_id?: InputMaybe<Scalars["Int"]["input"]>
  first: Scalars["Int"]["input"]
}

export type StoreItem = {
  __typename?: "StoreItem"
  authors?: Maybe<AuthorConnection>
  category_ids: Array<Maybe<Scalars["Int"]["output"]>>
  description?: Maybe<Scalars["String"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  meta: NodeMeta
  options: Array<Maybe<StoreItemOption>>
  short_description?: Maybe<Scalars["String"]["output"]>
  theme: NodeTheme
}

export type StoreItemAuthorsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
}

/** A connection to a list of items. */
export type StoreItemConnection = {
  __typename?: "StoreItemConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<StoreItemEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total?: Maybe<Scalars["Int"]["output"]>
}

/** An edge in a connection. */
export type StoreItemEdge = {
  __typename?: "StoreItemEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<StoreItem>
}

export type StoreItemOption = {
  __typename?: "StoreItemOption"
  id?: Maybe<Scalars["Int"]["output"]>
  meta: NodeMeta
  path_id?: Maybe<Scalars["Int"]["output"]>
  price?: Maybe<Scalars["Int"]["output"]>
}

export type StoreLink = {
  __typename?: "StoreLink"
  id?: Maybe<Scalars["Int"]["output"]>
  /** The school licensing the curriculum */
  licensee?: Maybe<School>
  /** The store through which curriculum can be licensed */
  store?: Maybe<Store>
}

/** A connection to a list of items. */
export type StoreLinkConnection = {
  __typename?: "StoreLinkConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<StoreLinkEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total?: Maybe<Scalars["Int"]["output"]>
}

/** An edge in a connection. */
export type StoreLinkEdge = {
  __typename?: "StoreLinkEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<StoreLink>
}

export type Storefront = {
  __typename?: "Storefront"
  id: Scalars["String"]["output"]
  meta: NodeMeta
}

export type StringFilter = {
  eq?: InputMaybe<Scalars["String"]["input"]>
  gt?: InputMaybe<Scalars["String"]["input"]>
  gte?: InputMaybe<Scalars["String"]["input"]>
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
  lt?: InputMaybe<Scalars["String"]["input"]>
  lte?: InputMaybe<Scalars["String"]["input"]>
  neq?: InputMaybe<Scalars["String"]["input"]>
  nin?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
  op?: InputMaybe<Scalars["String"]["input"]>
}

export enum StripeBillingScheme {
  PerUnit = "per_unit",
  Tiered = "tiered"
}

/** Represents a Coupon for a Subscription on Stripe. Used for Scholarships. */
export type StripeCoupon = {
  __typename?: "StripeCoupon"
  id?: Maybe<Scalars["Int"]["output"]>
  percent_off?: Maybe<Scalars["Float"]["output"]>
}

/** Represents a Price for a Product on Stripe that specifies pricing configuration. */
export type StripePrice = {
  __typename?: "StripePrice"
  active?: Maybe<Scalars["Boolean"]["output"]>
  billing_scheme?: Maybe<StripeBillingScheme>
  currency?: Maybe<Scalars["String"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  nickname?: Maybe<Scalars["String"]["output"]>
  product?: Maybe<StripeProduct>
  recurring_interval?: Maybe<StripeRecurringInterval>
  recurring_interval_count?: Maybe<Scalars["Int"]["output"]>
  stripe_price_id?: Maybe<Scalars["String"]["output"]>
  tiers?: Maybe<Array<Maybe<StripeTier>>>
  tiers_mode?: Maybe<StripeTiersMode>
  unit_amount_decimal?: Maybe<Scalars["Float"]["output"]>
}

/** A connection to a list of items. */
export type StripePriceConnection = {
  __typename?: "StripePriceConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<StripePriceEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total?: Maybe<Scalars["Int"]["output"]>
}

/** An edge in a connection. */
export type StripePriceEdge = {
  __typename?: "StripePriceEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<StripePrice>
}

/** Represents a Product on Stripe that can be sold. */
export type StripeProduct = {
  __typename?: "StripeProduct"
  active?: Maybe<Scalars["Boolean"]["output"]>
  description?: Maybe<Scalars["String"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  images?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>
  max_quantity?: Maybe<Scalars["Int"]["output"]>
  min_quantity?: Maybe<Scalars["Int"]["output"]>
  name?: Maybe<Scalars["String"]["output"]>
  processor?: Maybe<School>
  stripe_prices?: Maybe<StripePriceConnection>
  stripe_product_id?: Maybe<Scalars["String"]["output"]>
  unit_label?: Maybe<Scalars["String"]["output"]>
}

/** Represents a Product on Stripe that can be sold. */
export type StripeProductStripe_PricesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
}

/** A connection to a list of items. */
export type StripeProductConnection = {
  __typename?: "StripeProductConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<StripeProductEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total?: Maybe<Scalars["Int"]["output"]>
}

/** An edge in a connection. */
export type StripeProductEdge = {
  __typename?: "StripeProductEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<StripeProduct>
}

export enum StripeRecurringInterval {
  Day = "day",
  Month = "month",
  Week = "week",
  Year = "year"
}

export type StripeTier = {
  __typename?: "StripeTier"
  flat_amount?: Maybe<Scalars["Int"]["output"]>
  flat_amount_decimal?: Maybe<Scalars["String"]["output"]>
  unit_amount?: Maybe<Scalars["Int"]["output"]>
  unit_amount_decimal?: Maybe<Scalars["String"]["output"]>
  up_to?: Maybe<Scalars["Int"]["output"]>
}

export enum StripeTiersMode {
  Graduated = "graduated",
  Volume = "volume"
}

export type Subdomain = {
  __typename?: "Subdomain"
  id?: Maybe<Scalars["Int"]["output"]>
  subdomain?: Maybe<Scalars["String"]["output"]>
}

/** Subscription Data */
export type Subscription = {
  __typename?: "Subscription"
  canceled_dtime?: Maybe<Scalars["DateTime"]["output"]>
  cancellation_reason?: Maybe<Scalars["Int"]["output"]>
  card_last_4?: Maybe<Scalars["String"]["output"]>
  customer?: Maybe<User>
  cycle_end_dtime?: Maybe<Scalars["DateTime"]["output"]>
  cycle_start_dtime?: Maybe<Scalars["DateTime"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  is_canceled_at_cycle_end?: Maybe<Scalars["Boolean"]["output"]>
  is_delinquent?: Maybe<Scalars["Boolean"]["output"]>
  paused_until_dtime?: Maybe<Scalars["DateTime"]["output"]>
  /** The client_secret set on the payment intent associated with this subscription (if subscription.payment_intent_needing_action_id is not null). */
  payment_intent_client_secret?: Maybe<Scalars["String"]["output"]>
  payment_intent_needing_action_id?: Maybe<Scalars["String"]["output"]>
  quantity?: Maybe<Scalars["Int"]["output"]>
  /** Used in conjunction with the payment_intent_client_secret to execute the handleCardAction via Stripe.js. */
  stripe_publishable_api_key?: Maybe<Scalars["String"]["output"]>
  subscription_plan?: Maybe<SubscriptionPlan>
  trial_end_dtime?: Maybe<Scalars["DateTime"]["output"]>
  trial_type?: Maybe<Scalars["Int"]["output"]>
}

export enum SubscriptionChargeType {
  Invoice = "invoice",
  NoCharge = "no_charge",
  PathwrightTeam = "pathwright_team",
  Stripe = "stripe"
}

/** A connection to a list of items. */
export type SubscriptionConnection = {
  __typename?: "SubscriptionConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SubscriptionEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total?: Maybe<Scalars["Int"]["output"]>
}

/** An edge in a connection. */
export type SubscriptionEdge = {
  __typename?: "SubscriptionEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<Subscription>
}

export type SubscriptionIntervalFilter = {
  eq?: InputMaybe<SubscriptionIntervalType>
  gt?: InputMaybe<SubscriptionIntervalType>
  gte?: InputMaybe<SubscriptionIntervalType>
  in?: InputMaybe<Array<InputMaybe<SubscriptionIntervalType>>>
  lt?: InputMaybe<SubscriptionIntervalType>
  lte?: InputMaybe<SubscriptionIntervalType>
  neq?: InputMaybe<SubscriptionIntervalType>
  nin?: InputMaybe<Array<InputMaybe<SubscriptionIntervalType>>>
  op?: InputMaybe<Scalars["String"]["input"]>
}

export enum SubscriptionIntervalType {
  Biannually = "biannually",
  Lifetime = "lifetime",
  Monthly = "monthly",
  Yearly = "yearly"
}

/** Payment Subscription Plan Data */
export type SubscriptionPlan = {
  __typename?: "SubscriptionPlan"
  amount?: Maybe<Scalars["Float"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  interval?: Maybe<Scalars["Int"]["output"]>
  name?: Maybe<Scalars["String"]["output"]>
  plan_type?: Maybe<Scalars["String"]["output"]>
  trial_period_days?: Maybe<Scalars["Int"]["output"]>
  trial_type?: Maybe<Scalars["Int"]["output"]>
}

/** A generic tag */
export type Tag = {
  __typename?: "Tag"
  color?: Maybe<Scalars["String"]["output"]>
  description?: Maybe<Scalars["String"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  name?: Maybe<Scalars["String"]["output"]>
  school?: Maybe<School>
  slug?: Maybe<Scalars["String"]["output"]>
  tagAttachment?: Maybe<TagAttachment>
  tagAttachments?: Maybe<TagAttachmentConnection>
}

/** A generic tag */
export type TagTagAttachmentArgs = {
  context?: InputMaybe<TagAttachmentContextInput>
  id?: InputMaybe<Scalars["Int"]["input"]>
}

/** A generic tag */
export type TagTagAttachmentsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  context?: InputMaybe<TagAttachmentContextInput>
  first: Scalars["Int"]["input"]
  tag_id?: InputMaybe<Scalars["Int"]["input"]>
}

/** A tag attachment provides a means of attaching a tag to a context. */
export type TagAttachment = {
  __typename?: "TagAttachment"
  id?: Maybe<Scalars["Int"]["output"]>
  position?: Maybe<Scalars["String"]["output"]>
  resource?: Maybe<Resource>
  resource_id?: Maybe<Scalars["Int"]["output"]>
  school?: Maybe<School>
  school_id?: Maybe<Scalars["Int"]["output"]>
  tag?: Maybe<Tag>
  tag_id?: Maybe<Scalars["Int"]["output"]>
}

/** A connection to a list of items. */
export type TagAttachmentConnection = {
  __typename?: "TagAttachmentConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TagAttachmentEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total?: Maybe<Scalars["Int"]["output"]>
}

export type TagAttachmentContextInput = {
  resource_id?: InputMaybe<Scalars["Int"]["input"]>
}

/** An edge in a connection. */
export type TagAttachmentEdge = {
  __typename?: "TagAttachmentEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<TagAttachment>
}

/** A connection to a list of items. */
export type TagConnection = {
  __typename?: "TagConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TagEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total?: Maybe<Scalars["Int"]["output"]>
}

export type TagCreateInput = {
  color?: InputMaybe<Scalars["String"]["input"]>
  description?: InputMaybe<Scalars["String"]["input"]>
  name: Scalars["String"]["input"]
}

export type TagDeleteInput = {
  id: Scalars["Int"]["input"]
}

/** An edge in a connection. */
export type TagEdge = {
  __typename?: "TagEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<Tag>
}

/** A tagLink that links tags to other models within a tagAttachment context, currently only to a discussion context. */
export type TagLink = {
  __typename?: "TagLink"
  discussion_context_id?: Maybe<Scalars["Int"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  tag?: Maybe<Tag>
  tag_id?: Maybe<Scalars["Int"]["output"]>
}

/** A connection to a list of items. */
export type TagLinkConnection = {
  __typename?: "TagLinkConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TagLinkEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total?: Maybe<Scalars["Int"]["output"]>
}

export type TagLinkContextInput = {
  discussion_context_id?: InputMaybe<Scalars["Int"]["input"]>
}

/** An edge in a connection. */
export type TagLinkEdge = {
  __typename?: "TagLinkEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<TagLink>
}

export type TagUpdateInput = {
  color?: InputMaybe<Scalars["String"]["input"]>
  description?: InputMaybe<Scalars["String"]["input"]>
  id: Scalars["Int"]["input"]
  name?: InputMaybe<Scalars["String"]["input"]>
}

export type TextObject = {
  alignment: TextObjectAlignment
  dimensions: TextObjectDimensions
  font_color: Scalars["String"]["input"]
  font_name: Scalars["String"]["input"]
  font_size: Scalars["Float"]["input"]
  gutter?: InputMaybe<Scalars["Float"]["input"]>
  letter_spacing?: InputMaybe<Scalars["Float"]["input"]>
  line_height?: InputMaybe<Scalars["Float"]["input"]>
  position: TextObjectPosition
  text: Scalars["String"]["input"]
}

export enum TextObjectAlignment {
  Center = "center",
  Left = "left",
  Right = "right"
}

export type TextObjectDimensions = {
  height: Scalars["Float"]["input"]
  width: Scalars["Float"]["input"]
}

export type TextObjectPosition = {
  left: Scalars["Float"]["input"]
  top: Scalars["Float"]["input"]
}

/** A School Theme */
export type Theme = {
  __typename?: "Theme"
  background_image?: Maybe<Scalars["String"]["output"]>
  background_overlay?: Maybe<Scalars["String"]["output"]>
  background_type?: Maybe<Scalars["Int"]["output"]>
  color_palette?: Maybe<NodeThemeColorPalette>
  custom_css?: Maybe<Scalars["String"]["output"]>
  heading_font?: Maybe<Scalars["String"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  primary_color?: Maybe<Scalars["String"]["output"]>
  processed_custom_css?: Maybe<Scalars["String"]["output"]>
  typekit_id?: Maybe<Scalars["String"]["output"]>
}

/** A School Theme */
export type ThemeBackground_ImageArgs = {
  blur?: InputMaybe<Scalars["Int"]["input"]>
  crop?: InputMaybe<Crop>
  dpr?: InputMaybe<Scalars["Int"]["input"]>
  fit?: InputMaybe<Fit>
  height?: InputMaybe<Scalars["Int"]["input"]>
  width?: InputMaybe<Scalars["Int"]["input"]>
}

/** Auth token */
export type Token = {
  __typename?: "Token"
  created?: Maybe<Scalars["DateTime"]["output"]>
  token?: Maybe<Scalars["String"]["output"]>
  user_id?: Maybe<Scalars["Int"]["output"]>
}

/** The context of a Pathwright 'session' in a school. */
export type Translation = {
  __typename?: "Translation"
  id?: Maybe<Scalars["Int"]["output"]>
  is_default?: Maybe<Scalars["Boolean"]["output"]>
  language?: Maybe<Scalars["String"]["output"]>
  master_translation?: Maybe<Scalars["JSON"]["output"]>
  name?: Maybe<Scalars["String"]["output"]>
  school_translation?: Maybe<Scalars["JSON"]["output"]>
  source_translation?: Maybe<Scalars["JSON"]["output"]>
  translation?: Maybe<Scalars["JSON"]["output"]>
}

/** A Pathwright User */
export type User = {
  __typename?: "User"
  display_name?: Maybe<Scalars["String"]["output"]>
  email?: Maybe<Scalars["String"]["output"]>
  external_account_link?: Maybe<ExternalAccountLink>
  first_name?: Maybe<Scalars["String"]["output"]>
  full_name?: Maybe<Scalars["String"]["output"]>
  gravatar?: Maybe<Gravatar>
  group_role_stats?: Maybe<Array<Maybe<GroupRoleStats>>>
  id?: Maybe<Scalars["Int"]["output"]>
  /** A user account setup is onl considered 'finalized' when the `first_name` and `last_name` fields have been set. */
  is_finalized?: Maybe<Scalars["Boolean"]["output"]>
  is_staff?: Maybe<Scalars["Boolean"]["output"]>
  is_superuser?: Maybe<Scalars["Boolean"]["output"]>
  last_name?: Maybe<Scalars["String"]["output"]>
  membership?: Maybe<Membership>
  memberships?: Maybe<MembershipConnection>
  mentorGroupMemberships?: Maybe<MentorGroupMembershipConnection>
  no_password_set?: Maybe<Scalars["Boolean"]["output"]>
  permissions?: Maybe<ContextPermissions>
  profile?: Maybe<Profile>
  registrations?: Maybe<RegistrationConnection>
  school_group_subscriptions?: Maybe<SchoolGroupSubscriptionConnection>
  school_subscription?: Maybe<SchoolSubscription>
  token?: Maybe<Token>
  username?: Maybe<Scalars["String"]["output"]>
}

/** A Pathwright User */
export type UserMembershipsArgs = {
  access_revoked_date_filter?: InputMaybe<Array<InputMaybe<DateFilter>>>
  after?: InputMaybe<Scalars["String"]["input"]>
  archived_date_filter?: InputMaybe<Array<InputMaybe<DateFilter>>>
  daysInactive?: InputMaybe<Scalars["Int"]["input"]>
  first: Scalars["Int"]["input"]
  global?: InputMaybe<Scalars["Boolean"]["input"]>
  hasAccess?: InputMaybe<Scalars["Boolean"]["input"]>
  orderBy?: InputMaybe<Scalars["String"]["input"]>
  role_filter?: InputMaybe<Array<InputMaybe<MembershipRoleFilter>>>
  search?: InputMaybe<Scalars["String"]["input"]>
}

/** A Pathwright User */
export type UserMentorGroupMembershipsArgs = {
  _or?: InputMaybe<MentorGroupMembershipOr>
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  group_type?: InputMaybe<MentorGroupType>
  group_types?: InputMaybe<MentorGroupTypeFilter>
  mentor_group_seat_limit?: InputMaybe<Scalars["Int"]["input"]>
  mentor_group_seat_limit_filter?: InputMaybe<IntFilter>
  mentor_group_seats_filled?: InputMaybe<Scalars["Int"]["input"]>
  mentor_group_seats_filled_filter?: InputMaybe<IntFilter>
  order_by?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
  role?: InputMaybe<MentorGroupMembershipRoleFilter>
  search?: InputMaybe<Scalars["String"]["input"]>
}

/** A Pathwright User */
export type UserRegistrationsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  completion_state?: InputMaybe<CompletionStateFilter>
  created?: InputMaybe<DateFilter>
  first: Scalars["Int"]["input"]
  has_parent_registration?: InputMaybe<Scalars["Boolean"]["input"]>
  last_active?: InputMaybe<DateFilter>
  orderBy?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
  parent_cohort_filter?: InputMaybe<IdFilter>
  progress_filter?: InputMaybe<ProgressFilter>
  role?: InputMaybe<RegistrationRoleEnum>
  role_filter?: InputMaybe<RoleFilter>
  search?: InputMaybe<Scalars["String"]["input"]>
  send_discussion_notifications?: InputMaybe<Scalars["Boolean"]["input"]>
  send_due_notifications?: InputMaybe<Scalars["Boolean"]["input"]>
}

/** A Pathwright User */
export type UserSchool_Group_SubscriptionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
}

/** Check on the existence and verification status of any email/username. */
export type UserStatus = {
  __typename?: "UserStatus"
  /**
   * Whether a user exists with the provided email (or username).
   * @deprecated Use 'user_exists' instead.
   */
  email_exists: Scalars["Boolean"]["output"]
  /** Whether a user's email has been verified. */
  email_verified: Scalars["Boolean"]["output"]
  /** Whether a user has a membership for the Space. */
  has_membership: Scalars["Boolean"]["output"]
  /** Indicates the type of input received. */
  input_type: UserStatusInputType
  /**
   * Whether the email or username is being proxied.
   * @deprecated Use 'input_type' instead.
   */
  is_proxy: Scalars["Boolean"]["output"]
  /** Whether a user has a password set. */
  no_password_set: Scalars["Boolean"]["output"]
  /** Whether the email or username exists. */
  user_exists: Scalars["Boolean"]["output"]
}

export enum UserStatusInputType {
  Email = "email",
  Proxy = "proxy",
  Username = "username"
}

export type UserSubmission = {
  __typename?: "UserSubmission"
  file_image?: Maybe<Scalars["String"]["output"]>
  file_name?: Maybe<Scalars["String"]["output"]>
  file_url?: Maybe<Scalars["String"]["output"]>
  id?: Maybe<Scalars["Int"]["output"]>
  text?: Maybe<Scalars["String"]["output"]>
}

/** An event from a webhook subscription. Currently these will only be faild webhook events. */
export type WebhookEvent = {
  __typename?: "WebhookEvent"
  /** The event types to being subscribed to. */
  event: WebhookEventType
  event_data: Scalars["JSON"]["output"]
  last_attempt_dtime: Scalars["DateTime"]["output"]
  status: WebhookEventStatus
  uuid: Scalars["UUID"]["output"]
  webhook_subscription: WebhookSubscription
}

/** A connection to a list of items. */
export type WebhookEventConnection = {
  __typename?: "WebhookEventConnection"
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<WebhookEventEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  total?: Maybe<Scalars["Int"]["output"]>
}

/** An edge in a connection. */
export type WebhookEventEdge = {
  __typename?: "WebhookEventEdge"
  /** A cursor for use in pagination */
  cursor: Scalars["String"]["output"]
  /** The item at the end of the edge */
  node?: Maybe<WebhookEvent>
}

export type WebhookEventOrderBy = {
  last_attempt_dtime?: InputMaybe<OrderByDir>
}

export enum WebhookEventStatus {
  Failed = "FAILED"
}

export enum WebhookEventType {
  All = "ALL",
  CohortCreated = "COHORT_CREATED",
  NewLearningActivity = "NEW_LEARNING_ACTIVITY",
  RegistrationSucceeded = "REGISTRATION_SUCCEEDED",
  StepCompletionReviewed = "STEP_COMPLETION_REVIEWED",
  StudentCourseCompleted = "STUDENT_COURSE_COMPLETED",
  StudentMembershipCreated = "STUDENT_MEMBERSHIP_CREATED",
  StudentMembershipUpdated = "STUDENT_MEMBERSHIP_UPDATED",
  StudentRegistrationSucceeded = "STUDENT_REGISTRATION_SUCCEEDED",
  StudentSubscriptionCanceled = "STUDENT_SUBSCRIPTION_CANCELED",
  StudentSubscriptionSucceeded = "STUDENT_SUBSCRIPTION_SUCCEEDED",
  UserEmailUpdated = "USER_EMAIL_UPDATED"
}

/** An event from a webhook subscription. Currently these will only be faild webhook events. */
export type WebhookSubscription = {
  __typename?: "WebhookSubscription"
  /** When the credentials were archived. */
  archived_time?: Maybe<Scalars["DateTime"]["output"]>
  /** The time the credentials were created. */
  created_time: Scalars["DateTime"]["output"]
  /** The event types to being subscribed to. */
  event: WebhookEventType
  id: Scalars["Int"]["output"]
  /** The school this subscription belongs to. */
  school: School
  /** The receiver URL. */
  target_url: Scalars["URL"]["output"]
}

export enum Crop {
  Bottom = "bottom",
  Edges = "edges",
  Entropy = "entropy",
  Faces = "faces",
  Focalpoint = "focalpoint",
  Left = "left",
  Right = "right",
  Top = "top"
}

export enum Fit {
  Clamp = "clamp",
  Clip = "clip",
  Crop = "crop",
  Facearea = "facearea",
  Fill = "fill",
  Fillmax = "fillmax",
  Max = "max",
  Min = "min",
  Scale = "scale"
}

export enum Interval {
  Annually = "annually",
  Monthly = "monthly"
}

export enum Visibility {
  Pathwright_1 = "pathwright_1",
  Pathwright_2 = "pathwright_2",
  PrivateCustom = "private_custom"
}

export type AuthContextQueryVariables = Exact<{
  inviteToken?: Maybe<Scalars["String"]["input"]>
}>

export type AuthContextQuery = {
  __typename?: "Query"
  space: {
    __typename?: "Space"
    id: number
    consent_description?: string | null
    allow_signup: boolean
    always_prompt_for_password?: boolean | null
    authMethods: Array<{
      __typename?: "AuthMethod"
      id: string
      enabled: boolean
      meta: {
        __typename?: "NodeMeta"
        id: string
        key: string
        type: string
        url?: string | null
        title?: string | null
        icon?: string | null
      }
    }>
  }
}

export type AuthMethodFragment = {
  __typename?: "AuthMethod"
  id: string
  enabled: boolean
  meta: {
    __typename?: "NodeMeta"
    id: string
    key: string
    type: string
    url?: string | null
    title?: string | null
    icon?: string | null
  }
}

export type AuthMethodsQueryVariables = Exact<{ [key: string]: never }>

export type AuthMethodsQuery = {
  __typename?: "Query"
  space: {
    __typename?: "Space"
    id: number
    authMethods: Array<{
      __typename?: "AuthMethod"
      id: string
      enabled: boolean
      meta: {
        __typename?: "NodeMeta"
        id: string
        key: string
        type: string
        url?: string | null
        title?: string | null
        icon?: string | null
      }
    }>
  }
}

export type RefreshTokenMutationVariables = Exact<{
  schoolId?: Maybe<Scalars["ID"]["input"]>
}>

export type RefreshTokenMutation = {
  __typename?: "Mutations"
  refreshToken?: string | null
}

export type MeQueryVariables = Exact<{ [key: string]: never }>

export type MeQuery = {
  __typename?: "Query"
  me?: {
    __typename?: "User"
    id?: number | null
    email?: string | null
    first_name?: string | null
    last_name?: string | null
    full_name?: string | null
    display_name?: string | null
    is_finalized?: boolean | null
    profile?: {
      __typename?: "Profile"
      id?: number | null
      bio?: string | null
      image?: string | null
      location?: string | null
      last_activity?: any | null
      notification_digest_interval?: NotificationDigestInterval | null
    } | null
    membership?: {
      __typename?: "Membership"
      id?: number | null
      role?: number | null
    } | null
  } | null
}

export type UserMembershipFragment = {
  __typename?: "Membership"
  id?: number | null
  role?: number | null
}

export type UserFragment = {
  __typename?: "User"
  id?: number | null
  email?: string | null
  first_name?: string | null
  last_name?: string | null
  full_name?: string | null
  display_name?: string | null
  is_finalized?: boolean | null
  profile?: {
    __typename?: "Profile"
    id?: number | null
    bio?: string | null
    image?: string | null
    location?: string | null
    last_activity?: any | null
    notification_digest_interval?: NotificationDigestInterval | null
  } | null
  membership?: {
    __typename?: "Membership"
    id?: number | null
    role?: number | null
  } | null
}

export type AuthRequestFragment = {
  __typename?: "AuthRequest"
  uuid: any
  request_method: AuthRequestMethod
  request_key?: string | null
  request_dtime: any
  confirmed_dtime?: any | null
  expiration_dtime?: any | null
  confirmation_attempts: number
  context?: any | null
  truncated_email?: string | null
  issued_auth_token?: { __typename?: "JSONWebToken"; token: string } | null
}

export type StartAuthRequestMutationVariables = Exact<{
  method: AuthRequestMethod
  email?: Maybe<Scalars["EmailAddress"]["input"]>
  username?: Maybe<Scalars["String"]["input"]>
  nextUrl?: Maybe<Scalars["String"]["input"]>
}>

export type StartAuthRequestMutation = {
  __typename?: "Mutations"
  startAuthRequest?: {
    __typename?: "AuthRequest"
    uuid: any
    request_method: AuthRequestMethod
    request_key?: string | null
    request_dtime: any
    confirmed_dtime?: any | null
    expiration_dtime?: any | null
    confirmation_attempts: number
    context?: any | null
    truncated_email?: string | null
    issued_auth_token?: { __typename?: "JSONWebToken"; token: string } | null
  } | null
}

export type AuthRequestQueryVariables = Exact<{
  uuid: Scalars["String"]["input"]
}>

export type AuthRequestQuery = {
  __typename?: "Query"
  authRequest?: {
    __typename?: "AuthRequest"
    uuid: any
    request_method: AuthRequestMethod
    request_key?: string | null
    request_dtime: any
    confirmed_dtime?: any | null
    expiration_dtime?: any | null
    confirmation_attempts: number
    context?: any | null
    truncated_email?: string | null
    issued_auth_token?: { __typename?: "JSONWebToken"; token: string } | null
  } | null
}

export type AuthRequestTokenQueryVariables = Exact<{
  uuid: Scalars["String"]["input"]
}>

export type AuthRequestTokenQuery = {
  __typename?: "Query"
  authRequestToken?: any | null
}

export type ConfirmAuthRequestMutationVariables = Exact<{
  uuid: Scalars["UUID"]["input"]
  key: Scalars["String"]["input"]
}>

export type ConfirmAuthRequestMutation = {
  __typename?: "Mutations"
  confirmAuthRequest?: {
    __typename?: "AuthRequest"
    uuid: any
    request_method: AuthRequestMethod
    request_key?: string | null
    request_dtime: any
    confirmed_dtime?: any | null
    expiration_dtime?: any | null
    confirmation_attempts: number
    context?: any | null
    truncated_email?: string | null
    issued_auth_token?: { __typename?: "JSONWebToken"; token: string } | null
  } | null
}

export type FinalizeExternalAccountLinkMutationVariables = Exact<{
  token: Scalars["String"]["input"]
}>

export type FinalizeExternalAccountLinkMutation = {
  __typename?: "Mutations"
  finalizeExternalAccountLink?: {
    __typename?: "ExternalAccountLink"
    id?: number | null
  } | null
}

export type JoinSpaceMutationVariables = Exact<{
  consent: Scalars["Boolean"]["input"]
  inviteToken?: Maybe<Scalars["String"]["input"]>
}>

export type JoinSpaceMutation = {
  __typename?: "Mutations"
  joinSpace?: {
    __typename?: "Membership"
    consent_snapshot?: string | null
    consent_dtime?: any | null
    id?: number | null
    role?: number | null
  } | null
}

export type ChangePasswordMutationVariables = Exact<{
  currentPassword: Scalars["String"]["input"]
  password1: Scalars["String"]["input"]
  password2: Scalars["String"]["input"]
}>

export type ChangePasswordMutation = {
  __typename?: "Mutations"
  changePassword?: boolean | null
}

export type CreatePasswordMutationVariables = Exact<{
  password1: Scalars["String"]["input"]
  password2: Scalars["String"]["input"]
}>

export type CreatePasswordMutation = {
  __typename?: "Mutations"
  createPassword?: boolean | null
}

export type PassordResetConfirmMutationVariables = Exact<{
  resetHash: Scalars["String"]["input"]
  password1: Scalars["String"]["input"]
  password2: Scalars["String"]["input"]
}>

export type PassordResetConfirmMutation = {
  __typename?: "Mutations"
  resetPassword?: boolean | null
}

export type StartPasswordRetrievalMutationVariables = Exact<{
  email: Scalars["String"]["input"]
  nextUrl?: Maybe<Scalars["String"]["input"]>
}>

export type StartPasswordRetrievalMutation = {
  __typename?: "Mutations"
  startPasswordRetrieval?: boolean | null
}

export type UserStatusFragment = {
  __typename?: "UserStatus"
  input_type: UserStatusInputType
  user_exists: boolean
  email_verified: boolean
  no_password_set: boolean
  has_membership: boolean
}

export type UserStatusQueryVariables = Exact<{
  input?: Maybe<Scalars["String"]["input"]>
}>

export type UserStatusQuery = {
  __typename?: "Query"
  userStatus?: {
    __typename?: "UserStatus"
    input_type: UserStatusInputType
    user_exists: boolean
    email_verified: boolean
    no_password_set: boolean
    has_membership: boolean
  } | null
}

export type SignInPasswordMutationVariables = Exact<{
  email: Scalars["String"]["input"]
  password: Scalars["String"]["input"]
}>

export type SignInPasswordMutation = {
  __typename?: "Mutations"
  getToken?: {
    __typename?: "Token"
    token?: string | null
    user_id?: number | null
    created?: any | null
  } | null
}

export type SignOutMutationVariables = Exact<{ [key: string]: never }>

export type SignOutMutation = {
  __typename?: "Mutations"
  signOut?: boolean | null
}

export type SignUpMutationVariables = Exact<{
  firstName: Scalars["String"]["input"]
  lastName: Scalars["String"]["input"]
  email: Scalars["String"]["input"]
  password?: Maybe<Scalars["String"]["input"]>
  consent?: Maybe<Scalars["Boolean"]["input"]>
  inviteToken?: Maybe<Scalars["String"]["input"]>
  createMembership?: Maybe<Scalars["Boolean"]["input"]>
}>

export type SignUpMutation = {
  __typename?: "Mutations"
  signUp?: {
    __typename?: "Token"
    token?: string | null
    user_id?: number | null
    created?: any | null
  } | null
}

export type TermsAgreementQueryVariables = Exact<{ [key: string]: never }>

export type TermsAgreementQuery = {
  __typename?: "Query"
  school?: {
    __typename?: "School"
    page?: {
      __typename?: "Page"
      id?: number | null
      name?: string | null
      slug?: string | null
      external_url?: string | null
    } | null
  } | null
}

export type RegistrationListViewFragment = {
  __typename?: "Registration"
  id?: number | null
  role?: number | null
  meta: {
    __typename?: "NodeMeta"
    id: string
    key: string
    type: string
    url?: string | null
    title?: string | null
    subtitle?: string | null
    description?: string | null
    image?: { __typename?: "NodeImage"; src?: string | null } | null
  }
  path?: {
    __typename?: "Path"
    id?: number | null
    progress?: number | null
    meta: {
      __typename?: "NodeMeta"
      id: string
      key: string
      type: string
      url?: string | null
      title?: string | null
      subtitle?: string | null
      description?: string | null
      image?: { __typename?: "NodeImage"; src?: string | null } | null
    }
    theme: {
      __typename?: "NodeTheme"
      color: string
      color_palette?: {
        __typename?: "NodeThemeColorPalette"
        _50?: string | null
        _100?: string | null
        _200?: string | null
        _300?: string | null
        _400?: string | null
        _500?: string | null
        _600?: string | null
        _700?: string | null
        _800?: string | null
        _900?: string | null
      } | null
      background?: {
        __typename?: "NodeThemeBackground"
        color?: string | null
        contrast_color?: string | null
        image?: { __typename?: "NodeImage"; src?: string | null } | null
        image_blurred?: { __typename?: "NodeImage"; src?: string | null } | null
        image_blurred_dark?: {
          __typename?: "NodeImage"
          src?: string | null
        } | null
      } | null
    }
  } | null
}

export type PathRegistrationsQueryVariables = Exact<{
  first: Scalars["Int"]["input"]
}>

export type PathRegistrationsQuery = {
  __typename?: "Query"
  me?: {
    __typename?: "User"
    id?: number | null
    registrations?: {
      __typename?: "RegistrationConnection"
      edges?: Array<{
        __typename?: "RegistrationEdge"
        node?: {
          __typename?: "Registration"
          id?: number | null
          role?: number | null
          meta: {
            __typename?: "NodeMeta"
            id: string
            key: string
            type: string
            url?: string | null
            title?: string | null
            subtitle?: string | null
            description?: string | null
            image?: { __typename?: "NodeImage"; src?: string | null } | null
          }
          path?: {
            __typename?: "Path"
            id?: number | null
            progress?: number | null
            meta: {
              __typename?: "NodeMeta"
              id: string
              key: string
              type: string
              url?: string | null
              title?: string | null
              subtitle?: string | null
              description?: string | null
              image?: { __typename?: "NodeImage"; src?: string | null } | null
            }
            theme: {
              __typename?: "NodeTheme"
              color: string
              color_palette?: {
                __typename?: "NodeThemeColorPalette"
                _50?: string | null
                _100?: string | null
                _200?: string | null
                _300?: string | null
                _400?: string | null
                _500?: string | null
                _600?: string | null
                _700?: string | null
                _800?: string | null
                _900?: string | null
              } | null
              background?: {
                __typename?: "NodeThemeBackground"
                color?: string | null
                contrast_color?: string | null
                image?: { __typename?: "NodeImage"; src?: string | null } | null
                image_blurred?: {
                  __typename?: "NodeImage"
                  src?: string | null
                } | null
                image_blurred_dark?: {
                  __typename?: "NodeImage"
                  src?: string | null
                } | null
              } | null
            }
          } | null
        } | null
      } | null> | null
    } | null
  } | null
}

export type PathViewFragment = {
  __typename?: "Path"
  id?: number | null
  user?: {
    __typename?: "User"
    id?: number | null
    email?: string | null
    first_name?: string | null
    last_name?: string | null
    full_name?: string | null
    display_name?: string | null
    is_finalized?: boolean | null
    profile?: {
      __typename?: "Profile"
      id?: number | null
      bio?: string | null
      image?: string | null
      location?: string | null
      last_activity?: any | null
      notification_digest_interval?: NotificationDigestInterval | null
    } | null
    membership?: {
      __typename?: "Membership"
      id?: number | null
      role?: number | null
    } | null
  } | null
  meta: {
    __typename?: "NodeMeta"
    id: string
    key: string
    type: string
    url?: string | null
    title?: string | null
    subtitle?: string | null
    description?: string | null
    image?: { __typename?: "NodeImage"; src?: string | null } | null
  }
  theme: {
    __typename?: "NodeTheme"
    color: string
    color_palette?: {
      __typename?: "NodeThemeColorPalette"
      _50?: string | null
      _100?: string | null
      _200?: string | null
      _300?: string | null
      _400?: string | null
      _500?: string | null
      _600?: string | null
      _700?: string | null
      _800?: string | null
      _900?: string | null
    } | null
    background?: {
      __typename?: "NodeThemeBackground"
      color?: string | null
      contrast_color?: string | null
      image?: { __typename?: "NodeImage"; src?: string | null } | null
      image_blurred?: { __typename?: "NodeImage"; src?: string | null } | null
      image_blurred_dark?: {
        __typename?: "NodeImage"
        src?: string | null
      } | null
    } | null
  }
}

export type PathQueryVariables = Exact<{
  id: Scalars["Int"]["input"]
}>

export type PathQuery = {
  __typename?: "Query"
  path?: {
    __typename?: "Path"
    id?: number | null
    user?: {
      __typename?: "User"
      id?: number | null
      email?: string | null
      first_name?: string | null
      last_name?: string | null
      full_name?: string | null
      display_name?: string | null
      is_finalized?: boolean | null
      profile?: {
        __typename?: "Profile"
        id?: number | null
        bio?: string | null
        image?: string | null
        location?: string | null
        last_activity?: any | null
        notification_digest_interval?: NotificationDigestInterval | null
      } | null
      membership?: {
        __typename?: "Membership"
        id?: number | null
        role?: number | null
      } | null
    } | null
    meta: {
      __typename?: "NodeMeta"
      id: string
      key: string
      type: string
      url?: string | null
      title?: string | null
      subtitle?: string | null
      description?: string | null
      image?: { __typename?: "NodeImage"; src?: string | null } | null
    }
    theme: {
      __typename?: "NodeTheme"
      color: string
      color_palette?: {
        __typename?: "NodeThemeColorPalette"
        _50?: string | null
        _100?: string | null
        _200?: string | null
        _300?: string | null
        _400?: string | null
        _500?: string | null
        _600?: string | null
        _700?: string | null
        _800?: string | null
        _900?: string | null
      } | null
      background?: {
        __typename?: "NodeThemeBackground"
        color?: string | null
        contrast_color?: string | null
        image?: { __typename?: "NodeImage"; src?: string | null } | null
        image_blurred?: { __typename?: "NodeImage"; src?: string | null } | null
        image_blurred_dark?: {
          __typename?: "NodeImage"
          src?: string | null
        } | null
      } | null
    }
  } | null
}

export type PathItemListViewFragment = {
  __typename?: "PathItem"
  id: number
  source_id: number
  order: number
  name: string
  type: string
  description?: string | null
  due?: any | null
  passive_due?: boolean | null
  starts?: any | null
  passive_starts?: boolean | null
  has_started?: boolean | null
  parent_id?: number | null
  parent_source_id?: number | null
  assignment_type?: PathItemAssignmentType | null
  verb?: string | null
  content_type?: string | null
  content_id?: number | null
  item_content_type_id?: number | null
  points?: number | null
  progress: number
  path_id: number
  time_to_complete?: number | null
  meta: {
    __typename?: "NodeMeta"
    id: string
    key: string
    type: string
    url?: string | null
    title?: string | null
    subtitle?: string | null
    description?: string | null
    image?: { __typename?: "NodeImage"; src?: string | null } | null
  }
  completion?: {
    __typename?: "Completion"
    id?: number | null
    checked_date?: any | null
    completion_attempts?: number | null
    completion_comment?: string | null
    completion_date?: any | null
    completion_due?: any | null
    last_completion_attempt_date?: any | null
    has_failed_passing_attempt?: boolean | null
    has_points?: boolean | null
    is_late?: boolean | null
    is_graded?: boolean | null
    is_skipped?: boolean | null
    is_complete?: boolean | null
    is_auto_graded?: boolean | null
    is_forced_completion?: boolean | null
    needs_grading?: boolean | null
    userpoints_earned?: number | null
    userpoints_explanation?: string | null
    userpoints_value?: number | null
    minimum_passing_score?: number | null
    grading_type?: string | null
    requires_api_completion?: boolean | null
    overridden_by_api?: boolean | null
    overridden_by?: {
      __typename?: "User"
      id?: number | null
      email?: string | null
      first_name?: string | null
      last_name?: string | null
      full_name?: string | null
      display_name?: string | null
      is_finalized?: boolean | null
      profile?: {
        __typename?: "Profile"
        id?: number | null
        bio?: string | null
        image?: string | null
        location?: string | null
        last_activity?: any | null
        notification_digest_interval?: NotificationDigestInterval | null
      } | null
      membership?: {
        __typename?: "Membership"
        id?: number | null
        role?: number | null
      } | null
    } | null
    checked_by?: {
      __typename?: "User"
      id?: number | null
      email?: string | null
      first_name?: string | null
      last_name?: string | null
      full_name?: string | null
      display_name?: string | null
      is_finalized?: boolean | null
      profile?: {
        __typename?: "Profile"
        id?: number | null
        bio?: string | null
        image?: string | null
        location?: string | null
        last_activity?: any | null
        notification_digest_interval?: NotificationDigestInterval | null
      } | null
      membership?: {
        __typename?: "Membership"
        id?: number | null
        role?: number | null
      } | null
    } | null
    user?: {
      __typename?: "User"
      id?: number | null
      email?: string | null
      first_name?: string | null
      last_name?: string | null
      full_name?: string | null
      display_name?: string | null
      is_finalized?: boolean | null
      profile?: {
        __typename?: "Profile"
        id?: number | null
        bio?: string | null
        image?: string | null
        location?: string | null
        last_activity?: any | null
        notification_digest_interval?: NotificationDigestInterval | null
      } | null
      membership?: {
        __typename?: "Membership"
        id?: number | null
        role?: number | null
      } | null
    } | null
    submission?: {
      __typename?: "UserSubmission"
      id?: number | null
      text?: string | null
      file_url?: string | null
      file_name?: string | null
      file_image?: string | null
    } | null
  } | null
}

export type PathItemsQueryVariables = Exact<{
  id: Scalars["Int"]["input"]
  first: Scalars["Int"]["input"]
}>

export type PathItemsQuery = {
  __typename?: "Query"
  pathItems?: {
    __typename?: "PathItemConnection"
    edges?: Array<{
      __typename?: "PathItemEdge"
      node?: {
        __typename?: "PathItem"
        id: number
        source_id: number
        order: number
        name: string
        type: string
        description?: string | null
        due?: any | null
        passive_due?: boolean | null
        starts?: any | null
        passive_starts?: boolean | null
        has_started?: boolean | null
        parent_id?: number | null
        parent_source_id?: number | null
        assignment_type?: PathItemAssignmentType | null
        verb?: string | null
        content_type?: string | null
        content_id?: number | null
        item_content_type_id?: number | null
        points?: number | null
        progress: number
        path_id: number
        time_to_complete?: number | null
        meta: {
          __typename?: "NodeMeta"
          id: string
          key: string
          type: string
          url?: string | null
          title?: string | null
          subtitle?: string | null
          description?: string | null
          image?: { __typename?: "NodeImage"; src?: string | null } | null
        }
        completion?: {
          __typename?: "Completion"
          id?: number | null
          checked_date?: any | null
          completion_attempts?: number | null
          completion_comment?: string | null
          completion_date?: any | null
          completion_due?: any | null
          last_completion_attempt_date?: any | null
          has_failed_passing_attempt?: boolean | null
          has_points?: boolean | null
          is_late?: boolean | null
          is_graded?: boolean | null
          is_skipped?: boolean | null
          is_complete?: boolean | null
          is_auto_graded?: boolean | null
          is_forced_completion?: boolean | null
          needs_grading?: boolean | null
          userpoints_earned?: number | null
          userpoints_explanation?: string | null
          userpoints_value?: number | null
          minimum_passing_score?: number | null
          grading_type?: string | null
          requires_api_completion?: boolean | null
          overridden_by_api?: boolean | null
          overridden_by?: {
            __typename?: "User"
            id?: number | null
            email?: string | null
            first_name?: string | null
            last_name?: string | null
            full_name?: string | null
            display_name?: string | null
            is_finalized?: boolean | null
            profile?: {
              __typename?: "Profile"
              id?: number | null
              bio?: string | null
              image?: string | null
              location?: string | null
              last_activity?: any | null
              notification_digest_interval?: NotificationDigestInterval | null
            } | null
            membership?: {
              __typename?: "Membership"
              id?: number | null
              role?: number | null
            } | null
          } | null
          checked_by?: {
            __typename?: "User"
            id?: number | null
            email?: string | null
            first_name?: string | null
            last_name?: string | null
            full_name?: string | null
            display_name?: string | null
            is_finalized?: boolean | null
            profile?: {
              __typename?: "Profile"
              id?: number | null
              bio?: string | null
              image?: string | null
              location?: string | null
              last_activity?: any | null
              notification_digest_interval?: NotificationDigestInterval | null
            } | null
            membership?: {
              __typename?: "Membership"
              id?: number | null
              role?: number | null
            } | null
          } | null
          user?: {
            __typename?: "User"
            id?: number | null
            email?: string | null
            first_name?: string | null
            last_name?: string | null
            full_name?: string | null
            display_name?: string | null
            is_finalized?: boolean | null
            profile?: {
              __typename?: "Profile"
              id?: number | null
              bio?: string | null
              image?: string | null
              location?: string | null
              last_activity?: any | null
              notification_digest_interval?: NotificationDigestInterval | null
            } | null
            membership?: {
              __typename?: "Membership"
              id?: number | null
              role?: number | null
            } | null
          } | null
          submission?: {
            __typename?: "UserSubmission"
            id?: number | null
            text?: string | null
            file_url?: string | null
            file_name?: string | null
            file_image?: string | null
          } | null
        } | null
      } | null
    } | null> | null
  } | null
}

export type PathItemGroupViewFragment = {
  __typename?: "PathItem"
  id: number
  source_id: number
  order: number
  name: string
  type: string
  description?: string | null
  due?: any | null
  passive_due?: boolean | null
  starts?: any | null
  passive_starts?: boolean | null
  has_started?: boolean | null
  parent_id?: number | null
  parent_source_id?: number | null
  assignment_type?: PathItemAssignmentType | null
  verb?: string | null
  content_type?: string | null
  content_id?: number | null
  item_content_type_id?: number | null
  points?: number | null
  progress: number
  path_id: number
  time_to_complete?: number | null
  items?: {
    __typename?: "PathItemConnection"
    edges?: Array<{
      __typename?: "PathItemEdge"
      node?: {
        __typename?: "PathItem"
        id: number
        source_id: number
        order: number
        name: string
        type: string
        description?: string | null
        due?: any | null
        passive_due?: boolean | null
        starts?: any | null
        passive_starts?: boolean | null
        has_started?: boolean | null
        parent_id?: number | null
        parent_source_id?: number | null
        assignment_type?: PathItemAssignmentType | null
        verb?: string | null
        content_type?: string | null
        content_id?: number | null
        item_content_type_id?: number | null
        points?: number | null
        progress: number
        path_id: number
        time_to_complete?: number | null
        meta: {
          __typename?: "NodeMeta"
          id: string
          key: string
          type: string
          url?: string | null
          title?: string | null
          subtitle?: string | null
          description?: string | null
          image?: { __typename?: "NodeImage"; src?: string | null } | null
        }
        completion?: {
          __typename?: "Completion"
          id?: number | null
          checked_date?: any | null
          completion_attempts?: number | null
          completion_comment?: string | null
          completion_date?: any | null
          completion_due?: any | null
          last_completion_attempt_date?: any | null
          has_failed_passing_attempt?: boolean | null
          has_points?: boolean | null
          is_late?: boolean | null
          is_graded?: boolean | null
          is_skipped?: boolean | null
          is_complete?: boolean | null
          is_auto_graded?: boolean | null
          is_forced_completion?: boolean | null
          needs_grading?: boolean | null
          userpoints_earned?: number | null
          userpoints_explanation?: string | null
          userpoints_value?: number | null
          minimum_passing_score?: number | null
          grading_type?: string | null
          requires_api_completion?: boolean | null
          overridden_by_api?: boolean | null
          overridden_by?: {
            __typename?: "User"
            id?: number | null
            email?: string | null
            first_name?: string | null
            last_name?: string | null
            full_name?: string | null
            display_name?: string | null
            is_finalized?: boolean | null
            profile?: {
              __typename?: "Profile"
              id?: number | null
              bio?: string | null
              image?: string | null
              location?: string | null
              last_activity?: any | null
              notification_digest_interval?: NotificationDigestInterval | null
            } | null
            membership?: {
              __typename?: "Membership"
              id?: number | null
              role?: number | null
            } | null
          } | null
          checked_by?: {
            __typename?: "User"
            id?: number | null
            email?: string | null
            first_name?: string | null
            last_name?: string | null
            full_name?: string | null
            display_name?: string | null
            is_finalized?: boolean | null
            profile?: {
              __typename?: "Profile"
              id?: number | null
              bio?: string | null
              image?: string | null
              location?: string | null
              last_activity?: any | null
              notification_digest_interval?: NotificationDigestInterval | null
            } | null
            membership?: {
              __typename?: "Membership"
              id?: number | null
              role?: number | null
            } | null
          } | null
          user?: {
            __typename?: "User"
            id?: number | null
            email?: string | null
            first_name?: string | null
            last_name?: string | null
            full_name?: string | null
            display_name?: string | null
            is_finalized?: boolean | null
            profile?: {
              __typename?: "Profile"
              id?: number | null
              bio?: string | null
              image?: string | null
              location?: string | null
              last_activity?: any | null
              notification_digest_interval?: NotificationDigestInterval | null
            } | null
            membership?: {
              __typename?: "Membership"
              id?: number | null
              role?: number | null
            } | null
          } | null
          submission?: {
            __typename?: "UserSubmission"
            id?: number | null
            text?: string | null
            file_url?: string | null
            file_name?: string | null
            file_image?: string | null
          } | null
        } | null
      } | null
    } | null> | null
  } | null
  meta: {
    __typename?: "NodeMeta"
    id: string
    key: string
    type: string
    url?: string | null
    title?: string | null
    subtitle?: string | null
    description?: string | null
    image?: { __typename?: "NodeImage"; src?: string | null } | null
  }
  completion?: {
    __typename?: "Completion"
    id?: number | null
    checked_date?: any | null
    completion_attempts?: number | null
    completion_comment?: string | null
    completion_date?: any | null
    completion_due?: any | null
    last_completion_attempt_date?: any | null
    has_failed_passing_attempt?: boolean | null
    has_points?: boolean | null
    is_late?: boolean | null
    is_graded?: boolean | null
    is_skipped?: boolean | null
    is_complete?: boolean | null
    is_auto_graded?: boolean | null
    is_forced_completion?: boolean | null
    needs_grading?: boolean | null
    userpoints_earned?: number | null
    userpoints_explanation?: string | null
    userpoints_value?: number | null
    minimum_passing_score?: number | null
    grading_type?: string | null
    requires_api_completion?: boolean | null
    overridden_by_api?: boolean | null
    overridden_by?: {
      __typename?: "User"
      id?: number | null
      email?: string | null
      first_name?: string | null
      last_name?: string | null
      full_name?: string | null
      display_name?: string | null
      is_finalized?: boolean | null
      profile?: {
        __typename?: "Profile"
        id?: number | null
        bio?: string | null
        image?: string | null
        location?: string | null
        last_activity?: any | null
        notification_digest_interval?: NotificationDigestInterval | null
      } | null
      membership?: {
        __typename?: "Membership"
        id?: number | null
        role?: number | null
      } | null
    } | null
    checked_by?: {
      __typename?: "User"
      id?: number | null
      email?: string | null
      first_name?: string | null
      last_name?: string | null
      full_name?: string | null
      display_name?: string | null
      is_finalized?: boolean | null
      profile?: {
        __typename?: "Profile"
        id?: number | null
        bio?: string | null
        image?: string | null
        location?: string | null
        last_activity?: any | null
        notification_digest_interval?: NotificationDigestInterval | null
      } | null
      membership?: {
        __typename?: "Membership"
        id?: number | null
        role?: number | null
      } | null
    } | null
    user?: {
      __typename?: "User"
      id?: number | null
      email?: string | null
      first_name?: string | null
      last_name?: string | null
      full_name?: string | null
      display_name?: string | null
      is_finalized?: boolean | null
      profile?: {
        __typename?: "Profile"
        id?: number | null
        bio?: string | null
        image?: string | null
        location?: string | null
        last_activity?: any | null
        notification_digest_interval?: NotificationDigestInterval | null
      } | null
      membership?: {
        __typename?: "Membership"
        id?: number | null
        role?: number | null
      } | null
    } | null
    submission?: {
      __typename?: "UserSubmission"
      id?: number | null
      text?: string | null
      file_url?: string | null
      file_name?: string | null
      file_image?: string | null
    } | null
  } | null
}

export type PathItemGroupQueryVariables = Exact<{
  sourceId?: Maybe<Scalars["Int"]["input"]>
  userId?: Maybe<Scalars["Int"]["input"]>
  pathId?: Maybe<Scalars["Int"]["input"]>
}>

export type PathItemGroupQuery = {
  __typename?: "Query"
  pathItem?: {
    __typename?: "PathItem"
    id: number
    source_id: number
    order: number
    name: string
    type: string
    description?: string | null
    due?: any | null
    passive_due?: boolean | null
    starts?: any | null
    passive_starts?: boolean | null
    has_started?: boolean | null
    parent_id?: number | null
    parent_source_id?: number | null
    assignment_type?: PathItemAssignmentType | null
    verb?: string | null
    content_type?: string | null
    content_id?: number | null
    item_content_type_id?: number | null
    points?: number | null
    progress: number
    path_id: number
    time_to_complete?: number | null
    items?: {
      __typename?: "PathItemConnection"
      edges?: Array<{
        __typename?: "PathItemEdge"
        node?: {
          __typename?: "PathItem"
          id: number
          source_id: number
          order: number
          name: string
          type: string
          description?: string | null
          due?: any | null
          passive_due?: boolean | null
          starts?: any | null
          passive_starts?: boolean | null
          has_started?: boolean | null
          parent_id?: number | null
          parent_source_id?: number | null
          assignment_type?: PathItemAssignmentType | null
          verb?: string | null
          content_type?: string | null
          content_id?: number | null
          item_content_type_id?: number | null
          points?: number | null
          progress: number
          path_id: number
          time_to_complete?: number | null
          meta: {
            __typename?: "NodeMeta"
            id: string
            key: string
            type: string
            url?: string | null
            title?: string | null
            subtitle?: string | null
            description?: string | null
            image?: { __typename?: "NodeImage"; src?: string | null } | null
          }
          completion?: {
            __typename?: "Completion"
            id?: number | null
            checked_date?: any | null
            completion_attempts?: number | null
            completion_comment?: string | null
            completion_date?: any | null
            completion_due?: any | null
            last_completion_attempt_date?: any | null
            has_failed_passing_attempt?: boolean | null
            has_points?: boolean | null
            is_late?: boolean | null
            is_graded?: boolean | null
            is_skipped?: boolean | null
            is_complete?: boolean | null
            is_auto_graded?: boolean | null
            is_forced_completion?: boolean | null
            needs_grading?: boolean | null
            userpoints_earned?: number | null
            userpoints_explanation?: string | null
            userpoints_value?: number | null
            minimum_passing_score?: number | null
            grading_type?: string | null
            requires_api_completion?: boolean | null
            overridden_by_api?: boolean | null
            overridden_by?: {
              __typename?: "User"
              id?: number | null
              email?: string | null
              first_name?: string | null
              last_name?: string | null
              full_name?: string | null
              display_name?: string | null
              is_finalized?: boolean | null
              profile?: {
                __typename?: "Profile"
                id?: number | null
                bio?: string | null
                image?: string | null
                location?: string | null
                last_activity?: any | null
                notification_digest_interval?: NotificationDigestInterval | null
              } | null
              membership?: {
                __typename?: "Membership"
                id?: number | null
                role?: number | null
              } | null
            } | null
            checked_by?: {
              __typename?: "User"
              id?: number | null
              email?: string | null
              first_name?: string | null
              last_name?: string | null
              full_name?: string | null
              display_name?: string | null
              is_finalized?: boolean | null
              profile?: {
                __typename?: "Profile"
                id?: number | null
                bio?: string | null
                image?: string | null
                location?: string | null
                last_activity?: any | null
                notification_digest_interval?: NotificationDigestInterval | null
              } | null
              membership?: {
                __typename?: "Membership"
                id?: number | null
                role?: number | null
              } | null
            } | null
            user?: {
              __typename?: "User"
              id?: number | null
              email?: string | null
              first_name?: string | null
              last_name?: string | null
              full_name?: string | null
              display_name?: string | null
              is_finalized?: boolean | null
              profile?: {
                __typename?: "Profile"
                id?: number | null
                bio?: string | null
                image?: string | null
                location?: string | null
                last_activity?: any | null
                notification_digest_interval?: NotificationDigestInterval | null
              } | null
              membership?: {
                __typename?: "Membership"
                id?: number | null
                role?: number | null
              } | null
            } | null
            submission?: {
              __typename?: "UserSubmission"
              id?: number | null
              text?: string | null
              file_url?: string | null
              file_name?: string | null
              file_image?: string | null
            } | null
          } | null
        } | null
      } | null> | null
    } | null
    meta: {
      __typename?: "NodeMeta"
      id: string
      key: string
      type: string
      url?: string | null
      title?: string | null
      subtitle?: string | null
      description?: string | null
      image?: { __typename?: "NodeImage"; src?: string | null } | null
    }
    completion?: {
      __typename?: "Completion"
      id?: number | null
      checked_date?: any | null
      completion_attempts?: number | null
      completion_comment?: string | null
      completion_date?: any | null
      completion_due?: any | null
      last_completion_attempt_date?: any | null
      has_failed_passing_attempt?: boolean | null
      has_points?: boolean | null
      is_late?: boolean | null
      is_graded?: boolean | null
      is_skipped?: boolean | null
      is_complete?: boolean | null
      is_auto_graded?: boolean | null
      is_forced_completion?: boolean | null
      needs_grading?: boolean | null
      userpoints_earned?: number | null
      userpoints_explanation?: string | null
      userpoints_value?: number | null
      minimum_passing_score?: number | null
      grading_type?: string | null
      requires_api_completion?: boolean | null
      overridden_by_api?: boolean | null
      overridden_by?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        is_finalized?: boolean | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          notification_digest_interval?: NotificationDigestInterval | null
        } | null
        membership?: {
          __typename?: "Membership"
          id?: number | null
          role?: number | null
        } | null
      } | null
      checked_by?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        is_finalized?: boolean | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          notification_digest_interval?: NotificationDigestInterval | null
        } | null
        membership?: {
          __typename?: "Membership"
          id?: number | null
          role?: number | null
        } | null
      } | null
      user?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        is_finalized?: boolean | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          notification_digest_interval?: NotificationDigestInterval | null
        } | null
        membership?: {
          __typename?: "Membership"
          id?: number | null
          role?: number | null
        } | null
      } | null
      submission?: {
        __typename?: "UserSubmission"
        id?: number | null
        text?: string | null
        file_url?: string | null
        file_name?: string | null
        file_image?: string | null
      } | null
    } | null
  } | null
}

export type PathsCodexNodeFragment = {
  __typename?: "PathsCodexNode"
  key?: string | null
  type?: string | null
  version?: number | null
}

export type PublishPathToCodexMutationVariables = Exact<{
  pathId: Scalars["Int"]["input"]
  overrideId?: Maybe<Scalars["String"]["input"]>
  upsert?: Maybe<Scalars["Boolean"]["input"]>
  suffix?: Maybe<Scalars["String"]["input"]>
}>

export type PublishPathToCodexMutation = {
  __typename?: "Mutations"
  publishPathToCodex?: {
    __typename?: "CodexPathPublish"
    node?: {
      __typename?: "PathsCodexNode"
      key?: string | null
      type?: string | null
      version?: number | null
    } | null
  } | null
}

export type PublishPathToCodexPermissionQueryVariables = Exact<{
  [key: string]: never
}>

export type PublishPathToCodexPermissionQuery = {
  __typename?: "Query"
  space: {
    __typename?: "Space"
    id: number
    features: { __typename?: "SpaceFeatures"; paths_app__publish: boolean }
  }
  me?: {
    __typename?: "User"
    id?: number | null
    permissions?: {
      __typename?: "ContextPermissions"
      has_pathwright_staff_access?: boolean | null
    } | null
  } | null
}

export type SpaceConnectionsQueryVariables = Exact<{
  first: Scalars["Int"]["input"]
  search?: Maybe<Scalars["String"]["input"]>
}>

export type SpaceConnectionsQuery = {
  __typename?: "Query"
  spaceConnections?: {
    __typename?: "SpaceConnectionConnection"
    total?: number | null
    edges?: Array<{
      __typename?: "SpaceConnectionEdge"
      node?: {
        __typename?: "SpaceConnection"
        role: number
        last_activity_date?: any | null
        space: {
          __typename?: "Space"
          id: number
          name: string
          subdomain: string
          created: string
          is_activated: boolean
          ietf_language_tag?: string | null
          is_owner: boolean
          image: string
          meta: {
            __typename?: "NodeMeta"
            id: string
            key: string
            type: string
            url?: string | null
            title?: string | null
            subtitle?: string | null
            description?: string | null
            image?: { __typename?: "NodeImage"; src?: string | null } | null
          }
          theme: {
            __typename?: "NodeTheme"
            color: string
            color_palette?: {
              __typename?: "NodeThemeColorPalette"
              _50?: string | null
              _100?: string | null
              _200?: string | null
              _300?: string | null
              _400?: string | null
              _500?: string | null
              _600?: string | null
              _700?: string | null
              _800?: string | null
              _900?: string | null
            } | null
            background?: {
              __typename?: "NodeThemeBackground"
              color?: string | null
              contrast_color?: string | null
              image?: { __typename?: "NodeImage"; src?: string | null } | null
              image_blurred?: {
                __typename?: "NodeImage"
                src?: string | null
              } | null
              image_blurred_dark?: {
                __typename?: "NodeImage"
                src?: string | null
              } | null
            } | null
          }
        }
      } | null
    } | null> | null
  } | null
}

export type SpaceContextQueryQueryVariables = Exact<{ [key: string]: never }>

export type SpaceContextQueryQuery = {
  __typename?: "Query"
  user?: {
    __typename?: "User"
    id?: number | null
    email?: string | null
    first_name?: string | null
    last_name?: string | null
    full_name?: string | null
    display_name?: string | null
    is_finalized?: boolean | null
    profile?: {
      __typename?: "Profile"
      id?: number | null
      bio?: string | null
      image?: string | null
      location?: string | null
      last_activity?: any | null
      notification_digest_interval?: NotificationDigestInterval | null
    } | null
    membership?: {
      __typename?: "Membership"
      id?: number | null
      role?: number | null
    } | null
  } | null
  space: {
    __typename?: "Space"
    id: number
    name: string
    subdomain: string
    created: string
    is_activated: boolean
    ietf_language_tag?: string | null
    is_owner: boolean
    image: string
    meta: {
      __typename?: "NodeMeta"
      id: string
      key: string
      type: string
      url?: string | null
      title?: string | null
      subtitle?: string | null
      description?: string | null
      image?: { __typename?: "NodeImage"; src?: string | null } | null
    }
    theme: {
      __typename?: "NodeTheme"
      color: string
      color_palette?: {
        __typename?: "NodeThemeColorPalette"
        _50?: string | null
        _100?: string | null
        _200?: string | null
        _300?: string | null
        _400?: string | null
        _500?: string | null
        _600?: string | null
        _700?: string | null
        _800?: string | null
        _900?: string | null
      } | null
      background?: {
        __typename?: "NodeThemeBackground"
        color?: string | null
        contrast_color?: string | null
        image?: { __typename?: "NodeImage"; src?: string | null } | null
        image_blurred?: { __typename?: "NodeImage"; src?: string | null } | null
        image_blurred_dark?: {
          __typename?: "NodeImage"
          src?: string | null
        } | null
      } | null
    }
  }
}

export type NodeMetaFragment = {
  __typename?: "NodeMeta"
  id: string
  key: string
  type: string
  url?: string | null
  title?: string | null
  subtitle?: string | null
  description?: string | null
  image?: { __typename?: "NodeImage"; src?: string | null } | null
}

export type NodeThemeBackgroundFragment = {
  __typename?: "NodeThemeBackground"
  color?: string | null
  contrast_color?: string | null
  image?: { __typename?: "NodeImage"; src?: string | null } | null
  image_blurred?: { __typename?: "NodeImage"; src?: string | null } | null
  image_blurred_dark?: { __typename?: "NodeImage"; src?: string | null } | null
}

export type NodeThemeFragment = {
  __typename?: "NodeTheme"
  color: string
  color_palette?: {
    __typename?: "NodeThemeColorPalette"
    _50?: string | null
    _100?: string | null
    _200?: string | null
    _300?: string | null
    _400?: string | null
    _500?: string | null
    _600?: string | null
    _700?: string | null
    _800?: string | null
    _900?: string | null
  } | null
  background?: {
    __typename?: "NodeThemeBackground"
    color?: string | null
    contrast_color?: string | null
    image?: { __typename?: "NodeImage"; src?: string | null } | null
    image_blurred?: { __typename?: "NodeImage"; src?: string | null } | null
    image_blurred_dark?: {
      __typename?: "NodeImage"
      src?: string | null
    } | null
  } | null
}

export type SpaceFragment = {
  __typename?: "Space"
  id: number
  name: string
  subdomain: string
  created: string
  is_activated: boolean
  ietf_language_tag?: string | null
  is_owner: boolean
  image: string
  meta: {
    __typename?: "NodeMeta"
    id: string
    key: string
    type: string
    url?: string | null
    title?: string | null
    subtitle?: string | null
    description?: string | null
    image?: { __typename?: "NodeImage"; src?: string | null } | null
  }
  theme: {
    __typename?: "NodeTheme"
    color: string
    color_palette?: {
      __typename?: "NodeThemeColorPalette"
      _50?: string | null
      _100?: string | null
      _200?: string | null
      _300?: string | null
      _400?: string | null
      _500?: string | null
      _600?: string | null
      _700?: string | null
      _800?: string | null
      _900?: string | null
    } | null
    background?: {
      __typename?: "NodeThemeBackground"
      color?: string | null
      contrast_color?: string | null
      image?: { __typename?: "NodeImage"; src?: string | null } | null
      image_blurred?: { __typename?: "NodeImage"; src?: string | null } | null
      image_blurred_dark?: {
        __typename?: "NodeImage"
        src?: string | null
      } | null
    } | null
  }
}

export type SpaceFeaturesFragment = {
  __typename?: "SpaceFeatures"
  auth__login_option__email_link: boolean
  auth__login_option__global_sso__apple: boolean
  auth__login_option__global_sso__google: boolean
  auth__login_option__password: boolean
  auth__platform: boolean
}

export type SpaceFeaturesQueryVariables = Exact<{ [key: string]: never }>

export type SpaceFeaturesQuery = {
  __typename?: "Query"
  space: {
    __typename?: "Space"
    id: number
    features: {
      __typename?: "SpaceFeatures"
      auth__login_option__email_link: boolean
      auth__login_option__global_sso__apple: boolean
      auth__login_option__global_sso__google: boolean
      auth__login_option__password: boolean
      auth__platform: boolean
    }
  }
  user?: {
    __typename?: "User"
    id?: number | null
    is_superuser?: boolean | null
  } | null
}

export type PathItemViewFragment = {
  __typename?: "PathItem"
  id: number
  source_id: number
  order: number
  name: string
  type: string
  description?: string | null
  due?: any | null
  passive_due?: boolean | null
  starts?: any | null
  passive_starts?: boolean | null
  has_started?: boolean | null
  parent_id?: number | null
  parent_source_id?: number | null
  assignment_type?: PathItemAssignmentType | null
  verb?: string | null
  content_type?: string | null
  content_id?: number | null
  item_content_type_id?: number | null
  points?: number | null
  progress: number
  path_id: number
  time_to_complete?: number | null
  meta: {
    __typename?: "NodeMeta"
    id: string
    key: string
    type: string
    url?: string | null
    title?: string | null
    subtitle?: string | null
    description?: string | null
    image?: { __typename?: "NodeImage"; src?: string | null } | null
  }
  completion?: {
    __typename?: "Completion"
    id?: number | null
    checked_date?: any | null
    completion_attempts?: number | null
    completion_comment?: string | null
    completion_date?: any | null
    completion_due?: any | null
    last_completion_attempt_date?: any | null
    has_failed_passing_attempt?: boolean | null
    has_points?: boolean | null
    is_late?: boolean | null
    is_graded?: boolean | null
    is_skipped?: boolean | null
    is_complete?: boolean | null
    is_auto_graded?: boolean | null
    is_forced_completion?: boolean | null
    needs_grading?: boolean | null
    userpoints_earned?: number | null
    userpoints_explanation?: string | null
    userpoints_value?: number | null
    minimum_passing_score?: number | null
    grading_type?: string | null
    requires_api_completion?: boolean | null
    overridden_by_api?: boolean | null
    overridden_by?: {
      __typename?: "User"
      id?: number | null
      email?: string | null
      first_name?: string | null
      last_name?: string | null
      full_name?: string | null
      display_name?: string | null
      is_finalized?: boolean | null
      profile?: {
        __typename?: "Profile"
        id?: number | null
        bio?: string | null
        image?: string | null
        location?: string | null
        last_activity?: any | null
        notification_digest_interval?: NotificationDigestInterval | null
      } | null
      membership?: {
        __typename?: "Membership"
        id?: number | null
        role?: number | null
      } | null
    } | null
    checked_by?: {
      __typename?: "User"
      id?: number | null
      email?: string | null
      first_name?: string | null
      last_name?: string | null
      full_name?: string | null
      display_name?: string | null
      is_finalized?: boolean | null
      profile?: {
        __typename?: "Profile"
        id?: number | null
        bio?: string | null
        image?: string | null
        location?: string | null
        last_activity?: any | null
        notification_digest_interval?: NotificationDigestInterval | null
      } | null
      membership?: {
        __typename?: "Membership"
        id?: number | null
        role?: number | null
      } | null
    } | null
    user?: {
      __typename?: "User"
      id?: number | null
      email?: string | null
      first_name?: string | null
      last_name?: string | null
      full_name?: string | null
      display_name?: string | null
      is_finalized?: boolean | null
      profile?: {
        __typename?: "Profile"
        id?: number | null
        bio?: string | null
        image?: string | null
        location?: string | null
        last_activity?: any | null
        notification_digest_interval?: NotificationDigestInterval | null
      } | null
      membership?: {
        __typename?: "Membership"
        id?: number | null
        role?: number | null
      } | null
    } | null
    submission?: {
      __typename?: "UserSubmission"
      id?: number | null
      text?: string | null
      file_url?: string | null
      file_name?: string | null
      file_image?: string | null
    } | null
  } | null
}

export type PathStepQueryVariables = Exact<{
  sourceId?: Maybe<Scalars["Int"]["input"]>
  userId?: Maybe<Scalars["Int"]["input"]>
  pathId?: Maybe<Scalars["Int"]["input"]>
}>

export type PathStepQuery = {
  __typename?: "Query"
  pathItem?: {
    __typename?: "PathItem"
    id: number
    source_id: number
    order: number
    name: string
    type: string
    description?: string | null
    due?: any | null
    passive_due?: boolean | null
    starts?: any | null
    passive_starts?: boolean | null
    has_started?: boolean | null
    parent_id?: number | null
    parent_source_id?: number | null
    assignment_type?: PathItemAssignmentType | null
    verb?: string | null
    content_type?: string | null
    content_id?: number | null
    item_content_type_id?: number | null
    points?: number | null
    progress: number
    path_id: number
    time_to_complete?: number | null
    meta: {
      __typename?: "NodeMeta"
      id: string
      key: string
      type: string
      url?: string | null
      title?: string | null
      subtitle?: string | null
      description?: string | null
      image?: { __typename?: "NodeImage"; src?: string | null } | null
    }
    completion?: {
      __typename?: "Completion"
      id?: number | null
      checked_date?: any | null
      completion_attempts?: number | null
      completion_comment?: string | null
      completion_date?: any | null
      completion_due?: any | null
      last_completion_attempt_date?: any | null
      has_failed_passing_attempt?: boolean | null
      has_points?: boolean | null
      is_late?: boolean | null
      is_graded?: boolean | null
      is_skipped?: boolean | null
      is_complete?: boolean | null
      is_auto_graded?: boolean | null
      is_forced_completion?: boolean | null
      needs_grading?: boolean | null
      userpoints_earned?: number | null
      userpoints_explanation?: string | null
      userpoints_value?: number | null
      minimum_passing_score?: number | null
      grading_type?: string | null
      requires_api_completion?: boolean | null
      overridden_by_api?: boolean | null
      overridden_by?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        is_finalized?: boolean | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          notification_digest_interval?: NotificationDigestInterval | null
        } | null
        membership?: {
          __typename?: "Membership"
          id?: number | null
          role?: number | null
        } | null
      } | null
      checked_by?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        is_finalized?: boolean | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          notification_digest_interval?: NotificationDigestInterval | null
        } | null
        membership?: {
          __typename?: "Membership"
          id?: number | null
          role?: number | null
        } | null
      } | null
      user?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        is_finalized?: boolean | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          notification_digest_interval?: NotificationDigestInterval | null
        } | null
        membership?: {
          __typename?: "Membership"
          id?: number | null
          role?: number | null
        } | null
      } | null
      submission?: {
        __typename?: "UserSubmission"
        id?: number | null
        text?: string | null
        file_url?: string | null
        file_name?: string | null
        file_image?: string | null
      } | null
    } | null
  } | null
}

export type CompletePathItemMutationVariables = Exact<{
  id: Scalars["Int"]["input"]
  pathId: Scalars["Int"]["input"]
  skip?: Maybe<Scalars["Boolean"]["input"]>
}>

export type CompletePathItemMutation = {
  __typename?: "Mutations"
  completePathItem?: {
    __typename?: "PathItem"
    id: number
    source_id: number
    order: number
    name: string
    type: string
    description?: string | null
    due?: any | null
    passive_due?: boolean | null
    starts?: any | null
    passive_starts?: boolean | null
    has_started?: boolean | null
    parent_id?: number | null
    parent_source_id?: number | null
    assignment_type?: PathItemAssignmentType | null
    verb?: string | null
    content_type?: string | null
    content_id?: number | null
    item_content_type_id?: number | null
    points?: number | null
    progress: number
    path_id: number
    time_to_complete?: number | null
    meta: {
      __typename?: "NodeMeta"
      id: string
      key: string
      type: string
      url?: string | null
      title?: string | null
      subtitle?: string | null
      description?: string | null
      image?: { __typename?: "NodeImage"; src?: string | null } | null
    }
    completion?: {
      __typename?: "Completion"
      id?: number | null
      checked_date?: any | null
      completion_attempts?: number | null
      completion_comment?: string | null
      completion_date?: any | null
      completion_due?: any | null
      last_completion_attempt_date?: any | null
      has_failed_passing_attempt?: boolean | null
      has_points?: boolean | null
      is_late?: boolean | null
      is_graded?: boolean | null
      is_skipped?: boolean | null
      is_complete?: boolean | null
      is_auto_graded?: boolean | null
      is_forced_completion?: boolean | null
      needs_grading?: boolean | null
      userpoints_earned?: number | null
      userpoints_explanation?: string | null
      userpoints_value?: number | null
      minimum_passing_score?: number | null
      grading_type?: string | null
      requires_api_completion?: boolean | null
      overridden_by_api?: boolean | null
      overridden_by?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        is_finalized?: boolean | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          notification_digest_interval?: NotificationDigestInterval | null
        } | null
        membership?: {
          __typename?: "Membership"
          id?: number | null
          role?: number | null
        } | null
      } | null
      checked_by?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        is_finalized?: boolean | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          notification_digest_interval?: NotificationDigestInterval | null
        } | null
        membership?: {
          __typename?: "Membership"
          id?: number | null
          role?: number | null
        } | null
      } | null
      user?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        is_finalized?: boolean | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          notification_digest_interval?: NotificationDigestInterval | null
        } | null
        membership?: {
          __typename?: "Membership"
          id?: number | null
          role?: number | null
        } | null
      } | null
      submission?: {
        __typename?: "UserSubmission"
        id?: number | null
        text?: string | null
        file_url?: string | null
        file_name?: string | null
        file_image?: string | null
      } | null
    } | null
  } | null
}

export type CompletionFragmentFragment = {
  __typename?: "Completion"
  id?: number | null
  checked_date?: any | null
  completion_attempts?: number | null
  completion_comment?: string | null
  completion_date?: any | null
  completion_due?: any | null
  last_completion_attempt_date?: any | null
  has_failed_passing_attempt?: boolean | null
  has_points?: boolean | null
  is_late?: boolean | null
  is_graded?: boolean | null
  is_skipped?: boolean | null
  is_complete?: boolean | null
  is_auto_graded?: boolean | null
  is_forced_completion?: boolean | null
  needs_grading?: boolean | null
  userpoints_earned?: number | null
  userpoints_explanation?: string | null
  userpoints_value?: number | null
  minimum_passing_score?: number | null
  grading_type?: string | null
  requires_api_completion?: boolean | null
  overridden_by_api?: boolean | null
  overridden_by?: {
    __typename?: "User"
    id?: number | null
    email?: string | null
    first_name?: string | null
    last_name?: string | null
    full_name?: string | null
    display_name?: string | null
    is_finalized?: boolean | null
    profile?: {
      __typename?: "Profile"
      id?: number | null
      bio?: string | null
      image?: string | null
      location?: string | null
      last_activity?: any | null
      notification_digest_interval?: NotificationDigestInterval | null
    } | null
    membership?: {
      __typename?: "Membership"
      id?: number | null
      role?: number | null
    } | null
  } | null
  checked_by?: {
    __typename?: "User"
    id?: number | null
    email?: string | null
    first_name?: string | null
    last_name?: string | null
    full_name?: string | null
    display_name?: string | null
    is_finalized?: boolean | null
    profile?: {
      __typename?: "Profile"
      id?: number | null
      bio?: string | null
      image?: string | null
      location?: string | null
      last_activity?: any | null
      notification_digest_interval?: NotificationDigestInterval | null
    } | null
    membership?: {
      __typename?: "Membership"
      id?: number | null
      role?: number | null
    } | null
  } | null
  user?: {
    __typename?: "User"
    id?: number | null
    email?: string | null
    first_name?: string | null
    last_name?: string | null
    full_name?: string | null
    display_name?: string | null
    is_finalized?: boolean | null
    profile?: {
      __typename?: "Profile"
      id?: number | null
      bio?: string | null
      image?: string | null
      location?: string | null
      last_activity?: any | null
      notification_digest_interval?: NotificationDigestInterval | null
    } | null
    membership?: {
      __typename?: "Membership"
      id?: number | null
      role?: number | null
    } | null
  } | null
  submission?: {
    __typename?: "UserSubmission"
    id?: number | null
    text?: string | null
    file_url?: string | null
    file_name?: string | null
    file_image?: string | null
  } | null
}

export type ResetPathItemMutationVariables = Exact<{
  id: Scalars["Int"]["input"]
  pathId: Scalars["Int"]["input"]
}>

export type ResetPathItemMutation = {
  __typename?: "Mutations"
  resetPathItem?: {
    __typename?: "PathItem"
    id: number
    source_id: number
    order: number
    name: string
    type: string
    description?: string | null
    due?: any | null
    passive_due?: boolean | null
    starts?: any | null
    passive_starts?: boolean | null
    has_started?: boolean | null
    parent_id?: number | null
    parent_source_id?: number | null
    assignment_type?: PathItemAssignmentType | null
    verb?: string | null
    content_type?: string | null
    content_id?: number | null
    item_content_type_id?: number | null
    points?: number | null
    progress: number
    path_id: number
    time_to_complete?: number | null
    meta: {
      __typename?: "NodeMeta"
      id: string
      key: string
      type: string
      url?: string | null
      title?: string | null
      subtitle?: string | null
      description?: string | null
      image?: { __typename?: "NodeImage"; src?: string | null } | null
    }
    completion?: {
      __typename?: "Completion"
      id?: number | null
      checked_date?: any | null
      completion_attempts?: number | null
      completion_comment?: string | null
      completion_date?: any | null
      completion_due?: any | null
      last_completion_attempt_date?: any | null
      has_failed_passing_attempt?: boolean | null
      has_points?: boolean | null
      is_late?: boolean | null
      is_graded?: boolean | null
      is_skipped?: boolean | null
      is_complete?: boolean | null
      is_auto_graded?: boolean | null
      is_forced_completion?: boolean | null
      needs_grading?: boolean | null
      userpoints_earned?: number | null
      userpoints_explanation?: string | null
      userpoints_value?: number | null
      minimum_passing_score?: number | null
      grading_type?: string | null
      requires_api_completion?: boolean | null
      overridden_by_api?: boolean | null
      overridden_by?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        is_finalized?: boolean | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          notification_digest_interval?: NotificationDigestInterval | null
        } | null
        membership?: {
          __typename?: "Membership"
          id?: number | null
          role?: number | null
        } | null
      } | null
      checked_by?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        is_finalized?: boolean | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          notification_digest_interval?: NotificationDigestInterval | null
        } | null
        membership?: {
          __typename?: "Membership"
          id?: number | null
          role?: number | null
        } | null
      } | null
      user?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        is_finalized?: boolean | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          notification_digest_interval?: NotificationDigestInterval | null
        } | null
        membership?: {
          __typename?: "Membership"
          id?: number | null
          role?: number | null
        } | null
      } | null
      submission?: {
        __typename?: "UserSubmission"
        id?: number | null
        text?: string | null
        file_url?: string | null
        file_name?: string | null
        file_image?: string | null
      } | null
    } | null
  } | null
}

export type PathStepCompletionQueryVariables = Exact<{
  sourceId?: Maybe<Scalars["Int"]["input"]>
  userId?: Maybe<Scalars["Int"]["input"]>
  pathId?: Maybe<Scalars["Int"]["input"]>
}>

export type PathStepCompletionQuery = {
  __typename?: "Query"
  pathItem?: {
    __typename?: "PathItem"
    id: number
    completion?: {
      __typename?: "Completion"
      id?: number | null
      checked_date?: any | null
      completion_attempts?: number | null
      completion_comment?: string | null
      completion_date?: any | null
      completion_due?: any | null
      last_completion_attempt_date?: any | null
      has_failed_passing_attempt?: boolean | null
      has_points?: boolean | null
      is_late?: boolean | null
      is_graded?: boolean | null
      is_skipped?: boolean | null
      is_complete?: boolean | null
      is_auto_graded?: boolean | null
      is_forced_completion?: boolean | null
      needs_grading?: boolean | null
      userpoints_earned?: number | null
      userpoints_explanation?: string | null
      userpoints_value?: number | null
      minimum_passing_score?: number | null
      grading_type?: string | null
      requires_api_completion?: boolean | null
      overridden_by_api?: boolean | null
      overridden_by?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        is_finalized?: boolean | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          notification_digest_interval?: NotificationDigestInterval | null
        } | null
        membership?: {
          __typename?: "Membership"
          id?: number | null
          role?: number | null
        } | null
      } | null
      checked_by?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        is_finalized?: boolean | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          notification_digest_interval?: NotificationDigestInterval | null
        } | null
        membership?: {
          __typename?: "Membership"
          id?: number | null
          role?: number | null
        } | null
      } | null
      user?: {
        __typename?: "User"
        id?: number | null
        email?: string | null
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        display_name?: string | null
        is_finalized?: boolean | null
        profile?: {
          __typename?: "Profile"
          id?: number | null
          bio?: string | null
          image?: string | null
          location?: string | null
          last_activity?: any | null
          notification_digest_interval?: NotificationDigestInterval | null
        } | null
        membership?: {
          __typename?: "Membership"
          id?: number | null
          role?: number | null
        } | null
      } | null
      submission?: {
        __typename?: "UserSubmission"
        id?: number | null
        text?: string | null
        file_url?: string | null
        file_name?: string | null
        file_image?: string | null
      } | null
    } | null
  } | null
}

export type StoreAuthorsQueryVariables = Exact<{
  first: Scalars["Int"]["input"]
  itemsFirst2: Scalars["Int"]["input"]
}>

export type StoreAuthorsQuery = {
  __typename?: "Query"
  storeCollections?: Array<{
    __typename?: "StoreCollection"
    items?: {
      __typename?: "StoreItemConnection"
      edges?: Array<{
        __typename?: "StoreItemEdge"
        node?: {
          __typename?: "StoreItem"
          authors?: {
            __typename?: "AuthorConnection"
            edges?: Array<{
              __typename?: "AuthorEdge"
              node?: {
                __typename?: "Author"
                id?: number | null
                full_name?: string | null
              } | null
            } | null> | null
          } | null
        } | null
      } | null> | null
    } | null
  } | null> | null
}

export type StoreQueryQueryVariables = Exact<{
  first: Scalars["Int"]["input"]
  categoryId?: Maybe<Scalars["Int"]["input"]>
  authorId?: Maybe<Scalars["Int"]["input"]>
}>

export type StoreQueryQuery = {
  __typename?: "Query"
  storeBanners?: Array<{
    __typename?: "StoreBanner"
    id?: number | null
    button_text?: string | null
    button_url?: string | null
    meta: {
      __typename?: "NodeMeta"
      id: string
      key: string
      type: string
      url?: string | null
      title?: string | null
      subtitle?: string | null
      description?: string | null
      banner?: {
        __typename?: "NodeImage"
        src?: string | null
        width?: number | null
      } | null
      image?: { __typename?: "NodeImage"; src?: string | null } | null
    }
  } | null> | null
  storeCollections?: Array<{
    __typename?: "StoreCollection"
    id?: number | null
    meta: {
      __typename?: "NodeMeta"
      id: string
      key: string
      type: string
      url?: string | null
      title?: string | null
      subtitle?: string | null
      description?: string | null
      image?: { __typename?: "NodeImage"; src?: string | null } | null
    }
    items?: {
      __typename?: "StoreItemConnection"
      edges?: Array<{
        __typename?: "StoreItemEdge"
        node?: {
          __typename?: "StoreItem"
          id?: number | null
          meta: {
            __typename?: "NodeMeta"
            id: string
            key: string
            type: string
            url?: string | null
            title?: string | null
            subtitle?: string | null
            description?: string | null
            cover?: {
              __typename?: "NodeImage"
              src?: string | null
              width?: number | null
            } | null
            image?: { __typename?: "NodeImage"; src?: string | null } | null
          }
          authors?: {
            __typename?: "AuthorConnection"
            edges?: Array<{
              __typename?: "AuthorEdge"
              node?: {
                __typename?: "Author"
                id?: number | null
                meta: {
                  __typename?: "NodeMeta"
                  id: string
                  key: string
                  type: string
                  url?: string | null
                  title?: string | null
                  subtitle?: string | null
                  description?: string | null
                  image?: {
                    __typename?: "NodeImage"
                    src?: string | null
                  } | null
                }
              } | null
            } | null> | null
          } | null
        } | null
      } | null> | null
    } | null
  } | null> | null
}

export type StoreBannerViewFragment = {
  __typename?: "StoreBanner"
  id?: number | null
  button_text?: string | null
  button_url?: string | null
  meta: {
    __typename?: "NodeMeta"
    id: string
    key: string
    type: string
    url?: string | null
    title?: string | null
    subtitle?: string | null
    description?: string | null
    banner?: {
      __typename?: "NodeImage"
      src?: string | null
      width?: number | null
    } | null
    image?: { __typename?: "NodeImage"; src?: string | null } | null
  }
}

export type StoreItemListViewFragment = {
  __typename?: "StoreItem"
  id?: number | null
  meta: {
    __typename?: "NodeMeta"
    id: string
    key: string
    type: string
    url?: string | null
    title?: string | null
    subtitle?: string | null
    description?: string | null
    cover?: {
      __typename?: "NodeImage"
      src?: string | null
      width?: number | null
    } | null
    image?: { __typename?: "NodeImage"; src?: string | null } | null
  }
  authors?: {
    __typename?: "AuthorConnection"
    edges?: Array<{
      __typename?: "AuthorEdge"
      node?: {
        __typename?: "Author"
        id?: number | null
        meta: {
          __typename?: "NodeMeta"
          id: string
          key: string
          type: string
          url?: string | null
          title?: string | null
          subtitle?: string | null
          description?: string | null
          image?: { __typename?: "NodeImage"; src?: string | null } | null
        }
      } | null
    } | null> | null
  } | null
}

export type StoreAuthorQueryVariables = Exact<{
  storeCollectionId: Scalars["Int"]["input"]
  first: Scalars["Int"]["input"]
  categoryId?: Maybe<Scalars["Int"]["input"]>
  authorId?: Maybe<Scalars["Int"]["input"]>
}>

export type StoreAuthorQuery = {
  __typename?: "Query"
  storeCollection?: {
    __typename?: "StoreCollection"
    id?: number | null
    meta: {
      __typename?: "NodeMeta"
      id: string
      key: string
      type: string
      url?: string | null
      title?: string | null
      subtitle?: string | null
      description?: string | null
      image?: { __typename?: "NodeImage"; src?: string | null } | null
    }
    items?: {
      __typename?: "StoreItemConnection"
      edges?: Array<{
        __typename?: "StoreItemEdge"
        node?: {
          __typename?: "StoreItem"
          id?: number | null
          meta: {
            __typename?: "NodeMeta"
            id: string
            key: string
            type: string
            url?: string | null
            title?: string | null
            subtitle?: string | null
            description?: string | null
            cover?: {
              __typename?: "NodeImage"
              src?: string | null
              width?: number | null
            } | null
            image?: { __typename?: "NodeImage"; src?: string | null } | null
          }
          authors?: {
            __typename?: "AuthorConnection"
            edges?: Array<{
              __typename?: "AuthorEdge"
              node?: {
                __typename?: "Author"
                id?: number | null
                meta: {
                  __typename?: "NodeMeta"
                  id: string
                  key: string
                  type: string
                  url?: string | null
                  title?: string | null
                  subtitle?: string | null
                  description?: string | null
                  image?: {
                    __typename?: "NodeImage"
                    src?: string | null
                  } | null
                }
              } | null
            } | null> | null
          } | null
        } | null
      } | null> | null
    } | null
  } | null
}

export type StoreCollectionQueryVariables = Exact<{
  storeCollectionId: Scalars["Int"]["input"]
  first: Scalars["Int"]["input"]
  categoryId?: Maybe<Scalars["Int"]["input"]>
  authorId?: Maybe<Scalars["Int"]["input"]>
}>

export type StoreCollectionQuery = {
  __typename?: "Query"
  storeCollection?: {
    __typename?: "StoreCollection"
    id?: number | null
    meta: {
      __typename?: "NodeMeta"
      id: string
      key: string
      type: string
      url?: string | null
      title?: string | null
      subtitle?: string | null
      description?: string | null
      image?: { __typename?: "NodeImage"; src?: string | null } | null
    }
    items?: {
      __typename?: "StoreItemConnection"
      edges?: Array<{
        __typename?: "StoreItemEdge"
        node?: {
          __typename?: "StoreItem"
          id?: number | null
          meta: {
            __typename?: "NodeMeta"
            id: string
            key: string
            type: string
            url?: string | null
            title?: string | null
            subtitle?: string | null
            description?: string | null
            cover?: {
              __typename?: "NodeImage"
              src?: string | null
              width?: number | null
            } | null
            image?: { __typename?: "NodeImage"; src?: string | null } | null
          }
          authors?: {
            __typename?: "AuthorConnection"
            edges?: Array<{
              __typename?: "AuthorEdge"
              node?: {
                __typename?: "Author"
                id?: number | null
                meta: {
                  __typename?: "NodeMeta"
                  id: string
                  key: string
                  type: string
                  url?: string | null
                  title?: string | null
                  subtitle?: string | null
                  description?: string | null
                  image?: {
                    __typename?: "NodeImage"
                    src?: string | null
                  } | null
                }
              } | null
            } | null> | null
          } | null
        } | null
      } | null> | null
    } | null
  } | null
}

export type StoreSearchQueryQueryVariables = Exact<{
  first: Scalars["Int"]["input"]
  search?: Maybe<Scalars["String"]["input"]>
  categorySearch?: Maybe<Scalars["String"]["input"]>
  authorSearch?: Maybe<Scalars["String"]["input"]>
}>

export type StoreSearchQueryQuery = {
  __typename?: "Query"
  storeCollections?: Array<{
    __typename?: "StoreCollection"
    id?: number | null
    meta: {
      __typename?: "NodeMeta"
      id: string
      key: string
      type: string
      url?: string | null
      title?: string | null
      subtitle?: string | null
      description?: string | null
      image?: { __typename?: "NodeImage"; src?: string | null } | null
    }
    items?: {
      __typename?: "StoreItemConnection"
      edges?: Array<{
        __typename?: "StoreItemEdge"
        node?: {
          __typename?: "StoreItem"
          id?: number | null
          meta: {
            __typename?: "NodeMeta"
            id: string
            key: string
            type: string
            url?: string | null
            title?: string | null
            subtitle?: string | null
            description?: string | null
            image?: { __typename?: "NodeImage"; src?: string | null } | null
          }
          authors?: {
            __typename?: "AuthorConnection"
            edges?: Array<{
              __typename?: "AuthorEdge"
              node?: {
                __typename?: "Author"
                id?: number | null
                first_name?: string | null
                full_name?: string | null
                display_name?: string | null
                image?: string | null
                last_name?: string | null
                order?: number | null
                salutation?: string | null
                bio?: string | null
                slug?: string | null
              } | null
            } | null> | null
          } | null
        } | null
      } | null> | null
    } | null
  } | null> | null
}

export type PathPreviewQueryVariables = Exact<{
  id: Scalars["Int"]["input"]
}>

export type PathPreviewQuery = {
  __typename?: "Query"
  path?: {
    __typename?: "Path"
    id?: number | null
    user?: {
      __typename?: "User"
      id?: number | null
      email?: string | null
      first_name?: string | null
      last_name?: string | null
      full_name?: string | null
      display_name?: string | null
      is_finalized?: boolean | null
      profile?: {
        __typename?: "Profile"
        id?: number | null
        bio?: string | null
        image?: string | null
        location?: string | null
        last_activity?: any | null
        notification_digest_interval?: NotificationDigestInterval | null
      } | null
      membership?: {
        __typename?: "Membership"
        id?: number | null
        role?: number | null
      } | null
    } | null
    meta: {
      __typename?: "NodeMeta"
      id: string
      key: string
      type: string
      url?: string | null
      title?: string | null
      subtitle?: string | null
      description?: string | null
      image?: { __typename?: "NodeImage"; src?: string | null } | null
    }
    theme: {
      __typename?: "NodeTheme"
      color: string
      color_palette?: {
        __typename?: "NodeThemeColorPalette"
        _50?: string | null
        _100?: string | null
        _200?: string | null
        _300?: string | null
        _400?: string | null
        _500?: string | null
        _600?: string | null
        _700?: string | null
        _800?: string | null
        _900?: string | null
      } | null
      background?: {
        __typename?: "NodeThemeBackground"
        color?: string | null
        contrast_color?: string | null
        image?: { __typename?: "NodeImage"; src?: string | null } | null
        image_blurred?: { __typename?: "NodeImage"; src?: string | null } | null
        image_blurred_dark?: {
          __typename?: "NodeImage"
          src?: string | null
        } | null
      } | null
    }
  } | null
  pathItems?: {
    __typename?: "PathItemConnection"
    edges?: Array<{
      __typename?: "PathItemEdge"
      node?: {
        __typename?: "PathItem"
        id: number
        source_id: number
        order: number
        name: string
        type: string
        description?: string | null
        due?: any | null
        passive_due?: boolean | null
        starts?: any | null
        passive_starts?: boolean | null
        has_started?: boolean | null
        parent_id?: number | null
        parent_source_id?: number | null
        assignment_type?: PathItemAssignmentType | null
        verb?: string | null
        content_type?: string | null
        content_id?: number | null
        item_content_type_id?: number | null
        points?: number | null
        progress: number
        path_id: number
        time_to_complete?: number | null
        meta: {
          __typename?: "NodeMeta"
          id: string
          key: string
          type: string
          url?: string | null
          title?: string | null
          subtitle?: string | null
          description?: string | null
          image?: { __typename?: "NodeImage"; src?: string | null } | null
        }
        completion?: {
          __typename?: "Completion"
          id?: number | null
          checked_date?: any | null
          completion_attempts?: number | null
          completion_comment?: string | null
          completion_date?: any | null
          completion_due?: any | null
          last_completion_attempt_date?: any | null
          has_failed_passing_attempt?: boolean | null
          has_points?: boolean | null
          is_late?: boolean | null
          is_graded?: boolean | null
          is_skipped?: boolean | null
          is_complete?: boolean | null
          is_auto_graded?: boolean | null
          is_forced_completion?: boolean | null
          needs_grading?: boolean | null
          userpoints_earned?: number | null
          userpoints_explanation?: string | null
          userpoints_value?: number | null
          minimum_passing_score?: number | null
          grading_type?: string | null
          requires_api_completion?: boolean | null
          overridden_by_api?: boolean | null
          overridden_by?: {
            __typename?: "User"
            id?: number | null
            email?: string | null
            first_name?: string | null
            last_name?: string | null
            full_name?: string | null
            display_name?: string | null
            is_finalized?: boolean | null
            profile?: {
              __typename?: "Profile"
              id?: number | null
              bio?: string | null
              image?: string | null
              location?: string | null
              last_activity?: any | null
              notification_digest_interval?: NotificationDigestInterval | null
            } | null
            membership?: {
              __typename?: "Membership"
              id?: number | null
              role?: number | null
            } | null
          } | null
          checked_by?: {
            __typename?: "User"
            id?: number | null
            email?: string | null
            first_name?: string | null
            last_name?: string | null
            full_name?: string | null
            display_name?: string | null
            is_finalized?: boolean | null
            profile?: {
              __typename?: "Profile"
              id?: number | null
              bio?: string | null
              image?: string | null
              location?: string | null
              last_activity?: any | null
              notification_digest_interval?: NotificationDigestInterval | null
            } | null
            membership?: {
              __typename?: "Membership"
              id?: number | null
              role?: number | null
            } | null
          } | null
          user?: {
            __typename?: "User"
            id?: number | null
            email?: string | null
            first_name?: string | null
            last_name?: string | null
            full_name?: string | null
            display_name?: string | null
            is_finalized?: boolean | null
            profile?: {
              __typename?: "Profile"
              id?: number | null
              bio?: string | null
              image?: string | null
              location?: string | null
              last_activity?: any | null
              notification_digest_interval?: NotificationDigestInterval | null
            } | null
            membership?: {
              __typename?: "Membership"
              id?: number | null
              role?: number | null
            } | null
          } | null
          submission?: {
            __typename?: "UserSubmission"
            id?: number | null
            text?: string | null
            file_url?: string | null
            file_name?: string | null
            file_image?: string | null
          } | null
        } | null
      } | null
    } | null> | null
  } | null
}

export type RelatedItemsQueryQueryVariables = Exact<{
  storeCollectionId: Scalars["Int"]["input"]
  first: Scalars["Int"]["input"]
}>

export type RelatedItemsQueryQuery = {
  __typename?: "Query"
  storeCollection?: {
    __typename?: "StoreCollection"
    id?: number | null
    meta: {
      __typename?: "NodeMeta"
      id: string
      key: string
      type: string
      url?: string | null
      title?: string | null
      subtitle?: string | null
      description?: string | null
      image?: { __typename?: "NodeImage"; src?: string | null } | null
    }
    items?: {
      __typename?: "StoreItemConnection"
      edges?: Array<{
        __typename?: "StoreItemEdge"
        node?: {
          __typename?: "StoreItem"
          id?: number | null
          meta: {
            __typename?: "NodeMeta"
            id: string
            key: string
            type: string
            url?: string | null
            title?: string | null
            subtitle?: string | null
            description?: string | null
            cover?: {
              __typename?: "NodeImage"
              src?: string | null
              width?: number | null
            } | null
            image?: { __typename?: "NodeImage"; src?: string | null } | null
          }
          authors?: {
            __typename?: "AuthorConnection"
            edges?: Array<{
              __typename?: "AuthorEdge"
              node?: {
                __typename?: "Author"
                id?: number | null
                meta: {
                  __typename?: "NodeMeta"
                  id: string
                  key: string
                  type: string
                  url?: string | null
                  title?: string | null
                  subtitle?: string | null
                  description?: string | null
                  image?: {
                    __typename?: "NodeImage"
                    src?: string | null
                  } | null
                }
              } | null
            } | null> | null
          } | null
        } | null
      } | null> | null
    } | null
  } | null
}

export type StoreItemQueryVariables = Exact<{
  storeItemId: Scalars["Int"]["input"]
}>

export type StoreItemQuery = {
  __typename?: "Query"
  storeItem?: {
    __typename?: "StoreItem"
    id?: number | null
    category_ids: Array<number | null>
    short_description?: string | null
    description?: string | null
    meta: {
      __typename?: "NodeMeta"
      id: string
      key: string
      type: string
      url?: string | null
      title?: string | null
      subtitle?: string | null
      description?: string | null
      cover?: {
        __typename?: "NodeImage"
        src?: string | null
        width?: number | null
        color?: string | null
      } | null
      cover_default?: {
        __typename?: "NodeImage"
        src?: string | null
        width?: number | null
        color?: string | null
      } | null
      image?: { __typename?: "NodeImage"; src?: string | null } | null
    }
    theme: {
      __typename?: "NodeTheme"
      background?: {
        __typename?: "NodeThemeBackground"
        image?: {
          __typename?: "NodeImage"
          src?: string | null
          width?: number | null
        } | null
      } | null
    }
    options: Array<{
      __typename?: "StoreItemOption"
      id?: number | null
      price?: number | null
      path_id?: number | null
      meta: {
        __typename?: "NodeMeta"
        id: string
        key: string
        type: string
        url?: string | null
        title?: string | null
        subtitle?: string | null
        description?: string | null
        image?: { __typename?: "NodeImage"; src?: string | null } | null
      }
    } | null>
    authors?: {
      __typename?: "AuthorConnection"
      edges?: Array<{
        __typename?: "AuthorEdge"
        node?: {
          __typename?: "Author"
          meta: {
            __typename?: "NodeMeta"
            id: string
            key: string
            type: string
            url?: string | null
            title?: string | null
            subtitle?: string | null
            description?: string | null
            image?: { __typename?: "NodeImage"; src?: string | null } | null
          }
        } | null
      } | null> | null
    } | null
  } | null
}

export type StoreItemViewFragment = {
  __typename?: "StoreItem"
  id?: number | null
  category_ids: Array<number | null>
  short_description?: string | null
  description?: string | null
  meta: {
    __typename?: "NodeMeta"
    id: string
    key: string
    type: string
    url?: string | null
    title?: string | null
    subtitle?: string | null
    description?: string | null
    cover?: {
      __typename?: "NodeImage"
      src?: string | null
      width?: number | null
      color?: string | null
    } | null
    cover_default?: {
      __typename?: "NodeImage"
      src?: string | null
      width?: number | null
      color?: string | null
    } | null
    image?: { __typename?: "NodeImage"; src?: string | null } | null
  }
  theme: {
    __typename?: "NodeTheme"
    background?: {
      __typename?: "NodeThemeBackground"
      image?: {
        __typename?: "NodeImage"
        src?: string | null
        width?: number | null
      } | null
    } | null
  }
  options: Array<{
    __typename?: "StoreItemOption"
    id?: number | null
    price?: number | null
    path_id?: number | null
    meta: {
      __typename?: "NodeMeta"
      id: string
      key: string
      type: string
      url?: string | null
      title?: string | null
      subtitle?: string | null
      description?: string | null
      image?: { __typename?: "NodeImage"; src?: string | null } | null
    }
  } | null>
  authors?: {
    __typename?: "AuthorConnection"
    edges?: Array<{
      __typename?: "AuthorEdge"
      node?: {
        __typename?: "Author"
        meta: {
          __typename?: "NodeMeta"
          id: string
          key: string
          type: string
          url?: string | null
          title?: string | null
          subtitle?: string | null
          description?: string | null
          image?: { __typename?: "NodeImage"; src?: string | null } | null
        }
      } | null
    } | null> | null
  } | null
}

export const AuthMethodFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AuthMethod" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "AuthMethod" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "enabled" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "meta" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "key" } },
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "url" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "icon" } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode
export const AuthRequestFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AuthRequest" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "AuthRequest" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          { kind: "Field", name: { kind: "Name", value: "request_method" } },
          { kind: "Field", name: { kind: "Name", value: "request_key" } },
          { kind: "Field", name: { kind: "Name", value: "request_dtime" } },
          { kind: "Field", name: { kind: "Name", value: "confirmed_dtime" } },
          { kind: "Field", name: { kind: "Name", value: "expiration_dtime" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "confirmation_attempts" }
          },
          { kind: "Field", name: { kind: "Name", value: "context" } },
          { kind: "Field", name: { kind: "Name", value: "truncated_email" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "issued_auth_token" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "token" } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode
export const UserStatusFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserStatus" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "UserStatus" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "input_type" } },
          { kind: "Field", name: { kind: "Name", value: "user_exists" } },
          { kind: "Field", name: { kind: "Name", value: "email_verified" } },
          { kind: "Field", name: { kind: "Name", value: "no_password_set" } },
          { kind: "Field", name: { kind: "Name", value: "has_membership" } }
        ]
      }
    }
  ]
} as unknown as DocumentNode
export const NodeMetaFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NodeMeta" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "NodeMeta" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "key" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "url" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "subtitle" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "src" } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode
export const NodeThemeBackgroundFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NodeThemeBackground" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "NodeThemeBackground" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "src" } }
              ]
            }
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "image_blurred" },
            name: { kind: "Name", value: "image" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "blur" },
                value: { kind: "IntValue", value: "320" }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "src" } }
              ]
            }
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "image_blurred_dark" },
            name: { kind: "Name", value: "image" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "blur" },
                value: { kind: "IntValue", value: "320" }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "brightness" },
                value: { kind: "IntValue", value: "-20" }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "src" } }
              ]
            }
          },
          { kind: "Field", name: { kind: "Name", value: "color" } },
          { kind: "Field", name: { kind: "Name", value: "contrast_color" } }
        ]
      }
    }
  ]
} as unknown as DocumentNode
export const NodeThemeFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NodeTheme" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "NodeTheme" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "color" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "color_palette" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_50" } },
                { kind: "Field", name: { kind: "Name", value: "_100" } },
                { kind: "Field", name: { kind: "Name", value: "_200" } },
                { kind: "Field", name: { kind: "Name", value: "_300" } },
                { kind: "Field", name: { kind: "Name", value: "_400" } },
                { kind: "Field", name: { kind: "Name", value: "_500" } },
                { kind: "Field", name: { kind: "Name", value: "_600" } },
                { kind: "Field", name: { kind: "Name", value: "_700" } },
                { kind: "Field", name: { kind: "Name", value: "_800" } },
                { kind: "Field", name: { kind: "Name", value: "_900" } }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "background" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "NodeThemeBackground" }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NodeThemeBackground" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "NodeThemeBackground" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "src" } }
              ]
            }
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "image_blurred" },
            name: { kind: "Name", value: "image" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "blur" },
                value: { kind: "IntValue", value: "320" }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "src" } }
              ]
            }
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "image_blurred_dark" },
            name: { kind: "Name", value: "image" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "blur" },
                value: { kind: "IntValue", value: "320" }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "brightness" },
                value: { kind: "IntValue", value: "-20" }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "src" } }
              ]
            }
          },
          { kind: "Field", name: { kind: "Name", value: "color" } },
          { kind: "Field", name: { kind: "Name", value: "contrast_color" } }
        ]
      }
    }
  ]
} as unknown as DocumentNode
export const RegistrationListViewFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "RegistrationListView" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Registration" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "role" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "meta" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "NodeMeta" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "path" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "progress" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "meta" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "NodeMeta" }
                      }
                    ]
                  }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "theme" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "NodeTheme" }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NodeMeta" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "NodeMeta" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "key" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "url" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "subtitle" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "src" } }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NodeThemeBackground" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "NodeThemeBackground" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "src" } }
              ]
            }
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "image_blurred" },
            name: { kind: "Name", value: "image" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "blur" },
                value: { kind: "IntValue", value: "320" }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "src" } }
              ]
            }
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "image_blurred_dark" },
            name: { kind: "Name", value: "image" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "blur" },
                value: { kind: "IntValue", value: "320" }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "brightness" },
                value: { kind: "IntValue", value: "-20" }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "src" } }
              ]
            }
          },
          { kind: "Field", name: { kind: "Name", value: "color" } },
          { kind: "Field", name: { kind: "Name", value: "contrast_color" } }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NodeTheme" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "NodeTheme" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "color" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "color_palette" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_50" } },
                { kind: "Field", name: { kind: "Name", value: "_100" } },
                { kind: "Field", name: { kind: "Name", value: "_200" } },
                { kind: "Field", name: { kind: "Name", value: "_300" } },
                { kind: "Field", name: { kind: "Name", value: "_400" } },
                { kind: "Field", name: { kind: "Name", value: "_500" } },
                { kind: "Field", name: { kind: "Name", value: "_600" } },
                { kind: "Field", name: { kind: "Name", value: "_700" } },
                { kind: "Field", name: { kind: "Name", value: "_800" } },
                { kind: "Field", name: { kind: "Name", value: "_900" } }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "background" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "NodeThemeBackground" }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode
export const UserMembershipFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserMembership" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Membership" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "role" } }
        ]
      }
    }
  ]
} as unknown as DocumentNode
export const UserFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "User" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "first_name" } },
          { kind: "Field", name: { kind: "Name", value: "last_name" } },
          { kind: "Field", name: { kind: "Name", value: "full_name" } },
          { kind: "Field", name: { kind: "Name", value: "display_name" } },
          { kind: "Field", name: { kind: "Name", value: "is_finalized" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "profile" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "bio" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "image" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "width" },
                      value: { kind: "IntValue", value: "250" }
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "height" },
                      value: { kind: "IntValue", value: "250" }
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "crop" },
                      value: { kind: "EnumValue", value: "faces" }
                    }
                  ]
                },
                { kind: "Field", name: { kind: "Name", value: "location" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "last_activity" }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "notification_digest_interval" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "membership" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "UserMembership" }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserMembership" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Membership" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "role" } }
        ]
      }
    }
  ]
} as unknown as DocumentNode
export const PathViewFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PathView" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Path" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "User" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "meta" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "NodeMeta" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "theme" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "NodeTheme" }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserMembership" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Membership" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "role" } }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "User" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "first_name" } },
          { kind: "Field", name: { kind: "Name", value: "last_name" } },
          { kind: "Field", name: { kind: "Name", value: "full_name" } },
          { kind: "Field", name: { kind: "Name", value: "display_name" } },
          { kind: "Field", name: { kind: "Name", value: "is_finalized" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "profile" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "bio" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "image" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "width" },
                      value: { kind: "IntValue", value: "250" }
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "height" },
                      value: { kind: "IntValue", value: "250" }
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "crop" },
                      value: { kind: "EnumValue", value: "faces" }
                    }
                  ]
                },
                { kind: "Field", name: { kind: "Name", value: "location" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "last_activity" }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "notification_digest_interval" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "membership" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "UserMembership" }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NodeMeta" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "NodeMeta" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "key" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "url" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "subtitle" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "src" } }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NodeThemeBackground" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "NodeThemeBackground" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "src" } }
              ]
            }
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "image_blurred" },
            name: { kind: "Name", value: "image" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "blur" },
                value: { kind: "IntValue", value: "320" }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "src" } }
              ]
            }
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "image_blurred_dark" },
            name: { kind: "Name", value: "image" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "blur" },
                value: { kind: "IntValue", value: "320" }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "brightness" },
                value: { kind: "IntValue", value: "-20" }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "src" } }
              ]
            }
          },
          { kind: "Field", name: { kind: "Name", value: "color" } },
          { kind: "Field", name: { kind: "Name", value: "contrast_color" } }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NodeTheme" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "NodeTheme" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "color" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "color_palette" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_50" } },
                { kind: "Field", name: { kind: "Name", value: "_100" } },
                { kind: "Field", name: { kind: "Name", value: "_200" } },
                { kind: "Field", name: { kind: "Name", value: "_300" } },
                { kind: "Field", name: { kind: "Name", value: "_400" } },
                { kind: "Field", name: { kind: "Name", value: "_500" } },
                { kind: "Field", name: { kind: "Name", value: "_600" } },
                { kind: "Field", name: { kind: "Name", value: "_700" } },
                { kind: "Field", name: { kind: "Name", value: "_800" } },
                { kind: "Field", name: { kind: "Name", value: "_900" } }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "background" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "NodeThemeBackground" }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode
export const CompletionFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CompletionFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Completion" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "checked_date" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "completion_attempts" }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "completion_comment" }
          },
          { kind: "Field", name: { kind: "Name", value: "completion_date" } },
          { kind: "Field", name: { kind: "Name", value: "completion_due" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "last_completion_attempt_date" }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "has_failed_passing_attempt" }
          },
          { kind: "Field", name: { kind: "Name", value: "has_points" } },
          { kind: "Field", name: { kind: "Name", value: "is_late" } },
          { kind: "Field", name: { kind: "Name", value: "is_graded" } },
          { kind: "Field", name: { kind: "Name", value: "is_skipped" } },
          { kind: "Field", name: { kind: "Name", value: "is_complete" } },
          { kind: "Field", name: { kind: "Name", value: "is_auto_graded" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "is_forced_completion" }
          },
          { kind: "Field", name: { kind: "Name", value: "needs_grading" } },
          { kind: "Field", name: { kind: "Name", value: "userpoints_earned" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "userpoints_explanation" }
          },
          { kind: "Field", name: { kind: "Name", value: "userpoints_value" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "minimum_passing_score" }
          },
          { kind: "Field", name: { kind: "Name", value: "grading_type" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "requires_api_completion" }
          },
          { kind: "Field", name: { kind: "Name", value: "overridden_by_api" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "overridden_by" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "User" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "checked_by" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "User" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "User" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "submission" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "text" } },
                { kind: "Field", name: { kind: "Name", value: "file_url" } },
                { kind: "Field", name: { kind: "Name", value: "file_name" } },
                { kind: "Field", name: { kind: "Name", value: "file_image" } }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserMembership" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Membership" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "role" } }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "User" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "first_name" } },
          { kind: "Field", name: { kind: "Name", value: "last_name" } },
          { kind: "Field", name: { kind: "Name", value: "full_name" } },
          { kind: "Field", name: { kind: "Name", value: "display_name" } },
          { kind: "Field", name: { kind: "Name", value: "is_finalized" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "profile" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "bio" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "image" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "width" },
                      value: { kind: "IntValue", value: "250" }
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "height" },
                      value: { kind: "IntValue", value: "250" }
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "crop" },
                      value: { kind: "EnumValue", value: "faces" }
                    }
                  ]
                },
                { kind: "Field", name: { kind: "Name", value: "location" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "last_activity" }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "notification_digest_interval" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "membership" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "UserMembership" }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode
export const PathItemListViewFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PathItemListView" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "PathItem" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "source_id" } },
          { kind: "Field", name: { kind: "Name", value: "order" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "due" } },
          { kind: "Field", name: { kind: "Name", value: "passive_due" } },
          { kind: "Field", name: { kind: "Name", value: "starts" } },
          { kind: "Field", name: { kind: "Name", value: "passive_starts" } },
          { kind: "Field", name: { kind: "Name", value: "has_started" } },
          { kind: "Field", name: { kind: "Name", value: "parent_id" } },
          { kind: "Field", name: { kind: "Name", value: "parent_source_id" } },
          { kind: "Field", name: { kind: "Name", value: "assignment_type" } },
          { kind: "Field", name: { kind: "Name", value: "verb" } },
          { kind: "Field", name: { kind: "Name", value: "content_type" } },
          { kind: "Field", name: { kind: "Name", value: "content_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "item_content_type_id" }
          },
          { kind: "Field", name: { kind: "Name", value: "points" } },
          { kind: "Field", name: { kind: "Name", value: "progress" } },
          { kind: "Field", name: { kind: "Name", value: "path_id" } },
          { kind: "Field", name: { kind: "Name", value: "time_to_complete" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "meta" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "NodeMeta" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "completion" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CompletionFragment" }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserMembership" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Membership" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "role" } }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "User" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "first_name" } },
          { kind: "Field", name: { kind: "Name", value: "last_name" } },
          { kind: "Field", name: { kind: "Name", value: "full_name" } },
          { kind: "Field", name: { kind: "Name", value: "display_name" } },
          { kind: "Field", name: { kind: "Name", value: "is_finalized" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "profile" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "bio" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "image" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "width" },
                      value: { kind: "IntValue", value: "250" }
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "height" },
                      value: { kind: "IntValue", value: "250" }
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "crop" },
                      value: { kind: "EnumValue", value: "faces" }
                    }
                  ]
                },
                { kind: "Field", name: { kind: "Name", value: "location" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "last_activity" }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "notification_digest_interval" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "membership" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "UserMembership" }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NodeMeta" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "NodeMeta" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "key" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "url" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "subtitle" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "src" } }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CompletionFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Completion" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "checked_date" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "completion_attempts" }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "completion_comment" }
          },
          { kind: "Field", name: { kind: "Name", value: "completion_date" } },
          { kind: "Field", name: { kind: "Name", value: "completion_due" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "last_completion_attempt_date" }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "has_failed_passing_attempt" }
          },
          { kind: "Field", name: { kind: "Name", value: "has_points" } },
          { kind: "Field", name: { kind: "Name", value: "is_late" } },
          { kind: "Field", name: { kind: "Name", value: "is_graded" } },
          { kind: "Field", name: { kind: "Name", value: "is_skipped" } },
          { kind: "Field", name: { kind: "Name", value: "is_complete" } },
          { kind: "Field", name: { kind: "Name", value: "is_auto_graded" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "is_forced_completion" }
          },
          { kind: "Field", name: { kind: "Name", value: "needs_grading" } },
          { kind: "Field", name: { kind: "Name", value: "userpoints_earned" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "userpoints_explanation" }
          },
          { kind: "Field", name: { kind: "Name", value: "userpoints_value" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "minimum_passing_score" }
          },
          { kind: "Field", name: { kind: "Name", value: "grading_type" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "requires_api_completion" }
          },
          { kind: "Field", name: { kind: "Name", value: "overridden_by_api" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "overridden_by" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "User" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "checked_by" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "User" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "User" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "submission" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "text" } },
                { kind: "Field", name: { kind: "Name", value: "file_url" } },
                { kind: "Field", name: { kind: "Name", value: "file_name" } },
                { kind: "Field", name: { kind: "Name", value: "file_image" } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode
export const PathItemGroupViewFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PathItemGroupView" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "PathItem" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "PathItemListView" }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "items" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "IntValue", value: "100" }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "edges" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "node" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "PathItemListView" }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserMembership" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Membership" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "role" } }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "User" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "first_name" } },
          { kind: "Field", name: { kind: "Name", value: "last_name" } },
          { kind: "Field", name: { kind: "Name", value: "full_name" } },
          { kind: "Field", name: { kind: "Name", value: "display_name" } },
          { kind: "Field", name: { kind: "Name", value: "is_finalized" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "profile" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "bio" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "image" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "width" },
                      value: { kind: "IntValue", value: "250" }
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "height" },
                      value: { kind: "IntValue", value: "250" }
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "crop" },
                      value: { kind: "EnumValue", value: "faces" }
                    }
                  ]
                },
                { kind: "Field", name: { kind: "Name", value: "location" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "last_activity" }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "notification_digest_interval" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "membership" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "UserMembership" }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PathItemListView" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "PathItem" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "source_id" } },
          { kind: "Field", name: { kind: "Name", value: "order" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "due" } },
          { kind: "Field", name: { kind: "Name", value: "passive_due" } },
          { kind: "Field", name: { kind: "Name", value: "starts" } },
          { kind: "Field", name: { kind: "Name", value: "passive_starts" } },
          { kind: "Field", name: { kind: "Name", value: "has_started" } },
          { kind: "Field", name: { kind: "Name", value: "parent_id" } },
          { kind: "Field", name: { kind: "Name", value: "parent_source_id" } },
          { kind: "Field", name: { kind: "Name", value: "assignment_type" } },
          { kind: "Field", name: { kind: "Name", value: "verb" } },
          { kind: "Field", name: { kind: "Name", value: "content_type" } },
          { kind: "Field", name: { kind: "Name", value: "content_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "item_content_type_id" }
          },
          { kind: "Field", name: { kind: "Name", value: "points" } },
          { kind: "Field", name: { kind: "Name", value: "progress" } },
          { kind: "Field", name: { kind: "Name", value: "path_id" } },
          { kind: "Field", name: { kind: "Name", value: "time_to_complete" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "meta" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "NodeMeta" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "completion" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CompletionFragment" }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NodeMeta" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "NodeMeta" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "key" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "url" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "subtitle" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "src" } }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CompletionFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Completion" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "checked_date" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "completion_attempts" }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "completion_comment" }
          },
          { kind: "Field", name: { kind: "Name", value: "completion_date" } },
          { kind: "Field", name: { kind: "Name", value: "completion_due" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "last_completion_attempt_date" }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "has_failed_passing_attempt" }
          },
          { kind: "Field", name: { kind: "Name", value: "has_points" } },
          { kind: "Field", name: { kind: "Name", value: "is_late" } },
          { kind: "Field", name: { kind: "Name", value: "is_graded" } },
          { kind: "Field", name: { kind: "Name", value: "is_skipped" } },
          { kind: "Field", name: { kind: "Name", value: "is_complete" } },
          { kind: "Field", name: { kind: "Name", value: "is_auto_graded" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "is_forced_completion" }
          },
          { kind: "Field", name: { kind: "Name", value: "needs_grading" } },
          { kind: "Field", name: { kind: "Name", value: "userpoints_earned" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "userpoints_explanation" }
          },
          { kind: "Field", name: { kind: "Name", value: "userpoints_value" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "minimum_passing_score" }
          },
          { kind: "Field", name: { kind: "Name", value: "grading_type" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "requires_api_completion" }
          },
          { kind: "Field", name: { kind: "Name", value: "overridden_by_api" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "overridden_by" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "User" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "checked_by" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "User" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "User" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "submission" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "text" } },
                { kind: "Field", name: { kind: "Name", value: "file_url" } },
                { kind: "Field", name: { kind: "Name", value: "file_name" } },
                { kind: "Field", name: { kind: "Name", value: "file_image" } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode
export const PathsCodexNodeFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PathsCodexNode" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "PathsCodexNode" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "key" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "version" } }
        ]
      }
    }
  ]
} as unknown as DocumentNode
export const SpaceFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Space" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Space" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "subdomain" } },
          { kind: "Field", name: { kind: "Name", value: "created" } },
          { kind: "Field", name: { kind: "Name", value: "is_activated" } },
          { kind: "Field", name: { kind: "Name", value: "ietf_language_tag" } },
          { kind: "Field", name: { kind: "Name", value: "is_owner" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "width" },
                value: { kind: "IntValue", value: "150" }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "height" },
                value: { kind: "IntValue", value: "150" }
              }
            ]
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "meta" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "NodeMeta" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "theme" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "NodeTheme" }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NodeMeta" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "NodeMeta" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "key" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "url" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "subtitle" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "src" } }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NodeThemeBackground" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "NodeThemeBackground" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "src" } }
              ]
            }
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "image_blurred" },
            name: { kind: "Name", value: "image" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "blur" },
                value: { kind: "IntValue", value: "320" }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "src" } }
              ]
            }
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "image_blurred_dark" },
            name: { kind: "Name", value: "image" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "blur" },
                value: { kind: "IntValue", value: "320" }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "brightness" },
                value: { kind: "IntValue", value: "-20" }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "src" } }
              ]
            }
          },
          { kind: "Field", name: { kind: "Name", value: "color" } },
          { kind: "Field", name: { kind: "Name", value: "contrast_color" } }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NodeTheme" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "NodeTheme" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "color" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "color_palette" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_50" } },
                { kind: "Field", name: { kind: "Name", value: "_100" } },
                { kind: "Field", name: { kind: "Name", value: "_200" } },
                { kind: "Field", name: { kind: "Name", value: "_300" } },
                { kind: "Field", name: { kind: "Name", value: "_400" } },
                { kind: "Field", name: { kind: "Name", value: "_500" } },
                { kind: "Field", name: { kind: "Name", value: "_600" } },
                { kind: "Field", name: { kind: "Name", value: "_700" } },
                { kind: "Field", name: { kind: "Name", value: "_800" } },
                { kind: "Field", name: { kind: "Name", value: "_900" } }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "background" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "NodeThemeBackground" }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode
export const SpaceFeaturesFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SpaceFeatures" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "SpaceFeatures" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "auth__login_option__email_link" }
          },
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "auth__login_option__global_sso__apple"
            }
          },
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "auth__login_option__global_sso__google"
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "auth__login_option__password" }
          },
          { kind: "Field", name: { kind: "Name", value: "auth__platform" } }
        ]
      }
    }
  ]
} as unknown as DocumentNode
export const PathItemViewFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PathItemView" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "PathItem" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "PathItemListView" }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserMembership" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Membership" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "role" } }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "User" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "first_name" } },
          { kind: "Field", name: { kind: "Name", value: "last_name" } },
          { kind: "Field", name: { kind: "Name", value: "full_name" } },
          { kind: "Field", name: { kind: "Name", value: "display_name" } },
          { kind: "Field", name: { kind: "Name", value: "is_finalized" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "profile" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "bio" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "image" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "width" },
                      value: { kind: "IntValue", value: "250" }
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "height" },
                      value: { kind: "IntValue", value: "250" }
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "crop" },
                      value: { kind: "EnumValue", value: "faces" }
                    }
                  ]
                },
                { kind: "Field", name: { kind: "Name", value: "location" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "last_activity" }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "notification_digest_interval" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "membership" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "UserMembership" }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PathItemListView" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "PathItem" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "source_id" } },
          { kind: "Field", name: { kind: "Name", value: "order" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "due" } },
          { kind: "Field", name: { kind: "Name", value: "passive_due" } },
          { kind: "Field", name: { kind: "Name", value: "starts" } },
          { kind: "Field", name: { kind: "Name", value: "passive_starts" } },
          { kind: "Field", name: { kind: "Name", value: "has_started" } },
          { kind: "Field", name: { kind: "Name", value: "parent_id" } },
          { kind: "Field", name: { kind: "Name", value: "parent_source_id" } },
          { kind: "Field", name: { kind: "Name", value: "assignment_type" } },
          { kind: "Field", name: { kind: "Name", value: "verb" } },
          { kind: "Field", name: { kind: "Name", value: "content_type" } },
          { kind: "Field", name: { kind: "Name", value: "content_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "item_content_type_id" }
          },
          { kind: "Field", name: { kind: "Name", value: "points" } },
          { kind: "Field", name: { kind: "Name", value: "progress" } },
          { kind: "Field", name: { kind: "Name", value: "path_id" } },
          { kind: "Field", name: { kind: "Name", value: "time_to_complete" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "meta" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "NodeMeta" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "completion" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CompletionFragment" }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NodeMeta" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "NodeMeta" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "key" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "url" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "subtitle" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "src" } }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CompletionFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Completion" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "checked_date" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "completion_attempts" }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "completion_comment" }
          },
          { kind: "Field", name: { kind: "Name", value: "completion_date" } },
          { kind: "Field", name: { kind: "Name", value: "completion_due" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "last_completion_attempt_date" }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "has_failed_passing_attempt" }
          },
          { kind: "Field", name: { kind: "Name", value: "has_points" } },
          { kind: "Field", name: { kind: "Name", value: "is_late" } },
          { kind: "Field", name: { kind: "Name", value: "is_graded" } },
          { kind: "Field", name: { kind: "Name", value: "is_skipped" } },
          { kind: "Field", name: { kind: "Name", value: "is_complete" } },
          { kind: "Field", name: { kind: "Name", value: "is_auto_graded" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "is_forced_completion" }
          },
          { kind: "Field", name: { kind: "Name", value: "needs_grading" } },
          { kind: "Field", name: { kind: "Name", value: "userpoints_earned" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "userpoints_explanation" }
          },
          { kind: "Field", name: { kind: "Name", value: "userpoints_value" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "minimum_passing_score" }
          },
          { kind: "Field", name: { kind: "Name", value: "grading_type" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "requires_api_completion" }
          },
          { kind: "Field", name: { kind: "Name", value: "overridden_by_api" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "overridden_by" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "User" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "checked_by" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "User" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "User" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "submission" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "text" } },
                { kind: "Field", name: { kind: "Name", value: "file_url" } },
                { kind: "Field", name: { kind: "Name", value: "file_name" } },
                { kind: "Field", name: { kind: "Name", value: "file_image" } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode
export const StoreBannerViewFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "StoreBannerView" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "StoreBanner" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "button_text" } },
          { kind: "Field", name: { kind: "Name", value: "button_url" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "meta" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "NodeMeta" }
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "banner" },
                  name: { kind: "Name", value: "image" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "ar" },
                      value: {
                        kind: "StringValue",
                        value: "16:9",
                        block: false
                      }
                    }
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "src" } },
                      { kind: "Field", name: { kind: "Name", value: "width" } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NodeMeta" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "NodeMeta" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "key" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "url" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "subtitle" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "src" } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode
export const StoreItemListViewFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "StoreItemListView" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "StoreItem" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "meta" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "NodeMeta" }
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "cover" },
                  name: { kind: "Name", value: "image" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "ar" },
                      value: { kind: "StringValue", value: "4:3", block: false }
                    }
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "src" } },
                      { kind: "Field", name: { kind: "Name", value: "width" } }
                    ]
                  }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "authors" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "IntValue", value: "100" }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "edges" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "node" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "meta" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "FragmentSpread",
                                    name: { kind: "Name", value: "NodeMeta" }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NodeMeta" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "NodeMeta" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "key" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "url" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "subtitle" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "src" } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode
export const StoreItemViewFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "StoreItemView" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "StoreItem" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "meta" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "NodeMeta" }
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "cover" },
                  name: { kind: "Name", value: "image" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "ar" },
                      value: {
                        kind: "StringValue",
                        value: "16:9",
                        block: false
                      }
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "size" },
                      value: { kind: "EnumValue", value: "xl" }
                    }
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "src" } },
                      { kind: "Field", name: { kind: "Name", value: "width" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "color" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "mode" },
                            value: { kind: "EnumValue", value: "dark" }
                          },
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "tone" },
                            value: { kind: "EnumValue", value: "muted" }
                          }
                        ]
                      }
                    ]
                  }
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "cover_default" },
                  name: { kind: "Name", value: "image" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "ar" },
                      value: { kind: "StringValue", value: "4:3", block: false }
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "size" },
                      value: { kind: "EnumValue", value: "lg" }
                    }
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "src" } },
                      { kind: "Field", name: { kind: "Name", value: "width" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "color" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "mode" },
                            value: { kind: "EnumValue", value: "dark" }
                          },
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "tone" },
                            value: { kind: "EnumValue", value: "muted" }
                          }
                        ]
                      }
                    ]
                  }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "theme" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "background" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "image" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "src" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "width" }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          },
          { kind: "Field", name: { kind: "Name", value: "category_ids" } },
          { kind: "Field", name: { kind: "Name", value: "short_description" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "options" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "price" } },
                { kind: "Field", name: { kind: "Name", value: "path_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "meta" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "NodeMeta" }
                      }
                    ]
                  }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "authors" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "IntValue", value: "10" }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "edges" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "node" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "meta" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "FragmentSpread",
                                    name: { kind: "Name", value: "NodeMeta" }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NodeMeta" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "NodeMeta" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "key" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "url" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "subtitle" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "src" } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode
export const AuthContextDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "AuthContext" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "inviteToken" }
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "space" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "consent_description" }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "allow_signup" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "invite_token" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "inviteToken" }
                      }
                    }
                  ]
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "always_prompt_for_password" }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "authMethods" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "AuthMethod" }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AuthMethod" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "AuthMethod" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "enabled" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "meta" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "key" } },
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "url" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "icon" } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode

/**
 * __useAuthContextQuery__
 *
 * To run a query within a React component, call `useAuthContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthContextQuery({
 *   variables: {
 *      inviteToken: // value for 'inviteToken'
 *   },
 * });
 */
export function useAuthContextQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AuthContextQuery,
    AuthContextQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AuthContextQuery, AuthContextQueryVariables>(
    AuthContextDocument,
    options
  )
}
export function useAuthContextLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AuthContextQuery,
    AuthContextQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AuthContextQuery, AuthContextQueryVariables>(
    AuthContextDocument,
    options
  )
}
export function useAuthContextSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AuthContextQuery,
    AuthContextQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<AuthContextQuery, AuthContextQueryVariables>(
    AuthContextDocument,
    options
  )
}
export type AuthContextQueryHookResult = ReturnType<typeof useAuthContextQuery>
export type AuthContextLazyQueryHookResult = ReturnType<
  typeof useAuthContextLazyQuery
>
export type AuthContextSuspenseQueryHookResult = ReturnType<
  typeof useAuthContextSuspenseQuery
>
export type AuthContextQueryResult = Apollo.QueryResult<
  AuthContextQuery,
  AuthContextQueryVariables
>
export const AuthMethodsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "AuthMethods" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "space" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "authMethods" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "AuthMethod" }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AuthMethod" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "AuthMethod" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "enabled" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "meta" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "key" } },
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "url" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "icon" } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode

/**
 * __useAuthMethodsQuery__
 *
 * To run a query within a React component, call `useAuthMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthMethodsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthMethodsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AuthMethodsQuery,
    AuthMethodsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AuthMethodsQuery, AuthMethodsQueryVariables>(
    AuthMethodsDocument,
    options
  )
}
export function useAuthMethodsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AuthMethodsQuery,
    AuthMethodsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AuthMethodsQuery, AuthMethodsQueryVariables>(
    AuthMethodsDocument,
    options
  )
}
export function useAuthMethodsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AuthMethodsQuery,
    AuthMethodsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<AuthMethodsQuery, AuthMethodsQueryVariables>(
    AuthMethodsDocument,
    options
  )
}
export type AuthMethodsQueryHookResult = ReturnType<typeof useAuthMethodsQuery>
export type AuthMethodsLazyQueryHookResult = ReturnType<
  typeof useAuthMethodsLazyQuery
>
export type AuthMethodsSuspenseQueryHookResult = ReturnType<
  typeof useAuthMethodsSuspenseQuery
>
export type AuthMethodsQueryResult = Apollo.QueryResult<
  AuthMethodsQuery,
  AuthMethodsQueryVariables
>
export const RefreshTokenDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "RefreshToken" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "schoolId" }
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "refreshToken" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "school_id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "schoolId" }
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode
export type RefreshTokenMutationFn = Apollo.MutationFunction<
  RefreshTokenMutation,
  RefreshTokenMutationVariables
>

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useRefreshTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshTokenMutation,
    RefreshTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RefreshTokenMutation,
    RefreshTokenMutationVariables
  >(RefreshTokenDocument, options)
}
export type RefreshTokenMutationHookResult = ReturnType<
  typeof useRefreshTokenMutation
>
export type RefreshTokenMutationResult =
  Apollo.MutationResult<RefreshTokenMutation>
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<
  RefreshTokenMutation,
  RefreshTokenMutationVariables
>
export const MeDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Me" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "User" }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserMembership" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Membership" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "role" } }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "User" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "first_name" } },
          { kind: "Field", name: { kind: "Name", value: "last_name" } },
          { kind: "Field", name: { kind: "Name", value: "full_name" } },
          { kind: "Field", name: { kind: "Name", value: "display_name" } },
          { kind: "Field", name: { kind: "Name", value: "is_finalized" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "profile" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "bio" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "image" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "width" },
                      value: { kind: "IntValue", value: "250" }
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "height" },
                      value: { kind: "IntValue", value: "250" }
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "crop" },
                      value: { kind: "EnumValue", value: "faces" }
                    }
                  ]
                },
                { kind: "Field", name: { kind: "Name", value: "location" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "last_activity" }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "notification_digest_interval" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "membership" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "UserMembership" }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(
  baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options)
}
export function useMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options)
}
export function useMeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<MeQuery, MeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<MeQuery, MeQueryVariables>(MeDocument, options)
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>
export type MeSuspenseQueryHookResult = ReturnType<typeof useMeSuspenseQuery>
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>
export const StartAuthRequestDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "StartAuthRequest" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "method" }
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "AuthRequestMethod" }
            }
          }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "email" }
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "EmailAddress" }
          }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "username" }
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "nextUrl" }
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "startAuthRequest" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "method" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "method" }
                }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "email" }
                }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" }
                }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "next_url" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "nextUrl" }
                }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "AuthRequest" }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AuthRequest" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "AuthRequest" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          { kind: "Field", name: { kind: "Name", value: "request_method" } },
          { kind: "Field", name: { kind: "Name", value: "request_key" } },
          { kind: "Field", name: { kind: "Name", value: "request_dtime" } },
          { kind: "Field", name: { kind: "Name", value: "confirmed_dtime" } },
          { kind: "Field", name: { kind: "Name", value: "expiration_dtime" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "confirmation_attempts" }
          },
          { kind: "Field", name: { kind: "Name", value: "context" } },
          { kind: "Field", name: { kind: "Name", value: "truncated_email" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "issued_auth_token" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "token" } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode
export type StartAuthRequestMutationFn = Apollo.MutationFunction<
  StartAuthRequestMutation,
  StartAuthRequestMutationVariables
>

/**
 * __useStartAuthRequestMutation__
 *
 * To run a mutation, you first call `useStartAuthRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartAuthRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startAuthRequestMutation, { data, loading, error }] = useStartAuthRequestMutation({
 *   variables: {
 *      method: // value for 'method'
 *      email: // value for 'email'
 *      username: // value for 'username'
 *      nextUrl: // value for 'nextUrl'
 *   },
 * });
 */
export function useStartAuthRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StartAuthRequestMutation,
    StartAuthRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    StartAuthRequestMutation,
    StartAuthRequestMutationVariables
  >(StartAuthRequestDocument, options)
}
export type StartAuthRequestMutationHookResult = ReturnType<
  typeof useStartAuthRequestMutation
>
export type StartAuthRequestMutationResult =
  Apollo.MutationResult<StartAuthRequestMutation>
export type StartAuthRequestMutationOptions = Apollo.BaseMutationOptions<
  StartAuthRequestMutation,
  StartAuthRequestMutationVariables
>
export const AuthRequestDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "AuthRequest" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "uuid" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
          }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "authRequest" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "uuid" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "uuid" }
                }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "AuthRequest" }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AuthRequest" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "AuthRequest" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          { kind: "Field", name: { kind: "Name", value: "request_method" } },
          { kind: "Field", name: { kind: "Name", value: "request_key" } },
          { kind: "Field", name: { kind: "Name", value: "request_dtime" } },
          { kind: "Field", name: { kind: "Name", value: "confirmed_dtime" } },
          { kind: "Field", name: { kind: "Name", value: "expiration_dtime" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "confirmation_attempts" }
          },
          { kind: "Field", name: { kind: "Name", value: "context" } },
          { kind: "Field", name: { kind: "Name", value: "truncated_email" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "issued_auth_token" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "token" } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode

/**
 * __useAuthRequestQuery__
 *
 * To run a query within a React component, call `useAuthRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthRequestQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useAuthRequestQuery(
  baseOptions: Apollo.QueryHookOptions<
    AuthRequestQuery,
    AuthRequestQueryVariables
  > &
    (
      | { variables: AuthRequestQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AuthRequestQuery, AuthRequestQueryVariables>(
    AuthRequestDocument,
    options
  )
}
export function useAuthRequestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AuthRequestQuery,
    AuthRequestQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AuthRequestQuery, AuthRequestQueryVariables>(
    AuthRequestDocument,
    options
  )
}
export function useAuthRequestSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AuthRequestQuery,
    AuthRequestQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<AuthRequestQuery, AuthRequestQueryVariables>(
    AuthRequestDocument,
    options
  )
}
export type AuthRequestQueryHookResult = ReturnType<typeof useAuthRequestQuery>
export type AuthRequestLazyQueryHookResult = ReturnType<
  typeof useAuthRequestLazyQuery
>
export type AuthRequestSuspenseQueryHookResult = ReturnType<
  typeof useAuthRequestSuspenseQuery
>
export type AuthRequestQueryResult = Apollo.QueryResult<
  AuthRequestQuery,
  AuthRequestQueryVariables
>
export const AuthRequestTokenDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "AuthRequestToken" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "uuid" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
          }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "authRequestToken" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "uuid" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "uuid" }
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode

/**
 * __useAuthRequestTokenQuery__
 *
 * To run a query within a React component, call `useAuthRequestTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthRequestTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthRequestTokenQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useAuthRequestTokenQuery(
  baseOptions: Apollo.QueryHookOptions<
    AuthRequestTokenQuery,
    AuthRequestTokenQueryVariables
  > &
    (
      | { variables: AuthRequestTokenQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AuthRequestTokenQuery, AuthRequestTokenQueryVariables>(
    AuthRequestTokenDocument,
    options
  )
}
export function useAuthRequestTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AuthRequestTokenQuery,
    AuthRequestTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AuthRequestTokenQuery,
    AuthRequestTokenQueryVariables
  >(AuthRequestTokenDocument, options)
}
export function useAuthRequestTokenSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AuthRequestTokenQuery,
    AuthRequestTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    AuthRequestTokenQuery,
    AuthRequestTokenQueryVariables
  >(AuthRequestTokenDocument, options)
}
export type AuthRequestTokenQueryHookResult = ReturnType<
  typeof useAuthRequestTokenQuery
>
export type AuthRequestTokenLazyQueryHookResult = ReturnType<
  typeof useAuthRequestTokenLazyQuery
>
export type AuthRequestTokenSuspenseQueryHookResult = ReturnType<
  typeof useAuthRequestTokenSuspenseQuery
>
export type AuthRequestTokenQueryResult = Apollo.QueryResult<
  AuthRequestTokenQuery,
  AuthRequestTokenQueryVariables
>
export const ConfirmAuthRequestDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ConfirmAuthRequest" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "uuid" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } }
          }
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "key" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
          }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "confirmAuthRequest" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "uuid" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "uuid" }
                }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "key" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "key" }
                }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "AuthRequest" }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AuthRequest" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "AuthRequest" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          { kind: "Field", name: { kind: "Name", value: "request_method" } },
          { kind: "Field", name: { kind: "Name", value: "request_key" } },
          { kind: "Field", name: { kind: "Name", value: "request_dtime" } },
          { kind: "Field", name: { kind: "Name", value: "confirmed_dtime" } },
          { kind: "Field", name: { kind: "Name", value: "expiration_dtime" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "confirmation_attempts" }
          },
          { kind: "Field", name: { kind: "Name", value: "context" } },
          { kind: "Field", name: { kind: "Name", value: "truncated_email" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "issued_auth_token" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "token" } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode
export type ConfirmAuthRequestMutationFn = Apollo.MutationFunction<
  ConfirmAuthRequestMutation,
  ConfirmAuthRequestMutationVariables
>

/**
 * __useConfirmAuthRequestMutation__
 *
 * To run a mutation, you first call `useConfirmAuthRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmAuthRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmAuthRequestMutation, { data, loading, error }] = useConfirmAuthRequestMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      key: // value for 'key'
 *   },
 * });
 */
export function useConfirmAuthRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmAuthRequestMutation,
    ConfirmAuthRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ConfirmAuthRequestMutation,
    ConfirmAuthRequestMutationVariables
  >(ConfirmAuthRequestDocument, options)
}
export type ConfirmAuthRequestMutationHookResult = ReturnType<
  typeof useConfirmAuthRequestMutation
>
export type ConfirmAuthRequestMutationResult =
  Apollo.MutationResult<ConfirmAuthRequestMutation>
export type ConfirmAuthRequestMutationOptions = Apollo.BaseMutationOptions<
  ConfirmAuthRequestMutation,
  ConfirmAuthRequestMutationVariables
>
export const FinalizeExternalAccountLinkDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "FinalizeExternalAccountLink" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "token" }
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
          }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "finalizeExternalAccountLink" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "token" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "token" }
                }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode
export type FinalizeExternalAccountLinkMutationFn = Apollo.MutationFunction<
  FinalizeExternalAccountLinkMutation,
  FinalizeExternalAccountLinkMutationVariables
>

/**
 * __useFinalizeExternalAccountLinkMutation__
 *
 * To run a mutation, you first call `useFinalizeExternalAccountLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinalizeExternalAccountLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finalizeExternalAccountLinkMutation, { data, loading, error }] = useFinalizeExternalAccountLinkMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useFinalizeExternalAccountLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FinalizeExternalAccountLinkMutation,
    FinalizeExternalAccountLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    FinalizeExternalAccountLinkMutation,
    FinalizeExternalAccountLinkMutationVariables
  >(FinalizeExternalAccountLinkDocument, options)
}
export type FinalizeExternalAccountLinkMutationHookResult = ReturnType<
  typeof useFinalizeExternalAccountLinkMutation
>
export type FinalizeExternalAccountLinkMutationResult =
  Apollo.MutationResult<FinalizeExternalAccountLinkMutation>
export type FinalizeExternalAccountLinkMutationOptions =
  Apollo.BaseMutationOptions<
    FinalizeExternalAccountLinkMutation,
    FinalizeExternalAccountLinkMutationVariables
  >
export const JoinSpaceDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "JoinSpace" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "consent" }
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" }
            }
          }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "inviteToken" }
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "joinSpace" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "consent" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "consent" }
                }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "invite_token" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "inviteToken" }
                }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "UserMembership" }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "consent_snapshot" }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "consent_dtime" }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserMembership" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Membership" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "role" } }
        ]
      }
    }
  ]
} as unknown as DocumentNode
export type JoinSpaceMutationFn = Apollo.MutationFunction<
  JoinSpaceMutation,
  JoinSpaceMutationVariables
>

/**
 * __useJoinSpaceMutation__
 *
 * To run a mutation, you first call `useJoinSpaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinSpaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinSpaceMutation, { data, loading, error }] = useJoinSpaceMutation({
 *   variables: {
 *      consent: // value for 'consent'
 *      inviteToken: // value for 'inviteToken'
 *   },
 * });
 */
export function useJoinSpaceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JoinSpaceMutation,
    JoinSpaceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<JoinSpaceMutation, JoinSpaceMutationVariables>(
    JoinSpaceDocument,
    options
  )
}
export type JoinSpaceMutationHookResult = ReturnType<
  typeof useJoinSpaceMutation
>
export type JoinSpaceMutationResult = Apollo.MutationResult<JoinSpaceMutation>
export type JoinSpaceMutationOptions = Apollo.BaseMutationOptions<
  JoinSpaceMutation,
  JoinSpaceMutationVariables
>
export const ChangePasswordDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ChangePassword" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "currentPassword" }
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
          }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "password1" }
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
          }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "password2" }
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
          }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "changePassword" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "current_password" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "currentPassword" }
                }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "password1" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "password1" }
                }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "password2" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "password2" }
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode
export type ChangePasswordMutationFn = Apollo.MutationFunction<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      currentPassword: // value for 'currentPassword'
 *      password1: // value for 'password1'
 *      password2: // value for 'password2'
 *   },
 * });
 */
export function useChangePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >(ChangePasswordDocument, options)
}
export type ChangePasswordMutationHookResult = ReturnType<
  typeof useChangePasswordMutation
>
export type ChangePasswordMutationResult =
  Apollo.MutationResult<ChangePasswordMutation>
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>
export const CreatePasswordDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreatePassword" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "password1" }
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
          }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "password2" }
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
          }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createPassword" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "password1" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "password1" }
                }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "password2" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "password2" }
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode
export type CreatePasswordMutationFn = Apollo.MutationFunction<
  CreatePasswordMutation,
  CreatePasswordMutationVariables
>

/**
 * __useCreatePasswordMutation__
 *
 * To run a mutation, you first call `useCreatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPasswordMutation, { data, loading, error }] = useCreatePasswordMutation({
 *   variables: {
 *      password1: // value for 'password1'
 *      password2: // value for 'password2'
 *   },
 * });
 */
export function useCreatePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePasswordMutation,
    CreatePasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreatePasswordMutation,
    CreatePasswordMutationVariables
  >(CreatePasswordDocument, options)
}
export type CreatePasswordMutationHookResult = ReturnType<
  typeof useCreatePasswordMutation
>
export type CreatePasswordMutationResult =
  Apollo.MutationResult<CreatePasswordMutation>
export type CreatePasswordMutationOptions = Apollo.BaseMutationOptions<
  CreatePasswordMutation,
  CreatePasswordMutationVariables
>
export const PassordResetConfirmDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "PassordResetConfirm" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "resetHash" }
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
          }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "password1" }
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
          }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "password2" }
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
          }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "resetPassword" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "reset_hash" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "resetHash" }
                }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "password1" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "password1" }
                }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "password2" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "password2" }
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode
export type PassordResetConfirmMutationFn = Apollo.MutationFunction<
  PassordResetConfirmMutation,
  PassordResetConfirmMutationVariables
>

/**
 * __usePassordResetConfirmMutation__
 *
 * To run a mutation, you first call `usePassordResetConfirmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePassordResetConfirmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passordResetConfirmMutation, { data, loading, error }] = usePassordResetConfirmMutation({
 *   variables: {
 *      resetHash: // value for 'resetHash'
 *      password1: // value for 'password1'
 *      password2: // value for 'password2'
 *   },
 * });
 */
export function usePassordResetConfirmMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PassordResetConfirmMutation,
    PassordResetConfirmMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    PassordResetConfirmMutation,
    PassordResetConfirmMutationVariables
  >(PassordResetConfirmDocument, options)
}
export type PassordResetConfirmMutationHookResult = ReturnType<
  typeof usePassordResetConfirmMutation
>
export type PassordResetConfirmMutationResult =
  Apollo.MutationResult<PassordResetConfirmMutation>
export type PassordResetConfirmMutationOptions = Apollo.BaseMutationOptions<
  PassordResetConfirmMutation,
  PassordResetConfirmMutationVariables
>
export const StartPasswordRetrievalDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "StartPasswordRetrieval" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "email" }
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
          }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "nextUrl" }
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "startPasswordRetrieval" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "email" }
                }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "next_url" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "nextUrl" }
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode
export type StartPasswordRetrievalMutationFn = Apollo.MutationFunction<
  StartPasswordRetrievalMutation,
  StartPasswordRetrievalMutationVariables
>

/**
 * __useStartPasswordRetrievalMutation__
 *
 * To run a mutation, you first call `useStartPasswordRetrievalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartPasswordRetrievalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startPasswordRetrievalMutation, { data, loading, error }] = useStartPasswordRetrievalMutation({
 *   variables: {
 *      email: // value for 'email'
 *      nextUrl: // value for 'nextUrl'
 *   },
 * });
 */
export function useStartPasswordRetrievalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StartPasswordRetrievalMutation,
    StartPasswordRetrievalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    StartPasswordRetrievalMutation,
    StartPasswordRetrievalMutationVariables
  >(StartPasswordRetrievalDocument, options)
}
export type StartPasswordRetrievalMutationHookResult = ReturnType<
  typeof useStartPasswordRetrievalMutation
>
export type StartPasswordRetrievalMutationResult =
  Apollo.MutationResult<StartPasswordRetrievalMutation>
export type StartPasswordRetrievalMutationOptions = Apollo.BaseMutationOptions<
  StartPasswordRetrievalMutation,
  StartPasswordRetrievalMutationVariables
>
export const UserStatusDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "UserStatus" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" }
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "userStatus" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" }
                }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "UserStatus" }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserStatus" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "UserStatus" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "input_type" } },
          { kind: "Field", name: { kind: "Name", value: "user_exists" } },
          { kind: "Field", name: { kind: "Name", value: "email_verified" } },
          { kind: "Field", name: { kind: "Name", value: "no_password_set" } },
          { kind: "Field", name: { kind: "Name", value: "has_membership" } }
        ]
      }
    }
  ]
} as unknown as DocumentNode

/**
 * __useUserStatusQuery__
 *
 * To run a query within a React component, call `useUserStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserStatusQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserStatusQuery,
    UserStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserStatusQuery, UserStatusQueryVariables>(
    UserStatusDocument,
    options
  )
}
export function useUserStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserStatusQuery,
    UserStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserStatusQuery, UserStatusQueryVariables>(
    UserStatusDocument,
    options
  )
}
export function useUserStatusSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UserStatusQuery,
    UserStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<UserStatusQuery, UserStatusQueryVariables>(
    UserStatusDocument,
    options
  )
}
export type UserStatusQueryHookResult = ReturnType<typeof useUserStatusQuery>
export type UserStatusLazyQueryHookResult = ReturnType<
  typeof useUserStatusLazyQuery
>
export type UserStatusSuspenseQueryHookResult = ReturnType<
  typeof useUserStatusSuspenseQuery
>
export type UserStatusQueryResult = Apollo.QueryResult<
  UserStatusQuery,
  UserStatusQueryVariables
>
export const SignInPasswordDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SignInPassword" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "email" }
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
          }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "password" }
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
          }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getToken" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "email" }
                }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "password" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "password" }
                }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "token" } },
                { kind: "Field", name: { kind: "Name", value: "user_id" } },
                { kind: "Field", name: { kind: "Name", value: "created" } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode
export type SignInPasswordMutationFn = Apollo.MutationFunction<
  SignInPasswordMutation,
  SignInPasswordMutationVariables
>

/**
 * __useSignInPasswordMutation__
 *
 * To run a mutation, you first call `useSignInPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInPasswordMutation, { data, loading, error }] = useSignInPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSignInPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignInPasswordMutation,
    SignInPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SignInPasswordMutation,
    SignInPasswordMutationVariables
  >(SignInPasswordDocument, options)
}
export type SignInPasswordMutationHookResult = ReturnType<
  typeof useSignInPasswordMutation
>
export type SignInPasswordMutationResult =
  Apollo.MutationResult<SignInPasswordMutation>
export type SignInPasswordMutationOptions = Apollo.BaseMutationOptions<
  SignInPasswordMutation,
  SignInPasswordMutationVariables
>
export const SignOutDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SignOut" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "signOut" } }
        ]
      }
    }
  ]
} as unknown as DocumentNode
export type SignOutMutationFn = Apollo.MutationFunction<
  SignOutMutation,
  SignOutMutationVariables
>

/**
 * __useSignOutMutation__
 *
 * To run a mutation, you first call `useSignOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signOutMutation, { data, loading, error }] = useSignOutMutation({
 *   variables: {
 *   },
 * });
 */
export function useSignOutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignOutMutation,
    SignOutMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SignOutMutation, SignOutMutationVariables>(
    SignOutDocument,
    options
  )
}
export type SignOutMutationHookResult = ReturnType<typeof useSignOutMutation>
export type SignOutMutationResult = Apollo.MutationResult<SignOutMutation>
export type SignOutMutationOptions = Apollo.BaseMutationOptions<
  SignOutMutation,
  SignOutMutationVariables
>
export const SignUpDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SignUp" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "firstName" }
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
          }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "lastName" }
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
          }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "email" }
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
          }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "password" }
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "consent" }
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "inviteToken" }
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "createMembership" }
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "signUp" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "first_name" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "firstName" }
                }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "last_name" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "lastName" }
                }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "email" }
                }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "password" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "password" }
                }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "consent" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "consent" }
                }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "invite_token" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "inviteToken" }
                }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "create_membership" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "createMembership" }
                }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "token" } },
                { kind: "Field", name: { kind: "Name", value: "user_id" } },
                { kind: "Field", name: { kind: "Name", value: "created" } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode
export type SignUpMutationFn = Apollo.MutationFunction<
  SignUpMutation,
  SignUpMutationVariables
>

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      consent: // value for 'consent'
 *      inviteToken: // value for 'inviteToken'
 *      createMembership: // value for 'createMembership'
 *   },
 * });
 */
export function useSignUpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignUpMutation,
    SignUpMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(
    SignUpDocument,
    options
  )
}
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>
export type SignUpMutationOptions = Apollo.BaseMutationOptions<
  SignUpMutation,
  SignUpMutationVariables
>
export const TermsAgreementDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "TermsAgreement" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "school" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "page" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "page_type" },
                      value: { kind: "IntValue", value: "5" }
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "is_live" },
                      value: { kind: "BooleanValue", value: true }
                    }
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "external_url" }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode

/**
 * __useTermsAgreementQuery__
 *
 * To run a query within a React component, call `useTermsAgreementQuery` and pass it any options that fit your needs.
 * When your component renders, `useTermsAgreementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTermsAgreementQuery({
 *   variables: {
 *   },
 * });
 */
export function useTermsAgreementQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TermsAgreementQuery,
    TermsAgreementQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TermsAgreementQuery, TermsAgreementQueryVariables>(
    TermsAgreementDocument,
    options
  )
}
export function useTermsAgreementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TermsAgreementQuery,
    TermsAgreementQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TermsAgreementQuery, TermsAgreementQueryVariables>(
    TermsAgreementDocument,
    options
  )
}
export function useTermsAgreementSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    TermsAgreementQuery,
    TermsAgreementQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    TermsAgreementQuery,
    TermsAgreementQueryVariables
  >(TermsAgreementDocument, options)
}
export type TermsAgreementQueryHookResult = ReturnType<
  typeof useTermsAgreementQuery
>
export type TermsAgreementLazyQueryHookResult = ReturnType<
  typeof useTermsAgreementLazyQuery
>
export type TermsAgreementSuspenseQueryHookResult = ReturnType<
  typeof useTermsAgreementSuspenseQuery
>
export type TermsAgreementQueryResult = Apollo.QueryResult<
  TermsAgreementQuery,
  TermsAgreementQueryVariables
>
export const PathRegistrationsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "PathRegistrations" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "first" }
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } }
          }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "registrations" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "first" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "first" }
                      }
                    }
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "edges" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "node" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "RegistrationListView"
                                    }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "RegistrationListView" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Registration" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "role" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "meta" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "NodeMeta" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "path" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "progress" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "meta" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "NodeMeta" }
                      }
                    ]
                  }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "theme" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "NodeTheme" }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NodeMeta" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "NodeMeta" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "key" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "url" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "subtitle" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "src" } }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NodeThemeBackground" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "NodeThemeBackground" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "src" } }
              ]
            }
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "image_blurred" },
            name: { kind: "Name", value: "image" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "blur" },
                value: { kind: "IntValue", value: "320" }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "src" } }
              ]
            }
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "image_blurred_dark" },
            name: { kind: "Name", value: "image" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "blur" },
                value: { kind: "IntValue", value: "320" }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "brightness" },
                value: { kind: "IntValue", value: "-20" }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "src" } }
              ]
            }
          },
          { kind: "Field", name: { kind: "Name", value: "color" } },
          { kind: "Field", name: { kind: "Name", value: "contrast_color" } }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NodeTheme" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "NodeTheme" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "color" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "color_palette" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_50" } },
                { kind: "Field", name: { kind: "Name", value: "_100" } },
                { kind: "Field", name: { kind: "Name", value: "_200" } },
                { kind: "Field", name: { kind: "Name", value: "_300" } },
                { kind: "Field", name: { kind: "Name", value: "_400" } },
                { kind: "Field", name: { kind: "Name", value: "_500" } },
                { kind: "Field", name: { kind: "Name", value: "_600" } },
                { kind: "Field", name: { kind: "Name", value: "_700" } },
                { kind: "Field", name: { kind: "Name", value: "_800" } },
                { kind: "Field", name: { kind: "Name", value: "_900" } }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "background" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "NodeThemeBackground" }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode

/**
 * __usePathRegistrationsQuery__
 *
 * To run a query within a React component, call `usePathRegistrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePathRegistrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePathRegistrationsQuery({
 *   variables: {
 *      first: // value for 'first'
 *   },
 * });
 */
export function usePathRegistrationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    PathRegistrationsQuery,
    PathRegistrationsQueryVariables
  > &
    (
      | { variables: PathRegistrationsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    PathRegistrationsQuery,
    PathRegistrationsQueryVariables
  >(PathRegistrationsDocument, options)
}
export function usePathRegistrationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PathRegistrationsQuery,
    PathRegistrationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    PathRegistrationsQuery,
    PathRegistrationsQueryVariables
  >(PathRegistrationsDocument, options)
}
export function usePathRegistrationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PathRegistrationsQuery,
    PathRegistrationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    PathRegistrationsQuery,
    PathRegistrationsQueryVariables
  >(PathRegistrationsDocument, options)
}
export type PathRegistrationsQueryHookResult = ReturnType<
  typeof usePathRegistrationsQuery
>
export type PathRegistrationsLazyQueryHookResult = ReturnType<
  typeof usePathRegistrationsLazyQuery
>
export type PathRegistrationsSuspenseQueryHookResult = ReturnType<
  typeof usePathRegistrationsSuspenseQuery
>
export type PathRegistrationsQueryResult = Apollo.QueryResult<
  PathRegistrationsQuery,
  PathRegistrationsQueryVariables
>
export const PathDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Path" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } }
          }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "path" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "PathView" }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserMembership" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Membership" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "role" } }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "User" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "first_name" } },
          { kind: "Field", name: { kind: "Name", value: "last_name" } },
          { kind: "Field", name: { kind: "Name", value: "full_name" } },
          { kind: "Field", name: { kind: "Name", value: "display_name" } },
          { kind: "Field", name: { kind: "Name", value: "is_finalized" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "profile" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "bio" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "image" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "width" },
                      value: { kind: "IntValue", value: "250" }
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "height" },
                      value: { kind: "IntValue", value: "250" }
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "crop" },
                      value: { kind: "EnumValue", value: "faces" }
                    }
                  ]
                },
                { kind: "Field", name: { kind: "Name", value: "location" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "last_activity" }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "notification_digest_interval" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "membership" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "UserMembership" }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PathView" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Path" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "User" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "meta" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "NodeMeta" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "theme" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "NodeTheme" }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NodeMeta" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "NodeMeta" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "key" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "url" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "subtitle" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "src" } }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NodeThemeBackground" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "NodeThemeBackground" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "src" } }
              ]
            }
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "image_blurred" },
            name: { kind: "Name", value: "image" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "blur" },
                value: { kind: "IntValue", value: "320" }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "src" } }
              ]
            }
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "image_blurred_dark" },
            name: { kind: "Name", value: "image" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "blur" },
                value: { kind: "IntValue", value: "320" }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "brightness" },
                value: { kind: "IntValue", value: "-20" }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "src" } }
              ]
            }
          },
          { kind: "Field", name: { kind: "Name", value: "color" } },
          { kind: "Field", name: { kind: "Name", value: "contrast_color" } }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NodeTheme" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "NodeTheme" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "color" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "color_palette" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_50" } },
                { kind: "Field", name: { kind: "Name", value: "_100" } },
                { kind: "Field", name: { kind: "Name", value: "_200" } },
                { kind: "Field", name: { kind: "Name", value: "_300" } },
                { kind: "Field", name: { kind: "Name", value: "_400" } },
                { kind: "Field", name: { kind: "Name", value: "_500" } },
                { kind: "Field", name: { kind: "Name", value: "_600" } },
                { kind: "Field", name: { kind: "Name", value: "_700" } },
                { kind: "Field", name: { kind: "Name", value: "_800" } },
                { kind: "Field", name: { kind: "Name", value: "_900" } }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "background" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "NodeThemeBackground" }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode

/**
 * __usePathQuery__
 *
 * To run a query within a React component, call `usePathQuery` and pass it any options that fit your needs.
 * When your component renders, `usePathQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePathQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePathQuery(
  baseOptions: Apollo.QueryHookOptions<PathQuery, PathQueryVariables> &
    ({ variables: PathQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PathQuery, PathQueryVariables>(PathDocument, options)
}
export function usePathLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PathQuery, PathQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PathQuery, PathQueryVariables>(
    PathDocument,
    options
  )
}
export function usePathSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<PathQuery, PathQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<PathQuery, PathQueryVariables>(
    PathDocument,
    options
  )
}
export type PathQueryHookResult = ReturnType<typeof usePathQuery>
export type PathLazyQueryHookResult = ReturnType<typeof usePathLazyQuery>
export type PathSuspenseQueryHookResult = ReturnType<
  typeof usePathSuspenseQuery
>
export type PathQueryResult = Apollo.QueryResult<PathQuery, PathQueryVariables>
export const PathItemsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "PathItems" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } }
          }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "first" }
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } }
          }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "pathItems" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "first" }
                }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "path_id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "edges" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "node" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "PathItemListView" }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserMembership" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Membership" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "role" } }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "User" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "first_name" } },
          { kind: "Field", name: { kind: "Name", value: "last_name" } },
          { kind: "Field", name: { kind: "Name", value: "full_name" } },
          { kind: "Field", name: { kind: "Name", value: "display_name" } },
          { kind: "Field", name: { kind: "Name", value: "is_finalized" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "profile" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "bio" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "image" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "width" },
                      value: { kind: "IntValue", value: "250" }
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "height" },
                      value: { kind: "IntValue", value: "250" }
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "crop" },
                      value: { kind: "EnumValue", value: "faces" }
                    }
                  ]
                },
                { kind: "Field", name: { kind: "Name", value: "location" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "last_activity" }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "notification_digest_interval" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "membership" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "UserMembership" }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PathItemListView" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "PathItem" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "source_id" } },
          { kind: "Field", name: { kind: "Name", value: "order" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "due" } },
          { kind: "Field", name: { kind: "Name", value: "passive_due" } },
          { kind: "Field", name: { kind: "Name", value: "starts" } },
          { kind: "Field", name: { kind: "Name", value: "passive_starts" } },
          { kind: "Field", name: { kind: "Name", value: "has_started" } },
          { kind: "Field", name: { kind: "Name", value: "parent_id" } },
          { kind: "Field", name: { kind: "Name", value: "parent_source_id" } },
          { kind: "Field", name: { kind: "Name", value: "assignment_type" } },
          { kind: "Field", name: { kind: "Name", value: "verb" } },
          { kind: "Field", name: { kind: "Name", value: "content_type" } },
          { kind: "Field", name: { kind: "Name", value: "content_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "item_content_type_id" }
          },
          { kind: "Field", name: { kind: "Name", value: "points" } },
          { kind: "Field", name: { kind: "Name", value: "progress" } },
          { kind: "Field", name: { kind: "Name", value: "path_id" } },
          { kind: "Field", name: { kind: "Name", value: "time_to_complete" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "meta" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "NodeMeta" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "completion" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CompletionFragment" }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NodeMeta" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "NodeMeta" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "key" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "url" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "subtitle" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "src" } }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CompletionFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Completion" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "checked_date" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "completion_attempts" }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "completion_comment" }
          },
          { kind: "Field", name: { kind: "Name", value: "completion_date" } },
          { kind: "Field", name: { kind: "Name", value: "completion_due" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "last_completion_attempt_date" }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "has_failed_passing_attempt" }
          },
          { kind: "Field", name: { kind: "Name", value: "has_points" } },
          { kind: "Field", name: { kind: "Name", value: "is_late" } },
          { kind: "Field", name: { kind: "Name", value: "is_graded" } },
          { kind: "Field", name: { kind: "Name", value: "is_skipped" } },
          { kind: "Field", name: { kind: "Name", value: "is_complete" } },
          { kind: "Field", name: { kind: "Name", value: "is_auto_graded" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "is_forced_completion" }
          },
          { kind: "Field", name: { kind: "Name", value: "needs_grading" } },
          { kind: "Field", name: { kind: "Name", value: "userpoints_earned" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "userpoints_explanation" }
          },
          { kind: "Field", name: { kind: "Name", value: "userpoints_value" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "minimum_passing_score" }
          },
          { kind: "Field", name: { kind: "Name", value: "grading_type" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "requires_api_completion" }
          },
          { kind: "Field", name: { kind: "Name", value: "overridden_by_api" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "overridden_by" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "User" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "checked_by" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "User" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "User" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "submission" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "text" } },
                { kind: "Field", name: { kind: "Name", value: "file_url" } },
                { kind: "Field", name: { kind: "Name", value: "file_name" } },
                { kind: "Field", name: { kind: "Name", value: "file_image" } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode

/**
 * __usePathItemsQuery__
 *
 * To run a query within a React component, call `usePathItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePathItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePathItemsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *   },
 * });
 */
export function usePathItemsQuery(
  baseOptions: Apollo.QueryHookOptions<
    PathItemsQuery,
    PathItemsQueryVariables
  > &
    ({ variables: PathItemsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PathItemsQuery, PathItemsQueryVariables>(
    PathItemsDocument,
    options
  )
}
export function usePathItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PathItemsQuery,
    PathItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PathItemsQuery, PathItemsQueryVariables>(
    PathItemsDocument,
    options
  )
}
export function usePathItemsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PathItemsQuery,
    PathItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<PathItemsQuery, PathItemsQueryVariables>(
    PathItemsDocument,
    options
  )
}
export type PathItemsQueryHookResult = ReturnType<typeof usePathItemsQuery>
export type PathItemsLazyQueryHookResult = ReturnType<
  typeof usePathItemsLazyQuery
>
export type PathItemsSuspenseQueryHookResult = ReturnType<
  typeof usePathItemsSuspenseQuery
>
export type PathItemsQueryResult = Apollo.QueryResult<
  PathItemsQuery,
  PathItemsQueryVariables
>
export const PathItemGroupDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "PathItemGroup" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "sourceId" }
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "userId" }
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "pathId" }
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "pathItem" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "source_id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "sourceId" }
                }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "user_id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "userId" }
                }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "path_id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "pathId" }
                }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "PathItemGroupView" }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserMembership" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Membership" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "role" } }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "User" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "first_name" } },
          { kind: "Field", name: { kind: "Name", value: "last_name" } },
          { kind: "Field", name: { kind: "Name", value: "full_name" } },
          { kind: "Field", name: { kind: "Name", value: "display_name" } },
          { kind: "Field", name: { kind: "Name", value: "is_finalized" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "profile" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "bio" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "image" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "width" },
                      value: { kind: "IntValue", value: "250" }
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "height" },
                      value: { kind: "IntValue", value: "250" }
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "crop" },
                      value: { kind: "EnumValue", value: "faces" }
                    }
                  ]
                },
                { kind: "Field", name: { kind: "Name", value: "location" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "last_activity" }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "notification_digest_interval" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "membership" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "UserMembership" }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PathItemListView" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "PathItem" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "source_id" } },
          { kind: "Field", name: { kind: "Name", value: "order" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "due" } },
          { kind: "Field", name: { kind: "Name", value: "passive_due" } },
          { kind: "Field", name: { kind: "Name", value: "starts" } },
          { kind: "Field", name: { kind: "Name", value: "passive_starts" } },
          { kind: "Field", name: { kind: "Name", value: "has_started" } },
          { kind: "Field", name: { kind: "Name", value: "parent_id" } },
          { kind: "Field", name: { kind: "Name", value: "parent_source_id" } },
          { kind: "Field", name: { kind: "Name", value: "assignment_type" } },
          { kind: "Field", name: { kind: "Name", value: "verb" } },
          { kind: "Field", name: { kind: "Name", value: "content_type" } },
          { kind: "Field", name: { kind: "Name", value: "content_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "item_content_type_id" }
          },
          { kind: "Field", name: { kind: "Name", value: "points" } },
          { kind: "Field", name: { kind: "Name", value: "progress" } },
          { kind: "Field", name: { kind: "Name", value: "path_id" } },
          { kind: "Field", name: { kind: "Name", value: "time_to_complete" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "meta" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "NodeMeta" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "completion" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CompletionFragment" }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PathItemGroupView" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "PathItem" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "PathItemListView" }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "items" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "IntValue", value: "100" }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "edges" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "node" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "PathItemListView" }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NodeMeta" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "NodeMeta" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "key" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "url" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "subtitle" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "src" } }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CompletionFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Completion" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "checked_date" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "completion_attempts" }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "completion_comment" }
          },
          { kind: "Field", name: { kind: "Name", value: "completion_date" } },
          { kind: "Field", name: { kind: "Name", value: "completion_due" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "last_completion_attempt_date" }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "has_failed_passing_attempt" }
          },
          { kind: "Field", name: { kind: "Name", value: "has_points" } },
          { kind: "Field", name: { kind: "Name", value: "is_late" } },
          { kind: "Field", name: { kind: "Name", value: "is_graded" } },
          { kind: "Field", name: { kind: "Name", value: "is_skipped" } },
          { kind: "Field", name: { kind: "Name", value: "is_complete" } },
          { kind: "Field", name: { kind: "Name", value: "is_auto_graded" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "is_forced_completion" }
          },
          { kind: "Field", name: { kind: "Name", value: "needs_grading" } },
          { kind: "Field", name: { kind: "Name", value: "userpoints_earned" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "userpoints_explanation" }
          },
          { kind: "Field", name: { kind: "Name", value: "userpoints_value" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "minimum_passing_score" }
          },
          { kind: "Field", name: { kind: "Name", value: "grading_type" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "requires_api_completion" }
          },
          { kind: "Field", name: { kind: "Name", value: "overridden_by_api" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "overridden_by" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "User" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "checked_by" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "User" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "User" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "submission" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "text" } },
                { kind: "Field", name: { kind: "Name", value: "file_url" } },
                { kind: "Field", name: { kind: "Name", value: "file_name" } },
                { kind: "Field", name: { kind: "Name", value: "file_image" } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode

/**
 * __usePathItemGroupQuery__
 *
 * To run a query within a React component, call `usePathItemGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `usePathItemGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePathItemGroupQuery({
 *   variables: {
 *      sourceId: // value for 'sourceId'
 *      userId: // value for 'userId'
 *      pathId: // value for 'pathId'
 *   },
 * });
 */
export function usePathItemGroupQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PathItemGroupQuery,
    PathItemGroupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PathItemGroupQuery, PathItemGroupQueryVariables>(
    PathItemGroupDocument,
    options
  )
}
export function usePathItemGroupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PathItemGroupQuery,
    PathItemGroupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PathItemGroupQuery, PathItemGroupQueryVariables>(
    PathItemGroupDocument,
    options
  )
}
export function usePathItemGroupSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PathItemGroupQuery,
    PathItemGroupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    PathItemGroupQuery,
    PathItemGroupQueryVariables
  >(PathItemGroupDocument, options)
}
export type PathItemGroupQueryHookResult = ReturnType<
  typeof usePathItemGroupQuery
>
export type PathItemGroupLazyQueryHookResult = ReturnType<
  typeof usePathItemGroupLazyQuery
>
export type PathItemGroupSuspenseQueryHookResult = ReturnType<
  typeof usePathItemGroupSuspenseQuery
>
export type PathItemGroupQueryResult = Apollo.QueryResult<
  PathItemGroupQuery,
  PathItemGroupQueryVariables
>
export const PublishPathToCodexDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "PublishPathToCodex" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "pathId" }
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } }
          }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "overrideId" }
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "upsert" }
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "suffix" }
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "publishPathToCodex" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "path_id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "pathId" }
                }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "override_id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "overrideId" }
                }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "upsert" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "upsert" }
                }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "suffix" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "suffix" }
                }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "node" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "PathsCodexNode" }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PathsCodexNode" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "PathsCodexNode" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "key" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "version" } }
        ]
      }
    }
  ]
} as unknown as DocumentNode
export type PublishPathToCodexMutationFn = Apollo.MutationFunction<
  PublishPathToCodexMutation,
  PublishPathToCodexMutationVariables
>

/**
 * __usePublishPathToCodexMutation__
 *
 * To run a mutation, you first call `usePublishPathToCodexMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishPathToCodexMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishPathToCodexMutation, { data, loading, error }] = usePublishPathToCodexMutation({
 *   variables: {
 *      pathId: // value for 'pathId'
 *      overrideId: // value for 'overrideId'
 *      upsert: // value for 'upsert'
 *      suffix: // value for 'suffix'
 *   },
 * });
 */
export function usePublishPathToCodexMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PublishPathToCodexMutation,
    PublishPathToCodexMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    PublishPathToCodexMutation,
    PublishPathToCodexMutationVariables
  >(PublishPathToCodexDocument, options)
}
export type PublishPathToCodexMutationHookResult = ReturnType<
  typeof usePublishPathToCodexMutation
>
export type PublishPathToCodexMutationResult =
  Apollo.MutationResult<PublishPathToCodexMutation>
export type PublishPathToCodexMutationOptions = Apollo.BaseMutationOptions<
  PublishPathToCodexMutation,
  PublishPathToCodexMutationVariables
>
export const PublishPathToCodexPermissionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "publishPathToCodexPermission" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "space" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "features" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "paths_app__publish" }
                      }
                    ]
                  }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "permissions" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "has_pathwright_staff_access"
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode

/**
 * __usePublishPathToCodexPermissionQuery__
 *
 * To run a query within a React component, call `usePublishPathToCodexPermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishPathToCodexPermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishPathToCodexPermissionQuery({
 *   variables: {
 *   },
 * });
 */
export function usePublishPathToCodexPermissionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PublishPathToCodexPermissionQuery,
    PublishPathToCodexPermissionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    PublishPathToCodexPermissionQuery,
    PublishPathToCodexPermissionQueryVariables
  >(PublishPathToCodexPermissionDocument, options)
}
export function usePublishPathToCodexPermissionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PublishPathToCodexPermissionQuery,
    PublishPathToCodexPermissionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    PublishPathToCodexPermissionQuery,
    PublishPathToCodexPermissionQueryVariables
  >(PublishPathToCodexPermissionDocument, options)
}
export function usePublishPathToCodexPermissionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PublishPathToCodexPermissionQuery,
    PublishPathToCodexPermissionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    PublishPathToCodexPermissionQuery,
    PublishPathToCodexPermissionQueryVariables
  >(PublishPathToCodexPermissionDocument, options)
}
export type PublishPathToCodexPermissionQueryHookResult = ReturnType<
  typeof usePublishPathToCodexPermissionQuery
>
export type PublishPathToCodexPermissionLazyQueryHookResult = ReturnType<
  typeof usePublishPathToCodexPermissionLazyQuery
>
export type PublishPathToCodexPermissionSuspenseQueryHookResult = ReturnType<
  typeof usePublishPathToCodexPermissionSuspenseQuery
>
export type PublishPathToCodexPermissionQueryResult = Apollo.QueryResult<
  PublishPathToCodexPermissionQuery,
  PublishPathToCodexPermissionQueryVariables
>
export const SpaceConnectionsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "spaceConnections" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "first" }
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } }
          }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "search" }
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "spaceConnections" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "first" }
                }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "search" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "search" }
                }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "total" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "edges" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "node" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "space" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "FragmentSpread",
                                    name: { kind: "Name", value: "Space" }
                                  }
                                ]
                              }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "role" }
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "last_activity_date"
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NodeMeta" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "NodeMeta" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "key" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "url" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "subtitle" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "src" } }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NodeThemeBackground" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "NodeThemeBackground" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "src" } }
              ]
            }
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "image_blurred" },
            name: { kind: "Name", value: "image" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "blur" },
                value: { kind: "IntValue", value: "320" }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "src" } }
              ]
            }
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "image_blurred_dark" },
            name: { kind: "Name", value: "image" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "blur" },
                value: { kind: "IntValue", value: "320" }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "brightness" },
                value: { kind: "IntValue", value: "-20" }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "src" } }
              ]
            }
          },
          { kind: "Field", name: { kind: "Name", value: "color" } },
          { kind: "Field", name: { kind: "Name", value: "contrast_color" } }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NodeTheme" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "NodeTheme" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "color" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "color_palette" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_50" } },
                { kind: "Field", name: { kind: "Name", value: "_100" } },
                { kind: "Field", name: { kind: "Name", value: "_200" } },
                { kind: "Field", name: { kind: "Name", value: "_300" } },
                { kind: "Field", name: { kind: "Name", value: "_400" } },
                { kind: "Field", name: { kind: "Name", value: "_500" } },
                { kind: "Field", name: { kind: "Name", value: "_600" } },
                { kind: "Field", name: { kind: "Name", value: "_700" } },
                { kind: "Field", name: { kind: "Name", value: "_800" } },
                { kind: "Field", name: { kind: "Name", value: "_900" } }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "background" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "NodeThemeBackground" }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Space" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Space" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "subdomain" } },
          { kind: "Field", name: { kind: "Name", value: "created" } },
          { kind: "Field", name: { kind: "Name", value: "is_activated" } },
          { kind: "Field", name: { kind: "Name", value: "ietf_language_tag" } },
          { kind: "Field", name: { kind: "Name", value: "is_owner" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "width" },
                value: { kind: "IntValue", value: "150" }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "height" },
                value: { kind: "IntValue", value: "150" }
              }
            ]
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "meta" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "NodeMeta" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "theme" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "NodeTheme" }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode

/**
 * __useSpaceConnectionsQuery__
 *
 * To run a query within a React component, call `useSpaceConnectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpaceConnectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpaceConnectionsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSpaceConnectionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SpaceConnectionsQuery,
    SpaceConnectionsQueryVariables
  > &
    (
      | { variables: SpaceConnectionsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SpaceConnectionsQuery, SpaceConnectionsQueryVariables>(
    SpaceConnectionsDocument,
    options
  )
}
export function useSpaceConnectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpaceConnectionsQuery,
    SpaceConnectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SpaceConnectionsQuery,
    SpaceConnectionsQueryVariables
  >(SpaceConnectionsDocument, options)
}
export function useSpaceConnectionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SpaceConnectionsQuery,
    SpaceConnectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    SpaceConnectionsQuery,
    SpaceConnectionsQueryVariables
  >(SpaceConnectionsDocument, options)
}
export type SpaceConnectionsQueryHookResult = ReturnType<
  typeof useSpaceConnectionsQuery
>
export type SpaceConnectionsLazyQueryHookResult = ReturnType<
  typeof useSpaceConnectionsLazyQuery
>
export type SpaceConnectionsSuspenseQueryHookResult = ReturnType<
  typeof useSpaceConnectionsSuspenseQuery
>
export type SpaceConnectionsQueryResult = Apollo.QueryResult<
  SpaceConnectionsQuery,
  SpaceConnectionsQueryVariables
>
export const SpaceContextQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SpaceContextQuery" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            alias: { kind: "Name", value: "user" },
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "User" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "space" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Space" }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserMembership" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Membership" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "role" } }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "User" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "first_name" } },
          { kind: "Field", name: { kind: "Name", value: "last_name" } },
          { kind: "Field", name: { kind: "Name", value: "full_name" } },
          { kind: "Field", name: { kind: "Name", value: "display_name" } },
          { kind: "Field", name: { kind: "Name", value: "is_finalized" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "profile" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "bio" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "image" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "width" },
                      value: { kind: "IntValue", value: "250" }
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "height" },
                      value: { kind: "IntValue", value: "250" }
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "crop" },
                      value: { kind: "EnumValue", value: "faces" }
                    }
                  ]
                },
                { kind: "Field", name: { kind: "Name", value: "location" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "last_activity" }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "notification_digest_interval" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "membership" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "UserMembership" }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NodeMeta" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "NodeMeta" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "key" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "url" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "subtitle" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "src" } }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NodeThemeBackground" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "NodeThemeBackground" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "src" } }
              ]
            }
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "image_blurred" },
            name: { kind: "Name", value: "image" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "blur" },
                value: { kind: "IntValue", value: "320" }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "src" } }
              ]
            }
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "image_blurred_dark" },
            name: { kind: "Name", value: "image" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "blur" },
                value: { kind: "IntValue", value: "320" }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "brightness" },
                value: { kind: "IntValue", value: "-20" }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "src" } }
              ]
            }
          },
          { kind: "Field", name: { kind: "Name", value: "color" } },
          { kind: "Field", name: { kind: "Name", value: "contrast_color" } }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NodeTheme" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "NodeTheme" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "color" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "color_palette" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_50" } },
                { kind: "Field", name: { kind: "Name", value: "_100" } },
                { kind: "Field", name: { kind: "Name", value: "_200" } },
                { kind: "Field", name: { kind: "Name", value: "_300" } },
                { kind: "Field", name: { kind: "Name", value: "_400" } },
                { kind: "Field", name: { kind: "Name", value: "_500" } },
                { kind: "Field", name: { kind: "Name", value: "_600" } },
                { kind: "Field", name: { kind: "Name", value: "_700" } },
                { kind: "Field", name: { kind: "Name", value: "_800" } },
                { kind: "Field", name: { kind: "Name", value: "_900" } }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "background" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "NodeThemeBackground" }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Space" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Space" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "subdomain" } },
          { kind: "Field", name: { kind: "Name", value: "created" } },
          { kind: "Field", name: { kind: "Name", value: "is_activated" } },
          { kind: "Field", name: { kind: "Name", value: "ietf_language_tag" } },
          { kind: "Field", name: { kind: "Name", value: "is_owner" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "width" },
                value: { kind: "IntValue", value: "150" }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "height" },
                value: { kind: "IntValue", value: "150" }
              }
            ]
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "meta" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "NodeMeta" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "theme" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "NodeTheme" }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode

/**
 * __useSpaceContextQueryQuery__
 *
 * To run a query within a React component, call `useSpaceContextQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpaceContextQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpaceContextQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useSpaceContextQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpaceContextQueryQuery,
    SpaceContextQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SpaceContextQueryQuery,
    SpaceContextQueryQueryVariables
  >(SpaceContextQueryDocument, options)
}
export function useSpaceContextQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpaceContextQueryQuery,
    SpaceContextQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SpaceContextQueryQuery,
    SpaceContextQueryQueryVariables
  >(SpaceContextQueryDocument, options)
}
export function useSpaceContextQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SpaceContextQueryQuery,
    SpaceContextQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    SpaceContextQueryQuery,
    SpaceContextQueryQueryVariables
  >(SpaceContextQueryDocument, options)
}
export type SpaceContextQueryQueryHookResult = ReturnType<
  typeof useSpaceContextQueryQuery
>
export type SpaceContextQueryLazyQueryHookResult = ReturnType<
  typeof useSpaceContextQueryLazyQuery
>
export type SpaceContextQuerySuspenseQueryHookResult = ReturnType<
  typeof useSpaceContextQuerySuspenseQuery
>
export type SpaceContextQueryQueryResult = Apollo.QueryResult<
  SpaceContextQueryQuery,
  SpaceContextQueryQueryVariables
>
export const SpaceFeaturesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SpaceFeatures" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "space" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "features" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "SpaceFeatures" }
                      }
                    ]
                  }
                }
              ]
            }
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "user" },
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "is_superuser" } }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SpaceFeatures" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "SpaceFeatures" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "auth__login_option__email_link" }
          },
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "auth__login_option__global_sso__apple"
            }
          },
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "auth__login_option__global_sso__google"
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "auth__login_option__password" }
          },
          { kind: "Field", name: { kind: "Name", value: "auth__platform" } }
        ]
      }
    }
  ]
} as unknown as DocumentNode

/**
 * __useSpaceFeaturesQuery__
 *
 * To run a query within a React component, call `useSpaceFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpaceFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpaceFeaturesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSpaceFeaturesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SpaceFeaturesQuery,
    SpaceFeaturesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SpaceFeaturesQuery, SpaceFeaturesQueryVariables>(
    SpaceFeaturesDocument,
    options
  )
}
export function useSpaceFeaturesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpaceFeaturesQuery,
    SpaceFeaturesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SpaceFeaturesQuery, SpaceFeaturesQueryVariables>(
    SpaceFeaturesDocument,
    options
  )
}
export function useSpaceFeaturesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SpaceFeaturesQuery,
    SpaceFeaturesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    SpaceFeaturesQuery,
    SpaceFeaturesQueryVariables
  >(SpaceFeaturesDocument, options)
}
export type SpaceFeaturesQueryHookResult = ReturnType<
  typeof useSpaceFeaturesQuery
>
export type SpaceFeaturesLazyQueryHookResult = ReturnType<
  typeof useSpaceFeaturesLazyQuery
>
export type SpaceFeaturesSuspenseQueryHookResult = ReturnType<
  typeof useSpaceFeaturesSuspenseQuery
>
export type SpaceFeaturesQueryResult = Apollo.QueryResult<
  SpaceFeaturesQuery,
  SpaceFeaturesQueryVariables
>
export const PathStepDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "PathStep" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "sourceId" }
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "userId" }
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "pathId" }
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "pathItem" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "source_id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "sourceId" }
                }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "user_id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "userId" }
                }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "path_id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "pathId" }
                }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "PathItemView" }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserMembership" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Membership" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "role" } }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "User" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "first_name" } },
          { kind: "Field", name: { kind: "Name", value: "last_name" } },
          { kind: "Field", name: { kind: "Name", value: "full_name" } },
          { kind: "Field", name: { kind: "Name", value: "display_name" } },
          { kind: "Field", name: { kind: "Name", value: "is_finalized" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "profile" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "bio" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "image" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "width" },
                      value: { kind: "IntValue", value: "250" }
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "height" },
                      value: { kind: "IntValue", value: "250" }
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "crop" },
                      value: { kind: "EnumValue", value: "faces" }
                    }
                  ]
                },
                { kind: "Field", name: { kind: "Name", value: "location" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "last_activity" }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "notification_digest_interval" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "membership" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "UserMembership" }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PathItemListView" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "PathItem" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "source_id" } },
          { kind: "Field", name: { kind: "Name", value: "order" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "due" } },
          { kind: "Field", name: { kind: "Name", value: "passive_due" } },
          { kind: "Field", name: { kind: "Name", value: "starts" } },
          { kind: "Field", name: { kind: "Name", value: "passive_starts" } },
          { kind: "Field", name: { kind: "Name", value: "has_started" } },
          { kind: "Field", name: { kind: "Name", value: "parent_id" } },
          { kind: "Field", name: { kind: "Name", value: "parent_source_id" } },
          { kind: "Field", name: { kind: "Name", value: "assignment_type" } },
          { kind: "Field", name: { kind: "Name", value: "verb" } },
          { kind: "Field", name: { kind: "Name", value: "content_type" } },
          { kind: "Field", name: { kind: "Name", value: "content_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "item_content_type_id" }
          },
          { kind: "Field", name: { kind: "Name", value: "points" } },
          { kind: "Field", name: { kind: "Name", value: "progress" } },
          { kind: "Field", name: { kind: "Name", value: "path_id" } },
          { kind: "Field", name: { kind: "Name", value: "time_to_complete" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "meta" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "NodeMeta" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "completion" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CompletionFragment" }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NodeMeta" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "NodeMeta" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "key" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "url" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "subtitle" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "src" } }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PathItemView" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "PathItem" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "PathItemListView" }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CompletionFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Completion" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "checked_date" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "completion_attempts" }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "completion_comment" }
          },
          { kind: "Field", name: { kind: "Name", value: "completion_date" } },
          { kind: "Field", name: { kind: "Name", value: "completion_due" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "last_completion_attempt_date" }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "has_failed_passing_attempt" }
          },
          { kind: "Field", name: { kind: "Name", value: "has_points" } },
          { kind: "Field", name: { kind: "Name", value: "is_late" } },
          { kind: "Field", name: { kind: "Name", value: "is_graded" } },
          { kind: "Field", name: { kind: "Name", value: "is_skipped" } },
          { kind: "Field", name: { kind: "Name", value: "is_complete" } },
          { kind: "Field", name: { kind: "Name", value: "is_auto_graded" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "is_forced_completion" }
          },
          { kind: "Field", name: { kind: "Name", value: "needs_grading" } },
          { kind: "Field", name: { kind: "Name", value: "userpoints_earned" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "userpoints_explanation" }
          },
          { kind: "Field", name: { kind: "Name", value: "userpoints_value" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "minimum_passing_score" }
          },
          { kind: "Field", name: { kind: "Name", value: "grading_type" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "requires_api_completion" }
          },
          { kind: "Field", name: { kind: "Name", value: "overridden_by_api" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "overridden_by" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "User" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "checked_by" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "User" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "User" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "submission" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "text" } },
                { kind: "Field", name: { kind: "Name", value: "file_url" } },
                { kind: "Field", name: { kind: "Name", value: "file_name" } },
                { kind: "Field", name: { kind: "Name", value: "file_image" } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode

/**
 * __usePathStepQuery__
 *
 * To run a query within a React component, call `usePathStepQuery` and pass it any options that fit your needs.
 * When your component renders, `usePathStepQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePathStepQuery({
 *   variables: {
 *      sourceId: // value for 'sourceId'
 *      userId: // value for 'userId'
 *      pathId: // value for 'pathId'
 *   },
 * });
 */
export function usePathStepQuery(
  baseOptions?: Apollo.QueryHookOptions<PathStepQuery, PathStepQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PathStepQuery, PathStepQueryVariables>(
    PathStepDocument,
    options
  )
}
export function usePathStepLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PathStepQuery,
    PathStepQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PathStepQuery, PathStepQueryVariables>(
    PathStepDocument,
    options
  )
}
export function usePathStepSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PathStepQuery,
    PathStepQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<PathStepQuery, PathStepQueryVariables>(
    PathStepDocument,
    options
  )
}
export type PathStepQueryHookResult = ReturnType<typeof usePathStepQuery>
export type PathStepLazyQueryHookResult = ReturnType<
  typeof usePathStepLazyQuery
>
export type PathStepSuspenseQueryHookResult = ReturnType<
  typeof usePathStepSuspenseQuery
>
export type PathStepQueryResult = Apollo.QueryResult<
  PathStepQuery,
  PathStepQueryVariables
>
export const CompletePathItemDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CompletePathItem" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } }
          }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "pathId" }
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } }
          }
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "skip" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "completePathItem" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "path_id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "pathId" }
                }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "skip" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "skip" }
                }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "PathItemListView" }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserMembership" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Membership" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "role" } }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "User" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "first_name" } },
          { kind: "Field", name: { kind: "Name", value: "last_name" } },
          { kind: "Field", name: { kind: "Name", value: "full_name" } },
          { kind: "Field", name: { kind: "Name", value: "display_name" } },
          { kind: "Field", name: { kind: "Name", value: "is_finalized" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "profile" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "bio" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "image" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "width" },
                      value: { kind: "IntValue", value: "250" }
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "height" },
                      value: { kind: "IntValue", value: "250" }
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "crop" },
                      value: { kind: "EnumValue", value: "faces" }
                    }
                  ]
                },
                { kind: "Field", name: { kind: "Name", value: "location" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "last_activity" }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "notification_digest_interval" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "membership" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "UserMembership" }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PathItemListView" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "PathItem" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "source_id" } },
          { kind: "Field", name: { kind: "Name", value: "order" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "due" } },
          { kind: "Field", name: { kind: "Name", value: "passive_due" } },
          { kind: "Field", name: { kind: "Name", value: "starts" } },
          { kind: "Field", name: { kind: "Name", value: "passive_starts" } },
          { kind: "Field", name: { kind: "Name", value: "has_started" } },
          { kind: "Field", name: { kind: "Name", value: "parent_id" } },
          { kind: "Field", name: { kind: "Name", value: "parent_source_id" } },
          { kind: "Field", name: { kind: "Name", value: "assignment_type" } },
          { kind: "Field", name: { kind: "Name", value: "verb" } },
          { kind: "Field", name: { kind: "Name", value: "content_type" } },
          { kind: "Field", name: { kind: "Name", value: "content_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "item_content_type_id" }
          },
          { kind: "Field", name: { kind: "Name", value: "points" } },
          { kind: "Field", name: { kind: "Name", value: "progress" } },
          { kind: "Field", name: { kind: "Name", value: "path_id" } },
          { kind: "Field", name: { kind: "Name", value: "time_to_complete" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "meta" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "NodeMeta" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "completion" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CompletionFragment" }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NodeMeta" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "NodeMeta" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "key" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "url" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "subtitle" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "src" } }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CompletionFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Completion" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "checked_date" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "completion_attempts" }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "completion_comment" }
          },
          { kind: "Field", name: { kind: "Name", value: "completion_date" } },
          { kind: "Field", name: { kind: "Name", value: "completion_due" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "last_completion_attempt_date" }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "has_failed_passing_attempt" }
          },
          { kind: "Field", name: { kind: "Name", value: "has_points" } },
          { kind: "Field", name: { kind: "Name", value: "is_late" } },
          { kind: "Field", name: { kind: "Name", value: "is_graded" } },
          { kind: "Field", name: { kind: "Name", value: "is_skipped" } },
          { kind: "Field", name: { kind: "Name", value: "is_complete" } },
          { kind: "Field", name: { kind: "Name", value: "is_auto_graded" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "is_forced_completion" }
          },
          { kind: "Field", name: { kind: "Name", value: "needs_grading" } },
          { kind: "Field", name: { kind: "Name", value: "userpoints_earned" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "userpoints_explanation" }
          },
          { kind: "Field", name: { kind: "Name", value: "userpoints_value" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "minimum_passing_score" }
          },
          { kind: "Field", name: { kind: "Name", value: "grading_type" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "requires_api_completion" }
          },
          { kind: "Field", name: { kind: "Name", value: "overridden_by_api" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "overridden_by" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "User" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "checked_by" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "User" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "User" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "submission" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "text" } },
                { kind: "Field", name: { kind: "Name", value: "file_url" } },
                { kind: "Field", name: { kind: "Name", value: "file_name" } },
                { kind: "Field", name: { kind: "Name", value: "file_image" } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode
export type CompletePathItemMutationFn = Apollo.MutationFunction<
  CompletePathItemMutation,
  CompletePathItemMutationVariables
>

/**
 * __useCompletePathItemMutation__
 *
 * To run a mutation, you first call `useCompletePathItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompletePathItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completePathItemMutation, { data, loading, error }] = useCompletePathItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      pathId: // value for 'pathId'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useCompletePathItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompletePathItemMutation,
    CompletePathItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CompletePathItemMutation,
    CompletePathItemMutationVariables
  >(CompletePathItemDocument, options)
}
export type CompletePathItemMutationHookResult = ReturnType<
  typeof useCompletePathItemMutation
>
export type CompletePathItemMutationResult =
  Apollo.MutationResult<CompletePathItemMutation>
export type CompletePathItemMutationOptions = Apollo.BaseMutationOptions<
  CompletePathItemMutation,
  CompletePathItemMutationVariables
>
export const ResetPathItemDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ResetPathItem" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } }
          }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "pathId" }
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } }
          }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "resetPathItem" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "path_id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "pathId" }
                }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "PathItemListView" }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserMembership" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Membership" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "role" } }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "User" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "first_name" } },
          { kind: "Field", name: { kind: "Name", value: "last_name" } },
          { kind: "Field", name: { kind: "Name", value: "full_name" } },
          { kind: "Field", name: { kind: "Name", value: "display_name" } },
          { kind: "Field", name: { kind: "Name", value: "is_finalized" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "profile" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "bio" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "image" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "width" },
                      value: { kind: "IntValue", value: "250" }
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "height" },
                      value: { kind: "IntValue", value: "250" }
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "crop" },
                      value: { kind: "EnumValue", value: "faces" }
                    }
                  ]
                },
                { kind: "Field", name: { kind: "Name", value: "location" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "last_activity" }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "notification_digest_interval" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "membership" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "UserMembership" }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PathItemListView" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "PathItem" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "source_id" } },
          { kind: "Field", name: { kind: "Name", value: "order" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "due" } },
          { kind: "Field", name: { kind: "Name", value: "passive_due" } },
          { kind: "Field", name: { kind: "Name", value: "starts" } },
          { kind: "Field", name: { kind: "Name", value: "passive_starts" } },
          { kind: "Field", name: { kind: "Name", value: "has_started" } },
          { kind: "Field", name: { kind: "Name", value: "parent_id" } },
          { kind: "Field", name: { kind: "Name", value: "parent_source_id" } },
          { kind: "Field", name: { kind: "Name", value: "assignment_type" } },
          { kind: "Field", name: { kind: "Name", value: "verb" } },
          { kind: "Field", name: { kind: "Name", value: "content_type" } },
          { kind: "Field", name: { kind: "Name", value: "content_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "item_content_type_id" }
          },
          { kind: "Field", name: { kind: "Name", value: "points" } },
          { kind: "Field", name: { kind: "Name", value: "progress" } },
          { kind: "Field", name: { kind: "Name", value: "path_id" } },
          { kind: "Field", name: { kind: "Name", value: "time_to_complete" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "meta" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "NodeMeta" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "completion" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CompletionFragment" }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NodeMeta" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "NodeMeta" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "key" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "url" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "subtitle" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "src" } }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CompletionFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Completion" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "checked_date" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "completion_attempts" }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "completion_comment" }
          },
          { kind: "Field", name: { kind: "Name", value: "completion_date" } },
          { kind: "Field", name: { kind: "Name", value: "completion_due" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "last_completion_attempt_date" }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "has_failed_passing_attempt" }
          },
          { kind: "Field", name: { kind: "Name", value: "has_points" } },
          { kind: "Field", name: { kind: "Name", value: "is_late" } },
          { kind: "Field", name: { kind: "Name", value: "is_graded" } },
          { kind: "Field", name: { kind: "Name", value: "is_skipped" } },
          { kind: "Field", name: { kind: "Name", value: "is_complete" } },
          { kind: "Field", name: { kind: "Name", value: "is_auto_graded" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "is_forced_completion" }
          },
          { kind: "Field", name: { kind: "Name", value: "needs_grading" } },
          { kind: "Field", name: { kind: "Name", value: "userpoints_earned" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "userpoints_explanation" }
          },
          { kind: "Field", name: { kind: "Name", value: "userpoints_value" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "minimum_passing_score" }
          },
          { kind: "Field", name: { kind: "Name", value: "grading_type" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "requires_api_completion" }
          },
          { kind: "Field", name: { kind: "Name", value: "overridden_by_api" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "overridden_by" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "User" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "checked_by" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "User" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "User" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "submission" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "text" } },
                { kind: "Field", name: { kind: "Name", value: "file_url" } },
                { kind: "Field", name: { kind: "Name", value: "file_name" } },
                { kind: "Field", name: { kind: "Name", value: "file_image" } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode
export type ResetPathItemMutationFn = Apollo.MutationFunction<
  ResetPathItemMutation,
  ResetPathItemMutationVariables
>

/**
 * __useResetPathItemMutation__
 *
 * To run a mutation, you first call `useResetPathItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPathItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPathItemMutation, { data, loading, error }] = useResetPathItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      pathId: // value for 'pathId'
 *   },
 * });
 */
export function useResetPathItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPathItemMutation,
    ResetPathItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ResetPathItemMutation,
    ResetPathItemMutationVariables
  >(ResetPathItemDocument, options)
}
export type ResetPathItemMutationHookResult = ReturnType<
  typeof useResetPathItemMutation
>
export type ResetPathItemMutationResult =
  Apollo.MutationResult<ResetPathItemMutation>
export type ResetPathItemMutationOptions = Apollo.BaseMutationOptions<
  ResetPathItemMutation,
  ResetPathItemMutationVariables
>
export const PathStepCompletionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "PathStepCompletion" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "sourceId" }
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "userId" }
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "pathId" }
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "pathItem" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "source_id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "sourceId" }
                }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "user_id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "userId" }
                }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "path_id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "pathId" }
                }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "completion" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "CompletionFragment" }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserMembership" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Membership" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "role" } }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "User" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "first_name" } },
          { kind: "Field", name: { kind: "Name", value: "last_name" } },
          { kind: "Field", name: { kind: "Name", value: "full_name" } },
          { kind: "Field", name: { kind: "Name", value: "display_name" } },
          { kind: "Field", name: { kind: "Name", value: "is_finalized" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "profile" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "bio" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "image" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "width" },
                      value: { kind: "IntValue", value: "250" }
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "height" },
                      value: { kind: "IntValue", value: "250" }
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "crop" },
                      value: { kind: "EnumValue", value: "faces" }
                    }
                  ]
                },
                { kind: "Field", name: { kind: "Name", value: "location" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "last_activity" }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "notification_digest_interval" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "membership" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "UserMembership" }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CompletionFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Completion" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "checked_date" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "completion_attempts" }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "completion_comment" }
          },
          { kind: "Field", name: { kind: "Name", value: "completion_date" } },
          { kind: "Field", name: { kind: "Name", value: "completion_due" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "last_completion_attempt_date" }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "has_failed_passing_attempt" }
          },
          { kind: "Field", name: { kind: "Name", value: "has_points" } },
          { kind: "Field", name: { kind: "Name", value: "is_late" } },
          { kind: "Field", name: { kind: "Name", value: "is_graded" } },
          { kind: "Field", name: { kind: "Name", value: "is_skipped" } },
          { kind: "Field", name: { kind: "Name", value: "is_complete" } },
          { kind: "Field", name: { kind: "Name", value: "is_auto_graded" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "is_forced_completion" }
          },
          { kind: "Field", name: { kind: "Name", value: "needs_grading" } },
          { kind: "Field", name: { kind: "Name", value: "userpoints_earned" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "userpoints_explanation" }
          },
          { kind: "Field", name: { kind: "Name", value: "userpoints_value" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "minimum_passing_score" }
          },
          { kind: "Field", name: { kind: "Name", value: "grading_type" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "requires_api_completion" }
          },
          { kind: "Field", name: { kind: "Name", value: "overridden_by_api" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "overridden_by" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "User" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "checked_by" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "User" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "User" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "submission" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "text" } },
                { kind: "Field", name: { kind: "Name", value: "file_url" } },
                { kind: "Field", name: { kind: "Name", value: "file_name" } },
                { kind: "Field", name: { kind: "Name", value: "file_image" } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode

/**
 * __usePathStepCompletionQuery__
 *
 * To run a query within a React component, call `usePathStepCompletionQuery` and pass it any options that fit your needs.
 * When your component renders, `usePathStepCompletionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePathStepCompletionQuery({
 *   variables: {
 *      sourceId: // value for 'sourceId'
 *      userId: // value for 'userId'
 *      pathId: // value for 'pathId'
 *   },
 * });
 */
export function usePathStepCompletionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PathStepCompletionQuery,
    PathStepCompletionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    PathStepCompletionQuery,
    PathStepCompletionQueryVariables
  >(PathStepCompletionDocument, options)
}
export function usePathStepCompletionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PathStepCompletionQuery,
    PathStepCompletionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    PathStepCompletionQuery,
    PathStepCompletionQueryVariables
  >(PathStepCompletionDocument, options)
}
export function usePathStepCompletionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PathStepCompletionQuery,
    PathStepCompletionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    PathStepCompletionQuery,
    PathStepCompletionQueryVariables
  >(PathStepCompletionDocument, options)
}
export type PathStepCompletionQueryHookResult = ReturnType<
  typeof usePathStepCompletionQuery
>
export type PathStepCompletionLazyQueryHookResult = ReturnType<
  typeof usePathStepCompletionLazyQuery
>
export type PathStepCompletionSuspenseQueryHookResult = ReturnType<
  typeof usePathStepCompletionSuspenseQuery
>
export type PathStepCompletionQueryResult = Apollo.QueryResult<
  PathStepCompletionQuery,
  PathStepCompletionQueryVariables
>
export const StoreAuthorsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "StoreAuthors" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "first" }
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } }
          }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "itemsFirst2" }
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } }
          }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "storeCollections" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "items" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "first" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "itemsFirst2" }
                      }
                    }
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "edges" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "node" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "authors" },
                                    arguments: [
                                      {
                                        kind: "Argument",
                                        name: { kind: "Name", value: "first" },
                                        value: {
                                          kind: "Variable",
                                          name: { kind: "Name", value: "first" }
                                        }
                                      }
                                    ],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "edges"
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "node"
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id"
                                                      }
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "full_name"
                                                      }
                                                    }
                                                  ]
                                                }
                                              }
                                            ]
                                          }
                                        }
                                      ]
                                    }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode

/**
 * __useStoreAuthorsQuery__
 *
 * To run a query within a React component, call `useStoreAuthorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreAuthorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreAuthorsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      itemsFirst2: // value for 'itemsFirst2'
 *   },
 * });
 */
export function useStoreAuthorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    StoreAuthorsQuery,
    StoreAuthorsQueryVariables
  > &
    (
      | { variables: StoreAuthorsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<StoreAuthorsQuery, StoreAuthorsQueryVariables>(
    StoreAuthorsDocument,
    options
  )
}
export function useStoreAuthorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StoreAuthorsQuery,
    StoreAuthorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<StoreAuthorsQuery, StoreAuthorsQueryVariables>(
    StoreAuthorsDocument,
    options
  )
}
export function useStoreAuthorsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    StoreAuthorsQuery,
    StoreAuthorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<StoreAuthorsQuery, StoreAuthorsQueryVariables>(
    StoreAuthorsDocument,
    options
  )
}
export type StoreAuthorsQueryHookResult = ReturnType<
  typeof useStoreAuthorsQuery
>
export type StoreAuthorsLazyQueryHookResult = ReturnType<
  typeof useStoreAuthorsLazyQuery
>
export type StoreAuthorsSuspenseQueryHookResult = ReturnType<
  typeof useStoreAuthorsSuspenseQuery
>
export type StoreAuthorsQueryResult = Apollo.QueryResult<
  StoreAuthorsQuery,
  StoreAuthorsQueryVariables
>
export const StoreQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "StoreQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "first" }
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } }
          }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "categoryId" }
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "authorId" }
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "storeBanners" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "StoreBannerView" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "storeCollections" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "meta" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "NodeMeta" }
                      }
                    ]
                  }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "items" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "first" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "first" }
                      }
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "category_id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "categoryId" }
                      }
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "author_id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "authorId" }
                      }
                    }
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "edges" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "node" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "StoreItemListView"
                                    }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NodeMeta" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "NodeMeta" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "key" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "url" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "subtitle" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "src" } }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "StoreBannerView" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "StoreBanner" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "button_text" } },
          { kind: "Field", name: { kind: "Name", value: "button_url" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "meta" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "NodeMeta" }
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "banner" },
                  name: { kind: "Name", value: "image" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "ar" },
                      value: {
                        kind: "StringValue",
                        value: "16:9",
                        block: false
                      }
                    }
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "src" } },
                      { kind: "Field", name: { kind: "Name", value: "width" } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "StoreItemListView" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "StoreItem" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "meta" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "NodeMeta" }
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "cover" },
                  name: { kind: "Name", value: "image" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "ar" },
                      value: { kind: "StringValue", value: "4:3", block: false }
                    }
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "src" } },
                      { kind: "Field", name: { kind: "Name", value: "width" } }
                    ]
                  }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "authors" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "IntValue", value: "100" }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "edges" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "node" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "meta" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "FragmentSpread",
                                    name: { kind: "Name", value: "NodeMeta" }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode

/**
 * __useStoreQueryQuery__
 *
 * To run a query within a React component, call `useStoreQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreQueryQuery({
 *   variables: {
 *      first: // value for 'first'
 *      categoryId: // value for 'categoryId'
 *      authorId: // value for 'authorId'
 *   },
 * });
 */
export function useStoreQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    StoreQueryQuery,
    StoreQueryQueryVariables
  > &
    (
      | { variables: StoreQueryQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<StoreQueryQuery, StoreQueryQueryVariables>(
    StoreQueryDocument,
    options
  )
}
export function useStoreQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StoreQueryQuery,
    StoreQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<StoreQueryQuery, StoreQueryQueryVariables>(
    StoreQueryDocument,
    options
  )
}
export function useStoreQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    StoreQueryQuery,
    StoreQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<StoreQueryQuery, StoreQueryQueryVariables>(
    StoreQueryDocument,
    options
  )
}
export type StoreQueryQueryHookResult = ReturnType<typeof useStoreQueryQuery>
export type StoreQueryLazyQueryHookResult = ReturnType<
  typeof useStoreQueryLazyQuery
>
export type StoreQuerySuspenseQueryHookResult = ReturnType<
  typeof useStoreQuerySuspenseQuery
>
export type StoreQueryQueryResult = Apollo.QueryResult<
  StoreQueryQuery,
  StoreQueryQueryVariables
>
export const StoreAuthorDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "StoreAuthor" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "storeCollectionId" }
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } }
          }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "first" }
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } }
          }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "categoryId" }
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "authorId" }
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "storeCollection" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "storeCollectionId" }
                }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "meta" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "NodeMeta" }
                      }
                    ]
                  }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "items" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "first" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "first" }
                      }
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "category_id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "categoryId" }
                      }
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "author_id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "authorId" }
                      }
                    }
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "edges" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "node" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "StoreItemListView"
                                    }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NodeMeta" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "NodeMeta" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "key" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "url" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "subtitle" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "src" } }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "StoreItemListView" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "StoreItem" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "meta" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "NodeMeta" }
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "cover" },
                  name: { kind: "Name", value: "image" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "ar" },
                      value: { kind: "StringValue", value: "4:3", block: false }
                    }
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "src" } },
                      { kind: "Field", name: { kind: "Name", value: "width" } }
                    ]
                  }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "authors" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "IntValue", value: "100" }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "edges" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "node" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "meta" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "FragmentSpread",
                                    name: { kind: "Name", value: "NodeMeta" }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode

/**
 * __useStoreAuthorQuery__
 *
 * To run a query within a React component, call `useStoreAuthorQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreAuthorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreAuthorQuery({
 *   variables: {
 *      storeCollectionId: // value for 'storeCollectionId'
 *      first: // value for 'first'
 *      categoryId: // value for 'categoryId'
 *      authorId: // value for 'authorId'
 *   },
 * });
 */
export function useStoreAuthorQuery(
  baseOptions: Apollo.QueryHookOptions<
    StoreAuthorQuery,
    StoreAuthorQueryVariables
  > &
    (
      | { variables: StoreAuthorQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<StoreAuthorQuery, StoreAuthorQueryVariables>(
    StoreAuthorDocument,
    options
  )
}
export function useStoreAuthorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StoreAuthorQuery,
    StoreAuthorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<StoreAuthorQuery, StoreAuthorQueryVariables>(
    StoreAuthorDocument,
    options
  )
}
export function useStoreAuthorSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    StoreAuthorQuery,
    StoreAuthorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<StoreAuthorQuery, StoreAuthorQueryVariables>(
    StoreAuthorDocument,
    options
  )
}
export type StoreAuthorQueryHookResult = ReturnType<typeof useStoreAuthorQuery>
export type StoreAuthorLazyQueryHookResult = ReturnType<
  typeof useStoreAuthorLazyQuery
>
export type StoreAuthorSuspenseQueryHookResult = ReturnType<
  typeof useStoreAuthorSuspenseQuery
>
export type StoreAuthorQueryResult = Apollo.QueryResult<
  StoreAuthorQuery,
  StoreAuthorQueryVariables
>
export const StoreCollectionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "StoreCollection" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "storeCollectionId" }
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } }
          }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "first" }
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } }
          }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "categoryId" }
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "authorId" }
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "storeCollection" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "storeCollectionId" }
                }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "meta" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "NodeMeta" }
                      }
                    ]
                  }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "items" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "first" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "first" }
                      }
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "category_id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "categoryId" }
                      }
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "author_id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "authorId" }
                      }
                    }
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "edges" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "node" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "StoreItemListView"
                                    }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NodeMeta" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "NodeMeta" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "key" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "url" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "subtitle" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "src" } }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "StoreItemListView" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "StoreItem" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "meta" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "NodeMeta" }
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "cover" },
                  name: { kind: "Name", value: "image" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "ar" },
                      value: { kind: "StringValue", value: "4:3", block: false }
                    }
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "src" } },
                      { kind: "Field", name: { kind: "Name", value: "width" } }
                    ]
                  }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "authors" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "IntValue", value: "100" }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "edges" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "node" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "meta" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "FragmentSpread",
                                    name: { kind: "Name", value: "NodeMeta" }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode

/**
 * __useStoreCollectionQuery__
 *
 * To run a query within a React component, call `useStoreCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreCollectionQuery({
 *   variables: {
 *      storeCollectionId: // value for 'storeCollectionId'
 *      first: // value for 'first'
 *      categoryId: // value for 'categoryId'
 *      authorId: // value for 'authorId'
 *   },
 * });
 */
export function useStoreCollectionQuery(
  baseOptions: Apollo.QueryHookOptions<
    StoreCollectionQuery,
    StoreCollectionQueryVariables
  > &
    (
      | { variables: StoreCollectionQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<StoreCollectionQuery, StoreCollectionQueryVariables>(
    StoreCollectionDocument,
    options
  )
}
export function useStoreCollectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StoreCollectionQuery,
    StoreCollectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    StoreCollectionQuery,
    StoreCollectionQueryVariables
  >(StoreCollectionDocument, options)
}
export function useStoreCollectionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    StoreCollectionQuery,
    StoreCollectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    StoreCollectionQuery,
    StoreCollectionQueryVariables
  >(StoreCollectionDocument, options)
}
export type StoreCollectionQueryHookResult = ReturnType<
  typeof useStoreCollectionQuery
>
export type StoreCollectionLazyQueryHookResult = ReturnType<
  typeof useStoreCollectionLazyQuery
>
export type StoreCollectionSuspenseQueryHookResult = ReturnType<
  typeof useStoreCollectionSuspenseQuery
>
export type StoreCollectionQueryResult = Apollo.QueryResult<
  StoreCollectionQuery,
  StoreCollectionQueryVariables
>
export const StoreSearchQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "storeSearchQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "first" }
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } }
          }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "search" }
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "categorySearch" }
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "authorSearch" }
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "storeCollections" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "search" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "search" }
                }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "category_search" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "categorySearch" }
                }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "author_search" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "authorSearch" }
                }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "meta" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "NodeMeta" }
                      }
                    ]
                  }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "items" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "first" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "first" }
                      }
                    }
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "edges" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "node" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "meta" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "FragmentSpread",
                                          name: {
                                            kind: "Name",
                                            value: "NodeMeta"
                                          }
                                        }
                                      ]
                                    }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "authors" },
                                    arguments: [
                                      {
                                        kind: "Argument",
                                        name: { kind: "Name", value: "first" },
                                        value: { kind: "IntValue", value: "5" }
                                      }
                                    ],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "edges"
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "node"
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id"
                                                      }
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "first_name"
                                                      }
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "full_name"
                                                      }
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "display_name"
                                                      }
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "image"
                                                      }
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "last_name"
                                                      }
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "order"
                                                      }
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "salutation"
                                                      }
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "bio"
                                                      }
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "slug"
                                                      }
                                                    }
                                                  ]
                                                }
                                              }
                                            ]
                                          }
                                        }
                                      ]
                                    }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NodeMeta" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "NodeMeta" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "key" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "url" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "subtitle" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "src" } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode

/**
 * __useStoreSearchQueryQuery__
 *
 * To run a query within a React component, call `useStoreSearchQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreSearchQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreSearchQueryQuery({
 *   variables: {
 *      first: // value for 'first'
 *      search: // value for 'search'
 *      categorySearch: // value for 'categorySearch'
 *      authorSearch: // value for 'authorSearch'
 *   },
 * });
 */
export function useStoreSearchQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    StoreSearchQueryQuery,
    StoreSearchQueryQueryVariables
  > &
    (
      | { variables: StoreSearchQueryQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<StoreSearchQueryQuery, StoreSearchQueryQueryVariables>(
    StoreSearchQueryDocument,
    options
  )
}
export function useStoreSearchQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StoreSearchQueryQuery,
    StoreSearchQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    StoreSearchQueryQuery,
    StoreSearchQueryQueryVariables
  >(StoreSearchQueryDocument, options)
}
export function useStoreSearchQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    StoreSearchQueryQuery,
    StoreSearchQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    StoreSearchQueryQuery,
    StoreSearchQueryQueryVariables
  >(StoreSearchQueryDocument, options)
}
export type StoreSearchQueryQueryHookResult = ReturnType<
  typeof useStoreSearchQueryQuery
>
export type StoreSearchQueryLazyQueryHookResult = ReturnType<
  typeof useStoreSearchQueryLazyQuery
>
export type StoreSearchQuerySuspenseQueryHookResult = ReturnType<
  typeof useStoreSearchQuerySuspenseQuery
>
export type StoreSearchQueryQueryResult = Apollo.QueryResult<
  StoreSearchQueryQuery,
  StoreSearchQueryQueryVariables
>
export const PathPreviewDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "PathPreview" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } }
          }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "path" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "PathView" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pathItems" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "IntValue", value: "500" }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "path_id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "edges" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "node" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "PathItemListView" }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserMembership" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Membership" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "role" } }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "User" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "first_name" } },
          { kind: "Field", name: { kind: "Name", value: "last_name" } },
          { kind: "Field", name: { kind: "Name", value: "full_name" } },
          { kind: "Field", name: { kind: "Name", value: "display_name" } },
          { kind: "Field", name: { kind: "Name", value: "is_finalized" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "profile" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "bio" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "image" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "width" },
                      value: { kind: "IntValue", value: "250" }
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "height" },
                      value: { kind: "IntValue", value: "250" }
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "crop" },
                      value: { kind: "EnumValue", value: "faces" }
                    }
                  ]
                },
                { kind: "Field", name: { kind: "Name", value: "location" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "last_activity" }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "notification_digest_interval" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "membership" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "UserMembership" }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PathView" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Path" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "User" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "meta" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "NodeMeta" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "theme" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "NodeTheme" }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PathItemListView" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "PathItem" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "source_id" } },
          { kind: "Field", name: { kind: "Name", value: "order" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "due" } },
          { kind: "Field", name: { kind: "Name", value: "passive_due" } },
          { kind: "Field", name: { kind: "Name", value: "starts" } },
          { kind: "Field", name: { kind: "Name", value: "passive_starts" } },
          { kind: "Field", name: { kind: "Name", value: "has_started" } },
          { kind: "Field", name: { kind: "Name", value: "parent_id" } },
          { kind: "Field", name: { kind: "Name", value: "parent_source_id" } },
          { kind: "Field", name: { kind: "Name", value: "assignment_type" } },
          { kind: "Field", name: { kind: "Name", value: "verb" } },
          { kind: "Field", name: { kind: "Name", value: "content_type" } },
          { kind: "Field", name: { kind: "Name", value: "content_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "item_content_type_id" }
          },
          { kind: "Field", name: { kind: "Name", value: "points" } },
          { kind: "Field", name: { kind: "Name", value: "progress" } },
          { kind: "Field", name: { kind: "Name", value: "path_id" } },
          { kind: "Field", name: { kind: "Name", value: "time_to_complete" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "meta" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "NodeMeta" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "completion" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CompletionFragment" }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NodeMeta" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "NodeMeta" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "key" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "url" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "subtitle" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "src" } }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NodeThemeBackground" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "NodeThemeBackground" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "src" } }
              ]
            }
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "image_blurred" },
            name: { kind: "Name", value: "image" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "blur" },
                value: { kind: "IntValue", value: "320" }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "src" } }
              ]
            }
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "image_blurred_dark" },
            name: { kind: "Name", value: "image" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "blur" },
                value: { kind: "IntValue", value: "320" }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "brightness" },
                value: { kind: "IntValue", value: "-20" }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "src" } }
              ]
            }
          },
          { kind: "Field", name: { kind: "Name", value: "color" } },
          { kind: "Field", name: { kind: "Name", value: "contrast_color" } }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NodeTheme" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "NodeTheme" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "color" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "color_palette" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_50" } },
                { kind: "Field", name: { kind: "Name", value: "_100" } },
                { kind: "Field", name: { kind: "Name", value: "_200" } },
                { kind: "Field", name: { kind: "Name", value: "_300" } },
                { kind: "Field", name: { kind: "Name", value: "_400" } },
                { kind: "Field", name: { kind: "Name", value: "_500" } },
                { kind: "Field", name: { kind: "Name", value: "_600" } },
                { kind: "Field", name: { kind: "Name", value: "_700" } },
                { kind: "Field", name: { kind: "Name", value: "_800" } },
                { kind: "Field", name: { kind: "Name", value: "_900" } }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "background" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "NodeThemeBackground" }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CompletionFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Completion" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "checked_date" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "completion_attempts" }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "completion_comment" }
          },
          { kind: "Field", name: { kind: "Name", value: "completion_date" } },
          { kind: "Field", name: { kind: "Name", value: "completion_due" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "last_completion_attempt_date" }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "has_failed_passing_attempt" }
          },
          { kind: "Field", name: { kind: "Name", value: "has_points" } },
          { kind: "Field", name: { kind: "Name", value: "is_late" } },
          { kind: "Field", name: { kind: "Name", value: "is_graded" } },
          { kind: "Field", name: { kind: "Name", value: "is_skipped" } },
          { kind: "Field", name: { kind: "Name", value: "is_complete" } },
          { kind: "Field", name: { kind: "Name", value: "is_auto_graded" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "is_forced_completion" }
          },
          { kind: "Field", name: { kind: "Name", value: "needs_grading" } },
          { kind: "Field", name: { kind: "Name", value: "userpoints_earned" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "userpoints_explanation" }
          },
          { kind: "Field", name: { kind: "Name", value: "userpoints_value" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "minimum_passing_score" }
          },
          { kind: "Field", name: { kind: "Name", value: "grading_type" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "requires_api_completion" }
          },
          { kind: "Field", name: { kind: "Name", value: "overridden_by_api" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "overridden_by" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "User" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "checked_by" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "User" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "User" }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "submission" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "text" } },
                { kind: "Field", name: { kind: "Name", value: "file_url" } },
                { kind: "Field", name: { kind: "Name", value: "file_name" } },
                { kind: "Field", name: { kind: "Name", value: "file_image" } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode

/**
 * __usePathPreviewQuery__
 *
 * To run a query within a React component, call `usePathPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `usePathPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePathPreviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePathPreviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    PathPreviewQuery,
    PathPreviewQueryVariables
  > &
    (
      | { variables: PathPreviewQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PathPreviewQuery, PathPreviewQueryVariables>(
    PathPreviewDocument,
    options
  )
}
export function usePathPreviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PathPreviewQuery,
    PathPreviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PathPreviewQuery, PathPreviewQueryVariables>(
    PathPreviewDocument,
    options
  )
}
export function usePathPreviewSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PathPreviewQuery,
    PathPreviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<PathPreviewQuery, PathPreviewQueryVariables>(
    PathPreviewDocument,
    options
  )
}
export type PathPreviewQueryHookResult = ReturnType<typeof usePathPreviewQuery>
export type PathPreviewLazyQueryHookResult = ReturnType<
  typeof usePathPreviewLazyQuery
>
export type PathPreviewSuspenseQueryHookResult = ReturnType<
  typeof usePathPreviewSuspenseQuery
>
export type PathPreviewQueryResult = Apollo.QueryResult<
  PathPreviewQuery,
  PathPreviewQueryVariables
>
export const RelatedItemsQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "RelatedItemsQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "storeCollectionId" }
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } }
          }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "first" }
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } }
          }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "storeCollection" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "storeCollectionId" }
                }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "meta" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "NodeMeta" }
                      }
                    ]
                  }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "items" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "first" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "first" }
                      }
                    }
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "edges" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "node" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "StoreItemListView"
                                    }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NodeMeta" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "NodeMeta" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "key" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "url" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "subtitle" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "src" } }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "StoreItemListView" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "StoreItem" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "meta" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "NodeMeta" }
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "cover" },
                  name: { kind: "Name", value: "image" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "ar" },
                      value: { kind: "StringValue", value: "4:3", block: false }
                    }
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "src" } },
                      { kind: "Field", name: { kind: "Name", value: "width" } }
                    ]
                  }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "authors" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "IntValue", value: "100" }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "edges" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "node" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "meta" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "FragmentSpread",
                                    name: { kind: "Name", value: "NodeMeta" }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode

/**
 * __useRelatedItemsQueryQuery__
 *
 * To run a query within a React component, call `useRelatedItemsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRelatedItemsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRelatedItemsQueryQuery({
 *   variables: {
 *      storeCollectionId: // value for 'storeCollectionId'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useRelatedItemsQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    RelatedItemsQueryQuery,
    RelatedItemsQueryQueryVariables
  > &
    (
      | { variables: RelatedItemsQueryQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    RelatedItemsQueryQuery,
    RelatedItemsQueryQueryVariables
  >(RelatedItemsQueryDocument, options)
}
export function useRelatedItemsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RelatedItemsQueryQuery,
    RelatedItemsQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    RelatedItemsQueryQuery,
    RelatedItemsQueryQueryVariables
  >(RelatedItemsQueryDocument, options)
}
export function useRelatedItemsQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    RelatedItemsQueryQuery,
    RelatedItemsQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    RelatedItemsQueryQuery,
    RelatedItemsQueryQueryVariables
  >(RelatedItemsQueryDocument, options)
}
export type RelatedItemsQueryQueryHookResult = ReturnType<
  typeof useRelatedItemsQueryQuery
>
export type RelatedItemsQueryLazyQueryHookResult = ReturnType<
  typeof useRelatedItemsQueryLazyQuery
>
export type RelatedItemsQuerySuspenseQueryHookResult = ReturnType<
  typeof useRelatedItemsQuerySuspenseQuery
>
export type RelatedItemsQueryQueryResult = Apollo.QueryResult<
  RelatedItemsQueryQuery,
  RelatedItemsQueryQueryVariables
>
export const StoreItemDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "StoreItem" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "storeItemId" }
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } }
          }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "storeItem" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "storeItemId" }
                }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "StoreItemView" }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NodeMeta" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "NodeMeta" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "key" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "url" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "subtitle" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "src" } }
              ]
            }
          }
        ]
      }
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "StoreItemView" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "StoreItem" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "meta" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "NodeMeta" }
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "cover" },
                  name: { kind: "Name", value: "image" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "ar" },
                      value: {
                        kind: "StringValue",
                        value: "16:9",
                        block: false
                      }
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "size" },
                      value: { kind: "EnumValue", value: "xl" }
                    }
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "src" } },
                      { kind: "Field", name: { kind: "Name", value: "width" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "color" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "mode" },
                            value: { kind: "EnumValue", value: "dark" }
                          },
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "tone" },
                            value: { kind: "EnumValue", value: "muted" }
                          }
                        ]
                      }
                    ]
                  }
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "cover_default" },
                  name: { kind: "Name", value: "image" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "ar" },
                      value: { kind: "StringValue", value: "4:3", block: false }
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "size" },
                      value: { kind: "EnumValue", value: "lg" }
                    }
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "src" } },
                      { kind: "Field", name: { kind: "Name", value: "width" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "color" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "mode" },
                            value: { kind: "EnumValue", value: "dark" }
                          },
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "tone" },
                            value: { kind: "EnumValue", value: "muted" }
                          }
                        ]
                      }
                    ]
                  }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "theme" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "background" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "image" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "src" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "width" }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          },
          { kind: "Field", name: { kind: "Name", value: "category_ids" } },
          { kind: "Field", name: { kind: "Name", value: "short_description" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "options" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "price" } },
                { kind: "Field", name: { kind: "Name", value: "path_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "meta" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "NodeMeta" }
                      }
                    ]
                  }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "authors" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "IntValue", value: "10" }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "edges" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "node" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "meta" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "FragmentSpread",
                                    name: { kind: "Name", value: "NodeMeta" }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode

/**
 * __useStoreItemQuery__
 *
 * To run a query within a React component, call `useStoreItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreItemQuery({
 *   variables: {
 *      storeItemId: // value for 'storeItemId'
 *   },
 * });
 */
export function useStoreItemQuery(
  baseOptions: Apollo.QueryHookOptions<
    StoreItemQuery,
    StoreItemQueryVariables
  > &
    ({ variables: StoreItemQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<StoreItemQuery, StoreItemQueryVariables>(
    StoreItemDocument,
    options
  )
}
export function useStoreItemLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StoreItemQuery,
    StoreItemQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<StoreItemQuery, StoreItemQueryVariables>(
    StoreItemDocument,
    options
  )
}
export function useStoreItemSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    StoreItemQuery,
    StoreItemQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<StoreItemQuery, StoreItemQueryVariables>(
    StoreItemDocument,
    options
  )
}
export type StoreItemQueryHookResult = ReturnType<typeof useStoreItemQuery>
export type StoreItemLazyQueryHookResult = ReturnType<
  typeof useStoreItemLazyQuery
>
export type StoreItemSuspenseQueryHookResult = ReturnType<
  typeof useStoreItemSuspenseQuery
>
export type StoreItemQueryResult = Apollo.QueryResult<
  StoreItemQuery,
  StoreItemQueryVariables
>
