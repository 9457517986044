import {
  Button,
  ButtonProps,
  Link as ChakraLink,
  LinkProps as ChakraLinkProps
} from "@chakra-ui/react"
import { MouseEventHandler } from "react"
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  useInRouterContext
} from "react-router-dom"

export type LinkProps = ButtonProps & ChakraLinkProps & RouterLinkProps

// TODO: figure out how Link should work in:
// 1) Router context
// 2) Router context, but wrapped with some handler that intercepts navigation and rather performs some state change (for Container components)
// 3) When not in router context at all (when View is used in some non-web client).
const Link = (props: LinkProps) => {
  const inRouterContext = useInRouterContext()
  // const [searchParams, setSearchParams] = useSearchParams()
  const Link = inRouterContext ? RouterLink : ChakraLink

  const isModal = props.state && props.state.modal === true

  const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (isModal) {
      // NOTE: modal state prop is not in use ATM. The current implementation
      // breaks <Link /> components used outside of a route context. One example of
      // that is when testing a component in isolation. Probably just need to manually
      // configure the `to` prop with the "m" query param.
      console.log("TODO: handle modal click")
      // e.preventDefault()
      // setSearchParams({ m: props.to as string })
    }
  }

  const handleMouseDown: MouseEventHandler<HTMLButtonElement> = (e) =>
    e.preventDefault() // avoid validating any form elements via onBlur handlers, see: https://github.com/redux-form/redux-form/issues/860#issuecomment-625254444

  return (
    <Button
      as={Link}
      variant="link"
      // onClick={handleClick}
      onMouseDown={handleMouseDown}
      {...props}
    />
  )
}

export default Link
