import defaults from "lodash/defaults"
import { hasAuthenticationToken } from "../../../lib/utils/auth-token"
import {
  AUTH_INTENT_EMAIL_PARAM_KEY,
  AUTH_INTENT_ERROR_PARAM_KEY,
  AUTH_INTENT_PARAM_KEY
} from "./authIntent"

type RouteSearchParamsTuple = [string, URLSearchParams]

// Util for parsing ourt the base route and the search params
// from a url or pathname.
export const parseSearchParams = (route: string): RouteSearchParamsTuple => {
  // Capture all characters that are not "?" up until the first "?",
  // then capture all characters coming after the first "?".
  const re = /([^?]*)\?(.+)/
  const routeSearchMatch = route.match(re)

  if (routeSearchMatch) {
    const route = routeSearchMatch[1]
    const search = routeSearchMatch[2]
    return [route, new URLSearchParams(search)]
  }

  return [route, new URLSearchParams()]
}

export const mergeSearchParams = (...args: RouteSearchParamsTuple): string => {
  const [route, searchParams] = args
  // Decoding to remove any unsightly encodings in the url.
  const search = decodeURIComponent(searchParams.toString())
  return search ? `${route}?${search}` : route
}

// Convenient method for getting search param key.
export const getSearchParam = (route: string, key: string): string | null => {
  const [_, urlSearchParams] = parseSearchParams(route)
  return urlSearchParams.get(key)
}

// Convenient method for removing a search param key.
export const removeSearchParam = (route: string, key: string): string => {
  const [baseRoute, urlSearchParams] = parseSearchParams(route)
  urlSearchParams.delete(key)
  return `${baseRoute}?${urlSearchParams}`
}

// Get the relative route.
const getReleativeRoute = (route: string) => {
  try {
    const url = new URL(route)
    return url.pathname + url.search
  } catch {
    return route
  }
}

type AuthRouteOptions = {
  url?: string
  shouldUseNext?: boolean
}

// Expecting either a literal URLSearchParams or a URL in
// the form of a string, from which the URLSearchParams can be parsed.
export function removeNonTransferrableAuthSearchParams(
  searchParams: URLSearchParams
) {
  // Delete all other params that should not be carried over.
  searchParams.delete(AUTH_INTENT_PARAM_KEY)
  searchParams.delete(AUTH_INTENT_ERROR_PARAM_KEY)
  searchParams.delete(AUTH_INTENT_EMAIL_PARAM_KEY)
}

export function removeNonTransferrableAuthSearchParamsFromUrl(url: string) {
  const [route, searchParams] = parseSearchParams(url)
  removeNonTransferrableAuthSearchParams(searchParams)
  return mergeSearchParams(route, searchParams)
}

type AuthRouteDefaultOptions = Required<AuthRouteOptions>

// Ensuring the `AuthRouteOptions` values are defaulted.
function getAuthRouteOptions(
  options?: AuthRouteOptions
): AuthRouteDefaultOptions {
  const defaultOptions: AuthRouteDefaultOptions = {
    url: window.location.href,
    shouldUseNext: hasAuthenticationToken()
  }
  return defaults<AuthRouteOptions, AuthRouteDefaultOptions>(
    options || {},
    defaultOptions
  )
}

// Depending on if the user is authenticated, we navigate either
// to the ?next url or to the next route, presercing all url search params.
function getAuthRoute(route: string, options?: AuthRouteOptions): string {
  const { url, shouldUseNext } = getAuthRouteOptions(options)
  const [_, urlSearchParams] = parseSearchParams(url)
  const nextParam = urlSearchParams.get("next")

  if (nextParam && shouldUseNext) {
    route = getReleativeRoute(nextParam)
  } else {
    const [baseRoute, routeSearchParams] = parseSearchParams(route)
    // If we found search params on the route, let's merged
    // them with the search params in the current url.
    if (routeSearchParams) {
      const searchParams = new URLSearchParams({
        ...Object.fromEntries(urlSearchParams),
        ...Object.fromEntries(routeSearchParams)
      })
      // Always ensure the ?next query param is the last query param,
      // though, at this point, it likely is.
      const nextParam = searchParams.get("next")
      if (nextParam) {
        searchParams.delete("next")
        searchParams.set("next", nextParam)
      }
      // Delete all other params that should not be carried over.
      removeNonTransferrableAuthSearchParams(searchParams)
      route = mergeSearchParams(baseRoute, searchParams)
    }
  }

  return route
}

export default getAuthRoute
