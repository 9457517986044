import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import Card from "@pathwright/ui/src/components/card/Card"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import { ConnectStores } from "lib/core/store"
import AuthorList from "./AuthorList"

const AuthorListCard = ({
  card,
  baseURL,
  authors,
  authors_loading,
  updateAuthorOrder
}) => (
  <Card
    title="Authors"
    completeAction={{
      to: `${baseURL}new/`,
      children: "New Author"
    }}
    card={card}
  >
    {authors_loading ? (
      <LoadingCircle />
    ) : (authors != null ? authors.length : undefined) ? (
      <AuthorList
        baseURL={baseURL}
        authors={authors}
        updateAuthorOrder={updateAuthorOrder}
      />
    ) : (
      <BlankSlate
        heading="Your library doesn't have any authors yet."
        body="Create author profiles to share more about yourself or another course author who you'd like to highlight on your Course About pages"
      />
    )}
  </Card>
)

AuthorListCard.displayName = "AuthorListCard"

const mapStateToProps = function (state, ownProps) {
  const { authors, authors_loading } = state.authorAdminStore.state
  const { updateAuthorOrder } = state.authorAdminStore.action
  const { url: baseURL } = state.navigationStore.state

  return _.assign(
    {},
    {
      baseURL,
      authors,
      authors_loading,
      updateAuthorOrder
    },
    ownProps
  )
}

const connectStores = [
  { store: "navigation", watch: ["url"] },
  { store: "authorAdmin", watch: ["authors", "authors_loading"] }
]

export default ConnectStores(AuthorListCard, connectStores, {}, mapStateToProps)
