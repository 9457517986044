// Globals (yay)
require("./vendor-globals")

// CSS
require("@pathwright/web/src/modules/theme/chakra-reset.css")
require("./vendor/styles/bootstrap-tooltip.css") // TODO: delete once notes/discussion replaced
require("./vendor/styles/bootstrap-dropdown.css") // TODO: delete once notes/discussion replaced
require("global/views/styles/bootstrap-overrides.css") // TODO: delete once notes/discussion replaced
require("global/views/styles/typography.css")
require("global/views/styles/core.css")
require("global/views/styles/app.css")

// Scripts
// Note: files in vendor/scripts are loaded via script-loader and not minified!
require("./vendor/scripts/backbone-min.js")
require("./vendor/scripts/parsley.js")
require("./vendor/scripts/bootstrap.js")
require("./vendor/scripts/jquery.waitforimages.min.js")
require("./vendor/scripts/jquery.tipsy.js")
require("./vendor/scripts/jquery.autoresize.js")

window.$.Velocity = require("velocity-animate")
window.$.Velocity.Promise = Promise
window.$.parsley = window.Parsley
