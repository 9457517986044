import { Button, InputGroup, InputRightElement } from "@chakra-ui/react"
import { useFormikContext } from "formik"
import { useState } from "react"
import { InputField, InputFieldProps } from "../../../../components/form/Field"

const defaultPasswordFieldName = "password"

export type PasswordInputProps = Partial<InputFieldProps>

const PasswordInput = (props: PasswordInputProps) => {
  const [showPasswordValue, setShowPasswordValue] = useState(false)
  const handleClick = () => setShowPasswordValue((show) => !show)
  const formik = useFormikContext()
  // @ts-ignore: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'. No index signature with a parameter of type 'string' was found on type '{}'.
  const passwordValue = formik.values?.[props.name || defaultPasswordFieldName]
  // Smartly show the toggle button. Basically, only show when there is user input
  // or the user has toggled the button on.
  const showToggleButton = !!passwordValue || showPasswordValue

  return (
    <InputGroup size="md">
      <InputField
        id={defaultPasswordFieldName}
        name={defaultPasswordFieldName}
        type={showPasswordValue ? "text" : "password"}
        placeholder="Enter a password (6+ characters)..."
        pr="4.5rem"
        {...props}
      />
      {showToggleButton && (
        <InputRightElement width="4.5rem">
          <Button h="1.75rem" size="sm" onClick={handleClick}>
            {showPasswordValue ? "Hide" : "Show"}
          </Button>
        </InputRightElement>
      )}
    </InputGroup>
  )
}

export default PasswordInput
