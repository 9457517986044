import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import {
  RESOURCE_TYPE_COLLECTION,
  RESOURCE_TYPE_COURSE,
  RESOURCE_TYPE_LESSON
} from "./constants"

// NOTE: this may exist in the translation-extractor branch, just needing it now
export const getResourceTypeLabel = (resourceType, translator) => {
  switch (resourceType) {
    case RESOURCE_TYPE_COURSE:
      return translator(`resource.types.course`)
    case RESOURCE_TYPE_LESSON:
      return translator(`resource.types.lesson`)
    case RESOURCE_TYPE_COLLECTION:
      return translator(`resource.types.collection`)
    default:
      return "course"
  }
}

export const useTranslatedResourceTypeLabel = (resource) => {
  const { t } = useTranslate()

  if (!resource) return ""

  switch (resource.resource_type) {
    case RESOURCE_TYPE_COURSE:
      return t(`resource.types.course`)
    case RESOURCE_TYPE_LESSON:
      return t(`resource.types.lesson`)
    case RESOURCE_TYPE_COLLECTION:
      return t(`resource.types.collection`)
    default:
      return "course"
  }
}

export const withTranslatedResourceTypeLabel = (Component) => (props) => {
  const resourceTypeLabel = useTranslatedResourceTypeLabel(props.resource)
  return <Component {...props} resourceTypeLabel={resourceTypeLabel} />
}

export const getRandomResourceCover = () => {
  const n = Math.ceil(Math.random() * 72)
  return `https://pathwright-static-media.s3.amazonaws.com/default-image-set-v1/cover-image-${n}.png`
}
