import gql from "graphql-tag"
import AUTHOR_FRAGMENT from "../../../author/graphql/author-fragment"
import CATEGORY_FRAGMENT from "../../../category/graphql/category-fragment"

export default gql`
  mutation (
    $id: Int!
    $name: String!
    $resource_type: ResourceType!
    $cover_image: String
  ) {
    updateResource(
      id: $id
      name: $name
      resource_type: $resource_type
      cover_image: $cover_image
    ) {
      id
      name
      image
      description
      short_description
      trailer_embed_code
      visibility_level_override
      certificate_generator_class
      discussion_enabled
      authors(first: 10) {
        total
        edges {
          node {
            ...Author
          }
        }
      }
      categories(first: 10) {
        total
        edges {
          node {
            ...Category
          }
        }
      }
    }
  }
  ${CATEGORY_FRAGMENT}
  ${AUTHOR_FRAGMENT}
`
