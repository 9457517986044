import { useQuery as _useQuery } from "@apollo/client"
import { graphql } from "@apollo/client/react/hoc"
import camelCase from "lodash/camelCase"
import get from "lodash/get"
import has from "lodash/has"
import { Component, useState } from "react"

// Retains expected behavior of Apollo Client v2 where data is NOT reset to undefined when loading state changes.
export const useQuery = (q, options = {}) => {
  const query = _useQuery(q, options)
  query.data = query.data ?? query.previousData
  return query
}

// flattenEdges({ foo: { edges: [{ node: { bar: 1 } }] } })
// => { foo: [{ bar: 1 }] }

export function flattenEdges(obj) {
  const isObject = Object.prototype.toString.call(obj) === "[object Object]"
  if (!isObject) return obj
  let result = {}
  for (let key in obj) {
    if (key === "edges") {
      result = (obj[key] || []).map((edge) => flattenEdges(edge.node))
      // this is probably a bad idea
      result.pageInfo = obj.pageInfo
      result.total = obj.total
    } else {
      result[key] = flattenEdges(obj[key])
    }
  }
  return result
}

// util for throwing an error if the expected pageInfo path does not exist in the query data
const _errorOnNonExistyPageInfoPath = (data, path) => {
  if (has(data, path) && !has(data, `${path}.pageInfo`)) {
    throw new Error(
      `Attempted to setup paginator for "${path}" but unable as pageInfo does not exist in the query data. Be sure to add pageInfo to your query.`
    )
  }
}

// resolves data path (related to `useQuery` and `graphql` data shape differences)
const _getDataPath = (data, path) => {
  if (data && data.data) return `data.${path}`
  return path
}

// loadMoreAuthors: createPaginator(data, "resource.authors", "authorCursor")
// => () => promise

// NOTE: in order for the loading prop to be updated when calling `fetchMore`
// you must pass notifyOnNetworkStatusChange: true, in the GQL query options
export function createPaginator(data, path, cursorVar = "cursor") {
  const dataPath = _getDataPath(data, path)
  // ensure we have pageInfo
  _errorOnNonExistyPageInfoPath(data, dataPath)

  const endCursorPath = `${dataPath}.pageInfo.endCursor`

  return function () {
    return data.fetchMore({
      variables: {
        ...data.variables,
        [cursorVar]: get(data, endCursorPath)
      }
    })
  }
}

export const usePaginator = ({
  data,
  path,
  cursorKey = "cursor" // or something like "cohorts_cursor"
}) => {
  const [loading, setLoading] = useState(false)

  const dataPath = _getDataPath(data, path)
  // ensure we have pageInfo
  _errorOnNonExistyPageInfoPath(data, dataPath)

  const hasMore = get(data, `${dataPath}.pageInfo.hasNextPage`)

  const paginator = createPaginator(data, path, cursorKey)

  const loadMore = async () => {
    if (hasMore) {
      setLoading(true)
      await paginator()
      setLoading(false)
    }
  }

  return {
    hasMore,
    loadingMore: loading,
    loadMore
  }
}

// Handles `hasMore`, `loadMore`, and `loadingMore` key
// NOTE: depends on parent Component to pass `data` prop down
export const withPaginator =
  ({ path, cursorKey, loadingKey = "" }) =>
  (ComposedComponent) =>
  (props) => {
    const { loadMore, hasMore, loadingMore } = usePaginator({
      data: props.data,
      path,
      cursorKey
    })

    const hasMorePropName = camelCase(`hasMore ${loadingKey}`)
    const loadMorePropName = camelCase(`loadMore ${loadingKey}`)
    const loadingMorePropName = camelCase(`loadingMore ${loadingKey}`)

    const passProps = {
      [hasMorePropName]: hasMore,
      [loadingMorePropName]: loadingMore,
      [loadMorePropName]: loadMore
    }

    return <ComposedComponent {...props} {...passProps} />
  }

export const withMutationProps =
  (mutationConfig) => (mutation, options) => (C) => {
    let mutateFunc = null

    class WithMutationProps extends Component {
      state = {
        saving: false,
        saved: false,
        error: null,
        result: null
      }

      ComposedComponent = graphql(mutation, {
        ...options,
        props: (props) => {
          mutateFunc = props.mutate
          const passProps = options.props
            ? options.props({
                ...props,
                mutate: this.mutate
              })
            : {}
          return {
            ...passProps
          }
        }
      })(C)

      mutate = async (...args) => {
        this.setState({
          saving: true,
          saved: false,
          error: null
        })
        try {
          const result = await mutateFunc(...args)
          this.setState({
            result: get(result, `data.${mutationConfig.name}`) || result,
            saved: true
          })
          return result
        } catch (error) {
          this.setState({
            error: error
          })
        } finally {
          this.setState({
            saving: false
          })
        }
      }

      render() {
        return (
          <this.ComposedComponent
            {...this.props}
            {...{
              [`${mutationConfig.key}_saving`]: this.state.saving,
              [`${mutationConfig.key}_saved`]: this.state.saved,
              [`${mutationConfig.key}_error`]: this.state.error,
              [`${mutationConfig.key}_result`]: this.state.result
            }}
          />
        )
      }
    }

    WithMutationProps.displayName = `WithMutationPropsFor_${mutationConfig.key}`

    return WithMutationProps
  }
