import Card from "@pathwright/ui/src/components/card/Card"
import CardLink from "@pathwright/ui/src/components/card/CardLink"
import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import get from "lodash/get"
import PropTypes from "prop-types"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import DownloadReport from "../../report/DownloadReport"
import useReportUrls from "../../report/graphql/useReportUrls"
import STORE_QUERY from "../../store/graphql/store-query"

const ManageAccountReports = ({ card }) => {
  const { school } = usePathwrightContext()

  const storeQuery = useQuery(STORE_QUERY)
  const storeId = get(storeQuery, "data.store.id")

  const reportUrls = useReportUrls({
    variables: {
      school_resources: {},
      school_cohorts: {},
      school_members: {},
      school_member_progress: {},
      school_registrations: {},
      school_coupon_summary: {},
      school_coupon_redemptions: {},
      licensing_store_connected_accounts: { store_id: storeId },
      licensing_store_resource_usage: { store_id: storeId },
      licensing_store_member_progress: { store_id: storeId },
      licensing_member_created_cohorts: {}
    },
    skip: storeQuery.loading
  })

  const {
    schoolResourcesReportUrl,
    schoolCohortsReportUrl,
    schoolMembersReportUrl,
    schoolMemberProgressReportUrl,
    schoolRegistrationsReportUrl,
    schoolCouponSummaryReportUrl,
    schoolCouponRedemptionsReportUrl,
    licensingStoreConnectedAccountsReportUrl,
    licensingStoreResourceUsageReportUrl,
    licensingStoreMemberProgressReportUrl,
    licensingMemberCreatedCohortsReportUrl
  } = reportUrls

  return (
    <DownloadReport>
      {({ handleDownloadReport }) => (
        <Card card={card} title={`${school.name} Reports`}>
          <CardLink
            icon="download"
            label="Download member report"
            meta="Contact info, membership role, and more for all members."
            onClick={() => handleDownloadReport(schoolMembersReportUrl)}
            download
          />
          <CardLink
            icon="download"
            label="Download member progress report"
            meta="Contact info, course progress, scores, and more for all registrations."
            onClick={() => handleDownloadReport(schoolMemberProgressReportUrl)}
            download
          />
          <CardLink
            icon="download"
            label="Download course registrations"
            meta="Contact info, registrations, sales, and more for all registrations."
            onClick={() => handleDownloadReport(schoolRegistrationsReportUrl)}
            download
          />
          <CardLink
            icon="download"
            label="Download Resource information and stats"
            meta="Course & Collection urls, total registrations, and more."
            onClick={() => handleDownloadReport(schoolResourcesReportUrl)}
            download
          />
          <CardLink
            icon="download"
            label="Download Cohort information and stats"
            meta="URLs, registrations, complete, incomplete, and more."
            onClick={() => handleDownloadReport(schoolCohortsReportUrl)}
            download
          />
          <CardLink
            icon="download"
            label="Download summary level discount code usage"
            meta="Discount code, total number of redemptions, and more."
            onClick={() => handleDownloadReport(schoolCouponSummaryReportUrl)}
            download
          />
          <CardLink
            icon="download"
            label="Download all discount code redemptions"
            meta="Contact info, discount code data, redemption date, and more."
            onClick={() =>
              handleDownloadReport(schoolCouponRedemptionsReportUrl)
            }
            download
          />
          {!!storeId && (
            <CardLink
              icon="download"
              label="Download Curriculum Store connected accounts summary"
              meta="School information and summary of curriculum use."
              onClick={() =>
                handleDownloadReport(licensingStoreConnectedAccountsReportUrl)
              }
              download
            />
          )}
          {!!storeId && (
            <CardLink
              icon="download"
              label="Download Curriculum Store course usage summary"
              meta="Registrations, completions, and cohorts for each course."
              onClick={() =>
                handleDownloadReport(licensingStoreResourceUsageReportUrl)
              }
              download
            />
          )}
          {!!storeId && (
            <CardLink
              icon="download"
              label="Download Curriculum Store member progress report"
              meta="Course progress, scores, and more for all learners taking a licensed course."
              onClick={() =>
                handleDownloadReport(licensingStoreMemberProgressReportUrl)
              }
              download
            />
          )}
          {/* Could error due to school not having resource licensing enabled. */}
          {!licensingMemberCreatedCohortsReportUrl?.error && (
            <CardLink
              icon="download"
              label="Download member-created-cohort data"
              meta="Creator information, seat counts, purchase information, and more."
              onClick={() =>
                handleDownloadReport(licensingMemberCreatedCohortsReportUrl)
              }
              download
            />
          )}
        </Card>
      )}
    </DownloadReport>
  )
}

ManageAccountReports.displayName = "ManageAccountReports"

ManageAccountReports.propTypes = {
  // provided by CardStack > CardStackCard
  card: PropTypes.object.isRequired
}

export default ManageAccountReports
