import gql from "graphql-tag"
import DISCUSSION_POST_FRAGMENT from "./discussion-post-fragment"

export default gql`
  mutation CreateDiscussionLike(
    $context: DiscussionContextInput!
    $discussion_id: Int!
  ) {
    createDiscussionLike(context: $context, discussion_id: $discussion_id) {
      ...DiscussionPost
    }
  }
  ${DISCUSSION_POST_FRAGMENT}
`
