import { Component } from "react"
import { withDebouncedProp } from "./component"

export const withSearch = (C) => {
  class Search extends Component {
    handleSearch = (search, handleSearch) => {
      if (handleSearch || this.props.debouncedSearch !== search) {
        this.props.handleDebouncedSearch(search)
      }
    }

    render() {
      return (
        <C
          {...this.props}
          search={this.props.debouncedSearch}
          onSearch={this.handleSearch}
        />
      )
    }
  }

  Search.displayName = "Search"

  return withDebouncedProp("search")(Search)
}
