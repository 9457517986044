import isEqual from "react-fast-compare"
import useLocalStorage from "./useLocalStorage"

// Expects initialValue to be an array of default items.
// The length of that array will determine how many items
// are stored in localStorage. The most recently stored item
// will be shifted to the front of the list.
const useMostRecent = (key, initialValue, options = {}) => {
  // Handles storing array as an object with list and index keys.
  // This allows for tracking the most recently used value by index
  // while maintaining the array ordering. JSON.stringify doesn't
  // record keys on arrays, so this is the workaround.
  const getValueStorage = (value, index = 0) =>
    Array.isArray(value)
      ? {
          list: value,
          index
        }
      : initialValue

  const [mostRecent, setMostRecent] = useLocalStorage(
    key,
    getValueStorage(initialValue)
  )

  const updateMostRecent = (value) => {
    // Ensure we're dealing with an array.
    if (Array.isArray(initialValue)) {
      // This ensures the most recent value only occurs once.
      const comparator = (v) => !isEqual(v, value)
      // Capping at current length.
      const maxOptions = mostRecent.list.length

      // At what index to insert the new value.
      let insertIndex = 0
      let nextMostRecent = mostRecent.list
      // Optionally maintain the order of the array if the value already
      // exists in the array.
      if (options.maintainOrder) {
        insertIndex = Math.max(
          insertIndex,
          mostRecent.list.findIndex((v) => v === value)
        )
      }

      // If value does not exist in the array, or if not maintaining order, or
      // if maintaining order but value exists in first position, then insert
      // value into first position. That last condition results in no change.
      if (insertIndex === 0) {
        // The updated mostRecent list.
        nextMostRecent = [
          // Insert most recent value at the beginning.
          value,
          // Filtering out any value already in the mostRecent list
          // matching value.
          ...mostRecent.list.filter(comparator)
          // Slicing the result to cap the nextMostRecent list at the
          // current length of tracked values.
        ].slice(0, maxOptions)
      }

      // Update localStorage.
      setMostRecent(
        getValueStorage(
          nextMostRecent,
          // Track the most receently used value by index.
          insertIndex
        )
      )
    } else {
      // Simply update localStorage.
      setMostRecent(getValueStorage(value))
    }
  }

  return [mostRecent, updateMostRecent]
}

export default useMostRecent
