import gql from "graphql-tag"
import SUBSCRIPTION_PLAN from "./subscription-plan-fragment"

export default gql`
  fragment SchoolSubscriptionPlan on SchoolSubscriptionPlan {
    id
    image
    details_html
    subscribe_complete_html
    catalog_header
    is_visible
    giftable_cycles
    subscription_plan {
      ...SubscriptionPlan
    }
  }
  ${SUBSCRIPTION_PLAN}
`
