import Card from "@pathwright/ui/src/components/card/Card"
import EnterpriseSSOFeatureBlock from "../../feature/auth/EnterpriseSSOFeatureBlock"
import PathwrightSignInFeatureBlock from "../../feature/auth/PathwrightSignInFeatureBlock"
import FeatureBlockList, {
  Container
} from "../../feature/block/FeatureBlockList"
import {
  FEATURE_ACTION_AUTH_LOGIN,
  FEATURE_KEY_AUTH_LOGIN_OPTION_GLOBAL_SSO_APPLE,
  FEATURE_KEY_AUTH_LOGIN_OPTION_GLOBAL_SSO_GOOGLE
} from "../../feature/constants"

const ManageAccountFeatures = ({ card }) => {
  return (
    <Card card={card} title="Configure Sign-in Options">
      <Container>
        <PathwrightSignInFeatureBlock />
      </Container>
      <FeatureBlockList
        key={FEATURE_ACTION_AUTH_LOGIN}
        featureAction={FEATURE_ACTION_AUTH_LOGIN}
        featureKeys={[
          FEATURE_KEY_AUTH_LOGIN_OPTION_GLOBAL_SSO_APPLE,
          FEATURE_KEY_AUTH_LOGIN_OPTION_GLOBAL_SSO_GOOGLE
        ]}
        showTagFeatureBlock={false}
      />
      <Container>
        <EnterpriseSSOFeatureBlock />
      </Container>
    </Card>
  )
}

ManageAccountFeatures.displayName = "ManageAccountFeatures"

export default ManageAccountFeatures
