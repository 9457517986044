import PropTypes from "prop-types"
import StoreResourcesQuery from "../../graphql/StoreResourcesQuery"
import BrowseGrid from "./BrowseGrid"

function BrowseGridContainer({
  max,
  clip,
  resourcesQueryOptions,
  heading,
  browseTo,
  onChangeFacets
}) {
  return (
    <StoreResourcesQuery {...resourcesQueryOptions} first={max}>
      {(data) => (
        <BrowseGrid
          clip={clip}
          count={max}
          heading={heading}
          hasMore={data.hasMore}
          loading={data.loading}
          resources={data.resources}
          loadMore={() => onChangeFacets(resourcesQueryOptions)}
          browseTo={browseTo}
        />
      )}
    </StoreResourcesQuery>
  )
}

BrowseGridContainer.displayName = "BrowseGridContainer"

BrowseGridContainer.propTypes = {
  resourcesQueryOptions: PropTypes.shape(StoreResourcesQuery.propTypes)
}

BrowseGridContainer.defaultProps = {
  max: 5
}

export default BrowseGridContainer
