import classnames from "classnames"
import PropTypes from "prop-types"
import styled from "styled-components"
import Portal from "../portal/Portal"

export const FULLSCREEN_LAYER_BASE = 999999999
export const FULLSCREEN_LAYER_APP = 0
export const FULLSCREEN_LAYER_PANEL = 2000
export const FULLSCREEN_LAYER_OVERLAY = 2020

const layers = {
  app: FULLSCREEN_LAYER_APP,
  panel: FULLSCREEN_LAYER_PANEL,
  overlay: FULLSCREEN_LAYER_OVERLAY
}

const Container = styled.div`
  z-index: ${(props) => FULLSCREEN_LAYER_BASE + props.layer};
  position: fixed;
  top: ${(props) => props.offset.top || 0}px;
  left: ${(props) => props.offset.left || 0}px;
  right: ${(props) => props.offset.right || 0}px;
  bottom: ${(props) => props.offset.bottom || 0}px;
  display: flex;
  flex-direction: column;
`

const FullscreenContainer = ({
  portalElementID,
  className,
  children,
  offset,
  layer,
  style
}) => {
  // Transofmr string layer to number.
  if (typeof layer === "string") {
    layer = layers[layer]
  }

  const fullscreen = (
    <Container
      className={classnames("FullscreenContainer", className)}
      layer={layer}
      offset={offset}
      style={style}
    >
      {children}
    </Container>
  )

  return portalElementID ? (
    <Portal portalElementID={portalElementID}>{fullscreen}</Portal>
  ) : (
    fullscreen
  )
}

FullscreenContainer.displayName = "FullscreenContainer"

FullscreenContainer.propTypes = {
  offset: PropTypes.shape({
    top: PropTypes.number,
    left: PropTypes.number,
    right: PropTypes.number,
    bottom: PropTypes.number
  }),
  layer: PropTypes.oneOf([...Object.keys(layers), ...Object.values(layers)])
}

FullscreenContainer.defaultProps = {
  portalElementID: "fullscreen-portal",
  offset: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  layer: FULLSCREEN_LAYER_APP
}

export default FullscreenContainer
