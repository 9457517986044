import Support from "@pathwright/web/src/modules/support/Support"
import { ConnectStores } from "lib/core/store"

const mapStateToProps = (state, ownProps) => {
  const { media } = state.schoolStore.state
  const { support } = state.supportStore.state
  const { exitModal } = state.navigationStore.action

  const mode = state.supportStore.request.getMode()

  return _.assign(
    {},
    {
      media,
      mode,
      exitModal
    },
    support,
    ownProps
  )
}

const stores = [
  { store: "support", watch: false },
  { store: "school", watch: false },
  { store: "auth", watch: false },
  { store: "navigation", watch: false }
]

export default ConnectStores(Support, stores, {}, mapStateToProps)
