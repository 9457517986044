import classnames from "classnames"
import isFunction from "lodash/isFunction"
import PropTypes from "prop-types"
import LoadingCircle from "../../loading/LoadingCircle"
import FieldWrapper from "../form-utils/FieldWrapper"
import Switch from "./Switch"
import "./ToggleInput.css"

const ToggleInput = (props) => {
  const {
    name,
    label,
    value,
    loading,
    onChange,
    borderTop,
    borderBottom,
    alignRight,
    inverted,
    disabled
  } = props

  const className = classnames(
    "ToggleInput",
    {
      "ToggleInput--align-right": alignRight,
      "ToggleInput--border-top": borderTop,
      "ToggleInput--border-bottom": borderBottom
    },
    props.className
  )

  return (
    <FieldWrapper {...props} className={className}>
      <div className="ToggleInput__switch">
        <Switch
          name={name}
          label={label}
          inverted={inverted}
          active={value}
          onChange={(val, e) => isFunction(onChange) && onChange(val, e)}
          disabled={disabled}
        />
        {loading && <LoadingCircle size={16} center={false} />}
      </div>
    </FieldWrapper>
  )
}

ToggleInput.displayName = "ToggleInput"

ToggleInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.bool,
  onChange: PropTypes.func
}

export default ToggleInput
