export default function getStripePaymentPortalHref(school) {
  let domain = school
    ? school.url
    : window.school
    ? window.school.url
    : window.location.origin + "/"
  return (
    domain +
    "payment/portal?return_to=" +
    encodeURIComponent(window.location.href)
  )
}
