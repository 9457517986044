// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
const isTestRunner = () =>
  (typeof navigator !== "undefined" && navigator !== null
    ? navigator.userAgent.indexOf("PhantomJS")
    : undefined) > -1

// const Backbone = require("backbone")

const { createResourceActions } = require("lib/core/api/resource")

class BackboneStore extends require("lib/static-shim").default(Backbone.Model) {
  static initClass() {
    ;`\
A BackboneStore is similar to a Flux store only
it also handles actions that mutate the store.

A store should be thought of the application/modules state from the
user's perspective or a data-based representation of the UI.
The UI should be a rendering of that data and should
not mutate the state directly.

To avoid views that use the store mutating it directly, the
set method is overriden and sends a warning to the console if called.\
`
    ;`\
Use this to define what data points are in the store\
`
    ;`\
Any action that mutate the store are defined here. Actions
can only return the store actions to allow chaining and to prevent them from being used incorrectly
(e.g. store.action.selectPage("page").setURL())\
`
    this.prototype.actions = {}
    ;`\
Request function are helper methods for getting information that isn't directly
accessibly via store.get(key). Requests should never mutate the store.\
`
    this.prototype.requests = {}
    ;`\
Store triggers are used for cases where custom events are needed that are not
covered by normal backbone model events and are meant for store-to-store communication.

Usage Example:
\`\`\`
  storeTriggers:
    "my:custom:event" -> @get('some_val') # The return value is what gets passed with the event to any listeners
\`\`\`

This can now be called by the store: \`@storeTrigger("my:custom:event")\`.
Note no arguments are passed with the \`storeTrigger\` function since this is handled by the \`storeTriggers\` declaration.

Views can also listen to storeTriggers the same as any backbone event
\`\`\`
@listenTo(myStore, "my:custom:event", (some_val)-> console.log(some_val))
\`\`\`
  \
`
    this.prototype.storeTriggers = {}
    ;`\
Listens to storeTriggers from another store. Used to centralize and declare
inter-store communications.

Example:
\`\`\`
storeTriggerHandlers:
  user:
    "session:authenticated": (user)->
      @action.setUser(user)
\`\`\`\
`
    this.prototype.storeTriggerHandlers = {}
    ;`\
Store events are a declarative way to manage side effects of a store mutation. For example, to clear out any stored
user data when a session is deleted, you could do something like:
\`\`\`
storeEvents:
  "change:session": -> @action.clearUser() if !@get("session)
\`\`\`

Note: If any mutations to the store occur as a side effect of a store event, the actual mutation should be handled by
an action, not the event handler itself.
  \
`
    this.prototype.storeEvents = {}
    ;`\
Store resources are for loading and updating API resources and automatically caching and setting keys\
`
    this.prototype.resources = {}
    ;`\
Store persistentKeys allow you to persist a set of store values to local storage.\
`
    this.prototype.persistentKeys = {}
    ;`\
Allows you to validate a store key before allowing a mutate.\
`
    this.prototype.keyValidators = {}
  }
  defaults() {}

  constructor() {
    super(...arguments)
    this._persistentKeyChangeHandler =
      this._persistentKeyChangeHandler.bind(this)
    this._bindActions()
    this._bindRequests()
    this._bindStoreEvents()
    this._createResources()
    this._initValidators()
    if (window.bootstrappedData && !_.isEmpty(window.bootstrappedData)) {
      this.bootstrapStore(window.bootstrappedData)
    }
  }

  _initialize() {
    this._bindStoreTriggers()
    this.initializeStore()
    this._initPersistentKeys()
    return this._setState()
  }

  initializeStore() {
    return `\
Override in store. Called when the store is first registered.\
`
  }

  bootstrapStore() {
    return `\
Override in store. Should pre-load any bootstrapped data
into the store's attributes.\
`
  }

  computeURLForState() {
    ;`\
Override in store. Should return a URL that will
route to the current store's state if applicable.\
`
    return null
  }

  updateStateForURL(url) {
    ;`\
Override in store. Should optionally handle updating
the state of the store based on the given URL.
Note: This must be manually wired up to listen to
the navigation store's "url:changed" store event\
`
    return null
  }

  reset(options) {
    if (options == null) {
      options = {}
    }
    this.onBeforeReset()
    // Abort any pending requests
    for (let key in this.resource) {
      if (this.get(`${key}_loading`) && this.get(`${key}_controller`)) {
        this.get(`${key}_controller`).abort()
      }
    }
    const update = _.assign({}, _.result(this, "defaults", {}))
    this._isReseting = true

    for (let key in this.resource) {
      const resource = this.resource[key]
      resource.clearCache()
      _.defaults(update, resource.initialState)
    }

    _.assign(update, this.getPersistentKeyState())

    this._mutate(update, options)

    this._isReseting = false
    this._setState()
    return this.onReset()
  }

  onBeforeReset() {} // override me
  onReset() {} // override me

  set(attributes, options) {
    // Set is allowed to be called once (in the constructor)
    super.set(...arguments)
    return (this.set = () =>
      console.warn(
        "BackboneStore: set is not allowed on stores... use actions to mutate!",
        console.trace()
      ))
  }

  serialized(key) {
    // Returns a plain-ol-object version of the request key
    return this._serializeObject(this.get(key).toJSON())
  }

  updateInitialState(state) {
    // current = _.pick(@getState(), _.keys(state))
    // updated = _.defaults(current, state)
    return this._mutate(state)
  }

  storeTrigger(key, payload) {
    if (payload == null) {
      payload = null
    }
    if (this._storeTriggers[key]) {
      if (!payload) {
        payload = this._storeTriggers[key]()
      }
      return this.trigger(key, payload)
    } else {
      throw new Error(`No store event ${key} registered.`)
    }
  }

  getStore(key, subKey) {
    // shortuct/helper method
    if (subKey == null) {
      subKey = null
    }
    return window.App.stores.requestStore(key, {}, subKey)
  }

  _validate(update, options) {
    if (this._lastAction == null) {
      return true // this is a boot-level mutate
    }

    if (_.isString(update)) {
      update = { [update]: options }
    }
    let valid = true
    for (let key in update) {
      const value = update[key]
      if (_.isFunction(this.keyValidators[key])) {
        valid = valid && this.keyValidators[key].bind(this)(value)
      }
    }
    return valid
  }

  triggerChange(event, data) {
    let keys = []
    try {
      keys = event
        .split(/ +/)
        .map((change) => change.split("change:")[1].trim())
    } catch (error) {
      // noop
    }

    // Only update the state of those keys that have changed.
    if (keys.length) {
      keys.forEach((key) => this._setState(key))
    } else {
      this._setState()
    }
    return this.trigger(event, this)
  }

  _mutate(attributes, options) {
    if (options == null) {
      options = {}
    }
    if (_.isEmpty(attributes)) {
      // Nothing to do
      return
    }

    if (this._validate(attributes, options)) {
      options = { ...options, silent: true }
      Backbone.Model.prototype.set.call(this, attributes, options)
      const changed = this.changedAttributes()
      if (changed) {
        const changeEvents = _.keys(changed)
          .map((k) => `change:${k}`)
          .join(" ")
        this.triggerChange(changeEvents)
        return this._logMutation(changed)
      }
    } else {
      console.warn(
        `${this._storeKey}: _mutate failed validation: `,
        attributes,
        options
      )
      console.warn("last action was: ", this._lastAction)
      return console.trace()
    }
  }

  _initValidators() {
    this.keyValidator = {}
    return (() => {
      const result = []
      for (let key in this.keyValidators) {
        const validator = this.keyValidators[key]
        result.push((this.keyValidator[key] = validator.bind(this)))
      }
      return result
    })()
  }

  _createResources() {
    this.resource = {}
    return (() => {
      const result = []
      for (let resourceKey in this.resources) {
        const config = this.resources[resourceKey]

        result.push(
          (this.resource[resourceKey] = createResourceActions(
            this,
            resourceKey,
            config
          ))
        )
      }
      return result
    })()
  }

  _persistentKeyChangeHandler(key) {
    if (this._isReseting) {
      // Don't override peristed values on store.reset()
      return
    }

    const storageKey = this.persistentKey[key].getKey(key)
    const value = this._serializeValue(this.get(key))
    return store.set(storageKey, value)
  }

  _initPersistentKeys() {
    if (_.isEmpty(this.persistentKeys)) {
      return
    }

    if (!store.enabled) {
      console.warn(
        "Cannot store persistentKeys since localStorage is not available!"
      )
    } else {
      this._persistentKeysEnabled = true
    }

    this.persistentKey = {}
    this._persistentKeys = []

    const createConfig = (key, config) => {
      if (_.isFunction(config)) {
        config = config()
      }

      if (!_.isObject(config)) {
        config = {}
      }

      config = _.defaults({}, config, {
        autoLoad: true,
        key
      })

      if (!config.getKey) {
        config.getKey = function (key) {
          return this._getPersistentKey(key)
        }
      }
      config.getKey = config.getKey.bind(this)

      if (!config.load) {
        config.load = function () {
          const pkey = config.getKey(key)
          const value = store.get(pkey)
          if (Array.from(this._persistentKeys).includes(!pkey)) {
            this._persistentKeys.push(pkey)
          }
          if (value) {
            return this._mutate({ [key]: value })
          }
        }
      }
      config.load = config.load.bind(this)

      if (!config.onChange) {
        config.onChange = function (key) {
          return this._persistentKeyChangeHandler(key)
        }
      }
      config.onChange = config.onChange.bind(this)

      const createHandler = (key) => () => config.onChange(key)
      this.listenTo(this, `change:${key}`, createHandler.bind(this)(key))

      return config
    }

    return (() => {
      const result = []
      for (let key in this.persistentKeys) {
        let config = this.persistentKeys[key]
        let item
        config = createConfig(key, config)
        this.persistentKey[key] = config
        if (config.autoLoad) {
          item = config.load()
        }
        result.push(item)
      }
      return result
    })()
  }

  getPersistentKeyState() {
    const keysState = {}
    for (let key in this.persistentKeys) {
      const val = this.persistentKeys[key]
      keysState[key] = this._getPersistentValue(key)
    }
    return keysState
  }

  clearPersistentKeys() {
    if (
      this._persistentKeys != null ? this._persistentKeys.length : undefined
    ) {
      for (let key of Array.from(this._persistentKeys)) {
        store.remove(key)
      }
    }
    return (this._persistentKeys = [])
  }

  _getPersistentKey(key) {
    return `${this._storeKey}:${key}`
  }

  _getPersistentValue(key) {
    return store.get(this._getPersistentKey(key))
  }

  _bindStoreEvents() {
    this._storeEvents = {}
    for (let key in this.storeEvents) {
      const func = this.storeEvents[key]
      this._storeEvents[key] = func
    }
    return (() => {
      const result = []
      for (let ev in this._storeEvents) {
        const handler = this._storeEvents[ev]
        result.push(this.listenTo(this, ev, handler))
      }
      return result
    })()
  }

  _bindStoreTriggers() {
    this._storeTriggers = {}
    for (let key in this.storeTriggers) {
      const func = this.storeTriggers[key]
      this._storeTriggers[key] = _.bind(func, this)
    }

    return (() => {
      const result = []
      for (let storeKey in this.storeTriggerHandlers) {
        var events = this.storeTriggerHandlers[storeKey]
        var store = window.App.stores.requestStore(storeKey)
        result.push(
          (() => {
            const result1 = []
            for (let ev in events) {
              const handler = events[ev]
              result1.push(this.listenTo(store, ev, handler))
            }
            return result1
          })()
        )
      }
      return result
    })()
  }

  _bindActions() {
    // Set default action helpers
    const _defaultActions = {
      setURL(navigate, trigger, prefix) {
        if (navigate == null) {
          navigate = true
        }
        if (trigger == null) {
          trigger = false
        }
        if (prefix == null) {
          prefix = ""
        }
        let url = this.computeURLForState()
        if (url) {
          if (prefix.length) {
            url = window.App.utils.urljoin(prefix, url)
          }

          this._mutate({ _url: url })
          if (navigate) {
            return window.App.navigate(this.get("_url"), { trigger })
          }
        }
      },

      _debug(debug, debug_theme) {
        const _debug_theme = this._getDebugTheme(debug_theme || "default")
        return this._mutate({ _debug: debug, _debug_theme })
      },

      mutate(data) {
        return this._mutate(data)
      }
    }

    _.defaults(this.actions, _defaultActions)

    this._lastAction = null
    // Wrap all actions
    const _wrapAction = (func, key) => {
      return function () {
        const actionData = {
          action: key,
          args: arguments
        }
        this.get("_debug") && console.time(key)
        this._logAction(actionData)
        this._lastAction = actionData
        const rv = _.bind(func, this)(...arguments)
        if (_.isFunction(rv != null ? rv.then : undefined)) {
          // The action returned a promise
          this.action.promise = rv
          rv.then(() => {
            this.get("_debug") && console.timeEnd(key)
          })
        } else {
          this.get("_debug") && console.timeEnd(key)
        }
        return this.action
      }.bind(this)
    }

    // Make local to instance
    this.action = {}
    return (() => {
      const result = []
      for (let key in this.actions) {
        const func = this.actions[key]
        result.push((this.action[key] = _wrapAction(func, key)))
      }
      return result
    })()
  }

  _bindRequests() {
    this.request = {}
    return (() => {
      const result = []
      for (let key in this.requests) {
        const func = this.requests[key]
        result.push((this.request[key] = _.bind(func, this)))
      }
      return result
    })()
  }

  _getDebugTheme(debug_theme) {
    const themes = {
      default: {
        action: {
          background: "#b8ddf0",
          color: "#fff"
        },
        mutation: {
          background: "#ddb052",
          color: "#fff"
        }
      },
      against_black: {
        // for when the Console background is dark
        action: {
          background: "#b8ddf0",
          color: "#000"
        },
        mutation: {
          background: "#ddb052",
          color: "#000"
        }
      }
    }

    return themes[debug_theme]
  }

  _log(message, data, bgColor, textColor) {
    let color
    if (isTestRunner()) {
      color = null
    }
    if (color !== null) {
      message = `%c${message}`
      console.log(message, `background: ${bgColor}; color: ${textColor};`, data)
    } else {
      console.log(message, data)
    }
  }

  _logAction(action) {
    if (this.get("_debug") && this.key()) {
      const { background, color } = __guard__(
        this.get("_debug_theme"),
        (x) => x.action
      )
      this._log(
        `ACTION: ${this.key()}Store.action.${action.action}`,
        action.args,
        background,
        color
      )
    }
  }

  _logMutation(changed) {
    if (this.get("_debug") && this.key()) {
      const data = this._serializeObject(changed)
      const { background, color } = __guard__(
        this.get("_debug_theme"),
        (x) => x.mutation
      )
      return this._log(`MUTATION: ${this.key()}`, data, background, color)
    }
  }

  logActionStack() {
    return console.log(this._actionStack)
  }

  // TODO: move to base model?
  _serializeValue(val) {
    if (_.isFunction(val != null ? val.serializeStore : undefined)) {
      return this._serializeObject(val.serializeStore())
    } else if (_.isFunction(val != null ? val.serialize : undefined)) {
      return this._serializeObject(val.serialize())
    } else if (_.isFunction(val != null ? val.toJSON : undefined)) {
      return this._serializeObject(val.toJSON())
    } else {
      return val
    }
  }

  _serializeObject(obj) {
    if (!_.isArray(obj)) {
      const data = {}
      for (let key in obj) {
        const val = obj[key]
        data[key] = this._serializeValue(val)
      }
      return data
    } else {
      const list = []
      if (obj) {
        for (let o of Array.from(obj)) {
          list.push(this._serializeValue(o))
        }
      }
      return list
    }
  }

  serializeStore() {
    return this._serializeObject(this.toJSON())
  }

  key() {
    return this._key
  }

  getState() {
    return this._state
  }

  _setState(key) {
    if (key) {
      this._state = {
        ...this._state,
        ...this._serializeObject({ [key]: this.get(key) })
      }
    } else {
      this._state = this.serializeStore()
    }
  }

  help() {
    let func, key
    console.info("Current State:")
    console.log(this.getState())

    console.info("Available Actions:")
    if (this.action) {
      for (key in this.action) {
        func = this.action[key]
        console.log(`action.${key}`)
      }
    }

    if (this.request) {
      console.info("Available Reqests:")
      return (() => {
        const result = []
        for (key in this.request) {
          func = this.request[key]
          result.push(console.log(`request.${key}`))
        }
        return result
      })()
    }
  }

  getStoreJSON(spaces) {
    if (spaces == null) {
      spaces = 2
    }
    return JSON.stringify(this.serializeStore(), null, spaces)
  }

  snapshot() {}
  // TODO: save state to local storage

  loadSnapshot() {}
}
BackboneStore.initClass()
// TODO: load from local storage
;` Example:

class SessionStore extends require("lib/static-shim") BackboneStore

defaults: ->
  is_authenticated: no

actions:
  signIn: (username, password)->
    @_mutate(username: username, isAuthenticated: yes)

sessionStore = SessionStore()
sessionStore.on "change:is_authenticated", -> console.log("authenticated!)
sessionStore.action.signIn(username, password)
\
`

export default BackboneStore

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null
    ? transform(value)
    : undefined
}
