export const MENTOR_GROUP_TYPE_COMMUNITY = 5
export const MENTOR_GROUP_TYPE_PERSONAL = 10
export const MENTOR_GROUP_TYPE_GROUP_SUBSCRIPTION = 15

export const MENTOR_GROUP_TYPES = [
  MENTOR_GROUP_TYPE_COMMUNITY,
  MENTOR_GROUP_TYPE_PERSONAL
]

export const MENTOR_GROUP_TYPE_INPUTS = {
  [MENTOR_GROUP_TYPE_COMMUNITY]: "community",
  [MENTOR_GROUP_TYPE_PERSONAL]: "personal"
}

export const MENTOR_GROUP_TYPE_LABELS = {
  [MENTOR_GROUP_TYPE_COMMUNITY]: "Community",
  [MENTOR_GROUP_TYPE_PERSONAL]: "Personal"
}

export const MENTOR_GROUP_DESCRIPTIONS = {
  [MENTOR_GROUP_TYPE_COMMUNITY]: "Set this up as a community group.",
  [MENTOR_GROUP_TYPE_PERSONAL]: "Set this up as a personal group."
}

export const MENTOR_GROUP_MEMBERSHIP_ROLE_MEMBER = 5
export const MENTOR_GROUP_MEMBERSHIP_ROLE_MODERATOR = 10
export const MENTOR_GROUP_MEMBERSHIP_ROLE_ADMIN = 15

export const MENTOR_GROUP_MEMBERSHIP_ROLES = [
  MENTOR_GROUP_MEMBERSHIP_ROLE_MEMBER,
  MENTOR_GROUP_MEMBERSHIP_ROLE_MODERATOR,
  MENTOR_GROUP_MEMBERSHIP_ROLE_ADMIN
]

export const MENTOR_GROUP_MEMBERSHIP_ROLE_INPUTS = {
  [MENTOR_GROUP_MEMBERSHIP_ROLE_MEMBER]: "member",
  [MENTOR_GROUP_MEMBERSHIP_ROLE_MODERATOR]: "moderator",
  [MENTOR_GROUP_MEMBERSHIP_ROLE_ADMIN]: "admin"
}

export const MENTOR_GROUP_MEMBERSHIP_ROLE_INTS = {
  member: MENTOR_GROUP_MEMBERSHIP_ROLE_MEMBER,
  moderator: MENTOR_GROUP_MEMBERSHIP_ROLE_MODERATOR,
  admin: MENTOR_GROUP_MEMBERSHIP_ROLE_ADMIN
}

export const getAvailableRoles = (permissions = {}) => {
  let roles = []
  if (permissions.can_add_member) {
    roles.push(MENTOR_GROUP_MEMBERSHIP_ROLE_MEMBER)
  }
  if (permissions.can_add_moderator) {
    roles.push(MENTOR_GROUP_MEMBERSHIP_ROLE_MODERATOR)
  }
  if (permissions.can_add_admin) {
    roles.push(MENTOR_GROUP_MEMBERSHIP_ROLE_ADMIN)
  }
  return roles
}
