class Author extends require("lib/static-shim").default(
  require("lib/utils/model").default
) {
  static initClass() {
    this.prototype.defaults = { order: 0 }
  }

  url() {
    let url = this.urlRoot(`/api/private/library/author/`)
    if (this.id) {
      url += `${this.id}/`
    }
    return url
  }
}
Author.initClass()
export default Author
