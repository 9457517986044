import View from "@pathwright/ui/src/components/ui/View"
import pluralize from "pluralize"

function BrowseResultDescription({ total, loading }) {
  return (
    <View style={{ marginBottom: 20 }}>
      {loading ? "Loading..." : pluralize("results", total, true)}
    </View>
  )
}

BrowseResultDescription.displayName = "BrowseResultDescription"

export default BrowseResultDescription
