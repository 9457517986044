import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import Button from "@pathwright/ui/src/components/button/Button"
import usePreviousEffect from "@pathwright/ui/src/components/hooks/usePreviousEffect"
import useScrollToMostRecentNode from "@pathwright/ui/src/components/hooks/useScrollToMostRecentNode"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import Text from "@pathwright/ui/src/components/ui/Text"
import styled from "styled-components"
import { useDiscussionContext } from "./DiscussionProvider"
import DiscussionResponse from "./DiscussionResponse"

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`
const Spacer = styled.div`
  position: relative;
  min-height: 100%;
`

const BackLinkContainer = styled.div`
  padding: 20px;
`

const tPrefix = "discussion.responses"

const DiscussionResponses = ({ children }) => {
  const { t } = useTranslate()

  const {
    response,
    responseDeleted,
    responses,
    responsesError,
    loading,
    responsesHasMore,
    loadMoreResponses,
    viewAllResponses,
    scrollToResponse,
    updateUserDiscussionHistory
  } = useDiscussionContext()

  usePreviousEffect(
    ([prevResponse]) => {
      // only scroll to response if response has changed
      if (response && response !== prevResponse) scrollToResponse(response)
    },
    [response]
  )

  // Scroll to the newest response.
  const handleRecentResponseNode = useScrollToMostRecentNode({
    list: responses,
    getItemMatch: (item1, item2) => item1.id === item2.id,
    scrollOptions: {
      behavior: "auto",
      block: "nearest"
    },
    // A threshold of 1 ensures the list scrolls when a new response is added.
    threshold: 1,
    // Update the user's discussion history whenever new responese
    // have been scrolled to.
    onScrolled: updateUserDiscussionHistory
  })

  if (response || responseDeleted) {
    return (
      <div>
        <BackLinkContainer>
          <Text.Meta>
            {t(`${tPrefix}.viewing_single`)}.{" "}
            <Button styleType="text" onClick={viewAllResponses}>
              {t(`${tPrefix}.view_all`)} <Pathicon icon="arrow-right" />
            </Button>
          </Text.Meta>
        </BackLinkContainer>
        {response ? (
          <DiscussionResponse key={response.id} response={response} />
        ) : (
          <BlankSlate
            icons={null}
            heading={t(`${tPrefix}.doesnt_exist`)}
            body={t(`${tPrefix}.deleted`)}
          />
        )}
      </div>
    )
  } else if (responses && responses.length > 0) {
    return (
      <>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            padding: "1em"
          }}
        >
          {responsesHasMore && loading ? (
            <Spacer>
              <LoadingCircle />
            </Spacer>
          ) : responsesHasMore ? (
            <Button onClick={loadMoreResponses} styleType="text">
              {t(`${tPrefix}.load_previous`)}
            </Button>
          ) : null}
        </div>

        <List>
          {responses.map((response) => (
            <DiscussionResponse
              key={response.id}
              ref={handleRecentResponseNode(response)}
              response={response}
            />
          ))}
          <div className="scroll-buffer" style={{ height: "20px" }} />
        </List>
      </>
    )
  }

  if (loading) {
    return (
      <Spacer>
        <LoadingCircle center />
      </Spacer>
    )
  }

  if (responsesError) {
    return (
      <BlankSlate
        icons={null}
        heading={t(`${tPrefix}.unexpected_error`)}
        body={responsesError.message || JSON.stringify(responsesError)}
      />
    )
  }

  return (
    // use props.children to replace the default BlankSlate
    children || (
      <BlankSlate
        heading={t(`${tPrefix}.start_discussion`)}
        body={t(`${tPrefix}.enter_message`)}
      />
    )
  )
}

DiscussionResponses.displayName = "DiscussionResponses"

export default DiscussionResponses
