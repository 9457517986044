import gql from "graphql-tag"

export default gql`
  mutation (
    $id: Int!
    $role: RegistrationRoleEnum
    $expiration_dtime: DateTime
  ) {
    updateRegistration(
      id: $id
      role: $role
      expiration_dtime: $expiration_dtime
    ) {
      id
      role
      expiration_dtime
    }
  }
`
