import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import moment from "moment"
import qs from "query-string"
import { useMemo } from "react"
import { StringParam, useQueryParam } from "use-query-params"
import { useNotificationsState } from "../notifications/state/notification-state"
import { GRADING_TYPE_MANUAL } from "../path/constants"
import { usePathwrightContext } from "../pathwright/PathwrightContext"
import { canAdministrate } from "../user/permissions"
import { useInboxReviewers } from "./context/Reviewers"

export const getURLParams = (url = null) => {
  if (!url) url = window.location.search
  const params = qs.parse(url)
  return params
}

export const setURLParams = (params, replace = false) => {
  const currentParams = replace ? {} : getURLParams()
  const nextParams = { ...currentParams, ...params }
  const url = `${window.location.pathname}?${qs.stringify(nextParams)}`
  window.history.pushState({}, "", url.toString())
}

export const getUserInitials = (name) => {
  return name
    .split(" ")
    .map((word) => word[0])
    .join(".")
}

export const getFeedItemDateString = (date) => {
  const time = moment(date)
  return time.fromNow()
}

export const timeago = (date) => {
  // return timeAgo.format(new Date(date), "mini")
  return moment(date).fromNow()
}

export const isItemReviewable = (item) => {
  if (!item.data || !item.data.completion) return false
  const { grading_type, needs_grading, userpoints_value } = item.data.completion
  return (
    grading_type === GRADING_TYPE_MANUAL ||
    needs_grading ||
    userpoints_value > 0
  )
}

export const isItemPendingReview = (item) => {
  if (!isItemReviewable(item)) return false
  if (!item.data || !item.data.completion) return false
  return item.data.completion.checked_by === null
}

export const isItemRevieww = (item) => {
  if (!isItemReviewable(item)) return false
  return item.data.completion.checked_by === null
}

export const isUsersCompletion = (item, pwContext) => {
  if (!item || !item.data.completion) return false
  if (!pwContext.me || !item.data.completion.user) return false
  return pwContext.me.id === item.data.completion.user.id
}

const USER_CAN_REVIEW_FOR_USER_QUERY = gql`
  query UserCanReviewForUser($forUserId: Int!) {
    canReview(for_user_id: $forUserId)
  }
`

// Determines whether user can review item using a combination of
// the reveiwers list and a fallback query. We check the reviewers
// list for if the current user exists in the reviewer list as a
// cohort staff member with review perms.
// If not, we fall back to the canReview query to see if user has
// either group mentorship reveiw perms or admin perms.
// A user's `can_rewview` setting trumps admin perms.
const useUserCanReviewForUser = (item) => {
  const pwContext = usePathwrightContext()
  const { reviewers } = useInboxReviewers(item.data.path_id)
  // If user can review for the cohort context, that takes precedence over
  // any group membership `can_review` setting.
  const canReviewForCohort =
    !!reviewers?.some(
      (reviewer) =>
        reviewer.user.id === pwContext?.me?.id &&
        reviewer.can_review &&
        reviewer.cohort_role
    ) || canAdministrate(pwContext)

  const query = useQuery(USER_CAN_REVIEW_FOR_USER_QUERY, {
    variables: { forUserId: item.data.completion.user.id },
    skip: canReviewForCohort
  })

  const canReviewForUser = !!query.data?.canReview
  return canReviewForCohort || canReviewForUser
}

// Item must be reviewable and must be for a differnt user.
// This is a basic perm check which errs on the side of safety.
// Some user's that fail this check actually do have permissions
// to review the item (i.e. moderator+ viewing their own item).
export const useUserCanReviewItem = (item) => {
  const pwContext = usePathwrightContext()
  const userCanReviewForUser = useUserCanReviewForUser(item)

  if (!isItemReviewable(item)) return false
  if (isUsersCompletion(item, pwContext)) return false
  return userCanReviewForUser
}

export const getItemUrl = ({ root, selected }) => {
  let item = selected || root
  let urlParts = []
  while (item) {
    switch (item.type) {
      case "step":
        // Igore the step when no item is selected.
        if (selected) urlParts.unshift("step", item.id)
        break
      case "lesson":
        if (!urlParts.includes("step")) {
          urlParts.unshift("lesson", item.id)
        }
        break
      case "path":
        urlParts.unshift(
          "library",
          item.data.resource.id,
          item.data.cohort.id,
          "path"
        )
        break
    }
    item = item.parent
  }

  return urlParts.length ? `/${urlParts.join("/")}/` : "/home/"
}

export function usePanelQueryParm() {
  const [_, setSelectedPanel] = useQueryParam("ui_panel", StringParam)
  return (panel) => setSelectedPanel(panel)
}

// Get's a filtered list of a user's "ask-to-answer" notifications
// that are for the provided item.
export function useUserAskedToRespondNotificationsForItem(itemOrId) {
  const itemId = typeof itemOrId === "number" ? itemOrId : itemOrId.data.id

  const { items: notifications, remove } = useNotificationsState()

  const askToRespondNotifications = useMemo(() => {
    return notifications.filter((notification) => {
      return (
        notification.type === "item" &&
        notification.data.stepId === itemId &&
        notification.data.action.toLowerCase() === "asked you to reply to"
      )
    })
  }, [notifications, itemId])

  function clearAll() {
    remove(askToRespondNotifications, true /* ignoreSelected */)
  }

  return { notifications: askToRespondNotifications, clearAll }
}
