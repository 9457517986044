export const BILLING_MODEL_PAYG = 0
export const BILLING_MODEL_HYBRID = 5
export const BILLING_MODEL_USER = 10

export const BILLING_INTERVAL_MONTH = 5
export const BILLING_INTERVAL_BIANNUAL = 7
export const BILLING_INTERVAL_YEAR = 10

export const PLAN_VISIBILITY_LEGACY = 0
export const PLAN_VISIBILITY_PW2 = 10
export const PLAN_VISIBILITY_HIDDEN = 15

export const LEARNER_COUNTING_METHOD_ACCESS = 1
export const LEARNER_COUNTING_METHOD_ACTIVITY = 2

export const BILLING_HELP_ARTICLES = {
  [BILLING_MODEL_PAYG]: "https://help.pathwright.com/pay-as-you-go-plan-guide",
  [BILLING_MODEL_HYBRID]:
    "https://help.pathwright.com/monthly-membership-plan-guide",
  [BILLING_MODEL_USER]: {
    [LEARNER_COUNTING_METHOD_ACCESS]:
      "https://help.pathwright.com/en/articles/2883306-pricing-faqs-for-pathwright-billing-plans",
    [LEARNER_COUNTING_METHOD_ACTIVITY]:
      "https://help.pathwright.com/monthly-access-plan-guide"
  }
}
