import gql from "graphql-tag"
import ORDER_FRAGMENT from "./order-fragment"

export default gql`
  mutation CreateGiftSubscriptionOrder(
    $plan_id: Int!
    $plan_visibility_code: String
    $num_cycles: Int!
    $recipient_first_name: String!
    $recipient_last_name: String!
    $gift_message: String
  ) {
    createGiftSubscriptionOrder(
      plan_id: $plan_id
      plan_visibility_code: $plan_visibility_code
      num_cycles: $num_cycles
      recipient_first_name: $recipient_first_name
      recipient_last_name: $recipient_last_name
      gift_message: $gift_message
    ) {
      ...Order
    }
  }
  ${ORDER_FRAGMENT}
`
