import gql from "graphql-tag"
import USER_FRAGMENT from "../../user/graphql/user-fragment"

export default gql`
  fragment MentorGroupMembership on MentorGroupMembership {
    id
    role
    created
    access_revoked_time
    group_id
    send_mentor_digest
    can_review
    user {
      ...User
    }
  }
  ${USER_FRAGMENT}
`
