import { HStack, Spinner } from "@chakra-ui/react"
import Card from "@pathwright/ui/src/components/card/Card"
import CardLink from "@pathwright/ui/src/components/card/CardLink"
import PropTypes from "prop-types"
import MediaManager from "../../media-manager/MediaManager"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import { canAdministrate } from "../../user/permissions"

const MediaManagerCardLink = ({ onClick, isLoading }) => {
  // Interesting variable reuse...
  let label = "Open media manager"
  label = isLoading ? (
    <HStack>
      <span>{label}</span>
      <Spinner size="sm" />
    </HStack>
  ) : (
    label
  )

  return (
    <CardLink
      icon="image"
      linkIcon="external-link"
      label={label}
      meta="Upload, edit, organize, and download your school's media."
      onClick={onClick}
    />
  )
}

const ManageLibraryMenu = ({ card }) => {
  const pwContext = usePathwrightContext()
  return (
    <Card card={card} title={`${pwContext.school.name} Library`}>
      {canAdministrate(pwContext) && (
        <MediaManager
          contextKey={`school-media-manager-${pwContext.school.id}`}
          onChooseMedia={(media) => {}}
          preventChoosingMedia
          mediaType="any"
          renderLoading={() => <MediaManagerCardLink isLoading />}
          renderButton={({ onClick }) => (
            <MediaManagerCardLink onClick={onClick} />
          )}
        />
      )}
      <CardLink
        icon="bullet-list"
        label="Manage categories"
        meta="Help people discover just the right course with categories."
        to={`${card.path}category/`}
      />
      <CardLink
        icon="avatar"
        label="Manage authors"
        meta="Help people discover courses from a particular person."
        to={`${card.path}author/`}
      />
      <CardLink
        icon="image"
        label="Manage banners"
        meta="Promote your brand, a new course, and more above your Library."
        to={`${card.path}banner/`}
      />
      {/* Asked to hide this for now due to incomplete UX */}
      {/* {school.is_curriculum_subscriber && (
        <CardLink
          icon="bullet-list"
          label="Manage curriculum licenses"
          meta="View and update your licenses for curriculum from another school."
          to={`${card.path}license/`}
        />
      )} */}
    </Card>
  )
}

ManageLibraryMenu.displayName = "ManageLibraryMenu"

ManageLibraryMenu.propTypes = {
  card: PropTypes.object.isRequired
}

export default ManageLibraryMenu
