const { createResourceStore } = require("lib/core/store/resource-store")
const CategoryCollection = require("../models/category-collection").default
const Category = require("../models/category").default

const options = {
  list_params: { loadAll: true },
  load_params: { loadAll: true }
}

const CategoryAdminStore = createResourceStore(
  "category",
  CategoryCollection,
  Category,
  options
)

export default window.App.stores.registerStore(
  "categoryAdmin",
  CategoryAdminStore
)
