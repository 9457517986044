import { getIsLocalHost } from "@pathwright/ui/src/components/utils/env"
import { getSchoolConfigURL } from "lib/core/api/urls"
import { bootstrapSchool } from "lib/core/boot/bootstrap"

export async function bootstrapData() {
  const shouldLoadSchoolConfig = !window.bootstrappedData

  if (shouldLoadSchoolConfig) {
    const schoolURL = getSchoolConfigURL()
    if (schoolURL) {
      const data = await bootstrapSchool(schoolURL)
      if (getIsLocalHost()) {
        localStorage.setItem("school_config_url", data.school.url)
      }
    }
  }
}
