import Button from "@pathwright/ui/src/components/button/Button"
import DropdownItem from "@pathwright/ui/src/components/dropdown/DropdownItem"
import DropdownMenu from "@pathwright/ui/src/components/dropdown/DropdownMenu"
import DropdownTrigger from "@pathwright/ui/src/components/dropdown/DropdownTrigger"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import PropTypes from "prop-types"
import useCohortMembersStats from "../../cohort/useCohortMembersStats"
import { DISCUSSION_NOTIFY_OPTIONS } from "./constants"
import { getDiscussionNotificationInfo } from "./utils"

const DiscussionNotifiySelect = ({
  context,
  value,
  onChange,
  disabled,
  inverted
}) => {
  const { t } = useTranslate()
  const cohortMemberStats = useCohortMembersStats(context)
  const controls = Object.values(DISCUSSION_NOTIFY_OPTIONS)

  const { icon, title } = getDiscussionNotificationInfo(value, t, {
    cohortMemberStats
  })

  return (
    <DropdownTrigger
      className="DiscussionNotifySelect"
      alignment="left"
      disabled={disabled}
      renderDropdown={() => (
        <DropdownMenu closeOnClick>
          {controls.map((control) => {
            const { icon, title, description } = getDiscussionNotificationInfo(
              control,
              t,
              { cohortMemberStats }
            )

            return (
              <DropdownItem
                key={control}
                onClick={() => onChange(control)}
                icon={icon}
                label={title}
                meta={description}
                styleType={control === value ? "secondary" : "tertiary"}
                multiline
              />
            )
          })}
        </DropdownMenu>
      )}
    >
      <Button
        styleType="inline"
        size="medium"
        inverted={inverted}
        preIcon={icon}
        postIcon="chevron-down"
        label={title}
        disabled={disabled}
      />
    </DropdownTrigger>
  )
}

DiscussionNotifiySelect.displayName = "DiscussionNotifiySelect"

DiscussionNotifiySelect.propTypes = {
  // controlled
  value: PropTypes.oneOf(Object.values(DISCUSSION_NOTIFY_OPTIONS)).isRequired,
  onChange: PropTypes.func.isRequired,
  getDiscussionNotificationInfo: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  inverted: PropTypes.bool
}

DiscussionNotifiySelect.defaultProps = {
  value: DISCUSSION_NOTIFY_OPTIONS.DO_NOT_NOTIFY,
  disabled: true,
  inverted: true
}

export default DiscussionNotifiySelect
