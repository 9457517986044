import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import { usePathwrightContext } from "../pathwright/PathwrightContext"

// Skip a query if there is no user or there is no auth token.
// There may be cases where one exists and no the other, like
// during a Apollo store reset.
export const useSkipAuthRequiredQuery = () => {
  const { me } = usePathwrightContext()
  const authToken = localStorage.getItem("auth_token")
  return Boolean(!me || (!authToken && process.env.NODE_ENV !== "test"))
}

// handles refetching query when auth changes
const useAuthAwareQuery = (operation, options = {}) => {
  const query = useQuery(operation, {
    ...(options || {}),
    skip: useSkipAuthRequiredQuery()
  })

  return query
}

export default useAuthAwareQuery
