import FullscreenConfirm from "@pathwright/ui/src/components/confirm/FullscreenConfirm"
import View from "@pathwright/ui/src/components/ui/View"
import { usePathwrightContext } from "../pathwright/PathwrightContext"

const getAssigError = (errorCode, { school, user, resource, cohort }) => {
  switch (errorCode) {
    case "already_registered":
      return `${user.full_name} is already in the ${cohort.name} cohort taking ${resource.name}.`
    case "default_cohort":
      return `Unable to register ${user.full_name} in the default cohort for ${resource.name}.`
    case "membership_required":
      return `${user.full_name} is not a member of ${school.name}.`
    case "subscription_required":
      return `${user.full_name} is not subscribed.`
    default:
      return `Unable to register ${user.full_name} in the ${cohort.name} cohort for ${resource.name}. Error unknown.`
  }
}

const AssignErrors = ({
  children,
  resourceCohortMap,
  cohortRegistrationMap,
  user,
  resources,
  cohorts
}) => {
  const { school } = usePathwrightContext()

  const assignedCohortsFailed = Object.keys(cohortRegistrationMap)
    .filter((oId) => isNaN(cohortRegistrationMap[oId]))
    .map((id) => parseInt(id))

  return (
    <FullscreenConfirm
      hideCancel
      onConfirm={() => {}}
      heading="The following issues occured:"
      body={
        <>
          {assignedCohortsFailed.map((cohortId, i) => {
            const errorCode = cohortRegistrationMap[cohortId]
            const cohort = cohorts.find((o) => (o.id = cohortId))
            const resource = resources.find(
              (r) =>
                r.id ===
                Object.keys(resourceCohortMap)
                  .map((rId) => parseInt(rId))
                  .find((rId) => resourceCohortMap[rId] === cohortId)
            )
            const errorMessage = getAssigError(errorCode, {
              school,
              user,
              resource,
              cohort
            })

            return (
              <View
                borderBottom={i !== assignedCohortsFailed.length - 1}
                padding
                key={i}
              >
                {errorMessage}
              </View>
            )
          })}
        </>
      }
    >
      {(props) => children({ ...props, assignedCohortsFailed })}
    </FullscreenConfirm>
  )
}

AssignErrors.displayName = AssignErrors

export default AssignErrors
