import PropTypes from "prop-types"
import React, { useState } from "react"
import styled from "styled-components"
import Alert from "../alert/Alert"
import BlankSlate from "../blank/BlankSlate"
import Fullscreen, { FULLSCREEN_LAYER_OVERLAY } from "../fullscreen/Fullscreen"
import View from "../ui/View"
import withConfirm from "./withConfirm"

const StyledWrapper = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`

const Container = styled(View.Primary)`
  position: relative;
  min-width: 300px;
  max-width: 500px;
  text-align: center;
  h4 {
    margin-bottom: 5px;
  }
`

export const FullscreenConfirmContext = React.createContext({})

export const useFullscreenConfirmContext = () =>
  React.useContext(FullscreenConfirmContext)

export const FullscreenConfirmProvider = ({ children }) => {
  const [confirm, setConfirm] = useState(null)
  const [confirmLoading, setConfirmLoading] = useState(false)

  return (
    <FullscreenConfirmContext.Provider
      value={{
        handleConfirm: setConfirm
      }}
    >
      {confirm && (
        <FullscreenConfirm
          stack
          {...confirm}
          loading={confirmLoading}
          onCancel={() => setConfirm(null)}
          onConfirm={async () => {
            setConfirmLoading(true)
            await confirm.callback()
            setConfirmLoading(false)
          }}
          layer={FULLSCREEN_LAYER_OVERLAY}
        />
      )}
      {children}
    </FullscreenConfirmContext.Provider>
  )
}

const FullscreenConfirm = withConfirm(
  ({
    icon,
    heading,
    body,
    preActionContent,
    primaryAction,
    secondaryAction,
    confirmingStatus,
    error,
    clearError,
    stack,
    layer = FULLSCREEN_LAYER_OVERLAY
  }) => (
    <Fullscreen
      layer={layer}
      background={{
        overlay: "rgba(0, 0, 0, 0.6)"
      }}
    >
      <StyledWrapper>
        <Container rounded border>
          <BlankSlate
            icons={null}
            icon={icon}
            stack={stack}
            heading={heading}
            body={body}
            footer={error && <Alert error={error} onClear={clearError} />}
            preActionContent={preActionContent}
            primaryAction={primaryAction}
            secondaryAction={secondaryAction}
            meta={confirmingStatus}
          ></BlankSlate>
        </Container>
      </StyledWrapper>
    </Fullscreen>
  )
)

FullscreenConfirm.displayName = "FullscreenConfirm"
FullscreenConfirm.propTyps = {
  stack: PropTypes.bool
}

FullscreenConfirm.defaultProps = {
  stack: false
}

export default FullscreenConfirm
