import { Flex } from "@chakra-ui/react"
import Error from "@pathwright/ui/src/components/error/Error"
import useLocalStorage from "@pathwright/ui/src/components/hooks/useLocalStorage"
import React, { useEffect, useState } from "react"

export const PathwrightContextSchoolMissingErrorContext = React.createContext(
  {}
)

export const usePathwrightContextSchoolMissingError = () =>
  React.useContext(PathwrightContextSchoolMissingErrorContext)

function PathwrightContextSchoolMissingError({ hasData, school, children }) {
  const [
    pathwrightContextSchoolMissingError,
    setPathwrightContextSchoolMissingError
  ] = useLocalStorage("pathwrightContextSchoolMissingError", null, {
    storageType: "session"
  })

  const [potentialError, setPotentialError] = useState(null)

  function usePotentialError(potentialError, deps = []) {
    useEffect(() => {
      if (potentialError) {
        setPotentialError(potentialError)
        return () => setPotentialError(null)
      }
    }, deps)
  }

  useEffect(() => {
    if (hasData && !school) {
      // Likely we ran into a situation where the user doesn't have sufficient permissions
      // to query some sub query on the { school }. The school returns as null and breaks
      // the client.
      // We could trying signing them out and redirecting to /auth/sign-in?next={{curentUrl}}
      // but that might not be best UX.
      // For now, we'll just reload the root url. We'll include a general error

      // NOTE: super hacky, but we can check if there's already an expected error
      // and show that instead.
      let errorData = potentialError

      if (!errorData) {
        try {
          school.id
        } catch (error) {
          errorData = {
            title: "An unexpected error occurred",
            details: error.message
          }
        }
      }
      setPathwrightContextSchoolMissingError(errorData)
    }
  }, [school])

  return (
    <PathwrightContextSchoolMissingErrorContext.Provider
      value={{
        pathwrightContextSchoolMissingError,
        setPathwrightContextSchoolMissingError,
        usePotentialError
      }}
    >
      {pathwrightContextSchoolMissingError ? (
        <Flex h="100vh" w="100%">
          <Error
            errorTitle={pathwrightContextSchoolMissingError.title}
            errorDetails={pathwrightContextSchoolMissingError.details}
            primaryAction={{
              label: "Ok",
              onClick: () => {
                setPathwrightContextSchoolMissingError()
                window.location = "/"
              }
            }}
          />
        </Flex>
      ) : school ? (
        children
      ) : null}
    </PathwrightContextSchoolMissingErrorContext.Provider>
  )
}

export default PathwrightContextSchoolMissingError
