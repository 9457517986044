import FullscreenConfirm from "@pathwright/ui/src/components/confirm/FullscreenConfirm"

function CertificateResourceRemoveConfirm({ children, resource, onRemove }) {
  return (
    <FullscreenConfirm
      key={resource.id}
      heading={`Are you sure you want to remove the Certificate from ${resource.name}?`}
      body={`Anyone who previously completed ${resource.name} will no longer have access to download the certificate.`}
      confirmPrompt="Yes, Remove"
      cancelPrompt="Cancel"
      onConfirm={() => onRemove(resource)}
      metaBypassesConfirm
    >
      {children}
    </FullscreenConfirm>
  )
}

export default CertificateResourceRemoveConfirm
