// TODO: possibly move to GQL query that loads both context data and variables map?
const BLOCKS_CONTEXT_VARIABLES = {
  user: {
    label: "User",
    variables: {
      id: {
        label: "Id"
      },
      username: {
        label: "Username"
      },
      first_name: {
        label: "First name"
      },
      last_name: {
        label: "Last name"
      },
      full_name: {
        label: "Full name"
      },
      display_name: {
        label: "Display name"
      },
      email: {
        label: "Email address"
      },
      date_joined: {
        label: "Date joined"
      }
    }
  },
  step: {
    label: "Step",
    variables: {
      id: {
        label: "Id"
      },
      name: {
        label: "Name"
      },
      description: {
        label: "Description"
      },
      url: {
        label: "URL"
      },
      step_verb: {
        label: "Step verb"
      },
      userpoints_value: {
        label: "Userpoints value"
      },
      starts: {
        label: "Starts date"
      },
      due: {
        label: "Due date"
      },
      time_to_complete: {
        label: "Time to complete"
      }
    }
  },
  path: {
    label: "Path",
    variables: {
      points: {
        label: "Points",
        variables: {
          earned: {
            label: "Total Points Earned"
          },
          total: {
            label: "Total Points Attempted"
          },
          total_points_possible: {
            label: "Total Points Possible"
          }
        }
      }
    }
  },
  group: {
    label: "Cohort",
    variables: {
      id: {
        label: "Id"
      },
      name: {
        label: "Name"
      },
      description: {
        label: "Description"
      },
      url: {
        label: "URL"
      },
      access_starts: {
        label: "Access starts date"
      },
      access_ends: {
        label: "Access ends date"
      },
      enrollment_starts: {
        label: "Registration starts date"
      },
      enrollment_ends: {
        label: "Registration ends date"
      }
    }
  },
  resource: {
    label: "Resource",
    variables: {
      id: {
        label: "Id"
      },
      name: {
        label: "Name"
      },
      description: {
        label: "Description"
      },
      short_description: {
        label: "Short description"
      },
      url: {
        label: "URL"
      },
      cover_image: {
        label: "Cover image"
      },
      background_image: {
        label: "Background image"
      },
      backgroundw_image_blurred_cdn: {
        label: "Blurred background image"
      },
      sum_registration_count: {
        label: "Total registrations"
      }
    }
  },
  school: {
    label: "School",
    variables: {
      name: {
        label: "Name"
      },
      short_description: {
        label: "Short description"
      },
      subdomain: {
        label: "Subdomain"
      },
      url: {
        label: "URL"
      }
    }
  },
  registration: {
    label: "Registration",
    variables: {
      id: {
        label: "ID"
      },
      role: {
        label: "Role"
      },
      last_activity_time: {
        label: "Last Activity Time"
      },
      certificate_download_url: {
        label: "Certificate Download URL"
      }
    }
  }
}

export default BLOCKS_CONTEXT_VARIABLES
