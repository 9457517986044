import { useToast } from "@chakra-ui/react"
import { CautionTriangleIcon } from "@pathwright/pathicons"
import { useEffect } from "react"
import { useSelectPeopleContext } from "./SelectPeopleContext"

// Very basic error handling for the (hopefully rare) selection errors.
const SelectError = () => {
  const { addPeopleToState } = useSelectPeopleContext()

  const toast = useToast()

  useEffect(() => {
    if (addPeopleToState.error) {
      toast({
        title: "An error occurred",
        status: "error",
        duration: 5000,
        isClosable: true,
        icon: <CautionTriangleIcon />
      })
    }
  }, [addPeopleToState.error])

  return null
}

export default SelectError
