import IconButton from "@pathwright/ui/src/components/button/IconButton"
import styled from "styled-components"
import { DASHBOARD_MOBILE_WIDTH } from "../constants"

const StyledSearchInput = styled.input`
  padding: 0px;
  padding-left: 20px;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.3);
  height: 37px;
  border: 0px;
  font-size: 16px;
  width: 100%;
  color: rgba(0, 0, 0, 0.5);
  :focus {
    outline: none;
  }
  ::placeholder {
    color: rgba(0, 0, 0, 0.5) !important;
  }
`

const SearchInputContainer = styled.div`
  width: 100%;
  position: relative;
  padding-right: 10px;
  @media (max-width: ${DASHBOARD_MOBILE_WIDTH}px) {
    padding-left: 10px;
  }
  .IconButton {
    position: absolute;
    right: 20px;
    top: 11px;
    i {
      color: rgba(0, 0, 0, 0.8);
    }
  }
`

const SearchInput = ({ onClose, ...props }) => {
  return (
    <SearchInputContainer className="SearchInput">
      <StyledSearchInput autoFocus {...props} />
      <IconButton size={16} icon="x" onClick={onClose} />
    </SearchInputContainer>
  )
}

export default SearchInput
