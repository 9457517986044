// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
let DiscussionResponseDeleteView
require("./styles/discussion-response-delete.css")

export default DiscussionResponseDeleteView = (function () {
  DiscussionResponseDeleteView = class DiscussionResponseDeleteView extends (
    require("lib/static-shim").default(require("lib/core/item-view").default)
  ) {
    static initClass() {
      this.prototype.template = require("./templates/discussion-response-delete.html")
      this.prototype.className = "discussion-response-delete-view"

      this.prototype.triggers = {
        "click .delete-response": "delete:response",
        "click .cancel-delete-response": "cancel:delete:response"
      }
    }
  }
  DiscussionResponseDeleteView.initClass()
  return DiscussionResponseDeleteView
})()
