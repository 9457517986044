import { useRandomResourceImageQuery } from "../../api/generated"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"

// Get random resource image based on the school theme.
function useRandomThemeResourceImage() {
  const { school } = usePathwrightContext()

  const randomResourceImageQuery = useRandomResourceImageQuery({
    variables: {
      themeType: school.theme?.theme_type
    },
    fetchPolicy: "network-only"
  })
  const randomResourceCover = randomResourceImageQuery.data?.resourceImage

  return randomResourceCover
}

export default useRandomThemeResourceImage
