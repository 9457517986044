import styled from "styled-components"
import Text from "../../ui/Text"

const Header = styled.header`
  position: relative;
  padding-top: 20px;
  padding-bottom: 5px;
  text-align: left;
  a {
    text-decoration: underline;
  }
  & > *:last-child {
    margin-bottom: 0;
  }
`

const Title = styled(Text.H5)`
  &:not(:only-child) {
    margin-bottom: 5px;
  }
`

const FieldHeader = ({ title, body, children, inverted, className, style }) => {
  return (
    <Header className={className} style={style}>
      {title && <Title inverted={inverted}>{title}</Title>}
      {body && <Text.Meta inverted={inverted}>{body}</Text.Meta>}
      {children}
    </Header>
  )
}

FieldHeader.displayName = "FieldHeader"

export default FieldHeader
