import { snakeCase } from "change-case"

export const getQueryVariables = (certificateState) => {
  // for now, certificateState is just the textObjects array
  if (certificateState) {
    // convert camelCase keys in textObjects to snake_case
    const queryVariables = certificateState.map((textObject) =>
      Object.entries(textObject).reduce((acc, [key, value]) => {
        acc[snakeCase(key)] = value
        return acc
      }, {})
    )

    return queryVariables
  }

  return null
}
