import { useMutation } from "@apollo/client"
import { useCallback } from "react"
import {
  DELETE_TAG_ATTACHMENT_MUTATION,
  DELETE_TAG_LINK_MUTATION,
  DELETE_TAG_MUTATION
} from "./graphql"
import useRefetchTagQueries from "./useRefetchTagQueries"
import { getTagLinksContext, getTagsContext } from "./utils"

const useUnselectTags = ({ context, tags }) => {
  const refetchTagQueries = useRefetchTagQueries(context)

  const [
    deleteTagMutation
    // deleteTagMutationState
  ] = useMutation(DELETE_TAG_MUTATION, { refetchQueries: refetchTagQueries })

  const [
    deleteTagAttachmentMutation
    // deleteTagAttachmentMutationState
  ] = useMutation(DELETE_TAG_ATTACHMENT_MUTATION, {
    refetchQueries: refetchTagQueries
  })

  const [
    deleteTagLinkMutation
    // deleteTagLinkMutationState
  ] = useMutation(DELETE_TAG_LINK_MUTATION, {
    refetchQueries: refetchTagQueries
  })

  const unselectTags = useCallback(
    async (selectedTags, modifiedTagLinksContext) => {
      // Accepting the second argument modifiedTagLinksContext to optionally
      // modify the tagLinksContext with a dynamic context at execution time.
      const tagsContext = getTagsContext(context)
      const tagLinksContext = getTagLinksContext(
        context,
        modifiedTagLinksContext
      )

      // Those tags that are exiting selection.
      const removedTags = tags.selected.filter(
        (selectedTag) =>
          !selectedTags.find((tag) => tag.name === selectedTag.name)
      )

      // If neither tagsContext or !tagLinksContext, perform deleteTagMutation per removed tag.
      const tagsToDelete =
        !tagsContext && !tagLinksContext ? removedTags.slice() : []

      // If tagsContext and not tagLinksContext, then perform deleteTagAttachmentMutation per tag exiting context.
      const tagsToDetach =
        tagsContext && !tagLinksContext ? removedTags.slice() : []

      // If tagLinksContext, then perform deleteTagLinkMutation per tag entering context.
      const tagsToUnlink = tagLinksContext ? removedTags.slice() : []

      // For each deleted tag, perform a deleteTagMutation.
      await Promise.all(
        tagsToDelete.map((tag) =>
          deleteTagMutation({
            variables: {
              tag: { id: tag.id }
            }
          })
        )
      )

      // For each tag exiting the tagsContext, perform a deleteTagAttachmentMutation.
      await Promise.all(
        tagsToDetach.map((tag) =>
          deleteTagAttachmentMutation({
            variables: {
              tagId: tag.id,
              context: tagsContext
            }
          })
        )
      )

      // For each tag exiting the tagLinksContext, perform a deleteTagLinkMutation.
      await Promise.all(
        tagsToUnlink.map((tag) =>
          deleteTagLinkMutation({
            variables: { tagId: tag.id, context: tagLinksContext }
          })
        )
      )

      // Once all mutations have finished, we'll requery tags for top-level context
      // and current context, if provided.
      if (tagsToDelete.length || tagsToDetach.length) {
        await refetchTagQueries()
      }
    },
    [tags]
  )

  return unselectTags
}

export default useUnselectTags
