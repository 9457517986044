import { chakra, VStack } from "@chakra-ui/react"
import { Formik } from "formik"
import defaults from "lodash/defaults"
import { toFormikValidate } from "zod-formik-adapter"
import { FormikFormStateInputField } from "../../../components/form/state/FormikFormStateField"
import { useScopedTranslation } from "../../../lib/lng/TranslationsProvider"
import { ActionType } from "../../../lib/types"
import AuthFormAlert from "../components/AuthFormAlert"
import AuthFormButton from "../components/AuthFormButton"
import { UserPropertyDefaults } from "../types"
import { AUTH_EMAIL_STORAGE_KEY } from "../utils/authEmailFormStateStorage"
import { authRequestFormSchema, AuthRequestFormSchema } from "./api/action"

const validate = toFormikValidate(authRequestFormSchema)

type AuthRequestFormProps = ActionType & {
  submitButtonLabel?: string
  userPropertyDefaults?: UserPropertyDefaults
}

const AuthRequestForm = ({
  submitButtonLabel,
  userPropertyDefaults,
  error,
  loading,
  onSubmit
}: AuthRequestFormProps) => {
  const { t } = useScopedTranslation("auth.auth_request_form")

  if (!submitButtonLabel) submitButtonLabel = t("send_sign_in_link")

  const initialValues = defaults<UserPropertyDefaults, AuthRequestFormSchema>(
    { ...userPropertyDefaults },
    {
      email: ""
    }
  )

  return (
    <Formik
      initialValues={initialValues}
      validateOnMount={!!userPropertyDefaults}
      validate={validate}
      onSubmit={() => {}} // noop
    >
      <chakra.form onSubmit={onSubmit} w="100%">
        <VStack spacing={4} width="100%">
          <AuthFormAlert error={error} />
          <FormikFormStateInputField
            formStateKey={AUTH_EMAIL_STORAGE_KEY}
            localStorageOptions={{ storageType: "session" }}
            name="email"
            type="email"
            placeholder={t("enter_your_email")}
            isDisabled={loading}
          />
          <AuthFormButton
            size="lg"
            loading={loading}
            // leftIcon={<PaperAirplaneIcon />}
          >
            {submitButtonLabel}
          </AuthFormButton>
        </VStack>
      </chakra.form>
    </Formik>
  )
}

export default AuthRequestForm
