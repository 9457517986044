import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import gql from "graphql-tag"
import get from "lodash/get"
import {
  BILLING_MODEL_HYBRID,
  BILLING_MODEL_PAYG
} from "../../billing/constants"

const FEATURE_INCLUDED_COUNT_QUERY = gql`
  query FeatureIncludedCountQuery {
    account {
      id
      billing_model
      is_activated
      is_activated_via_override
      billing_subscription {
        id
        extra_cohorts
        extra_mentor_groups
        plan {
          id
          cohorts_included
          mentor_groups_included
        }
      }
    }
  }
`

export const UPGRADE_PROMPT_TYPES = {
  NONE: 1, // show no prompt
  ACTIVATE: 2, // show an activation prompt
  UPGRADE: 3 // show an upgrade prompt
}

// these keys correspond to fields in the FEATURE_INCLUDED_COUNT_QUERY
export const UPGRADE_PROMPT_KEYS = {
  COHORTS: "cohorts",
  MENTOR_GROUPS: "mentor_groups"
}

// number of items included by default for any school
const DEFAULT_INCLUDED = {
  [UPGRADE_PROMPT_KEYS.COHORTS]: 1,
  [UPGRADE_PROMPT_KEYS.MENTOR_GROUPS]: 1
}

// util hook for determing what kind of prompt the user should be given
// depending on usage of a specific feature
const useUpgradePrompt = ({ key, totalUsed }) => {
  // null result indicates loading
  let prompt = null
  let totalIncluded = null

  const includedKey = `${key}_included`
  const extraKey = `extra_${key}`

  // paths to the values
  const includedPath = `account.billing_subscription.plan.${includedKey}`
  const extraPath = `account.billing_subscription.${extraKey}`

  const featureIncludedCountQuery = useQuery(FEATURE_INCLUDED_COUNT_QUERY)

  if (featureIncludedCountQuery.data) {
    const { account } = featureIncludedCountQuery.data

    // default included count on the plan
    const included = get(
      featureIncludedCountQuery.data,
      includedPath,
      DEFAULT_INCLUDED[key]
    )

    // extra count added to the school's billing subscription
    const extra = get(featureIncludedCountQuery.data, extraPath, 0)

    // total included is the base + extra
    totalIncluded = included + extra

    if (account.is_activated) {
      if (account.is_activated_via_override) totalIncluded = Infinity
      if (account.billing_model === BILLING_MODEL_PAYG) totalIncluded = Infinity
      if (included === -1) totalIncluded = Infinity

      // Hybrid included count yet to be determined, but assumption is
      // that if the included count is the default, then we must not have set any by default and treating as unlimited
      if (
        account.billing_model === BILLING_MODEL_HYBRID &&
        totalIncluded <= DEFAULT_INCLUDED[key]
      )
        totalIncluded = Infinity
    }

    if (totalUsed >= totalIncluded) {
      // prompt activation
      if (!account.is_activated) {
        prompt = UPGRADE_PROMPT_TYPES.ACTIVATE
      } else {
        // prompt upgrade
        prompt = UPGRADE_PROMPT_TYPES.UPGRADE
      }
    } else {
      // no prompt is necessary
      prompt = UPGRADE_PROMPT_TYPES.NONE
    }
  }

  return {
    prompt,
    totalIncluded
  }
}

export default useUpgradePrompt
