// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
let FocusFirstFieldBehavior
export default FocusFirstFieldBehavior = (function () {
  FocusFirstFieldBehavior = class FocusFirstFieldBehavior extends (
    require("lib/static-shim").default(Marionette.Behavior)
  ) {
    static initClass() {
      this.prototype.defaults = { focusEvent: "render" }
    }

    initialize() {
      if (this.options.focusEvent) {
        this.listenTo(this.view, this.options.focusEvent, this.focus)
      }
      return this.listenTo(this.view, "focus:first:field", this.focus)
    }

    focus() {
      // Set focus to the first input
      // For now disable this on IE
      let allowFocus = true
      // This a very strange error that can happen in the   window.App...
      if (
        typeof Modernizr !== "undefined" && Modernizr !== null
          ? Modernizr.input
          : undefined
      ) {
        allowFocus = Modernizr.input.placeholder
      }
      allowFocus = allowFocus && !Pathwright.request("is:ios")
      if (allowFocus) {
        return _.defer(() => {
          const $f = this._getFirstEmptyField()
          if ($f.length) {
            return $f.focus()
          }
        })
      }
    }

    _getFirstEmptyField() {
      return this.$("input[value='']:not(:checkbox,:button):visible:first")
    }
  }
  FocusFirstFieldBehavior.initClass()
  return FocusFirstFieldBehavior
})()
