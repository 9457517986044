import gql from "graphql-tag"
import SCHOOL_BILLING_PLAN_SET_FRAGMENT from "../../billing/graphql/school-billing-plan-set-fragment"
import SCHOOL_BILLING_SUBSCRIPTION_FRAGMENT from "../../billing/graphql/school-billing-subscription-fragment"
import CURRICULUM_SUBSCRIPTION_FRAGMENT from "../../school/graphql/curriculum-subscription-fragment"

export default gql`
  query Account($default_billing_plan_set: Boolean = true) {
    account {
      id
      is_activated
      is_activated_via_override
      is_on_legacy_plan
      billing_model
      billing_subscription {
        ...SchoolBillingSubscription
      }
      curriculum_subscription {
        ...CurriculumSubscription
      }
      billing_plan_set(default: $default_billing_plan_set) {
        ...SchoolBillingPlanSet
      }
      scholarship {
        id
        coupon {
          id
          percent_off
        }
        ends
        iterations
        order
        plan_id
        starts
      }
    }
  }
  ${SCHOOL_BILLING_SUBSCRIPTION_FRAGMENT}
  ${SCHOOL_BILLING_PLAN_SET_FRAGMENT}
  ${CURRICULUM_SUBSCRIPTION_FRAGMENT}
`
