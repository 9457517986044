import { Box } from "@chakra-ui/react"
import classnames from "classnames"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useCertificateContext } from "../context/CertificateContext"
import { useCertificateState } from "../context/CertificateState"
import CertificateTemplate, {
  CertificateTemplateDimensions
} from "../template/CertificateTemplate"
import CertificateText from "../text/CertificateText"

const EditorContainer = styled(CertificateTemplateDimensions)`
  position: relative;
  border: 1px solid black;
  box-sizing: content-box;
  margin-bottom: 10px;
`

const CertificateEditor = ({ offset }) => {
  const { certificateScope } = useCertificateContext()
  const { templateDimensions } = useCertificateState()

  return (
    <Box
      className={classnames("CertificateEditor", {
        locked: !!certificateScope
      })}
      marginRight={offset ? `${offset}px` : undefined}
    >
      <EditorContainer>
        <CertificateTemplate />
        {!!templateDimensions && <CertificateText />}
      </EditorContainer>
    </Box>
  )
}

CertificateEditor.displayName = "CertificateEditor"

CertificateEditor.propTypes = {
  onChangeTextObjects: PropTypes.func
}

export default CertificateEditor
