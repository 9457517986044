const PreventOrphan = ({ text }) => {
  const words = text.split(" ")
  if (words.length < 4) {
    return <span className="PreventOrphan">{words.join(" ")}</span>
  } else {
    const last_two_words = words.slice(words.length - 2).join(" ")
    const first_words = words
      .slice(0, +words.length - 3 + 1 || undefined)
      .join(" ")
    return (
      <span className="PreventOrphan">
        {first_words}{" "}
        <span style={{ whiteSpace: "nowrap" }}>{last_two_words}</span>
      </span>
    )
  }
}

PreventOrphan.displayName = "PreventOrphan"

export default PreventOrphan
