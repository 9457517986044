import Attachment from "@pathwright/ui/src/components/attachment/Attachment"
import PropTypes from "prop-types"
import styled from "styled-components"

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const DiscussionResponseAttachments = ({
  attachments,
  onRemoveAttachment,
  download
}) => {
  try {
    attachments = JSON.parse(attachments)
  } catch (e) {
    // do nothing
  }

  return attachments && attachments.length ? (
    <Wrapper>
      {attachments.map((attachment, i) => (
        <Attachment
          key={`${attachment.mediaID}-${i}`}
          name={attachment.name}
          mimetype={attachment.mimetype}
          url={`${attachment.url}?dl=true`} // assumes filestack api
          onRemove={
            onRemoveAttachment ? () => onRemoveAttachment(attachment) : null
          }
          requireConfirmation={false}
        />
      ))}
    </Wrapper>
  ) : null
}

DiscussionResponseAttachments.displayName = "DiscussionResponseAttachments"
DiscussionResponseAttachments.propTypes = {
  attachment: PropTypes.arrayOf(
    PropTypes.shape({
      filestackHandle: PropTypes.string,
      mediaID: PropTypes.string,
      mimetype: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.string
    })
  ),
  onRemoveAttachment: PropTypes.func
}

DiscussionResponseAttachments.defaultProps = {
  download: false,
  onRemoveAttachment: null
}

export default DiscussionResponseAttachments
