import { useMutation } from "@apollo/client"
import IconButton from "@pathwright/ui/src/components/button/IconButton"
import FullscreenConfirm from "@pathwright/ui/src/components/confirm/FullscreenConfirm"
import DropdownItem from "@pathwright/ui/src/components/dropdown/DropdownItem"
import DropdownMenu from "@pathwright/ui/src/components/dropdown/DropdownMenu"
import DropdownTrigger from "@pathwright/ui/src/components/dropdown/DropdownTrigger"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import useCohortPermissions from "../cohort/useCohortPermissions"
import ManageRegistration from "../registration/ManageRegistration"
import DELETE_REGISTRATION_MUTATION from "../registration/graphql/delete-registration-mutation"

export const ConfirmRemoval = ({ registration, update, children }) => {
  const { t } = useTranslate()

  const [mutation] = useMutation(DELETE_REGISTRATION_MUTATION, {
    variables: {
      registration_id: registration.id,
      group_id: registration.group.id
    },
    update: (cache) => {
      if (update) update(cache)
    }
  })

  return (
    <FullscreenConfirm
      heading={t("Are you sure you want to remove {{ user }}?", {
        user: `${registration.user.first_name} ${registration.user.last_name}`
      })}
      body={t("This will remove all of their progress and access.")}
      confirmPrompt={t("Yes, Remove")}
      cancelPrompt={t("Cancel")}
      onConfirm={() => mutation()}
      onConfirmed={() => {}}
      stack
    >
      {children}
    </FullscreenConfirm>
  )
}

const ProfileRegistrationControls = ({
  registration,
  onRemoveRegistration
}) => {
  const { t } = useTranslate()
  const cohortPerms = useCohortPermissions({ cohortId: registration.group_id })

  if (!cohortPerms.hasModeratorLevelAccess) {
    return null
  }

  return (
    <ConfirmRemoval
      registration={registration}
      update={(cache) => onRemoveRegistration(registration, cache)}
    >
      {({ confirm, confirming }) => (
        <DropdownTrigger
          preventHide={confirming}
          renderDropdown={() => {
            return (
              <DropdownMenu>
                {registration.role <= 15 && (
                  <DropdownItem
                    icon="gear"
                    label={t("Configure")}
                    to={{
                      card: {
                        component: ManageRegistration,
                        props: {
                          groupId: registration.group.id,
                          registrationId: registration.id,
                          onSubmitSuccess:
                            App.getStore("member").action
                              .respondToMemberRoleUpdate
                        }
                      }
                    }}
                    closeOnClick={true}
                  />
                )}
                <DropdownItem
                  icon="slash"
                  label={t("Remove")}
                  onClick={confirm}
                  preventHide={confirming}
                />
              </DropdownMenu>
            )
          }}
        >
          <IconButton
            icon="ellipses-horizontal"
            iconProps={{ style: { color: `rgba(1,1,1, .5)` } }}
          />
        </DropdownTrigger>
      )}
    </ConfirmRemoval>
  )
}

ProfileRegistrationControls.displayName = "ProfileRegistrationControls"

export default ProfileRegistrationControls
