export const BACKGROUND_TYPE_PRISTINE = 1
export const BACKGROUND_TYPE_BLUR = 5

// export const PRODUCTION_STRIPE_REDIRECT_URI = "https://get.pathwright.com/school/stripe/oauth/callback/"
export const STAGING_STRIPE_REDIRECT_URI =
  "https://get.pathwrightstaging.com/school/stripe/oauth/callback/"
export const TESTING_STRIPE_REDIRECT_URI =
  "https://get.pantswright.com/school/stripe/oauth/callback/"

export const PAGE_TYPE_GENERIC = 0
export const PAGE_TYPE_TERMS = 5
export const PAGE_TYPE_HOME = 10
export const PAGE_TYPES = {
  [PAGE_TYPE_GENERIC]: "Generic",
  [PAGE_TYPE_HOME]: "Home",
  [PAGE_TYPE_TERMS]: "Terms of Use"
}
