import OptionsButton from "@pathwright/ui/src/components/button/OptionsButton"
import DropdownItem from "@pathwright/ui/src/components/dropdown/DropdownItem"
import DropdownMenu from "@pathwright/ui/src/components/dropdown/DropdownMenu"
import DropdownTrigger from "@pathwright/ui/src/components/dropdown/DropdownTrigger"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"

const NotificationsControls = ({
  items,
  onShowDigest,
  onMarkAsRead,
  onClear
}) => {
  const { t } = useTranslate()

  const handleMarkAsRead = () => {
    onMarkAsRead()
  }

  const handleClear = () => {
    onClear()
  }

  const handleDigest = () => {
    onShowDigest()
  }

  return (
    <DropdownTrigger
      alignment="right"
      renderDropdown={() => (
        <DropdownMenu closeOnClick className="NotificationsControls">
          <DropdownItem
            icon="envelope"
            label={t("Digest Settings")}
            onClick={handleDigest}
          />
          {!!items.length && (
            <DropdownItem
              icon={"check"}
              label={"Mark all as read"}
              onClick={handleMarkAsRead}
            />
          )}
          {/* <DropdownItem
            icon={"x"}
            label={"Remove all unpinned"}
            onClick={handleClear}
          /> */}
        </DropdownMenu>
      )}
    >
      <OptionsButton />
    </DropdownTrigger>
  )
}

export default NotificationsControls
