import {
  createBrowserRouter,
  RouteObject,
  RouterProvider
} from "react-router-dom"

type RouterAdapterProps = {
  element: RouteObject["element"]
}

const RouterAdapter = ({ element }: RouterAdapterProps) => {
  return (
    <RouterProvider
      router={createBrowserRouter([
        {
          path: "*",
          element
        }
      ])}
    />
  )
}

export default RouterAdapter
