import gql from "graphql-tag"

export default gql`
  fragment SchoolCurriculumPlan on SchoolCurriculumPlan {
    id
    name
    amount
    fee_boundary
    fee_per_user
    is_visible
    lower_boundary
    plan_id
    transaction_fee_percent
    upper_boundary
    visibility_code
  }
`
