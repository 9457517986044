import {
  handleAuthToken,
  hasAuthenticationToken
} from "@pathwright/web-new/src/lib/utils/auth-token"
import { useEffect } from "react"
import { RefreshToken } from "../../auth/useRefreshToken"
import IntercomProvider from "../../intercom/IntercomProvider"
import ErrorParamAlert from "../../lib/ErrorParamAlert"
import { getEnv } from "../../lib/env"
import { Heartbeat } from "../../lib/useHeartbeat"
import I18nextProvider from "../../lng/I18nextProvider"
import { GlobalThemeStyles } from "../../pathwright/GlobalSchoolThemeStyles"
import {
  PathwrightClient,
  getApolloClient
} from "../../pathwright/PathwrightClient"
import {
  PathwrightContextProvider,
  useAuthChange,
  usePathwrightContext
} from "../../pathwright/PathwrightContext"
import StagingPreviewAlert from "../../pathwright/StagingPreviewAlert"
import { Referral } from "../../referral/useReferral"
import ChakraThemeProvider from "../../theme/ChakraThemeProvider"

// Probably want to refactor this out somewhere, but necessary
// for syning the session, which the platform in general requires.
// Specificall, the "cbsessionid" cookie.
function AuthSync() {
  const { school } = usePathwrightContext()

  function getSessionUrl() {
    return `${school.url}api/private/session/`
  }

  function getSessionHeaders(token?: string) {
    // NOTE: these headers largly come from the legacy client
    // getPathwrightRequestHeaders util.
    const headers: Record<string, string> = {
      Accept: "application/json, text/javascript, */*; q=0.01",
      "Content-Type": "application/json"
    }

    if (getEnv() === "production") {
      headers["X-Pathwright-API-Version"] = "2016-06-15"
    } else {
      const d = new Date()
      headers["X-Pathwright-Application-Version"] = "1.0"
      headers["X-Pathwright-API-Version"] = `${d.getFullYear()}-${("0" + d.getMonth()).slice(-2)}-${("0" + d.getDate()).slice(-2)}` // prettier-ignore
    }

    if (token) {
      headers["Authorization"] = `Token ${token}`
    }

    return headers
  }

  async function getSession(token?: string) {
    return fetch(getSessionUrl(), {
      method: "GET",
      headers: getSessionHeaders(token)
    })
  }

  async function deleteSession() {
    return fetch(getSessionUrl(), {
      method: "DELETE",
      headers: getSessionHeaders()
    })
  }

  async function syncSession(token: string | null) {
    if (token) {
      return getSession(token)
    } else {
      return deleteSession()
    }
  }

  async function checkSession() {
    if (!hasAuthenticationToken()) {
      // Let's check the session, and set the token if found.
      // NOTE: this is especially necessary when user authenticates via Global SSO.
      try {
        const resp = await getSession()
        const session = await resp.json()
        const { auth_token } = session
        if (auth_token) handleAuthToken(auth_token)
      } catch {
        /* noop */
      }
    }
  }

  useAuthChange(async (token) => {
    // Reset apollo store.
    await getApolloClient().resetStore()
    await syncSession(token)
  })

  useEffect(() => {
    checkSession()
  }, [])

  return null
}

type StandaloneSpaceSignupFlowWrapperProps = {
  children: JSX.Element
}

const StandaloneSpaceSignupFlowWrapper = ({
  children
}: StandaloneSpaceSignupFlowWrapperProps) => (
  <PathwrightClient schoolId={7888}>
    <PathwrightContextProvider>
      <AuthSync />
      <ChakraThemeProvider resetCSS>
        <I18nextProvider lng="en" useLocalTranslation>
          <IntercomProvider>
            <GlobalThemeStyles />
            <StagingPreviewAlert forceShow={false} />
            <ErrorParamAlert />
            <Heartbeat />
            <RefreshToken />
            <Referral />
            {children}
          </IntercomProvider>
        </I18nextProvider>
      </ChakraThemeProvider>
    </PathwrightContextProvider>
  </PathwrightClient>
)

export default StandaloneSpaceSignupFlowWrapper
