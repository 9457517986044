import CardLink from "@pathwright/ui/src/components/card/CardLink"

const PathwrightAdminCardLink = ({ card }) => (
  <CardLink
    icon="file-code"
    label="Pathwright Superuser"
    meta="A Pathwright Staff UI for running administrative tasks."
    to={`${card.path}commands/`}
  />
)

PathwrightAdminCardLink.displayName = "PathwrightAdminCardLink"

export default PathwrightAdminCardLink
