import Alert from "@pathwright/ui/src/components/alert/Alert"
import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import Button from "@pathwright/ui/src/components/button/Button"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import ScrollView from "@pathwright/ui/src/components/scroll/ScrollView"
import { INVITE_METHOD_LINK } from "invitation/constants"
import LicensedSeats from "invitation/invite/components/licensed-seats/LicensedSeats"
import { getLookupPlaceholder } from "invitation/utils"
import { toJS } from "mobx"
import { observer } from "mobx-react"
import inviteStore from "../../store/invite-store"
import InviteLookup from "../lookup/InviteLookup"
import InviteSuccess from "../success/InviteSuccess"
import InviteField from "./InviteField"
import "./InviteForm.css"
import InviteFormError from "./InviteFormError"
import InviteFormFooter from "./InviteFormFooter"

const InviteFormField = observer(({ inviteKey }) => {
  // onChange={email => inviteStore.action.changeInvite(inviteKey, email)}
  // onBlur={() => inviteStore.action.validateInvite(inviteKey)}
  return (
    <InviteField
      invite={inviteStore.invites.get(inviteKey)}
      onRemove={() => inviteStore.action.removeInvite(inviteKey)}
    />
  )
})

const InviteForm = observer((props) => {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
      }}
    >
      <header>
        <LicensedSeats />
        <p className="InviteForm__prompt">
          Add people to invite below, or&nbsp;
          <Button
            brand
            styleType="text"
            onClick={() => inviteStore.action.changeMethod(INVITE_METHOD_LINK)}
          >
            <Pathicon icon="link" /> get a shareable link
          </Button>
          .
        </p>
        <InviteLookup
          hits={props.hits}
          onSearch={props.refine}
          onAdd={inviteStore.action.addInvites}
          placeholder={getLookupPlaceholder(
            inviteStore.type,
            props.searchEnabled
          )}
          loading={inviteStore.adding}
        />
      </header>
      <ScrollView>
        {inviteStore.count > 0 ? (
          <ul>
            {Object.keys(toJS(inviteStore.invites))
              .reverse()
              .map((key) => (
                <InviteFormField inviteKey={key} key={key} />
              ))}
          </ul>
        ) : (
          <BlankSlate
            icons={null}
            body={
              <>
                <b>Tip:</b> to invite a lot of people at once, simply copy and
                paste a comma separated list of emails into the box above.
              </>
            }
          />
        )}
      </ScrollView>
      {inviteStore.errors.length ? (
        <InviteFormError />
      ) : (
        inviteStore.error && (
          <div>
            <Alert
              error={inviteStore.error}
              onClear={inviteStore.action.clearError}
            />
          </div>
        )
      )}
      {inviteStore.count > 0 && <InviteFormFooter />}
    </form>
  )
})

InviteForm.displayName = "InviteForm"

const InviteFormContainer = observer(({ enableSearch, ...rest }) => {
  // The school_membership Algolia index is not in use
  const searchIsEnabled = false // enableSearch && !!get(window.bootstrappedData, "integrations.algolia")

  return (
    <div className="InviteForm">
      {!!inviteStore.numsent ? <InviteSuccess /> : <InviteForm {...rest} />}
    </div>
  )
})

export default InviteFormContainer
