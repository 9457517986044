import { useMutation } from "@apollo/client"
import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import get from "lodash/get"
import React, { useContext, useMemo } from "react"
import {
  hasEditorLevelAccess,
  hasTeacherLevelAccess
} from "../../group/permissions"
import PATH_QUERY from "../../path/graphql/path-query"
import { usePathwrightClient } from "../../pathwright/PathwrightClient"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import COHORT_SYNC_PATH_QUERY from "../graphql/cohort-sync-path-query"
import EXECUTE_PATH_SYNC_PLAN_MUTATION from "../graphql/execute-path-sync-plan-mutation"
import PATH_SYNC_PLAN_QUERY from "../graphql/path-sync-plan-query"
import { SYNC_DIR_BACKWARD, SYNC_DIR_FORWARD } from "./constants"
import { getSyncChangesCount, groupSyncChanges } from "./utils"

// Cannot pass a new client instance to refetchQueries so using
// a hack to "refetch" path queries but with the _consistentReadClient client.
const useRefetchPathQueries = (fromPathId, fromCohortId) => {
  const client = usePathwrightClient()

  /**
   * refetching both possible path queries that could be in cache
   * really don't like having the fromCohortId dependency, but seems to be the only way
   * simply calling refetchQueries: ["PathQuery"] would refetch all cached path queries, so not ideal
   *
   * NOTE: in the case of a back sync this is imperative whenever
   * new path items are back synced as the items in the cohort will now
   * have new ids and source ids! This may be the only case–in which a
   * back sync requires refetching the path–but currently refetching in all cases.
   *
   * NOTE: in the case of a forward sync, it is at least necessary to refetch
   * the path query for those path items that have been published for the first time
   * in order to update the published_dtime on those path items
   */

  const pathQuery = useQuery(PATH_QUERY, {
    variables: {
      id: fromPathId,
      cohort_id: fromCohortId
    },
    skip: true,
    fetchPolicy: "network-only",
    client: client._consistentReadClient
  })

  const cohortPathQuery = useQuery(PATH_QUERY, {
    variables: {
      cohort_id: fromCohortId
    },
    skip: true,
    fetchPolicy: "network-only",
    client: client._consistentReadClient
  })

  const refetch = () =>
    Promise.all([pathQuery.refetch(), cohortPathQuery.refetch()])

  return refetch
}

export const useSyncPlan = ({
  fromPathId,
  toPathId,
  fromCohortId,
  // toCohortId,
  selectedItemSourceIds = {},
  queryOptions = {}
}) => {
  const refetchPathQueries = useRefetchPathQueries(fromPathId, fromCohortId)

  const useSyncPlanQueryMutation = ({
    [SYNC_DIR_FORWARD]: forwardSyncOptions,
    [SYNC_DIR_BACKWARD]: backwardSyncOptions
  }) => {
    // apollo cache sees a differently ordered list of the same values and treats as a different query
    const sortSourceIds = (sourceIds) => sourceIds.sort((a, b) => a - b)

    // base variables for forward sync
    const forwardSyncPlanVariables = {
      from_path_id: fromPathId,
      include_source_ids: sortSourceIds(forwardSyncOptions.includedSourceIds),
      exclude_source_ids: sortSourceIds(forwardSyncOptions.excludedSourceIds)
    }

    // base variables for backward sync
    const backwardSyncPlanVariables = {
      from_path_id: fromPathId,
      to_path_id: toPathId,
      include_source_ids: sortSourceIds(backwardSyncOptions.includedSourceIds),
      exclude_source_ids: sortSourceIds(backwardSyncOptions.excludedSourceIds)
    }

    const forwardSyncPlanQuery = useQuery(PATH_SYNC_PLAN_QUERY, {
      ...queryOptions,
      variables: {
        sync_plan: forwardSyncPlanVariables
      },
      notifyOnNetworkStatusChange: true,
      skip: !fromPathId || !!queryOptions.skip
    })

    const backwardSyncPlanQuery = useQuery(PATH_SYNC_PLAN_QUERY, {
      ...queryOptions,
      variables: {
        sync_plan: backwardSyncPlanVariables
      },
      notifyOnNetworkStatusChange: true,
      skip: !toPathId || !!queryOptions.skip
    })

    const forwardSyncPlanMutation = useMutation(
      EXECUTE_PATH_SYNC_PLAN_MUTATION,
      {
        variables: {
          sync_plan: {
            ...forwardSyncPlanVariables,
            // backend uses the hash to verify nothing has changed between the generation of the sync plan
            // and the execution of it
            hash: get(forwardSyncPlanQuery, "data.pathSyncPlan.hash")
          }
        },
        onCompleted: () => refetchPathQueries()
      }
    )

    const backwardSyncPlanMutation = useMutation(
      EXECUTE_PATH_SYNC_PLAN_MUTATION,
      {
        variables: {
          sync_plan: {
            ...backwardSyncPlanVariables,
            // backend uses the hash to verify nothing has changed between the generation of the sync plan
            // and the execution of it
            hash: get(backwardSyncPlanQuery, "data.pathSyncPlan.hash")
          }
        },
        onCompleted: () => refetchPathQueries()
      }
    )

    return {
      [SYNC_DIR_FORWARD]: {
        query: forwardSyncPlanQuery,
        mutation: forwardSyncPlanMutation
      },
      [SYNC_DIR_BACKWARD]: {
        query: backwardSyncPlanQuery,
        mutation: backwardSyncPlanMutation
      }
    }
  }

  // by default, the base sync plan does not include/exclude any item source IDs
  // based on user request
  const baseSyncOptions = {
    [SYNC_DIR_FORWARD]: {
      includedSourceIds: [],
      excludedSourceIds: []
    },
    [SYNC_DIR_BACKWARD]: {
      includedSourceIds: [],
      excludedSourceIds: []
    }
  }

  // base sync plan with no user requested inclusions/exclustions
  const baseSyncPlans = useSyncPlanQueryMutation(baseSyncOptions)

  // ensuring we only send included/excluded item source IDs when the IDs are not
  // already included/excluded by default
  const getUserRequestedItemSourceIds = (syncDir) => {
    let includedSourceIds = []
    let excludedSourceIds = []

    const query = baseSyncPlans[syncDir].query

    if (query.data) {
      const itemSourceIds = selectedItemSourceIds[syncDir]

      if (itemSourceIds) {
        includedSourceIds = query.data.pathSyncPlan.excluded_changes
          .map((change) => change.item_source_id)
          .filter((itemSourceId) => itemSourceIds.includes(itemSourceId))
        excludedSourceIds = query.data.pathSyncPlan.included_changes
          .map((change) => change.item_source_id)
          .filter((itemSourceId) => !itemSourceIds.includes(itemSourceId))
      }
    }

    return {
      includedSourceIds,
      excludedSourceIds
    }
  }

  // construct the requestedSyncOptions based on the currently included/excluded item source
  // in a way that only includes the item source ID when it would otherwise be excluded and
  // only excludes the item source ID when it would otherwise be included
  const requestedSyncOptions = {
    [SYNC_DIR_FORWARD]: getUserRequestedItemSourceIds(SYNC_DIR_FORWARD),
    [SYNC_DIR_BACKWARD]: getUserRequestedItemSourceIds(SYNC_DIR_BACKWARD)
  }

  const requestedSyncPlans = useSyncPlanQueryMutation(requestedSyncOptions)

  return useMemo(() => {
    let hasSyncableChanges = false
    let forwardSync = null
    let backwardSync = null

    const getWillReorder = (syncPlanIncludedChanges) =>
      !!syncPlanIncludedChanges.find((item) => item.change_details.reorder)

    const getSyncData = ({ query, mutation }) => {
      let syncData = null

      if (query.data) {
        const { included_changes, excluded_changes } = query.data.pathSyncPlan

        syncData = {
          query,
          mutation: {
            mutate: mutation[0],
            ...mutation[1]
          },
          includedChanges: included_changes,
          excludedChanges: excluded_changes,
          groupedChanges: groupSyncChanges([
            ...included_changes,
            ...excluded_changes
          ]),
          willReorder: getWillReorder(included_changes)
        }
      } else if (query.error) {
        syncData = {
          query,
          mutation: null,
          includedChanges: [],
          excludedChanges: [],
          groupedChanges: groupSyncChanges([]),
          willReorder: false
        }
      }

      return syncData
    }

    forwardSync = getSyncData(requestedSyncPlans[SYNC_DIR_FORWARD])
    backwardSync = getSyncData(requestedSyncPlans[SYNC_DIR_BACKWARD])

    const syncPlans = {
      [SYNC_DIR_FORWARD]: forwardSync,
      [SYNC_DIR_BACKWARD]: backwardSync
    }

    // check if any syncable changes exist
    hasSyncableChanges =
      !!getSyncChangesCount(forwardSync) || !!getSyncChangesCount(backwardSync)

    // handles refetching both sync plans
    const refetchSyncPlanQueries = () => {
      const promises = [SYNC_DIR_FORWARD, SYNC_DIR_BACKWARD].reduce(
        (promises, syncDir) => {
          const query = syncPlans[syncDir] ? syncPlans[syncDir].query : null

          if (query) {
            promises.push(query.refetch())
          }

          return promises
        },
        []
      )
      return Promise.all(promises)
    }

    return {
      ...syncPlans,
      hasSyncableChanges,
      refetchSyncPlanQueries
    }
  }, [
    requestedSyncPlans[SYNC_DIR_FORWARD].query,
    requestedSyncPlans[SYNC_DIR_BACKWARD].query,
    ...requestedSyncPlans[SYNC_DIR_FORWARD].mutation,
    ...requestedSyncPlans[SYNC_DIR_BACKWARD].mutation
  ])
}

export const useCohortSyncPlanQuery = ({ cohortId }) => {
  const cohortSyncPlanQuery = useQuery(COHORT_SYNC_PATH_QUERY, {
    variables: {
      cohortId: cohortId
    },
    skip: !cohortId
  })

  const fromCohort = get(cohortSyncPlanQuery, "data.cohort")
  // note that the source cohort should only exist when cohortId is the ID for a non-source cohort
  const toCohort = get(cohortSyncPlanQuery, "data.cohort.source")

  return {
    query: cohortSyncPlanQuery,
    fromCohort,
    toCohort
  }
}

// accepts either cohortId or cohort_id for convenience
export const useCohortSyncPlanContext = ({ cohortId, cohort_id, ...rest }) => {
  const pwContext = usePathwrightContext()

  const { fromCohort, toCohort } = useCohortSyncPlanQuery({
    cohortId: cohortId || cohort_id
  })

  const fromCohortId = get(fromCohort, "id")
  const toCohortId = get(toCohort, "id")

  let fromPathId = null
  let toPathId = null

  if (fromCohort) {
    if (fromCohort.is_master) {
      // only generate forward sync plan if user can edit the fromCohort
      if (hasEditorLevelAccess(pwContext, fromCohort)) {
        fromPathId = fromCohort.path.id
      }
    } else {
      // only generate forward sync plan if user can teach the fromCohort
      if (hasTeacherLevelAccess(pwContext, fromCohort)) {
        fromPathId = fromCohort.path.id
      }
    }
  }

  if (toCohort) {
    // only generate back sync plan if user can edit the toCohort
    if (hasEditorLevelAccess(pwContext, toCohort)) {
      toPathId = toCohort.path.id
    }
  }

  return useSyncPlan({
    fromPathId,
    toPathId,
    // not desireable, but necessary for requering the PATH_QUERY
    fromCohortId,
    toCohortId,
    ...rest
  })
}

export const SyncPlanContext = React.createContext()

export const SyncPlanContextProvider = ({ children, ...syncPlanOptions }) => (
  <SyncPlanContext.Provider value={useSyncPlan(syncPlanOptions)}>
    {children}
  </SyncPlanContext.Provider>
)

export const useSyncPlanContext = () => useContext(SyncPlanContext)

// export const withSyncPlanContext = Component => props => {
//   const syncPlanContext = useSyncPlanContext(props)
//   return <Component {...syncPlanContext} {...props} />
// }

export const withCohortSyncPlanContext = (Component) => (props) => {
  const cohortSyncPlanContext = useCohortSyncPlanContext(props)
  return <Component {...cohortSyncPlanContext} {...props} />
}
