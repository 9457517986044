import { Heading, Text } from "@chakra-ui/react"
import BackgroundImage from "@pathwright/ui/src/components/background/BackgroundImage"
import ResourceBackground from "@pathwright/ui/src/components/background/ResourceBackground"
import OptionsButton from "@pathwright/ui/src/components/button/OptionsButton"
import Link from "@pathwright/ui/src/components/link/Link"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import ProgressBar from "@pathwright/ui/src/components/progress/ProgressBar"
import classnames from "classnames"
import gql from "graphql-tag"
import get from "lodash/get"
import moment from "moment"
import styled from "styled-components"
import usePathAnalytics from "../path/usePathAnalytics"
import { setStoredPathMode } from "../path/utils"
import { getGroupUrl } from "../utils/urls"
import CohortMembers from "./CohortMembers"
import { CohortControls } from "./controls/CohortControls"

const StyledControls = styled.div`
  position: absolute;
  top: 18px;
  right: 18px;

  width: 30px;
  height: 30px;
  border-radius: 50%;
  line-height: 30px;
  /* background-color: rgba(255, 255, 255, 0.1); */
`

export const CohortCardControls = ({ cohort, analytics, zIndex = 1 }) => {
  return (
    <StyledControls
      className="CohortCardControls"
      style={{ zIndex: zIndex + 1 }}
    >
      <CohortControls cohort={cohort} analytics={analytics} showShareControl>
        {({ showIndicator }) => (
          <OptionsButton indicator={showIndicator} inverted />
        )}
      </CohortControls>
    </StyledControls>
  )
}

export const COHORT_FRAGMENT = gql`
  fragment Cohort on Group {
    id
    name
    created
    created_by_id
    status
    is_collection_linked
    is_master
    access_starts
    parent_section_id
    registration {
      id
      role
      send_due_notifications
      send_discussion_notifications
    }
    path(type: source) {
      id
    }
    access_ends
    resource {
      id
      name
      image
      slug
      background_image
      background_overlay
      resource_type
    }
    resource_license {
      id
      expiration_dtime
    }
  }
`

export const COHORTS_QUERY = gql`
  query Cohorts($cursor: String) {
    groups(
      first: 1042
      after: $cursor
      role: { in: [teacher, moderator] }
      include_curriculum: false
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...Cohort
        }
      }
    }
  }
  ${COHORT_FRAGMENT}
`

COHORTS_QUERY._pathToPageInfo = "groups"

const StyledCohortCard = styled(Link)`
  text-decoration: none;
  color: #000;
  position: relative;
  z-index: 1;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  min-height: 140px;
  overflow: hidden;
  backface-visibility: hidden;
  /* padding-left: 10px; */

  &.CohortCard {
    margin: 10px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  }

  &:hover {
    .ResourceBackground {
      transition-duration: 8s;
      transition-timing-function: ease-out;
      transform: scale(1.2);
    }
  }

  .ResourceBackground {
    transform: scale(1.01);
    transition: transform 1s;
    backface-visibility: hidden;

    .BackgroundImage {
      transform: scale(1.01);
    }
  }

  .ProgressBar {
    margin-top: 10px;
  }

  h2 {
    text-shadow: 0px 0px rgba(0, 0, 0, 0.33);
    margin: 0px;
  }

  @media (max-width: 600px) {
    max-width: 100%;
  }

  .CohortMembers {
    margin-top: 10px;
  }
`

const StyledCohortsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  grid-gap: 20px;

  ${StyledCohortCard} {
    margin: 0;
  }
`

const CohortBG = styled.div`
  overflow: hidden;
  border-radius: 20px;

  /* height: 116px; */
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;

  .ResourceBackground.View {
    background: none;
  }
`

export const SimpleCohortCard = ({ className, item: cohort, zIndex = 0 }) => {
  const cohortUrl = getGroupUrl(cohort.resource.slug, cohort.id, "path")

  return (
    <StyledCohortCardWrapper style={{ zIndex }}>
      <StyledCohortCard
        className={classnames("SimpleCohortCard", className)}
        href={cohortUrl.pathname}
        to={cohortUrl}
        style={{ zIndex }}
      >
        <CohortBG className="CohortCard__background">
          <ResourceBackground resource={cohort.resource}>
            <BackgroundImage overlay={`rgba(0,0,0, .5)`} />
          </ResourceBackground>
        </CohortBG>
        <div style={{ position: "relative", padding: 10 }}>
          <header>
            <Heading
              as="h1"
              title={cohort.name}
              className="CohortCard__cohort-title theme-heading theme-heading-bold"
              fontSize="24px"
              lineHeight="28px"
              color="white"
              margin="0px"
              maxWidth="calc(100% - 20px)"
              text-align="left"
              textShadow="0px 0px 10px rgba(0, 0, 0, 0.33)"
              noOfLines={2}
            >
              {cohort.name}
            </Heading>
          </header>
          <CohortMembers cohortId={cohort.id} inverted />
        </div>
      </StyledCohortCard>
    </StyledCohortCardWrapper>
  )
}

SimpleCohortCard.displayName = "SimpleCohortCard"

const StyledCohortCardWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 393px;
  transition: transform 0.3s;
  backface-visibility: hidden;
  transform: scale(1);
  /* 
  .CohortCardControls {
    opacity: 0;
  } */

  &:hover {
    transform: scale(${(p) => (p.expired ? 1 : 1.01)});
    .CohortCardControls {
      opacity: 1;
    }
  }

  /* max-width: 300px; */

  /* @media (max-width: 740px) {
    width: 100%;
    max-width: 400;
  } */
`

export const getCohortTitles = (cohort) => {
  const resource = cohort.resource
  let title = resource.name
  let subTitle = cohort.name !== resource.name ? cohort.name : null

  // reverse it for collections
  const isCollectionCohort = cohort.is_collection_linked

  // console.log({ isCollection })

  if (isCollectionCohort) {
    title = resource.name
    subTitle = cohort.name
  }

  return { title, subTitle }
}

const StyledCohortStatusContainer = styled.div`
  display: flex;
  align-items: center;
  color: white;
  text-align: left;
  margin-top: 10px;

  i {
    margin-right: 4px;
  }
`

const getCohortIsExpired = (cohort) =>
  !!cohort.resource_license &&
  !!cohort.resource_license.expiration_dtime &&
  moment().isAfter(cohort.resource_license.expiration_dtime)

const CohortStatus = ({ cohort }) => {
  const { t } = useTranslate()

  if (getCohortIsExpired(cohort)) {
    return (
      <StyledCohortStatusContainer>
        <Pathicon icon="clock" />
        {t(`registration.status.access_expired`)}
      </StyledCohortStatusContainer>
    )
  }

  return null
}

export const CohortCard = (props) => {
  const { className, item: cohort, index, itemCount } = props
  const zIndex = itemCount - index
  const analytics = usePathAnalytics(cohort)
  const cohortUrl = getGroupUrl(cohort.resource.slug, cohort.id, "path")
  const expired = getCohortIsExpired(cohort)

  const { title, subTitle } = getCohortTitles(cohort)

  const actionProps = expired
    ? {}
    : {
        href: cohortUrl,
        to: cohortUrl,
        onClick: () => setStoredPathMode(10)
      }

  return (
    <StyledCohortCardWrapper
      className="CohortCard__wrapper"
      style={{ zIndex }}
      expired={expired}
    >
      {!expired && (
        <CohortCardControls
          cohort={cohort}
          analytics={analytics}
          zIndex={zIndex}
        />
      )}
      <StyledCohortCard
        className={classnames("CohortCard", className)}
        style={{ zIndex }}
        {...actionProps}
      >
        <CohortBG className="CohortCard__background">
          <ResourceBackground resource={cohort.resource}>
            <BackgroundImage overlay={`rgba(0,0,0, .5)`} />
          </ResourceBackground>
        </CohortBG>

        <div style={{ position: "relative", padding: 10 }}>
          {/* <div>
            <Cover src={cohort.resource.image} />
          </div> */}
          <header>
            <Heading
              as="h1"
              title={title}
              className="CohortCard__cohort-title theme-heading theme-heading-bold"
              color="white"
              fontSize="24px"
              m="0 0 2px 0"
              maxWidth="calc(100% - 20px)"
              textAlign="left"
              textShadow="0px 0px 10px rgba(0, 0, 0, 0.33)"
              noOfLines={2}
            >
              {title}
            </Heading>
            {subTitle ? (
              <Text
                as="h3"
                className="CohortCard__resource-title"
                color="white"
                fontSize="12px"
                textTransform="uppercase"
                margin="0px"
                fontWeight="normal"
                textShadow="0px 0px 10px rgba(0, 0, 0, 0.33)"
                maxWidth="calc(100% - 30px)"
                textAlign="left"
                noOfLines={1}
              >
                {subTitle}
              </Text>
            ) : null}
          </header>
          <CohortMembers cohortId={cohort.id} analytics={analytics} inverted />
          {get(analytics, "path.completion_rate") > 0 ? (
            <ProgressBar
              progress={get(analytics, "path.completion_rate")}
              fillColor={"rgba(255,255,255, .8)"}
              backColor={"rgba(0,0,0,.33)"}
            />
          ) : null}
          <CohortStatus cohort={cohort} />
        </div>
      </StyledCohortCard>
    </StyledCohortCardWrapper>
  )
}

CohortCard.displayName = "CohortCard"

CohortCard.defaultProps = {
  index: 0,
  itemCount: 1
}

export const CohortsGrid = (props) => {
  return <StyledCohortsContainer {...props} />
}
