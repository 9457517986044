import partial from "lodash/partial"
import { redirect } from "react-router-dom"
import { useAsyncFn } from "react-use"
import { z } from "zod"
import { zfd } from "zod-form-data"
import { getClient } from "../../../../../api/client"
import {
  ConfirmAuthRequestDocument,
  ConfirmAuthRequestMutation,
  ConfirmAuthRequestMutationVariables
} from "../../../../../api/generated"
import { handleAuthToken } from "../../../../../lib/utils/auth-token"
import getAuthRoute from "../../../utils/getAuthRoute"
import { setPreferredAuthMethod } from "../../../utils/preferredAuthMethod"

const schemaFields = {
  authRequestId: z.string().uuid(),
  authRequestKey: z.string().length(6)
}

export const schema = z.object(schemaFields)

export const formDataSchema = zfd
  .formData({
    authRequestId: zfd.text(z.string().uuid()),
    authRequestKey: zfd.text(z.string().length(6))
  })
  .transform((val) => ({
    uuid: val.authRequestId,
    key: val.authRequestKey
  }))

export const confirmAuthRequest = async (formData: FormData) => {
  const client = getClient()
  const { data } = await client.mutate<
    ConfirmAuthRequestMutation,
    ConfirmAuthRequestMutationVariables
  >({
    mutation: ConfirmAuthRequestDocument,
    variables: formDataSchema.parse(formData)
  })

  const token = data?.confirmAuthRequest?.issued_auth_token?.token
  const nextUrl = data?.confirmAuthRequest?.context?.next_url

  if (!token) {
    throw new Error("Failed to confirm authentication request.")
  }

  await handleAuthToken(token)
  setPreferredAuthMethod("request")

  return { success: true, nextUrl: nextUrl }
}

export const useConfirmAuthRequest = partial(
  useAsyncFn<typeof confirmAuthRequest>,
  confirmAuthRequest
)

const confirmAuthRequestAction = async (formData: FormData) => {
  const result = await confirmAuthRequest(formData)
  if (result.nextUrl) {
    return redirect(result.nextUrl)
  }
  return redirect(getAuthRoute("/"))
}

export default confirmAuthRequestAction
