export * from "../../../auth-request/api/loader"
import partial from "lodash/partial"
import { LoaderFunctionArgs } from "react-router-dom"
import { useAsync } from "react-use"
import { z } from "zod"
import { default as authRequestLoader } from "../../../auth-request/api/loader"
import { getAuthEmailFormStateEmail } from "../../../utils/authEmailFormStateStorage"
import { setPreferredAuthMethod } from "../../../utils/preferredAuthMethod"
import { getUserStatus } from "../../api/action"

export type SignInAuthRequestLoaderReturn = {
  showPasswordOption: boolean
}

export const getShowPasswordOption =
  async (): Promise<SignInAuthRequestLoaderReturn> => {
    let showPasswordOption: boolean = true

    const storedEmail = getAuthEmailFormStateEmail()
    const emailSchema = z.string().email()
    if (storedEmail && emailSchema.safeParse(storedEmail).success) {
      // We'll simply allow user signup here since the only thing we're interested in
      // is whether the user has a password set.
      const userStatus = await getUserStatus({
        input: storedEmail,
        allowSignup: true
      })
      showPasswordOption = !userStatus?.no_password_set
    }

    return {
      showPasswordOption
    }
  }

const signInAuthRequestLoader = async (
  loaderFnArgs: LoaderFunctionArgs
): Promise<SignInAuthRequestLoaderReturn> => {
  // Clearing the preferred auth method allows user to route backwards to /auth/sign-in route.
  setPreferredAuthMethod()
  await authRequestLoader(loaderFnArgs)
  return getShowPasswordOption()
}

export const useShowPasswordOption = partial(
  useAsync<typeof getShowPasswordOption>,
  getShowPasswordOption
)

export default signInAuthRequestLoader
