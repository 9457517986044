import { Component } from "react"
import ReactDOM from "react-dom"
import { Motion, spring } from "react-motion"

const lerp = (start, diff, progress) => {
  return start + Math.max(0, Math.min(1, progress)) * diff
}

const transition = {
  stiffness: 250,
  damping: 20
}

const LaunchAnimator = ({ rect, onDone, PreviewComponent }) => {
  const target = {
    top: 0,
    left: 0,
    width: window.innerWidth,
    height: window.innerHeight
  }

  return (
    <Motion
      defaultStyle={{ x: 0 }}
      style={{ x: spring(1, transition) }}
      onRest={onDone}
    >
      {({ x }) => {
        return (
          <div
            className="LaunchAnimator"
            children={PreviewComponent && <PreviewComponent />}
            style={{
              zIndex: 999999999,
              position: "fixed",
              top: lerp(rect.top, target.top - rect.top, x),
              left: lerp(rect.left, target.left - rect.left, x),
              width: lerp(rect.width, target.width - rect.width, x),
              height: lerp(rect.height, target.height - rect.height, x),
              borderRadius: lerp(20, -20, x),
              backgroundColor: !PreviewComponent && "whitesmoke",
              transform: "translateZ(0)",
              overflow: "hidden"
            }}
          />
        )
      }}
    </Motion>
  )
}

const withLauncher = (options) => {
  // Allowing for options to be a component or set of components.
  const {
    Component: ComposedComponent,
    Preview: PreviewComponent,
    Preload: PreloadComponent
  } = options.Component
    ? options
    : {
        Component: options
      }

  return class Launcher extends Component {
    defaultState = {
      show: false,
      rect: null,
      to: null
    }

    state = { ...this.defaultState }

    componentDidMount() {
      this.mounted = true
    }

    componentWillUnmount() {
      this.mounted = false
    }

    handleLaunch = async (to) => {
      const rect = ReactDOM.findDOMNode(this).getBoundingClientRect()

      if (PreloadComponent && typeof PreloadComponent.preload === "function") {
        await PreloadComponent.preload()
      }

      this.setState({
        to,
        rect,
        show: true
      })
    }

    handleDone = (to) => {
      // FIXME: better way to do this

      if (window.App) window.App.navigate(to)
      if (typeof this.props.onLaunched === "function") this.props.onLaunched(to)
      if (this.mounted) this.setState(this.defaultState)
    }

    render() {
      return (
        <>
          <ComposedComponent {...this.props} onLaunch={this.handleLaunch} />
          {this.state.show
            ? ReactDOM.createPortal(
                <LaunchAnimator
                  rect={this.state.rect}
                  PreviewComponent={PreviewComponent}
                  onDone={() => this.handleDone(this.state.to)}
                />,
                document.body
              )
            : null}
        </>
      )
    }
  }
}

export default withLauncher
