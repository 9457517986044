import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import DatePicker from "@pathwright/ui/src/components/form/form-date-picker/DatePicker"
import RadioButton from "@pathwright/ui/src/components/form/form-radio/RadioButton"
import RadioGroup from "@pathwright/ui/src/components/form/form-radio/RadioGroup"
import TextInput from "@pathwright/ui/src/components/form/form-text-input/TextInput"
import ToggleInput from "@pathwright/ui/src/components/form/form-toggle/ToggleInput"
import {
  getFormError,
  validate as validateFn
} from "@pathwright/ui/src/components/form/utils"
import useCohortPermissions from "@pathwright/web/src/modules/cohort/useCohortPermissions"
import { Formik } from "formik"
import { validate } from "lib/core/components/forms"
import styled from "styled-components"
import { findErrorMessage } from "../../../../lib/core/components/forms/form-utils/validate"
import { cohortEndsWhenLicenseExpires } from "../GroupUtils"

const StyledForm = styled.form`
  padding: 25px;
  padding-top: 0;

  .DatePicker__input input {
    padding: 5px 30px !important;
  }
`

const labelWidth = 200

const GroupAccessForm = function ({ group, initialValues, onSubmit }) {
  const { hasEditorLevelAccess } = useCohortPermissions({
    cohortId: group ? group.id : null
  })

  // lock expiration if group expires same time that resource license expires (if licensed)
  let lockExpiration = false

  // if user can edit resource then they have permission to update group access ends date
  if (!hasEditorLevelAccess) {
    lockExpiration = cohortEndsWhenLicenseExpires(group)
  }

  if (!group) return null

  return (
    <Formik
      initialValues={initialValues}
      validate={validateFn((key, value, values) => {
        const validatorMap = {
          seats:
            values.seats_limit == 1
              ? [validate.isGreaterThanOrEqualTo(1)]
              : null,
          enrollment_starts: values.enrollment_starts
            ? [
                validate.isValidDate,
                validate.dateIsBefore(values.enrollment_ends)
              ]
            : null,
          enrollment_ends: values.enrollment_ends
            ? [
                validate.isValidDate,
                validate.dateIsAfter(values.enrollment_starts)
              ]
            : null,
          access_starts: values.access_starts
            ? [validate.isValidDate, validate.dateIsBefore(values.access_ends)]
            : null,
          access_ends: values.access_ends
            ? [validate.isValidDate, validate.dateIsAfter(values.access_starts)]
            : null
        }
        const validators = validatorMap[key]
        return validators
          ? findErrorMessage(...validators)(values, value)
          : null
      })}
    >
      {(form) => {
        const handleSeatsLimit = function (e) {
          if (e.target.checked) {
            const value = e.target.value
            // NOTE: first setting "seats" value as "seats" validation
            // depends on "seats_limit".
            if (value == 0) form.setFieldValue("seats", null)
            form.setFieldValue("seats_limit", value)
          }
        }

        return (
          <StyledForm onSubmit={(e) => e.preventDefault()}>
            <SubmitButton
              disabled={!form.isValid}
              styleType="primary"
              type="submit"
              onClick={() => onSubmit(form.values)}
            >
              Save
            </SubmitButton>
            <RadioGroup
              name="seats_limit"
              label="Seat limit"
              labelWidth={labelWidth}
              value={form.values.seats_limit}
            >
              <RadioButton
                value={0}
                checked={form.values.seats_limit == 0}
                onChange={handleSeatsLimit}
              >
                Unlimited.
              </RadioButton>
              <RadioButton
                value={1}
                checked={form.values.seats_limit == 1}
                onChange={handleSeatsLimit}
              >
                <span>Close registration after </span>
                <TextInput
                  name="seats"
                  type="number"
                  required={form.values.seats_limit == 1}
                  minWidth={30}
                  autoSize
                  inline
                  value={form.values.seats}
                  onChange={(value, e) => form.handleChange(e)}
                  onBlur={(value, e) => form.handleBlur(e)}
                  errors={getFormError(form, "seats")}
                  onFocus={() => {
                    form.setFieldValue("seats_limit", 1)
                  }}
                />
                <span> join</span>
              </RadioButton>
            </RadioGroup>
            <ToggleInput
              name="enrollment_window"
              label="Limit registration window"
              labelWidth={labelWidth}
              value={form.values.enrollment_window}
              onChange={(value, e) => form.handleChange(e)}
            />
            {!!form.values.enrollment_window && (
              <DatePicker
                name="enrollment_starts"
                label="Registration opens"
                placeholder="Anytime"
                labelWidth={labelWidth}
                optional
                value={form.values.enrollment_starts}
                onChange={(value) =>
                  form.setFieldValue("enrollment_starts", value)
                }
                errors={getFormError(
                  form,
                  "enrollment_starts",
                  false /* mustBeTouched */
                )}
              />
            )}
            {!!form.values.enrollment_window && (
              <DatePicker
                name="enrollment_ends"
                label="Registration closes"
                placeholder="Never closes"
                labelWidth={labelWidth}
                optional
                value={form.values.enrollment_ends}
                onChange={(value) =>
                  form.setFieldValue("enrollment_ends", value)
                }
                errors={getFormError(
                  form,
                  "enrollment_ends",
                  false /* mustBeTouched */
                )}
              />
            )}
            <ToggleInput
              name="access_window"
              label="Limit learner access"
              labelWidth={labelWidth}
              value={form.values.access_window}
              onChange={(value, e) => form.handleChange(e)}
            />
            {!!form.values.access_window && (
              <DatePicker
                name="access_starts"
                label="Access opens"
                placeholder="Anytime"
                labelWidth={labelWidth}
                optional
                value={form.values.access_starts}
                onChange={(value) => form.setFieldValue("access_starts", value)}
                errors={getFormError(
                  form,
                  "access_starts",
                  false /* mustBeTouched */
                )}
              />
            )}
            {!!form.values.access_window && (
              <DatePicker
                name="access_ends"
                disabled={lockExpiration}
                label="Access closes"
                placeholder="Never closes"
                labelWidth={labelWidth}
                optional
                value={form.values.access_ends}
                onChange={(value) => form.setFieldValue("access_ends", value)}
                errors={getFormError(
                  form,
                  "access_ends",
                  false /* mustBeTouched */
                )}
              />
            )}
          </StyledForm>
        )
      }}
    </Formik>
  )
}

GroupAccessForm.displayName = "GroupAccessForm"

export default GroupAccessForm
