export default {
  type: "Markdown",
  icon: "markdown",
  helpLink:
    "https://help.pathwright.com/create-a-course/adding-content-with-blocks/markdown-block-new",
  menu: {
    category: "text",
    order: 3
  },
  layouts: {
    markdown: {
      key: "text",
      label: "Markdown",
      data: {
        markdown: `# Hello World`,
        html: "<h1># Hello World</h1>"
      }
    }
  },
  schema: `type Markdown implements Block {
    id: ID!
    markdown: String!
    html: String!
  }`
}
