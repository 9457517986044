import StoreOrLibraryBrowse from "store/StoreOrLibraryBrowse"
import { getStoreUrl } from "store/utils"
import { createCardRoutes } from "../lib/core/routing/card-routing"
import Support from "./Support"

const onEnterSupport = (nextState) => {
  const mode = nextState.location.query.mode
  if (mode) {
    window.App.getStore("support").action.setMode(mode)
  }
}

const onLeaveSupport = () => {
  window.App.getStore("support").action.setMode(null)
}

export default createCardRoutes(
  "/",
  {
    help: {
      component: Support,
      getDefaultLaunchedFrom: () => getStoreUrl(),
      middleware: onEnterSupport,
      leaveMiddleware: onLeaveSupport
    }
  },
  StoreOrLibraryBrowse
)
