import { getIsLocalHost } from "@pathwright/ui/src/components/utils/env"
import {
  constructUrl,
  getQueryObject,
  getRelativeUrl,
  getSchoolUrl
} from "@pathwright/web/src/modules/utils/urls"
const path = require("path-browserify")

export const getLocalConfigURL = function () {
  let configUrl =
    getQueryObject(window.location.search).school ||
    localStorage.getItem("school_config_url")

  if (!configUrl) {
    confirm(
      `Could not load Pathwright client. Set the school URL in the querystring: ${window.location.origin}?school=connect.pathwrightstaging.com`
    )
  }

  const protocol = /\.pathwrightdev\.com/.test(configUrl) ? "http" : "https"

  return configUrl.replace(/(https*:\/\/|^)/, `${protocol}://`)
}

export const getSchoolConfigURL = function () {
  if (getIsLocalHost()) {
    return getLocalConfigURL()
  } else {
    return getSchoolUrl()
  }
}

export const createSchoolUrl = getSchoolUrl

// optionally has initial forwardslash
const PRIVATE_API_RE = /(^(?!\/)|\/)api\/private\//
const LEGACY_API_RE = /(^(?!\/)|\/)api\/v1\//

export const getAPIUrl = function (relativeURL) {
  if (!PRIVATE_API_RE.test(relativeURL) && !LEGACY_API_RE.test(relativeURL)) {
    relativeURL = constructUrl("/api/private/", relativeURL)
  }
  return createSchoolUrl(getRelativeUrl(relativeURL))
}

export const encodeURLParams = function (obj) {
  let params = []
  for (let k in obj) {
    const v = obj[k]
    params.push(`${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
  }
  return (params = params.join("&"))
}

export const hasURLParameter = (param) => {
  const params = new URLSearchParams(window.location.search)
  return params.has(param)
}

export const getURLParameter = (param) => {
  const params = new URLSearchParams(window.location.search)
  return params.get(param)
}
