import { Box, BoxProps, DarkMode } from "@chakra-ui/react"
import classNames from "classnames"

function BGMode(props: BoxProps) {
  return (
    <DarkMode>
      <Box
        {...props}
        className={classNames("chakra-ui-reset-css", props.className)}
        sx={{
          ...props.sx,
          // NOTE: using classnames instead of tag names since, at least with "p",
          // when using tag names it seems to affect other styling.
          [`[data-theme="dark"].chakra-heading,
          [data-theme="dark"].chakra-text,
          [data-theme="dark"].chakra-form__label,
          [data-theme="dark"].chakra-form__error-message,
          [data-theme="dark"].chakra-input__right-addon,
          [data-theme="dark"].chakra-checkbox__label,
          [data-theme="dark"].chakra-icon`]: {
            color: "white"
          },
          [`[data-theme="dark"].chakra-input`]: {
            bg: "white",
            _placeholder: { color: "gray.500" }
          },
          [`button[data-theme="dark"].chakra-button`]: {
            _disabled: {
              color: "whiteAlpha.500",
              opacity: 0.6,
              cursor: "not-allowed"
            }
          },
          [`[data-theme="dark"].chakra-spinner`]: {
            borderTopColor: "white",
            borderRightColor: "white"
          }
        }}
      />
    </DarkMode>
  )
}

export default BGMode
