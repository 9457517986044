import {
  Box,
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text
} from "@chakra-ui/react"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import React, { useEffect } from "react"

const renderOption = (node) => node

const ShareDropdown = ({ name, value, label, options, variant, onChange }) => {
  const [currentItem, setCurrentItem] = React.useState(value || options[0])

  // Allow for currentItem to be controlled.
  useEffect(() => {
    if (typeof value !== "undefined" && value !== currentItem) {
      setCurrentItem(value)
    }
  }, [value])

  const handleChange = (item) => {
    setCurrentItem(item)
    onChange(item)
  }

  return (
    // Ugh, wrapping with Box fixes: https://github.com/chakra-ui/chakra-ui/issues/3440#issuecomment-851707911
    <Box>
      <Menu name={name} placement="bottom-end">
        <MenuButton
          as={Button}
          size="sm"
          colorScheme="gray"
          variant="ghost"
          flexShrink={0}
        >
          <HStack spacing={1} mx={2}>
            <Text size="xs">{label || currentItem.label}</Text>
            <Pathicon
              icon="chevronDown"
              style={{ width: "20px", height: "20px" }}
            />
          </HStack>
        </MenuButton>
        <MenuList
          border="solid 1px"
          borderColor="gray.100"
          borderRadius="4px"
          outline="none"
          boxShadow="none"
          overflow="hidden"
          p={0}
          minW={0}
        >
          {options.map((menuItem, index) => (
            <MenuItem
              key={index}
              _hover={{
                backgroundColor:
                  currentItem !== menuItem ? "gray.50" : "gray.100"
              }}
              _focus={{
                backgroundColor:
                  currentItem !== menuItem ? "gray.50" : "gray.100"
              }}
              onClick={() => handleChange(menuItem)}
              backgroundColor={
                currentItem === menuItem ? "gray.100" : "transparent"
              }
              border="none"
              px={4}
              py={2}
            >
              {(menuItem.render || renderOption)(
                <Text
                  size="xs"
                  color={currentItem === menuItem ? "gray.900" : "gray.500"}
                  m={0}
                >
                  {menuItem.label}
                </Text>
              )}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  )
}

export default ShareDropdown
