import Card from "@pathwright/ui/src/components/card/Card"
import CardLink from "@pathwright/ui/src/components/card/CardLink"
import withTranslate from "@pathwright/ui/src/components/lng/withTranslate"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import { useCurrencyFormatter } from "@pathwright/web/src/modules/currency/CurrencyFormatterProvider"
import PathwrightAccountPermissions from "lib/core/components/pathwright/PathwrightAccountPermissions"
import { ConnectStores } from "lib/core/store"
import GroupDetailsForm from "./GroupDetailsForm"

const getLinkData = (group, baseURL, currencyFormatter, t) => ({
  features: {
    icon: "bullet-list",
    label: t("Edit Cohort Description"),
    meta: t("Describe any unique benefits in this Cohort."),
    to: `${baseURL}features/`
  },
  access: {
    icon: "calendar",
    label: t("Configure availability"),
    meta: t("Set registration windows, seat, and access limits."),
    to: `${baseURL}access/`
  },
  communitySettings: {
    icon: "group",
    label: t("Configure community settings"),
    meta: t("Enable or disable Community interactions"),
    to: `${baseURL}community/`
  },
  commerce: {
    icon: "tag-dollar-sign",
    label: t("Configure Commerce"),
    meta: t("Set a price to join or add to a Subscription plan."),
    to: `${baseURL}commerce/`
  },
  archive: {
    icon: "trash",
    label: t("Delete Cohort"),
    meta: t("Permanently delete this Cohort."),
    to: `${baseURL}archive/`,
    dangerous: true
  }
})

const GroupDetailsCard = function (props) {
  const { card, baseURL, cardProps, canDelete, group, resourceId, t } = props
  const { currencyFormatter } = useCurrencyFormatter()

  const linksData = getLinkData(group, baseURL, currencyFormatter, t)

  return (
    <Card {...Object.assign({}, cardProps, { card: card })}>
      {group ? (
        <>
          <GroupDetailsForm
            onPristine={() => card.setCardDirty(false)}
            onDirty={() => card.setCardDirty(true)}
          />
          <CardLink {...linksData.features} />
          <CardLink {...linksData.access} />
          <CardLink {...linksData.communitySettings} />
          <PathwrightAccountPermissions offering={group}>
            {({ can_edit_offering_commerce }) => {
              return (
                can_edit_offering_commerce && (
                  <CardLink
                    disabled={!!group.parent_offering_id}
                    tooltip={
                      !!group.parent_offering_id
                        ? "Currently unavailable for collection cohorts"
                        : null
                    }
                    {...linksData.commerce}
                  />
                )
              )
            }}
          </PathwrightAccountPermissions>
          {canDelete ? <CardLink {...linksData.archive} /> : undefined}
        </>
      ) : (
        <LoadingCircle center />
      )}
    </Card>
  )
}

GroupDetailsCard.displayName = "GroupDetailsCard"

const mapStateToProps = function (state, ownProps) {
  const { group, is_loading_group, is_saving_group, group_success } =
    state.groupAdminStore.state
  const { url: baseURL } = state.navigationStore.state

  const cardProps = {
    title: group ? group.name : "",
    showStatus: is_loading_group || is_saving_group,
    showLeaveStatus: group_success != null,
    statusMessage: (() => {
      if (is_loading_group) {
        return "Loading..."
      } else if (is_saving_group) {
        return "Saving..."
      }
    })(),
    statusLeaveMessage: group_success ? "Saved" : undefined
  }

  const canDelete = !!group && !!group.id && !group.is_curriculum

  return _.assign(
    {},
    {
      group,
      baseURL,
      cardProps,
      canDelete
    },
    ownProps
  )
}

const connectStores = [
  { store: "navigation", watch: ["url"] },
  {
    store: "groupAdmin",
    watch: ["is_loading_group", "is_saving_group", "group"]
  }
]

export default withTranslate(
  ConnectStores(GroupDetailsCard, connectStores, {}, mapStateToProps)
)
