import { useQuery } from "@apollo/client"
import { createContext, useContext } from "react"
// Note: not importing from "@pathwright/media-client" due to circular dep.
// import { MUX_VIDEO_EMBED_QUERY } from "@pathwright/media-client"
import { MUX_VIDEO_EMBED_QUERY } from "./mux-graphql"

const MuxEmbedContext = createContext<any>({})
export const useMuxEmbed = () => useContext(MuxEmbedContext)

const MuxGraphQLEmbedProvider = ({ children, ...props }) => {
  const { data, loading, error, refetch } = useQuery(MUX_VIDEO_EMBED_QUERY, {
    variables: { id: props.id },
    fetchPolicy: "cache-and-network",
    skip: !props.id
  })

  const passValue = {
    ...props,
    embed: data?.muxVideoEmbed,
    loading,
    error,
    refetch
  }

  return (
    <MuxEmbedContext.Provider value={passValue}>
      {children}
    </MuxEmbedContext.Provider>
  )
}

const MuxEmbedProvider = ({ children, ...props }) => {
  if (props.isLocal) {
    console.log(
      `Cannot render Mux videos without a server connection. Video id: ${props.id}`
    )
    return null
  }

  return (
    <MuxGraphQLEmbedProvider {...props}>{children}</MuxGraphQLEmbedProvider>
  )
}

export default MuxEmbedProvider
