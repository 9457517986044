import gql from "graphql-tag"
import USER_FRAGMENT from "../../../user/graphql/user-fragment"
import RESOURCE_FRAGMENT from "../../graphql/resource-fragment"

export default gql`
  query ResourceEditorQuery($id: Int, $cursor: String) {
    resource(id: $id) {
      ...Resource
      registration_meta {
        editor_registration_count
      }
      group(type: curriculum) {
        id
        registrations(first: 10, role_filter: { eq: editor }, after: $cursor) {
          total
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              role
              user {
                ...User
              }
            }
          }
        }
      }
    }
  }
  ${RESOURCE_FRAGMENT}
  ${USER_FRAGMENT}
`
