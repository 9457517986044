import * as UpChunk from "@mux/upchunk"
import { useCreateVideo } from "@pathwright/media-client"

import { withCheckAndUpdateStatus } from "@pathwright/media-client"
import { humanFileSize } from "@pathwright/ui/src/components/attachment/utils"
import Button from "@pathwright/ui/src/components/button/Button"
import IconButton from "@pathwright/ui/src/components/button/IconButton"
import AnimatedDots from "@pathwright/ui/src/components/loading/AnimatedDots"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import ProgressCircle from "@pathwright/ui/src/components/progress/ProgressCircle"
import Text from "@pathwright/ui/src/components/ui/Text"
import View from "@pathwright/ui/src/components/ui/View"
import React from "react"
import styled from "styled-components"

// Equivalent to 1.5GB
const VIDEO_UPLOAD_MAX_IN_BYTES = 1.5 * 1000 * 1000 * 1024
const VIDEO_UPLOAD_MAX_LABEL = `${humanFileSize(
  VIDEO_UPLOAD_MAX_IN_BYTES,
  true
)}`

const RenderVideoStatus = (props) => {
  const { checkInterval } = props

  const [statusInterval, setStatusInterval] = React.useState(null)

  // Used to poll the status of an asset
  React.useEffect(() => {
    setStatusInterval(setTimeout(checkStatus, checkInterval))

    return () => {
      clearInterval(statusInterval)
    }
  }, [])

  const checkStatus = () => {
    // TODO: call the status mutation
    console.log("checkStatus: ", props)
  }

  return (
    <View>
      <Text>Doing work...</Text>
    </View>
  )
}

export const VideoStatus = withCheckAndUpdateStatus(RenderVideoStatus)
VideoStatus.defaultProps = { checkInterval: 1500 }

const black = "#1a1a1a"

const VideoUploadWrapper = styled(View)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  position: relative;
  color: ${black};
  background-color: #fff;
  ${(p) =>
    p.fullSize
      ? `position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10000000000;
  bottom: 0;`
      : null}
`

const UploadView = styled(View)`
  padding: 4rem 3rem;
  background: #efefef;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const CancelButtonContainer = styled.div`
  position: absolute;
  bottom: 2rem;
`

const VideoUploadingWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 2rem 0.5rem 1rem;
  min-width: 250px;
  max-width: 90%;
  width: 100%;
  background: #fff;
  border-radius: 10px;

  .ProgressCircle {
    padding: 1px;
  }

  .ProgressCircle__inner {
    background-color: #efefef;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const initState = ({ video }) => ({
  status: "pending",
  progress: 0,
  error: null,
  warning: "",
  video: video || null,
  filename: "New Video",
  fileData: null
})

const reducer = (state, { type, ...payload }) => ({ ...state, ...payload })

const MuxVideoUploader = (props) => {
  const { onVideoCreated, onUpload, onCancelUpload, fullSize } = props

  const [state, setState] = React.useReducer(reducer, props, initState)

  const inputRef = React.useRef(null)
  const createVideo = useCreateVideo()

  React.useEffect(() => {
    const notifyOfUpload = async () => {
      await onUpload(state.video, state.fileData)
    }

    if (state.progress === 1 && state.video && state.fileData) {
      notifyOfUpload()
    }
  }, [state.progress, state.fileData])

  const createUploadMedia = async (file) => {
    const createResult = await createVideo({
      variables: {
        name: file.filename
      }
    })

    const video = createResult.data.createMuxVideo
    setState({ video })
    if (onVideoCreated) onVideoCreated(video)

    return video
  }

  const handleSelectFile = async (e) => {
    const file = e.target.files[0]

    if (!file) return new Error("No file data")

    if (!file.type.match("video")) {
      return setState({ warning: "Please upload a valid video file." })
    }

    if (file.size > VIDEO_UPLOAD_MAX_IN_BYTES) {
      return setState({
        warning: `Please upload a video file that is smaller than ${VIDEO_UPLOAD_MAX_LABEL}`
      })
    }

    const fileData = {
      filename: file.name,
      lastModified: file.lastModified,
      lastModifiedDate: file.lastModifiedDate,
      size: file.size,
      type: file.type
    }

    setState({ fileData, filename: file.name })

    const video = await createUploadMedia(fileData)

    const upload = UpChunk.createUpload({
      endpoint: video.upload.url,
      file,
      chunkSize: 1024
    })
    upload.on("error", (error) => setState({ error }))
    upload.on("progress", (progress) => {
      setState({ progress: progress.detail / 100 })
    })
    upload.on("success", () => {
      setState({ progress: 1, status: "finished" })
    })

    setState({ status: "started" })
  }

  const handleClickChooseFile = (event) => {
    // event.preventDefault()

    if (!inputRef.current) return console.warn("No file picker input available")

    inputRef.current.click()
  }

  const renderVideoStatus = () => {
    const { status } = state

    if (status === "error") {
      return (
        <View>
          <Text style={{ color: "red" }}>Error: {state.error}</Text>
        </View>
      )
    }

    // Upload screen
    if (status === "pending") {
      return (
        <UploadView>
          <Text.H2 style={{ color: black, marginBottom: "1.5rem" }}>
            Select a video file
          </Text.H2>
          <Text
            style={{
              color: "rgba(0,0,0,0.3)",
              fontSize: "0.9em",
              marginBottom: "1.5rem"
            }}
          >
            Maximum file size {VIDEO_UPLOAD_MAX_LABEL}
          </Text>
          <Button
            onClick={handleClickChooseFile}
            styleType="primary"
            style={{
              padding: "1rem 2rem",
              backgroundColor: black,
              color: "#fff"
            }}
          >
            Choose File
          </Button>
          {state.warning ? (
            <Text
              style={{
                margin: "2rem 0 -2rem",
                maxWidth: "250px",
                textAlign: "center",
                color: "#d0011b"
              }}
            >
              {state.warning}
            </Text>
          ) : null}
          <input
            ref={inputRef}
            style={{ visibility: "hidden" }}
            type="file"
            accept=".m2v,video/mp4,video/m2v,video/x-m4v,video/*"
            onChange={handleSelectFile}
          />
        </UploadView>
      )
    }

    // Loading screen
    if (status === "started" || status === "finished") {
      return (
        <UploadView>
          <Text.H2 style={{ color: black, marginBottom: "2rem" }}>
            {status === "started" ? (
              <span>
                <span style={{ display: "flex", justifyContent: "center" }}>
                  Uploading
                  <AnimatedDots />
                  <br />
                </span>
                <br />
                <Text.Body style={{ fontSize: "16px" }}>
                  This may take a little while, so feel free to continue working
                  by clicking{" "}
                  <a
                    target="_blank"
                    href={`${window.location.href}`}
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                  >
                    here.
                  </a>
                </Text.Body>
              </span>
            ) : (
              <span style={{ display: "flex", justifyContent: "center" }}>
                Upload Complete!
              </span>
            )}
          </Text.H2>
          <VideoUploadingWrapper>
            <div style={{ marginRight: "1rem" }}>
              <ProgressCircle
                progress={status === "started" ? state.progress : 1}
                size={80}
                trailColor="#fff"
                strokeColor={black}
              >
                <Pathicon
                  icon={status === "started" ? "file-video" : "check"}
                  style={{ color: "gray", fontSize: "2.5em" }}
                />
              </ProgressCircle>
            </div>
            <Text
              style={{
                overflowWrap: "break-word",
                whiteSpace: "pre-wrap"
              }}
            >
              {state.filename}
            </Text>
            {/* Disabling deleting/removing video for now */}
            {/* <IconButton
              style={{ position: "absolute", right: "0.5rem", color: black }}
              icon="x"
              onClick={() => "delete video"}
            /> */}
          </VideoUploadingWrapper>
        </UploadView>
      )
    }
  }

  const handleCancelUpload = (event) => {
    setState({ status: "pending" })
    props.onCancelUpload()
  }

  const { status } = state
  // States
  // - pending: the user hasn't hit "pick" yet
  // - started: the user has selected a file and we've got the mux upload endpoint from the server (mutation)
  // - finished: the upload is finished

  return (
    <VideoUploadWrapper fullSize={fullSize}>
      {renderVideoStatus()}
      {onCancelUpload ? (
        status === "error" || status === "pending" ? (
          <CancelButtonContainer>
            or{" "}
            <Button
              onClick={handleCancelUpload}
              styleType="text"
              style={{ textDecoration: "underline!important", color: black }}
            >
              {status === "error" ? "Return" : "Cancel Upload"}
            </Button>
          </CancelButtonContainer>
        ) : null
      ) : null}
      <IconButton
        style={{
          position: "absolute",
          top: "16px",
          left: "7px",
          color: "gray"
        }}
        icon="x"
        onClick={handleCancelUpload}
      />
    </VideoUploadWrapper>
  )
}

MuxVideoUploader.defaultProps = {
  onVideoCreated: () => {}
}

export default MuxVideoUploader
