import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import { COMPLETION_FILTERS } from "./context/completion-filters"
import { useReviewContext } from "./state/ReviewContextProvider"
import useHasInboxItems from "./state/useHasInboxItems"

const InboxBlankSlate = () => {
  const { t } = useTranslate()
  const { filter } = useReviewContext()

  if (useHasInboxItems()) return null

  switch (filter) {
    case COMPLETION_FILTERS.all.key:
      return (
        <BlankSlate
          heading={t("No one has steps to complete so far.")}
          body={t(
            "Once someone joins a Path, their incomplete and complete steps will appear in this Inbox."
          )}
          inverted
        />
      )
    case COMPLETION_FILTERS.pending.key:
      return (
        <BlankSlate
          heading={t("No steps are pending review.")}
          body={t("Select from the filter menu above to see more options.")}
          primaryAction={{
            styleType: "text",
            label: "Learn more about reviewable steps",
            postIcon: "external-link",
            href: "https://help.pathwright.com/en/articles/6293036-make-any-step-reviewable"
          }}
          inverted
        />
      )
    case COMPLETION_FILTERS.reviewed.key:
      return (
        <BlankSlate
          heading={t("No steps have been reviewed.")}
          body={t("Select from the filter menu above to see more options.")}
          primaryAction={{
            styleType: "text",
            label: "Learn more about reviewable steps",
            postIcon: "external-link",
            href: "https://help.pathwright.com/en/articles/6293036-make-any-step-reviewable"
          }}
          inverted
        />
      )
    case COMPLETION_FILTERS.complete.key:
      return (
        <BlankSlate
          heading={t("No completed steps so far.")}
          body={t("Select from the filter menu above to see more options.")}
          inverted
        />
      )
    case COMPLETION_FILTERS.incomplete.key:
      return (
        <BlankSlate
          heading={t("No incomplete steps.")}
          body={t("Select from the filter menu above to see more options.")}
          inverted
        />
      )
  }

  return null
}

InboxBlankSlate.displayName = "InboxBlankSlate"

export default InboxBlankSlate
