import get from "lodash/get"

// TODO: deprecate this store
class LayoutStore extends require("lib/static-shim").default(
  require("lib/core/store/backbone-store").default
) {
  static initClass() {
    // TODO: move over layout related things from nav store (scroll/size etc...)

    this.prototype.defaults = {
      fullscreen: null,
      modal: null,
      panel: null,
      message: null,
      aside: null
    }

    this.prototype.actions = {
      showIn(regionKey, Component, props, config) {
        if (props == null) {
          props = {}
        }
        if (config == null) {
          config = {}
        }
        const state = this.getState()
        state[regionKey] = { Component, props, config }
        return this._mutate(state)
      },

      closeIn(regionKey) {
        const state = this.getState()
        const onWillClose = get(state[regionKey], "config.onWillClose")

        if (!state[regionKey] || !state[regionKey].Component || !onWillClose) {
          state[regionKey] = null
          this._mutate(state)
        } else {
          this._mutate({
            [regionKey]: {
              Component: null,
              config: {
                onWillClose: () => {
                  onWillClose()
                  App.getStore("layout").action.closeIn(regionKey)
                }
              }
            }
          })
        }
      }
    }
  }
}
LayoutStore.initClass()

export default window.App.stores.registerStore("layout", LayoutStore)
