import { createContext, useContext, useEffect, useState } from "react"
import CodexClient from "../core/client"

const CodexReactContext = createContext(undefined)

const useCodex = () => useContext(CodexReactContext)

export const CodexContextProvider = ({ config, fallback, children }) => {
  let [loading, setLoading] = useState(true)
  const [client] = useState(new CodexClient(config))

  useEffect(() => {
    ;(async () => {
      await client.init()
      console.assert(!!client.context)
      setLoading(false)
      window["codexClient"] = client
    })()
  }, [])

  if (loading || !client.context) return fallback

  return (
    <CodexReactContext.Provider value={client}>
      {children}
    </CodexReactContext.Provider>
  )
}

export default useCodex
