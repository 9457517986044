import styled from "styled-components"

export const VideoWrapper = styled.div`
  height: auto;
  width: 100%;
  max-width: 100%;
  position: relative;
  margin: 0 auto;

  & > .video-react.TheVideo > .video-react-video {
    max-height: ${(p) => (p.$videoHeight ? `${p.$videoHeight}px` : "100%")};
  }

  & > .video-react.TheVideo.video-react-fullscreen > .video-react-video {
    max-height: 100%;
    height: 100%;
  }

  .video-react-controls-enabled {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: none;
  }
  .video-react-big-play-button.video-react-big-play-button-left.TheVideo {
    display: none;
  }

  .video-react .video-react-control {
    width: 3em;
  }
  .video-react-time-control {
    padding-left: 0.5em;
    padding-right: 0.5em;

    &.video-react-time-divider {
      padding: 0;
    }
  }

  .video-react-icon-fullscreen.video-react-fullscreen-control {
    display: ${(p) => (p.$hideFullscreen ? "none !important" : "flex")};
  }
  .video-react-volume-menu-button {
    display: ${(p) => (p.$hideFullscreen ? "none !important" : "flex")};
  }

  ${(p) =>
    p.$hideControls
      ? `
		.video-react-big-play-button,
		.video-react-control-bar {
			display: none !important;
		}
	`
      : ``}
`

export const VideoOverlay = styled.div`
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.6);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`
