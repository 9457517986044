import {
  ApolloCache,
  FetchResult,
  MutationUpdaterFunction
} from "@apollo/client"
import { Media, MediaInput } from "@pathwright/media-utils"
import gql from "graphql-tag"
import get from "lodash/get"

const baseMediaFields = gql`
  fragment baseMediaFields on Media {
    id
    createdByID
    accountID
    name
    type
    mimetype
    createdDate
    url
    description
    downloadURL
    tags
    externalID
    initialMetaAdded
    muxVideoID
  }
`

const mediaInputFields = gql`
  fragment mediaInputFields on Media {
    ...baseMediaFields
    fileSize
    encodingStatus
  }
  ${baseMediaFields}
`
const mediaItemFields = gql`
  fragment mediaItemFields on Media {
    ...baseMediaFields
    thumb {
      large
      medium
      small
      thumb
      original
    }
    size {
      width
      height
      dpi
      fileSize
    }
    playback {
      length
      startTime
      endTime
    }
    encoding {
      status
      jobID
      startTime
      endTime
      pollingURL
    }
    lastUsedDateTime
  }
  ${baseMediaFields}
`

// Get all media for one account
export const ACCOUNT_MEDIA_QUERY = gql`
  query AccountMedia($contextKey: String!) {
    mediaByAccount(contextKey: $contextKey) {
      ...mediaItemFields
    }
  }
  ${mediaItemFields}
`

export const ACCOUNT_TAGS_QUERY = gql`
  query AccountTags($contextKey: String!) {
    accountTags(contextKey: $contextKey)
  }
`

export const DELETE_ACCOUNT_TAG_MUTATION = gql`
  mutation DeleteAccountTag($name: String!, $contextKey: String!) {
    deleteAccountTag(name: $name, contextKey: $contextKey)
  }
`

export const MEDIA_SAVE_BATCH_MUTATION = gql`
  mutation MEDIA_SAVE_BATCH_MUTATION(
    $media: [MediaInput!]!
    $contextKey: String!
  ) {
    saveMediaBatch(media: $media, contextKey: $contextKey) {
      ...mediaItemFields
    }
  }
  ${mediaItemFields}
`

export const MEDIA_SAVE_MUTATION = gql`
  mutation SaveMedia($media: MediaInput!, $contextKey: String!) {
    saveMedia(media: $media, contextKey: $contextKey) {
      ...mediaItemFields
    }
  }
  ${mediaItemFields}
`

export const MEDIA_UPDATE_META_MUTATION = gql`
  mutation UpdateMeta(
    $metaFields: MediaMetaInput!
    $id: String!
    $contextKey: String!
  ) {
    updateMediaMeta(metaFields: $metaFields, id: $id, contextKey: $contextKey) {
      ...mediaItemFields
    }
  }
  ${mediaItemFields}
`

export const MEDIA_DELETE_MUTATION = gql`
  mutation DeleteMedia($id: String!, $contextKey: String!) {
    deleteMedia(id: $id, contextKey: $contextKey)
  }
`

export const MEDIA_DELETE_BATCH_MUTATION = gql`
  mutation DeleteMediaBatch($options: JSON!, $contextKey: String!) {
    deleteMediaBatch(options: $options, contextKey: $contextKey)
  }
`

export const queryMap = {
  mediaByAccount: {
    query: ACCOUNT_MEDIA_QUERY,
    label: "Account Media",
    attribute: "accountID"
  }
}

type MediaData = {
  mediaByAccount?: Media[]
  saveMediaBatch?: Media[]
  saveMedia?: Media
}

type MediaVariables = {
  contextKey?: string
}

type UpdateMediaCache = {
  media: Media[]
  operation: string
  callback: (() => void) | null
  contextKey: string
  optimisticKey?: keyof MediaData
  queryName?: string
}

// operation: one of "create", "update", "delete"
// media: array
export const updateMediaCache =
  ({
    media = [],
    operation = "",
    callback = null,
    contextKey,
    optimisticKey = "mediaByAccount",
    queryName = "mediaByAccount"
  }: UpdateMediaCache): MutationUpdaterFunction<
    MediaData,
    MediaVariables,
    {},
    ApolloCache<any>
  > =>
  (cache: ApolloCache<any>, result: FetchResult<MediaData>) => {
    const optimisticData = result.data?.[optimisticKey]

    // Wait for cache to be ready
    const rootQueries = get(cache, `data.data.ROOT_QUERY`)
    if (
      !rootQueries ||
      Object.keys(rootQueries).every((k) => !k.includes(queryName))
    )
      return

    // const { query } = queryMap[queryName]

    const query = ACCOUNT_MEDIA_QUERY

    let nextData: MediaData = { mediaByAccount: [] }
    const data: MediaData | null = cache.readQuery({
      query,
      variables: { contextKey }
    })

    if (!data) return

    if (operation === "delete") {
      // TODO: Cache data may be lost when replacing the mediaByAccount field of a Query object.
      // This warning is thrown by Apollo and they recommend:
      // * Ensuring entity objects have IDs: https://go.apollo.dev/c/generating-unique-identifiers
      // * Defining custom merge functions: https://go.apollo.dev/c/merging-non-normalized-objects
      // No noticeable issues though, so seems safe to solve later. The deleted item, and only that item
      // appears to be removed.
      nextData = {
        ...data,
        [queryName]: data![queryName].filter(
          (m: Media) => !media.find((mediaItem: Media) => mediaItem.id === m.id)
        )
      }
    } else if (operation === "update") {
      const newMedia = optimisticData
        ? Array.isArray(optimisticData)
          ? optimisticData
          : [optimisticData]
        : media
      nextData = {
        ...data,
        [queryName]: newMedia.concat(
          data![queryName].filter(
            (m: Media) => !newMedia.find((nm: Media) => nm.id === m.id)
          )
        )
      }
    } else if (operation === "create") {
      const newMedia = optimisticData
        ? Array.isArray(optimisticData)
          ? optimisticData
          : [optimisticData]
        : media
      nextData = {
        ...data,
        [queryName]: data[queryName].concat(newMedia)
      }
    } else {
      throw new Error(
        `Invalid operation provided: ${operation}. Provide "create",  "update", or "delete".`
      )
    }

    cache.writeQuery({
      query: ACCOUNT_MEDIA_QUERY,
      variables: { contextKey },
      data: nextData
    })
    if (typeof callback === "function") callback()
  }

// Used for Apollo optimisticResponse
export const mockMediaResponse = (
  mediaInput: MediaInput,
  userID: string | number
): Media => {
  const createdByID = Number(userID)
  return {
    __typename: "Media",
    id: mediaInput.id || "temp-id",
    createdByID,
    accountID: mediaInput.accountID || "7888",
    name: mediaInput.name,
    type: mediaInput.type,
    mimetype: mediaInput.mimetype,
    createdDate: mediaInput.createdDate || new Date().getTime(),
    url: mediaInput.url,
    description: mediaInput.description || "",
    downloadURL: mediaInput.downloadURL,
    tags: mediaInput.tags || [],
    externalID: mediaInput.externalID || "",
    initialMetaAdded: false,
    muxVideoID: mediaInput.muxVideoID || "",
    thumb: {
      large: "",
      medium: "",
      small: "",
      thumb: "",
      original: ""
    },
    size: {
      width: null,
      height: null,
      dpi: null,
      fileSize: mediaInput.fileSize || null,
      __typename: "MediaSize"
    },
    playback: {
      length: null,
      startTime: null,
      endTime: null
    },
    encoding: {
      status: mediaInput.encodingStatus || "new",
      jobID: null,
      startTime: null,
      endTime: null,
      pollingURL: null,
      __typename: "MediaEncoding"
    },
    lastUsedDateTime: new Date().getTime()
  }
}
