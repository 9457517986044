import { useMutation } from "@apollo/client"
import PopupAlert from "@pathwright/ui/src/components/alert/PopupAlert"
import PasteButton from "@pathwright/ui/src/components/button/PasteButton"
import useClipboard from "../../clipboard/useClipboard"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import COPY_RESOURCE_MUTATION from "../../resource/manage/graphql/copy-resource-mutation"
import { canAdministrate } from "../../user/permissions"

// Handles copying a resource from the clipboard. The resource in the clipboard
// can be from the current school or from any other school, though permission
// checks will be applied on the backend when attempting the resource copy.
const PasteResource = () => {
  const pwContext = usePathwrightContext()
  const clipboard = useClipboard()
  const clip = clipboard.getClip({ object_type: "resource" })
  const resourceId = clip ? clip.object_id : null

  const canPasteResource = Boolean(
    // Can only paste resource when clip with resource_id.
    resourceId &&
      // Ignore perms if no clip data exists.
      (!clip.data ||
        // Ignore perms if no school_id exists in clip data.
        !clip.data.school_id ||
        // Assume user can always paste resource to same school.
        clip.data.school_id === pwContext.school.id ||
        // Only admins can paste resource to different school.
        (clip.data.user_is_admin_of_school && canAdministrate(pwContext)))
  )

  const [mutation, { loading, error }] = useMutation(COPY_RESOURCE_MUTATION, {
    variables: {
      id: resourceId
    },
    refetchQueries: ["LibraryResourcesQuery", "UserResources"]
  })

  const handlePaste = async () => {
    await mutation()
    clipboard.clear({ object_type: "resource" })
  }

  const handleCancel = () => {
    clipboard.clear({ object_type: "resource" })
  }

  return (
    <>
      {!!error && <PopupAlert error={error} />}
      {canPasteResource && (
        <PasteButton
          onPaste={handlePaste}
          onCancel={handleCancel}
          clip={clip}
          styleMode="dark"
          tooltipProps={{ placement: "left" }}
        />
      )}
    </>
  )
}

PasteResource.displayName = "PasteResource"

export default PasteResource
