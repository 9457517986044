import { useMutation, useQuery } from "@apollo/client"
import { prepMediaForSaving } from "@pathwright/media-utils"
import { useCallback, useMemo } from "react"
import MediaSyncerContext, {
  SyncerContextType,
  SyncerProps
} from "./MediaSyncerContext"
import * as c from "./constants"
import {
  ACCOUNT_MEDIA_QUERY,
  ACCOUNT_TAGS_QUERY,
  MEDIA_DELETE_BATCH_MUTATION,
  MEDIA_DELETE_MUTATION,
  MEDIA_SAVE_BATCH_MUTATION,
  MEDIA_SAVE_MUTATION,
  mockMediaResponse,
  updateMediaCache
} from "./graphql"

const MediaGraphqlSyncer = ({ children, contextKey, userID }: SyncerProps) => {
  const {
    loading: mediaLoading,
    error: mediaError,
    data: mediaData
  } = useQuery(ACCOUNT_MEDIA_QUERY, {
    variables: { contextKey }
  })

  const {
    loading: tagsLoading,
    error: tagsError,
    data: tagsData,
    refetch: refetchTags
  } = useQuery(ACCOUNT_TAGS_QUERY, {
    variables: { contextKey }
  })

  const media = useMemo(() => {
    return mediaData ? mediaData.mediaByAccount : []
  }, [mediaData])

  const tags = useMemo(() => {
    return tagsData ? tagsData.accountTags : []
  }, [tagsData])

  const gqlError = useMemo(() => {
    return mediaError || tagsError
  }, [mediaError, tagsError])

  const [saveBatch] = useMutation(MEDIA_SAVE_BATCH_MUTATION)
  const [deleteBatch] = useMutation(MEDIA_DELETE_BATCH_MUTATION)
  const [saveMedia] = useMutation(MEDIA_SAVE_MUTATION)
  const [deleteMedia] = useMutation(MEDIA_DELETE_MUTATION)
  // const [deleteAccountTag] = useMutation(DELETE_ACCOUNT_TAG_MUTATION)

  const syncToGraphQL = useCallback(async (action, state, dispatch) => {
    const { type, payload } = action

    switch (type) {
      case c.SAVE_MEDIA_BATCH:
        try {
          const media = payload

          await saveBatch({
            variables: {
              media: media.map(prepMediaForSaving),
              contextKey
            },
            optimisticResponse: {
              __typename: "Mutation",
              saveMediaBatch: media.map((m) => ({
                ...mockMediaResponse(m, userID),
                __typename: "Media"
              }))
            },
            update: updateMediaCache({
              media,
              operation: "update",
              contextKey,
              optimisticKey: "saveMediaBatch",
              callback: () => {
                refetchTags()
                dispatch({ type: c.RESET, payload: { uploadCount: 0 } })
              }
            })
          })
        } catch (err) {
          console.log("error saving batch: ", err)
          dispatch({ type: c.ERROR, payload: err })
          dispatch({ type: c.RESET, payload: { uploadCount: 0 } })
        }
        break
      case c.DELETE_BATCH:
        try {
          const selectedBatch = state.selectedBatch || []

          await deleteBatch({
            variables: {
              options: {
                ids: selectedBatch.map((i) => i.id)
              },
              contextKey
            },
            optimisticResponse: {
              __typename: "Mutation",
              deleteMediaBatch: true
            },
            update: updateMediaCache({
              media: selectedBatch,
              operation: "delete",
              contextKey,
              callback: () => {
                dispatch({ type: c.RESET })
              }
            })
          })
        } catch (err) {
          console.log("error deleting batch: ", err)
          dispatch({ type: c.ERROR, payload: err })
          dispatch({ type: c.RESET })
        }
        break
      case c.START_SAVING_MEDIA:
        try {
          const media = payload

          await saveMedia({
            variables: { media, contextKey },
            optimisticResponse: {
              __typename: "Mutation",
              saveMedia: {
                ...mockMediaResponse(media, userID),
                id: media.id || "temp-id"
              }
            },
            update: updateMediaCache({
              media: [media], // fallback
              operation: "update",
              contextKey,
              optimisticKey: "saveMedia",
              callback: () => {
                dispatch({ type: c.RESET, payload: { uploadCount: 0 } })
                dispatch({ type: c.STOP_LOADING })
                dispatch({ type: c.STOP_EDITING })
                refetchTags()
              }
            })
          })
        } catch (err) {
          console.log("error saving media: ", err)
          dispatch({ type: c.ERROR, payload: err })
          dispatch({ type: c.RESET, payload: { uploadCount: 0 } })
          dispatch({ type: c.STOP_LOADING })
        }
        break
      case c.DELETE_MEDIA:
        try {
          const media = payload

          await deleteMedia({
            variables: { id: media.id, contextKey },
            optimisticResponse: {
              __typename: "Mutation",
              deleteMedia: true
            },
            update: updateMediaCache({
              media: [media],
              operation: "delete",
              contextKey,
              callback: () => dispatch({ type: c.RESET })
            })
          })
        } catch (err) {
          console.log("error deleting media: ", err)
          dispatch({ type: c.ERROR, payload: err })
          dispatch({ type: c.RESET })
        }
        break

      default:
      // console.log("no GQL action taken for: ", type)
    }
  }, [])

  const value: SyncerContextType = useMemo(
    () => ({
      syncer: syncToGraphQL,
      media,
      accountTags: tags,
      gqlError,
      tagsLoading,
      mediaLoading
    }),
    [syncToGraphQL, media, tags, gqlError, tagsLoading, mediaLoading]
  )

  return (
    <MediaSyncerContext.Provider value={value}>
      {children}
    </MediaSyncerContext.Provider>
  )
}

export default MediaGraphqlSyncer
