import COMPLETION_FRAGMENT from "@pathwright/web/src/modules/completion/graphql/completion-fragment"
import produce from "immer"
import { getApolloClient } from "../../../pathwright/PathwrightClient"

// When discussion is created or response is created/deleted...
// 1) update the completion feedback_discussion_context
// 2) as a side-effect, this should update the feedbackReviewers query
export function updateFeedbackDiscussionNumResponses(
  discussion,
  setNumResponses
) {
  const client = getApolloClient()

  // Default to set num_responses to total_responses.
  setNumResponses ||= () => discussion.total_responses

  const fragment = {
    fragment: COMPLETION_FRAGMENT,
    fragmentName: "Completion",
    id: `Completion:${discussion.step_id}`
  }

  const fragmentData = client.readFragment(fragment)

  const nextFragmentData = produce(fragmentData, (draft) => {
    if (!draft.feedback_discussion_context) {
      draft.feedback_discussion_context = {
        id: discussion.context_id,
        discussion_id: discussion.id,
        num_responses: 0
      }
    }

    draft.feedback_discussion_context.num_responses = setNumResponses(
      draft.feedback_discussion_context.num_responses
    )
  })
  client.writeFragment({
    ...fragment,
    data: nextFragmentData
  })
}
