import { useMutation } from "@apollo/client"
import Card from "@pathwright/ui/src/components/card/Card"
import CardBlock from "@pathwright/ui/src/components/card/CardBlock"
import CardLink from "@pathwright/ui/src/components/card/CardLink"
import FullscreenConfirm from "@pathwright/ui/src/components/confirm/FullscreenConfirm"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import gql from "graphql-tag"
import PropTypes from "prop-types"
import FeatureBlockList from "../../feature/block/FeatureBlockList"
import {
  FEATURE_ACTION_VIEW,
  FEATURE_KEY_ACTIVITY_FEED,
  FEATURE_KEY_MEMBER_DIRECTORY,
  FEATURE_PERMISSION_DISABLED,
  FEATURE_PERMISSION_EVERYONE
} from "../../feature/constants"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import useTagPermissions from "../../tag/useTagPermissions"
import { useFeaturePermission } from "../form/FeaturePermissionsForm"
import withFeatureContext from "./withFeatureContext"

const ConfirmEnable = ({ ...confirmProps }) => {
  const { t } = useTranslate()

  return (
    <FullscreenConfirm
      icon="caution-triangle"
      heading={t("Enable Community in this Cohort?")}
      body={t(
        "Enable members to post discussions in the Path and access features in the Community Tab."
      )}
      confirmPrompt={t("Enable Community")}
      cancelPrompt={t("Nevermind, go back")}
      stack
      {...confirmProps}
    />
  )
}

const ConfirmDisable = ({ ...confirmProps }) => {
  const { t } = useTranslate()

  return (
    <FullscreenConfirm
      icon="caution-triangle"
      heading={t("Disable Community in this Cohort?")}
      body={t(
        "Prevent members from posting discussions in the Path or accessing features in the Community Tab."
      )}
      confirmPrompt={t("Yes, Disable Community  ")}
      cancelPrompt={t("Nevermind, go back")}
      stack
      {...confirmProps}
    />
  )
}

const COMMUNITY_ENABLE_MUTATION = gql`
  mutation CommunityEnableMutation(
    $cohortId: ID!
    $communityEnabled: Boolean!
  ) {
    updateGroupDiscussionEnabled(
      id: $cohortId
      discussion_enabled: $communityEnabled
    ) {
      id
      discussion_enabled
    }
  }
`

const ManageCohortFeatures = withFeatureContext(
  ({ card, context, communityEnabled, onChangeCommunityEnabled }) => {
    const { t } = useTranslate()
    const pwContext = usePathwrightContext()
    const tagPermissions = useTagPermissions({ context })
    const permissions = {
      [FEATURE_KEY_ACTIVITY_FEED]: useFeaturePermission({
        featureKey: FEATURE_KEY_ACTIVITY_FEED,
        featureAction: FEATURE_ACTION_VIEW,
        context
      }),
      [FEATURE_KEY_MEMBER_DIRECTORY]: useFeaturePermission({
        featureKey: FEATURE_KEY_MEMBER_DIRECTORY,
        featureAction: FEATURE_ACTION_VIEW,
        context
      })
    }

    const [communityEnableMutation] = useMutation(COMMUNITY_ENABLE_MUTATION, {
      variables: {
        cohortId: context.cohort_id
      },
      onCompleted: (result) => {
        onChangeCommunityEnabled &&
          onChangeCommunityEnabled(
            result.updateGroupDiscussionEnabled.discussion_enabled
          )
      }
    })

    const handleEnableCommunity = (enable) => {
      const mutationPromises = Object.values(permissions)
        .filter((config) => config.canUpdate)
        .map((config) =>
          config.mutation[0]({
            variables: {
              permissionInContext: enable
                ? FEATURE_PERMISSION_EVERYONE
                : FEATURE_PERMISSION_DISABLED
            }
          })
        )

      const communityEnableMutationPromise = communityEnableMutation({
        variables: {
          communityEnabled: enable
        }
      })

      return Promise.all([...mutationPromises, communityEnableMutationPromise])
    }

    // only allow user to enable/disable community if they have `view` update permissions
    // for all features
    const canEnableCommunity = Object.values(permissions).reduce(
      (bool, { canUpdate }) => bool && canUpdate,
      true
    )

    // Only show those features which the user can edit.
    const featureKeys = Object.entries(permissions).reduce(
      (keys, [key, { canUpdate }]) => {
        if (canUpdate) keys.push(key)
        return keys
      },
      []
    )

    return (
      <Card card={card} title={t("Community Features")} emphasis="secondary">
        <CardBlock paddingTop={false}>
          <FeatureBlockList
            featureAction={FEATURE_ACTION_VIEW}
            featureKeys={featureKeys}
            context={context}
            showTagFeatureBlock={tagPermissions.canEdit}
          />
          {canEnableCommunity ? (
            communityEnabled ? (
              <ConfirmDisable onConfirm={() => handleEnableCommunity(false)}>
                {({ confirm }) => (
                  <CardLink
                    label={t("Disable Community features")}
                    meta={t(
                      "Disable discussion and all other Community Features"
                    )}
                    onClick={confirm}
                    // hack to show chevron-right icon
                    to={window.location.pathname}
                  />
                )}
              </ConfirmDisable>
            ) : (
              <ConfirmEnable onConfirm={() => handleEnableCommunity(true)}>
                {({ confirm }) => (
                  <CardLink
                    label={t("Enable Community features")}
                    meta={t(
                      "Enable discussion and all other Community Features"
                    )}
                    onClick={confirm}
                    // hack to show chevron-right icon
                    to={window.location.pathname}
                  />
                )}
              </ConfirmEnable>
            )
          ) : null}
        </CardBlock>
      </Card>
    )
  }
)

ManageCohortFeatures.displayName = "ManageCohortFeatures"

ManageCohortFeatures.propTypes = {
  // provided by CardStack > CardStackCard
  // card: PropTypes.object.isRequired,
  ...withFeatureContext._propTypes,
  communityEnabled: PropTypes.bool,
  onChangeCommunityEnabled: PropTypes.func
}

export default ManageCohortFeatures
