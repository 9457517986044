import { useQuery } from "@apollo/client"
import { CERTIFICATE_QUERY } from "../graphql"

function useActiveCertificate() {
  const certificateQuery = useQuery(CERTIFICATE_QUERY)
  const certificate = certificateQuery.data?.certificate
  return certificate
}

export default useActiveCertificate
