import { useEffect } from "react"
import useIntersection from "./useIntersection"

const useIntersectionLoader = (paginationArgs = {}, intersectionArgs = {}) => {
  const { hasMore, loadingMore, loadMore } = paginationArgs
  const { setIntersectionNode, hasIntersected } =
    useIntersection(intersectionArgs)
  // Reset hasIntersected by removing ref when loading more.
  const ref = loadingMore ? null : setIntersectionNode

  useEffect(() => {
    if (hasIntersected && hasMore && !loadingMore) {
      loadMore()
    }
  }, [hasIntersected])

  return ref
}

export default useIntersectionLoader
