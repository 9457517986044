import defaults from "lodash/defaults"
import { useCallback, useEffect, useRef, useState } from "react"
import { usePrevious } from "react-use"

// `storageType` - Whether to store changes in localStorage or sessionStorage.
// `sync` - Whether to sync the local changes "globally" (to other browser tabs).
//          Only possible when using the "local" `storageType` (via localStorage).
export type LocalStorageOptions =
  | {
      storageType?: "local"
      sync?: boolean
    }
  | {
      storageType?: "session"
      sync?: false
    }

// "local": The change occurred locally, in the current web page.
// "global": The change occurred globally, from a different web page.
export type LocalStorageSource = "local" | "global"

// Data detailing info about the stored value.
//   - source: The actor responsible for the current value, one of LocalStorageSource
export type LocalStorageMeta = {
  source: LocalStorageSource
}

export type LocalStorageValue<T extends any> =
  | T
  | ((value?: T) => T | undefined)

export type LocalStorageSetter<T extends any> = (
  value?: LocalStorageValue<T>
) => void

export type LocalStorageReturn<T extends any> = [
  T | undefined,
  LocalStorageSetter<T>,
  LocalStorageMeta
]

function useLocalStorage<T extends any>(
  key: string,
  initialValue: LocalStorageValue<T>,
  options: LocalStorageOptions = {}
): LocalStorageReturn<T> {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  options = defaults(options, {
    storageType: "local",
    sync: false
  })

  const sourceRef = useRef<LocalStorageSource>("local")

  const storage =
    window[`${options.storageType}Storage` as "localStorage" | "sessionStorage"]

  if (!storage) {
    throw Error(
      `Cannot find storage on window for storage type: "${options.storageType}"`
    )
  }

  const getInitialValue = () => {
    const handleInitialValue = (result?: T) => {
      if (initialValue instanceof Function) {
        return initialValue(result)
      }
      // Ensure we return result even if it's falsey, unless it's null which
      // indicates the key doesn't exist.
      return result !== null ? result : initialValue
    }

    if (!key) {
      return handleInitialValue()
    }

    try {
      // Get from local storage by key
      const item = storage.getItem(key)
      // Parse stored json or if none return initialValue
      return handleInitialValue(item && JSON.parse(item))
    } catch (error) {
      // If error also return initialValue
      console.log(error)
      return handleInitialValue()
    }
  }

  const [storedValue, setStoredValue] = useState<T | undefined>(getInitialValue)

  // For tracking current storedValue.
  const storedValueRef = useRef<T>()
  storedValueRef.current = storedValue

  // If the key changes, set the initial value for that key.
  const previousKey = usePrevious(key)
  useEffect(() => {
    if (previousKey && key !== previousKey) setStoredValue(getInitialValue())
  }, [key])

  const setValueToStore = useCallback((valueToStore?: T) => {
    try {
      // Calling setValue with no argument provides simple mechanic for
      // removing item from localStorage.
      if (typeof valueToStore === "undefined") {
        storage.removeItem(key)
      } else {
        // Save to local storage
        storage.setItem(key, JSON.stringify(valueToStore))
      }
      // Save state
      setStoredValue(valueToStore)
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error)
    }
  }, [])

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue: LocalStorageSetter<T> = (value) => {
    sourceRef.current = "local"
    // Allow value to be a function so we have same API as useState
    const valueToStore =
      value instanceof Function ? value(storedValueRef.current) : value

    setValueToStore(valueToStore)
  }

  // Sync changes in localStorage from other same-domain web pages.
  // Keep in mind that this does not sync changes in localStorage for
  // the same web page making the change.
  useEffect(() => {
    if (options.sync) {
      const handler = (e: StorageEvent) => {
        if (e.key === key) {
          sourceRef.current = "global"
          // e.newValue will be null when the item has been removed
          // from localStorage.
          if (e.newValue === null) {
            setValueToStore()
          } else {
            setValueToStore(JSON.parse(e.newValue))
          }
        }
      }
      // Add and remove listener.
      window.addEventListener("storage", handler)
      return () => window.removeEventListener("storage", handler)
    }
  }, [options?.sync])

  const meta = { source: sourceRef.current }

  return [storedValue, setValue, meta]
}

export const useSessionStorage: typeof useLocalStorage = (key, initialValue) =>
  useLocalStorage(key, initialValue, { storageType: "session" })

export default useLocalStorage
