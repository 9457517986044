import { useMutation } from "@apollo/client"
import Alert from "@pathwright/ui/src/components/alert/Alert"
import Button from "@pathwright/ui/src/components/button/Button"
import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import FullscreenConfirm from "@pathwright/ui/src/components/confirm/FullscreenConfirm"
import ToggleInput from "@pathwright/ui/src/components/form/form-toggle/ToggleInput"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import View from "@pathwright/ui/src/components/ui/View"
import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import { Formik } from "formik"
import { useEffect, useRef } from "react"
import { useIntercomContext } from "../../intercom/IntercomProvider"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import {
  FEATURE_ACTION_UPDATE_VISIBILITY,
  FEATURE_ACTION_VIEW,
  FEATURE_PERMISSION_ADMIN_ONLY,
  FEATURE_PERMISSION_STAFF_ONLY
} from "../constants"
import {
  CONTEXTUAL_FEATURE_PERMISSION_QUERY,
  UPDATE_CONTEXTUAL_FEATURE_PERMISSION_MUTATION
} from "../graphql"
import { useFeatureUpdatePermission } from "../permissions"
import { getFeatureInfo, getFeaturePermissionInfo } from "../utils"
import FeatureVisibilitySelect from "./FeatureVisibilitySelect"

const Confirm = ({ children, featureKey, permission, ...confirmProps }) => {
  const { t } = useTranslate()
  const { school } = usePathwrightContext()

  return (
    <FullscreenConfirm
      icon="caution-triangle"
      heading={t("Update Visibilty for All Cohorts?")}
      body={t(
        "The {{ feature }} will be visible to {{ audience }} in all cohorts in {{ school }}. (Cohorts with customized visibility settings won't be be updated.)",
        {
          feature: getFeatureInfo(featureKey, t).title,
          audience: getFeaturePermissionInfo(featureKey, permission, t).title,
          school: school.name
        }
      )}
      confirmPrompt={t("Yes, Update Visibility")}
      cancelPrompt={t("Nevermind, go back")}
      stack
      {...confirmProps}
    >
      {children}
    </FullscreenConfirm>
  )
}

export const useFeaturePermission = ({
  featureKey,
  featureAction,
  context
}) => {
  const { usingPathwrightIntercom } = useIntercomContext()

  const variables = {
    featureKey,
    featureAction,
    context
  }

  // ugly way to retain the original permission_in_context for event tracking
  // likely in ApollClient 3.0^ we could simply check the permission.query.previousData
  const originalPermissionRef = useRef(null)

  const permission = {
    key: featureAction,
    query: useQuery(CONTEXTUAL_FEATURE_PERMISSION_QUERY, {
      variables
    }),
    mutation: useMutation(UPDATE_CONTEXTUAL_FEATURE_PERMISSION_MUTATION, {
      variables,
      onCompleted: (result) => {
        // tracking an Intercom event for when the user changes a cfp's permission_in_context
        // though only for when Pathwright Intercom is enabled
        if (usingPathwrightIntercom && window.Intercom) {
          const cfp = result.updateContextualFeaturePermission
          const eventData = Object.entries({
            feature: cfp.feature_key,
            action: cfp.feature_action,
            school: cfp.school_id,
            resource: cfp.resource_id,
            cohort: cfp.cohort_id,
            from: originalPermissionRef.current,
            to: cfp.permission_in_context
          }).reduce(
            // remove any null values (potentially resource and cohort)
            (data, [key, value]) => (value ? { ...data, [key]: value } : data),
            {}
          )

          window.Intercom("trackEvent", "changed-feature-visibility", eventData)
        }
      },
      refetchQueries: () => {
        // possible that a CFP for the given context does not yet exist
        // (whether the result of the permission.query is the platform default or an existing CFP from a parent context)
        // let's refetch related queries as the mutation will have created
        // the CFP and we need related UIs to have the latest data
        // const {
        //   id,
        //   resource_id,
        //   cohort_id
        // } = permission.query.data.contextualFeaturePermission

        // if (
        //   !id ||
        //   (context &&
        //     (resource_id !== context.resource_id ||
        //       cohort_id !== context.cohort_id))
        // ) {
        //   return [
        //     "ContextualFeaturePermissionsQuery",
        //     {
        //       query: CONTEXTUAL_FEATURE_PERMISSION_QUERY,
        //       variables
        //     }
        //   ]
        // }

        // Simply always refetch the CFP query. The cached data wasn't updating for school
        // context (when context is falsy), but keep in mind this is not a new issue with
        // the upgrade to Apollo Client v3 (was also happening in v2). Probably could alternatively
        // update the cache manually in this case?
        return [
          "ContextualFeaturePermissionsQuery",
          {
            query: CONTEXTUAL_FEATURE_PERMISSION_QUERY,
            variables
          }
        ]
      }
    }),
    canUpdate: useFeatureUpdatePermission(variables).hasPermission
  }

  useEffect(() => {
    if (permission.query.data) {
      // ugly again, having to delay slightly before updating originalPermissionRef
      // as the query data changes as a result of mutation BEFORE the onCompleted callback runs
      setTimeout(() => {
        originalPermissionRef.current =
          permission.query.data.contextualFeaturePermission.permission_in_context
      }, 50)
    }
  }, [permission.query.data])

  return permission
}

// extending permissions for form
const useFeaturePermissions = ({ featureKey, context }) => {
  const permissions = {
    [FEATURE_ACTION_VIEW]: {
      ...useFeaturePermission({
        featureKey,
        featureAction: FEATURE_ACTION_VIEW,
        context
      }),
      getInitialValue: function () {
        if (!this.query.data) return undefined
        return this.query.data.contextualFeaturePermission.permission_in_context
      },
      getFormValue: function (values) {
        return values[this.key]
      }
    },
    [FEATURE_ACTION_UPDATE_VISIBILITY]: {
      ...useFeaturePermission({
        featureKey,
        featureAction: FEATURE_ACTION_UPDATE_VISIBILITY,
        context
      }),
      getInitialValue: function () {
        if (!this.query.data) return undefined
        return (
          this.query.data.contextualFeaturePermission.permission_in_context ===
          FEATURE_PERMISSION_STAFF_ONLY
        )
      },
      getFormValue: function (values) {
        return values[this.key]
          ? FEATURE_PERMISSION_STAFF_ONLY
          : FEATURE_PERMISSION_ADMIN_ONLY
      }
    }
  }

  return permissions
}

const FeaturePermissionsForm = ({ featureKey, context }) => {
  const formikRef = useRef(null)
  const { t } = useTranslate()
  const { title: featureTitle } = getFeatureInfo(featureKey, t)
  const permissions = useFeaturePermissions({ featureKey, context })

  const { loading, error } = Object.values(permissions).reduce(
    (state, { query }) => ({
      loading: state.loading || query.loading,
      error: state.error || query.error
    }),
    {}
  )

  if (loading) {
    return <LoadingCircle center />
  }

  if (error) {
    return <Alert error={error} />
  }

  const initialValues = {
    [FEATURE_ACTION_VIEW]: permissions[FEATURE_ACTION_VIEW].getInitialValue(),
    [FEATURE_ACTION_UPDATE_VISIBILITY]: permissions[FEATURE_ACTION_UPDATE_VISIBILITY].getInitialValue() /* prettier-ignore */
  }

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      // enableReinitialize allows the form to reset to new state after submission
      enableReinitialize
    >
      {(form) => {
        const { values, dirty, setFieldValue, resetForm } = form

        // NOTE: always performing an update mutation even though at times
        // there may not exist a CFP–the backend automatically creates if no CFP exists
        // even when the request is to update an existing CFP
        const handleSubmit = async () => {
          await Promise.all(
            Object.values(permissions)
              // filter out unchanged permissions
              .filter(
                (config) => values[config.key] !== initialValues[config.key]
              )
              // filter out permissions which user cannot update
              .filter((config) => config.canUpdate)
              .map((config) =>
                config.mutation[0]({
                  variables: {
                    permissionInContext: config.getFormValue(values)
                  }
                })
              )
          )
        }

        return (
          <form
            onSubmit={(e) => e.preventDefault()}
            style={{ minWidth: "100%" }}
          >
            <div /* wrapper for View separate prop to work */>
              <View separate paddingTop paddingBottom>
                <FeatureVisibilitySelect
                  labelWidth={200}
                  label={t("Make visible to")}
                  helperText={t(
                    `Sets the visibility of the {{feature}} for all cohorts.`,
                    {
                      feature: featureTitle
                    }
                  )}
                  alignRight
                  value={values[FEATURE_ACTION_VIEW]}
                  onChange={(value) =>
                    setFieldValue(FEATURE_ACTION_VIEW, value, false)
                  }
                  isDisabled={!permissions[FEATURE_ACTION_VIEW].canUpdate}
                  featureKey={featureKey}
                />
              </View>
              {!context && (
                <View separate paddingTop paddingBottom>
                  <ToggleInput
                    labelWidth={260}
                    label={t("Enable staff to customize visibility")}
                    helperText={t(
                      "Enable editors, moderators, and teachers to customize the {{ feature }} visibility for cohorts they have access to.",
                      {
                        feature: featureTitle
                      }
                    )}
                    value={values[FEATURE_ACTION_UPDATE_VISIBILITY]}
                    onChange={(value) =>
                      setFieldValue(
                        FEATURE_ACTION_UPDATE_VISIBILITY,
                        value,
                        false
                      )
                    }
                    disabled={
                      !permissions[FEATURE_ACTION_UPDATE_VISIBILITY].canUpdate
                    }
                    alignRight
                  />
                </View>
              )}
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              {context ? (
                <SubmitButton
                  styleType="primary"
                  onClick={handleSubmit}
                  disabled={!dirty}
                  brand
                >
                  {t("Update Visibility")}
                </SubmitButton>
              ) : (
                <Confirm
                  onConfirm={handleSubmit}
                  onCancel={() => resetForm()}
                  featureKey={featureKey}
                  permission={permissions[FEATURE_ACTION_VIEW].getFormValue(
                    values
                  )}
                >
                  {({ confirm }) => (
                    <Button
                      styleType="primary"
                      onClick={confirm}
                      disabled={!dirty}
                      brand
                    >
                      {t("Update Visibility")}
                    </Button>
                  )}
                </Confirm>
              )}
            </div>
          </form>
        )
      }}
    </Formik>
  )
}

FeaturePermissionsForm.displayName = "FeaturePermissionsForm"

FeaturePermissionsForm.propTypes = {
  ...CONTEXTUAL_FEATURE_PERMISSION_QUERY._propTypes
}

export default FeaturePermissionsForm
