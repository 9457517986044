import { graphql } from "@apollo/client/react/hoc"
import Alert from "@pathwright/ui/src/components/alert/Alert"
import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import Checkbox from "@pathwright/ui/src/components/form/form-checkbox/Checkbox"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import Text from "@pathwright/ui/src/components/ui/Text"
import {
  OVERLAY_GRAY,
  OVERLAY_WHITE,
  PRIMARY_GRAY,
  PRIMARY_WHITE
} from "@pathwright/ui/src/components/utils/colors"
import { media } from "@pathwright/ui/src/components/utils/styles"
import PropTypes from "prop-types"
import { Component } from "react"
import { Field, Form, Submit } from "react-connect-form-forked"
import styled from "styled-components"
import { PathwrightContext } from "../pathwright/PathwrightContext"
import {
  BELLTOWER_TYPE_LABELS,
  SCHOOL_TYPE_ICONS,
  SCHOOL_TYPE_LABELS,
  SCHOOL_TYPE_REFERRER
} from "./constants"
import UPDATE_SCHOOL_PROFILE_MUTATION from "./graphql/update-school-profile-mutation"

const StyledContainer = styled.div``

const StyledSection = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
`

const StyledFooter = styled.footer`
  margin-top: 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
`

const StyledSubmit = styled(SubmitButton)`
  margin-top: 10px;
`

const StyledRadioContainer = styled.label`
  width: 320px;
  padding: 10px;
  text-align: left;
  cursor: pointer;
  @media (max-width: 680px) {
    width: 200px;
  }
  @media (max-width: 440px) {
    width: 150px;
  }
`

const StyledRadioInput = styled.input`
  display: none;
  &:active + div {
    transform: scale(0.95);
  }
  &:checked + div {
    border-color: ${(props) => (props.inverted ? PRIMARY_WHITE : PRIMARY_GRAY)};
  }
`

const StyledRadioBody = styled.div`
  padding: 20px;
  border-radius: 10px;
  transition: all 100ms ease-out;
  border: 1px solid transparent;
  color: ${(props) => (props.inverted ? PRIMARY_WHITE : PRIMARY_GRAY)};
  background-color: ${(props) =>
    props.inverted ? OVERLAY_WHITE : OVERLAY_GRAY};
  display: flex;
  align-items: center;
  .Pathicon {
    margin-right: 10px;
  }
  @media (max-width: 680px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    min-height: 110px;
    .Pathicon {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
`

const StyledCheckbox = styled(Checkbox)`
  ${media.max.phone`max-width: 250px;`};
`

class SignupSchoolProfileForm extends Component {
  state = {
    error: null
  }

  get initialSchoolType() {
    // else check referrer website url
    return Object.keys(SCHOOL_TYPE_REFERRER).reduce((type, key) => {
      if (type) return type
      const regex = new RegExp(key)
      if (
        regex.test(document.referrer) ||
        regex.test(this.props.initialSchoolType)
      ) {
        return SCHOOL_TYPE_REFERRER[key]
      }
      return null
    }, null)
  }

  handleError = (err) => {
    const error = err ? String(err.message || err) : null
    this.setState({ error })
  }

  handleSubmit = (form) => {
    if (this.props.belltower) {
      return this.handleOnNext()
    }

    if (form.school_type || form.plan_to_sell) {
      return this.props
        .updateSchoolProfile({
          id: this.props.createdSchool.profile.id,
          school_type: form.school_type,
          plan_to_sell: form.plan_to_sell
        })
        .then(this.handleOnNext)
    }

    return this.handleOnNext()
  }

  handleOnNext = () => {
    // check if created a Pathwright school
    if (!this.props.licensorId && this.context.school.id === 7888) {
      return this.props.onNext()
    }

    // otherwise redirect to created school
    return (window.location.href = this.state.createdSchool.sso_link)
  }

  render() {
    if (!this.context.me || !this.props.createdSchool) {
      return null
    }
    return (
      <StyledContainer>
        {this.props.renderHeader && this.props.renderHeader()}
        <Form
          initialValue={{ school_type: this.initialSchoolType }}
          onSubmit={this.handleSubmit}
          onSubmitFailure={this.handleError}
        >
          {this.props.licensorId ? (
            <StyledSection>
              {Object.keys(BELLTOWER_TYPE_LABELS).map((key) => (
                <Field
                  key={key}
                  value={key}
                  type="radio"
                  name="belltower_type"
                  component={null}
                  render={(radioProps) => (
                    <StyledRadioContainer>
                      <StyledRadioInput
                        {...radioProps}
                        inverted={this.props.inverted}
                      />
                      <StyledRadioBody inverted={this.props.inverted}>
                        <span>
                          {BELLTOWER_TYPE_LABELS[radioProps.value]} tips
                        </span>
                      </StyledRadioBody>
                    </StyledRadioContainer>
                  )}
                />
              ))}
            </StyledSection>
          ) : this.context.school.id === 7888 ? (
            <StyledSection>
              {Object.keys(SCHOOL_TYPE_LABELS).map((key) => (
                <Field
                  key={key}
                  value={key}
                  type="radio"
                  name="school_type"
                  component={null}
                  render={(radioProps) => (
                    <StyledRadioContainer>
                      <StyledRadioInput
                        {...radioProps}
                        inverted={this.props.inverted}
                      />
                      <StyledRadioBody inverted={this.props.inverted}>
                        <Pathicon icon={SCHOOL_TYPE_ICONS[radioProps.value]} />
                        <span>{SCHOOL_TYPE_LABELS[radioProps.value]} tips</span>
                      </StyledRadioBody>
                    </StyledRadioContainer>
                  )}
                />
              ))}
            </StyledSection>
          ) : null}

          <StyledFooter>
            {!this.props.belltower && (
              <Field
                name="plan_to_sell"
                type="checkbox"
                value={true}
                component={StyledCheckbox}
              >
                <Text.Body inverted={this.props.inverted}>
                  Send tips for selling courses or subscriptions.
                </Text.Body>
              </Field>
            )}
            {this.state.error && (
              <Alert
                error={this.state.error}
                onClear={() => this.handleError(null)}
              />
            )}
            <div style={{ height: 10 }} />
            <Submit
              render={(submitProps) =>
                this.props.renderSubmit ? (
                  this.props.renderSubmit(submitProps)
                ) : (
                  <StyledSubmit
                    {...submitProps}
                    styleType="primary"
                    size="large"
                    inverted
                    hideSuccess
                    disabled={false}
                  >
                    Start teaching
                  </StyledSubmit>
                )
              }
            />
          </StyledFooter>
        </Form>
      </StyledContainer>
    )
  }
}

SignupSchoolProfileForm.displayName = "SignupSchoolProfileForm"

SignupSchoolProfileForm.contextType = PathwrightContext

SignupSchoolProfileForm.propTypes = {
  // used to setup a SchoolCurriculumSubscription
  licensorId: PropTypes.number
}

export default graphql(UPDATE_SCHOOL_PROFILE_MUTATION, {
  props: ({ mutate }) => ({
    updateSchoolProfile: (form) => mutate({ variables: form })
  })
})(SignupSchoolProfileForm)
