import gql from "graphql-tag"
import SCHOOL_GROUP_SUBSCRIPTION_FRAGMENT from "../../subscription/graphql/school-group-subscription-fragment"

export default gql`
  query MentorGroupSubscription($group_id: Int!) {
    school {
      id
      mentor_group(id: $group_id) {
        id
        group_subscriptions(first: 1) {
          total
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              ...SchoolGroupSubscription
            }
          }
        }
      }
    }
  }
  ${SCHOOL_GROUP_SUBSCRIPTION_FRAGMENT}
`
