import PropTypes from "prop-types"
import { PureComponent } from "react"
import isEqual from "react-fast-compare"
import AutoSizer from "react-virtualized/dist/commonjs/AutoSizer"
import InfiniteLoader from "react-virtualized/dist/commonjs/InfiniteLoader"
import List from "react-virtualized/dist/commonjs/List"
import "react-virtualized/styles.css"
import LoadingCircle from "../loading/LoadingCircle"
import "./InfiniteGrid.css"

// Sort of ensures content that otherwise may be clipped (i.e. absolutely possitoned dropdown) will potentially be visible
// const StyledList = styled(List)`
//   ${props =>
//     props.hasItems
//       ? `
//         > * {
//           min-height: 100%;
//         }`
//       : ""}
// `

class InfiniteList extends PureComponent {
  // List component doesn't update for dynamic data
  // so force update if items have changed
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.items, this.props.items)) {
      if (this.list) {
        this.list.forceUpdateGrid()
      }
    }

    if (!isEqual(nextProps.isNextPageLoading, this.props.isNextPageLoading)) {
      if (this.list) {
        this.list.forceUpdateGrid()
      }
    }
  }

  getRowHeight = ({ index }) => {
    const { headerRenderer, rowHeight, headerHeight } = this.props
    if (headerRenderer && index === 0) {
      return headerHeight
    } else {
      return rowHeight
    }
  }

  isRowLoaded = ({ index }) => {
    const { items, hasNextPage } = this.props
    return !hasNextPage || index < items.length
  }

  loadMoreRows = () => {
    const { isNextPageLoading, loadNextPage } = this.props
    if (!isNextPageLoading) {
      const promise = loadNextPage()
      if (promise && promise.then) {
        return promise
      } else {
        console.warn("return promise to refresh items in view.")
      }
    }
    return false
  }

  rowRenderer = (row) => {
    const { rowRenderer, headerRenderer, loadingRenderer } = this.props
    if (headerRenderer && row.index === 0) {
      return headerRenderer()
    } else if (this.isRowLoaded(row)) {
      return rowRenderer(row)
    } else {
      return loadingRenderer(row)
    }
  }

  noRowsRenderer = () => {
    const { isNextPageLoading, noRowsRenderer, loadingRenderer } = this.props
    if (isNextPageLoading) {
      return loadingRenderer()
    } else {
      return noRowsRenderer()
    }
  }

  recomputeRowHeights = () => {
    return this.list.recomputeRowHeights()
  }

  render() {
    const { items, className, hasNextPage, selectedItem, overscanRowCount } =
      this.props
    // If there are more items to be loaded then add an extra row to hold a loading indicator.
    const rowCount = items.length
      ? hasNextPage
        ? items.length + 1
        : items.length
      : 0

    return (
      <InfiniteLoader
        isRowLoaded={this.isRowLoaded}
        loadMoreRows={this.loadMoreRows}
        rowCount={rowCount}
      >
        {({ onRowsRendered, registerChild }) => (
          <AutoSizer>
            {({ width, height }) => (
              <List
                hasItems={rowCount > 0}
                ref={(c) => {
                  this.list = c
                  registerChild(c)
                }}
                className={className}
                width={width}
                height={height}
                rowCount={rowCount}
                rowHeight={this.getRowHeight}
                overscanRowCount={overscanRowCount}
                onRowsRendered={onRowsRendered}
                noRowsRenderer={this.noRowsRenderer}
                rowRenderer={this.rowRenderer}
                selectedItem={selectedItem}
              />
            )}
          </AutoSizer>
        )}
      </InfiniteLoader>
    )
  }
}

InfiniteList.displayName = "InfiniteList"

InfiniteList.propTypes = {
  // Are there more items to load?
  hasNextPage: PropTypes.bool,
  // Are we currently loading a page of items?
  isNextPageLoading: PropTypes.bool,
  // Length of items loaded so far.
  items: PropTypes.array.isRequired,
  // Callback function responsible for loading the next page of items.
  loadNextPage: PropTypes.func,

  rowRenderer: PropTypes.func.isRequired,

  loadingRenderer: PropTypes.func.isRequired
}

InfiniteList.defaultProps = {
  loadingRenderer(row) {
    return (
      <div
        key={row && row.index}
        style={{
          position: "relative",
          ...(row ? row.style : {})
        }}
      >
        <LoadingCircle />
      </div>
    )
  },
  noRowsRenderer() {
    return null
  }
}

export default InfiniteList
