import { graphql } from "@apollo/client/react/hoc"
import Alert from "@pathwright/ui/src/components/alert/Alert"
import Card from "@pathwright/ui/src/components/card/Card"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import compose from "lodash/flowRight"
import get from "lodash/get"
import isEmpty from "lodash/isEmpty"
import PropTypes from "prop-types"

import ACCOUNT_QUERY from "../../account/graphql/account-query"
import SCHOOL_BILLING_INVOICE_UPCOMING_QUERY from "../graphql/school-billing-invoice-upcoming-query"
import { InvoiceType } from "../types"
import ManageBillingInvoiceItemDetails from "./ManageBillingInvoiceItemDetails"

export const ManageBillingStatus = ({
  card,
  invoice,
  account,
  loading,
  error
}) => {
  // const fakeData = getBillingTestData({})
  // invoice = fakeData.invoice
  // account = { subscription: fakeData.subscription }

  const isCanceled = !isEmpty(
    get(account, "billing_subscription.subscription.canceled_dtime")
  )

  return (
    <Card
      className="ManageBillingStatus"
      card={card}
      title={`Your Plan ${isCanceled ? "(Inactive)" : "& Next Bill Estimate"}`}
    >
      {error && <Alert error={error} />}
      {loading ? (
        <LoadingCircle />
      ) : (
        <ManageBillingInvoiceItemDetails
          loading={loading}
          invoice={invoice}
          subscription={get(account, "billing_subscription")}
          schoolScholarship={get(account, "scholarship")}
        />
      )}
    </Card>
  )
}

ManageBillingStatus.displayName = "ManageBillingStatus"

ManageBillingStatus.propTypes = {
  invoice: InvoiceType,
  loading: PropTypes.bool,
  account: PropTypes.object
}

export default compose(
  graphql(ACCOUNT_QUERY, {
    props: ({ data }) => {
      const { error, loading, account } = data
      return {
        error,
        loading,
        account
      }
    }
  }),
  graphql(SCHOOL_BILLING_INVOICE_UPCOMING_QUERY, {
    props: ({ data }) => ({
      invoice: get(data, "school.upcoming_school_billing_invoice")
    })
  })
)(ManageBillingStatus)
