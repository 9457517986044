// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
// Creating a new Resource model here that is just for save, delete
const Resource = require("./resource-admin-model").default
const Offering = require("library/models/offering").default
const OfferingCollection = require("library/models/offering-collection").default

class ResourceAdminStore extends require("lib/static-shim").default(
  require("lib/core/store/backbone-store").default
) {
  static initClass() {
    this.prototype.defaults = {
      resource: null, // the resource being created/updated/selected
      offerings: null, // the offerings on the resource

      is_saving_resource: false,
      is_deleting_resource: false,
      resource_loading: false,
      resource_error: null,
      resource_success: null,

      offerings_loading: false,
      offerings_error: null
    }

    this.prototype.resources = {
      resource: {
        model: Resource
      },
      offering: {
        model: Offering,
        collection: OfferingCollection
      }
    }

    this.prototype.storeTriggers = {
      "resource:created"(resource) {
        return resource
      },
      "resource:updated"() {
        return this.get("resource")
      },
      "resource:deleted"(resource_id) {
        return resource_id
      }
    }

    this.prototype.storeTriggerHandlers = {
      groupAdmin: {
        "offering:created"(offering) {
          let resource_id
          let { resource } = this.getState()
          if (!resource) {
            ;({ resource } = this.getStore("resource").getState())
          }
          if (resource) {
            resource_id = resource.id
          }
          return this.action.loadOfferings(resource_id, true)
        }
      }
    }
    // categoryAdmin:
    //   "category:created": (category)->
    //     resource = @get("resource")
    //     if resource?
    //       categories = _.clone(resource.toJSON().categories)
    //       categories.push(category.id)
    //       @action.saveResource({ categories })
    //
    // authorAdmin:
    //   "author:created": (author)->
    //     resource = @get("resource")
    //     if resource?
    //       authors = _.clone(resource.toJSON().authors)
    //       authors.push(author.id)
    //       @action.saveResource({ authors })

    this.prototype.actions = {
      saveResource(resourceData) {
        const isNew = __guard__(this.get("resource"), (x) => x.id) == null
        return this.resource.resource.save(resourceData).then((resource) => {
          if (resource) {
            this.action.setSuccessTimeout("resource_success", "Saved")
            if (isNew) {
              this.storeTrigger("resource:created", resource)
            } else {
              this.storeTrigger("resource:updated")
            }
            return resource
          }
        })
      },

      deleteResource(id) {
        let resource = this.get("resource")
        if (!resource || (id != null && resource.id !== id)) {
          resource = new Resource({ id })
        }
        this._mutate({ is_deleting_resource: true, resource_error: null })
        return new Promise(
          function (resolve, reject) {
            return resource
              .destroy()
              .then(() => {
                this.storeTrigger("resource:deleted", id)
                this._mutate({ resource: null })
                this.resource.resource.clearCache()
                return resolve()
              })
              .fail((err) => {
                this._mutate({ resource_error: err })
                return reject(err)
              })
              .always(() => {
                return this._mutate({ is_deleting_resource: false })
              })
          }.bind(this)
        )
      },

      loadResource(id, force) {
        if (force == null) {
          force = false
        }
        return this.resource.resource.load({ id }, force)
      },

      selectResource(id) {
        return this.resource.resource.load({ id })
      },

      loadOfferings(resource_id, force) {
        if (force == null) {
          force = false
        }
        if (resource_id == null) {
          resource_id = this.get("resource").id
        }
        resource_id = parseInt(resource_id)
        return this.resource.offering
          .list({ resource: resource_id, manage: 1 }, force)
          .catch((error) => console.log("error", error))
          .then((offerings) =>
            // filter out curriculum offerings
            offerings.toJSON().filter((g) => !g.is_curriculum)
          )
      },

      loadMoreOfferings() {
        const offerings = this.get("offerings")
        const loading = this.get("offerings_loading")

        if (!loading) {
          return new Promise(
            function (resolve, reject) {
              this._mutate({ offerings_loading: true })
              return offerings.next().then(() => {
                this._mutate({ offerings_loading: false })
                this.triggerChange("change:offerings")
                return resolve()
              })
            }.bind(this)
          )
        }
      },

      selectNewResource(data) {
        if (data == null) {
          data = {}
        }
        data = _.defaults(data, {
          resource_type: 10, // course
          categories: [],
          authors: []
        })
        if (!data.cover_image) {
          data.cover_image = this.getRandomCoverImage()
        }
        const resource = new Resource(data)
        this._mutate({ resource, offerings: null })
        return resource
      },

      unselectResource() {
        return this.reset()
      },

      setSuccessTimeout(key, message) {
        this._mutate({ [key]: message })
        return setTimeout(() => {
          return this._mutate({ [key]: null })
        }, 1000)
      }
    }

    this.prototype.requests = {
      hasUnloadedOfferings() {
        return __guard__(this.get("offerings"), (x) => x.nextPage) > -1
      },

      getCurriculumOffering() {
        const { offerings } = this.getState()
        return _.find(offerings, (o) => {
          return o.is_curriculum
        })
      }
    }
  }

  getRandomCoverImage() {
    return `https://pathwright-static-media.s3.amazonaws.com/default-image-set-v1/cover-image-${_.random(
      1,
      72
    )}.png`
  }
}
ResourceAdminStore.initClass()

export default window.App.stores.registerStore(
  "resourceAdmin",
  ResourceAdminStore
)

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null
    ? transform(value)
    : undefined
}
