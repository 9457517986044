import {
  Badge,
  Box,
  Button,
  Text as CharkaText,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spinner
} from "@chakra-ui/react"
import useDebouncedValue from "@pathwright/ui/src/components/hooks/useDebouncedValue"
import useDidMountEffect from "@pathwright/ui/src/components/hooks/useDidMountEffect"
import Image from "@pathwright/ui/src/components/image/Image"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import { useObserveSize } from "@pathwright/ui/src/components/observers/ObserveSizeContext"
import PickerDropdown from "@pathwright/ui/src/components/picker/PickerDropdown"
import * as PickerUI from "@pathwright/ui/src/components/picker/ui"
import Text from "@pathwright/ui/src/components/ui/Text"
import { TERTIARY_GRAY } from "@pathwright/ui/src/components/utils/colors"
import Pathicon from "@pathwright/web/src/modules/pathicon/Pathicon"
import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import gql from "graphql-tag"
import get from "lodash/get"
import PropTypes from "prop-types"
import { useEffect, useMemo, useRef, useState } from "react"
import { useHotkeys, useHotkeysContext } from "react-hotkeys-hook"
import styled from "styled-components"
import LicensorLibraryPickerPrompt, {
  LicensorLibraryPickerBlankSlate
} from "../../library/picker/LicensorLibraryPickerPrompt"
import {
  RESOURCE_TYPE_COLLECTION,
  RESOURCE_TYPE_COURSE,
  RESOURCE_TYPE_LABELS
} from "../../resource/constants"
import useRandomThemeResourceImage from "../../resource/query/useRandomThemeResourceImage"
import CURRICULUM_SUBSCRIPTIONS_QUERY from "../../school/graphql/curriculum-subscriptions-query"
import STORE_LINKS_QUERY from "../../store/graphql/store-links-query"
import { flattenEdges, usePaginator } from "../../utils/apollo"

const RESOURCES_QUERY = gql`
  query ResourcesQuery(
    $cursor: String
    $resourceTypes: ResourceTypeFilter
    $search: String
  ) {
    resources(
      first: 25
      after: $cursor
      resource_types: $resourceTypes
      search: $search
      licensed_from_school: { eq: null }
    ) {
      total
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          name
          resource_type
          image(width: 100, height: 75, fit: crop)
        }
      }
    }
  }
`

const SCHOOL_IMAGE_QUERY = gql`
  query SchoolImageQuery {
    school {
      id
      image(width: 100, height: 75, fit: crop)
    }
  }
`

const useLicensors = (options) => {
  const storeLinksQuery = useQuery(STORE_LINKS_QUERY, options)
  const curriculumSubscriptionsQuery = useQuery(
    CURRICULUM_SUBSCRIPTIONS_QUERY,
    options
  )

  return useMemo(() => {
    const storeLinks = flattenEdges(get(storeLinksQuery, "data.storeLinks"))
    const curriculumSubscriptions = flattenEdges(
      get(curriculumSubscriptionsQuery, "data.school.curriculum_subscriptions")
    )

    const licensors = [
      ...(storeLinks || []).map((storeLink) => storeLink.store.licensor),
      ...(curriculumSubscriptions || []).map(
        (curriculumSubscription) => curriculumSubscription.licensor
      )
    ]

    return licensors
  }, [storeLinksQuery.data, curriculumSubscriptionsQuery.data])
}

// include licensor picker prompts for licensing other resources
const ResourcePickerLicensor = ({ noResults, onOpenStore }) => {
  const licensors = useLicensors()

  const licensorId = get(licensors, "[0].id")
  // if there is no licensor found, render nothing
  if (!licensorId) return null

  // if there are no resources, show blank slate for first licensor (TODO: handle multiple licensors case?)
  if (noResults) {
    return <LicensorLibraryPickerBlankSlate licensorId={licensorId} />
  }

  // show prompt for each licensor
  return (
    <>
      {licensors.map((licensor) => (
        <LicensorLibraryPickerPrompt
          key={`store-licensor-${licensor.id}`}
          licensorId={licensor.id}
          onOpenStore={onOpenStore}
        />
      ))}
    </>
  )
}

const ResourcePicker = ({
  resourceType,
  onChangeResourceName,
  preSelectedResource,
  selectedResource,
  onSelectResource,
  onSelectResourceCoverImage,
  onOpenStore,
  onFocusCohortNameInput
}) => {
  // For exactly setting the right padding on the text input for accounting
  // for the input right element.
  const [inputRightElementRectValue, __, setInputRightElementNodeRef] =
    useObserveSize()
  const [showingPicker, setShowingPicker] = useState(false)
  const [focused, setFocused] = useState(false)
  const { t } = useTranslate()
  const [search, debouncedSearch, debouncingSearch, handleDebounceSearch] =
    useDebouncedValue(300)
  const inputRef = useRef(null)

  function toggleShowingPicker() {
    setShowingPicker((showingPicker) => !showingPicker)
  }

  function onClose() {
    setShowingPicker(false)
  }
  // Scope the "esc" hotkey to the picker, so that the hotkey doesn't propagate.
  const { enableScope, disableScope } = useHotkeysContext()
  useHotkeys("esc", onClose, { scopes: ["picker"] })
  useEffect(() => {
    showingPicker ? enableScope("picker") : disableScope("picker")
  }, [showingPicker])

  const randomResourceCover = useRandomThemeResourceImage()

  useEffect(() => {
    onSelectResourceCoverImage(randomResourceCover)
  }, [randomResourceCover])

  const useResourcesQuery = () => {
    const resourcesQuery = useQuery(RESOURCES_QUERY, {
      variables: {
        search: debouncedSearch,
        resourceTypes: { eq: RESOURCE_TYPE_LABELS[resourceType] }
      }
    })

    const { data, error, loading } = resourcesQuery

    const { loadMore, hasMore, loadingMore } = usePaginator({
      data: resourcesQuery, // hacky
      path: "resources"
    })

    // const activeSearch = resourcesQuery.loading ? debouncedSearch :
    const resources = (flattenEdges(get(data, "resources")) || []).sort(
      // the closer to the beggining of the resource.name that the debouncedSearch matches,
      // the closer to the begging of the list the resource should be moved
      (resourceA, resourceB) =>
        // check against current query search variable
        resourceA.name.indexOf(resourcesQuery.variables.search) -
        resourceB.name.indexOf(resourcesQuery.variables.search)
    )

    return {
      resourcesQuery,
      pickerItems: resources,
      loading,
      loadingMore,
      loadMore,
      hasMore
    }
  }

  const {
    resourcesQuery,
    pickerItems: resources,
    loading
  } = useResourcesQuery()
  const totalResources = resourcesQuery.data?.resources?.total

  const isSearching = showingPicker && (debouncingSearch || loading)

  // for legacy courses that have no image set
  const schoolImageQuery = useQuery(SCHOOL_IMAGE_QUERY, {
    skip:
      !resources ||
      !resources.length ||
      !resources.find((resource) => !resource.image)
  })

  useEffect(() => {
    inputRef.current?.focus()
  }, [showingPicker])

  useDidMountEffect(() => {
    onChangeResourceName(search)
    if (selectedResource && selectedResource.name !== search)
      onSelectResource(null)
  }, [search])

  useEffect(() => {
    if (selectedResource) handleDebounceSearch(selectedResource.name)
  }, [selectedResource])

  const handleSelectResource = (resource) => {
    onSelectResource(resource)
    setShowingPicker(false)
  }

  // Closes picker and auto focuses cohort name input.
  function handleFocusCohortNameInput() {
    setShowingPicker(false)
    onFocusCohortNameInput()
  }

  const coverImage = selectedResource
    ? selectedResource.image
    : randomResourceCover

  return (
    <ResourcePickerContainer>
      <PickerUI.Row style={{ position: "relative", overflow: "visible" }}>
        <PickerUI.Icon>
          <Image
            src={coverImage}
            _loading={{
              width: "100%",
              opacity: 1,
              backgroundColor: TERTIARY_GRAY
            }}
          />
        </PickerUI.Icon>
        {preSelectedResource ? (
          <Text.H5>{preSelectedResource.name}</Text.H5>
        ) : (
          <InputGroup>
            {showingPicker && (
              <InputLeftElement>
                {/* Only show spinner when searching. */}
                {isSearching ? (
                  <Spinner size="sm" />
                ) : (
                  <Pathicon size="1.1em" icon="search-list" />
                )}
              </InputLeftElement>
            )}
            <Input
              ref={inputRef}
              type="text"
              name="path name"
              value={search}
              autoFocus={!selectedResource}
              onChange={(e) => handleDebounceSearch(e.target.value)}
              placeholder={
                showingPicker
                  ? t("path.new_path_form.existing", { count: totalResources })
                  : t("path.new_path_form.new")
              }
              onFocus={() => setFocused(true)}
              onBlur={() => setTimeout(() => setFocused(false), 250)}
              onKeyDown={(e) => {
                // When user hits enter, either select existing resource when
                // showing picker, or advance to cohort input when 'search' value exists.
                if (e.keyCode === 13) {
                  if (showingPicker && resources && resources.length) {
                    handleSelectResource(resources[0])
                  } else if (search) {
                    handleFocusCohortNameInput()
                  }
                }
                // Ideally this would be handled by useHotkeys but for some reason it's not
                // when escape is keyed while input is focused.
                if (e.key === "Escape") {
                  setShowingPicker(false)
                }
              }}
              pr={`${inputRightElementRectValue?.width}px`}
              backgroundColor={showingPicker ? "gray.100" : "white.400"}
              sx={{
                // NOTE: the transition isn't applying. No idea why.
                transition: "background-color .3s ease"
              }}
            />
            <InputRightElement
              ref={setInputRightElementNodeRef}
              width="auto"
              justifyContent="flex-end"
            >
              <Button
                onClick={toggleShowingPicker}
                cursor="pointer"
                variant="solid"
                m="0 4px"
                size="sm"
                pos="relative"
                // For some reason the default focus styles aren't applied unless we
                // include the _focus prop...
                _focus={{}}
              >
                <HStack p="0 6px" justifyContent="space-between" w="100%">
                  {!showingPicker && (
                    <Pathicon size="1.1em" icon="search-list" />
                  )}
                  {/* Only show total resources count when > 0 */}
                  {!showingPicker && (
                    <>
                      <CharkaText as="span" opacity="1">
                        Select...
                      </CharkaText>
                      {!!totalResources && (
                        <Badge
                          pos="absolute"
                          top="-3px"
                          right="-6px"
                          borderRadius="4px"
                          border="1px solid white"
                          fontSize=".95em"
                          color="gray.600"
                          bg="gray.300"
                        >
                          {totalResources}
                        </Badge>
                      )}
                    </>
                  )}

                  {/* Only show show picker "x" when picking. */}
                  {showingPicker && <Pathicon size="1.1em" icon="x" />}
                </HStack>
              </Button>
            </InputRightElement>
          </InputGroup>
        )}
        {showingPicker && (
          // TODO: handle up and down arrows to highlight items (and then select with enter keypress)
          <PickerDropdown
            prompt={null}
            searchPrompt={null}
            usePickerQuery={useResourcesQuery}
            onPick={handleSelectResource}
            getPickerItem={(pickerItem) => ({
              ...pickerItem,
              image:
                pickerItem.image || get(schoolImageQuery, "data.school.image")
            })}
          >
            {({ pickerList }) => (
              <>
                {pickerList || (
                  <Box p={4} textAlign="center">
                    <Text as="span">
                      {`${t("path.new_path_form.no_results", { search })} `}
                      <Button
                        variant="link"
                        bg="none"
                        cursor="pointer"
                        onClick={handleFocusCohortNameInput}
                        size="lg"
                      >
                        {t("path.new_path_form.no_results_prompt")}
                      </Button>
                    </Text>
                  </Box>
                )}
                <ResourcePickerLicensor
                  // for now, let's not show the no results blank slate
                  // since we're filtering out licensed resources from the resources query
                  // noResults={!resources.length && !debouncedSearch}
                  onOpenStore={onOpenStore}
                />
              </>
            )}
          </PickerDropdown>
        )}
      </PickerUI.Row>
    </ResourcePickerContainer>
  )
}

ResourcePicker.displayName = "ResourcePicker"

ResourcePicker.propTypes = {
  resourceType: PropTypes.oneOf([
    RESOURCE_TYPE_COLLECTION,
    RESOURCE_TYPE_COURSE
  ]),
  onChangeResourceName: PropTypes.func.isRequired,
  preSelectedResource: PropTypes.object,
  selectedResource: PropTypes.object,
  onSelectResource: PropTypes.func.isRequired,
  onSelectResourceCoverImage: PropTypes.func.isRequired
}

const ResourcePickerContainer = styled.div`
  width: 100%;

  .TextInput {
    flex-grow: 1;
  }

  /* highlight the first result to indicate selectable */
  .PickerList .PickerListItem:first-child {
    background: var(--item-background-color);
  }

  /* remove highlight of the first result when hovering over the results list (but not over the highlighted item) */
  @media (hover: hover) {
    .Picker:hover .PickerList .PickerListItem:first-child:not(:hover) {
      background: initial;
    }
  }
`

export default ResourcePicker
