import gql from "graphql-tag"
import ORDER_FRAGMENT from "./order-fragment"

export default gql`
  mutation CreateGroupSubscriptionOrder(
    $price_id: Int!
    $quantity: Int!
    $group_name: String!
    $redirect_to: String
  ) {
    createGroupSubscriptionOrder(
      price_id: $price_id
      quantity: $quantity
      group_name: $group_name
      redirect_to: $redirect_to
    ) {
      ...Order
    }
  }
  ${ORDER_FRAGMENT}
`
