import { useApolloClient } from "@apollo/client"
import Alert from "@pathwright/ui/src/components/alert/Alert"
// import PopupAlert from "@pathwright/ui/src/components/alert/PopupAlert"
import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import useControlledState from "@pathwright/ui/src/components/hooks/useControlledState"
import withTranslate from "@pathwright/ui/src/components/lng/withTranslate"
import { PLAN_INTERVALS } from "@pathwright/web/src/modules/pricing/lib/utils"
import path from "path-browserify"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import styled from "styled-components"
import { usePathwrightContext } from "../pathwright/PathwrightContext"
import { trackEvent } from "../plausible/events"
import { ORDER_CREATED_EVENT, getUTMParameters } from "../utils/dispatcher"
import CREATE_CURRICULUM_LICENSE_ORDER_MUTATION from "./graphql/create-curriculum-license-order-mutation"
import CREATE_CURRICULUM_SUBSCRIPTION_ORDER_MUTATION from "./graphql/create-curriculum-subscription-order-mutation"
import CREATE_GIFT_SUBSCRIPTION_ORDER_MUTATION from "./graphql/create-gift-subscription-order-mutation"
import CREATE_GROUP_LICENSE_ORDER_MUTATION from "./graphql/create-group-license-order-mutation"
import CREATE_GROUP_SUBSCRIPTION_ORDER_MUTATION from "./graphql/create-group-subscription-order-mutation"
import CREATE_LIFETIME_SCHOOL_SUBSCRIPTION_ORDER_MUTATION from "./graphql/create-lifetime-school-subscription-order-mutation"
import CREATE_PLATFORM_SUBSCRIPTION_ORDER_MUTATION from "./graphql/create-platform-subscription-order-mutation"
import CREATE_REGISTRATION_ORDER_MUTATION from "./graphql/create-registration-order-mutation"
import CREATE_SCHOOL_SUBSCRIPTION_ORDER_MUTATION from "./graphql/create-school-subscription-order"
import { dispatchOrderEvent } from "./utils/order-events"

const CenteredContent = styled.div`
  padding: 10px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
`

export const useOrderStripeCheckout = ({ orderType, orderData }) => {
  const apolloClient = useApolloClient()
  const { me } = usePathwrightContext()

  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [order, setOrder] = useState(null)
  const clearError = useCallback(() => setError(null), [])

  const handleOrder = useCallback(async () => {
    try {
      setError(null)
      setLoading(true)

      let mutationResult
      if (orderType === "createCurriculumLicenseOrder") {
        mutationResult = await apolloClient.mutate({
          mutation: CREATE_CURRICULUM_LICENSE_ORDER_MUTATION,
          variables: {
            license_offering_id: orderData.license_offering_id,
            licensee_school_id: orderData.licensee_school_id,
            licensor_school_id: orderData.licensor_school_id,
            name: orderData.name,
            seat_count: orderData.seat_count,
            role: orderData.role,
            coupon_code: orderData.coupon_code
          }
        })
      }
      if (orderType === "createCurriculumSubscriptionOrder") {
        mutationResult = await apolloClient.mutate({
          mutation: CREATE_CURRICULUM_SUBSCRIPTION_ORDER_MUTATION,
          variables: {
            curriculum_plan_id: orderData.curriculum_plan_id,
            plan_visibility_code: orderData.plan_visibility_code,
            licensee_id: orderData.licensee_id
          }
        })
      }
      if (orderType === "createGiftSubscriptionOrder") {
        mutationResult = await apolloClient.mutate({
          mutation: CREATE_GIFT_SUBSCRIPTION_ORDER_MUTATION,
          variables: {
            plan_id: orderData.plan_id,
            plan_visibility_code: orderData.plan_visibility_code,
            num_cycles: orderData.num_cycles,
            recipient_first_name: orderData.recipient_first_name,
            recipient_last_name: orderData.recipient_last_name,
            gift_message: orderData.gift_message
          }
        })
      }
      if (orderType === "createGroupLicenseOrder") {
        mutationResult = await apolloClient.mutate({
          mutation: CREATE_GROUP_LICENSE_ORDER_MUTATION,
          variables: {
            license_offering_id: orderData.license_offering_id,
            name: orderData.name,
            seat_count: orderData.seat_count,
            role: orderData.role,
            coupon_code: orderData.coupon_code
          }
        })
      }
      if (orderType === "createLifetimeSchoolSubscriptionOrder") {
        mutationResult = await apolloClient.mutate({
          mutation: CREATE_LIFETIME_SCHOOL_SUBSCRIPTION_ORDER_MUTATION,
          variables: {
            plan_id: orderData.plan_id,
            coupon_code: orderData.coupon_code,
            plan_visibility_code: orderData.plan_visibility_code
          }
        })
      }
      if (orderType === "createRegistrationOrder") {
        mutationResult = await apolloClient.mutate({
          mutation: CREATE_REGISTRATION_ORDER_MUTATION,
          variables: {
            offering_id: orderData.offering_id,
            coupon_code: orderData.coupon_code,
            invitation_code: orderData.invitation_code,
            use_subscription_plan_id: orderData.use_subscription_plan_id,
            use_group_subscription_id: orderData.use_group_subscription_id
          }
        })
      }
      if (orderType === "createSchoolSubscriptionOrder") {
        mutationResult = await apolloClient.mutate({
          mutation: CREATE_SCHOOL_SUBSCRIPTION_ORDER_MUTATION,
          variables: {
            plan_id: orderData.plan_id,
            plan_visibility_code: orderData.plan_visibility_code,
            start_free_trial: orderData.start_free_trial,
            coupon_code: orderData.coupon_code,
            redirect_to: orderData.redirect_to
          }
        })
      }
      if (orderType === "createPlatformSubscriptionOrder") {
        mutationResult = await apolloClient.mutate({
          mutation: CREATE_PLATFORM_SUBSCRIPTION_ORDER_MUTATION,
          variables: {
            billing_plan_id: orderData.billing_plan_id,
            interval: orderData.interval,
            schoolId: orderData.schoolId
          }
        })
      }
      if (orderType === "createGroupSubscriptionOrder") {
        mutationResult = await apolloClient.mutate({
          mutation: CREATE_GROUP_SUBSCRIPTION_ORDER_MUTATION,
          variables: {
            price_id: orderData.price_id,
            quantity: orderData.quantity,
            group_name: orderData.group_name,
            redirect_to: orderData.redirect_to
          }
        })
      }

      if (!mutationResult) {
        throw new Error("Order not recognized.")
      }

      let order = mutationResult.data && mutationResult.data[orderType]

      if (!order) {
        throw new Error("Unable to process order.")
      }

      // Track this event in Plausible
      if (orderType === "createPlatformSubscriptionOrder") {
        const { schoolId, plan = {}, customSource } = orderData
        const user = me.display_name
        const interval = Object.keys(PLAN_INTERVALS)
          .find((int) => PLAN_INTERVALS[int].id === orderData.interval)
          .toLowerCase()

        let { utm_source } = getUTMParameters()

        const source =
          customSource || utm_source || document.referrer || "unknown"

        trackEvent({
          event: "Platform Checkout Test 2",
          props: { schoolId, user, interval, plan: plan.name, source }
        })
      }

      setOrder(order)
      dispatchOrderEvent(ORDER_CREATED_EVENT, { user: me, order })
    } catch (error) {
      setError(error.message)
      throw error
    } finally {
      setLoading(false)
    }
  }, [orderType, orderData])

  return {
    order,
    handleOrder,
    loading,
    error,
    clearError
  }
}

// helper for handling order fulfillment after an order has been created
// either the user will be redirected to Stripe Checkout to fulfill the order
// via payment/coupon or, in the case of an order that does not require a transaction
// the order is automatically fulfilled and they will be redirected to the confirmation
export const useOrderFulfillment = (
  // options are not required
  options = {
    order: null,
    stripeApiKey: null
  }
) => {
  // optionally controlling internal state
  const [order, setOrder] = useControlledState(options.order)

  // getting contextQuery in order to refetch after non-stripe transaction
  // (since Stripe Checkout will result in a browser refresh of Pathwright anyhow)
  const { query: contextQuery, school } = usePathwrightContext()

  // defaulting to school's Stripe key
  const stripeApiKey = options.stripeApiKey || school.stripe_publishable_api_key

  const handleOrderFulfillment = (order) => {
    if (order.checkout_session_id) {
      window.Stripe(stripeApiKey).redirectToCheckout({
        sessionId: order.checkout_session_id
      })
    } else {
      // refetching context mainly in order to update the user's group_role_stats
      // which controls what Home UIs are available to the user
      contextQuery.refetch()

      // If order doesn't return a Stripe checkout session we
      // can simply navigate to order confirmation screen.
      // TODO: would be better to receive the redirect URL from the server.
      let confirmationUrl = path.join(
        window.location.pathname,
        `order/${order.id}/thank-you/`
      )
      window.App.navigate(confirmationUrl)
    }
  }

  useEffect(() => {
    if (order) handleOrderFulfillment(order)
  }, [order])

  // return an optional setter in case order is not passed in as a prop
  return setOrder
}

function useIsAwaitingCheckout({ order, loading, error }) {
  // We're awaiting Stripe checkout to open when loading the order, or the order exists
  // as long as there's no error.
  const isAwaitingCheckout = useMemo(() => {
    return !!(order || loading) && !error
  }, [order, loading, error])

  return isAwaitingCheckout
}

// Somewhat streamlined version of the the <OrderStripeCheckoutForm /> where
// the expectation is that the order will result in a checkout session,
// and consumers can render a button as seen fit in the calling component.
export function useStripeOrder({ orderType, orderData, stripeApiKey }) {
  const { order, handleOrder, loading, error, clearError } =
    useOrderStripeCheckout({
      orderType,
      orderData
    })

  const isAwaitingCheckout = useIsAwaitingCheckout({
    order,
    loading,
    error
  })

  useEffect(() => {
    if (order) {
      window.Stripe(stripeApiKey).redirectToCheckout({
        sessionId: order.checkout_session_id
      })
    }
  }, [order])

  return {
    order,
    handleOrder,
    loading: isAwaitingCheckout,
    error,
    clearError
  }
}

const OrderStripeCheckoutForm = function OrderStripeCheckoutForm({
  disabled,
  orderType,
  orderData,
  stripeApiKey,
  renderButton,
  maintenanceModeOverride = false,
  submitLabel = "Go To Checkout",
  ...rest
}) {
  const { platformConfig } = usePathwrightContext()

  const { order, handleOrder, loading, error, clearError } =
    useOrderStripeCheckout({
      orderType,
      orderData
    })

  const isAwaitingCheckout = useIsAwaitingCheckout({
    order,
    loading,
    error
  })

  useOrderFulfillment({ order, stripeApiKey })

  if (platformConfig.payments_maintenance_mode && !maintenanceModeOverride) {
    return (
      <CenteredContent>
        <BlankSlate
          icon="caution-triangle"
          heading="Payments Under Maintenance."
          body={
            <span>
              Please{" "}
              <a href="https://status.pathwright.com/" target="_blank">
                check back
              </a>{" "}
              shortly to complete your purchase.
            </span>
          }
        />
      </CenteredContent>
    )
  }

  if (renderButton) {
    return (
      <React.Fragment>
        {renderButton({
          disabled,
          order,
          isAwaitingCheckout,
          onSubmit: handleOrder,
          error,
          clearError,
          submitLabel
        })}
        {/* {isAwaitingCheckout && <PopupAlert loading>Loading...</PopupAlert>} */}
        <Alert error={error} onClear={clearError} />
      </React.Fragment>
    )
  }

  return (
    <CenteredContent>
      <SubmitButton
        styleType="primary"
        size="large"
        brand
        {...rest}
        submitting={isAwaitingCheckout}
        onSubmit={handleOrder}
        disabled={disabled}
      >
        {submitLabel}
      </SubmitButton>
      <Alert error={error} onClear={clearError} />
    </CenteredContent>
  )
}

OrderStripeCheckoutForm.displayName = "OrderStripeCheckoutForm"

export default withTranslate(OrderStripeCheckoutForm)
