import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import get from "lodash/get"
import { flattenEdges } from "../../utils/apollo"
import STRIPE_PRODUCTS_QUERY from "./stripe-products-query"

export default function useStripeProducts(variables) {
  const query = useQuery(STRIPE_PRODUCTS_QUERY, {
    variables: { first: 50, ...variables }
  })
  const stripeProducts = flattenEdges(get(query, "data.stripeProducts")) || []
  return [stripeProducts, query]
}
