import ThemeBackground from "@pathwright/ui/src/components/background/ThemeBackground"
import Portal from "@pathwright/ui/src/components/portal/Portal"
import ScrollView from "@pathwright/ui/src/components/scroll/ScrollView"
import styled, { keyframes } from "styled-components"
import Activity from "../activity/Activity"
import AccountConfig from "./AccountConfig"
import AccountLogo from "./AccountLogo"
import Places from "./Places"

const shrinkIn = keyframes`
	from {
		transform: scale(1.5);
		opacity: 0.5;
	}
	to {
		transform: scale(1);
		opacity: 1;
	}
`

const StyledContainer = styled.div`
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  animation: ${shrinkIn} 500ms ease-out;
  transform-origin: top center;
`

const StyledScroll = styled(ScrollView)`
  position: relative;
  height: 100vh;
  padding: 50px 0;
`

const StyledWrapper = styled.div`
  position: relative;
  max-width: 1200px;
  min-height: 500px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: white;
`

const AdminDashboard = () => (
  <StyledContainer>
    <Portal portalElementID="theme-portal">
      <ThemeBackground />
    </Portal>
    <StyledScroll fill>
      <AccountLogo />
      <AccountConfig />
      <StyledWrapper>
        <Places />
      </StyledWrapper>
      <Activity canViewAvatarCard canViewAvatarProfile />
    </StyledScroll>
  </StyledContainer>
)

AdminDashboard.displayName = "AdminDashboard"

export default AdminDashboard
