import IconButton from "@pathwright/ui/src/components/button/IconButton"
import Fullscreen, {
  FULLSCREEN_LAYER_PANEL
} from "@pathwright/ui/src/components/fullscreen/Fullscreen"
import withStripeProducts from "@pathwright/web/src/modules/stripe/graphql/withStripeProducts"
import { withUserCommunity } from "@pathwright/web/src/modules/user/UserCommunityContext"
import { canGoHome } from "belltower/utils"
import { ConnectStores } from "lib/core/store"
import { Motion, spring } from "react-motion"
import AppPanelLinks from "./AppPanelLinks"
import "./styles/AppPanel.css"

const AppPanel = ({
  user,
  school,
  isOpen,
  isAdmin,
  closePanel,
  showHome,
  showManage,
  showSubscribe,
  showActivate,
  pages,
  is_authenticated,
  activateSupport
}) => {
  const transition = { stiffness: 250, damping: 25 }
  const nextPosition = isOpen ? 0 : -280
  const configStyle = { x: spring(nextPosition, transition) }

  const handleClickPanelLink = (e) => {
    // If we clicked a link element in the AppPanel, let's ensure
    // the panel closes. It may not close otherwise, for instance,
    // when the link's pathname matches the current pathname (doesn't trigger navigation).
    if (e.target.closest("a")?.closest(".AppPanel")) {
      closePanel()
    }
  }

  return (
    <Fullscreen
      className="FullscreenAppPanel"
      layer={FULLSCREEN_LAYER_PANEL}
      background={isOpen ? { overlay: "rgba(0, 0, 0, 0.6)" } : null}
      onClickBackground={closePanel}
      allowScroll={false}
    >
      <Motion style={configStyle}>
        {(interpolatingStyle) => {
          const { x } = interpolatingStyle

          return (
            <div
              className="AppPanel"
              style={{ transform: `translate3d(${x}px,0,0)` }}
              onClick={handleClickPanelLink}
            >
              <IconButton
                className="AppPanel__close"
                onClick={closePanel}
                icon="x"
                color="#888"
                size={50}
              />
              <AppPanelLinks
                user={user}
                school={school}
                showHome={showHome}
                showManage={showManage}
                showActivate={showActivate}
                showSubscribe={showSubscribe}
                pages={pages}
                activateSupport={activateSupport}
                isOpen={isOpen}
              />
            </div>
          )
        }}
      </Motion>
    </Fullscreen>
  )
}

AppPanel.displayName = "AppPanel"

const connectedStores = [
  { store: "auth", watch: false },
  { store: "support", watch: false },
  { store: "subscribe", watch: ["subscribe_state"] },
  { store: "navigation", watch: ["nav_panel_open"] },
  { store: "school", watch: ["permissions"] },
  { store: "pathwrightAccount", watch: ["is_activated"] }
]

let previousIsOpen = null
let enableClickHandler = false

const mapStateToProps = function (state, ownProps) {
  const { toggleNavPanelOpen } = state.navigationStore.action
  const { subscribe_state } = state.subscribeStore.state
  const { nav_panel_open: isOpen } = state.navigationStore.state
  const { subscription_plans, school, permissions } = state.schoolStore.state
  const { is_activated, is_on_legacy_plan } = state.pathwrightAccountStore.state
  const { hasActivePathwrightSubscription } =
    state.pathwrightAccountStore.request
  const { activateSupport } = state.supportStore.action
  const { stripeProducts, communityGroupWithActiveSubscription } = ownProps

  const user_is_subscribed = subscribe_state === "active"
  const is_authenticated = state.authStore.request.isAuthenticated()
  const pages = state.schoolStore.request.getPages()
  const isAdmin =
    permissions != null ? permissions.user_is_school_admin : undefined
  const isEditor =
    permissions != null ? permissions.user_is_school_editor : undefined
  const showHome = canGoHome()
  const showManage = is_authenticated && isAdmin
  const hasPlans =
    !!(subscription_plans && subscription_plans.length) ||
    !!(stripeProducts && stripeProducts.length)
  const showSubscribe =
    hasPlans && !user_is_subscribed && !communityGroupWithActiveSubscription
  // NOTE: a school is currently considered activated even if it has a
  // curriculum subscription, but we want to prompt them to activate on a PW plan
  const showActivate =
    isAdmin && (!is_activated || !hasActivePathwrightSubscription())

  // only proceed if isOpen changed!
  if (previousIsOpen !== isOpen) {
    previousIsOpen = isOpen
    enableClickHandler = false
    setTimeout(() => {
      return (enableClickHandler = true)
    }, 100)
  }

  const closePanel = function () {
    // Since we added the mouseover event on the Menu button
    // the Menu will not open on tap on mobile. This appers to be because
    // the mouseover event is fired on tap followed by a click event which
    // triggers this closePanel function immedietly after the panel was supposed to open
    // so we need a complex delay before enabling the click handler
    if (enableClickHandler) {
      return toggleNavPanelOpen(false)
    }
  }

  return _.assign(
    {},
    {
      // actions
      closePanel,
      // state
      isOpen,
      school,
      isAdmin,
      showHome,
      showManage,
      showSubscribe,
      showActivate,
      pages,
      is_authenticated,
      activateSupport
    },
    ownProps
  )
}

export default withStripeProducts(
  withUserCommunity(
    ConnectStores(AppPanel, connectedStores, {}, mapStateToProps)
  )
)
