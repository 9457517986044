import withLauncher from "@pathwright/ui/src/components/launcher/withLauncher"
import gql from "graphql-tag"
import { usePathwrightContext } from "../pathwright/PathwrightContext"
import LibraryPreview from "./LibraryPreview"
import Place from "./Place"

const PlaceLauncher = withLauncher({
  Component: Place,
  Preload: window.LazyLibraryRoutes
})

const LibraryPlace = ({
  visibleResources,
  privateResources,
  resourceStats,
  onLaunch
}) => {
  const { school } = usePathwrightContext()
  if (!visibleResources || !privateResources) return null
  const hasResources =
    visibleResources.edges.length > 0 || privateResources.edges.length > 0
  return (
    <PlaceLauncher
      name={school.platform_version >= 2.8 ? "Store" : "Library"}
      icon="grid-large"
      color="#7ED321"
      to={school.platform_version >= 2.8 ? "/store/" : "/library/"}
      action={
        school.platform_version >= 2.8 ? "Explore store" : "Explore library"
      }
      onLaunch={onLaunch}
      isBlank={!hasResources}
      blankImage="library"
      blankDescription={`Organize your discoverable courses from ${school.name}.`}
      footer={
        hasResources &&
        resourceStats && (
          <p>
            {resourceStats.public} discoverable • {resourceStats.private}{" "}
            private
          </p>
        )
      }
    >
      {hasResources && (
        <LibraryPreview
          visibleResources={visibleResources.edges
            .map((edge) => edge.node)
            .reverse()}
          privateResources={privateResources.edges
            .map((edge) => edge.node)
            .reverse()}
        />
      )}
    </PlaceLauncher>
  )
}

LibraryPlace.displayName = "LibraryPlace"

LibraryPlace.fragments = {
  resourceStats: gql`
    fragment ResourceStats on School {
      resourceStats: resource_stats {
        private
        public
      }
    }
  `,
  visibleResources: gql`
    fragment VisibleResources on Query {
      visibleResources: resources(
        first: 6
        visibility: { gte: members }
        resource_types: { eq: course }
      ) {
        total
        edges {
          node {
            id
            name
            slug
            image(width: 100, height: 75, fit: clamp)
          }
        }
      }
    }
  `,
  privateResources: gql`
    fragment PrivateResources on Query {
      privateResources: resources(
        first: 6
        visibility: { lt: members }
        resource_types: { eq: course }
      ) {
        total
        edges {
          node {
            id
            name
            slug
            image(width: 100, height: 75, fit: clamp)
          }
        }
      }
    }
  `
}

export default LibraryPlace
