import { withTranslate } from "@pathwright/ui/src/components/"
import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import TextInput from "@pathwright/ui/src/components/form/form-text-input/TextInput"
import Fieldset from "@pathwright/ui/src/components/form/form-utils/Fieldset"
import PropTypes from "prop-types"
import { Component } from "react"
import { Field, Form, Submit, validators } from "react-connect-form-forked"
import styled from "styled-components"

const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const StyledFormWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  & > div {
    width: 100%;
  }
`

const StyledFieldsWrapper = styled.div`
  text-align: center;
  position: relative;
  > div:first-child {
    display: inline-block;
  }
`

class DiscountForm extends Component {
  componentDidMount() {
    const discountCode = new URLSearchParams(window.location.search).get(
      this.props.searchParam
    )

    let result = discountCode && this.props.onSubmit(discountCode)
    const promise =
      result &&
      (result.then
        ? result
        : result.promise && result.promise.then
        ? result.promise
        : null)
    promise && promise.then(this.handleSuccess).catch(this.handleError)
  }

  handleError = (error) => {
    this.props.onError && this.props.onError(error)
  }

  handleSuccess = (discount) => {
    this.props.onDiscount && this.props.onDiscount(discount)
  }

  render() {
    const { onSubmit, t } = this.props

    return (
      <StyledWrapper>
        <StyledFormWrapper>
          <Form
            onSubmit={({ code }) => onSubmit(code)}
            onSubmitFailure={this.handleError}
            onSubmitSuccess={this.handleSuccess}
          >
            <StyledFieldsWrapper>
              <div>
                <Fieldset title={t("payments.discount_form.enter_code")}>
                  <Field
                    name="code"
                    type="text"
                    autoFocus={true}
                    validators={[validators.isRequired]}
                    component={TextInput}
                  />
                </Fieldset>
              </div>
              <div>
                <Submit
                  size="large"
                  styleType="primary"
                  type="submit"
                  component={(props) => {
                    return (
                      <SubmitButton
                        {...props}
                        onClick={(e) => e.preventDefault()}
                        disabled={!props.touched || !props.valid}
                      />
                    )
                  }}
                >
                  {t("payments.discount_form.submit_code")}
                </Submit>{" "}
              </div>
            </StyledFieldsWrapper>
          </Form>
        </StyledFormWrapper>
      </StyledWrapper>
    )
  }
}

DiscountForm.displayName = "DiscountForm"
DiscountForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onDiscount: PropTypes.func,
  onError: PropTypes.func,
  discountMessage: PropTypes.string, // pass to override state
  searchParam: PropTypes.string
}

DiscountForm.defaultProps = {
  searchParam: "coupon"
}

export default withTranslate(DiscountForm)
