// feature permission
export const FEATURE_PERMISSION_DISABLED = "disabled"
export const FEATURE_PERMISSION_ADMIN_ONLY = "admin_only"
export const FEATURE_PERMISSION_STAFF_ONLY = "staff_only"
export const FEATURE_PERMISSION_EVERYONE = "everyone"

// feature key
export const FEATURE_KEY_MEMBER_DIRECTORY = "cohort__member_directory"
export const FEATURE_KEY_ACTIVITY_FEED = "cohort__activity_feed"
export const FEATURE_KEY_AUTH_PLATFORM = "auth__platform"
export const FEATURE_KEY_AUTH_LOGIN_OPTION_PASSWORD =
  "auth__login_option__password"
export const FEATURE_KEY_AUTH_LOGIN_OPTION_EMAIL_LINK =
  "auth__login_option__email_link"
export const FEATURE_KEY_AUTH_LOGIN_OPTION_GLOBAL_SSO_GOOGLE =
  "auth__login_option__global_sso__google"
export const FEATURE_KEY_AUTH_LOGIN_OPTION_GLOBAL_SSO_APPLE =
  "auth__login_option__global_sso__apple"

// feature action
export const FEATURE_ACTION_VIEW = "view"
export const FEATURE_ACTION_UPDATE_VISIBILITY = "update__visibility"
export const FEATURE_ACTION_AUTH_LOGIN = "auth__login"
