export default {
  type: "Quote",
  icon: "quote", // TODO: pathicon quote => quote-marks
  helpLink:
    "https://help.pathwright.com/create-a-course/adding-content-with-blocks/quote-blocks-new",
  menu: {
    category: "text",
    order: 2
  },
  layouts: {
    quote: {
      key: "quote",
      label: "Quote",
      data: {
        quote: `<p>A doctor can bury his mistakes, but an architect can only advise his clients to plant vines.</p>`,
        attribution: `<p>Frank Lloyd Wright</p>`
      }
    },
    quoteImage: {
      key: "quoteImage",
      label: "Quote with image",
      data: {
        image: `https://cdn.filestackcontent.com/YNkGhKISV6dzCo4qDV9A`,
        quote: `<p>We shape our buildings; thereafter they shape us.</p>`,
        attribution: `<p>Winston Churchill</p>`
      }
    }
  },
  schema: `type Quote implements Block {
    id: ID!
    image: String @media
    quote: String!
    attribution: String
  }`
}
