export const RESPONSE_ORDER_FILTER_BEST = "best"
export const RESPONSE_ORDER_FILTER_ACTIVE = "active"
export const RESPONSE_ORDER_FILTER_NEWEST = "newest"
export const RESPONSE_ORDER_FILTER_OLDEST = "oldest"

export const RESPONSE_ORDER_FILTERS = [
  RESPONSE_ORDER_FILTER_BEST,
  RESPONSE_ORDER_FILTER_ACTIVE,
  RESPONSE_ORDER_FILTER_NEWEST,
  RESPONSE_ORDER_FILTER_OLDEST
]
