import { graphql } from "@apollo/client/react/hoc"
import compose from "lodash/flowRight"
import get from "lodash/get"
import PropTypes from "prop-types"
import { PathwrightContext } from "../../pathwright/PathwrightContext"
import { createPaginator, flattenEdges } from "../../utils/apollo"
import { withContextAsProp } from "../../utils/component"
import { withSearch } from "../../utils/search"
import AUTHORS_QUERY from "./authors-query"

export const withAuthors = compose(
  withContextAsProp(PathwrightContext, "schoolId", "school.id"),
  graphql(AUTHORS_QUERY, {
    options: ({ first, schoolId, debouncedSearch, excludeIds, manage }) => ({
      variables: {
        first: first || 10,
        school_id: schoolId,
        search: debouncedSearch,
        exclude_ids: excludeIds,
        manage
      },
      fetchPolicy: "cache-and-network"
    }),
    skip: ({ debouncedSearch, skipSearch }) =>
      skipSearch ? skipSearch(debouncedSearch) : false,
    props: ({ data, ownProps }) => ({
      error: data.error,
      loading: data.loading || ownProps.debouncedSearch !== ownProps.search,
      authors: flattenEdges(get(data, "context.school.authors")),
      total: get(data, "context.school.authors.total", 0),
      hasMore: get(data, "context.school.authors.pageInfo.hasNextPage"),
      loadMore: createPaginator(data, "context.school.authors")
    })
  })
)

const AuthorsQuery = withSearch(
  withAuthors(({ children, ...rest }) => {
    return children(rest) || null
  })
)

AuthorsQuery.displayName = "AuthorsQuery"

AuthorsQuery.propTypes = {
  children: PropTypes.func.isRequired,
  // page length
  first: PropTypes.number
}

export default AuthorsQuery
