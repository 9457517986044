import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import PropTypes from "prop-types"
import { feedFilterPropType } from "./feed/propTypes"

const NotificationsEmptyFeed = ({ filter, search }) => {
  const { t } = useTranslate()

  if (!filter || filter.key === "all") {
    return search ? (
      <BlankSlate
        icons={null}
        heading={t(`No notifications matching "{{ search }}".`, { search })}
      />
    ) : (
      <BlankSlate
        icons={null}
        heading={t("No notifications.")}
        body={t(
          "Subscribe to discussions or steps to be notified about new activity."
        )}
      />
    )
  }

  switch (filter.key) {
    case "unread":
      return search ? (
        <BlankSlate
          icons={null}
          heading={t(`No new notifications matching "{{ search }}".`, {
            search
          })}
        />
      ) : (
        <BlankSlate
          icons={null}
          heading={t("No new notifications.")}
          body={t(
            "Subscribe to discussions or steps to be notified about new activity."
          )}
        />
      )
    case "pinned":
      return search ? (
        <BlankSlate
          icons={null}
          heading={t(`No pinned notifications matching "{{ search }}".`, {
            search
          })}
        />
      ) : (
        <BlankSlate
          icons={null}
          heading={t("No pinned notifications.")}
          body={t("Pin a notification to find it later.")}
        />
      )
  }
  return null
}

NotificationsEmptyFeed.displayName = "NotificationsEmptyFeed"

NotificationsEmptyFeed.propTypes = {
  filter: feedFilterPropType,
  search: PropTypes.string
}

NotificationsEmptyFeed.defaultProps = {}

export default NotificationsEmptyFeed
