import { LICENSING_MODES } from "@pathwright/web/src/modules/store/resource/offering/license/constants"
import { useProducts } from "@pathwright/web/src/modules/stripe/product/ProductCheckboxes"
import { useSchoolSubscriptionPlans } from "@pathwright/web/src/modules/subscription/manage/SchoolSubscriptionPlansCheckboxes"

// returns { hasOptions, hasSelectedOptions, licensingMode }
// hasOptions: Boolean
//   - indicates whether subscription options exists for RLO licensing mode
// hasSelectedOptions: Boolean
//   - indicates whether subscription options are selected for RLO licensing mode
// licensingMode: Int
//   - defaults to the "group_subscription" type if school has products
export const useRLOSubscriptionOptions = (licenseOffering) => {
  const { schoolSubscriptionPlans } = useSchoolSubscriptionPlans()
  const { products } = useProducts()

  let hasOptions = false
  let hasSelectedOptions = false

  const defaultLicensingMode = products?.length
    ? LICENSING_MODES.GROUP_SUBSCRIPTION.int
    : LICENSING_MODES.PRIVATE_COHORTS.int

  // For some reason, a licenseOffing lacking an ID may be passed in.
  const licensingMode = licenseOffering?.id
    ? licenseOffering.licensing_mode
    : defaultLicensingMode

  if (licenseOffering?.id) {
    const { include_in_subscription_plans, include_with_products } =
      licenseOffering

    // may not exist if the licenseOffering hasn't been created
    if (licensingMode) {
      const licensingModes = LICENSING_MODES._for(licensingMode)
      switch (licensingMode) {
        case licensingModes.PRIVATE_COHORTS:
          hasOptions = !!schoolSubscriptionPlans?.length
          hasSelectedOptions = !!include_in_subscription_plans.length
          break
        case licensingModes.GROUP_SUBSCRIPTION:
          hasOptions = !!products?.length
          hasSelectedOptions = !!include_with_products.length
          break
      }
    }
  }

  return {
    hasOptions,
    hasSelectedOptions,
    licensingMode
  }
}
