import gql from "graphql-tag"
import DISCUSSION_POST from "./discussion-post-fragment"

export default gql`
  mutation UpdateUserDiscussionHistory(
    $context: DiscussionContextInput!
    $discussion_id: Int!
  ) {
    updateUserDiscussionHistory(
      context: $context
      discussion_id: $discussion_id
    ) {
      ...DiscussionPost
    }
  }
  ${DISCUSSION_POST}
`
