import { FormikValues } from "formik"
import debounce from "lodash/debounce"
import { useEffect, useMemo, useState } from "react"
import isEqual from "react-fast-compare"
import { usePrevious } from "react-use"
import useFormStateStorage, {
  FormStateKey,
  FormStateStorage
} from "./useFormStateStorage"
import { LocalStorageOptions } from "./useLocalStorage"

type FormState = {
  values: FormikValues
  initialValues: FormikValues
}

// Manually count how many fields differ from their initial value.
const getNumberOfChangedFields = (formState: FormState) => {
  const keys = Object.keys(formState.values)
  const numberOfChanges = keys.reduce((numberOfChanges, key) => {
    // Compare initialValues against current values, per key.
    const increment = isEqual(
      formState.initialValues[key],
      formState.values[key]
    )
      ? 0
      : 1
    return numberOfChanges + increment
  }, 0)

  return numberOfChanges
}

export type DiscardFormState = (preseve: boolean) => void

export type SetFormState<T = any> = (
  formState: Exclude<FormStateStorage<T>, null | undefined>
) => void

export type DiscardFormStateReturn = {
  discardCount: number
  discardFormState: DiscardFormState
}

export type DiscardFormStateOptions<T = any> = {
  key?: FormStateKey
  formState: Exclude<FormStateStorage<T>, null | undefined>
  setFormState: SetFormState<T>
  localStorageOptions?: LocalStorageOptions
}

// Handles setting initial form state based on stored form state and
// synchronizing provided form state with stored form state.
const useDiscardFormState = <T = any>({
  key,
  formState,
  setFormState,
  localStorageOptions
}: DiscardFormStateOptions<T>) => {
  const [formStateStorage, setFormStorageState, formStateStorageMeta] =
    useFormStateStorage(formState.initialValues, key, localStorageOptions)
  const [numberOfChanges, setNumberOfChangedFields] = useState<number>(0)

  const debouncedHandleFormChange = useMemo(
    () =>
      debounce(
        (formState) => {
          // The form state has been modified.
          setFormStorageState(formState.values)
          setNumberOfChangedFields(getNumberOfChangedFields(formState))
        },
        // NOTE: for now choosing a very low delay to avoid an edge case with fields
        // that 1) share same storage key and 2) are unmounted and mounted in quick succession.
        // This can lead to subsequently mounted fields having stale stored value.
        50,
        {
          leading: false,
          trailing: true
        }
      ),
    []
  )

  const debouncedHandleGlobalStorageChange = useMemo(
    () =>
      debounce(
        (formStateStorage, formState) => {
          // An undefined value indicates the storage key has been completely
          // removed, while an otherwise nullish value indicates the storage
          // data has been discarded. Both cases indicate that the "local"
          // storage should be discarded.
          if (!formStateStorage) {
            // If formStateStorage is undefined, we can safely preserve the
            // formState. Otherwise we discard formState changes.
            const preserve = formStateStorage === undefined
            discardFormState(preserve)
          } else {
            // Simply update the current values.
            setFormState({
              values: formStateStorage.values,
              initialValues: formState.initialValues
            })
          }
        },
        300,
        {
          leading: true,
          trailing: true
        }
      ),
    []
  )

  // Must flush any debounced changes before unmounting.
  useEffect(() => {
    return () => {
      debouncedHandleFormChange.flush()
      debouncedHandleGlobalStorageChange.flush()
    }
  }, [])

  const previousFormState = usePrevious(formState)
  // Only respond to changes to formrState after initial setup.
  useEffect(() => {
    // The form state has been modified.
    if (
      previousFormState &&
      (previousFormState.initialValues !== formState.initialValues ||
        previousFormState.values !== formState.values)
    ) {
      debouncedHandleFormChange(formState)
    }
  }, [formState.initialValues, formState.values])

  const previousFormStateStorage = usePrevious(formState)
  // When formStateStorage changes and the change source was
  // "global", meaning this change occurred in another web page,
  // update the current formState, which in turn will update the
  // "local" storage.
  useEffect(() => {
    if (
      previousFormStateStorage &&
      previousFormStateStorage !== formStateStorage &&
      formStateStorageMeta.source === "global"
    ) {
      debouncedHandleGlobalStorageChange(formStateStorage, formState)
    }
  }, [formStateStorage])

  useEffect(() => {
    if (
      formStateStorage &&
      !isEqual(formState.values, formStateStorage.values)
    ) {
      // The form state has not been modified but differs from the
      // local form state, presumably upon initial form mount.
      setFormState({
        values: formStateStorage.values,
        initialValues: formState.initialValues
      })
    }
  }, [])

  // Reset form to initial state.
  const discardFormState: DiscardFormState = (preserve = false) => {
    // Discarding form state might require preserving the form's
    // current state, for instance, after successful form submission.
    if (preserve === true) {
      setFormState({
        values: formState.values,
        initialValues: formState.values
      })
    } else {
      // Here we're discarding form's current state, which will result in
      // also discarding stored form state.
      setFormState({
        values: formState.initialValues,
        initialValues: formState.initialValues
      })
    }

    // HACK ALERT...

    // Sort of rerverse the logic for formStateStorage. The main reason for
    // handling discard logic differently for formStateStorage based on preserve
    // flag is for "global" syncing of localStorage state. The type of discard
    // communicates to other web pages about how to set the preserve flag value
    // when discarding changes.
    if (preserve === true) {
      // Completely discard stored form state.
      setFormStorageState()
    } else {
      // Preseve form state key, but clear the value.
      // Only necessary for "global" localStorage syncing.
      setFormStorageState(null)
    }
  }

  return {
    discardCount: numberOfChanges,
    discardFormState
  }
}

export default useDiscardFormState
