import ResourceBackground from "@pathwright/ui/src/components/background/ResourceBackground"
import Button from "@pathwright/ui/src/components/button/Button"
import { media } from "@pathwright/ui/src/components/utils/styles"
import ImagePicker from "@pathwright/web/src/modules/media-manager/ImagePickerWithMM"
import path from "path-browserify"
import PropTypes from "prop-types"
import { Field } from "react-connect-form-forked"
import styled from "styled-components"

const Header = styled.header`
  position: relative;
  display: flex;
  justify-content: center;
  padding: 10px;
  ${media.max.phone`padding-top: 30px`};

  & > .Button {
    position: absolute;
    right: 10px;
    top: 10px;
  }
`

const ManageResourceHeader = ({ resource }) => {
  return (
    <Header>
      <ResourceBackground resource={resource} />
      <Button
        to={path.join(window.location.pathname, "/theme/")}
        styleType="secondary"
        size="small"
        inverted
      >
        Edit Background
      </Button>
      <Field
        name="cover_image"
        type="image"
        apiKey="PKIcKga_QLKv1rkAdPQB"
        options={{ accept: ["image/jpg", "image/jpeg", "image/png"] }}
        initialValue={resource.image_raw}
        component={(props) => (
          <ImagePicker
            {...props}
            blocksContextKey={`resource-${resource.id}`}
          />
        )}
        width={160}
        height={120}
        hideStatus
        required
        inverted
      />
    </Header>
  )
}

ManageResourceHeader.displayName = "ManageResourceHeader"

ManageResourceHeader.propTypes = {
  resource: PropTypes.object.isRequired
}

export default ManageResourceHeader
