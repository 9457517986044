import { withTranslate } from "@pathwright/ui/src/components/"
import BannerSlider from "../../../banner/display/BannerSlider"
import { useSchool } from "../../../school/SchoolContext"

const BrowseBanner = ({ banners, t, ...rest }) => {
  const school = useSchool()
  const defaultBanner = {
    heading: t("library.store_labels.library"),
    subhead: t("library.browse.from_school", { school_name: school.name })
  }

  return (
    <BannerSlider
      {...rest}
      banners={banners.length ? banners : [defaultBanner]}
    />
  )
}

BrowseBanner.displayName = "BrowseBanner"

export default withTranslate(BrowseBanner)
