import Card from "@pathwright/ui/src/components/card/Card"
import CardHeader from "@pathwright/ui/src/components/card/CardHeader"
import withTranslate from "@pathwright/ui/src/components/lng/withTranslate"
import Tab from "@pathwright/ui/src/components/tab/Tab"
import { useScreensizeContext } from "@pathwright/ui/src/components/ui/Screensize"
import { paddingScreensizeMedium } from "@pathwright/ui/src/components/ui/View"
import MentorGroups from "../mentor-group/groups/list/MentorGroups"
import MentorGroupsCard from "../mentor-group/groups/list/MentorGroupsCard"
import { usePathwrightContext } from "../pathwright/PathwrightContext"
import { canAdministrate } from "../user/permissions"
import MembershipContainer from "./MembershipContainer"
import MembershipReports from "./MembershipReports"

const MembershipCard = (props) => {
  const pwContext = usePathwrightContext()
  const screensize = useScreensizeContext()
  const negativeMargin = paddingScreensizeMedium({ screensize })

  return canAdministrate(pwContext) ? (
    <Card card={props.card} navless>
      <CardHeader
        card={props.card}
        title={`${pwContext.school.name} Community`}
        meta={null}
      >
        <div
          style={{
            marginTop: 10,
            marginBottom: -negativeMargin,
            display: "flex",
            justifyContent: "center"
          }}
        >
          <Tab
            inverted
            active={props.tab === "members"}
            to="/community/members/"
            tab={{
              icon: "avatar",
              label: "Members"
            }}
          />
          <Tab
            inverted
            active={props.tab === "groups"}
            to="/community/groups/"
            tab={{
              icon: "group-2",
              label: props.t("mentor_group.label_plural")
            }}
          />
        </div>
      </CardHeader>
      <MembershipReports />
      {props.tab === "members" ? (
        <MembershipContainer {...props} />
      ) : (
        <MentorGroups parentId={null} />
      )}
    </Card>
  ) : (
    <MentorGroupsCard card={props.card} />
  )
}

MembershipCard.displayName = "MembershipCard"

MembershipCard.defaultProps = {
  onTabChange: () => {}
}

export default withTranslate(MembershipCard)
