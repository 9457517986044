import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import Card from "@pathwright/ui/src/components/card/Card"
import CardBlock from "@pathwright/ui/src/components/card/CardBlock"
import ColorPickerToggle from "@pathwright/ui/src/components/form/form-color/ColorPickerToggle"
import ToggleInput from "@pathwright/ui/src/components/form/form-toggle/ToggleInput"
import ImagePicker from "@pathwright/web/src/modules/media-manager/ImagePickerWithMM"
import PropTypes from "prop-types"
import { Field, Form, Submit } from "react-connect-form-forked"
import styled from "styled-components"
import MediaQuery from "../../lib/MediaQuery"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"

const StyledCard = styled(Card)`
  .ColorPickerToggle__toggle > div {
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
`

const BackgroundImagePicker = (props) => {
  const { form, value, ...rest } = props
  const { overlay, blur } = form.value
  return (
    <CardBlock>
      <MediaQuery
        url={value}
        blur={blur ? 500 : null}
        width={1000}
        height={400}
        fit={null}
      >
        {({ loading, image, error }) => (
          <ImagePicker
            {...rest}
            value={value}
            preview={image}
            overlay={overlay}
          />
        )}
      </MediaQuery>
    </CardBlock>
  )
}

const ManageBackground = ({ card, initialValue, onSubmit }) => {
  const { school } = usePathwrightContext()
  return (
    <StyledCard card={card} title={`${school.name} Background`}>
      <Form initialValue={initialValue} onSubmit={onSubmit}>
        <Field
          name="image"
          topLabel
          alignRight
          labelWidth={300}
          label="Upload a custom background image"
          helperText="For best results, upload a background image that's at least 1000px wide. Be sure that there's enough contrast for white text to be visible on top of it."
          apiKey="PKIcKga_QLKv1rkAdPQB"
          options={{ accept: ["image/jpeg", "image/png"], maxFiles: 1 }}
          component={(props) => (
            <BackgroundImagePicker
              {...props}
              blocksContextKey={`background-${school.id}`}
            />
          )}
        />
        <Field
          name="blur"
          label="Blur background image?"
          helperText="Recommended for background images with details."
          labelWidth={225}
          alignRight
          render={(fieldProps) =>
            fieldProps.form.value.image ? (
              <CardBlock>
                <ToggleInput {...fieldProps} />
              </CardBlock>
            ) : null
          }
        />
        <Field
          name="overlay"
          label="Adjust color overlay"
          helperText="Tip: a subtle layer over your background can make text easier to read."
          labelWidth={225}
          alignRight
          render={(fieldProps) =>
            fieldProps.form.value.image ? (
              <CardBlock>
                <ColorPickerToggle
                  {...fieldProps}
                  swatches={[
                    "rgba(22,160,133,0.5)",
                    "rgba(39,174,96,0.5)",
                    "rgba(41,128,185,0.5)",
                    "rgba(142,68,173,0.5)",
                    "rgba(243,156,18,0.5)",
                    "rgba(211,84,0,0.5)",
                    "rgba(192,57,43,0.5)",
                    "rgba(127,140,141,0.5)",
                    "rgba(44,62,80,0.5)",
                    "rgba(0,0,0,0.5)"
                  ]}
                />
              </CardBlock>
            ) : null
          }
        />
        <Submit styleType="primary" component={SubmitButton}>
          Save
        </Submit>
      </Form>
    </StyledCard>
  )
}

ManageBackground.displayName = "ManageBackground"

ManageBackground.propTypes = {
  // provided by CardStack > CardStackCard
  card: PropTypes.object.isRequired,
  // handler to save field values
  onSubmit: PropTypes.func.isRequired,
  // field values
  initialValue: PropTypes.shape({
    image: PropTypes.string,
    blur: PropTypes.bool,
    overlay: PropTypes.string
  })
}

export default ManageBackground
