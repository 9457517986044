import IconButton from "@pathwright/ui/src/components/button/IconButton"
import classnames from "classnames"
import PropTypes from "prop-types"

const OptionsButton = ({ rotate, className, ...props }) => (
  <IconButton
    className={classnames("OptionsButton", className)}
    icon="ellipses-horizontal"
    styleType="secondary"
    rotate={rotate ? 90 : 0}
    {...props}
  />
)

OptionsButton.displayName = "OptionsButton"

OptionsButton.propTypes = {
  ...IconButton.propTypes,
  rotate: PropTypes.bool
}

export default OptionsButton
