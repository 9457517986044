import PropTypes from "prop-types"
import React from "react"
import InvalidVariable from "./InvalidVariable"
import useVariableSelector from "./useVariableSelector"

export const VariableSelectorShape = PropTypes.shape({
  [PropTypes.string]: PropTypes.shape({
    label: PropTypes.string,
    source: PropTypes.string,
    variables: PropTypes.object // recursive object shape
  })
})

export const VariableDelimiterConfigs = PropTypes.arrayOf(
  PropTypes.shape({
    beforeSelectionRe: PropTypes.instanceOf(RegExp),
    afterSelectionRe: PropTypes.instanceOf(RegExp),
    template: PropTypes.func
  })
)

useVariableSelector.propTypes = {
  variables: VariableSelectorShape,
  delimiterConfigs: VariableDelimiterConfigs
}

const VariableSelector = ({
  variables,
  value,
  delimiterConfigs,
  EmptyVariableSelectorList,
  children,
  ...passProps
}) => {
  const { selector, ...rest } = useVariableSelector({
    variables,
    value,
    delimiterConfigs,
    EmptyVariableSelectorList
  })

  return (
    <React.Fragment>
      {selector}
      {children({
        ...passProps,
        ...rest
      })}
    </React.Fragment>
  )
}

VariableSelector.displayName = "VariableSelector"

VariableSelector.defaultProps = {
  EmptyVariableSelectorList: InvalidVariable
}

export default VariableSelector
