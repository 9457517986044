import getNextUrl from "./getNextUrl"

// Preventing creating membership when signing in/up through global /sign-up.
export const getCreateMembership = (): boolean | undefined => {
  const nextUrl = getNextUrl()

  if (nextUrl) {
    try {
      if (new URL(nextUrl!).pathname.startsWith("/sign-up")) {
        return false
      }
    } catch {}
  }
  return undefined
}

export const extendWithCreateMembership = <
  T extends Record<string, any> & { createMembership?: boolean }
>(
  data: T,
  createMembership?: boolean
): T => {
  if (typeof createMembership === "undefined") {
    createMembership = getCreateMembership()
  }

  if (typeof createMembership === "undefined") {
    createMembership = data.createMembership
  }

  return {
    ...data,
    createMembership
  }
}
