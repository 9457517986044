import gql from "graphql-tag"
import USER_FRAGMENT from "../../user/graphql/user-fragment"
// import USER_FRAGMENT from "../../user/graphql/user-fragment"

export default gql`
  fragment DiscussionResponse on Response {
    id
    body
    attachments
    likes
    user_liked
    created_time
    user {
      ...User
    }
  }
  ${USER_FRAGMENT}
`
