import ManageAccount from "@pathwright/web/src/modules/account/manage/ManageAccount"
import ManageAccountMenu from "@pathwright/web/src/modules/account/manage/ManageAccountMenu"
import ManageAccountReports from "@pathwright/web/src/modules/account/manage/ManageAccountReports"
import ManageDomain from "@pathwright/web/src/modules/account/manage/ManageDomain"
import ManagePages from "@pathwright/web/src/modules/account/manage/ManagePages"
import ManagePrivacy from "@pathwright/web/src/modules/account/manage/ManagePrivacy"
import ManageSupport from "@pathwright/web/src/modules/account/manage/ManageSupport"
import ManageToggleTranslations from "@pathwright/web/src/modules/account/manage/ManageToggleTranslations"
import ManageTranslationCard from "@pathwright/web/src/modules/account/manage/ManageTranslationCard"
import GenerateCouponCodesForResources from "@pathwright/web/src/modules/pathwright-admin/GenerateCouponCodesForResources"
import GenerateCouponCodesForSubscriptionPlans from "@pathwright/web/src/modules/pathwright-admin/GenerateCouponCodesForSubscriptionPlans"
import PathwrightAdminCard from "@pathwright/web/src/modules/pathwright-admin/PathwrightAdminCard"
import { createCardRoutes } from "lib/core/routing/card-routing"
import {
  authRedirect,
  pathwrightAdminAccessRedirect
} from "lib/core/routing/middleware"
import { LibraryCardRoutes } from "library/browse/routes"
import { observer } from "mobx-react"
import store from "school/manage/school-manage-store"
import FallbackComponent from "store/StoreOrLibraryBrowse"
import { getStoreUrl } from "store/utils"
import BillingCardRoutes from "./billing-routes"
import BrandCardRoutes from "./brand-routes"
import featuresRoutes from "./features-routes"
import pageRoutes from "./page-routes"

const ManageAccountMenuContainer = observer(({ card }) => {
  const { school } = window.App.getStore("school").getState()
  const hasActivePathwrightSubscription =
    App.getStore("pathwrightAccount").request.hasActivePathwrightSubscription()
  const can_manage_billing = store.can_manage_billing

  // NOTE: a school is currently considered activated even if it has a
  // curriculum subscription, but we want to prompt them to activate on a PW plan
  const activated = school.is_activated && !!hasActivePathwrightSubscription

  return (
    <ManageAccountMenu
      card={card}
      activated={activated}
      billingAlert={false}
      can_manage_billing={can_manage_billing}
    />
  )
})

const ManageAccountContainer = observer(({ card }) => {
  return (
    <ManageAccount
      card={card}
      onSubmit={store.action.saveDetails}
      hasCustomDomain={store.has_custom_domain}
      hasPages={store.has_pages}
      initialValue={{
        name: store.details.name,
        subdomain: store.details.subdomain || "",
        short_description: store.details.short_description || ""
      }}
    />
  )
})

const ManageDomainContainer = observer(({ card }) => (
  <ManageDomain card={card} />
))

const ManageSupportContainer = observer(({ card }) => {
  return (
    <ManageSupport
      card={card}
      onSubmit={store.action.saveSupport}
      initialValue={store.support}
    />
  )
})

const ManageAccountReportsContainer = observer(({ card }) => (
  <ManageAccountReports card={card} />
))

const cardRoutes = {
  school: {
    getDefaultLaunchedFrom: () => getStoreUrl(),
    middleware: authRedirect,
    component: ManageAccountMenuContainer,
    childCards: {
      account: {
        component: ManageAccountContainer,
        middleware: store.action.loadDetails,
        childCards: {
          domain: {
            component: ManageDomainContainer
          },
          support: {
            component: ManageSupportContainer,
            middleware: store.action.loadSupport
          },
          privacy: {
            component: ManagePrivacy,
            childCards: {
              page: pageRoutes
            }
          },
          translation: {
            component: ManageTranslationCard,
            childCards: {
              "toggle-translations": {
                component: ManageToggleTranslations
              }
            }
          },
          pages: {
            component: ManagePages,
            childCards: {
              ":page_id(\\d+)/": pageRoutes
            }
          },
          ...BrandCardRoutes
        }
      },
      reports: {
        component: ManageAccountReportsContainer
      },
      commands: {
        component: PathwrightAdminCard,
        middleware: pathwrightAdminAccessRedirect,
        childCards: {
          1: {
            path: "generate-coupon-codes/",
            middleware: pathwrightAdminAccessRedirect,
            component: GenerateCouponCodesForResources
          },
          2: {
            path: "generate-coupon-codes-for-subscription-plans/",
            middleware: pathwrightAdminAccessRedirect,
            component: GenerateCouponCodesForSubscriptionPlans
          }
        }
      },
      ...LibraryCardRoutes,
      ...BillingCardRoutes,
      ...featuresRoutes
    }
  }
}

export default createCardRoutes("/manage/", cardRoutes, FallbackComponent)
