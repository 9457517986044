import { chakra, Heading, Spacer, Text, VStack } from "@chakra-ui/react"
import { Formik } from "formik"
import { Trans } from "react-i18next"
import { toFormikValidate } from "zod-formik-adapter"
import { FormikFormStateInputField } from "../../../../components/form/state/FormikFormStateField"
import { useScopedTranslation } from "../../../../lib/lng/TranslationsProvider"
import { ActionType } from "../../../../lib/types"
import AuthFormAlert from "../../components/AuthFormAlert"
import AuthFormButton from "../../components/AuthFormButton"
import AuthFormFooterBox from "../../components/AuthFormFooterBox"
import AuthFormFooterBoxLink from "../../components/AuthFormFooterBoxLink"
import AuthFormHeading from "../../components/AuthFormHeading"
import AuthMotion from "../../components/AuthMotion"
import { AUTH_EMAIL_STORAGE_KEY } from "../../utils/authEmailFormStateStorage"
import getAuthRoute from "../../utils/getAuthRoute"
import { schema } from "./api/action"

const validate = toFormikValidate(schema)

export interface PasswordResetViewProps extends ActionType {}

const PasswordResetView = ({
  loading,
  success,
  error,
  onSubmit
}: PasswordResetViewProps) => {
  const { t } = useScopedTranslation("auth.password_reset_view")

  if (success) {
    return (
      <VStack spacing={4}>
        {/* TODO: use fontFamily="theme.heading" once the parent ChakraProvider uses correct heading theme font */}
        <Heading
          as="h1"
          size="md"
          textAlign="center"
          className="theme-heading theme-heading-bold"
        >
          {t("check_your_email")}
        </Heading>
        <Spacer />
        <Text>{t("confirm_email_sent")}</Text>
      </VStack>
    )
  }

  return (
    <Formik
      initialValues={{
        email: ""
      }}
      validate={validate}
      onSubmit={() => {}} // noop
    >
      {(form) => (
        <AuthMotion>
          <chakra.form onSubmit={onSubmit} width="100%">
            <VStack spacing={4} width="100%">
              <AuthFormAlert error={error} />
              <AuthFormHeading>{t("reset_password")}</AuthFormHeading>
              <Spacer />
              <FormikFormStateInputField
                formStateKey={AUTH_EMAIL_STORAGE_KEY}
                localStorageOptions={{ storageType: "session" }}
                name="email"
                type="email"
                variant="filled"
                placeholder={t(`email_placeholder`) + "..."}
                isDisabled={loading}
              />
              <AuthFormButton loading={loading}>
                {t("send_reset_instructions")}
              </AuthFormButton>
              <Spacer />
              <AuthFormFooterBox>
                {/* TODO: do we need to hide this in some cases? */}
                <Trans
                  parent={Text}
                  i18nKey="auth.password_reset_view.or_sign_in_with_magic_link"
                  values={{
                    sign_in_with_magic_link: t("sign_in_with_magic_link")
                  }}
                  components={[
                    <AuthFormFooterBoxLink
                      to={getAuthRoute("../request")}
                      key="0"
                    >
                      {t("sign_in_with_magic_link")}
                    </AuthFormFooterBoxLink>
                  ]}
                />
              </AuthFormFooterBox>
            </VStack>
          </chakra.form>
        </AuthMotion>
      )}
    </Formik>
  )
}

export default PasswordResetView
