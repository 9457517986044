import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger
} from "@chakra-ui/react"
import ChakraTooltip from "@pathwright/ui/src/components/tooltip/ChakraTooltip"
import { useEffect, useState } from "react"

const InteractiveTooltip = ({ children, label }) => {
  // HACK ALERT: ideally we'd simply set the Popover trigger to "hover" and leave it at that.
  // This can result in the Popover displaying on mount in certain situations (like when mounted
  // in a modal). To work around that, setting trigger to "hover" only after the Popover has mounted.
  const [trigger, setTrigger] = useState()
  useEffect(() => setTrigger("hover"), [])

  // Let's try just using the ChakraTooltip instead.
  return (
    <ChakraTooltip title={label} trigger={trigger}>
      {children}
    </ChakraTooltip>
  )

  return (
    <Popover placement="bottom-start" trigger={trigger}>
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent
        bgColor="black"
        border="none"
        borderRadius="5px"
        p={2}
        color="#fff"
        fontSize="md"
        fontWeight="normal"
      >
        <PopoverArrow bgColor="black" />
        <PopoverBody alignItems="flex-start">{label}</PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default InteractiveTooltip
