import LoadingBlock from "@pathwright/ui/src/components/loading/LoadingBlock"
import PropTypes from "prop-types"
import styled from "styled-components"
import BannersQuery from "../../../banner/graphql/BannersQuery"
import BrowseBanner from "./BrowseBanner"

const LoadingBanner = styled(LoadingBlock)`
  width: 100%;
  height: 270px;
  background-size: 1400px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
`

function BrowseBannerContainer({ schoolId, ...passProps }) {
  return (
    <BannersQuery schoolId={schoolId}>
      {({ loading, banners }) =>
        loading ? (
          <LoadingBanner />
        ) : (
          <BrowseBanner banners={banners} {...passProps} />
        )
      }
    </BannersQuery>
  )
}

BrowseBannerContainer.displayName = "BrowseBannerContainer"

BrowseBannerContainer.propTypes = {
  // scope to another school
  schoolId: PropTypes.number
}

export default BrowseBannerContainer
