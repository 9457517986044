import gql from "graphql-tag"
import USER_FRAGMENT from "../user/graphql/user-fragment"

export const NOTE_FRAGMENT = gql`
  fragment Note on Note {
    id
    title
    body
    visibility
    created_time
    modified_time
    context_id
    resource_id
    cohort_id
    step_id
    user {
      ...User
    }
  }
  ${USER_FRAGMENT}
`

export const NOTES_QUERY = gql`
  query Notes(
    $first: Int = 50
    $cursor: String
    $context: ContextModelInput!
    $visibility: NoteVisibility
  ) {
    notes(
      first: $first
      after: $cursor
      context: $context
      visibility: $visibility
    ) {
      total
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...Note
        }
      }
    }
  }
  ${NOTE_FRAGMENT}
`
