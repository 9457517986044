import { parseSearchParams } from "./getAuthRoute"

export type NextUrl = string | null

const getNextUrl = (): NextUrl => {
  const { pathname, search } = window.location
  const [_, urlSearchParams] = parseSearchParams(search)

  // If we're in the standalone auth flow, we only care about the "?next" url.
  if (pathname.startsWith("/auth")) return urlSearchParams.get("next")
  // Don't care about root path.
  if (pathname === "/") return null

  // Otherwise, we want to route back to this same url.
  // TODO: do we need the full url?
  return pathname + search
}

export default getNextUrl
