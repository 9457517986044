import Avatar from "@pathwright/ui/src/components/avatar/Avatar"
import IconButton from "@pathwright/ui/src/components/button/IconButton"
import TextArea from "@pathwright/ui/src/components/form/form-textarea/TextArea"
import validate from "lib/core/components/forms/form-utils/validate"
import { observer } from "mobx-react"
import inviteStore from "../../store/invite-store"
import "./InviteFormMessage.css"

const InviteFormMessage = () => {
  const isOpen = inviteStore.message !== null
  const showMessage = () => inviteStore.action.showMessage(true)
  const hideMessage = () => inviteStore.action.showMessage(false)
  return (
    <div className="InviteFormMessage">
      {isOpen && <Avatar user={window.user} size="36px" />}
      {isOpen ? (
        <div className="InviteFormMessage__field">
          <TextArea
            className="InviteFormMessage__field"
            placeholder="Enter custom message (Optional)"
            onChange={inviteStore.action.changeMessage}
            value={inviteStore.message}
            errors={[validate.maxLength(1000)({}, inviteStore.message)].filter(
              (error) => error
            )}
            minRows={1}
            maxRows={3}
            autoFocus
            inline
            // only show limit when user gets close to the limit
            limit={inviteStore.message.length > 900 ? 1000 : null}
          />
        </div>
      ) : (
        <p className="InviteFormMessage__prompt">
          <a onClick={showMessage} href="javascript:void(0)">
            Add a personal message?
          </a>
        </p>
      )}
      {isOpen && (
        <IconButton
          className="InviteFormMessage__cancel"
          onClick={hideMessage}
          tabIndex={-1}
          icon="x"
          color="#aaa"
        />
      )}
    </div>
  )
}

InviteFormMessage.displayName = "InviteFormMessage"

export default observer(InviteFormMessage)
