export const MAX_NAME_LENGTH = 20

// https://gist.github.com/solotimes/2537334
export const clampFileName = (n, options = {}) => {
  const { isFile = true, len = MAX_NAME_LENGTH } = options
  if (!isFile) {
    return n.length > len
      ? n.substr(0, len - 4) + "..." + n.substr(n.length - 3, n.length)
      : n
  }

  const FINAL_LETTER_COUNT = 3
  const ext =
    n.lastIndexOf(".") !== -1
      ? n.substring(n.lastIndexOf(".") + 1, n.length).toLowerCase()
      : ""
  let filename = n.replace("." + ext, "")

  if (filename.length <= len) return n

  const returned =
    filename.substr(0, len - FINAL_LETTER_COUNT) +
    (n.length > len ? "[...]" : "") +
    filename.substr(filename.length - FINAL_LETTER_COUNT, filename.length - 1)

  return `${returned}${ext ? "." + ext : ""}`
}

// https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string
export const humanFileSize = (bytes, si) => {
  var thresh = si ? 1000 : 1024
  if (Math.abs(bytes) < thresh) {
    return bytes + " B"
  }
  var units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"]
  var u = -1
  do {
    bytes /= thresh
    ++u
  } while (Math.abs(bytes) >= thresh && u < units.length - 1)
  return bytes.toFixed(1) + " " + units[u]
}
