import ThemeBackground from "@pathwright/ui/src/components/background/ThemeBackground"
import { getUrlFromRoute } from "@pathwright/ui/src/components/link/utils"
import { getSpaceHasFeature } from "@pathwright/web/src/modules/lib/useSpaceHasFeature"
import { PathwrightContext } from "@pathwright/web/src/modules/pathwright/PathwrightContext"
import { PreviousLocationProvider } from "@pathwright/web/src/modules/utils/usePreviousLocation"
import React, { Suspense, useEffect, useState } from "react"
import { createRoot } from "react-dom/client"
import lazy from "react-lazy-with-preload"
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  generatePath
} from "react-router-dom"
import { QueryParamProvider } from "use-query-params"
import App from "./App"
import ClientRoutes from "./ClientRoutes"
import { bootApp } from "./boot"
import RouteCardStack from "./lib/core/components/cards/RouteCardStack"
import Modal from "./lib/core/components/modal/Modal"
import {
  AppRouteContext,
  AppRouteContextProvider
} from "./lib/core/routing/AppRoute"
import InvalidRoute from "./lib/core/routing/InvalidRoute"
import { createRoutes, getInitialRedirect } from "./lib/core/routing/utils"
import SignupRoute from "./signup/routes"

const LazyLibraryRoutes = lazy(() =>
  import("library/routes" /* webpackChunkName: "LibraryRoutes" */)
)

// HACK ALERT: allowing CompletionPreview to preload library/routes
// in order to assign AssessmentSubmissionPreview to window.
window.LazyLibraryRoutes = LazyLibraryRoutes

const LazyStoreRoutes = React.lazy(() =>
  import("store/routes" /* webpackChunkName: "StoreRoutes" */)
)

const getAppRoutes = (hasAuthPw3) =>
  createRoutes(
    [
      require("school/routes").pageRoute,
      require("school/routes").pageModalRoute,
      require("school/routes").schoolInactiveRoute,
      require("school/routes").sitemapRoute,
      require("school/routes").faqsRoute,
      !hasAuthPw3 && require("password/routes").default, // prettier-ignore
      !hasAuthPw3 && require("auth/routes").default,
      require("auth/global/routes").default,
      require("home/routes").createCohortCardRoute,
      require("home/routes").default.homeRoute,
      require("home/routes").default.activateRoute,
      require("mentor-group/routes").default,
      require("signup/routes").legacySignup,
      require("dashboard/routes").default,
      require("join/routes").default,
      require("completion/routes").default,
      require("billing/display/routes").default,
      require("billing/display/routes").upgradeCardRoutes,
      require("billing/confirm-stripe-routes").default,
      require("sso/routes").default,
      require("order/routes").subscriptionRoute,
      require("order/routes").giftSubscriptionRoute,
      require("order/routes").curriculumLicenseeLicenseRoute,
      require("order/routes").curriculumSubscriptionRoute,

      // Modals
      require("library/browse/routes").default,
      require("profile/routes").default,
      require("calendar/routes").default,
      require("certificate/routes").default,
      require("inbox/routes").default,
      require("invitation/invite/routes").schoolRoute,
      require("invitation/list/routes").schoolRoute,
      require("home/components/manage/routes").default,
      require("resource/components/manage/routes").default,
      require("group/components/manage/routes").default,
      require("support/routes").default,
      require("category/routes").default,
      require("author/routes").default,
      require("banner/routes").default,
      // require("account/subscription/routes").default,
      // require("account/subscription/gift/routes").default,
      require("school/manage/routes").default,
      require("account/subscription/gift/redeem-routes").default
    ].filter(Boolean)
  )

// prettier-ignore
// These are routes that were moved.
// We could possible add some Sentry logging to see if these routes are still being hit.
const redirects = {
  '/manage/school/brand/': '/manage/school/account/brand/',
  '/manage/school/commerce/': '/manage/school/features/commerce/',
  '/manage/school/integrations/': '/manage/school/features/integrations/',
  '/manage/school/features/custom-sso/': '/manage/school/features/auth/custom-sso/',
  // '/manage/school/features/:featureKey/': '/manage/school/features/auth/:featureKey/',
  '/manage/resource/:resource_id(\\d+)/group/': '/manage/resource/:resource_id(\\d+)/cohorts/',
  '/manage/school/features/discussion-topics/': '/manage/school/features/cohort/discussion-topics/',
  '/manage/school/features/pathwright-signin/': '/manage/school/features/auth/pathwright-signin/',
  '/manage/resource/:resource_id(\\d+)/:group_id(\\d+)/': '/manage/resource/:resource_id(\\d+)/cohorts/:group_id(\\d+)/',
  '/manage/resource/:resource_id(\\d+)/advanced/archive/': '/manage/resource/:resource_id(\\d+)/archive/',
  '/manage/resource/:resource_id(\\d+)/advanced/license/': '/manage/resource/:resource_id(\\d+)/cohorts/license/',
  '/manage/resource/:resource_id(\\d+)/advanced/license/:resource_license_offering_id(\\d+)/': '/manage/resource/:resource_id(\\d+)/cohorts/license/:resource_license_offering_id(\\d+)/'
}

class PrimaryComponentWrapper extends React.Component {
  shouldComponentUpdate(nextProps) {
    // Prevent updating primary component with new route props
    // if showing some secondary component (like a modal or card stack).
    return (
      !nextProps.components ||
      nextProps.PrimaryComponent !== this.props.PrimaryComponent
    )
  }

  render() {
    return this.props.children
  }
}

// // Since listening to the history import from "history/browser"
// // seems faulty, using the history under react-router-dom context.
// const HistoryListener = () => {
//   const history = useHistory()
//   useEffect(() => {
//     // Initial location is not listened to, so manually doing so.
//     historyListener(history.location)
//     // Sync history with nav store.
//     const unlisten = history.listen(historyListener)
//     // Unlisten when HistoryListener unmounts.
//     return unlisten
//   }, [])

//   return null
// }

function AuthRoutes({ authSync }) {
  // Wrapping with an /auth/ path matcher to because
  // 1) we are only concerned with /auth/ routes and
  // 2) we only want to render the ThemeBackground here
  // when showing auth components. This is basically an escape
  // hatch for rendering a consistent theme bg as we're getting
  // slightly different results when using the space.theme's bg.
  return (
    <Route
      path="/auth/"
      render={() => (
        <>
          {/* NOTE: "fixed" position necessary due to layout (overrides "absolute") */}
          <ThemeBackground style={{ position: "fixed" }} />
          <ClientRoutes authSync={authSync} />
        </>
      )}
    />
  )
}

// NOTE: using class component due to some odd warnings related to hooks
// when using a functional component.
class RootRoute extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.appRoutes = getAppRoutes(this.hasAuthPw3)
  }

  get hasAuthPw3() {
    return getSpaceHasFeature(this.context, "auth__platform")
  }

  render() {
    return (
      <Route
        render={(props) => {
          const rootRedirect = getInitialRedirect(props.location)
          return rootRedirect &&
            // Ensurce we are not already at said rootRedirect
            getUrlFromRoute(rootRedirect) !==
              props.location.pathname + props.location.search ? (
            <Redirect to={rootRedirect} />
          ) : (
            <Switch>
              {Object.entries(redirects).map(([path, redirect]) => (
                <Route
                  key={path}
                  path={path}
                  render={({ match }) => (
                    <Redirect to={generatePath(redirect, match.params)} />
                  )}
                  exact
                />
              ))}
              {this.appRoutes}
              <Route path="/library/">
                <LazyLibraryRoutes />
              </Route>
              <Route path="/store/">
                <LazyStoreRoutes />
              </Route>
              {this.hasAuthPw3 && <AuthRoutes authSync={this.props.authSync} />}
              <InvalidRoute />
            </Switch>
          )
        }}
      />
    )
  }
}

RootRoute.contextType = PathwrightContext

function AppBooter() {
  const [hasBooted, setHasBooted] = useState(false)

  // At this point we haven't yet booted up the legacy Backbone App
  // so let's do that now.
  useEffect(() => {
    bootApp().then(() => {
      setHasBooted(true)
    })
  }, [])

  return hasBooted ? (
    <AppRouteContextProvider>
      <AppRouteContext.Consumer>
        {({ routeContext }) => {
          const primary = routeContext.PrimaryComponent ? (
            <PrimaryComponentWrapper
              PrimaryComponent={routeContext.PrimaryComponent}
              {...routeContext.routeState}
            >
              <routeContext.PrimaryComponent {...routeContext.routeState} />
            </PrimaryComponentWrapper>
          ) : null

          const secondary = routeContext.SecondaryComponent ? (
            <routeContext.SecondaryComponent {...routeContext.routeState} />
          ) : null

          const modal = routeContext.ModalComponent ? (
            <Modal>
              <routeContext.ModalComponent {...routeContext.routeState} />
            </Modal>
          ) : null

          const cardStack =
            routeContext.routeState &&
            routeContext.routeState.cards &&
            routeContext.routeState.cards.length ? (
              <Modal
                className="Modal--cardstack"
                key={routeContext.routeState.cards[0].key}
              >
                <RouteCardStack {...routeContext.routeState} />
              </Modal>
            ) : null

          return (
            <App
              primary={primary}
              secondary={secondary}
              modal={modal}
              cardStack={cardStack}
            >
              {({ authSync }) => (
                <Suspense fallback={null}>
                  {/* For now, show nothing when waiting on lazy components to load.
                  This prevents showing somethign like a LoadingCircle overtop unrelated UIs. */}
                  <RootRoute authSync={authSync} />
                </Suspense>
              )}
            </App>
          )
        }}
      </AppRouteContext.Consumer>
    </AppRouteContextProvider>
  ) : null
}

const app = (
  <BrowserRouter>
    <QueryParamProvider ReactRouterRoute={Route}>
      <PreviousLocationProvider>
        {/* <HistoryListener /> */}
        <Switch>
          {/* Hoist any routes that can be independent of the app here. */}
          <Route path={SignupRoute.path} render={SignupRoute} exact />
          <Route path="*" render={() => <AppBooter />} />
        </Switch>
      </PreviousLocationProvider>
    </QueryParamProvider>
  </BrowserRouter>
)

const root = createRoot(document.getElementById("root"))

root.render(app)
