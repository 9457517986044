import Button from "@pathwright/ui/src/components/button/Button"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import { useScrollContext } from "@pathwright/ui/src/components/scroll/ScrollView"
import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import { Motion, spring } from "react-motion"

// Assumes a ScrollView component is in the tree above this component
const ScrollToTopButton = ({ offset = 1200, ...buttonProps }) => {
  const { t } = useTranslate()
  const [showBackToTop, setShowBackToTop] = useState(false)
  const { scrollTo, useScroll, scrollNode } = useScrollContext()

  useEffect(() => {
    if (!scrollNode) return

    // Maybe should set via prop
    scrollNode.style.scrollBehavior = "smooth"
  }, [scrollNode])

  useScroll({
    onScroll: () => {
      if (!scrollNode) return

      if (scrollNode.scrollTop > 1200) {
        setShowBackToTop(true)
      } else {
        setShowBackToTop(false)
      }
    }
  })

  if (!scrollNode) return null

  return (
    <Motion
      defaultStyle={{ opacity: 0 }}
      style={{ opacity: showBackToTop ? spring(1) : spring(0) }}
    >
      {(interpolated) => (
        <Button
          styleType="primary-alt"
          interactive
          style={{
            position: "fixed",
            bottom: 70,
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 1000,
            opacity: interpolated.opacity,
            pointerEvents: interpolated.opacity === 0 ? "none" : "auto",
            boxShadow: "0px 2px 15px rgba(0, 0, 0, 0.25)",
            fontWeight: `600 !important`,
            ...buttonProps.style
          }}
          onClick={() => {
            scrollTo(0)
          }}
          icon="chevron-up"
          label={t("Back to Top")}
          {...buttonProps}
        />
      )}
    </Motion>
  )
}

ScrollToTopButton.displayName = "ScrollToTopButton"

ScrollToTopButton.propTypes = {
  nodeSelector: PropTypes.string,
  offset: PropTypes.number
}

export default ScrollToTopButton
