import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import Button from "@pathwright/ui/src/components/button/Button"
import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import Card from "@pathwright/ui/src/components/card/Card"
import CardBlock from "@pathwright/ui/src/components/card/CardBlock"
import CardHeader from "@pathwright/ui/src/components/card/CardHeader"
import ErrorMessage from "@pathwright/ui/src/components/error/ErrorMessage"
import RadioButton from "@pathwright/ui/src/components/form/form-radio/RadioButton"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import Text from "@pathwright/ui/src/components/ui/Text"
import useSpaceHasFeature from "@pathwright/web/src/modules/lib/useSpaceHasFeature"
import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import get from "lodash/get"
import { useState } from "react"
import { Field, Form, Submit } from "react-connect-form-forked/dist/components"
import { validators } from "react-connect-form-forked/dist/utils"
import styled from "styled-components"
import AdaptedAuthContainer from "../auth/AdaptedAuthContainer"
import SignUpOrIn from "../auth/SignUpOrIn"
import { useCurrencyFormatter } from "../currency/CurrencyFormatterProvider"
import { usePathwrightContext } from "../pathwright/PathwrightContext"
import CURRICULUM_SUBSCRIPTION_PLANS_QUERY from "../school/graphql/curriculum-subscription-plans-query"
import SignupFlow from "../signup/SignupFlow"
import { flattenEdges } from "../utils/apollo"
import OrderStripeCheckoutForm from "./OrderStripeCheckoutForm"

const SchoolCard = styled.div`
  border: 1px solid #eee;
  border-radius: 1em;
  padding: 1em;
  margin: 1em;
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-column-gap: 0.5em;
  align-items: center;

  img {
    height: 3em;
    max-width: unset;
  }
`

export default function OrderCurriculumSubscriptionCard({
  card,
  visibilityCode,
  visibilityCodeFilter
}) {
  const { me, school } = usePathwrightContext()
  const { currencyFormatter } = useCurrencyFormatter()
  const hasAuthPw3 = useSpaceHasFeature("auth__platform")

  const curriculumPlansQuery = useQuery(CURRICULUM_SUBSCRIPTION_PLANS_QUERY, {
    variables: { visibilityCode, visibilityCodeFilter }
  })

  // NOTE: visibilityCodeFilter only supports eq right now.
  const planVisibilityCode =
    visibilityCode || (visibilityCodeFilter && visibilityCodeFilter.eq)

  const error = curriculumPlansQuery.error
  const loading = curriculumPlansQuery.loading
  const curriculumPlans =
    flattenEdges(get(curriculumPlansQuery, "data.school.curriculum_plans")) ||
    []

  const [curriculumPlanId, setCurriculumPlanId] = useState(null)
  const [licenseeSchool, setLicenseeSchool] = useState(null)
  const curriculumPlan = curriculumPlans.find((x) => x.id === curriculumPlanId)

  function getCurriculumPlanLabel(plan) {
    return `${plan.name} — ${currencyFormatter.customFormat(plan.amount)}/month`
  }

  return (
    <Card card={card} navless noaction>
      <CardHeader
        card={card}
        title="Build Your Online School"
        meta={`Setup your online school and offer courses from ${school.name}`}
      />
      {error ? (
        <BlankSlate
          icon="statuscaution"
          heading="An unexpected error occurred"
          body={<ErrorMessage error={error} />}
        />
      ) : loading ? (
        <LoadingCircle />
      ) : curriculumPlans.length === 0 ? (
        <BlankSlate icon="subscription" heading="No curriculum plans found" />
      ) : !curriculumPlan ? (
        <CardBlock>
          <Text.H4>Choose a plan</Text.H4>
          <Text.Body>
            Select a monthly subscription plan based on how many members you'd
            like to have access to your school.
          </Text.Body>
          <br />
          <Form onSubmit={(values) => setCurriculumPlanId(values.plan)}>
            {curriculumPlans.map((x, i) => (
              <Field
                name="plan"
                value={x.id}
                type="radio"
                initialChecked={i === 0}
                component={RadioButton}
                validators={[validators.isRequired]}
                required
              >
                {getCurriculumPlanLabel(x)}
              </Field>
            ))}
            <br />
            <br />
            <footer style={{ textAlign: "center" }}>
              <Submit size="large" styleType="primary" component={SubmitButton}>
                Next
              </Submit>
            </footer>
          </Form>
        </CardBlock>
      ) : !me ? (
        hasAuthPw3 ? (
          <AdaptedAuthContainer authWrapperProps={{ w: "unset" }} />
        ) : (
          <SignUpOrIn />
        )
      ) : !licenseeSchool ? (
        <SignupFlow
          // passing licensorId will set this school up as a curriculum subscriber
          licensorId={school.id}
          onSchoolSelected={setLicenseeSchool}
          namePlaceholder="School name..."
          renderNav={() => (
            <Button styleType="blank" onClick={() => setCurriculumPlanId(null)}>
              <Pathicon icon="arrowleft" />
              Back
            </Button>
          )}
          renderHeader={() => (
            <>
              <CardBlock>
                <Text.H4>Your Online School</Text.H4>
                <Text.Body>
                  Your online school is where your members will access all of
                  your courses. As a school administrator you'll be able manage
                  your school and publish courses from {school.name}.
                </Text.Body>
              </CardBlock>
              {/* <SignedIn /> */}
            </>
          )}
          renderSubmit={({ step, ...submitProps }) => (
            <CardBlock paddingTop={0}>
              <SubmitButton {...submitProps} styleType="primary" size="large">
                Next
              </SubmitButton>
            </CardBlock>
          )}
        />
      ) : (
        <>
          <Button styleType="blank" onClick={() => setLicenseeSchool(null)}>
            <Pathicon icon="arrowleft" />
            Back
          </Button>
          <br />
          <SchoolCard>
            <img src={licenseeSchool.image} />
            <Text.H4>{licenseeSchool.name}</Text.H4>
          </SchoolCard>
          <CardBlock>
            <Text.H4>Proceed to payment</Text.H4>
            <Text.Body>
              We’ll automatically bill your credit card each month based on the
              total number of members in your account. We'll automatically
              adjust your monthly fee as your membership grows or shrinks.
            </Text.Body>
            <br />
            <Text.Body>
              You've selected the plan:{" "}
              <strong>{getCurriculumPlanLabel(curriculumPlan)}</strong>
            </Text.Body>
            <br />
            <br />
            <OrderStripeCheckoutForm
              orderType="createCurriculumSubscriptionOrder"
              orderData={{
                curriculum_plan_id: curriculumPlanId,
                plan_visibility_code: planVisibilityCode,
                licensee_id: licenseeSchool.id
              }}
            />
          </CardBlock>
        </>
      )}
    </Card>
  )
}

OrderCurriculumSubscriptionCard.displayName = "OrderCurriculumSubscriptionCard"
