import ThemeBackground from "@pathwright/ui/src/components/background/ThemeBackground"
import AdaptedAuthContainer from "@pathwright/web/src/modules/auth/AdaptedAuthContainer"
import SignUpOrIn from "@pathwright/web/src/modules/auth/SignUpOrIn"
import { SIGN_IN } from "@pathwright/web/src/modules/auth/constants"
import useSpaceHasFeature from "@pathwright/web/src/modules/lib/useSpaceHasFeature"
import { usePathwrightContext } from "@pathwright/web/src/modules/pathwright/PathwrightContext"
import "./GlobalAuth.css"
import GlobalSchoolList from "./GlobalSchoolList"

const pwLogo = ""
// "https://pathwright.imgix.net/https%3A%2F%2Fwww.filepicker.io%2Fapi%2Ffile%2F7J8boTRey3SGsQOE7epg%3Fpolicy%3DeyJjYWxsIjogWyJyZWFkIiwgInN0YXQiLCAiY29udmVydCJdLCAiZXhwaXJ5IjogNDYyMDM3NzAzMX0%253D%26signature%3D888b9ea3eb997a4d59215bfbe2983c636df3c7da0ff8c6f85811ff74c8982e34?crop=faces&fit=crop&h=600&ixlib=python-1.1.0&w=600&s=03ec0eac7d7a32fafbbd1c79a1b64d7a"

const GlobalAuth = () => {
  const { me } = usePathwrightContext()
  const hasAuthPw3 = useSpaceHasFeature("auth__platform")
  return (
    <>
      <ThemeBackground />
      <div className="GlobalAuth__wrapper">
        <div className="GlobalAuth">
          {!me ? (
            <header className="GlobalAuth__header">
              <img src={pwLogo} />
              <h1>Sign in to Pathwright</h1>
            </header>
          ) : undefined}
          {hasAuthPw3 ? (
            <AdaptedAuthContainer
              allowSignup={false}
              createMembership={false}
            />
          ) : (
            <SignUpOrIn view={SIGN_IN} renderHeader={() => null} />
          )}
          {me ? <GlobalSchoolList /> : undefined}
        </div>
      </div>
    </>
  )
}

GlobalAuth.displayName = "GlobalAuth"

export default GlobalAuth
