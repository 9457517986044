import Screensize from "./Screensize"

/*
const className = cx(
  css(heading1(props)),
  css(customh1(props))
)

const style = {
  ...heading1(props),
  ...customh1(props),
}

const ContentWrapper = styled('div')`
  h1 {
    ${heading1}
    ${customh1}
  }
  h2 {
    ${heading2}
    ${customh2}
  }
`
*/

const defaultStyles = {
  margin: 0,
  fontSize: "1em",
  fontWeight: 400,
  lineHeight: "1em"
}

const fontFamily = (props) =>
  props.font
    ? {
        fontFamily: props.font
      }
    : {}

const sizeAdjust = (size, value) => {
  const adjustMap = {
    small: 0.875,
    medium: 1,
    large: 1.125
  }

  return adjustMap[size || "medium"] * value
}

export const heading1 = (props) => ({
  ...defaultStyles,
  fontSize: Screensize.select({
    sm: `${sizeAdjust(props.size, 1.95)}em`,
    md: `${sizeAdjust(props.size, 2.1)}em`,
    lg: `${sizeAdjust(props.size, 2.25)}em`
  })(props),
  fontWeight: 700,
  ...fontFamily(props)
})

export const heading2 = (props) => ({
  ...defaultStyles,
  fontSize: Screensize.select({
    sm: `${sizeAdjust(props.size, 0.9)}em`,
    md: `${sizeAdjust(props.size, 1.875)}em`,
    lg: `${sizeAdjust(props.size, 2)}em`
  })(props),
  fontWeight: 700,
  ...fontFamily(props)
})

export const heading3 = (props) => ({
  ...defaultStyles,
  fontSize: Screensize.select({
    sm: `${sizeAdjust(props.size, 1.3125)}em`,
    md: `${sizeAdjust(props.size, 1.4)}em`,
    lg: `${sizeAdjust(props.size, 1.5)}em`
  })(props),
  fontWeight: 700,
  ...fontFamily(props)
})

export const heading4 = (props) => ({
  ...defaultStyles,
  fontSize: Screensize.select({
    sm: `${sizeAdjust(props.size, 1.09375)}em`,
    md: `${sizeAdjust(props.size, 1.17)}em`,
    lg: `${sizeAdjust(props.size, 1.25)}em`
  })(props),
  fontWeight: 700,
  ...fontFamily(props)
})

export const heading5 = (props) => ({
  ...defaultStyles,
  fontSize: Screensize.select({
    sm: `${sizeAdjust(props.size, 0.875)}em`,
    md: `${sizeAdjust(props.size, 0.9375)}em`,
    lg: `${sizeAdjust(props.size, 1)}em`
  })(props),
  fontWeight: 700,
  lineHeight: `${sizeAdjust(props.size, 1.3)}em`,
  ...fontFamily(props)
})

export const heading6 = (props) => ({
  ...defaultStyles,
  fontSize: Screensize.select({
    sm: `${sizeAdjust(props.size, 0.55)}em`,
    md: `${sizeAdjust(props.size, 0.6)}em`,
    lg: `${sizeAdjust(props.size, 0.65)}em`
  })(props),
  fontWeight: 700,
  lineHeight: `${sizeAdjust(props.size, 1.3)}em`,
  ...fontFamily(props)
})

export const body = (props) => ({
  ...defaultStyles,
  fontSize: Screensize.select({
    sm: `${sizeAdjust(props.size, 0.875)}em`,
    md: `${sizeAdjust(props.size, 0.9375)}em`,
    lg: `${sizeAdjust(props.size, 1)}em`
  })(props),
  lineHeight: `${sizeAdjust(props.size, 1.3)}em`,
  ...fontFamily(props)
})

export const meta = (props) => ({
  ...defaultStyles,
  fontSize: Screensize.select({
    sm: `${sizeAdjust(props.size, 0.765)}em`,
    md: `${sizeAdjust(props.size, 0.82)}em`,
    lg: `${sizeAdjust(props.size, 0.875)}em`
  })(props),
  lineHeight: `${sizeAdjust(props.size, 1.3)}em`,
  ...fontFamily(props)
})

export const textStyles = (props) => {
  if (props.typography === "heading1") return heading1(props)
  if (props.typography === "heading2") return heading2(props)
  if (props.typography === "heading3") return heading3(props)
  if (props.typography === "heading4") return heading4(props)
  if (props.typography === "heading5") return heading5(props)
  if (props.typography === "heading6") return heading6(props)
  if (props.typography === "meta") return meta(props)
  return body(props)
}

export const overflowStyles = (props) => {
  if (typeof props.lines !== "number") return null
  const { lineHeight } = textStyles(props)
  let styles = {
    position: "relative",
    overflow: "hidden",
    display: "block",
    lineHeight: lineHeight
  }

  // fade out
  if (props.fade) {
    styles = {
      ...styles,
      width: "200%",
      maxHeight: `calc(${lineHeight} * ${props.lines})`
    }

    styles["&:after"] = {
      content: "''",
      position: "absolute",
      top: `calc(${lineHeight} * ${props.lines - 1})`,
      right: 0,
      height: lineHeight,
      width: `calc(50% + 50px)`,
      background: `linear-gradient(to right, rgba(255, 255, 255, 0), ${props.backgroundColor} 50px)` // prettier-ignore
    }

    if (props.lines > 1) {
      styles["&:before"] = props.lines > 1 && {
        content: "''",
        display: "inline-block",
        float: "right",
        width: "50%",
        height: `calc(${lineHeight} * ${props.lines - 1.5})`
      }
    }
  }

  if (!props.fade) {
    if (props.lines === 1) {
      // if only 1 line, truncate with nowrap
      styles = {
        ...styles,
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
      }
    } else {
      // clamp lines with ellipses https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-line-clamp
      styles = {
        ...styles,
        maxWidth: "100%",
        display: "-webkit-box",
        WebkitLineClamp: props.lines,
        WebkitBoxOrient: "vertical"
      }
    }
  }

  return styles
}

export const DEFAULT_FONT_FAMILY = `-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`
