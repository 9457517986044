import Avatar from "@pathwright/ui/src/components/avatar/Avatar"
import AvatarCardTrigger from "@pathwright/ui/src/components/avatar/AvatarCardTrigger"
import CardStack from "@pathwright/ui/src/components/card/CardStack"
import Modal from "@pathwright/ui/src/components/modal/Modal"
import PropTypes from "prop-types"
import React from "react"
import ProfileCard from "./ProfileCard"

const ProfileAvatarCardTrigger = ({
  user,
  canViewCard,
  canViewProfile,
  ...passProps
}) => {
  const [showProfile, setShowProfile] = React.useState(false)

  if (!canViewCard) {
    return <Avatar user={user} {...passProps} />
  }

  if (!canViewProfile) {
    return <AvatarCardTrigger user={user} {...passProps} />
  }

  return (
    <>
      <AvatarCardTrigger
        user={user}
        onClickAvatar={() => setShowProfile(true)}
        {...passProps}
      />
      {showProfile && (
        <Modal onClose={() => setShowProfile(false)}>
          <CardStack
            card={{
              component: ProfileCard,
              props: { userId: user.id }
            }}
            onCloseCard={(card, index) => {
              index === 0 && setShowProfile(false)
            }}
          />
        </Modal>
      )}
    </>
  )
}

ProfileAvatarCardTrigger.displayName = "ProfileAvatarCardTrigger"

ProfileAvatarCardTrigger.propTypes = {
  user: PropTypes.object.isRequired,
  canViewCard: PropTypes.bool.isRequired,
  canViewProfile: PropTypes.bool.isRequired
}

ProfileAvatarCardTrigger.defaultProps = {
  canViewCard: true,
  canViewProfile: true
}

export default ProfileAvatarCardTrigger
