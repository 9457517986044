export default window.App.module(
  "Behaviors",
  function (Behaviors, App, Backbone, Marionette, $, _) {
    // Have to explicitly require the behaviors
    Behaviors.PaginatedCollection = require("./paginated-collection").default
    Behaviors.FilteredCollection = require("./filtered-collection").default
    Behaviors.Media = require("./media").default
    Behaviors.ViewStates = require("./view-states").default
    Behaviors.Bind = require("./bind").default
    Behaviors.Highlight = require("./highlight").default
    Behaviors.State = require("./state").default
    Behaviors.FlashMessage = require("./flash-message").default
    Behaviors.Vents = require("./vents").default
    Behaviors.Radio = require("./radio").default
    Behaviors.RichText = require("./richtext").default
    Behaviors.Parsley = require("./parsley").default
    Behaviors.Tooltip = require("./tooltip").default
    Behaviors.GridForm = require("./grid-form").default
    Behaviors.SwitchState = require("./switch-state").default
    Behaviors.MathJax = require("./mathjax").default
    Behaviors.FocusFirstField = require("./focus-first-field").default
    Behaviors.ConnectStore = require("./connect-store").default

    window.App.commands.setHandler(
      "register:behavior",
      (key, BehaviorsClass) => (Behaviors[key] = BehaviorsClass)
    )

    return (Marionette.Behaviors.behaviorsLookup = Behaviors)
  }
)
