import Alert from "@pathwright/ui/src/components/alert/Alert"
import IconButton from "@pathwright/ui/src/components/button/IconButton"
import DockPanel, {
  DockPanelContext
} from "@pathwright/ui/src/components/panel/DockPanel"
import ScrollView from "@pathwright/ui/src/components/scroll/ScrollView"
import Tooltip from "@pathwright/ui/src/components/tooltip/Tooltip"
import { usePreload } from "@pathwright/ui/src/components/utils/preload"
import { useEffect, useState } from "react"

// HACK ALERT: requiring notes store which is only
// initialized once the LazyLibraryRoutes have loaded.
const withNotesStore = (Component) => (props) => {
  const [preloading] = usePreload({
    Component: window.LazyLibraryRoutes,
    preload: true
  })

  if (preloading) return null

  return <Component {...props} />
}

const WrappedNotePanelView = (props) => {
  // NOTE: rendering window.WrappedNotePanelView directly fails in legacy/web
  const WrappedNotePanelView = window.WrappedNotePanelView
  if (WrappedNotePanelView) return <WrappedNotePanelView {...props} />
  return null
}

WrappedNotePanelView.displayName = "WrappedNotePanelView"

const CompletionFeedbackNotes = ({ pathItem }) => {
  const [notes, setNotes] = useState(null)
  const [showNotes, setShowNotes] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    window.App && window.App.getStore("note")._mutate({ mode: "view" })
    return () => {
      window.App && window.App.getStore("note")._mutate({ mode: "edit" })
    }
  }, [])

  useEffect(() => {
    if (!pathItem) {
      return
    }

    const noteContext = {
      course: pathItem.context.resource_id,
      class: pathItem.context.cohort_id,
      step: pathItem.source_id
    }

    const noteContextStr = Object.keys(noteContext)
      .map((key) => `${key}_${noteContext[key]}`)
      .join(",")

    window.App &&
      window.App.getStore("note")
        .resource.note.list({
          context: noteContextStr
        })
        .then((notes) => {
          const notes_per_target = {
            [pathItem.source_id]: {
              notes,
              total_notes: notes.length,
              item: {
                ...pathItem,
                // massage data to be same shape as expected by note store
                step_verb: pathItem.verb,
                item_type: "A", // hardcoding as steps are the only type of path item that can be reviewed currently AND that value is not present in the schema
                sync_source_id: pathItem.source_id
              }
            }
          }
          window.App.getStore("note")._mutate({
            notes_per_target: notes_per_target
          })
          setNotes(notes)
        })
        .catch(setError)
  }, [pathItem.source_id])

  const hasNotes = notes ? notes.length > 0 : false

  if (!pathItem) {
    return
  }

  return (
    <>
      {hasNotes && (
        <Tooltip title="Notes">
          <IconButton icon="notes" onClick={() => setShowNotes(true)} />
        </Tooltip>
      )}
      {showNotes && (
        <DockPanel
          configKe="NoteDockPanel"
          panelWidthPerc={0.8}
          title={`Notes for ${pathItem.name}`}
          onClose={() => setShowNotes(false)}
        >
          <DockPanelContext.Consumer>
            {({ setHeader }) =>
              error ? (
                <Alert error={error} />
              ) : hasNotes ? (
                <ScrollView fill>
                  <WrappedNotePanelView
                    notes={notes}
                    setHeader={setHeader}
                    view="list"
                    target_id={pathItem.source_id}
                  />
                </ScrollView>
              ) : null
            }
          </DockPanelContext.Consumer>
        </DockPanel>
      )}
    </>
  )
}

CompletionFeedbackNotes.displayName = "CompletionFeedbackNotes"

export default withNotesStore(CompletionFeedbackNotes)
