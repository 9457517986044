import gql from "graphql-tag"

export default gql`
  mutation ($id: Int!, $is_archived: Boolean!) {
    setMemberArchived(id: $id, is_archived: $is_archived) {
      id
      archived_time
      has_access
    }
  }
`
