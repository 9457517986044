import Success from "@pathwright/ui/src/components/success/Success"
import PathwrightUI, {
  useUIThemeContext
} from "@pathwright/ui/src/components/ui/PathwrightUI"
import { constructUrl } from "@pathwright/web/src/modules/utils/urls"
import pluralize from "pluralize"
import { useEffect } from "react"
import inviteStore from "../../store/invite-store"

const InviteSuccess = () => {
  const closeTo =
    inviteStore.context && inviteStore.context.offering
      ? constructUrl(inviteStore.context.offering.url, "path")
      : "/community/members/"
  const toPending =
    inviteStore.context && inviteStore.context.offering
      ? `${inviteStore.context.offering.url}invitation/?status=pending`
      : "/manage/invitation/?status=pending"

  const themeContext = useUIThemeContext()
  const resource = inviteStore.context && inviteStore.context.resource

  const theme = resource
    ? {
        backgroundImage: resource.background_image_blurred_cdn,
        backgroundOverlay: resource.background_overlay
      }
    : themeContext

  // clear the inviteStore after unmounting
  useEffect(
    () => () => {
      inviteStore.action.reset()
    },
    []
  )

  return (
    <PathwrightUI.Provider theme={theme}>
      <Success
        icon="check"
        inverted
        heading={
          inviteStore.numsent > 0 &&
          `${pluralize("people", inviteStore.numsent, true)} invited`
        }
        primaryAction={{
          children: "Done",
          to: closeTo
        }}
        secondaryAction={{
          children: "View pending invites",
          to: toPending
        }}
      />
    </PathwrightUI.Provider>
  )
}

InviteSuccess.displayName = "InviteSuccess"

export default InviteSuccess
