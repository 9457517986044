import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import Card from "@pathwright/ui/src/components/card/Card"
import CardBlock from "@pathwright/ui/src/components/card/CardBlock"
import TextInput from "@pathwright/ui/src/components/form/form-text-input/TextInput"
import Fieldset from "@pathwright/ui/src/components/form/form-utils/Fieldset"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import gql from "graphql-tag"
import get from "lodash/get"
import PropTypes from "prop-types"
import { Field, Form, Submit } from "react-connect-form-forked"
import {
  HELP_LINK_INTERCOM,
  INTERCOM_IDENTITY_VERIFICATION
} from "../../help/constants"

// Note that the intercom integrations fields aren't synced with
// the context-query's intercom integrations fields. Might cause
// just a slight confusion, probably only if the "Enabled" preview
// on the Intercom integration card link remains visible after removing app ID.
const INTERCOM_INTEGRATION_QUERY = gql`
  query IntercomIntegrationQuery {
    integrations {
      intercom {
        school_app_id
        school_secret_key
        school_access_token
      }
    }
  }
`

const ManageIntercom = ({ card, onSubmit }) => {
  const intercomIntegrationQuery = useQuery(INTERCOM_INTEGRATION_QUERY, {
    fetchPolicy: "network-only"
  })
  const intercomIntegration = get(
    intercomIntegrationQuery,
    "data.integrations.intercom"
  )
  const initialValue = intercomIntegration
    ? {
        intercom_app_id: intercomIntegration.school_app_id,
        intercom_secret_key: intercomIntegration.school_secret_key,
        intercom_access_token: intercomIntegration.school_access_token
      }
    : null

  return (
    <Card card={card} title="Intercom Integration">
      {initialValue ? (
        <Form initialValue={initialValue} onSubmit={onSubmit}>
          <CardBlock>
            <Fieldset
              title="Send personal, timely, in-app messages & emails"
              header={
                <p>
                  Embed Intercom.io's messenger into your account to enable
                  chat, personal support, and automatically schedule in app
                  messages & emails based on activity.{" "}
                  <a
                    href={HELP_LINK_INTERCOM}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Learn more about our Intercom.io integration.
                  </a>
                </p>
              }
            >
              <Field
                name="intercom_app_id"
                label="Intercom App ID"
                labelWidth={170}
                placeholder="1abcde2f"
                helperText="To enable, paste the App ID from Intercom Settings > App settings > API keys."
                component={TextInput}
              />
            </Fieldset>
          </CardBlock>
          <CardBlock>
            <Fieldset
              title="Ensure your users are who they claim to be"
              header={
                <p>
                  Enabling Intercom.io's Identity Verification will keep your
                  conversations secure and prevent anyone from impersonating
                  another user.{" "}
                  <a
                    href={INTERCOM_IDENTITY_VERIFICATION}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Find your secret key and enable Intercom.io's Identity
                    Verification.
                  </a>
                </p>
              }
            >
              <Field
                name="intercom_secret_key"
                label="Intercom Secret Key"
                labelWidth={170}
                placeholder="f2edcba1"
                component={TextInput}
              />
            </Fieldset>
          </CardBlock>
          <CardBlock>
            <Fieldset
              title="Enable closer integration with Intercom"
              header={
                <p>
                  Track events like student subscriptions and group licensing.
                </p>
              }
            >
              <Field
                name="intercom_access_token"
                label="Intercom Access Token"
                labelWidth={170}
                placeholder="1abcde2ff2edcba1"
                component={TextInput}
              />
            </Fieldset>
          </CardBlock>
          <Submit styleType="primary" component={SubmitButton}>
            Save
          </Submit>
        </Form>
      ) : (
        <LoadingCircle center />
      )}
    </Card>
  )
}

ManageIntercom.displayName = "ManageIntercom"

ManageIntercom.propTypes = {
  card: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired
}

export default ManageIntercom
