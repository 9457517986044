import gql from "graphql-tag"
import RESOURCE_FRAGMENT from "../../resource/graphql/resource-fragment"
import GROUP_FRAGMENT from "./group-fragment"

export default gql`
  mutation (
    $base_resource_id: Int!
    $parent_offering_id: Int
    $visibility: Int!
    $name: String!
    $role: RegistrationRoleEnum
  ) {
    createGroup(
      base_resource_id: $base_resource_id
      parent_offering_id: $parent_offering_id
      visibility: $visibility
      name: $name
      role: $role
    ) {
      ...Group
      resource {
        ...Resource
        blurred_background: background_image(blur: 500, width: 250, height: 250)
      }
    }
  }
  ${GROUP_FRAGMENT}
  ${RESOURCE_FRAGMENT}
`
