import Dropdown from "@pathwright/ui/src/components/dropdown"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import classnames from "classnames"
import get from "lodash/get"
import React, { useState } from "react"
import {
  BackgroundTaskContextProvider,
  ScopedBackgroundTaskRefetchQuery
} from "../../bg-task/BackgroundTaskContext"
import {
  FEATURE_ACTION_VIEW,
  FEATURE_KEY_ACTIVITY_FEED,
  FEATURE_KEY_MEMBER_DIRECTORY
} from "../../feature/constants"
import { useFeatureUpdatePermission } from "../../feature/permissions"
import {
  hasEditorLevelAccess,
  hasModeratorLevelAccess,
  hasTeacherLevelAccess
} from "../../group/permissions"
import GroupNotificationsForm from "../../home/teach/item/GroupNotificationsForm"
import { getReviewRoute } from "../../inbox/routes"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import { ShareDumb } from "../../share/Share"
import {
  getGroupCommunityUrl,
  getGroupMembersUrl,
  getGroupMessageUrl,
  getManageGroupUrl,
  getManageResourceUrl
} from "../../utils/urls"
import CompleteCohortModal from "../completion/CompleteCohortModal"

const ReviewControl = ({ cohort, analytics }) => {
  const needsFeedbackCount =
    analytics && analytics.path ? analytics.path.need_feedback : 0

  const { tca } = useTranslate()

  return (
    <Dropdown.Item
      icon="inbox"
      label={tca("Review")}
      indicator={needsFeedbackCount > 0}
      to={{
        pathname: getReviewRoute(),
        query: {
          cohort_id: cohort.id,
          ...(needsFeedbackCount > 0 ? { filter: "pending" } : {})
        },
        state: { setLaunchedFrom: true }
      }}
    />
  )
}

const SettingsControl = ({ cohort }) => {
  const pwContext = usePathwrightContext()
  const { tca } = useTranslate()

  if (!hasEditorLevelAccess(pwContext, cohort)) return null

  return (
    <Dropdown.Item
      icon="gear"
      label={tca("Edit Cohort Settings")}
      to={getManageGroupUrl(cohort.resource.id, cohort.id)}
    />
  )
}

const CommunitySettingsControl = ({ cohort }) => {
  const pwContext = usePathwrightContext()

  // hiding for editors and above
  if (hasEditorLevelAccess(pwContext, cohort)) return null
  // showing for moderators and above
  if (!hasModeratorLevelAccess(pwContext, cohort)) return null

  return <CommunitySettingsControlContainer cohort={cohort} />
}

// Contains the queries that should only run (in a hook) after
// cheaper checks have been bypassed.
const CommunitySettingsControlContainer = ({ cohort }) => {
  const { tca } = useTranslate()

  // NOTE: cohort community settings also include discussion topics, but
  // only library editors and above can edit those anyhow.
  const canUpdate = [
    useFeatureUpdatePermission({
      featureKey: FEATURE_KEY_ACTIVITY_FEED,
      featureAction: FEATURE_ACTION_VIEW,
      context: {
        cohort_id: cohort.id,
        resource_id: cohort.resource.id
      }
    }),
    useFeatureUpdatePermission({
      featureKey: FEATURE_KEY_MEMBER_DIRECTORY,
      featureAction: FEATURE_ACTION_VIEW,
      context: {
        cohort_id: cohort.id,
        resource_id: cohort.resource.id
      }
    })
  ].some((perm) => perm.hasPermission)

  return canUpdate ? (
    <Dropdown.Item
      icon="gear"
      label={tca("Change Community Settings")}
      to={getGroupCommunityUrl({
        resourceSlug: cohort.resource.slug,
        groupId: cohort.id
      })}
    />
  ) : null
}

const MarkCompleteControl = ({ cohort, onMarkComplete }) => {
  const pwContext = usePathwrightContext()
  const { tca } = useTranslate()

  if (
    !hasTeacherLevelAccess(pwContext, cohort) ||
    cohort.status === "completed"
  )
    return null

  return (
    <Dropdown.Item
      icon="check"
      label={tca("Mark completed")}
      onClick={() => onMarkComplete()}
    />
  )
}

const ProgressControl = ({ cohort, section }) => {
  const { tca } = useTranslate()

  return (
    <Dropdown.Item
      icon="group"
      label={tca("View learner progress")}
      to={getGroupMembersUrl(cohort.resource.slug, cohort.id, section)}
    />
  )
}

export const MessageControl = ({ cohort, section }) => {
  const { tca } = useTranslate()

  return (
    <Dropdown.Item
      icon="envelope"
      label={tca("Message everyone")}
      to={getGroupMessageUrl(cohort.resource.slug, cohort.id, section)}
    />
  )
}

const ShareControl = React.forwardRef(({ cohort, onToggleIsOpen }, ref) => {
  const { tca } = useTranslate()

  return (
    <BackgroundTaskContextProvider>
      <>
        <ScopedBackgroundTaskRefetchQuery
          backbroundTaskRefetchQueries={[
            {
              type: "apollo",
              queryName: "Cohort",
              variables: {
                id: cohort.id,
                orderBy: ["-role", "created"]
              }
            }
          ]}
        />
        <ShareDumb
          context={{ type: "cohort", typeId: cohort.id }}
          placement="bottom-end"
          displayType="modal"
          onToggleIsOpen={onToggleIsOpen}
        >
          {({ renderTrigger }) =>
            renderTrigger(
              React.forwardRef((shareTriggerProps, ref) => (
                <Dropdown.Item
                  {...shareTriggerProps}
                  ref={ref}
                  icon="group-plus"
                  label={tca("Share")}
                  onClick={shareTriggerProps.onToggle}
                  closeOnClick={false}
                />
              ))
            )
          }
        </ShareDumb>
      </>
    </BackgroundTaskContextProvider>
  )
})

const EditResourceControl = ({ cohort, showDivider = false }) => {
  const { tca } = useTranslate()
  const pwContext = usePathwrightContext()
  // hiding for editors and above
  if (!hasEditorLevelAccess(pwContext, cohort)) return null

  return (
    <>
      {showDivider && <Dropdown.Divider style={{ margin: 0 }} />}
      <Dropdown.Item
        icon="gear"
        label={tca("Configure Path")}
        to={getManageResourceUrl(cohort.resource.id)}
      />
    </>
  )
}

const CohortControlsContainer = React.forwardRef(
  (
    {
      cohort,
      analytics,
      renderDropdown,
      className,
      style,
      showFeedbackIndicator,
      children
    },
    ref
  ) => {
    const [showCompleteModal, setShowCompleteModal] = useState(false)
    // Prevent hiding the Menu, especiallyl necessary when user is interacting
    // with the Share modal.
    const [preventHideMenu, setPreventHideMenu] = useState(false)

    const showIndicator =
      showFeedbackIndicator && analytics && cohort.status !== "completed"
        ? get(analytics, "path.need_feedback") > 0
        : false

    return (
      <div ref={ref} className={classnames("CohortControls", className)}>
        <Dropdown.Trigger
          style={style}
          onClick={(e) => e.stopPropagation()}
          preventHide={preventHideMenu}
          renderDropdown={() =>
            renderDropdown({
              setShowCompleteModal,
              setPreventHideMenu
            })
          }
        >
          {children({ showIndicator })}
        </Dropdown.Trigger>
        {showCompleteModal && (
          <CompleteCohortModal
            cohort={cohort}
            onClose={() => setShowCompleteModal(false)}
          />
        )}
      </div>
    )
  }
)

CohortControlsContainer.defaultProps = {
  showFeedbackIndicator: true
}

CohortControlsContainer.displayName = "CohortControlsContainer"

export const CohortControls = React.forwardRef(
  (
    {
      cohort,
      analytics,
      section,
      showFeedbackIndicator,
      showShareControl,
      className,
      children
    },
    ref
  ) => {
    return (
      <CohortControlsContainer
        ref={ref}
        cohort={cohort}
        analytics={analytics}
        showFeedbackIndicator={showFeedbackIndicator}
        className={classnames("CohortControls", className)}
        closeOnClick={false}
        renderDropdown={({ setShowCompleteModal, setPreventHideMenu }) => (
          <Dropdown.Menu>
            {showShareControl && (
              <ShareControl
                cohort={cohort}
                onToggleIsOpen={setPreventHideMenu}
              />
            )}
            <ReviewControl cohort={cohort} analytics={analytics} />
            <ProgressControl cohort={cohort} section={section} />
            <MessageControl cohort={cohort} section={section} />
            <SettingsControl cohort={cohort} />
            <CommunitySettingsControl cohort={cohort} />
            <MarkCompleteControl
              cohort={cohort}
              onMarkComplete={() => setShowCompleteModal(true)}
            />
            {cohort.registration ? (
              <GroupNotificationsForm
                registration={cohort.registration}
                labelWidth={170}
              />
            ) : null}
            <EditResourceControl cohort={cohort} showDivider />
          </Dropdown.Menu>
        )}
      >
        {children}
      </CohortControlsContainer>
    )
  }
)

CohortControls.displayName = "CohortControls"
