import Alert from "@pathwright/ui/src/components/alert/Alert"
import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import Card from "@pathwright/ui/src/components/card/Card"
import CardHeader from "@pathwright/ui/src/components/card/CardHeader"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import get from "lodash/get"
import styled from "styled-components"
import SSO_LINK_QUERY from "../auth/graphql/sso-link-query"
import { useCurrencyFormatter } from "../currency/CurrencyFormatterProvider"
import { usePathwrightContext } from "../pathwright/PathwrightContext"
import CURRICULUM_SUBSCRIPTION_QUERY from "../school/graphql/curriculum-subscription-query"
import useOrderPollingQuery from "./useOrderPollingQuery"

const NarrowCard = styled(Card)`
  & > .View {
    max-width: 500px;
  }
`

const CenteredContent = styled.div`
  padding: 40px 25px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
`

export default function CurriculumSubscriptionOrderConfirmationCard({
  card,
  orderId
}) {
  const { school } = usePathwrightContext()
  const { currencyFormatter } = useCurrencyFormatter()
  const orderQuery = useOrderPollingQuery(orderId)
  const order = get(orderQuery, "data.order")

  const curriculumSubscriptionQuery = useQuery(CURRICULUM_SUBSCRIPTION_QUERY, {
    skip: !order?.fulfilled_object_id,
    variables: {
      schoolId: order?.school_id,
      curriculumSubscriptionId: order?.fulfilled_object_id
    }
  })
  const curriculumSubscription = get(
    curriculumSubscriptionQuery,
    "data.context.school.curriculum_subscription"
  )

  const ssoLinkQuery = useQuery(SSO_LINK_QUERY, {
    variables: {
      schoolId: get(curriculumSubscription, "licensee.id")
    },
    skip: !curriculumSubscription
  })
  const ssoLink = get(ssoLinkQuery, "data.context.school.sso_link")

  return (
    <NarrowCard card={card} navless>
      {curriculumSubscription ? (
        <>
          <CardHeader
            card={card}
            title={school.name}
            meta={`Curriculum Subscription • ${currencyFormatter.customFormat(
              curriculumSubscription.curriculum_plan.amount
            )}/member`}
          />
          {orderQuery.error ? (
            <CenteredContent>
              <Alert error={orderQuery.error} />
            </CenteredContent>
          ) : order && order.order_status === "fulfilled" ? (
            <BlankSlate
              animateIcon
              stack
              icons={null}
              icon="check"
              heading={`${curriculumSubscription.licensee.name} is ready to teach!`}
              body={`The curriculum is ready to offer under your account, ${curriculumSubscription.licensee.name}`}
              primaryAction={{
                href: ssoLink,
                children: "Go To Your School"
              }}
              secondaryAction={{
                brand: true,
                to: "/library/",
                children: `Or return to ${school.name} library.`
              }}
            />
          ) : (
            <BlankSlate
              icons={null}
              heading="Processing order..."
              primaryAction={{
                brand: false,
                submitting: true
              }}
            />
          )}
        </>
      ) : (
        <LoadingCircle />
      )}
    </NarrowCard>
  )
}

CurriculumSubscriptionOrderConfirmationCard.displayName =
  "CurriculumSubscriptionOrderConfirmationCard"
