// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
let MathJaxBehavior
const {
  renderMathInElement
} = require("lib/core/components/text/katex-auto-render")

export default MathJaxBehavior = (function () {
  MathJaxBehavior = class MathJaxBehavior extends (
    require("lib/static-shim").default(Marionette.Behavior)
  ) {
    static initClass() {
      this.prototype.defaults = { event: "render" }
    }

    initialize() {
      if (renderMathInElement != null) {
        return this.listenTo(this.view, this.options.event, this._mathJax)
      }
    }

    _mathJax() {
      try {
        return renderMathInElement(this.el, { display: false })
      } catch (err) {
        return console.warn("Error parsing KaTex: ", err)
      }
    }

    onShow() {
      return _.delay(() => {
        return this._responsifyKatex()
      }, 100)
    }

    _responsifyKatex() {
      return this.$(".katex").each((i, el) => {
        const kw = $(el).width() + $(el).position().left
        const cw = this.$el.width()
        if (kw > cw) {
          const percShrink = Math.floor((cw / kw) * 100)
          return $(el).css("font-size", `${percShrink}%`)
        }
      })
    }
  }
  MathJaxBehavior.initClass()
  return MathJaxBehavior
})()
