import {
  Button,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  useBreakpointValue,
  useDisclosure
} from "@chakra-ui/react"
import { QrCodeIcon, XIcon } from "@pathwright/pathicons"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import { QRCodeCanvas } from "qrcode.react"
import LinkButton from "./LinkButton"

const tPrefix = "share_card.link_tab.actions.QR Code"

const QRCodeLink = ({ url }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { tc } = useTranslate()

  const qrSize = useBreakpointValue({
    base: "250",
    xs: "250",
    sm: "300",
    md: "400",
    lg: "500"
  })

  const downloadQRCode = () => {
    const canvas = document.getElementById("qr-code")
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream")
    let downloadLink = document.createElement("a")
    downloadLink.href = pngUrl
    downloadLink.download = `qr-code.png`
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }

  const handleOpen = () => {
    onOpen()
  }

  const handleClose = () => {
    onClose()
  }

  return (
    <>
      <LinkButton
        label={tc(`${tPrefix}.buttonLabel`)}
        icon={<QrCodeIcon />}
        onClick={handleOpen}
      />

      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent
          className="InviteQRCode"
          maxWidth={["100%", "100%", "700px"]}
          width="100%"
          borderRadius="16px"
          mx={5}
          position="relative"
        >
          <Button
            onClick={handleClose}
            variant="ghost"
            _hover={{ bgColor: "transparent" }}
            _active={{ bgColor: "transparent" }}
            color="gray.900"
            width="fit-content"
            position="absolute"
            top={3}
            right={2}
          >
            <XIcon size={"24px"} />
          </Button>
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            m={10}
            pt={2}
          >
            <QRCodeCanvas
              id="qr-code"
              value={url}
              size={qrSize}
              style={{ width: "100%" }}
            />
            <Button
              onClick={() => {
                downloadQRCode()
                handleClose()
              }}
              _hover={{ bgColor: "transparent", textDecoration: "underline" }}
              variant="unstyled"
              color="brand.500"
              mt={5}
            >
              {tc(`${tPrefix}.download`)}
            </Button>
          </Flex>
        </ModalContent>
      </Modal>
    </>
  )
}

export default QRCodeLink
