import Avatar from "@pathwright/ui/src/components/avatar/Avatar"
import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import IconButton from "@pathwright/ui/src/components/button/IconButton"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import ScrollView from "@pathwright/ui/src/components/scroll/ScrollView"
import Text from "@pathwright/ui/src/components/ui/Text"
import styled from "styled-components"

const List = styled.ul`
  margin: 0;
  padding: 0 25px 10px;
  list-style: none;
`

const Entry = styled.li`
  position: relative;
  display: flex;
  margin: 15px 0;
  align-items: center;
`

const Info = styled.div`
  position: relative;
  flex-grow: 1;
  padding: 2px 10px;
`

const Remove = styled(IconButton)`
  &:hover {
    color: #d0011b !important;
  }
  html.no-touch ${Entry}:not(:hover) & {
    opacity: 0;
  }
`

const AddMentorGroupMembersList = ({ items = [], mentorGroup, onRemove }) => {
  const { t } = useTranslate()

  return (
    <ScrollView style={{ height: 300 }}>
      <List>
        {items.length > 0 ? (
          items.map((item, index) => {
            if (item.type === "email") {
              return (
                <Entry key={item.email}>
                  <Avatar initials={item.email.charAt(0)} size="36px" />
                  <Info>
                    <Text.H5>{item.email}</Text.H5>
                  </Info>
                  <Remove
                    onClick={() => onRemove(index)}
                    tabIndex={-1}
                    icon="x"
                    color="#aaa"
                  />
                </Entry>
              )
            }
            if (item.type === "user") {
              return (
                <Entry key={item.user.id}>
                  <Avatar user={item.user} size="36px" />
                  <Info>
                    <Text.H5>{item.user.full_name}</Text.H5>
                    <Text.Meta>{item.user.email}</Text.Meta>
                  </Info>
                  <Remove
                    onClick={() => onRemove(index)}
                    tabIndex={-1}
                    icon="x"
                    color="#aaa"
                  />
                </Entry>
              )
            }
            return null
          })
        ) : (
          <BlankSlate
            icons={null}
            heading={t("Add people to {{ group }}", {
              group: mentorGroup.name
            })}
            body={t("Type existing members or invite new members by email.")}
          />
        )}
      </List>
    </ScrollView>
  )
}

export default AddMentorGroupMembersList
