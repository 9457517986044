import { useEffect, useMemo, useState } from "react"
import isEqual from "react-fast-compare"
import useControlledQueryParams from "../utils/useControlledQueryParams"
import useTags from "./useTags"

// Controls what tags are selected based on query params while updating
// query params (using supplied queryParamKey) when downstream selected tags change.
const useControlledTagsQueryParam = ({ context, queryParamKey = "tags" }) => {
  const [selectedTags, setSelectedTags] = useState([])

  // Use currently available tags to filter the selectedTags as a way of
  // validating the selectedTags, which only really makes sense for those
  // tag IDs supplied from query params.
  const { tags, tagsData } = useTags({ context, selectedTags: selectedTags })

  // As selecteTags change, update query params.
  const tagsQueryParmsObj = useMemo(
    () => ({
      [queryParamKey]: selectedTags ? selectedTags.map((tag) => tag.id) : []
    }),
    [selectedTags]
  )

  // Retrieve the currently selected tags as prescribed by the query params.
  const controlledQueryParams = useControlledQueryParams(tagsQueryParmsObj)
  const queryParamTagIds = controlledQueryParams[queryParamKey].map((id) =>
    parseInt(id)
  )
  // Form the tagIdFilter based on the queryParamTagIds.
  const tagIdFilter = useMemo(
    () =>
      queryParamTagIds.length
        ? // Map the queryParamTagIds to numbers (as they may be string values).
          { in: queryParamTagIds }
        : null,
    [controlledQueryParams]
  )

  // As query params change, update selected tags.
  useEffect(() => {
    // Only after the tagsData has data should we proceed to setSelectedTags
    // based on queryParamTagIds.
    if (tagsData) {
      const selectedTagIds = selectedTags.map((tag) => tag.id)

      // Only proceed to setSelectedTags if ids of currently selectedTags
      // differ from queryParamTagIds.
      if (!isEqual(queryParamTagIds, selectedTagIds)) {
        // Only select those tags with ids matching queryParamTagIds if they also
        // appear in the tags.filtered to ensure we don't select invalid tags.
        const nextSelectedTags = tags.filtered.filter((tag) =>
          queryParamTagIds.includes(tag.id)
        )
        setSelectedTags(nextSelectedTags)
      }
    }
  }, [controlledQueryParams, tagsData])

  return {
    tags: selectedTags,
    setTags: setSelectedTags,
    tagIdFilter
  }
}

export default useControlledTagsQueryParam
