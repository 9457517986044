import Button from "@pathwright/ui/src/components/button/Button"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import Tooltip from "@pathwright/ui/src/components/tooltip/Tooltip"
import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import gql from "graphql-tag"
import styled from "styled-components"

const STATS_QUERY = gql`
  query CalStats($path_id: ID) {
    calendarStats(path_id: $path_id) {
      total
      upcoming
      due
      late
    }
  }
`

const Container = styled.div`
  position: relative;
  display: inline-flex;
  > span {
    margin-left: 10px;
  }
  .calendarIconButton {
    display: inline-flex;
    color: rgba(255, 255, 255, 0.8);
    flex-direction: row;
    border: 0px;

    &:hover {
      color: #fff !important;
    }
  }
`

const CalendarButton = ({ pathId, label, ...props }) => {
  const { t } = useTranslate()
  const { data, error, loading } = useQuery(STATS_QUERY, {
    variables: {
      pathId
    }
  })

  // // Don't render at all if we have no dates to deal with
  if (loading || error) return null

  const { total, upcoming, late, due } = data.calendarStats
  if (total === 0) return null

  // NOTE: hiding indicator for not till we can provide better calendar navigation for quickly viewing due/late items
  const indicator = null // late > 0 ? DANGEROUS_RED : due > 0 ? WARNING_ORANGE : null
  const stats = []
  if (upcoming > 0) stats.push(t("{{ count }} upcoming", { count: upcoming }))
  if (due > 0) stats.push(t("{{ count }} due", { count: due }))
  if (late > 0) stats.push(t("{{ count }} late", { count: late }))
  // NOTE: hiding tooltip for now until we revisit how stats are calculated as currently some items are counting towards multiple stat categories
  const tooltip = "" // stats.join(", ")

  return (
    <Container className="CalendarButton">
      <Tooltip
        style={{ width: "100%", zIndex: 10000 }}
        placement="bottom"
        title={tooltip}
      >
        <Button
          styleType="secondary"
          className="calendarIconButton"
          to="/calendar/"
          {...props}
          icon="calendar"
          label={label}
          countIndicatorProps={
            indicator && {
              color: indicator
            }
          }
        />
      </Tooltip>
    </Container>
  )
}

CalendarButton.defaultProps = {
  label: "Calendar"
}

export default CalendarButton
