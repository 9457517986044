import gql from "graphql-tag"
import MEMBERSHIP_FRAGMENT from "../../membership/graphql/membership-fragment"

export default gql`
  mutation ($consents: Boolean) {
    updateMemberConsent(consents: $consents) {
      ...Membership
    }
  }
  ${MEMBERSHIP_FRAGMENT}
`
