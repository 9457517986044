import gql from "graphql-tag"
import SCHOOL_FRAGMENT from "../../school/graphql/school-fragment"
import SCHOOL_SUBSCRIPTION_PLAN_FRAGMENT from "./school-subscription-plan-fragment"

export default gql`
  query SchoolSubscriptionPlansQuery(
    $ids: IDFilter
    $visibility_code: String
    $visibility_code_filter: StringFilter
    $ignore_visibility: Boolean
    $giftable: Boolean
    $interval: SubscriptionIntervalType
    $intervalFilter: SubscriptionIntervalFilter
  ) {
    school {
      ...School
      subscription_plans(
        first: 50
        ids: $ids
        visibility_code: $visibility_code
        visibility_code_filter: $visibility_code_filter
        ignore_visibility: $ignore_visibility
        giftable: $giftable
        interval: $interval
        interval_filter: $intervalFilter
      ) {
        total
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            ...SchoolSubscriptionPlan
          }
        }
      }
    }
  }
  ${SCHOOL_FRAGMENT}
  ${SCHOOL_SUBSCRIPTION_PLAN_FRAGMENT}
`
