export const HELP_LINK_SUBSCRIPTIONS =
  "https://help.pathwright.com/sell-courses/offer-a-monthly-or-annual-subscription-plan"
export const HELP_LINK_DOMAIN =
  "https://help.pathwright.com/account-and-billing/managing-your-account/set-up-a-custom-domain"
export const HELP_LINK_ZAPIER_SETUP =
  "https://help.pathwright.com/integrations/integrate-with-other-apps-through-zapier"
export const HELP_LINK_ZAPIER_HOW_TO =
  "https://help.pathwright.com/integrations/how-to-automate-actions-with-zapier"
export const HELP_LINK_GA =
  "https://help.pathwright.com/account-and-billing/managing-your-account/track-affiliate-sales"
export const HELP_LINK_INTERCOM =
  "https://help.pathwright.com/integrations/integrate-with-intercom-to-provide-learners-with-a-private-chat"
export const HELP_LINK_DISCOUNTS =
  "https://help.pathwright.com/sell-courses/request-a-discount-or-free-coupon-code"
export const HELP_LINK_SSO =
  "https://help.pathwright.com/en/articles/1296482-integrate-sso-single-sign-on-with-your-pathwright-account"
export const INTERCOM_IDENTITY_VERIFICATION =
  "https://app.intercom.io/a/apps/_/guide/web_integration/web_integration_identity_verification"
// export const HELP_LINK_ZAPIER_PW_INVITATION =
//   "https://zapier.com/developer/invite/21184/1f61acbc74ba810417c4199d458fe8d5/"
export const HELP_LINK_ZAPIER_PW_INTEGRATION =
  "https://zapier.com/apps/pathwright/integrations/"
