import { useDisclosure } from "@chakra-ui/react"
import React, { useEffect } from "react"
import { useKey } from "react-use"
import { shareSections } from "../tabs/ShareTabs"

const ShareUIContext = React.createContext()

export const useShareUIContext = () => React.useContext(ShareUIContext)

const useOpenWithKeys = ({ onKeyCombo, shouldOpen }) => {
  useKey(
    (event) => {
      if (!shouldOpen) return false

      const isWindowsTrigger =
        event.altKey &&
        event.shiftKey &&
        !event.metaKey &&
        event.key &&
        event.key.toLowerCase() === "s"

      const isMacTrigger =
        event.metaKey &&
        event.shiftKey &&
        event.key &&
        event.key.toLowerCase() === "s"

      return isWindowsTrigger || isMacTrigger
    },
    () => {
      onKeyCombo()
    }
  )
}

const ShareUIContextProvider = ({
  children,
  context,
  openWithKeys,
  onToggleIsOpen
}) => {
  const { isOpen, onClose, onToggle } = useDisclosure()

  useOpenWithKeys({ onKeyCombo: onToggle, shouldOpen: openWithKeys })

  const [pendingInviteCount, setPendingInviteCount] = React.useState(0)
  const [tabIndex, setTabIndex] = React.useState(0)
  const tabKey = React.useMemo(() => shareSections[tabIndex]?.key, [tabIndex])

  const canClose = (e) => {
    // Check if we can close the Share card.
    return !(
      document.querySelector(".InviteQRCode") ||
      document.querySelector(".RoleDescriptorsDrawer") ||
      (document.querySelector(".chakra-toast") &&
        // Allow closing Share card when toast is present as long as user is not
        // clicking in toast (like so as to close the toast).
        [...document.querySelectorAll(".chakra-toast")].some((node) =>
          node.contains(e.target)
        ))
    )
  }

  useEffect(() => {
    onToggleIsOpen?.(isOpen)
  }, [isOpen])

  const value = {
    // Context props
    context,

    // State
    pendingInviteCount,
    setPendingInviteCount: () => {
      // console.log("TODO: setPendingInviteCount disabled for demo")
    },
    tabKey,
    tabIndex,
    setTabIndex,
    onClose,
    canClose,
    isOpen,
    onToggle
  }

  return (
    <ShareUIContext.Provider value={value}>
      {typeof children === "function" ? children(value) : children}
    </ShareUIContext.Provider>
  )
}

ShareUIContextProvider.displayName = "ShareUIContextProvider"

export default ShareUIContextProvider
