import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import IconButton from "@pathwright/ui/src/components/button/IconButton"
import SearchInput from "@pathwright/ui/src/components/form/form-text-input/SearchInput"
import useDebouncedValue from "@pathwright/ui/src/components/hooks/useDebouncedValue"
import useIntersection from "@pathwright/ui/src/components/hooks/useIntersection"
// import IconButton from "@pathwright/ui/src/components/button/IconButton"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import defaults from "lodash/defaults"
import pick from "lodash/pick"
import { stringify } from "querystring"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import { getQueryObject } from "../../utils/urls"
import { COMPLETION_FILTERS } from "../context/completion-filters"
import InboxNavigatorItem from "./InboxNavigatorItem"
import useInboxItems from "./useInboxItems"

const ItemsContainer = styled.div`
  width: 100%;
  min-width: min(280px, 100vw);
  max-width: 420px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 16px;

  max-height: calc(var(--vh) - 200px);
  display: flex;
  flex-direction: column;

  > header {
    display: flex;
    align-items: center;
    padding: 10px;
    .Pathicon.pathicon-inbox-fill {
      margin-right: 10px;
    }
    h1 {
      font-size: 1.5rem;
      font-weight: bold;
      margin: 0px;
      flex-grow: 1;
    }
  }

  > nav {
    padding: 0px 10px;
    display: flex;
    align-items: center;
    li[role="tab"] {
      flex-grow: 0;
      margin: 0.2em;
    }
  }

  .InboxNavigator__items {
    overflow-y: auto;
    flex-grow: 1;
    list-style: none;
    padding: 0;

    > li:last-child .InboxNavigatorItem {
      border-bottom: none;
    }
  }

  .SearchInput {
    margin: 10px;
    margin-bottom: 0;
  }
`

const LoadingMore = styled.div`
  height: 50px;
  width: 100%;
  position: relative;
`

const GroupItemContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  overflow: hidden;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  header {
    display: flex;
    flex-direction: column;
    h1,
    h2 {
      margin: 0;
    }
    h1 {
      font-size: 1.2em;
    }
    h2 {
      font-size: 0.8em;
      color: rgba(0, 0, 0, 0.8);
    }
  }

  .IconButton {
    margin-left: auto;
  }
`

// Paginate items on scroll.
const usePaginate = ({ loading, loadMore, hasMore, loadingMore }) => {
  const [scrollNode, setScrollNode] = useState()
  const { setIntersectionNode, hasIntersected } = useIntersection({
    root: scrollNode
  })

  useEffect(() => {
    if (hasIntersected && !loading && !loadingMore && hasMore) loadMore()
  }, [hasIntersected])

  return [setScrollNode, setIntersectionNode]
}

const InboxNavigator = ({ onSelect }) => {
  const [search, debouncedSearch, debouncingSearch, handleDebouncedSearch] =
    useDebouncedValue(300, null)
  const { items, loading, loadMore, hasMore, loadingMore } = useInboxItems({
    search: debouncedSearch
  })
  const { t } = useTranslate()
  const [setScrollNode, setIntersectionNode] = usePaginate({
    loading,
    loadMore,
    hasMore,
    loadingMore
  })
  const [showSearch, setShowSearch] = useState(false)
  const toggleSearch = () => {
    setShowSearch((search) => !search)
    handleDebouncedSearch(null)
  }

  const history = useHistory()

  const handleSelect = (item, filter) => () => {
    // Presrving default filter and order params if they are set.
    const defaultParams = {
      filter,
      order: "asc"
    }
    const currentParams = pick(
      getQueryObject(window.location.search),
      Object.keys(defaultParams)
    )
    const params = {
      ...defaults(currentParams, defaultParams),
      ui_selected_parent: item.id
    }

    if (item.type === "group") {
      params.group_id = item.id
    } else if (item.type === "path") {
      params.cohort_id = item.id
    }
    const location = {
      pathname: "/inbox",
      search: `?${stringify(params)}`
    }
    history.push(location)

    if (typeof onSelect === "function") {
      onSelect(item)
    }
  }

  return (
    <ItemsContainer className="InboxNavigator">
      <header>
        <Pathicon icon="inbox-fill" />
        <h1>Inbox</h1>
        <IconButton
          styleType={showSearch ? "secondary" : "tertiary"}
          icon="search"
          onClick={toggleSearch}
        />
      </header>
      {showSearch && (
        <SearchInput
          placeholder="Search..."
          onChange={handleDebouncedSearch}
          onClear={() => handleDebouncedSearch(null)}
          searching={debouncingSearch || (loading && search)}
          value={search}
        />
      )}
      {/* <nav>
        {filters.map(filter => {
          return (
            <Tab
              key={filter.key}
              onClick={handleFilter(filter)}
              active={selectedFilter === filter.key}
            >
              {filter.label}
            </Tab>
          )
        })}
      </nav> */}
      {loading && !items.length ? (
        <LoadingCircle center />
      ) : !items.length ? (
        <BlankSlate icons={false} heading={t("Nothing to review")} />
      ) : (
        <ul className="InboxNavigator__items" ref={setScrollNode}>
          {/* {items.map(item => {
            return (
              <GroupItemContainer key={item.id} onClick={handleSelect(item)}>
                <header>
                  {item.meta.subtitle && <h2>{item.meta.subtitle}</h2>}
                  <h1>{item.meta.title}</h1>
                </header>
                <IconButton
                  icon="inbox"
                  countIndicatorProps={{ count: item.meta.count }}
                />
              </GroupItemContainer>
            )
          })} */}
          {items.map((item, i) => (
            // Paginate after second to last item comes into view.
            <li
              key={`item-${i}`}
              ref={(i === items.length) - 2 && setIntersectionNode}
            >
              <InboxNavigatorItem
                key={`${item.id}${item.type}`}
                item={item}
                onSelect={handleSelect(item, COMPLETION_FILTERS.pending.key)}
                onSelectPending={handleSelect(
                  item,
                  COMPLETION_FILTERS.pending.key
                )}
              />
            </li>
          ))}
          {hasMore && (
            <LoadingMore>
              <LoadingCircle center />
            </LoadingMore>
          )}
        </ul>
      )}
    </ItemsContainer>
  )
}

InboxNavigator.displayName = "InboxNavigator"

export default InboxNavigator
