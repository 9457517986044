import CurriculumLicenseOrderConfirmationCard from "@pathwright/web/src/modules/order/CurriculumLicenseOrderConfirmationCard"
import CurriculumSubscriptionOrderConfirmationCard from "@pathwright/web/src/modules/order/CurriculumSubscriptionOrderConfirmationCard"
import GiftSubscriptionOrderConfirmationCard, {
  GiftSubscriptionConfirmationCard
} from "@pathwright/web/src/modules/order/GiftSubscriptionOrderConfirmationCard"
import OrderCurriculumSubscriptionCard from "@pathwright/web/src/modules/order/OrderCurriculumSubscriptionCard"
import OrderGiftSubscriptionCard from "@pathwright/web/src/modules/order/OrderGiftSubscriptionCard"
import OrderSubscriptionCard from "@pathwright/web/src/modules/order/OrderSubscriptionCard"
import SubscriptionOrderConfirmationCard, {
  SubscriptionRedeemptionConfirmationCard
} from "@pathwright/web/src/modules/order/SubscriptionOrderConfirmationCard"
import { getParam } from "@pathwright/web/src/modules/utils/urls"
import { createCardRoutes } from "lib/core/routing/card-routing"
import { withPrintContent } from "lib/core/utils/print"
import StoreOrLibraryBrowse from "store/StoreOrLibraryBrowse"

const PrintableGiftSubscriptionOrderConfirmationCard = withPrintContent(
  ".gift-subscription-printable"
)(GiftSubscriptionOrderConfirmationCard)

const PrintableGiftSubscriptionConfirmationCard = withPrintContent(
  ".gift-subscription-printable"
)(GiftSubscriptionConfirmationCard)

// when licensee school licenses through licensor store
export const curriculumLicenseeLicenseRoute = createCardRoutes(
  `/library/store/:schoolId(\\d+)/:resourceId(\\d+)/:licenseOfferingId(\\d+)/`,
  {
    success: {
      path: "order/:order_id(\\d+)/thank-you/",
      getDefaultLaunchedFrom: () => "/library/",
      component: ({ card, match }) => (
        <CurriculumLicenseOrderConfirmationCard
          card={card}
          orderId={parseInt(match.params.order_id)}
        />
      )
    }
  },
  StoreOrLibraryBrowse
)

export const subscriptionRoute = createCardRoutes(
  "/",
  {
    checkout: {
      path: "subscribe/",
      getDefaultLaunchedFrom: () => "/library/",
      component: ({ card, location }) => (
        <OrderSubscriptionCard
          card={card}
          visibilityCode={getParam(location.search).vc}
          visibilityCodeFilter={getParam(location.search, /vc_(?<vc>.+)/)}
        />
      )
    },
    checkoutSuccess: {
      path: "subscribe/order/:order_id(\\d+)/thank-you/",
      getDefaultLaunchedFrom: () => "/library/",
      component: ({ card, match }) => (
        <SubscriptionOrderConfirmationCard
          card={card}
          orderId={parseInt(match.params.order_id)}
        />
      )
    },
    redeemSuccess: {
      path: "subscribe/thank-you/",
      getDefaultLaunchedFrom: () => "/library/",
      component: ({ card, match }) => (
        <SubscriptionRedeemptionConfirmationCard card={card} />
      )
    }
  },
  StoreOrLibraryBrowse
)

export const giftSubscriptionRoute = createCardRoutes(
  "/",
  {
    checkout: {
      path: "gift-subscription/",
      getDefaultLaunchedFrom: () => "/library/",
      component: ({ card, location }) =>
        getParam(location.search).gc ? (
          <PrintableGiftSubscriptionConfirmationCard
            card={card}
            code={getParam(location.search).gc}
          />
        ) : (
          <OrderGiftSubscriptionCard
            card={card}
            visibilityCode={getParam(location.search).vc}
            visibilityCodeFilter={getParam(location.search, /vc_(?<vc>.+)/)}
          />
        )
    },
    success: {
      path: "gift-subscription/order/:order_id(\\d+)/thank-you/",
      getDefaultLaunchedFrom: () => "/library/",
      component: ({ card, match }) => (
        <PrintableGiftSubscriptionOrderConfirmationCard
          card={card}
          orderId={parseInt(match.params.order_id)}
        />
      )
    }
  },
  StoreOrLibraryBrowse
)

export const curriculumSubscriptionRoute = createCardRoutes(
  "/",
  {
    checkout: {
      path: "school-licensing/signup/",
      getDefaultLaunchedFrom: () => "/library/",
      component: ({ card, location }) => (
        <OrderCurriculumSubscriptionCard
          card={card}
          visibilityCode={getParam(location.search).vc}
          visibilityCodeFilter={getParam(location.search, /vc_(?<vc>.+)/)}
        />
      )
    },
    success: {
      path: "school-licensing/signup/order/:order_id(\\d+)/thank-you/",
      getDefaultLaunchedFrom: () => "/library/",
      component: ({ card, match }) => (
        <CurriculumSubscriptionOrderConfirmationCard
          card={card}
          orderId={parseInt(match.params.order_id)}
        />
      )
    }
  },
  StoreOrLibraryBrowse
)
