import classnames from "classnames"
import isFunction from "lodash/isFunction"
import PropTypes from "prop-types"
import { useEffect, useRef } from "react"
import "velocity-animate"
import "velocity-animate/velocity.ui"
import { VelocityTransitionGroup } from "velocity-react"
import "./Overlay.css"

const Overlay = ({
  placement,
  alignment,
  show,
  children,
  onClick,
  enterTransition,
  enterDuration,
  onEntering,
  onEntered,
  leaveTransition,
  leaveDuration,
  onExiting,
  onExited
}) => {
  const ref = useRef()

  useEffect(() => {
    if (show) {
      // Only scrolls the overlay if necessary, since using "nearest" option.
      ref.current.scrollIntoView({ behavior: "smooth", block: "nearest" })
    }
  }, [show])

  const getEnterTransition = () => {
    if (!enterTransition) return
    return {
      animation: enterTransition,
      duration: enterDuration,
      begin: (nodes) => isFunction(onEntering) && onEntering(nodes),
      complete: (nodes) => isFunction(onEntered) && onEntered(nodes)
    }
  }

  const getLeaveTransition = () => {
    if (!leaveTransition) return
    return {
      animation: leaveTransition,
      duration: leaveDuration,
      backwards: true,
      begin: (nodes) => isFunction(onExiting) && onExiting(nodes),
      complete: (nodes) => isFunction(onExited) && onExited(nodes)
    }
  }

  const className = classnames(
    "Overlay",
    `Overlay--place-${placement}`,
    `Overlay--align-${alignment}`
  )

  return (
    <div ref={ref} className={className}>
      <VelocityTransitionGroup
        enter={getEnterTransition()}
        leave={getLeaveTransition()}
      >
        {show && (
          <div onClick={(e) => isFunction(onClick) && onClick(e)}>
            {children}
          </div>
        )}
      </VelocityTransitionGroup>
    </div>
  )
}

Overlay.displayName = "Overlay"

Overlay.propTypes = {
  // Should overlay be shown
  show: PropTypes.bool,
  // Should clicks inside overlay bubble to OverlayTrigger
  preventClickPropagation: PropTypes.bool,
  // Name of transition: see Velocity UI
  enterTransition: PropTypes.string,
  leaveTransition: PropTypes.string,
  // Speed of transition
  enterDuration: PropTypes.number,
  leaveDuration: PropTypes.number,
  // Where overlay should be positioned relative to container
  placement: PropTypes.oneOf(["top", "right", "bottom", "left"]),
  // Which overlay edge should align with container
  alignment: PropTypes.oneOf(["center", "top", "right", "bottom", "left"]),
  // Event hooks
  onEntering: PropTypes.func,
  onEntered: PropTypes.func,
  onExiting: PropTypes.func,
  onExited: PropTypes.func
}

Overlay.defaultProps = {
  show: false,
  enterDuration: 150,
  leaveDuration: 100,
  placement: "bottom",
  alignment: "right"
}

export default Overlay
