import { useMutation } from "@apollo/client"
import gql from "graphql-tag"
import { useEffect, useState } from "react"
import { useIdleTimer } from "react-idle-timer"
import { usePathwrightContext } from "../pathwright/PathwrightContext"

const HEARTBEAT_INTERVAL = 60 // seconds

const HEARTBEAT_MUTATION = gql`
  mutation Heartbeat {
    heartbeat
  }
`

const useHeartbeat = () => {
  const getDate = () => new Date()
  const { me, school } = usePathwrightContext()
  // Initializing with the current time will mean the initial heartbeat
  // sent from the onActive() method will ocurr at least 60 seconds after
  // hook mounts.
  const [date, setDate] = useState(getDate)

  const [mutation] = useMutation(HEARTBEAT_MUTATION, {
    onCompleted: () => setDate(getDate)
  })

  // Only send the heartbeat when the school is activated.
  const sendHeartbeat = () => {
    if (school.is_activated) mutation()
  }

  const handleHeartbeat = () => {
    // Diff of the time of the last reported action and the current time.
    const secondsDiff =
      Math.abs(new Date().getTime() - new Date(date).getTime()) / 1000

    // If the diff is greater than our heartbeat seconds settings, then report
    // a new action.
    if (secondsDiff > HEARTBEAT_INTERVAL) {
      sendHeartbeat()
    }
  }

  const { resume, pause } = useIdleTimer({
    onActive: handleHeartbeat,
    timeout: 1000,
    eventsThrottle: 3000,
    startManually: !me
  })

  useEffect(() => {
    if (me) {
      resume()
      // Always send the heartbeat when authenticated user changes
      // (or hook mounts with authenticated user).
      sendHeartbeat()
    } else {
      pause()
    }
  }, [me])
}

export function Heartbeat() {
  useHeartbeat()
  return null
}

export default useHeartbeat
