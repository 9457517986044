import { defineStyleConfig, extendTheme } from "@chakra-ui/react"

const defaultThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: true
}

// Custom Components TOOD: move this
const StoreItemHeader = defineStyleConfig({
  baseStyle: {},
  variants: {
    cinematic: {},
    app: {}
  },
  defaultProps: {
    variant: "cinematic"
  }
})

const defaultTheme = extendTheme({
  config: defaultThemeConfig,
  semanticTokens: {
    colors: {
      text: {
        default: "gray.900",
        _dark: "gray.50"
      },
      textSecondary: {
        default: "blackAlpha.700",
        _dark: "whiteAlpha.700"
      },
      // text that is always on a white bg
      contentText: {
        default: "blackAlpha.900",
        _dark: "white"
      },
      contentTextSecondary: {
        default: "blackAlpha.500",
        _dark: "blackAlpha.500"
      },
      overlayText: {
        default: "gray.50",
        _dark: "gray.30"
      },
      // the main window background
      background: {
        default: "gray.50",
        _dark: "gray.900"
      },
      // background for content (for readable text)
      contentBackground: {
        default: "rgba(255,255,255,1)",
        _dark: "rgba(33, 29, 39, 1)"
      },
      contentBackgroundSecondary: {
        default: "gray.100",
        _dark: "gray.700"
      },
      border: {
        default: "blackAlpha.400",
        _dark: "whiteAlpha.600"
      },
      islandBackground: {
        default: "gray.900",
        _dark: "gray.900"
      },
      islandText: {
        default: "gray.50",
        _dark: "gray.50"
      },
      surface: {
        default: "rgba(255, 255, 255, 0.88)",
        _dark: "rgba(33, 29, 39, 0.77)"
      },
      surfaceThin: {
        default: "rgba(255, 255, 255, 0.66)",
        _dark: "rgba(33, 29, 39, 0.4)"
      },
      secondary: {
        default: "gray.200",
        _dark: "gray.700"
      }
    },
    shadows: {
      card: {
        default: "md",
        _dark: "2xl"
      }
    }
  },
  components: {
    StoreItemHeader
  }
})

export default defaultTheme
