import FieldShaker from "@pathwright/ui/src/components/form/form-utils/FieldShaker"
import classnames from "classnames"
import { validate } from "lib/core/components/forms"
import enhanceWithClickOutside from "react-click-outside"
import "./InviteLookup.css"
import InviteLookupInput from "./InviteLookupInput"
import InviteLookupLoader from "./InviteLookupLoader"
import InviteLookupResults from "./InviteLookupResults"
import { emailCount } from "./utils"

const initialState = {
  value: "",
  focus: true,
  focusedIndex: 0
}

class InviteLookup extends React.Component {
  constructor(props) {
    super(props)
    this.handleAdd = this.handleAdd.bind(this)
    this.handleEnter = this.handleEnter.bind(this)
    this.handleArrow = this.handleArrow.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleFocus = this.handleFocus.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.state = initialState
  }

  handleAdd(emails) {
    if (this.props.onAdd(emails)) {
      this.setState(initialState)
    } else {
      this.shaker.shake()
    }
  }

  handleEnter() {
    if (this.emailCount > 1) {
      this.handleAdd(this.state.value)
    } else {
      if (!this.focusedEmail || validate.isEmail(null, this.focusedEmail)) {
        this.shaker.shake()
      } else {
        this.handleAdd(this.focusedEmail)
      }
    }
  }

  handleArrow(change) {
    this.setState((prevState) => {
      const nextIndex = prevState.focusedIndex + change
      return {
        focusedIndex: Math.max(0, Math.min(this.results.length - 1, nextIndex))
      }
    })
  }

  handleSearch(value) {
    if (this.props.onSearch && value.length >= 3 && value.indexOf(",") < 0) {
      this.props.onSearch(value)
    }
  }

  handleChange(value) {
    this.setState({ value, focusedIndex: 0, addError: null })
  }

  handleFocus(e) {
    this.setState({ focus: true })
  }

  handleBlur(e) {
    this.setState({ focus: false })
  }

  handleClickOutside() {
    if (this.state.focus) {
      this.handleBlur()
    }
  }

  get emailCount() {
    return emailCount(this.state.value)
  }

  get intentIsEmail() {
    return this.state.value.indexOf("@") > -1
  }

  get exactMatch() {
    return _.find(this.props.hits, (hit) => hit.user.email === this.state.value)
  }

  get results() {
    let results = []
    if (this.emailCount > 1) {
      return []
    }
    if (this.exactMatch) {
      return [this.exactMatch]
    }
    if (this.intentIsEmail) {
      results.push({ value: this.state.value })
    }
    if (this.state.value.length >= 3 && this.props.hits.length > 0) {
      results.push(...this.props.hits)
    }
    return results
  }

  get focusedEmail() {
    const result = this.results[this.state.focusedIndex]
    return result && (result.user ? result.user.email : result.value)
  }

  render() {
    return (
      <div
        className={classnames("InviteLookup", {
          "InviteLookup--focus": this.state.focus
        })}
      >
        <FieldShaker ref={(c) => (this.shaker = c)}>
          <InviteLookupLoader loading={this.props.loading} />
          <InviteLookupInput
            value={this.state.value}
            placeholder={this.props.placeholder}
            onDownArrow={() => this.handleArrow(1)}
            onUpArrow={() => this.handleArrow(-1)}
            onChange={this.handleChange}
            onSearch={this.handleSearch}
            onEnter={this.handleEnter}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
          />
          {this.state.focus && (
            <InviteLookupResults
              value={this.state.value}
              results={this.results}
              emailCount={this.emailCount}
              focusedEmail={this.focusedEmail}
              focusedIndex={this.state.focusedIndex}
              onSelect={this.handleAdd}
            />
          )}
        </FieldShaker>
      </div>
    )
  }
}

InviteLookup.displayName = "InviteLookup"

// InviteLookup can be used without search
InviteLookup.defaultProps = {
  hits: [],
  onSearch: () => {}
}

export default enhanceWithClickOutside(InviteLookup)
