import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import gql from "graphql-tag"
import { useEffect, useState } from "react"

export const INBOX_CONTEXT_QUERY = gql`
  query InboxContextQuery($cohort_id: Int, $group_id: Int) {
    inboxContext(cohort_id: $cohort_id, group_id: $group_id) {
      id
      cohort {
        id
        name
        resource {
          id
          name
        }
      }
      group {
        id
        name
      }
    }
  }
`

export const getInboxFromQueryResponse = (data) => {
  const { cohort, group } = data.inboxContext

  let inbox =
    cohort || group
      ? {
          id: cohort ? cohort.id : group.id,
          type: cohort ? "cohort" : "group",
          meta: {
            title: cohort ? cohort.resource.name : group.name,
            subtitle: cohort ? cohort.name : null
          }
        }
      : {
          id: "*",
          type: "inbox",
          meta: {
            title: "Inbox"
          }
        }

  return inbox
}

const useInboxContext = ({ cohort_id, group_id }) => {
  let [inbox, setInbox] = useState()

  const { data, loading, error } = useQuery(INBOX_CONTEXT_QUERY, {
    // no-cache is required here otherwhise we never get a data update for some reason
    fetchPolicy: "no-cache",
    variables: { cohort_id, group_id }
  })

  useEffect(() => {
    if (data && data.inboxContext) {
      setInbox(getInboxFromQueryResponse(data))
    }
  }, [data])

  return { inbox, loading, error }
}

export default useInboxContext
