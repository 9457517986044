import styled from "styled-components"
import formatStripeAmount from "./utils/formatStripeAmount"

// Check if tiers is within the product min/max quantity range.
export const getTierIsWithinRange = (stripePrice, tier) => {
  const tierIsWithinRange =
    !tier.up_to ||
    (tier.up_to >= (stripePrice.product.min_quantity || 1) &&
      tier.up_to < (stripePrice.product.max_quantity || Infinity))

  return tierIsWithinRange
}

export default function StripePricePreview({ stripePrice }) {
  if (stripePrice.billing_scheme === "tiered") {
    if (stripePrice.tiers_mode === "graduated") {
      const sortedTiers = stripePrice.tiers.slice().reverse()
      const sampleUnits = sortedTiers[1].up_to + 1
      return (
        <StyledContainer>
          {stripePrice.tiers.map((tier, index) => {
            const prevTier = stripePrice.tiers[index - 1]
            const unitCount =
              tier.up_to && prevTier ? tier.up_to - prevTier.up_to : 1
            const flatAmount = tier.flat_amount
            const tierTotal = tier.unit_amount * unitCount + flatAmount
            // NOTE: filtering out tiers that are not within the product min/max range now
            // so that we can still reference previous tiers that fall outside of the range
            // in order to show a proper tier unit count for the tier range.
            return getTierIsWithinRange(stripePrice, tier) ? (
              <StyledRow key={index}>
                <span>
                  {index === 0 ? "First " : "Next "}
                  {unitCount}
                  {" x "}
                  {formatStripeAmount(
                    tier.unit_amount,
                    stripePrice.currency,
                    false
                  )}
                  {tier.flat_amount
                    ? " + " +
                      formatStripeAmount(
                        tier.flat_amount,
                        stripePrice.currency,
                        false
                      )
                    : null}
                  {" = "}
                  {formatStripeAmount(tierTotal, stripePrice.currency, false)}
                </span>
              </StyledRow>
            ) : null
          })}
        </StyledContainer>
      )
    } else if (stripePrice.tiers_mode === "volume") {
      const sortedTiers = stripePrice.tiers.slice().reverse()
      const sampleUnits = sortedTiers[1].up_to + 1
      return (
        <StyledContainer>
          {stripePrice.tiers.map((tier, index) => {
            const prevTier = stripePrice.tiers[index - 1]
            // NOTE: filtering out tiers that are not within the product min/max range now
            // so that we can still reference previous tiers that fall outside of the range
            // in order to show a tier "start point" for the tier range.
            return getTierIsWithinRange(stripePrice, tier) ? (
              <StyledRow key={index}>
                <span>
                  {prevTier
                    ? `${prevTier.up_to + 1}${tier.up_to ? "–" : "+"}`
                    : null}
                  {tier.up_to}
                </span>
                <span>
                  {formatStripeAmount(
                    tier.unit_amount,
                    stripePrice.currency,
                    false
                  )}
                  {tier.flat_amount
                    ? " + " +
                      formatStripeAmount(
                        tier.flat_amount,
                        stripePrice.currency,
                        false
                      )
                    : null}
                  {" / seat"}
                </span>
              </StyledRow>
            ) : null
          })}
        </StyledContainer>
      )
    } else {
      return null
    }
  } else {
    return (
      <StyledContainer>
        <span>
          {formatStripeAmount(
            stripePrice.unit_amount_decimal,
            stripePrice.currency,
            false
          )}{" "}
          per seat
        </span>
      </StyledContainer>
    )
  }
}

const StyledContainer = styled.div`
  padding: 10px;
  span {
    white-space: nowrap;
    line-height: 1.5;
  }
`

const StyledRow = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  > span + span {
    margin-left: 1em;
  }
`
