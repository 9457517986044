import gql from "graphql-tag"
import SUBSCRIPTION_FRAGMENT from "./subscription-fragment"

export default gql`
  query SubscriptionQuery($id: Int!) {
    subscription(id: $id) {
      ...Subscription
      payment_intent_client_secret
      stripe_publishable_api_key
    }
  }
  ${SUBSCRIPTION_FRAGMENT}
`
