import { DISCUSSION_NOTIFY_OPTIONS } from "./constants"

export const getDiscussionNotificationInfo = (
  option,
  translator,
  tOptions = {
    cohortMemberStats: {
      total: null,
      send_discussion_notifications: null
    }
  }
) => {
  switch (option) {
    case DISCUSSION_NOTIFY_OPTIONS.NOTIFY:
      return {
        icon: "bell-fill",
        title: translator("Notify {{ count, number }} person", {
          defaultValue_plural: "Notify {{ count, number }} people",
          count: tOptions.cohortMemberStats.send_discussion_notifications
        }),
        description: translator("Notify in-app and by email.")
      }
    case DISCUSSION_NOTIFY_OPTIONS.DO_NOT_NOTIFY:
      return {
        icon: "bell-off-fill",
        title: translator("Don't notify")
      }
  }
}
