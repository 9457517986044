import Avatar from "@pathwright/ui/src/components/avatar/Avatar"
import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import Text from "@pathwright/ui/src/components/ui/Text"
import defaults from "lodash/defaults"
import moment from "moment"
import styled from "styled-components"
import useNotes from "../../note/useNotes"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import { useReviewContext } from "../state/ReviewContextProvider"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex-grow: 1;
  width: 100%;
  height: 100%;

  > ul {
    padding: 0;
    list-style: none;
  }
`

const NoteItemContainer = styled.li`
  margin: 1em;
  padding: 1em;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 4px;

  > header {
    display: flex;
    align-items: center;

    .Avatar {
      margin-right: 0.4em;
    }
  }

  article {
    img {
      max-width: 100%;
    }
  }
`

const NoteAuthor = ({ note }) => {
  const { school } = usePathwrightContext()

  if (note.visibility === "author") {
    return <Avatar user={note.user} size="2em" />
  }

  // Somewhat hacky school Avatar for notes set to "staff"
  // or "everyone" visibility.
  return (
    <Avatar
      user={{
        first_name: school.name
      }}
      image={school.image}
      size="2em"
    />
  )
}

const NoteItem = ({ note }) => (
  <NoteItemContainer>
    <header>
      <NoteAuthor note={note} />
      <div>
        <Text.H5>{note.title}</Text.H5>
        <Text.Meta>{moment(note.created_time).fromNow()}</Text.Meta>
      </div>
    </header>
    <article>
      <div dangerouslySetInnerHTML={{ __html: note.body }} />
    </article>
  </NoteItemContainer>
)

const Notes = ({ notes }) => (
  <ul>
    {notes.map((note) => (
      <NoteItem key={note.id} note={note} />
    ))}
  </ul>
)

// Derives the notes contextInput either from props or
// current review context.
const useNoteContextInput = (contextInput) => {
  const reviewContext = useReviewContext()
  const step = reviewContext.selected ? reviewContext.selected.data : null
  const reviewNoteContext = step
    ? {
        resource_id: step.context.resource_id,
        cohort_id: step.context.cohort_id,
        // NOTE: must scope to the step source_id.
        path_item_source_id: step.source_id
      }
    : null

  return defaults(contextInput, reviewNoteContext)
}

export const useInboxNotes = (contextInput) =>
  useNotes({
    contextInput: useNoteContextInput(contextInput),
    visibility: "staff"
  })

const InboxNotes = ({ contextInput }) => {
  const { notes } = useInboxNotes(contextInput)

  return (
    <Container>
      {notes ? (
        notes.length ? (
          <Notes notes={notes} />
        ) : (
          // TODO: get approved blank slate.
          <BlankSlate heading="There are no notes available." />
        )
      ) : (
        <LoadingCircle />
      )}
    </Container>
  )
}

export default InboxNotes
