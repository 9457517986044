import { redirect } from "react-router-dom"
import {
  formDataSchema,
  startAuthRequest
} from "../../../auth-request/api/action"
import getAuthRoute from "../../../utils/getAuthRoute"
export * from "../../../auth-request/api/action"

const startAuthRequestAction = async (
  formData: FormData
): Promise<Response> => {
  const { uuid } = await startAuthRequest(formData)
  return redirect(
    getAuthRoute(`/auth/sign-in/request/confirm?auth_request_id=${uuid}`)
  )
}

startAuthRequestAction.schema = formDataSchema

export default startAuthRequestAction

// TODO: is it possible to just return the default action?
// Currently this results in an unexpected redirect to "/auth/sign-in/confirm" rather than "/auth/sign-in/request/confirm"
// export * from "../../../auth-request/api/action"
// export { default as default } from "../../../auth-request/api/action"
