import {
  Box,
  Flex,
  Heading,
  HStack,
  Link,
  Text,
  VStack
} from "@chakra-ui/react"
import { Link as RouterLink } from "react-router-dom"

// import Pathicon from "../../../../../../client/src/components/pathicon/Pathicon"
import Pathicon from "@pathwright/web/src/modules/pathicon/Pathicon"
import AtmosphericImage from "../shared/AtmosphericImage"
import { useShareDataContext } from "../shared/ShareDataContextProvider"
import { useShareUIContext } from "../shared/ShareUIContextProvider"

const Prompt = ({ heading, text, hasChevron }) => (
  <HStack justifyContent="flex-start" w="100%">
    <Pathicon icon="rocket" size={30} />
    <VStack alignItems="flex-start" spacing={1} px={2} flexGrow={1}>
      <Heading as="h2" size="md" m={0}>
        {heading}
      </Heading>
      <Text>{text}</Text>
    </VStack>
    {hasChevron && <Pathicon icon="chevron-right" size={30} />}
  </HStack>
)

const LinkablePrompt = ({ link, ...rest }) => {
  if (link) {
    return (
      <Link as={RouterLink} to={link} display="flex" w="100%">
        <Prompt {...rest} hasChevron />
      </Link>
    )
  }

  return <Prompt {...rest} />
}

const Gate = ({ children, heading, text, link }) => {
  return (
    <Box
      pos="relative"
      display="grid"
      gridTemplateColumns="1fr"
      placeContent="center" // overlay while growing the size of each child as needed
      sx={{
        ["> *"]: {
          gridArea: "1/1" // overlay while growing the size of each child as needed
        }
      }}
    >
      {children}
      <Flex
        p="var(--card-padding)"
        sx={{
          // Alter the AtmosphericImage wrapper.
          ["> *:first-child"]: {
            pos: "static",
            opacity: 0.65
          }
        }}
      >
        <AtmosphericImage />
        <Box display="flex" pos="relative" w="100%">
          <LinkablePrompt heading={heading} text={text} link={link} />
        </Box>
      </Flex>
    </Box>
  )
}

// Shown to uses who can activate the school on a Pathwright plan.
const ActivateGate = ({ children }) => (
  <Gate
    heading="Upgrade your account"
    text="Choose a plan to invite others to join this Path."
    link="/pricing/plan/"
  >
    {children}
  </Gate>
)

// Shown to uses who cannot activate the school on a Pathwright plan.
const InactiveGate = ({ children, ownerEmail }) => {
  return (
    <Gate
      heading="Account inactive"
      text={
        <span>
          Contact{" "}
          <Link
            href={`mailto:${ownerEmail}`}
            fontWeight="bold"
            textDecor="underline"
          >
            {ownerEmail}
          </Link>
          .
        </span>
      }
    >
      {children}
    </Gate>
  )
}

const InactiveSchoolGate = ({ children }) => {
  const { tabKey } = useShareUIContext()
  const { permissions } = useShareDataContext()
  const accessKey = `share_${tabKey}_access`

  if (
    permissions[accessKey]?.access === "GATED" &&
    permissions[accessKey]?.gated_reason === "SCHOOL_INACTIVE"
  ) {
    if (permissions.allow_activate) {
      return <ActivateGate>{children}</ActivateGate>
    } else {
      return (
        <InactiveGate ownerEmail={permissions.activate_contact_email}>
          {children}
        </InactiveGate>
      )
    }
  }

  return children
}

export default InactiveSchoolGate
