import { z } from "zod"
import { zfd } from "zod-form-data"

export const inviteTokenParamSchema = zfd
  .formData({
    invite_token: zfd.text(z.string().optional())
  })
  .transform((val) => ({
    inviteToken: val.invite_token || null
  }))

export type InviteTokenParamSchema = z.output<typeof inviteTokenParamSchema>

const getInviteTokenParam = (url: string): InviteTokenParamSchema => {
  const searchStr = new URL(url).search
  const searchParams = new URLSearchParams(searchStr)

  try {
    return inviteTokenParamSchema.parse(searchParams)
  } catch {
    return {
      inviteToken: null
    }
  }
}

export default getInviteTokenParam
