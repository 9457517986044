class BaseView extends require("lib/static-shim").default(Marionette.ItemView) {
  constructor() {
    super(...arguments)
    this.subviewCache = []
    if (this.subviews) {
      this.on("render", () => {
        return this.renderSubviews()
      })
    }
  }

  render(context) {
    // This is a hack to allow rendering of
    // "headless" views (no template)
    if (context == null) {
      context = {}
    }
    if (this.template != null) {
      // Marionette.ItemView.render()
      return super.render()
    } else {
      // HACK alert!
      this.triggerMethod("render", this)
      this.triggerMethod("item:rendered", this)
    }
    return this
  }

  renderSubviews() {
    if (this.subviews != null) {
      console.warn("BaseView.subviews is deprecated and should be removed")
    }
    if (!this.subviewCache) {
      this.subviewCache = []
    }
    return (() => {
      const result = []
      for (let selector in this.subviews) {
        const view = this.subviews[selector]
        const $c = this.findEl(selector)
        if ($c.length) {
          var subview
          if (typeof view === "function") {
            // bind the function to the view scope...
            // maybe there's a better way to do this
            this.subViewFunc = view
            subview = this.subViewFunc($c)
          }
          if (typeof view === "Object") {
            subview = view
          }

          if (subview && subview instanceof Backbone.View) {
            // If the element is set, just use that...
            if (subview.el === $c[0]) {
              subview.render()
            } else {
              $c.html(subview.render().el)
            }
            result.push(this.subviewCache.push(subview))
          } else {
            result.push(
              console.error("Subview class not a Backbone View", subview)
            )
          }
        } else {
          result.push(
            console.error(
              `Subview container ${selector} not found in el: `,
              this.el
            )
          )
        }
      }
      return result
    })()
  }

  onRender() {} // override me

  html(markup) {
    return this.$el.html("").append($(markup))
  }

  findEl(selector) {
    return this.$el.find(selector)
  }

  remove() {
    if (!this.subviewCache) return super.remove()
    for (let subview of Array.from(this.subviewCache)) {
      subview.destroy()
    }
    return super.remove()
  }

  serializeData() {
    return this.getViewContext()
  }

  getViewContext() {
    // TODO: deprecate this in favor of serliazeData
    let vcontext = {}
    if (this.model != null) {
      if (this.model.getViewContext == null) {
        vcontext = this.model.toJSON()
      } else {
        vcontext = this.model.getViewContext()
      }
    }
    return vcontext
  }
}

export default BaseView
