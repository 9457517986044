import { Stack, Text } from "@chakra-ui/react"
import {
  ResourceCopyConfigFragment,
  useResourceCopyConfigConnectionsQuery
} from "../../api/generated"
import ResourceCopyConfigItem, {
  ResourceCopyConfig,
  ResourceCopyConfigItemStack
} from "./ResourceCopyConfigItem"

// Parse a copy config key that possibly contains a _(def|inst) copy behavior.
// We only look up the copy configs by the actual lookup key.
export function parseCopyConfigKey(key: string): string {
  const re = /(?<copyKey>[A-Z0-9]{8})(_(?<copyBehavior>[a-z_]+))?/
  return key?.match?.(re)?.groups?.copyKey || ""
}

// Query and parse the list of resource copy configs.
export function useResourceCopyConfigs(
  resourceCopyConfigKeys?: string[]
): ResourceCopyConfigFragment[] {
  const query = useResourceCopyConfigConnectionsQuery({
    variables: {
      first: 1042,
      keys: resourceCopyConfigKeys?.map(parseCopyConfigKey) || []
    },
    skip: !resourceCopyConfigKeys
  })

  const items =
    (
      query.data || query.previousData
    )?.resourceCopyConfigConnections?.edges?.map((edge) => edge!.node!) || []

  return items
}

export function flattenResourceCopyConfigs(
  resourceCopyConfigs: ResourceCopyConfigFragment[],
  flattenedResourceCopyConfigs: Omit<
    ResourceCopyConfigFragment,
    "children"
  >[] = []
) {
  // Loop through the configs list.
  resourceCopyConfigs.forEach((resourceCopyConfig) => {
    if (resourceCopyConfig.children?.length) {
      // Flatten the children.
      flattenResourceCopyConfigs(
        resourceCopyConfig.children as ResourceCopyConfigFragment[],
        flattenedResourceCopyConfigs
      )
    } else {
      // Push the tree sink.
      flattenedResourceCopyConfigs.push(resourceCopyConfig)
    }
  })

  return flattenedResourceCopyConfigs
}

// Flatten the configs into a list of sinks in the config tree.
export function useFlattenedResourceCopyConfigs(
  resourceCopyConfigKeys?: string[]
) {
  return flattenResourceCopyConfigs(
    useResourceCopyConfigs(resourceCopyConfigKeys)
  )
}

// Reduces the list of resource copy config keys to only those which are valid.
export function useValidResourceCopyConfigKeys(
  resourceCopyConfigKeys?: string[]
): string[] | undefined {
  const configs = useResourceCopyConfigs(resourceCopyConfigKeys)
  const validResourceCopyConfigKeys = resourceCopyConfigKeys?.filter((key) =>
    configs.find((config) => config.lookup_key === parseCopyConfigKey(key))
  )
  // Only returning the valid keys when we have configs.
  if (configs.length) return validResourceCopyConfigKeys
}

type ResourceCopyConfigItemsProps = {
  resourceCopyConfigKeys: string[]
  renderInternal?: ResourceCopyConfig
}

function ResourceCopyConfigItems({
  resourceCopyConfigKeys,
  renderInternal
}: ResourceCopyConfigItemsProps) {
  const items = useResourceCopyConfigs(resourceCopyConfigKeys)

  return items.length ? (
    <Stack>
      {resourceCopyConfigKeys?.map((key) => {
        const parsedKey = parseCopyConfigKey(key)
        const item = items?.find((item) => item?.lookup_key === parsedKey)
        return item ? (
          <ResourceCopyConfigItem
            key={item.id}
            item={item}
            renderInternal={renderInternal}
          />
        ) : (
          <ResourceCopyConfigItemStack key={parsedKey}>
            <Text>"{parsedKey}" Copy Config Not Found</Text>
          </ResourceCopyConfigItemStack>
        )
      })}
    </Stack>
  ) : null
}

export default ResourceCopyConfigItems
