import { useCurrencyFormatter } from "./CurrencyFormatterProvider"

const CurrencyFormatter = ({ children, locale, currency, value }) => {
  const { getCurrencyFormatter } = useCurrencyFormatter()
  const formatter = getCurrencyFormatter(locale, currency)
  const format = formatter.customFormat(value)
  // formatToParts throws error TypeError. Has to do with class extention...
  // const symbol = formatter.formatToParts
  //   ? get(
  //       formatter.formatToParts(value).find(p => p.type === "currency"),
  //       "value"
  //     ) || ""
  //   : `^\\d\\.\\,`
  const symbol = `^\\d\\.\\,`
  const rgx = new RegExp(`\\s*[${symbol}]\\s*`)
  const match = rgx.exec(format)
  const symbolStr = match && match[0]
  const symbolIndex = match && match.index
  const currencyLabel =
    symbol === "$" && formatter.currencyLabel !== "USD"
      ? formatter.currencyLabel
      : null

  return children ? (
    <span>
      {symbolIndex === 0 && symbolStr ? symbolStr : null}
      {children}
      {symbolIndex !== 0 && symbolStr ? symbolStr : " "}
      {currencyLabel}
    </span>
  ) : (
    <span>{format}</span>
  )
}

CurrencyFormatter.displayName = "CurrencyFormatter"
CurrencyFormatter.defaultProps = {
  value: 0
}

export default CurrencyFormatter
