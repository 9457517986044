import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import { ConnectStores } from "lib/core/store"
import React from "react"
import GlobalSchoolLink from "./GlobalSchoolLink"

class GlobalSchoolList extends React.Component {
  UNSAFE_componentWillMount() {
    return this.props.loadUserSchools()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { schools } = nextProps
    if ((schools != null ? schools.length : undefined) === 1) {
      return (window.location.href = _.first(schools).redir_url)
    }
  }

  render() {
    const { schools, schools_loading } = this.props

    return (
      <div className="GlobalSchoolList">
        {(() => {
          if (schools != null ? schools.length : undefined) {
            return schools.map((school) => (
              <GlobalSchoolLink
                {...Object.assign({}, school, { key: school.id })}
              />
            ))
          } else if (schools_loading) {
            return <LoadingCircle />
          }
        })()}
      </div>
    )
  }
}

GlobalSchoolList.displayName = "GlobalSchoolList"

const mapStateToProps = function (state, ownProps) {
  const { schools, schools_loading } = state.pathwrightAccountStore.state
  const { loadUserSchools } = state.pathwrightAccountStore.action

  return _.assign(
    {},
    {
      schools,
      schools_loading,
      loadUserSchools
    },
    ownProps
  )
}

const stores = [
  { store: "pathwrightAccount", watch: ["schools", "schools_loading"] }
]

export default ConnectStores(GlobalSchoolList, stores, {}, mapStateToProps)
