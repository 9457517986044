import gql from "graphql-tag"
import MEMBERSHIP_FRAGMENT from "../../membership/graphql/membership-fragment"

export default gql`
  {
    me {
      id
      membership {
        ...Membership
      }
    }
  }
  ${MEMBERSHIP_FRAGMENT}
`
