import moment from "moment"

export const getIsSubscriptionDelinquent = (subscription) =>
  !!subscription && !!subscription.is_delinquent

export const getIsSubscriptionExpired = (subscription) =>
  !!subscription &&
  !!subscription.canceled_dtime &&
  moment().isAfter(subscription.canceled_dtime)

export const getIsSubscriptionActive = (subscription) =>
  !!subscription &&
  // not expired and not delinquent
  !getIsSubscriptionExpired(subscription)

export const getIsSubscriptionUsable = (subscription) =>
  !!subscription &&
  // not expired and not delinquent
  getIsSubscriptionActive(subscription) &&
  !getIsSubscriptionDelinquent(subscription)
