// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
const Resource = require("library/models/resource").default
const ShareLinkGenerator = require("./share-link-generator").default

class SocialUtils {
  getEnabledNetworksForSchool() {
    const networks = []
    const social = window.App.getStore("school").get("social")
    const { twitter_username, facebook_username, pinterest_username } = social

    if (twitter_username) {
      networks.push({
        name: "Twitter",
        icon: "twitter",
        username: twitter_username,
        url: `https://twitter.com/${twitter_username}`,
        share_url_template: `https://twitter.com/share?url={{url}}&text={{title}} on @${twitter_username}`
      })
    }
    if (facebook_username) {
      let fb_app_id = window.App.getStore("school").get("integrations").facebook
      fb_app_id = fb_app_id || 198349190185069 // default to Pathwright's app id
      networks.push({
        name: "Facebook",
        icon: "facebook",
        username: facebook_username,
        url: `https://facebook.com/${facebook_username}`,
        share_url_template: `http://www.facebook.com/dialog/feed?app_id=${fb_app_id}&link={{url}}&picture={{img}}&name={{title}}&caption=%20&description={{desc}}&redirect_uri=http%3A%2F%2Fwww.facebook.com%2F`
      })
    }
    if (pinterest_username) {
      networks.push({
        name: "Pinterest",
        icon: "pinterest",
        username: pinterest_username,
        url: `https://www.pinterest.com/${pinterest_username}`,
        share_url_template:
          "https://pinterest.com/pin/create/bookmarklet/?media={{img}}&url={{url}}&description={{title}}"
      })
    }
    return networks
  }

  getShareLinksForResource(resource, title) {
    if (title == null) {
      title = null
    }
    const shareLinkGenerator = new ShareLinkGenerator()
    const networks = this.getEnabledNetworksForSchool()
    // Make sure resource is instance of Resource Model
    if (!resource.get) {
      resource = new Resource(resource)
    }
    const url = resource.get("url")
    const shareLinks = []
    for (let network of Array.from(networks)) {
      const scontext = {
        url,
        img: resource.get("thumb_2x"),
        desc: resource.get("short_description")
      }
      if (title != null) {
        scontext.title = title
      } else {
        scontext.title = `${resource.get("name")}`
      }

      const shareLink = window.App.utils.renderString(
        network.share_url_template,
        {
          ...scontext,
          desc: encodeURI(scontext.desc || ""),
          title: encodeURI(scontext.title || "")
        }
      )

      const data = { share_url: shareLink }

      shareLinks.push(_.extend(data, network))
    }
    return shareLinks
  }
}

export default new SocialUtils()
