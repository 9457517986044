import Card from "@pathwright/ui/src/components/card/Card"
import CardBlock from "@pathwright/ui/src/components/card/CardBlock"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import FeatureBlockContainer from "../block/FeatureBlockContainer"
import { FEATURE_ACTION_VIEW } from "../constants"
import FeaturePermissionsForm from "../form/FeaturePermissionsForm"
import { featureKeyPropType } from "../propTypes"
import { getFeatureInfo } from "../utils"
import withFeatureContext from "./withFeatureContext"

const ManageFeaturePermissions = withFeatureContext(
  ({ card, featureKey, context }) => {
    const { t } = useTranslate()
    const { title } = getFeatureInfo(featureKey, t)

    return (
      <Card
        card={card}
        title={t("{{ feature }} visibility", {
          feature: title
        })}
        noaction
        emphasis="secondary"
      >
        <CardBlock paddingTop={false}>
          <FeatureBlockContainer
            featureKey={featureKey}
            featureAction={FEATURE_ACTION_VIEW}
            context={context}
          />
          <FeaturePermissionsForm featureKey={featureKey} context={context} />
        </CardBlock>
      </Card>
    )
  }
)

ManageFeaturePermissions.displayName = "ManageFeaturePermissions"

ManageFeaturePermissions.propTypes = {
  // provided by CardStack > CardStackCard
  // card: PropTypes.object.isRequired,
  featureKey: featureKeyPropType.isRequired,
  ...withFeatureContext._propTypes
}

export default ManageFeaturePermissions
