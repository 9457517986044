import { useEffect, useState } from "react"

// from https://jh3y.medium.com/how-to-where-s-the-caret-getting-the-xy-position-of-the-caret-a24ba372990a

// TODO: refactor useVariableSelector to handle "selectionchange" event. Since useCursorRect
// responds to that event, it ends up updating the VariableSelectorPicker position a split second
// before useVariableSelector updates its showVariableSelector boolean causing the picker to flash
// in the wrong place for a split second after the cursor exits the {{ }}/@ delimiters.

// given an input (<input type="text" /> or <textarea />) find the exact rect value
// of the cursor
const useCursorRect = (input) => {
  const [rect, setRect] = useState(null)

  const value = input ? input.value : ""

  // given a targetSelectionPosition, get the cursor rect
  // TODO: refactor for efficiency, maybe keep ahold of the div, just hiding it with absolute positioning
  const getCursorRectForPosition = (targetSelectionPosition) => {
    if (input) {
      const inputClientRect = input.getBoundingClientRect()
      // create a dummy element that will be a clone of our input
      const div = document.createElement("div")
      // get the computed style of the input and clone it onto the dummy element
      const copyStyle = getComputedStyle(input)
      for (let i = 0; i < copyStyle.length; i++) {
        const key = copyStyle[i]
        div.style[key] = copyStyle[key]
      }

      // set the div content to that of the textarea up until selection
      const textContent = input.value.substr(0, targetSelectionPosition)
      // set the text content of the dummy element div
      div.textContent = textContent
      if (input.tagName === "TEXTAREA") div.style.height = "auto"
      // if a single line input then the div needs to be single line and not break out like a text area
      if (input.tagName === "INPUT") div.style.width = "auto"
      // create a marker element to obtain caret position
      const span = document.createElement("span")
      // append the span marker to the div
      div.appendChild(span)
      // append the remaining content to the div so that the recreated dummy element is as close as possible
      // (more accurate when text alignment is something other than "left")
      div.insertAdjacentText(
        "beforeend",
        input.value.substr(targetSelectionPosition) || ""
      )
      // append the dummy element to the body
      document.body.appendChild(div)
      // get the marker position, this is the caret position top and left relative to the input
      const divClientRect = div.getBoundingClientRect()
      const spanClientRect = span.getBoundingClientRect()
      // lastly, remove that dummy element
      document.body.removeChild(div)

      // return a rect object accounting for relative positioning of div > span
      const relativeTop = spanClientRect.top - divClientRect.top
      const relativeLeft = spanClientRect.left - divClientRect.left

      // get innermost top
      const top = inputClientRect.top + relativeTop
      // get innermost bottom
      const bottom = inputClientRect.top + relativeTop + spanClientRect.height
      // get innermost left
      const left = inputClientRect.left + relativeLeft
      // get innermost right
      const right = inputClientRect.left + relativeLeft + spanClientRect.width
      // get width and height of span
      const width = spanClientRect.width
      const height = spanClientRect.height

      // return a rect object accounting for relative positioning of div > span
      return {
        top,
        bottom,
        left,
        right,
        width,
        height
      }
    }
  }

  // get both cursor rects for the selectionStart and the selectionEnd
  // and assimilate the containing rect
  const handleCursorRect = () => {
    if (input) {
      const startPosition = input.selectionStart
      const endPosition = input.selectionEnd
      const startRect = getCursorRectForPosition(startPosition)

      // if the start and end positions are equal, then we can just
      // use the startRect
      if (startPosition === endPosition) {
        setRect(startRect)
      } else {
        // otherwise find the rect that contains both start and end rects
        const endRect = getCursorRectForPosition(endPosition)

        // get topmost top
        const top = Math.min(startRect.top, endRect.top)
        // get bottommost bottom
        const bottom = Math.max(startRect.bottom, endRect.bottom)
        // get leftmost left
        const left = Math.min(startRect.left, endRect.left)
        // get rightmost right
        const right = Math.max(startRect.right, endRect.right)
        // get width from leftmost left to rightmost right
        const width = Math.abs(left - right)
        // get height from topmost top to bottommost bottom
        const height = Math.abs(top - bottom)

        setRect({
          top,
          bottom,
          left,
          right,
          width,
          height
        })
      }
    }
  }

  // handling cursor rect when selection changes
  useEffect(() => {
    if (input) {
      document.addEventListener("selectionchange", handleCursorRect)
    }

    return () => {
      document.removeEventListener("selectionchange", handleCursorRect)
    }
  }, [input])

  // handling cursor rect when input value changes
  useEffect(() => {
    handleCursorRect()
  }, [input, value])

  return rect
}

export default useCursorRect
