import { HStack } from "@chakra-ui/react"
import { withTranslate } from "@pathwright/ui/src/components/"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import { useUIThemeContext } from "@pathwright/ui/src/components/ui/PathwrightUI"
import { useTranslatedResourceTypeLabel } from "@pathwright/web/src/modules/resource/utils"
import styled from "styled-components"
import { ENROLLMENT_ROLE_STUDENT } from "./constants"
import { isRegistrationComplete } from "./utils"

const tPrefix = "registration.status"

const Meta = styled.span`
  display: flex;
  align-items: center;
  white-space: pre-wrap;
  font-size: 0.85em;
`

const COMPLETED_COLOR = "forestgreen"
const EXPIRED_COLOR = "grey"

const RegistrationStatus = function (props, context) {
  const { registration, expired, t } = props
  const { path, resource } = registration
  const theme = useUIThemeContext()
  const resourceTypeLabel = useTranslatedResourceTypeLabel(resource)

  if (!path) {
    return null
  }

  const verb =
    registration.role >= ENROLLMENT_ROLE_STUDENT
      ? t(`${tPrefix}.start`)
      : t(`${tPrefix}.view`)
  const unstarted = path.progress === 0
  const completed = path.progress >= 1

  return (
    <div className="RegistrationStatus">
      {expired ? (
        <Meta style={{ color: EXPIRED_COLOR }}>
          <HStack spacing={1}>
            <Pathicon icon="clock" />
            <span>{t(`${tPrefix}.access_expired`)}</span>
          </HStack>
        </Meta>
      ) : unstarted && !isRegistrationComplete(registration) ? (
        <Meta style={{ color: theme.primaryBrandColor }}>
          <HStack spacing={1}>
            <span>
              {verb} {resourceTypeLabel}
            </span>
            <Pathicon icon="chevron-right" />
          </HStack>
        </Meta>
      ) : completed ? (
        <Meta style={{ color: COMPLETED_COLOR }}>
          <HStack spacing={1}>
            <Pathicon icon="check-circle" />
            <span>{t(`${tPrefix}.completed`)}</span>
          </HStack>
        </Meta>
      ) : null}
    </div>
  )
}

RegistrationStatus.displayName = "RegistrationStatus"

export default withTranslate(RegistrationStatus)
