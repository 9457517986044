import { BoltIcon } from "@pathwright/pathicons"
import Button from "@pathwright/ui/src/components/button/Button"
import { usePathwrightContext } from "@pathwright/web/src/modules/pathwright/PathwrightContext"
import { canAdministrate } from "@pathwright/web/src/modules/user/permissions"
import styled from "styled-components"
import Changelog from "./Changelog"
import useChangelogUnreadIndicator from "./useChangelogUnreadIndicator"

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: 0px 10px;
`

function ChangelogToggle() {
  const [hasUnread, handleRead] = useChangelogUnreadIndicator()

  function toggleChangelogAside() {
    const { showIn, closeIn } = window.App.getStore("layout").action
    const isShowing = window.App.getStore("layout").get("aside")

    // When exit animation completes, actually close the changelog aside.
    function onCloseEnd() {
      if (window.App.getStore("layout").get("aside")?.props?.closing) {
        closeIn("aside")
      }
    }

    // Close merely updates the Changelog `closing` prop to true.
    // This allows the AnimatePresence to handle animating out the container
    // before the AnimatePresence itself is unmounted.
    function close() {
      showIn("aside", Changelog, {
        closing: true,
        onCloseEnd
      })
    }

    function show() {
      showIn("aside", Changelog, {
        closing: false,
        onClose: close,
        onCloseEnd
      })
    }

    if (isShowing) {
      close()
    } else {
      show()
      handleRead()
    }
  }

  return (
    <Container>
      <Button
        onClick={toggleChangelogAside}
        inverted
        styleType="tertiary"
        rounded={true}
        style={{
          width: "30px",
          height: "30px",
          lineHeight: "30px",
          padding: 0
        }}
        label={<BoltIcon size="1em" />}
        indicator={hasUnread}
      />
    </Container>
  )
}

function ChangelogToggleContainer() {
  const pwContext = usePathwrightContext()

  return canAdministrate(pwContext) ? <ChangelogToggle /> : null
}

export default ChangelogToggleContainer
