import { i18n } from "@pathwright/ui/src/components/lng/I18nextProvider"

export default class Discussion extends require("lib/static-shim").default(
  require("lib/utils/model").default
) {
  initialize() {
    if (this.get("context")) {
      this.setContextStr()
    }
    return this.listenTo(this, "sync", function () {
      return this.setContextStr()
    })
  }

  url() {
    let url = this.urlRoot(`/api/private/discussion/`)
    if (!this.isNew()) {
      url = `${url}${this.id}/`
    }
    return `${url}?${$.param({ context: this.get("context_str") })}`
  }

  defaults() {
    return {
      author: null,
      title: "",
      body: "",
      user_has_subscribed: false,
      is_discussion_question: false,
      user_permissions: {}
    }
  }

  toJSON() {
    // console.log("serialized discussion", super.toJSON, this)
    const data = super.toJSON()
    data.discussion_type_label = this._getTypeLabel()
    data.discussion_action_label = this._getActionLabel()

    return data
  }

  parse(response) {
    // Convert the date to the right format
    response.created_time = moment(response.created_time).local()
    response.num_votes = response.context.num_votes
    response.num_responses = response.context.num_responses
    return super.parse(response)
  }

  setContextStr() {
    return this.set({ context_str: this.getContextStr() })
  }

  getContextStr(ctx, blackList) {
    if (ctx == null) {
      ctx = {}
    }
    if (blackList == null) {
      blackList = ""
    }
    let context_str = this.get("context_str") || ""
    if (_.isObject(this.get("context")) && !context_str) {
      // Using store inside model! Bad?
      context_str = window.App.getStore("discussion").request.getContextStr(
        this.get("context")
      )
    }
    return context_str
  }

  // getContextStr: (ctx={}, blackList="")->
  //   context_str = @get("context_str") or ""
  //   if _.isObject(@get("context"))
  //     ctx = _.extend(_.omit(@get("context"), blackList), ctx)
  //     # Using store inside model! Bad?
  //     context_str = window.App.getStore("discussion").request.getContextStr(ctx)
  //   return context_str

  save() {
    let data = {
      author: this.attributes.author.id,
      title: this.attributes.title,
      body: this.attributes.body,
      discussion_type: this.attributes.discussion_type,
      // notify: this.attributes.notify,
      context: this.attributes.context_str,
      context_str: this.attributes.context_str
    }
    return super.save(data)
  }

  _getTypeLabel() {
    const types = {
      5: i18n.t("discussion.types.question"),
      10: i18n.t("discussion.types.post"),
      15: i18n.t("discussion.types.review"),
      20: i18n.t("discussion.types.faq")
    }
    return types[this.get("discussion_type")]
  }

  _getActionLabel() {
    // TODO: handle post type
    return this.get("user_permissions").can_respond
      ? i18n.t(`discussion.actions.answer`)
      : i18n.t(`discussion.actions.view`)
  }
}
