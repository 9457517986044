import AvatarStack from "@pathwright/ui/src/components/avatar/AvatarStack"
import useIntersection from "@pathwright/ui/src/components/hooks/useIntersection"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import Tooltip from "@pathwright/ui/src/components/tooltip/Tooltip"
import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import get from "lodash/get"
import PropTypes from "prop-types"
import { useEffect } from "react"
import styled from "styled-components"
import { flattenEdges } from "../../../utils/apollo"
import DISCUSSION_PARTICIPANTS_QUERY from "../../graphql/discussion-participants-query"
import DiscussionUnreadIndicator from "./DiscussionUnreadIndicator"
import { getDiscussionContext, getDiscussionResponsesTooltip } from "./utils"

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  .AvatarStack {
    margin-right: 0.4em;
  }
`

const StyledDiscussionUnreadIndicator = styled(DiscussionUnreadIndicator)`
  top: 0.3em;
  right: -0.3em;
`

const DiscussionAvatarStack = ({ discussion, limit }) => {
  const { t } = useTranslate()
  const [setIntersectionNode, intersection, hasIntersected] = useIntersection()

  const discussionParticipantsQuery = useQuery(DISCUSSION_PARTICIPANTS_QUERY, {
    variables: {
      context: getDiscussionContext(discussion),
      discussion_id: discussion.id,
      first: limit
    },
    // avoid querying for participants if there aren't any responses or the component hasn't entered the viewport
    skip: !discussion.total_responses || !hasIntersected
  })

  // if ever the number of responses changes, refetch the discussion participants
  useEffect(() => {
    if (discussionParticipantsQuery.data) discussionParticipantsQuery.refetch()
  }, [discussion.total_responses])

  const avatars = flattenEdges(
    get(discussionParticipantsQuery, "data.discussionParticipants", [])
  )

  const tooltip = getDiscussionResponsesTooltip(
    {
      totalResponses: discussion.total_responses,
      totalResponders: get(
        discussionParticipantsQuery,
        "data.discussionParticipants.total"
      ),
      userLastRespondTime: discussion.user_last_responded_time
    },
    t
  )

  return (
    <Tooltip title={tooltip}>
      <Container ref={setIntersectionNode}>
        <AvatarStack
          users={avatars.slice(-limit)}
          limit={limit}
          size="1.6em"
          itemOffset="-0.4em"
        />
        {!!discussion.total_responses && (
          <span>
            {t("{{ count }} reply", {
              defaultValue_plural: "{{ count }} replies",
              count: discussion.total_responses
            })}
          </span>
        )}
        <StyledDiscussionUnreadIndicator
          discussion={discussion}
          unreadType="responses"
        />
      </Container>
    </Tooltip>
  )
}

DiscussionAvatarStack.displayName = "DiscussionAvatarStack"

DiscussionAvatarStack.propTypes = {
  limit: PropTypes.number
}

DiscussionAvatarStack.defaultProps = {
  limit: 3
}

export default DiscussionAvatarStack
