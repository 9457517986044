import { ApolloProvider } from "@apollo/client"
import { useEffect, useState } from "react"
import getClient from "./client"

const BlocksApolloProvider = ({ children, endpoint, accountID }) => {
  const [client, setClient] = useState(null)

  useEffect(() => {
    const client = getClient({ endpoint, accountID })
    setClient(client)
  }, [endpoint, accountID])

  if (!client) return null

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default BlocksApolloProvider
