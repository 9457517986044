import gql from "graphql-tag"

export default gql`
  fragment StripeProduct on StripeProduct {
    id
    active
    name
    description
    images
    unit_label
    stripe_product_id
    min_quantity
    max_quantity
  }
`
