import { ApolloQueryResult, QueryResult } from "@apollo/client"
import { getClient } from "../../../api/client"
import {
  SpaceContextQueryDocument,
  SpaceContextQueryQuery,
  SpaceContextQueryQueryResult,
  SpaceContextQueryQueryVariables,
  useSpaceContextQueryQuery
} from "../../../api/generated"
import {
  clearAuthenticationToken,
  hasAuthenticationToken
} from "../../../lib/utils/auth-token"

export type SpaceLoaderReturnType = Exclude<
  SpaceContextQueryQueryResult["data"],
  undefined
>

// TODO: convert to util for generating route loaders and hook loaders.
type ApolloSpaceContextQueryResult =
  | QueryResult<SpaceContextQueryQuery, SpaceContextQueryQueryVariables>
  | ApolloQueryResult<SpaceContextQueryQuery>

// Like React.useEffect.
const loaderEffect = (query: ApolloSpaceContextQueryResult): void => {
  const { data, loading, error, ...rest } = query
  // Automatically clear the auth token (and refetch) when no user is found
  // in the db matching the provided auth token.
  if (data && !data.user && !loading && !error && hasAuthenticationToken()) {
    clearAuthenticationToken()
  }
}

// Process the query value.
const loaderValue = (
  query: ApolloSpaceContextQueryResult
): SpaceLoaderReturnType => {
  return query.data as SpaceLoaderReturnType
}

const loadSpace = async (): Promise<SpaceLoaderReturnType> => {
  const client = getClient()
  const query = await client.query<
    SpaceContextQueryQuery,
    SpaceContextQueryQueryVariables
  >({
    query: SpaceContextQueryDocument,
    fetchPolicy: "cache-first"
  })

  loaderEffect(query)
  return loaderValue(query)
}

// Electing to use the useSpaceContextQueryQuery as react-use's useAsync hook
// doesn't have a way to set an initial value, which can result in a brief UI "hiccup"
// while we have to wait for the hook to set the cached (if cached) query result in state.
export const useSpaceLoader = () => {
  const query = useSpaceContextQueryQuery()
  // For now, let's leave it up to the route loader to handle the effect.
  // Currently some AuthContainer tests fail due to clearing the auth token prematurely.
  // useEffect(() => loaderEffect(query), [query])
  return loaderValue(query)
}

export default loadSpace
