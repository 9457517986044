import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import { AudioButtonUI } from "./ui"

const DownloadWrapper = styled.div`
  width: 55px;
  margin: 0px;
`

const DownloadButtonUI = styled(AudioButtonUI)`
  height: 40px;
  width: 50px;
  i.Pathicon {
    font-size: 24px;
  }
`

export const DownloadButton = ({ source }) => {
  source = source.includes("filestackcontent") ? `${source}?dl=true` : source

  return (
    <DownloadWrapper>
      <DownloadButtonUI
        icon="download"
        href={source}
        download={true}
        target="_blank"
      />
    </DownloadWrapper>
  )
}

DownloadButton.propTypes = {
  source: PropTypes.string.isRequired
}

export default DownloadButton
