import { useMemo } from "react"
// import useCohortPermissions from "../cohort/useCohortPermissions"
import { usePathwrightContext } from "../pathwright/PathwrightContext"
import { canEditLibrary } from "../user/permissions"

// NOTE: currently limiting all permissions to Library Editors.
// The commented out code envisions providing Course Editors limited
// permissions, which may be a future update.
const useTagPermissions = (options = {}) => {
  // const cohortPermissions = useCohortPermissions({
  //   cohortId: context.cohort_id
  // })

  // const permissions = useMemo(
  //   () => ({
  //     canAdd: cohortPermissions.hasEditorLevelAccess,
  //     canEdit: cohortPermissions.hasEditorLevelAccess,
  //     canRemove: cohortPermissions.hasEditorLevelAccess,
  //     // Only allow reordering tags attached to resources.
  //     canReorder:
  //       !!context &&
  //       !!context.resource_id &&
  //       cohortPermissions.hasEditorLevelAccess
  //   }),
  //   [cohortPermissions]
  // )

  const pwContext = usePathwrightContext()
  const { context } = options

  const permissions = useMemo(
    () => ({
      canAdd: canEditLibrary(pwContext),
      canEdit: canEditLibrary(pwContext),
      canRemove: canEditLibrary(pwContext),
      // Only allow reordering tags attached to resources.
      canReorder:
        !!context && !!context.resource_id && canEditLibrary(pwContext)
    }),
    [pwContext]
  )

  return permissions
}

export default useTagPermissions
