import ImagePicker from "@pathwright/ui/src/components/form/form-file-picker/ImagePicker"
import MediaManager from "./MediaManager"

const ImagePickerWithMediaManager = (props) => {
  const { blocksContextKey, ...passProps } = props

  return (
    <ImagePicker
      {...passProps}
      renderPicker={({ onChange, renderButton }) => (
        <MediaManager
          contextKey={blocksContextKey}
          renderButton={renderButton}
          onChooseMedia={(media) => onChange(media.url)}
        />
      )}
    />
  )
}

export default ImagePickerWithMediaManager
