import {
  Box,
  Button,
  Flex,
  HStack,
  Input,
  Text,
  VStack
} from "@chakra-ui/react"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import InactiveSchoolGate from "../gate/InactiveSchoolGate"
import AtmosphericImage from "../shared/AtmosphericImage"
import LoadingOverlay from "../shared/LoadingOverlay"
import { useSelectPeopleContext } from "./SelectPeopleContext"
import useNotifyCount from "./useNotifyCount"

const tPrefix = "share_card.add_tab"

const NotifyCount = () => {
  const { t } = useTranslate()
  const notifyCount = useNotifyCount()
  const notifyBlurb = t(`${tPrefix}.{{ count }} person will be notified.`, {
    defaultValue_plural: `${tPrefix}.{{ count }} people will be notified.`,
    count: notifyCount
  })

  return (
    <Text size="xxs" w="100%" color="gray.500">
      {notifyCount > 0 && notifyBlurb}
    </Text>
  )
}

const AtmosphericImageContainer = (props) => {
  return (
    <Box
      {...props}
      borderRadius="0 0 var(--card-border-radius-lg) var(--card-border-radius-lg)"
      p="var(--card-padding)"
    />
  )
}

const SelectAction = () => {
  const { t, tc } = useTranslate()
  const {
    message,
    setMessage,
    addPeopleTo,
    addPeopleToState,
    peopleSelection: { selected, queued }
  } = useSelectPeopleContext()

  const handleSubmit = () => {
    addPeopleTo({
      variables: {
        notificationMessage: message
      }
    })
  }

  return (
    <InactiveSchoolGate>
      <AtmosphericImage
        components={{
          Container: AtmosphericImageContainer
        }}
      >
        <Flex alignItems="center" h="100%">
          <VStack w="100%" p={1}>
            <HStack w="100%" m={0}>
              <Input
                type="text"
                placeholder={`${t(`${tPrefix}.Add a message (optional)`)}...`}
                _placeholder={{ color: "gray.400" }}
                flexGrow={1}
                bg="white"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <Button
                variant="solid"
                px={5}
                py={2}
                onClick={handleSubmit}
                colorScheme="brand"
                isDisabled={Boolean(!selected.length || queued.length)}
              >
                <Text size="xs" fontWeight="bold" color="white">
                  {tc(`${tPrefix}.Add`)}
                </Text>
              </Button>
            </HStack>
            <NotifyCount />
          </VStack>
        </Flex>
      </AtmosphericImage>
      {addPeopleToState.loading && <LoadingOverlay />}
    </InactiveSchoolGate>
  )
}

export default SelectAction
