import {
  Box,
  BoxProps,
  ChakraProvider,
  extendTheme,
  useTheme
} from "@chakra-ui/react"
import { useEffect } from "react"
import {
  BlurredThemeBackgroundImageQueryVariables,
  useBlurredThemeBackgroundImageQuery
} from "../../api/generated"
import {
  PresetHeadingFontKey,
  PresetThemeKey,
  presetHeadingFontCss,
  presetThemePalettes,
  presetThemes
} from "../../theme/presets"

type _CachedMediaImage = string | null | undefined
// Ugh. Apollo client has no of caching the result of media queries
// because there's no way to uniquely identify the root media field.
const _humbleMediaCache: Record<string, _CachedMediaImage> = {}

function _getCacheKeyForMedia(
  args: BlurredThemeBackgroundImageQueryVariables
): string {
  return JSON.stringify(args)
}

export function useBlurredThemeBackgroundImage(
  presetTheme: PresetThemeKey
): _CachedMediaImage {
  const theme = presetThemes[presetTheme]
  const variables: BlurredThemeBackgroundImageQueryVariables = {
    url: theme.background_image,
    width: 250,
    height: 250,
    blur: 500,
    high: -80,
    blend: 666666,
    balph: 40,
    fm: "jpg",
    q: 100,
    bm: "screen"
  }
  const cacheKey = _getCacheKeyForMedia(variables)
  const blurredBgImageQuery = useBlurredThemeBackgroundImageQuery({
    variables,
    fetchPolicy: "network-only",
    skip: !!_humbleMediaCache[cacheKey]
  })

  if (blurredBgImageQuery?.data?.media?.image) {
    _humbleMediaCache[cacheKey] = blurredBgImageQuery?.data?.media?.image
  }

  return _humbleMediaCache[cacheKey]
}

export function usePresetThemeFont(headingFont: PresetHeadingFontKey) {
  // Append font styles to <head />.
  useEffect(() => {
    const id = `${headingFont.split(" ").join("-")}-font-styles`
    const head = document.querySelector("head")!

    // Only append when we haven't already done so (avoid dupes).
    if (!head.querySelector(`#${id}`)) {
      const temp = document.createElement("template")
      const css = presetHeadingFontCss[headingFont]
      temp.innerHTML = `<style id="${id}">${css}</style>`
      head.appendChild(temp.content)
    }
  }, [headingFont])
}

type PresetThemeProps = {
  presetTheme: PresetThemeKey
  children?: JSX.Element
} & BoxProps

export function PresetTheme({
  presetTheme,
  children,
  ...boxProps
}: PresetThemeProps) {
  const blurredBgImage = useBlurredThemeBackgroundImage(presetTheme)
  const theme = presetThemes[presetTheme]
  usePresetThemeFont(theme.heading_font)

  return (
    <>
      {!!blurredBgImage && (
        <Box
          position="absolute"
          w="100%"
          h="100%"
          inset={0}
          bgImage={blurredBgImage}
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
          backgroundSize="cover"
          transition="background 0.3s ease-out"
          {...boxProps}
        />
      )}
      {"background_overlay" in theme && (
        <Box
          position="absolute"
          w="100%"
          h="100%"
          inset={0}
          bgColor={`rgba(${theme.background_overlay})`}
          transition="background 0.3s ease-out"
          {...boxProps}
        />
      )}
      {children}
    </>
  )
}

type SpaceSignupThemeProps = {
  presetTheme: PresetThemeKey
  children: JSX.Element
}

// TODO: mv out of space signup since we'll want to apply themes across various UIs.
function SpaceSignupTheme({ presetTheme, children }: SpaceSignupThemeProps) {
  const theme = presetThemes[presetTheme]

  // Extend chakra theme to override the "theme.heading"
  const parentChakraTheme = useTheme()
  const presetChakraTheme = extendTheme(
    {
      fonts: {
        theme: {
          heading: `${theme.heading_font}, "ProximaNova-Regular", Helvetica, sans-serif`
        }
      },
      colors: {
        brand: presetThemePalettes[theme.primary_color]
      }
    },
    parentChakraTheme
  )

  return (
    <ChakraProvider theme={presetChakraTheme} resetCSS={false}>
      <PresetTheme presetTheme={presetTheme} position="fixed" zIndex={-1}>
        {children}
      </PresetTheme>
    </ChakraProvider>
  )
}

export default SpaceSignupTheme
