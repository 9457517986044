import FormStateSubmitButton from "@pathwright/ui/src/components/form/form-state/FormStateSubmitButton"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import { useCertificateContext } from "../context/CertificateContext"
import { useCertificateState } from "../context/CertificateState"

const CertificateSubmitButton = () => {
  const { t } = useTranslate()
  const { handleSubmit } = useCertificateContext()
  const {
    certificateState,
    certificateStoredState: { discardCount, discardFormState }
  } = useCertificateState()

  return (
    <FormStateSubmitButton
      className="CertificateSubmitButton"
      styleType="primary"
      label={t("Save Changes")}
      onSubmit={() => handleSubmit(certificateState)}
      discardCount={discardCount ? -1 : 0}
      discardFormState={discardFormState}
      tooltipProps={{
        placement: "top",
        alignment: "center"
      }}
    />
  )
}

export default CertificateSubmitButton
