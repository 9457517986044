import gql from "graphql-tag"
import { USER_FRAGMENT_FIELDS } from "../../user/graphql/user-fragment"

export default gql`
  query DiscussionParticipantsQuery(
    $first: Int = 3
    $context: DiscussionContextInput!
    $discussion_id: Int!
  ) {
    discussionParticipants(
      first: $first
      context: $context
      discussion_id: $discussion_id
    ) {
      total
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ${USER_FRAGMENT_FIELDS}
        }
      }
    }
  }
`
