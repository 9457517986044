import { media } from "@pathwright/ui/src/components/utils/styles"
import styled from "styled-components"

import Button from "@pathwright/ui/src/components/button/Button"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"

const StyledContainer = styled.div`
  z-index: 1000;
  position: absolute;
  top: 60px;
  right: 10px;
`

const StyledButton = styled(Button)`
  ${media.max.tablet`
		text-transform: capitalize;
	`};
`

const AccountConfig = () => {
  return (
    <StyledContainer>
      <StyledButton to="/manage/school/" styleType="primary-alt" inverted>
        <Pathicon icon="gear" /> Account settings
      </StyledButton>
    </StyledContainer>
  )
}

AccountConfig.displayName = "AccountConfig"

export default AccountConfig
