import classnames from "classnames"
import defaults from "lodash/defaults"
import isFunction from "lodash/isFunction"
import PropTypes from "prop-types"
import styled from "styled-components"
import Indicator from "../indicator/Indicator"
import Link from "../link/Link"
import Pathicon from "../pathicon/Pathicon"
import ChakraTooltip from "../tooltip/ChakraTooltip"
import {
  PRIMARY_GRAY,
  PRIMARY_WHITE,
  SECONDARY_GRAY,
  SECONDARY_WHITE
} from "../utils/colors"
import { media } from "../utils/styles"

const getTabCurrentColor = ({ inverted, active }) => {
  if (inverted) {
    if (active) return PRIMARY_WHITE
    return SECONDARY_WHITE
  }
  if (active) return PRIMARY_GRAY
  return SECONDARY_GRAY
}

const StyledTab = styled.li`
  --tab-current-color: ${getTabCurrentColor};
  &:hover {
    --tab-current-color: ${(p) => getTabCurrentColor({ ...p, active: true })};
  }

  cursor: pointer;
  position: relative;
  text-align: center;
  list-style-type: none;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 8em;
  height: 3.125em;
  max-height: 100%;
  margin: 0 1em;
  transition: border-color 200ms ease-in;
  font-weight: ${(props) => (props.active ? 700 : 600)};
  color: var(--tab-current-color);
  border-bottom: 0.1875em solid var(--tab-current-color);

  ${(p) =>
    !p.active &&
    `
    &:not(:hover) {
      border-bottom: 0.1875em solid transparent;
    }
  `}

  ${(p) => !p.underline && "border-bottom: none !important;"}

  ${media.max.tablet`
    margin: 0 0.625em;
  `}
  ${media.max.phone`
    margin: 0;
  `}
`

const StyledLink = styled.a`
  color: inherit !important;
  border-bottom: transparent !important;
  display: block;
  font-family: inherit;
  font-size: 1em;
  text-decoration: none;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const TabContent = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .Pathicon {
    margin-right: 0.4em;
  }

  .Indicator {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(100%, -65%);
    font-size: 0.8em;
  }

  .Tab__count {
    color: ${(p) => getTabCurrentColor({ ...p, inverted: !p.inverted })};
    background-color: ${getTabCurrentColor};
    padding: 0.1em 0.2em;
    border-radius: 5px;
    margin-left: 0.4em;
    font-size: 0.8em;
  }
`

const Tab = ({
  children,
  className,
  tab,
  onClick,
  to,
  active,
  inverted,
  underline,
  ...rest
}) => {
  const renderTab = (options) => {
    let { icon, label, count, indicator, tooltip } = defaults(options, tab)

    const tabUI = (
      <TabContent inverted={inverted} active={active}>
        {!!icon && <Pathicon icon={icon} />}
        {label}
        {!!count && <div className="Tab__count">{count}</div>}
        {!!indicator && <Indicator />}
      </TabContent>
    )

    return tooltip ? <ChakraTooltip {...tooltip}>{tabUI}</ChakraTooltip> : tabUI
  }

  const renderChildren = () =>
    isFunction(children)
      ? children({ renderTab })
      : children
      ? children
      : renderTab()

  return (
    <StyledTab
      {...rest}
      className={classnames("Tab", active ? "Tab--active" : "", className)}
      active={active}
      inverted={inverted}
      role="tab"
      underline={underline}
    >
      {to ? (
        <StyledLink as={Link} onClick={onClick} to={to}>
          {renderChildren()}
        </StyledLink>
      ) : (
        <StyledLink onClick={onClick}>{renderChildren()}</StyledLink>
      )}
    </StyledTab>
  )
}

Tab.displayName = "Tab"

Tab.propTypes = {
  children: PropTypes.any,
  renderTab: PropTypes.func,
  to: PropTypes.string,
  active: PropTypes.bool.isRequired,
  inverted: PropTypes.bool,
  underline: PropTypes.bool,
  onClick: PropTypes.func
}

Tab.defaultProps = {
  underline: true
}

export default Tab
