import { graphql } from "@apollo/client/react/hoc"
import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import { RESOURCE_TYPE_LABELS } from "@pathwright/web/src/modules/resource/constants"
import PropTypes from "prop-types"
import { Form, Submit } from "react-connect-form-forked"
import UPDATE_RESOURCE_MENU_MUTATION from "../manage/graphql/update-resource-menu-mutation"
import ManageResourceHeader from "./ManageResourceHeader"

const ManageResourceHeaderContainer = (props) => {
  const { card, resource, updateResource, onSubmitSuccess } = props
  return (
    <Form
      onPristine={() => card.setCardDirty(false)}
      onDirty={() => card.setCardDirty(true)}
      onSubmitSuccess={(...args) => {
        card.setCardDirty(false)
        onSubmitSuccess && onSubmitSuccess(...args)
      }}
      onSubmit={(form) =>
        updateResource({
          id: resource.id,
          name: resource.name,
          resource_type: RESOURCE_TYPE_LABELS[resource.resource_type],
          cover_image: form.cover_image
        })
      }
    >
      <Submit component={SubmitButton} styleType="primary">
        Save
      </Submit>
      <ManageResourceHeader resource={resource} />
    </Form>
  )
}

ManageResourceHeaderContainer.displayName = "ManageResourceHeaderContainer"

ManageResourceHeaderContainer.propTypes = {
  card: PropTypes.object.isRequired,
  resource: PropTypes.object.isRequired,
  updateResource: PropTypes.func.isRequired
}

export default graphql(UPDATE_RESOURCE_MENU_MUTATION, {
  props: ({ mutate, ownProps }) => ({
    updateResource: (changes) => mutate({ variables: changes })
  })
})(ManageResourceHeaderContainer)
