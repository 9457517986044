import gql from "graphql-tag"
import PATH_FRAGMENT from "./path-fragment"
import PATH_ITEM_FRAGMENT from "./path-item-fragment"

export default gql`
  fragment PathItems on Path {
    ...Path
    items(flatten: true, first: 1000, after: $cursor) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...PathItem
        }
      }
    }
  }
  ${PATH_ITEM_FRAGMENT}
  ${PATH_FRAGMENT}
`
