import partial from "lodash/partial"
import { useAsyncFn } from "react-use"
import { z } from "zod"
import { zfd } from "zod-form-data"
import { getClient } from "../../../../../api/client"
import {
  StartPasswordRetrievalDocument,
  StartPasswordRetrievalMutation,
  StartPasswordRetrievalMutationVariables
} from "../../../../../api/generated"
import getNextUrl from "../../../utils/getNextUrl"
import { setPreferredAuthMethod } from "../../../utils/preferredAuthMethod"

export const schema = z.object({
  email: z.string().email()
})

export const formDataSchema = zfd.formData({
  email: zfd.text(z.string().email())
})

const startPasswordRetrieval = async (
  formData: FormData
): Promise<{ success: true }> => {
  const nextUrl = getNextUrl()
  const client = getClient()
  const { data } = await client.mutate<
    StartPasswordRetrievalMutation,
    StartPasswordRetrievalMutationVariables
  >({
    mutation: StartPasswordRetrievalDocument,
    variables: {
      ...formDataSchema.parse(formData),
      nextUrl: nextUrl
    }
  })

  if (!data?.startPasswordRetrieval) {
    throw new Error("Failed to send password reset email.")
  }

  setPreferredAuthMethod("password")

  return { success: true }
}

startPasswordRetrieval.schema = formDataSchema

export const useStartPasswordRetrieval = partial(
  useAsyncFn,
  startPasswordRetrieval
)

export default startPasswordRetrieval
