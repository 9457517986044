import PropTypes from "prop-types"
import React, { Component } from "react"
import { withTranslate } from "../lng"
import SubmitHandler from "../submit/SubmitHandler"
import Success from "../success/Success"

const withConfirm = (ConfirmComponent) => {
  class Confirm extends Component {
    state = {
      loading: false,
      confirmed: false,
      confirming: false
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      if (nextProps.loading !== this.props.loading) {
        this.setState({ loading: nextProps.loading })
      }
    }

    onSubmitProcessing = () => {
      this.setState({ loading: true, confirming: true, error: null })
    }

    onSubmitFailure = (error) => {
      this.setState({ error, confirmed: false, loading: false })
    }

    onSubmitSuccess = () => {
      const confirming =
        this.props.confirmedHeading || this.props.confirmedBody ? true : false

      this.setState({
        confirming,
        confirmed: confirming,
        loading: false
      })
    }

    handleOnCancel = (e) => {
      e.stopPropagation()
      const onConfirmed = this.state.confirmed && this.props.onConfirmed
      this.setState({
        confirming: false,
        confirmed: false,
        loading: false,
        error: null
      })

      if (onConfirmed) return onConfirmed(e)
      if (this.props.onCancel) return this.props.onCancel(e)
    }

    handleSelectOption = (option) => {
      this.setState({ onConfirm: option ? option.action : null })
    }

    render() {
      const {
        confirmedHeading,
        confirmedBody,
        confirmOptions,
        onConfirm,
        onCancel,
        hideCancel,
        hideSuccess,
        cancelTo,
        t,
        children,
        confirmProps = {},
        metaBypassesConfirm,
        ...passProps
      } = this.props

      const submitProps = {
        ...confirmProps,
        onSubmitFailure: this.onSubmitFailure,
        onSubmitSuccess: this.onSubmitSuccess,
        onSubmitProcessing: this.onSubmitProcessing
      }

      const cancelPrompt =
        this.props.cancelPrompt || t("user_confirmation.cancel")
      const confirmPrompt = this.props.confirmPrompt
        ? this.props.confirmPrompt
        : this.props.onCancel
        ? t("user_confirmation.yes")
        : t("user_confirmation.ok")
      const confirmedPrompt =
        this.props.confirmedPrompt || t("user_confirmation.ok")

      const confirm = (e) => {
        // Optionally execute the action immediately when Meta key is present
        // as long as there aren't multiple confirm options.
        if (
          metaBypassesConfirm &&
          !confirmOptions?.length &&
          e?.getModifierState?.("Meta")
        ) {
          onConfirm()
        } else {
          this.setState({ confirming: true })
        }
      }

      return (
        <React.Fragment>
          {children &&
            children({
              confirm,
              confirming: this.state.confirming
            })}
          {this.state.confirming || !children ? (
            this.state.confirmed && !hideSuccess ? (
              <Success
                hideModal={false}
                icon="check"
                heading={confirmedHeading}
                body={confirmedBody}
                onBackgroundClick={this.handleOnCancel}
                primaryAction={{
                  onClick: this.handleOnCancel,
                  children: confirmedPrompt
                }}
              />
            ) : (
              <SubmitHandler onSubmit={() => onConfirm()} {...submitProps}>
                {({ handleSubmit }) => (
                  <ConfirmComponent
                    {...passProps}
                    primaryAction={
                      confirmOptions
                        ? {
                            ...submitProps,
                            label: confirmPrompt,
                            options: confirmOptions,
                            interactive: !this.state.loading
                          }
                        : {
                            ...submitProps,
                            onClick: (e) => handleSubmit(e),
                            children: confirmPrompt,
                            interactive: !this.state.loading
                          }
                    }
                    secondaryAction={
                      !hideCancel &&
                      !this.state.confirmed &&
                      !this.state.loading
                        ? {
                            styleType: "text",
                            to: cancelTo,
                            onClick: this.handleOnCancel,
                            disabled: this.state.loading,
                            children: cancelPrompt,
                            interactive: !this.state.loading
                          }
                        : null
                    }
                    error={
                      this.state.error && this.state.error.message
                        ? this.state.error.message
                        : this.state.error
                    }
                    clearError={() => this.setState({ error: null })}
                    loading={this.state.loading}
                  />
                )}
              </SubmitHandler>
            )
          ) : null}
        </React.Fragment>
      )
    }
  }

  Confirm.displayName = "Confirm"
  Confirm.propTyps = {
    heading: PropTypes.string,
    body: PropTypes.string,
    confirmedHeading: PropTypes.string,
    confirmedBody: PropTypes.string,
    cancelTo: PropTypes.string,
    cancelPrompt: PropTypes.string,
    confirmPrompt: PropTypes.string,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    confirmingStatus: PropTypes.string,
    hideCancel: PropTypes.bool,
    hideSuccess: PropTypes.bool,
    confirmOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        action: PropTypes.func.isRequired,
        selected: PropTypes.bool
      })
    ),
    metaBypassesConfirm: PropTypes.bool
  }

  Confirm.defaultProps = {
    hideCancel: false,
    hideSuccess: false,
    metaBypassesConfirm: false
  }

  return withTranslate(Confirm)
}

export default withConfirm
