import gql from "graphql-tag"
import USER_FRAGMENT from "../../user/graphql/user-fragment"

export default gql`
  fragment Completion on Completion {
    id
    checked_date
    completion_attempts
    completion_comment
    completion_date
    completion_due
    last_completion_attempt_date
    has_failed_passing_attempt
    has_points
    is_late
    is_graded
    is_skipped
    is_complete
    is_auto_graded
    is_forced_completion
    needs_grading
    userpoints_earned
    userpoints_explanation
    userpoints_value
    minimum_passing_score
    grading_type
    requires_api_completion
    overridden_by_api
    overridden_by {
      ...User
    }
    checked_by {
      ...User
    }
    user {
      ...User
    }
    submission {
      id
      text
      file_url
      file_name
      file_image
    }
    feedback_discussion_context {
      id
      discussion_id
      num_responses
    }
  }
  ${USER_FRAGMENT}
`
