import { useEffect, useRef } from "react"

// use hook per interval call being handled
// NOTE: self clears on unmount AND on subsequent calls
const useCleanupInterval = () => {
  const intervalRef = useRef(null)

  useEffect(() => {
    return () => clearInterval(intervalRef.current)
  }, [])

  const cleanupInterval = (interval) => {
    clearInterval(intervalRef.current)
    intervalRef.current = interval
    return interval
  }

  cleanupInterval.intervalRef = intervalRef

  return cleanupInterval
}

export default useCleanupInterval
