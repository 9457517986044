import { Box, Button as ChakraButton } from "@chakra-ui/react"
import Button from "@pathwright/ui/src/components/button/Button"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import NotificationFeedItem from "./NotificationFeedItem"
import NotificationsControls from "./NotificationsControls"
import NotificationsDigestSettings from "./NotificationsDigestSettings"
import NotificationsEmptyFeed from "./NotificationsEmptyFeed"
import Feed from "./feed/Feed"
import { useNotificationsState } from "./state/notification-state"

export const filters = [
  {
    key: "unread",
    getLabel: (t) => t("Unread"),
    filter: (item) => !item.data.read,
    showCount: true
  },
  {
    key: "pinned",
    getLabel: (t) => t("Pinned"),
    filter: (item) => item.data.pinned,
    showCount: true
  },
  {
    key: "all",
    getLabel: (t) => t("All"),
    filter: () => true,
    showCount: false
  }
]

function Paginate() {
  const { paginate } = useNotificationsState()
  return paginate ? (
    <Box w="100%" px={4} mb={2}>
      <ChakraButton w="100%" onClick={paginate} cursor="pointer">
        Load more
      </ChakraButton>
    </Box>
  ) : null
}

const NotificationsFeedContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    h1 {
      font-size: 1.3em;
      margin: 0px;
    }
  }
`

export const NotificationsFeed = () => {
  let { items, markRead, remove, setKey } = useNotificationsState()
  const { t } = useTranslate()

  const [showDigest, setShowDigest] = useState(false)

  let feedItems = items
    .filter((item) => item.type === "item")
    .sort((a, b) => {
      return b.data.when - a.data.when
    })

  const handleShowDigest = () => {
    setShowDigest(true)
  }

  const handleMarkAsRead = () => {
    markRead(items)
  }

  const handleClear = () => {
    // remove(items, true /* ignoreSelected */)
  }

  const renderControls = () => {
    return (
      <NotificationsControls
        items={feedItems}
        onShowDigest={handleShowDigest}
        onMarkAsRead={handleMarkAsRead}
        onClear={handleClear}
      />
    )
  }

  return (
    <NotificationsFeedContainer>
      {!showDigest ? (
        <React.Fragment>
          <header>
            <h1>{t("notifications.label")}</h1>
            {renderControls()}
          </header>
          <Feed
            items={feedItems}
            feedItemComponent={NotificationFeedItem}
            emptyFeedCompoent={NotificationsEmptyFeed}
            paginateComponent={Paginate}
            filters={filters}
            onFilter={setKey}
          />
        </React.Fragment>
      ) : (
        <div>
          <header>
            <Button
              onClick={() => setShowDigest(false)}
              label="Back"
              icon="chevron-left"
              styleType="text"
              style={{ borderBottom: 0 }}
            />
          </header>
          <NotificationsDigestSettings />
        </div>
      )}
    </NotificationsFeedContainer>
  )
}

export default NotificationsFeed
