import { useMutation, useQuery } from "@apollo/client"
import { HStack, VStack, useToast } from "@chakra-ui/react"
import { CheckIcon } from "@pathwright/pathicons"
import Avatar from "@pathwright/ui/src/components/avatar/Avatar"
import IconButton from "@pathwright/ui/src/components/button/IconButton"
import useIntersection from "@pathwright/ui/src/components/hooks/useIntersection"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import ChakraTooltip from "@pathwright/ui/src/components/tooltip/ChakraTooltip"
import gql from "graphql-tag"
import { useEffect } from "react"
import styled from "styled-components"
import { DISCUSSION_TYPE_FEEDBACK } from "../../../discussion/constants"
import CREATE_DISCUSSION from "../../../discussion/graphql/create-discussion-mutation"
import { usePathwrightContext } from "../../../pathwright/PathwrightContext"
import { canEditLibrary } from "../../../user/permissions"
import Reviewers, { useInboxReviewers } from "../../context/Reviewers"
import { updateFeedbackDiscussionNumResponses } from "../../context/discuss/utils"
import { getResponseCountStr } from "./FeedbackResponses"

function useAskToShareFeedback(item) {
  const { t } = useTranslate()
  const toast = useToast()

  const stepId = item.data.id
  const cohortId = item.data.cohort.id
  const resourceId = item.data.resource.id

  const [createDiscussion] = useMutation(CREATE_DISCUSSION, {
    variables: {
      context: {
        step_id: stepId,
        cohort_id: cohortId,
        resource_id: resourceId,
        completion_id: stepId
      },
      post: {
        title: t("completion.feedback.panel.feedback_for_step", {
          step: item.data.name
        }),
        body: "<br/>",
        type: DISCUSSION_TYPE_FEEDBACK
      }
    }
  })

  return async (reviewer) => {
    // Let's give immediate feedback.
    toast({
      title: `Asked ${reviewer.user.display_name} to share feedback.`,
      // description: `@${copiedVariable}`,
      status: "success",
      duration: 4000,
      isClosable: false,
      icon: <CheckIcon />
      // onCloseComplete: () => setCopiedVariable()
    })

    let discussionId, discussionContextId
    const { feedback_discussion_context: discussionData } = item.data.completion

    if (discussionData) {
      discussionId = discussionData.discussion_id
      discussionContextId = discussionData.id
    } else {
      // The feedback discussion doesn't exist yet, so we must create it.
      const result = await createDiscussion()
      const discussion = result.data.createDiscussion
      // Basically we just need to add the feedback_discussion_context into the cache
      // so we can reference it next time ask to answer is attempted on this item
      // (so we don't attempt to create the discussion again).
      updateFeedbackDiscussionNumResponses(discussion)
      discussionId = discussion.id
      discussionContextId = discussion.context_id
    }

    const target = `discussion_${discussionId}`
    const userId = reviewer.id
    const contextKey = `step_${stepId},class_${cohortId},course_${resourceId}`

    window.App.getStore("notification").action.sendActionNotification(
      1, // ask to answer
      target,
      [userId],
      contextKey,
      discussionContextId
    )
  }
}

// Set the avatar highlight color.

// For each reviewer that has responded,
// display the avatar highlight and override the z-index to ensure a reviewer
// who has not responded and directly precedes one that has responded layers
// beneath the responder.

// For each reviewer that has not responded, lower the avatar opacity.
const HighlightedReviewers = styled(Reviewers)`
  --avatar-highlight: var(--school-main-color);

  ${(p) =>
    p.$feedbackReviewerPositioning.map((hasResponded, i) => {
      if (hasResponded) {
        return `
          .Stack__item:nth-child(${i + 1}) {
            z-index: ${200 - i} !important;
            .Avatar--highlight {
              display: block;
            }
          }
        `
      } else {
        return `
          .Stack__item:nth-child(${i + 1}) .Avatar {
            opacity: 0.5;
          }
        `
      }
    })}
`

// Lists ids of all reviewers who have responded via feedback discussion
// ordered by earliest response.
const FEEDBACK_REVIEWERS_QUERY = gql`
  query FeedbackReviewersQuery($completionId: Int!) {
    feedbackReviewers(completion_id: $completionId) {
      id
      reviewers
    }
  }
`

// Defer rendering FeedbackReviewers until parent node has intersected viewport.
export function DeferredFeedbackReviewers({ item, parentNode, ...rest }) {
  const { hasIntersected, setIntersectionNode } = useIntersection(
    { disconnectAfterIntersection: true },
    // NOTE: the delay only applies if the element does not immediately intersect the root node
    300 /* delay */
  )

  useEffect(() => {
    setIntersectionNode(parentNode)
  }, [parentNode])

  // Query the cache to see if they reviewers loaded.
  const { reviewers: cachedReviewers } = useInboxReviewers(item.data.path_id, {
    fetchPolicy: "cache-only"
  })

  // Defer rendering of the FeedbackReviewers until either the parent node has come into view
  // or we already have loaded the reviewers. This ensure we don't show the FeedbackReviewers
  // prematurely, but we don't hide it unnecessarily either.
  return hasIntersected || cachedReviewers.length ? (
    <FeedbackReviewers
      item={item}
      deferrResponseCounts={!hasIntersected}
      {...rest}
    />
  ) : null
}

// Only certain users are allowed to access feedback reviewers component.
// The data isn't sensitive, so it's fine to handle this check on the client.
export const withFeedbackReviewersContainer =
  (FeedbackReviewersComponent) =>
  ({ item, ...rest }) => {
    const pwContext = usePathwrightContext()

    // Query the cache to see if they reviewers loaded.
    const { reviewers: cachedReviewers } = useInboxReviewers(
      item.data.path_id,
      {
        fetchPolicy: "cache-only"
      }
    )

    // Infer reviewer privilege, but default to "allow" when unknown.
    const hasReviewerPrivileges =
      // library editor+ have priviliges...
      canEditLibrary(pwContext) ||
      // user who does not own the completion has privileges (they naturally have reviewer perms)...
      item.data.completion.user.id !== pwContext.me.id ||
      // reviewers have priviliges...
      !cachedReviewers.length ||
      cachedReviewers.some((reviewer) => reviewer.id === pwContext.me.id)

    const ReviewersComponent = hasReviewerPrivileges
      ? FeedbackReviewersComponent
      : Reviewers

    // Defer rendering of the FeedbackReviewers until either the parent node has come into view
    // or we already have loaded the reviewers. This ensure we don't show the FeedbackReviewers
    // prematurely, but we don't hide it unnecessarily either.
    return <ReviewersComponent item={item} {...rest} />
  }

const FeedbackReviewers = withFeedbackReviewersContainer(
  function FeedbackReviewers({
    item,
    limit = 12,
    highlightResponders = false,
    deferrResponseCounts = false,
    ...reviewerProps
  }) {
    const { t } = useTranslate()
    const { completion } = item.data

    const askToShareFeedback = useAskToShareFeedback(item)
    const { reviewers } = useInboxReviewers(item.data.path_id)

    const totlaResponses =
      completion.feedback_discussion_context?.num_responses || 0

    // In some contexts, we'll want to defer querying the response counts.
    // Think list view where component is below the viewport.
    const skipQuery = !totlaResponses || deferrResponseCounts

    const query = useQuery(FEEDBACK_REVIEWERS_QUERY, {
      variables: { completionId: completion.id },
      skip: skipQuery
    })

    useEffect(() => {
      if (totlaResponses && !query.loading && !skipQuery) {
        query.refetch()
      }
    }, [totlaResponses])

    const feedbackReviewers = query.data?.feedbackReviewers?.reviewers || []
    const feedbackReviewerIds = feedbackReviewers.map((reviewer) => reviewer.id)

    // List of booleans describing the position of reviewers in the
    // reviewers avatar stack that have responded.
    const feedbackReviewerPositioning = reviewers.map((reviewer) =>
      feedbackReviewerIds.includes(reviewer.id)
    )

    const reviwersTooltipUI = (
      <VStack alignItems="flex-start">
        {reviewers.map((reviewer) => {
          const responseCount =
            feedbackReviewers.find(
              (feedbackReviewer) => feedbackReviewer.id === reviewer.id
            )?.response_count || 0

          return (
            <HStack key={reviewer.id}>
              <HStack whiteSpace="nowrap">
                <Avatar user={reviewer.user} size="1.4em"></Avatar>
                <strong>{reviewer.user.display_name}</strong>
                <span>
                  {responseCount
                    ? `left ${getResponseCountStr(t, responseCount)}`
                    : "hasn't responded"}
                </span>
              </HStack>
              {!responseCount && (
                <ChakraTooltip
                  title={`Ask ${reviewer.user.display_name} to share feedback.`}
                  openDelay={600}
                  placement="left"
                  fitContent
                >
                  <IconButton
                    icon="paper-airplane"
                    style={{ height: "20px" }}
                    onClick={() => askToShareFeedback(reviewer)}
                    color="whitesmoke"
                  />
                </ChakraTooltip>
              )}
            </HStack>
          )
        })}
      </VStack>
    )

    const ReviewersComponent = highlightResponders
      ? HighlightedReviewers
      : Reviewers

    return !!reviewers.length ? (
      <ChakraTooltip title={reviwersTooltipUI}>
        <ReviewersComponent
          item={item}
          limit={limit}
          getTooltip={null}
          {...reviewerProps}
          $feedbackReviewerPositioning={feedbackReviewerPositioning}
        />
      </ChakraTooltip>
    ) : null
  }
)

export default FeedbackReviewers
