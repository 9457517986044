// Not working: Cannot read properties of undefined (reading 'source')
// import { UserFragmentDoc } from "../../api/generated";
// export default UserFragmentDoc

import gql from "graphql-tag"

export const USER_FRAGMENT_FIELDS = `
  id
  email
  first_name
  last_name
  full_name
  display_name
  profile {
    id
    bio
    image(width: 250, height: 250, crop: faces)
    location
    last_activity
    rewardful_referral_id
  }
`

export default gql`
  fragment User on User {
    ${USER_FRAGMENT_FIELDS}
  }
`
