const changelogPathURL = new URL("https://pwdemo.paths.app/pw2-change-log")

export const CHANGELOG_PATH = changelogPathURL.href
// Use this url for testing out the latest changes which may not yet be in the main branch.
// const CHANGELOG_PATH = "https://next.paths.app/pw2-change-log"

export const CHANGELOG_PATH_API_URL = `${changelogPathURL.origin}/api${changelogPathURL.pathname}.json`

// Use the setup below for testing locally against a local Paths dev.
// export const CHANGELOG_PATH = `http://pwdemo.localhost:3000/pw2-change-log`,
// export const CHANGELOG_PATH_API_URL = "http://127.0.0.1:3000/api/path/pw2-change-log.json"
