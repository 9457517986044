import { Box, Text } from "@chakra-ui/react"

function RoleBadge({ label, color, highlight }) {
  const hoverBoxStyles = {
    _hover: {
      bg: `${color}.200`
    }
  }

  const highlightBoxStyles = {
    bg: `${color}.500`,
    color: "white",
    shadow: "md"
  }

  const highlightTextStyles = {
    color: `${color}.100`
  }

  const boxStyles = highlight ? highlightBoxStyles : hoverBoxStyles
  const textStyles = highlight ? highlightTextStyles : {}

  return (
    <Box
      cursor="pointer"
      borderRadius="10px"
      bg="rgba(255,255,255,.8)"
      my={1}
      px={2}
      py={1}
      {...boxStyles}
    >
      <Text
        size="xs"
        fontWeight="bold"
        m={0}
        color={`${color}.500`}
        id={`role-badge-label-${label}`}
        {...textStyles}
      >
        {label}
      </Text>
    </Box>
  )
}

export default RoleBadge
