import { css, keyframes } from "styled-components"

const zoom = keyframes`
  0% {
    transform: scale(1.01);
  }
	100% {
		transform: scale(1.04);
	}
`

export const overlay = css`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  min-height: 100%;
  min-width: 100%;
  overflow: hidden;

  &.zoom {
    animation: ${zoom} 2s;
    animation-fill-mode: forwards;
  }
`
