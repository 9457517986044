import { getStoreUrl } from "../store/utils"
const { createCardRoutes } = require("lib/core/routing/card-routing")
const { loadAuthors, selectAuthor, unselectAuthor } = require("./middleware")

const AuthorListCard = require("./list/AuthorListCard").default
const AuthorFormCard = require("./item/AuthorFormCard").default

export const cardRoutes = {
  author: {
    component: AuthorListCard,
    middleware: loadAuthors,
    getDefaultLaunchedFrom: () => getStoreUrl(),
    childCards: {
      item: {
        path: ":author_id(\\d+|new)/",
        middleware: selectAuthor,
        leaveMiddleware: unselectAuthor,
        component: AuthorFormCard
      }
    }
  }
}

const fallbackComponent = require("store/StoreOrLibraryBrowse").default

export default createCardRoutes("/manage/", cardRoutes, fallbackComponent)
