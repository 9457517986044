import gql from "graphql-tag"
import MENTOR_GROUP_FRAGMENT from "./mentor-group-fragment"

export default gql`
  mutation UpdateMentorGroup(
    $id: Int!
    $name: String
    $parent: Int
    $description: String
    $type: MentorGroupType
  ) {
    updateMentorGroup(
      id: $id
      name: $name
      parent: $parent
      description: $description
      type: $type
    ) {
      ...MentorGroup
    }
  }
  ${MENTOR_GROUP_FRAGMENT}
`
