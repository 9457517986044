import UnlocksOverlay from "@pathwright/ui/src/components/overlay/UnlocksOverlay"
import useCohortPermissions from "@pathwright/web/src/modules/cohort/useCohortPermissions"
import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import get from "lodash/get"
import PATH_ITEM_QUERY from "../path/graphql/path-item-query"
import { getPathItemNonPassiveFutureStartsDate } from "../path/utils"
import { usePathwrightContext } from "../pathwright/PathwrightContext"

/**
 *
 * @param { cohortId } Int used for querying user's cohort permissions
 * @param { pathItemSourceId } Int used for querying user's path item
 * @param { parentPathItemSourceId } Int used for querying parent path item of user's path item
 * @returns UnlocksOverlay if discussion is locked for user, otherwise null
 *
 * A cohort teacher+ has full access to discussion.
 *
 * A cohort moderator has full access to discussion provided
 * the path item is not password locked.
 *
 * A cohort learner- has full access to discussion provided
 * the path item is not password locked and neither the path item nor
 * the parent path item has a future non-passive starts date.
 */

const DiscussionUnlocksOverlay = ({
  cohortId,
  pathItemSourceId,
  parentPathItemSourceId: parentPathItemSourceIdProp
}) => {
  const { me } = usePathwrightContext()
  const cohortPerms = useCohortPermissions({ cohortId })

  const pathItemQuery = useQuery(PATH_ITEM_QUERY, {
    variables: {
      source_id: pathItemSourceId,
      user_id: get(me, "id")
    },
    skip: !me || cohortPerms.hasTeacherLevelAccess
  })

  const pathItem = get(pathItemQuery, "data.pathItem")
  // get the parentPathItemSourceId either from the pathItem or props
  const parentPathItemSourceId =
    get(pathItem, "parent_source_id") || parentPathItemSourceIdProp

  const parentPathItemQuery = useQuery(PATH_ITEM_QUERY, {
    variables: {
      source_id: parentPathItemSourceId,
      user_id: get(me, "id")
    },
    // only quering if there is a parentPathItemSourceId
    skip: !me || !parentPathItemSourceId || cohortPerms.hasTeacherLevelAccess
  })

  const parentPathItem = get(parentPathItemQuery, "data.pathItem")

  if (!pathItem || cohortPerms.hasTeacherLevelAccess) return null

  // NOTE: beyond this point parentPathItem may be undefined
  const starts = getPathItemNonPassiveFutureStartsDate(pathItem, parentPathItem)
  const startLocked = !!starts && !cohortPerms.hasModeratorLevelAccess
  const passwordLocked = pathItem.is_locked || get(parentPathItem, "is_locked")

  if (!passwordLocked && !startLocked) return null

  return (
    <UnlocksOverlay starts={starts} passwordLocked={passwordLocked} overlay />
  )
}

DiscussionUnlocksOverlay.displayName = "DiscussionUnlocksOverlay"

export default DiscussionUnlocksOverlay
