import classnames from "classnames"
import PropTypes from "prop-types"
import React from "react"
import isEqual from "react-fast-compare"
import FieldWrapper from "../form-utils/FieldWrapper"
import "./Checkbox.css"

class Checkbox extends React.Component {
  static contextTypes = {
    checkboxGroup: PropTypes.shape({
      id: PropTypes.string,
      selected: PropTypes.any,
      onToggle: PropTypes.func,
      onChange: PropTypes.func
    })
  }

  constructor(props) {
    super(props)
    const { value } = props
    this.state = { value }
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (nextProps.value !== this.props.value) {
      return this.setState({ value: nextProps.value })
    }
  }

  handleChange = (value) => {
    const { value: old_value } = this.state
    this.setState({ value })
    this.context.checkboxGroup.onChange(value, old_value)
    return typeof this.props.onChange === "function"
      ? this.props.onChange(value)
      : undefined
  }

  handleToggle = () => {
    const { value } = this.state
    this.context.checkboxGroup.onToggle(value)
    return typeof this.props.onChange === "function"
      ? this.props.onChange(value)
      : undefined
  }

  render() {
    const { value } = this.state
    const { checkboxGroup } = this.context
    let { children, className } = this.props
    className = classnames("Checkbox", className)
    const isChecked = _.find(checkboxGroup.selected, (item) =>
      isEqual(item, value)
    )
      ? true
      : false

    return (
      <label className={className}>
        <input
          type="checkbox"
          value={value != null ? value : ""}
          name={checkboxGroup.id}
          checked={isChecked}
          onChange={this.handleToggle}
        />
        {_.isFunction(children) ? (
          children({ value, onChange: this.handleChange })
        ) : (
          <span>{children}</span>
        )}
      </label>
    )
  }
}

class CheckboxGroup extends React.Component {
  getChildContext() {
    const checkboxGroup = {
      id: this.props.id,
      selected: this.props.value,
      onToggle: this.handleToggle,
      onChange: this.handleChange
    }
    return { checkboxGroup }
  }

  handleToggle = (checkboxValue) => {
    let newSelected
    const { value: selected, onChange } = this.props
    if (_.includes(selected, checkboxValue)) {
      newSelected = _.without(selected, checkboxValue)
    } else {
      newSelected = _([]).concat(selected, checkboxValue).value()
    }
    return typeof onChange === "function" ? onChange(newSelected) : undefined
  }

  handleChange = (newValue, oldValue) => {
    const { value: selected, onChange } = this.props
    const newSelected = _([])
      .concat(_.without(selected, oldValue), newValue)
      .value()
    return typeof onChange === "function" ? onChange(newSelected) : undefined
  }

  render() {
    let { children, className } = this.props

    className = classnames("CheckboxGroup", className)

    return (
      <FieldWrapper
        {...Object.assign({}, this.props, {
          className: className,
          fixedLabel: true
        })}
      >
        {children}
      </FieldWrapper>
    )
  }
}

CheckboxGroup.displayName = "CheckboxGroup"

CheckboxGroup.childContextTypes = {
  checkboxGroup: PropTypes.shape({
    // Sets child Checkbox name to group inputs.
    id: PropTypes.string,
    // Compared against child Checkbox value to set checked.
    selected: PropTypes.any,
    // Hook for child Checkbox to trigger.
    onChange: PropTypes.func
  })
}

export default { CheckboxGroup, Checkbox }
