import { VStack } from "@chakra-ui/react"
import { useAuthContext } from "../api/context"
import AuthMotion from "../components/AuthMotion"
import AuthOptionsDivider from "../components/AuthOptionsDivider"
import AuthSpaceHeader from "../components/AuthSpaceHeader"
import ExternalAuthMethods from "../components/ExternalAuthMethods"
import { useAuthMethods } from "../utils/authMethods"
import SignInForm, { SignInFormProps } from "./SignInForm"

export type SignInViewProps = Omit<
  SignInFormProps,
  "inviteToken" | "allowSignup"
>

// List the various auth methods for signing in. If only the "pathwright"
// auth method exists, simply show the AuthRequestView.
const SignInView = (props: SignInViewProps) => {
  const { inviteToken, userPropertyDefaults, allowSignup } = useAuthContext()
  const { pathwrightAuthMethod, externalAuthMethods } = useAuthMethods()

  return (
    <AuthMotion>
      <VStack spacing={4} w="100%">
        <AuthSpaceHeader />
        {!!pathwrightAuthMethod && (
          <SignInForm
            {...props}
            inviteToken={inviteToken}
            userPropertyDefaults={userPropertyDefaults}
            allowSignup={allowSignup}
          />
        )}
        {!!pathwrightAuthMethod && !!externalAuthMethods.length && (
          <AuthOptionsDivider />
        )}
        {!!externalAuthMethods.length && (
          <ExternalAuthMethods authIntent="sign_in_or_sign_up" />
        )}
      </VStack>
    </AuthMotion>
  )
}

export default SignInView
