import BackgroundImage from "@pathwright/ui/src/components/background/BackgroundImage"
import useCountDown from "@pathwright/ui/src/components/hooks/useCountDown"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import {
  PRIMARY_GRAY,
  SECONDARY_WHITE,
  TERTIARY_GRAY
} from "@pathwright/ui/src/components/utils/colors"
import { media } from "@pathwright/ui/src/components/utils/styles"
import moment from "moment"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

const Container = styled.div`
  > * {
    z-index: 1001;
  }

  section {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    h3 {
      font-size: 28px;
      margin: 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      ${media.max.phone`font-size: 20px;`}
    }

    .Pathicon {
      margin-bottom: 10px;
    }
  }
`

const UnlocksOverlay = ({ starts, passwordLocked, overlay, inverted }) => {
  const { t, tc } = useTranslate()
  const startsForceUpdate = useCountDown(starts)
  const startsLocked = !!starts && moment(starts).isAfter()

  if (!startsLocked && !passwordLocked) return null

  const label = startsLocked ? (
    <React.Fragment>
      {tc("Unlocks {{date}}", { date: moment(starts).fromNow() })}
    </React.Fragment>
  ) : (
    t("Password locked")
  )

  if (overlay) {
    return (
      <Container>
        <BackgroundImage overlay={inverted ? TERTIARY_GRAY : SECONDARY_WHITE} />
        <section>
          <h3
            style={{
              color: inverted ? "white" : PRIMARY_GRAY
            }}
            inverted={inverted}
          >
            <Pathicon icon="lock" />
            {label}
          </h3>
        </section>
      </Container>
    )
  }

  return label
}

UnlocksOverlay.displayName = "UnlocksOverlay"

UnlocksOverlay.propTypes = {
  starts: PropTypes.string,
  passwordLocked: PropTypes.bool,
  inverted: PropTypes.bool,
  overlay: PropTypes.bool
}

UnlocksOverlay.defaultProps = {
  passwordLocked: false,
  inverted: false,
  overlay: false
}

export default UnlocksOverlay
