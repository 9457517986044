import { COHORT_STATUS_COMPLETED } from "../group/constants"
import {
  INVITATION_TYPE_PERMISSION_MAP,
  ROLES,
  ROLE_DESCRIPTORS,
  ROLE_ICONS,
  ROLE_LABELS,
  ROLE_LABELS_PLURAL,
  ROLE_TYPE_OFFERING
} from "./constants"

export const getRoleOptions = (type, filter, { t, tca }) => {
  const withLabelAndDescriptor = (role) => {
    return {
      value: role,
      icon: ROLE_ICONS[type]?.[role],
      label: tca(ROLE_LABELS[type][role]),
      label_plural: tca(ROLE_LABELS_PLURAL[type][role]),
      descriptor: t(ROLE_DESCRIPTORS[type][role]),
      roleType: role
    }
  }

  let roles = ROLES[type]

  if (filter) {
    roles = roles.filter((role) => filter(type, role))
  }

  return roles.map(withLabelAndDescriptor)
}

export const filterAvailableRoles = (type, role, group) => {
  switch (type) {
    case ROLE_TYPE_OFFERING:
      return group
        ? !!(
            group.status !== COHORT_STATUS_COMPLETED &&
            group.permissions &&
            group.permissions[INVITATION_TYPE_PERMISSION_MAP[type][role]]
          )
        : true // if no group, simple allow all role options
    default:
      return true
  }
}
