import partial from "lodash/partial"
import { redirect } from "react-router-dom"
import { useAsyncFn } from "react-use"
import { z } from "zod"
import { zfd } from "zod-form-data"
import { getClient } from "../../../../api/client"
import {
  SignUpDocument,
  SignUpMutation,
  SignUpMutationVariables
} from "../../../../api/generated"
import { handleAuthToken } from "../../../../lib/utils/auth-token"
import { passwordSchema } from "../../password/change/api/action"
import getAuthRoute from "../../utils/getAuthRoute"
import { setPreferredAuthMethod } from "../../utils/preferredAuthMethod"

export const schema = z.object({
  firstName: z.string().min(2),
  lastName: z.string().min(2),
  email: z.string().email(),
  consent: z.boolean().optional(),
  inviteToken: z.string().optional(),
  createMembership: z.boolean().optional(),
  // Only when a Space requires for the user to be prompted to enter a password
  // will we expect the passwoord fields.
  password: passwordSchema.optional()
})

export const formDataSchema = zfd.formData(
  z.object({
    firstName: zfd.text(schema.shape.firstName),
    lastName: zfd.text(schema.shape.lastName),
    email: zfd.text(schema.shape.email),
    consent: zfd.checkbox({ trueValue: "true" }),
    inviteToken: zfd.text(schema.shape.inviteToken),
    createMembership: zfd.text().transform((val) => val === "yes"),
    // Only when a Space requires for the user to be prompted to enter a password
    // will we expect the passwoord fields.
    password: zfd.text(passwordSchema).optional()
  })
)

export type SchemaType = z.infer<typeof schema>

export const signUp = async (
  formData: FormData
): Promise<{ success: true }> => {
  const client = getClient()
  let result = await client.mutate<SignUpMutation, SignUpMutationVariables>({
    mutation: SignUpDocument,
    variables: formDataSchema.parse(formData)
  })

  const token = result.data?.signUp?.token

  if (!token) {
    throw new Error("Failed to create new account.")
  }

  await handleAuthToken(token)
  setPreferredAuthMethod("request")

  return { success: true }
}

const signUpAction = async (formData: FormData): Promise<Response> => {
  await signUp(formData)
  return redirect(getAuthRoute("/"))
}

signUpAction.schema = formDataSchema

export const useSignUp = partial(useAsyncFn<typeof signUp>, signUp)

export default signUpAction
