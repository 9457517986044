// SignupFlow steps
export const AUTH = 1
export const SCHOOL = 2
export const PROCESSING = 3
export const SURVEY = 4

export const SCHOOL_TYPE_TRAINING = "training"
export const SCHOOL_TYPE_COACH_TUTOR = "coach_tutor"
export const SCHOOL_TYPE_K12 = "k12"
export const SCHOOL_TYPE_CUSTOMER_TRAINING = "customer_training"
export const SCHOOL_TYPE_PROFESSIONAL_DEVELOPMENT = "professional_development"
export const SCHOOL_TYPE_HIGHER_ED = "higher_ed"
export const SCHOOL_TYPE_OTHER = "other"
export const SCHOOL_TYPE_MINISTRY = "ministry"
export const SCHOOL_TYPE_CURRICULUM_PUBLISHER = "curriculum_publisher"
// export const SCHOOL_TYPE_UNSPECIFIED = "unspecified"
// export const SCHOOL_TYPE_NON_PROFIT = "non_profit"

export const SCHOOL_TYPE_LABELS = {
  [SCHOOL_TYPE_TRAINING]: "Team training",
  [SCHOOL_TYPE_COACH_TUTOR]: "Coaching / tutoring",
  [SCHOOL_TYPE_K12]: "K–12 classroom",
  [SCHOOL_TYPE_CUSTOMER_TRAINING]: "Customer training",
  [SCHOOL_TYPE_PROFESSIONAL_DEVELOPMENT]: "Professional development",
  [SCHOOL_TYPE_HIGHER_ED]: "Higher education",
  [SCHOOL_TYPE_OTHER]: "Other",
  [SCHOOL_TYPE_MINISTRY]: "Ministry or church",
  [SCHOOL_TYPE_CURRICULUM_PUBLISHER]: "Curriculum publisher"
}

export const SCHOOL_TYPE_ICONS = {
  [SCHOOL_TYPE_TRAINING]: "connections",
  [SCHOOL_TYPE_COACH_TUTOR]: "whistle",
  [SCHOOL_TYPE_K12]: "apple",
  [SCHOOL_TYPE_CUSTOMER_TRAINING]: "window-smile",
  [SCHOOL_TYPE_PROFESSIONAL_DEVELOPMENT]: "certificate",
  [SCHOOL_TYPE_HIGHER_ED]: "grad-cap",
  [SCHOOL_TYPE_OTHER]: "shapes",
  [SCHOOL_TYPE_MINISTRY]: "church",
  [SCHOOL_TYPE_CURRICULUM_PUBLISHER]: "files"
}

export const SCHOOL_TYPE_REFERRER = {
  "online-schools": SCHOOL_TYPE_HIGHER_ED, // not sure which to go with
  "team-trainers": SCHOOL_TYPE_TRAINING,
  experts: SCHOOL_TYPE_COACH_TUTOR,
  "live-classes": null,
  ministry: SCHOOL_TYPE_MINISTRY
}

export const BELLTOWER_TYPE_CHURCH = "belltower_church"
export const BELLTOWER_TYPE_PLANT = "belltower_church_plant"
export const BELLTOWER_TYPE_CAMPUS = "belltower_church_campus"
export const BELLTOWER_TYPE_HOMESCHOOL = "belltower_homeschool"
export const BELLTOWER_TYPE_PRIVATE = "belltower_private_school"
export const BELLTOWER_TYPE_STUDY = "belltower_study_group"

export const BELLTOWER_TYPE_LABELS = {
  [BELLTOWER_TYPE_CHURCH]: "Church",
  [BELLTOWER_TYPE_PLANT]: "Church Plant",
  [BELLTOWER_TYPE_CAMPUS]: "Multi Campus Church",
  [BELLTOWER_TYPE_HOMESCHOOL]: "Homeschool",
  [BELLTOWER_TYPE_PRIVATE]: "Private School",
  [BELLTOWER_TYPE_STUDY]: "Informal Study Group"
}
