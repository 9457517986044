import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import SelectButton from "@pathwright/ui/src/components/button/SelectButton"
import Card from "@pathwright/ui/src/components/card/Card"
import copyToClipboard from "@pathwright/ui/src/components/copy/copyToClipboard"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import PropTypes from "prop-types"
import {
  HELP_LINK_ZAPIER_HOW_TO,
  HELP_LINK_ZAPIER_PW_INTEGRATION,
  HELP_LINK_ZAPIER_SETUP
} from "../../help/constants"
import FeatureLock from "../../lib/FeatureLock"

const ManageZapier = ({ card, secret_key }) => {
  return (
    <Card card={card} title="Zapier">
      <FeatureLock has="essential-app-integrations">
        {({
          hasFeatures,
          activationLocked,
          lockedFeatures,
          getPlansNameList
        }) => {
          return !hasFeatures ? (
            <BlankSlate
              icon="sparkles"
              heading="Sync data with other software"
              body={
                <>
                  Connect a zapier account with your Pathwright school to enable
                  hundreds of integrations with other apps.
                  <br />
                  <a href={HELP_LINK_ZAPIER_SETUP} target="_blank">
                    Learn more about connecting Zapier.
                  </a>
                </>
              }
              meta={`Included in the ${getPlansNameList(
                lockedFeatures["essential-app-integrations"]
              )}`}
              primaryAction={{
                children: activationLocked ? "Activate" : "Upgrade Plan",
                to: "/pricing/"
              }}
            />
          ) : (
            <BlankSlate
              icon="box-inset"
              heading="Sync data with other software"
              body={
                <>
                  Zapier is an easy-to-use, inexpensive, and powerful tool for
                  syncing your Pathwright data with other software and creating
                  dynamic workflows.
                  <br />
                  <br />
                  To get started, copy your private API key:
                  <br />
                  <br />
                  <SelectButton
                    brand
                    label={secret_key}
                    action={<Pathicon icon="copy" />}
                    options={[
                      {
                        selected: true,
                        action: () =>
                          Promise.resolve(copyToClipboard(secret_key))
                      }
                    ]}
                  />
                </>
              }
              stack
              primaryAction={{
                size: "large",
                children: "Visit Zapier.com to Get Started",
                href: HELP_LINK_ZAPIER_PW_INTEGRATION,
                target: "_blank"
              }}
              secondaryAction={{
                pre: "Or",
                children: "learn more about integrating with Zapier",
                href: HELP_LINK_ZAPIER_HOW_TO,
                target: "_blank"
              }}
            />
          )
        }}
      </FeatureLock>
    </Card>
  )
}

ManageZapier.displayName = "ManageZapier"

ManageZapier.propTypes = {
  // provided by CardStack > CardStackCard
  card: PropTypes.object.isRequired
}

export default ManageZapier
