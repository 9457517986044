import { Heading, Text, VStack } from "@chakra-ui/react"
import { LockIcon } from "@pathwright/pathicons"

const Gate = ({ title, description, children }) => {
  return (
    <VStack p={8} color="gray.500">
      <LockIcon />
      <Heading as="h2" size="lg" textAlign="center">
        {title}
      </Heading>
      <Text color="gray.500" textAlign="center">
        {description}
      </Text>
      {children}
    </VStack>
  )
}

export default Gate
