import Card from "@pathwright/ui/src/components/card/Card"
import CardLink from "@pathwright/ui/src/components/card/CardLink"
import { Component } from "react"

class PathwrightAdminCard extends Component {
  render() {
    return (
      <Card card={this.props.card} title="Pathwright Superuser">
        <div>
          <CardLink
            icon="tag"
            label="Generate Coupon Codes"
            meta="Create coupon codes for one or more resources."
            to={`${this.props.card.path}generate-coupon-codes/`}
          />
          <CardLink
            icon="tag"
            label="Generate Coupon Codes for Subscription Plans"
            meta="Create coupon codes for one or more school subscription plans."
            to={`${this.props.card.path}generate-coupon-codes-for-subscription-plans/`}
          />
        </div>
      </Card>
    )
  }
}

PathwrightAdminCard.displayName = "PathwrightAdminCard"

export default PathwrightAdminCard
