import classnames from "classnames"
import styled from "styled-components"
import TagManagerContainer from "../../tag/TagManagerContainer"
import { tagAttachmentContextPropType } from "../../tag/propTypes"
import DiscussionTagFeatureBlock from "./DiscussionTagFeatureBlock"

const Container = styled.div`
  .FeatureBlock {
    margin-bottom: 1em;
  }

  .TagForm {
    background-color: white;
    margin: 10px 0;
    border-radius: 20px;
  }
`

const DiscussionTagManager = ({ className, context, ...tagManagerProps }) => (
  <Container className={classnames("DiscussionTagManager", className)}>
    <DiscussionTagFeatureBlock context={context} />
    <TagManagerContainer context={context} {...tagManagerProps} />
  </Container>
)

DiscussionTagManager.displayName = "DiscussionTagManager"

DiscussionTagManager.propTypes = {
  context: tagAttachmentContextPropType
}

DiscussionTagManager.defaultProps = {}

export default DiscussionTagManager
