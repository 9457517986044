import { useMemo } from "react"
import { AuthMethodFragment } from "../../../api/generated"
import { useAuthContext } from "../api/context"

// type PathwrightAuthMethod = AuthMethodFragment & { meta: {type: "pathwright"}}
// // NOTE: would prefer to exclude PathwrightAuthMethod from AuthMethodFragment to
// // create ExternalAuthMethod, but not sure how, or if possible.
// type ExternalAuthMethod = AuthMethodFragment & { meta: {type: "saml2" | "openid"}}
// type AuthMethods = (PathwrightAuthMethod | ExternalAuthMethod)[]

export const getPathwrightAuthMethod = function (
  authMethods: AuthMethodFragment[]
): AuthMethodFragment | undefined {
  return authMethods.find(
    (authMethod) => authMethod.enabled && authMethod.meta.type === "pathwright"
  )
}

export const getExternalAuthMethods = function (
  authMethods: AuthMethodFragment[]
): AuthMethodFragment[] {
  return authMethods.filter(
    (authMethod) => authMethod.enabled && authMethod.meta.type !== "pathwright"
  )
}

export const useAuthMethods = (): {
  authMethods: AuthMethodFragment[]
  pathwrightAuthMethod: ReturnType<typeof getPathwrightAuthMethod>
  externalAuthMethods: ReturnType<typeof getExternalAuthMethods>
} => {
  const { authMethods } = useAuthContext()
  const pathwrightAuthMethod = useMemo(
    () => getPathwrightAuthMethod(authMethods),
    [authMethods]
  )
  const externalAuthMethods = useMemo(
    () => getExternalAuthMethods(authMethods),
    [authMethods]
  )

  return {
    authMethods,
    pathwrightAuthMethod,
    externalAuthMethods
  }
}
