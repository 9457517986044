import { Box } from "@chakra-ui/react"
import Alert from "@pathwright/ui/src/components/alert/Alert"
import ThemeBackground from "@pathwright/ui/src/components/background/ThemeBackground"
import Card from "@pathwright/ui/src/components/card/Card"
import { FullscreenConfirmProvider } from "@pathwright/ui/src/components/confirm/FullscreenConfirm"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import Tab from "@pathwright/ui/src/components/tab/Tab"
import Text from "@pathwright/ui/src/components/ui/Text"
import get from "lodash/get"
import { useEffect, useState } from "react"
import styled from "styled-components"
import { usePathwrightContext } from "../../../pathwright/PathwrightContext"
import { usePathwrightContextSchoolMissingError } from "../../../pathwright/PathwrightContextSchoolMissingError"
import getStripePaymentPortalHref from "../../../stripe/utils/getStripePaymentPortalHref"
import MentorGroups from "../../groups/list/MentorGroups"
import MentorGroupHeader from "./MentorGroupHeader"
import MentorGroupListContextProvider, {
  useMentorGroupListContext
} from "./MentorGroupListContext"
import MentorGroupMemberList from "./MentorGroupMemberList"
import MentorGroupShareBackgroundTaskContextProvider from "./MentorGroupShareBackgroundTaskContextProvider"

const StyledCard = styled(Card)`
  .Card__Nav {
    border-radius: 10px 10px 0 0;
  }
  .Card__Heading {
    margin: 0 150px;
  }

  .MentorGroupMembersCard__members,
  .MentorGroupMembersCard__groups {
    display: none;
  }

  ${(p) => `.MentorGroupMembersCard__${p.tab} { 
    display: block
  }`}
`

const StyledTabs = styled.div`
  margin-top: 10;
  margin-bottom: -15;
  display: flex;
  justify-content: center;
`

const MentorGroupMembersCard = ({ card }) => {
  const { mentorGroup, groupSubscription, permissions } =
    useMentorGroupListContext()
  const [tab, setTab] = useState("members")
  const [tabHistory, setTabHistory] = useState(new Set([tab]))
  const { t, tca, Trans } = useTranslate()
  const { school } = usePathwrightContext()
  const { usePotentialError } = usePathwrightContextSchoolMissingError()

  // If after a mutation the user no longer has permission to query the
  // mentor group, we will run into the glitch where the school will no
  // longer exist in pw context. This is the error we will show.
  usePotentialError(
    mentorGroup
      ? {
          title: "Access removed.",
          details: `You no longer have staff access to "${mentorGroup.name}".`
        }
      : {
          title: "No access.",
          details: "You do not have staff access to this group."
        },
    [mentorGroup?.name]
  )

  useEffect(() => {
    setTabHistory(new Set([...tabHistory, tab]))
  }, [tab])

  const tPrefix = "mentor_group.members.card"
  const hasGroupSubscription = get(mentorGroup, "type") === 15

  return (
    <MentorGroupShareBackgroundTaskContextProvider
      mentorGroupId={mentorGroup?.id}
    >
      <StyledCard card={card} title={school.name} tab={tab}>
        {mentorGroup ? (
          <>
            <Box pos="relative">
              <ThemeBackground />
              <MentorGroupHeader
                permissions={permissions}
                mentorGroup={mentorGroup}
                groupSubscription={groupSubscription}
              />
              {(get(permissions, "can_add_group") ||
                get(mentorGroup, "has_child_groups")) && (
                <StyledTabs>
                  <Tab
                    active={tab === "members"}
                    onClick={() => setTab("members")}
                    inverted
                  >
                    {t(`${tPrefix}.members`)}
                  </Tab>
                  <Tab
                    active={tab === "groups"}
                    onClick={() => setTab("groups")}
                    inverted
                  >
                    {tca("mentor_group.child_group_label_plural")}
                  </Tab>
                </StyledTabs>
              )}
            </Box>
            {groupSubscription &&
            mentorGroup &&
            mentorGroup.seats_available < 0 ? (
              <Alert error style={{ maxWidth: "100%", margin: 10 }}>
                <Text>
                  <Text.H5>
                    {t("Your subscription to {{ product }} is suspended.", {
                      product: groupSubscription.price.product.name
                    })}
                  </Text.H5>
                  <Text.Body>
                    {t(
                      "Your subscription only includes {{ seatsCount }} seat but there are {{ membersCount }} members.",
                      {
                        // NOTE: assuming mentorGroup.seats_filled is always > 1
                        defaultValue_plural:
                          "Your subscription only includes {{ seatsCount }} seats but there are {{ membersCount }} members.",
                        count: mentorGroup.seat_limit,
                        seatsCount: mentorGroup.seat_limit,
                        membersCount: mentorGroup.seats_filled
                      }
                    )}{" "}
                    <Trans
                      i18nKey={
                        "To restore access, <a>purchase more seats</a>, or remove the extra members."
                      }
                      components={{
                        a: (
                          <a
                            href={getStripePaymentPortalHref()}
                            style={{ textDecoration: "underline" }}
                          />
                        )
                      }}
                    />
                  </Text.Body>
                </Text>
              </Alert>
            ) : null}
            {/* TODO: use Tabs component from s2-inbox */}
            {(tab === "members" || tabHistory.has("members")) && (
              <div className="MentorGroupMembersCard__members">
                <MentorGroupMemberList />
              </div>
            )}
            {(tab === "groups" || tabHistory.has("groups")) && (
              <div className="MentorGroupMembersCard__groups">
                <MentorGroups parentId={mentorGroup.id} />
              </div>
            )}
          </>
        ) : (
          <LoadingCircle />
        )}
      </StyledCard>
    </MentorGroupShareBackgroundTaskContextProvider>
  )
}

MentorGroupMembersCard.displayName = "MentorGroupMembersCard"

const MentorGroupMembersCardContainer = ({ groupId, ...rest }) => {
  return (
    <MentorGroupListContextProvider groupId={groupId}>
      <FullscreenConfirmProvider>
        <MentorGroupMembersCard {...rest} />
      </FullscreenConfirmProvider>
    </MentorGroupListContextProvider>
  )
}

export default MentorGroupMembersCardContainer
