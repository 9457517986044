import gql from "graphql-tag"

export default gql`
  fragment Group on Group {
    id
    name
    created
    description
    price
    seats
    seats_filled
    seats_available
    registration_ends
    registration_starts
    registration_started
    registration_ended
    access_ends
    access_starts
    is_master
    is_source_cohort
    source_cohort_id
    parent_section_id
    visibility
    is_collection_linked
    is_subscription_only
    status
    analytics_disabled
  }
`
