import { useShareUIContext } from "../shared/ShareUIContextProvider"
import CohortShareGate from "./CohortShareGate"
import GroupShareGate from "./GroupShareGate"
import useGatedShareAccessKey from "./useGatedShareAccessKey"

const gates = {
  cohort: CohortShareGate,
  group: GroupShareGate
  // TODO: "group" and "school" gates.
}

const ShareGate = () => {
  const { context } = useShareUIContext()
  const Gate = gates[context.type]
  return useGatedShareAccessKey() && Gate ? <Gate /> : null
}

export default ShareGate
