import useSpaceHasFeature from "@pathwright/web/src/modules/lib/useSpaceHasFeature"
import AdaptedAuthContainer from "../auth/AdaptedAuthContainer"
import SignUpOrIn from "../auth/SignUpOrIn"
import { SIGN_UP } from "../auth/constants"

const SignupAccountForm = ({
  renderNav,
  renderHeader,
  renderSubmit,
  inverted,
  onNext
}) => {
  const hasAuthPw3 = useSpaceHasFeature("auth__platform")
  return hasAuthPw3 ? (
    <AdaptedAuthContainer
      onComplete={onNext}
      authWrapperProps={{ bg: "background", maxW: "500px", m: "auto" }}
      createMembership={false}
    />
  ) : (
    <SignUpOrIn
      inverted={inverted}
      initialView={SIGN_UP}
      renderHeader={renderHeader}
      renderSubmit={renderSubmit}
      onContinue={onNext}
    />
  )
}

SignupAccountForm.displayName = "SignupAccountForm"

export default SignupAccountForm
