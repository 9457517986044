import { i18n } from "@pathwright/ui/src/components/lng/I18nextProvider"

export default {
  discussionFilters: {
    get label() {
      return i18n.t("discussion.filters.show")
    },
    options: [
      {
        default: true,
        get label() {
          return i18n.t("discussion.filters.best")
        },
        filters: {
          ordering: "-is_discussion_question,-num_votes,-num_responses"
        }
      },
      {
        get label() {
          return i18n.t("discussion.filters.active")
        },
        filters: {
          ordering: "active"
        }
      },
      {
        get label() {
          return i18n.t("discussion.filters.recent")
        },
        filters: {
          ordering: "-created_time"
        }
      },
      {
        get label() {
          return i18n.t("discussion.filters.oldest")
        },
        filters: {
          ordering: "created_time"
        }
      },
      {
        get label() {
          return i18n.t("discussion.filters.unanswered")
        },
        filters: {
          num_responses: 0
        }
      }
    ]
  },
  responseFilters: {
    get label() {
      return i18n.t("discussion.filters.show")
    },
    options: [
      {
        get label() {
          return i18n.t("discussion.filters.best")
        },
        filters: {
          ordering: "best"
        }
      },
      {
        get label() {
          return i18n.t("discussion.filters.active")
        },
        filters: {
          ordering: "active"
        }
      },
      {
        get label() {
          return i18n.t("discussion.filters.recent")
        },
        filters: {
          ordering: "newest"
        }
      },
      {
        get label() {
          return i18n.t("discussion.filters.oldest")
        },
        filters: {
          ordering: "oldest"
        }
      }
    ]
  }
}
