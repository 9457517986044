import classnames from "classnames"
import PropTypes from "prop-types"
import { useUIThemeContext } from "../ui/PathwrightUI"
import "./ProgressBar.css"

const ProgressBar = ({
  progress,
  height,
  inverted,
  fillColor,
  backColor,
  className,
  style
}) => {
  const theme = useUIThemeContext()
  const backStyle = {
    ...style,
    height,
    backgroundColor: inverted
      ? "rgba(255,255,255,0.1)"
      : backColor || "rgba(0,0,0,0.05)"
  }
  const fillStyle = {
    width: `${Math.floor(progress * 100)}%`,
    backgroundColor: inverted
      ? "rgba(255,255,255,0.8)"
      : fillColor || theme.primaryBrandColor
  }

  return (
    <div className={classnames("ProgressBar", className)} style={backStyle}>
      {progress > 0 && <div className="ProgressBar__fill" style={fillStyle} />}
    </div>
  )
}

ProgressBar.displayName = "ProgressBar"

ProgressBar.propTypes = {
  progress: PropTypes.number, // between 0 and 1
  backColor: PropTypes.string,
  fillColor: PropTypes.string
}

ProgressBar.defaultProps = {
  progress: 0,
  height: "3px"
}

export default ProgressBar
