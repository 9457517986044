import CardBlock from "@pathwright/ui/src/components/card/CardBlock"
import DatePicker from "@pathwright/ui/src/components/form/form-date-picker/DatePicker"
import TextInput from "@pathwright/ui/src/components/form/form-text-input/TextInput"
import ToggleInput from "@pathwright/ui/src/components/form/form-toggle/ToggleInput"
import {
  useInitialValues,
  validate
} from "@pathwright/ui/src/components/form/utils"
import Link from "@pathwright/ui/src/components/link/Link"
import Text from "@pathwright/ui/src/components/ui/Text"
import { Formik } from "formik"
import moment from "moment"
import PropTypes from "prop-types"
import styled from "styled-components"

const StyledDatePicker = styled(DatePicker)`
  .rdtPicker {
    bottom: 100%;
  }
`

const ManageDiscountUsageForm = ({ setValues, showSeatsLimit }) => (
  <Formik
    initialValues={useInitialValues(ManageDiscountUsageForm.initialValues)}
    validate={(values) => {
      setValues(values)
      // return the errors
      return ManageDiscountUsageForm.validate(values)
    }}
  >
    {({
      values,
      touched,
      errors,
      handleChange,
      handleBlur,
      setValues,
      setFieldTouched
    }) => (
      <CardBlock paddingTop={false} paddingBottom={false}>
        <CardBlock paddingLeft={false} paddingRight={false}>
          <Text.H5>Code usage settings</Text.H5>
        </CardBlock>
        <CardBlock borderTop={false} paddingLeft={false} paddingRight={false}>
          <ToggleInput
            name="isOnePerUser"
            alignRight
            label="One use per person"
            labelWidth={225}
            helperText="Each person may only use a code once."
            value={values.isOnePerUser}
            errors={
              errors.isOnePerUser && touched.isOnePerUser
                ? [errors.isOnePerUser]
                : null
            }
            onChange={(value, e) => handleChange(e)}
          />
        </CardBlock>
        <CardBlock paddingLeft={false} paddingRight={false}>
          <ToggleInput
            name="limitTotalUses"
            alignRight
            label="Limit total uses"
            labelWidth={225}
            helperText="Toggle on to set a maximum number of code uses."
            value={values.limitTotalUses}
            errors={
              errors.limitTotalUses && touched.limitTotalUses
                ? [errors.limitTotalUses]
                : null
            }
            onChange={(value, e) => handleChange(e)}
          />
          {values.limitTotalUses && (
            <TextInput
              name="maxRedemptions"
              label="Max uses"
              step="1"
              type="number"
              helperText="A code will expire for everyone after a total number of uses."
              autoSize
              minWidth={50}
              required
              value={values.maxRedemptions}
              errors={
                errors.maxRedemptions && touched.maxRedemptions
                  ? [errors.maxRedemptions]
                  : null
              }
              onChange={(value, e) => handleChange(e)}
              onBlur={(value, e) => handleBlur(e)}
            />
          )}
        </CardBlock>
        {showSeatsLimit && (
          <CardBlock paddingLeft={false} paddingRight={false}>
            <TextInput
              name="maxSeats"
              label="Seat limit"
              step="1"
              type="number"
              helperText={
                <>
                  Optionally limit how many seats are discounted{" "}
                  <Link href="https://intercom.help/pathwright/en/articles/430587-member-created-cohorts-allow-anyone-to-lead-a-private-group">
                    when purchasing for a group
                  </Link>
                  .
                </>
              }
              autoSize
              minWidth={50}
              required
              value={values.maxSeats}
              errors={
                errors.maxSeats && touched.maxSeats ? [errors.maxSeats] : null
              }
              onChange={(value, e) => handleChange(e)}
              onBlur={(value, e) => handleBlur(e)}
            />
          </CardBlock>
        )}
        <CardBlock paddingLeft={false} paddingRight={false}>
          <StyledDatePicker
            name="expiryDate"
            label="Expires"
            value={values.expiryDate}
            helperText="Optionally set a day & time after which the code can no longer be used."
            errors={
              errors.expiryDate && touched.expiryDate
                ? [errors.expiryDate]
                : null
            }
            // no event to handle, so must explicitly set the next value
            onChange={(value) => setValues({ ...values, expiryDate: value })}
            onBlur={() => setFieldTouched("expiryDate")}
          />
        </CardBlock>
      </CardBlock>
    )}
  </Formik>
)

ManageDiscountUsageForm.displayName = "ManageDiscountUsageForm"

ManageDiscountUsageForm.propTypes = {
  showSeatsLimit: PropTypes.bool
}

ManageDiscountUsageForm.defaultProps = {
  showSeatsLimit: false
}

ManageDiscountUsageForm.initialValues = {
  isOnePerUser: true,
  limitTotalUses: false,
  maxRedemptions: null,
  maxSeats: null,
  expiryDate: null
}

ManageDiscountUsageForm.validate = validate((key, value, values) => {
  switch (key) {
    case "maxRedemptions":
      if (!values.limitTotalUses) break
      if (
        parseInt(value) < 0 ||
        parseInt(value) > 1000000 ||
        parseFloat(value) !== parseInt(value)
      )
        return "Please enter maximum uses between 0 and 1,000,000"
      break
    case "maxSeats":
      if (value === null) break
      if (
        parseInt(value) < 0 ||
        parseInt(value) > 1000000 ||
        parseFloat(value) !== parseInt(value)
      )
        return "Please enter maximum number of seats between 0 and 1,000,000"
      break
    case "expiryDate":
      if (value && moment(value).isBefore())
        return "Please select a future date."
      break
  }
})

export default ManageDiscountUsageForm
