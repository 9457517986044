import classnames from "classnames"
import React from "react"
import LazyQuillEditor from "../../quill/LazyQuillEditor"
import FieldWrapper from "../form-utils/FieldWrapper"

const RichTextInput = React.forwardRef(function RichTextInput(props, ref) {
  return (
    <FieldWrapper
      {...props}
      className={classnames("RichTextInput", props.className)}
    >
      {({ value, ...passProps }) => (
        <LazyQuillEditor
          {...passProps}
          html={value}
          ref={ref}
          bounds=".RichTextInput"
        />
      )}
    </FieldWrapper>
  )
})

RichTextInput.displayName = "RichTextInput"

export default RichTextInput
