import { Box } from "@chakra-ui/react"
import { useShareUIContext } from "../shared/ShareUIContextProvider"
import CohortMeta from "./CohortMeta"
import GroupMeta from "./GroupMeta"

const ShareMetaSwitch = () => {
  const { context } = useShareUIContext()
  switch (context.type) {
    case "cohort":
      return <CohortMeta />
    case "group":
      return <GroupMeta />
    default:
      return null
  }
}

const ShareMeta = () => {
  return (
    <Box
      position="absolute"
      right="0"
      top="0"
      bottom="0"
      display="flex"
      alignItems="center"
      justifyContent="center"
      px="var(--card-padding)"
    >
      <ShareMetaSwitch />
    </Box>
  )
}

export default ShareMeta
