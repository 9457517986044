import classnames from "classnames"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import Stack from "../stack/Stack"
import ChakraTooltip from "../tooltip/ChakraTooltip"
import { sizePropType } from "../utils/props"
import Avatar from "./Avatar"

const StyledStack = styled(Stack)`
  .ChakraTooltipWrapper {
    display: inline-flex !important;
  }
`

const AvatarStack = React.forwardRef(function AvatarStack(
  { users, size, limit, itemOffset, className, reverse, getTooltip },
  ref
) {
  const avatars = users.slice(0, limit)

  return avatars.length ? (
    <StyledStack
      ref={ref}
      className={classnames("AvatarStack", className)}
      items={avatars}
      itemWidth={size}
      itemOffset={itemOffset}
      reverse={reverse}
    >
      {({ item, itemWidth }) => {
        const avatarComponent = <Avatar user={item} size={itemWidth} />
        if (getTooltip) {
          return (
            <ChakraTooltip title={getTooltip(item)}>
              {avatarComponent}
            </ChakraTooltip>
          )
        } else {
          return avatarComponent
        }
      }}
    </StyledStack>
  ) : null
})

AvatarStack.displayName = "AvatarStack"

AvatarStack.propTypes = {
  users: PropTypes.array,
  limit: PropTypes.number,
  size: sizePropType,
  itemOffset: sizePropType,
  getTooltip: PropTypes.func,
  ...Avatar.propTypes
}

AvatarStack.defaultProps = {
  users: [],
  ...Avatar.defaultProps
}

export default AvatarStack
