export const COMPLETION_FILTERS = {
  pending: {
    key: "pending",
    label: "Pending review",
    variables: {
      needs_review: true,
      reviewable: true,
      order_by: ["completion_date"]
    }
  },
  reviewed: {
    key: "reviewed",
    label: "Reviewed",
    variables: {
      is_reviewed: true,
      order_by: ["checked_date"]
    }
  },
  complete: {
    key: "complete",
    label: "Complete",
    variables: {
      is_complete: true,
      order_by: ["completion_date"]
    }
  },
  incomplete: {
    key: "incomplete",
    label: "Incomplete",
    variables: {
      is_complete: false,
      order_by: ["due", "id"]
    }
  },
  all: {
    key: "all",
    label: "All",
    variables: {
      order_by: ["id"]
    }
  }
}

export const getFiltersList = () => {
  return Object.values(COMPLETION_FILTERS)
}

export const getFilterVariables = (key) => {
  if (!COMPLETION_FILTERS[key]) {
    return {}
  }
  return { ...COMPLETION_FILTERS[key].variables }
}
