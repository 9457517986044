import Avatar from "@pathwright/ui/src/components/avatar/Avatar"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import Text from "@pathwright/ui/src/components/ui/Text"
import View from "@pathwright/ui/src/components/ui/View"
import capitalize from "lodash/capitalize"
import styled from "styled-components"
import SelectRole from "../invitation/SelectRole"
import { ROLE_TYPE_OFFERING } from "../invitation/constants"
import { REGISTRATION_ROLES } from "../registration/constants"
import { useAssignRoles } from "./hooks"

const StyledView = styled(View)`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-auto-rows: minmax(30px, auto);
  grid-gap: 5px 15px;
  align-items: center;
  padding: 0px 20px 20px;
`

const StyledMeta = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2px;

  .Avatar,
  .Pathicon {
    margin-right: 5px;
  }
`

const AssignHeader = ({
  user,
  communityGroup,
  role,
  selectRole,
  getPrompt
}) => {
  const { t } = useTranslate()
  const { assignRoles } = useAssignRoles()

  return (
    <StyledView>
      <Text.Body>{t("Add to:")}</Text.Body>
      <StyledMeta>
        {user ? (
          <StyledMeta>
            <Avatar user={user} size="20px" /> {user.full_name}
          </StyledMeta>
        ) : communityGroup ? (
          <StyledMeta>
            <Pathicon icon="group" /> {communityGroup.name}
          </StyledMeta>
        ) : null}
      </StyledMeta>
      <Text.Body>{t("Add as:")}</Text.Body>
      <SelectRole
        roleType={ROLE_TYPE_OFFERING}
        value={role}
        onChange={({ value }) => selectRole(value)}
        prompt={(role) => (
          <span>
            {capitalize(communityGroup ? role.label_plural : role.label)}
          </span>
        )}
        roleFilter={(_, role) =>
          assignRoles.includes(REGISTRATION_ROLES._intsStrsMap[role])
        }
      />
    </StyledView>
  )
}

AssignHeader.displayName = "AssignHeader"

export default AssignHeader
