// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
let LicenseOffering
export default LicenseOffering = class LicenseOffering extends (
  require("lib/static-shim").default(require("lib/utils/model").default)
) {
  defaults() {
    return { cost_per_seat: 0 }
  }

  url() {
    const { resource_id } = this.toJSON()
    let url = this.urlRoot(
      `/api/private/library/resource/${resource_id}/license-offering/`
    )
    if (this.id) {
      url = `${url}${this.id}/`
    }
    return url
  }

  parse(response) {
    if (response != null ? response[0] : undefined) {
      return response != null ? response[0] : undefined
    }
    return response
  }
}
