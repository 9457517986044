import ManageAccountAuth from "@pathwright/web/src/modules/account/manage/ManageAccountAuth"
import ManageAccountFeatures from "@pathwright/web/src/modules/account/manage/ManageAccountFeatures"
import ManageCohortFeatures from "@pathwright/web/src/modules/account/manage/ManageCohortFeatures"
import CertificateAddResourceCard from "@pathwright/web/src/modules/certificate/cards/CertificateAddResourceCard"
import CertificateCard from "@pathwright/web/src/modules/certificate/cards/CertificateCard"
import DiscussionTagManagerCard from "@pathwright/web/src/modules/discussion/topics/DiscussionTagManagerCard"
import ManageEnterpriseSSOFeature from "@pathwright/web/src/modules/feature/auth/ManageEnterpriseSSOFeature"
import ManagePathwrightSignInFeature from "@pathwright/web/src/modules/feature/auth/ManagePathwrightSignInFeature"
import {
  FEATURE_ACTION_AUTH_LOGIN,
  FEATURE_ACTION_VIEW,
  FEATURE_KEY_ACTIVITY_FEED,
  FEATURE_KEY_AUTH_LOGIN_OPTION_GLOBAL_SSO_APPLE,
  FEATURE_KEY_AUTH_LOGIN_OPTION_GLOBAL_SSO_GOOGLE,
  FEATURE_KEY_MEMBER_DIRECTORY
} from "@pathwright/web/src/modules/feature/constants"
import ManageFeature from "@pathwright/web/src/modules/feature/manage/ManageFeature"
import ManageFeaturePermissions from "@pathwright/web/src/modules/feature/manage/ManageFeaturePermissions"
import ManageFeatures from "@pathwright/web/src/modules/feature/manage/ManageFeatures"
import ManageFeaturesAuthLoginFeature from "@pathwright/web/src/modules/feature/manage/ManageFeaturesAuthLoginFeature"
import { featureParamToKey } from "@pathwright/web/src/modules/feature/utils"
import { z } from "zod"
import CommerceCardRoutes from "./commerce-routes"
import IntegrationCardRoutes from "./integration-routes"

const featureKeySchema = z.union([
  z
    .union([
      z.literal(FEATURE_KEY_MEMBER_DIRECTORY),
      z.literal(FEATURE_KEY_ACTIVITY_FEED)
    ])
    .transform(() => ({
      featureAction: FEATURE_ACTION_VIEW,
      FeatureComponent: ManageFeature,
      PermissionComponent: ManageFeaturePermissions
    })),
  z
    .union([
      z.literal(FEATURE_KEY_AUTH_LOGIN_OPTION_GLOBAL_SSO_GOOGLE),
      z.literal(FEATURE_KEY_AUTH_LOGIN_OPTION_GLOBAL_SSO_APPLE)
    ])
    .transform(() => ({
      featureAction: FEATURE_ACTION_AUTH_LOGIN,
      FeatureComponent: ManageFeaturesAuthLoginFeature,
      PermissionComponent: () => null
    })),
  z.any().transform(() => ({
    featureAction: FEATURE_ACTION_VIEW,
    FeatureComponent: ManageFeature,
    PermissionComponent: ManageFeaturePermissions
  }))
])

const ManageFeaturesContainer = ({ card }) => <ManageFeatures card={card} />

const ManageFeatureContainer = ({ card, match }) => {
  const featureKey = featureParamToKey(match.params.featureKey)
  const { featureAction, FeatureComponent } = featureKeySchema.parse(featureKey)
  return (
    <FeatureComponent
      card={card}
      featureKey={featureKey}
      featureAction={featureAction}
    />
  )
}

const ManageFeaturePermissionsContainer = ({ card, match }) => {
  const featureKey = featureParamToKey(match.params.featureKey)
  const { PermissionComponent } = featureKeySchema.parse(featureKey)

  return (
    <PermissionComponent
      card={card}
      featureKey={featureParamToKey(match.params.featureKey)}
    />
  )
}

const featureRoute = {
  [":featureKey"]: {
    component: ManageFeatureContainer,
    childCards: {
      settings: {
        component: ManageFeaturePermissionsContainer
      }
    }
  }
}

export const authFeatureRoutes = {
  // Pathwright Sign-in is not a feature governed by CFPs.
  ["pathwright-signin"]: {
    component: ManagePathwrightSignInFeature
  },
  // Enterprise SSO is not a feature governed by CFPs.
  ["custom-sso"]: {
    component: ManageEnterpriseSSOFeature
  },
  ...featureRoute
}

export const cohortFeatureRoutes = {
  // Discussion topics are not a feature governed by CFPs.
  ["discussion-topics"]: {
    component: DiscussionTagManagerCard
  },
  ...featureRoute
}

export default {
  features: {
    component: ManageAccountFeatures,
    childCards: {
      auth: {
        component: ManageAccountAuth,
        childCards: authFeatureRoutes
      },
      cohort: {
        component: ManageCohortFeatures,
        childCards: cohortFeatureRoutes
      },
      certificate: {
        component: CertificateCard,
        childCards: {
          add: {
            component: CertificateAddResourceCard
          }
        }
      },
      ...CommerceCardRoutes,
      ...IntegrationCardRoutes
    }
  }
}
