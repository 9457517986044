import { graphql, withApollo } from "@apollo/client/react/hoc"
import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import TextInput from "@pathwright/ui/src/components/form/form-text-input/TextInput"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import Tooltip from "@pathwright/ui/src/components/tooltip/Tooltip"
import Text from "@pathwright/ui/src/components/ui/Text"
import compose from "lodash/flowRight"
import { Component } from "react"
import { Field, Form, Submit, validators } from "react-connect-form-forked"
import styled from "styled-components"
import CREATE_SCHOOL_MUTATION from "./graphql/create-school-mutation"
import SUBDOMAIN_QUERY from "./graphql/subdomain-query"
const { isMinLength, isMaxLength, isAlphanumeric } = validators

const StyledContainer = styled.div`
  width: 100%;
`

const StyledForm = styled(Form)`
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
`

const StyledInput = styled(TextInput)`
  .FieldStyler__label {
    text-align: right;
  }

  align-items: baseline;
`

const StyledTooltip = styled(Tooltip)`
  .Tooltip {
    z-index: 100;
    min-width: 150px;
    max-width: 250px;
    padding: 5px 10px;
    line-height: 16px;
    white-space: normal;
    font-size: 12px !important;
  }
`

const StyledInfo = styled(Text.Body)`
  white-space: pre;
  display: flex;
  align-items: center;

  .Tooltip__trigger {
    display: flex !important;
  }
`

const StyledFooter = styled.footer`
  margin-top: 40px;
  text-align: center;
`

class SignupSchoolForm extends Component {
  handleSubmit = (form) => {
    let options = {
      ...this.props.utmParameters,
      copy: this.props.copy,
      name: form.name,
      subdomain: form.subdomain
    }
    if (this.props.licensorId) {
      options.licensor_id = this.props.licensorId
    }
    return this.props.createSchool(options).then((result) => {
      if (result.data.createSchool) {
        this.props.onSchoolCreated(result.data.createSchool)
      }
    })
  }

  isUniqueSubdomain = (subdomain) => {
    return new Promise((resolve, reject) => {
      this.props.client
        .query({
          query: SUBDOMAIN_QUERY,
          variables: { subdomain }
        })
        .then(({ data }) => {
          if (data.subdomain) {
            reject("Sorry, that url already exists. Please choose another.")
          } else {
            resolve()
          }
        })
    })
  }

  render() {
    return (
      <StyledContainer>
        {this.props.renderHeader && this.props.renderHeader()}
        <StyledForm
          onSubmit={this.handleSubmit}
          onSubmitSuccess={this.props.onNext}
        >
          <Field
            name="name"
            label="Account name:"
            placeholder={this.props.namePlaceholder}
            inverted={this.props.inverted}
            component={StyledInput}
            labelWidth={130}
            validators={[isMaxLength(30)]}
            flushLabel
            required
          />
          <Field
            name="subdomain"
            label="URL:"
            placeholder="mysubdomain"
            component={StyledInput}
            inverted={this.props.inverted}
            labelWidth={130}
            minWidth={100}
            stretch={false}
            flushLabel
            required
            autoSize
            validators={[
              isMinLength(3),
              isMaxLength(50),
              isAlphanumeric,
              this.isUniqueSubdomain
            ]}
            custom={
              <StyledInfo inverted={this.props.inverted}>
                .pathwright.com{" "}
                <StyledTooltip
                  placement="right"
                  transition="transition.slideRightIn"
                  title="You can change this later or even setup your own domain"
                >
                  <Pathicon icon="info-circle" />
                </StyledTooltip>
              </StyledInfo>
            }
          />
          <StyledFooter>
            <Submit
              render={(submitProps) =>
                this.props.renderSubmit ? (
                  this.props.renderSubmit(submitProps)
                ) : (
                  <SubmitButton
                    {...submitProps}
                    styleType="primary"
                    size="large"
                    hideSuccess
                    inverted={this.props.inverted}
                  >
                    Next<span> </span>
                    <Pathicon icon="arrow-right" />
                  </SubmitButton>
                )
              }
            />
          </StyledFooter>
        </StyledForm>
      </StyledContainer>
    )
  }
}

SignupSchoolForm.displayName = "SignupSchoolForm"

SignupSchoolForm.defaultProps = {
  namePlaceholder: `"Initech training", "Space Academy", ...`
}

export default compose(
  withApollo,
  graphql(CREATE_SCHOOL_MUTATION, {
    props: ({ mutate }) => ({
      createSchool: (form) => mutate({ variables: form })
    })
  })
)(SignupSchoolForm)
