import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import AdaptedAuthContainer from "../../auth/AdaptedAuthContainer"
import AdaptedSignedInContainer from "../../auth/AdaptedSignedInContainer"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"

const RedeemSchoolGiftSubscriptionAuthGate = (props) => {
  const { me, school } = usePathwrightContext()
  const { t } = useTranslate()

  return me ? <AdaptedSignedInContainer /> : <AdaptedAuthContainer />
}

RedeemSchoolGiftSubscriptionAuthGate.displayName =
  "RedeemSchoolGiftSubscriptionAuthGate"

export default RedeemSchoolGiftSubscriptionAuthGate
