import gql from "graphql-tag"
import STRIPE_PRICE_FRAGMENT from "./stripe-price-fragment"
import STRIPE_PRODUCT_FRAGMENT from "./stripe-product-fragment"

export default gql`
  query StripeProductsQuery(
    $first: Int!
    $after: String
    $ids: IDFilter
    $includeInactive: Boolean
  ) {
    stripeProducts(
      first: $first
      after: $after
      ids: $ids
      include_inactive: $includeInactive
    ) {
      edges {
        node {
          ...StripeProduct
          stripe_prices(first: 50) {
            edges {
              node {
                ...StripePrice
              }
            }
          }
        }
      }
    }
  }
  ${STRIPE_PRODUCT_FRAGMENT}
  ${STRIPE_PRICE_FRAGMENT}
`
