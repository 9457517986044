/*
  - TODO: need link for subscription feature/activation locked blank slate
  - TODO: need link for subscription blank slate
  - TODO: need link for discount feature/activation locked blank slate
  - TODO: need link for discount blank slate
  - TODO: need disconnect Stripe confirmation when clicking "Disconnect Stripe"
  - TODO: BlankSlate should allow for mailto links
*/

import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import Card from "@pathwright/ui/src/components/card/Card"
import CardLink from "@pathwright/ui/src/components/card/CardLink"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import PropTypes from "prop-types"
import styled from "styled-components"

const StyledLoader = styled.div`
  position: relative;
  min-height: 50px;
`

const ManageCommerce = ({
  card,
  stripe_access_token,
  stripe_activation_url,
  loading
}) => {
  return (
    <Card card={card} title="Commerce">
      {loading ? (
        <StyledLoader>
          <LoadingCircle />
        </StyledLoader>
      ) : stripe_access_token ? (
        <div>
          <CardLink
            icon="check-circle"
            label="Commerce is activated"
            meta="You're connected to Stripe.com and ready to take payments."
            to={`${card.path}stripe/`}
          />
          <CardLink
            icon="recur"
            label="Add subscription plans"
            meta="Sell multiple courses monthly or annually with subscription plans."
            to={`${card.path}subscription-plans/`}
          />
          <CardLink
            icon="tag-dollar-sign"
            label="Manage discounts"
            meta="Create discount codes to offer special deals or free access."
            to={`${card.path}discount-codes/`}
          />
        </div>
      ) : (
        <BlankSlate
          icon="storefront"
          heading="Make income from your teaching"
          body="Sell courses, subscriptions, offer discounts and more right from your Library. To get started, setup an account with our world class payment processor partner, Stripe.com."
          meta="(Only takes about 5 min)"
          primaryAction={{
            children: "Connect Stripe",
            href: stripe_activation_url
          }}
        />
      )}
    </Card>
  )
}

ManageCommerce.displayName = "ManageCommerce"

ManageCommerce.propTypes = {
  card: PropTypes.object.isRequired,
  stripe_access_token: PropTypes.string,
  stripe_activation_url: PropTypes.string,
  loading: PropTypes.bool
}

export default ManageCommerce
