import usePreviousEffect from "@pathwright/ui/src/components/hooks/usePreviousEffect"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"

import moment from "moment"
import { useEffect, useMemo, useState } from "react"
import { useFirebaseContext } from "../../firebase/utils"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import { dispatchEvent } from "../../utils/dispatcher"
import { parseNotificationsData } from "./parser"

export type FirebaseNotification = {
  // TODO: type the string as a literal (i.e. "posted")
  action: string
  // Arbitrary label.
  context: string
  // TODO: type based on resource type.
  context_data: any
  from_user_id: number
  image: string
  link: string
  pinned: boolean
  read: boolean
  // TODO: type the string as a literal (i.e. "discussion")
  resource_key: `${string}:${number}`
  to_user_id: number
  user: string
  when: number
}
export type FirebaseNotifications = Record<string, FirebaseNotification>

export type NotificationNode = {
  id: string
  type: string
  data: FirebaseNotification
}
type NotificationNodes = Record<string, NotificationNode>

type UpdateNotification = (
  id: string,
  data: Partial<FirebaseNotification>
) => void
type DeleteNotification = (id: string) => void

export type NotificationsKey = "unread" | "pinned" | "all"

type UseFirebaseNotificationsOptions = {
  getDbRef: (dbRef: any) => any
  notifications: FirebaseNotifications
  getNotifications: (
    get: (notifications: FirebaseNotifications) => FirebaseNotifications
  ) => void
}

export type UseFirebaseNotificationsHookReturn = {
  notifications: FirebaseNotifications
  updateNotification: UpdateNotification
  deleteNotification: DeleteNotification
  paginateNotifications: (() => void) | null
  listen: () => void
  unlisten: () => void
}

export const useFirebaseNotifications = ({
  getDbRef,
  notifications,
  getNotifications
}: UseFirebaseNotificationsOptions): UseFirebaseNotificationsHookReturn => {
  const { firebase, isAuthenticated } = useFirebaseContext()
  const { user } = usePathwrightContext()
  const [isListening, setIsListening] = useState<boolean>(false)
  const [lastKey, setLastKey] = useState<string | null>(null)

  const pageSize = 200

  // firebase may not be defined here...
  const db = firebase?.database()

  // Handles the snapshot from fb. If data is found, merges the notifications
  // with existing notifications and also sets the last key for paginating.
  // Otherwise, clears the last key.
  function handleSnapshot(snapshot: { val: () => FirebaseNotifications }) {
    const data = snapshot.val()
    if (data) {
      getNotifications((notifications) => ({
        ...notifications,
        ...(data as FirebaseNotifications)
      }))
      // Capturing the last key only when it is likely that
      // another page exists (when the number of items returned
      // equals the page size).
      setLastKey(
        Object.keys(data).length === pageSize
          ? // Gptta sort the keys because Object.keys doesn't order
            // the keys correctly (Uppercase letters aren't ordering first.)
            Object.keys(data).sort()[0]
          : null
      )
    } else {
      setLastKey(null)
    }
  }

  // Subscribes to notifications using the supplied db ref.
  const subscribeToNotifications = () => {
    if (user) {
      let dbRef = getDbRef(db.ref(`user/${user.id}/notifications`)).limitToLast(
        pageSize
      )

      dbRef.on("value", handleSnapshot)

      return () => {
        if (dbRef && typeof dbRef.off === "function") {
          dbRef.off()
        }
      }
    }
  }

  // Paginate notifications.
  // NOTE: this only works for db refs where a starting point hasn't already
  // been set by an .equalTo)() or a .startAt(). So, in practice, this only
  // works for the "all" filtered notifications.
  const paginateNotifications = lastKey
    ? () => {
        if (user) {
          getDbRef(db.ref(`user/${user.id}/notifications`))
            .endAt(lastKey)
            .limitToLast(pageSize)
            .once("value", handleSnapshot)
        }
      }
    : null

  // Listening to notifications db ref must be triggered manually.
  useEffect(() => {
    if (db && isAuthenticated && isListening) return subscribeToNotifications()
  }, [db, isAuthenticated, isListening])

  const updateNotification: UpdateNotification = (id, data) => {
    let n = notifications[id]
    if (!n || !user) return
    let newData = { ...n, ...data }
    getNotifications((notifications) => ({ ...notifications, [id]: newData }))
    // update firebase
    let dbRef = db.ref(`user/${user.id}/notifications`)
    dbRef.child(id).update(newData)
  }

  const deleteNotification: DeleteNotification = (id) => {
    if (!user) return
    // Remove the deleted node by creating a new set of notifications
    // that does not include the deleted node.
    getNotifications((notifications) =>
      Object.entries(notifications).reduce<FirebaseNotifications>(
        (acc, [key, value]) => {
          if (key !== id) acc[key] = value
          return acc
        },
        {}
      )
    )
    let dbRef = db.ref(`user/${user.id}/notifications`)
    dbRef.child(id).remove()
  }

  return {
    notifications,
    updateNotification,
    deleteNotification,
    paginateNotifications,
    listen: () => setIsListening(true),
    unlisten: () => setIsListening(false)
  }
}

type NotificationNodesOptions = {
  notifications: FirebaseNotifications
  updateNotification: UpdateNotification
  deleteNotification: DeleteNotification
}

export type NotificationNodesHookReturn = {
  get: (id: string) => NotificationNode
  update: (ids: string[], itemData: Partial<FirebaseNotification>) => void
  remove: (ids: string[]) => void
  nodes: NotificationNode[]
}

export const useNotificationNodes = ({
  notifications,
  updateNotification,
  deleteNotification
}: NotificationNodesOptions): NotificationNodesHookReturn => {
  const [nodes, setNodes] = useState<NotificationNodes>({})
  const { i18n } = useTranslate()

  useEffect(() => {
    if (notifications) {
      let nextNodes = parseNotificationsData(notifications, i18n)
      setNodes(nextNodes)
    } else if (nodes) {
      setNodes({})
    }
  }, [notifications])

  let allNodes = useMemo(
    () =>
      Object.values(nodes).sort((a, b) => (a.data.when > b.data.when ? -1 : 1)),
    [nodes]
  )

  useDispatchRecentNotification(allNodes)

  const get: NotificationNodesHookReturn["get"] = (id) => {
    return nodes[id]
  }

  const update: NotificationNodesHookReturn["update"] = (ids, itemData) => {
    ids.forEach((id) => updateNotification(id, itemData))
  }

  const remove: NotificationNodesHookReturn["remove"] = (ids) => {
    if (ids.length) {
      if (ids.length === 1) {
        // Only deleting one item.
        const id = ids[0]
        deleteNotification(id)
      } else {
        // Deleting multiple items.
        ids.forEach((id) => deleteNotification(id))
      }
    }
  }

  return { nodes: allNodes, get, update, remove }
}

export const useDispatchRecentNotification = (nodes: any[]) => {
  usePreviousEffect(
    ([prevNodes]: [any[]]) => {
      if (nodes.length - prevNodes.length > 0) {
        const recentNodes = nodes
          // Node is new to the nodes list.
          .filter((node) => !prevNodes.find((n) => n.id === node.id))
          // Node has been created within the last 5 mins.
          .filter(
            (node) =>
              node.data.when &&
              moment(node.data.when).isAfter(moment().subtract(5, "minutes"))
          )

        recentNodes.forEach((node) =>
          dispatchEvent("notification:received", node)
        )
      }
    },
    [nodes]
  )
}
