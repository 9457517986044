import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

const RangeWrapper = styled.div`
  display: flex;
  flex-direction: ${(p) => (p.$vertical ? "column" : "row")};
  align-items: stretch;
  justify-content: flex-start;
  width: ${(p) => (p.$vertical ? "" : "100%")};
  height: ${(p) => (p.$vertical ? "100%" : "")};
  padding: 8px;
  margin: 0;
  cursor: pointer;

  .RangedControl__progress-wrapper {
    display: flex;
    transform: ${(p) => (p.$vertical ? "rotateZ(180deg)" : "")};
    flex-direction: ${(p) => (p.$vertical ? "column" : "row")};
    align-items: center;
    overflow: visible;
    width: ${(p) => (p.$vertical ? "4px" : "100%")};
    height: ${(p) => (p.$vertical ? "100%" : "4px")};
    background: #e5e5e5;
  }

  .RangedControl__current-progress {
    display: flex;
    background: rgb(102, 102, 102);
    width: ${(p) => (p.$vertical ? "100%" : `${p.$rangeValue}%`)};
    height: ${(p) => (p.$vertical ? `${100 - p.$rangeValue}%` : "100%")};
  }
`

const RangeMarker = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: rgb(102, 102, 102);
  transition: transform 0.1s ease-out;
  transform: ${(p) => (p.$vertical ? "translateY(-1px)" : "translateX(-1px)")};
  transform-origin: center center;
  overflow: visible;

  &:hover {
    transform: scale(1.5)
      ${(p) => (p.$vertical ? "translateY(-1px)" : "translateX(-1px)")};
  }
`

const RangedControl = (props) => {
  const {
    rangeValue: propsRangeValue,
    vertical,
    value,
    onStartScrub,
    updateOnScrub,
    onStopScrub,
    onSeek
  } = props

  const [mouseDown, setMouseDown] = React.useState(false)
  const [dragging, setDragging] = React.useState(false)
  const [rangeValue, setRangeValue] = React.useState(propsRangeValue)

  React.useEffect(() => {
    if (dragging) return

    setRangeValue(value)
  }, [value])

  const handleMouseDown = (e) => {
    setMouseDown(true)
    if (onStartScrub) onStartScrub()
  }

  const handleMouseMove = (e) => {
    if (!mouseDown) return

    const nextValue = calcRangeValue(e)

    setDragging(true)
    setRangeValue(nextValue)

    if (updateOnScrub) {
      onSeek(nextValue)
    }
  }

  const handleMouseEnter = (event) => {
    if (!dragging) return

    setMouseDown(false)
    setDragging(false)
  }

  const handleMouseUp = (event) => {
    event.preventDefault()
    event.stopPropagation()

    const nextValue = calcRangeValue(event)

    setMouseDown(false)
    setDragging(false)

    onSeek(nextValue)
    if (onStopScrub) {
      onStopScrub()
    }
  }

  const delimitValue = (valueAsPercentage) => {
    let delimited = valueAsPercentage
    if (valueAsPercentage > 100) delimited = 100
    if (valueAsPercentage < 0) delimited = 0

    return delimited
  }

  const calcRangeValue = (event) => {
    const { type, clientX, changedTouches } = event

    const x = type.includes("touch") ? changedTouches[0].clientX : clientX

    const rect = event.currentTarget.getClientRects()[0]
    const nextX = x - rect.left
    const nextValue = (nextX / rect.width) * 100

    return delimitValue(nextValue)
  }

  return (
    <RangeWrapper
      className="RangedControl"
      onMouseUp={handleMouseUp}
      onTouchEnd={handleMouseUp}
      onMouseDown={handleMouseDown}
      onTouchStart={handleMouseDown}
      onMouseMove={handleMouseMove}
      onTouchMove={handleMouseMove}
      onMouseEnter={handleMouseEnter}
      onClick={handleMouseUp}
      $vertical={vertical}
      $rangeValue={rangeValue}
    >
      <div className="RangedControl__progress-wrapper">
        <div className="RangedControl__current-progress" />
        <RangeMarker $vertical={vertical} />
      </div>
    </RangeWrapper>
  )
}

RangedControl.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onSeek: PropTypes.func.isRequired,
  onStartScrub: PropTypes.func,
  onStopScrub: PropTypes.func,
  updateOnScrub: PropTypes.bool,
  $vertical: PropTypes.bool
}

export default RangedControl
