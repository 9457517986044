import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import styled from "styled-components"
import { usePathwrightContext } from "../../../pathwright/PathwrightContext"
import { isItemReviewable } from "../../utils"

const ReviewStatusBadgeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: rgba(0, 0, 0, 0.1); */
  color: rgba(0, 0, 0, 0.8);
  font-size: 0.9em;
  border-radius: 8px;
  padding: 4px 10px;

  .icon-and-label {
    display: flex;
    align-items: center;
  }

  .score {
    font-size: 14px;
    color: #868e9b;
    margin-left: 4px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .score {
      margin-left: 0px;
    }
  }

  .Pathicon {
    margin-right: 4px;
  }
`

export const ReviewStatusBadge = ({ item }) => {
  const { user } = usePathwrightContext()

  const {
    data: { completion }
  } = item

  const {
    checked_by,
    checked_date,
    grading_type,
    needs_grading,
    is_auto_graded,
    is_graded,
    userpoints_value,
    userpoints_earned,
    is_complete
  } = completion

  const reviewable = isItemReviewable(item)

  const completed = !reviewable || (reviewable && !!checked_date)

  if (!is_complete) return null

  var showScore = userpoints_value > 0 //&& !!checked_by

  let label = ""

  // we consider something auto reviewed if it's configured to auto and also has points but hasn't been manually graded
  const wasAutoReviewed = is_auto_graded && userpoints_value > 0 && !checked_by
  const wasReviewedByUser = checked_by && checked_by.id === user.id

  if (needs_grading) {
    label = "Pending review"
  } else if (wasReviewedByUser) {
    label = "You reviewed"
  } else if (checked_by) {
    label = `${checked_by.display_name} reviewed`
  } else if (wasAutoReviewed) {
    label = "Auto-reviewed"
  }

  let className = "ReviewStatusBadge"
  if (completed) {
    className += " completed"
  }

  return (
    <ReviewStatusBadgeContainer className={className}>
      <div className="icon-and-label">
        {label.length > 0 ? (
          completed ? (
            <Pathicon icon="box-check" />
          ) : (
            <Pathicon icon="box" />
          )
        ) : null}
        {label}
      </div>
      {showScore && (
        <span className="score">
          {/* &nbsp;&bull;&nbsp; */}
          {userpoints_earned}/{userpoints_value}
        </span>
      )}
    </ReviewStatusBadgeContainer>
  )
}
