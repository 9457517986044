import { cleanHex } from "@pathwright/ui/src/components/utils/colors"
import get from "lodash/get"
import pick from "lodash/pick"
import isEqual from "react-fast-compare"

// Util for getting the expected context keys to pass to tag queries/mutations.
export const getTagsContext = (context) => {
  const pickedContext = pick(context, "resource_id")
  const tagsContext = Object.keys(pickedContext).length ? pickedContext : null
  return tagsContext
}

// Utils for getting expected context keys to pass to tagLink queries/mutations.
export const getTagLinksContext = (context, modifiedContext) => {
  const pickedContext = pick(context, "discussion_context_id")
  const tagLinksContext = Object.keys(pickedContext).length
    ? pickedContext
    : null

  // Optionally pass modifiedContext to alter the tagLinksContext.
  if (modifiedContext) {
    const mergedContext = {
      ...context,
      ...modifiedContext
    }
    const tagsContext = getTagsContext(context)
    const modifiedTagsContext = getTagsContext(mergedContext)
    // Ensure that the modifiedContext does not alter the tagsContext which provides
    // a slight assurance that the modifiedContext is only altering the tagLinksContext.
    if (isEqual(tagsContext, modifiedTagsContext)) {
      return getTagLinksContext(mergedContext)
    } else {
      throw Error("Modified tagLinksContext must not modify tagsContext.")
    }
  }

  return tagLinksContext
}

// Util for getting the expected data when creating and updating tags.
export const getTagData = (tag) => ({
  id: tag.id,
  name: tag.name,
  description: tag.description,
  color: cleanHex(tag.color)
})

export const getTagAttachment = (tag) =>
  get(tag, "contextualTagAttachments.edges[0].node")

// Sort tags based on tagAttachment position or tag name.
export const sortTags = (tags) =>
  tags.sort((tagA, tagB) => {
    let positionA = null
    let positionB = null

    // Attempt to sort based on contextualTagAttachment position.
    if (getTagAttachment(tagA)) positionA = getTagAttachment(tagA).position
    if (getTagAttachment(tagB)) positionB = getTagAttachment(tagB).position

    // If neither have position, prefer sort by tag.name.
    if (!positionA && !positionB) {
      positionA = tagA.name
      positionB = tagB.name
    }
    // If tagA lacks position, prefer tagB.
    if (!positionA) return 1
    // If tagBlacks position, prefer tagA.
    if (!positionB) return -1
    // Otherwise compare positions of tagA and tagB.
    return positionA < positionB ? -1 : positionA > positionB ? 1 : 0
  })

export const getTagContextualLabel = (tag, t) => {
  if (!tag) return

  // Contextual-level tag.
  if (getTagAttachment(tag)) {
    // For now, we're not showing contextual labels for resource context.
    // return tag.tagAttachments.total > 1 ? t("Shared") : t("Only used here")
    return
  }

  // Top-level tag.
  if (tag.tagAttachments.total >= 1) {
    return t("{{ count }} course", {
      defaultValue_plural: "{{ count }} courses",
      count: tag.tagAttachments.total
    })
  }
}
