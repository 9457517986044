import PropTypes from "prop-types"
import React, { Component } from "react"
import ReactDOM from "react-dom"

// This higher-order component listens to any click events
// outside its (wrapped) children. For optimal performance
// use shouldListen prop to limit active listeners.

class OutsideClickWrapper extends Component {
  componentDidMount() {
    if (this.props.shouldListen) {
      document.body.addEventListener(this.props.mouseEvent, this.handleClick)
      document.body.addEventListener("touchend", this.handleClick)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.shouldListen && !this.props.shouldListen) {
      document.body.addEventListener(nextProps.mouseEvent, this.handleClick)
      document.body.addEventListener("touchend", this.handleClick)
    } else if (this.props.shouldListen && !nextProps.shouldListen) {
      document.body.removeEventListener(nextProps.mouseEvent, this.handleClick)
      document.body.removeEventListener("touchend", this.handleClick)
    }
  }

  componentWillUnmount() {
    document.body.removeEventListener(this.props.mouseEvent, this.handleClick)
    document.body.removeEventListener("touchend", this.handleClick)
  }

  handleClick = (e) => {
    const node = ReactDOM.findDOMNode(this)
    if (node && e.target !== node && !node.contains(e.target)) {
      if (this.props.onOutsideClick) {
        this.props.onOutsideClick(e)
      }
    }
  }

  render() {
    return React.Children.only(this.props.children)
  }
}

OutsideClickWrapper.displayName = "OutsideClickWrapper"

OutsideClickWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  mouseEvent: PropTypes.string,
  shouldListen: PropTypes.bool,
  onOutsideClick: PropTypes.func
}

OutsideClickWrapper.defaultProps = {
  shouldListen: true,
  mouseEvent: "mousedown"
}

export default OutsideClickWrapper
