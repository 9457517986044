import classnames from "classnames"
import PropTypes from "prop-types"
import React, { useCallback, useMemo, useState } from "react"
import usePreviousEffect from "../hooks/usePreviousEffect"
import { sizePropType } from "../utils/props"
import "./Stack.css"

const Stack = React.forwardRef(function Stack(
  {
    items,
    itemWidth,
    itemOffset,
    hoverable,
    reverse,
    style,
    className,
    children
  },
  ref
) {
  const [activeIndex, setActiveIndex] = useState(items.length - 1)

  usePreviousEffect(
    ([prevItems]) => {
      if (prevItems.length !== items.length) {
        setActiveIndex(items.length - 1)
      }
    },
    [items]
  )

  const setIndex = useCallback(
    (activeIndex) => {
      if (!hoverable) return
      setActiveIndex(activeIndex)
    },
    [hoverable]
  )

  const lastIndex = items.length - 1

  const maxWidth = useMemo(() => {
    const width = sizePropType._serialize(itemWidth).number * items.length
    // NOTE: assumption is that width and spacing use same unit!!
    const spacing =
      sizePropType._serialize(itemOffset).number * (items.length - 1)

    return sizePropType._serialize({
      number: width + spacing,
      unit: sizePropType._serialize(itemWidth).unit
    }).style
  }, [items, itemWidth, itemOffset])

  return (
    <ul
      ref={ref}
      className={classnames("Stack", className)}
      // why reset after mouseout?
      // onMouseLeave={() => setIndex(lastIndex)}
      style={{
        // ensure itemOffset is honored if set, otherwise, allowing items to overlap depending on container width
        minWidth:
          sizePropType._serialize(itemOffset).number === 0
            ? sizePropType._serialize(itemWidth).style
            : maxWidth,
        maxWidth,
        ...style
      }}
    >
      {items.map((item, index) => {
        const offset = index - activeIndex
        const className = classnames("Stack__item", {
          "Stack__item--top": offset === 0
        })
        return (
          <li
            key={index}
            className={className}
            onMouseEnter={() => setIndex(index)}
            style={{
              zIndex: reverse ? Math.abs(offset - 100) : 100 - Math.abs(offset),
              minWidth:
                index === lastIndex
                  ? sizePropType._serialize(itemWidth).style
                  : 0,
              marginRight:
                index === lastIndex
                  ? 0
                  : sizePropType._serialize(itemOffset).style
            }}
          >
            {children({ item, offset, itemWidth })}
          </li>
        )
      })}
    </ul>
  )
})

Stack.displayName = "Stack"

Stack.propTypes = {
  items: PropTypes.array.isRequired,
  itemWidth: sizePropType,
  // the space separating each item - can be negative or positive
  itemOffset: sizePropType,
  hoverable: PropTypes.bool,
  // optionally reverse the layering in which the avatars stack
  reverse: PropTypes.bool
}

Stack.defaultProps = {
  items: [],
  hoverable: false,
  itemOffset: "0px",
  reverse: false
}

export default Stack
