import { chakra, VStack } from "@chakra-ui/react"
import { Formik, useFormikContext } from "formik"
import defaults from "lodash/defaults"
import { KeyboardEventHandler, useEffect } from "react"
import { toFormikValidate } from "zod-formik-adapter"
import { FormikFormStateInputField } from "../../../../components/form/state/FormikFormStateField"
import { useScopedTranslation } from "../../../../lib/lng/TranslationsProvider"
import { ActionType } from "../../../../lib/types"
import AuthFormAlert from "../../components/AuthFormAlert"
import AuthFormButton from "../../components/AuthFormButton"
import AuthFormFooterBoxLink from "../../components/AuthFormFooterBoxLink"
import PasswordInput from "../../password/components/PasswordInput"
import PasswordResetConfirmToast from "../../password/reset-confirm/PasswordResetConfirmToast"
import { UserPropertyDefaults } from "../../types"
import { AUTH_EMAIL_STORAGE_KEY } from "../../utils/authEmailFormStateStorage"
import getAuthRoute from "../../utils/getAuthRoute"
import useColorModeBranding from "../../utils/useColorModeBranding"
import { schema, SchemaType } from "./api/action"

const validate = toFormikValidate(schema)

export type SignInPasswordFormProps = ActionType & {
  submitPrompt?: string // Override default submit button prompt.
  userPropertyDefaults?: UserPropertyDefaults // Provide a default email which will constrain the email input.
  isDisabled?: boolean // override disabled state (helpful when mixed in with other forms)
  onChange?: (data: SchemaType) => void // Hanlde when form values change.
  onEnterEmail?: (data: SchemaType) => void // Hanlde "enter" keyboard event on "email" field.
}

// Grossly handle changes in Formik values for upstream component.
const OnChangeHandler = ({
  onChange
}: Pick<SignInPasswordFormProps, "onChange">): null => {
  const form = useFormikContext<SchemaType>()
  useEffect(() => {
    if (onChange) {
      onChange(form.values)
    }
  }, [form.values])

  return null
}

const SignInPasswordForm = ({
  submitPrompt,
  userPropertyDefaults,
  isDisabled,
  loading,
  error,
  onSubmit,
  onChange,
  onEnterEmail
}: SignInPasswordFormProps) => {
  const colorScheme = useColorModeBranding()
  const { t } = useScopedTranslation("auth.sign_in_form")
  submitPrompt ||= t("prompt")
  isDisabled = isDisabled || loading
  // Ugly, only overriding the submit button's `isDisabled` prop when isDisabled is true,
  // otherwise falling back to default behavior.
  const submitButtonDisabledProp = isDisabled ? { isDisabled: true } : {}

  const initialValues = defaults<UserPropertyDefaults, SchemaType>(
    { ...userPropertyDefaults },
    {
      email: "",
      password: ""
    }
  )

  const handleOnEnterUsername =
    (data: SchemaType): KeyboardEventHandler =>
    (e) => {
      if (e.key === "Enter" && onEnterEmail) {
        onEnterEmail(data)
      }
    }

  return (
    <Formik
      initialValues={initialValues}
      validateOnMount={!!userPropertyDefaults}
      validate={validate}
      onSubmit={() => {}} // noop
    >
      {(form) => (
        <chakra.form onSubmit={onSubmit} width="100%" id="signInPasswordForm">
          <OnChangeHandler onChange={onChange} />
          <PasswordResetConfirmToast hideToast={form.dirty} />
          <VStack spacing={4} width="100%" alignItems="stretch">
            <AuthFormAlert error={error} />
            <FormikFormStateInputField
              formStateKey={AUTH_EMAIL_STORAGE_KEY}
              localStorageOptions={{ storageType: "session" }}
              id="email"
              name="email"
              variant="filled"
              placeholder={t(`email_placeholder`) + "..."}
              isDisabled={isDisabled}
              isReadOnly={!!userPropertyDefaults?.email}
              onKeyUp={handleOnEnterUsername(form.values)}
            />
            <PasswordInput
              placeholder={t(`password_placeholder`) + "..."}
              isDisabled={loading}
            />
            <AuthFormFooterBoxLink
              colorScheme={colorScheme}
              alignSelf="flex-end"
              to={getAuthRoute("../password/reset")}
              isDisabled={isDisabled}
            >
              {t("forgot_password")}
            </AuthFormFooterBoxLink>
            <AuthFormButton
              size="lg"
              loading={loading}
              {...submitButtonDisabledProp}
            >
              {submitPrompt}
            </AuthFormButton>
          </VStack>
        </chakra.form>
      )}
    </Formik>
  )
}

export default SignInPasswordForm
