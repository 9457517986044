import DropdownItem from "@pathwright/ui/src/components/dropdown/DropdownItem"
import DropdownMenu from "@pathwright/ui/src/components/dropdown/DropdownMenu"
import DropdownTrigger from "@pathwright/ui/src/components/dropdown/DropdownTrigger"
import withTranslate from "@pathwright/ui/src/components/lng/withTranslate"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import styled from "styled-components"
import { usePathwrightContext } from "../pathwright/PathwrightContext"
import {
  LEARNING_ACTION_FILTERS_SCHOOL,
  LEARNING_ACTION_FILTERS_STEP,
  LEARNING_ACTION_FILTERS_USER_COURSE,
  LEARNING_ACTION_FILTER_NONE
} from "./constants"

const getActionFilters = (isUserActivity, isCourseActivity, isStepActivity) => {
  if (isUserActivity || isCourseActivity)
    return LEARNING_ACTION_FILTERS_USER_COURSE
  if (isStepActivity) return LEARNING_ACTION_FILTERS_STEP
  return LEARNING_ACTION_FILTERS_SCHOOL
}

const StyledContainer = styled.div`
  text-align: center;
  margin: 10px 0;
  font-size: 18px;
  font-weight: 700;
`

const StyledDropdownItem = styled(DropdownItem)`
  font-weight: 400;
  &:first-letter {
    text-transform: capitalize;
  }
`

const StyledLabel = styled.label`
  margin-left: 5px;
  border-bottom: 1px dotted rgba(255, 255, 255, 0.6);
  color: white;
  font-weight: 700;
  display: flex;
  align-items: center;
  white-space: pre;
`

const ActivityFilter = ({
  userId,
  actionType,
  activity_user,
  isUserActivity,
  isCourseActivity,
  isStepActivity,
  onFilter,
  t
}) => {
  const { user } = usePathwrightContext()

  const actionFilters = getActionFilters(
    isUserActivity,
    isCourseActivity,
    isStepActivity
  )

  return (
    <StyledContainer>
      Here's{" "}
      {activity_user
        ? userId === user.id
          ? "your"
          : `${activity_user.first_name}’s`
        : "all"}
      <DropdownTrigger
        alignment="center"
        renderDropdown={() => (
          <DropdownMenu closeOnClick>
            {actionFilters.map((key) => (
              <StyledDropdownItem
                key={key}
                onClick={() => onFilter(key)}
                label={
                  key === LEARNING_ACTION_FILTER_NONE
                    ? t(`activity.filters.${key}`)
                    : t(`activity.filters.${key}_plural`)
                }
              />
            ))}
          </DropdownMenu>
        )}
      >
        <StyledLabel>
          {actionType === LEARNING_ACTION_FILTER_NONE
            ? t(`activity.filters.${actionType}`)
            : t(`activity.filters.${actionType}_plural`)}
          <span> </span>
          <Pathicon icon="chevron-down" />
        </StyledLabel>
      </DropdownTrigger>
    </StyledContainer>
  )
}

ActivityFilter.displayName = "ActivityFilter"

export default withTranslate(ActivityFilter)
