import * as urls from "../utils/urls"

export const getRedirectForSSOLogin = (redirectUrl) => {
  if (!window.App) return redirectUrl

  const { pathname, search } = window.location
  // Ugh.
  let { next_url: nextUrl } = window.App.getStore("auth").getState()

  if (!nextUrl && pathname !== "/auth/sign-in/") {
    nextUrl = `${pathname}${search}`
  }

  if (nextUrl) {
    return `${redirectUrl}?next=${urls.constructUrl(
      urls.getSchoolUrl(nextUrl)
    )}`
  }

  return redirectUrl
}
