import { Button, chakra, HStack, Spacer, VStack } from "@chakra-ui/react"
import { KeyIcon } from "@pathwright/pathicons"
import { motion } from "framer-motion"
import {
  ForwardedRef,
  forwardRef,
  MouseEventHandler,
  useRef,
  useState
} from "react"
import { z } from "zod"
import Link from "../../../../components/link/Link"
import { useScopedTranslation } from "../../../../lib/lng/TranslationsProvider"
import { ActionType } from "../../../../lib/types"
import { useActionState } from "../../../../lib/utils/action-state"
import { useAuthContext } from "../../api/context"
import AuthFormIntentErrorAlert from "../../components/AuthFormIntentErrorAlert"
import AuthMotion from "../../components/AuthMotion"
import AuthOptionsDivider from "../../components/AuthOptionsDivider"
import AuthSpaceHeader from "../../components/AuthSpaceHeader"
import ExternalAuthMethods from "../../components/ExternalAuthMethods"
import { UserPropertyDefaults } from "../../types"
import { AuthIntentKeyWithError } from "../../utils/authIntent"
import getAuthRoute from "../../utils/getAuthRoute"
import useColorModeBranding from "../../utils/useColorModeBranding"
import { SchemaType } from "./api/action"
import SignInPasswordForm from "./SignInPasswordForm"

const authIntentErrorKey: AuthIntentKeyWithError = "sign_in:password"

const emailSchema = z.string().email()

// In order to submit the magic link form when user hits "enter" in email input,
// we must validate against this schema, which requires that the password field be
// empty and that the email field be a valid email.
const signInEmailSchema = z.object({
  email: emailSchema,
  password: z.string().max(0).optional()
})

export type SignInPasswordViewProps = ActionType

type SignInEmailProps = ActionType & {
  // TODO: use form state storage for this
  userPropertyDefaults: UserPropertyDefaults
}

// If user has typed a valid email, clicking the "Sign in without a password" button
// will submit an auth request. Otherwise, it will navigate user to the AuthRequestView.
const SignInEmail = forwardRef(
  (
    { userPropertyDefaults, loading, onSubmit }: SignInEmailProps,
    ref: ForwardedRef<HTMLFormElement>
  ) => {
    const colorScheme = useColorModeBranding()
    const { t } = useScopedTranslation("auth.signin_password_view")

    const hasValidEmail = emailSchema.safeParse(
      userPropertyDefaults?.email
    ).success

    const handleMouseDown: MouseEventHandler<HTMLButtonElement> = (e) =>
      e.preventDefault() // avoid validating any form elements via onBlur handlers, see: https://github.com/redux-form/redux-form/issues/860#issuecomment-625254444

    return hasValidEmail ? (
      <chakra.form ref={ref} onSubmit={onSubmit} w="100%">
        {!!userPropertyDefaults?.email && (
          <input
            value={userPropertyDefaults?.email}
            name="email"
            hidden
            readOnly
          />
        )}
        <motion.div
          animate={{ scale: [1, 1.05, 1] }}
          transition={{ duration: 0.15 }}
        >
          <Button
            variant="solid"
            colorScheme={colorScheme}
            width="full"
            type="submit"
            isLoading={loading}
            leftIcon={<KeyIcon size="1em" />}
            onMouseDown={handleMouseDown}
          >
            {t("sign_in_without_password")}
          </Button>
        </motion.div>
      </chakra.form>
    ) : (
      <Link
        variant="solid"
        colorScheme={colorScheme}
        width="full"
        leftIcon={<KeyIcon size="1em" />}
        to={getAuthRoute("../request")}
      >
        {t("sign_in_without_password")}
      </Link>
    )
  }
)

const SignInPasswordView = ({
  loading,
  error,
  onSubmit
}: SignInPasswordViewProps) => {
  const colorScheme = useColorModeBranding()
  const { t } = useScopedTranslation("auth.signin_password_view")
  const { allowSignup, userPropertyDefaults } = useAuthContext()
  const formRef = useRef<HTMLFormElement>(null)
  const useActiveActionState = useActionState({
    loading,
    error,
    onSubmit
  })
  const signInPassword = useActiveActionState()
  const signInEmail = useActiveActionState()

  const [values, setValues] = useState<SchemaType>()

  const handleOnEnterEmail = (values: SchemaType) => {
    if (signInEmailSchema.safeParse(values).success) {
      // Clicking submit button correctly submits form.
      const submitButton = formRef.current?.querySelector(
        "button[type='submit']"
      )
      if (submitButton instanceof HTMLElement) submitButton.click()
    }
  }

  return (
    <AuthMotion>
      <VStack spacing={4} width="100%" alignItems="stretch">
        <AuthSpaceHeader />
        <AuthFormIntentErrorAlert authIntentKey={authIntentErrorKey} />
        <SignInPasswordForm
          {...signInPassword}
          isDisabled={loading}
          // Show either passowrd or email error (putting error at top of form this way).
          error={error}
          userPropertyDefaults={userPropertyDefaults}
          onEnterEmail={handleOnEnterEmail}
          onChange={setValues}
        />
        <AuthOptionsDivider />
        <SignInEmail
          {...signInEmail}
          userPropertyDefaults={{
            email: values?.email
          }}
          ref={formRef}
        />
        <ExternalAuthMethods authIntent={authIntentErrorKey} />
        <Spacer />
        <HStack justifyContent="center">
          {allowSignup && (
            <Link
              colorScheme={colorScheme}
              textDecor="underline"
              whiteSpace="normal"
              display="inline"
              to={getAuthRoute("../../sign-up")}
            >
              {t("create_account")}
            </Link>
          )}
        </HStack>
      </VStack>
    </AuthMotion>
  )
}

export default SignInPasswordView
