import gql from "graphql-tag"
import MENTOR_GROUP_MEMBERSHIP_FRAGMENT from "./mentor-group-membership-fragment"

const MENTOR_GROUP_MEMBERSHIP_QUERY = gql`
  query MentorGroupMembershipQuery($id: Int!) {
    mentorGroupMembership(id: $id) {
      ...MentorGroupMembership
    }
  }
  ${MENTOR_GROUP_MEMBERSHIP_FRAGMENT}
`

export default MENTOR_GROUP_MEMBERSHIP_QUERY
