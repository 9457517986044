// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
let Response
export default Response = class Response extends (
  require("lib/static-shim").default(require("lib/utils/model").default)
) {
  url() {
    let url = this.urlRoot(
      `api/private/discussion/${this.get("discussion")}/response/`
    )
    if (!this.isNew()) {
      url = `${url}${this.id}/`
    }
    return `${url}?${$.param({ context: this.get("context_str") })}`
  }

  initialize(...args) {
    const obj = args[0],
      val = args[1],
      options = val != null ? val : {}
    this.context = (options != null ? options.context : undefined) || null
    this.discussion = (options != null ? options.discussion : undefined) || null
    return (this.reply_parent =
      (options != null ? options.reply_parent : undefined) || null)
  }

  defaults() {
    return {
      discussion: null,
      reply_parent: null,
      body: "",
      class_section: null,
      user_has_voted: false
    }
  }

  parse(response) {
    // Convert the date to the right format
    response.created_time = moment(response.created_time).local()
    return super.parse(response)
  }

  validate(attrs, options) {
    const d = this.attributes
    if (!d.author) {
      return "author field must be set!"
    }
  }

  save() {
    const data = this.toJSON()
    if (!_.isNumber(data.author)) {
      data.author = data.author.id
    }
    // The API expexts context to be in the POST body and be a string.
    data.context = data.context_str
    return super.save(data)
  }

  isArchived() {
    return this.id != null && this.get("archived_time") !== null
  }
}
