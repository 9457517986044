import gql from "graphql-tag"
import DISCUSSION_POST_FRAGMENT from "./discussion-post-fragment"
import DISCUSSION_RESPONSE_FRAGMENT from "./discussion-response-fragment"

export default gql`
  query DiscussionQuery(
    $id: Int
    $context: DiscussionContextInput!
    $orderResponsesBy: [String]
    $cursor: String
  ) {
    discussion(id: $id, context: $context) {
      ...DiscussionPost
      responses(first: 25, after: $cursor, order_by: $orderResponsesBy) {
        total
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            ...DiscussionResponse
          }
        }
      }
    }
  }
  ${DISCUSSION_POST_FRAGMENT}
  ${DISCUSSION_RESPONSE_FRAGMENT}
`
