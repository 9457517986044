import IconButton from "@pathwright/ui/src/components/button/IconButton"
import Fullscreen from "@pathwright/ui/src/components/fullscreen/Fullscreen"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import classnames from "classnames"
import { ConnectStores } from "lib/core/store"
import "./PricingOverlay.css"

const PricingOverlay = (props) => {
  const { children, inverted, className, backTo } = props

  return (
    <Fullscreen
      className={classnames("PricingOverlay", className)}
      background={null}
    >
      <LoadingCircle />
      {children}
      <IconButton
        className="PricingOverlay__exit"
        icon="x"
        inverted={inverted}
        to={backTo}
        size={40}
      />
    </Fullscreen>
  )
}

PricingOverlay.displayName = "PricingOverlay"

const mapStateToProps = (state, ownProps) => {
  const backTo = state.billingStore.request.toCurrentStep()

  return _.assign(
    {},
    {
      backTo
    },
    ownProps
  )
}

const stores = [{ store: "billing", watch: false }]

export default ConnectStores(PricingOverlay, stores, {}, mapStateToProps)
