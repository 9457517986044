import PropTypes from "prop-types"

export const inboxNavigatorItemPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  type: PropTypes.oneOf(["group", "path"]).isRequired,
  users: PropTypes.shape({
    staff: PropTypes.arrayOf(PropTypes.object).isRequired,
    members: PropTypes.arrayOf(PropTypes.object).isRequired
  }).isRequired,
  meta: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    image: PropTypes.string,
    backgroundImage: PropTypes.string,
    backgroundOverlay: PropTypes.string
  }).isRequired,
  data: PropTypes.object
})
