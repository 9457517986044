import React, { Children, useContext } from "react"
import { usePathwrightContext } from "../pathwright/PathwrightContext"
import CurrencyFormat from "./CurrencyFormat"

export const CurrencyFormatterContext = React.createContext()

const CurrencyFormatterContainer = ({ children, school: schoolProp }) => {
  const { school: schoolContext } = usePathwrightContext()
  const school = schoolProp || schoolContext
  const { ietf_language_tag: locale, currency_code: currency } = school || {}

  const _getCurrencyFormatter = React.useCallback((locale, currency) => {
    if ((locale || currency) && !(locale && currency)) {
      console.warn(
        "CurrencyFormatterProvider.getCurrencyFormatter called with locale or currency but not both!"
      )
    }

    let formatter = null

    if (locale && currency) {
      try {
        formatter = new CurrencyFormat(locale, { currency })
      } catch (error) {
        console.error(
          `Failed to instantiate a CurrencyFormatter for "${locale}": ${error}`
        )
        // still provide currency formatter so we don't break everything
        formatter = new CurrencyFormat("EN-US", { currency: "USD" })
      }
    }

    return formatter
  }, [])

  const currentFormatter = React.useMemo(
    () => _getCurrencyFormatter(locale, currency),
    [locale, currency]
  )

  const getCurrencyFormatter = React.useCallback(
    (locale, currency) =>
      _getCurrencyFormatter(locale, currency) || currentFormatter,
    [currentFormatter]
  )

  return (
    <CurrencyFormatterContext.Provider
      value={{
        currencyFormatter: currentFormatter,
        getCurrencyFormatter
      }}
    >
      {Children.only(children)}
    </CurrencyFormatterContext.Provider>
  )
}

export const useCurrencyFormatter = () => {
  return useContext(CurrencyFormatterContext)
}

export const withCurrencyFormatter = (Component) => (props) => {
  const x = useCurrencyFormatter()
  return <Component {...x} {...props} />
}

export default CurrencyFormatterContainer
