require("./styles/discussion-panel.css")

const DiscussionDetailView = require("./discussion-detail").default
const DiscussionForm = require("./discussion-form").default

class DiscussionPanelView extends require("lib/static-shim").default(
  require("lib/core/layout").default
) {
  static initClass() {
    ;`\
This view shows a discussion for a give context. 
Meant to be used as a contextual slide-out panel\
`
    this.prototype.template = require("./templates/discussion-panel.html")
    this.prototype.className = "discussion-panel-view"

    this.prototype.regions = { discussionRegion: ".discussion-panel-region" }
  }

  initialize() {
    if (this.options.context_label) {
      // Hack to capitalize the verb :(
      const lbl = this.options.context_label
      return (this.options.context_label = `${lbl[0].toUpperCase()}${lbl.slice(
        1
      )}`)
    }
  }

  onRender() {
    switch (this.options.view) {
      case "show":
      case "update":
        // Show discussion detail
        this.view = new DiscussionDetailView({
          model: this.model,
          type: this.options.type,
          types: this.options.types,
          context_label: this.options.context_label,
          edit: this.options.edit,
          onUpdateDiscussion: this.options.onUpdateDiscussion
        })
        this.listenTo(this.view, "discussion:deleted", function (discussion) {
          this.options.onDeleteDiscussion &&
            this.options.onDeleteDiscussion(discussion.toJSON())
          App.vent.trigger("discussion:deleted", discussion)
          App.getStore("layout").action.closeIn("panel")
        })
        break
      case "create":
        break
      // case "create":
      //   // Create a new discussion
      //   this.view = new DiscussionForm({
      //     types: this.options.types,
      //     type: this.options.type,
      //     collection: this.options.collection,
      //     context_label: this.options.context_label
      //   })
      //   this.listenTo(this.view, "cancel:discussion", function() {
      //     return App.getStore("layout").action.closeIn("panel")
      //   })
      //   this.listenTo(this.view, "discussion:created", function(discussion) {
      //     this.options.onCreateDiscussion &&
      //       this.options.onCreateDiscussion(discussion.toJSON())
      //     App.getStore("layout").action.closeIn("panel")
      //   })
      //   break
      // case "update":
      //   // Edit a discussion
      //   this.view = new DiscussionForm({
      //     model: this.model,
      //     type: this.options.type,
      //     types: this.options.types
      //   })
      //   this.listenTo(this.view, "cancel:discussion", function() {
      //     App.getStore("layout").action.closeIn("panel")
      //   })
      //   this.listenTo(this.view, "discussion:updated", function(discussion) {
      //     this.options.onUpdateDiscussion &&
      //       this.options.onUpdateDiscussion(discussion.toJSON())
      //     App.getStore("layout").action.closeIn("panel")
      //   })
      //   break
      default:
        throw new Error("no recognized view set")
    }
    if (this.view) {
      this.discussionRegion.show(this.view)
      if (this.options.view === "show") this.view.loadResponses()
    }
  }
}

DiscussionPanelView.initClass()

export default DiscussionPanelView
