import styled, { keyframes } from "styled-components"

const load = keyframes`
	0% {
		content: '';
	}

	25% {
		content: '.';
	}

	50% {
		content: '..';
	}

	75% {
		content: '...';
	}
`

const AnimatedDots = styled.div`
  position: relative;
  width: 15px;

  &::after {
    content: "";
    animation: ${load} 1.5s infinite;
    overflow-wrap: normal;
  }
`

export default AnimatedDots
