export const MAX_NOTIFICATIONS_SHOWN = 100

const actionIconColors = {
  green: "rgba(52, 199, 89, 1)",
  blue: "rgba(0, 122, 255, 1)",
  purple: "rgba(88, 86, 214, 1)",
  red: "rgba(255, 59, 48, 1)",
  teal: "rgba(48, 176, 199, 1)",
  brown: "rgb(208 167 61)"
}

const getItemActionIcon = (item) => {
  switch (item.action) {
    case "completed":
      return {
        icon: "check-circle-fill",
        color: actionIconColors.green
      }
    // NOTE: "Asked you to answer" and "Asked you to respond to" actions
    // have been replaced with "asked you to reply to" going forward.
    // NOTE: "asked" action has been replaced with "posted" going forward.
    case "Asked you to answer":
    case "Asked you to respond to":
    case "asked you to reply to":
    case "asked":
    case "posted":
      return {
        icon: "chat-bubble-plus-fill",
        color: actionIconColors.blue
      }
    // NOTE: "answered" action has been replaced with "replied" going forward.
    case "answered":
    case "replied":
    case "replied to":
      return {
        icon: "chat-bubbles-fill",
        color: actionIconColors.purple
      }
    // NOTE: "upvoted" action has been replaced with "liked" going forward.
    case "upvoted":
    case "liked":
      return {
        icon: "heart-fill",
        color: actionIconColors.red
      }
    case "reviewed":
      return {
        icon: "feedback-fill",
        color: actionIconColors.teal
      }
    case "assigned":
      return {
        icon: "plus-circle-fill",
        color: actionIconColors.brown
      }
  }
}

export { actionIconColors, getItemActionIcon }
