import { constructUrl } from "@pathwright/web/src/modules/utils/urls"

const request = require("../api/request")
const Storage = require("lib/utils/store").default
const locStorage = new Storage()

export const bootstrapSchool = function (schoolURL) {
  // Returns a promise
  let configURL = `${constructUrl(schoolURL)}config.json`
  if (configURL.indexOf("http") === -1) {
    configURL = `http://${configURL}`
  }
  return request
    .get(configURL)
    .then(function (bootstrappedData) {
      window.bootstrappedData = bootstrappedData
      return bootstrappedData
    })
    .catch(function (error) {
      console.error(error)
      if (error.response && error.response.status === 401) {
        // invalid token?, try again
        locStorage.removeItem("auth_token")
        return bootstrapSchool(schoolURL)
      } else {
        throw error
      }
    })
}
