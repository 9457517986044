import get from "lodash/get"
import moment from "moment"
import {
  DROP_TYPE_PATH_ITEM,
  SHOW_GRADING_FEEDBACK_COMPLETION,
  SHOW_GRADING_FEEDBACK_NEVER
} from "./constants"

export const sortPathItemsOrder = (itemA, itemB) =>
  itemA.order - itemB.order || itemB.id - itemA.id // prefer smaller order else greater id (newer item)

export const createPathFromItems = (items = []) =>
  items
    // !item.parent_id fails for legacy paths where path items like lessons have parent ids
    .filter(
      (item) =>
        item.type === "lesson" ||
        item.type === "divider" ||
        (item.type === "resource" && !item.parent_id)
    )
    .sort(sortPathItemsOrder)
    .map((lesson) => ({
      ...lesson,
      items: items
        .filter((item) => item.parent_id === lesson.id)
        .sort(sortPathItemsOrder)
    }))

export const handlePathItemDragEnd = (dragEndResult, pathItems) => {
  pathItems = [...pathItems]

  if (!dragEndResult.destination) {
    // dropped outside the list
    return
  }

  if (dragEndResult.type === DROP_TYPE_PATH_ITEM) {
    // dropped in lesson
    if (
      dragEndResult.source.droppableId === dragEndResult.destination.droppableId
    ) {
      // dragged within same lesson
      const parent = pathItems.find(item => item.id == dragEndResult.source.droppableId) // prettier-ignore
      const parentItems = [...parent.items]
      // remove item from parent items
      const [removed] = parentItems.splice(dragEndResult.source.index, 1)
      // reinsert at new index
      parentItems.splice(dragEndResult.destination.index, 0, removed)
      // update parent
      parent.items = parentItems
    } else {
      // dragged between lessons
      const source = pathItems.find(item => item.id == dragEndResult.source.droppableId) // prettier-ignore
      const destination = pathItems.find(item => item.id == dragEndResult.destination.droppableId) // prettier-ignore
      // get new items arrays to safely mutate
      const sourceItems = [...source.items]
      const destinationItems = [...destination.items]
      // remove item from source parent items
      const [removed] = sourceItems.splice(dragEndResult.source.index, 1)
      // move item to destination parent items
      destinationItems.splice(dragEndResult.destination.index, 0, removed)
      // update source and destination parents
      source.items = sourceItems
      destination.items = destinationItems
    }
  } else {
    // dragged at root level
    // remove item from path items
    const [removed] = pathItems.splice(dragEndResult.source.index, 1)
    // reinsert at new index
    pathItems.splice(dragEndResult.destination.index, 0, removed)
  }

  // return the reordered path items
  return pathItems
}

export const getPathStepById = (items, stepId) => {
  for (let i = 0; i < items.length; i++) {
    const step = items[i].items.find((item) => item.id === stepId)
    if (step) {
      return step
    }
  }
  return null
}

export const getPathStepBySourceId = (items, stepSourceId) => {
  for (let i = 0; i < items.length; i++) {
    const step = items[i].items.find((item) => item.source_id === stepSourceId)
    if (step) {
      return step
    }
  }
  return null
}

export const isPathItemNotStarted = (pathItem) => get(pathItem, "progress") <= 0

export const isPathItemInProgress = (pathItem) =>
  get(pathItem, "progress") > 0 && get(pathItem, "progress") < 1

export const isPathItemComplete = (pathItem) => get(pathItem, "progress") >= 1

export const getPathItemNonPassiveFutureStartsDate = (
  pathItem,
  parentPathItem,
  cohort
) => {
  // factoring in passive_starts setting
  const getItemDate = (item) => {
    if (item && item.starts) {
      return !item.passive_starts && moment(item.starts).isAfter()
        ? item.starts
        : null
    }

    return null
  }

  const getCohortDate = (cohort) => {
    if (cohort && cohort.access_starts) {
      return moment(cohort.access_starts).isAfter()
        ? cohort.access_starts
        : null
    }

    return null
  }

  // sort "most future" dates to the front
  const sortDates = (dates) =>
    dates
      .filter((date) => date)
      .sort(
        (date1, date2) => new Date(date1).getTime() - new Date(date2).getTime()
      )

  // comparing the pathItem, parentPathItem, and cohort start dates
  const dates = [
    getItemDate(pathItem),
    getItemDate(parentPathItem),
    getCohortDate(cohort)
  ]
  // returning the date sorted to the front of the list, which
  // will either be null or the "most future" date
  return sortDates(dates)[0]
}

export const getStepVerbIcon = (stepVerb) => {
  const iconsMap = {
    read: "text-reading",
    watch: "video",
    listen: "headphones",
    attend: "calendar",
    submit: "inbox-in",
    take: "assessment",
    "to-do": "check-dash-square"
  }

  const key = stepVerb ? stepVerb.toLowerCase() : ""

  return iconsMap[key] || "check-dash-square"
}

export const getStepVerb = (stepVerb, t) => {
  switch (stepVerb) {
    case "read":
      // i18next-scanner v.1 t("read")
      return t(["step.verbs.read", "read"])
    case "watch":
      // i18next-scanner v.1 t("watch")
      return t(["step.verbs.watch", "watch"])
    case "take":
      // i18next-scanner v.1 t("take")
      return t(["step.verbs.take", "take"])
    case "listen":
      // i18next-scanner v.1 t("listen")
      return t(["step.verbs.listen", "listen"])
    case "submit":
      // i18next-scanner v.1 t("submit")
      return t(["step.verbs.submit", "submit"])
    case "attend":
      // i18next-scanner v.1 t("attend")
      return t(["step.verbs.attend", "attend"])
    case "to_do":
    case "to-do":
      // i18next-scanner v.1 t("to-do")
      return t(["step.verbs.to_do", "step.verbs.to-do", "to-do"])
    default:
      // i18next-scanner v.1 t("to-do")
      return t(["step.verbs.to_do", "step.verbs.to-do", "to-do"])
  }
}

// TODO: handle translations (fails due to literal translation being a key in current nested translation JSON)
export const getPathItemLabel = (pathItemType, translator) => {
  switch (pathItemType) {
    case "divider":
      return "divider"
    // return translator("divider")
    case "lesson":
      return "lesson"
    // return translator("lesson")
    case "step":
      return "step"
    // return translator("step")
    case "resource":
      return "course"
    // return translator("course")
    default:
      return "step"
    // return translator("step")
  }
}

// Determine if blocks should reveal learner feedback (ex: Multiple Choice answers)
export const shouldRevealCompletionFeedback = (user, step) => {
  // assuming if current user is viewing another user's completion then they have permissions to view completion feedback regardless of show_grading_feedback settings
  if (
    user &&
    step.completion.user &&
    // Oddly enough, a "blank" user is added sometimes to step.completion
    step.completion.user.id &&
    user.id !== step.completion.user.id
  )
    return true

  // never reveal
  if (step.show_grading_feedback === SHOW_GRADING_FEEDBACK_NEVER) return false

  // reveal on completion
  if (
    step.show_grading_feedback === SHOW_GRADING_FEEDBACK_COMPLETION &&
    !step.completion.is_complete
  )
    return false

  // default to revealing feedback
  return true
}

// getter and setter helpers for stored pathMode
export const getStoredPathMode = () =>
  parseInt(localStorage.getItem("pathMode")) || null

export const setStoredPathMode = (pathMode) =>
  pathMode
    ? localStorage.setItem("pathMode", pathMode)
    : localStorage.removeItem("pathMode")
