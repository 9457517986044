import { createContext, useContext } from "react"
import type { BlocksContentType, BlocksContextType } from "../types"
import { type ReducerActionType } from "./useSyncedReducer"

export type SyncerContextType = {
  content: BlocksContentType | null
  blocksContext: BlocksContextType | null
  blockTypes: any[] | null
  contentLoading
  syncer:
    | ((
        action: ReducerActionType,
        state: Record<string, any>,
        dispatch: any
      ) => void)
    | null
}

const defaultSyncState: SyncerContextType = {
  syncer: null,
  content: null,
  blocksContext: null,
  blockTypes: null,
  contentLoading: false
}

const SyncerContext = createContext<SyncerContextType>(defaultSyncState)
export const useSyncer = () => useContext(SyncerContext)

export default SyncerContext
