import gql from "graphql-tag"
import PATH_ITEM_FRAGMENT from "./path-item-fragment"

export default gql`
  mutation SkipPathItemForUserMutation($id: Int!, $path_id: Int!) {
    skipPathItemForUser(id: $id, path_id: $path_id) {
      ...PathItem
    }
  }
  ${PATH_ITEM_FRAGMENT}
`
