import Alert from "@pathwright/ui/src/components/alert/Alert"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import styled from "styled-components"
import DiscussionTagFeatureBlock from "../../discussion/topics/DiscussionTagFeatureBlock"
import { CONTEXTUAL_FEATURE_PERMISSIONS_QUERY } from "../graphql"
import { featureKeyToParam } from "../utils"
import FeatureBlockItem from "./FeatureBlockItem"

const getToFeature = (feature) =>
  `${window.location.pathname}${featureKeyToParam(feature.feature_key)}/`

const getFeatures = (cfpsQuery, featureKeys) => {
  // grab only the first feature that matches each feature key
  // as the features should be ordered by closest matching context first
  const features = cfpsQuery.data.contextualFeaturePermissions.reduce(
    (features, feature) => {
      if (features.every((f) => f.feature_key !== feature.feature_key)) {
        features.push(feature)
      }
      return features
    },
    []
  )

  // sorting based on order of the featureKeys
  return features
    .filter((feature) => featureKeys.includes(feature.feature_key))
    .sort(
      (featureA, featureB) =>
        featureKeys.indexOf(featureA.feature_key) -
        featureKeys.indexOf(featureB.feature_key)
    )
}

const FeatureBlockList = ({
  featureAction,
  featureKeys,
  context,
  showTagFeatureBlock = true
}) => {
  const cfpsQuery = useQuery(CONTEXTUAL_FEATURE_PERMISSIONS_QUERY, {
    variables: {
      featureAction,
      featureKeys: {
        in: featureKeys
      },
      context
    }
  })

  if (cfpsQuery.loading) {
    return <LoadingCircle center />
  }

  if (cfpsQuery.error) {
    return <Alert error={cfpsQuery.error} />
  }

  return (
    <Container>
      {getFeatures(cfpsQuery, featureKeys).map((feature) => (
        <FeatureBlockItem
          key={feature.feature_key}
          feature={feature}
          to={getToFeature(feature)}
        />
      ))}
      {showTagFeatureBlock && (
        <DiscussionTagFeatureBlock
          context={context}
          to={`${window.location.pathname}discussion-topics/`}
        />
      )}
    </Container>
  )
}

FeatureBlockList.displayName = "FeatureBlockList"

FeatureBlockList.propTypes = {
  ...CONTEXTUAL_FEATURE_PERMISSIONS_QUERY._propTypes
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    margin-left: 1em;
    margin-right: 1em;
  }

  > * + * {
    margin-top: 1em;
  }

  > *:last-child {
    margin-bottom: 1em;
  }
`

export default FeatureBlockList
