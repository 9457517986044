import classnames from "classnames"
import FieldWrapper from "../form-utils/FieldWrapper"

const CheckboxGroup = ({ className, description, children, ...rest }) => {
  return (
    <FieldWrapper
      {...rest}
      className={classnames("CheckboxGroup", className)}
      fixedLabel
    >
      {description && (
        <p className="CheckboxGroup__description">{description}</p>
      )}
      {children}
    </FieldWrapper>
  )
}

CheckboxGroup.displayName = "CheckboxGroup"

export default CheckboxGroup
