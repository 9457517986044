import PropTypes from "prop-types"
import { useLibraryResourcesQuery } from "../graphql/LibraryResourcesQuery"
import LicensorLibraryPickerPrompt, {
  LicensorLibraryPickerBlankSlate
} from "./LicensorLibraryPickerPrompt"

const withLibraryPicker =
  (Component) =>
  ({
    licensorId,
    onOpenStore,
    licensedFromSchoolFilter,
    resourceTypeFilter,
    resourceIdsFilter,
    assignConfig,
    first: firstProp,
    fetchPolicy = "network-only",
    ...passProps
  }) => {
    const useLibraryPickerQuery = ({
      first,
      search,
      filterBySelectedPickerItemIds,
      selectedPickerItemIds
    }) => {
      const { resources, loading, loadingMore, loadMore, hasMore } =
        useLibraryResourcesQuery({
          first: firstProp || first,
          search,
          licensedFromSchoolFilter: licensedFromSchoolFilter,
          // filter resources by currently selected resources
          idFilter: filterBySelectedPickerItemIds
            ? {
                in: selectedPickerItemIds
              }
            : resourceIdsFilter,
          resourceTypeFilter,
          assignConfig,
          fetchPolicy
        })

      return {
        pickerItems: resources,
        loading,
        loadingMore,
        loadMore,
        hasMore
      }
    }

    return (
      <Component {...passProps} usePickerQuery={useLibraryPickerQuery}>
        {({ pickerItems, pickerList }) =>
          pickerItems && pickerItems.length ? (
            <>
              {pickerList}
              <LicensorLibraryPickerPrompt
                licensorId={licensorId}
                onOpenStore={onOpenStore}
              />
            </>
          ) : (
            <LicensorLibraryPickerBlankSlate
              licensorId={licensorId}
              onOpenStore={onOpenStore}
            />
          )
        }
      </Component>
    )
  }

withLibraryPicker.displayName = "withLibraryPicker"

withLibraryPicker.propTypes = {
  licensorId: PropTypes.number,
  onOpenStore: PropTypes.func,
  licensedFromSchoolFilter: PropTypes.shape({
    eq: PropTypes.number,
    neq: PropTypes.number
  }),
  resourceIdsFilter: PropTypes.shape({
    in: PropTypes.arrayOf(PropTypes.number),
    nin: PropTypes.arrayOf(PropTypes.number)
  }),
  resourceTypeFilter: PropTypes.shape({
    eq: PropTypes.string,
    neq: PropTypes.string,
    in: PropTypes.arrayOf(PropTypes.string),
    nin: PropTypes.arrayOf(PropTypes.string)
  })
}

export default withLibraryPicker
