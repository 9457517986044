import { useFirebaseValue } from "../firebase/utils"
import { usePathwrightContext } from "../pathwright/PathwrightContext"

// get's the path and item analytics from Firebase
const usePathAnalytics = (cohort) => {
  const { me } = usePathwrightContext()
  // Supporting legacy offering.path_id.
  const pathId = cohort?.path_id || cohort?.path?.id
  const analyticsEnabled = !!me && !cohort?.analytics_disabled

  // Only provide Fierbase ref path if analytics is not disabled.
  const path = pathId && analyticsEnabled ? `/path/${pathId}/analytics/` : null

  const [analytics] = useFirebaseValue(path)

  return analytics
}

export default usePathAnalytics
