import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useBreakpointValue,
  useDisclosure
} from "@chakra-ui/react"
import { getEnv } from "@pathwright/ui/src/components/utils/env"
import { useMemo, useState } from "react"
import { usePreviousDistinct } from "react-use"
import { useToggleIntercomVisibility } from "../../intercom/IntercomProvider"
import { useCertificateContext } from "../context/CertificateContext"
import { useCertificateState } from "../context/CertificateState"
import CertificateSubmitButton from "./CertificateSubmitButton"
import IconLauncher from "./IconLauncher"
import panels from "./panels"

// These are known drawer widths. We could alternatively observe the width of the drawer,
// but this should do.
export const drawerWidths = {
  xs: 320,
  sm: 448
}

export const useDrawerSize = () =>
  useBreakpointValue({
    base: "xs",
    "2xl": "sm"
  })

// Initially the drawer should not animate in, but always
// animate in/out afterwards, unless drawer is offset.
const useMotionProps = ({ isOpen, preventAnimation }) => {
  const prevIsOpen = usePreviousDistinct(isOpen)
  return useMemo(() => {
    if (preventAnimation || typeof prevIsOpen !== "boolean") {
      return {
        initial: false
      }
    }
    return {}
  }, [prevIsOpen])
}

const CertificateDrawer = ({ offset }) => {
  const { certificate } = useCertificateContext()
  const {
    certificateStoredState: { discardCount }
  } = useCertificateState()

  const size = useDrawerSize()

  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: window.innerWidth > 400 && !getEnv("development")
  })

  // Controlling the tab index so a tab can be directly opened to
  // from opening the icon launcher.
  const [tabIndex, setTabIndex] = useState()

  const handleOpenAtTab = (tabIndex) => {
    setTabIndex(tabIndex)
    onOpen()
  }

  const motionProps = useMotionProps({
    isOpen,
    preventAnimation: !!offset
  })

  useToggleIntercomVisibility(isOpen)

  const showSubmit = Boolean(certificate || discardCount)

  return (
    <>
      {!isOpen && <IconLauncher onSelectPanel={handleOpenAtTab} />}
      <Tabs
        variant="line"
        colorScheme={"gray"}
        index={tabIndex}
        onChange={(i) => setTabIndex(i)}
        isLazy
        lazyBehavior="keepMounted"
      >
        <Drawer
          isOpen={isOpen}
          onClose={onClose}
          variant="aside"
          size={size}
          trapFocus={false}
          blockScrollOnMount={false}
          closeOnOverlayClick={false}
        >
          <DrawerContent
            className="CertificateDrawer"
            motionProps={motionProps}
            bg="transparent"
            backdropFilter="blur(10px)"
            top="60px !important"
            bottom="10px !important"
            marginRight={offset ? `${offset}px` : undefined}
          >
            {!offset && <DrawerCloseButton zIndex={1000} />}
            <DrawerHeader
              bg="rgba(255, 255, 255, 0.95)"
              py={".5em"}
              borderTopRadius={"20px"}
            >
              <TabList
                paddingLeft={2}
                paddingRight={2}
                justifyContent="center"
                borderBottom="none"
              >
                {panels.map((panel) => (
                  <Tab
                    key={panel.key}
                    gap={1}
                    fontWeight="bold"
                    borderBottom="none"
                    opacity=".45"
                    _selected={{ color: "black", opacity: "1" }}
                  >
                    <panel.Icon size="1em" /> {panel.label}
                  </Tab>
                ))}
              </TabList>
            </DrawerHeader>
            <DrawerBody
              bg="rgba(255, 255, 255, 0.9)"
              borderBottomRadius={showSubmit ? 0 : "20px"}
            >
              <TabPanels>
                {panels.map((panel) => (
                  <TabPanel key={panel.key} padding={0}>
                    <panel.Panel />
                  </TabPanel>
                ))}
              </TabPanels>
            </DrawerBody>
            {showSubmit && (
              <DrawerFooter
                bg="rgba(255, 255, 255, 0.95)"
                marginTop="auto"
                display="flex"
                justifyContent={"center"}
                borderBottomRadius={"20px"}
              >
                <CertificateSubmitButton />
              </DrawerFooter>
            )}
          </DrawerContent>
        </Drawer>
      </Tabs>
    </>
  )
}

CertificateDrawer.displayName = "CertificateDrawer"

export default CertificateDrawer
