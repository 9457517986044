import { getDomainEnv } from "@pathwright/ui/src/components/utils/env"
import Marionette from "backbone.marionette"
import Utils from "global/utils"
import { getAPIUrl } from "lib/core/api/urls"
import _ from "lodash"

const Backbone = window.Backbone

class Application extends Marionette.Application {
  static initClass() {
    this.prototype.rootRoute = ""
  }

  initialize() {
    this.utils = new Utils()
  }

  navigate(route, options, title = null, track) {
    if (options == null) {
      options = {}
    }
    if (track == null) {
      track = true
    }
    let navigationStore = window.App.getStore("navigation")
    return navigationStore.action.navigate(route, options, title)
  }

  getCurrentRoute() {
    let frag = Backbone.history.fragment
    if (_.isEmpty(frag)) {
      return null
    } else {
      return frag
    }
  }

  getAPIUrl(url) {
    url = getAPIUrl(url || "")
    // Only use https protocol in non-dev envs.
    const protocol = getDomainEnv(url) === "development" ? "http:" : "https:"
    return url.replace("http:", protocol)
  }
}
Application.initClass()

export default Application
