import gql from "graphql-tag"
import DISCUSSION_RESPONSE from "./discussion-response-fragment"

export default gql`
  mutation CreateDiscussionResponseLike(
    $context: DiscussionContextInput!
    $discussion_id: Int!
    $response_id: Int!
  ) {
    createDiscussionResponseLike(
      context: $context
      discussion_id: $discussion_id
      response_id: $response_id
    ) {
      ...DiscussionResponse
    }
  }
  ${DISCUSSION_RESPONSE}
`
