import { usePathwrightContext } from "../pathwright/PathwrightContext"

export const getSpaceHasFeature = (context, feature) => {
  return context.school.features[feature] === true
}

const useSpaceHasFeature = (feature) => {
  const context = usePathwrightContext()
  return getSpaceHasFeature(context, feature)
}

export default useSpaceHasFeature
