import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import gql from "graphql-tag"
import get from "lodash/get"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import { canEditLibrary } from "../../user/permissions"
import { usePaginator } from "../../utils/apollo"
import { COHORT_FRAGMENT } from "../Cohorts"

export const COHORTS_LIMIT = 25

export const COHORT_SWITCHER_COHORTS_QUERY = gql`
  query CohortSwitcherCohorts(
    $first: Int = ${COHORTS_LIMIT}
    $cursor: String
    $resourceId: Int!
    $role: RoleFilter
    $search: String
  ) {
    cohorts: groups(
      first: $first
      after: $cursor
      resource_id: $resourceId
      role: $role
      search: $search
      include_curriculum: false
      status: in_progress
    ) {
      total
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...Cohort
        }
      }
    }
  }
  ${COHORT_FRAGMENT}
`

// simple hook for querying the cohorts, reuseable by the Picker
const useCohortsQuery = ({ resourceId, search }) => {
  const pwContext = usePathwrightContext()

  const variables = {
    resourceId
  }

  // if the user cannot edit the library then let's filter out any cohorts the user
  // is not registered for
  if (!canEditLibrary(pwContext)) {
    variables.role = {
      neq: null
    }
  }

  // ensure we're not running a query for null or empty string search variable
  if (search) {
    variables.search = search
  }

  const cohortsQuery = useQuery(COHORT_SWITCHER_COHORTS_QUERY, {
    variables
  })

  const { loading, error } = cohortsQuery

  const cohorts = get(cohortsQuery, "data.cohorts.edges", []).map((e) => e.node)

  const { loadingMore, loadMore, hasMore } = usePaginator({
    data: cohortsQuery, // hacky
    path: "cohorts"
  })

  return {
    query: cohortsQuery,
    cohorts: cohorts,
    error,
    loading,
    loadingMore,
    loadMore,
    hasMore
  }
}

export default useCohortsQuery
