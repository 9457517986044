import { withApollo } from "@apollo/client/react/hoc"
import Button from "@pathwright/ui/src/components/button/Button"
import Tooltip from "@pathwright/ui/src/components/tooltip/Tooltip"
import capitalize from "lodash/capitalize"
import get from "lodash/get"
import words from "lodash/words"
import pluralize from "pluralize"
import PropTypes from "prop-types"
import { Component } from "react"
import styled from "styled-components"
import MEMBERSHIP_META_QUERY from "./graphql/membership-meta-query"

const StyledList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 5px;
  text-align: left;
`

const META_MAP = {
  editor: {
    key: "editor",
    name: "editor",
    label: "course"
  },
  teacher: {
    key: "teacher",
    name: "teacher",
    label: "course"
  },
  moderator: {
    key: "moderator",
    name: "moderator",
    label: "course"
  },
  learner: {
    key: "learner",
    name: "learner",
    label: "course"
  },
  observer: {
    key: "observer",
    name: "observer",
    label: "course"
  },
  lead_mentor: {
    key: "lead_mentor",
    name: "lead mentor",
    label: "group"
  },
  mentor: {
    key: "mentor",
    name: "mentor",
    label: "group"
  }
}

class MembershipMeta extends Component {
  state = {
    meta: null
  }

  getStaffMeta = () => {
    const { userId, client, selectedMeta } = this.props

    if (!selectedMeta || !selectedMeta.length) {
      return
    }

    return new Promise((resolve, reject) => {
      client
        .query({
          query: MEMBERSHIP_META_QUERY,
          variables: {
            user_id: userId
          }
        })
        .then(({ data }) => {
          const meta = get(data, "membership_meta")
          if (meta && !this.state.meta) {
            this.setState({ meta })
          }
        })
        .catch(reject)
    })
  }

  get meta() {
    return (
      <StyledList>
        {this.props.selectedMeta.map((metaType, i) => (
          <li key={i}>
            {words(META_MAP[metaType].name).map(capitalize).join(" ")}:{" "}
            {pluralize(
              META_MAP[metaType].label,
              this.state.meta[metaType],
              true
            )}
          </li>
        ))}
      </StyledList>
    )
  }

  render() {
    const { children, userId, client, selectedMeta, ...rest } = this.props

    return (
      <Tooltip title={this.state.meta ? this.meta : null}>
        <div onMouseEnter={this.getStaffMeta}>
          <Button
            el="span"
            styleType="primary"
            size="small"
            interactive={false}
            brand
            {...rest}
          >
            {children}
          </Button>
        </div>
      </Tooltip>
    )
  }
}

MembershipMeta.displayName = "MembershipMeta"

MembershipMeta.propTypes = {
  selectedMeta: PropTypes.arrayOf(
    (propValue, key, componentName, location, propFullName) => {
      if (
        !Object.keys(META_MAP).find(
          (metaObjKey) => META_MAP[metaObjKey].key === propValue[key]
        )
      ) {
        return new Error(
          `Invalid prop '${propFullName}' supplied to '${componentName}'. Validation failed.`
        )
      }
    }
  )
}

export default withApollo(MembershipMeta)
