import gql from "graphql-tag"
import PATH_SYNC_PLAN_CHANGE_FRAGMENT from "./path-sync-plan-change-fragment"

export default gql`
  query PathSyncPlanQuery($sync_plan: PathSyncPlanInput!) {
    pathSyncPlan(sync_plan: $sync_plan) {
      from_path_id
      to_path_id
      included_changes {
        ...PathSyncPlanChange
      }
      excluded_changes {
        ...PathSyncPlanChange
      }
      hash
    }
  }
  ${PATH_SYNC_PLAN_CHANGE_FRAGMENT}
`
