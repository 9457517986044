import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Link,
  Stack,
  Text,
  useDisclosure
} from "@chakra-ui/react"
import { CheckIcon, ChevronRightIcon, XIcon } from "@pathwright/pathicons"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import { useRef } from "react"
import RoleBadge from "./RoleBadge"
import { useRolesConfigWithLabels } from "./rolesConfig"

const tPrefix = "share_card.link_tab.roles"

// TODO: move to some utils file
const runIfFn = (possibleFn, ...args) => {
  if (typeof possibleFn === "function") {
    return possibleFn(...args)
  }
  return possibleFn
}

const RoleHeader = ({ role }) => (
  <HStack>
    <RoleBadge label={role.label} color={role.color} highlight />
    <Text as="span" fontStyle="italic" color="gray.500">
      {role.includes}
    </Text>
  </HStack>
)

const RoleFeature = ({ role, feature }) => (
  <HStack key={feature.label}>
    <Box>
      <CheckIcon color={role.color} size="1em" />
    </Box>
    <Box sx={{ "* + *": { marginLeft: ".4em" } }}>
      {!!feature.label && (
        <Text as="span" fontWeight="bold">
          {feature.label}:
        </Text>
      )}
      <Text as="span">{feature.description}</Text>
    </Box>
  </HStack>
)

const LearnMoreLink = () => {
  const { tc } = useTranslate()

  return (
    <HStack spacing={1}>
      <Button
        as={Link}
        variant="link"
        href="https://help.pathwright.com/en/articles/2083448-pathwright-member-roles"
        target="_blank"
      >
        {tc(`${tPrefix}.Learn more about roles`)}
      </Button>
      <ChevronRightIcon size="1em" />
    </HStack>
  )
}

const RoleDescriptorsDrawer = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = useRef()
  const rolesConfig = useRolesConfigWithLabels()

  return (
    <>
      {runIfFn(children, { ref: btnRef, onOpen })}
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        size="md"
      >
        <DrawerOverlay />
        <DrawerContent className="RoleDescriptorsDrawer">
          <DrawerCloseButton icon={<XIcon />} />
          <DrawerHeader /> {/* for space */}
          <DrawerBody>
            <Stack spacing={4}>
              {Object.entries(rolesConfig)
                .filter(([keu, role]) => role.features?.length)
                .map(([key, role]) => (
                  <Stack key={key}>
                    <RoleHeader role={role} />
                    {role.features.map((feature) => (
                      <RoleFeature
                        key={feature.label}
                        role={role}
                        feature={feature}
                      />
                    ))}
                  </Stack>
                ))}
            </Stack>
          </DrawerBody>
          <DrawerFooter justifyContent="flex-start">
            <LearnMoreLink />
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default RoleDescriptorsDrawer
