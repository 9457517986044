import {
  BackgroundTaskContextProvider,
  ScopedBackgroundTaskRefetchQuery
} from "../../../bg-task/BackgroundTaskContext"

type MentorGroupShareBackgroundTaskContextProviderProps = {
  mentorGroupId: number
  children: JSX.Element
}

// Bg task context for the Mentor Goup Share card.
// After users are added to a group, refetch queries to update group
// with added members/stats.
const MentorGroupShareBackgroundTaskContextProvider = ({
  mentorGroupId,
  children
}: MentorGroupShareBackgroundTaskContextProviderProps) => {
  return (
    <BackgroundTaskContextProvider>
      <>
        <ScopedBackgroundTaskRefetchQuery
          backbroundTaskRefetchQueries={[
            {
              queryName: "MentorGroup",
              variables: { id: mentorGroupId }
            },
            {
              queryName: "MentorGroupStaffMemberships",
              variables: { group_id: mentorGroupId }
            },
            {
              queryName: "MentorGroupMemberships",
              variables: { group_id: mentorGroupId, search: null }
            }
          ]}
        />
        {children}
      </>
    </BackgroundTaskContextProvider>
  )
}

export default MentorGroupShareBackgroundTaskContextProvider
