import {
  BlockCompletionType,
  BlockDataType,
  BlockType,
  BlocksContentType,
  ContentCompletionType
} from "../types"
import * as actions from "./actions"
import * as c from "./constants"

export const defaultState: ViewerStateType = {
  blocks: [],
  syncing: false,
  completion: { progress: 0, completionToken: "" }
}

export type ViewerStateType = {
  blocks: BlockType[]
  syncing: boolean
  completion: ContentCompletionType
  error?: Error
  lastModified?: number
}

export type ViewerReducerActionType =
  | {
      type: "ERROR"
      payload: { error: Error }
    }
  | {
      type: "GET_STATE"
      payload?: any
    }
  | {
      type: "UPDATE_USER_BLOCK"
      payload: {
        id: string
        data: BlockDataType
        userID: string | number
        completion?: BlockCompletionType
        trackProgress?: boolean
        draft?: boolean
      }
    }
  | {
      type: "SYNCED_USER_BLOCK"
      payload: BlockType
    }
  | {
      type: "START_SYNCING"
      payload?: any
    }
  | {
      type: "STOP_SYNCING"
      payload?: any
    }
  | {
      type: "RESET"
      payload?: any
    }

export const getInitialState = (
  props: { content: BlocksContentType } | undefined
): ViewerStateType => {
  if (!props) return defaultState

  return actions.syncViewerAction({
    content: {
      ...props.content,
      blocks: props.content?.blocks
    }
  })(defaultState)
}

export const reducer = (
  state: ViewerStateType,
  action: ViewerReducerActionType
): ViewerStateType => {
  const { type, payload } = action

  switch (type) {
    case c.GET_STATE:
      return { ...state }
    case c.START_SYNCING:
      return { ...state, ...actions.startSyncingAction()() }
    case c.STOP_SYNCING:
      return { ...state, ...actions.stopSyncingAction()() }
    case c.UPDATE_USER_BLOCK:
      return { ...state, ...actions.updateUserBlockAction(payload)(state) }
    case c.SYNCED_USER_BLOCK:
      return { ...state, ...actions.syncedUserBlockAction(payload)(state) }
    case c.ERROR:
      return { ...state, ...actions.errorAction(payload)() }
    case c.RESET:
      return { ...getInitialState(payload) }
    default:
      // require named actions
      throw new Error(`Unknown action type in Viewer: ${type}`)
  }
}
