import { useUIThemeContext } from "@pathwright/ui/src/components/ui/PathwrightUI"
import PropTypes from "prop-types"

// <Switch active=yes />
import "./Switch.css"

import classnames from "classnames"

const Switch = function ({ active, inverted, color, onChange, className }) {
  const theme = useUIThemeContext()
  className = classnames(
    "Switch",
    {
      "Switch--on": active,
      "Switch--off": !active,
      "Switch--inverted": inverted
    },
    className
  )

  color = color || theme.primaryBrandColor
  if (inverted && !active) {
    color = "rgba(255,255,255,0.5)"
  }

  return (
    <div
      className={className}
      style={{ backgroundColor: active ? color : undefined }}
      onClick={() => onChange(!active)}
    >
      <div className="SwitchToggle" />
    </div>
  )
}

Switch.displayName = "Switch"

Switch.propTypes = {
  active: PropTypes.bool,
  color: PropTypes.string,
  inverted: PropTypes.bool,
  onChange: PropTypes.func
}

export default Switch
