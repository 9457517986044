import {
  AuthIntentKeyWithError,
  useAuthIntentErrorText
} from "../utils/authIntent"
import AuthFormAlert from "./AuthFormAlert"

const AuthFormIntentErrorAlert = ({
  authIntentKey
}: {
  authIntentKey: AuthIntentKeyWithError
}): JSX.Element | null => {
  const error = useAuthIntentErrorText(authIntentKey)
  return error ? <AuthFormAlert error={error} /> : null
}

export default AuthFormIntentErrorAlert
