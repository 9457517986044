import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import ActivityContainer from "./ActivityContainer"
import {
  LEARNING_ACTION_FILTERS,
  LEARNING_ACTION_FILTER_NONE
} from "./constants"

// component concerns:
// - deliver query variables props to query
// - provide callback props to change variable state

const Activity = ({
  userId,
  resourceId,
  groupId,
  stepId,
  singleColumn,
  actionType: actionTypeProp,
  canViewAvatarCard,
  canViewAvatarProfile,
  children
}) => {
  const [actionType, setActionType] = useState(
    actionTypeProp || LEARNING_ACTION_FILTER_NONE
  )

  useEffect(() => {
    if (actionTypeProp && actionTypeProp !== actionType) {
      setActionType(actionTypeProp)
    }
  }, [actionTypeProp])

  return (
    <ActivityContainer
      userId={userId}
      resourceId={resourceId}
      groupId={groupId}
      stepId={stepId}
      actionType={actionType}
      controlled={!!actionTypeProp}
      onFilter={setActionType}
      singleColumn={singleColumn}
      isUserActivity={!!userId}
      isStepActivity={!!stepId}
      isCourseActivity={!!(resourceId || groupId)}
      isSchoolActivity={!(resourceId || groupId || stepId || userId)}
      canViewAvatarCard={canViewAvatarCard}
      canViewAvatarProfile={canViewAvatarProfile}
    >
      {children}
    </ActivityContainer>
  )
}

Activity.displayName = "Activity"

Activity.propTypes = {
  // optional params to filter activity scope
  userId: PropTypes.number,
  resourceId: PropTypes.number,
  groupId: PropTypes.number,
  stepId: PropTypes.number,
  // prevent left/right columns, even on larger screen sizes
  singleColumn: PropTypes.bool,
  actionType: PropTypes.oneOf(LEARNING_ACTION_FILTERS), // controlled
  canViewAvatarCard: PropTypes.bool,
  canViewAvatarProfile: PropTypes.bool,
  children: PropTypes.func
}

Activity.defaultProps = {
  canViewAvatarCard: false,
  canViewAvatarProfile: false
}

export default Activity
