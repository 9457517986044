import SSOError from "@pathwright/web/src/modules/sso/SSOError"

export default {
  path: "/sso/error/",
  component: ({ location }) => (
    <SSOError
      errorCode={location.query.error_code}
      token={location.query.context}
    />
  )
}
