import classnames from "classnames"
import PropTypes from "prop-types"
import { DANGEROUS_RED } from "../utils/colors"
import { sizePropType } from "../utils/props"
import "./CountIndicator.css"
import Indicator from "./Indicator"

const CountIndicator = ({
  size: sizeProp,
  count,
  color,
  inverted,
  max,
  maxLabel,
  className: classNameProp,
  style,
  ...props
}) => {
  const maxed = max && count >= max
  const label = maxed ? maxLabel : count || null
  const className = classnames(
    "CountIndicator",
    {
      "CountIndicator--empty": !count || count <= 0,
      "CountIndicator--maxed": maxed
    },
    classNameProp
  )

  const sizeSerialized = sizePropType._serialize(sizeProp)

  const size = {
    number:
      count > 0 && (!maxed || maxLabel)
        ? sizeSerialized.number
        : sizeSerialized.number / 1.25,
    unit: sizeSerialized.unit
  }

  return (
    <Indicator
      className={className}
      size={size}
      color={color}
      inverted={inverted}
      style={style}
      {...props}
    >
      {label}
    </Indicator>
  )
}

CountIndicator.displayName = "CountIndicator"

CountIndicator.propTypes = {
  size: sizePropType,
  color: PropTypes.string,
  inverted: PropTypes.bool,
  count: PropTypes.number,
  max: PropTypes.number,
  maxLabel: PropTypes.string
}

CountIndicator.defaultProps = {
  color: DANGEROUS_RED,
  size: "16px",
  max: 99,
  maxLabel: "*"
}

export default CountIndicator
