import { getVariables, isCustomVariable } from "../variables/variables"
import useActiveCertificate from "./useActiveCertificate"

function useCertificateCustomVariables() {
  const certificate = useActiveCertificate()
  const variables = getVariables(certificate?.text_objects, isCustomVariable)
  return variables
}

export default useCertificateCustomVariables
