import Error from "@pathwright/ui/src/components/error/Error"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import Modal from "@pathwright/ui/src/components/modal/Modal"
import { useMemo } from "react"
import { useQueryParam } from "use-query-params"
import { usePathwrightContext } from "../pathwright/PathwrightContext"

const getPermissionsError = (error, t, pwContext) => {
  switch (error) {
    case "insufficient_permissions":
      return {
        title: t("You do not have permission.")
      }
    case "invalid_invitation":
      return {
        title: t("The invitation token is invalid.")
      }
    case "invitation_required":
      return {
        title: t("An invitation token is required.")
      }
    case "school_membership_required":
      return {
        title: t("A {{ school }} membership is required.", {
          school: pwContext.school.name
        })
      }
    case "school_access_denied":
      return {
        title: t(
          "You do not have access to {{ school }}. Your {{ school }} membership has been deactivated.",
          {
            school: pwContext.school.name
          }
        )
      }
    case "cohort_not_started":
      return {
        title: t("The cohort has not started yet.")
      }
    case "cohort_ended":
      return {
        title: t("The cohort has already ended.")
      }
    case "cohort_completed":
      return {
        title: t("The cohort has been completed.")
      }
    case "cohort_full":
      return {
        title: t("The cohort seat limit has been reached.")
      }
    case "cohort_registration_closed":
      return {
        title: t("The cohort is closed for registration.")
      }
    case "registration_expired":
      return {
        title: t("Your registration has expired.")
      }
    case "registration_already_exists":
      return {
        title: t("You are already registered.")
      }
    case "registration_to_collection_required":
      return {
        title: t("You must first register for the collection.")
      }
    case "subscription_required":
      return {
        title: t("A subscription is required.")
      }
  }

  return null
}

const errorMethods = {
  permissions: getPermissionsError
}

const getError = (errorType, errorKey, t, pwContext) => {
  const errorMethod = errorMethods[errorType]
  if (errorMethod) return errorMethod(errorKey, t, pwContext)
}

const useError = (errorParam) => {
  const { t } = useTranslate()
  const pwContext = usePathwrightContext()
  const error = useMemo(() => {
    if (errorParam) {
      // i.e. "permissions:registration_expired"
      const [errorType, errorKey] = errorParam.split(":")
      return getError(errorType, errorKey, t, pwContext)
    }

    return null
  }, [errorParam])

  return error
}

const ErrorParamAlert = () => {
  const [errorParam, setErrorParam] = useQueryParam("error")
  const error = useError(errorParam)

  const handleClear = () => {
    setErrorParam()
  }

  return error ? (
    <Modal onClose={handleClear}>
      <Error
        errorTitle={error.title}
        primaryAction={{ label: "OK", onClick: handleClear }}
      />
    </Modal>
  ) : null
}

ErrorParamAlert.displayName = "ErrorParamAlert"

export default ErrorParamAlert
