import ManageBilling from "@pathwright/web/src/modules/billing/manage/ManageBilling"
import ManageBillingAccess from "@pathwright/web/src/modules/billing/manage/ManageBillingAccess"
import ManageBillingAccessRemove from "@pathwright/web/src/modules/billing/manage/ManageBillingAccessRemove"
import ManageBillingInvoiceItem from "@pathwright/web/src/modules/billing/manage/ManageBillingInvoiceItem"
import ManageBillingInvoices from "@pathwright/web/src/modules/billing/manage/ManageBillingInvoices"
import ManageBillingPayment from "@pathwright/web/src/modules/billing/manage/ManageBillingPayment"
import ManageBillingStatus from "@pathwright/web/src/modules/billing/manage/ManageBillingStatus"
import { observer } from "mobx-react"
import store from "school/manage/school-manage-store"

const ManageBillingContainer = observer(({ card }) => {
  const passProps = {}
  if (!!store.paid_curriculum_subscription) {
    passProps.numInvoices = store.invoices ? store.invoices.length : 0
  }
  return (
    <ManageBilling
      card={card}
      canManageBilling={store.can_manage_billing}
      {...passProps}
    />
  )
})

const ManageBillingInvoicesContainer = observer(({ card }) => {
  const passProps = {}
  if (!!store.paid_curriculum_subscription) {
    passProps.invoices = store.invoices
  }
  return <ManageBillingInvoices card={card} {...passProps} />
})

const ManageBillingInvoiceItemContainer = ({ card, match }) => (
  <ManageBillingInvoiceItem
    card={card}
    id={parseInt(match.params.invoice_id)}
  />
)

const ManageBillingPaymentContainer = observer(({ card }) => {
  const { publishable_key, subscription, payment_error, payment_saving } = store
  return (
    <ManageBillingPayment
      card={card}
      publishable_key={publishable_key}
      subscription={subscription}
      payment_error={payment_error}
      payment_saving={payment_saving}
      clearErrors={store.action.clearErrors}
      handleToken={store.action.savePayment}
    />
  )
})

const ManageBillingStatusContainer = observer(({ card }) => {
  const { can_view_upcoming_invoice } = store

  if (!can_view_upcoming_invoice) {
    return null
  }

  return <ManageBillingStatus card={card} />
})

const loadInvoices = (nextState) => {
  if (!!store.paid_curriculum_subscription) {
    store.action.loadCurriculumSubscriberInvoices()
  }
}

export default {
  billing: {
    component: ManageBillingContainer,
    middleware: loadInvoices,
    childCards: {
      status: {
        component: ManageBillingStatusContainer
      },
      invoices: {
        component: ManageBillingInvoicesContainer,
        childCards: {
          invoice: {
            path: ":invoice_id/",
            component: ManageBillingInvoiceItemContainer
          }
        }
      },
      payment: {
        component: ManageBillingPaymentContainer
      },
      access: {
        component: ManageBillingAccess,
        childCards: {
          remove: {
            component: ManageBillingAccessRemove
          }
        }
      }
    }
  }
}
