import View from "@pathwright/ui/src/components/ui/View"
import { media } from "@pathwright/ui/src/components/utils/styles"
import get from "lodash/get"
import PropTypes from "prop-types"
import styled from "styled-components"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import withCompletion from "../graphql/withCompletion"
import CompletionFeedbackForm from "./CompletionFeedbackForm"
import CompletionFeedbackLabel from "./CompletionFeedbackLabel"
import CompletionFeedbackNotes from "./CompletionFeedbackNotes"

const Container = styled(View.Primary)`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  ${media.max.phone`
    flex-direction: column;
    padding: 10px;
  `};
`
const Row = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
`

const CompletionFeedbackHeader = ({ step: pathItem }) => {
  const { me } = usePathwrightContext()

  if (!pathItem) {
    return null
  }

  const { completion } = pathItem
  const isMe = me.id === completion.user.id

  return (
    <Container>
      <Row>
        <CompletionFeedbackLabel completion={completion} />
        <Row style={{ justifyContent: "flex-end" }}>
          <CompletionFeedbackNotes pathItem={pathItem} />
        </Row>
      </Row>
      <CompletionFeedbackForm
        step={pathItem}
        completion={completion}
        // TODO: better permissions, though slightly improved with step.permissions.can_edit
        permissions={{
          canGradeCompletion: !isMe || get(pathItem.permissions, "can_edit")
        }}
      />
    </Container>
  )
}

CompletionFeedbackHeader.displayName = "CompletionFeedbackHeader"

CompletionFeedbackHeader.propTypes = {
  stepId: PropTypes.number.isRequired
}

export default withCompletion(CompletionFeedbackHeader)
