import { Component } from "react"
import MembershipQuery from "./MembershipQuery"
import { MEMBERSHIP_FILTERS, MEMBERSHIP_FILTER_ALL } from "./constants"

// component concerns:
// - deliver query variables props to query
// - provide callback props to change variable state

class MembershipContainer extends Component {
  state = {
    membershipFilter: MEMBERSHIP_FILTER_ALL
  }

  handleMembershipFilter = (membershipFilter) => {
    this.setState({
      membershipFilter
    })
  }

  get filters() {
    return MEMBERSHIP_FILTERS[this.state.membershipFilter]
  }

  render() {
    return (
      <MembershipQuery
        {...this.filters}
        membershipFilter={this.state.membershipFilter}
        onFilter={this.handleMembershipFilter}
        location={this.props.location}
        fetchPolicy="cache-and-network"
      />
    )
  }
}

MembershipContainer.displayName = "MembershipContainer"

export default MembershipContainer
