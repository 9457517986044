import { Box } from "@chakra-ui/react"
import Avatar from "@pathwright/ui/src/components/avatar/Avatar"
import IconButton from "@pathwright/ui/src/components/button/IconButton"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import ChakraTooltip from "@pathwright/ui/src/components/tooltip/ChakraTooltip"
import BulletSeparation from "@pathwright/ui/src/components/ui/BulletSeparation"
import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import { useEffect, useState } from "react"
import styled from "styled-components"
import { usePathwrightContext } from "../../../../pathwright/PathwrightContext"
import { USER_PROFILE_QUERY } from "../../../../profile/Profile"
import { getMaxLastSeen } from "../../../../user/MaxLastSeen"
import { When } from "../../../ui/meta"
import FeedbackReviewers from "../../../ui/meta/FeedbackReviewers"
import InboxReview from "../../review/InboxReview"

const PanelSectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid rgba(51, 49, 50, 0.1);
  /* padding: 10px 20px; */
  padding: 20px;

  &.selectable {
    cursor: pointer;
    &:hover {
      background-color: rgba(51, 49, 50, 0.05);
    }
  }

  > div {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    > header {
      display: flex;
      align-items: center;
      h1 {
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.01em;
      }
      .Pathicon {
        margin-right: 10px;
      }
    }
  }

  > .IconButton {
    .Pathicon {
      font-size: 18px;
    }
    color: rgba(0, 0, 0, 0.5);
    margin-left: auto;
  }
`

const PanelSectionMetaContainer = styled(BulletSeparation)`
  color: rgba(0, 0, 0, 0.5);
  width: 100%;
  font-size: 0.8em;

  span {
    white-space: nowrap;
  }
`

const PanelSection = ({ icon, title, children, onSelect, ...props }) => {
  let className = "PanelSection"
  const selectable = typeof onSelect === "function"
  if (selectable) {
    className += " selectable"
  }

  const handleSelect = () => {
    if (selectable) {
      onSelect()
    }
  }

  return (
    <PanelSectionContainer
      className={className}
      onClick={handleSelect}
      {...props}
    >
      <div>
        {title && (
          <header>
            <Pathicon icon={icon} />
            <h1>{title}</h1>
          </header>
        )}
        {children}
      </div>
      {selectable && <IconButton icon="chevron-right" />}
    </PanelSectionContainer>
  )
}

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  position: relative;

  .name-and-status {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.2em;

    h1 {
      margin: 0px;
      font-weight: bold;
      font-size: 18px;
      margin-right: 6px !important;
    }
  }

  > .Avatar {
    margin-right: 10px;
  }
`

export const ReviewPanelSection = ({ onSelect }) => {
  return (
    <PanelSection icon="checklist-square" title="Review" onSelect={onSelect}>
      <InboxReview />
    </PanelSection>
  )
}

export const DiscussPanelSection = ({ onSelect }) => {
  return (
    <PanelSection icon="chat-bubble" title="Discuss" onSelect={onSelect}>
      {/* <DiscussionAvatarStack /> */}
      <p>Discussion avatar here...</p>
    </PanelSection>
  )
}

export const NotesPanelSection = ({ onSelect }) => {
  return (
    <PanelSection icon="notes" title="Notes" onSelect={onSelect}>
      <p>Notes here...</p>
    </PanelSection>
  )
}

export const ProfilePanelSectionOld = ({ item, onSelect }) => {
  const [user, setUser] = useState(item.user)

  const userQuery = useQuery(USER_PROFILE_QUERY, {
    variables: {
      id: user.id
    }
  })

  const { error, loading } = userQuery

  useEffect(() => {
    if (!loading && userQuery.data) {
      setUser(userQuery.data.user)
    }
  }, [userQuery, loading])

  return (
    <PanelSection onSelect={onSelect}>
      <ProfileContainer>
        <Avatar size={60} user={user} />
        <div>
          <h1>{user.full_name}</h1>
          <PanelSectionMetaContainer>
            {user.membership && (
              <span>
                Last active {getMaxLastSeen(user, user.membership).fromNow()}
              </span>
            )}
          </PanelSectionMetaContainer>
        </div>
      </ProfileContainer>
    </PanelSection>
  )
}

const ProfilePanelReviewers = ({ item }) => {
  return <FeedbackReviewers item={item} limit={3} />
}

const ProfilePanelSectionContainer = styled.div`
  margin-bottom: 1px;
  padding: 20px;
  position: relative;
  /* background-color: rgba(255, 255, 255, 0.08); */
`

const CompletionStatusContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 2px 8px;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.8);
  font-size: 0.8em;
  .Pathicon {
    font-size: 12px;
    margin-right: 4px;
  }
  /* text-transform: uppercase; */
`

const CompletionStatus = ({ item }) => {
  const { me } = usePathwrightContext()
  const {
    data: {
      name,
      verb,
      completion: { completion_date, is_skipped, overridden_by },
      due
    }
  } = item
  const { t } = useTranslate()
  const completionLabel = completion_date
    ? is_skipped
      ? t("Skipped")
      : t("Completed")
    : t("Incomplete")
  const icon = completion_date ? "check" : "x"
  const tooltip =
    is_skipped && overridden_by
      ? me && overridden_by.id === me.id
        ? t("Skipped by you")
        : t("Skipped by {{ user }}", { user: overridden_by.display_name })
      : completionLabel
  return (
    <ChakraTooltip title={tooltip}>
      <CompletionStatusContainer>
        <Pathicon icon={icon} />
        {completionLabel}
      </CompletionStatusContainer>
    </ChakraTooltip>
  )
}

export const ProfilePanelSection = ({ item, onSelect }) => {
  const { t } = useTranslate()
  const {
    data: {
      name,
      completion: { completion_date },
      due
    }
  } = item

  const completionLabel = completion_date ? t("Completed") : t("Incomplete")

  const selectProps = {
    onClick: onSelect,
    style: {
      cursor: onSelect ? "pointer" : "normal"
    }
  }

  return (
    <ProfilePanelSectionContainer className="ProfilePanelSection">
      <Box
        pos="absolute"
        inset={0}
        borderTopRadius="20px"
        bg="rgba(255, 255, 255, 0.8)"
      />
      <ProfileContainer>
        <Avatar size="2.5em" user={item.user} {...selectProps} />
        <div>
          <div className="name-and-status">
            <h1 {...selectProps}>{item.user.full_name}</h1>
            <CompletionStatus item={item} />
          </div>
          <PanelSectionMetaContainer>
            <When item={item} showDueStatus />
            <ProfilePanelReviewers item={item} />
          </PanelSectionMetaContainer>
        </div>
      </ProfileContainer>
    </ProfilePanelSectionContainer>
  )
}
