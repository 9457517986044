import PropTypes from "prop-types"
import styled from "styled-components"
import CategoriesQuery from "../category/graphql/CategoriesQuery"
import BrowseContainer from "./browse/BrowseContainer"

// make space for navbar
const Container = styled.div`
  .Banner {
    padding-top: 70px;
  }
`

function StoreBrowseContainer({ schoolId }) {
  return (
    <CategoriesQuery schoolId={schoolId} first={50}>
      {({ categories }) =>
        categories && (
          <Container>
            <BrowseContainer
              schoolId={schoolId}
              browseTo={(resource) => ({
                pathname: `/store/${resource.slug}/`,
                state: { setResourceLaunchedFrom: true }
              })}
              structure={categories.map((c) => ({
                heading: c.name,
                queryOptions: { categoryIdFilter: { eq: c.id } }
              }))}
            />
          </Container>
        )
      }
    </CategoriesQuery>
  )
}

StoreBrowseContainer.displayName = "StoreBrowseContainer"

StoreBrowseContainer.propTypes = {
  // optionally scope to another school
  schoolId: PropTypes.number
}

export default StoreBrowseContainer
