import PropTypes from "prop-types"
import { ReactNode, createContext, useContext } from "react"
import type {
  BlocksContentType,
  BlocksContextType,
  BlocksTemplateVariablesType,
  ContentCompletionType,
  CopiedBlockClipType
} from "../types"

export type BlocksConfigContextType = {
  // User's ID; used for saving blocks.
  userID: number | string
  // School/space ID; used for saving blocks.
  accountID: number | string
  // Context key; used for locating and validating blocks content.
  contextKey: string
  // Is Blocks acting in viewer or editor mode (VIEW is default).
  mode?: "VIEW" | "EDIT"

  // If using default Blocks Apollo client
  graphQLEndpoint?: string

  // Allows passing blocks from context instead of server
  initialBlocks?: {
    blocksContext: BlocksContextType
    content: BlocksContentType
  }
  // Is the content being viewed in standalone/offline mode
  offline?: boolean
  // Disable user input (ex: Teacher reviewing Learner answers)
  disableInput?: boolean
  // Custom message to display when input is disabled
  inputDisabledMessage?: string
  // Trigger a "Reset" on all submitted blocks; hack for when a parent step is reset.
  needsForcedUnsubmit?: boolean
  // This is to allow force updating blocks from the "outside" (offline app etc.)
  externalForcedUpdateDatetime?: string
  // Implement step setting that hides answer feedback.
  hideAssessmentFeedback?: boolean
  // Clipboard interface: copy a block to the clipboard.
  copyBlock?: (blockClip: CopiedBlockClipType | null) => void
  // Clipboard interface: paste a block from the clipboard clip.
  copiedBlock?: CopiedBlockClipType
  // Alert parent when blocks are saved.
  onSave?: ({ content }: { content: BlocksContentType }) => void
  // Alert parent when blocks content has been initialized.
  onDataLoaded?: ({
    content,
    blocksContext
  }: {
    content: BlocksContentType
    blocksContext: BlocksContextType
  }) => void
  // Alert parent when progress block completion is updated.
  renderLoading?: () => ReactNode
  onProgress?: (completion: ContentCompletionType) => void
  // Respond to reload request (for error handling)
  onReload?: () => void
  // Debounce rate for syncing blocks
  changeSaveInterval?: number
  // Template variable data to be used in blocks text
  templateVariables?: BlocksTemplateVariablesType
  // Allows passing a theme to Blocks; currently implemented for pathwright-ui
  theme?: Record<string, any>
  // Current user has superuser permissions
  isSuperuser?: boolean

  // Currently unused
  enableVideo?: boolean
  template?: string
  isLocal?: boolean
}

type BlocksConfigPropsType = BlocksConfigContextType & {
  children: ReactNode
}

const initalState: BlocksConfigContextType = {
  mode: "VIEW",
  userID: 1,
  accountID: 3,
  contextKey: "/blocks",
  onSave: () => {},
  changeSaveInterval: 2000
}

const BlocksConfigContext = createContext<BlocksConfigContextType>(initalState)

export const useBlocksConfig = (): BlocksConfigContextType =>
  useContext(BlocksConfigContext)

export const BlocksConfigProvider = (props: BlocksConfigPropsType) => {
  const { children, ...passValue } = props

  return (
    <BlocksConfigContext.Provider value={passValue}>
      {children}
    </BlocksConfigContext.Provider>
  )
}

export const BlocksConfigPropTypes = {
  mode: PropTypes.oneOf(["VIEW", "EDIT"]).isRequired,
  offline: PropTypes.bool,
  userID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  accountID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  contextKey: PropTypes.string.isRequired,
  enableVideo: PropTypes.bool,
  disableInput: PropTypes.bool,
  inputDisabledMessage: PropTypes.string,
  needsForcedUnsubmit: PropTypes.bool,
  hideAssessmentFeedback: PropTypes.bool,
  theme: PropTypes.object,
  copyBlock: PropTypes.func,
  copiedBlock: PropTypes.shape({
    object_id: PropTypes.string,
    object_type: PropTypes.string,
    object_label: PropTypes.string
  }),
  onSave: PropTypes.func.isRequired,
  onProgress: PropTypes.func,
  onReload: PropTypes.func,
  onDataLoaded: PropTypes.func,
  changeSaveInterval: PropTypes.number,
  template: PropTypes.string,
  templateVariables: PropTypes.object,
  isLocal: PropTypes.bool,
  // This is to allow force updating blocks from the "outside" (offline app etc.)
  externalForcedUpdateDatetime: PropTypes.string,
  // Allows passing blocks from context instead of server
  initialBlocks: PropTypes.shape({
    content: PropTypes.object,
    blocksContext: PropTypes.object
  })
}

BlocksConfigProvider.propTypes = BlocksConfigPropTypes

BlocksConfigProvider.defaultProps = {
  onSave: () => {},
  changeSaveInterval: 2000,
  mode: "VIEW"
}

export default BlocksConfigProvider
