import partial from "lodash/partial"
import { redirect } from "react-router-dom"
import { useAsyncFn } from "react-use"
import { z } from "zod"
import { zfd } from "zod-form-data"
import { getClient } from "../../../../api/client"
import {
  JoinSpaceDocument,
  JoinSpaceMutation,
  JoinSpaceMutationVariables
} from "../../../../api/generated"
import getAuthRoute from "../../utils/getAuthRoute"

export const joinSpaceSchema = z.object({
  consent: z.boolean(),
  inviteToken: z.string().optional()
})

export const joinSpaceFormDataSchema = zfd.formData({
  consent: zfd.checkbox({ trueValue: "true" }),
  inviteToken: zfd.text(z.string().optional())
})

export const joinSpace = async (
  formData: FormData
): Promise<{ success: true }> => {
  const client = getClient()
  let result = await client.mutate<
    JoinSpaceMutation,
    JoinSpaceMutationVariables
  >({
    mutation: JoinSpaceDocument,
    variables: joinSpaceFormDataSchema.parse(formData)
  })

  const role = result.data?.joinSpace?.role

  if (typeof role !== "number") {
    throw new Error("Failed to join.")
  }

  return { success: true }
}

export const useJoinSpace = partial(useAsyncFn<typeof joinSpace>, joinSpace)

const joinSpaceAction = async (formData: FormData): Promise<Response> => {
  await joinSpace(formData)
  return redirect(getAuthRoute("/"))
}

export default joinSpaceAction
