import gql from "graphql-tag"

export default gql`
  fragment SchoolBillingPlanFeature on SchoolBillingPlanFeature {
    id
    name
    description
    link
    key
    icon
    feature_type
    order
    platform_version
  }
`
