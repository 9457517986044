import { Avatar, Flex, HStack, Image, Text, VStack } from "@chakra-ui/react"
import { EnvelopeFillIcon, Group2Icon } from "@pathwright/pathicons"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import { getUserRoleLabel } from "./utils"

const tPrefix = "share_card.add_tab"

const getItemMeta = (item, t) => {
  const meta = []

  // subtitle
  switch (item.type) {
    case "user":
      meta.push(getUserRoleLabel(item, "active_verb", t))
      break
    case "cohort":
      meta.push(item.meta.subtitle)
      break
    case "group":
      if (item.meta.subtitle) {
        meta.push(t(`${tPrefix}.In {{ group }}`, { group: item.meta.subtitle }))
      }
      break
  }

  // count
  switch (item.type) {
    case "cohort":
    case "group":
      meta.push(
        t(`${tPrefix}.{{ count }} person`, {
          defaultValue_plural: `${tPrefix}.{{ count }} people`,
          count: item.people_count
        })
      )
  }

  return meta.filter(Boolean).join(" • ")
}

const PoepleItemImage = ({ item }) => {
  switch (item.type) {
    case "user":
      return (
        <Avatar size="sm" name={item.meta.title} src={item.meta.image?.src} />
      )
    case "email":
      // TODO: haven't tested this design yet.
      return <EnvelopeFillIcon color="gray.500" size={"1.2em"} />
    case "cohort":
      return (
        <Image
          boxSize="40px"
          objectFit="cover"
          src={item.meta.image?.src}
          alt={item.meta.title}
        />
      )
    case "group":
      return (
        <Flex
          size="sm"
          borderRadius={"100%"}
          bg="gray.100"
          color="white"
          w={"sm"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ aspectRatio: "1/1" }}
        >
          <Group2Icon size={"80%"} />
        </Flex>
      )
    default:
      return null
  }
}

const PeopleItem = ({ item, showMeta = true }) => {
  const { t } = useTranslate()

  return (
    <VStack
      alignItems="flex-start"
      flexGrow={1}
      color="gray.900"
      minW={0}
      data-testId={`people-item-${item.meta.key}`}
    >
      <HStack spacing={2}>
        <Flex w="40px" minW="40px" alignItems="center" justifyContent="center">
          <PoepleItemImage item={item} />
        </Flex>
        <VStack alignItems="flex-start" spacing={0}>
          <Text
            size="xs"
            flex={1}
            fontWeight="bold"
            lineHeight="24px"
            m={0}
            noOfLines={1}
          >
            {item.meta.title}
          </Text>
          {showMeta && (
            <Text
              size="xxs"
              color="gray.400"
              lineHeight="21px"
              m={0}
              noOfLines={1}
            >
              {getItemMeta(item, t)}
            </Text>
          )}
        </VStack>
      </HStack>
    </VStack>
  )
}

export default PeopleItem
