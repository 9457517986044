import { Box, Button, HStack, Link } from "@chakra-ui/react"
import { ChevronRightIcon } from "@pathwright/pathicons"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import InvalidVariable from "@pathwright/ui/src/components/variable-selector/InvalidVariable"

// Possibly handle nested keys in the future:
// const variableRe = /^([a-zA-Z_]+\.?(?=[a-zA-Z_]+)[a-zA-Z_]*)+$/i

// Currently only allowing flat key
const variableRe = /^[a-zA-Z_]+$/i

export const CustomVariable = ({ variable }) => {
  const { t } = useTranslate()

  if (!variableRe.test(variable)) {
    return <InvalidVariable variable={variable} />
  }

  return (
    <Box color="whitesmoke">
      <div>
        <strong style={{ whiteSpace: "nowrap" }}>
          {t("Custom variable")}:
        </strong>
      </div>
      {t(
        "be sure to setup values for your custom variables when editing certificate settings for your courses."
      )}{" "}
      <Button
        as={Link}
        textDecoration="underline"
        textUnderlineOffset={2}
        variant="link"
        color="white"
        // TODO: get actual help article link.
        href="google.com"
        isExternal
      >
        <HStack spacing={1}>
          <span>Learn more</span>
          <ChevronRightIcon size="1em" />
        </HStack>
      </Button>
    </Box>
  )
}

CustomVariable.displayName = "CustomVariable"
