import { Button, ButtonProps, useColorModeValue } from "@chakra-ui/react"
import { useFormikContext } from "formik"

const AuthFormButton = (
  props: ButtonProps & { loading: boolean }
): JSX.Element => {
  const { loading, ...rest } = props
  const form = useFormikContext()
  const colorScheme = useColorModeValue("brand", undefined)

  return (
    <Button
      variant="solid"
      colorScheme={colorScheme}
      type="submit"
      width="full"
      isLoading={loading && form.isValid}
      isDisabled={
        loading || !form.isValid || (!form.dirty && !form.validateOnMount)
      }
      {...rest}
    />
  )
}

export default AuthFormButton
