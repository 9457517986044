import merge from "lodash/merge"
import { getAlphaColor } from "../utils/colors"
import { tagPropType } from "./propTypes"

export const TagValue = ({ tag, styles = {} }) => (
  <div className="TagValue" style={TagValue._getStyles(styles, { data: tag })}>
    {tag.name}
  </div>
)

TagValue.displayName = "TagValue"

TagValue.propTypes = {
  tag: tagPropType
}

TagValue._getStyles = (baseStyles = {}, state = {}) => {
  const { data: tag } = state
  const styles = {
    color: tag.color,
    fontSize: "inherit",
    width: "max-content",
    padding: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0
  }

  return merge({}, baseStyles, styles)
}

const Tag = ({ tag, styles = {}, onClick }) => (
  <div
    className="Tag"
    style={Tag._getStyles(styles, { data: tag }, { handleClick: !!onClick })}
    onClick={onClick}
  >
    <TagValue tag={tag} />
  </div>
)

Tag.displayName = "Tag"

Tag.propTypes = {
  tag: tagPropType
}

Tag._getStyles = (baseStyles = {}, state = {}, options = {}) => {
  const { data: tag } = state
  const styles = {
    color: tag.color,
    width: "max-content",
    margin: 3,
    fontWeight: 600,
    fontSize: "1em",
    borderRadius: 7,
    padding: 5,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 3,
    paddingBottom: 3
  }

  if (tag.color) {
    styles.backgroundColor = getAlphaColor(tag.color, 0.1)
    styles[":active"] = {
      backgroundColor: getAlphaColor(tag.color, 0.15)
    }
  }

  if (state.isFocused) {
    merge(styles, {
      backgroundColor: getAlphaColor(tag.color, 0.15)
    })
  }

  if (state.isSelected) {
    merge(styles, {
      backgroundColor: getAlphaColor(tag.color, 0.15),
      border: `1px solid ${tag.color}`,
      // Decreasing padding by 1 to account for the extra 1px size increase
      // from the border style in order to avoid any visual bounce.
      padding: 4,
      paddingLeft: 4,
      paddingRight: 4,
      paddingTop: 4,
      paddingBottom: 4
    })
  }

  if (options.handleClick) {
    merge(styles, {
      // NOTE: the :hover style is not getting applied!
      [":hover"]: {
        backgroundColor: getAlphaColor(tag.color, 0.25)
      }
    })
  }

  return merge({}, baseStyles, styles)
}

export default Tag
