import {
  constructUrl,
  getInboxUrl,
  getRelativeRoute,
  getRelativeUrl,
  getUrlValues,
  isRelativeUrl,
  isSchoolUrl,
  STEP_RE
} from "@pathwright/web/src/modules/utils/urls"

const legacyCourseLinkRe = /\/(?:library|course)\/[a-zA-Z0-9_-]+\/(\d+)\//

const completionRedirect = (notification) => {
  if (notification.link.indexOf("path/") === -1) {
    const [r, completion_id] = notification.resource_key.split(":")

    let search = new URLSearchParams(getRelativeRoute(notification.link).search)

    if (!search.has("user_id")) {
      search.set("user_id", notification.from_user_id)
    }

    if (!search.has("cohort_id")) {
      // get cohort ID
      const match = notification.link.match(legacyCourseLinkRe)
      if (match) {
        search.set("cohort_id", match[1])
      }
    }

    const redirect = `/completion/${completion_id}/?${search.toString()}`
    return redirect
  }
}

const feedbackRedirect = (notification) => {
  const [r, completion_id] = notification.resource_key.split(":")
  const redirect = `/completion/${completion_id}/?user_id=${notification.to_user_id}`
  return redirect
}

const reviewedRedirect = (notification) => {
  const url = getRelativeUrl(notification.link)
  if (STEP_RE.test(url)) {
    const [r, completion_id] = notification.resource_key.split(":")
    const { cohortId } = getUrlValues(STEP_RE, url)

    const redirect = getInboxUrl({
      cohortId,
      stepId: completion_id,
      userId: notification.to_user_id,
      panel: "review"
    })

    return redirect
  }
}

// For ask-to-answer feedback discussion, we need to append the "?ui_panel=discuss" query param
// so that the user is directed to the Inbox Discuss context panel.
const askedToAnswerRedirect = (notification) => {
  const askedToReplyQuestionRe =
    /^(?<ownder>[a-zA-Z]+)'s question: (("Feedback for step: (?<feedbackQuestion>.+)")|(?<question>.+))$/
  const match = notification.context.match(askedToReplyQuestionRe)
  return match && match.groups.feedbackQuestion
    ? notification.link + "&ui_panel=discuss"
    : notification.link
}

const actionRedirects = {
  completed: completionRedirect,
  ["gave feedback on"]: feedbackRedirect,
  reviewed: reviewedRedirect,
  ["asked you to reply to"]: askedToAnswerRedirect
}

const applyRedirects = function (notification) {
  let redirect = notification.link
  const redirectFn = actionRedirects[notification.action]
  if (redirectFn) {
    redirect = redirectFn(notification) || redirect
  }

  // TODO: handle url redirects separately (or maybe consider removing this
  // entirely as this redirect must be very old).
  if (redirect.indexOf("/course/") > -1) {
    redirect = redirect.replace("/course/", "/library/")
  }

  return redirect
}

// apply any redirects applicable to notification.link.
// returns either a route object for relative URLs or a full URL
export const getNotificationUrl = function (notification) {
  try {
    const url = notification.link
    const relativeRedirectUrl = getRelativeUrl(applyRedirects(notification))
    if (isSchoolUrl(url) || isRelativeUrl(url)) {
      return relativeRedirectUrl
    } else {
      return constructUrl(new URL(url).origin, relativeRedirectUrl)
    }
  } catch (error) {
    console.warn(
      `Could not construct notification URL for notification link "${notification.link}": ${error}`
    )
  }
}
