import useCardStack from "@pathwright/ui/src/components/card/useCardStack"
import { LinkContext } from "@pathwright/ui/src/components/link/Link"
import Tabs from "@pathwright/ui/src/components/tab/Tabs"
import React from "react"
import styled from "styled-components"
import { usePathwrightContext } from "../../../pathwright/PathwrightContext"
import ProfileCard from "../../../profile/ProfileCard"
import { useReviewContext } from "../../state/ReviewContextProvider"
import { ContextCardsStackContainer } from "./ContextCards"
import { ProfilePanelSection } from "./sections/ContextStackSections"

const TabContainer = styled.div`
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  position: relative;

  // we can't scroll in the review panel b/c it clips the richtext toolbar
  .Tabs__panels-container {
    overflow: auto;
  }

  .Tabs__tabs-container {
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8);

    /* Hide tab if there's only 1 */
    .Tab:only-child {
      display: none;
    }

    .Tab {
      color: #999;
      font-weight: normal;
      border-bottom: 0px;
      &.Tab--active,
      &:hover {
        border-bottom: 0px;
        color: #000;
      }

      &.Tab--active {
        font-weight: bold;
      }
    }
  }
`

const InboxContextPanel = ({ panels, onSelect, selectedPanel }) => {
  const { me } = usePathwrightContext()
  const { selected } = useReviewContext()
  const { meta } = selected

  // const { open } = useContextCards()

  const { cards, open } = useCardStack({
    component: ProfileCard,
    props: {
      title: "Profile",
      userId: meta.user.id,
      showTabs: ["registrations", "activity"]
    }
  })

  const openProfileCard =
    me.id !== selected.data.completion.user.id ? open : null

  return (
    <React.Fragment>
      <ProfilePanelSection item={selected} onSelect={openProfileCard} />
      <TabContainer>
        <Tabs
          tabs={panels}
          selected={selectedPanel}
          onSelect={onSelect}
          mode="panel"
        />
      </TabContainer>
      {!!cards && (
        <LinkContext.Provider value={{ target: "_blank" }}>
          <ContextCardsStackContainer>{cards}</ContextCardsStackContainer>
        </LinkContext.Provider>
      )}
    </React.Fragment>
  )
}

export default InboxContextPanel
