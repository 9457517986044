import AvatarStack from "@pathwright/ui/src/components/avatar/AvatarStack"
import classnames from "classnames"
import PropTypes from "prop-types"
import styled from "styled-components"
import InboxNavigatorItemReviewCount from "./InboxNavigatorItemReviewCount"
import { inboxNavigatorItemPropType } from "./propTypes"

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  /* margin-left: 0.8em;
  margin-right: 0.8em;
  padding-top: 0.8em;
  padding-bottom: 0.8em; */
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  position: relative;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  .InboxNavigatorItemReviewCount {
    margin-left: 1em;
    font-size: 1.2em;
  }

  .Button__content-wrapper {
    .CountIndicator {
      position: absolute;
      top: 0px;
      right: 0px;
    }
  }
`

const IdentifierContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  /* margin-right: 0.5em;
  padding-left: 1.2em; */
  overflow: hidden;

  h1 {
    font-size: 1.2em;
    margin: 0;
    margin-bottom: 0.2em;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
  }

  h1 + div {
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.5);

    h2 {
      font-size: 1em;
      margin: 0;
      margin-left: 0.4em;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 100%;
      overflow: hidden;
    }
  }
`

// Avoids any odd behavior with nested elements handling the onSelect
// method when clicked. Can result in multiple onSelects firing.
const SelectHandler = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const InboxNavigatorItemMeta = ({ item }) => (
  <IdentifierContainer className="InboxNavigatorItem__meta">
    <h1>{item.meta.title}</h1>
    <div>
      <AvatarStack
        users={[...item.users.staff, ...item.users.members]}
        size="1.6em"
        itemOffset="-0.4em"
      />
      {/* <AvatarStack users={item.users.members} size="1.6em" itemOffset="-0.4em" /> */}
      <h2>{item.meta.subtitle}</h2>
    </div>
  </IdentifierContainer>
)

const InboxNavigatorItem = ({ className, item, onSelect, onSelectPending }) => (
  <Container
    className={classnames(
      "InboxNavigatorItem",
      `InboxNavigatorItem__${item.type}`,
      className
    )}
    onClick={onSelect}
  >
    {/* <SelectHandler onClick={onSelect} /> */}
    <InboxNavigatorItemMeta item={item} />
    <InboxNavigatorItemReviewCount
      item={item}
      // onSelect={onSelectPending}
    />
  </Container>
)

InboxNavigatorItem.displayName = "InboxNavigatorItem"

InboxNavigatorItem.propTypes = {
  item: inboxNavigatorItemPropType.isRequired,
  onSelect: PropTypes.func.isRequired,
  onSelectPending: PropTypes.func.isRequired
}

InboxNavigatorItem.defaultProps = {}

export default InboxNavigatorItem
