import { Box } from "@chakra-ui/react"
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState
} from "react"

// When restricting proximity lines to target, we should ignore the "middle-*" lines
// which are unhelpful in that context as we only care about the edge lines.
const ignoredLinesWhenRestrcited = ["middle-vertical", "middle-horizontal"]

// Accepts an objectRect referencing the current object
// and the targetRect referencing the rect we want to calculate proximity to.
export function getLines(objectRect) {
  // prettier-ignore
  return [
    // top
    {
      key: "top",
      left: 0,
      right: 0,
      top: objectRect.top
    },
    // left
    {
      key: "left",
      left: objectRect.left,
      top: 0,
      bottom: 0
    },
    // bottom
    {
      key: "bottom",
      left: 0,
      right: 0,
      top: objectRect.bottom
    },
    // right
    {
      key: "right",
      left: objectRect.right,
      top: 0,
      bottom: 0 
    },
    // middle-vertical
    {
      key: "middle-vertical",
      left: objectRect.left + (objectRect.right - objectRect.left) / 2,
      top: 0,
      bottom: 0
    },
    // middle-horizontal
    {
      key: "middle-horizontal",
      left: 0,
      right: 0,
      top: objectRect.top + (objectRect.bottom - objectRect.top) / 2
    }
    // Use an order key for sorting ties.
  ].map((line, i)=> {
    line.order = i
    return line
  })
}

export function getLineVectorKey(line) {
  // If "bottom" key is in line, then the comparison key is "left"
  // (and if the "bottom" key isn't in the line then the comparison key is "top").
  return "bottom" in line ? "left" : "top"
}

function getLineProximity(line, targetLines) {
  // If "bottom" key is in line, then the comparison key is "left"
  // (and if the "bottom" key isn't in the line then the comparison key is "top").
  const comparisonKey = getLineVectorKey(line)

  // Get the proximity of the line to all target lines.
  const proximities = targetLines
    .filter((targetLine) => comparisonKey === getLineVectorKey(targetLine))
    .map((targetLine) =>
      Math.abs(line[comparisonKey] - targetLine[comparisonKey])
    )

  // Return the smallest proximity value.
  return Math.min(...proximities, Infinity)
}

// Filter out dupes from lines.
function getUniqueLines(lines) {
  return lines.reduce((acc, line) => {
    if (
      !acc.some((accLine) => {
        const accLineVectorKey = getLineVectorKey(accLine)
        const lineVectorKey = getLineVectorKey(line)
        // Ornate equality check?
        return (
          accLineVectorKey === lineVectorKey &&
          accLine[accLineVectorKey] === line[lineVectorKey]
        )
      })
    ) {
      acc.push(line)
    }
    return acc
  }, [])
}

function getClosestLinesToLines(lines, targetLines, restrictToTarget) {
  // Number of pixels the line must be in proximity to the target in order to
  // be included.
  const threshold = restrictToTarget ? Infinity : 15

  return (
    lines
      // Finally, filter out the lines that don't fall within the threshold.
      .filter((line) => getLineProximity(line, targetLines) <= threshold)
      // Sort the closest lines to the front.
      .sort((lineA, lineB) => {
        return (
          getLineProximity(lineA, targetLines) -
            getLineProximity(lineB, targetLines) || lineA.order - lineB.order
        )
      })
  )
}

// Use targetLineKeys to optionally filter what lines we want to check proximity for
// against the target.
export function getClosestLinesToTarget(
  objectRects,
  targetRect,
  targetLineKeys,
  restrictToTarget = false
) {
  // Get the lines for the object and target rects.
  const lines = objectRects.map((objectRect) =>
    getLines(objectRect).filter(
      (line) =>
        // Filter out any lines that should be ignored when restricting to target.
        !restrictToTarget || !ignoredLinesWhenRestrcited.includes(line.key)
    )
  )
  const targetLines = getLines(targetRect).filter(
    (line) =>
      // Filter out any lines not included in the target.
      (!targetLineKeys?.length || targetLineKeys.includes(line.key)) &&
      // Filter out any lines that should be ignored when restricting to target.
      (!restrictToTarget || !ignoredLinesWhenRestrcited.includes(line.key))
  )

  // Just mush all the lines together since we simply care about closest in proximity
  // apart from any tie to an object.
  const flattenedLines = lines.reduce((acc, lines) => {
    acc.push(...lines)
    return acc
  }, [])

  // Filter out dupes.
  const uniqueLines = getUniqueLines(flattenedLines)

  // Sort the set of lines that has the closest singular line to the front.
  return getClosestLinesToLines(uniqueLines, targetLines, restrictToTarget)
}

// Split the lines into categories of "primary" and "seconday"
// where "primary" lines are those that will be used to reposition object
// and "secondary" or those that are within the threshold, but won't affect
// object positioning.
function getCategorizeLines(lines, restrictToTarget = false) {
  const initialVectorKey = lines.length ? getLineVectorKey(lines[0]) : "top"
  // Find the first lines matching these vectors respectively.
  let vectorKeys = restrictToTarget
    ? // When restricting, we only want to snap to the closest same-vector lines.
      lines.map((_) => initialVectorKey)
    : // When not restricting, we just want the ordered
      [initialVectorKey, initialVectorKey === "top" ? "left" : "top"]
  // Get list of usable lines, being a list of 0-2 lines.
  const categorizedLines = lines.reduce(
    (acc, line) => {
      const vectorKey = getLineVectorKey(line)
      if (vectorKeys.includes(vectorKey)) {
        // (Internal side-effect): Remove used vector key.
        vectorKeys = vectorKeys.slice(1)
        acc.primary.push(line)
      } else {
        acc.secondary.push(line)
      }
      return acc
    },
    {
      primary: [],
      secondary: []
    }
  )

  return categorizedLines
}

// Get the thickness of a line based on which vector (vertical or horizontal).
function getThickness(line) {
  return line.left + line.right === 0 ? { h: "2px" } : { w: "2px" }
}

export function getProximityObjectRects(targetIndex, restrictToTarget = false) {
  const certificateNode = document.querySelector(".CertificateText")

  if (certificateNode) {
    // If restricting to target, we simply look for the target being dragged or resized
    // otherwise we look for all other objects.
    // Always only getting non-nested objects.
    const objectNodes = restrictToTarget
      ? // There can only be 1 object that is dragging or resizing at any time.
        [
          ...document.querySelectorAll(
            `.CertificateText > .DesignObject-${targetIndex}`
          )
        ]
      : [
          ...document.querySelectorAll(`.CertificateText > .DesignObject:not(.DesignObject-${targetIndex})`) // prettier-ignore
        ]

    const certificateRect = certificateNode.getBoundingClientRect()
    // We filter out the object being dragged and those that are nested.

    const objectRects = objectNodes.map((node) => {
      const objectRect = node.getBoundingClientRect()
      const top = objectRect.top - certificateRect.top
      const left = objectRect.left - certificateRect.left
      const right = left + objectRect.width
      const bottom = top + objectRect.height
      return {
        top,
        left,
        right,
        bottom
      }
    })

    if (!restrictToTarget) {
      // also including the certificate rect
      // return objectRects
      const certificateAlteredRect = {
        top: 0,
        left: 0,
        right: certificateRect.width,
        bottom: certificateRect.height
      }

      objectRects.push(certificateAlteredRect)
    }

    return objectRects
  }
  return []
}

// Calculates the top and left offset which can be applied to the target object
// to adjust positioning or dimensions.
export function getProximityOffset({
  targetRect,
  targetIndex,
  restrictToTarget = false,
  targetLineKeys,
  proximityObjectRects: overriddenProximityObjectRects // used for overriding
}) {
  // Default to no offset.
  const proximityOffset = {
    top: 0,
    left: 0
  }

  const proximityObjectRects =
    overriddenProximityObjectRects ||
    getProximityObjectRects(targetIndex, restrictToTarget)
  // Now check if an object lies within an acceptable proximity,
  // and if so, calculate the offset relative that object.
  const objectLines = getClosestLinesToTarget(
    proximityObjectRects,
    targetRect,
    targetLineKeys,
    restrictToTarget
  )

  // We only care about the primary proximity lines.
  const primaryObjectLines = getCategorizeLines(
    objectLines,
    restrictToTarget
  ).primary

  // We'll use the target object lines to derive a diff between them
  // and the object lines to get the offset to apply to the offset.
  const targetObjectLines = getLines(targetRect)

  let primaryObjectLine
  // Until we've exhausted primary lines, adjust the drop rect accordingly.
  while ((primaryObjectLine = primaryObjectLines.shift())) {
    // Get the target line closest to the object line which we can use
    // to derive the offset diff.
    const targetObjectLine = getClosestLinesToLines(
      targetObjectLines,
      [primaryObjectLine],
      restrictToTarget
    )[0]
    const vectorKey = getLineVectorKey(primaryObjectLine)
    // Now, apply the diff between the lines.
    proximityOffset[vectorKey] +=
      targetObjectLine[vectorKey] - primaryObjectLine[vectorKey]
  }

  return proximityOffset
}

// Draw the proximity line.
function ProximityLine({ line, isPrimary }) {
  return (
    <Box
      bg={isPrimary ? "red.400" : "red.100"}
      opacity={isPrimary ? 1 : 0.5}
      pos="absolute"
      zIndex="1"
      left={`${line.left}px`}
      right={`${line.right}px`}
      top={`${line.top}px`}
      bottom={`${line.bottom}px`}
      {...getThickness(line)}
    />
  )
}

const ProximityLines = forwardRef(
  ({ targetRect, targetIndex, targetLineKeys, restrictToTarget }, ref) => {
    const [objectRects, setObjectRects] = useState([])

    // Record the rects from when we initially
    const initialTargetRects = useMemo(
      () => getProximityObjectRects(targetIndex),
      [targetIndex]
    )
    const initialRestrictedTargetRects = useMemo(
      () => getProximityObjectRects(targetIndex, true),
      [targetIndex]
    )

    // Store the object rects at the time of the target rect being supplied.
    useEffect(() => {
      if (typeof targetIndex !== "undefined") {
        setObjectRects(
          restrictToTarget ? initialRestrictedTargetRects : initialTargetRects
        )
      } else {
        setObjectRects([])
      }
    }, [targetIndex, restrictToTarget])

    // Calculate the proximity lines
    const categorizedLines = useMemo(() => {
      return targetRect
        ? getCategorizeLines(
            getClosestLinesToTarget(
              objectRects,
              targetRect,
              targetLineKeys,
              restrictToTarget
            ),
            restrictToTarget
          )
        : null
    }, [targetRect])

    // We can assign the forwarded ref to a derived function which the parent
    // component can call when it's time to apply the proximity offset (on drop or on resize).
    // NOTE: using ref instead of some onChange prop to avoid needlessly calculating the
    // proximity offset as it is only needed at the end of the event (drag/resize).
    const getProximityOffsetCallback = useCallback(
      () =>
        getProximityOffset({
          targetRect,
          targetIndex,
          restrictToTarget,
          targetLineKeys,
          // We need to supply the initial object rects, rather then derived them dynamically
          // at the time we calculate the proximity offset (mainly to resolve issue with restricting
          // resize of text area since the tect area size changes when resizing).
          proximityObjectRects: objectRects
        }),
      [targetRect, targetIndex, restrictToTarget, targetLineKeys, objectRects]
    )

    // Assigning the forwarded ref to the proximity offset callback.
    useImperativeHandle(ref, () => getProximityOffsetCallback)

    return categorizedLines ? (
      <>
        {categorizedLines.primary.map((line, i) => (
          <ProximityLine key={i} line={line} isPrimary />
        ))}
        {categorizedLines.secondary.map((line, i) => (
          <ProximityLine key={i} line={line} />
        ))}
      </>
    ) : null
  }
)

export default ProximityLines
