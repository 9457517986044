import capitalize from "lodash/capitalize"
import styled from "styled-components"
import Pathicon from "../../pathicon/Pathicon"
import Text from "../../ui/Text"
import { components } from "./ReactSelectInput"

const Container = styled.div`
  h5 {
    margin-top: 0;
    margin-bottom: 0;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  p {
    margin: 0;
    font-size: 14px;
  }

  .Pathicon {
    margin-right: 0.4em;
  }
`

const ReactSelectInputOptionLabel = ({ className, icon, label, inverted }) => (
  <Container className={className}>
    <Text.H5 inverted={inverted}>
      <Pathicon icon={icon} />
      {capitalize(label)}
    </Text.H5>
  </Container>
)

ReactSelectInputOptionLabel.displayName = "ReactSelectInputOptionLabel"

const StyledControl = styled(ReactSelectInputOptionLabel)`
  .Pathicon {
    margin-left: 0.4em;
  }
`

export const SelectInputOptionControl = ({
  icon,
  label,
  inverted,
  children,
  ...controlProps
}) => (
  <components.Control {...controlProps}>
    <StyledControl icon={icon} label={label} />
    {children}
  </components.Control>
)

export default ReactSelectInputOptionLabel
