import gql from "graphql-tag"
import ORDER_FRAGMENT from "./order-fragment"

export default gql`
  mutation CreateSchoolSubscriptionOrder(
    $plan_id: Int!
    $plan_visibility_code: String
    $start_free_trial: Boolean
    $coupon_code: String
    $redirect_to: String
  ) {
    createSchoolSubscriptionOrder(
      plan_id: $plan_id
      plan_visibility_code: $plan_visibility_code
      start_free_trial: $start_free_trial
      coupon_code: $coupon_code
      redirect_to: $redirect_to
    ) {
      ...Order
    }
  }
  ${ORDER_FRAGMENT}
`
