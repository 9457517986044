import PropTypes from "prop-types"

export const tagPropType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  description: PropTypes.string
})

export const tagsPropType = PropTypes.shape({
  // All possible tags.
  all: PropTypes.arrayOf(tagPropType),
  // Of all possible tags, those tags that are selectable.
  filtered: PropTypes.arrayOf(tagPropType),
  // Of filtered tags, those tags that are selected.
  selected: PropTypes.arrayOf(tagPropType)
})

export const tagPermissionsPropType = PropTypes.shape({
  canAdd: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
  canRemove: PropTypes.bool.isRequired,
  canReorder: PropTypes.bool.isRequired
})

// A PropType for mere documentation.
const tagsChangeDetais = PropTypes.shape({
  target: PropTypes.oneOf([tagPropType, PropTypes.arrayOf(tagPropType)]),
  action: PropTypes.oneOf([
    "update-tag",
    "unselect-tag",
    "select-tags",
    "reorder-tag"
  ])
})

/**
 * onChange handler for when tags of tagsPropType are changed
 * @param {tagsPropType} tags
 * @param {tagsChangeDetais} details
 */
export const onChangeTagsPropType = PropTypes.func
