export const INVITATION_ROLE_TYPE_MAP = {
  // Cohort roles:
  1: {
    "-1": "cohort_observer",
    5: "cohort_learner",
    10: "cohort_moderator",
    15: "cohort_teacher",
    20: "cohort_course_editor"
  },
  // School roles:
  2: {
    0: "school_member",
    10: "school_staff",
    30: "school_admin"
  },
  // Group roles:
  3: {
    5: "group_member",
    10: "group_moderator",
    15: "group_admin"
  }
}

export const DELIVERED_DELIVERY_LABEL = "Delivered"
export const FAILED_DELIVERY_LABEL = "Failed"
export const JOINED_FILTER_LABEL = "Joined"
export const NOT_JOINED_FILTER_LABEL = "Not Joined"

export const NOT_ACCEPTED_STATUS_MAP = {
  sent: DELIVERED_DELIVERY_LABEL,
  opened: DELIVERED_DELIVERY_LABEL,
  clicked: DELIVERED_DELIVERY_LABEL,
  rejected: FAILED_DELIVERY_LABEL,
  deferred: FAILED_DELIVERY_LABEL,
  bounced: FAILED_DELIVERY_LABEL,
  complained: FAILED_DELIVERY_LABEL,
  unsubscribed: FAILED_DELIVERY_LABEL
}
