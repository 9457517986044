import withTranslate from "@pathwright/ui/src/components/lng/withTranslate"
import { ConnectForm, FieldFooter, RichText } from "lib/core/components/forms"
import { ConnectStores } from "lib/core/store"

import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"

let fieldsData = (props) => ({
  features: {
    label: "Cohort Features",
    placeholder: props.t("cohort.forms.features.fields.features.placeholder"),
    maxHeight: 250,
    hideLabel: true,
    optional: true
  }
})

const GroupFeaturesForm = function (props) {
  let handleSubmit, isValid
  ;({ fieldsData, isValid, handleSubmit } = props)

  return (
    <form onSubmit={handleSubmit}>
      <SubmitButton
        {...Object.assign({}, props, {
          disabled: !isValid,
          styleType: "primary"
        })}
      >
        Save
      </SubmitButton>
      <RichText {...Object.assign({}, fieldsData.features)} />
      <FieldFooter>
        What unique features or benefits come with access?
        <br />
        How should they expect to interact?
      </FieldFooter>
    </form>
  )
}

const ConnectedForm = ConnectForm(GroupFeaturesForm, fieldsData)
ConnectedForm.displayName = "ConnectedForm"

const mapStateToProps = function (state, ownProps) {
  const { group } = state.groupAdminStore.state
  const { saveGroup } = state.groupAdminStore.action

  const fieldValues = {
    features: group != null ? group.description : undefined
  }

  const onSubmit = function (fieldsData, resetForm) {
    const groupData = { description: fieldsData.features.value }
    return saveGroup(groupData)
  }

  const props = _.assign(
    {},
    {
      fieldValues,
      onSubmit
    },
    ownProps
  )
  return props
}

const stores = [{ store: "groupAdmin", watch: ["group"] }]

export default withTranslate(
  ConnectStores(ConnectedForm, stores, {}, mapStateToProps)
)
