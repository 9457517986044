import gql from "graphql-tag"
import DISCUSSION_POST_FRAGMENT from "./discussion-post-fragment"

export default gql`
  query DiscussionsQuery(
    $context: DiscussionContextInput!
    $type: DiscussionTypeInput
    $is_discussion_question: Boolean
    $total_responses: IntFilter
    $tagIdFilter: IntFilter
    $order_by: [String]
    $first: Int = 15
    $cursor: String
  ) {
    discussions(
      context: $context
      type: $type
      is_discussion_question: $is_discussion_question
      total_responses: $total_responses
      tag_id_filter: $tagIdFilter
      order_by: $order_by
      first: $first
      after: $cursor
    ) {
      total
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...DiscussionPost
        }
      }
    }
  }
  ${DISCUSSION_POST_FRAGMENT}
`
