import {
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Tooltip
} from "@chakra-ui/react"

function ChakraTooltip({ children, title, fitContent, ...rest }) {
  // Optionally force popover to fit width of content.
  // Otherwise, popover will render content at a max-width.
  const contentProps = fitContent
    ? {
        width: "auto",
        whiteSpace: "nowrap"
      }
    : {}

  return title ? (
    <Popover
      trigger="hover"
      textAlign="left"
      zIndex="900000000000001"
      {...rest}
    >
      <PopoverTrigger>
        <HStack as="span" className="ChakraTooltipWrapper">
          {children}
        </HStack>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          border="none"
          fontSize="14px"
          opacity="0.9"
          lineHeight="1.3"
          letterSpacing="1px"
          backgroundColor="#000"
          color="whitesmoke"
          p="5px"
          borderRadius="5px"
          boxShadow="0 0 10px 2px rgba(0, 0, 0, 0.2)"
          onClick={(e) => {
            e.stopPropagation()
          }}
          {...contentProps}
        >
          <PopoverArrow border="none" backgroundColor="#000" />
          <PopoverBody>{title}</PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  ) : (
    <span className="ChakraTooltipWrapper">{children}</span>
  )

  // If we wanted a ChakraTooltip based off of Tooltip
  return (
    <Tooltip
      {...props}
      hasArrow
      textAlign="left"
      width="auto"
      zIndex="900000000000001"
      position="relative"
      p="12px 15px"
      borderRadius="5px"
      whiteSpace="nowrap"
      fontSize="14px"
      opacity="0.9"
      lineHeight="1.3"
      letterSpacing="1px"
      backgroundColor="#000"
      color="whitesmoke"
      boxShadow="0 0 10px 2px rgba(0, 0, 0, 0.2)"
    >
      <span>{props.children}</span>
    </Tooltip>
  )
}

export default ChakraTooltip
