import { graphql } from "@apollo/client/react/hoc"
import compose from "lodash/flowRight"
import get from "lodash/get"
import PropTypes from "prop-types"
import { PathwrightContext } from "../../pathwright/PathwrightContext"
import { createPaginator, flattenEdges } from "../../utils/apollo"
import { withContextAsProp, withDebouncedProp } from "../../utils/component"
import CATEGORIES_QUERY from "./categories-query"

export const withCategories = compose(
  withContextAsProp(PathwrightContext, "schoolId", "school.id"),
  withDebouncedProp("search"),
  graphql(CATEGORIES_QUERY, {
    options: ({ first, schoolId, debouncedSearch, excludeIds, manage }) => ({
      variables: {
        first: first || 10,
        school_id: schoolId,
        search: debouncedSearch,
        exclude_ids: excludeIds,
        manage
      },
      fetchPolicy: "cache-and-network"
    }),
    skip: ({ debouncedSearch, skipSearch, ...rest }) =>
      skipSearch ? skipSearch(debouncedSearch) : false,
    props: ({ data, ownProps }) => ({
      error: data.error,
      loading: data.loading || ownProps.debouncedSearch !== ownProps.search,
      total: get(data, "context.school.categories.total", 0),
      hasMore: get(data, "context.school.categories.pageInfo.hasNextPage"),
      loadMore: createPaginator(data, "context.school.categories"),
      categories: flattenEdges(get(data, "context.school.categories"))
    })
  })
)

const CategoriesQuery = withCategories(
  ({ children, ...rest }) => children(rest) || null
)

CategoriesQuery.displayName = "CategoriesQuery"

CategoriesQuery.propTypes = {
  children: PropTypes.func.isRequired,
  // page length
  first: PropTypes.number
}

export default CategoriesQuery
