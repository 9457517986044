import classnames from "classnames"
import FilePickerUpload from "lib/core/components/file-picker-upload/FilePickerUpload"
import PropTypes from "prop-types"
import AtmospherePreviewer from "./_AtmospherePreviewer"
import AtmosphereUploader from "./_AtmosphereUploader"
import CoverPreviewer from "./_CoverPreviewer"
import CoverUploader from "./_CoverUploader"

// TODO: get rid of atmosphere previewer

const ImageUpload = function (props) {
  let {
    label,
    value,
    uploader,
    previewer,
    styleType,
    className,
    showAtmosphere,
    onChange,
    mediaType
  } = props

  if (uploader == null) {
    uploader = showAtmosphere ? AtmosphereUploader : CoverUploader
  }
  if (previewer == null) {
    previewer = showAtmosphere ? AtmospherePreviewer : CoverPreviewer
  }
  const image =
    (value != null ? value.url : undefined) ||
    (value != null ? value.image : undefined)

  className = classnames(
    "ImageUpload",
    {
      [`ImageUpload--${styleType}`]: styleType,
      "ImageUpload--has-file": image,
      "ImageUpload--default":
        (image != null ? image.indexOf("profile-default") : undefined) > -1
    },
    className
  )

  return (
    <div className={className}>
      <FilePickerUpload
        file={value}
        mediaType={mediaType}
        uploader={uploader}
        previewer={previewer}
        onFileUploaded={({ url }) =>
          typeof onChange === "function"
            ? onChange(_.assign({}, value, { url }, props))
            : undefined
        }
      />
    </div>
  )
}

ImageUpload.displayName = "ImageUpload"
ImageUpload.propTypes = {
  label: PropTypes.string,
  styleType: PropTypes.oneOf(["avatar", "cover", "badge"]),
  showAtmosphere: PropTypes.bool,
  value: PropTypes.object,
  onChange: PropTypes.func,
  mediaType: PropTypes.string
}
ImageUpload.defaultProps = { mediaType: "image" }

export default ImageUpload
