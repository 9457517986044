class BindBehavior extends require("lib/static-shim").default(
  Marionette.Behavior
) {
  static initClass() {
    this.prototype.defaults = {
      init: null,
      bindEvent: "render",
      serializeBindData() {
        return { model: this.model }
      },
      getBindEl() {
        return this.el
      },
      options: {
        // override rivets config
      }
    }
  }

  initialize() {
    if (_.isFunction(this.options.init)) {
      _.bind(this.options.init, this.view)()
    }
    if (this.options.bindEvent) {
      this.listenTo(this.view, this.options.bindEvent, this._bind)
    }
    return this.listenTo(this.view, "apply:binding", this._bind)
  }

  _bind() {
    if (this.binding != null) {
      this.binding.unbind()
    }
    this.view.triggerMethod("before:bind")
    const bindData = _.bind(this.options.serializeBindData, this.view)
    const el = _.bind(this.options.getBindEl, this)()
    try {
      this.binding = rivets.bind(el, bindData(), this.options.options)
    } catch (err) {
      console.warn("Rivets error binding data: ", this.view.el, bindData(), err)
    }
    return this.view.triggerMethod("bind")
  }

  onDestroy() {
    return this.binding != null ? this.binding.unbind() : undefined
  }

  onRebind() {
    this.binding.unbind()
    return this._bind()
  }
}
BindBehavior.initClass()
export default BindBehavior
