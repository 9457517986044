import ManageCustomCode from "@pathwright/web/src/modules/integration/manage/ManageCustomCode"
import ManageGoogleAnalytics from "@pathwright/web/src/modules/integration/manage/ManageGoogleAnalytics"
import ManageIntegrationMenu from "@pathwright/web/src/modules/integration/manage/ManageIntegrationMenu"
import ManageIntercom from "@pathwright/web/src/modules/integration/manage/ManageIntercom"
import ManageSSO from "@pathwright/web/src/modules/integration/manage/ManageSSO"
import ManageZapier from "@pathwright/web/src/modules/integration/manage/ManageZapier"
import { observer } from "mobx-react"
import store from "school/manage/school-manage-store"

const ManageIntegrationMenuContainer = observer(({ card }) => {
  return (
    <ManageIntegrationMenu
      card={card}
      gaEnabled={!!store.integrations.analytics_account_id}
      zapierEnabled={false} // TODO: how do we determine this?
      ssoEnabled={false} // TODO: how do we determine this?
    />
  )
})

const ManageGoogleAnalyticsContainer = observer(({ card }) => (
  <ManageGoogleAnalytics
    card={card}
    onSubmit={store.action.saveIntegrations}
    initialValue={{
      analytics_account_id: store.integrations.analytics_account_id
    }}
  />
))

const ManageIntercomContainer = observer(({ card }) => (
  <ManageIntercom card={card} onSubmit={store.action.saveIntegrations} />
))

const ManageSSOContainer = observer(({ card }) => (
  <ManageSSO card={card} supportEmail="hello@pathwright.com" />
))

const ManageZapierContainer = observer(({ card }) => {
  const { school } = store
  return <ManageZapier card={card} secret_key={school.secret_key} />
})

const ManageCustomCodeContainer = observer(({ card }) => (
  <ManageCustomCode
    card={card}
    onSubmit={store.action.saveSnippets}
    loading={store.snippets_loading}
    initialValue={{
      head_markup: store.snippets.head_markup,
      script_markup: store.snippets.script_markup,
      conversion_tracking_code: store.snippets.conversion_tracking_code
    }}
  />
))

export default {
  integrations: {
    component: ManageIntegrationMenuContainer,
    middleware: store.action.loadIntegrations,
    childCards: {
      ga: { component: ManageGoogleAnalyticsContainer },
      intercom: { component: ManageIntercomContainer },
      sso: { component: ManageSSOContainer },
      zapier: { component: ManageZapierContainer },
      custom: {
        component: ManageCustomCodeContainer,
        middleware: store.action.loadSnippets
      }
    }
  }
}
