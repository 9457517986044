import classnames from "classnames"
import PropTypes from "prop-types"
import { useState } from "react"
import styled from "styled-components"
import IconButton from "../../button/IconButton"
import useControlledState from "../../hooks/useControlledState"
import Pathicon from "../../pathicon/Pathicon"
import { createDownloader } from "./utils"

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px;
  border: 2px dashed;
  border-radius: 20px;
  cursor: pointer;

  ${(p) => (p.isDraggingOver ? `background: rgba(0, 0, 0, 0.05);` : "")}

  /* Prevent child elements from causing onDragLeave to fire. */
  ${(p) => (p.isDraggingOver ? `* { pointer-events: none; }` : "")}

  @media (hover: hover) {
    &:not(:disabled):hover {
      background: rgba(0, 0, 0, 0.05);
    }
  }

  > ul {
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
  }

  > ul > li {
    padding: 8px 0;

    + li {
      border-top: 1px solid rgba(0, 0, 0, 0.4);
    }
  }

  > ul > li > div {
    display: flex;
    width: 100%;
    align-items: center;

    > * + * {
      margin-left: 0.4em;
    }

    > span {
      flex-grow: 1;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      position: relative;
    }
  }

  > span {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 0;

    > i {
      margin-right: 0.4em;
    }
  }
`

/* 
FileInput allows for selecting or drag/dropping one or more files. Note that
the file input's value cannot be controlled. This has the side-effect of not being
able to clear the input value pragmattically, so if an item is removed from state
it may still exist in the input's value, and thus cannot be re-uploaded without
remounting the component.
*/

const callAll =
  (...fns) =>
  (...args) =>
    fns.forEach((fn) => fn && fn(...args))

export const useFileInput = ({
  accept,
  maxFiles,
  onChange,
  filesData: filesDateProp
}) => {
  const [filesData, setFilesData] = useControlledState(filesDateProp, onChange)
  const [isDraggingOver, setIsDraggingOver] = useState(false)

  const handleFilesData = async (fileList) => {
    // Only handle drop if more files are allowed.
    if (Object.keys(filesData).length <= maxFiles) {
      const files = [...fileList].slice(0, maxFiles)
      const data = await Promise.all(files.map((file) => getFileData(file)))
      const filesData = files.reduce(
        (filesData, file, i) => ({
          ...filesData,
          [file.name]: data[i]
        }),
        {}
      )

      setFilesData(filesData)
    }
  }

  // Read a file as text.
  const getFileData = (file) =>
    new Promise((resolve) => {
      const reader = new FileReader()
      reader.onload = (e) => resolve(e.target.result)
      reader.readAsText(file)
    })

  function handleChange(e) {
    const files = e.target.files
    handleFilesData(files)
  }

  const silenceEvent = (e) => {
    e.stopPropagation()
    e.preventDefault()
  }

  const handleDrop = (e) => {
    silenceEvent(e)
    const dt = e.dataTransfer
    const files = dt.files
    handleFilesData(files)
    setIsDraggingOver(false)
  }

  const handleDragEnter = (e) => {
    silenceEvent(e)
    setIsDraggingOver(true)
  }

  const handleDragLeave = (e) => {
    silenceEvent(e)
    setIsDraggingOver(false)
  }

  // Remove file from filesData.
  const handleRemove = (filename) => {
    const nextFilesData = { ...filesData }
    delete nextFilesData[filename]
    setFilesData(nextFilesData)
  }

  const getDropTargetProps = (props = {}) => ({
    onDragEnter: callAll(props.handleDragEnter, handleDragEnter),
    onDragLeave: callAll(props.handleDragLeave, handleDragLeave),
    onDragOver: callAll(props.silenceEvent, silenceEvent),
    onDrop: callAll(props.handleDrop, handleDrop),
    isDraggingOver
  })

  const uploadFile = () => {
    const fileInput = document.createElement("input")
    fileInput.style.display = "none"
    fileInput.setAttribute("type", "file")
    fileInput.setAttribute("accept", accept.join(","))
    fileInput.addEventListener("change", (e) => {
      handleChange(e)
      document.body.removeChild(fileInput)
    })
    document.body.appendChild(fileInput)
    fileInput.click()
  }

  const downloadFile = (filename) =>
    createDownloader(filesData[filename], filename)()

  return {
    filesData,
    uploadFile,
    downloadFile,
    handleChange,
    handleRemove,
    getDropTargetProps
  }
}

const FileInput = ({
  className,
  prompt,
  accept,
  maxFiles,
  onChange,
  filesData: filesDateProp
}) => {
  const {
    filesData,
    downloadFile,
    uploadFile,
    handleRemove,
    getDropTargetProps
  } = useFileInput({
    accept,
    maxFiles,
    onChange,
    filesData: filesDateProp
  })

  prompt =
    prompt || (maxFiles > 1 ? `Upload Files (${maxFiles})` : `Upload File`)

  return (
    <Container
      className={classnames("FileInput", className)}
      onClick={uploadFile}
      {...getDropTargetProps()}
    >
      {Object.keys(filesData).length ? (
        <ul>
          {Object.keys(filesData).map((filename) => (
            <li key={filename}>
              <div>
                <Pathicon icon="file" />
                <span>{filename}</span>
                <IconButton
                  styleType="secondary"
                  icon="download"
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    downloadFile(filename)
                  }}
                />
                <IconButton
                  styleType="secondary"
                  icon="x"
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    handleRemove(filename)
                  }}
                />
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <span>
          <Pathicon icon="upload" /> {prompt}
        </span>
      )}
    </Container>
  )
}

FileInput.displayName = "FileInput"

FileInput.propTypes = {
  accept: PropTypes.arrayOf(PropTypes.string),
  maxFiles: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  prompt: PropTypes.string,
  // Object with key/value pairs where key is the filename and value is the text of the file.
  filesData: PropTypes.object
}

FileInput.defaultProps = {
  maxFiles: 1,
  // Instantiating filesData as object in default props rather than in render method
  // to avoid new value on every render which causes useControlledState to setState.
  filesData: {}
}

export default FileInput
