import gql from "graphql-tag"

export default gql`
  query GroupShareContextQuery($type: ShareContextType!, $typeId: Int!) {
    school {
      id
      group: mentor_group(id: $typeId) {
        id
        name
        description
        type
        seat_limit
        seats_available
        seats_filled
        group_subscription {
          id
          subscription {
            id
            canceled_dtime
          }
        }
      }
    }
    sharePermissions(context: { type: $type, type_id: $typeId }) {
      ... on ShareGroupPermissions {
        type
        type_id
        add_limit
        allow_manage
        manage_url
        allow_activate
        activate_contact_email
        share_access {
          access
          gated_reason
        }
        share_link_access {
          access
          gated_reason
        }
        share_add_access {
          access
          gated_reason
        }
        share_track_access {
          access
          gated_reason
        }
        invite_to_roles {
          group_member: member
          group_moderator: moderator
          group_admin: admin
        }
        __typename
      }
    }
  }
`
