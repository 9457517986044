import { useMemo } from "react"
import { getIsSubscriptionUsable } from "../subscription/utils"
import { flattenEdges } from "../utils/apollo"
import useAuthAwareQuery from "../utils/useAuthAwareQuery"
import USER_COMMUNITY_GROUP_SUBSCRIPTIONS_QUERY from "./graphql/user-community-group-subscriptions-query"

export function useUserCommunityContext() {
  const communityQuery = useAuthAwareQuery(
    USER_COMMUNITY_GROUP_SUBSCRIPTIONS_QUERY
  )

  return useMemo(() => {
    let flattenedData = flattenEdges(communityQuery.data)
    let communityGroups = []
    let communityGroupMemberships = []
    let communityGroupSubscriptions = []
    let activeCommunityGroupSubscriptions = []
    let communityGroupsWithActiveSubscription = []
    let inactiveCommunityGroupSubscriptions = []
    let communityGroupsWithInactiveSubscription = []
    let isCommunityGroupOwner = false
    let isCommunityGroupAdmin = false
    if (flattenedData && flattenedData.me) {
      flattenedData.me.mentorGroupMemberships.map((membership) => {
        if (membership.role > 5) {
          isCommunityGroupAdmin = true
        }
        if (membership.group.owner.id === flattenedData.me.id) {
          isCommunityGroupOwner = true
        }
        communityGroupMemberships.push(membership)
        communityGroups.push(membership.group)
        membership.group.group_subscriptions.forEach((subscription) => {
          communityGroupSubscriptions.push(subscription)
          if (
            getIsSubscriptionUsable(subscription.subscription) &&
            !membership.access_revoked_time &&
            membership.group.seats_available >= 0
          ) {
            activeCommunityGroupSubscriptions.push(subscription)
            communityGroupsWithActiveSubscription.push(membership.group)
          } else {
            inactiveCommunityGroupSubscriptions.push(subscription)
            communityGroupsWithInactiveSubscription.push(membership.group)
          }
        })
      })
    }

    // Find if the user has an active membership for the provided group subscription.
    const hasActiveMembership = (group) => {
      if (!group) return false

      return communityGroupMemberships.some((membership) => {
        return (
          membership.group.id === group.id && !membership.access_revoked_time
        )
      })
    }

    return {
      communityQuery,
      communityGroups,
      isCommunityGroupOwner,
      isCommunityGroupAdmin,
      communityGroupMemberships,
      communityGroupSubscriptions,
      activeCommunityGroupSubscriptions,
      activeCommunityGroupSubscription: activeCommunityGroupSubscriptions[0],
      communityGroupsWithActiveSubscription,
      communityGroupWithActiveSubscription:
        communityGroupsWithActiveSubscription[0],
      inactiveCommunityGroupSubscriptions,
      inactiveCommunityGroupSubscription:
        inactiveCommunityGroupSubscriptions[0],
      communityGroupsWithInactiveSubscription,
      communityGroupWithInactiveSubscription:
        communityGroupsWithInactiveSubscription[0],
      hasActiveMembership
    }
  }, [communityQuery.data])
}

export const withUserCommunity = (Component) => (props) => {
  const communityProps = useUserCommunityContext()
  return <Component {...communityProps} {...props} />
}
