import { InMemoryCache } from "@apollo/client"
import {
  FieldMergeFunction,
  KeyArgsFunction,
  TypePolicies
} from "@apollo/client/cache/inmemory/policies"
import mergeWith from "lodash/mergeWith"

const getConnectionKeyArgs: KeyArgsFunction = (args, context) => {
  if (args) {
    return Object.keys(args).filter((arg) => arg !== "first" && arg !== "after")
  } else {
    console.warn(
      `Attempting to get connection key args for "${context.typename}", which is likely not a valid connection type.`
    )
  }
}

const mergeEdges: FieldMergeFunction = (
  existing,
  incoming,
  { args, readField, mergeObjects }
) => {
  // return existing and incoming merged together
  return existing
    ? mergeWith(
        {}, // merge into new object (apollo prevents mutation)
        existing,
        incoming,
        // customize merge behavior
        (objValue, srcValue, key, object, source) => {
          // concat rather than merge the new edges
          if (key === "edges" && objValue && srcValue) {
            // Reverse the src items if the merge is not the result of pagination.
            // The assumption is that we're either updating items in the list,
            // or we're remove item(s) from the list.
            let items = args?.after ? [...objValue] : [...srcValue]
            let srcItems = args?.after ? [...srcValue] : [...objValue]

            items = items.reduce((acc, item) => {
              let nextItem = item

              // Find the index of a pontentially matching src item, meaning,
              // the incoming item alredy exists, so we shouldn't concatenate it,
              // but rather merge it.
              const matchingSrcItemIndex = srcItems.findIndex(
                (srcItem) =>
                  // NOTE: all nodes should have a ref, but there could be cases where we are not
                  // including one on the schema, or in the GQL request. Only comparing truthy refs
                  // will avoid matching items based on undefined refs (and thereby removing the matching item).
                  item.node.__ref &&
                  srcItem.node.__ref &&
                  item.node.__ref === srcItem.node.__ref
              )
              if (matchingSrcItemIndex > -1) {
                // Find and remove the matching src item.
                const matchingSrcItem = srcItems.splice(
                  matchingSrcItemIndex,
                  1
                )[0]
                // Merge the src item with the existing item.
                nextItem = mergeObjects(item, matchingSrcItem)
              }

              // Always push the next item.
              acc.push(nextItem)
              return acc
            }, [])

            // Return all items if paginating.
            if (args?.after) {
              // Finally concatenate all the remaining src items.
              items = [...items, ...srcItems]
            } else {
              // Return only the first page of items if not paginating.
              // This seems to resolve pagination issues where, if a component remounts,
              // it may pull from cached data, which will contain multiple pages of data,
              // but will use the initial page cursor for paginating. By only resolving
              // the first page here, we ensure the pagination will actually result in a
              // subsequent page of data being loaded (either from cache or from network).
              items = items.slice(0, args?.first)
            }

            return items
          }
          // otherwise default merge behavior
        }
      )
    : incoming
}

const connectionField = {
  keyArgs: getConnectionKeyArgs,
  merge: mergeEdges
}

export const typePolicies: TypePolicies = {
  Query: {
    fields: {
      groups: connectionField,
      activity: connectionField,
      resources: connectionField,
      categories: connectionField,
      authors: connectionField,
      resource_licenses: connectionField,
      orders: connectionField,
      pathItems: connectionField,
      discussions: connectionField,
      discussionParticipants: connectionField,
      responses: connectionField,
      messages: connectionField,
      subscriptions: connectionField,
      stripeProducts: connectionField,
      storeLinks: connectionField,
      schoolGroupSubscriptions: connectionField,
      contextualFeaturePermissions: connectionField,
      tags: connectionField,
      tagAttachments: connectionField,
      notes: connectionField,
      inboxPaths: connectionField,
      inboxGroups: connectionField,
      inboxReviewers: connectionField,
      inboxGroupConnections: connectionField,
      inboxPeopleCompletionCounts: connectionField,
      spaceConnections: connectionField,
      peopleAndGroupConnection: connectionField,
      peopleAndGroupConnections: connectionField,
      invitations: connectionField
    }
  },
  User: {
    fields: {
      memberships: connectionField,
      registrations: connectionField,
      mentorGroupMemberships: connectionField,
      school_group_subscriptions: connectionField
    }
  },
  School: {
    fields: {
      subscription_plans: connectionField,
      curriculum_plans: connectionField,
      activity: connectionField,
      resources: connectionField,
      registrations: connectionField,
      pathItems: connectionField,
      publishers: connectionField,
      authors: connectionField,
      banners: connectionField,
      categories: connectionField,
      subscriptions: connectionField,
      curriculum_subscriptions: connectionField,
      mentor_groups: connectionField,
      members: connectionField,
      pages: connectionField,
      school_billing_invoices: connectionField,
      storeLinks: connectionField
    }
  },
  SchoolBillingSubscription: {
    fields: {
      child_subscriptions: connectionField
    }
  },
  SchoolBillingPlan: {
    fields: {
      billing_blocks: connectionField
    }
  },
  Resource: {
    fields: {
      categories: connectionField,
      authors: connectionField,
      groups: connectionField,
      license_offerings: connectionField,
      school_licenses: connectionField
    }
  },
  Group: {
    fields: {
      include_in_plans: connectionField,
      include_with_products: connectionField,
      registrations: connectionField
    }
  },
  Path: {
    fields: {
      items: connectionField,
      previewable_steps: connectionField,
      published_changes: connectionField
    }
  },
  PathItem: {
    fields: {
      items: connectionField,
      reminders: connectionField
    }
  },
  StripeProduct: {
    fields: {
      stripe_prices: connectionField
    }
  },
  MentorGroup: {
    fields: {
      group_subscriptions: connectionField,
      child_groups: connectionField,
      flattened_child_groups: connectionField,
      memberships: connectionField,
      invitations: connectionField
    }
  },
  LicenseOffering: {
    fields: {
      include_in_curriculum_plans: connectionField,
      include_in_subscription_plans: connectionField,
      include_in_school_curriculum_subscriptions: connectionField
    }
  },
  Discussion: {
    fields: {
      responses: connectionField,
      tagLinks: connectionField
    }
  },
  Response: {
    fields: {
      responses: connectionField
    }
  },
  Tag: {
    fields: {
      tagAttachments: connectionField
    }
  },
  PathwrightAdmin: {
    fields: {
      schools: connectionField
    }
  },
  DiscussionParticipants: {
    fields: {
      memberships: connectionField,
      registrations: connectionField,
      mentorGroupMemberships: connectionField,
      school_group_subscriptions: connectionField
    }
  },
  // InboxGroupConnectionEdge: {
  //   fields: {
  //     node: connectionField
  //   }
  // },
  // SpaceConnectionEdge: {
  //   fields: {
  //     node: connectionField
  //   }
  // },
  // PeopleAndGroupConnectionEdge: {
  //   fields: {
  //     node: connectionField
  //   }
  // },
  ContextualFeaturePermission: {
    //  ContextualFeaturePermission sometimes has a null value for ID, so
    //  must derive the dataId from identifying values on the object.
    keyFields: [
      "id",
      "feature_key",
      "feature_action",
      "permission_in_context",
      "school_id",
      "resource_id",
      "cohort_id"
    ]
  },
  PeopleAndGroupConnection: {
    keyFields: ["type", "id", "role"]
  },
  NodeMeta: {
    keyFields: ["key"]
  },
  RoleIn: {
    keyFields: ["user_id", "type", "type_id"]
  },
  SharePermissions: {
    keyFields: ["type", "type_id"]
  },
  ContextPermissions: {
    merge: true
  },
  Points: {
    keyFields: ["path_id"]
  },
  PathSync: {
    keyFields: ["path_id"]
  },
  // NOTE: this isn't actually ensuring separate media queries are cached!
  Media: {
    keyFields: ["image"]
  }
}

const cache = new InMemoryCache({
  typePolicies
})

export default cache
