import { useMutation } from "@apollo/client"
import {
  FIRST_POSITION,
  positionAfter
} from "@pathwright/ui/src/components/utils/fractional-positioning"
import { useCallback } from "react"
import {
  CREATE_TAG_ATTACHMENT_MUTATION,
  CREATE_TAG_LINK_MUTATION,
  CREATE_TAG_MUTATION
} from "./graphql"
import useRefetchTagQueries from "./useRefetchTagQueries"
import {
  getTagAttachment,
  getTagData,
  getTagLinksContext,
  getTagsContext
} from "./utils"

const useSelectTags = ({ context, tags }) => {
  const refetchTagQueries = useRefetchTagQueries(context)

  const [
    createTagMutation
    // createTagMutationState
  ] = useMutation(CREATE_TAG_MUTATION)

  const [
    createTagLinkMutation
    // createTagLinkMutationState
  ] = useMutation(CREATE_TAG_LINK_MUTATION)

  const [
    createTagAttachmentMutation
    // createTagAttachmentMutationState
  ] = useMutation(CREATE_TAG_ATTACHMENT_MUTATION)

  // Handle adding the selected tags, whether that means creating a new tag or creating
  // a new tagAttachment for a new/existing tag for the provided context.
  const selectTags = useCallback(
    async (selectedTags, modifiedTagLinksContext) => {
      // Accepting the second argument modifiedTagLinksContext to optionally
      // modify the tagLinksContext with a dynamic context at execution time.
      const tagsContext = getTagsContext(context)
      const tagLinksContext = getTagLinksContext(
        context,
        modifiedTagLinksContext
      )

      // Those tags that are entering selection.
      const addedTags = selectedTags.filter(
        (selectedTag) =>
          !tags.selected.find((tag) => tag.name === selectedTag.name)
      )

      // If selectedTags tags contain new tags, perform createTagMutation per new tag.
      const tagsToCreate = selectedTags.filter(
        (selectedTag) => !tags.all.find((tag) => tag.name === selectedTag.name)
      )

      // If tagsContext, then perform createTagAttachmentMutation per tag entering context.
      const tagsToAttach = tagsContext
        ? addedTags.filter((tag) => !getTagAttachment(tag))
        : []

      // If tagLinksContext, then perform createTagLinkMutation per tag entering context.
      const tagsToLink = tagLinksContext ? addedTags.slice() : []

      // For each new tag, perform a createTagMutation.
      // NOTE: all createTagMutations must take place before any createTagAttachmentMutations,
      // or at least before any createTagAttachmentMutations related to the tagsToCreate to avoid
      // any race conditions.
      const createdTagResults = await Promise.all(
        tagsToCreate.map((tag) =>
          createTagMutation({
            variables: { tag: getTagData(tag) }
          })
        )
      )

      const createdTags = createdTagResults.map(({ data }) => data.createTag)

      // Merge created tags with tagsToAttach.
      if (tagsContext) {
        tagsToAttach.push(...createdTags)
      }

      // Get the IDs of the tags to attach, filtering out any undefineds
      // from those tags in tagsToAttach that had not yet been created.
      const tagIdsToAttach = [...tagsToAttach.map((tag) => tag.id)].filter(
        Boolean
      )

      let tagAttachmentPositions = []
      // If we're attaching tags, we gotta handle positioning.
      if (tagsToAttach.length) {
        // Acquire a list of positions to pass along with the createTagAttachmentMutations.
        // Explicitly passing the position, which is optional, prevents any sort of race condition
        // the backend might run into if it otherwise had to calculate the position itself, which
        // it does at creation time basing the position off the last tagAttachment's position.

        // Depending on the context, base last position of of last filtered or last selected tag.
        const lastTagAttachment = tagLinksContext
          ? getTagAttachment(tags.filtered[tags.filtered.length - 1])
          : getTagAttachment(tags.selected[tags.selected.length - 1])
        // Sequentially find the next last position derrived from the previos position.
        tagAttachmentPositions = tagIdsToAttach.reduce((positions, _) => {
          // Get the next position to push into positions list.
          const position = positions.length
            ? // Get the position after the last position in the list.
              positionAfter(positions[positions.length - 1])
            : lastTagAttachment
            ? // Or start with the position after the position of the lastTagAttachment.
              positionAfter(lastTagAttachment.position)
            : // Or start with the FIRST_POSITION.
              FIRST_POSITION

          return [...positions, position]
        }, [])
      }

      // For each tag entering the tagsContext, perform a createTagAttachmentMutation.
      // NOTE: all createTagAttachmentMutations must take place before any createTagLinkMutations,
      // or at least before any createTagLinkMutations related to the tagsToAttach to avoid
      // any race conditions.
      await Promise.all(
        tagIdsToAttach.map((tagId, i) =>
          createTagAttachmentMutation({
            variables: {
              tagId,
              context: tagsContext,
              position: tagAttachmentPositions[i]
            }
          })
        )
      )

      if (tagLinksContext) {
        tagsToLink.push(...createdTags)
      }

      // Get the IDs of the tags to link, filtering out any undefineds
      // from those tags in tagsToLink that had not yet been created.
      const tagIdsToLink = [...tagsToLink.map((tag) => tag.id)].filter(Boolean)

      // For each tag entering the tagLinksContext, perform a createTagLinkMutation.
      await Promise.all(
        tagIdsToLink.map((tagId) =>
          createTagLinkMutation({
            variables: { tagId, context: tagLinksContext }
          })
        )
      )

      // Once all mutations have finished, we'll requery tags for top-level context
      // and current context, if provided.
      if (tagsToCreate.length || tagsToAttach.length) {
        await refetchTagQueries()
      }
    },
    [tags]
  )

  return selectTags
}

export default useSelectTags
