import { useShareDataContext } from "../shared/ShareDataContextProvider"
import CohortShareLimit from "./CohortShareLimit"

const limits = {
  cohort: CohortShareLimit
  // TODO: "group" and "school" limits.
}

const ShareLimit = () => {
  const { context, permissions } = useShareDataContext()
  const ShareLimit = limits[context.type]
  return permissions.add_limit >= 0 && ShareLimit ? <ShareLimit /> : null
}

export default ShareLimit
