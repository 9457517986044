import { graphql } from "@apollo/client/react/hoc"
import IconButton from "@pathwright/ui/src/components/button/IconButton"
import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import Card from "@pathwright/ui/src/components/card/Card"
import CardBlock from "@pathwright/ui/src/components/card/CardBlock"
import RedactorRichText from "@pathwright/ui/src/components/form/form-richtext/RedactorRichText"
import TextInput from "@pathwright/ui/src/components/form/form-text-input/TextInput"
import TextArea from "@pathwright/ui/src/components/form/form-textarea/TextArea"
import ToggleInput from "@pathwright/ui/src/components/form/form-toggle/ToggleInput"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import { RESOURCE_TYPE_LABELS } from "@pathwright/web/src/modules/resource/constants"
import compose from "lodash/flowRight"
import path from "path-browserify"
import PropTypes from "prop-types"
import { Field, Form, Submit, validators } from "react-connect-form-forked"
import AuthorsQuery from "../../author/graphql/AuthorsQuery"
import CategoriesQuery from "../../category/graphql/CategoriesQuery"
import SelectInput from "../../lib/SelectInput/SelectInput"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import { canEditLibrary } from "../../user/permissions"
import { flattenEdges } from "../../utils/apollo"
import ManageResourceHeader from "../header/ManageResourceHeader"
import MANAGE_RESOURCE_QUERY from "./graphql/manage-resource-query"
import UPDATE_RESOURCE_DETAILS_MUTATION from "./graphql/update-resource-details-mutation"

const StyledRedactorRichText = (props) => {
  return (
    <RedactorRichText
      {...Object.assign(
        { className: "ManageResourceDetails_RedactorRichText" },
        props
      )}
    />
  )
}

const ManageResourceDetails = ({
  card,
  resource,
  onSubmitSuccess,
  updateResource
}) => {
  const pwContext = usePathwrightContext()

  return (
    <Card
      card={card}
      title={resource && resource.name}
      className="ManageResourceDetails"
    >
      {resource ? (
        <>
          <Form
            initialValue={{
              name: resource.name,
              description: resource.description,
              short_description: resource.short_description,
              trailer_embed_code: resource.trailer_embed_code,
              categories: resource.categories,
              authors: resource.authors,
              visibility: resource.visibility_level_override === 15,
              cover_image: resource.image_raw
            }}
            onPristine={() => card.setCardDirty(false)}
            onDirty={() => card.setCardDirty(true)}
            onSubmit={(form) =>
              updateResource({
                id: resource.id,
                resource_type: RESOURCE_TYPE_LABELS[resource.resource_type],
                name: form.name,
                description: form.description,
                short_description: form.short_description,
                trailer_embed_code: form.trailer_embed_code,
                categories: form.categories.map((c) => c.id),
                authors: form.authors.map((a) => a.id),
                visibility_level_override: form.visibility ? 15 : null,
                cover_image: form.cover_image
              })
            }
            onSubmitSuccess={(...args) => {
              card.setCardDirty(false)
              return onSubmitSuccess(args)
            }}
          >
            <Submit component={SubmitButton} styleType="primary">
              Save
            </Submit>
            <ManageResourceHeader resource={resource} />
            <CardBlock>
              <Field
                name="name"
                id="course_name"
                type="text"
                component={TextInput}
                label="Title"
                placeholder="Enter the Path name"
                validations={[validators.isMinLength(3)]}
                topLabel
                required
              />
              <Field
                name="short_description"
                id="short_description"
                type="text"
                component={TextArea}
                label="Summary"
                placeholder="In one sentence, why should someone complete this Path?"
                readOnly={!!resource.licensed_from_resource_id}
                validators={[validators.isMaxLength(300)]}
                showLimit={300}
                maxRows={3}
                topLabel
              />
              <Field
                name="description"
                id="course_description"
                type="custom"
                className="ManageResourceDetails_RedactorRichText"
                component={StyledRedactorRichText}
                // component={RedactorRichTextInput}
                // theme="snow"
                // toolbar={TOOLBAR_CONFIG_TYPES.FULL}
                label="About"
                placeholder="Describe who should take this, what they’ll learn, features, and more..."
                readOnly={!!resource.licensed_from_resource_id}
                topLabel
              />
              <Field
                id="trailer_embed_code"
                name="trailer_embed_code"
                type="text"
                component={TextArea}
                label="Video preview"
                placeholder="Enter a video embed code to display a video overview..."
                labelWidth={300}
                minRows={3}
                topLabel
              />
              {card.isActive && ( // forcing refetching authors from network by unmounting/remounting the AuthorsQuery
                <AuthorsQuery
                  first={50}
                  ids={{ nin: resource.authors.map((a) => a.id) }}
                  skipSearch={(search) => typeof search !== "string"}
                  manage
                >
                  {({ authors, loading, hasMore, loadMore, onSearch }) => (
                    <Field
                      name="authors"
                      id="course_authors"
                      type="custom"
                      component={SelectInput}
                      options={authors}
                      isLoading={loading}
                      readOnly={!!resource.licensed_from_resource_id}
                      onMenuScrollToBottom={() => hasMore && loadMore()}
                      label="Authors"
                      placeholder="Select authors..."
                      noResultsText="No authors found"
                      labelKey="full_name"
                      valueKey="id"
                      searchable
                      topLabel
                      multi
                      onInputChange={(val) => {
                        onSearch && onSearch(val, hasMore !== false)
                        // return val for react-select to handle
                        return val
                      }}
                      onFocus={() => onSearch && onSearch("", true)}
                      custom={
                        canEditLibrary(pwContext) && (
                          <IconButton
                            icon="gear"
                            color="#888"
                            size={25}
                            to={path.join(window.location.pathname, "/author/")}
                          />
                        )
                      }
                    />
                  )}
                </AuthorsQuery>
              )}
              {card.isActive && ( // forcing refetching categories from network by unmounting/remounting the CategoriesQuery
                <CategoriesQuery
                  first={50}
                  ids={{ nin: resource.categories.map((c) => c.id) }}
                  skipSearch={(search) => typeof search !== "string"}
                  manage
                >
                  {({
                    categories,
                    loading,
                    hasMore,
                    loadMore,
                    handleDebouncedSearch
                  }) => (
                    <Field
                      name="categories"
                      id="course_categories"
                      type="custom"
                      component={SelectInput}
                      isLoading={loading}
                      options={categories}
                      readOnly={!!resource.licensed_from_resource_id}
                      onMenuScrollToBottom={() => hasMore && loadMore()}
                      label="Categories"
                      placeholder="Select categories..."
                      noResultsText="No categories found"
                      labelKey="name"
                      valueKey="id"
                      searchable
                      topLabel
                      multi
                      onInputChange={(val) => {
                        hasMore !== false &&
                          handleDebouncedSearch &&
                          handleDebouncedSearch(val)
                        // return val for react-select to handle
                        return val
                      }}
                      onFocus={() =>
                        handleDebouncedSearch && handleDebouncedSearch("")
                      }
                      custom={
                        canEditLibrary(pwContext) && (
                          <IconButton
                            icon="gear"
                            color="#888"
                            size={25}
                            to={path.join(
                              window.location.pathname,
                              "/category/"
                            )}
                          />
                        )
                      }
                    />
                  )}
                </CategoriesQuery>
              )}
              <Field
                id="visibility"
                name="visibility"
                type="custom"
                tooltip="The Library only displays Paths to visitors and members that have at least one Cohort that’s visible to them. Toggle on this option if you’d like this Path to be visible in your Library to everyone at all times."
                label="Always show in Library"
                instructions="Display this Path and About page in your Library, even when there are no visible Cohorts."
                component={ToggleInput}
                labelWidth={300}
                alignRight
                topLabel
                layoutAsColumns
                style={{
                  marginTop: "10px"
                }}
              />
            </CardBlock>
          </Form>
        </>
      ) : (
        <LoadingCircle />
      )}
    </Card>
  )
}

ManageResourceDetails.displayName = "ManageResourceDetails"

ManageResourceDetails.propTypes = {
  id: PropTypes.number.isRequired
}

export default compose(
  graphql(MANAGE_RESOURCE_QUERY, {
    options: ({ id }) => ({
      variables: {
        id
      }
    }),
    props: ({ data }) => ({
      resource: flattenEdges(data.resource)
    })
  }),
  graphql(UPDATE_RESOURCE_DETAILS_MUTATION, {
    props: ({ mutate, ownProps }) => ({
      updateResource: (changes) =>
        mutate({
          variables: {
            id: ownProps.resource.id,
            name: ownProps.resource.name,
            resource_type:
              RESOURCE_TYPE_LABELS[ownProps.resource.resource_type],
            ...changes
          },
          refetchQueries: ["ManageResourceQuery"]
        })
    })
  })
)(ManageResourceDetails)
