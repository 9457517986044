import gql from "graphql-tag"
import ORDER_FRAGMENT from "./order-fragment"

export default gql`
  mutation CreateCurriculumSubscriptionOrder(
    $curriculum_plan_id: Int!
    $plan_visibility_code: String
    $licensee_id: Int!
  ) {
    createCurriculumSubscriptionOrder(
      curriculum_plan_id: $curriculum_plan_id
      plan_visibility_code: $plan_visibility_code
      licensee_id: $licensee_id
    ) {
      ...Order
    }
  }
  ${ORDER_FRAGMENT}
`
