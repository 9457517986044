import gql from "graphql-tag"

export default gql`
  fragment PathPerformance on PathPerformance {
    id
    points {
      total
      earned
      possible
      percentage
      applicable_score
      applicable_score_percentage
    }
    progress {
      steps_completed
      required_steps_completed
      total_steps
      total_required_steps
      percentage
    }
  }
`
