import gql from "graphql-tag"
import AUTHOR_FRAGMENT from "../../../author/graphql/author-fragment"
import CATEGORY_FRAGMENT from "../../../category/graphql/category-fragment"
import RESOURCE_FRAGMENT from "../../graphql/resource-fragment"

export default gql`
  query ManageResourceQuery(
    $id: Int
    $authors_cursor: String
    $categories_cursor: String
  ) {
    resource(id: $id) {
      ...Resource
      registration_meta {
        editor_registration_count
      }
      trailer_embed_code
      visibility_level_override
      certificate_generator_class
      licensed_from_resource_id
      authors(first: 50, after: $authors_cursor) {
        total
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            ...Author
          }
        }
      }
      categories(first: 50, after: $categories_cursor) {
        total
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            ...Category
          }
        }
      }
      groups(first: 1) {
        total
        edges {
          node {
            id
            seats_filled
          }
        }
      }
    }
  }
  ${RESOURCE_FRAGMENT}
  ${CATEGORY_FRAGMENT}
  ${AUTHOR_FRAGMENT}
`
