import gql from "graphql-tag"
import PATH_ITEMS_FRAGMENT from "./path-items-fragment"

export default gql`
  query PathQuery($id: Int, $cohort_id: Int, $cursor: String) {
    path(id: $id, cohort_id: $cohort_id) {
      ...PathItems
      schedule {
        start {
          behavior
          date
        }
        end {
          behavior
          date
        }
      }
    }
  }
  ${PATH_ITEMS_FRAGMENT}
`
