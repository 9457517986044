import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo
} from "react"
import { TipStatus } from "./types"

type TipsContextValue = {
  getTipStatus: (id: string) => TipStatus
  setTipStatus: (id: string, status: TipStatus) => void
}

const initialState = {
  getTipStatus: (id: string) => null,
  setTipStatus: (id: string, status: TipStatus) => null
}

const TipsContext = createContext<TipsContextValue>(initialState)
export const useTips = () => useContext<TipsContextValue>(TipsContext)

const TipsProvider = ({ children }: { children: ReactNode }) => {
  const getTipStatus = useCallback(
    (id: string): TipStatus => {
      return localStorage.getItem(`tip-${id}`) as TipStatus
    },
    [localStorage]
  )

  const setTipStatus = useCallback(
    (id: string, status: TipStatus) => {
      localStorage.setItem(`tip-${id}`, status!)
    },
    [localStorage]
  )

  const contextValue = useMemo(
    () => ({
      getTipStatus,
      setTipStatus
    }),
    [getTipStatus, setTipStatus]
  )

  return (
    <TipsContext.Provider value={contextValue}>{children}</TipsContext.Provider>
  )
}

export default TipsProvider
