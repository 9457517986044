import gql from "graphql-tag"

export default gql`
  fragment Banner on Banner {
    id
    order
    heading
    subhead
    font_color
    background_image_raw
    background_image(width: 1600, fit: crop)
    background_type
    background_overlay
    button_func
    button_url
    button_text
    is_visible
    hide_for_subscription_plans
    show_for_subscription_plans
    hide_for_products
    show_for_products
  }
`
