import Button from "@pathwright/ui/src/components/button/Button"
import IconButton from "@pathwright/ui/src/components/button/IconButton"
// import Link from "@pathwright/ui/src/components/link/Link"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import classNames from "classnames"
import { useEffect, useState } from "react"
import styled from "styled-components"
import { usePreMountLocation } from "../../utils/usePreviousLocation"
import InboxSelector from "../navigator/InboxSelector"
import { useReviewContext } from "../state/ReviewContextProvider"
import useReviewStack from "../state/useReviewStack"
import {
  INBOX_CONTEXT_PANEL_THRESHOLD,
  INBOX_LAYOUT_NAV_HEIGHT
} from "../ui/constants"
import { What } from "../ui/meta"
import { getItemUrl } from "../utils"
import { CompletionAvatar } from "./InboxFocusCompletionItem"

const PrevNextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #fff;
  flex-shrink: 0;

  .LoadingCircle {
    position: static !important;
    margin-left: 10px;
    margin-right: 10px;
  }
`

const useAwaitingNextItem = () => {
  const { selected } = useReviewStack()
  const {
    context: { loadingMore }
  } = useReviewContext()
  // More gracefully await the next item when paginating.
  const [awaitingNext, setAwaitingNext] = useState(false)

  useEffect(() => {
    if (loadingMore) setAwaitingNext(true)
  }, [loadingMore])

  useEffect(() => {
    if (awaitingNext) setAwaitingNext(false)
  }, [selected.index])

  return awaitingNext
}

const PrevNext = () => {
  const {
    selected,
    actions: { next, previous }
  } = useReviewStack()
  const {
    context: { total }
  } = useReviewContext()

  const awaitingNext = useAwaitingNextItem()

  if (!selected) return null

  return (
    <PrevNextContainer className="PrevNext">
      <IconButton
        icon="chevron-left"
        disabled={!previous || awaitingNext}
        onClick={previous}
        inverted
      />
      {selected.index + 1}/{total}
      {awaitingNext ? (
        <LoadingCircle size={15} inverted />
      ) : (
        <IconButton
          icon="chevron-right"
          disabled={!next}
          onClick={next}
          inverted
        />
      )}
    </PrevNextContainer>
  )
}

const NavBar = styled.nav`
  position: relative;
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: ${INBOX_LAYOUT_NAV_HEIGHT}px;
  z-index: 100;
  flex-shrink: 0;
  width: 100%;
  @media (max-width: ${INBOX_CONTEXT_PANEL_THRESHOLD}px) {
    .CompletionFilters {
      label {
        display: none;
      }
    }
  }

  /* padding: 0 10px; */
`

const SelectedItemTitleContainer = styled.div`
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.9em;

  max-width: calc(100% - 190px);

  /* add a text-shadow */
  /* text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5); */

  .CompletionAvatar {
    margin-right: 10px;
  }

  h1 {
    font-size: 1em;
    font-weight: normal;
    margin-right: 4px;
  }
  > span,
  > div {
    margin-right: 4px;
  }

  > h1,
  > span,
  > a,
  > a .Button__content-wrapper {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  > a .Button__content-wrapper {
    justify-content: flex-start;
  }

  > a:not(:hover) {
    border-color: transparent;
  }
`

const SelectedItemTitle = () => {
  const { selected } = useReviewContext()
  const { user } = selected

  return (
    <SelectedItemTitleContainer>
      <CompletionAvatar item={selected} size={36} />
      <h1>{user.display_name}</h1>
      <Pathicon icon="chevron-right" />
      <Button
        href={getItemUrl({ selected })}
        target="_blank"
        styleType="text"
        color="#fff"
      >
        <What item={selected} hideVerb={true} />
      </Button>
    </SelectedItemTitleContainer>
  )
}

const SelectedItemNavigationContainer = styled(NavBar)`
  /* justify-content: space-around; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding: 20px; */

  > .IconButton__chevron-left {
    position: absolute;
    left: 10px;
    top: calc(50% - 15px);
    z-index: 100;
  }

  > .IconButton__x {
    position: absolute;
    right: 10px;
    top: calc(50% - 15px);
    z-index: 100;
  }

  > .PrevNext {
    position: absolute;
    right: 10px;
    top: calc(50% - 15px);
    z-index: 100;
  }
`

export const SelectedItemNavigation = () => {
  const {
    actions: { clearSelection }
  } = useReviewContext()

  const handleClose = () => {
    clearSelection(null)
  }

  return (
    <SelectedItemNavigationContainer className="SelectedItemNavigation">
      <IconButton inverted icon="chevron-left" onClick={handleClose} />
      <SelectedItemTitle />
      <PrevNext />
    </SelectedItemNavigationContainer>
  )
}

const SingleItemNavigation = () => (
  <SelectedItemNavigationContainer>
    <IconButton inverted icon="x" to={useInboxCloseUrl()} />
    <SelectedItemTitle />
  </SelectedItemNavigationContainer>
)

const ListNavContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  > .IconButton__x {
    position: absolute;
    right: 10px;
  }
`

const ListItemsNavigation = () => {
  return (
    <ListNavContainer>
      <InboxSelector />
      <IconButton inverted className="close" icon="x" to={useInboxCloseUrl()} />
    </ListNavContainer>
  )
}

const NavigationBarContainer = styled.div`
  /* position: fixed; */
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  backdrop-filter: blur(10px);

  padding: 0px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &.selected-item {
    visibility: hidden;
  }

  .IconButton.close {
    margin-left: auto;
    margin-right: 10px;
  }
`

const useInboxCloseUrl = () => {
  const { root, selected } = useReviewContext()
  return usePreMountLocation(getItemUrl({ root, selected }))
}

export const InboxFocusNavigationBar = () => {
  const { isSingleItemMode } = useReviewStack()
  return (
    <NavigationBarContainer className="InboxFocusNavigationBar">
      {isSingleItemMode ? <SingleItemNavigation /> : <SelectedItemNavigation />}
    </NavigationBarContainer>
  )
}

const InboxNavigationBar = () => {
  const { selected } = useReviewContext()

  let bgColor = "rgba(0, 0, 0, 0.333)" //path ? getBGColor(path.data.resource) : "rgba(0, 0, 0, 0.7)"

  const className = classNames("InboxNavigationBar", {
    ["selected-item"]: !!selected
  })

  return (
    <NavigationBarContainer
      className={className}
      style={{ backgroundColor: bgColor }}
    >
      <ListItemsNavigation />
    </NavigationBarContainer>
  )
}

export default InboxNavigationBar
