import { Link, Text } from "@chakra-ui/react"
import i18next from "i18next"
import { Trans } from "react-i18next"
import { useTermsAgreementQuery } from "../../../api/generated"
import { useScopedTranslation } from "../../../lib/lng/TranslationsProvider"
import { useSpaceContext } from "../../space/api/context"

const TermsAgreement = () => {
  const { t } = useScopedTranslation()
  const { space } = useSpaceContext()
  const termsAgreementQuery = useTermsAgreementQuery()
  const termsAgreementPage = termsAgreementQuery.data?.school?.page
  let termsAgreementPageLink = termsAgreementPage
    ? termsAgreementPage.external_url
      ? termsAgreementPage.external_url
      : `${window.location.origin}/p/${termsAgreementPage.slug}/`
    : ""

  // In some cases we may not have the origin, which can trip up the Link component.
  if (!termsAgreementPageLink.startsWith("http")) {
    termsAgreementPageLink = `https://${termsAgreementPageLink}`
  }

  const termsLinkProps = {
    target: "_blank",
    rel: "noopener noreferrer",
    color: "brand.500"
  }

  return termsAgreementPage ? (
    i18next.exists("account.terms_agreement_with_school_terms") ? (
      // prettier-ignore
      // Trans formatting integral to translation json!
      <Text>
        <Trans i18nKey="account.terms_agreement_with_school_terms">
          {"By clicking the "}
          {{ button_label: `"${t("auth.sign_in_form.continue")}" `}}
          {"button below you agree to "}
          {{ school_name_possessive: `${space.name}'s ` }}
          <Link {...termsLinkProps} href={termsAgreementPageLink}>
            {/* @ts-ignore */}
            {{ school_terms_of_use: termsAgreementPage.name }}
          </Link>
          {" and the platform "}
          <Link
            {...termsLinkProps}
            href="https://www.pathwright.com/privacy-policy/"
          >
            {/* @ts-ignore */}
            {{ pathwright_privacy_policy: t(`account.privacy_policy`) }}
          </Link>
          {" & "}
          <Link
            {...termsLinkProps}
            href="https://www.pathwright.com/terms-of-use/"
          >
            {/* @ts-ignore */}
            {{ pathwright_terms_of_use: t(`account.terms_of_use`) }}
          </Link>
          {"."}
        </Trans>
      </Text>
    ) : (
      // prettier-ignore
      // Trans formatting integral to translation json!
      <Text>
        <Trans i18nKey="account.school_terms_aggrement">
          {"By clicking the "}
          {{ button_label: `"${t("auth.sign_in_form.continue")}" `}}
          {" button below you agree to "}
          {{ school: `${space.name}'s ` }}
          <Link {...termsLinkProps} href={termsAgreementPageLink}>
            {/* @ts-ignore */}
            {{ terms_of_use: termsAgreementPage.name }}
          </Link>
          {"."}
        </Trans>
      </Text>
    )
  ) : null
}

export default TermsAgreement
