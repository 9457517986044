import React, { Suspense } from "react"
import { PathiconProps } from "./ExpensivePathicon"

const LazyPathicon = React.lazy(
  () => import("./ExpensivePathicon" /* webpackChunkName: "Pathicons" */)
)

const Pathicon = React.forwardRef((props: PathiconProps, ref) => (
  <Suspense fallback={null}>
    <LazyPathicon ref={ref} {...props} />
  </Suspense>
))

export default Pathicon
