import gql from "graphql-tag"
import SCHOOL_FRAGMENT from "../../school/graphql/school-fragment"
import MENTOR_GROUP_FRAGMENT from "./mentor-group-fragment"
import MENTOR_GROUP_MEMBERSHIP_FRAGMENT from "./mentor-group-membership-fragment"

export default gql`
  query MentorGroup($id: Int!) {
    school {
      ...School
      mentor_group(id: $id) {
        ...MentorGroup
        user_membership {
          ...MentorGroupMembership
        }
        parent {
          ...MentorGroup
        }
      }
    }
  }
  ${SCHOOL_FRAGMENT}
  ${MENTOR_GROUP_FRAGMENT}
  ${MENTOR_GROUP_MEMBERSHIP_FRAGMENT}
`
