import Button from "@pathwright/ui/src/components/button/Button"
import IconLabel from "@pathwright/ui/src/components/label/IconLabel"
import Link from "@pathwright/ui/src/components/link/Link"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import Tooltip from "@pathwright/ui/src/components/tooltip/Tooltip"
import PropTypes from "prop-types"
import styled from "styled-components"

const FeatureBlockUI = ({ featureInfo, featurePermissionInfo, to }) => {
  const { t } = useTranslate()

  return (
    <Container className="FeatureBlock">
      <PathiconContainer>
        {typeof featureInfo.icon === "string" ? (
          <Pathicon icon={featureInfo.icon} />
        ) : (
          featureInfo.icon
        )}
      </PathiconContainer>
      <ContentContainer>
        <h3>{featureInfo.title}</h3>
        <p>{featureInfo.desc}</p>
        {!!featurePermissionInfo && (
          <IconLabel
            icon={featurePermissionInfo.icon}
            label={featurePermissionInfo.title}
          />
        )}
      </ContentContainer>
      {to ? (
        <>
          <Pathicon icon="chevron-right" />
          <Link to={to} />
        </>
      ) : (
        <Tooltip
          title={
            <>
              {featureInfo.tooltip}
              <br />
              <Button
                inverted
                styleType="text"
                href={featureInfo.link}
                target="_blank"
                postIcon="chevron-right"
                label={t("Learn more")}
              />
            </>
          }
          alignment="center"
          placement="left"
          transition="transition.slideRightIn"
        >
          <Pathicon icon="info-circle" />
        </Tooltip>
      )}
    </Container>
  )
}

FeatureBlockUI.displayName = "FeatureBlockUI"

FeatureBlockUI.propTypes = {
  featureInfo: PropTypes.shape({
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    title: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired
  }).isRequired,
  featurePermissionInfo: PropTypes.shape({
    icon: PropTypes.string,
    title: PropTypes.string
  }),
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
}

const Container = styled.div`
  --feature-block-spacer: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: calc(var(--feature-block-spacer) * 2.5);
  border-radius: 20px;
  box-shadow: 0 2px 5px 0 rgb(0, 0, 0, 0.1);
  background-color: white;

  h3 {
    font-weight: 400;
    margin: 0;
    margin-bottom: var(--feature-block-spacer);
  }

  p {
    color: rgb(161, 161, 161);
    margin: 0;
    font-size: 0.9em;
  }

  .IconLabel {
    font-size: 0.8em;
    margin-top: calc(var(--feature-block-spacer) * 2);
  }

  .Link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .Pathicon.pathicon-chevron-right {
    font-size: 1.5em;
  }

  .Tooltip {
    min-width: 200px;
    white-space: normal;
    text-align: left;
  }

  .Tooltip__trigger {
    position: absolute !important;
    top: calc(var(--feature-block-spacer) * 2);
    right: calc(var(--feature-block-spacer) * 2);
    /* Increase target area */
    padding: 1em;
    padding-left: 0;
    margin-top: -1em;
    margin-right: -1em;
  }
`

const PathiconContainer = styled.div`
  background-color: #db8b2d;
  color: white;
  border-radius: 12px;
  height: 4em;
  width: 4em;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  flex-shrink: 0;
  margin-right: calc(var(--feature-block-spacer) * 2);

  .Pathicon {
    font-size: 2em;
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-right: 1.5em;
  flex-grow: 1;UI
`

export default FeatureBlockUI
