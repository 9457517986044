import { useChangePasswordAction } from "./api/action"
import PasswordChangeView from "./PasswordChangeView"

const PasswordChangeViewContainer = ({
  onContinue
}: {
  onContinue: Function
}) => {
  const [state, action] = useChangePasswordAction()

  const handleSubmit = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.currentTarget)
    await action(formData)
  }

  return (
    <PasswordChangeView
      data={state.value}
      loading={state.loading}
      success={!!state.value}
      error={state.error?.message}
      onSubmit={handleSubmit}
      onContinue={onContinue}
    />
  )
}

export default PasswordChangeViewContainer
