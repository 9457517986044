import { ConnectStores } from "lib/core/store"
import PropTypes from "prop-types"
import ActivationLockedFallback from "./ActivationLockedFallback"

// PathwrightFeature locks down wrapped children
// unless the pathwright account includes the
// appropriate features. If no `has` props is
// provided then PathwrightFeature simply checks
// that the school is activated.

// Provide children as a function to override
// default behavior and defer show/hide logic
// for children to handle.

// Provide a `fallback` component to show a
// blank slate instead of nothing.

const PathwrightFeature = (props) => {
  const { children, fallback, enabled } = props

  if (_.isFunction(children)) {
    return children(enabled)
  } else if (enabled && children) {
    return React.cloneElement(children)
  } else if (fallback) {
    return React.createElement(fallback)
  } else {
    return null
  }
}

PathwrightFeature.displayName = "PathwrightFeature"
PathwrightFeature.propTypes = {
  has: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string
  ]),
  fallback: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  activationLock: PropTypes.bool,
  activationLocked: PropTypes.bool
}

const mapStateToProps = (state, ownProps) => {
  const { hasFeatures } = state.pathwrightAccountStore.request
  const { is_activated } = state.pathwrightAccountStore.state
  const { has, activationLock, activationLocked } = ownProps
  const features = _.isArray(has) ? has : [has]
  const enabled = ownProps.hasOwnProperty("activationLocked")
    ? !activationLocked
    : activationLock
    ? is_activated
    : hasFeatures(features)

  // If this is an activation lock and no fallback is given, set it to default
  let fallback = ownProps.fallback
  if (
    (activationLock || ownProps.hasOwnProperty("activationLocked")) &&
    !fallback
  )
    fallback = ActivationLockedFallback

  return _.assign(
    {},
    {
      enabled,
      fallback
    },
    ownProps
  )
}

const stores = [
  { store: "pathwrightAccount", watch: ["subscription", "is_activated"] }
]

export default ConnectStores(PathwrightFeature, stores, {}, mapStateToProps)
