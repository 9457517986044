import classnames from "classnames"
import PropTypes from "prop-types"
import IconButton from "../../button/IconButton"
import LazyRedactorEditor from "../../richtext/LazyRedactorEditor"
import HtmlText from "../../text/HtmlText"
import FieldWrapper from "../form-utils/FieldWrapper"
import "./RedactorRichText.css"

// TODO: hide the toolbar when not in focus.
// CHALLENGES:
// - Redactor focus callbacks don't fire correctly in source mode.
// - Resulting height jumps can prevent clicks outside from intended
//   targets, and aren't the best user experience.

const RedactorRichText = function (props) {
  let {
    label,
    value,
    config,
    height,
    onChange,
    placeholder,
    minHeight,
    maxHeight,
    autoFocus,
    className
  } = props

  className = classnames(
    "RedactorRichText",
    { "RedactorRichText--empty": !value },
    className
  )

  const heightStyle = {
    minHeight: minHeight || height,
    maxHeight: maxHeight || height
  }

  return (
    <FieldWrapper {...Object.assign({}, props, { className: className })}>
      {({ focus, onFocus, onBlur }) => {
        return (
          <div className="RedactorRichText__input">
            {focus ? (
              <LazyRedactorEditor
                {...Object.assign({}, heightStyle, {
                  name: label,
                  value: value,
                  config: config,
                  onChange: onChange,
                  placeholder: placeholder,
                  toolbarOverflow: false,
                  autoFocus: true
                })}
              />
            ) : (
              <>
                <div className="RedactorRichText__preview" style={heightStyle}>
                  <HtmlText html={value || placeholder} />
                </div>
                {/* Necessary to put focus handler overlayed on top of RedactorRichText__preview
                    to catch the click event, rather than allowing content (like an iframe)
                    to eat it up. */}
                <div className="RedactorRichText__overlay" onClick={onFocus} />
              </>
            )}
            {focus && <IconButton icon="x" color="black" onClick={onBlur} />}
          </div>
        )
      }}
    </FieldWrapper>
  )
}

RedactorRichText.displayName = "RedactorRichText"
RedactorRichText.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  height: PropTypes.number,
  required: PropTypes.bool,
  focus: PropTypes.bool,
  // Show validation results
  showSuccess: PropTypes.bool,
  showError: PropTypes.bool,
  errorMessage: PropTypes.string,
  // Hook to TextInput events
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func
}

RedactorRichText.defaultProps = {
  config: "discussion",
  minHeight: 75
}

export default RedactorRichText
