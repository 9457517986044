import Page from "@pathwright/web/src/modules/account/Page"
import BlockContent from "@pathwright/web/src/modules/blocks/BlockContent"

const PageBlocks = ({ id }) => {
  return <BlockContent contextKey={`/page-${id}`} templateVariables={{}} />
}

PageBlocks.displayName = "PageBlocks"

const PageContainer = (props) => <Page {...props} PageBlocks={PageBlocks} />

PageContainer.displayName = "PageContainer"

export default PageContainer
