// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
// CSRF Protection on create/update/delete
// alias away the sync method
Backbone._sync = Backbone.sync
const Cookie = require("js-cookie")

// define a new sync method
const env = process.env.NODE_ENV || "development"

const { getPathwrightRequestHeaders } = require("lib/core/api/request")

Backbone.sync = function (method, model, options) {
  // only need a token for non-get requests
  options.beforeSend = function (xhr) {
    xhr.url = this.url
    if (method === "create" || method === "update" || method === "delete") {
      // CSRF token value is in an embedded meta tag
      let csrfToken = Cookie.get("csrftoken")
      if (!csrfToken) {
        csrfToken = bootstrappedData.security.csrf_token
      }

      if (!csrfToken) {
        const session = window.App.request("get:session")
        if (session) {
          csrfToken = session.get("csrf_token")
        }
      }

      if (csrfToken) {
        xhr.setRequestHeader("X-CSRFToken", csrfToken)
      }
    }

    const headers = getPathwrightRequestHeaders(xhr.url)

    return (() => {
      const result = []
      for (let header in headers) {
        const val = headers[header]
        result.push(xhr.setRequestHeader(header, val))
      }
      return result
    })()
  }

  // proxy the call to the old sync method
  return Backbone._sync(method, model, options)
}

// Setup Backbone Base Classes
window.BackboneUtils = {
  Model: require("lib/utils/model").default,
  Collection: require("lib/utils/collection").default,
  View: require("lib/utils/view").default
}
