import DropdownItem from "@pathwright/ui/src/components/dropdown/DropdownItem"
import AssignCard from "./AssignCard"
import {
  ASSIGN_ICONS,
  ASSIGN_TARGET_INDIVIDUAL,
  ASSIGN_TARGET_MENTOR_GROUP
} from "./constants"

const getAssignTargetType = (user, communityGroup) => {
  if (user) {
    return ASSIGN_TARGET_INDIVIDUAL
  } else if (communityGroup) {
    return ASSIGN_TARGET_MENTOR_GROUP
  }
}

const getAssignTargetParams = (userId, communityGroupId) => {
  const assignTargetType = getAssignTargetType(userId, communityGroupId)

  switch (assignTargetType) {
    case ASSIGN_TARGET_INDIVIDUAL:
      return { user: userId }
    case ASSIGN_TARGET_MENTOR_GROUP:
      return { community_group: communityGroupId }
    default:
      return {}
  }
}

const getAssignTargetProps = (userId, communityGroupId) => {
  const assignTargetType = getAssignTargetType(userId, communityGroupId)

  switch (assignTargetType) {
    case ASSIGN_TARGET_INDIVIDUAL:
      return { userId }
    case ASSIGN_TARGET_MENTOR_GROUP:
      return { communityGroupId }
    default:
      return {}
  }
}

// NOTE: assumes user, not handling communityGroup
export const getAssignTo = ({ userId, communityGroupId }) => {
  const assignTargetParams = getAssignTargetParams(userId, communityGroupId)
  const pathname = `${window.location.pathname}add-to-path/`

  return {
    pathname,
    query: assignTargetParams,
    // state: { setResourceLaunchedFrom: true },
    card: {
      component: AssignCard,
      props: getAssignTargetProps(userId, communityGroupId)
    }
  }
}

const AssignDropdownItem = ({
  userId,
  communityGroupId,
  ...dropDownItemProps
}) => {
  const assignTargetType = getAssignTargetType(userId, communityGroupId)

  return (
    <DropdownItem
      icon={ASSIGN_ICONS[assignTargetType]}
      label="Add to path..."
      to={getAssignTo({ userId, communityGroupId })}
      {...dropDownItemProps}
    />
  )
}

AssignDropdownItem.displayName = "AssignDropdownItem"

export default AssignDropdownItem
