import { useApolloClient } from "@apollo/client"
import { graphql } from "@apollo/client/react/hoc"
import produce from "immer"
import compose from "lodash/flowRight"
import get from "lodash/get"
import isEmpty from "lodash/isEmpty"
import throttle from "lodash/throttle"
import PropTypes from "prop-types"
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react"
import { createPaginator, flattenEdges } from "../utils/apollo"
import CREATE_DISCUSSION from "./graphql/create-discussion-mutation"
import CREATE_DISCUSSION_RESPONSE_LIKE from "./graphql/create-discussion-response-like-mutation"
import CREATE_DISCUSSION_RESPONSE from "./graphql/create-discussion-response-mutation"
import CREATE_DISCUSSION_SUBSCRIPTION from "./graphql/create-discussion-subscription-mutation"
import DELETE_DISCUSSION_RESPONSE_LIKE from "./graphql/delete-discussion-response-like-mutation"
import DELETE_DISCUSSION_RESPONSE from "./graphql/delete-discussion-response-mutation"
import DELETE_DISCUSSION_SUBSCRIPTION from "./graphql/delete-discussion-subscription-mutation"
import DISCUSSION_QUERY from "./graphql/discussion-query"
import DISCUSSION_RESPONSE_QUERY from "./graphql/discussion-response-query"
import UPDATE_DISCUSSION_RESPONSE from "./graphql/update-discussion-response-mutation"
import UPDATE_USER_DISCUSSION_HISTORY from "./graphql/update-user-discussion-history-mutation"

const DiscussionContext = createContext()

export const useDiscussionContext = () => useContext(DiscussionContext)

function DiscussionProvider(props) {
  const client = useApolloClient()

  const [loading, setLoading] = useState(false)
  const [replyingTo, setReplyingTo] = useState(null)
  const [editingResponse, setEditingResponse] = useState(null)
  const [response, setResponse] = useState(props.response || null)
  const [unreadResponses, setUnreadResponses] = useState([])

  const scrollRef = useRef()

  const sortedResponses = useMemo(() => {
    let sorted = []
    if (props.initialResponse) {
      sorted.push(props.initialResponse)
    }
    if (props.responses) {
      let lookup = {}
      let tree = []
      // build reference lookup
      props.responses.forEach((response) => {
        lookup[response.id] = {
          ...response,
          responses: []
        }
      })
      // build tree
      props.responses.forEach((response) => {
        if (response.parent_id) {
          let responseParent = lookup[response.parent_id]
          if (responseParent) {
            responseParent.responses.push(lookup[response.id])
          }
        } else {
          tree.push(lookup[response.id])
        }
      })
      // flatten tree into sorted array
      function flatten(response) {
        sorted.push(response)
        response.responses.forEach(flatten)
      }
      tree.forEach(flatten)
    }
    return props.sortResponses ? props.sortResponses(sorted) : sorted
  }, [props.initialResponse, props.responses])

  useEffect(() => {
    if (!props.responseLoading && !props.postLoading) {
      setLoading(false)
    }
  }, [props.responseLoading, props.postLoading])

  useEffect(() => {
    setResponse(props.response)
  }, [props.response])

  useEffect(() => {
    if (replyingTo) {
      scrollToResponse(replyingTo)
    }
    if (editingResponse) {
      scrollToResponse(editingResponse)
    }
  }, [replyingTo, editingResponse])

  useEffect(() => {
    if (
      props.post &&
      !isEmpty(props.post) &&
      props.responses &&
      props.responses.length
    ) {
      props.updateUserDiscussionHistory()
    }
  }, [!!props.post])

  // TODO: make this configurable?
  // useEffect(() => {
  //   if (!props.response_id) {
  //     const timeoutId = setInterval(loadNewResponses, 5000)
  //     return () => clearTimeout(timeoutId)
  //   }
  // }, [props.response_id])

  // async function loadNewResponses() {
  //   const since = moment(Date.now() - 5000)
  //   const query = RESPONSES_QUERY
  //   const context = {
  //     ...props.context,
  //     discussion_id: props.id
  //   }
  //   const result = await client.query({
  //     fetchPolicy: "no-cache", // we will manually merge into cache
  //     query: query,
  //     variables: {
  //       context: context,
  //       modified_time: {
  //         gte: since.toISOString()
  //       }
  //     }
  //   })
  //   if (
  //     result.data &&
  //     result.data.responses &&
  //     result.data.responses.edges &&
  //     result.data.responses.edges.length
  //   ) {
  //     // merge responses into original query result
  //     const data = client.readQuery({
  //       query,
  //       variables: { context }
  //     })
  //     const nextEdges = []
  //     const newEdges = [...result.data.responses.edges]
  //     // start with previous responses to keep ordering from server
  //     data.responses.edges.forEach(edge => {
  //       // okay to findIndex here since newEdges.length should be small
  //       const index = newEdges.findIndex(x => x.node.id == edge.node.id)
  //       if (index !== -1) {
  //         // updated response found, replace previous response
  //         const [nextEdge] = newEdges.splice(index, 1)
  //         nextEdges.push(nextEdge)
  //       } else {
  //         // updated response not found, keep previous response
  //         nextEdges.push(edge)
  //       }
  //     })
  //     // insert remaining new responses at the front
  //     nextEdges.unshift(...newEdges)
  //     const nextData = {
  //       ...data,
  //       responses: {
  //         ...data.responses,
  //         edges: nextEdges
  //       }
  //     }
  //     client.writeQuery({
  //       query,
  //       variables: { context },
  //       data: nextData
  //     })
  //     setUnreadResponses(x => [...x, ...newEdges.map(x => x.node)])
  //   }
  // }

  const markAllResponsesAsRead = useCallback(
    throttle(() => {
      setTimeout(() => {
        setUnreadResponses([])
      }, 1000)
    }, 1000),
    []
  )

  async function loadMoreResponses() {
    if (props.hasMore && !loading) {
      try {
        setLoading(true)
        await props.loadMore()
      } finally {
        setLoading(false)
      }
    }
  }

  function scrollToResponse(response) {
    const index = sortedResponses.findIndex((x) => x.id === response.id)
    if (scrollRef.current) {
      scrollRef.current.scrollToIndex({
        index: index !== -1 ? index : 0,
        viewOffset: 0,
        viewPosition: 0.3
      })
    }
  }

  function viewResponse(response) {
    setResponse(response)
    if (props.onViewResponse) {
      props.onViewResponse(response)
    }
  }

  function viewAllResponses() {
    setResponse(null)
    if (props.onViewAllResponses) {
      props.onViewAllResponses()
    }
  }

  const discussionContext = {
    // data
    context: props.context,
    loading: props.postLoading || props.responseLoading || loading,
    post: props.post,
    postLoading: props.postLoading || loading,
    postError: props.postError,
    response: response,
    responseDeleted: props.responseDeleted,
    responses: sortedResponses,
    responsesLoading: props.postLoading || loading,
    responsesError: props.responsesError,
    responsesHasMore: props.hasMore,
    // state
    replyingTo: replyingTo,
    editingResponse: editingResponse,
    setUnreadResponses: setUnreadResponses,
    unreadResponses: unreadResponses,
    // actions
    markAllResponsesAsRead: markAllResponsesAsRead,
    loadMoreResponses: loadMoreResponses,
    setReplyingTo: setReplyingTo,
    setEditingResponse: setEditingResponse,
    scrollToResponse: scrollToResponse,
    scrollRef: scrollRef,
    viewResponse: viewResponse,
    viewAllResponses: viewAllResponses,
    // mutations
    createDiscussion: props.createDiscussion,
    updateDiscussion: props.updateDiscussion,
    updateUserDiscussionHistory: props.updateUserDiscussionHistory,
    likeDiscussion: props.likeDiscussion,
    unlikeDiscussion: props.unlikeDiscussion,
    subscribeToDiscussion: props.subscribeToDiscussion,
    unsubscribeToDiscussion: props.unsubscribeToDiscussion,
    createResponse: props.createResponse,
    updateResponse: props.updateResponse,
    deleteResponse: props.deleteResponse,
    likeResponse: props.likeResponse,
    unlikeResponse: props.unlikeResponse
  }

  return (
    <DiscussionContext.Provider value={discussionContext}>
      {props.children}
    </DiscussionContext.Provider>
  )
}

DiscussionProvider.displayName = "DiscussionProvider"

DiscussionProvider.propTypes = {
  id: PropTypes.number, // discussion id used to query for discussion and responses
  response_id: PropTypes.number,
  onViewAllResponses: PropTypes.func,
  onViewResponse: PropTypes.func,
  // intitialResponse is a hack for accepting a fake response (i.e. a step.userpoints_explanation)
  initialResponse: PropTypes.shape({
    id: PropTypes.oneOf(["initialResponse"]),
    body: PropTypes.string,
    user: PropTypes.object,
    likes: PropTypes.number,
    user_liked: PropTypes.bool,
    created_time: PropTypes.string,
    attachments: PropTypes.array
  }),
  context: PropTypes.shape({
    completion_id: PropTypes.number,
    resource_id: PropTypes.number,
    cohort_id: PropTypes.number,
    step_id: PropTypes.number
  }).isRequired
}

const optimisticDiscussionReponse = ({ body, user }) => ({
  createDiscussionResponse: {
    // Ensure date is unique (helps with comparing old and new data in responses list).
    id: `discussion-response-optimistic-${new Date().getTime()}`,
    body: body,
    attachments: null,
    likes: 0,
    user_liked: null,
    created_time: new Date().toISOString(),
    user: { ...user, __typename: "User" },
    __typename: "Response"
  }
})

// TODO: figure out how to make this work
const optimisticCreateDiscussionResponse = ({ body, user }) => ({
  createDiscussion: {
    id: "discussion-optimistic",
    body: "",
    responses: {
      total: 1,
      edges: [{ node: optimisticDiscussionReponse({ body, user }) }],
      __typename: "Discussion"
    }
  }
})

const emitDiscussionEvent = (props, event, data) => {
  if (typeof props.onDiscussionEvent === "function") {
    props.onDiscussionEvent(event, data)
  }
}

export default compose(
  graphql(DISCUSSION_QUERY, {
    options: ({ id, context, orderResponsesBy }) => ({
      variables: { id, context, orderResponsesBy },
      fetchPolicy: "cache-and-network"
    }),
    // skip if response_id
    skip: ({ response_id }) => !!response_id,
    props: ({ data, ownProps }) => {
      const { responses, ...post } = data.discussion || {}
      emitDiscussionEvent(ownProps, "discussion-loaded", data.discussion)
      return {
        post: isEmpty(post) ? null : post,
        error: data.error,
        loading: data.loading,
        postLoading: data.loading,
        responses: flattenEdges(responses),
        hasMore: get(data, "discussion.responses.pageInfo.hasNextPage"),
        loadMore: createPaginator(data, "discussion.responses", "cursor")
      }
    }
  }),
  graphql(DISCUSSION_RESPONSE_QUERY, {
    options: ({ id, context, response_id }) => ({
      variables: { id, context, response_id }
    }),
    // skip if no response_id
    skip: ({ response_id }) => !response_id,
    props: ({ data, ownProps }) => {
      const { response, ...post } = data.discussion || {}
      emitDiscussionEvent(
        ownProps,
        "discussion-responses-loaded",
        data.discussion
      )
      return {
        post,
        error: data.error,
        loading: data.loading,
        responseLoading: data.loading,
        response,
        responseDeleted: data.loading ? false : !response
      }
    }
  }),
  graphql(CREATE_DISCUSSION, {
    options: ({ onDiscussionCreated }) => ({
      onCompleted: ({ createDiscussion }) =>
        onDiscussionCreated && onDiscussionCreated(createDiscussion)
    }),
    props: ({ mutate, ownProps }) => ({
      createDiscussion: ({ title, body, type, initialResponse }) => {
        mutate({
          variables: {
            context: ownProps.context,
            post: {
              title,
              body,
              type
            },
            initialResponse
          },
          // TODO: make this optimistic somehow... better yet, create a new mutation that auto-creates the discussion
          // so we don't have to do this here
          // optimisticResponse: optimisticCreateDiscussionResponse(
          //   initialResponse
          // ),
          refetchQueries: ["DiscussionQuery"]
        })
      }
    })
  }),
  graphql(CREATE_DISCUSSION_RESPONSE, {
    props: ({ mutate, ownProps }) => {
      return {
        createResponse: ({ body, attachments, depth, reply_parent_id, user }) =>
          mutate({
            variables: {
              context: ownProps.context,
              response: {
                body,
                attachments,
                depth,
                reply_parent_id,
                discussion_id: ownProps.post.id
              }
            },
            optimisticResponse: optimisticDiscussionReponse({
              body,
              user
            }),
            onCompleted: ({ createDiscussionResponse }) =>
              ownProps.onDiscussionResponseCreated &&
              ownProps.onDiscussionResponseCreated(
                ownProps.post,
                createDiscussionResponse
              ),
            update: (cache, result) => {
              const query = DISCUSSION_QUERY
              const variables = {
                id: ownProps.id,
                context: ownProps.context,
                orderResponsesBy: ownProps.orderResponsesBy
              }
              const data = cache.readQuery({ query, variables })
              const edge = {
                node: result.data.createDiscussionResponse,
                __typename: "ResponseEdge"
              }
              const nextData = {
                discussion: {
                  ...data.discussion,
                  total_responses: data.discussion.total_responses + 1,
                  // user is subscribed asynchronously on the backend when user responds
                  user_is_subscribed: true,
                  responses: {
                    ...data.discussion.responses,
                    total: data.discussion.responses.total + 1,
                    edges: ownProps.insertNewResponseEdge
                      ? ownProps.insertNewResponseEdge(
                          data.discussion.responses.edges,
                          edge
                        )
                      : [...data.discussion.responses.edges, edge]
                  }
                }
              }
              cache.writeQuery({ query, variables, data: nextData })
            }
          })
      }
    }
  }),
  graphql(UPDATE_DISCUSSION_RESPONSE, {
    props: ({ mutate, ownProps }) => ({
      updateResponse: (response) =>
        mutate({
          variables: {
            context: ownProps.context,
            response_id: response.id,
            response: {
              body: response.body,
              attachments: response.attachments,
              discussion_id: ownProps.post.id
            }
          },
          optimisticResponse: {
            updateDiscussionResponse: {
              ...response,
              __typename: "Response"
            }
          }
        })
    })
  }),
  graphql(DELETE_DISCUSSION_RESPONSE, {
    props: ({ mutate, ownProps }) => ({
      deleteResponse: (response) =>
        mutate({
          variables: {
            response_id: response.id,
            discussion_id: ownProps.post.id,
            context: ownProps.context
          },
          onCompleted: () =>
            ownProps.onDiscussionResponseDeleted &&
            ownProps.onDiscussionResponseDeleted(ownProps.post),
          update: (cache) => {
            if (ownProps.response_id) {
              const query = DISCUSSION_RESPONSE_QUERY
              const variables = {
                id: ownProps.id,
                response_id: ownProps.response_id,
                context: ownProps.context
              }
              const data = cache.readQuery({ query, variables })
              const nextData = produce(data, (draft) => {
                draft.discussion.response = null
              })
              cache.writeQuery({ query, variables, data: nextData })
            } else {
              const query = DISCUSSION_QUERY
              const variables = {
                id: ownProps.id,
                context: ownProps.context,
                orderResponsesBy: ownProps.orderResponsesBy
              }
              const data = cache.readQuery({ query, variables })
              const nextData = {
                discussion: {
                  ...data.discussion,
                  total_responses: data.discussion.total_responses - 1,
                  responses: {
                    ...data.discussion.responses,
                    total: data.discussion.responses.total - 1,
                    edges: data.discussion.responses.edges.filter(
                      (edge) => edge.node.id !== response.id
                    )
                  }
                }
              }
              cache.writeQuery({ query, variables, data: nextData })
            }
          }
        })
    })
  }),
  graphql(CREATE_DISCUSSION_RESPONSE_LIKE, {
    props: ({ mutate, ownProps }) => ({
      likeResponse: (response) =>
        mutate({
          variables: {
            response_id: response.id,
            discussion_id: ownProps.post.id,
            context: ownProps.context
          }
        })
    })
  }),
  graphql(DELETE_DISCUSSION_RESPONSE_LIKE, {
    props: ({ mutate, ownProps }) => ({
      unlikeResponse: (response) =>
        mutate({
          variables: {
            response_id: response.id,
            discussion_id: ownProps.post.id,
            context: ownProps.context
          },
          optimisticResponse: {
            __typename: "Mutation",
            deleteDiscussionResponseLike: true
          },
          update: (cache) => {
            if (ownProps.response_id) {
              const query = DISCUSSION_RESPONSE_QUERY
              const variables = {
                id: ownProps.id,
                response_id: ownProps.response_id,
                context: ownProps.context
              }
              const data = cache.readQuery({ query, variables })
              const nextData = produce(data, (draft) => {
                draft.discussion.response.user_liked = false
                draft.discussion.response.likes -= 1
              })
              cache.writeQuery({ query, variables, data: nextData })
            } else {
              const query = DISCUSSION_QUERY
              const variables = {
                id: ownProps.id,
                context: ownProps.context,
                orderResponsesBy: ownProps.orderResponsesBy
              }
              const data = cache.readQuery({ query, variables })
              const nextData = produce(data, (draft) => {
                const edge = draft.discussion.responses.edges.find(
                  (edge) => edge.node.id === response.id
                )
                edge.node.user_liked = false
                edge.node.likes -= 1
              })
              cache.writeQuery({ query, variables, data: nextData })
            }
          }
        })
    })
  }),
  graphql(UPDATE_USER_DISCUSSION_HISTORY, {
    props: ({ mutate, ownProps }) => ({
      updateUserDiscussionHistory: () =>
        mutate({
          variables: {
            context: ownProps.context,
            discussion_id: ownProps.post.id
          },
          optimisticResponse: {
            updateUserDiscussionHistory: {
              ...ownProps.post,
              user_last_viewed_time: new Date().toISOString(),
              user_has_viewed_recent: true,
              __typename: "Discussion"
            }
          }
        })
    })
  }),
  graphql(CREATE_DISCUSSION_SUBSCRIPTION, {
    props: ({ mutate, ownProps }) => ({
      subscribeToDiscussion: () =>
        mutate({
          variables: {
            context: ownProps.context,
            discussion_id: ownProps.post.id
          }
        })
    })
  }),
  graphql(DELETE_DISCUSSION_SUBSCRIPTION, {
    props: ({ mutate, ownProps }) => ({
      unsubscribeToDiscussion: () =>
        mutate({
          variables: {
            context: ownProps.context,
            discussion_id: ownProps.post.id
          }
        })
    })
  })
)(DiscussionProvider)
