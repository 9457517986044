import PropTypes from "prop-types"
import { useState } from "react"
import ErrorBoundary from "../error/ErrorBoundary"
import useDebouncedValue from "../hooks/useDebouncedValue"
import LoadingCircle from "../loading/LoadingCircle"
import View from "../ui/View"
import Picker from "./Picker"
import { useSelectMultiplePickerItems } from "./utils"

const PickerContainer = ({
  prompt,
  inline,
  contained,
  icon,
  searchPrompt,
  onPick,
  onPicking,
  onClose,
  getPickerItem,
  selectedPickerItemIds: selectedPickerItemIdsProp,
  onPickMultiple,
  pickerItemsFilter,
  usePickerQuery,
  renderPickerItem,
  className,
  children
}) => {
  const [picking, setPicking] = useState(!inline)

  const handleFocus = () => {
    handlePicking(true)
  }

  const handlePick = (pickerItem) => {
    handlePicking(false)
    onPick && onPick(pickerItem)
  }

  const handleClose = () => {
    handlePicking(false)
    onClose && onClose()
  }

  const handlePicking = (picking) => {
    if (inline) {
      setPicking(picking)
      onPicking && onPicking(picking)
    }
  }

  const [search, debouncedSearch, debouncingSearch, handleDebounceSearch] =
    useDebouncedValue()

  const {
    hoistedSelectedPickerItemIds,
    setFilteringSelectedPickerItems,
    filteringSelectedPickerItems,
    useUpdateSelectedPickerItems
  } = useSelectMultiplePickerItems()

  const {
    pickerItems, // expecting pickerItems in shape of [{ id: PropTypes.number, name: PropTypes.string, image: PropTypes.string }]
    loading,
    loadingMore,
    loadMore,
    hasMore
  } = usePickerQuery({
    first: 10,
    search: debouncedSearch,
    filterBySelectedPickerItemIds: filteringSelectedPickerItems,
    selectedPickerItemIds: hoistedSelectedPickerItemIds
  })

  const {
    selectedPickerItemIds,
    handleSelectPickerItem,
    handleUnselectPickerItem,
    selectingAllPickerItems,
    handleSelectAllPickerItems
  } = useUpdateSelectedPickerItems({
    search,
    pickerItemIds: pickerItems
      ? pickerItems.map((pickerItem) => pickerItem.id)
      : [],
    selectedPickerItemIdsProp
  })

  return pickerItems ? (
    <ErrorBoundary>
      <Picker
        className={className}
        search={search}
        searching={debouncingSearch || loading}
        prompt={prompt}
        contained={contained || inline}
        icon={icon}
        searchPrompt={searchPrompt}
        picking={picking}
        onPick={(pickerItem) => {
          handlePick(pickerItem)
          // clear search
          inline && handleDebounceSearch(null)
        }}
        onClose={handleClose}
        pickerItems={
          pickerItemsFilter
            ? pickerItems.filter(pickerItemsFilter)
            : pickerItems
        }
        getPickerItem={getPickerItem}
        onSearch={handleDebounceSearch}
        onClear={() => handleDebounceSearch(null)}
        onScrollBottom={!loading && !loadingMore && hasMore ? loadMore : null}
        onFocus={handleFocus}
        onPickMultiple={onPickMultiple}
        selectedPickerItemIds={selectedPickerItemIds}
        selectPickerItem={handleSelectPickerItem}
        unselectPickerItem={handleUnselectPickerItem}
        selectAllPickerItems={handleSelectAllPickerItems}
        selectingAllPickerItems={selectingAllPickerItems}
        filterSelectedPickerItems={setFilteringSelectedPickerItems}
        filteringSelectedPickerItems={filteringSelectedPickerItems}
        renderPickerItem={renderPickerItem}
      >
        {children}
      </Picker>
    </ErrorBoundary>
  ) : (
    <View size="large" padding>
      <LoadingCircle />
    </View>
  )
}

PickerContainer.displayName = "PickerContainer"

PickerContainer.propTypes = {
  prompt: PropTypes.string,
  inline: PropTypes.bool,
  contained: PropTypes.bool,
  usePickerQuery: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  onPick: PropTypes.func,
  onPickMultiple: PropTypes.func,
  onPicking: PropTypes.func,
  icon: PropTypes.string,
  searchPrompt: PropTypes.string,
  pickerItemsFilter: PropTypes.func,
  getPickerItem: PropTypes.func,
  renderPickerItem: PropTypes.func
}

export default PickerContainer
