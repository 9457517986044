import {
  LEARNING_ACTION_FILTER_COMPLETE_COURSE,
  // LEARNING_ACTION_FILTER_NONE,
  LEARNING_ACTION_FILTER_COMPLETE_STEP,
  // LEARNING_ACTION_FILTER_CREATE_GROUP,
  LEARNING_ACTION_FILTER_CREATE_DISCUSSION,
  LEARNING_ACTION_FILTER_CREATE_MEMBERSHIP,
  LEARNING_ACTION_FILTER_CREATE_REGISTRATION,
  LEARNING_ACTION_FILTER_CREATE_RESPONSE,
  LEARNING_ACTION_FILTER_CREATE_SUBSCRIPTION,
  // LEARNING_ACTION_FILTER_CANCEL_SUBSCRIPTION,
  LEARNING_ACTION_FILTER_SKIP_STEP
} from "./constants"

export default function getBlanks(context) {
  const { userName, accountName } = context
  return [
    {
      type: LEARNING_ACTION_FILTER_COMPLETE_STEP,
      name: "Dora",
      description: `completed the step, Welcome to ${accountName}`
    },
    {
      type: LEARNING_ACTION_FILTER_SKIP_STEP,
      name: "Skippy",
      description: `skipped the step, Welcome to ${accountName}`
    },
    {
      type: LEARNING_ACTION_FILTER_CREATE_REGISTRATION,
      name: "Buzz",
      description: "joined the course, To Infinity & Beyond"
    },
    {
      type: LEARNING_ACTION_FILTER_CREATE_DISCUSSION,
      name: "Elon",
      description: "posted a discussion",
      content: `Is ${userName} not the best teacher in the universe? I think it’s undeniable.`
    },
    {
      type: LEARNING_ACTION_FILTER_CREATE_MEMBERSHIP,
      name: "Darth",
      description: "created an account"
    },
    {
      type: LEARNING_ACTION_FILTER_COMPLETE_COURSE,
      name: "George",
      description: "completed the course, Intro to Fremen Culture"
    },
    {
      type: LEARNING_ACTION_FILTER_CREATE_RESPONSE,
      name: "Chris",
      description: "replied to Elon W.",
      content: "The answer is 42!"
    },
    {
      type: LEARNING_ACTION_FILTER_CREATE_SUBSCRIPTION,
      name: "Mae",
      description: "became a subscriber"
    },
    {
      type: LEARNING_ACTION_FILTER_CREATE_DISCUSSION,
      name: "Elon",
      description: "asked a question",
      content: "What is the answer to life, the universe, and everything?"
    },
    {
      type: LEARNING_ACTION_FILTER_COMPLETE_STEP,
      name: "Sally",
      description: "completed the step, Submit your project for review"
    },
    {
      type: LEARNING_ACTION_FILTER_SKIP_STEP,
      name: "Skippy",
      description: "skipped the step, Submit your project for review"
    },
    {
      type: LEARNING_ACTION_FILTER_COMPLETE_STEP,
      name: "Misty",
      description: "completed the step, What to learn next"
    },
    {
      type: LEARNING_ACTION_FILTER_SKIP_STEP,
      name: "Skippy",
      description: "skipped the step, What to learn next"
    },
    {
      type: LEARNING_ACTION_FILTER_CREATE_MEMBERSHIP,
      name: "Darth",
      description: "created an account"
    }
  ]
}
