import { useJoinSpace } from "./api/action"
import JoinForm, { JoinFormProps } from "./JoinForm"

export type JoinViewContainerProps = Pick<JoinFormProps, "onComplete">

const JoinViewContainer = ({ onComplete }: JoinViewContainerProps) => {
  const [state, action] = useJoinSpace()

  const handleSubmit: JoinFormProps["onSubmit"] = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.currentTarget)
    await action(formData)
  }

  return (
    <JoinForm
      loading={state.loading}
      success={!!state.value}
      error={state.error?.message}
      onSubmit={handleSubmit}
      onComplete={onComplete}
    />
  )
}

export default JoinViewContainer
