import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import Link from "@pathwright/ui/src/components/link/Link"
import FeatureLock from "@pathwright/web/src/modules/lib/FeatureLock"
import { getPlatformUrl } from "@pathwright/web/src/modules/utils/urls"

// TODO: manage locking purely by feature included in plan?
// (currently resource_licensing_enabled flag must be true for school to have access to licensing)

const ManageLicenseLocked = () => (
  <div>
    <FeatureLock has="member-created-group-courses">
      {({ hasFeatures, activationLocked, lockedFeatures, getPlansNameList }) =>
        !hasFeatures ? (
          <BlankSlate
            icon="groups"
            heading="Help anyone teach your course"
            body={
              <>
                {" "}
                The <strong>member-created cohorts</strong> feature unlocks the
                option for you to offer courses for pay or for free to any team
                leader, teacher, parent, or mentor who'd like to use your course
                to learn with their own private cohort.{" "}
                <Link to="https://help.pathwright.com/ways-to-offer-courses-with-curriculum/let-anyone-lead-a-private-cohort-through-your-courses">
                  Learn more.
                </Link>
              </>
            }
            primaryAction={{
              children: activationLocked ? "Activate" : "Upgrade Plan",
              to: getPlatformUrl("/pricing/plan/")
            }}
            meta={`Included in the ${getPlansNameList(
              lockedFeatures["member-created-group-courses"]
            )}`}
          />
        ) : (
          // Feature in plan but resource_licensing_enabled flag not enabled
          <BlankSlate
            icon="group"
            heading="Almost there!"
            body={
              <>
                Enabling the <strong>member-created cohorts</strong> feature
                unlocks the option for you to offer courses for a seat-based
                price of your choice or for free to any team leader, teacher,
                parent, or mentor who'd like to use your course to learn with
                their own private cohort.{" "}
                <Link to="https://help.pathwright.com/ways-to-offer-courses-with-curriculum/let-anyone-lead-a-private-cohort-through-your-courses">
                  Learn more.
                </Link>
              </>
            }
            primaryAction={{
              children: "Enable member-created cohorts",
              onClick: () =>
                window.Intercom
                  ? window.Intercom("showNewMessage")
                  : window.open("mailto:hello@pathwright.com")
            }}
            meta={
              <span>
                Contact us to enable <strong>member-created cohorts</strong> for
                your school.
              </span>
            }
          />
        )
      }
    </FeatureLock>
  </div>
)

ManageLicenseLocked.displayName = "ManageLicenseLocked"

export default ManageLicenseLocked
