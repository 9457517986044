import get from "lodash/get"
import React from "react"

const useAlgoliaSearch = ({
  query, // search string
  indices = [], // list of indices being searched
  authorFacetValues,
  categoryFacetValues,
  massageResults // optional function for massaging results data before setting in state
}) => {
  // the results of the latest search
  const [results, setResults] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  // a search error if any
  const [error, setError] = React.useState(null)
  // the Algolia search keys available to this school
  const searchKeys = get(window.bootstrappedData, "integrations.algolia")
  // determine if algolia is enabled
  const algoliaEnabled =
    !!window.algoliasearch &&
    !!searchKeys &&
    indices.every((index) => Object.keys(searchKeys).includes(index))

  const client = React.useMemo(
    () =>
      algoliaEnabled
        ? window.algoliasearch(
            "UTMZ56WOUH", // appId
            "1565fef5ce85305f58c3b5cc28915436" // apiKey
          )
        : null,
    [algoliaEnabled]
  )

  // handle searching based on query and facet filters
  const search = async (query, facetFilters) => {
    if (query) {
      // build search
      const queries = indices.reduce((queries, index) => {
        // get index key and params
        const { key, params } = searchKeys[index]
        // huh?
        delete params["restrictIndices"]
        // huh?
        params.facets = "*"
        // add facet filters
        params.facetFilters = facetFilters
        // derive the index name
        const indexName = `${searchKeys.index_prefix}${index}`

        return [
          ...queries,
          // append new query
          {
            indexName,
            query: query || "",
            params
          }
        ]
      }, [])

      setLoading(true)
      // attempt the seatch
      try {
        const { results } = await new Promise((resolve, reject) =>
          client.search(queries, (err, results) =>
            err ? reject(err) : resolve(results)
          )
        )

        // success, update results
        setResults(massageResults ? massageResults(results) : results)
      } catch (error) {
        // failed, handle error
        setError(error)
      } finally {
        setLoading(false)
      }
    } else {
      setResults(null)
    }
  }

  React.useEffect(() => {
    if (client) {
      // construct author facet filters
      const authorsFacetFilters = (authorFacetValues || []).map(
        (v) => `authors.display_name:${v}`
      )
      // construct category facet filters
      const categoriesFacetFilters = (categoryFacetValues || []).map(
        (v) => `categories.name:${v}`
      )

      // join filters with comma
      const facetFilters =
        [...authorsFacetFilters, ...categoriesFacetFilters].join(",") || null

      // query based on current search and facet filters
      search(query, facetFilters)
    }
  }, [client, query, authorFacetValues, categoryFacetValues])

  return {
    results,
    loading,
    error,
    algoliaEnabled
  }
}

export default useAlgoliaSearch
