import { PickerFileMetadata, PickerResponse } from "filestack-js"
import get from "lodash/get"
import orderBy from "lodash/orderBy"
import { createFilestackDownloadURL } from "./filestack"
import { MEDIA_TYPES, getMediaTypeFromMimetype } from "./media-types"
import { MuxFileInput, createMuxDownloadURL } from "./mux"
import { Media, MediaInput, MediaType } from "./types"

// These constants and functions assume the GraphQL schema (currently) defined here:
// https://github.com/duointeractive/pathwright/blob/master/server/blocks/src/media/schema.js

type MuxData = {
  download?: {
    url?: string
  }
}

export const createMediaDownloadURL = (
  mediaItem: Partial<Media & { filestackHandle?: string }> = {},
  muxData?: MuxData
): string => {
  if (mediaItem.muxVideoID) {
    if (!muxData) {
      console.warn("No Mux video data — can't get a download URL")
      return ""
    }

    return createMuxDownloadURL(
      get(muxData, "download.url", ""),
      mediaItem.name
    )
  }

  const handle = mediaItem.filestackHandle || mediaItem.externalID

  if (handle) {
    return createFilestackDownloadURL(handle, mediaItem.name)
  }

  // Unable to create a download URL at this point.
  return ""
}

type UploadOptions = {
  mediaType: MediaType
  accountID?: string | number
  useMux?: boolean
}

export const uploadToMedia = (
  dataArray: PickerResponse["filesUploaded"] | MuxFileInput[],
  { mediaType, accountID = "", useMux = false }: UploadOptions
): MediaInput[] => {
  const returned: MediaInput[] = dataArray.map(
    (
      file: Partial<PickerFileMetadata> &
        Partial<MuxFileInput> & { id?: string }
    ): MediaInput => {
      const {
        mimetype,
        url,
        filename,
        size,
        handle,
        type,
        muxVideoID = null,
        id
      } = file

      return {
        // id: useMux ? muxVideoID || "" : id,
        description: "",
        tags: [] as string[],
        fileSize: size,
        accountID,
        muxVideoID,
        type: useMux
          ? MEDIA_TYPES.VIDEO
          : (getMediaTypeFromMimetype(mimetype!) as MediaType),
        url: useMux ? "" : url!,
        downloadURL: useMux ? "" : url!,
        mimetype: useMux ? type! : mimetype! || "application/*",
        name: useMux ? filename || "New Video" : filename!,
        externalID: useMux ? muxVideoID : handle,
        encodingStatus: useMux ? "" : mediaType === "VIDEO" ? "new" : ""
      }
    }
  )

  return returned
}

export const prepMediaForSaving = (mediaItem: Media): MediaInput => {
  const {
    // Algolia fields to remove
    _highlightResult,
    __typename,
    objectID,
    __position,

    // Objects not suitable for mutations
    size,
    playback,
    thumb,
    encoding,

    // Legacy data fields
    contentType,

    // Snuck in
    lastUsedDateTime,

    // This is all we need
    ...validFields
  } = mediaItem

  return {
    ...validFields,
    tags: validFields.tags || []
  }
}

// prevMedia: Media
// updatedFields: MediaInput fields
// userID: string
// returns MediaInput
export const fieldsToMedia = (
  prevMedia: Media,
  updatedFields: Partial<Media>,
  userID?: string | number
) => {
  // Don't update createdByID if it's already set
  const createdByID = prevMedia.createdByID || userID || 100

  const media = {
    ...prevMedia,
    ...updatedFields,
    createdByID: Number(createdByID)
  }

  return prepMediaForSaving(media)
}

// mediaItems: [Media]
// returns [Media]
export const validateMedia = (mediaItems: Media[]) => {
  if (!mediaItems || !Array.isArray(mediaItems)) return []

  return mediaItems.filter((mediaItem) => {
    if (mediaItem.muxVideoID) return true

    if (!mediaItem.type || !mediaItem.url) {
      return false
    }

    return true
  })
}

// mediaItems: [Media]
// returns [Media]
// Use name as fallback sort param b/c multiple uploads can be simultaneous
export const sortMediaByDate = (mediaItems: Media[]) =>
  orderBy(mediaItems, ["createdDate", "name"], ["desc", "asc"])
