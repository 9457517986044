import gql from "graphql-tag"
import CURRICULUM_SUBSCRIPTION_FRAGMENT from "./curriculum-subscription-fragment"

export default gql`
  query CurriculumSubscriptionQuery(
    $schoolId: Int!
    $curriculumSubscriptionId: Int!
  ) {
    context(school_id: $schoolId) {
      school {
        curriculum_subscription(id: $curriculumSubscriptionId) {
          ...CurriculumSubscription
        }
      }
    }
  }
  ${CURRICULUM_SUBSCRIPTION_FRAGMENT}
`
