import gql from "graphql-tag"
import ORDER_FRAGMENT from "./order-fragment"

export default gql`
  mutation CreateRegistrationOrder(
    $offering_id: Int!
    $coupon_code: String
    $invitation_code: String
    $use_subscription_plan_id: Int
    $use_group_subscription_id: Int
  ) {
    createRegistrationOrder(
      offering_id: $offering_id
      coupon_code: $coupon_code
      invitation_code: $invitation_code
      use_subscription_plan_id: $use_subscription_plan_id
      use_group_subscription_id: $use_group_subscription_id
    ) {
      ...Order
    }
  }
  ${ORDER_FRAGMENT}
`
