import gql from "graphql-tag"
import SCHOOL_FRAGMENT from "../../school/graphql/school-fragment"
import STORE_FRAGMENT from "./store-fragment"

export default gql`
  mutation CreateStoreLink($schoolId: Int!, $copy: [String]) {
    createStoreLink(school_id: $schoolId, copy: $copy) {
      id
      licensee {
        ...School
        sso_link(pathname: "/home/teach/")
      }
      store {
        ...Store
      }
    }
  }
  ${SCHOOL_FRAGMENT}
  ${STORE_FRAGMENT}
`
