// use in conjunction with react-lazy-with-preload

import { useEffect, useState } from "react"

// Only flag non-preloaded components as initially preloading.
// Otherwise, if the component is in the set, then we know it's already preloaded.
const _preloadedComponents = new Set([])

// pass preload: false to manually controll when Component is preloaded
// by calling the setPreloading method
export const usePreload = ({ Component, preload: preloadBool = true }) => {
  const [preloading, setPreloading] = useState(
    _preloadedComponents.has(Component) ? false : preloadBool
  )

  // preload the component
  const preloadFn = () =>
    Component.preload().then(() => {
      setPreloading(false)
      _preloadedComponents.add(Component)
    })

  useEffect(() => {
    if (preloadBool && Component) preloadFn()
  }, [preloadBool, Component])

  return [preloading, setPreloading]
}

export const withPreload = (PreloadComponent) => (Component) => (props) => {
  const [preloading, setPreloading] = usePreload({
    Component: PreloadComponent
  })

  return (
    <Component
      {...props}
      preloading={preloading}
      setPreloading={setPreloading}
    />
  )
}
