import { useState } from "react"
import CardStack from "./CardStack"

const useCardStack = (card) => {
  const [showingCardStack, setShowingCardStack] = useState(false)
  const open = () => setShowingCardStack(true)
  const close = () => setShowingCardStack(false)

  const cardStack = showingCardStack ? (
    <CardStack
      card={card}
      onCloseCard={(card, index) => {
        if (index === 0) close()
      }}
    />
  ) : null

  return {
    cards: cardStack,
    open,
    close
  }
}

export default useCardStack
