import { withTranslate } from "@pathwright/ui/src/components/"
import Avatar from "@pathwright/ui/src/components/avatar/Avatar"
import Tooltip from "@pathwright/ui/src/components/tooltip/Tooltip"
import Text from "@pathwright/ui/src/components/ui/Text"
import moment from "moment"
import PropTypes from "prop-types"
import styled from "styled-components"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import { dueTimeStatus } from "../utils"

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
`

const CompletionFeedbackLabel = ({ completion, hideUser, Trans }) => {
  const { me } = usePathwrightContext()

  if (!completion) {
    return null
  }

  const completionTime = moment(completion.completion_date).format("LLLL")
  const actingUser =
    completion.overridden_by && completion.is_complete
      ? completion.overridden_by
      : completion.user
  const completionUser = completion.user

  const isMe = me.id === actingUser.id

  let completionMessage = null

  if (completion.is_skipped) {
    if (completion.overridden_by) {
      if (completion.overridden_by.id === me.id) {
        completionMessage = (
          <Trans i18nKey="completion.header.skipped_for_second_person">
            <strong>You</strong> skipped this step for{" "}
            {{ userAbbrName: completionUser.display_name }}
          </Trans>
        )
      } else {
        completionMessage = (
          <Trans i18nKey="completion.header.skipped_for">
            <strong>{{ actingUserAbbrName: actingUser.display_name }}</strong>{" "}
            skipped this step for{" "}
            {{ userAbbrName: completionUser.display_name }}
          </Trans>
        )
      }
    } else {
      if (completion.user.id === me.id) {
        completionMessage = (
          <Trans i18nKey="completion.header.skipped_second_person">
            <strong>You</strong> skipped this step
          </Trans>
        )
      } else {
        completionMessage = (
          <Trans i18nKey="completion.header.skipped">
            <strong>{{ actingUserAbbrName: actingUser.display_name }}</strong>{" "}
            skipped this step
          </Trans>
        )
      }
    }
  } else if (completion.completion_date) {
    if (completion.user.id === me.id) {
      completionMessage = (
        <Trans i18nKey="completion.header.completed_second_person">
          <strong>You</strong> completed this step
        </Trans>
      )
    } else {
      completionMessage = (
        <Trans i18nKey="completion.header.completed">
          <strong>{{ actingUserAbbrName: actingUser.display_name }}</strong>{" "}
          completed this step
        </Trans>
      )
    }
  } else {
    if (completion.user.id === me.id) {
      completionMessage = (
        <Trans i18nKey="completion.header.incomplete_second_person">
          <strong>You</strong> haven't completed this step
        </Trans>
      )
    } else {
      completionMessage = (
        <Trans i18nKey="completion.header.incomplete">
          <strong>{{ actingUserAbbrName: actingUser.display_name }}</strong>{" "}
          hasn't completed this step
        </Trans>
      )
    }
  }

  if (hideUser) return null

  return (
    <Container>
      {!isMe && <Avatar user={actingUser} size="36px" />}
      <div style={{ padding: "0 10px" }}>
        <Text.Body>{completionMessage} </Text.Body>
        {completion.is_late ? (
          <Tooltip placement="top" title={completionTime}>
            <Text.Body style={{ color: "red" }}>
              {dueTimeStatus(
                completion.completion_date,
                completion.completion_due
              )}
            </Text.Body>
          </Tooltip>
        ) : completion.completion_date ? (
          <Tooltip placement="top" title={completionTime}>
            <Text.Body>
              {moment(completion.completion_date).fromNow()}
            </Text.Body>
          </Tooltip>
        ) : null}
      </div>
    </Container>
  )
}

CompletionFeedbackLabel.displayName = "CompletionFeedbackLabel"

CompletionFeedbackLabel.propTypes = {
  stepId: PropTypes.number,
  completion: PropTypes.object
}

export default withTranslate(CompletionFeedbackLabel)
