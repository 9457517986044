import {
  Box,
  Button,
  Heading,
  Icon,
  Spacer,
  Text,
  VStack
} from "@chakra-ui/react"
import { CheckCircleFillIcon } from "@pathwright/pathicons"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import { useEffect, useState } from "react"
import FocusLock from "react-focus-lock"
import AtmosphericImage from "../shared/AtmosphericImage"
import { useShareUIContext } from "../shared/ShareUIContextProvider"
import { useSelectPeopleContext } from "./SelectPeopleContext"
import useNotifyCount from "./useNotifyCount"
import { getOutcomeCounts } from "./utils"

const tPrefix = "share_card.add_tab"

const SuccessHeading = ({ outcomeCounts }) => {
  const { tc } = useTranslate()

  let title = ""

  if (outcomeCounts.add && !outcomeCounts.email) {
    title = tc(`${tPrefix}.Adding {{count}} Person`, {
      defaultValue_plural: `${tPrefix}.Adding {{count}} People`,
      count: outcomeCounts.add
    })
  } else if (!outcomeCounts.add && outcomeCounts.email) {
    title = tc(`${tPrefix}.Inviting {{count}} Person`, {
      defaultValue_plural: `${tPrefix}.Inviting {{count}} People`,
      count: outcomeCounts.email
    })
  } else {
    title =
      tc(`${tPrefix}.Adding {{count}} Person`, {
        defaultValue_plural: `${tPrefix}.Adding {{count}} People`,
        count: outcomeCounts.add
      }) +
      " & " +
      tc(`${tPrefix}.Inviting {{count}} Person`, {
        defaultValue_plural: `${tPrefix}.Inviting {{count}} People`,
        count: outcomeCounts.email
      })
  }

  return (
    <Heading as="h2" size="lg" textAlign={"center"}>
      {title}
    </Heading>
  )
}

// Conditionally indicate that either everyone, some, or none of the selected people will
// receive notifications.
const SuccessMessage = () => {
  const { tc } = useTranslate()
  const {
    peopleSelection: { selected }
  } = useSelectPeopleContext()
  const notifyCount = useNotifyCount()

  return (
    <Text size="xs" textAlign="center">
      {tc(
        notifyCount === selected.length
          ? `${tPrefix}.Everyone will be notified within the next few minutes. You can close this screen anytime.`
          : notifyCount > 0
          ? `${tPrefix}.Notifications will be sent in the next few minutes. You can close this screen anytime.`
          : `${tPrefix}.You can close this screen anytime.`
      )}
    </Text>
  )
}

const SelectSuccess = () => {
  const { t } = useTranslate()
  const { isOpen, onClose, context } = useShareUIContext()
  const {
    addPeopleToState,
    peopleSelection: { selected },
    removeFromPeopleSelection,
    setMessage
  } = useSelectPeopleContext()

  const outcomeCounts = getOutcomeCounts(
    addPeopleToState.data?.addPeopleTo?.result?.selection,
    context,
    ["email", "add"]
  )

  const [showSuccess, setShowSuccess] = useState(false)

  useEffect(() => {
    if (addPeopleToState.data && !addPeopleToState.error) {
      setShowSuccess(true)
    }
  }, [addPeopleToState.data, addPeopleToState.error])

  const handleReset = () => {
    setMessage("")
    removeFromPeopleSelection(selected)
    setShowSuccess(false)
  }

  const handleDone = () => {
    onClose()
    // Hack: Delay state changes that change current UI until after card is closed.
    setTimeout(handleReset, 400)
  }

  // Reset success state when card is reopened since a user may simply close
  // card by clicking outside.
  useEffect(() => {
    if (isOpen && showSuccess) handleReset()
  }, [isOpen])

  return showSuccess ? (
    <FocusLock>
      <Box
        position="absolute"
        inset="0"
        bg="white"
        m={"0 !important"}
        borderRadius="var(--card-border-radius-lg)"
        zIndex={10}
      >
        <AtmosphericImage>
          <VStack h="100%" spacing={4} p={12}>
            <Spacer flex={2} />
            <Icon as={CheckCircleFillIcon} color="brand.500" boxSize={10} />
            <SuccessHeading outcomeCounts={outcomeCounts} />
            <SuccessMessage />
            <Spacer />
            <Button
              colorScheme={"brand"}
              variant="solid"
              size="lg"
              minW={"200px"}
              onClick={handleDone}
            >
              <Text size="xs" fontWeight="bold" color="white">
                {t("Done")}
              </Text>
            </Button>
            <Button
              colorScheme={"brand"}
              variant="link"
              size="lg"
              minW={"200px"}
              onClick={handleReset}
            >
              <Text size="xs" fontWeight={600} color="primaryBrandColor">
                {t(`${tPrefix}.Add more people`)}
              </Text>
            </Button>
            <Spacer flex={2} />
          </VStack>
        </AtmosphericImage>
      </Box>
    </FocusLock>
  ) : null
}

export default SelectSuccess
