import partial from "lodash/partial"
import { useAsyncFn } from "react-use"
import { getClient } from "../../../../../api/client"
import {
  CreatePasswordDocument,
  CreatePasswordMutation,
  CreatePasswordMutationVariables
} from "../../../../../api/generated"
import { setPreferredAuthMethod } from "../../../utils/preferredAuthMethod"
import {
  passwordsFormDataSchema,
  passwordsSchema,
  refinePasswordsSchema
} from "../../change/api/action"

export const schema = refinePasswordsSchema(passwordsSchema)
export const formDataSchema = refinePasswordsSchema(passwordsFormDataSchema)

const createPasswordAction = async (
  formData: FormData
): Promise<{ success: true }> => {
  const client = getClient()
  let result = await client.mutate<
    CreatePasswordMutation,
    CreatePasswordMutationVariables
  >({
    mutation: CreatePasswordDocument,
    variables: formDataSchema.parse(formData)
  })

  const success = result.data?.createPassword

  if (!success) {
    throw new Error("Failed to create password.")
  }

  // NOTE: while this action results in a password change (see https://docs.djangoproject.com/en/4.2/topics/auth/default/#session-invalidation-on-password-change),
  // the backend will *not* invalidate the user's session in this case
  // so no need to re-authenticate the user.

  setPreferredAuthMethod("password")

  return { success: true }
}

export const useCreatePasswordAction = partial(useAsyncFn, createPasswordAction)

export default createPasswordAction
