import Button from "@pathwright/ui/src/components/button/Button"
import Card from "@pathwright/ui/src/components/card/Card"
import CardLink from "@pathwright/ui/src/components/card/CardLink"
import ConnectStores from "lib/core/store/components/ConnectStores"
import * as urls from "lib/core/utils/urls"
import React from "react"
import SubscriptionInfo from "./SubscriptionInfo"
// SubscriptionActivateWrapped = require("./Activate")

const linkData = {
  viewcard: {
    icon: "credit-card",
    label: "Update Card",
    to: "/manage/profile/billing/payment/"
  },
  subscription: {
    icon: "recur-pause",
    label: "Manage Subscription",
    to: "/manage/profile/billing/subscription/"
  },
  cancelsub: {
    icon: "recur-x",
    label: "Cancel Subscription",
    to: "/manage/profile/billing/cancel/"
  },
  history: {
    icon: "credit-card",
    label: "View Payment History",
    to: "/manage/profile/billing/history/"
  },
  activate: {
    icon: "recur",
    label: "Activate Subscription",
    to: "/subscribe/"
  }
}

class BillingCard extends React.Component {
  static initClass() {
    this.displayName = "BillingCard"
  }

  componentDidMount() {
    return this.props.loadUserSubscription()
  }

  render() {
    const {
      card,
      subscription,
      subscription_plans,
      activateSubscription,
      showStatus,
      statusMessage
    } = this.props

    return (
      <Card
        title="Billing"
        showStatus={showStatus}
        statusMessage={statusMessage}
        card={card}
      >
        <SubscriptionInfo
          {...subscription}
          starting_plan={subscription_plans.starting_plan}
        />
        {!subscription || !subscription.subscription ? (
          <div>
            <Button styleType="card" to={urls.modalRoute("/subscribe/")}>
              Subscribe
            </Button>
            <CardLink {...linkData.history} />
          </div>
        ) : (
          <div>
            {!subscription.is_active && <CardLink {...linkData.activate} />}
            {subscription.subscription.card_last_4 &&
              !subscription.subscription.is_canceled_at_cycle_end && (
                <CardLink
                  {...linkData.viewcard}
                  preview={`Card ending in ${subscription.subscription.card_last_4}`}
                />
              )}
            <CardLink {...linkData.history} />
            {
              // can cancel if subscription isn't set to cancel
              subscription.is_active &&
                !subscription.subscription.is_canceled_at_cycle_end && (
                  <CardLink {...linkData.cancelsub} />
                )
            }
          </div>
        )}
      </Card>
    )
  }
}
BillingCard.initClass()

const mapStateToProps = function (state, ownProps) {
  const { subscription, subscription_loading } = state.subscribeStore.state
  const { loadUserSubscription } = state.subscribeStore.action
  const { subscription_plans } = state.schoolStore.state

  subscription_plans.starting_plan = _.first(subscription_plans)
  for (let plan of Array.from(subscription_plans)) {
    if (plan.amount < subscription_plans.starting_plan.amount) {
      subscription_plans.starting_plan = plan
    }
  }

  const showStatus = subscription_loading
  const statusMessage = "Loading..."

  const props = _.assign(
    {},
    {
      subscription,
      subscription_plans,
      loadUserSubscription,
      showStatus,
      statusMessage
    },
    ownProps
  )
  return props
}

const stores = [
  { store: "subscribe", watch: ["subscription_loading"] },
  { store: "school", watch: false }
]

export default ConnectStores(BillingCard, stores, {}, mapStateToProps)
