import FullscreenConfirm from "@pathwright/ui/src/components/confirm/FullscreenConfirm"
import get from "lodash/get"
import { usePathwrightContext } from "../pathwright/PathwrightContext"

const UserMembershipAccessExpired = () => {
  const { me, school } = usePathwrightContext()

  const accessExpired =
    get(me, "membership") && !get(me, "membership.has_access")

  return accessExpired ? (
    <FullscreenConfirm
      heading="Your membership is inactive"
      body={`Please contact ${school.name} with any questions.`}
      confirmPrompt={`Email ${school.email}`}
      onConfirm={() => {
        window.open(`mailto:${school.email}`)
      }}
      cancelPrompt="Or sign-in with a different account"
      cancelTo={{
        pathname: "/auth/sign-out/",
        query: {
          next: "/auth/sign-in/"
        }
      }}
      stack
    />
  ) : null
}
// Or sign-in with a different account.

UserMembershipAccessExpired.displayName = "UserMembershipAccessExpired"

export default UserMembershipAccessExpired
