import {
  MENTOR_GROUP_MEMBERSHIP_ROLE_ADMIN,
  MENTOR_GROUP_MEMBERSHIP_ROLE_MEMBER,
  MENTOR_GROUP_MEMBERSHIP_ROLE_MODERATOR
} from "./constants"

export const getCommunityGroupRoleLabel = (role, translator) => {
  switch (role) {
    case MENTOR_GROUP_MEMBERSHIP_ROLE_MEMBER:
      return translator("member")
    case MENTOR_GROUP_MEMBERSHIP_ROLE_MODERATOR:
      return translator("mentor")
    case MENTOR_GROUP_MEMBERSHIP_ROLE_ADMIN:
      return translator("group admin")
  }
}

// including groupId1-5 capture group names for visual clarity
const basePathnameRe = new RegExp(
  "/community/groups/(?:(?<groupId1>\\d+)/)(?:(?<groupId2>\\d+)/)?(?:(?<groupId3>\\d+)/)?(?:(?<groupId4>\\d+)/)?(?:(?<groupId5>\\d+)/)?"
)

// util for getting the base pathname for mentor groups routing
const getBaseToGroup = (groupId) => {
  if (groupId) {
    const match = window.location.pathname.match(basePathnameRe)

    // find if current pathname includes provided groupId
    if (match) {
      const groupIds = match.slice(1).filter(Boolean)
      const lastGroupId = parseInt(groupIds[groupIds.length - 1])

      // ensure we aren't appending groupId when groupId already exists in current pathname
      return groupId === lastGroupId ? match[0] : `${match[0]}${groupId}/`
    }

    // no match found, use provided groupId
    return `/community/groups/${groupId}/`
  }

  // no groupId provided, use base route
  return `/community/groups/`
}

// mentor group to prop utils
export const getToGroup = (groupId) => getBaseToGroup(groupId)
export const getToGroupAdd = (groupId) => `${getBaseToGroup(groupId)}add-group/`
export const getToGroupAddMembers = groupId => `${getBaseToGroup(groupId)}add-member/` // prettier-ignore
export const getToGroupManage = (groupId) => `${getBaseToGroup(groupId)}manage/`
export const getToGroupManageMember = (groupId, memberId) =>
  `${getBaseToGroup(groupId)}manage-member-${memberId}/`
