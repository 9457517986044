import gql from "graphql-tag"
import InvitationFragment from "./invitation-fragment.js"

export default gql`
  query InvitationsQuery(
    $first: Int!
    $context: InvitationContext
    $deliveryStatus: EmailDeliveryStatus
    $status: InvitationStatus
    $orderBy: [String]
  ) {
    invitations(
      first: $first
      context: $context
      delivery_status: $deliveryStatus
      status: $status
      order_by: $orderBy
    ) {
      edges {
        node {
          ...InvitationFragment
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${InvitationFragment}
`
