import styled from "styled-components"
import Divider from "../divider/Divider"

const StyledDivider = styled(Divider)`
  margin-top: 5px;
  margin-bottom: 5px;

  label {
    font-weight: bold;
  }
`

export default StyledDivider
