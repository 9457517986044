import gql from "graphql-tag"
import MENTOR_GROUP_FRAGMENT from "./mentor-group-fragment"

export default gql`
  mutation CreateMentorGroup(
    $name: String!
    $parent: Int
    $type: MentorGroupType
  ) {
    createMentorGroup(name: $name, parent: $parent, type: $type) {
      ...MentorGroup
      memberships(first: 5) {
        total
        edges {
          node {
            id
            user {
              ...User
            }
          }
        }
      }
    }
  }
  ${MENTOR_GROUP_FRAGMENT}
`
