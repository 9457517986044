import { HStack, Spacer, Text, VStack } from "@chakra-ui/react"
import Link from "../../../components/link/Link"
import { useScopedTranslation } from "../../../lib/lng/TranslationsProvider"
import { ActionType } from "../../../lib/types"
import { useAuthContext } from "../api/context"
import AuthFormIntentErrorAlert from "../components/AuthFormIntentErrorAlert"
import AuthMotion from "../components/AuthMotion"
import AuthOptionsDivider from "../components/AuthOptionsDivider"
import AuthSpaceHeader from "../components/AuthSpaceHeader"
import ExternalAuthMethods from "../components/ExternalAuthMethods"
import { AuthIntentKeyWithError } from "../utils/authIntent"
import { useAuthMethods } from "../utils/authMethods"
import getAuthRoute from "../utils/getAuthRoute"
import { getPreferredAuthMethod } from "../utils/preferredAuthMethod"
import useColorModeBranding from "../utils/useColorModeBranding"
import SignUpForm from "./SignUpForm"

const authIntentErrorKey: AuthIntentKeyWithError = "sign_up"

export type SignUpViewProps = ActionType

const SignUpView = (props: SignUpViewProps) => {
  const { t } = useScopedTranslation("auth.sign_up_view")
  const { pathwrightAuthMethod, externalAuthMethods } = useAuthMethods()
  const {
    inviteToken,
    consentDescription,
    userPropertyDefaults,
    alwaysPromptForPassword,
    createMembership
  } = useAuthContext()
  const colorScheme = useColorModeBranding()

  return (
    <AuthMotion>
      <VStack spacing={4} w="100%">
        <AuthSpaceHeader />
        <AuthFormIntentErrorAlert authIntentKey={authIntentErrorKey} />
        {!!pathwrightAuthMethod && (
          <SignUpForm
            {...props}
            inviteToken={inviteToken}
            consentDescription={consentDescription}
            userPropertyDefaults={userPropertyDefaults}
            alwaysPromptForPassword={alwaysPromptForPassword}
            createMembership={createMembership}
          />
        )}
        {!!pathwrightAuthMethod && !!externalAuthMethods.length && (
          <AuthOptionsDivider />
        )}
        {!!externalAuthMethods.length && (
          <ExternalAuthMethods authIntent={authIntentErrorKey} />
        )}
        <Spacer />
        <HStack>
          <Text>{t("already_have_account")} </Text>
          <Link
            colorScheme={colorScheme}
            textDecor="underline"
            whiteSpace="normal"
            display="inline"
            to={getAuthRoute(`../sign-in/${getPreferredAuthMethod()}`)}
          >
            {t("sign_in")}
          </Link>
        </HStack>
      </VStack>
    </AuthMotion>
  )
}

export default SignUpView
