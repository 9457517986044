import ManageLibraryMenu from "@pathwright/web/src/modules/library/manage/ManageLibraryMenu"
import ManageResourceLicenses from "@pathwright/web/src/modules/library/manage/ManageResourceLicenses"
import { loadAuthors } from "author/middleware"
import { loadCategories } from "category/middleware"
import { createCardRoutes } from "lib/core/routing/card-routing"
import FallbackComponent from "store/StoreOrLibraryBrowse"
import { cardRoutes as authorRoutes } from "../../author/routes"
import { cardRoutes as bannerRoutes } from "../../banner/routes"
import { cardRoutes as categoryRoutes } from "../../category/routes"
import { getStoreUrl } from "../../store/utils"

const loadLibraryFeatures = (location) => {
  loadCategories(location)
  loadAuthors(location)
}

export const LibraryCardRoutes = {
  library: {
    component: ManageLibraryMenu,
    middleware: loadLibraryFeatures,
    getDefaultLaunchedFrom: () => getStoreUrl(),
    childCards: {
      license: {
        component: ManageResourceLicenses
      },
      ...authorRoutes,
      ...categoryRoutes,
      ...bannerRoutes
    }
  }
}

export default createCardRoutes(
  "/manage/",
  LibraryCardRoutes,
  FallbackComponent
)
