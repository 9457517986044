import Alert from "@pathwright/ui/src/components/alert/Alert"
import Card from "@pathwright/ui/src/components/card/Card"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import Success from "@pathwright/ui/src/components/success/Success"
import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import get from "lodash/get"
import styled from "styled-components"
import { getPathname } from "../home/people/People"
import SCHOOL_GROUP_SUBSCRIPTION_QUERY from "../subscription/graphql/school-group-subscription-query"
import useOrderPollingQuery from "./useOrderPollingQuery"

export default function GroupSubscriptionOrderConfirmationCard({
  card,
  orderId
}) {
  const orderQuery = useOrderPollingQuery(orderId)
  const order = get(orderQuery, "data.order")
  const redirectTo = get(order, "order_data.redirect_to")
  const groupSubscriptionId = get(order, "fulfilled_object_id")
  const groupSubscriptionQuery = useQuery(SCHOOL_GROUP_SUBSCRIPTION_QUERY, {
    skip: !groupSubscriptionId,
    variables: {
      id: groupSubscriptionId
    }
  })
  const groupSubscription = get(
    groupSubscriptionQuery,
    "data.schoolGroupSubscription"
  )
  const stripeProduct = get(groupSubscription, "price.product", {})

  const error = orderQuery.error || groupSubscriptionQuery.error

  if (error) {
    return (
      <Card card={card} navless>
        <CenteredContent>
          <Alert error={error} />
        </CenteredContent>
      </Card>
    )
  }

  if (!order || !groupSubscription || !stripeProduct) {
    return (
      <Card card={card} navless>
        <LoadingCircle />
      </Card>
    )
  }

  const isMultiSeatGroupSubscription =
    get(groupSubscription, "subscription.quantity") > 1

  return (
    <Success
      icon="check"
      inverted
      heading={"Subscription Complete"}
      body={`Your subscription to ${stripeProduct.name} is complete. We've emailed you a receipt.`}
      primaryAction={{
        // NOTE: assumes that redirectTo is for returning to registration flow
        // TODO: tweak "Go back to course" wording here and in SubscriptionConfirmationCard?
        children: redirectTo ? "Go back to course" : "Get Started",
        to:
          redirectTo ||
          (isMultiSeatGroupSubscription
            ? getPathname({
                areaIndex: 1, // corresponds to "groups"
                nodeKey: `group:${groupSubscription.community_group.id}`
              })
            : "/home/")
      }}
    />
  )
}

const CenteredContent = styled.div`
  padding: 40px 25px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
`
