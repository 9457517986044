import { Image, VStack } from "@chakra-ui/react"
import { useSpaceContext } from "../../space/api/context"
import AuthFormHeading from "./AuthFormHeading"

const AuthSpaceHeader = () => {
  const { space } = useSpaceContext()

  return (
    <VStack className="AuthSpaceHeader">
      {!!space.meta.image?.src && (
        <Image
          src={space.meta.image.src}
          alt={`${space.name} image`}
          maxW="100px"
        />
      )}
      {/* TODO: use fontFamily="theme.heading" once the parent ChakraProvider uses correct heading theme font */}
      <AuthFormHeading
        pt=".5em"
        pb="1.5em"
        className="theme-heading theme-heading-bold"
      >
        {space.name}
      </AuthFormHeading>
    </VStack>
  )
}

export default AuthSpaceHeader
