import gql from "graphql-tag"
import AUTHOR_FRAGMENT from "../../../author/graphql/author-fragment"
import CATEGORY_FRAGMENT from "../../../category/graphql/category-fragment"
import RESOURCE_FRAGMENT from "../../../resource/graphql/resource-fragment"

export default gql`
  mutation (
    $id: Int!
    $name: String!
    $resource_type: ResourceType!
    $trailer_embed_code: String
    $visibility_level_override: Int
    $certificate_generator_class: String
    $discussion_enabled: Boolean
  ) {
    updateResource(
      id: $id
      name: $name
      resource_type: $resource_type
      trailer_embed_code: $trailer_embed_code
      visibility_level_override: $visibility_level_override
      certificate_generator_class: $certificate_generator_class
      discussion_enabled: $discussion_enabled
    ) {
      ...Resource
      authors(first: 10) {
        total
        edges {
          node {
            ...Author
          }
        }
      }
      categories(first: 10) {
        total
        edges {
          node {
            ...Category
          }
        }
      }
    }
  }
  ${RESOURCE_FRAGMENT}
  ${CATEGORY_FRAGMENT}
  ${AUTHOR_FRAGMENT}
`
