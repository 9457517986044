import { CHANGELOG_PATH } from "./constants"

export function getChangeLogEmbedUrl(user) {
  const embeddedPathOptions = {
    root: 1,
    sidebar: 0,
    player: 1,
    exit: 1,
    space: "pwdemo"
  }

  // Optionally set the persona when a user is provided.
  if (user) {
    embeddedPathOptions.set = "persona"
    embeddedPathOptions.name = user.full_name
    if (user.profile?.image) embeddedPathOptions.image = user.profile.image
  }

  const embeddedPathOptionsSearch = new URLSearchParams(
    embeddedPathOptions
  ).toString()

  return `${CHANGELOG_PATH}?${embeddedPathOptionsSearch}`
}
