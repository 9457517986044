import { graphql } from "@apollo/client/react/hoc"
import Button from "@pathwright/ui/src/components/button/Button"
import Card from "@pathwright/ui/src/components/card/Card"
import Gate from "@pathwright/ui/src/components/gate/Gate"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import compose from "lodash/flowRight"
import get from "lodash/get"
import PropTypes from "prop-types"
import { Component } from "react"
import {
  PAGE_CONTENT_TYPES,
  PAGE_CONTENT_TYPE_BLOCKS,
  PAGE_CONTENT_TYPE_LINK,
  PAGE_CONTENT_TYPE_TEMPLATE
} from "../constants"
import PAGE_QUERY from "../graphql/page-query"
import UPDATE_PAGE_MUTATION from "../graphql/update-page-mutation"
import ManagePageHTML from "./ManagePageHTML"
import ManagePageLink from "./ManagePageLink"

const PAGE_CONTENT_NONE = null

class ManagePageContentGate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      content_type: this.props.page
        ? this.props.page.content_type
        : PAGE_CONTENT_NONE
    }
  }

  UNSAFE_componentWillReceiveProps({ page }) {
    let content_type = page ? page.content_type : PAGE_CONTENT_NONE
    this.setState({ content_type })
  }

  selectContentType = (content_type) => {
    if (content_type) {
      // Only update content_type if content_type is not null
      this.props.onChangeContentType({
        id: this.props.page.id,
        content_type: content_type
      })
    }
    this.setState({ content_type })
  }

  render() {
    const { card, loading, page, PageBlocks } = this.props
    return (
      <Card
        card={card}
        title="Content"
        subTitle={PAGE_CONTENT_TYPES[this.state.content_type]}
      >
        {loading ? (
          <LoadingCircle />
        ) : this.state.content_type ? (
          <div>
            <div style={{ textAlign: "right" }}>
              <Button
                styleType="blank"
                brand
                onClick={(e) => {
                  this.selectContentType(PAGE_CONTENT_NONE)
                }}
              >
                Switch content <Pathicon icon="shuffle" />
              </Button>
            </div>
            {this.state.content_type === PAGE_CONTENT_TYPE_LINK && (
              <ManagePageLink id={page.id} />
            )}
            {this.state.content_type === PAGE_CONTENT_TYPE_TEMPLATE && (
              <ManagePageHTML id={page.id} />
            )}
            {this.state.content_type === PAGE_CONTENT_TYPE_BLOCKS && (
              <PageBlocks id={page.id} />
            )}
          </div>
        ) : (
          <div className="Gates">
            <div className="Gates__wrapper">
              <Gate
                line1="Link"
                line2="Link to an existing page."
                onClick={() => this.selectContentType(PAGE_CONTENT_TYPE_LINK)}
              />
              {/* hide template_content for now unless page already has template_content */}
              {page.template_content && (
                <Gate
                  line1="HTML"
                  line2="Use html to build a custom page."
                  onClick={() =>
                    this.selectContentType(PAGE_CONTENT_TYPE_TEMPLATE)
                  }
                />
              )}
              <Gate
                line1="Blocks"
                line2="Use blocks to build a custom page."
                onClick={() => {
                  this.selectContentType(PAGE_CONTENT_TYPE_BLOCKS)
                }}
              />
            </div>
          </div>
        )}
      </Card>
    )
  }
}

ManagePageContentGate.displayName = "ManagePageContentGate"

ManagePageContentGate.propTypes = {
  // provided by CardStack > CardStackCard
  card: PropTypes.object.isRequired,
  // page values
  page: PropTypes.shape({
    template_content: PropTypes.string,
    content_type: PropTypes.number
  }),
  PageBlocks: PropTypes.element.isRequired
}

export default compose(
  graphql(PAGE_QUERY, {
    options: ({ id, pageType }) => ({
      variables: {
        id,
        page_type: pageType
      }
    }),
    props: ({ data }) => ({
      error: data.error,
      loading: data.loading,
      page: get(data, "school.page")
    })
  }),
  graphql(UPDATE_PAGE_MUTATION, {
    props: ({ mutate, ownProps }) => ({
      onChangeContentType: (changes) =>
        mutate({
          variables: {
            ...ownProps.page,
            ...changes
          }
        })
    })
  })
)(ManagePageContentGate)
