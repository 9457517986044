import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import Screensize from "@pathwright/ui/src/components/ui/Screensize"
import Text from "@pathwright/ui/src/components/ui/Text"
import View from "@pathwright/ui/src/components/ui/View"
import { media } from "@pathwright/ui/src/components/utils/styles"
import styled from "styled-components"
import AddMentorGroupMembersRole from "./AddMentorGroupMembersRole"

const Footer = styled(View.Secondary)`
  flex-grow: 0;
  flex-shrink: 0;
  border-radius: 0 0 10px 10px;
  padding: 20px 25px;
  ${media.max.phone`padding:0;`};
`

const Action = styled.div`
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  .SubmitButton {
    margin-left: 5px;
  }
  ${media.max.phone`
    position: fixed;
    right: 5px;
    top: 5px;
  `};
`

const AddMentorGroupMembersAction = ({
  permissions,
  addingCount,
  selectedRole,
  onRoleChange,
  onSubmit,
  submittingStatus
}) => {
  const { t } = useTranslate()
  return (
    <Screensize.Consumer>
      {(screensize) => (
        <Footer>
          <Action>
            {screensize !== "sm" && (
              <AddMentorGroupMembersRole
                permissions={permissions}
                addingCount={addingCount}
                selectedRole={selectedRole}
                onRoleChange={onRoleChange}
              />
            )}
            <SubmitButton
              styleType="primary"
              onClick={onSubmit}
              size={screensize === "sm" ? "small" : "medium"}
              inverted={screensize === "sm"}
              isSubmitting={!!submittingStatus}
            >
              {t("Add")}
            </SubmitButton>
          </Action>
          {submittingStatus ? (
            <Text.Meta style={{ display: "block", textAlign: "center" }}>
              {submittingStatus}
            </Text.Meta>
          ) : null}
        </Footer>
      )}
    </Screensize.Consumer>
  )
}

AddMentorGroupMembersAction.displayName = "AddMentorGroupMembersAction"

export default AddMentorGroupMembersAction
