import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import get from "lodash/get"
import SSO_LINK_QUERY from "./sso-link-query"

const useSSOLink = (schoolId) => {
  const ssoLinkQuery = useQuery(SSO_LINK_QUERY, {
    variables: {
      schoolId
    },
    skip: !schoolId
  })

  return get(ssoLinkQuery, "data.context.school.sso_link")
}

export default useSSOLink
