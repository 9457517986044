// Condenses the variables so that [key].variables[key] becomes [key][key].
export const condensedVariables = (variables = {}) => {
  return Object.entries(variables).reduce(
    (acc, [key, obj]) => ({
      ...acc,
      // If obj has nested variables obj, then continue to condense.
      [key]: obj.variables ? condensedVariables(obj.variables) : null
    }),
    {}
  )
}

// Flattens the variables object into a list of variable paths.
export const flattenVariables = (
  variables,
  variablePaths = [],
  variablePath = ""
) => {
  const keys = Object.keys(variables)

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i]
    const nextPath = variablePath ? `${variablePath}.${key}` : key
    const obj = variables[key]
    if (obj.variables) {
      flattenVariables(obj.variables, variablePaths, nextPath)
    } else {
      variablePaths.push(nextPath)
    }
  }

  return variablePaths
}
