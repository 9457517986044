import SwitchButton from "@pathwright/ui/src/components/button/SwitchButton"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import { COHORTS_QUERY, CohortCard, CohortsGrid } from "../cohort/Cohorts"
import {
  GROUP_STATUS_COMPLETED,
  GROUP_STATUS_IN_PROGRESS
} from "../group/constants"
import {
  ENROLLMENT_ROLE_TEACHER,
  ROLE_TYPE_OFFERING,
  ROLE_VALUES
} from "../invitation/constants"
import { usePathwrightContext } from "../pathwright/PathwrightContext"
import {
  canEditLibrary,
  canViewHomeDesignTab,
  canViewHomeTeachTab
} from "../user/permissions"
import { flattenEdges } from "../utils/apollo"
import { getStoreUrl } from "../utils/urls"
import {
  ResourceGrid,
  ResourceThumb,
  USER_EDITOR_RESOURCES_QUERY,
  USER_RESOURCES_QUERY
} from "./design/Design"
import {
  LearnListContainer,
  LearnListItem,
  USER_REGISTRATION_QUERY
} from "./learn/Learn"
import { isRegistrationComplete } from "./learn/utils"

export const NAV_SECTION_LEARN = "learn"
export const NAV_SECTION_TEACH = "teach"
export const NAV_SECTION_DESIGN = "design"

const groupRegistrationItems = (items) => {
  // Each item must have a resource and a group for this to work

  let itemsByCohortMap = {}

  // handle unparented items first
  items
    .filter((item) => !item.group.parent_section_id)
    .forEach((item) => {
      itemsByCohortMap[item.group.id] = {
        ...item,
        childItems: []
      }
    })

  // handle parented items
  items
    .filter((item) => !!item.group.parent_section_id)
    .forEach((item) => {
      const { group } = item
      if (item.path.progress < 1) {
        if (itemsByCohortMap[group.parent_section_id]) {
          itemsByCohortMap[group.parent_section_id].childItems.push(item)
        } else {
          console.log("found orphaned registration: ", item.id)
          // for now, just add these back to the main list so they still show up
          itemsByCohortMap[item.group.id] = item
        }
      }
    })

  // TODO: how do we handle sorting the child cohorts? We really can't currently because their
  // order is tied to the path. I think just sorting by latest created reg still makes sense.

  const allItems = [...Object.values(itemsByCohortMap)].sort((a, b) =>
    a.created > b.created ? -1 : 1
  )

  return allItems
}

export const useSections = () => {
  const pwContext = usePathwrightContext()
  const { tc, tca } = useTranslate()
  const sectionData = {
    [NAV_SECTION_LEARN]: {
      title: tc("Learn"),
      to: "/home/learn/",
      section: NAV_SECTION_LEARN,
      queries: [USER_REGISTRATION_QUERY],
      blankSlate: {
        heading: tc("No in-progress Paths"),
        body: tc("Join a new Path to get started"),
        primaryAction: {
          children: (
            <>
              {tc(`learn.blank_slate.in_progress_action`)}{" "}
              <Pathicon icon="chevron-right" />
            </>
          ),
          to: getStoreUrl()
        }
      },
      searchOptions: { keys: ["group.name", "resource.name"] },
      getItemsData: (data) => {
        // make sure collections are first
        const all = flattenEdges(data.me.registrations)
        return groupRegistrationItems(all)
      },
      getSectionData: (registrations) => {
        const active = registrations.filter(
          (reg) => !isRegistrationComplete(reg)
        )
        const complete = registrations.filter(isRegistrationComplete)
        return [
          { title: tc("In-progress Paths"), data: active },
          { title: tca("Completed Paths"), data: complete }
        ]
      },
      getSectionIsVisible: () => true,
      // NOTE: this leads to awkward UX in that the newly created path will
      // not appear on the user's Learn tab (from which they are creating the path).
      // This is temporary as we await the "unified" Home view.
      cohortCreatorRole:
        ROLE_VALUES[ROLE_TYPE_OFFERING][ENROLLMENT_ROLE_TEACHER],
      ListContainer: LearnListContainer,
      ListItem: LearnListItem,
      index: 0
    },
    [NAV_SECTION_TEACH]: {
      title: tc("Teach"),
      to: "/home/teach/",
      section: NAV_SECTION_TEACH,
      searchOptions: { keys: ["name", "resource.name"] },
      queries: [COHORTS_QUERY],
      blankSlate: {
        heading: tc("No in-progress Cohorts"),
        body: tc("Create a new Cohort to get started")
      },

      getItemsData: (data) => {
        return flattenEdges(data.groups).filter((c) => !!c.registration)
      },
      getSectionData: (cohorts) => {
        const active = cohorts.filter(
          (c) => c.status === null || c.status === GROUP_STATUS_IN_PROGRESS
        )
        const complete = cohorts.filter(
          (c) => c.status === GROUP_STATUS_COMPLETED
        )
        return [
          { title: tc("In-progress Cohorts"), data: active },
          { title: tca("Completed Cohorts"), data: complete }
        ]
      },
      getSectionIsVisible: canViewHomeTeachTab,
      cohortCreatorRole:
        ROLE_VALUES[ROLE_TYPE_OFFERING][ENROLLMENT_ROLE_TEACHER],
      ListContainer: CohortsGrid,
      ListItem: CohortCard,
      index: 1
    },
    [NAV_SECTION_DESIGN]: {
      // title: tc("activity")
      title: tc("Design"),
      to: "/home/design/",
      section: NAV_SECTION_DESIGN,
      searchOptions: { keys: ["name", "resource.name"] },
      queries: [USER_RESOURCES_QUERY, USER_EDITOR_RESOURCES_QUERY],
      blankSlate: {
        heading: tc("No paths yet"),
        body: tc("Create a new Path to get started")
      },

      getItemsData: (data) => {
        let resources = flattenEdges(data.resources).filter((res) => !!res)
        return resources
      },
      getSectionData: (resources, data) => {
        const editorResourceIds =
          data && data.me.registrations
            ? flattenEdges(data.me.registrations).map((reg) => reg.resource.id)
            : []

        let userResources = []
        let allResources = []

        resources.forEach((res) => {
          if (
            res.created_by_id === data.me.id ||
            editorResourceIds.includes(res.id)
          ) {
            userResources.push(res)
            // only include 'other paths' on Design tab for library editor/admin
          } else if (canEditLibrary(pwContext)) {
            allResources.push(res)
          }
        })

        const sortByCreated = (a, b) => {
          return new Date(a.created) > new Date(b.created) ? -1 : 1
        }

        return [
          {
            title: tca("Your Paths"),
            data: userResources.sort(sortByCreated)
          },
          {
            title: tca("Other Paths"),
            data: allResources.sort(sortByCreated)
          }
        ]
      },
      getSectionIsVisible: canViewHomeDesignTab,
      cohortCreatorRole: null,
      ListContainer: ResourceGrid,
      ListItem: ResourceThumb,
      index: 2
    }
  }

  const getSection = (key) => sectionData[key] || sectionData[NAV_SECTION_LEARN]

  const sections = Object.values(sectionData)
    .filter((op) => op.getSectionIsVisible(pwContext))
    .map((op) => ({
      ...op,
      label: op.title
    }))

  return { sections, getSection }
}

const SectionNav = ({ onSelect, selectedSection }) => {
  const { sections, getSection } = useSections()

  const activeSection = getSection(selectedSection)

  const handleSelect = (i, op) => {
    if (window.App) window.App.navigate(op.to)
    onSelect(op)
  }

  if (sections.length < 2) return null

  return (
    <SwitchButton
      options={sections}
      onChange={handleSelect}
      activeIndex={activeSection.index}
    />
  )
}

export default SectionNav
