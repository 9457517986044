import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import gql from "graphql-tag"
import COHORT_FRAGMENT from "../../group/graphql/group-fragment"

export const COHORT_QUERY = gql`
  query GroupQuery($id: Int!) {
    cohort: group(id: $id) {
      ...Group
    }
  }
  ${COHORT_FRAGMENT}
`

const useCohortQuery = ({ cohortId }) => {
  const cohortQuery = useQuery(COHORT_QUERY, {
    variables: {
      id: cohortId
    },
    skip: !cohortId
  })

  return cohortQuery
}

export default useCohortQuery
