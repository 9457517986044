import { Button, HStack } from "@chakra-ui/react"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import { forwardRef } from "react"

const MetaButton = forwardRef(({ icon, label, children, ...rest }, ref) => {
  return (
    <Button
      ref={ref}
      py="1.5"
      px="4"
      size="md"
      fontWeight="normal"
      colorScheme="gray"
      tabIndex="32767" // effectively ensuring we only tab into CohortMeta after everything else within focusable tree.
      {...rest}
    >
      {children || (
        <HStack spacing={1} alignItems="center">
          {!!icon && <Pathicon icon={icon} />}
          <span>{label}</span>
        </HStack>
      )}
    </Button>
  )
})

export default MetaButton
