import {
  Box,
  Button,
  CloseButton,
  Divider,
  Flex,
  Heading,
  Input,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  useDisclosure,
  useToast,
  VStack
} from "@chakra-ui/react"
import { CheckIcon } from "@pathwright/pathicons"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import { flattenVariables } from "@pathwright/ui/src/components/variable-selector/utils"
import { useEffect, useState } from "react"
import { Link as ReactRouterLink } from "react-router-dom"
import { useCopyToClipboard } from "react-use"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import { canEditLibrary } from "../../user/permissions"
import { useCertificateContext } from "../context/CertificateContext"
import { useCertificateState } from "../context/CertificateState"
import { certificateVariables } from "../variables/variables"
import VariableTag from "../variables/VariableTag"

const Info = () => {
  const { certificateScope } = useCertificateContext()
  const pwContext = usePathwrightContext()

  if (canEditLibrary(pwContext)) {
    if (certificateScope) {
      return (
        <VStack spacing={4} alignItems="flex-start" width="100%" boxShadow="lg">
          <Box bg={"white"} p={4} borderRadius={"md"}>
            To modify the variables for all certificates visit the{" "}
            <Link
              as={ReactRouterLink}
              textDecoration="underline"
              textUnderlineOffset={2}
              to="/certificate/"
            >
              Certificate Template Editor
            </Link>
            .
          </Box>
        </VStack>
      )
    }

    return (
      <VStack spacing={4} alignItems="flex-start" width="100%" boxShadow="lg">
        <Box bg={"white"} p={4} borderRadius={"md"}>
          Type an "@" character into a Text Box to use or create a variable.{" "}
          <Link
            textDecoration="underline"
            textUnderlineOffset={2}
            href="http://help.pathwright.com/en/articles/9211185-provide-a-certificate-of-completion"
            isExternal
          >
            <Box display={"inline"} mr={".4em"}>
              Learn more
            </Box>
            <Pathicon icon="external-link" />
          </Link>
        </Box>
      </VStack>
    )
  }

  return null
}

const VariableSettings = ({ variable, onClose }) => {
  const {
    certificateScope,
    customVariablesStats,
    updateCertificatesVariables
  } = useCertificateContext()
  const {
    certificateState: { customVariables }
  } = useCertificateState()

  const [selectedVariable, setSelectedVariable] = useState(null)
  const handleSelectedVariable = (variable) =>
    setSelectedVariable((selectedVariable) =>
      selectedVariable === variable ? null : variable
    )

  const handleMerge = () => {
    updateCertificatesVariables({
      variables: {
        merge: [
          {
            current_key: variable,
            new_key: selectedVariable
          }
        ]
      }
    })
  }

  const handleDelete = () => {
    updateCertificatesVariables({
      variables: {
        merge: [
          {
            current_key: variable
          }
        ]
      }
    })
  }

  const handleSubmit = selectedVariable ? handleMerge : handleDelete

  const truncatedVar =
    variable.length > 15 ? `${variable.slice(0, 15)}...` : variable

  return (
    <Box
      position={"absolute"}
      top={0}
      left={0}
      right={0}
      bottom={0}
      backgroundColor="whitesmoke"
      zIndex={1000}
      padding={4}
      borderRadius="20px"
    >
      <CloseButton position={"absolute"} top={2} right={3} onClick={onClose} />
      <VStack spacing={4}>
        <Heading as="h5" size="sm">
          Delete @{truncatedVar}
        </Heading>
        <Box mb={4}>
          Deleting <b>@{truncatedVar}</b> will remove it from{" "}
          {certificateScope ? (
            <>this certificate.</>
          ) : (
            <>{customVariablesStats[variable]} certificate(s).</>
          )}
          {!!Object.keys(customVariables).length && (
            <>
              {" "}
              You can optionally replace <b>@{truncatedVar}</b> with:
            </>
          )}
        </Box>

        {!!Object.keys(customVariables).length && (
          <>
            <Flex flexWrap={"wrap"} gap={2}>
              {Object.keys(customVariables).map((variable) => (
                <Variable
                  key={variable}
                  variable={variable}
                  active={variable === selectedVariable}
                  count={
                    // Hide the count when scoped to resource.
                    !certificateScope && customVariablesStats[variable]
                  }
                  enableSettings={false}
                  onClick={() => handleSelectedVariable(variable)}
                />
              ))}
            </Flex>
            <Divider />
          </>
        )}

        <VStack spacing={2}>
          <Button colorScheme="red" onClick={handleSubmit}>
            Delete @{truncatedVar}
          </Button>
          {!!selectedVariable && (
            <Text color="gray.500" fontSize={"sm"}>
              Automatically replace with @{selectedVariable}
            </Text>
          )}
        </VStack>
      </VStack>
    </Box>
  )
}

const Variable = ({
  variable,
  active,
  count,
  enableSettings = false,
  onClick
}) => {
  const { certificateScope } = useCertificateContext()
  const { isOpen, onOpen, onClose } = useDisclosure()

  // NOTE: main reason why rendering VariableSettings or Popover via ternary
  // is to prevent the Popover from lingering for a second before hiding after
  // VariableSettings opens.
  return isOpen ? (
    <VariableSettings
      variable={variable}
      active={active}
      count={count}
      onClose={onClose}
    />
  ) : (
    <Popover trigger="hover" isOpen={enableSettings ? undefined : false}>
      <PopoverTrigger>
        <Box>
          <VariableTag
            variable={variable}
            active={active}
            count={count}
            onClick={onClick}
          />
        </Box>
      </PopoverTrigger>
      {/* Make Popover responsive to content width. */}
      <PopoverContent maxWidth="unset" width="unset">
        <PopoverArrow />
        <PopoverHeader>
          Used in{" "}
          {certificateScope ? (
            <>this certificate</>
          ) : (
            <>{count} certificate(s)</>
          )}
        </PopoverHeader>
        <PopoverBody>
          <Button colorScheme={"red"} onClick={onOpen}>
            <Pathicon icon="trash" style={{ marginRight: ".2em" }} /> Delete
          </Button>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

// Could handle setting state, copying, and toasting all in one handler
// but using effects feel more react-like.
const useCopyVariable = () => {
  const [copiedVariable, setCopiedVariable] = useState()
  const [_, onCopy] = useCopyToClipboard()
  const toast = useToast()

  const handleCopyVariable = (variable) => () => setCopiedVariable(variable)

  const handleCopy = () => {
    if (copiedVariable) onCopy(`@${copiedVariable}`)
  }

  const handleToast = () => {
    if (copiedVariable)
      toast({
        title: "Copied to clipboard",
        description: `@${copiedVariable}`,
        status: "success",
        duration: 3000,
        isClosable: false,
        icon: <CheckIcon />,
        onCloseComplete: () => setCopiedVariable()
      })
  }

  useEffect(handleCopy, [copiedVariable])
  useEffect(handleToast, [copiedVariable])
  useEffect(() => () => setCopiedVariable(), [])

  return handleCopyVariable
}

const CustomVariablesSection = () => {
  const { certificateScope, customVariablesStats, resource } =
    useCertificateContext()
  const {
    certificateState: { customVariables },
    setCertificateState
  } = useCertificateState()

  const handleCopyVariable = useCopyVariable()

  const handleChange = (key, value) =>
    setCertificateState({
      customVariables: {
        ...customVariables,
        [key]: value
      }
    })

  const variables = [
    ...new Set([
      ...Object.keys(customVariables),
      ...Object.keys(customVariablesStats)
    ])
  ]

  return (
    <VStack spacing={4} alignItems="flex-start" width="100%">
      <Heading as="h5" size="sm" mb={2}>
        Custom Variables
      </Heading>

      <Flex flexWrap={"wrap"} gap={2}>
        {Object.keys(variables).length ? (
          variables.map((variable) => {
            const active = variable in customVariables

            return certificateScope ? (
              <Flex
                key={variable}
                gap={2}
                alignItems="center"
                flexGrow={active ? 1 : 0}
              >
                <Variable
                  variable={variable}
                  active={active}
                  enableSettings={!active}
                  onClick={handleCopyVariable(variable)}
                />
                {active && (
                  <Input
                    type="text"
                    placeholder={`Enter @${variable} value for ${resource.name}`}
                    value={customVariables[variable]}
                    onChange={(e) => handleChange(variable, e.target.value)}
                  />
                )}
              </Flex>
            ) : (
              <Variable
                key={variable}
                variable={variable}
                active={active}
                count={customVariablesStats[variable]}
                enableSettings={!active}
                onClick={handleCopyVariable(variable)}
              />
            )
          })
        ) : (
          <Text color={"rgba(0,0,0,.4)"}>No custom variables included.</Text>
        )}
      </Flex>
    </VStack>
  )
}

const PresetVariablesSection = () => {
  const {
    certificateState: { baseVariables }
  } = useCertificateState()

  const handleCopyVariable = useCopyVariable()

  return (
    <VStack spacing={4} alignItems="flex-start" width="100%">
      <Heading as="h5" size="sm" mb={2}>
        Preset Variables
      </Heading>

      <Flex flexWrap={"wrap"} gap={2}>
        {flattenVariables(certificateVariables).map((variable) => {
          const active = variable in baseVariables

          return (
            <Variable
              key={variable}
              variable={variable}
              active={active}
              onClick={handleCopyVariable(variable)}
            />
          )
        })}
      </Flex>
    </VStack>
  )
}

const VariablesPanel = () => {
  return (
    <VStack spacing={6} pt={2} pb={4}>
      <Info />
      <CustomVariablesSection />
      <Divider />
      <PresetVariablesSection />
    </VStack>
  )
}

VariablesPanel.displayName = "VariablesPanel"

export default VariablesPanel
