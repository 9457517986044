import { chakra, Checkbox, VStack } from "@chakra-ui/react"
import { Field, Formik } from "formik"
import defaults from "lodash/defaults"
import { toFormikValidate } from "zod-formik-adapter"
import { FormikFormStateInputField } from "../../../components/form/state/FormikFormStateField"
import { useScopedTranslation } from "../../../lib/lng/TranslationsProvider"
import { ActionType } from "../../../lib/types"
import AuthFormAlert from "../components/AuthFormAlert"
import AuthFormButton from "../components/AuthFormButton"
import { UserPropertyDefaults } from "../types"
import { AUTH_EMAIL_STORAGE_KEY } from "../utils/authEmailFormStateStorage"
import { schema, SchemaType } from "./api/action"

const validate = toFormikValidate(schema)

export type SignInFormProps = ActionType & {
  inviteToken?: string | null
  userPropertyDefaults?: UserPropertyDefaults
  allowSignup: boolean
}

const SignInForm = ({
  inviteToken,
  userPropertyDefaults,
  allowSignup,
  error,
  loading,
  onSubmit
}: SignInFormProps) => {
  const { t } = useScopedTranslation("auth.sign_in_form")
  const initialValues = defaults<UserPropertyDefaults, SchemaType>(
    { ...userPropertyDefaults },
    {
      email: "",
      allowSignup
    }
  )

  return (
    <Formik
      initialValues={initialValues}
      validateOnMount={!!userPropertyDefaults}
      validate={validate}
      onSubmit={() => {}} // noop
    >
      <chakra.form onSubmit={onSubmit} w="100%">
        <VStack spacing={4} width="100%">
          <AuthFormAlert error={error} />
          <Field
            name="allowSignup"
            type="checkbox"
            as={Checkbox}
            color="text"
            isChecked={allowSignup}
            hidden
          />
          {!!inviteToken && (
            <Field
              type="text"
              name="inviteToken"
              value={inviteToken}
              hidden
              readOnly
            />
          )}
          <FormikFormStateInputField
            formStateKey={AUTH_EMAIL_STORAGE_KEY}
            localStorageOptions={{ storageType: "session" }}
            name="email"
            type="text"
            placeholder={`${t("email_placeholder")}...`}
            isDisabled={loading}
          />
          <AuthFormButton size="lg" loading={loading}>
            {t("continue")}
          </AuthFormButton>
        </VStack>
      </chakra.form>
    </Formik>
  )
}

export default SignInForm
