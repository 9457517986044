class ParsleyBehavior extends require("lib/static-shim").default(
  Marionette.Behavior
) {
  constructor(...args) {
    super(...args)
    this.fieldValidated = this.fieldValidated.bind(this)
    this.formValidated = this.formValidated.bind(this)
    this.onValidate = this.onValidate.bind(this)
  }

  onRender() {
    return _.defer(() => {
      return this.initValidation()
    })
  }

  initValidation() {
    this.form = new $.parsley.Factory(this.el)
    return this.subscribe()
  }

  onResetValidation() {
    this.unsubscribe()
    this.form.destroy()
    return this.initValidation()
  }

  fieldValidated(pfield) {
    this.view.triggerMethod("parsley:field:validated")
    const pform = pfield.parent
    return this.view.state.set("valid", pform.isValid())
  }

  formValidated(pform) {
    return this.view.triggerMethod("submit")
  }

  subscribe() {
    this.subscribeToField()
    return this.subscribeToForm()
  }

  unsubscribe() {
    this.unsubscribeToField()
    return this.unsubscribeToForm()
  }

  subscribeToField() {
    return this.form.on("field:validated", this.fieldValidated)
  }
  subscribeToForm() {
    return this.form.on("form:validated", this.formValidated)
  }
  unsubscribeToField() {
    return this.form != null
      ? this.form.off("field:validated", this.fieldValidated)
      : undefined
  }
  unsubscribeToForm() {
    return this.form != null
      ? this.form.off("form:validated", this.formValidated)
      : undefined
  }

  // This method does not get called anywhere
  addFieldError(fieldName, message) {
    const $f = this.$el.find(`*[name=${fieldName}]`)
    if ($f.length) {
      const pf = $f.data("parsleyField")
      pf.addError({ serverError: message })
      return pf.validate()
    } else {
      return console.log(`couldn't find field: ${fieldName}`)
    }
  }

  onDestroy() {
    this.unsubscribe()
    return this.form != null ? this.form.destroy() : undefined
  }

  onResetParsley() {
    return this.form != null ? this.form.reset() : undefined
  }

  isValid() {
    return this.form != null ? this.form.isValid() : undefined
  }

  onValidate(silent) {
    // Ghetto way of allowing for validating form without submitting
    if (silent == null) {
      silent = false
    }
    if (silent) {
      this.unsubscribeToForm()
    }
    this.form.validate()
    if (silent) {
      return this.subscribeToForm
    }
  }
}

export default ParsleyBehavior
