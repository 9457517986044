import { Box, IconButton, Link, Text } from "@chakra-ui/react"
import { ChevronLeftIcon } from "@pathwright/pathicons"
import { Link as ReactRouterLink } from "react-router-dom"
import { usePreMountLocation } from "../../utils/usePreviousLocation"
import { useCertificateContext } from "../context/CertificateContext"

const CertificateNavigationBar = () => {
  const { resource, certificateScope } = useCertificateContext()
  // TODO: likely should be passed as prop to component since we want components
  // to be navigation-unaware.
  const previousLocation = usePreMountLocation()
  const defaultBackTo = certificateScope?.resourceId
    ? {
        pathname: `/library/${certificateScope.resourceId}/about/`
      }
    : { pathname: "/dashboard/" }
  const backTo = previousLocation || defaultBackTo

  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      height="50px"
      width="100%"
      backdropFilter="blur(10px)"
      zIndex="110" // place above MultiSelect
      display="flex"
      alignItems="center"
      justifyContent="center"
      background="rgba(0,0,0, 0.333)"
    >
      <Link as={ReactRouterLink} to={backTo} position="absolute" left="10px">
        <IconButton
          colorScheme="whiteAlpha"
          variant="ghost"
          icon={<ChevronLeftIcon />}
        />
      </Link>

      <Text
        color="white"
        maxWidth="calc(100vw - 100px)"
        textAlign="center"
        noOfLines={1}
      >
        {resource?.name ? (
          <>{resource.name} Certificate</>
        ) : (
          <>Certificate Template</>
        )}
      </Text>
    </Box>
  )
}

export default CertificateNavigationBar
