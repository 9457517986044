import PortableFullscreen from "@pathwright/ui/src/components/fullscreen/PortableFullscreen"
import OutsideClickWrapper from "@pathwright/ui/src/components/overlay/OutsideClickWrapper"
import View from "@pathwright/ui/src/components/ui/View"
import classnames from "classnames"
import styled from "styled-components"
import PickerContainer from "./PickerContainer"

const Wrapper = styled(View.Primary)`
  &:empty {
    display: none;
  }

  &.PickerDropdown__fullscreen {
    min-height: 100vh;
  }

  &:not(.PickerDropdown__fullscreen) {
    z-index: 999999999;
    position: absolute;
    max-width: calc(100vw - 20px);
    width: 400px;
    top: calc(50% + 35px);
    left: 50%;
    transform: translateX(-50%);
  }
`

const Container = styled.div`
  max-width: 100%;
  border-radius: inherit;

  ${Wrapper}:not(.PickerDropdown__fullscreen) & {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);

    &:before {
      content: "";
      position: absolute;
      left: calc(50% - 10px);
      top: -10px;
      width: 0;
      height: 0;
      border-bottom: 10px solid white;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
    }
  }
`

const OverflowContainer = styled.div`
  padding: 10px;
  border-radius: inherit;

  ${Wrapper}:not(.PickerDropdown__fullscreen) & {
    /* allows for funky background in Picker children to be clipped to this containing View's border-radius */
    overflow: hidden;
    /* fixing overflow glitch on hover in Safari https://gist.github.com/ayamflow/b602ab436ac9f05660d9c15190f4fd7b */
    -webkit-mask-image: -webkit-radial-gradient(white, black);
  }
`

const PickerDropdown = ({ onOutsideClick, ...props }) => (
  <PortableFullscreen showIcons={false}>
    {({ porting }) => (
      <OutsideClickWrapper
        mouseEvent="mouseup"
        shouldListen={!porting}
        onOutsideClick={onOutsideClick}
      >
        <Wrapper
          className={classnames("PickerDropdown", {
            ["PickerDropdown__fullscreen"]: porting
          })}
          rounded={!porting}
        >
          <Container>
            <OverflowContainer>
              <PickerContainer {...props} contained />
            </OverflowContainer>
          </Container>
        </Wrapper>
      </OutsideClickWrapper>
    )}
  </PortableFullscreen>
)

PickerDropdown.displayName = "PickerDropdown"

export default PickerDropdown
