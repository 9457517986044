import Link from "@pathwright/ui/src/components/link/Link"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import ProgressCircle from "@pathwright/ui/src/components/progress/ProgressCircle"
import Tooltip from "@pathwright/ui/src/components/tooltip/Tooltip"
import Text from "@pathwright/ui/src/components/ui/Text"
import styled from "styled-components"
import { getInboxUrl } from "../../utils/urls"
import ReportActions from "./ReportActions"
import ReportPathCompletionLabel from "./ReportPathCompletionLabel"

const Container = styled.div`
  position: relative;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  cursor: pointer;

  html.no-touch &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  html.no-touch &:not(:hover) .ReportActions i.pwai-ellipses-horizontal {
    visibility: hidden;
  }

  > *:not(:last-child) {
    margin-right: 8px;
  }
`
const CompletionState = styled.div`
  min-width: 25px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`
const Name = styled(Text.Body)`
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
const BackgroundLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 5px;
`

const Tip = styled(Tooltip)`
  display: flex !important;
  align-items: center;
  justify-content: center;
`

const ReportPathStep = ({ pathItem, to }) => {
  const iconSize = 20
  const pathiconStyle = { fontSize: `${iconSize}px` }
  const inboxTo = getInboxUrl({
    cohortId: pathItem.context.cohort_id,
    userId: pathItem.completion.user ? pathItem.completion.user.id : null,
    stepId: pathItem.id,
    selectedParent: pathItem.context.cohort_id
  })

  // Allow `to` link to take presedence even when set to null.
  const linkTo = to || to === null ? to : inboxTo

  return (
    <Container>
      <BackgroundLink to={linkTo} />
      <CompletionState>
        {pathItem.completion.is_skipped ? (
          <Tip title="Skipped">
            <Pathicon style={pathiconStyle} icon="skip-circle" />
          </Tip>
        ) : pathItem.completion.is_complete &&
          pathItem.completion.needs_grading ? (
          <Tip title="Needs Review">
            <Pathicon style={pathiconStyle} icon="check-dash-circle" />
          </Tip>
        ) : (
          <Tip title={`${Math.floor(pathItem.progress * 100)}% complete`}>
            {pathItem.completion.is_complete ? (
              <Pathicon style={pathiconStyle} icon="check-circle" />
            ) : (
              <ProgressCircle
                progress={pathItem.progress}
                size={iconSize}
                strokeColor="#000"
                strokeWidth={8}
              />
            )}
          </Tip>
        )}
      </CompletionState>
      <Name disabled={!pathItem.is_complete}>
        {pathItem.type === "resource"
          ? pathItem.linked_resource.name
          : pathItem.name}
      </Name>
      <ReportPathCompletionLabel pathItem={pathItem} />
      <ReportActions pathItem={pathItem} />
    </Container>
  )
}

ReportPathStep.displayName = "ReportPathStep"

export default ReportPathStep
