import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import React, { Suspense } from "react"
import HomeOrLibraryBrowse from "../home/components/HomeOrLibraryBrowse"
import { authRedirect } from "../lib/core/routing/middleware"

const Inbox = React.lazy(() =>
  import(
    "@pathwright/web/src/modules/inbox/InboxContainer"
    /* webpackChunkName: "Inbox" */
  )
)
const InboxContainer = () => {
  const handleClose = () => {
    // TODO: make sure inbox params are cleared
    window.App.getStore("navigation").action.exitModal()
  }

  return (
    <Suspense fallback={<LoadingCircle />}>
      <Inbox onClose={handleClose} />
    </Suspense>
  )
}

export default {
  path: "/inbox/",
  onEnter: authRedirect,
  components: {
    primary: HomeOrLibraryBrowse,
    secondary: InboxContainer
  },
  getDefaultLaunchedFrom: () => "/home/"
}
