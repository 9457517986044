import gql from "graphql-tag"
import USER_FRAGMENT from "../../user/graphql/user-fragment"

export default gql`
  fragment Path on Path {
    id
    progress
    points {
      path_id
      total
      earned
      total_points_possible
      percentage
      has_points
    }
    next_step_id
    completion_date
    created_date
    sync {
      path_id
      synced
      last_synced_date
      sync_source_path_id
      # NOTE: including user_id in order for GQL's path query to know
      # whether to automatically sync the path or not.
      user_id
    }
    user {
      ...User
    }
  }
  ${USER_FRAGMENT}
`
