import { useMemo } from "react"
import { updateCacheOnReview } from "../../completion/feedback/useSaveCompletionFeedbackMutation"
import resetCompletionFeedbackMutations from "../../completion/graphql/reset-completion-feedback-mutations"
import saveCompletionFeedbackMutation from "../../completion/graphql/save-completion-feedback-mutation"
import { getApolloClient } from "../../pathwright/PathwrightClient"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"

const useReviewMutations = () => {
  const { school } = usePathwrightContext()
  const client = useMemo(() => getApolloClient(school.id), [school.id])

  const reviewItem = async ({
    item,
    userpoints_explanation,
    userpoints_earned,
    notify = true
  }) => {
    const variables = {
      id: item.id,
      pathId: item.data.path_id,
      userpointsEarned: userpoints_earned,
      userpointsExplanation: userpoints_explanation,
      notify
    }
    let result = await client.mutate({
      mutation: saveCompletionFeedbackMutation,
      variables,
      update: updateCacheOnReview(
        item.user.id,
        item.data.completion.needs_grading,
        (result) => result.data.saveCompletionFeedback.needs_grading
      ),
      refetchQueries: ["InboxQuery"]
    })
    return result
  }

  const resetReview = async (item) => {
    const variables = {
      id: item.id,
      pathId: item.data.path_id
    }
    let result = await client.mutate({
      mutation: resetCompletionFeedbackMutations,
      variables,
      update: updateCacheOnReview(
        item.user.id,
        item.data.completion.needs_grading,
        (result) => result.data.resetCompletionFeedback.needs_grading
      ),
      refetchQueries: ["InboxQuery"]
    })
    return result
  }

  return { reviewItem, resetReview }
}

export default useReviewMutations
