import { useTranslation } from "react-i18next"
import { z } from "zod"
import { zfd } from "zod-form-data"
import { exhaustiveGuard } from "../../../lib/utils/exhaustive-switch"
import { useAuthContext } from "../api/context"

export const AUTH_INTENT_PARAM_KEY = "auth_intent" as const
export const AUTH_INTENT_ERROR_PARAM_KEY = "auth_intent_error" as const
export const AUTH_INTENT_EMAIL_PARAM_KEY = "auth_intent_email" as const

// Specifies the intent of the user when authententicating.
// Maps the auth intent to the possible error the user could receive.
const authIntentSchema = z.union([
  z.object({
    [AUTH_INTENT_PARAM_KEY]: z.literal("sign_in:without_password"),
    [AUTH_INTENT_ERROR_PARAM_KEY]: z.literal("account_doesnt_exist"),
    [AUTH_INTENT_EMAIL_PARAM_KEY]: z.string()
  }),
  z.object({
    [AUTH_INTENT_PARAM_KEY]: z.literal("sign_in:password"),
    [AUTH_INTENT_ERROR_PARAM_KEY]: z.literal("account_doesnt_exist"),
    [AUTH_INTENT_EMAIL_PARAM_KEY]: z.string()
  }),
  z.object({
    [AUTH_INTENT_PARAM_KEY]: z.literal("sign_up"),
    [AUTH_INTENT_ERROR_PARAM_KEY]: z.literal("account_already_exists"),
    [AUTH_INTENT_EMAIL_PARAM_KEY]: z.string()
  }),
  z.object({
    [AUTH_INTENT_PARAM_KEY]: z.literal("sign_in_or_sign_up"),
    [AUTH_INTENT_ERROR_PARAM_KEY]: z.literal(null),
    [AUTH_INTENT_EMAIL_PARAM_KEY]: z.string()
  })
])

const authIntentParamsSchema = zfd
  .formData(authIntentSchema)
  .transform((val) => ({
    key: val.auth_intent,
    error: val.auth_intent_error,
    email: val.auth_intent_email
  }))

// Specifies the intent of the user when authententicating.
// Maps the auth intent to the possible error the user could receive.
export type AuthIntent = z.output<typeof authIntentParamsSchema>

export type AuthIntentKey = AuthIntent["key"]

export type AuthIntentKeyWithError = AuthIntentKey
export type AuthIntentErrorKey = AuthIntent["error"]

// Get the auth intent error from the url search params, if it is present.
export const getAuthIntent = (): AuthIntent | null => {
  const serachParams = new URLSearchParams(window.location.search)
  const safeParsed = authIntentParamsSchema.safeParse(serachParams)
  if (safeParsed.success) {
    return safeParsed.data
  }
  return null
}

export const useAuthIntentErrorText = (
  authIntentKey: AuthIntentKeyWithError
): string | null => {
  const { t } = useTranslation()
  const { authIntent } = useAuthContext()
  if (authIntent && authIntent.key === authIntentKey) {
    const { email, error } = authIntent
    switch (error) {
      case "account_already_exists":
        return t("Account associated with {{email}} already exists.", { email })
      case "account_doesnt_exist":
        return t("No account associated with {{email}} found.", { email })
      case null:
        return null
      default:
        exhaustiveGuard(error)
    }
  }
  return null
}
