import { graphql } from "@apollo/client/react/hoc"
import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import CardBlock from "@pathwright/ui/src/components/card/CardBlock"
import TextInput from "@pathwright/ui/src/components/form/form-text-input/TextInput"
import ToggleInput from "@pathwright/ui/src/components/form/form-toggle/ToggleInput"
import compose from "lodash/flowRight"
import get from "lodash/get"
import PropTypes from "prop-types"
import { Field, Form, Submit } from "react-connect-form-forked"
import PAGE_QUERY from "../graphql/page-query"
import UPDATE_PAGE_MUTATION from "../graphql/update-page-mutation"

const ManagePageLink = ({ initialValue, onSubmit }) => {
  return (
    <Form
      initialValue={initialValue}
      onSubmit={(formValues) =>
        onSubmit({
          ...formValues,
          external_url: !formValues.is_iframe_url ? formValues.url : "",
          iframe_url: formValues.is_iframe_url ? formValues.url : "",
          id: initialValue.id
        })
      }
      render={({ onSubmit, ...formState }) => {
        return (
          <form onSubmit={onSubmit}>
            <CardBlock>
              <Field
                name="url"
                label="Link"
                type="text"
                placeholder="The link to your page."
                component={TextInput}
                labelWidth={130}
              />
            </CardBlock>
            {formState.value.url && (
              <CardBlock>
                <Field
                  name="is_iframe_url"
                  label="Load in iframe"
                  type="custom"
                  helperText="Choose to either load this link in an iframe or open this link in a new tab."
                  component={ToggleInput}
                  labelWidth={130}
                />
              </CardBlock>
            )}

            <Submit
              styleType="primary"
              component={SubmitButton}
              disabled={!formState.valid}
            >
              Save
            </Submit>
          </form>
        )
      }}
    />
  )
}

ManagePageLink.displayName = "ManagePageLink"

ManagePageLink.propTypes = {
  // handler to save field values
  onSubmit: PropTypes.func.isRequired,
  // field values
  initialValue: PropTypes.shape({
    url: PropTypes.string
  })
}

export default compose(
  graphql(PAGE_QUERY, {
    options: ({ id, pageType }) => ({
      variables: {
        id,
        page_type: pageType
      }
    }),
    props: ({ data }) => ({
      initialValue: get(data, "school.page") && {
        ...data.school.page,
        url:
          get(data, "school.page.external_url") ||
          get(data, "school.page.iframe_url"),
        is_iframe_url: !!get(data, "school.page.iframe_url")
      }
    })
  }),
  graphql(UPDATE_PAGE_MUTATION, {
    props: ({ mutate, ownProps }) => ({
      onSubmit: (changes) => {
        return mutate({
          variables: {
            ...ownProps.initialValue,
            ...changes
          },
          refetchQueries: ["PageQuery"]
        })
      }
    })
  })
)(ManagePageLink)
