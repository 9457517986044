import get from "lodash/get"
import set from "lodash/set"
import { getNotificationUrl } from "../urls"
import { getItemActionIcon } from "../utils"

const completionLinkRe = /\/completion\/(?<completionId>\d+)\//

export const getItemContextData = (item) => {
  let data = { sourceId: null, stepId: null }

  if (item.type === "completion") {
    return { stepId: item.id }
  }

  if (item.data.from_user_id) data.userId = parseInt(item.data.from_user_id)

  if (item.data.context_data && item.data.context_data.step) {
    // the ID here may be the source ID, not the step ID so that's probably an issue...
    data.sourceId = parseInt(item.data.context_data.step)
  }

  if (item.data.action === "completed") {
    data.stepId = parseInt(item.data.resource_key.split(":")[1])
  }

  // Attempt to grab the completion ID from the notification link.
  if (!data.stepId) {
    try {
      const match = new URL(item.data.link).pathname.match(completionLinkRe)
      if (match) {
        data.stepId = parseInt(match.groups.completionId)
      }
    } catch {}
  }

  return data
}

const makeSearchString = (str) => {
  // strip the title of any punctuation
  str = str.replace(/[^\w\s]/gi, "")
  return str.toLowerCase()
}

// Validates data meant for updating a notification item,
// ensuring we don't overwrite data unexpectedly.
const validateData = (data) => {
  const failure = (path, value) => {
    throw Error(`Invalid value ${value} supplied for "${path}".`)
  }
  const assertBool = (value) => typeof value === "boolean"

  // Map of assertions for the possible paths that could be updated.
  const assertions = {
    read: assertBool,
    pinned: assertBool
  }

  // Filter out all unexpected data, and assert the expected data
  // has the correct shape.
  const filteredData = Object.entries(assertions).reduce(
    (obj, [path, assertion]) => {
      const value = get(data, path)
      // Only run assertions on paths that have a value.
      if (typeof value !== "undefined") {
        if (!assertion(value)) {
          failure(path, value)
        } else {
          set(obj, path, value)
        }
      }
      return obj
    },
    {}
  )

  return filteredData
}

const parseAdded = (data, i18n) => {
  const context_type = data.action_id.split(":")[1]

  // NOTE: we parse out the user so as to highlight the user name in bold in the notification.
  // Including the {{ user }} bit for a comprehensible translation, though functionally unnecessary.
  let tOptions = {
    // user: data.user
  }
  let translation

  // TODO: handle group and school context types.
  switch (context_type) {
    case "cohort":
      tOptions.path = data.context_data.course_name
      switch (data.context_data.role) {
        case -1:
          translation = "{{ user }} added you as an observer to {{ path }}"
          break
        case 5:
          translation = "{{ user }} added you as a learner to {{ path }}"
          break
        case 10:
          translation = "{{ user }} added you as a moderator to {{ path }}"
          break
        case 15:
          translation = "{{ user }} added you as a teacher to {{ path }}"
          break
        case 20:
          translation = "{{ user }} added you as a path editor to {{ path }}"
          break
      }
      break
    case "group":
      tOptions.group = data.context_data.group_name
      translation = "{{ user }} added you to {{ group }}"
      break
  }

  // Ugly hack due to our {{ user }} translation hackery above.
  return i18n.t(translation, tOptions).split("{{ user }}")[1]
}

const parseInvited = (data, i18n) => {
  const context_type = data.action_id.split(":")[1]

  // NOTE: we parse out the user so as to highlight the user name in bold in the notification.
  // Including the {{ user }} bit for a comprehensible translation, though functionally unnecessary.
  let tOptions = {
    // user: data.user
  }
  let translation

  // TODO: handle group and school context types.
  switch (context_type) {
    case "cohort":
      tOptions.path = data.context_data.course_name
      switch (data.context_data.role) {
        case -1:
          translation = "{{ user }} invited you to observe {{ path }}"
          break
        case 5:
          translation = "{{ user }} invited you to learn {{ path }}"
          break
        case 10:
          translation = "{{ user }} invited you to moderate {{ path }}"
          break
        case 15:
          translation = "{{ user }} invited you to teach {{ path }}"
          break
        case 20:
          translation = "{{ user }} invited you to edit {{ path }}"
          break
      }
  }

  // Ugly hack due to our {{ user }} translation hackery above.
  return i18n.t(translation, tOptions).split("{{ user }}")[1]
}

const getContextTranslation = (data, i18n) => {
  const { context, action } = data

  switch (action) {
    case "assigned":
      const assignedRe = /^you (one|[0-9]+) resource[s]? to ([A-Za-z]+)$/
      const assignedMatch = context.match(assignedRe)
      if (assignedMatch) {
        const [_, count, verb] = assignedMatch
        const isPlural = count === "one"
        const verbT = i18n.t(`notifications.list_item.role_verbs.${verb}`)
        const translation = isPlural
          ? i18n.t(`notifications.list_item.contexts.assigned_you`, {
              verb: verbT
            })
          : i18n.t(`notifications.list_item.contexts.assigned_you_plural`, {
              count,
              verb: verbT
            })
        return translation
      }
    case "posted":
      const postedCompletionRe =
        /^on (your|their|[a-zA-Z]+'s) completion of (.+)$/
      const postedCompletionMatch = context.match(postedCompletionRe)

      const postedFeedbackOnCompletionRe =
        /^feedback on (your|their|[a-zA-Z]+'s) completion of (.+)$/
      const postedFeedbackOnCompletionMatch = context.match(
        postedFeedbackOnCompletionRe
      )

      const match = postedCompletionMatch || postedFeedbackOnCompletionMatch

      if (match) {
        const [_, completionOwner, step] = match
        const isFeedback = !!postedFeedbackOnCompletionMatch

        const tPath = `notifications.list_item.contexts`

        const getFeedbackCompletionTranslation = () => {
          return completionOwner === "their"
            ? i18n.t(`${tPath}.posted_feedback_on_completion_own`, { step })
            : completionOwner === "your"
            ? i18n.t(
                `notifications.list_item.contexts.posted_feedback_on_completion_2nd`,
                {
                  step
                }
              )
            : i18n.t(
                `notifications.list_item.contexts.posted_feedback_on_completion_3rd`,
                {
                  step,
                  owner: completionOwner
                }
              )
        }

        const getCompletionTranslation = () => {
          return completionOwner === "their"
            ? i18n.t(`${tPath}.posted_on_completion_own`, { step })
            : completionOwner === "your"
            ? i18n.t(
                `notifications.list_item.contexts.posted_on_completion_2nd`,
                {
                  step
                }
              )
            : i18n.t(
                `notifications.list_item.contexts.posted_on_completion_3rd`,
                {
                  step,
                  owner: completionOwner
                }
              )
        }

        const translation = isFeedback
          ? getFeedbackCompletionTranslation()
          : getCompletionTranslation()

        return translation
      }
    case "replied":
      const repliedQuestionRe = /^(to your )?question: (.+)$/
      const repliedQuestionMatch = context.match(repliedQuestionRe)
      if (repliedQuestionMatch) {
        let tKey = "notifications.list_item.contexts"

        if (repliedQuestionMatch.length === 3) {
          const [_, toYour, question] = repliedQuestionMatch
          tKey = `${tKey}.replied_to_your_question`
          return i18n.t(tKey, { question })
        } else {
          const [_, question] = repliedQuestionMatch
          tKey = `${tKey}.replied_to_question`
          return i18n.t(tKey, { question })
        }
      }
    case "replied to":
      const repliedToRe = /^you(r reply)?$/
      const repliedToMatch = context.match(repliedToRe)
      if (repliedToMatch) {
        const [_, rReply] = repliedToMatch
        const tKey = "notifications.list_item.contexts"
        return i18n.t(
          `${tKey}.${rReply ? "replied_to_your_reply" : "replied_to_you"}`
        )
      }
    case "asked you to reply to":
    case "Asked you to respond to":
    case "Asked you to answer":
      const askedToReplyResponseRe = /^(?<ownder>[a-zA-Z]+)'s response$/
      const askedToReplyQuestionRe =
        /^(?<ownder>[a-zA-Z]+)'s question: (("Feedback for step: (?<feedbackQuestion>.+)")|(?<question>.+))$/
      const askedToReplyResponseMatch = context.match(askedToReplyResponseRe)
      const askedToReplyQuestionMatch = context.match(askedToReplyQuestionRe)

      if (askedToReplyResponseMatch) {
        const tKey = "notifications.list_item.contexts"
        return i18n.t(
          `${tKey}.asked_to_reply_to_response`,
          askedToReplyResponseMatch.groups
        )
      } else if (askedToReplyQuestionMatch) {
        // Parse feeback discussions slightly differently from non-feedback discussion.
        const tKey = "notifications.list_item.contexts"
        const { feedbackQuestion } = askedToReplyQuestionMatch.groups
        if (feedbackQuestion) {
          return i18n.t(`${tKey}.asked_to_reply_to_feedback_question`, {
            ...askedToReplyQuestionMatch.groups,
            // We stripped the surrounding quote marks when matching, so add them back.
            feedbackQuestion: `"${feedbackQuestion}"`
          })
        } else {
          return i18n.t(
            `${tKey}.asked_to_reply_to_question`,
            askedToReplyQuestionMatch.groups
          )
        }
      }
      break
    case "added":
      return parseAdded(data, i18n)
    case "invited":
      return parseInvited(data, i18n)
    default:
      return ""
  }
}

const handleLegacyTranslations = (action, contextParts, i18n) => {
  if (
    contextParts[0] === "question" &&
    contextParts[1] &&
    action === "replied"
  ) {
    return i18n.t("notifications.list_item.contexts.replied_to_question", {
      question: contextParts[1]
    })
  }

  return ""
}

// Supporting existing translations.
// TODO: notifications should each have a notification type and
// context data should be void of `{{ context.type }}: "{{ context.title }}"`
// method. Rather, the consumer of notifications should build the translated
// notification title based on notification type (like an int).
const getNotificationTitle = (item, i18n) => {
  const context = item.context.replace("step: ", "")
  const contextParts = context.split(":")
  const actionTPath = `notifications.list_item.actions.${item.action}`
  const contextTypeTPath = `notifications.types.${contextParts[0]}`

  const action = i18n.exists(actionTPath)
    ? i18n.t(actionTPath)
    : item.action.toLowerCase()

  const getDefaultTranslation = () => `${action} ${contextParts.join(":")}`

  let title = ""

  const legacyTranslation = handleLegacyTranslations(action, contextParts, i18n)

  // Support some legacy translation issues
  if (legacyTranslation) {
    title = legacyTranslation
  } else if (i18n.exists(contextTypeTPath)) {
    // Ex: "liked your reponse" or "completed step: [step name]"
    contextParts[0] = i18n.t(contextTypeTPath)
    title = getDefaultTranslation()
  } else {
    // More complex translations, ex: posted on {{owner}}'s completion of {{step}}
    title = getContextTranslation(item, i18n)
  }

  // Untranslated case (English)
  if (!title) {
    title = getDefaultTranslation()
  }

  return title
}

export const parseNotification = (n, i18n) => {
  if (!validateInboxNode(n)) return {}

  const { stepId, sourceId, userId } = getItemContextData({ data: n })

  let title = getNotificationTitle(n, i18n)
  let search = makeSearchString(`${n.user} ${title}`)

  let iconMeta = n.action ? getItemActionIcon(n) : null

  return {
    user_id: userId,
    type: "item",
    meta: {
      user: n.user,
      title,
      search,
      url: getNotificationUrl(n),
      ...iconMeta
    },
    data: { stepId, sourceId, ...n }
  }
}

export const validateInboxNode = (n) => {
  if (n.action === undefined) return false
  if (n.context === undefined) return false
  return true
}

export const parseNotificationsData = (data, i18n) => {
  let nextNodes = {}
  let userNodes = {}
  let stepNodes = {}
  Object.keys(data).forEach((key) => {
    // message node

    if (!validateInboxNode(data[key])) return

    let n = { ...parseNotification(data[key], i18n), id: key }
    nextNodes[n.id] = n

    // persona node
    let userId = n.data.from_user_id
    let userName = n.data.user
    let image = n.data.image
    if (!userNodes[userId]) {
      let initials = userName
        .split(" ")
        .map((name) => name[0])
        .join("")
      userNodes[userId] = {
        id: userId,
        type: "persona",
        data: { name: userName, image: image, initials }
      }
    }

    // step node (if applicable)
    if (
      n.type === "item" &&
      n.data &&
      n.data.resource_key &&
      n.data.resource_key.indexOf("completion:") > -1
    ) {
      const { stepId } = getItemContextData(n)
      if (!stepNodes[stepId]) {
        const step = {
          id: stepId,
          user_id: userId,
          type: "step",
          meta: {
            title: n.data.context.replace("step:", "")
          },
          data: { ...n.data }
        }
        stepNodes[stepId] = step
      }
    }
  })

  return { ...nextNodes, ...userNodes, ...stepNodes }
}
