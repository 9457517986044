import { useMutation } from "@apollo/client"
import SKIP_PATH_ITEM_FOR_USER from "../graphql/skip-path-item-for-user-mutation"

const useSkipPathItemForUser = (pathItem) => {
  const [skipPathItemForUser, { data, loading, error }] = useMutation(
    SKIP_PATH_ITEM_FOR_USER,
    {
      variables: {
        id: pathItem.id,
        path_id: pathItem.path_id
      },
      refetchQueries: ["PathQuery", "PathPerformanceQuery"]
    }
  )

  return [skipPathItemForUser, { data, loading, error }]
}

export default useSkipPathItemForUser
