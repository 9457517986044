import gql from "graphql-tag"
import STRIPE_PRICE_FRAGMENT from "../stripe/graphql/stripe-price-fragment"
import STRIPE_PRODUCT_FRAGMENT from "../stripe/graphql/stripe-product-fragment"

export const COHORTS_INCLUDED_PRODUCTS_QUERY = gql`
  query CohortsIncludedProductsQuery(
    $resourceId: Int
    $visibilityFilter: GroupVisibilityFilter
  ) {
    groups(
      resource_id: $resourceId
      visibility_filter: $visibilityFilter
      first: 50
    ) {
      edges {
        node {
          id
          include_with_products(first: 50) {
            edges {
              node {
                ...StripeProduct
                stripe_prices(first: 50) {
                  edges {
                    node {
                      ...StripePrice
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${STRIPE_PRODUCT_FRAGMENT}
  ${STRIPE_PRICE_FRAGMENT}
`

export const COHORT_MEMBERS_STATS_QUERY = gql`
  query CohortMembersStatsQuery($id: Int!) {
    cohort: group(id: $id) {
      id
      member_stats {
        total
        send_discussion_notifications
      }
    }
  }
`
