import classnames from "classnames"
import isFunction from "lodash/isFunction"
import PropTypes from "prop-types"
import React, { useEffect } from "react"
import styled from "styled-components"
import Button from "../button/Button"
import IconButton from "../button/IconButton"
import useCopyToClipboard from "../hooks/useCopyToClipboard"
import CountIndicator from "../indicator/CountIndicator"
import { useOverlayTriggerContext } from "../overlay/OverlayTrigger"
import Pathicon from "../pathicon/Pathicon"
import Text from "../ui/Text"
import { useDropDownTriggerContext } from "./DropdownTrigger"

const StyledDropdownItem = styled.li`
  white-space: nowrap;
  font-size: 16px;
  text-align: left;
  cursor: pointer;
  position: relative;
  width: 100%;

  & > .Button {
    padding: 5px 10px 5px 10px;
    text-align: left;

    > .Button__content-wrapper {
      justify-content: flex-start;
    }
  }

  /* allowing for multiline text */
  ${(p) =>
    p.multiline
      ? `min-width: 200px;
  
    .Button__content-wrapper {
      white-space: normal !important;
  
      span {
        white-space: normal !important;
      }
    }`
      : ""}
`

const StyledDropdownItemContent = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;
`

const StyledDropdownItemLabel = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 100%;

  > .Pathicon:first-child {
    display: inline-block;
    text-align: center;
    font-size: 0.9em;
  }

  > .Pathicon {
    margin-right: 0.4em;
  }
`

const StyledMeta = styled(Text.Meta)`
  white-space: normal;
`

const StyledIndicator = styled(CountIndicator)`
  position: static !important;
  margin-left: 10px;
`

function PostIcon({ href, copyValue: copyValueProp, postIcon: postIconProp }) {
  // By defalt, attempt to show a "copy" post icon for copying an email address.
  const email =
    typeof href === "string"
      ? href.match(/mailto:(?<email>.+)/)?.groups?.email
      : null
  const copyValue = typeof copyValueProp === "undefined" ? email : copyValueProp
  const handleCopyEmail = useCopyToClipboard(copyValue)

  const postIcon =
    typeof postIconProp === "undefined"
      ? copyValue
        ? {
            icon: "copy",
            styleType: "secondary",
            onClick: (e) => {
              // Prevent default behavior of clicking dropdown item.
              e.preventDefault()
              handleCopyEmail()
            }
          }
        : null
      : postIconProp

  return postIcon ? (
    <div
      style={{
        marginLeft: ".4em",
        flexGrow: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end"
      }}
    >
      {typeof postIcon === "string" ? (
        <Pathicon icon={postIcon} />
      ) : (
        <IconButton {...postIcon} />
      )}
    </div>
  ) : null
}

const DropdownItem = React.forwardRef((props, ref) => {
  const className = classnames("Dropdown__Item", props.className)
  const {
    icon,
    postIcon,
    label,
    meta,
    children,
    indicator,
    closeOnClick,
    onClick,
    interactive,
    styleType,
    active,
    preventHide,
    multiline,
    copyValue,
    ...buttonProps
  } = props

  const dpContext = useDropDownTriggerContext()
  const overlayContext = useOverlayTriggerContext()
  const hideOverlay = overlayContext ? overlayContext.hideOverlay : null

  // update preventHide in context if preventHide prop changes
  useEffect(() => {
    if (
      typeof preventHide === "boolean" &&
      preventHide !== dpContext.preventHide
    ) {
      dpContext.setPreventHide(preventHide)
    }
  }, [preventHide])

  const handleClick = (e) => {
    if (closeOnClick) {
      isFunction(hideOverlay) && hideOverlay(e)
    }
    isFunction(onClick) && onClick(e)
  }

  let color = null
  if (interactive === false) {
    color = "#a4a4a4"
  }
  color = color || buttonProps.color

  return (
    <StyledDropdownItem ref={ref} className={className} multiline={multiline}>
      <Button
        styleType={styleType ? styleType : active ? "secondary" : "tertiary"}
        stretch
        rounded={false}
        onClick={handleClick}
        interactive={interactive}
        color={color}
        {...buttonProps}
      >
        {children ? (
          children
        ) : (
          <StyledDropdownItemContent>
            <StyledDropdownItemLabel>
              {icon && <Pathicon icon={icon} />}
              {label}
              {indicator ? (
                typeof indicator === "number" ? (
                  <StyledIndicator>{indicator}</StyledIndicator>
                ) : (
                  <StyledIndicator />
                )
              ) : null}
              <PostIcon
                href={buttonProps.href}
                copyValue={copyValue}
                postIcon={postIcon}
              />
            </StyledDropdownItemLabel>
            {meta && <StyledMeta>{meta}</StyledMeta>}
          </StyledDropdownItemContent>
        )}
      </Button>
    </StyledDropdownItem>
  )
})

DropdownItem.displayName = "DropdownItem"

DropdownItem.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  indicator: PropTypes.bool,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  closeOnClick: PropTypes.bool,
  brand: Button.propTypes.brand,
  disabled: Button.propTypes.disabled,
  styleType: Button.propTypes.styleType,
  active: PropTypes.bool,
  multiline: PropTypes.bool
}

DropdownItem.defaultProps = {
  closeDropdownOnClick: true,
  disabled: false,
  brand: false,
  active: false,
  multiline: false
}

export default DropdownItem
