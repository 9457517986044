import Alert from "@pathwright/ui/src/components/alert/Alert"
import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import Card from "@pathwright/ui/src/components/card/Card"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import get from "lodash/get"
import PropTypes from "prop-types"
import styled from "styled-components"
import ACCOUNT_QUERY from "../../account/graphql/account-query"
import StripeElementsForm from "../../lib/StripeElementsForm"
import { SUBSCRIPTION_CHARGE_TYPE_STRIPE } from "../../subscription/constants"
import ManageBillingOverdueBill from "./ManageBillingOverdueBill"

const StyledContainer = styled.div`
  padding: 20px;
`

const ManageBillingPayment = ({
  card,
  payment_error,
  payment_saving,
  publishable_key,
  handleToken,
  clearErrors
}) => {
  const { t } = useTranslate()

  const accountQuery = useQuery(ACCOUNT_QUERY)
  const account = get(accountQuery, "data.account")
  const billingSubscription = get(account, "billing_subscription")
  const curriculumSubscription = get(account, "curriculum_subscription")
  const subscription =
    get(billingSubscription, "subscription") ||
    get(curriculumSubscription, "subscription")

  let cardLast4 = ""
  if (subscription) {
    cardLast4 = `ending in ${subscription.card_last_4}`
  }

  const cardProps = {
    showStatus: payment_saving,
    statusMessage: "Saving...",
    title: `Update Card ${cardLast4}`
  }

  const onHandleToken = (token) => {
    const response = handleToken(token)
    const promise =
      response && response.then
        ? response
        : response && response.promise
        ? response.promise
        : Promise.resolve()
    return promise.then(accountQuery.refetch)
  }

  return (
    <Card card={card} {...cardProps}>
      {accountQuery.loading ? (
        <LoadingCircle center />
      ) : (
        <>
          {get(billingSubscription, "charge_type") ===
            SUBSCRIPTION_CHARGE_TYPE_STRIPE || !!curriculumSubscription ? (
            <StyledContainer>
              {/* TODO: Alert not capable of handling legacy API call errors */}
              {payment_error && (
                <Alert error={payment_error} onClear={clearErrors} />
              )}
              <StripeElementsForm
                apiKey={publishable_key}
                onToken={onHandleToken}
                hidePostalCode={true}
              />
              <ManageBillingOverdueBill>
                {(OverdueComponent) => (
                  <div
                    style={{ backgroundColor: "whitesmoke", padding: "20px" }}
                  >
                    {OverdueComponent}
                  </div>
                )}
              </ManageBillingOverdueBill>
            </StyledContainer>
          ) : (
            <BlankSlate
              icons={null}
              icon="meh"
              heading={t("Cannot update card.")}
              body={t("Your school invoice payments are handled elsewhere.")}
            />
          )}
        </>
      )}
    </Card>
  )
}

ManageBillingPayment.displayName = "ManageBillingPayment"

ManageBillingPayment.propTypes = {
  payment_error: PropTypes.oneOf([PropTypes.string, PropTypes.object]),
  payment_saving: PropTypes.bool,
  publishable_key: PropTypes.string.isRequired,
  clearErrors: PropTypes.func.isRequired,
  handleToken: PropTypes.func.isRequired
}

export default ManageBillingPayment
