import PropTypes from "prop-types"
import styled from "styled-components"
import DiscussionControls from "../../discussion/DiscussionControls"

const Container = styled.div`
  padding: 10px 20px;
  display: flex;
  justify-content: flex-end;
`

const CompletionFeedbackDiscussionControls = (props, context) => {
  const { responses } = context.discussionProvider

  return responses ? (
    <Container>
      <DiscussionControls />
    </Container>
  ) : null
}

CompletionFeedbackDiscussionControls.displayName =
  "CompletionFeedbackDiscussionControls"
CompletionFeedbackDiscussionControls.contextTypes = {
  discussionProvider: PropTypes.object
}

export default CompletionFeedbackDiscussionControls
