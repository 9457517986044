import usePreviousEffect from "@pathwright/ui/src/components/hooks/usePreviousEffect"
import get from "lodash/get"
import { useEffect, useMemo, useState } from "react"
import { StringParam, useQueryParam } from "use-query-params"
import { getDiscussionUnread } from "../../../discussion/list/item/DiscussionUnreadIndicator"
import { usePathwrightContext } from "../../../pathwright/PathwrightContext"
import { useReviewContext } from "../../state/ReviewContextProvider"
import { getShareFeedbackStr } from "../../ui/meta/FeedbackResponses"
import { isItemReviewable, isUsersCompletion } from "../../utils"
import InboxDiscussion, { useInboxDiscussion } from "../InboxDiscussion"
import InboxNotes, { useInboxNotes } from "../InboxNotes"
import InboxReview from "../review/InboxReview"

const withTabContent =
  (Component) =>
  ({ active, panel }) => {
    const { selected } = useReviewContext()
    const selectedPanel = panels.find((s) => s.key === panel)
    const [selectedPanelItem, setSelectedPanelItem] = useQueryParam(
      "ui_panel_selected",
      StringParam
    )

    // Remove ui_panel_selected from query params when TabContent unmounts.
    useEffect(
      () => () => setSelectedPanelItem(undefined /* clearing */, "replaceIn"),
      []
    )
    // Remove ui_panel_selected from query params when TabContentComponent changes.
    usePreviousEffect(
      ([prevActive]) => {
        if (active !== prevActive) {
          setSelectedPanelItem(/* clearing */)
        }
      },
      [active]
    )

    if (!selectedPanel) {
      return null
    }

    return (
      <Component
        key={`${selectedPanel.key}-${selected.id}`}
        selectedItem={parseInt(selectedPanelItem)}
        setSelectedItem={setSelectedPanelItem}
      />
    )
  }

const InboxTab = ({
  useData = () => {},
  getTotal,
  getIndicator,
  getTooltip,
  renderTab
}) => {
  const data = useData()

  let total = null
  if (getTotal) {
    total = getTotal(data)
  }

  let indicator = false
  if (getIndicator) {
    indicator = getIndicator(data)
  }

  let tooltip = null
  if (getTooltip) {
    tooltip = {
      title: getTooltip(data)
    }
  }

  return renderTab({ indicator, tooltip, count: total })
}

const panels = [
  {
    key: "review",
    label: "Review",
    icon: "checklist-square",
    Tab: InboxTab,
    Panel: withTabContent(InboxReview),
    useVisibilityCondition: () => {
      const { selected } = useReviewContext()
      const pwContext = usePathwrightContext()

      // Completion owner can view "review" panel when the completion
      // is reviewable. Completion reviewer can always view "review" panel.
      return (
        isItemReviewable(selected, pwContext) ||
        !isUsersCompletion(selected, pwContext)
      )
    }
  },
  {
    key: "discuss",
    label: "Discuss",
    icon: "chat-bubbles",
    Tab: InboxTab,
    Panel: withTabContent(InboxDiscussion),
    useData: useInboxDiscussion,
    getTotal: (data) => get(data, "data.discussion.responses.total"),
    getIndicator: (data) => {
      const discusion = get(data, "data.discussion")
      return (
        getDiscussionUnread(discusion, "responses") ||
        !!data.askToAnswerNotification
      )
    },
    getTooltip: (data) => {
      return data.askToAnswerNotification
        ? getShareFeedbackStr(data.askToAnswerNotification)
        : ""
    }
  },
  {
    key: "notes",
    label: "Notes",
    icon: "note",
    Tab: InboxTab,
    Panel: withTabContent(InboxNotes),
    useData: useInboxNotes,
    getTotal: (data) => get(data.query, "data.notes.total"),
    useVisibilityCondition: (data) => {
      if (!data.query.loading) return !!get(data.query, "data.notes.total")
    }
  }
]

// Conditionally use panel based on data and review context.
const usePanel = (panelKey, onPanelCondition) => {
  const { useData = () => {}, useVisibilityCondition = () => true } =
    panels.find((panel) => panel.key === panelKey)
  const data = useData()
  const visible = useVisibilityCondition(data)

  useEffect(() => {
    /**
     * getCondition should return one of [undefined, false, true].
     * - true: the panel should be used
     * - false: the panel should not be used
     * - undefined: the panel may be used pending final visiblity condition (of true or false)
     */
    onPanelCondition(panelKey, visible)
  }, [visible])
}

// Inserts a `visible` condition for each panel that can be used to detect whether
// the panel should be used or not.
const usePanels = () => {
  const [pandelConditions, setPanelConditions] = useState({})
  const handlePanelCondition = (panelKey, visible) => {
    if (pandelConditions[panelKey] !== visible) {
      setPanelConditions((pandelConditions) => ({
        ...pandelConditions,
        [panelKey]: visible
      }))
    }
  }
  usePanel("review", handlePanelCondition)
  usePanel("discuss", handlePanelCondition)
  usePanel("notes", handlePanelCondition)

  const conditionalPanels = useMemo(
    () =>
      panels.map((panel) => ({
        ...panel,
        visible: pandelConditions[panel.key]
      })),
    [pandelConditions]
  )

  return conditionalPanels
}

export default usePanels
