import PropTypes from "prop-types"
import styled from "styled-components"
import Pathicon from "../pathicon/Pathicon"

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 0.3em;
  padding-right: 0.3em;
  padding-top: 0.1em;
  padding-bottom: 0.1em;
  border-radius: 10px;
  color: rgb(50, 50, 50);
  background: rgb(230, 230, 230);

  > * + * {
    margin-left: 0.4em;
  }

  i {
    font-size: 1.5em;
  }
`

const IconLabel = ({ icon, label }) => (
  <StyledContainer className="IconLabel">
    <Pathicon icon={icon} />
    <span>{label}</span>
  </StyledContainer>
)

IconLabel.displayName = "IconLabel"

IconLabel.propTypes = {
  icon: PropTypes.string.isRequired
}

export default IconLabel
