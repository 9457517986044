import { getStoreUrl } from "../store/utils"
const { createCardRoutes } = require("lib/core/routing/card-routing")
const { loadCategories, selectCategory } = require("./middleware")

export const cardRoutes = {
  category: {
    component: require("./list/CategoryListCard").default,
    middleware: loadCategories,
    getDefaultLaunchedFrom: () => getStoreUrl(),
    childCards: {
      item: {
        path: ":category_id(\\d+|new)/",
        middleware: selectCategory,
        component: require("./item/CategoryFormCard").default
      }
    }
  }
}

const fallbackComponent = require("store/StoreOrLibraryBrowse").default

export default createCardRoutes("/manage/", cardRoutes, fallbackComponent)
