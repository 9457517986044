// @ts-ignore
import { default as PathwrightUII18nextProvider } from "@pathwright/ui/src/components/lng/I18nextProvider"
// @ts-ignore
// import { preProcessTranslation } from "@pathwright/ui/src/components/lng/utils"

// import { useEffect, useState } from "react"
// import { useTranslationLazyQuery } from "../api/generated"
import { usePathwrightContext } from "../pathwright/PathwrightContext"

// NOTE: ideally we could catch when a key is missing in the school's
// translation (depending on `saveMissing` option) and then fetch the
// default english translation on demand, adding it as a new translation
// resource, at which point react i18n would rerender the offending component.
// This is not working, but I may be going about it in a naive way.
// So, for now, loading the default english translation with the school's
// translation in order to set it up as a fallback when initializing i18next.

// function MissingTranslationHandler() {
//   const { translation } = usePathwrightContext()

//   const [defaultEnglishTranslation, setDefaultEnglishTranslation] = useState(
//     () =>
//       translation.language === "en" && translation.is_default
//         ? translation
//         : null
//   )
//   const [loadDefaultEnglishTranslation, defaultEnglishTranslationState] =
//     useTranslationLazyQuery()

//   // Set the default translation.
//   useEffect(() => {
//     if (defaultEnglishTranslationState.data?.translation) {
//       setDefaultEnglishTranslation(
//         defaultEnglishTranslationState.data?.translation.master_translation
//       )
//     }
//   }, [defaultEnglishTranslationState.data?.translation])

//   useEffect(() => {
//     i18n.addResources(
//       translation.language,
//       "defaultEnglish",
//       preProcessTranslation(defaultEnglishTranslation),
//       true,
//       true
//     )
//   }, [defaultEnglishTranslation])

//   useEffect(() => {
//     i18n.on(
//       "missingKey",
//       function (lngs: any, namespace: any, key: any, res: any) {
//         if (!defaultEnglishTranslation) {
//           loadDefaultEnglishTranslation()
//         }
//       }
//     )
//   }, [])

//   return null
// }

// Simply providing the translation from PathwrightContext to the I18nextProvider from legacy/ui
const I18nextProvider = (props: any) => {
  const { translation, school } = usePathwrightContext()

  return (
    <PathwrightUII18nextProvider
      translation={translation}
      locale={school.ietf_language_tag}
      {...props}
    >
      {/* <MissingTranslationHandler /> */}
      {props.children}
    </PathwrightUII18nextProvider>
  )
}

export default I18nextProvider
