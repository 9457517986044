import Alert from "@pathwright/ui/src/components/alert/Alert"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import ScrollView from "@pathwright/ui/src/components/scroll/ScrollView"
import PropTypes from "prop-types"
import styled from "styled-components"
import DiscussionInput from "../../discussion/DiscussionInput"
import DiscussionProvider from "../../discussion/DiscussionProvider"
import DiscussionResponses from "../../discussion/DiscussionResponses"
import { useToggleIntercomVisibility } from "../../intercom/IntercomProvider"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import withCompletion from "../graphql/withCompletion"
import CompletionFeedbackDiscussionControls from "./CompletionFeedbackDiscussionControls"
import CompletionFeedbackHeader from "./CompletionFeedbackHeader"
import CompletionFeedbackPreview from "./CompletionFeedbackPreview"

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  .CompletionPreview {
    /*
      actually allows CompletionPreview to fill height
      (mainly fixes background color from getting clipped)
    */
    min-height: auto;
  }
`
const StyledScrollView = styled(ScrollView)`
  flex-grow: 1;
  flex-shrink: 0;
`
const StyledPreviewScrollView = styled(ScrollView)`
  max-height: 50vh;
  display: flex;
  flex-direction: column;
`

const getInitialResponse = (completion) =>
  completion && completion.userpoints_explanation && completion.checked_by
    ? {
        id: "initialResponse",
        body: completion.userpoints_explanation,
        user: completion.checked_by,
        likes: null,
        user_liked: null,
        created_time: completion.checked_date,
        attachments: null
      }
    : null

const DiscussionError = (props, context) => (
  <Alert error={context.discussionProvider.postError} />
)
DiscussionError.contextTypes = {
  discussionProvider: PropTypes.shape({
    postError: PropTypes.string
  }).isRequired
}

const CompletionFeedbackPanel = (props, context) => {
  const { me } = usePathwrightContext()
  const { t } = useTranslate()

  // Intercom UI covers DiscussionInput submit button so we must hide the Intercom UI
  useToggleIntercomVisibility()

  if (!props.step || !props.completion || !me) {
    return null
  }

  const discussionContext = {
    completion_id: props.stepId,
    resource_id: props.step.context.resource_id,
    cohort_id: props.step.context.cohort_id
  }

  return (
    <DiscussionProvider
      style={{ height: "100%" }}
      context={discussionContext}
      response_id={props.responseId}
      initialResponse={getInitialResponse(props.completion)}
      onViewResponse={props.onViewResponse}
      onViewAllResponses={props.onViewAllResponses}
      onDiscussionCreated={props.onDiscussionCreated}
    >
      <Container>
        <CompletionFeedbackHeader {...props} />
        <StyledScrollView scrollStart="bottom">
          <StyledPreviewScrollView>
            <CompletionFeedbackPreview
              // fullscreen={fullscreen}
              stepId={props.stepId}
              step={props.step || props.completion}
              me={me}
            />
          </StyledPreviewScrollView>
        </StyledScrollView>
        <div className="discussion">
          <DiscussionError />
          <CompletionFeedbackDiscussionControls />
          <DiscussionResponses>
            <></>
            {/* <CompletionFeedbackBlankSlate completion={props.completion} /> */}
          </DiscussionResponses>
        </div>

        {/* for now, can't respond directly to response */}
        {!props.responseId && (
          <DiscussionInput
            title={t("completion.feedback.panel.feedback_for_step", {
              step: props.step.name
            }).slice(0, 200)} // limit to 200 chars to avoid hitting backend char limit of 255 (sanitization may add chars when escaping value on the backend)
            placeholder={t("completion.feedback.panel.placeholder")}
          />
        )}
      </Container>
    </DiscussionProvider>
  )
}

CompletionFeedbackPanel.displayName = "CompletionFeedbackPanel"

CompletionFeedbackPanel.propTypes = {
  stepId: PropTypes.number.isRequired,
  responseId: PropTypes.number,
  onViewResponse: PropTypes.func,
  onViewAllResponses: PropTypes.func,
  onDiscussionCreated: PropTypes.func
}

export default withCompletion(CompletionFeedbackPanel)
