export default {
  type: "Meeting",
  icon: "text-cursor", // TODO: pathicon text => text-cursor
  helpLink: "http://help.pathwright.com/en/articles/4263727-meeting-block-new",
  menu: {
    category: "task",
    order: 2
  },
  layouts: {
    text: {
      key: "meeting",
      label: "Meeting",
      data: {
        type: null,
        link: "",
        isActive: true,
        buttonLabel: "Join Meeting"
      }
    }
  },
  schema: `
    type Meeting implements Block {
      id: ID!
      description: String
      link: String
      buttonLabel: String
    }
  `
}
