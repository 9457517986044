import ThemeBackground from "@pathwright/ui/src/components/background/ThemeBackground"
import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import ScrollView from "@pathwright/ui/src/components/scroll/ScrollView"
import View from "@pathwright/ui/src/components/ui/View"
import { media } from "@pathwright/ui/src/components/utils/styles"
import styled from "styled-components"
import { RESET_PW, SIGNED_IN, SIGN_IN, SIGN_UP } from "../auth/constants"
import { usePathwrightContext } from "../pathwright/PathwrightContext"
import SignupDots from "./SignupDots"
import SignupFlow from "./SignupFlow"
import SignupHeader from "./SignupHeader"
import { SCHOOL, SURVEY } from "./constants"

const StyledContainer = styled(View)`
  height: 100vh;
  width: 100vw;
`

const StyledScrollView = styled(ScrollView)`
  position: relative;
  height: 100vh;
`

const StyledWrapper = styled.div`
  position: static;
  width: 100vw;
  min-height: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
  padding: 120px 20px;
  ${media.max.phone`padding: 50px 10px;`};
`

const getTitleCopy = (step, me) => {
  if (step === SIGN_UP) return `First, create your personal account.`
  if (step === SIGN_IN) return `Sign in with your personal account.`
  if (step === RESET_PW) return `Forgot your password? No problem.`
  if (step === SIGNED_IN) return `Do you want to use this account?`
  if (step === SCHOOL) return `Create your Pathwright account.`
  if (step === SURVEY) return `Welcome to Pathwright, ${me.first_name}!`
}

const getDescriptionCopy = (step) => {
  if (step === SIGN_UP)
    return `You'll use this to sign in as the owner and administrator.`
  if (step === SIGN_IN)
    return `You'll use this account as the owner and administrator.`
  if (step === RESET_PW)
    return `Just enter your email below and we'll send you reset instructions.`
  if (step === SIGNED_IN)
    return `You'll use this account as the owner and administrator`
  if (step === SURVEY)
    return `💡 Select how you’re looking to teach and we’ll send some helpful tips for getting started.`
}

const getSubmitLabel = (step) => {
  if (step === SIGN_UP) return "Next"
  if (step === SIGN_IN) return "Next"
  if (step === RESET_PW) return "Reset Password"
  if (step === SIGNED_IN) return "Next"
  if (step === SCHOOL) return "Next"
  if (step === SURVEY) return "Start teaching"
  return "Next"
}

const getSubmitIcon = (step) => {
  if (step === SIGNED_IN || step === SCHOOL || step === SURVEY)
    return <Pathicon icon="arrow-right" />
  return null
}

const SignupDefault = ({
  onStepChanged,
  utmParameters,
  initialSchoolType,
  school,
  copy,
  stripeApiKey
}) => {
  const { me } = usePathwrightContext()
  return (
    <StyledContainer inverted>
      <ThemeBackground />
      <StyledScrollView fill>
        <StyledWrapper>
          <SignupFlow
            inverted
            onStepChanged={onStepChanged}
            utmParameters={utmParameters}
            initialSchoolType={initialSchoolType}
            school={school}
            copy={copy}
            stripeApiKey={stripeApiKey}
            renderNav={({ step, onChange }) => (
              <SignupDots activeStep={step} onChange={onChange} inverted />
            )}
            renderHeader={({ step }) => (
              <SignupHeader
                title={getTitleCopy(step, me)}
                body={getDescriptionCopy(step)}
                inverted
              />
            )}
            renderSubmit={({ step, ...submitProps }) => (
              <SubmitButton
                {...submitProps}
                styleType="primary"
                size="large"
                inverted
              >
                {getSubmitLabel(step)}
                <span> </span>
                {getSubmitIcon(step)}
              </SubmitButton>
            )}
          />
        </StyledWrapper>
      </StyledScrollView>
    </StyledContainer>
  )
}

SignupDefault.displayName = "SignupDefault"

export default SignupDefault
