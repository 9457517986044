import Button from "@pathwright/ui/src/components/button/Button"
import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import MediaManager from "@pathwright/web/src/modules/media-manager/MediaManager"
import {
  ConnectForm,
  ImageUpload,
  RichText,
  SelectInput,
  TextInput
} from "lib/core/components/forms"
import get from "lodash/get"
import CoverUploader from "../../lib/core/components/forms/form-img-upload/_CoverUploader"
import AuthorDelete from "./AuthorDelete"
import "./AuthorForm.css"

let fieldsData = {
  avatar: {
    label: "Profile image"
  },
  first_name: {
    label: "First Name",
    placeholder: "Enter author's first name"
  },
  last_name: {
    label: "Last Name",
    placeholder: "Enter author's last name"
  },
  salutation: {
    label: "Salutation",
    optional: true,
    options: [
      { label: "None", value: "" },
      { label: "Mr.", value: "Mr." },
      { label: "Mrs.", value: "Mrs." },
      { label: "Ms.", value: "Ms." },
      { label: "Dr.", value: "Dr." },
      { label: "Fr.", value: "Fr." },
      { label: "Professor", value: "Professor" },
      { label: "Rev.", value: "Rev." },
      { label: "Rabbi", value: "Rabbi" }
    ]
  },
  title: {
    label: "Title",
    placeholder: "e.g. Software Engineer at Google",
    optional: true
  },
  bio: {
    label: "Bio",
    placeholder: "About the author",
    hideLabel: true,
    optional: true,
    rows: 5
  }
}

const AuthorForm = function (props) {
  let author,
    canDelete,
    handleDelete,
    handleSubmit,
    isDeleting,
    isValid,
    selectAuthorForDeletion,
    unselectAuthorForDeletion
  ;({
    author,
    fieldsData,
    isValid,
    handleSubmit,
    canDelete,
    isDeleting,
    handleDelete,
    selectAuthorForDeletion,
    unselectAuthorForDeletion
  } = props)

  if (canDelete && isDeleting) {
    return (
      <AuthorDelete
        id={author.id}
        name={author.display_name}
        onCancel={unselectAuthorForDeletion}
        onDelete={handleDelete}
      />
    )
  } else {
    return (
      <form onSubmit={handleSubmit} autoComplete="off">
        <SubmitButton
          onSubmit={handleSubmit}
          disabled={!isValid}
          styleType={"primary"}
        >
          Save
        </SubmitButton>
        <div className="AuthorForm__form">
          <ImageUpload
            {...Object.assign({}, fieldsData.avatar, { styleType: "avatar" })}
            uploader={({ uploading, error }) => (
              <MediaManager
                contextKey={`author-*`}
                mediaType="image"
                onChooseMedia={(media) =>
                  fieldsData.avatar.onChange(
                    {
                      url: media.url,
                      mimetype: media.mimetype,
                      size: get(media, "size.fileSize"),
                      filename: media.name
                    },
                    "avatar"
                  )
                }
                renderButton={({ onClick }) => (
                  <CoverUploader
                    uploading={uploading}
                    error={error}
                    pickFile={onClick}
                  />
                )}
              />
            )}
          />
          <div className="AuthorForm__fields">
            <TextInput {...Object.assign({}, fieldsData.first_name)} />
            <TextInput {...Object.assign({}, fieldsData.last_name)} />
            <SelectInput {...Object.assign({}, fieldsData.salutation)} />
            <TextInput {...Object.assign({}, fieldsData.title)} />
          </div>
        </div>
        <RichText {...Object.assign({}, fieldsData.bio)} />
        {canDelete ? (
          <Button
            onClick={selectAuthorForDeletion}
            styleType="card"
            color="red"
          >
            Delete author
          </Button>
        ) : undefined}
      </form>
    )
  }
}

AuthorForm.displayName = "AuthorForm"

export default ConnectForm(AuthorForm, fieldsData)
