import React from "react"
import styled from "styled-components"
import Button from "../button/Button"
import Text from "../ui/Text"

const StyledVariableButton = styled(Button)`
  text-align: left;

  .VariableLabel {
    width: 100%;
    text-align: left;
  }
`

// TODO: break the modal part out into a separate component
const VariableSelectorList = React.memo(function VariableSelectorList({
  variable,
  variables,
  activeVariableIndex,
  handleSelectVariable,
  EmptyVariableSelectorList
}) {
  const [activeNode, setActiveNode] = React.useState(null)

  // ensure active node is always visible
  React.useEffect(() => {
    if (activeNode)
      activeNode.scrollIntoView({
        behavior: "auto", // "auto" scroll behavior to for "undetectable" scrolling
        block: "center"
      })
  }, [activeNode])

  // construct the variable selector UI
  const getFilteredVariableOptions = (variables) =>
    Object.keys(variables).map((key, i) => (
      <div key={key}>
        {variables[key].variables ? (
          <React.Fragment>
            <Text.Body
              style={
                i === 0
                  ? { color: "whitesmoke", marginBottom: ".1em" }
                  : {
                      color: "whitesmoke",
                      marginBottom: ".1em",
                      marginTop: ".5em"
                    }
              }
            >
              <strong>{variables[key].label.toUpperCase()}</strong>
            </Text.Body>
            <div>{getFilteredVariableOptions(variables[key].variables)}</div>
          </React.Fragment>
        ) : (
          <StyledVariableButton
            styleType={
              variables[key].index === activeVariableIndex
                ? "secondary"
                : "tertiary"
            }
            ref={
              variables[key].index === activeVariableIndex
                ? setActiveNode
                : null
            }
            style={{ transition: "none" }}
            stretch
            rounded
            size="small"
            onClick={() => {
              handleSelectVariable(variables[key])
            }}
            inverted
          >
            <div className="VariableLabel">{variables[key].label}</div>
          </StyledVariableButton>
        )}
      </div>
    ))

  if (Object.keys(variables).length)
    return getFilteredVariableOptions(variables)

  if (EmptyVariableSelectorList)
    return <EmptyVariableSelectorList variable={variable} />

  return null
})

VariableSelectorList.displayName = "VariableSelectorList"

export default VariableSelectorList
