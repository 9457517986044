import partial from "lodash/partial"
import { useAsyncFn } from "react-use"
import { z, ZodSchema } from "zod"
import { zfd } from "zod-form-data"
import { getClient } from "../../../../../api/client"
import {
  ChangePasswordDocument,
  ChangePasswordMutation,
  ChangePasswordMutationVariables
} from "../../../../../api/generated"
import { resolveAction } from "../../../../../lib/utils/multiple-actions"
import { signInPasswordMutation } from "../../../sign-in/password/api/action"
import startPasswordRetrieval from "../../reset/api/action"

export const passwordSchema = z
  .string()
  .regex(/^(?=.*[A-Z]).+$/, {
    message: "Password must include 1 upper case letter"
  })
  .regex(/^(?=.*[a-z]).+$/, {
    message: "Password must include 1 lower case letter"
  })
  // NOTE: using separate RegExp patterns to isolate the error message.
  // .regex(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).+$/)
  .regex(/^(?=.*\d).+$/, {
    message: "Password must include 1 number"
  })
  .min(6, {
    message: "Password must contain at least 6 character(s)"
  })

export const passwordsSchema = z.object({
  password1: passwordSchema,
  password2: passwordSchema
})

export const passwordsFormDataSchema = zfd.formData({
  password1: zfd.text(passwordSchema),
  password2: zfd.text(passwordSchema)
})

export type PasswordsSchema = z.infer<typeof passwordsSchema>

// Unfortunately refining seems to be the only way to add field-vs-field validation
// yet it results in a ZodEffects schema which can no longer be merged with ZodSchemas
// and will no longer provide type safety in Formik initialValues. This approach, by is
// breaking out the refine applications into separate functions, is currently recommended:
// https://github.com/colinhacks/zod/issues/454#issuecomment-848370721
export const refinePasswordsSchema = (schema: ZodSchema) =>
  schema.refine(({ password1, password2 }) => password1 === password2, {
    message: "Passwords must match",
    path: ["password2"]
  })

export const schema = refinePasswordsSchema(
  z
    .object({
      email: z.string().email(),
      currentPassword: z.string().min(1)
    })
    .merge(passwordsSchema)
)

const formDataSchema = zfd.formData({
  email: zfd.text(z.string().email()),
  currentPassword: zfd.text(z.string()),
  // Hmm, can't seem to merge with zfd.formData.
  password1: zfd.text(passwordSchema),
  password2: zfd.text(passwordSchema)
})

const refinedFormDataSchema = refinePasswordsSchema(formDataSchema)

export const changePassword = async (
  formData: FormData
): Promise<{ success: true }> => {
  const client = getClient()
  const variables = formDataSchema.parse(formData)
  let result = await client.mutate<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >({
    mutation: ChangePasswordDocument,
    variables
  })

  const success = result.data?.changePassword

  if (!success) {
    throw new Error("Failed to change password.")
  }

  // The backend invalidates the client's session after a password change, so if the user was to refresh
  // they would no longer be signed in. While this is expected behavior (and good! https://docs.djangoproject.com/en/4.2/topics/auth/default/#session-invalidation-on-password-change)
  // let's re-authenticate the user in the current tab for better UX.
  await signInPasswordMutation({
    ...variables,
    password: variables.password1
  })

  return { success: true }
}

// Experimental: attaching a zod schema for validating the
// form data when submitted.
changePassword.schema = refinedFormDataSchema

const changePasswordAction = resolveAction(
  changePassword,
  startPasswordRetrieval
)

export const useChangePasswordAction = partial(
  useAsyncFn<typeof changePasswordAction>,
  changePasswordAction
)

// Expirimental: returning an action resolver. The first one found that
// validates the provided formData will be executed when a route action takes place.
export default changePasswordAction
