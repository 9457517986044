import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import Dashboard from "@pathwright/web/src/modules/home/Dashboard"
import React, { Suspense } from "react"

const Calendar = React.lazy(() =>
  import(
    "@pathwright/web/src/modules/calendar/Calendar"
    /* webpackChunkName: "Calendar" */
  )
)

const CalendarContainer = () => {
  const handleSelect = (event) => {
    window.App.navigate(event.extendedProps.relative_url)
  }

  const handleClose = () => {
    window.App.getStore("navigation").action.exitModal()
  }

  return (
    <Suspense fallback={<LoadingCircle />}>
      <Calendar onSelectEvent={handleSelect} onClose={handleClose} />
    </Suspense>
  )
}

export default {
  path: "/calendar/:path?/",
  components: {
    primary: Dashboard,
    modal: CalendarContainer
  }
}
