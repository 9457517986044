import Button from "@pathwright/ui/src/components/button/Button"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"

const ActivateButton = (props) => {
  let activateLocation = {
    pathname: "/pricing/",
    query: {}
  }

  if (props.fromUrl) {
    activateLocation.query.from = props.fromUrl
  }

  if (props.utmSource) {
    activateLocation.query.utm_source = props.utmSource
  }

  return (
    <Button {...props} to={activateLocation}>
      {props.children ? (
        props.children
      ) : (
        <span>
          {props.prompt} <Pathicon icon="chevron-right" />
        </span>
      )}
    </Button>
  )
}

ActivateButton.displayName = "ActivateButton"
ActivateButton.defaultProps = {
  fromUrl: null,
  utmSource: "",
  prompt: "Activate Now",
  className: "ActivateButton",
  styleType: "link"
}
export default ActivateButton
