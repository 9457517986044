import gql from "graphql-tag"
import SCHOOL_GROUP_SUBSCRIPTION_FRAGMENT from "./school-group-subscription-fragment"

export default gql`
  query SchoolGroupSubscriptionQuery($id: Int!) {
    schoolGroupSubscription(id: $id) {
      ...SchoolGroupSubscription
    }
  }
  ${SCHOOL_GROUP_SUBSCRIPTION_FRAGMENT}
`
