import { useQuery } from "@apollo/client"
import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import Button from "@pathwright/ui/src/components/button/Button"
import Card from "@pathwright/ui/src/components/card/Card"
import CardBlock from "@pathwright/ui/src/components/card/CardBlock"
import {
  FullscreenConfirmProvider,
  useFullscreenConfirmContext
} from "@pathwright/ui/src/components/confirm/FullscreenConfirm"
import FormStateSubmitButton from "@pathwright/ui/src/components/form/form-state/FormStateSubmitButton"
import { FormikWithDiscardFormState } from "@pathwright/ui/src/components/form/form-state/FormikDiscardFormState"
import ToggleInput from "@pathwright/ui/src/components/form/form-toggle/ToggleInput"
import Fieldset from "@pathwright/ui/src/components/form/form-utils/Fieldset"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import Pathicon from "@pathwright/web/src/modules/pathicon/Pathicon"
import { Field, Form } from "formik"
import styled from "styled-components"
import SelectRole from "../../../invitation/SelectRole"
import { ROLE_TYPE_MENTOR_GROUP } from "../../../invitation/constants"
import { usePathwrightContext } from "../../../pathwright/PathwrightContext"
import { canAdministrate } from "../../../user/permissions"
import {
  MENTOR_GROUP_MEMBERSHIP_ROLE_INPUTS,
  MENTOR_GROUP_MEMBERSHIP_ROLE_INTS
} from "../../constants"
import MENTOR_GROUP_MEMBERSHIP_QUERY from "../../graphql/mentor-group-membership-query"
import MentorGroupListContextProvider, {
  useMentorGroupListContext
} from "./MentorGroupListContext"

// Styles stolen from EditStepSettingsCard
const StyledForm = styled(Form)`
  label {
    width: auto !important;
    font-weight: 700;
    color: rgba(42, 46, 51, 1);
  }

  label > * {
    font-weight: normal;
  }

  /* removing left margin on error messages */
  label + .styled-field > span {
    margin-left: 0;
  }

  /* standardize TextInput input widths */
  .TextInput input {
    max-width: 200px;
    min-width: 3rem;
  }

  .CardLink {
    h5 {
      font-size: 1em;
    }
  }

  label,
  label h5 {
    margin-bottom: 3px;
  }

  .cursor-pointer,
  .cursor-pointer .Tooltip__trigger.OverlayTrigger,
  .cursor-pointer label {
    cursor: pointer;
  }

  .UIFieldWrapper {
    .Tooltip {
      max-width: unset;
    }

    .Tooltip__trigger {
      margin-top: -0.5em;
    }

    & > p {
      display: grid;
      grid-template-columns: 1fr auto;
    }

    & > div:first-of-type {
      display: grid;
      grid-template-columns: 1fr auto;
    }

    &.has-custom {
      & > div:first-of-type,
      & > p {
        grid-template-columns: 1fr minmax(3rem, min-content) auto;
      }
    }
  }
`

// TODO: move to GQL MentorGroupMembership schema?
export function getRoleUpdateData(pwContext, membership, permissions) {
  const roleUpdateData = [
    "can_add_admin",
    "can_add_moderator",
    "can_add_member"
  ].reduce((acc, permissionKey) => {
    const roleKey = permissionKey.split("can_add_")[1]
    const roleInt = MENTOR_GROUP_MEMBERSHIP_ROLE_INTS[roleKey]
    const greatestRoleInt = acc[0]?.int || roleInt

    // User must have permission to update to target role and
    // the target membership's role must be beneath the target role.
    if (
      permissions[permissionKey] &&
      (canAdministrate(pwContext) ||
        pwContext.me.id === membership.user.id ||
        membership.role <= greatestRoleInt)
    ) {
      acc.push({
        key: roleKey,
        int: roleInt
      })
    }
    return acc
  }, [])

  return roleUpdateData
}

// TODO: move to GQL MentorGroupMembership schema?
export function getCanUpdateCanReview(pwContext, membership, permissions) {
  const canReviewUpdateData = [
    "can_update_member_review_permission",
    "can_update_moderator_review_permission",
    "can_update_admin_review_permission"
  ].some((permissionKey) => {
    const roleKey = permissionKey
      .split("can_update_")[1]
      .split("_review_permission")[0]
    const roleInt = MENTOR_GROUP_MEMBERSHIP_ROLE_INTS[roleKey]
    return (
      (permissions[permissionKey] ||
        // Edge case, but users who can modify the 'can_review' perm for mentors
        // also have permission to modify the 'can_review' perm for themselves.
        (permissions["can_update_moderator_review_permission"] &&
          membership.user.id === pwContext.me.id)) &&
      membership.role <= roleInt
    )
  }, [])

  return canReviewUpdateData
}

const SelectMentorGroupMemberRole = ({ membership, value, onChange }) => {
  const pwContext = usePathwrightContext()
  const { permissions } = useMentorGroupListContext()
  const roleUpdateData = getRoleUpdateData(pwContext, membership, permissions)

  return (
    <SelectRole
      value={value}
      onChange={onChange}
      roleType={ROLE_TYPE_MENTOR_GROUP}
      roleFilter={(type, role) =>
        roleUpdateData.find((data) => data.int === role) ||
        role === membership.role
      }
      styles={{
        menuList: (baseStyles) => ({
          ...baseStyles,
          maxHeight: "auto"
        })
      }}
    />
  )
}

const MentorGroupMembershipSettingsForm = ({ membership }) => {
  const { t, tc, Trans } = useTranslate()
  const pwContext = usePathwrightContext()
  const {
    mentorGroup,
    permissions,
    groupSubscription,
    seatLmitReached,
    updateMembership
  } = useMentorGroupListContext()
  const { handleConfirm } = useFullscreenConfirmContext()

  const canUpdateCanReview = getCanUpdateCanReview(
    pwContext,
    membership,
    permissions
  )

  const handleSubmit = (values) => updateMembership(membership)(values)

  // NOTE: certain fields can only be updated by the membership owner
  // so we need to be careful with which fields we include in the mutation.
  // Since all initial values are included when when user submits formik form,
  // we let initial values control what we show to user and send to GQL.
  const initialValues = {
    role: membership.role
  }

  if (membership.role > 5) {
    if (canUpdateCanReview) {
      initialValues.canReview = membership.can_review
    }
    if (pwContext.me.id === membership.user.id) {
      initialValues.sendMentorDigest = membership.send_mentor_digest
    }
  }

  // Assumption here is that the user should not be able to remove access from themselves
  // when they have staff perms in a Group Subscription.
  if (groupSubscription && membership.user.id !== pwContext.me.id) {
    initialValues.hasAccess = !membership.access_revoked_time
  }

  return (
    <FormikWithDiscardFormState
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {(formik) => (
        <StyledForm>
          <CardBlock>
            <Fieldset
              title={t("mentor_group.member.settings.role.label", {
                user: membership.user.first_name,
                group: mentorGroup.name
              })}
            >
              <Field
                name="role"
                type="custom"
                component={SelectMentorGroupMemberRole}
                onChange={({ value }) => formik.setFieldValue("role", value)}
                value={formik.values.role}
                membership={membership}
              />
            </Fieldset>
          </CardBlock>
          {"sendMentorDigest" in initialValues && (
            <CardBlock>
              <ToggleInput
                name="sendMentorDigest"
                label={t(
                  "mentor_group.member.settings.send_mentor_digest.label"
                )}
                helperText={t(
                  "mentor_group.member.settings.send_mentor_digest.desc"
                )}
                layoutAsColumns
                onChange={(value, e) => formik.handleChange(e)}
                value={formik.values.sendMentorDigest}
              />
            </CardBlock>
          )}
          {"canReview" in initialValues && (
            <CardBlock>
              <ToggleInput
                name="canReview"
                label={t("mentor_group.member.settings.can_review.label")}
                helperText={t("mentor_group.member.settings.can_review.desc")}
                tooltip={
                  <Trans
                    i18nKey="mentor_group.member.settings.can_review.tooltip"
                    defaults="Enable or disable access to post a final Review and award Points (if present) on a completion.<br/><br/><a>Learn more about Reviewer access <i/></a>"
                    components={{
                      a: (
                        <Button
                          styleType="text"
                          href="http://help.pathwright.com/en/articles/5596450-mentor-groups-provide-personal-mentoring-across-all-courses#h_e9fc555461"
                          target="_blank"
                          style={{
                            whiteSpace: "nowrap"
                          }}
                          postIcon="chevron-right"
                          inverted
                        />
                      ),
                      i: <Pathicon icon="chevron-right" />
                    }}
                  />
                }
                layoutAsColumns
                onChange={(value, e) => formik.handleChange(e)}
                value={formik.values.canReview}
              />
            </CardBlock>
          )}

          {"hasAccess" in initialValues && (
            <CardBlock>
              <ToggleInput
                name="hasAccess"
                label={t(
                  "mentor_group.member.settings.group_subscription_access.label"
                )}
                helperText={t(
                  "mentor_group.member.settings.group_subscription_access.desc",
                  {
                    user: membership.user.first_name,
                    subscription: groupSubscription.price.product.name
                  }
                )}
                tooltip={
                  !formik.values.hasAccess &&
                  seatLmitReached &&
                  tc(
                    "mentor_group.member.settings.group_subscription_access.tooltip"
                  )
                }
                layoutAsColumns
                disabled={!formik.values.hasAccess && seatLmitReached}
                onChange={(value, e) =>
                  value || value === initialValues.hasAccess
                    ? formik.handleChange(e)
                    : handleConfirm({
                        icon: "caution-triangle",
                        heading: t(
                          "mentor_group.member.group_subscription.confirm.remove_access.heading",
                          {
                            user: membership.user.full_name,
                            subscription: groupSubscription.price.product.name
                          }
                        ),
                        body: (
                          <Trans
                            i18nKey="mentor_group.member.group_subscription.confirm.remove_access.body"
                            defaults="Removing {{user}} will immediately <strong>disable access to {{subscription}}</strong>."
                            values={{
                              user: membership.user.first_name,
                              subscription: groupSubscription.price.product.name
                            }}
                          ></Trans>
                        ),
                        confirmPrompt: t(
                          "mentor_group.member.group_subscription.confirm.remove_access.confirm_prompt",
                          {
                            user: membership.user.first_name
                          }
                        ),
                        callback: () =>
                          updateMembership(membership)({
                            hasAccess: false,
                            role: MENTOR_GROUP_MEMBERSHIP_ROLE_INPUTS[5]
                          }).then(() => handleConfirm(null))
                      })
                }
                value={formik.values.hasAccess}
              />
            </CardBlock>
          )}
          <FormStateSubmitButton
            brand
            styleType="primary"
            disabled={
              !formik.discardCount || !formik.isValid || formik.isSubmitting
            }
            submitting={formik.isSubmitting}
            label={t("Save")}
          />
        </StyledForm>
      )}
    </FormikWithDiscardFormState>
  )
}

const MentorGroupMembershipSettingsCard = ({ card, membershipId }) => {
  const { mentorGroupQuery, groupSubscriptionQuery } =
    useMentorGroupListContext()
  const membershipQuery = useQuery(MENTOR_GROUP_MEMBERSHIP_QUERY, {
    variables: {
      id: membershipId
    }
  })
  const membership = membershipQuery.data?.mentorGroupMembership
  const loading =
    membershipQuery.loading ||
    mentorGroupQuery.loading ||
    groupSubscriptionQuery.loading

  return (
    <Card
      className="MentorGroupMembershipSettingsCard"
      card={card}
      title="Configure membership"
    >
      {loading ? (
        <LoadingCircle />
      ) : membershipQuery.error ? (
        <BlankSlate icons={null} heading="An unexpected error occurred." />
      ) : (
        <MentorGroupMembershipSettingsForm membership={membership} />
      )}
    </Card>
  )
}

const MentorGroupMembershipSettingsCardContainer = ({ groupId, ...props }) => {
  return (
    <MentorGroupListContextProvider groupId={groupId}>
      <FullscreenConfirmProvider>
        <MentorGroupMembershipSettingsCard {...props} />
      </FullscreenConfirmProvider>
    </MentorGroupListContextProvider>
  )
}

export default MentorGroupMembershipSettingsCardContainer
