import Button from "@pathwright/ui/src/components/button/Button"
import Fullscreen from "@pathwright/ui/src/components/fullscreen/Fullscreen"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import styled from "styled-components"

const StyledButton = styled(Button)`
  position: absolute;
  right: 20px;
  bottom: 20px;
`

const NeedHelpButton = ({ to = "" }) => {
  const { t } = useTranslate()

  return (
    <Fullscreen background={null} allowScroll={false}>
      <StyledButton to={to || "/help/"} styleType="primary-alt" inverted>
        <Pathicon icon="lightbulb" /> {t("nav.app_panel_links.need_help")}
      </StyledButton>
    </Fullscreen>
  )
}

export default NeedHelpButton
