import { getConstMap } from "@pathwright/ui/src/components/utils/constants"

// TODO: deprecate in favor of LICENSING_MODES
export const LICENSING_MODE_PRIVATE_COHORTS = "private_cohorts"
export const LICENSING_MODE_SCHOOL_LICENSING = "school_licensing"
export const LICENSING_MODE_GROUP_SUBSCRIPTION = "group_subscription"

export const LICENSING_MODES = getConstMap({
  PRIVATE_COHORTS: [1, "private_cohorts"],
  SCHOOL_LICENSING: [2, "school_licensing"],
  GROUP_SUBSCRIPTION: [3, "group_subscription"]
})
