import useDebounce from "@pathwright/ui/src/components/hooks/useDebounce"
import Fuse from "fuse.js"
import { useMemo } from "react"

const useFuse = (items, search, fuseOptions = {}) => {
  // Debouncing the provided searchTerm.
  const searchTerm = useDebounce(search, 300)

  // Use fuse to search against items.
  const fuse = useMemo(() => {
    return new Fuse(items, {
      threshold: 0.3,
      ...fuseOptions
    })
  }, [items, fuseOptions])

  // Get fusedItems, a subset of items,
  // whenever the searchTerm or fuse changes.
  const fusedItems = useMemo(() => {
    if (searchTerm && searchTerm.length) {
      const results = fuse.search(searchTerm)
      return results.map((r) => r.item)
    }
    return items
  }, [searchTerm, fuse])

  return fusedItems
}

export default useFuse
