// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.

// OO FTW
class ResourceCache {
  constructor(resourceKey) {
    this.resourceKey = resourceKey
    this.data = {}
  }

  set(key, value) {
    return (this.data[key] = value)
  }

  get(key) {
    return this.data[key]
  }

  clear(key) {
    return delete this.data[key]
  }

  isCached(key) {
    return this.data[key] != null
  }

  clearAll() {
    return (this.data = {})
  }

  toJSON() {
    return JSON.stringify(this.data, null, 2)
  }

  createKey(params) {
    var objectToKey = function (value) {
      if (_.isObject(value)) {
        return _.keys(value)
          .sort()
          .map((k) => `${k}-${objectToKey(value[k])}`)
          .join(":")
      } else {
        return `${value}`
      }
    }

    const paramsKey = objectToKey(params)
    const authToken = localStorage.getItem("auth_token")
    // Cache for user
    const user = authToken ? `user-${authToken}` : ""

    return [this.resourceKey, user, paramsKey].filter((k) => k).join(":")
  }

  isResourceCached(params) {
    return this.getResource(params) != null
  }

  setResource(params, resourcePromise) {
    return this.set(this.createKey(params), resourcePromise)
  }

  getResource(params) {
    return this.get(this.createKey(params))
  }
}

window.resourcesCaches = {}

const isResourceCached = (resourceKey) =>
  window.resourcesCaches[resourceKey] != null

const getResourceCache = (resourceKey) => window.resourcesCaches[resourceKey]

const createResourceCache = (resourceKey) =>
  (window.resourcesCaches[resourceKey] = new ResourceCache(resourceKey))

const getOrCreateResourceCache = function (resourceKey) {
  if (!isResourceCached(resourceKey)) {
    createResourceCache(resourceKey)
  }
  return getResourceCache(resourceKey)
}

const clearResourceCache = (resourceKey) =>
  window.resourcesCaches[resourceKey] != null
    ? window.resourcesCaches[resourceKey].clearAll()
    : undefined
//delete window.resourcesCaches[resourceKey]

const clearAllResourceCaches = function () {
  for (let resourceKey in window.resourcesCaches) {
    const cache = window.resourcesCaches[resourceKey]
    cache.clearAll()
  }
  return (window.resourcesCaches = {})
}

export {
  clearAllResourceCaches,
  clearResourceCache,
  getOrCreateResourceCache,
  getResourceCache,
  isResourceCached
}
