import View from "@pathwright/ui/src/components/ui/View"
import isEmpty from "lodash/isEmpty"
import PropTypes from "prop-types"
import { useState } from "react"
import styled from "styled-components"
import { RegistrationRoleType } from "../invitation/types"
import LibraryPickerContainer from "../library/picker/LibraryPickerContainer"
import AssignFooter from "./AssignFooter"
import AssignHeader from "./AssignHeader"
import AssignResourceCohortList from "./AssignResourceCohortList"
import { ASSIGN_ICONS, ASSIGN_TARGET_INDIVIDUAL } from "./constants"
import { getAssignConfig } from "./utils"

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const Assign = ({
  role,
  user,
  communityGroup,
  resourceCohortMap,
  resources,
  cohorts,
  // updaters
  updateAssign,
  selectRole,
  sendNotification,
  toggleSendNotification,
  assignmentMessage,
  setAssignmentMessage,
  onSearch,
  submit,
  error
}) => {
  const [selectingResourceCohort, setSelectingResourceCohort] = useState()

  const assigneeName = user
    ? user.first_name
    : communityGroup
    ? communityGroup.name
    : null

  return (
    <Container>
      <AssignHeader
        user={user}
        communityGroup={communityGroup}
        role={role}
        selectRole={selectRole}
      />
      <View.Secondary padding style={{ flexGrow: 1 }}>
        <LibraryPickerContainer
          inline
          prompt={null}
          searchPrompt="Search Library..."
          icon={ASSIGN_ICONS[ASSIGN_TARGET_INDIVIDUAL]}
          onPick={(resource) =>
            updateAssign({ type: "add", resourceId: resource.id })
          }
          onPicking={setSelectingResourceCohort}
          filter={(resource) =>
            !Object.keys(resourceCohortMap)
              .map((id) => parseInt(id))
              .includes(resource.id)
          }
          assignConfig={getAssignConfig(role)}
        />
        {!(isEmpty(resourceCohortMap) && selectingResourceCohort) && (
          <AssignResourceCohortList
            resourceCohortMap={resourceCohortMap}
            updateAssign={updateAssign}
            assigneeName={assigneeName}
            resources={resources}
            cohorts={cohorts}
            role={role}
          />
        )}
      </View.Secondary>
      <AssignFooter
        resourceCohortMap={resourceCohortMap}
        role={role}
        user={user}
        sendNotification={sendNotification}
        toggleSendNotification={toggleSendNotification}
        assignmentMessage={assignmentMessage}
        setAssignmentMessage={setAssignmentMessage}
        submit={submit}
        error={error}
      />
    </Container>
  )
}

Assign.displayName = "Assign"

Assign.propTypes = {
  user: PropTypes.object,
  communityGroup: PropTypes.object,
  role: RegistrationRoleType,
  resourceCohortMap: PropTypes.object.isRequired,
  // updaters
  selectRole: PropTypes.func.isRequired,
  updateAssign: PropTypes.func.isRequired
}

export default Assign
