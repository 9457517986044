import React, { useContext } from "react"

// Allow path components to see resource and offering context

// TODO: Use elsewhere.
// Currently only used to provide resource context to path components

const ResourceContext = React.createContext()

export const useResourceContext = () => useContext(ResourceContext)

export default ResourceContext
