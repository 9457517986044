import {
  constructUrl,
  getSchoolUrl
} from "@pathwright/web/src/modules/utils/urls"
import moment from "moment"
import { createDownloader } from "../../../download/utils"

const getUrlParams = (values) => {
  const {
    // general
    codeGenerationType,
    code,
    codePrefix,
    numCodes,
    discountPercentage,
    limitTotalUses,
    maxRedemptions,
    isOnePerUser,
    startDate,
    expiryDate,
    targetType,
    // resource
    selectedResourceIds,
    selectedCategoryIds,
    maxSeats,
    // subscription
    selectedPlanIds,
    numCycles
  } = values

  let params = {
    quantity: codeGenerationType === "single" ? 1 : numCodes,
    discount_percent: discountPercentage / 100,
    max_redemptions: limitTotalUses ? maxRedemptions || 0 : 0,
    one_redemption_per_user: isOnePerUser,
    start_dtime: startDate,
    end_dtime: expiryDate
  }

  if (codeGenerationType === "single") {
    params.code = code
  } else {
    params.prefix = codePrefix
  }

  switch (targetType) {
    case "allResources":
      params.all_courses = true
      break
    case "selectResources":
      params.all_courses = false
      params.course_ids = selectedResourceIds.join(",")
      break
    case "selectCategories":
      params.all_courses = false
      params.category_ids = selectedCategoryIds.join(",")
      break
    case "allPlans":
      params.all_plans = true
      break
    case "selectPlans":
      params.all_plans = false
      params.plan_ids = selectedPlanIds
      break
  }

  switch (targetType) {
    case "allResources":
    case "selectResources":
    case "selectCategories":
      params.max_num_seats = maxSeats || 0
      break
    case "allPlans":
    case "selectPlans":
      params.num_cycles = numCycles
      break
  }

  // get rid of keys with undefined or empty string values
  const paramsPruned = Object.keys(params).reduce((obj, key) => {
    const val = params[key]
    if (val !== undefined && val !== null && val !== "") {
      obj[key] = val
    }
    return obj
  }, {})

  return new URLSearchParams(paramsPruned).toString()
}

const baseCouponUrl = "/api/private/coupon/"

const getCouponUrl = (path, values) =>
  constructUrl(getSchoolUrl(baseCouponUrl), path, `?${getUrlParams(values)}`)

export const getCouponSingleUrl = (values) => getCouponUrl("single", values)
export const getCouponSetUrl = (values) => getCouponUrl("set", values)
export const getCouponSuperUserUrl = (values) =>
  getCouponUrl("superuser", values)

export const getCodes = async (values) => {
  const { codeGenerationType, code } = values
  if (codeGenerationType === "single") {
    await _fetch(getCouponSingleUrl(values))
    return code
  } else {
    // TODO: support superuser case
    const response = await _fetch(getCouponSetUrl(values))

    const downloader = await createDownloader(response, {
      key: "codes",
      filename: `${window.school.name}_coupons.csv`
    })
    // return a downloader function for downloading the CSV
    return downloader
  }
}

// TODO: move this...
const _fetch = async (url, options = {}) => {
  const authToken = localStorage.getItem("auth_token")
  const headers = {
    "X-Pathwright-API-Version": moment().format("YYYY-MM-DD"),
    "X-Pathwright-Application-Version": 1.0
  }
  if (authToken) headers.Authorization = `Token ${authToken}`

  const response = await fetch(url, {
    method: "get",
    headers,
    ...options
  })

  if (response.status === 201) {
    return response
  } else if (response.status === 400) {
    const data = await response.json()
    throw new Error(data.error)
  } else {
    throw new Error("Could not create discount code.")
  }
}
