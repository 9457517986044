import { graphql } from "@apollo/client/react/hoc"
import Alert from "@pathwright/ui/src/components/alert/Alert"
import gql from "graphql-tag"
import find from "lodash/find"
import uniq from "lodash/uniq"
import pluralize from "pluralize"
import PropTypes from "prop-types"
import { Component } from "react"

export const FEATURE_LOCK_QUERY = gql`
  query FeatureLockQuery($default_billing_plan_set: Boolean = true) {
    account {
      id
      is_activated
      is_activated_via_override
      is_on_legacy_plan
      billing_subscription {
        id
        plan {
          ...SchoolBillingPlan
        }
      }
      billing_plan_set(default: $default_billing_plan_set) {
        id
        plans {
          ...SchoolBillingPlan
        }
      }
    }
  }

  fragment SchoolBillingPlan on SchoolBillingPlan {
    id
    order
    name
    features {
      id
      key
    }
    plan_derived_from {
      id
    }
  }
`

// FeatureLock determines if the school's subscription includes the appropriate features.
// If no `has` props is provided, FeatureLock simply checks that the account is activated.

// <FeatureLock has={["single-sign-on-sso"]}>
//   {({
//     hasFeatures,
//     activationLocked,
//     lockedFeatures,
//     getPlansNameList
//   }) =>
//     hasFeatures ? (
//       <ManageCommerce />
//     ) : (
//       <Text.Body>
//         Included in the{" "}
//         {getPlansNameList(
//           lockedFeatures["single-sign-on-sso"]
//         )}.
//       </Text.Body>
//     )
//   }
// </FeatureLock>

class FeatureLock extends Component {
  // Map the plan's order to the order of the plan from which this plan
  // was derived, if possible. It's possible for the plan's order to be inaccurate.
  get planOrder() {
    const derivedFromPlan = this.plan.plan_derived_from
      ? this.plans.find((plan) => plan.id === this.plan.plan_derived_from.id)
      : null
    return derivedFromPlan ? derivedFromPlan.order : this.plan.order
  }

  get features() {
    if (!this.plans.length) return null
    return this.plans
      .map((plan) => plan.features)
      .reduce((m, n) => m.concat(n || []))
      .map((feature) => feature.key)
  }

  get unlockedFeatures() {
    if (this.activationLocked) return null
    if (!this.plans.length) return null
    const {
      is_on_legacy_plan,
      is_activated_via_override
      // TODO: need is_curriculum_subscriber
      // is_curriculum_subscriber,
    } = this.props.account

    const is_curriculum_subscriber =
      !this.activationLocked && !this.subscription

    let unlockedPlans = this.plans.filter(
      (plan) =>
        is_on_legacy_plan ||
        is_activated_via_override ||
        is_curriculum_subscriber ||
        // Assumes two things:
        // 1. this.plan is included in a billing plan set (that orders plans)
        // 2. this.plan.order corresponds to the order of the plans in the billing plan set (whether this.plan exists in the billing plan set or not)
        (this.plan &&
          this.plans.find((p) => p.order == this.planOrder) &&
          this.planOrder >= plan.order)
    )

    // Also include school's current plan.
    if (this.plan) {
      unlockedPlans.push(this.plan)
    }

    const unlockedFeatures = uniq(
      unlockedPlans
        .map((plan) => plan.features)
        .reduce((m, n) => m.concat(n || []))
        .map((feature) => feature.key)
    )

    if (this.plan && !unlockedFeatures.length) {
      console.warn("No features found for this school's plan!")
    }

    return unlockedFeatures
  }

  get invalidFeature() {
    return !!(
      this.props.has &&
      this.plans.length &&
      [].concat(this.props.has).filter((feature) => {
        const invalid = !this.plans.filter((plan) =>
          find(this.features, (f) => f == feature)
        ).length
        // yeah, yeah... side-effect
        if (invalid && process.env.NODE_ENV !== "test") {
          console.warn(
            `Invalid feature ${feature} provided to ${FeatureLock.displayName}.`
          )
        }
        return invalid
      }).length
    )
  }

  get plans() {
    return this.props.account.billing_plan_set
      ? this.props.account.billing_plan_set.plans
      : []
  }

  get plan() {
    return this.subscription ? this.subscription.plan : null
  }

  get subscription() {
    return this.props.account ? this.props.account.billing_subscription : null
  }

  get plansWithFeatures() {
    if (!this.features || this.invalidFeature) return []
    if (!this.props.has) return this.plans
    return [].concat(this.props.has).map((feature) => {
      const plan = this.getPlanWithFeature(feature)
      return {
        feature,
        plans: this.plans.filter(({ order }) => order >= plan.order)
      }
    })
  }

  get lockedFeatures() {
    return this.plansWithFeatures
      .filter(({ feature, plans }) => {
        return this.unlockedFeatures
          ? !find(this.unlockedFeatures, (f) => f == feature)
          : true
      })
      .reduce((m, { feature, plans }) => {
        m[feature] = plans
        return m
      }, {})
  }

  get hasFeatures() {
    const { account } = this.props
    if (!account) return false
    if (!account.is_activated) return false
    if (!this.props.has) return true
    if (account.is_on_legacy_plan) return true
    return !Object.keys(this.lockedFeatures).length
  }

  get activationLocked() {
    return this.props.account && !this.props.account.is_activated
  }

  getPlansNameList(plans) {
    return `${plans
      .map((p, i) => {
        return `${p.name}${
          {
            [plans.length - 1]: " ",
            [plans.length - 2]: " & "
          }[i] || ", "
        }`
      })
      .join("")}${pluralize("Plan", plans.length)}`
  }

  getPlanWithFeature(feature) {
    return find(this.plans, (plan) =>
      find(plan.features, (f) => f.key == feature)
    )
  }

  render() {
    return this.props.loading ? (
      this.props.loadingElement
    ) : this.props.error ? (
      <Alert error={this.props.error.message} />
    ) : (
      this.props.children({
        hasFeatures: this.hasFeatures,
        activationLocked: this.activationLocked,
        lockedFeatures: this.lockedFeatures,
        getPlansNameList: this.getPlansNameList
      })
    )
  }
}

FeatureLock.displayName = "FeatureLock"

FeatureLock.propTypes = {
  // children receives `hasFeatures` and `plansWithFeatures` props.
  children: PropTypes.func.isRequired,
  // has is either single feature (feature enum) or list of features (array of feature enums).
  has: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  loadingElement: PropTypes.node
}

FeatureLock.defaultProps = {
  loadingElement: null
}

export default graphql(FEATURE_LOCK_QUERY, {
  options: {
    variables: {
      // Interestingly, if FEATURE_LOCK_QUERY is used via useQuery, the default variable
      // will be passed in as a variable, but using via graphql will not do that which
      // will result in separate queries, so this is a bit of a hack to account for that.
      default_billing_plan_set: true
    }
  },
  props: ({ data }) => {
    const { error, loading, account } = data
    return {
      error,
      loading,
      account
    }
  }
})(FeatureLock)
