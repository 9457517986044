import DropdownTrigger from "@pathwright/ui/src/components/dropdown/DropdownTrigger"
import PropTypes from "prop-types"
import React from "react"
import { Checkboard } from "react-color/lib/components/common"
import FieldWrapper from "../form-utils/FieldWrapper"
import ColorPicker from "./ColorPicker"
import "./ColorPickerToggle.css"

class ColorPickerToggle extends React.Component {
  handleChange = (data) => {
    return this.props.onChange(data[this.props.colorSyntax], this.props)
  }

  renderPicker = () => {
    const { saturation, hue, swatches, alpha, hex, rgb, rgba } = this.props

    return (
      <div className="ColorPickerToggle__dropdown">
        <ColorPicker
          saturation={saturation}
          hue={hue}
          swatches={swatches}
          alpha={alpha}
          hex={hex}
          rgb={rgb}
          rgba={rgba}
          color={this.props.value || undefined}
          onChangeComplete={this.handleChange}
        />
      </div>
    )
  }

  getActiveColor = () => {
    const { value, colorSyntax } = this.props
    if (!value) {
      return
    }
    switch (colorSyntax) {
      case "rgb":
        return `rgba(${value.r},${value.g},${value.b},${value.a})`
      case "hex":
        return value
    }
  }

  render() {
    const activeColor = { backgroundColor: this.getActiveColor() }

    return (
      <FieldWrapper
        {...Object.assign({}, this.props, { className: "ColorPickerToggle" })}
      >
        <DropdownTrigger renderDropdown={this.renderPicker} alignment="right">
          <div className="ColorPickerToggle__button">
            <Checkboard />
            <div style={activeColor} />
          </div>
        </DropdownTrigger>
      </FieldWrapper>
    )
  }
}

ColorPickerToggle.defaultProps = {
  colorSyntax: "rgb"
}

ColorPickerToggle.propTypes = {
  colorSyntax: PropTypes.oneOf(["rgb", "hex"])
}

export default ColorPickerToggle
