import PropTypes from "prop-types"
import Collection from "./Collection"
import Course from "./Course"
import Lesson from "./Lesson"

const Resource = ({ type, resource, ...rest }) => {
  switch (type || (resource && resource.type)) {
    case 30:
      return <Collection {...rest} collection={resource} />
    case 20:
      return <Lesson {...rest} lesson={resource} />
    case 10:
      return <Course {...rest} course={resource} />
    default:
      return <Course {...rest} course={resource} />
  }
}

Resource.propTypes = {
  type: PropTypes.number,
  resource: PropTypes.shape({
    type: PropTypes.number
  })
}

export default Resource
