// Get an ordered list after a dragEnd event from react-beautiful-dnd.
export const getOrderedList = (list, dragEnd) => {
  const { destination, source } = dragEnd

  if (!destination) return list

  const item = list[source.index]
  const newList = list.slice()
  // remove item from sourc index
  newList.splice(source.index, 1)
  // add item to target index
  newList.splice(destination.index, 0, item)
  return {
    list: newList,
    item: item,
    index: source.index
  }
}
