export const BILLING_INTERVAL_MONTH = 5
export const BILLING_INTERVAL_BIANNUAL = 7
export const BILLING_INTERVAL_YEAR = 10

export const PRICING_STEP_PLAN = "plan"
export const PRICING_STEP_ACTIVATE = "activate"

export const PRICING_STEPS = [PRICING_STEP_PLAN, PRICING_STEP_ACTIVATE]

export const BILLING_INTERVALS = [
  BILLING_INTERVAL_MONTH,
  BILLING_INTERVAL_BIANNUAL,
  BILLING_INTERVAL_YEAR
]

export const BILLING_INTERVALS_ACTIVATION = [
  BILLING_INTERVAL_MONTH,
  BILLING_INTERVAL_YEAR
]

export const BILLING_INTERVAL_LABELS = {
  [BILLING_INTERVAL_MONTH]: "monthly",
  [BILLING_INTERVAL_BIANNUAL]: "biannually",
  [BILLING_INTERVAL_YEAR]: "annually"
}

export const BILLING_INTERVAL_PER = {
  [BILLING_INTERVAL_MONTH]: "month",
  [BILLING_INTERVAL_BIANNUAL]: "biannually",
  [BILLING_INTERVAL_YEAR]: "year"
}
