import { graphql } from "@apollo/client/react/hoc"
import { withTranslate } from "@pathwright/ui/src/components/"
import Alert from "@pathwright/ui/src/components/alert/Alert"
import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import TextInput from "@pathwright/ui/src/components/form/form-text-input/TextInput"
import Link from "@pathwright/ui/src/components/link/Link"
import PropTypes from "prop-types"
import { Component } from "react"
import { Field, Form, Submit, validators } from "react-connect-form-forked"
import styled from "styled-components"
import RESET_PASSWORD_MUTATION from "./graphql/reset-password-mutation"
import { AuthFooter, AuthWrapper } from "./styles"
const { isMinLength } = validators

const StyledInput = styled(TextInput)`
  width: 100%;
`

class ConfirmResetPasswordForm extends Component {
  state = {
    error: null,
    success: null
  }

  handleSuccess = () => {
    this.setState({ success: true })
  }

  handleFailure = (err) => {
    const error = String(err.message || err)
    this.setState({ error })
  }

  handleClear = () => {
    this.setState({
      error: null,
      success: null
    })
  }

  handleSubmit = (form) => {
    this.handleClear()
    return this.props.resetPassword({
      reset_hash: this.props.resetHash,
      password1: form.password1,
      password2: form.password2
    })
  }

  render() {
    const { t, Trans } = this.props
    const tPrefix = "auth.confirm_reset_password_form"

    return (
      <Form
        onSubmit={this.handleSubmit}
        onSubmitFailure={this.handleFailure}
        onSubmitSuccess={this.handleSuccess}
      >
        {this.props.renderHeader && this.props.renderHeader()}
        <AuthWrapper>
          <Field
            type="password"
            name="password1"
            label={`${t(`${tPrefix}.password`)}:`}
            placeholder={`${t(`${tPrefix}.min_characters`)}...`}
            component={StyledInput}
            validators={[isMinLength(6)]}
            inverted={this.props.inverted}
            flushLabel
            required
          />
          <Field
            type="password"
            name="password2"
            label={`${t(`${tPrefix}.confirm`)}:`}
            placeholder={`${t(`${tPrefix}.enter_password_again`)}...`}
            component={StyledInput}
            validators={[isMinLength(6)]}
            inverted={this.props.inverted}
            flushLabel
            required
          />
        </AuthWrapper>
        {this.state.error && (
          <Alert error={this.state.error} onClear={this.handleClear} />
        )}
        {this.state.success ? (
          <Alert success="">
            {/* prettier-ignore */}
            {/* Trans formatting integral to translation json! */}
            <p>
              {t(`${tPrefix}.success_message`)}
              <br />
              <br />
              <Link to="/auth/sign-in/">{t(`${tPrefix}.sign_in_now`)}</Link>
            </p>
          </Alert>
        ) : (
          <AuthFooter>
            <Submit
              render={(passProps) =>
                this.props.renderSubmit ? (
                  this.props.renderSubmit(passProps)
                ) : (
                  <SubmitButton
                    {...passProps}
                    inverted={this.props.inverted}
                    styleType="primary"
                    size="large"
                  >
                    {t(`${tPrefix}.change_password`)}
                  </SubmitButton>
                )
              }
            />
          </AuthFooter>
        )}
      </Form>
    )
  }
}

ConfirmResetPasswordForm.displayName = "ConfirmResetPasswordForm"

ConfirmResetPasswordForm.propTypes = {
  renderHeader: PropTypes.func,
  renderSubmit: PropTypes.func,
  resetHash: PropTypes.string.isRequired,
  inverted: PropTypes.bool
}

export default withTranslate(
  graphql(RESET_PASSWORD_MUTATION, {
    props: ({ mutate }) => ({
      resetPassword: (variables) => mutate({ variables })
    })
  })(ConfirmResetPasswordForm)
)
