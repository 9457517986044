import gql from "graphql-tag"

export default gql`
  mutation (
    $name: String!
    $cover_image: String!
    $resource_type: ResourceType
    $group_name: String
    $group_role: RegistrationRoleEnum
  ) {
    createResource(
      name: $name
      cover_image: $cover_image
      resource_type: $resource_type
      group_name: $group_name
      group_role: $group_role
    ) {
      id
      name
      slug
      background_overlay
      blurred_background: background_image(blur: 500, width: 250, height: 250)
      cohort: group(is_source: false) {
        id
        source_cohort_id
      }
    }
  }
`
