import gql from "graphql-tag"
import SCHOOL_FRAGMENT from "../../school/graphql/school-fragment"

export default gql`
  fragment Store on Store {
    id
    licensor {
      ...School
    }
  }
  ${SCHOOL_FRAGMENT}
`
