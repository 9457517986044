export const DISCUSSION_TYPE_QUESTION = 5
export const DISCUSSION_TYPE_POST = 10
export const DISCUSSION_TYPE_REVIEW = 15
export const DISCUSSION_TYPE_FAQ = 20
export const DISCUSSION_TYPE_FEEDBACK = 25

export const DISCUSSION_TYPE_INPUTS = {
  [DISCUSSION_TYPE_QUESTION]: "question",
  [DISCUSSION_TYPE_POST]: "post",
  [DISCUSSION_TYPE_REVIEW]: "review",
  [DISCUSSION_TYPE_FAQ]: "faq",
  [DISCUSSION_TYPE_FEEDBACK]: "feedback"
}
