import BackgroundImage from "@pathwright/ui/src/components/background/BackgroundImage"
import Text from "@pathwright/ui/src/components/ui/Text"
import View from "@pathwright/ui/src/components/ui/View"
import styled from "styled-components"

const Container = styled(View)`
  position: relative;
  width: 100%;
  margin-bottom: 20px;
`

const Wrapper = styled(View)`
  position: relative;
  margin: 0 auto;
  padding: 0 20px 0;
  width: 100%;
  max-width: 1200px;
`

const Header = styled(View)`
  position: relative;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 20px;
`

function BrowseBlock({
  children,
  emphasis,
  image,
  action,
  overlay,
  heading,
  sidescroll
}) {
  return (
    <Container emphasis={emphasis}>
      {image && <BackgroundImage image={image} overlay={overlay} />}
      <Wrapper>
        {heading && (
          <Header>
            <Text.H4>{heading}</Text.H4>
            {action && action}
          </Header>
        )}
        {children}
      </Wrapper>
    </Container>
  )
}

export default BrowseBlock
