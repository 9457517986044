import IconButton from "@pathwright/ui/src/components/button/IconButton"
import styled, { keyframes } from "styled-components"
import { INBOX_CONTEXT_PANEL_MIN_WIDTH } from "../../ui/constants"

const rotate = keyframes`
  from {
    transform: rotate(-90deg);
  }
  to {
    transform: rotate(0deg);
  }
`

const Container = styled.div`
  position: absolute;
  right: 10px;
  top: 20px;
  padding: 10px;
  z-index: 20000000;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  border-radius: 20px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease;

  &.open {
    padding: 0px;
    top: 0px;
    right: min(${INBOX_CONTEXT_PANEL_MIN_WIDTH + 10}px, calc(100vw - 40px));
    .IconButton {
      background: rgba(255, 255, 255, 0.8);
      backdrop-filter: blur(10px);
      border-radius: 50%;
      animation: ${rotate} 0.2s linear;
    }
  }
`

const ContextPanelNav = ({ panels, open, onSelect }) => {
  const handleSelect = (panel) => () => {
    onSelect(panel ? panel.key : null)
  }

  let className = "ContextPanelNav"
  className += open ? " open" : " closed"

  return (
    <Container className={className}>
      {!open ? (
        panels.map((panel) => {
          return (
            <IconButton
              key={panel.key}
              icon={panel.icon}
              onClick={handleSelect(panel)}
            />
          )
        })
      ) : (
        <IconButton icon={"x"} onClick={handleSelect(null)} />
      )}
    </Container>
  )
}

export default ContextPanelNav
