import gql from "graphql-tag"
import STORE_FRAGMENT from "./store-fragment"

export default gql`
  query StoreQuery {
    store {
      ...Store
    }
  }
  ${STORE_FRAGMENT}
`
