import React from "react"

class Image extends React.PureComponent {
  state = {
    loaded: false
  }

  UNSAFE_componentWillMount() {
    this.handleLoadImage()
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.src !== prevProps.src) {
      this.handleLoadImage()
    }
  }

  handleLoadImage = () => {
    const img = document.createElement("img")
    // handle asyncronous loading (first load)
    img.onload = this.handleLoad
    img.setAttribute("src", this.props.src)
    // handle syncrounous loading (cached img)
    if (img.naturalWidth) this.handleLoad()
  }

  componentWillUnmount() {
    this.willUnmount = true
  }

  handleLoad = () => {
    if (!this.state.loaded && !this.willUnmount) {
      this.setState({ loaded: true })
    }
  }

  render() {
    const { alt, src, style, _loading, ...rest } = this.props
    return (
      <img
        {...rest}
        alt={alt}
        src={src}
        style={{
          opacity: this.state.loaded ? 1 : 0,
          transition: "opacity 300ms",
          ...style,
          ...(this.state.loaded ? {} : _loading)
        }}
      />
    )
  }
}

Image.displayName = "Image"

export default Image
