import CardStack from "@pathwright/ui/src/components/card/CardStack"
import ConnectStores from "lib/core/store/components/ConnectStores"
import path from "path-browserify"
import { useCallback } from "react"
import { useHistory } from "react-router-dom"

const RouteCardStack = (props) => {
  const {
    cards,
    location,
    defaultRoute,
    prevent_modal_close,
    modal_launched_from_url
  } = props

  const history = useHistory()

  const handleCloseCard = useCallback(
    (card, index) => {
      // returning false prevents popping the card
      if (prevent_modal_close) return false
      // Allow popping the card, we must be popping a card that isn't
      // tied to a route.
      if (!card.path) return true

      const prevURL =
        index > 0 ? path.join(location.pathname, card.reversePath) : null

      if (prevURL && cards.length >= 2) {
        history.push({ pathname: prevURL })
      } else {
        // closing stack, route to prev url before card stack mounted
        history.push(modal_launched_from_url || defaultRoute())
      }
    },
    [cards, location, prevent_modal_close, modal_launched_from_url]
  )

  return <CardStack {...props} onCloseCard={handleCloseCard} />
}

RouteCardStack.displayName = "RouteCardStack"

const connectedStores = [
  {
    store: "navigation",
    watch: ["modal_launched_from_url", "prevent_modal_close"]
  }
]

const mapStateToProps = function (state, ownProps) {
  const { modal_launched_from_url, prevent_modal_close } =
    state.navigationStore.state
  const { defaultRoute } = state.navigationStore.request
  const storeProps = {
    modal_launched_from_url,
    prevent_modal_close,
    defaultRoute
  }
  const stackProps = _.assign({}, storeProps, ownProps)
  return stackProps
}

export default ConnectStores(
  RouteCardStack,
  connectedStores,
  {},
  mapStateToProps
)
