import { Box, IconButton, VStack } from "@chakra-ui/react"
import { useCertificateState } from "../context/CertificateState"
import panels from "./panels"

const IconLauncher = ({ onSelectPanel }) => {
  const {
    certificateStoredState: { discardCount }
  } = useCertificateState()

  const handleSelectPanel = (panelIndex) => () => onSelectPanel(panelIndex)

  return (
    <VStack
      className="CertificateDrawerLauncher"
      spacing={1}
      position="relative"
      backgroundColor="white"
      borderRadius="20px"
      boxShadow="rgb(0 0 0 / 20%) 0px 0px 3px"
      p={2}
    >
      {panels.map((panel, i) => (
        <IconButton
          key={panel.key}
          aria-label={`Certificate ${panel.label.toLowerCase()} settings`}
          size="sm"
          icon={<panel.Icon size="1.2em" />}
          onClick={handleSelectPanel(i)}
          colorScheme="gray"
          variant="ghost"
        />
      ))}
      {!!discardCount && (
        // Indicator.
        <Box
          position="absolute"
          top={0}
          right={0}
          transform=" translate(10%, -40%)"
          bg="red"
          width="12px"
          height="12px"
          borderRadius="100%"
          pointerEvents="none"
        />
      )}
    </VStack>
  )
}

export default IconLauncher
