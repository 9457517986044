import cloneDeep from "lodash/cloneDeep"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import InvalidVariable from "./InvalidVariable"
import VariableSelectorList from "./VariableSelectorList"
import VariableSelectorPicker from "./VariableSelectorPicker"

// Insert variable string into delimiters.
function variableTemplate(delimiters, value) {
  const [firstDelimiters, lastDelimiters = ""] = delimiters
  return `${firstDelimiters}${value}${lastDelimiters}`
}

// TODO: handle new line?

export const delimiterConfigsMap = {
  // Current @ delimiter:
  at: {
    beforeSelectionRe: new RegExp("(?<full>@(?<partial>[a-zA-Z._ ]*) *)$"),
    afterSelectionRe: new RegExp("^(?<full>(?<partial>[a-zA-Z._]*))"),
    template: (value) => variableTemplate`@${value.trim()}`
  },
  // Previous {{ }} delimiter:
  bracket: {
    beforeSelectionRe: new RegExp("(?<full>\\{\\{ *(?<partial>[a-zA-Z._ ]*) *)$"), // prettier-ignore
    afterSelectionRe: new RegExp("^(?<full> *(?<partial>[a-zA-Z._ ]*) *\\}\\})"), // prettier-ignore
    // template: value => variableTemplate`{{ ${value.trim()} }}`
    // Since the {{ }} delimiter is legacy, we'll just convert that style variable when possible.
    template: (value) => variableTemplate`@${value.trim()}`
  }
}

const useVariableSelector = ({
  variables = {},
  value: valueProp,
  delimiterConfigs = Object.values(delimiterConfigsMap),
  EmptyVariableSelectorList = InvalidVariable
}) => {
  const [value, setValue] = useState(valueProp || "")
  const [nextSelectionStart, setNextSelectionStart] = useState(0)
  const selectionStartRef = useRef(null)
  const [showVariableSelector, setShowVariableSelector] = useState(false)
  const [partialVariableMatch, setPartialVariableMatch] = useState(null)
  const [fullVariableMatch, setFullVariableMatch] = useState(null)
  const [activeVariableIndex, setActiveVariableIndex] = useState(0)
  const [selectorRect, setSelectorRect] = useState(null)

  // filters out variables that don't match the partialVariableMatch + adds meta data like `index`
  const getFilteredVariables = useCallback(
    (variables, path = "", fullLabel = "", totalIndeces = 0) => {
      const keys = Object.keys(variables)

      for (let i = 0; i < keys.length; i++) {
        const key = keys[i]
        const variablePath = path ? `${path}.${key}` : key
        const variableFullPath = fullLabel
          ? `${fullLabel} ${variables[key].label}`
          : variables[key].label

        let childVariables = {}
        if (variables[key].variables) {
          const [_childVariables, _totalIndeces] = getFilteredVariables(
            variables[key].variables,
            variablePath,
            variableFullPath,
            totalIndeces
          )

          childVariables = _childVariables
          totalIndeces = _totalIndeces
        }

        if (
          // prettier-ignore
          partialVariableMatch &&
          variablePath.toLowerCase().indexOf(partialVariableMatch.toLowerCase()) === -1 && 
          variableFullPath.toLowerCase().indexOf(partialVariableMatch.toLowerCase()) === -1 && 
          Object.keys(childVariables).length === 0
        ) {
          delete variables[key]
        } else {
          variables[key].path = variablePath
          if (!variables[key].variables) {
            variables[key].index = totalIndeces
            totalIndeces++
          }
        }
      }

      return [variables, totalIndeces]
    },
    [variables, partialVariableMatch]
  )

  // returns the variable matching all the provided key/value pairs if found
  // as well as the variable's tree (tree can later be used to derive something, like the variable's full label)
  const getVariable = useCallback(
    (variables, uniqueKeyValuePairs, tree = []) => {
      const keys = Object.keys(variables)

      for (let i = 0; i < keys.length; i++) {
        const key = keys[i]

        tree.push(variables[key])
        if (variables[key].variables) {
          const [variable, nextTree] = getVariable(
            variables[key].variables,
            uniqueKeyValuePairs,
            tree
          )
          if (variable) return [variable, nextTree]
          if (nextTree) nextTree.pop()
        } else if (
          // match all the provided key/value pairs
          Object.entries(uniqueKeyValuePairs).every(
            ([uniqueKey, uniqueValue]) =>
              variables[key][uniqueKey] === uniqueValue
          )
        ) {
          return [variables[key], tree]
        }
        tree.pop()
      }
      return [null, null]
    },
    []
  )

  // get the filteredVariables and totalIndeces based on the partialVariableMatch
  const [filteredVariables, totalIndeces] = useMemo(
    () => getFilteredVariables(cloneDeep(variables)),
    [variables, partialVariableMatch]
  )

  useEffect(() => {
    if (
      Object.keys(filteredVariables).length === 0 &&
      !EmptyVariableSelectorList
    )
      setShowVariableSelector(false)

    // reset activeVariableIndex if the filteredVariables no longer contain a variable at the current activeVariableIndex
    // this could be due to user moving between variable delimeters
    if (!getVariable(filteredVariables, { index: activeVariableIndex })[0]) {
      setActiveVariableIndex(0)
    }
  }, [filteredVariables])

  // send value downstream
  useEffect(() => {
    if (valueProp !== value) setValue(valueProp)
  }, [valueProp])

  useEffect(() => {
    const variable =
      // prettier-ignore
      // attempt to get the variable based on path and index
      getVariable(filteredVariables, { path: fullVariableMatch, index: activeVariableIndex })[0] ||
      // attempt to get the variable based on path
      getVariable(filteredVariables, { path: fullVariableMatch })[0] ||
      // attempt to get the variable based on partial path
      // NOTE: the partial variable match lookup would likely never result in a matched variable
      getVariable(filteredVariables, { path: partialVariableMatch })[0]

    if (variable) {
      setActiveVariableIndex(variable.index)
    }
  }, [partialVariableMatch, fullVariableMatch])

  // reset after selector no longer shown
  useEffect(() => {
    if (!showVariableSelector) {
      setPartialVariableMatch(null)
      setFullVariableMatch(null)
      setActiveVariableIndex(0)
    }
  }, [showVariableSelector])

  const handleSelection = useCallback(
    (e, val, nextSelectionStart) => {
      if (typeof val === "undefined") {
        val = e?.target.value
        nextSelectionStart = e?.target.selectionStart
      }

      // Catch unknown case where we still don't have a valid value.
      if (typeof val !== "string") return

      if (nextSelectionStart !== selectionStartRef.current)
        selectionStartRef.current = nextSelectionStart

      if (nextSelectionStart !== null) {
        const valueBeforeSelection = val.slice(0, nextSelectionStart)
        const valueAfterSelection = val.slice(nextSelectionStart)

        for (let i = 0; i < delimiterConfigs.length; i++) {
          const { beforeSelectionRe, afterSelectionRe } = delimiterConfigs[i]
          if (beforeSelectionRe.test(valueBeforeSelection)) {
            // only handle setting the current value if we have a match, reduces unnecessary renders due to state change
            if (val !== value) setValue(val)
            if (!showVariableSelector) setShowVariableSelector(true)

            const partialVariableStart = valueBeforeSelection
              .match(beforeSelectionRe)
              .groups.partial.trim()
            setPartialVariableMatch(partialVariableStart)

            if (afterSelectionRe.test(valueAfterSelection)) {
              const partialVariableEnd = valueAfterSelection
                .match(afterSelectionRe)
                .groups.partial.trim()
              const fullVariable = `${partialVariableStart}${partialVariableEnd}`
              setFullVariableMatch(fullVariable)
            }
            // Early return, handling the first config that matches.
            return
          }
        }

        // No matching config, close variable selector if open.
        if (showVariableSelector) setShowVariableSelector(false)
      }
    },
    [showVariableSelector, value]
  )

  // selecting variable via clicking variable item or hitting enter
  const handleSelectVariable = useCallback(
    (variable) => {
      // substring of value starting at beginning of value, ending at current selection index
      const valueBeforeSelection = value.slice(0, selectionStartRef.current)
      // substring of value starting at current selection index, ending at end of value
      const valueAfterSelection = value.slice(selectionStartRef.current)

      for (let i = 0; i < delimiterConfigs.length; i++) {
        const { beforeSelectionRe, afterSelectionRe, template } =
          delimiterConfigs[i]
        // check if opening delimeters occur before the current selection index
        if (beforeSelectionRe.test(valueBeforeSelection)) {
          // match found
          const beforeMatch = valueBeforeSelection.match(beforeSelectionRe)
          const startIndex = beforeMatch.index
          const matchedStr = beforeMatch.groups.full

          let endIndex = startIndex + matchedStr.length

          if (afterSelectionRe.test(valueAfterSelection)) {
            const afterMatch = valueAfterSelection.match(afterSelectionRe)
            const matchedStr = afterMatch.groups.full
            endIndex += matchedStr.length
          } else {
            // get the slice of the value starting at the beginning of the variable match
            const valueSubstr = value.slice(
              startIndex +
                beforeMatch.groups.full.length -
                beforeMatch.groups.partial.length
            )
            // attempt to get the endIndex by constucting RegExp from labels in variable tree
            // TODO: a complete solution would check all filteredVariables
            const [_, tree] = getVariable(filteredVariables, {
              path: variable.path
            })

            // NOTE: wrapping chars with [] to force literal matching (lazy way of escaping the . in variable path, for example)
            const constructReString = (words) => {
              const reStr = []
                .concat(words)
                .map((word, wi, words) => {
                  let subReStr = word
                    .split("") // split all characters in word
                    .map((char, ci, chars) => {
                      // NOTE: lookbehinds aren't currently supported by all major browsers (looking at you Safari), so must resort to less specific re
                      // if (ci) return `(?:(?<=[${chars[ci - 1]}])[${char}])?` // only match next char if it follows the preceeding char
                      // if (wi) return `[${char}]?` // no preceeding char
                      if (ci || wi) return `[${char}]?` // optionally match char
                      return `[${char}]` // no preceeding char or preceeding word, at leas match beginning char
                    })
                    .join("")
                  if (wi < words.length - 1) {
                    subReStr = `${subReStr}(?: +(?= *[${words[wi + 1][0]}]))?` // only match space(s) in between words if space(s) is/are followed by first char of next word (don't greedily grab those spaces)
                  }
                  return subReStr
                })
                .join("")

              // force match to start at beginning of string, ignoring spaces (necessary for if initial char of valueSubstr doesn't match the variable label or path)
              return `^ *${reStr}`
            }

            // construct RegExp string from variable paths and labels, separately
            const variablePathReString = constructReString(
              tree[tree.length - 1].path // only need the final path
            )
            const variableLabelReString = constructReString(
              tree.reduce(
                (words, { label }) => [
                  ...words,
                  ...label.toLowerCase().split(" ") // lowercase label and split into separate words
                ],
                []
              )
            )

            // now match either regexp, prioritizing the one that matches the most characters
            const afterMatch = [
              new RegExp(variablePathReString, "i"),
              new RegExp(variableLabelReString, "i")
            ]
              .map((re) => valueSubstr.match(re))
              .sort(
                (matchA, matchB) =>
                  // sort match with most matched chars to the front
                  (matchB ? matchB[0].length : 0) -
                  (matchA ? matchA[0].length : 0)
              )[0]

            if (afterMatch) {
              const matchedStr = afterMatch[0]
              // offset endIndex by the matchStr index & length, subtracting any variable match length
              endIndex +=
                afterMatch.index +
                matchedStr.length -
                beforeMatch.groups.partial.length
            }
          }
          // format the result
          const variableString = template(variable.path)
          // const variableString = `@${variable.path}`
          // get the value substsrings before and after the matched variable substring
          const beforeVariableString = value.slice(0, startIndex)
          const afterVariableString = value.slice(endIndex)
          // now insert the variableString
          const nextValue = `${beforeVariableString}${variableString}${afterVariableString}`
          // adjust the start of the selection to be at the end of the inserted variableString
          const nextSelectionStart =
            beforeVariableString.length + variableString.length
          // component using the hook will be updated with the value
          setValue(nextValue)
          setNextSelectionStart(nextSelectionStart)
          // reset states
          setPartialVariableMatch(null)
          setFullVariableMatch(null)
          setActiveVariableIndex(0)
          setShowVariableSelector(false)
          // Early return, handling the first config that matches.
          return
        }
      }
    },
    [value, filteredVariables]
  )

  const handleKeyDown = useCallback(
    (e) => {
      // Get current variable if showVariableSelector
      const [variable] = showVariableSelector
        ? getVariable(filteredVariables, {
            index: activeVariableIndex
          })
        : [null]

      // prevent moving cursor to beginning or end of input
      switch (e.keyCode) {
        case 40: // arrow down
        case 38: // arrow up
        case 13: // enter
          if (variable) {
            e.preventDefault()
            e.stopPropagation()
          }
      }
    },
    [showVariableSelector, activeVariableIndex, filteredVariables]
  )

  const handleKeyUp = useCallback(
    (e, value, selectionStart) => {
      // Get current variable if showVariableSelector
      const [variable] = showVariableSelector
        ? getVariable(filteredVariables, {
            index: activeVariableIndex
          })
        : [null]

      switch (e.keyCode) {
        case 40: // arrow down
        case 38: // arrow up
        case 13: // enter
          // Ensure variable exists as this may be
          if (variable) {
            e.preventDefault()
            e.stopPropagation()

            switch (e.keyCode) {
              case 40: // arrow down
                setActiveVariableIndex(() =>
                  Math.min(activeVariableIndex + 1, totalIndeces - 1)
                )
                return
              case 38: // arrow up
                setActiveVariableIndex(() =>
                  Math.max(activeVariableIndex - 1, 0)
                )
                return
              case 13: // enter
                // select variable based on activeVariableIndex
                const [variable, tree] = getVariable(filteredVariables, {
                  index: activeVariableIndex
                })
                handleSelectVariable(variable)
                return
            }
          }
          break
        case 27: // escape
          // close the selector when user hits escape key
          if (showVariableSelector) setShowVariableSelector(false)
          // ignore when user hits escape key
          return
      }

      handleSelection(e, value, selectionStart)
    },
    [
      showVariableSelector,
      handleSelectVariable,
      activeVariableIndex,
      totalIndeces,
      filteredVariables
    ]
  )

  const handleChange = useCallback(
    (e, val) => {
      if (typeof val !== "undefined") {
        if (val !== value) setValue(val)
      } else {
        if (e.target.value !== value) setValue(e.target.value)
      }
    },
    [value]
  )

  const handleBlur = useCallback(
    (e) => {
      if (showVariableSelector) {
        // HACK ALERT: fix for when the blur is occurring because user is clicking the variable selector UI, in which case we don't want to close the variable selecor just yet
        setTimeout(() => {
          setShowVariableSelector(false)
        }, 150)
      }
    },
    [showVariableSelector]
  )

  // Choose to ignore the partial variable match when there are spaces or
  // ends in a period and no exact matches are found.
  const ignoreVariable = useMemo(() => {
    const hasExactMatch = !!getVariable(filteredVariables, {
      path: partialVariableMatch
    })[0]
    const hasPartialMatch = !!Object.keys(filteredVariables).length
    const hasSpaces = /\.? +/.test(partialVariableMatch)
    const endsInPerioud = /\.$/.test(partialVariableMatch)
    return ((hasSpaces || endsInPerioud) && !hasPartialMatch) || hasExactMatch
  }, [partialVariableMatch, filteredVariables])

  const selector =
    showVariableSelector && !ignoreVariable ? (
      <VariableSelectorPicker rect={selectorRect}>
        <VariableSelectorList
          variable={partialVariableMatch}
          variables={filteredVariables}
          activeVariableIndex={activeVariableIndex}
          handleSelectVariable={handleSelectVariable}
          EmptyVariableSelectorList={EmptyVariableSelectorList}
        />
      </VariableSelectorPicker>
    ) : null

  return {
    selector,
    showingVariableSelector: showVariableSelector,
    onBlur: handleBlur,
    onKeyDown: handleKeyDown,
    onKeyUp: handleKeyUp,
    handleSelection: handleSelection,
    onChange: handleChange,
    value: value,
    nextSelectionStart,
    setSelectorRect: setSelectorRect
  }
}

export default useVariableSelector
