import USER_FRAGMENT from "@pathwright/web/src/modules/user/graphql/user-fragment"
import gql from "graphql-tag"
import ResourceThumbnail from "../ResourceThumbnail"

export default gql`
  query ActivityQuery(
    $cursor: String
    $action_type: ActionTypeFilter
    $user_id: Int
    $resource_id: Int
    $group_id: Int
    $step_id: Int
    $include_user: Boolean!
  ) {
    activity_user: user(id: $user_id) @include(if: $include_user) {
      id
      first_name
    }
    activity(
      first: 26
      after: $cursor
      action_type: $action_type
      user_id: $user_id
      resource_id: $resource_id
      group_id: $group_id
      step_id: $step_id
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          action_type
          action_dtime
          resource {
            ...ResourceThumbnail
          }
          group {
            id
            name
            seats
          }
          user {
            ...User
          }
          triggered_by {
            ...User
          }
          step {
            id
            source_id
            name
            verb
            completion {
              id
              user {
                id
              }
            }
          }
          discussion {
            id
            title
            body
            type
            is_discussion_question
            user {
              id
              display_name
            }
          }
          discussion_response {
            id
            body
            parent_response {
              body
              user {
                id
                display_name
              }
            }
          }
        }
      }
    }
  }
  ${ResourceThumbnail.fragments.resourceThumbnail}
  ${USER_FRAGMENT}
`
