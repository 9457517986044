import gql from "graphql-tag"
import SCHOOL_FRAGMENT from "../../school/graphql/school-fragment"
import Page from "./page-fragment"

export default gql`
  query PagesQuery($first: Int = 10) {
    school {
      ...School
      pages(first: $first) {
        edges {
          node {
            ...Page
          }
        }
      }
    }
  }
  ${SCHOOL_FRAGMENT}
  ${Page}
`
