import { Avatar, AvatarGroup, Box, Button, HStack } from "@chakra-ui/react"
import { getAvatarFallbackBG } from "@pathwright/ui/src/components/avatar/Avatar"
import Link from "@pathwright/ui/src/components/link/Link"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import { forwardRef } from "react"
import { ShareDumb } from "../../../share/Share"
import ManageMentorGroupCard from "../../manage/ManageMentorGroupCard"
import { getToGroupManage } from "../../utils"
import MentorGroupControls from "./MentorGroupControls"
import { useMentorGroupListContext } from "./MentorGroupListContext"

const GhostButton = forwardRef(({ children, ...props }, ref) => (
  <Button
    ref={ref}
    variant="ghost"
    color="white"
    colorScheme="whiteAlpha"
    size="sm"
    fontSize="1em"
    p={2}
    cursor="pointer"
    pos="relative"
    {...props}
  >
    <HStack spacing={1}>{children}</HStack>
  </Button>
))

const MentorGroupControlsContainer = () => {
  const { mentorGroup, groupSubscription, permissions } =
    useMentorGroupListContext()

  return permissions.can_edit_group && permissions.can_edit_subscription ? (
    <MentorGroupControls
      mentorGroup={mentorGroup}
      groupSubscription={groupSubscription}
    >
      {({ indicator }) => (
        // TODO: use buttonProps.indicator? Already showing indicar on MentorGroupSeats for same condition...
        <GhostButton>
          <Pathicon icon="ellipses-horizontal" />
        </GhostButton>
      )}
    </MentorGroupControls>
  ) : (
    <GhostButton
      as={Link}
      to={{
        pathname: getToGroupManage(mentorGroup.id),
        card: {
          component: ManageMentorGroupCard,
          props: {
            id: mentorGroup.id
          }
        }
      }}
    >
      <Pathicon icon="gear" />
    </GhostButton>
  )
}

const MentorGroupAvatars = ({ avatars }) => {
  const getName = (person) =>
    [person.first_name, person.last_name].filter(Boolean).join(" ")

  const getImage = (person) => {
    const { image } = person.profile
    return image && image.indexOf("profile-default") === -1 ? image : null
  }

  return avatars.length ? (
    <HStack spacing={1} minW={0} p={1}>
      <AvatarGroup size="xs" spacing={-2}>
        {avatars.slice(0, 3).map((person) => (
          <Avatar
            key={person.id}
            name={getName(person)}
            src={getImage(person)}
            bg={
              getImage(person)
                ? "gray.900"
                : getAvatarFallbackBG({ user: person })
            }
            color="white"
            border="none"
          />
        ))}
      </AvatarGroup>
    </HStack>
  ) : null
}

const MentorGroupAdd = forwardRef(({ onToggle, ...rest }, ref) => {
  const { t } = useTranslate()
  const { mentorGroup } = useMentorGroupListContext()

  return (
    <GhostButton ref={ref} {...rest} onClick={onToggle} fontWeight="normal">
      <Pathicon icon="avatar-plus" />{" "}
      {mentorGroup.seat_limit ? (
        <>
          <strong>{t("mentor_group.members.list.add")}</strong>{" "}
          <Box
            as="span"
            opacity={0.5}
          >{`${mentorGroup.seats_filled}/${mentorGroup.seat_limit}`}</Box>
        </>
      ) : (
        <strong>{t("mentor_group.members.list.add")}</strong>
      )}
    </GhostButton>
  )
})

const MentorGroupIsland = () => {
  const { mentorGroup, staff, members } = useMentorGroupListContext()
  const avatars = [...staff, ...members].map((member) => member.user)

  return mentorGroup ? (
    <Box
      bg="gray.900"
      borderRadius="50px"
      p={1}
      position="relative"
      zIndex={10}
    >
      <HStack className="chakra-ui-reset-css" display="inline-flex" spacing={1}>
        <MentorGroupAvatars avatars={avatars} />
        <ShareDumb
          context={{ type: "group", typeId: mentorGroup.id }}
          openWithKeys
          portal
        >
          {({ renderTrigger }) => renderTrigger(MentorGroupAdd)}
        </ShareDumb>
        <MentorGroupControlsContainer />
      </HStack>
    </Box>
  ) : null
}

export default MentorGroupIsland
