import { HStack, Wrap } from "@chakra-ui/react"
import IconButton from "@pathwright/ui/src/components/button/IconButton"
import Resource from "@pathwright/ui/src/components/library/Resource"
import VariableTag from "../variables/VariableTag"

function CertificateResource({
  resource,
  canEdit,
  onRemoveResourceCertificate
}) {
  const controls = (
    <HStack>
      {canEdit && (
        <IconButton
          icon="edit"
          to={`/certificate/?resource_id=${resource.id}`}
        />
      )}
      <IconButton
        icon="x"
        onClick={(e) => onRemoveResourceCertificate(e, resource)}
      />
    </HStack>
  )

  const meta = (
    <Wrap mt={0.5} spacing={1}>
      {Object.entries(resource.certificate_json || {}).map(([key, value]) =>
        value ? (
          <VariableTag key={key} variable={`${key}: ${value}`} active />
        ) : null
      )}
    </Wrap>
  )

  return (
    <Resource
      key={resource.id}
      layout="list"
      resource={resource}
      loading={!resource}
      controls={controls}
      meta={meta}
      style={{
        height: "90px",
        // Excess variables will simply be clipped.
        // We have room for two rows of variables, but after that,
        // variables will be clipped, so we may need a UX solution
        // possibly show total number of variables somewhere?
        overflow: "hidden"
      }}
    />
  )
}

export default CertificateResource
