import BackgroundImage from "@pathwright/ui/src/components/background/BackgroundImage"
import Button from "@pathwright/ui/src/components/button/Button"
import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import MediaManager from "@pathwright/web/src/modules/media-manager/MediaManager"
import { ImgixPreviewProvider } from "lib/core/components/filestack"
import {
  ColorPickerToggle,
  ConnectForm,
  ToggleInput
} from "lib/core/components/forms"
import { Col } from "lib/core/components/grid"
import get from "lodash/get"
import React from "react"
import "./ResourceThemeForm.css"

const labelWidth = 200
let fieldsData = {
  background: {
    label: "Replace Background Image",
    mediaType: "image"
  },
  blur: {
    label: "Blur background image?",
    labelWidth,
    alignRight: true
  },
  overlay: {
    label: "Background color overlay",
    labelWidth,
    alignRight: true,
    optional: true
  }
}

class ResourceThemeForm extends React.Component {
  render() {
    let handleSubmit, isValid
    ;({ fieldsData, isValid, handleSubmit } = this.props)
    const { background, overlay, blur } = fieldsData

    return (
      <form class="ResourceThemeForm" onSubmit={(e) => e.preventDefault()}>
        <div className="ResourceTheme__background">
          <ImgixPreviewProvider
            preview={
              background.value != null ? background.value.url : undefined
            }
            url={background.value != null ? background.value.url : undefined}
            blur={blur.value}
          >
            {({ preview }) => {
              return (
                <BackgroundImage
                  image={preview}
                  overlay={fieldsData.overlay.value}
                />
              )
            }}
          </ImgixPreviewProvider>
          <MediaManager
            contextKey={`resource-${this.props.resourceID}`}
            onChooseMedia={(media) => {
              background.onChange(
                {
                  url: media.url,
                  mimetype: media.mimetype,
                  size: get(media, "size.fileSize"),
                  filename: media.name
                },
                "background"
              )
            }}
            renderButton={({ onClick }) => (
              <Button
                styleType="secondary"
                inverted
                size="medium"
                onClick={onClick}
                className="EditButton"
                preIcon="shuffle"
                label={background.label}
              />
            )}
          />
        </div>
        {(background.value != null ? background.value.url : undefined) !=
        null ? (
          <Col xs={12} sm={6}>
            <ToggleInput {...Object.assign({}, blur)} />
          </Col>
        ) : undefined}
        <Col xs={12} sm={6}>
          <ColorPickerToggle {...Object.assign({}, overlay)} />
        </Col>
        <SubmitButton
          disabled={!isValid}
          styleType="primary"
          onClick={handleSubmit}
        >
          Save
        </SubmitButton>
      </form>
    )
  }
}

ResourceThemeForm = ConnectForm(ResourceThemeForm, fieldsData)

ResourceThemeForm.displayName = "ResourceThemeForm"

export default ResourceThemeForm
