import {
  hasEditorLevelAccess,
  hasLearnerLevelAccess,
  hasModeratorLevelAccess,
  hasObserverLevelAccess
} from "../group/permissions"
import { hasAccess } from "../user/permissions"

export const canEditDiscussion = (pwContext, discussion) => {
  // anonymous user cannot edit discussion
  if (!hasAccess(pwContext)) return false
  if (hasEditorLevelAccess(pwContext, discussion.cohort)) return true

  // moderators can edit non-discussion-type questions
  if (
    !discussion.is_discussion_question &&
    hasModeratorLevelAccess(pwContext, discussion.cohort)
  ) {
    return true
  }

  if (discussion.user.id === pwContext.me.id) return true

  return false
}

// same as canEditDiscussion
export const canDeleteDiscussion = (pwContext, discussion) => {
  if (canEditDiscussion(pwContext, discussion)) return true

  return false
}

// discussion may be start-locked, but not handling that in discussion permissions
export const canViewDiscussion = (pwContext, discussion) => {
  // anonymous user cannot view discussion
  if (!hasAccess(pwContext)) return false
  if (hasEditorLevelAccess(pwContext, discussion.cohort)) return true
  if (hasObserverLevelAccess(pwContext, discussion.cohort)) return true

  if (discussion.author_id === pwContext.me.id) return true

  return false
}

export const canAskToAnswerDiscussion = (pwContext, discussion) => {
  if (
    !discussion.is_discussion_question &&
    hasModeratorLevelAccess(pwContext, discussion.cohort)
  ) {
    return true
  }

  return false
}

export const canRespondToDiscussion = (pwContext, discussion) => {
  // anonymous user cannot respond to discussion
  if (!hasAccess(pwContext)) return false
  if (!discussion.allow_replies) return false
  if (hasEditorLevelAccess(pwContext, discussion.cohort)) return true
  // learners or greateer can respond to questions
  if (hasLearnerLevelAccess(pwContext, discussion.cohort)) return true

  return false
}

// same permissions as for responding
export const canLikeDiscussion = (pwContext, discussion) => {
  if (canRespondToDiscussion(pwContext, discussion)) return true

  return false
}

// same permissions as for responding
export const canSubscribeToDiscussion = (pwContext, discussion) => {
  if (canRespondToDiscussion(pwContext, discussion)) return true

  return false
}
