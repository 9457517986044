import { AppleLogoIcon, GoogleLogoIcon } from "@pathwright/pathicons"
import {
  FEATURE_KEY_ACTIVITY_FEED,
  FEATURE_KEY_AUTH_LOGIN_OPTION_GLOBAL_SSO_APPLE,
  FEATURE_KEY_AUTH_LOGIN_OPTION_GLOBAL_SSO_GOOGLE,
  FEATURE_KEY_MEMBER_DIRECTORY,
  FEATURE_PERMISSION_ADMIN_ONLY,
  FEATURE_PERMISSION_DISABLED,
  FEATURE_PERMISSION_EVERYONE,
  FEATURE_PERMISSION_STAFF_ONLY
} from "./constants"

export const featureKeyToParam = (key) => key.replace(/_/g, "-")
export const featureParamToKey = (param) => param.replace(/-/g, "_")

export const getFeatureInfo = (featureKey, translator) => {
  switch (featureKey) {
    case FEATURE_KEY_ACTIVITY_FEED:
      return {
        icon: "activity-feed",
        title: translator("Timeline"),
        desc: translator("Display member activity in Paths."),
        link: "https://help.pathwright.com/en/articles/5360083-cohort-timeline",
        tooltip: translator(
          "A day-by-day view of who's making progress and active in discussion."
        )
      }
    case FEATURE_KEY_MEMBER_DIRECTORY:
      return {
        icon: "avatar-stack",
        title: translator("Member Directory"),
        desc: translator("List members in Paths."),
        link: "https://help.pathwright.com/en/articles/5359893-cohort-member-directory",
        tooltip: translator(
          "A year-book-like view that helps members learn about each other."
        )
      }
    case FEATURE_KEY_AUTH_LOGIN_OPTION_GLOBAL_SSO_GOOGLE:
      return {
        icon: <GoogleLogoIcon size="2.5em" />,
        // NOTE: `name` is inconsistent key.
        name: "Google",
        title: translator("Sign in with Google"),
        desc: translator(`Enable members to sign in with Google.`),
        link: "https://help.pathwright.com/en/articles/8260475-enable-faster-sign-in-with-apple-and-google",
        tooltip: translator(
          "Speed up how quickly your members can access your Paths and reduce forgotten password support requests by enabling Sign in with Google."
        )
      }
    case FEATURE_KEY_AUTH_LOGIN_OPTION_GLOBAL_SSO_APPLE:
      return {
        icon: <AppleLogoIcon size="2.5em" />,
        // NOTE: `name` is inconsistent key.
        name: "Apple",
        title: translator("Sign in with Apple"),
        desc: translator(`Enable members to sign in with Apple.`),
        link: "https://help.pathwright.com/en/articles/8260475-enable-faster-sign-in-with-apple-and-google",
        tooltip: translator(
          "Speed up how quickly your members can access your Paths and reduce forgotten password support requests by enabling Sign in with Apple."
        )
      }
    case "discussion_topics_tbd":
      return {
        icon: "chat-bubble-check",
        title: translator("Discussion Topics"),
        desc: translator("Organize discussion topics across Paths."),
        link: "todo/"
      }
    default:
      return {
        icon: "",
        title: "",
        desc: ""
      }
  }
}

export const getFeaturePermissionInfo = (
  featureKey,
  permission,
  translator,
  tOptions = {}
) => {
  switch (featureKey) {
    case FEATURE_KEY_AUTH_LOGIN_OPTION_GLOBAL_SSO_GOOGLE:
    case FEATURE_KEY_AUTH_LOGIN_OPTION_GLOBAL_SSO_APPLE:
      switch (permission) {
        case FEATURE_PERMISSION_DISABLED:
          return {
            icon: "eye-off",
            title: translator("Disabled"),
            titleAbbr: translator("Disabled"),
            titleVerbose: translator("Disabled"),
            desc: "", // TODO
            descVerbose: "" // TODO
          }
        case FEATURE_PERMISSION_EVERYONE:
          return {
            icon: "eye",
            title: translator("Enabled"),
            titleAbbr: translator("Enabled"),
            titleVerbose: translator("Enabled"),
            desc: "", // TODO
            descVerbose: "" // TODO
          }
        default:
          return {
            title: translator("Coming soon"),
            desc: ""
          }
      }
    default:
      switch (permission) {
        case FEATURE_PERMISSION_DISABLED:
          return {
            icon: "eye-off",
            title: translator("Disabled"),
            titleAbbr: translator("Disabled"),
            titleVerbose: translator("Disabled"),
            desc: "", // TODO
            descVerbose: "" // TODO
          }
        case FEATURE_PERMISSION_ADMIN_ONLY:
          return {
            icon: "eye-lock-fill",
            title: translator("Admin only"),
            titleVerbose: translator("Visible to Admin Only"),
            titleAbbr: translator("Admin"),
            desc: translator("Only visible to Administrators."),
            descVerbose: translator(
              "Only Administrators can see the {{ feature }}.",
              tOptions
            )
          }
        case FEATURE_PERMISSION_STAFF_ONLY:
          return {
            icon: "eye-lock",
            title: translator("Staff only"),
            titleVerbose: translator("Visible to Staff Only"),
            titleAbbr: translator("Staff"),
            desc: translator(
              "Hidden from Learners but visible to Staff roles with access to the cohort."
            ),
            descVerbose: translator(
              "Only Administrators, Editors, Teachers, and Moderators with access to this Cohort can view the {{ feature }}.",
              tOptions
            )
          }
        case FEATURE_PERMISSION_EVERYONE:
          return {
            icon: "eye",
            title: translator("Members & Staff"),
            titleVerbose: translator("Visible to Members & Staff"),
            titleAbbr: translator("Everyone"),
            desc: translator("Visible to everyone with access to the cohort."),
            descVerbose: "" // TODO
          }
        default:
          return {
            title: translator("Coming soon"),
            desc: ""
          }
      }
  }
}
