import React from "react"
import styled from "styled-components"
import RangedControl from "./RangedControl"

const AudioProgressUI = styled.div`
  width: 100%;
  max-width: 375px;
  margin: 0 auto;
`

const AudioProgress = (props) => {
  const { progress, onSeek, onStartScrub, onStopScrub } = props

  if (!progress && progress !== 0) return <AudioProgressUI empty={true} />

  return (
    <AudioProgressUI>
      <RangedControl
        value={progress}
        onSeek={onSeek}
        updateOnScrub={true} // Possible performance bottleneck
        onStartScrub={onStartScrub}
        onStopScrub={onStopScrub}
      />
    </AudioProgressUI>
  )
}

export default AudioProgress
