import moment from "moment"
import styled from "styled-components"
import { dueTimeStatus } from "../../../completion/utils"

const DueStatusContainer = styled.span`
  display: inline-block;
  /* padding: 2px 5px; */
  border-radius: 3px;
  /* font-size: 11px; */
  color: ${(p) => p.color};
  /* background-color: ${(props) => props.color}; */
`

const getDueColor = (item) => {
  const {
    data: {
      completion: { completion_date },
      due,
      passive_due
    }
  } = item

  if (!due) {
    return ""
  }

  const late = completion_date
    ? moment(completion_date).isAfter(due)
    : moment().isAfter(due)

  const green = `rgba(52, 199, 89, 1)`
  const red = `rgba(255, 59, 48, 1)`
  const grey = "inherit"

  return late ? (passive_due ? grey : red) : green
}

export const DueStatus = ({ item }) => {
  const {
    data: {
      completion: { completion_date },
      due
    }
  } = item

  if (!due) return null

  const color = getDueColor(item)
  return (
    <DueStatusContainer color={color}>
      {dueTimeStatus(completion_date, due)}
    </DueStatusContainer>
  )
}

export default DueStatus
