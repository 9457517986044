import SelectButton from "@pathwright/ui/src/components/button/SelectButton"
import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import SelectRole from "@pathwright/web/src/modules/invitation/SelectRole"
import { filterAvailableRoles } from "@pathwright/web/src/modules/invitation/utils"
import { observer } from "mobx-react"
import pluralize from "pluralize"
import PropTypes from "prop-types"
import styled from "styled-components"
import inviteStore from "../../store/invite-store"
import "./InviteFormFooter.css"
import InviteFormMessage from "./InviteFormMessage"

const StyledPrompt = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`

// huh.
const getIndefiniteArticle = (noun) => {
  return ["a", "e", "i", "o", "u"].indexOf(String(noun)[0]) > -1 ? "an" : "a"
}

const InviteFormFooter = (props, context) => {
  const { count, sending, valid } = inviteStore
  const submitButtonProps = {
    brand: true,
    styleType: "primary",
    submitting: sending,
    disabled: !valid
  }
  return (
    <footer className="InviteFormFooter">
      <InviteFormMessage />
      <div className="InviteFormFooter__actions">
        {!sending && (
          <SelectRole
            position="top"
            onChange={inviteStore.action.changeRole}
            value={inviteStore.role}
            roleType={inviteStore.type}
            roleFilter={(type, role) =>
              filterAvailableRoles(
                type,
                role,
                inviteStore.context && inviteStore.context.offering
              )
            }
            prompt={(role) => (
              <StyledPrompt>
                <span>Invite {pluralize("people", count, !!count)} to </span>
                <strong>
                  join as {getIndefiniteArticle(role.label)} {role.label}
                </strong>
              </StyledPrompt>
            )}
          />
        )}
        {context.me.permissions.can_administrate ? (
          <SelectButton
            {...submitButtonProps}
            options={[
              {
                label: `From you (${context.me.full_name})`,
                action: () => {
                  inviteStore.action.changeSendFromSchool(false)
                  // letting submit handler handle promise
                  return inviteStore.action.sendInvites()
                },
                selected: !inviteStore.send_from_school
              },
              {
                label: `From ${context.school.name}`,
                action: () => {
                  inviteStore.action.changeSendFromSchool(true)
                  // letting submit handler handle promise
                  return inviteStore.action.sendInvites()
                },
                selected: inviteStore.send_from_school
              }
            ]}
          >
            Send {pluralize("Invite", count)}
          </SelectButton>
        ) : (
          <SubmitButton
            {...submitButtonProps}
            onSubmit={() => inviteStore.action.sendInvites()}
          >
            Send {pluralize("Invite", count)}
          </SubmitButton>
        )}
      </div>
    </footer>
  )
}

InviteFormFooter.contextTypes = {
  me: PropTypes.object,
  school: PropTypes.object
}

export default observer(InviteFormFooter)
