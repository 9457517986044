import includes from "lodash/includes"
import isArray from "lodash/isArray"
import isObject from "lodash/isObject"
import isString from "lodash/isString"
import memoize from "lodash/memoize"
import seedrandom from "seedrandom"

export const PRIMARY_GRAY = "rgba(42,46,51,1)"
export const SECONDARY_GRAY = "rgba(0,0,0,0.6)"
export const TERTIARY_GRAY = "rgba(0,0,0,0.4)"
export const BORDER_GRAY = "rgba(0,0,0,0.2)"
export const SHADOW_GRAY = "rgba(0,0,0,0.15)"
export const OVERLAY_GRAY = "rgba(0,0,0,0.05)"

export const PRIMARY_WHITE = "rgba(255,255,255,1)"
export const SECONDARY_WHITE = "rgba(255,255,255,0.8)"
export const TERTIARY_WHITE = "rgba(255,255,255,0.6)"
export const BORDER_WHITE = "rgba(255,255,255,0.3)"
export const OVERLAY_WHITE = "rgba(255,255,255,0.1)"

export const PRIMARY_WHITE_BACKGROUND = "rgba(255,255,255,1)"
export const SECONDARY_WHITE_BACKGROUND = "rgba(245,245,245,1)"
export const TERTIARY_WHITE_BACKGROUND = "rgba(234,234,234,1)"

export const FOCUS_BLUE = "rgba(106,159,203,1)"
export const EDITED_ORANGE = "rgb(206 150 101);"
export const ALERT_YELLOW = "rgba(255,252,230,1)"
export const WARNING_ORANGE = "rgba(255,133,27,1)"
export const DANGEROUS_RED = "rgba(255,0,0,1)"
export const SUCCESS_GREEN = "rgba(34,139,34,1)"

const SHORT_HAND_HEX_RE = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
const HEX_RE = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i
const HEX_INITIAL_HASHTAG_RE = /^#*/
const NAMED_COLOR_RE = /^[a-z]+$/i
const RGBA_FRAGMENT_RE = /[^\d,.]/g

const _removeWhitespace = (str) => (str ? str.replace(/\s/g, "") : str)

export const getPrimaryColor = function (theme) {
  if (theme == null) {
    return "#0d71fb"
  }
  if (theme.primary_color) {
    return formatHex(theme.primary_color)
  } else if (theme.primaryBrandColor) {
    return formatHex(theme.primaryBrandColor)
  } else {
    return "#0d71fb" // #0d71fb
  }
}

// "0,0,0,0" -> {r:0,g:0,b:0,a:0}
export const objectifyRgba = function (rgba) {
  if (!rgba || !rgba.length) {
    return null
  }
  let [r, g, b, a] = parseRgba(rgba).replace(RGBA_FRAGMENT_RE, "").split(",")

  // In some cases, the parsed rgba value will only be rgb, lacking
  // an alpha value. We can safely assume the alpha should be 1.
  if (typeof a === "undefined") {
    a = 1
  }

  return { r, g, b, a }
}

// {r:0,g:0,b:0,a:0} ||  -> "0,0,0,0"
export const stringifyRgba = function (rgba, keepAlpha = false) {
  rgba = typeof rgba === "string" ? objectifyRgba(rgba) : rgba

  if (rgba) {
    const val = rgba.r,
      r = val != null ? val : 0,
      val1 = rgba.g,
      g = val1 != null ? val1 : 0,
      val2 = rgba.b,
      b = val2 != null ? val2 : 0,
      val3 = rgba.a,
      a = val3 != null ? val3 : 1

    if (a === 1 && !keepAlpha) return `${r},${g},${b}`
    return `${r},${g},${b},${a}`
  } else {
    return null
  }
}

// {r:0,g:0,b:0,a:0} || [0,0,0,0] || "0,0,0,0" || #000 || "rgba(0,0,0,0)" || "green" -> "rgba(0,0,0,0)"
export const parseRgba = memoize((color) => {
  if (!color) {
    return
  } else if (isString(color)) {
    if (NAMED_COLOR_RE.test(color)) {
      return parseRgba(namedColorToRgb(color))
    }
    if (includes(formatHex(color), "#")) {
      return parseRgba(hexToRgba(color))
    } else if (includes(color, "rgb")) {
      return _removeWhitespace(color)
    } else {
      return _removeWhitespace(`rgba(${color})`)
    }
  } else if (isArray(color)) {
    return _removeWhitespace(`rgba(${color.join(",")})`)
  } else if (isObject(color)) {
    return _removeWhitespace(
      `rgba(${stringifyRgba(color, true /* keepAlpha */)})`
    )
  }
})

// from http://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
export const hexToRgba = function (hex, a) {
  if (a == null) {
    a = 1
  }
  const result = HEX_RE.exec(formatHex(hex))
  return {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
    a
  }
}

export const isHex = (str) => /^[0-9A-F]{6}$/i.test(cleanHex(str))

export const formatHex = (hex) => {
  const longHex = hex.replace(
    SHORT_HAND_HEX_RE,
    (m, r, g, b) => r + r + g + g + b + b
  )
  if (HEX_RE.test(longHex)) {
    // ensure "#"
    return `#${longHex}`.replace(HEX_INITIAL_HASHTAG_RE, "#")
  }
  return null
}

export const cleanHex = (hex) =>
  hex ? hex.replace(HEX_INITIAL_HASHTAG_RE, "") : ""

// https://stackoverflow.com/a/26500040/6362803
// returns [r, g, b, a]
export const namedColorToRgb = (color) => {
  const canvas = document.createElement("canvas")
  canvas.width = 1
  canvas.height = 1

  const context = canvas.getContext("2d")
  // context will be undefined in test env, even when mocked by jest-canvas-mock.
  if (context) {
    context.fillStyle = color
    context.fillRect(0, 0, 1, 1)
    const [r, g, b, a] = context.getImageData(0, 0, 1, 1).data
    return [r, g, b, 1]
  }

  return [0, 0, 0, 1]
}

export const getAlphaColor = (color, alpha) =>
  parseRgba({
    ...objectifyRgba(parseRgba(color)),
    a: alpha
  })

export const getSeedRandomColor = (seed) => {
  const random = seedrandom(seed)
  // Math.pow(15, 6) instead of Math.pow(16, 6)
  // attempting to avoid white colors which are the highest values
  let color = `#${Math.floor(random() * Math.pow(15, 6)).toString(16)}` // magic
  // A few colors are invalid with only 5 characters instead of 6
  // This is the case when using Math.pow(16, 6) as well
  return `${color}666`.slice(0, 7)
}
