import gql from "graphql-tag"

export default gql`
  mutation ($reset_hash: String!, $password1: String!, $password2: String!) {
    resetPassword(
      reset_hash: $reset_hash
      password1: $password1
      password2: $password2
    )
  }
`
