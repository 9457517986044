import Button from "@pathwright/ui/src/components/button/Button"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import ChakraTooltip from "@pathwright/ui/src/components/tooltip/ChakraTooltip"
import styled from "styled-components"
import { useReviewContext } from "../../state/ReviewContextProvider"
import {
  usePanelQueryParm,
  useUserAskedToRespondNotificationsForItem
} from "../../utils"

export function getResponseCountStr(t, responseCount) {
  return t("{{count}} response", {
    defaultValue_plural: "{{count}} responses",
    count: responseCount
  })
}

export function getShareFeedbackStr(notification) {
  return `${notification.meta.user} asked you to share feedback.`
}

const StyledButton = styled(Button)`
  .CountIndicator {
    right: -0.35em !important;
  }
`

function FeedbackResponses({ item }) {
  const { completion } = item.data
  const { t } = useTranslate()
  const {
    actions: { select, getItem }
  } = useReviewContext()
  const { notifications } = useUserAskedToRespondNotificationsForItem(item)
  const notification = notifications[0]

  const setSelectedPanel = usePanelQueryParm("ui_panel")

  const totlaResponses =
    completion.feedback_discussion_context?.num_responses || 0

  const tooltip = notification
    ? getShareFeedbackStr(notification)
    : getResponseCountStr(t, totlaResponses)

  return totlaResponses || notification ? (
    <span>
      <ChakraTooltip title={tooltip}>
        <StyledButton
          styleType="inline"
          onClick={(e) => {
            e.stopPropagation()
            setSelectedPanel("discuss")
            select(getItem(completion.id))
          }}
          icon="chat-bubbles"
          label={totlaResponses || null}
          size="large"
          indicator={!!notification}
        />
      </ChakraTooltip>
    </span>
  ) : null
}

export default FeedbackResponses
