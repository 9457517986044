import {
  BORDER_GRAY,
  FOCUS_BLUE
} from "@pathwright/ui/src/components/utils/colors"
import Downshift from "downshift"
import PropTypes from "prop-types"
import { Component } from "react"
import styled from "styled-components"
import LookupMenu from "./LookupMenu"

const Input = styled.input`
  width: 100%;
  padding: 5px;
  font-size: 16px;
  line-height: 25px;
  border-radius: 4px;
  border: 1px solid ${BORDER_GRAY};
  outline: none;
  resize: none;
  &:focus {
    border-color: ${FOCUS_BLUE};
  }
  &:not(:last-child) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-color: transparent;
  }
`

// precent reseting the Downshift input value when input is blurred
// or clicked out of
// https://codesandbox.io/s/github/kentcdodds/downshift-examples/tree/master/?module=/src/ordered-examples/03-typeahead.js&moduleview=1&file=/src/downshift/other-examples/prevent-reset-on-blur.js:181-340
function preventResetValue(state, changes) {
  switch (changes.type) {
    case Downshift.stateChangeTypes.blurInput:
    case Downshift.stateChangeTypes.mouseUp:
      return {} // no-changes
    default:
      return changes
  }
}

class Lookup extends Component {
  render() {
    const {
      style,
      onChange,
      onSelect,
      className,
      emailOnly,
      inputValue,
      placeholder,
      onInputValueChange
    } = this.props

    return (
      <Downshift
        inputValue={inputValue}
        onChange={onChange}
        onInputValueChange={onInputValueChange}
        onSelect={onSelect}
        stateReducer={preventResetValue}
        itemToString={(item) => {
          if (item) {
            if (item.type === "email") {
              return item.email
            }
            if (item.type === "user") {
              return item.user.display_name
            }
          }
          return ""
        }}
      >
        {({
          inputValue,
          getInputProps,
          getItemProps,
          getRootProps,
          getMenuProps,
          selectedItem,
          highlightedIndex,
          isOpen,
          selectItem
        }) => (
          <div
            className={className}
            style={{ position: "relative", ...style }}
            {...getRootProps({}, { suppressRefError: true })}
          >
            <Input
              {...getInputProps()}
              placeholder={placeholder}
              minRows={1}
              maxRows={1}
              autoFocus
            />
            <LookupMenu
              isOpen={isOpen}
              emailOnly={emailOnly}
              inputValue={inputValue}
              getItemProps={getItemProps}
              getMenuProps={getMenuProps}
              selectedItem={selectedItem}
              highlightedIndex={highlightedIndex}
            />
          </div>
        )}
      </Downshift>
    )
  }
}

Lookup.displayName = "Lookup"

Lookup.propTypes = {
  // what should the user enter?
  placeholder: PropTypes.string.isRequired,
  // should lookup show members or just email
  emailOnly: PropTypes.bool.isRequired,
  // callback when user selects member or email from results
  onSelect: PropTypes.func.isRequired
}

Lookup.defaultProps = {
  placeholder: "Enter email or search members...",
  emailOnly: false,
  onSelect: () => {}
}

export default Lookup
