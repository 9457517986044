const CONFIG_QUERY = `
query {
  codex {
    config {
      account {
        id
        name
      }
      user {
        id
        displayName
        image
      }
      firebase {
        apiKey
        databaseURL
        projectId
        userToken
      }
    }
  }
}
`

const SPACE_QUERY = `
query CodexSpace($key:String!) {
codex {
  space(key: $key, join: true) {
    id
    nodes(last_x_days: 1000) {
      id
      type
      userId
      graphId
      rootId
      parentId
      fromId
      toId
      key
      data
      created
      updated
    }
  }
}
}
`

export const GQL =
  (url, headers) =>
  async (query, variables = {}) => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...headers
      },
      body: JSON.stringify({
        query,
        variables
      })
    }
    const result = await fetch(url, options)

    return result.json()
  }

class CodexAPI {
  config
  gql

  constructor(config) {
    this.config = config
    this.gql = GQL(config.url, config.headers)
  }

  async init() {
    const result = await this.gql(CONFIG_QUERY)
    return result.data.codex.config
  }

  async auth(userToken) {
    return this.config.firebase.auth().signInWithCustomToken(userToken)
  }

  async load(id, join) {
    const result = await this.gql(SPACE_QUERY, { key: id, join })
    const space = result.data.codex.space
    return space
  }
}

export default CodexAPI
