import zipObject from "lodash/zipObject"

// takes a const map in the form of { KEY: [int, str] }
// and reduces to { KEY: { int, str } }
export const getConstMap = (constMap) => {
  const map = Object.entries(constMap).reduce(
    (map, [key, [int, str]]) => ({
      ...map,
      [key]: {
        int,
        str
      }
    }),
    {}
  )

  const ints = Object.entries(constMap).reduce(
    (ints, [key, [int, str]]) => ({
      ...ints,
      [key]: int
    }),
    {}
  )

  const strs = Object.entries(constMap).reduce(
    (strs, [key, [int, str]]) => ({
      ...strs,
      [key]: str
    }),
    {}
  )

  // safety measure for when we are unsure as to whether we're dealing
  // with an int or a string (use like: value === CONST_THING._for(value).KEY_THING)
  map._for = (value) => {
    switch (typeof value) {
      case "number":
        return ints
      case "string":
        return strs
    }
  }

  map._getKey = (value) => {
    const [key] = Object.entries(map._for(value)).find(([k, v]) => value === v)
    return key
  }

  map._ints = Object.values(ints)
  map._strs = Object.values(strs)
  map._intsStrsMap = zipObject(map._ints, map._strs)
  map._strsIntsMap = zipObject(map._strs, map._ints)

  return map
}
