import { ServerBlockTypeType } from "@pathwright/blocks-core"
import * as types from "../types"
import { ESPTypes } from "./EditorStateProvider"
import * as actions from "./actions"
import * as c from "./constants"

export const defaultState: EditorStateType = {
  mode: c.EDITOR_MODE.EDIT,
  blocks: [],
  deleteBlockID: undefined,
  lastModified: undefined,
  lastPublished: undefined,
  syncing: false,
  publishing: false,
  discarding: false,
  error: undefined,
  copiedBlockClip: undefined,
  copiedBlock: undefined,
  pastingBlockIndex: undefined,
  blockTypes: undefined,
  contentID: undefined
}

export type EditorStateType = {
  mode?: "edit" | "view"
  blocks: types.BlockType[]
  deleteBlockID?: string
  lastModified?: number
  lastPublished?: number
  syncing: boolean
  publishing: boolean
  discarding: boolean
  error?: Error
  copiedBlockClip?: types.CopiedBlockClipType
  copiedBlock?: types.BlockType
  pastingBlockIndex?: number
  blockTypes?: ServerBlockTypeType[]
  contentID?: string
}

export type EditorReducerActionType =
  | {
      type: "GET_STATE"
      payload?: any
    }
  | {
      type: "START_SYNCING"
      payload?: any
    }
  | {
      type: "STOP_SYNCING"
      payload?: any
    }
  | {
      type: "ERROR"
      payload: { error: Error }
    }
  | {
      type: "ADD_BLOCK"
      payload: {
        type: string
        order?: number
        data: types.BlockDataType
        layout: any
        style?: any
        id: string
      }
    }
  | {
      type: "UPDATE_BLOCK_DATA"
      payload: {
        id: string
        block: Partial<types.BlockType>
      }
    }
  | {
      type: "UPDATE_BLOCK_LAYOUT"
      payload: {
        id: string
        layout: string
        blockTypes: ServerBlockTypeType[]
      }
    }
  | {
      type: "UPDATE_BLOCK_STYLE"
      payload: {
        id: string
        style: types.BlockStyleType
      }
    }
  | {
      type: "CLEAR_DELETE_BLOCK"
      payload?: any
    }
  | {
      type: "DELETE_BLOCK"
      payload: {
        id: string
      }
    }
  | {
      type: "MOVE_BLOCK_UP"
      payload: {
        oldIndex: number
        id: string
      }
    }
  | {
      type: "MOVE_BLOCK_DOWN"
      payload: {
        oldIndex: number
        id: string
      }
    }
  | {
      type: "SET_COPIED_BLOCK"
      payload: {
        copiedBlockClip: types.CopiedBlockClipType
      }
    }
  | {
      type: "CLEAR_COPIED_BLOCK"
      payload?: any
    }
  | {
      type: "PASTE_BLOCK"
      payload: {
        index: number
      }
    }
  | {
      type: "SYNCED_PASTE_BLOCK"
      payload: {
        copiedBlock: types.BlockType
      }
    }
  | {
      type: "SYNCED_ADD_BLOCK"
      payload: types.BlockType
    }
  | {
      type: "SYNCED_UPDATE_BLOCK"
      payload: types.BlockType
    }
  | {
      type: "SYNCED_BLOCKS"
      payload: {
        blocks: types.BlockType[]
        lastModified: number
        lastPublished: number
      }
    }
  | {
      type: "TOGGLE_MODE"
      payload?: any
    }
  | {
      type: "START_PUBLISH"
      payload?: any
    }
  | {
      type: "SYNCED_PUBLISH"
      payload?: any
    }
  | {
      type: "START_DISCARD_DRAFT"
      payload?: any
    }
  | {
      type: "SYNCED_DISCARD_DRAFT"
      payload?: any
    }
  | {
      type: "RESET"
      payload: {
        content: types.BlocksContentType
        blockTypes: ServerBlockTypeType[]
      }
    }

export const getInitialState = (
  props: Partial<ESPTypes.Props>
): EditorStateType => ({
  ...defaultState,
  blocks: props.content ? [...props.content.blocks] : [],
  lastModified: props.content?.lastModifiedDateTime || 0,
  lastPublished: props.content?.lastPublishedDateTime || 0,
  contentID: props.content?.id,
  blockTypes: props.blockTypes
})

export const reducer = (
  state: EditorStateType,
  action: EditorReducerActionType
): EditorStateType => {
  const { type, payload } = action

  switch (type) {
    case c.GET_STATE:
      return { ...state }
    case c.START_SYNCING:
      return { ...state, ...actions.startSyncingAction()() }
    case c.STOP_SYNCING:
      return { ...state, ...actions.stopSyncingAction()() }
    case c.ERROR:
      return { ...state, ...actions.errorAction(payload)() }
    case c.ADD_BLOCK:
      return { ...state, ...actions.addBlockAction(payload)(state) }
    case c.UPDATE_BLOCK_DATA:
      return { ...state, ...actions.updateBlockAction(payload)(state) }
    case c.UPDATE_BLOCK_LAYOUT:
      return { ...state, ...actions.updateBlockLayoutAction(payload)(state) }
    case c.UPDATE_BLOCK_STYLE:
      return { ...state, ...actions.updateBlockStyleAction(payload)(state) }
    case c.CLEAR_DELETE_BLOCK:
      return { ...state, ...actions.clearDeleteBlockAction()() }
    case c.DELETE_BLOCK:
      return { ...state, ...actions.deleteBlockAction(payload)(state) }
    case c.MOVE_BLOCK_UP:
      return { ...state, ...actions.moveBlockUpAction(payload)(state) }
    case c.MOVE_BLOCK_DOWN:
      return { ...state, ...actions.moveBlockDownAction(payload)(state) }
    case c.SET_COPIED_BLOCK:
      return { ...state, ...actions.setCopiedBlockAction(payload)() }
    case c.CLEAR_COPIED_BLOCK:
      return { ...state, ...actions.clearCopiedBlockAction()() }
    case c.PASTE_BLOCK:
      return { ...state, ...actions.pasteBlockAction(payload)() }
    case c.SYNCED_PASTE_BLOCK:
      return { ...state, ...actions.syncedPasteBlockAction(payload)(state) }
    case c.SYNCED_ADD_BLOCK:
      return { ...state, ...actions.syncedAddBlockAction(payload)(state) }
    case c.SYNCED_UPDATE_BLOCK:
      return { ...state, ...actions.syncedUpdateBlockAction(payload)(state) }
    case c.SYNCED_BLOCKS:
      return { ...state, ...actions.syncedBlocksAction(payload)() }
    case c.TOGGLE_MODE:
      return { ...state, ...actions.setModeAction()(state) }
    case c.START_PUBLISH:
      return { ...state, ...actions.startPublishAction()() }
    case c.SYNCED_PUBLISH:
      return { ...state, ...actions.syncedPublishAction(payload)() }
    case c.START_DISCARD_DRAFT:
      return { ...state, ...actions.startDiscardDraftAction()() }
    case c.SYNCED_DISCARD_DRAFT:
      return { ...state, ...actions.syncedDiscardDraftAction(payload)() }
    case c.RESET:
      return { ...state, ...getInitialState(payload) }
    default:
      // require named actions
      throw new Error(`Unknown action type in Editor: ${action}`)
  }
}
