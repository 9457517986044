import Button from "@pathwright/ui/src/components/button/Button"
import copyToClipboard from "@pathwright/ui/src/components/copy/copyToClipboard"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import Success from "@pathwright/ui/src/components/success/Success"
import Text from "@pathwright/ui/src/components/ui/Text"
import styled from "styled-components"

const SuccessFooter = ({ onDone }) => (
  <>
    <Text.Body inverted>
      To review all discount codes & track usage, visit{" "}
      <Button styleType="text" brand inverted to="/manage/school/reports/">
        Account Settings
        <Pathicon icon="chevron-right" style={{ fontSize: "14px" }} />
        Download Reports
      </Button>
      .
    </Text.Body>
    <Button
      style={{ marginTop: "50px" }}
      styleType="secondary"
      inverted
      onClick={() => onDone()}
    >
      Done
    </Button>
  </>
)

export const DiscountsSetSuccess = ({ downloader, onDone }) => (
  <Success
    icon="check"
    heading="Code Set Created"
    body="Your codes are ready to share."
    primaryAction={{
      children: "Download Codes as CSV",
      onClick: downloader,
      size: "large"
    }}
    onBackgroundClick={onDone}
  >
    <SuccessFooter onDone={onDone} />
  </Success>
)

DiscountsSetSuccess.displayName = "DiscountsSetSuccess"

// Forcing SelectButton to truncate. Not sure how else to achieve this.
const StyledDiscountsSingleSuccess = styled(Success)`
  .SelectButton .OverlayTrigger .Button {
    max-width: 220px;
  }
`

export const DiscountsSingleSuccess = ({ code, onDone }) => (
  <StyledDiscountsSingleSuccess
    icon="check"
    heading="Code Created"
    body="It's ready to share."
    primaryAction={{
      label: code,
      inverted: true,
      truncate: true,
      action: <Pathicon icon="copy" />,
      size: "large",
      options: [
        {
          selected: true,
          action: () => Promise.resolve(copyToClipboard(code))
        }
      ]
    }}
    // onBackgroundClick={onDone} // don't close on background click as that could be accidental and there's no way to download the codes after that
  >
    <SuccessFooter onDone={onDone} />
  </StyledDiscountsSingleSuccess>
)

DiscountsSingleSuccess.displayName = "DiscountsSingleSuccess"
