import { jwtDecode } from "jwt-decode"
import { useMemo } from "react"
import { StringParam, useQueryParam } from "use-query-params"

const useCertificatePreviewParam = () => {
  const [preview, setPreview] = useQueryParam("preview", StringParam)

  const previewData = useMemo(() => {
    if (preview) {
      try {
        const data = jwtDecode(preview)
        // NOTE: any variables have already been interpolated into the text objects
        // at this point.
        return {
          template: data.pdf_template_url,
          textObjects: data.text_objects
        }
      } catch {
        /* noop */
      }
    }
    return null
  }, [preview])

  return [previewData, setPreview]
}

export default useCertificatePreviewParam
