import PropTypes from "prop-types"
import styled from "styled-components"
import Avatar from "../avatar/Avatar"
import { DropGateSticker } from "../drop/DropGate"
import Link from "../link/Link"
import Pathicon from "../pathicon/Pathicon"
import Tooltip from "../tooltip/Tooltip"
import Text from "../ui/Text"
import { DANGEROUS_RED, PRIMARY_GRAY, TERTIARY_GRAY } from "../utils/colors"
import CardBlock from "./CardBlock"

const getColor = (defaultColor) => (p) =>
  p.dangerous ? DANGEROUS_RED : defaultColor

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  font-size: 16px;
  text-decoration: none;
  white-space: nowrap;
  min-width: 1px;
  padding-top: 5px;
  padding-bottom: 5px;
  ${(props) =>
    props.disabled
      ? `
      pointer-events: none;
      opacity: 0.5;
    `
      : `
      &, label {
        cursor: pointer;
      }
    `}
`

const StyledLink = styled(Link)`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
`

const StyledTooltip = styled(Tooltip)`
  width: 100%;
  height: 100%;
`

const StyledA = styled.a`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
`

const StyledAvatar = styled(Avatar)`
  margin-right: 10px;
`

const StyledIcon = styled(Pathicon)`
  font-size: 18px;
  margin-right: 10px;
  flex-shrink: 0;
  color: ${getColor(TERTIARY_GRAY)};
`

const StyledLinkIcon = styled(Pathicon)`
  color: ${getColor(TERTIARY_GRAY)};
`

const StyledIconDraggable = styled(StyledIcon)`
  color: ${getColor(TERTIARY_GRAY)};
  padding-top: 5px;
  padding-bottom: 5px;
  position: relative;
  z-index: 1;
  cursor: move;
`

const StyledImg = styled.img`
  width: 25px;
  height: 25px;
  border-radius: 15px;
  margin-right: 10px;
`

const StyledContent = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin-bottom: 0;
  color: ${getColor(PRIMARY_GRAY)};
  overflow: hidden;

  .DropGateSticker {
    margin-right: 10px;
  }
`

const StyledLabel = styled.label`
  margin: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  & > * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.2em !important;
  }
`

const StyledPreview = styled.div`
  display: flex;
  flex-shrink: 0;
  overflow: hidden;
  padding: 0 10px;
  color: ${getColor(TERTIARY_GRAY)};
  text-overflow: ellipsis;
`

const CardLink = (props) => {
  const {
    to,
    icon,
    label,
    image,
    user,
    meta,
    href,
    onClick,
    download,
    preview,
    disabled,
    children,
    borderTop,
    borderBottom,
    className,
    dragHandleProps,
    tooltip,
    dangerous,
    seasonDrop,
    linkIcon: upstreamLinkIcon
  } = props

  const linkIcon = upstreamLinkIcon
    ? upstreamLinkIcon
    : href
    ? "external-link"
    : download
    ? icon === "download"
      ? "external-link"
      : "download"
    : to
    ? "chevron-right"
    : ""

  return (
    <CardBlock
      className={className}
      borderTop={borderTop}
      borderBottom={borderBottom}
    >
      <StyledTooltip title={disabled && tooltip}>
        <StyledWrapper disabled={disabled} onClick={onClick}>
          <StyledContent>
            {dragHandleProps ? (
              <span {...dragHandleProps}>
                <StyledIconDraggable
                  icon={icon || "arrow-double-vertical"}
                  dangerous={dangerous}
                />
              </span>
            ) : null}
            {user ? (
              <StyledAvatar user={user} size="25px" />
            ) : image ? (
              <StyledImg alt="CardLink__img" src={image} />
            ) : icon ? (
              <StyledIcon icon={icon} dangerous={dangerous} />
            ) : seasonDrop ? (
              <DropGateSticker size={30} />
            ) : null}
            {children ? (
              children
            ) : label ? (
              <StyledLabel>
                <Text.H5 color={dangerous && DANGEROUS_RED}>{label}</Text.H5>
                {meta && (
                  <Text.Meta color={dangerous && DANGEROUS_RED}>
                    {meta}
                  </Text.Meta>
                )}
              </StyledLabel>
            ) : null}
          </StyledContent>
          <StyledPreview dangerous={dangerous}>{preview}</StyledPreview>
          {linkIcon && <StyledLinkIcon icon={linkIcon} dangerous={dangerous} />}
        </StyledWrapper>
      </StyledTooltip>
      {!disabled && to && <StyledLink to={to} onClick={onClick} />}
      {!disabled && href && (
        <StyledA
          href={href}
          onClick={onClick}
          download={download}
          target="_blank"
        />
      )}
    </CardBlock>
  )
}

CardLink.displayName = "CardLink"

CardLink.propTypes = {
  // The route to link to
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  // Name action for next Card
  label: PropTypes.node,
  // Set icon to represent action/Card
  icon: PropTypes.string,
  // Set image instead of icon to represent action/Card
  image: PropTypes.string,
  // Set preview text for next Card
  preview: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node
  ]),
  // Optionally connect drag ability
  connectDragSource: PropTypes.func,
  disabled: PropTypes.bool,
  // TODO: allow for selecting specific season drop (defaulted to S1 for now)
  seasonDrop: PropTypes.bool
}

CardLink.defaultProps = {
  disabled: false
}

export default CardLink
