import AlertBannerSpacer from "@pathwright/ui/src/components/alert/AlertBannerSpacer"
import {
  FULLSCREEN_LAYER_BASE,
  FULLSCREEN_LAYER_OVERLAY
} from "@pathwright/ui/src/components/fullscreen/Fullscreen"
import styled from "styled-components"

// Layering the Inbox layout no higher than the fullscreen overlay
// which allows the "Add to path" success view to be visible.
// Previously we added 10, but this hides the said success view.
// Doesn't appear that the new zIndex cuases any issues.
const zIndex = FULLSCREEN_LAYER_BASE + FULLSCREEN_LAYER_OVERLAY + 0

const InboxLayoutContainer = styled(AlertBannerSpacer)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  color: #000;
  position: relative;
  z-index: ${zIndex};
  background-color: black;
`

export default InboxLayoutContainer
