import CardStack from "@pathwright/ui/src/components/card/CardStack"
import styled from "styled-components"
import ProfileCard from "../../profile/ProfileCard"
const PersonaCardWrap = styled.div`
  .ProfileCard__tab_content {
    overflow: hidden;
    overflow-y: scroll;
  }

  .ProfileCard {
    max-width: 100%;
    min-width: 100%;
    /* max-height: 600px; */
    background-color: transparent;
    position: relative;

    > header {
      background-color: white;
    }

    .BackgroundImage {
      /* display: none; */
    }

    .ProfileActivity {
      background-color: rgba(245, 245, 245, 1);
      * {
        color: #000 !important;
      }

      .StepActivity {
        background-color: #fff;
      }
    }

    /* .CompletionsList__header {
      display: none;
    } */
  }
`

const PersonaCard = ({ node, onClose, selectedTab }) => {
  return (
    <PersonaCardWrap>
      <CardStack
        card={{
          component: ProfileCard,
          props: {
            key: node.id,
            userId: parseInt(node.id),
            tabKey: selectedTab
          }
        }}
        onCloseCardStack={onClose}
      />
    </PersonaCardWrap>
  )
}

export default PersonaCard
