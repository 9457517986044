import { useMutation } from "@apollo/client"
import { VStack } from "@chakra-ui/react"
import Alert from "@pathwright/ui/src/components/alert/Alert"
import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import Button from "@pathwright/ui/src/components/button/Button"
import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import Card from "@pathwright/ui/src/components/card/Card"
import CardBlock from "@pathwright/ui/src/components/card/CardBlock"
import CardHeader from "@pathwright/ui/src/components/card/CardHeader"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import Success from "@pathwright/ui/src/components/success/Success"
import useSpaceHasFeature from "@pathwright/web/src/modules/lib/useSpaceHasFeature"
import get from "lodash/get"
import PropTypes from "prop-types"
import styled from "styled-components"
import AdaptedAuthContainer from "../auth/AdaptedAuthContainer"
import AdaptedSignedInContainer from "../auth/AdaptedSignedInContainer"
import SignUpOrIn from "../auth/SignUpOrIn"
import SignedIn from "../auth/SignedIn"
import { MENTOR_GROUP_MEMBERSHIP_ROLE_MEMBER } from "../mentor-group/constants"
import { getCommunityGroupRoleLabel } from "../mentor-group/utils"
import { usePathwrightContext } from "../pathwright/PathwrightContext"
import useRestAPIGetQuery from "../rest-api/graphql/useRestAPIGetQuery"
import { useUserCommunityContext } from "../user/UserCommunityContext"
import ACCEPT_COMMUNITY_GROUP_INVITATION from "./graphql/accept-community-group-invitation-mutation"

// TODO: figure out text if group is no longer active

export default function AcceptCommunityGroupInvitationCard({
  card,
  inviteToken
}) {
  const { me, school } = usePathwrightContext()
  const { t } = useTranslate()
  const {
    communityQuery,
    communityGroupMemberships,
    communityGroupSubscriptions,
    communityGroupWithActiveSubscription,
    activeCommunityGroupSubscription
  } = useUserCommunityContext()
  const hasAuthPw3 = useSpaceHasFeature("auth__platform")

  const [mutate, acceptMutation] = useMutation(
    ACCEPT_COMMUNITY_GROUP_INVITATION
  )

  const invitationQuery = useRestAPIGetQuery(
    `/invitation/${inviteToken}/?type=3`
  )
  const inviteData = get(invitationQuery, "data.restAPIGet")

  const { to_email, first_name, last_name } = get(inviteData, "extra", {})

  function acceptInvite(inviteToken) {
    return mutate({ variables: { token: inviteToken } }).then(() => {
      communityQuery.refetch()
      invitationQuery.refetch()
    })
  }

  let existingMembership = null
  if (inviteData) {
    existingMembership = communityGroupMemberships.find(
      (membership) => membership.group.id === inviteData.community_group.id
    )
  }

  return (
    <NarrowCard card={card} navless noaction>
      {invitationQuery.error || acceptMutation.error ? (
        <CardBlock>
          <Alert error={invitationQuery.error || acceptMutation.error} />
        </CardBlock>
      ) : me &&
        inviteData &&
        inviteData.community_group_membership &&
        inviteData.community_group_membership.user === me.id ? (
        <>
          {!communityGroupWithActiveSubscription && (
            <CardHeader
              card={card}
              title={t(`You joined “{{group_name}}”!`, {
                group_name: inviteData.community_group.name
              })}
              meta={t(`You’re now a {{role}} of this group.`, {
                role: getCommunityGroupRoleLabel(inviteData.role, t)
              })}
            />
          )}
          {communityGroupWithActiveSubscription ? (
            <Success
              icon="check"
              heading={t("You joined {{ groupName }}", {
                groupName: inviteData.community_group.name
              })}
              body={
                <>
                  {t(
                    "You now have access to all courses included in the {{ planName }}.",
                    {
                      planName:
                        activeCommunityGroupSubscription.price.product.name
                    }
                  )}
                  <br />
                  <br />
                  {t(
                    "If you'd like to change your membership in this group, please contact {{ userName }} at",
                    {
                      userName:
                        communityGroupWithActiveSubscription.owner.full_name
                    }
                  )}{" "}
                  <Button
                    href={`mailto:${communityGroupWithActiveSubscription.owner.email}`}
                    styleType="text"
                    inverted
                  >
                    {communityGroupWithActiveSubscription.owner.email}
                  </Button>
                  .
                </>
              }
              onBackgroundClick={card.onClose}
              primaryAction={{
                children: t("Start Learning"),
                to: "/home/learn/"
              }}
            ></Success>
          ) : communityGroupSubscriptions.length > 0 ? (
            <BlankSlate
              icon="subscription"
              body={
                <>
                  If you have any questions or want to change your membership in
                  this group, please contact the group admin{" "}
                  {
                    communityGroupSubscriptions[0].community_group.owner
                      .full_name
                  }{" "}
                  at{" "}
                  <a
                    href={`mailto:${communityGroupSubscriptions[0].community_group.owner.email}`}
                  >
                    {communityGroupSubscriptions[0].community_group.owner.email}
                  </a>
                </>
              }
            >
              <Button onClick={card.onClose} styleType="primary" brand>
                Done
              </Button>
            </BlankSlate>
          ) : acceptMutation.data ? (
            <Success
              heading={t("You joined “{{ groupName }}”!", {
                groupName: inviteData.community_group.name
              })}
              body={t("You’re now a {{ groupMembershipRole }} of this group.", {
                // TODO: translation per role, or nested t()
                groupMembershipRole: getCommunityGroupRoleLabel(
                  inviteData.role,
                  t
                )
              })}
              primaryAction={
                inviteData.role === MENTOR_GROUP_MEMBERSHIP_ROLE_MEMBER
                  ? {
                      label: t("Start Learning"),
                      to: "/home/learn/"
                    }
                  : {
                      label: t("Manage Members"),
                      to: "/home/people/"
                    }
              }
            />
          ) : (
            <BlankSlate
              primaryAction={
                inviteData.role === MENTOR_GROUP_MEMBERSHIP_ROLE_MEMBER
                  ? {
                      label: t("Start Learning"),
                      to: "/home/learn/"
                    }
                  : {
                      label: t("Manage Members"),
                      to: "/home/people/"
                    }
              }
            />
          )}
        </>
      ) : inviteData && existingMembership ? (
        <>
          <CardHeader
            card={card}
            title={t("{{ schoolName }} Invitation", {
              schoolName: school.name
            })}
            meta={t(
              "You're already a {{ groupMembershipRole }} of {{ groupName }}",
              {
                groupName: existingMembership.group.name,
                // TODO: translation per role, or nested t()
                groupMembershipRole: getCommunityGroupRoleLabel(
                  existingMembership.role,
                  t
                )
              }
            )}
          />
          <CardBlock>
            <SignedIn
              renderSubmit={() => (
                <SubmitButton
                  to={`/community/${existingMembership.group.id}/`}
                  styleType="primary"
                  brand
                >
                  {t("Go to group")} <Pathicon icon="chevron-right" />
                </SubmitButton>
              )}
            />
          </CardBlock>
        </>
      ) : inviteData && inviteData.community_group ? (
        <>
          <CardHeader
            card={card}
            title={t("{{ schoolName }} Invitation", {
              schoolName: school.name
            })}
            meta={t("Join the {{ groupName }} as a {{ groupMembershipRole }}", {
              groupName: inviteData.community_group.name,
              // TODO: translation per role, or nested t()
              groupMembershipRole: getCommunityGroupRoleLabel(
                inviteData.role,
                t
              )
            })}
          />
          <CardBlock>
            {!me ? (
              hasAuthPw3 ? (
                <AdaptedAuthContainer
                  userPropertyDefaults={{
                    email: to_email,
                    firstName: first_name,
                    lastName: last_name
                  }}
                  inviteToken={inviteToken}
                />
              ) : (
                <SignUpOrIn
                  providedEmail={to_email}
                  providedFirstName={first_name}
                  providedLastName={last_name}
                />
              )
            ) : hasAuthPw3 ? (
              <VStack w="100%">
                <AdaptedSignedInContainer />
                <SubmitButton
                  onSubmit={() => acceptInvite(inviteToken)}
                  styleType="primary"
                  brand
                >
                  {t("Join group")} <Pathicon icon="chevron-right" />
                </SubmitButton>
              </VStack>
            ) : (
              <SignedIn
                renderSubmit={() => (
                  <SubmitButton
                    onSubmit={() => acceptInvite(inviteToken)}
                    styleType="primary"
                    brand
                  >
                    {t("Join group")} <Pathicon icon="chevron-right" />
                  </SubmitButton>
                )}
              />
            )}
          </CardBlock>
        </>
      ) : (
        <LoadingCircle />
      )}
    </NarrowCard>
  )
}

AcceptCommunityGroupInvitationCard.displayName =
  "AcceptCommunityGroupInvitationCard"

AcceptCommunityGroupInvitationCard.propTypes = {
  inviteToken: PropTypes.string.isRequired
}

AcceptCommunityGroupInvitationCard.defaultProps = {
  inviteToken: ""
}

const NarrowCard = styled(Card)`
  & > .View {
    max-width: 500px;
  }
  margin-bottom: 100px;
`
