import classnames from "classnames"
import { useUIThemeContext } from "../ui/PathwrightUI"
import BackgroundImage from "./BackgroundImage"

var ThemeBackground = (props) => {
  const theme = useUIThemeContext()

  // const image =
  //   props.forceBlur
  //     ? resource.background_image_blurred_cdn || resource.blurred_background
  //     : resource.background_image

  return (
    <BackgroundImage
      className={classnames("ThemeBackground", props.className)}
      image={theme.backgroundImage}
      overlay={theme.backgroundOverlay}
      dots={theme.backgroundDots}
      zoom={props.zoom}
      position={props.position}
      size={props.size}
      onClick={props.onClick}
      rounded={props.rounded}
      roundedTop={props.roundedTop}
      roundedBottom={props.roundedBottom}
      style={props.style}
      shadow={props.shadow}
      screensizeSelect={props.screensizeSelect}
    />
  )
}

ThemeBackground.displayName = "ThemeBackground"

export default ThemeBackground
