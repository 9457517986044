import PropTypes from "prop-types"
import React from "react"
import Player from "./Player"
import Upload from "./Upload"

const VideoEditor = (props) => {
  const [video, setVideo] = React.useState(props.video)

  React.useEffect(() => {
    if (props.video.id !== video.id) {
      setVideo(props.video)
    }
  }, [props.video])

  const videoReady = !!video
  return !videoReady ? (
    <Upload
      onUpload={(video) => setVideo(video)}
      onVideoCreated={(video) => setVideo(video)}
    />
  ) : (
    <Player
      video={video}
      coverImage={props.coverImage}
      allowDownload={props.allowDownload || false}
      controls={props.controls}
    />
  )
}

VideoEditor.propTypes = {
  video: PropTypes.object
}

VideoEditor.defaultProps = {
  video: {}
}

export default VideoEditor
