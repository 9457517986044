import Link from "@pathwright/ui/src/components/link/Link"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import { useUIThemeContext } from "@pathwright/ui/src/components/ui/PathwrightUI"
import Text from "@pathwright/ui/src/components/ui/Text"
import View from "@pathwright/ui/src/components/ui/View"
import { media } from "@pathwright/ui/src/components/utils/styles"
import get from "lodash/get"
import moment from "moment"
import PropTypes from "prop-types"
import styled from "styled-components"
import { getStepVerbIcon } from "../../path/utils"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import CompletionFeedbackForm from "../feedback/CompletionFeedbackForm"
import CompletionFeedbackLabel from "../feedback/CompletionFeedbackLabel"
import { dueTimeStatus } from "../utils"

const Column = styled.div`
  display: flex;
  flex-direction: column;
`
const Container = styled(View)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-left-width: 2px;
  border-left-style: solid;
  cursor: pointer;
  ${media.max.tablet`
    height: auto;
  `};
`
const BackgroundLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`
const LayoutCell = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  ${media.max.tablet`
    flex-direction: column;
    align-items: stretch;
  `};
`
const StepCell = styled.div`
  order: 2;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 0;
  width: 300px;
  padding-right: 10px;

  h5 {
    max-width: 100%;
    display: flex;
    align-items: center;
    white-space: pre-wrap;

    > i {
      margin-right: 0.2em;
    }

    > span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  ${media.max.tablet`
    order: -1 !important;
  `};
`
const UserCell = styled.div`
  order: 1;
  flex-grow: 1;
  display: flex;
  align-items: center;
  ${media.max.tablet`
    margin-top: 8px;
  `};
`
const StatusCell = styled.div`
  order: 3;
  flex-grow: 1;
`
const FeedbackCell = styled.div`
  position: relative;
  flex-shrink: 0;
  width: 150px;
`

const CompletionStep = ({ step }) => {
  return (
    <StepCell>
      <Text.H5>
        <Pathicon icon={getStepVerbIcon(step.verb)} />
        <span>{step.name}</span>
      </Text.H5>
    </StepCell>
  )
}

const CompletionStatus = ({ completion }) => {
  return (
    <StatusCell>
      {completion.is_skipped ? (
        <Text.Meta>
          Skipped {moment(completion.completion_date).fromNow()}
        </Text.Meta>
      ) : completion.is_complete ? (
        <Text.Meta>
          Completed {moment(completion.completion_date).fromNow()}
        </Text.Meta>
      ) : (
        <Text.Meta>Not completed yet</Text.Meta>
      )}
      {completion.is_late && (
        <Text.Meta
          style={{ marginLeft: 5, fontStyle: "italic", color: "#FF4136" }}
        >
          {dueTimeStatus(completion.completion_date, completion.completion_due)}
        </Text.Meta>
      )}
    </StatusCell>
  )
}

const CompletionsItem = (props) => {
  const { step, completion, onSelect, hideUser, selected } = props
  const { me } = usePathwrightContext()
  const theme = useUIThemeContext()

  let emphasis = "tertiary"
  if (completion.is_complete && !completion.is_skipped) {
    emphasis = "primary"
  }

  let borderLeftColor = "transparent"
  if (selected) {
    borderLeftColor = theme.primaryBrandColor
  }

  return (
    <Container
      style={{ borderLeftColor }}
      onClick={() => onSelect(step)}
      emphasis={emphasis}
      padding
    >
      <LayoutCell>
        <CompletionStep step={step} />
        <CompletionFeedbackLabel
          completion={completion}
          // TODO: implement hideUser for hiding avatar and user's name? Might get weird if some of user's completions have been overridden by someone
          hideUser={hideUser}
        />
      </LayoutCell>
      <FeedbackCell onClick={(e) => e.stopPropagation()}>
        <CompletionFeedbackForm
          step={step}
          completion={completion}
          // TODO: better permissions, though slightly improved with step.permissions.can_edit
          permissions={{
            canGradeCompletion:
              (me && me.id !== completion.user.id) ||
              get(step.permissions, "can_edit")
          }}
        />
      </FeedbackCell>
    </Container>
  )
}

CompletionsItem.displayName = "CompletionsItem"

CompletionsItem.propTypes = {
  step: PropTypes.shape({
    verb: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }),
  completion: PropTypes.shape({
    completion_date: PropTypes.string,
    completion_due: PropTypes.string,
    is_late: PropTypes.bool,
    is_skipped: PropTypes.bool,
    is_complete: PropTypes.bool,
    user: PropTypes.object.isRequired
  }).isRequired,
  // listen to completion item click
  onSelect: PropTypes.func.isRequired,
  // optionally hide user (useful if showing in list of completions for single user)
  hideUser: PropTypes.bool
}

export default CompletionsItem
