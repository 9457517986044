import { Box, Flex, Heading, Spinner, Text } from "@chakra-ui/react"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import ScrollView from "@pathwright/ui/src/components/scroll/ScrollView"
import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import get from "lodash/get"
import { useEffect, useMemo, useState } from "react"
import { flattenEdges, usePaginator } from "../../../modules/utils/apollo"
import INVITATIONS_QUERY from "../graphql/invitations-query.js"
import Invitation from "../invitation/Invitation"
import {
  JOINED_FILTER_LABEL,
  NOT_ACCEPTED_STATUS_MAP,
  NOT_JOINED_FILTER_LABEL
} from "../invitation/constants"
import ShareDropdown from "../shared/ShareDropdown"
import { useShareUIContext } from "../shared/ShareUIContextProvider"

const tPrefix = "share_card.track_tab"

const statusFilterOptions = [
  {
    value: "NOT_JOINED",
    label: NOT_JOINED_FILTER_LABEL
  },
  {
    value: "JOINED",
    label: JOINED_FILTER_LABEL
  },
  {
    value: "ALL",
    label: "All"
  }
]

const resolveStatus = (invitation) => {
  const { invitation_status, invitation_email } = invitation

  const emailStatus = get(invitation_email, "delivery_status", null)

  if (invitation_status === "accepted") {
    return JOINED_FILTER_LABEL
  }

  return NOT_ACCEPTED_STATUS_MAP[emailStatus] || NOT_JOINED_FILTER_LABEL
}

export function getInvitationsContext(context) {
  switch (context.type) {
    case "group":
      return {
        community_group_id: context.typeId
      }
    default:
      return {
        [`${context.type}_id`]: context.typeId
      }
  }
}

const TrackTab = () => {
  const { tc, tca } = useTranslate()

  const filterOptions = useMemo(
    () =>
      statusFilterOptions.map((option) => {
        return {
          ...option,
          label: tc(`${tPrefix}.${option.label}`)
        }
      }),
    [statusFilterOptions]
  )

  const { context, setPendingInviteCount } = useShareUIContext()

  const [invitations, setInvitations] = useState([])
  const [currentStatusFilter, setCurrentStatusFilter] = useState(
    filterOptions[0]
  )

  const invitationsContext = (function () {
    switch (context.type) {
      case "group":
        return {
          community_group_id: context.typeId
        }
      default:
        return {
          [`${context.type}_id`]: context.typeId
        }
    }
  })()

  const invitationsQuery = useQuery(INVITATIONS_QUERY, {
    variables: {
      first: 300,
      context: getInvitationsContext(context)
    }
  })

  const { loading, error, data, refetch } = invitationsQuery

  const { loadMore, hasMore, loadingMore } = usePaginator({
    data: invitationsQuery,
    path: "invitations"
  })

  const doLoadMore = () => !loadingMore && hasMore && loadMore()

  useEffect(() => {
    if (data && data.invitations) {
      const nextInvitations = flattenEdges(data.invitations)
      setInvitations(nextInvitations)
      setPendingInviteCount(nextInvitations.length)
    }
  }, [data])

  // if (loading || error) return null // TODO: restore once error has been resolved

  return (
    <Box minH="300px">
      {data && invitations.length > 0 ? (
        <Box minH="250px" px={"var(--card-padding)"} pb={"var(--card-padding)"}>
          <Flex justifyContent="space-between" alignItems="center">
            <Text color="gray.500" m={0}>
              {tca(`${tPrefix}.Sent Invites`)}
            </Text>
            <ShareDropdown
              name="status-filters"
              options={filterOptions}
              variant="border"
              onChange={setCurrentStatusFilter}
            />
          </Flex>
          <ScrollView
            onScrollBottom={() => {
              doLoadMore()
            }}
          >
            <Box maxH="400px">
              {invitations
                .map((invitation) => {
                  return {
                    ...invitation,
                    status: tc(`${tPrefix}.${resolveStatus(invitation)}`)
                  }
                })
                .filter((invitation) => {
                  if (currentStatusFilter.value === "ALL") return true

                  if (
                    currentStatusFilter.value === "JOINED" &&
                    invitation.status === JOINED_FILTER_LABEL
                  )
                    return true

                  if (
                    currentStatusFilter.value === "NOT_JOINED" &&
                    invitation.status !== JOINED_FILTER_LABEL
                  )
                    return true

                  return false
                })
                .map((invitation, index) => (
                  <Invitation
                    key={`invitation-${index}`}
                    invitation={invitation}
                    onRevoke={refetch}
                    onResend={refetch}
                  />
                ))}
              {loadingMore && <Spinner />}
            </Box>
          </ScrollView>
        </Box>
      ) : (
        <Flex
          minHeight="300px"
          width="100%"
          height="100%"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          px={"var(--card-padding)"}
        >
          <Heading size="h5" fontWeight="bold" m={0}>
            {tc(`${tPrefix}.Track Email Invites`)}
          </Heading>
          <Text size="xs" color="gray.500" my={2}>
            {tc(`${tPrefix}.Review and manage sent email invitations here.`)}
          </Text>
        </Flex>
      )}
    </Box>
  )
}

export default TrackTab
