import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import styled from "styled-components"
import SubmitButton from "../../button/SubmitButton"
import { useTranslate } from "../../lng/withTranslate"
import TagForm from "../form/TagForm"
import {
  onChangeTagsPropType,
  tagPermissionsPropType,
  tagsPropType
} from "../propTypes"
import TagSelector from "../selector/TagSelector"
import * as utils from "../utils"
import TagManagerList from "./TagManagerList"

const Container = styled.div`
  .TagMangaerList {
    max-height: 100%;
    overflow: scroll;
  }
`

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > * + * {
    margin-left: 1em;
  }
`

const TagManager = ({
  tags,
  tagPermissions,
  tagFormLabels,
  selectLabel,
  getTagMetaLabels,
  onChange,
  isMulti
}) => {
  const { t } = useTranslate()
  const [isSubmitting, setIsSubmitting] = useState(false)

  // Modifying the tags prop for passing on to the TagSelecor.
  // TagSelecor should initialize with no tags selected since
  // the selected tags are displayed below the TagSelector.
  const getSelectorTags = (tags) => {
    // For "filtered" tags, only including those out those tags
    //  that are "filtered" and not "selected".
    const getFilteredTags = (tags) =>
      tags.filtered.filter(
        (filteredTag) =>
          !tags.selected.find(
            (selectedTag) => filteredTag.name === selectedTag.name
          )
      )

    const selectorTags = {
      all: tags.all,
      // Making sure we filter out those tags that are already selected.
      filtered: getFilteredTags(tags),
      // Defaulting selected tags to none.
      selected: []
    }

    return selectorTags
  }

  // Maintaining a modified tags state for passing to the TagSelector
  // which will stage the selected tags for ultimate selection via onSubmit.
  const [selectorTags, setSelectorTags] = useState(getSelectorTags(tags))

  // As tags prop is updated, we update selectorTags.
  useEffect(() => {
    setSelectorTags(getSelectorTags(tags))
    // Assume that a change in tags prop means we are no longer submitting.
    if (isSubmitting) setIsSubmitting(false)
  }, [tags])

  // Handles updating local selectorTags state, which will be
  // submitted when onSubmit is executed.
  const handleOnChangeSelected = (selectedTags) => {
    const mergedSelectorTags = utils.mergeSelectedTags(
      selectorTags,
      selectedTags
    )

    // Potentially unselecting a tag that only existed in TagSelector state.
    // If unhandled, this tag will remain as a filtered tag and be selectable
    // again, but with no way of removing completely without unmounting the
    // TagSelector. To avoid that, any unselected tags that do not exist in
    // TagManager tags prop can be safely removed from selectorTags.
    const tagsToRemove = selectorTags.selected.filter(
      (tag) =>
        // Those tags that have exited current selection.
        !utils.findMatchingTag(tag.name, selectedTags) &&
        // Those tags that do not exist in filtered tags for context.
        !utils.findMatchingTag(tag.name, tags.filtered)
    )

    // If any tagsToRemove have been found, remove them.
    if (tagsToRemove.length) {
      // Filter out all tagsToRemove from selectorTags.all and selectorTags.filtered.
      const filterOutTagsToRemove = (tagsSubset) =>
        tagsSubset.filter(
          (tag) => !utils.findMatchingTag(tag.name, tagsToRemove)
        )
      setSelectorTags({
        ...mergedSelectorTags,
        all: filterOutTagsToRemove(mergedSelectorTags.all),
        filtered: filterOutTagsToRemove(mergedSelectorTags.filtered)
      })
    } else {
      setSelectorTags(mergedSelectorTags)
    }
  }

  // Merge the currently selected tags with the selected tags from the TagSelector.
  // (There is no overlap.)
  const onSubmit = () => {
    if (!isSubmitting) {
      setIsSubmitting(true)
      // Pushing additionally selected tags to the end of the list.
      const selectedTags = [...tags.selected, ...selectorTags.selected]
      const mergedSelectorTags = utils.mergeSelectedTags(tags, selectedTags)
      onChange(mergedSelectorTags, {
        action: "select-tags",
        target: selectorTags.selected
      })
    }
  }

  return (
    <Container className="TagManager">
      <form onSubmit={(e) => e.preventDefault()}>
        <TagManagerList
          tags={tags}
          tagPermissions={tagPermissions}
          tagFormLabels={tagFormLabels}
          onChange={onChange}
          getTagMetaLabels={getTagMetaLabels}
        />
        <Row>
          <TagSelector
            tags={selectorTags}
            contextTags={tags}
            tagPermissions={tagPermissions}
            tagFormLabels={tagFormLabels}
            selectLabel={selectLabel}
            onChange={handleOnChangeSelected}
            isMulti={isMulti}
            menuPlacement={tags.all.length >= 1 ? "top" : "auto"}
            inverted
            styles={{
              container: {
                minWidth: "200px",
                maxWidth: "275px",
                borderRadius: "100px"
              },
              control: {
                borderRadius: "100px"
              },
              placeholder: {
                fontWeight: "bold"
              }
            }}
          />
          {tagPermissions.canAdd && (
            <SubmitButton
              styleType="primary"
              size="large"
              label={t("Add")}
              onClick={onSubmit}
              disabled={!selectorTags.selected.length}
              submitting={isSubmitting}
            />
          )}
        </Row>
      </form>
    </Container>
  )
}

TagManager.displayName = "TagManager"

TagManager.propTypes = {
  tags: tagsPropType,
  tagPermissions: tagPermissionsPropType.isRequired,
  tagFormLabels: TagForm.propTypes.labels,
  selectLabel: TagSelector.propTypes.selectLabel,
  getTagMetaLabels: PropTypes.func,
  onChange: onChangeTagsPropType.isRequired,
  isMulti: PropTypes.bool
}

TagManager.defaultProps = {
  isMulti: true,
  tagPermissions: {
    canAdd: false,
    canEdit: false,
    canRemove: false,
    canReorder: false
  }
}

export default TagManager
