
        
    const runtime = require("../../../../../../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
    __nunjucks_module_dependencies__.templates = {};
    __nunjucks_module_dependencies__.globals = {};
    __nunjucks_module_dependencies__.extensions = {};
    __nunjucks_module_dependencies__.filters = {};
    __nunjucks_module_dependencies__.assets = {};
    
    
    
    
    
            const __nunjucks_template_import__filter_date = require("../../../nunjucks/date.js");
            __nunjucks_module_dependencies__.filters['date'] = {
                module: __nunjucks_template_import__filter_date && __nunjucks_template_import__filter_date.default || __nunjucks_template_import__filter_date,
                async: false
            };
            const __nunjucks_template_import__filter_date_from_now = require("../../../nunjucks/date_from_now.js");
            __nunjucks_module_dependencies__.filters['date_from_now'] = {
                module: __nunjucks_template_import__filter_date_from_now && __nunjucks_template_import__filter_date_from_now.default || __nunjucks_template_import__filter_date_from_now,
                async: false
            };
            const __nunjucks_template_import__filter_t = require("../../../nunjucks/t.js");
            __nunjucks_module_dependencies__.filters['t'] = {
                module: __nunjucks_template_import__filter_t && __nunjucks_template_import__filter_t.default || __nunjucks_template_import__filter_t,
                async: false
            };
            const __nunjucks_template_import__filter_pluralize = require("../../../nunjucks/pluralize.js");
            __nunjucks_module_dependencies__.filters['pluralize'] = {
                module: __nunjucks_template_import__filter_pluralize && __nunjucks_template_import__filter_pluralize.default || __nunjucks_template_import__filter_pluralize,
                async: false
            };
    
        
            __nunjucks_module_dependencies__.templates["discussion/views/templates/response-list-item.html"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"response-who\">\n  <pw-avatar\n    class=\"small\"\n    src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "author")),"profile")),"image_thumb"), env.opts.autoescape);
output += "\"\n    user-id=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "author")),"id"), env.opts.autoescape);
output += "\"\n    first-name=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "author")),"first_name"), env.opts.autoescape);
output += "\"\n    last-name=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "author")),"last_name"), env.opts.autoescape);
output += "\"\n  ></pw-avatar>\n  <!-- TODO: show staff badge if staff -->\n</div>\n<div class=\"response-detail\">\n  <div class=\"response-info\">\n    <span class=\"response-by-line\">\n      ";
if(runtime.contextOrFrameLookup(context, frame, "shouldShowFullName")) {
output += " ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "author")),"full_name"), env.opts.autoescape);
output += " ";
;
}
else {
output += " ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "author")),"abb_name"), env.opts.autoescape);
output += " ";
;
}
output += "\n    </span>\n\n    <span class=\"response-time\">\n      <a href=\"#\" class=\"response-link\">\n        ";
if(runtime.contextOrFrameLookup(context, frame, "created_time")) {
output += "\n        <time is=\"time-ago\" datetime=\"";
output += runtime.suppressValue(env.getFilter("date").call(context, runtime.contextOrFrameLookup(context, frame, "created_time"),""), env.opts.autoescape);
output += "\"\n          >";
output += runtime.suppressValue(env.getFilter("date_from_now").call(context, runtime.contextOrFrameLookup(context, frame, "created_time"),runtime.contextOrFrameLookup(context, frame, "created_time")), env.opts.autoescape);
output += "</time\n        >\n        ";
;
}
output += "\n      </a>\n    </span>\n\n    ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "user_permissions")),"can_edit")) {
output += "\n    <div class=\"discussion-admin-menu-container\"></div>\n    ";
;
}
output += "\n  </div>\n\n  <article class=\"response-body brand-content content-reset\">\n    ";
output += runtime.suppressValue(env.getFilter("safe").call(context, runtime.contextOrFrameLookup(context, frame, "body")), env.opts.autoescape);
output += "\n  </article>\n\n  <a href=\"#\" class=\"response-read-more\"\n    >";
output += runtime.suppressValue(env.getFilter("t").call(context, "","discussion.list_item.show_all"), env.opts.autoescape);
output += "</a\n  >\n  <div class=\"response-meta\">\n    ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "user_permissions")),"can_vote") || runtime.contextOrFrameLookup(context, frame, "user_has_voted")) {
output += "\n    <a\n      href=\"#\"\n      class=\"response-votes brand-color\"\n      data-action=\"vote\"\n      data-toggle=\"tooltip\"\n      data-placement=\"top\"\n      rv-title=\"model:vote_prompt\"\n      rv-class-voted=\"model:user_has_voted\"\n    >\n      <i rv-unless=\"model:user_has_voted\" class=\"pwai-heart\"></i>\n      <i rv-if=\"model:user_has_voted\" class=\"pwai-heart-fill\"></i>\n      <span class=\"vote-count\">{ model:num_votes }</span>\n      <span class=\"vote-prompt\"\n        >+1 ";
output += runtime.suppressValue(env.getFilter("t").call(context, "","discussion.list_item.for"), env.opts.autoescape);
output += " ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "author")),"first_name"), env.opts.autoescape);
output += "</span\n      >\n    </a>\n    ";
;
}
else {
output += " ";
if(runtime.contextOrFrameLookup(context, frame, "num_votes") > 0) {
output += "\n    <span\n      class=\"response-votes\"\n      rv-title=\"model:vote_prompt\"\n      rv-class-voted=\"model:user_has_voted\"\n    >\n      <span class=\"vote-count\">{ model:num_votes }</span>\n      ";
output += runtime.suppressValue(env.getFilter("t").call(context, "votes","discussion.list_item." + env.getFilter("pluralize").call(context, "votes",runtime.contextOrFrameLookup(context, frame, "num_votes"))), env.opts.autoescape);
output += "\n    </span>\n    ";
;
}
output += " ";
;
}
output += " ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "user_permissions")),"can_respond")) {
output += "\n    <a\n      href=\"#\"\n      class=\"response-reply brand-color\"\n      data-toggle=\"tooltip\"\n      data-placement=\"top\"\n      title=\"";
output += runtime.suppressValue(env.getFilter("t").call(context, "","discussion.list_item.reply_to_title",{"author": runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "author")),"first_name"),"response_noun": runtime.contextOrFrameLookup(context, frame, "response_noun")}), env.opts.autoescape);
output += "\"\n    >\n      ";
output += runtime.suppressValue(env.getFilter("t").call(context, "","discussion.list_item.reply_upper"), env.opts.autoescape);
output += "\n      <span class=\"to-name\"\n        >";
output += runtime.suppressValue(env.getFilter("t").call(context, "","discussion.list_item.to"), env.opts.autoescape);
output += " ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "author")),"first_name"), env.opts.autoescape);
output += "</span\n      >\n    </a>\n    ";
;
}
output += "\n  </div>\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
            const templateContext = {
                __nunjucks_loader_assets__: __nunjucks_module_dependencies__.assets,
                ...ctx
            };

            var nunjucks = (runtime && runtime.default || runtime)(
                {"dev":false,"jinjaCompat":false,"isAsyncTemplate":false},
                __nunjucks_module_dependencies__
            );

            if (nunjucks.isAsync()) {
                return nunjucks.renderAsync(
                    "discussion/views/templates/response-list-item.html",
                    templateContext
                );
            }
        
            return nunjucks.render(
                "discussion/views/templates/response-list-item.html",
                templateContext
            );
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["discussion/views/templates/response-list-item.html"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
    