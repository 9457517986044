import { useMutation } from "@apollo/client"
import Button from "@pathwright/ui/src/components/button/Button"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import Text from "@pathwright/ui/src/components/ui/Text"
import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import { useState } from "react"
import styled from "styled-components"
import CREATE_STORE_LINK_MUTATION from "../store/graphql/create-store-link-mutation"
import STORE_QUERY from "../store/graphql/store-query"
import {
  SchoolLicensePickerDropdown,
  useUserSchoolLicenseQuery
} from "./SchoolLicensePicker"
import SignupFauxLoading from "./SignupFauxLoading"
import SignupSchoolForm from "./SignupSchoolForm"

const PickerContainer = styled.div`
  position: relative;
  padding: 2em 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const SignupSelectSchool = ({
  renderHeader,
  renderSubmit,
  licensorId,
  namePlaceholder,
  utmParameters,
  copy,
  onSchoolCreated,
  onSchoolSelected,
  onSchoolLinked,
  inverted,
  onNext
}) => {
  const [showSchoolLicensePicker, setShowSchoolLicensePicker] = useState(false)
  const [schoolToLink, setSchoolToLink] = useState(null)
  const { t } = useTranslate()

  const [createStoreLink] = useMutation(CREATE_STORE_LINK_MUTATION, {
    onCompleted: ({ createStoreLink }) =>
      onSchoolLinked && onSchoolLinked(createStoreLink.licensee)
  })

  const storeQuery = useQuery(STORE_QUERY)
  const userSchoolLicenseQuery = useUserSchoolLicenseQuery()
  const storeAvailable = !!storeQuery.data && !!storeQuery.data.store
  const curriculumSubscriptionAvailable = !!licensorId
  const userIsAdminOfOtherSchools = !!userSchoolLicenseQuery.schools.length

  return schoolToLink ? (
    <SignupFauxLoading linkedSchool={schoolToLink} inverted={inverted} />
  ) : (
    <>
      <SignupSchoolForm
        renderHeader={renderHeader}
        renderSubmit={renderSubmit}
        licensorId={licensorId}
        namePlaceholder={namePlaceholder}
        utmParameters={utmParameters}
        copy={copy}
        onSchoolCreated={onSchoolCreated}
        inverted={inverted}
        onNext={onNext}
      />
      {userIsAdminOfOtherSchools &&
        (curriculumSubscriptionAvailable || storeAvailable) && (
          <PickerContainer>
            <Button
              styleType="text"
              inverted={inverted}
              brand
              onClick={() => setShowSchoolLicensePicker(true)}
            >
              <Text.H5 inverted={inverted} brand>
                {t("or link to an existing Pathwright Account")}
              </Text.H5>
            </Button>
            {showSchoolLicensePicker ? (
              curriculumSubscriptionAvailable ? (
                <SchoolLicensePickerDropdown
                  searchPrompt={null}
                  onOutsideClick={() => setShowSchoolLicensePicker(false)}
                  getPickerItem={(school) => ({
                    ...school,
                    meta:
                      school.curriculum_subscriptions.total >= 1
                        ? t("Already linked")
                        : null
                  })}
                  onPick={(school) => {
                    if (school.curriculum_subscriptions.total === 0) {
                      onSchoolSelected && onSchoolSelected(school)
                    }
                  }}
                />
              ) : (
                <SchoolLicensePickerDropdown
                  searchPrompt={null}
                  onOutsideClick={() => setShowSchoolLicensePicker(false)}
                  getPickerItem={(school) => ({
                    ...school,
                    meta:
                      school.storeLinks.total >= 1 ? t("Already linked") : null
                  })}
                  onPick={(school) => {
                    if (school.storeLinks.total === 0) {
                      onSchoolSelected && onSchoolSelected(school)
                      setSchoolToLink(school)
                      createStoreLink({
                        variables: {
                          schoolId: school.id,
                          copy
                        }
                      })
                    }
                  }}
                />
              )
            ) : null}
          </PickerContainer>
        )}
    </>
  )
}

SignupSelectSchool.displayName = "SignupSelectSchool"

export default SignupSelectSchool
