import Card from "@pathwright/ui/src/components/card/Card"
import CardLink from "@pathwright/ui/src/components/card/CardLink"
import PropTypes from "prop-types"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"

const ManageAccountFeatures = ({ card }) => {
  const { school } = usePathwrightContext()

  return (
    <Card card={card} title="Configure Features">
      {school.stripe_publishable_api_key ? (
        <CardLink
          icon="tag-dollar-sign"
          label="Manage commerce"
          meta="Manage discounts, subscriptions, and commerce settings."
          to={`${card.path}commerce/`}
        />
      ) : !school.is_curriculum_subscriber ? (
        <CardLink
          icon="tag-dollar-sign"
          label="Enable commerce"
          meta="Turn on commerce to sell courses & subscriptions."
          to={`${card.path}commerce/`}
          preview="1"
        />
      ) : null}
      <CardLink
        icon="key"
        label="Configure Sign-In options"
        meta="Configure how your members sign up and sign-in."
        to={`${card.path}auth/`}
      />
      <CardLink
        icon="group"
        label="Configure Cohort communities"
        meta="Configure topics, timeline, and member directories."
        to={`${card.path}cohort/`}
      />
      <CardLink
        icon="certificate"
        label="Configure certificates"
        meta="Configure completion certificates."
        to={`${card.path}certificate/`}
      />
      <CardLink
        icon="integration-2"
        label="Connect other apps"
        meta="Share your data and integrate with other apps."
        to={`${card.path}integrations/`}
      />
    </Card>
  )
}

ManageAccountFeatures.displayName = "ManageAccountFeatures"

ManageAccountFeatures.propTypes = {
  // provided by CardStack > CardStackCard
  card: PropTypes.object.isRequired
}

export default ManageAccountFeatures
