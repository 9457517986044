import { Formik } from "formik"
import PropTypes from "prop-types"
import DiscardFormStateContext from "./DiscardFormStateContext"
import { FormStateFormProvider } from "./FormStateForm"
import useFormikDiscardFormState from "./useFormikDiscardFormState"

export const FormikWithDiscardFormState = ({
  children,
  storageKey,
  onChangeDiscardFormState,
  ...formikProps
}) => (
  <FormStateFormProvider onSubmit={formikProps.onSubmit}>
    {({ onSubmit }) => (
      <Formik {...formikProps} onSubmit={onSubmit}>
        {(form) => (
          <FormikDiscardFormState
            storageKey={storageKey}
            onChangeDiscardFormState={onChangeDiscardFormState}
          >
            {(formikFormDiscardState) =>
              typeof children === "function"
                ? children({
                    ...form,
                    ...formikFormDiscardState
                  })
                : children
            }
          </FormikDiscardFormState>
        )}
      </Formik>
    )}
  </FormStateFormProvider>
)

const FormikDiscardFormState = ({
  children,
  storageKey,
  onChangeDiscardFormState
}) => {
  const formikFormDiscardState = useFormikDiscardFormState(
    storageKey,
    onChangeDiscardFormState
  )

  return (
    <DiscardFormStateContext.Provider value={formikFormDiscardState}>
      {typeof children === "function"
        ? children(formikFormDiscardState)
        : children}
    </DiscardFormStateContext.Provider>
  )
}

FormikDiscardFormState.displayName = "FormikDiscardFormState"

FormikDiscardFormState.propTypes = {
  children: PropTypes.func.isRequired
}

export default FormikDiscardFormState
