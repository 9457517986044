import { z } from "zod"
import { UserStatusFragment } from "../../../api/generated"
import { AuthMode } from "../types"

// Handle a user's preferred auth method.
const preferredAuthMethodKey = "preferredAuthMethod"

const schema = z
  .union([z.literal("password"), z.literal("request")])
  .default("password")

// Intersect the literal AuthMode with the infered schema type to
// gain a little more type saftey.
export type PreferredAuthMethod = AuthMode & z.infer<typeof schema>
export type DefaultAuthMethodType = (AuthMode & "access") | PreferredAuthMethod

export const hasPreferredAuthMethod = (): boolean => {
  // Ok to directly parse since we provide a default, no error will be thrown.
  return localStorage.getItem(preferredAuthMethodKey) !== null
}

export const getPreferredAuthMethod = (): PreferredAuthMethod => {
  // Ok to directly parse since we provide a default, no error will be thrown.
  return schema.parse(localStorage[preferredAuthMethodKey])
}

export const setPreferredAuthMethod = (
  preferredAuthMethod?: PreferredAuthMethod
) => {
  if (preferredAuthMethod) {
    localStorage.setItem(preferredAuthMethodKey, preferredAuthMethod)
  } else {
    localStorage.removeItem(preferredAuthMethodKey)
  }
}

// Uses the user's preferred auth method when available, otherwise defaults
// to the "access" auth method.
export const getDefaultAuthMethod = (): DefaultAuthMethodType => {
  return hasPreferredAuthMethod() ? getPreferredAuthMethod() : "access"
}

// If the user doesn't have a password set, we know they cannot sign in via password,
// so they must sign in via auth request.
export const getAuthMethodForUserStatus = (
  userStatus: UserStatusFragment
): PreferredAuthMethod => {
  const authMethod: PreferredAuthMethod = userStatus.no_password_set
    ? "request"
    : getPreferredAuthMethod()

  return authMethod
}
