import gql from "graphql-tag"
import PATH_ITEM_FRAGMENT from "../../path/graphql/path-item-fragment"

export default gql`
  query Completion($id: Int) {
    pathItem(id: $id) {
      ...PathItem
      parent {
        id
        name
      }
      path {
        id
        progress
        points {
          path_id
          total
          earned
          percentage
          total_points_possible
          has_points
        }
      }
      resource {
        id
        name
        image(width: 150, height: 150)
      }
    }
  }
  ${PATH_ITEM_FRAGMENT}
`
