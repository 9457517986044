import { graphql } from "@apollo/client/react/hoc"
import Alert from "@pathwright/ui/src/components/alert/Alert"
import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import Button from "@pathwright/ui/src/components/button/Button"
import Card from "@pathwright/ui/src/components/card/Card"
import CardBlock from "@pathwright/ui/src/components/card/CardBlock"
import CardLink from "@pathwright/ui/src/components/card/CardLink"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import ScrollView from "@pathwright/ui/src/components/scroll/ScrollView"
import compose from "lodash/flowRight"
import get from "lodash/get"
import moment from "moment"
import numeral from "numeral"
import PropTypes from "prop-types"
import styled from "styled-components"

import { createPaginator, flattenEdges } from "../../utils/apollo"
import SCHOOL_BILLING_INVOICES_QUERY from "../graphql/school-billing-invoices-query"
import { InvoiceType } from "../types"
import ManageBillingOverdueBill from "./ManageBillingOverdueBill"

const StyledOverdueContainer = styled.div`
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.1);
`

const ManageBillingInvoices = ({
  card,
  invoices,
  hasMore,
  loadMore,
  loading,
  error
}) => (
  <Card card={card} title="Billing History">
    <ManageBillingOverdueBill>
      {(OverdueComponent) => (
        <StyledOverdueContainer>{OverdueComponent}</StyledOverdueContainer>
      )}
    </ManageBillingOverdueBill>
    <ScrollView style={{ maxHeight: 500 }} scrollKey="MANAGE_BILLING_INVOICES">
      {!!error && <Alert error={error.message} />}
      {invoices && invoices.length ? (
        invoices.map((invoice, index) => {
          const link = invoice.url || ""
          const date = moment(
            invoice.invoiced_date || invoice.cycle_end
          ).format("MMM DD, YYYY")
          const amount = `$${numeral(invoice.total_amount_billed).format(
            "0,0.00"
          )}`
          const label = invoice.id ? date : `Coming Due ${date}`

          return (
            <CardLink
              key={index}
              href={link}
              icon="device"
              label={label}
              preview={amount}
            />
          )
        })
      ) : loading ? (
        <CardBlock style={{ padding: "10px" }}>
          <LoadingCircle />
        </CardBlock>
      ) : !error ? (
        <BlankSlate heading="No invoices yet." />
      ) : null}
      {!!invoices ? (
        loading ? (
          <CardBlock style={{ padding: "10px" }}>
            <LoadingCircle />
          </CardBlock>
        ) : hasMore ? (
          <CardBlock>
            <Button
              onClick={loadMore}
              styleType="empty"
              rounded={false}
              stretch
            >
              Load more
            </Button>
          </CardBlock>
        ) : null
      ) : null}
    </ScrollView>
  </Card>
)

ManageBillingInvoices.displayName = "ManageBillingInvoices"

ManageBillingInvoices.propTypes = {
  card: PropTypes.object.isRequired,
  invoices: PropTypes.arrayOf(InvoiceType)
}

export default compose(
  graphql(SCHOOL_BILLING_INVOICES_QUERY, {
    options: () => ({
      notifyOnNetworkStatusChange: true
    }),
    skip: (props) => props.hasOwnProperty("invoices"),
    props: ({ data }) => ({
      invoices: flattenEdges(get(data, "school.school_billing_invoices")),
      total: get(data, "school.school_billing_invoices.total", 0),
      loading: data.loading,
      hasMore: get(data, "school.school_billing_invoices.pageInfo.hasNextPage"),
      loadMore: createPaginator(data, "school.school_billing_invoices"),
      error: data.error
    })
  })
  // graphql(SCHOOL_BILLING_INVOICE_UPCOMING_QUERY, {
  //   props: ({ data, ownProps }) => {
  //     const invoice = get(data, "school.upcoming_school_billing_invoice")
  //     return {
  //       loading: ownProps.loading || data.loading,
  //       invoices:
  //         ownProps.invoices && invoice
  //           ? [invoice].concat(ownProps.invoices)
  //           : null
  //     }
  //   }
  // })
)(ManageBillingInvoices)
