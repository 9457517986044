import { MEDIA_TYPES } from "@pathwright/ui/src/components/filestack/constants"
import PropTypes from "prop-types"
import MediaManager from "../../media-manager/MediaManager"

const CertificateMediaManager = ({ onChange, ...mediaManagerProps }) => (
  <MediaManager
    contextKey="certificate-*"
    mediaType={MEDIA_TYPES.FILE.toLowerCase()}
    mimeType="application/pdf"
    pickOptions={{
      accept: [".pdf"],
      maxFiles: 1
    }}
    onChooseMedia={(media) => onChange(media.url)}
    {...mediaManagerProps}
  />
)

CertificateMediaManager.displayName = "CertificateMediaManager"

CertificateMediaManager.propTypes = {
  onChange: PropTypes.func.isRequired
}

export default CertificateMediaManager
