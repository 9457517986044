import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import React from "react"
import styled from "styled-components"
import InboxFocus from "./focus/InboxFocus"
import NavigationBar from "./focus/NavigationBar"
import { useReviewContext } from "./state/ReviewContextProvider"
import InboxLayoutContainer from "./ui/InboxLayourContainer"
import SelectionBackground from "./ui/SelectionBackground"

const LayoutCardContainer = styled.div`
  position: relative;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  flex-grow: 1;
  display: flex;
  width: 100%;
  /* fix scroll perf glitches */
  will-change: scroll-position;
`

export const InboxContentContainer = styled.main`
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  &.with-selected-item {
    max-width: 1200px;
  }

  .InboxNavigationBar,
  .ContextPanel,
  .InboxFocusItems {
    h1,
    h2,
    h3 {
      font-weight: normal;
      margin: 0px;
    }
  }
`

const InboxLoadingContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1000;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  height: var(--vh);
  background-color: rgba(0, 0, 0, 0.5);
`

export const InboxLoading = () => {
  return (
    <InboxLoadingContainer>
      <LoadingCircle inverted />
    </InboxLoadingContainer>
  )
}

const Inbox = () => {
  const {
    selected,
    context: { loading, loadingMore, error, items }
  } = useReviewContext()

  const reload = loading && !loadingMore && !!items.length
  const initialLoad = loading && !loadingMore && !items.length

  return (
    <InboxLayoutContainer>
      <NavigationBar />
      <LayoutCardContainer>
        <SelectionBackground />
        {initialLoad ? (
          <InboxLoading />
        ) : error ? (
          <div>Error: {error.toString()}</div>
        ) : (
          <React.Fragment>
            <InboxContentContainer
              className={!!selected ? "with-selected-item" : ""}
            >
              <InboxFocus />
              {/* {selected && <ContextPanel key={selected.id} />} */}
              {reload && <InboxLoading />}
            </InboxContentContainer>
          </React.Fragment>
        )}
      </LayoutCardContainer>
    </InboxLayoutContainer>
  )
}

export default Inbox
