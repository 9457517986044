import { useObserveSize } from "@pathwright/ui/src/components/observers/ObserveSizeContext"
import debounce from "lodash/debounce"
import { useEffect, useMemo, useRef, useState } from "react"
import { usePreviousDistinct } from "react-use"
import { useCertificateState } from "../context/CertificateState"
import "../fonts/fonts.css"

const variableRe = /@(?<variable>([a-zA-Z0-9_-]|\.(?=[a-zA-Z_0-9-]+))+)/

const useObjectDimensions = ({ textObject, onChange, objectRef }) => {
  const { templateDimensions } = useCertificateState()
  const [
    objectDimensionsRect,
    objectDimensionsRef,
    setObjectDimensionsObserverNode
  ] = useObserveSize()
  const [maxDimensions, setMaxDimensions] = useState({
    height: 9999999,
    width: 9999999
  })
  const [minDimensions, setMinDimensions] = useState({
    height: 0,
    width: 0
  })

  const getMinDemensions = (singleLine = true) => {
    if (objectDimensionsRef.current) {
      // create a dummy element that will be a clone of our input
      const div = document.createElement("div")
      // get the computed style of the input and clone it onto the dummy element
      const copyStyle = getComputedStyle(objectDimensionsRef.current)
      for (let i = 0; i < copyStyle.length; i++) {
        const key = copyStyle[i]
        div.style[key] = copyStyle[key]
      }

      div.style.height = "unset"
      div.style.minHeight = "unset"
      // In some cases, want to force the text object to be the miniumum height to fit the text.
      if (singleLine) {
        div.style.width = "unset"
        div.style.maxWidth = "unset"
        div.style.whiteSpace = "pre"
      }

      // Doesn't really have to be the textObject's text, any string would do.
      // NOTE: appending "\n" since the fianl enter character seems to get dropped when appending ot DOM.
      div.textContent = (textObject.text || "") + "\n"
      // append the dummy element to the body
      document.body.appendChild(div)
      const rect = div.getBoundingClientRect()
      // lastly, remove that dummy element
      document.body.removeChild(div)
      return {
        height: rect.height,
        width: 120
      }
    }

    return minDimensions
  }

  const handleMinDimensions = (singleLine = true) => {
    const minDimensions = getMinDemensions(singleLine)
    if (minDimensions) setMinDimensions(minDimensions)
  }

  const handleMaxDimensions = () => {
    if (objectDimensionsRef.current) {
      const certificateNode =
        objectDimensionsRef.current.closest(".CertificateText")
      // Ensure we find the CertificateText.
      if (certificateNode) {
        const certificateNodeRect = certificateNode.getBoundingClientRect()
        // ensure we have latest objectDimensionsRect
        const objectDimensionsRect =
          objectDimensionsRef.current.getBoundingClientRect()
        const maxHeight = certificateNodeRect.bottom - objectDimensionsRect.top
        const maxWidth = certificateNodeRect.right - objectDimensionsRect.left
        if (
          maxHeight != maxDimensions.height ||
          maxWidth != maxDimensions.width
        ) {
          setMaxDimensions({
            height: maxHeight,
            width: maxWidth
          })
        }
      }
    }
  }

  // Ensure text boxes are constrained to PDF when PDF dimensions change.
  useEffect(() => {
    handleMaxDimensions()
  }, [templateDimensions])

  const isMountedRef = useRef(true)
  useEffect(() => {
    return () => {
      isMountedRef.current = false
    }
  }, [])

  // Util for getting the most up-to-date dimension value for the textarea.
  // We default to the textarea rect when the values differ from the text object.
  // This is mainly to avoid a glitch where resizing a textarea can result
  // in an infinite cycle between the resized dimensions and the prev text object dimensions.
  function getDimensionValue(key) {
    // return textObject.dimensions[key]
    return isMountedRef.current &&
      typeof objectDimensionsRect[key] !== "undefined" &&
      // Unfortunate check against percentage dimensions due to textObject
      // being initialized as a getDefaultTextObject().
      objectDimensionsRect[key] > 1 &&
      objectDimensionsRect[key] !== textObject.dimensions[key]
      ? objectDimensionsRect[key]
      : textObject.dimensions[key]
  }

  const debouncer = useRef(
    debounce((value) => value, 25, { leading: false })
  ).current

  // In order to allow the textObject.dimensions to override the observed
  // rect we have to do so only when we find we are not currently debouncing
  // the rect value (its not in flux due to resize, for example).
  const { height, width } = useMemo(() => {
    const dimensionsHash = `${getDimensionValue("height")}:${getDimensionValue(
      "width"
    )}`
    const debouncedDimensionsHash = debouncer(dimensionsHash)
    const isDebouncing = dimensionsHash !== debouncedDimensionsHash

    if (isDebouncing) {
      return {
        height: getDimensionValue("height"),
        width: getDimensionValue("width")
      }
    } else {
      return {
        height: textObject.dimensions.height,
        width: textObject.dimensions.width
      }
    }
  }, [
    objectDimensionsRect,
    objectDimensionsRect?.height,
    objectDimensionsRect?.width,
    textObject.dimensions.height,
    textObject.dimensions.width
  ])

  useEffect(() => {
    if (
      getDimensionValue("height") &&
      getDimensionValue("width") &&
      (getDimensionValue("height") !== textObject.dimensions.height ||
        getDimensionValue("width") !== textObject.dimensions.width)
    ) {
      onChange("dimensions", {
        height: objectDimensionsRect.height,
        width: objectDimensionsRect.width
      })
      // update the max height and width now that the textarea has been repositioned
      handleMaxDimensions()
      const singleLine = variableRe.test(textObject.text)
      handleMinDimensions(singleLine)
    }
  }, [objectDimensionsRect])

  // 1 line miniumum height.
  useEffect(() => {
    handleMinDimensions()
  }, [textObject.font_name, textObject.font_size, textObject.line_height])

  const prevText = usePreviousDistinct(textObject.text)
  // Full lines actual height. That is, we want to force the height of the text area
  // to be the heigh of the text, but not in a minimum way, so that the user can redefine
  // the height to be less than the actual height of the text (allows the generator to
  // dynamically reduce font size to fit text within prescribed dimensions). If we drop that
  // behavior, we should rather do `handleMinDimensions()` here instead.
  useEffect(() => {
    // Checking against previous text as we only want to enforce min dimensions when
    // text actually changes, not on mount!
    if (prevText && prevText !== textObject.text) {
      // NOTE: we'll only follow the above behavior when we detect use of variables, since that's
      // the only part of the text object's text that can be of variable length. Otherwise, the
      // text should be WYSIWYG, meaning, we can enforce a min height to match the text height.
      const singleLine = variableRe.test(textObject.text)
      handleMinDimensions(singleLine)

      // Optionally set the height of the text box to match the minHeight here.
      // Checking for variable in text, and treating as single line of found.
      onChange("dimensions", {
        ...textObject.dimensions,
        height: getMinDemensions(singleLine).height
      })
    }
  }, [textObject.text])

  // Handle object ref.
  useEffect(() => {
    if (objectRef?.current) {
      setObjectDimensionsObserverNode(objectRef.current)
    }
  }, [objectRef?.current])

  return {
    objectDimensionsRect,
    objectDimensionsRef,
    setObjectDimensionsObserverNode,
    height,
    width,
    maxHeight: maxDimensions.height,
    maxWidth: maxDimensions.width,
    minHeight: minDimensions.height,
    minWidth: minDimensions.width
  }
}

export default useObjectDimensions
