import { useApolloClient } from "@apollo/client"
import { Flex, HStack, Input, Spacer, Text, VStack } from "@chakra-ui/react"
import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import useDebouncedValue from "@pathwright/ui/src/components/hooks/useDebouncedValue"
import PickerContainer from "@pathwright/ui/src/components/picker/PickerContainer"
import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import { Field, Form, Formik } from "formik"
import get from "lodash/get"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { RESOURCE_TYPE_LABELS } from "../../resource/constants"
import UPDATE_RESOURCE_MUTATION from "../../resource/manage/graphql/update-resource-advanced-mutation"
import { flattenEdges } from "../../utils/apollo"
import { UPDATE_CERTIFICATE_VARIABLES_MUTATION } from "../graphql"
import useCertificateCustomVariables from "../hooks/useCertificateCustomVariables"
import VariableTag from "../variables/VariableTag"
import CertificateResource from "./CertificateResource"
import { CERTIFICATE_RESOURCES_QUERY } from "./CertificateResources"

const useLibraryPickerQuery = ({ search: searchProp }) => {
  const [search, debouncedSearch, debouncingSearch, handleDebounceSearch] =
    useDebouncedValue(300)

  const libraryResourcesQuery = useQuery(CERTIFICATE_RESOURCES_QUERY, {
    variables: {
      hasCertificate: false,
      search: searchProp || debouncedSearch,
      first: 1042
    },
    fetchPolicy: "network-and-cache",
    notifyOnNetworkStatusChange: true
  })

  const { data, loading } = libraryResourcesQuery
  const resources = flattenEdges(get(data, "resources"))

  // Force a refetch on mount to ensure latest available resources are present.
  // (This doesn't result in an extra query.)
  useEffect(() => {
    libraryResourcesQuery.refetch()
  }, [])

  return {
    loading,
    pickerItems: resources
  }
}

function CertificateResourceAdd() {
  const [resources, setResources] = useState([])
  const history = useHistory()
  const client = useApolloClient()
  const variables = useCertificateCustomVariables()
  const initialValues = variables.reduce((acc, key) => {
    acc[key] = ""
    return acc
  }, {})

  function handleAddResourceCertificate(resource) {
    setResources([...resources, resource])
  }

  function handleRemoveResourceCertificate(resource) {
    setResources(resources.filter((r) => r.id !== resource.id))
  }

  async function onSubmit(variables, { resetForm }) {
    let promises = []

    // Construct the merge list.
    const merge = Object.entries(variables).reduce((acc, [key, value]) => {
      if (value) {
        acc.push({
          new_key: key,
          new_value: value
        })
      }
      return acc
    }, [])

    // If we have a merge list, we can simply update the variables on the
    // resources. The backend will automatically enable the certificate.
    if (merge.length) {
      // merge resources and variables
      promises = resources.map((resource) =>
        client.mutate({
          mutation: UPDATE_CERTIFICATE_VARIABLES_MUTATION,
          variables: {
            merge,
            context: {
              resource_id: resource.id
            }
          }
        })
      )
    } else {
      // When we don't have a merge list, we'll need to enable the certificate on teh resources.
      promises = resources.map((resource) =>
        client.mutate({
          mutation: UPDATE_RESOURCE_MUTATION,
          variables: {
            // Required variables.
            id: resource.id,
            name: resource.name,
            resource_type: RESOURCE_TYPE_LABELS[resource.resource_type],
            // Enables the certificate.
            certificate_generator_class: "CertificateGenerator"
          }
        })
      )
    }

    // Wait for all the mustations to complete.
    await Promise.all(promises)

    // Clear selected resources in state.
    setResources([])
    // Clear out the variable values.
    resetForm()
    // Refetch the "CertificateResourcesQuery" to clear out the added resources
    // from the picker.
    client.refetchQueries({
      include: "active",
      onQueryUpdated(observableQuery) {
        switch (observableQuery.queryName) {
          case "CertificateResourcesQuery":
            return true
          default:
            return false
        }
      }
    })
    // Navigating back to certifiate card.
    // A bit uggly, probably should be handled via some `onAdd` prop.
    history.push("/manage/school/features/certificate/")
  }

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {(formik) => (
        <Form style={{ minWidth: "100%" }}>
          <VStack
            alignItems="flex-start"
            justifyContent="space-between"
            w="100%"
            minH="260px"
            sx={{
              [".SearchInput"]: {
                bg: "white"
              }
            }}
          >
            <VStack px={6} mb={4} w="100%" bg="whitesmoke" minW="100%" p={6}>
              <PickerContainer
                inline
                prompt={null}
                searchPrompt="Search Library..."
                onPick={handleAddResourceCertificate}
                usePickerQuery={useLibraryPickerQuery}
              />
              {resources.map((resource) => (
                <CertificateResource
                  key={resource.id}
                  resource={resource}
                  canEdit={false}
                  onRemoveResourceCertificate={(e, resource) =>
                    handleRemoveResourceCertificate(resource)
                  }
                />
              ))}
            </VStack>
            <VStack spacing={4} minW="100%" pb={6} px={6}>
              {!!variables.length && (
                <>
                  <Text m={0}>Set custom variables (optional)</Text>
                  {variables.map((variable) => (
                    <HStack w="100%">
                      <Flex
                        flexGrow={1}
                        justifyContent="flex-end"
                        w="40%"
                        overflow="hidden"
                      >
                        <VariableTag
                          variable={variable}
                          active={!!formik.values[variable]}
                        />
                      </Flex>
                      <Field key={variable} as={Input} name={variable} />
                    </HStack>
                  ))}
                  <Spacer />
                </>
              )}
              <SubmitButton
                styleType="primary"
                size="large"
                submitting={formik.isSubmitting}
                disabled={!resources.length}
              >
                Add Certificates
              </SubmitButton>
            </VStack>
          </VStack>
        </Form>
      )}
    </Formik>
  )
}

export default CertificateResourceAdd
