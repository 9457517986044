import gql from "graphql-tag"
import RESOURCE_FRAGMENT from "../../resource/graphql/resource-fragment"
import GROUP_FRAGMENT from "./group-fragment"

export default gql`
  query GroupQuery($id: Int!) {
    group(id: $id) {
      ...Group
      resource {
        ...Resource
        blurred_background: background_image(blur: 500, width: 250, height: 250)
      }
      user_registration {
        id
        role
      }
      user_source_registration {
        id
        role
      }
    }
  }
  ${GROUP_FRAGMENT}
  ${RESOURCE_FRAGMENT}
`
