import { useResourceContext } from "../ResourceContext"

export const usePathQueryVariables = () => {
  const resourceContext = useResourceContext()
  const { pathId, cohortId } = resourceContext || {}

  return {
    id: pathId,
    cohort_id: cohortId
  }
}

const withPathQueryVariables = (Component) => (props) => {
  const pathQueryVariables = usePathQueryVariables()
  return <Component {...props} pathQueryVariables={pathQueryVariables} />
}

export default withPathQueryVariables
