import gql from "graphql-tag"

export default gql`
  fragment Page on Page {
    name
    id
    order
    page_type
    title
    description
    external_url
    iframe_url
    template_content
    scripts
    slug
    show_in_nav
    mode
    is_live
    content_type
  }
`
