import {
  Badge,
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text
} from "@chakra-ui/react"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import { useToggleIntercomVisibility } from "../../intercom/IntercomProvider"
import ShareGate from "../gate/ShareGate"
import useGatedShareAccessKey from "../gate/useGatedShareAccessKey"
import ShareMeta from "../meta/ShareMeta"
import { useShareDataContext } from "../shared/ShareDataContextProvider"
import { useShareUIContext } from "../shared/ShareUIContextProvider"
import AddTab from "./AddTab"
import LinkTab from "./LinkTab"
import TrackTab from "./TrackTab"

const tPrefix = "share_card.tabs"

export const shareSections = [
  {
    key: "link",
    title: "Link",
    icon: "link",
    component: LinkTab
  },
  {
    key: "add",
    title: "Add",
    icon: "avatar-plus",
    component: AddTab
  },
  {
    key: "track",
    title: "Track",
    icon: "integration",
    component: TrackTab
  }
]

export const ShareTabList = () => {
  const { pendingInviteCount, tabIndex } = useShareUIContext()
  const { tc } = useTranslate()

  return (
    <TabList px={"var(--card-padding)"} py={2} w="100%" pos="relative">
      {shareSections.map((shareSection, index) => {
        const isSelected = index === tabIndex
        return (
          <Tab
            key={index}
            _focus={{ boxShadow: "none", outline: "none" }}
            _hover={{
              p: { color: "gray.900" },
              span: { color: "gray.900" }
            }}
            bg="white"
            border="none"
            px={0}
            pb="10px"
            mr="16px"
            position="relative"
          >
            {/* <Box
            as="span"
            color={isSelected ? "gray.900" : "gray.500"}
          >
            <Pathicon
              icon={shareSection.icon}
              // fill="gray.300"
              style={{ marginRight: "5px" }}
            />
          </Box> */}
            <Text
              size="xs"
              fontWeight={isSelected ? 700 : 600}
              m="0"
              color={isSelected ? "gray.900" : "gray.500"}
            >
              {tc(`${tPrefix}.${shareSection.title}`)}
            </Text>
            {pendingInviteCount > 0 && shareSection.key === "track" && (
              <Badge
                variant="solid"
                colorScheme="red"
                fontSize={"0.8rem"}
                p={"1px"}
                ml={".4em"}
              >
                {pendingInviteCount}
              </Badge>
            )}
          </Tab>
        )
      })}
      <ShareMeta />
    </TabList>
  )
}

export const ShareTabPanels = () => {
  const { permissions } = useShareDataContext()
  const gatedReason = permissions[useGatedShareAccessKey()]?.gated_reason
  // Only want to use the share gate when the reason isn't SCHOOL_INACTIVE
  // since that gate only partially gates the panel UI.
  const shouldUseShareGate = gatedReason && gatedReason !== "SCHOOL_INACTIVE"
  return (
    <TabPanels>
      {shareSections.map((shareSection, index) => {
        const TabComponent = shareSection.component
        return (
          <TabPanel key={index} p={0} tabIndex={-1}>
            {shouldUseShareGate ? (
              <ShareGate />
            ) : (
              <Box w="100%">
                <TabComponent />
              </Box>
            )}
          </TabPanel>
        )
      })}
    </TabPanels>
  )
}

export const ShareTabs = ({ children, ...rest }) => {
  const { tabIndex, setTabIndex, isOpen } = useShareUIContext()

  useToggleIntercomVisibility(isOpen)

  return (
    <Tabs
      index={tabIndex}
      onChange={(index) => setTabIndex(index)}
      variant="unstyled"
      isLazy
      lazyBehavior="keepMounted"
      {...rest}
    >
      {children}
    </Tabs>
  )
}
