import { MediaType } from "./types"

// These constants and functions assume the GraphQL schema (currently) defined here:
// https://github.com/duointeractive/pathwright/blob/master/server/blocks/src/media/schema.js

export const MEDIA_TYPES: { [key in MediaType]: MediaType } = {
  IMAGE: MediaType.IMAGE,
  VIDEO: MediaType.VIDEO,
  FILE: MediaType.FILE,
  AUDIO: MediaType.AUDIO,
  DOCUMENT: MediaType.DOCUMENT,
  ARCHIVE: MediaType.ARCHIVE,
  ANY: MediaType.ANY,
  LINK: MediaType.LINK
}

export const MEDIA_EXTENSIONS_MAP: { [key in MediaType]?: string[] } = {
  [MediaType.IMAGE]: [
    ".png",
    ".jpg",
    ".jpeg",
    ".gif",
    ".bmp",
    ".docx",
    ".heic"
  ],
  [MediaType.VIDEO]: [".mp4", ".mpeg", ".mov"],
  [MediaType.DOCUMENT]: [".doc", ".pdf", ".pages", ".rtf", ".docx"],
  [MediaType.ARCHIVE]: [".zip", ".tar", ".gz", ".rar"],
  [MediaType.FILE]: [],
  [MediaType.AUDIO]: [".mp3"],
  [MediaType.ANY]: []
}

// filestack accept (mimetype) parameters
export const MEDIA_TYPE_MAP: { [key in MediaType]?: string[] } = {
  [MediaType.IMAGE]: ["image/*", ".heic"], // .heic doesn't seem to have an image/* mimetype
  [MediaType.VIDEO]: ["video/*"],
  [MediaType.FILE]: ["application/*", "text/*"],
  [MediaType.DOCUMENT]: MEDIA_EXTENSIONS_MAP[MediaType.DOCUMENT],
  [MediaType.ARCHIVE]: MEDIA_EXTENSIONS_MAP[MediaType.ARCHIVE],
  [MediaType.AUDIO]: [
    "audio/*",
    "audio/aac",
    "audio/midi",
    "audio/mpeg",
    "audio/mp4",
    "audio/ogg",
    "audio/opus",
    "audio/wav",
    "audio/webm",
    "audio/x-m4a",
    "audio/3gpp"
  ],
  [MediaType.ANY]: undefined
}

export const generateMimeFromType = (
  type: MediaType = MEDIA_TYPES.FILE
): string => {
  const standardTypes = [
    MEDIA_TYPES.IMAGE,
    MEDIA_TYPES.VIDEO,
    MEDIA_TYPES.AUDIO,
    MEDIA_TYPES.FILE
  ]
  const mimetype = standardTypes.includes(type)
    ? MEDIA_TYPE_MAP[type]![0]
    : "application/*"
  return mimetype
}

type MimetypeObject = {
  fa?: string
  pi?: string
  mediaType: MediaType
  canPreview?: boolean
}

// List of official MIME Types: http://www.iana.org/assignments/media-types/media-types.xhtml
export const mimetypeMap: Record<string, MimetypeObject> = {
  // Media
  image: {
    fa: "fa-file-image-o",
    pi: "file-image",
    mediaType: MEDIA_TYPES.IMAGE,
    canPreview: true
  },
  audio: {
    fa: "fa-file-audio-o",
    pi: "file-audio",
    mediaType: MEDIA_TYPES.AUDIO
  },
  video: {
    fa: "fa-file-video-o",
    pi: "file-video",
    mediaType: MEDIA_TYPES.VIDEO
  },
  // Documents
  "application/pdf": {
    fa: "fa-file-pdf-o",
    pi: "file-text",
    mediaType: MEDIA_TYPES.FILE,
    canPreview: true
  },
  "application/msword": {
    fa: "fa-file-word-o",
    pi: "file-text",
    mediaType: MEDIA_TYPES.FILE,
    canPreview: true
  },
  "application/vnd.ms-word": {
    fa: "fa-file-word-o",
    pi: "file-text",
    mediaType: MEDIA_TYPES.FILE,
    canPreview: true
  },
  "application/vnd.oasis.opendocument.text": {
    fa: "fa-file-word-o",
    pi: "file-text",
    mediaType: MEDIA_TYPES.FILE,
    canPreview: true
  },
  "application/vnd.openxmlformats-officedocument.wordprocessingml": {
    fa: "fa-file-word-o",
    pi: "file-text",
    mediaType: MEDIA_TYPES.FILE,
    canPreview: true
  },
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": {
    fa: "fa-file-word-o",
    pi: "file-text",
    mediaType: MEDIA_TYPES.FILE,
    canPreview: true
  },
  "application/vnd.ms-excel": {
    fa: "fa-file-excel-o",
    pi: "file-text",
    mediaType: MEDIA_TYPES.FILE,
    canPreview: true
  },
  "application/vnd.openxmlformats-officedocument.spreadsheetml": {
    fa: "fa-file-excel-o",
    pi: "file-text",
    mediaType: MEDIA_TYPES.FILE,
    canPreview: true
  },
  "application/vnd.oasis.opendocument.spreadsheet": {
    fa: "fa-file-excel-o",
    pi: "file-text",
    mediaType: MEDIA_TYPES.FILE,
    canPreview: true
  },
  "application/vnd.ms-powerpoint": {
    fa: "fa-file-powerpoint-o",
    pi: "file-text",
    mediaType: MEDIA_TYPES.FILE,
    canPreview: true
  },
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": {
    fa: "fa-file-powerpoint-o",
    pi: "file-text",
    mediaType: MEDIA_TYPES.FILE,
    canPreview: true
  },
  "application/vapplication/vnd.ms-powerpoint.presentation.macroEnabled.12": {
    fa: "fa-file-powerpoint-o",
    pi: "file-text",
    mediaType: MEDIA_TYPES.FILE,
    canPreview: true
  },
  "application/vnd.openxmlformats-officedocument.presentationml": {
    fa: "fa-file-powerpoint-o",
    pi: "file-text",
    mediaType: MEDIA_TYPES.FILE,
    canPreview: true
  },
  "application/vnd.oasis.opendocument.presentation": {
    fa: "fa-file-powerpoint-o",
    pi: "file-text",
    mediaType: MEDIA_TYPES.FILE,
    canPreview: true
  },
  "text/plain": {
    fa: "fa-file-text-o",
    pi: "file-text",
    mediaType: MEDIA_TYPES.FILE,
    canPreview: true
  },
  "text/html": {
    fa: "fa-file-code-o",
    pi: "file-code",
    mediaType: MEDIA_TYPES.FILE,
    canPreview: true
  },
  "application/json": {
    fa: "fa-file-code-o",
    pi: "file-code",
    mediaType: MEDIA_TYPES.FILE
  },
  // Archives
  "application/gzip": {
    fa: "fa-file-archive-o",
    pi: "file-zipped",
    mediaType: MEDIA_TYPES.FILE
  },
  "application/zip": {
    fa: "fa-file-archive-o",
    pi: "file-zipped",
    mediaType: MEDIA_TYPES.FILE
  }
}

// https://gist.github.com/colemanw/9c9a12aae16a4bfe2678de86b661d922
export const getMimetypeData = (mimetype: string): MimetypeObject => {
  const matchedKey = Object.keys(mimetypeMap).filter((key) => {
    return mimetype && mimetype.search(key) === 0 // change to !== -1 for higher tolerance
  })[0]

  return matchedKey
    ? mimetypeMap[matchedKey]
    : {
        fa: "fa-file-o",
        pi: "file-text",
        mediaType: MEDIA_TYPES.FILE
      }
}

export const getMediaTypeFromMimetype = (mimetype: string): string => {
  // if (!mimetype && mimetype !== undefined) return

  const match = getMimetypeData(mimetype)
  return match.mediaType || MEDIA_TYPES.FILE
}

export const getPathiconForMimetype = (
  mimetype: string
): string | undefined => {
  if (!mimetype) return

  const match = getMimetypeData(mimetype)
  return match.pi || "file-text"
}

// typeField: string
// returns string
export const getAudioType = (typeField: string): string => {
  if (!typeField) return "audio/*"

  if (typeField.indexOf("audio/") > -1) {
    return typeField
  }

  return `audio/${typeField}`
}

export const canPreview = (mimetype: string): boolean | undefined => {
  const mimeTypeData = getMimetypeData(mimetype)
  return mimeTypeData && mimeTypeData.canPreview
}
