import gql from "graphql-tag"
import PEOPLE_AND_GROUP_CONNECTION_FRAGMENT from "./people-and-group-connection-fragment"

export default gql`
  query PeopleAndGroupConnectionsQuery(
    $first: Int!
    $cursor: String
    $context: PeopleAndGroupConnectionContext
    $roleInContext: RoleInContext
    $type: PeopleAndGroupConnectionType
    $searchTerm: [String]
  ) {
    peopleAndGroupConnections(
      first: $first
      after: $cursor
      context: $context
      role_in_context: $roleInContext
      type: $type
      search_term: $searchTerm
      people_count_filter: { gt: 0 }
    ) {
      total
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...PeopleAndGroupConnection
        }
      }
    }
  }
  ${PEOPLE_AND_GROUP_CONNECTION_FRAGMENT}
`
