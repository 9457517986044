import gql from "graphql-tag"

export default gql`
  query CohortShareContextQuery($type: ShareContextType!, $typeId: Int!) {
    cohort: group(id: $typeId) {
      id
      # for visibility
      visibility
      # for gating
      access_ends
      registration_ends
      resource {
        id
        name
        short_description
        image_raw
      }
    }
    sharePermissions(context: { type: $type, type_id: $typeId }) {
      ... on ShareCohortPermissions {
        type
        type_id
        add_limit
        allow_manage
        manage_url
        allow_activate
        activate_contact_email
        share_access {
          access
          gated_reason
        }
        share_link_access {
          access
          gated_reason
        }
        share_add_access {
          access
          gated_reason
        }
        share_track_access {
          access
          gated_reason
        }
        invite_to_roles {
          cohort_viewer: viewer
          cohort_observer: observer
          cohort_learner: learner
          cohort_moderator: moderator
          cohort_teacher: teacher
          cohort_course_editor: course_editor
        }
        __typename
      }
    }
  }
`
