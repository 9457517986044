import { Stack } from "@chakra-ui/react"
import ShareLimit from "../limit/ShareLimit"
import SelectAction from "../select/SelectAction"
import SelectError from "../select/SelectError"
import SelectItems from "../select/SelectItems"
import { SelectPeopleProvider } from "../select/SelectPeopleContext"
import SelectSuccess from "../select/SelectSuccess"
import SelectedPeople from "../select/SelectedPeople"
import { useShareDataContext } from "../shared/ShareDataContextProvider"

const AddTab = () => {
  const { context, permissions } = useShareDataContext()

  return (
    <SelectPeopleProvider
      addToKey={`${context.type}:${context.typeId}`}
      permissions={permissions}
    >
      <Stack spacing={2} px={"var(--card-padding)"}>
        <ShareLimit />
        <SelectItems />
        <SelectedPeople />
      </Stack>
      <SelectAction />
      <SelectSuccess />
      <SelectError />
    </SelectPeopleProvider>
  )
}

export default AddTab
