import { Box, Divider, IconButton, Stack, Text } from "@chakra-ui/react"
import { InfoCircleIcon } from "@pathwright/pathicons"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import { jwtDecode } from "jwt-decode"
import React from "react"
import ShareCard from "../ShareCard"
import InactiveSchoolGate from "../gate/InactiveSchoolGate"
import useGatedShareAccessKey from "../gate/useGatedShareAccessKey"
import { useCopyLink } from "../link/CopyLink"
import RadioPills from "../link/RadioPills"
import ShareLink from "../link/ShareLink"
import AtmosphericImage from "../shared/AtmosphericImage"
import RoleDescriptorsDrawer from "../shared/RoleDescriptorsDrawer"
import { useShareDataContext } from "../shared/ShareDataContextProvider"
import { useRolesConfigWithLabels } from "../shared/rolesConfig"

const tPrefix = "share_card.link_tab"

// Determine if user can invite to any pill-worthy role - that is,
// should we show the pills for the roles which the user can invite to or not.
// This may need some work.
const hasPillworthyRoles = (roles, context) => {
  if (roles) {
    switch (context.type) {
      case "cohort":
        return roles.some(
          (role) =>
            !["cohort_viewer", "cohort_observer", "cohort_learner"].includes(
              role
            )
        )
      case "group":
        return true
      case "school":
        console.warn(`Share context for "${context.type}" not implemented yet`)
        return false
    }
  }

  return false
}

const LinkTab = () => {
  const { context, permissions } = useShareDataContext()
  const gatedReason = permissions[useGatedShareAccessKey()]?.gated_reason
  const { tc } = useTranslate()
  const rolesConfig = useRolesConfigWithLabels()
  const inviteUrls = permissions.invite_to_roles
  const handleCopy = useCopyLink()
  const handleCopyInviteUrl = (inviteRole) => {
    // We don't want to allow copying the links when the school is inactive
    // since they can't add people to cohorts.
    if (gatedReason !== "SCHOOL_INACTIVE") {
      handleCopy(inviteUrls[inviteRole])()
    }
  }

  // Filter out the users role permissions, only including role options matching invite urls.
  const filteredInviteRoleOptions = React.useMemo(() => {
    function getRoleVal(key) {
      const token = new URL(inviteUrls[key]).searchParams.get("ic")
      try {
        return jwtDecode(token).role
      } catch {
        // Just sort non-rolled invite urls to the beginning.
        return -Infinity
      }
    }

    // Get the sorted list of roles that user can invite to.
    let roles = Object.keys(rolesConfig).sort((keyA, keyB) => {
      // Sort by role value in invite token.
      return getRoleVal(keyA) - getRoleVal(keyB)
    })

    // If no pill-worthy roles, limit to last role (which has greatest role).
    // NOTE: limiting to only 1 role ensures role pills are not displayed.
    if (hasPillworthyRoles(roles, context)) {
      // Filter out roles with mocked roles so we don't list them in the pill buttons.
      roles = roles.filter((key) => getRoleVal(key) !== -Infinity)
    } else {
      roles = roles.slice(-1)
    }

    // Build config back up.
    return roles.reduce((acc, key) => {
      acc.push({
        key,
        ...rolesConfig[key]
      })
      return acc
    }, [])
  }, [rolesConfig])

  // Defaulting to the first role option.
  const getDefaultInviteRole = () => {
    const studentRoleOption = filteredInviteRoleOptions.find(
      (config) => config.isDefault
    )
    const roleOption = studentRoleOption || filteredInviteRoleOptions[0]
    return roleOption?.key
  }

  const [inviteRole, setInviteRole] = React.useState(getDefaultInviteRole)

  React.useEffect(() => {
    if (!inviteRole) setInviteRole(getDefaultInviteRole)
  }, [filteredInviteRoleOptions])

  const inviteRoleOption = React.useMemo(
    () => filteredInviteRoleOptions.find((config) => config.key === inviteRole),
    [inviteRole]
  )
  const inviteUrl = inviteUrls && inviteUrls[inviteRole]

  if (!inviteRole) return null

  return (
    <Box mx={3} mb={3}>
      <AtmosphericImage>
        <Box px={"var(--card-padding)"} pt={"var(--card-padding)"}>
          <ShareCard />

          {filteredInviteRoleOptions.length > 1 ? (
            <Stack spacing={1}>
              <Text
                size="xs"
                color="gray.500"
                textAlign="center"
                fontWeight={500}
                mt={4}
                mb={0}
              >
                {tc(`${tPrefix}.Invite to`)}...
              </Text>
              <RadioPills
                options={filteredInviteRoleOptions}
                value={inviteRole}
                onChange={setInviteRole}
                onClick={(e, inviteRole) => handleCopyInviteUrl(inviteRole)}
                name="invite-permissions"
              />
              {!!inviteRoleOption && (
                <Text
                  size="xs"
                  color="gray.500"
                  textAlign="center"
                  m={0}
                  px={8}
                >
                  {tc(`${tPrefix}.roles.${inviteRoleOption.key}.subtitle`)}{" "}
                  <RoleDescriptorsDrawer>
                    {({ ref, onOpen }) => (
                      <IconButton
                        ref={ref}
                        icon={<InfoCircleIcon size="1em" />}
                        onClick={onOpen}
                        variant="link"
                        textDecoration="none"
                        color="gray.500"
                        minW={0}
                      />
                    )}
                  </RoleDescriptorsDrawer>
                </Text>
              )}
            </Stack>
          ) : null}
        </Box>

        <Divider mt={4} />

        <InactiveSchoolGate>
          <Box px={"var(--card-padding)"} pb={"var(--card-padding)"} pt={4}>
            <ShareLink url={inviteUrl} />
          </Box>
        </InactiveSchoolGate>
      </AtmosphericImage>
    </Box>
  )
}

export default LinkTab
