import { useCallback, useEffect, useMemo, useState } from "react"
import { usePreviousDistinct } from "react-use"
import { StringParam, useQueryParam } from "use-query-params"
import useReviewNotify from "../context/review/useReviewNotify"
import { isLeaf } from "./tree/path-tree"
import useCompletionsStore from "./useCompletionsStore"
import useReviewMutations from "./useReviewMutations"

const getRootPath = (store) => {
  let root = store.tree
  // if there's only one root child, select it instead
  if (root.children.size === 1) {
    root = root.children.values().next().value
  }
  return root
}

export const _useReviewContext = (context) => {
  const store = useCompletionsStore(context)

  const mutations = useReviewMutations()

  const [selectedId, setSelectedId] = useQueryParam(
    "ui_selected",
    StringParam,
    context.query ? context.query.step_id : null
  )
  const [selectedParentId, setSelectedParentId] = useQueryParam(
    "ui_selected_parent",
    StringParam
  )
  const prevSelectedParentId = usePreviousDistinct(parseInt(selectedParentId))

  // Track if any review status updates are made to inbox items.
  // These types of changes affect which itmes are listed in the Inbox list view
  // depending on the filter settings.
  const [isPendingSyncReviewUpdates, setIsPendingSyncReviewUpdates] =
    useState(false)
  const handleIsPendingSyncReviewUpdates = useCallback(() => {
    if (!isPendingSyncReviewUpdates) setIsPendingSyncReviewUpdates(true)
  }, [isPendingSyncReviewUpdates])

  // This indicates the user is returning to the list view after having potentially
  // updated inbox items in a way that would effectively remove them from the current
  // list of inbox items. Use a callback to respond.
  const prevSelectedId = usePreviousDistinct(selectedId)
  const useSyncReviewUpdates = (callback) => {
    useEffect(() => {
      if (!selectedId && prevSelectedId && isPendingSyncReviewUpdates) {
        callback?.()
        setIsPendingSyncReviewUpdates(false)
      }
    }, [selectedId, callback])
  }

  // Conditionally refetch inbox items in order to sync any review status updates
  // that would require syncing the Inbox list view with those updates.
  useSyncReviewUpdates(context.refetch)

  const map = store.map || {}
  const selectedParent = map[selectedParentId]
  const selected = map[selectedId]

  let isLoaded = !context.loading

  let root = useMemo(
    () => (store.tree ? getRootPath(store) : null),
    [store.tree]
  )

  const clearSelection = () => {
    setSelectedId(null)
  }

  // if the selectedParent is a root node and there's a better root, select it
  useEffect(() => {
    if (
      root &&
      !selectedParent &&
      // Safegaurd against resetting to same parent since
      // this indicates we are leaving inbox.
      root.id !== prevSelectedParentId
    ) {
      setSelectedParentId(root.id, "replaceIn")
    }
  }, [selectedParent, root])

  useEffect(() => {
    if (selectedParent && root) {
      if (root.type === "path" && selectedParent.type === "root") {
        setSelectedParentId(root.id, "replaceIn")
      }
    }
  }, [selectedParentId, root, selectedParent])

  // ACTIONS: select + navigation
  const select = (item) => {
    if (item === null) {
      clearSelection()
    } else {
      // item = get(item.id)
      if (isLeaf(item)) {
        setSelectedId(item.id)
        // hacky!
        // window.scrollTo(0, 0)
      } else {
        clearSelection()
        setSelectedParentId(item.id)
      }
    }
  }

  const updateNodeCompletion = (item) => (result) => {
    let data = result.data[Object.keys(result.data)[0]]
    const node = { ...item }
    node.data.completion = data
    store.actions.set(node.id, node)
  }

  const [reviewNotify, setReviewNotify] = useReviewNotify()
  const reviewItem = async ({
    item,
    userpoints_explanation,
    userpoints_earned,
    notify = reviewNotify
  }) => {
    const result = await mutations.reviewItem({
      item,
      userpoints_explanation,
      userpoints_earned,
      notify
    })
    updateNodeCompletion(item)(result)
    handleIsPendingSyncReviewUpdates()
    setReviewNotify(notify)
    return result
  }

  const reviewSelected = async ({
    userpoints_explanation,
    userpoints_earned,
    notify
  }) => {
    return reviewItem({
      item: selected,
      userpoints_explanation,
      userpoints_earned,
      notify
    })
  }

  // quick action for menu
  const markReviewed = async (item) => {
    return reviewItem({
      item,
      userpoints_explanation: "",
      userpoints_earned: 0,
      notify: false
    })
  }

  const reviewBatch = async ({
    items,
    userpoints_explanation,
    userpoints_earned,
    notify
  }) => {
    let results = []
    // loop through items and call reviewItem
    for (let item of items) {
      // todo apply userpoints_earned as a percentile score of item.userpoints_available
      let result = await reviewItem({
        item,
        userpoints_explanation,
        userpoints_earned,
        notify
      })
      results.push(result)
    }
    return results
  }

  const resetSelectedReview = async () => {
    let result = await mutations.resetReview(selected)
    updateNodeCompletion(selected)(result)
    handleIsPendingSyncReviewUpdates()
  }

  const filterContext = (key, order) => {
    clearSelection()
    context.filter(key, order)
  }

  const filterItems = (func) => {
    store.actions.filter(func)
  }

  const selectRoot = () => {
    setSelectedParentId(root.id, "replaceIn")
  }

  const clearAllSelections = () => {
    context.backToParentContext()
    clearSelection()
    setSelectedParentId(null)
  }

  const actions = {
    select,
    clearSelection,
    clearAllSelections,
    reviewSelected,
    reviewItem,
    markReviewed,
    resetSelectedReview,
    reviewBatch,
    filterContext,
    filterItems,
    selectRoot,
    getItem: (itemId) => store.actions.get(itemId),
    useSyncReviewUpdates
  }

  let state = {
    selectedParent,
    selected,
    actions,
    isLoaded,
    context,
    root,
    filter: context.selectedFilter,
    filters: context.filters,
    itemsFilter: store.itemsFilter,
    hasParentContext: context.hasParentContext(),
    backToParentContext: context.backToParentContext
  }

  // debug stuff
  window.reviewContext = {
    getState: () => state,
    actions
  }

  return state
}
