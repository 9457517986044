import classnames from "classnames"
import assign from "lodash/assign"
import PropTypes from "prop-types"
import { VelocityTransitionGroup } from "velocity-react"
import { useUIThemeContext } from "../ui/PathwrightUI"
import { hexToRgba, parseRgba } from "../utils/colors"
import "./AnimatedIcon.css"
import Pathicon from "./Pathicon"

const AnimatedIcon = (props, context) => {
  const {
    icon,
    inverted,
    iconSize,
    circleSize,
    iconAnimation,
    borderAnimation,
    backgroundAnimation,
    brand
  } = props

  const styles = {
    fontSize: iconSize,
    width: circleSize,
    height: circleSize,
    borderRadius: circleSize / 2
  }

  const className = classnames("AnimatedIcon", props.className)
  const theme = useUIThemeContext()
  const color = brand
    ? theme.primaryBrandColor
    : inverted
    ? "#fff"
    : props.color
  const backgroundStyles = assign({}, styles, {
    backgroundColor: parseRgba(hexToRgba(color, inverted ? 0.3 : 0.1))
  })
  const borderStyles = assign({}, styles, {
    borderColor: color
  })
  const iconStyles = { color }

  return (
    <div className={className} style={styles}>
      <VelocityTransitionGroup enter={backgroundAnimation} runOnMount={true}>
        <div className="AnimatedIcon__background" style={backgroundStyles} />
      </VelocityTransitionGroup>
      <VelocityTransitionGroup enter={borderAnimation} runOnMount={true}>
        <div className="AnimatedIcon__border" style={borderStyles} />
      </VelocityTransitionGroup>
      <VelocityTransitionGroup
        enter={iconAnimation}
        runOnMount={true}
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Pathicon
          className="AnimatedIcon__icon"
          icon={icon}
          style={iconStyles}
        />
      </VelocityTransitionGroup>
    </div>
  )
}

AnimatedIcon.displayName = "AnimatedIcon"
AnimatedIcon.contextTypes = { theme: PropTypes.object }

AnimatedIcon.defaultProps = {
  icon: "check",
  iconSize: 40,
  circleSize: 80,
  backgroundAnimation: {
    animation: "transition.shrinkOut",
    delay: 0
  },
  borderAnimation: {
    animation: "transition.shrinkIn",
    delay: 100
  },
  iconAnimation: {
    animation: "transition.expandIn",
    duration: 200,
    delay: 400
  },
  color: "#000"
}

export default AnimatedIcon
