import get from "lodash/get"

export default function formatStripeAmount(
  stripeDecimalAmount = 0,
  stripePriceCurrency = "usd",
  includeCurrencySuffix
) {
  const locale = get(window, "school.ietf_language_tag", "en-us")
  const currency = String(stripePriceCurrency || "usd").toUpperCase()
  const formatter = new Intl.NumberFormat(locale, { currency, style: "currency" }) // prettier-ignore
  const suffix = includeCurrencySuffix === false ? "" : currency === "USD" ? "" : " " + currency // prettier-ignore
  return (
    formatter.format(stripeDecimalAmount / 100).replace(/\D00$/, "") + suffix
  )
}
