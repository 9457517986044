import get from "lodash/get"
import isEmpty from "lodash/isEmpty"
import moment from "moment"
import pluralize from "pluralize"
import {
  SUBSCRIPTION_CHARGE_TYPE_INVOICE,
  SUBSCRIPTION_CHARGE_TYPE_NO_CHARGE,
  SUBSCRIPTION_INTERVAL_YEARLY
} from "../subscription/constants"
import {
  BILLING_MODEL_HYBRID,
  BILLING_MODEL_PAYG,
  BILLING_MODEL_USER,
  LEARNER_COUNTING_METHOD_ACTIVITY
} from "./constants"

export const usd = new Intl.NumberFormat("en-US", {
  currency: "usd",
  style: "currency"
}).format

export const usdNoDec = (amount) => usd(amount).replace(/\D00$/, "")

export const pct = new Intl.NumberFormat("en-US", {
  style: "percent"
}).format

export const dec = new Intl.NumberFormat("en-US", {
  style: "decimal"
}).format

export const getInvoiceBillingDate = (invoice) => {
  if (invoice) {
    const billingModel = invoice.invoice_json.billing_model
    // user-model billing plans are billed at the beginning of the cycle
    if (billingModel === BILLING_MODEL_USER) return invoice.cycle_start
    return invoice.cycle_end
  }
}

// util for getting the total amount billed for a list of blocks (i.e. user.staff.blocks)
const getBlocksTotal = (blocks) => {
  if (blocks && blocks.length) {
    return blocks.reduce((total, block) => total + block.total_price, 0)
  }

  return 0
}

// util for getting the total of all category line items
const getInvoiceTotal = (categories) =>
  // for each category section
  Object.values(categories).reduce(
    // for each category section, get the category
    (total, categorySection) =>
      total +
      categorySection.reduce(
        // for each category, get the items
        (total, { items }) =>
          total +
          // sum up the item totals
          (items || []).reduce(
            (total, { total: itemTotal }) => total + (itemTotal || 0),
            0
          ),
        0
      ),
    0
  )

export const getLineItemsForInvoice = (
  invoice,
  subscription,
  totalMembersWithAccess,
  schoolScholarship
) => {
  // Given invoice JSON data in the form of a dict, creates a list of line items along with subtotal/total.
  const { invoice_json } = invoice
  const { billing_model } = invoice_json

  // Set some useful flag constants
  const HAS_SCHOOL_SCHOLARSHIP = !!schoolScholarship
  const NEXT_BILL_HAS_SCHOLARSHIP = !!invoice_json.scholarship
  const IS_SCHOLARSHIP_CHANGE =
    HAS_SCHOOL_SCHOLARSHIP &&
    NEXT_BILL_HAS_SCHOLARSHIP &&
    schoolScholarship?.id !== invoice_json.scholarship?.id
  const IS_ANNUAL =
    invoice_json.billing_interval === SUBSCRIPTION_INTERVAL_YEARLY
  const HAS_ANNUAL_DISCOUNT =
    IS_ANNUAL && !!invoice_json.user && !!invoice_json.user.interval_discount
  const IS_PAYG_MODEL = billing_model === BILLING_MODEL_PAYG
  const IS_HYBRID_MODEL = billing_model === BILLING_MODEL_HYBRID
  const IS_USER_MODEL = billing_model === BILLING_MODEL_USER
  const IS_CANCELED = !!get(subscription, "subscription.canceled_dtime")
  const IS_DELINQUENT_INACTIVE =
    IS_CANCELED && !!get(subscription, "subscription.is_delinquent")
  const IS_MANUAL =
    get(subscription, "charge_type") === SUBSCRIPTION_CHARGE_TYPE_INVOICE
  const IS_CHILD = !!invoice.parent_subscription
  const IS_PARENT =
    !!invoice.child_subscriptions && !!invoice.child_subscriptions.length
  const IS_NO_CHARGE =
    get(subscription, "charge_type") === SUBSCRIPTION_CHARGE_TYPE_NO_CHARGE

  const schoolPricingURL = get(subscription, "school.url")
    ? `/pricing/plan/`
    : null

  // categories separated into distinct sections of the invoice
  const categories = {
    plan: [],
    staff: [],
    members: [],
    totals: []
  }

  let base_fee = invoice_json.plan_details
    ? invoice_json.plan_details.base_fee
    : null

  if (IS_ANNUAL) {
    base_fee = base_fee * 12
  }

  // Compute included learners and staff
  const included_members =
    get(invoice_json.plan_details, "included_members", 0) +
    get(invoice_json, "free_extras.learners", 0)
  const included_staff =
    get(invoice_json.plan_details, "included_staff", 0) +
    get(invoice_json, "free_extras.staff", 0)

  // TODO: Add typeform link in the url values for Plan features categories below
  // Section 1: Plan features
  const planLabel = "Plan features"

  if (IS_HYBRID_MODEL) {
    const itemLabel = `Base fee for the features included in your plan for up to ${dec(
      included_members
    )} active ${pluralize("member", included_members)}.`

    categories.plan.push({
      label: planLabel,
      total: base_fee,
      icon: "toolbox",
      // link: schoolPricingURL ? { label: "Review or update your plan", url: schoolPricingURL } : null,
      items: [
        {
          label: itemLabel,
          total: base_fee
        }
      ]
    })
  } else if (IS_USER_MODEL) {
    const link = IS_CANCELED
      ? null
      : schoolPricingURL
      ? { label: "Review or update your plan", url: schoolPricingURL }
      : null

    categories.plan.push({
      label: planLabel,
      total: base_fee,
      icon: "toolbox",
      link,
      items: [
        {
          // invoice_json.plan_details.name
          label: "Base fee for the features included in your plan",
          total: base_fee
        }
      ]
    })
  }

  // Section 2: Staff

  // There will never be more than one "block" of staff members (currently).
  // So we simply show the no. of included staff followed by the no. of extras being billed
  // Don't show staff on Hybird and PAYG plans
  if (!IS_PAYG_MODEL && !IS_HYBRID_MODEL) {
    const staffCategory = `Staff`
    let meta = null // by default don't show (ex: 10/20)
    let total = 0
    let itemLabel = `${included_staff} staff members included`

    const link = !IS_CANCELED
      ? { label: "Manage staff access", url: "/community/members/" }
      : null

    const planStaffBlock = get(invoice_json.plan_details, "blocks.staff") // { 1:[cost per staff member] }

    if (planStaffBlock) {
      const billableStaff = get(invoice_json, "user.staff.billable", 0)
      let billableExtraStaff = 0

      if (!IS_CANCELED) {
        meta = `(${dec(billableStaff)}/${dec(included_staff)})`
        total = getBlocksTotal(get(invoice_json, "user.staff.blocks"))
        billableExtraStaff = get(invoice_json, "user.staff.billed", 0)
      }

      const perStaffCost = dec(planStaffBlock["1"])
      itemLabel +=
        !IS_ANNUAL && !IS_MANUAL // don't show extra staff pricing for annual plans
          ? !IS_CANCELED && billableExtraStaff > 0
            ? ` + ${billableExtraStaff} extra at $${perStaffCost}/mo. each` // show cost per extra if exist
            : `, extras are $${perStaffCost}/mo. each` // for canceled/inactive plans show cost
          : ""
    }

    const items = [
      {
        label: itemLabel,
        total
      }
    ]

    categories.staff.push({
      label: staffCategory,
      total,
      icon: "glasses",
      meta,
      link,
      items
    })
  }

  // Section 3: Members

  // enrollment usage billing
  if (IS_PAYG_MODEL) {
    const enrollments = get(invoice_json.payg, "enrollments.total", 0)
    const totalSeats = get(invoice_json.payg, "enrollments.total", 0)
    const seatQty = get(invoice_json.payg, "enrollments.billed", 0)
    const total = get(invoice_json.payg, "enrollments.total_fee", 0)
    const perEnrollmentFee = get(
      invoice_json.payg,
      "enrollments.per_enrollment_fee",
      0
    )
    const creditsUsed = dec(
      get(invoice_json.payg, "enrollments.credits_used", 0)
    )
    const seatLabel = `${seatQty} ${pluralize("seat", seatQty)}`
    const eachLabel = seatQty > 1 ? " each" : ""

    // ex: "325 registrations - 37 credits ="
    const billedLabelA =
      creditsUsed > 0
        ? `${enrollments} ${pluralize(
            "seat",
            totalSeats
          )} - ${creditsUsed} ${pluralize("credit", creditsUsed)} =`
        : ``

    const average = total / seatQty || 0

    // ex: 288 registrations at $5.00 each
    const billedLabelB =
      average === perEnrollmentFee
        ? `${seatLabel} at ${usd(perEnrollmentFee)}${eachLabel}`
        : `${seatLabel} averaging ${usd(average)}${eachLabel}`

    categories.members.push({
      label: "Enrollment fees",
      total,
      icon: "bullet-list",
      items: [
        {
          label: `${billedLabelA} ${billedLabelB}`,
          total
        }
      ]
    })
  }

  // member billing
  if (IS_HYBRID_MODEL) {
    const total = get(invoice_json.hybrid, "members.total_fee", 0)
    const meta = `(${dec(invoice_json.hybrid.members.total)}/${dec(
      included_members
    )})`

    const itemLabel = `${dec(
      invoice_json.hybrid.members.billed
    )} additional active ${pluralize(
      "member",
      invoice_json.hybrid.members.billed
    )} over the plan limit at ${usdNoDec(
      invoice_json.plan_details.additional_user_fee
    )} per member`

    categories.members.push({
      label: "Additional members",
      total,
      icon: "group-2",
      meta,
      link: null,
      items: [
        {
          label: itemLabel,
          total
        }
      ]
    })
  }

  // Sales and subscription fees for PAYG and Hybrid plans
  if (IS_PAYG_MODEL || IS_HYBRID_MODEL) {
    const planKey = IS_PAYG_MODEL ? "payg" : "hybrid"
    const total = get(invoice_json[planKey], "sales.total_fee", 0)
    const revenue = get(invoice_json[planKey], "sales.total_revenue", 0)
    const salesFeePercent =
      get(invoice_json[planKey], "sales.sales_fee", 0) * 100

    categories.members.push({
      label: "Course sales fee",
      total,
      icon: "bar-chart",
      items: [
        {
          label: `${salesFeePercent}% of ${usdNoDec(revenue)} in sales`,
          total
        }
      ]
    })

    if (get(invoice_json[planKey], "subscriptions", null)) {
      const total = get(invoice_json[planKey], "subscriptions.total_fee", 0)
      const revenue = get(
        invoice_json[planKey],
        "subscriptions.total_revenue",
        0
      )
      const subFeePerc =
        get(invoice_json[planKey], "subscriptions.subscription_fee", 0) * 100

      categories.members.push({
        label: "Subscription sales fee",
        total,
        icon: "bar-chart",
        items: [
          {
            label: `${subFeePerc}% of ${usdNoDec(revenue)} in revenue`,
            total
          }
        ]
      })
    }
  }

  // learner usage based billing
  if (IS_USER_MODEL) {
    const category = `Members`
    let numUsers = invoice_json.user.learners.billable
    if (!numUsers && IS_ANNUAL) {
      numUsers = totalMembersWithAccess || 0
    }
    const meta = `(${dec(numUsers)}/${dec(included_members)})`

    // if no learner blocks included in the plan, then default to user.learners
    if (isEmpty(get(invoice_json.plan_details, "blocks.learners"))) {
      const total =
        get(invoice_json.user, "learners.billed") *
        get(invoice_json.plan_details, "additional_user_fee")

      const itemLabel = `${dec(
        invoice_json.user.learners.billed
      )} additional ${pluralize(
        "member",
        invoice_json.user.learners.billed
      )} over the plan limit
at ${usdNoDec(invoice_json.plan_details.additional_user_fee)}/member`

      const items = [
        {
          label: itemLabel,
          total
        }
      ]

      const link =
        invoice_json.plan_details.learner_counting ===
        LEARNER_COUNTING_METHOD_ACTIVITY
          ? null
          : {
              label: "Manage member access",
              url: "/manage/school/billing/access/"
            }

      categories.members.push({
        label: category,
        total,
        icon: "group-2",
        meta,
        link,
        items
      })
    } else {
      let total = 0
      // block-based usage billing
      const items = [
        {
          label: `${included_members} members included`,
          total: 0
        }
      ]
      if (
        get(invoice_json, "user.learners.blocks.length") > 0
        // Add this condition if we decide to implement pre-configured member/staff blocks for annual plans
        // && !IS_ANNUAL
      ) {
        // include learner blocks
        items.push(
          ...invoice_json.user.learners.blocks.map((block) => {
            const label = `+ ${dec(block.qty)} ${pluralize(
              "block",
              block.qty
            )} of ${block.size} extra ${pluralize(
              "learner",
              block.size
            )} at ${usdNoDec(block.total_price)}`
            return { label, total: block.total_price }
          })
        )

        total = getBlocksTotal(invoice_json.user.learners.blocks)
      }

      categories.members.push({
        label: category,
        total,
        icon: "group-2",
        meta,
        link: {
          label: "Manage member access",
          url: "/manage/school/billing/access/"
        },
        items
      })
    }
  }

  if (IS_PARENT) {
    const total = invoice.child_subscriptions.reduce(
      (agg, sub) => agg + get(sub, "next_invoice.total_amount_billed", 0),
      0
    )

    const items = [
      {
        // TODO: Are we hacking the categories data shape here by adding a label for non-billable item?
        label: `These totals are for your information only—they will not be applied to this bill.`,
        total: 0
      }
    ]

    items.push(
      ...invoice.child_subscriptions.map((sub) => ({
        // We don't currently include the cost of these into the total billed in the current invoice
        label: `Additional account for ${sub.school.name} (${usdNoDec(
          get(sub, "next_invoice.total_amount_billed", 0)
        )})`,
        total: 0
      }))
    )

    categories.members.push({
      label: `Additional ${pluralize(
        "plan",
        invoice.child_subscriptions.length
      )}`,
      total,
      // showSubtotals: invoice.child_subscriptions.length > 1
      items
    })
  }

  // Optional Section (legacy plans only): Additional Fees/Items
  // sales/sub fees
  // if ([BILLING_MODEL_PAYG, BILLING_MODEL_HYBRID].indexOf(billing_model) > -1) {
  //   const key = IS_PAYG_MODEL ? "payg" : "hybrid"

  //   const sales_fee_pct = invoice_json[key].sales.sales_fee
  //   const sales_fee_revenue = invoice_json[key].sales.total_revenue
  //   const sales_fee_total = invoice_json[key].sales.total_fee || 0

  //   const sub_fee_pct = invoice_json[key].subscriptions.subscription_fee
  //   const sub_fee_revenue = invoice_json[key].subscriptions.total_revenue
  //   const sub_fee_total = invoice_json[key].subscriptions.total_fee || 0

  //   // line items
  //   let extraItemsTotal = 0
  //   const extraLineItems =
  //     get(invoice_json, "line_items.length") > 0
  //       ? invoice_json.line_items.map(item => {
  //           extraItemsTotal += item.total_price
  //           return [
  //             `${item.desc} (${dec(item.qty)} at ${usdNoDec(
  //               item.unit_price
  //             )} each)`,
  //             item.total_price
  //           ]
  //         })
  //       : []

  //   categories.push([
  //     "Additional Fees",
  //     {
  //       link: null,
  //       categoryTotal: sales_fee_total + sub_fee_total + extraItemsTotal,
  //       categoryIcon: ""
  //     },
  //     [
  //       `Sales fees at ${pct(sales_fee_pct)} of ${usdNoDec(
  //         sales_fee_revenue
  //       )} in sales`,
  //       sales_fee_total
  //     ],
  //     [
  //       `Subscription fees at ${pct(sub_fee_pct)} of ${usdNoDec(
  //         sub_fee_revenue
  //       )} in revenue`,
  //       sub_fee_total
  //     ],
  //     ...extraLineItems
  //   ])
  // }

  // Sum all totals before discounts for sub total
  const subTotal = IS_CANCELED ? 0 : getInvoiceTotal(categories)

  // Add subtotal category
  if (!IS_CANCELED) {
    categories.totals.push({
      label: "Subtotal",
      total: subTotal
    })
  }

  // interval discount
  if (IS_USER_MODEL && HAS_ANNUAL_DISCOUNT && !NEXT_BILL_HAS_SCHOLARSHIP) {
    categories.totals.push({
      label: "Discount for Annual Payment",
      total: -invoice_json.user.interval_discount,
      icon: "gear-dollar-sign",
      items: [
        {
          label: `10% discount applied for annual payment`,
          total: -invoice_json.user.interval_discount
        }
      ]
    })
  }

  // scholarships
  if (IS_USER_MODEL && (NEXT_BILL_HAS_SCHOLARSHIP || HAS_SCHOOL_SCHOLARSHIP)) {
    const items = []
    let discount = 0

    if (NEXT_BILL_HAS_SCHOLARSHIP) {
      const {
        scholarship: {
          ends,
          iterations,
          coupon_discount_pct,
          discount_this_cycle
        }
      } = invoice_json

      const intervalName = IS_ANNUAL ? "year" : "month"
      const endFormatted = moment(ends).format("MMM Do, YYYY")

      discount = discount_this_cycle

      items.push({
        label: `${coupon_discount_pct}% off base fee ${
          iterations
            ? ` for ${iterations} ${pluralize(intervalName, iterations)}`
            : ``
        } ${`• Valid until ${endFormatted}`}`,
        total: -discount_this_cycle
      })
    }

    // Scholarship expires
    if (!NEXT_BILL_HAS_SCHOLARSHIP && HAS_SCHOOL_SCHOLARSHIP) {
      const {
        ends,
        coupon: { percent_off: coupon_discount_pct }
      } = schoolScholarship

      const cycleEnd = moment(ends)
      const endFormatted = moment(cycleEnd).format("MMM Do, YYYY")

      items.push({
        label: `Your current scholarship of ${coupon_discount_pct}% off expires on ${endFormatted} and will not apply to the next bill.`,
        total: null
      })
    }

    // Scholarship changes
    if (NEXT_BILL_HAS_SCHOLARSHIP && IS_SCHOLARSHIP_CHANGE) {
      const {
        scholarship: { ends, coupon_discount_pct }
      } = invoice_json

      const {
        ends: prevEnds,
        coupon: { percent_off: prevPercentOff }
      } = schoolScholarship

      const endFormatted = moment(ends).format("MMM Do, YYYY")

      const prevEndFormatted = moment(prevEnds).format("MMM Do, YYYY")

      items.push({
        label: `Your current scholarship of ${prevPercentOff}% off expires on ${prevEndFormatted} and will be replaced by a ${coupon_discount_pct}% off scholarship until ${endFormatted}.`,
        total: null
      })
    }

    if (invoice_json.description)
      items.push({ label: description, total: null })

    categories.totals.push({
      label: "Scholarship",
      total: -discount,
      icon: "award",
      items
    })
  }

  // Sum all totals for grand total
  const grandTotal =
    IS_CANCELED && !IS_DELINQUENT_INACTIVE ? 0 : getInvoiceTotal(categories)

  const cardLast4 = get(subscription, "subscription.card_last_4", null)
  const customerEmail =
    get(subscription, "subscription.customer.email", null) ||
    get(subscription, "school.email", null)
  const invoiceDue = moment(invoice.cycle_end).format("MMM D, YYYY")

  // `Total Estimate for ${moment(invoice.cycle_start).format("MMM D, YYYY")} -
  // ${moment(invoice.cycle_end).format("MMM D, YYYY")}`,
  const label = IS_DELINQUENT_INACTIVE
    ? "Overdue fees"
    : IS_ANNUAL && IS_USER_MODEL
    ? "Total fees"
    : "Total estimated fees"

  const total = IS_NO_CHARGE ? 0 : grandTotal

  const link =
    IS_MANUAL || IS_NO_CHARGE
      ? null
      : IS_CANCELED
      ? schoolPricingURL
        ? {
            label: "Renew or update your plan",
            url: schoolPricingURL
          }
        : null
      : {
          label: "Update payment method",
          url: "/manage/school/billing/payment/"
        }

  // Legacy plans (PAYG, hybrid) are post-paid; standard plans are pre-paid
  const billDueDate = IS_USER_MODEL ? invoice.cycle_start : invoice.cycle_end

  const itemLabel = IS_DELINQUENT_INACTIVE
    ? `We couldn't charge your card for your last invoice so your account is currently inactive.`
    : IS_CANCELED
    ? `Your plan is no longer active so no charges are due.`
    : IS_CHILD
    ? `Your plan fees are billed separately to ${get(
        invoice,
        "parent_subscription.school.name"
      )}`
    : IS_PARENT
    ? `Your invoice will be sent by email.`
    : IS_MANUAL
    ? `Your next invoice will be sent by email to ${customerEmail}`
    : IS_NO_CHARGE
    ? `Your account will not be charged.`
    : cardLast4
    ? `Card ending in ${cardLast4} will be charged on ${moment(
        billDueDate
      ).format("MMM Do, YYYY")}`
    : `Total fees due on ${moment(invoice.cycle_end).format("MMM Do, YYYY")}`

  // Add total category
  categories.totals.push({
    label,
    total,
    link,
    items: [
      {
        label: itemLabel,
        total: grandTotal
      }
    ]
  })

  return categories
}
