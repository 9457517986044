import CompleteStepActivity from "./CompleteStepActivity"
import CreateDiscussionActivity from "./CreateDiscussionActivity"
import CreateResponseActivity from "./CreateResponseActivity"
import SkipStepActivity from "./SkipStepActivity"

// NOTE: the values of theses LEARNING_ACTION_FILTER_* constants
// can be arbitrary, although only of consequence in the LEARNING_ACTION_QUERY_FILTERS
export const LEARNING_ACTION_FILTER_NONE = "none"
export const LEARNING_ACTION_FILTER_COMPLETE_STEP = "complete_step"
export const LEARNING_ACTION_FILTER_SKIP_STEP = "skip_step"
export const LEARNING_ACTION_FILTER_COMPLETE_COURSE = "complete_course"
export const LEARNING_ACTION_FILTER_CREATE_GROUP = "create_group"
export const LEARNING_ACTION_FILTER_CREATE_DISCUSSION = "create_discussion"
export const LEARNING_ACTION_FILTER_CREATE_RESPONSE = "create_response"
export const LEARNING_ACTION_FILTER_CREATE_REGISTRATION = "create_registration"
export const LEARNING_ACTION_FILTER_CREATE_MEMBERSHIP = "create_membership"
export const LEARNING_ACTION_FILTER_CREATE_SUBSCRIPTION = "create_subscription"
export const LEARNING_ACTION_FILTER_PAUSE_SUBSCRIPTION = "paused_subscription"
export const LEARNING_ACTION_FILTER_UNPAUSE_SUBSCRIPTION =
  "unpaused_subscription"
export const LEARNING_ACTION_FILTER_CANCELED_SUBSCRIPTION =
  "canceled_subscription"
export const LEARNING_ACTION_FILTER_CANCELED_PAUSED_SUBSCRIPTION =
  "canceled_paused_subscription"
export const LEARNING_ACTION_FILTER_PAUSED_UNPAUSED_CANCELED_SUBSCRIPTION =
  "paused_unpaused_canceled_subscription"
export const LEARNING_ACTION_FILTER_CREATE_DISCUSSION_CREATE_RESPONSE =
  "create_discussion_create_response"

export const LEARNING_ACTION_FILTERS = [
  LEARNING_ACTION_FILTER_NONE,
  LEARNING_ACTION_FILTER_COMPLETE_STEP,
  LEARNING_ACTION_FILTER_SKIP_STEP,
  LEARNING_ACTION_FILTER_COMPLETE_COURSE,
  LEARNING_ACTION_FILTER_CREATE_GROUP,
  LEARNING_ACTION_FILTER_CREATE_DISCUSSION,
  LEARNING_ACTION_FILTER_CREATE_RESPONSE,
  LEARNING_ACTION_FILTER_CREATE_REGISTRATION,
  LEARNING_ACTION_FILTER_CREATE_MEMBERSHIP,
  LEARNING_ACTION_FILTER_CREATE_SUBSCRIPTION,
  LEARNING_ACTION_FILTER_PAUSE_SUBSCRIPTION,
  LEARNING_ACTION_FILTER_UNPAUSE_SUBSCRIPTION,
  LEARNING_ACTION_FILTER_CANCELED_SUBSCRIPTION,
  LEARNING_ACTION_FILTER_CANCELED_PAUSED_SUBSCRIPTION,
  LEARNING_ACTION_FILTER_PAUSED_UNPAUSED_CANCELED_SUBSCRIPTION
]

export const LEARNING_ACTION_QUERY_FILTERS = {
  [LEARNING_ACTION_FILTER_NONE]: null,
  [LEARNING_ACTION_FILTER_COMPLETE_STEP]: `{"eq":"${LEARNING_ACTION_FILTER_COMPLETE_STEP}"}`,
  [LEARNING_ACTION_FILTER_SKIP_STEP]: `{"eq":"${LEARNING_ACTION_FILTER_SKIP_STEP}"}`,
  [LEARNING_ACTION_FILTER_COMPLETE_COURSE]: `{"eq":"${LEARNING_ACTION_FILTER_COMPLETE_COURSE}"}`,
  [LEARNING_ACTION_FILTER_CREATE_GROUP]: `{"eq":"${LEARNING_ACTION_FILTER_CREATE_GROUP}"}`,
  [LEARNING_ACTION_FILTER_CREATE_DISCUSSION]: `{"eq":"${LEARNING_ACTION_FILTER_CREATE_DISCUSSION}"}`,
  [LEARNING_ACTION_FILTER_CREATE_RESPONSE]: `{"eq":"${LEARNING_ACTION_FILTER_CREATE_RESPONSE}"}`,
  [LEARNING_ACTION_FILTER_CREATE_REGISTRATION]: `{"eq":"${LEARNING_ACTION_FILTER_CREATE_REGISTRATION}"}`,
  [LEARNING_ACTION_FILTER_CREATE_MEMBERSHIP]: `{"eq":"${LEARNING_ACTION_FILTER_CREATE_MEMBERSHIP}"}`,
  [LEARNING_ACTION_FILTER_CREATE_SUBSCRIPTION]: `{"eq":"${LEARNING_ACTION_FILTER_CREATE_SUBSCRIPTION}"}`,
  [LEARNING_ACTION_FILTER_PAUSE_SUBSCRIPTION]: `{"in":["${LEARNING_ACTION_FILTER_PAUSE_SUBSCRIPTION}"]}`,
  [LEARNING_ACTION_FILTER_UNPAUSE_SUBSCRIPTION]: `{"in":["${LEARNING_ACTION_FILTER_UNPAUSE_SUBSCRIPTION}"]}`,
  [LEARNING_ACTION_FILTER_CANCELED_SUBSCRIPTION]: `{"in":["${LEARNING_ACTION_FILTER_CANCELED_SUBSCRIPTION}"]}`,
  [LEARNING_ACTION_FILTER_CANCELED_PAUSED_SUBSCRIPTION]: `{"in":["${LEARNING_ACTION_FILTER_CANCELED_PAUSED_SUBSCRIPTION}"]}`,
  [LEARNING_ACTION_FILTER_PAUSED_UNPAUSED_CANCELED_SUBSCRIPTION]: `{"in":["${LEARNING_ACTION_FILTER_PAUSE_SUBSCRIPTION}","${LEARNING_ACTION_FILTER_UNPAUSE_SUBSCRIPTION}","${LEARNING_ACTION_FILTER_CANCELED_PAUSED_SUBSCRIPTION}","${LEARNING_ACTION_FILTER_CANCELED_SUBSCRIPTION}"]}`,
  [LEARNING_ACTION_FILTER_CREATE_DISCUSSION_CREATE_RESPONSE]: `{"in":["${LEARNING_ACTION_FILTER_CREATE_DISCUSSION}","${LEARNING_ACTION_FILTER_CREATE_RESPONSE}"]}`
}

export const LEARNING_ACTION_FILTER_LABELS = {
  [LEARNING_ACTION_FILTER_NONE]: "activity",
  [LEARNING_ACTION_FILTER_COMPLETE_STEP]: "step completions",
  [LEARNING_ACTION_FILTER_SKIP_STEP]: "step skips",
  [LEARNING_ACTION_FILTER_COMPLETE_COURSE]: "path completions",
  [LEARNING_ACTION_FILTER_CREATE_GROUP]: "new cohorts",
  [LEARNING_ACTION_FILTER_CREATE_DISCUSSION]: "discussion posts",
  [LEARNING_ACTION_FILTER_CREATE_RESPONSE]: "discussion replies",
  [LEARNING_ACTION_FILTER_CREATE_REGISTRATION]: "path registrations",
  [LEARNING_ACTION_FILTER_CREATE_MEMBERSHIP]: "memberships",
  [LEARNING_ACTION_FILTER_CREATE_SUBSCRIPTION]: "subscriptions",
  [LEARNING_ACTION_FILTER_PAUSED_UNPAUSED_CANCELED_SUBSCRIPTION]:
    "subscription changes"
}

export const LEARNING_ACTION_FILTERS_SCHOOL = Object.keys(
  LEARNING_ACTION_FILTER_LABELS
)

export const LEARNING_ACTION_FILTERS_USER_COURSE = [
  LEARNING_ACTION_FILTER_NONE,
  LEARNING_ACTION_FILTER_COMPLETE_STEP,
  LEARNING_ACTION_FILTER_SKIP_STEP,
  LEARNING_ACTION_FILTER_COMPLETE_COURSE,
  LEARNING_ACTION_FILTER_CREATE_GROUP,
  LEARNING_ACTION_FILTER_CREATE_DISCUSSION,
  LEARNING_ACTION_FILTER_CREATE_RESPONSE,
  LEARNING_ACTION_FILTER_CREATE_REGISTRATION
]

export const LEARNING_ACTION_FILTERS_STEP = [
  LEARNING_ACTION_FILTER_NONE,
  LEARNING_ACTION_FILTER_COMPLETE_STEP,
  LEARNING_ACTION_FILTER_SKIP_STEP,
  LEARNING_ACTION_FILTER_CREATE_DISCUSSION,
  LEARNING_ACTION_FILTER_CREATE_RESPONSE
]

export const LEARNING_ACTION_COMPLETE_STEP = 1
export const LEARNING_ACTION_SKIP_STEP = 2
export const LEARNING_ACTION_RESET_STEP = 3
export const LEARNING_ACTION_CREATE_DISCUSSION = 4
export const LEARNING_ACTION_CREATE_RESPONSE = 5
export const LEARNING_ACTION_SUBSCRIPTION_PAID = 6
export const LEARNING_ACTION_PAID_REGISTRATION = 7
export const LEARNING_ACTION_REGISTRATION = 8
export const LEARNING_ACTION_COMPLETED_COURSE = 9
export const LEARNING_ACTION_CREATE_MEMBERSHIP = 10
export const LEARNING_ACTION_SUBSCRIBED = 11
export const LEARNING_ACTION_UNSUBSCRIBED = 12
export const LEARNING_ACTION_CREATED_GROUP = 13
export const LEARNING_ACTION_PAUSED_SUBSCRIPTION = 14
export const LEARNING_ACTION_CANCELED_PAUSED_SUBSCRIPTION = 15
export const LEARNING_ACTION_UNPAUSED_SUBSCRIPTION = 16

export const COMBINED_LEARNING_ACTION_GROUP_CLASS =
  "COMBINED_LEARNING_ACTION_GROUP_CLASS"
export const COMBINED_LEARNING_ACTION_MEMBERSHIP =
  "COMBINED_LEARNING_ACTION_MEMBERSHIP"

export const LEARNING_ACTION_COMPONENTS = {
  [LEARNING_ACTION_COMPLETE_STEP]: CompleteStepActivity,
  [LEARNING_ACTION_CREATE_DISCUSSION]: CreateDiscussionActivity,
  [LEARNING_ACTION_CREATE_RESPONSE]: CreateResponseActivity,
  [LEARNING_ACTION_SKIP_STEP]: SkipStepActivity
  // [LEARNING_ACTION_REGISTRATION]: RegistrationActivity,
  // [LEARNING_ACTION_COMPLETED_COURSE]: CompleteCourseActivity,
  // [LEARNING_ACTION_CREATE_MEMBERSHIP]: CreateMemberActivity,
  // [LEARNING_ACTION_SUBSCRIBED]: SubscribedActivity,
  // [LEARNING_ACTION_UNSUBSCRIBED]: CancelSubscriptionActivity,
  // [LEARNING_ACTION_CREATED_GROUP]: CreateGroupActivity,
}

const STEP = "step"
const GROUP = "group"
const RESOURCE = "resource"
const DISCUSSION = "discussion"
const RESPONSE = "discussion_response"

// Required values per learning action in order for displaying action in UI
export const LEARNING_ACTION_EXPECTS = {
  [LEARNING_ACTION_COMPLETE_STEP]: [STEP, GROUP, RESOURCE],
  [LEARNING_ACTION_SKIP_STEP]: [STEP, GROUP, RESOURCE],
  [LEARNING_ACTION_RESET_STEP]: [STEP, GROUP, RESOURCE],
  [LEARNING_ACTION_CREATE_DISCUSSION]: [GROUP, RESOURCE, DISCUSSION],
  [LEARNING_ACTION_CREATE_RESPONSE]: [GROUP, RESOURCE, DISCUSSION, RESPONSE],
  [LEARNING_ACTION_SUBSCRIPTION_PAID]: [],
  [LEARNING_ACTION_PAID_REGISTRATION]: [],
  [LEARNING_ACTION_REGISTRATION]: [RESOURCE, GROUP],
  [LEARNING_ACTION_COMPLETED_COURSE]: [RESOURCE],
  [LEARNING_ACTION_CREATE_MEMBERSHIP]: [],
  [LEARNING_ACTION_SUBSCRIBED]: [],
  [LEARNING_ACTION_UNSUBSCRIBED]: [],
  [LEARNING_ACTION_PAUSED_SUBSCRIPTION]: [],
  [LEARNING_ACTION_CANCELED_PAUSED_SUBSCRIPTION]: [],
  [LEARNING_ACTION_UNPAUSED_SUBSCRIPTION]: [],
  [LEARNING_ACTION_CREATED_GROUP]: [],
  [COMBINED_LEARNING_ACTION_GROUP_CLASS]: [RESOURCE, GROUP],
  [COMBINED_LEARNING_ACTION_MEMBERSHIP]: []
}
