import { useQuery } from "@apollo/client"
import { REGISTRATION_ROLES } from "../registration/constants"
import ASSIGN_ROLES_QUERY from "./graphql/assign-roles-query"

export const useAssignRoles = () => {
  const assignRolesQuery = useQuery(ASSIGN_ROLES_QUERY)
  const assignRoles = assignRolesQuery.data?.assignRoles || []

  const defaultRoleOrder = [
    REGISTRATION_ROLES.LEARNER,
    REGISTRATION_ROLES.MODERATOR,
    REGISTRATION_ROLES.TEACHER,
    REGISTRATION_ROLES.OBSERVER,
    REGISTRATION_ROLES.COURSE_EDITOR
  ]

  const defaultAssignRole =
    defaultRoleOrder.find((role) => assignRoles.includes(role.str)) ||
    // Possibly we should not use a default when no assign roles are found since
    // the user may simply not have permission to add user to cohort as any role.
    defaultRoleOrder[0]

  return {
    assignRoles,
    defaultAssignRole: defaultAssignRole.int,
    assignRolesQuery
  }
}
