import { HStack } from "@chakra-ui/react"
import Avatar from "@pathwright/ui/src/components/avatar/Avatar"
import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import Button from "@pathwright/ui/src/components/button/Button"
import Dropdown from "@pathwright/ui/src/components/dropdown/Dropdown"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import PreventOrphan from "@pathwright/ui/src/components/text/PreventOrphan"
import ChakraTooltip from "@pathwright/ui/src/components/tooltip/ChakraTooltip"
import moment from "moment"
import { useEffect, useState } from "react"
import styled from "styled-components"
import { usePathwrightContext } from "../../../pathwright/PathwrightContext"
import { useReviewContext } from "../../state/ReviewContextProvider"
import { Score } from "../../ui/meta"
import {
  isItemReviewable,
  isUsersCompletion,
  useUserCanReviewItem
} from "../../utils"
import ReviewCompletionForm from "./ReviewCompletionForm"

const InboxReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: 100%;
`

const ReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  max-height: 100%;

  border-radius: 16px;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

  .RichTextInput {
    width: 100%;
    max-width: 100%;
    padding: 4px;
    /* border-radius: 8px; */
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #fff;

    .UIFieldWrapper__input {
      width: 100%;
    }

    .ql-editor {
      max-width: 100%;
      min-height: 160px;
      border: 0px;
      border-radius: 4px;
    }
  }

  .Tooltip {
    min-width: 180px;
    white-space: normal;
    text-align: left;
  }
`

const ReviewCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;

  > span,
  > div {
    margin-bottom: 10px;
  }

  .review-comments {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin: 10px 0;
    padding: 4px 10px;
  }

  .Dropdown {
    position: absolute !important;
    top: 0.5em;
    right: 0.5em;

    &:before {
      content: "";
      min-width: 100%;
      min-height: 100%;
      line-height: 40px;
      background: white;
      display: block;
      position: absolute;
      padding: 1.2em;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 100%;
    }
  }

  .review-date {
    color: rgba(0, 0, 0, 0.8);
    font-size: 12px;
  }
`

const ReviewNotesContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 0px;

  .Avatar {
    margin-right: 5px;
  }

  > main {
    display: flex;
    flex-direction: column;

    .review-notes {
      background-color: rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      padding: 10px;
      width: 100%;
      margin-bottom: 0.5em;
      > p {
        margin: 0px;
      }
    }
  }
`

const ReviewerBlankSlate = ({ message }) => {
  const { t } = useTranslate()
  const { selected } = useReviewContext()
  // If the completion is reviewable, the user must not have access to review at this point.
  const heading = isItemReviewable(selected)
    ? t("You don’t have access to leave a Review.")
    : t("Nothing to Review.")
  const learnMore = (
    <Button
      styleType={"text"}
      label={t("Learn more about Reviews, Points, & more")}
      href="https://help.pathwright.com/en/articles/6293036-make-any-step-reviewable"
      target="_blank"
      postIcon="external-link"
    />
  )
  return (
    <BlankSlate
      heading={<PreventOrphan text={heading} />}
      body={
        message ? (
          <>
            {message}
            <br />
            {learnMore}
          </>
        ) : (
          learnMore
        )
      }
    />
  )
}

const RevieweeBlankSlate = () => {
  const { t } = useTranslate()
  return (
    <BlankSlate
      heading={t("Pending review. ")}
      // TODO: need "Learn more" link? For learners, maybe not?
      body={t("You’ll be notified once a review is posted.")}
    />
  )
}

const ReviewedBy = ({ item }) => {
  const { t } = useTranslate()
  const {
    completion: { checked_by, checked_date }
  } = item.data

  return checked_by ? (
    <HStack className="review-date" spacing={1}>
      <span>
        {t("Reviewed by {{ user }}", {
          user: checked_by.display_name
        })}
      </span>
      <ChakraTooltip title={moment(checked_date).format("MMM D, YYYY h:mm A")}>
        <span>{moment(checked_date).fromNow()}</span>
      </ChakraTooltip>
    </HStack>
  ) : null
}

const ReviewNotes = ({ item }) => {
  const {
    completion: { checked_by, checked_date, userpoints_explanation: notes }
  } = item.data
  if (!notes) {
    return null
  }

  return (
    <ReviewNotesContainer>
      <Avatar user={checked_by} size={40} />
      <main>
        <div
          className="review-notes"
          dangerouslySetInnerHTML={{ __html: notes }}
        />
      </main>
    </ReviewNotesContainer>
  )
}

const ReviewCard = ({ onEdit }) => {
  const { t } = useTranslate()
  const {
    selected,
    actions: { resetSelectedReview }
  } = useReviewContext()
  const userCanReviewItem = useUserCanReviewItem(selected)

  const step = selected.data
  const { completion, permissions } = step

  const { userpoints_value, checked_by } = completion

  const showScore = userpoints_value > 0 && !!checked_by

  return (
    <ReviewCardContainer>
      {showScore && (
        <div>
          <label>Score </label>
          <Score item={selected} />
        </div>
      )}
      <ReviewNotes item={selected} />
      <ReviewedBy item={selected} />
      {userCanReviewItem && (
        <Dropdown
          items={[
            {
              icon: "recur",
              label: t("Reset review"),
              meta: t("Make Pending Review for {{ user }} and all reviewers.", {
                user: completion.user.first_name
              }),
              onClick: resetSelectedReview
            },
            {
              icon: "edit",
              label: t("Edit review"),
              meta: t("Edit review points or notes.", {
                user: completion.user.first_name
              }),
              onClick: onEdit
            }
          ]}
        />
      )}
    </ReviewCardContainer>
  )
}

const Review = () => {
  const { t } = useTranslate()

  const [isEditing, setIsEditing] = useState(false)
  const { selected } = useReviewContext()
  const pwContext = usePathwrightContext()
  const step = selected.data
  const { completion } = step

  const isReviewed = !!completion.checked_date && !completion.needs_grading
  const isCompleted = !!completion.completion_date

  const handleEdit = () => setIsEditing(true)
  const handleCancelEdit = () => setIsEditing(false)

  const getBlankSlateMessage = () => {
    if (!isCompleted) {
      return t("This step is incomplete.")
    }
  }

  useEffect(() => {
    if (isEditing) handleCancelEdit()
  }, [completion.checked_date])

  const userCanReviewItem = useUserCanReviewItem(selected)
  const isUserCompletion = isUsersCompletion(selected, pwContext)

  return (
    <ReviewContainer>
      {isReviewed && !isEditing ? (
        <ReviewCard onEdit={handleEdit} />
      ) : userCanReviewItem ? (
        <ReviewCompletionForm onCancel={handleCancelEdit} />
      ) : isUserCompletion ? (
        <RevieweeBlankSlate />
      ) : (
        <ReviewerBlankSlate message={getBlankSlateMessage()} />
      )}
    </ReviewContainer>
  )
}

const InboxReview = () => {
  const { selected: step } = useReviewContext()

  if (!step) return null

  return (
    <InboxReviewContainer>
      {/* <ContextPropertiesList properties={reviewProperties} /> */}
      <Review key={step.id} />
    </InboxReviewContainer>
  )
}

export default InboxReview
