import classnames from "classnames"
import FieldWrapper from "../form-utils/FieldWrapper"
import "./RadioGroup.css"

const RadioGroup = ({ className, description, children, ...rest }) => {
  return (
    <FieldWrapper
      {...rest}
      className={classnames("RadioGroup", className)}
      fixedLabel
    >
      {description && <p className="RadioGroup__description">{description}</p>}
      {children}
    </FieldWrapper>
  )
}

RadioGroup.displayName = "RadioGroup"

export default RadioGroup
