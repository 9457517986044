import gql from "graphql-tag"

export const CERTIFICATE_FRAGMENT = gql`
  fragment Certificate on Certificate {
    id
    name
    template
    text_objects
  }
`

export const CERTIFICATE_QUERY = gql`
  query CertificateQuery {
    certificate {
      ...Certificate
    }
  }
  ${CERTIFICATE_FRAGMENT}
`

// export const CERTIFICATES_QUERY = gql`
//   query CertificatesQuery($first: Int!) {
//     certificates(first: $first) {
//       edges {
//         node {
//           ...Certificate
//         }
//       }
//     }
//   }
//   ${CERTIFICATE_FRAGMENT}
// `

export const CERTIFICATE_PREVIEW_QUERY = gql`
  query CertificatePreviewQuery(
    $template: String!
    $textObjects: [TextObject]!
    $variables: JSON
  ) {
    certificatePreview(
      template: $template
      text_objects: $textObjects
      variables: $variables
    )
  }
`

export const CERTIFICATE_CREATE_MUTATION = gql`
  mutation CertificateCreateMutation(
    $name: String
    $template: String!
    $textObjects: [TextObject]
    $isActive: Boolean = true
  ) {
    createCertificate(
      name: $name
      template: $template
      text_objects: $textObjects
      is_active: $isActive
    ) {
      ...Certificate
    }
  }
  ${CERTIFICATE_FRAGMENT}
`

export const CERTIFICATE_UPDATE_MUTATION = gql`
  mutation CertificateUpdateMutation(
    $id: Int!
    $name: String
    $template: String
    $textObjects: [TextObject]
  ) {
    updateCertificate(
      id: $id
      name: $name
      template: $template
      text_objects: $textObjects
    ) {
      ...Certificate
    }
  }
  ${CERTIFICATE_FRAGMENT}
`

export const CERTIFICATE_DELETE_MUTATION = gql`
  mutation CertificateDeleteMutation($id: Int!) {
    deleteertificate(id: $id)
  }
  ${CERTIFICATE_FRAGMENT}
`

export const RESOURCE_CERTIFICATE_CONTEXT_QUERY = gql`
  query ResourceCertificateContextQuery($id: Int!) {
    resource(id: $id) {
      id
      name
      resource_type
      has_certificate
    }
  }
`

export const CERTIFICATE_VARIABLES_QUERY = gql`
  query CertificateVariablesQuery($context: CertificateVariablesContextInput) {
    certificateVariables(context: $context) {
      id
      stats
      variables
    }
  }
`

export const UPDATE_CERTIFICATE_VARIABLES_MUTATION = gql`
  mutation UpdateCertificateVariablesMutation(
    $merge: [CertificateVariablesMergeInput]!
    $context: CertificateVariablesContextInput
  ) {
    updateCertificateVariables(merge: $merge, context: $context) {
      id
      stats
      variables
    }
  }
`
