const Spinner = require("spin.js")

class PaginatedCollection extends require("lib/static-shim").default(
  Marionette.Behavior
) {
  static initClass() {
    this.prototype.ui = {
      next: "[data-action='next']",
      previous: "[data-action='previous']"
    }

    this.prototype.defaults = { stateModelKey: "state" }

    this.prototype.events = {
      "click @ui.next": "onPaginateNext",
      "click @ui.previous": "onPaginatePrevious"
    }

    // Make sure the view is instantiated with a collection
    // If collection set in initialize, the listener will not be set.
    this.prototype.collectionEvents = { page: "_setPageData" }
  }

  initialize() {
    return (this.spinOpts = Pathwright.utils.getSpinnerConfigSmall({
      left: "calc(50% - 48px)",
      top: "18px"
    }))
  }

  onPaginateNext(e) {
    e.preventDefault()
    const state = this.view[this.options.stateModelKey]
    if (!state.get("paginating")) {
      return this.paginate(this.ui.next, "next")
    }
  }

  onPaginatePrevious(e) {
    e.preventDefault()
    const state = this.view[this.options.stateModelKey]
    if (!state.get("paginating")) {
      return this.paginate(this.ui.previous, "previous")
    }
  }

  paginate(ui, paginateFunction) {
    this.view.triggerMethod("before:paginate")
    const state = this.view[this.options.stateModelKey]
    state.set("paginating", true)
    const spinner = new Spinner(this.spinOpts).spin()
    ui.prepend(spinner.el)
    return this.view.collection[paginateFunction]().then(() => {
      spinner.stop()
      state.set("paginating", false)
      return this.view.triggerMethod("paginate")
    })
  }

  onRender() {
    return _.defer(() => this._setPageData(this.view.collection))
  }

  onSetPageData() {
    return this._setPageData(this.view.collection)
  }

  _setPageData(collection) {
    if (collection == null) {
      return
    }
    const state = this.view[this.options.stateModelKey]
    if (!state) {
      return
    }
    // Clear state. Otherwise an issue if this is a cached colleciton.
    // If has_next is true and is set to true, no change is triggered
    state.clear()

    state.set("has_next", collection.nextPage > 0)
    state.set("has_previous", collection.previousPage > 0)
    state.set("next_count", collection.getNextCount())
    state.set("total_count", collection.count)
    return state.set("previous_count", collection.getPreviousCount())
  }
}

PaginatedCollection.initClass()
export default PaginatedCollection
