import AUTHORS_QUERY from "../author/graphql/authors-query"
import CATEGORIES_QUERY from "../category/graphql/categories-query"
import { COHORTS_QUERY } from "../cohort/Cohorts"
import GROUP_QUERY from "../group/graphql/group-query"
import GROUPS_QUERY from "../group/graphql/groups-query"
import { USER_RESOURCES_QUERY } from "../home/design/Design"
import { USER_REGISTRATION_QUERY } from "../home/learn/Learn"
import LIBRARY_RESOURCES_QUERY from "../library/graphql/library-resources-query"
import PATH_QUERY from "../path/graphql/path-query"
import MANAGE_RESOURCE_QUERY from "../resource/manage/graphql/manage-resource-query"

// Use this class to sync between apollo client
// and legacy/client stores.

// When a legacy/client store updates a
// resource, LegacyStoreBridge provides hooks
// to manually refetch or update the resource
// stored in apollo client.

// This is just a temporary solution while we
// update small pieces of the UI to use graphql.

// TODO: delete methods as legacy/client
// stores that use them are removed.
// Once legacy/client stores have been
// replaced this module can be removed.

class ApolloClientStoreBridge {
  constructor(client) {
    this.client = client
  }

  refetchResource(variables) {
    // requires id
    this.client.query({
      variables,
      query: MANAGE_RESOURCE_QUERY,
      fetchPolicy: "network-only"
    })
  }

  refetchAuthors(variables) {
    // requires school_id, first
    this.client.query({
      variables,
      query: AUTHORS_QUERY,
      fetchPolicy: "network-only"
    })
  }

  refetchCategories(variables) {
    // requires school_id, first
    this.client.query({
      variables,
      query: CATEGORIES_QUERY,
      fetchPolicy: "network-only"
    })
  }

  refetchTeachingResources(variables) {
    this.client.query({
      query: COHORTS_QUERY,
      fetchPolicy: "network-only"
    })
  }

  refetchLibraryResources() {
    this.client.query({
      variables: { first: 24 },
      query: LIBRARY_RESOURCES_QUERY,
      fetchPolicy: "network-only"
    })
  }

  refetchGroup(variables) {
    // requires id
    this.client.query({
      variables,
      query: GROUP_QUERY,
      fetchPolicy: "network-only"
    })
  }

  refetchGroups(variables) {
    // requires resource_id, first
    this.client.query({
      variables,
      query: GROUPS_QUERY,
      fetchPolicy: "network-only"
    })
  }

  refetchRegistrations(variables) {
    // requires id, progress_filter, role_filter
    this.client.query({
      variables,
      query: USER_REGISTRATION_QUERY,
      fetchPolicy: "network-only"
    })
  }

  refetchResources(variables) {
    this.client.query({
      variables,
      query: USER_RESOURCES_QUERY,
      fetchPolicy: "network-only"
    })
  }

  refetchPath(variables = {}) {
    // requires id
    this.client.query({
      variables: {
        first: 24,
        ...variables
      },
      query: PATH_QUERY,
      fetchPolicy: "network-only"
    })
  }
}

export default ApolloClientStoreBridge
