import gql from "graphql-tag"
import PATH_PERFORMANCE_FRAGMENT from "./path-performance-fragment"

export default gql`
  query PathPerformanceQuery($id: Int!) {
    path(id: $id) {
      id
      performance {
        ...PathPerformance
      }
    }
  }
  ${PATH_PERFORMANCE_FRAGMENT}
`
