import Avatar from "@pathwright/ui/src/components/avatar/Avatar"
import IconButton from "@pathwright/ui/src/components/button/IconButton"
import classnames from "classnames"
import { INVITATION_ERROR_MESSAGES } from "invitation/constants"
import { observer } from "mobx-react"
import "./InviteField.css"

const getErrorMessage = (error) => {
  if (error) {
    if (error.code) {
      return INVITATION_ERROR_MESSAGES[error.code]
    }
    return error
  }
}

const InviteField = ({ user, invite, onRemove }) => {
  return (
    <li
      className={classnames(
        "InviteField",
        invite.error && "InviteField--error"
      )}
    >
      <Avatar initials={invite.email[0]} user={invite.user} size="36px" />
      <div className="InviteField__meta">
        {!invite.error && invite.user && (
          <h4 className="InviteField__user">
            {invite.user.first_name} {invite.user.last_name}
          </h4>
        )}
        <label className="InviteField__email">{invite.email}</label>
        <span className="InviteField__error">
          {getErrorMessage(invite.error)}
        </span>
      </div>
      <IconButton onClick={onRemove} tabIndex={-1} icon="x" color="#aaa" />
    </li>
  )
}

export default observer(InviteField)
