import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import Button from "@pathwright/ui/src/components/button/Button"
import Card from "@pathwright/ui/src/components/card/Card"
import Error from "@pathwright/ui/src/components/error/Error"
import withTranslate from "@pathwright/ui/src/components/lng/withTranslate"
import PropTypes from "prop-types"
import { useState } from "react"
import styled from "styled-components"
import { usePathwrightContext } from "../../../pathwright/PathwrightContext"
import { useShouldShowStripeReconnect } from "./StripeAlert"

const StyledList = styled.ul`
  list-style: disc;
  padding-left: 20px;

  li + li {
    margin-top: 0.8em;
  }
`

const ManageStripe = ({
  card,
  disconnectStripe,
  stripe_access_token,
  stripe_reconnect_url,
  stripeConnectError,
  t
}) => {
  const [disconnecting, setDisconnecting] = useState(false)
  const { school } = usePathwrightContext()
  const shouldShowStripeReconnect = useShouldShowStripeReconnect()

  if (stripeConnectError) {
    return (
      <Card card={card} title={`${school.name} Stripe Connection`}>
        <Error
          errorTitle={
            disconnecting
              ? t(
                  "Something went wrong when disconnecting your Stripe account."
                )
              : t("Something went wrong when connecting your Stripe account.")
          }
          errorDetails={stripeConnectError}
          email="hello@pathwright.com"
          primaryAction={{
            label: t("Contact hello@pathwright.com")
          }}
        />
      </Card>
    )
  }

  if (disconnecting) {
    return (
      <Card card={card} title={`${school.name} Stripe Connection`}>
        <BlankSlate
          key="confirm" // fixes odd primary action sizer issue
          icon="caution-triangle"
          heading={t("Are you sure you want to disconnect Stripe?")}
          body={t(`Disconnecting Stripe removes your ability to take payments. 
          Additionally, if you have other Pathwright accounts connected to the same Stripe account, they will be disconnected as well.`)}
          primaryAction={{
            children: t("Yes, Disconnect Stripe"),
            dangerous: true,
            onClick: disconnectStripe
          }}
          secondaryAction={{
            children: t("Cancel"),
            onClick: () => setDisconnecting(false)
          }}
        />
      </Card>
    )
  }

  if (shouldShowStripeReconnect) {
    return (
      <Card card={card} title={`Upgrade your Stripe Connection`}>
        <BlankSlate
          icon="caution-triangle"
          heading="To enhance the security of your Stripe integration,
          please upgrade your connection."
          body={
            <div style={{ textAlign: "left" }}>
              <p>When you click the button below:</p>
              <StyledList>
                <li>You'll be redirected to Stripe.</li>
                <li>
                  Please login with the same Stripe account that's currently
                  connected to your school and follow the instructions.
                </li>
                <li>Once you're finished, you'll be redirected back here.</li>
              </StyledList>
              <p>
                For a step-by-step, see{" "}
                <Button
                  styleType="text"
                  target="_blank"
                  href="http://help.pathwright.com/en/articles/4360617-upgrade-your-stripe-connection"
                >
                  How to upgrade your Stripe connection
                </Button>
              </p>
            </div>
          }
          primaryAction={{
            children: "Upgrade your Stripe Connection",
            href: stripe_reconnect_url
          }}
        />
      </Card>
    )
  }

  return (
    <Card card={card} title={`${school.name} Stripe Connection`}>
      <BlankSlate
        icon="check-circle"
        heading="You're all set to take payments"
        body={`You're all setup with Stripe.com using the Stripe ID ${stripe_access_token}`}
        primaryAction={{
          children: "Disconnect Stripe",
          dangerous: true,
          onClick: () => setDisconnecting(true)
        }}
      />
    </Card>
  )
}

ManageStripe.displayName = "ManageStripe"

ManageStripe.propTypes = {
  card: PropTypes.object.isRequired,
  disconnectStripe: PropTypes.func.isRequired,
  stripe_access_token: PropTypes.string.isRequired,
  send_stripe_receipts: PropTypes.bool.isRequired
}

export default withTranslate(ManageStripe)
