import Animated from "animated/lib/targets/react-dom"
import classnames from "classnames"
import PropTypes from "prop-types"
import React from "react"
import Swipeable from "react-swipeable"
import styled from "styled-components"
import Pathicon from "../pathicon/Pathicon"
import View from "../ui/View"

const CarouselContext = React.createContext()

export const Control = styled(View.Primary)`
  cursor: pointer;
  position: absolute;
  z-index: 10;
  ${(props) => (props.dir === "next" ? "right" : "left")}: 10px;
  top: 50%;
  width: 36px;
  height: 36px;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 36px;
  transform: translateY(-50%);
  pointer-events: auto;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  html.touch & {
    display: none;
  }
`

export const CarouselControl = ({ dir }) => (
  <CarouselContext.Consumer>
    {({ infinite, totalIndeces, activeIndex, setActiveIndex }) => {
      // remove control if control cannot advance to next item
      if (!infinite) {
        if (dir === "prev" && activeIndex === 0) return null
        if (dir === "next" && activeIndex === totalIndeces - 1) return null
      }

      return (
        <Control
          className="CarouselControl"
          dir={dir}
          onClick={() =>
            setActiveIndex(activeIndex + (dir === "next" ? 1 : -1))
          }
        >
          <Pathicon icon={`chevron-${dir === "next" ? "right" : "left"}`} />
        </Control>
      )
    }}
  </CarouselContext.Consumer>
)

const Container = styled.div`
  position: relative;
  width: 100%;
`

class Carousel extends React.Component {
  state = {
    activeIndex:
      this.infinityIndexOffset +
      (this.isControlled ? this.props.activeIndex : 0),
    isSwiping: false
  }

  deltaX = new Animated.Value(this.state.activeIndex * this.props.itemWidth)

  componentDidMount() {
    if (this.props.autoPlay && this.props.autoPlaySpeed) {
      this.interval = setInterval(() => {
        // may have paused
        if (this.props.autoPlay) {
          const nextIndex = this.state.activeIndex + 1
          this.handleChange(nextIndex)
        }
      }, this.props.autoPlaySpeed)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.isControlled && prevProps.activeIndex !== this.props.activeIndex) {
      this.handleChange(this.infinityIndexOffset + this.props.activeIndex)
    }

    // itemWidth may have changed due to a window or element resize
    if (prevProps.itemWidth !== this.props.itemWidth) {
      this.getIndexOffsetAnimation().start()
    }

    if (
      !this.props.items ||
      (prevProps.items && this.props.items.length !== prevProps.items.length)
    ) {
      this.setState({ indexOffset: 0 })
    }
  }

  componentWillUnmount() {
    if (this.interval) clearInterval(this.interval)
  }

  get isControlled() {
    return typeof this.props.activeIndex === "number"
  }

  get indexOffset() {
    return this.state.activeIndex * this.props.itemWidth
  }

  // Since an infinity slider pads the beggining index of the items list by 1,
  // we need to account for that offset when calculating the active index.
  get infinityIndexOffset() {
    return this.props.infinite ? 1 : 0
  }

  getIndexOffsetAnimation(swiped = false) {
    if (swiped || !this.isControlled) {
      return Animated.spring(this.deltaX, {
        toValue: this.indexOffset,
        friction: 10,
        tension: 50
      })
    }

    // for bypassing animation
    return Animated.timing(this.deltaX, {
      toValue: this.indexOffset,
      duration: 0
    })
  }

  // When infinite loop, pad the beginning and ending indecis.
  // This way, when reaching one end, we can shift to the oppositie end,
  // then allow the animation to play out. That is, if list [1,2,3],
  // we derive list [3,1,2,3,1]. When going from index 1 to 0, we first
  // shift the active index to 4, adjust the deltaX to that position,
  // then move the active index to 3. This creates a seemless transition from
  // one end of the carousel to the other.
  get items() {
    const { items, infinite } = this.props

    return infinite
      ? [
          items[items.length - 1], // pad beginning index with last item
          ...items,
          items[0] // pad ending index with first item
        ]
      : items
  }

  get maxIndex() {
    return this.items.length - 1
  }

  handleChange = (nextIndex) => {
    let changed = false
    // clip index to the min/max possible index
    nextIndex = Math.max(0, Math.min(this.maxIndex, nextIndex))
    if (this.state.activeIndex !== nextIndex) {
      changed = true
      if (this.props.infinite) {
        this.handleIndexOffset(this.state.activeIndex, nextIndex)
      } else {
        this.updateActiveIndex(nextIndex)
      }
    }
    return changed
  }

  // in an infinite loop, we need to adjust the index offset which will in turn adjust the items list order
  handleIndexOffset = (activeIndex, nextIndex) => {
    // when next index is an extreme (0 || this.items.length - 1)
    // reset the active index to the other extreme.
    // Recalibrate deltaX to that index's position.

    // Going forward (1) or backward (-1) in the list?
    const offsetAdjust = nextIndex > activeIndex ? 1 : -1
    const isEdgeIndex = nextIndex === 0 || nextIndex === this.maxIndex

    if (isEdgeIndex) {
      this.setState(
        {
          activeIndex: nextIndex === 0 ? this.maxIndex : 0
        },
        () => {
          // recalibrating the index since we've side-effectively altered the items list order
          const recalibratedIndex = nextIndex > activeIndex ? 0 : this.maxIndex
          // reposition the animated div to compensate for the altered list
          // this will recalibrate the position of the animated div so that the current item is still active
          this.deltaX.setValue(recalibratedIndex * this.props.itemWidth)
          // now animate to the next item
          this.updateActiveIndex(recalibratedIndex + offsetAdjust)
        }
      )
    } else {
      this.updateActiveIndex(nextIndex)
    }
  }

  updateActiveIndex = (activeIndex) => {
    this.setState({ activeIndex }, () => {
      this.props.onChange(activeIndex - this.infinityIndexOffset)
      // animate to new index offset
      this.getIndexOffsetAnimation().start()
    })
  }

  handleSwiped = (e, deltaX, deltaY, isFlick, velocity) => {
    const isSideFlick = isFlick && Math.abs(deltaY) < Math.abs(deltaX) - 100 // flick movement is 100px more horizontal than vertical
    const isSideSwipe = Math.abs(deltaX) > 30 // swipe movement is more than 50px
    let swiped = false
    if (this.state.isSwiping && (isSideFlick || isSideSwipe)) {
      const indexMovement = Math.round(deltaX / this.props.itemWidth)
      const nextIndex = this.state.activeIndex + indexMovement
      swiped = this.handleChange(nextIndex)
    }

    if (swiped) {
      if (this.state.isSwiping) {
        this.setState({ isSwiping: false })
      }
    } else {
      // animate back to index offset
      this.getIndexOffsetAnimation(true /* swiped */).start()
    }
  }

  handleSwiping = (e, deltaX, deltaY, absX, absY, velocity) => {
    const isSideSwipe = Math.abs(deltaX) > Math.abs(deltaY) // swipe movement is more horizontal than vertical
    if (isSideSwipe) {
      // e.preventDefault() // cancel horizontal scroll
      // add swipe movement to index offset
      this.deltaX.setValue(this.indexOffset + deltaX)
    }

    if (isSideSwipe !== this.state.isSwiping) {
      this.setState({ isSwiping: isSideSwipe })
    }
  }

  render() {
    const {
      className,
      itemWidth,
      renderItem,
      renderHeader,
      renderFooter,
      renderControls,
      trackMouse,
      infinite
    } = this.props
    const items = this.items
    const { activeIndex } = this.state
    const passProps = {
      infinite,
      totalIndeces: items.length,
      activeIndex,
      activeItem: items[activeIndex],
      setActiveIndex: this.handleChange
    }

    return (
      <CarouselContext.Provider value={passProps}>
        <Container className={classnames("Carousel", className)}>
          {renderHeader(passProps)}
          <Swipeable
            onSwiping={this.handleSwiping}
            onSwiped={this.handleSwiped}
            // trackTouch={this.props.freeze ? false : true}
            // trackMouse={this.props.freeze ? false : trackMouse}
          >
            <Animated.div
              className="Carousel__animated-div"
              style={{
                position: "relative",
                width: itemWidth * items.length,
                height: "100%",
                display: "flex",
                alignItems: "stretch",
                justifyContent: "center",
                transform: [
                  {
                    translateX: this.deltaX.interpolate({
                      inputRange: [0, 100],
                      outputRange: ["-0px", "-100px"]
                    })
                  }
                ],
                WebkitTransform: [
                  {
                    translateX: this.deltaX.interpolate({
                      inputRange: [0, 100],
                      outputRange: ["-0px", "-100px"]
                    })
                  }
                ]
              }}
            >
              {items.map((item, index) => (
                <div
                  style={{
                    width: itemWidth,
                    minWidth: itemWidth,
                    maxWidth: itemWidth
                  }}
                  key={`${item.id}-${index}`}
                >
                  {renderItem({ ...passProps, item, index })}
                </div>
              ))}
            </Animated.div>
          </Swipeable>
          {renderControls(passProps)}
          {renderFooter(passProps)}
        </Container>
      </CarouselContext.Provider>
    )
  }
}

Carousel.displayName = "Carousel"

Carousel.propTypes = {
  // array of items
  items: PropTypes.array.isRequired,
  // requires fixed width for items.
  itemWidth: PropTypes.number.isRequired,
  // control the rendering
  renderItem: PropTypes.func.isRequired,
  renderHeader: PropTypes.func,
  renderFooter: PropTypes.func,
  renderControls: PropTypes.func,
  // listen to change activeIndex event.
  onChange: PropTypes.func,
  // turn Carousel into controlled component.
  activeIndex: PropTypes.number,
  // optionally track mouse swipes
  trackMouse: PropTypes.bool,
  // prevent swipe
  freeze: PropTypes.bool,
  // can carousel loop indefinitely
  infinite: PropTypes.bool
}

Carousel.defaultProps = {
  renderItem: () => null,
  renderHeader: () => null,
  renderFooter: () => null,
  renderControls: () => null,
  onChange: () => {},
  infinite: false
}

export default Carousel
