import gql from "graphql-tag"
import PropTypes from "prop-types"
import {
  featureActionPropType,
  featureContextPropType,
  featureKeyPropType
} from "./propTypes"

export const CONTEXTUAL_FEATURE_PERMISSION_FRAGMENT = gql`
  fragment ContextualFeaturePermission on ContextualFeaturePermission {
    id
    feature_key
    feature_action
    permission_in_context
    school_id
    resource_id
    cohort_id
  }
`

export const CONTEXTUAL_FEATURE_PERMISSION_QUERY = gql`
  query ContextualFeaturePermissionQuery(
    $featureKey: FeatureKeyType!
    $featureAction: FeatureActionType!
    $context: FeatureContextInput
  ) {
    contextualFeaturePermission(
      feature_action: $featureAction
      feature_key: $featureKey
      context: $context
    ) {
      ...ContextualFeaturePermission
    }
  }
  ${CONTEXTUAL_FEATURE_PERMISSION_FRAGMENT}
`

CONTEXTUAL_FEATURE_PERMISSION_QUERY._propTypes = {
  featureAction: featureActionPropType,
  featureKey: featureKeyPropType,
  context: featureContextPropType
}

export const CONTEXTUAL_FEATURE_PERMISSIONS_QUERY = gql`
  query ContextualFeaturePermissionsQuery(
    $featureKeys: FeatureKeyFilter
    $featureAction: FeatureActionType
    $context: FeatureContextInput
  ) {
    contextualFeaturePermissions(
      feature_action: $featureAction
      feature_keys: $featureKeys
      context: $context
    ) {
      ...ContextualFeaturePermission
    }
  }
  ${CONTEXTUAL_FEATURE_PERMISSION_FRAGMENT}
`

CONTEXTUAL_FEATURE_PERMISSIONS_QUERY._propTypes = {
  featureAction: featureActionPropType,
  featureKeys: PropTypes.arrayOf(featureKeyPropType),
  context: featureContextPropType
}

export const CREATE_CONTEXTUAL_FEATURE_PERMISSION_MUTATION = gql`
  mutation CreateContextualFeaturePermissionMutation(
    $featureKey: FeatureKeyType!
    $featureAction: FeatureActionType!
    $permissionInContext: FeaturePermissionType!
    $context: FeatureContextInput
  ) {
    createContextualFeaturePermission(
      feature_action: $featureAction
      feature_key: $featureKey
      permission_in_context: $permissionInContext
      context: $context
    ) {
      ...ContextualFeaturePermission
    }
  }
  ${CONTEXTUAL_FEATURE_PERMISSION_FRAGMENT}
`

export const UPDATE_CONTEXTUAL_FEATURE_PERMISSION_MUTATION = gql`
  mutation UpdateContextualFeaturePermissionMutation(
    $featureKey: FeatureKeyType!
    $featureAction: FeatureActionType!
    $permissionInContext: FeaturePermissionType!
    $context: FeatureContextInput
  ) {
    updateContextualFeaturePermission(
      feature_action: $featureAction
      feature_key: $featureKey
      permission_in_context: $permissionInContext
      context: $context
    ) {
      ...ContextualFeaturePermission
    }
  }
  ${CONTEXTUAL_FEATURE_PERMISSION_FRAGMENT}
`

export const DELETE_CONTEXTUAL_FEATURE_PERMISSION_MUTATION = gql`
  mutation DeleteContextualFeaturePermissionMutation(
    $featureKey: FeatureKeyType!
    $featureAction: FeatureActionType!
    $context: FeatureContextInput
  ) {
    deleteContextualFeaturePermission(
      feature_action: $featureAction
      feature_key: $featureKey
      context: $context
    )
  }
`
