export type DomainEnvType = "production" | "staging" | "development" | "testing"
export type EnvType = DomainEnvType | "test"

// map of pathwright domains to environment
export const PATHWRIGHT_DOMAIN_RES = new Map<RegExp, DomainEnvType>([
  [/\.(pathwright)\.com/, "production"],
  [/\.(pathwrightstaging)\.com/, "staging"],
  [/\.(pathwrightdev)\.com/, "development"],
  [/\.(pantswright)\.com/, "testing"]
])

// get the environment that matches the window.location.hostname
export const getDomainEnv = (): DomainEnvType | void => {
  // Check for window (which will not be present in node env).
  if (typeof window !== "undefined") {
    const domainEnvEntry = Array.from(PATHWRIGHT_DOMAIN_RES.entries()).find(
      ([re]) => re.test(window.location.hostname)
    )

    if (domainEnvEntry) return domainEnvEntry[1]
  }
}

// get the pathwright domain from the window.location.hostname
export const getPathwrightDomain = () => {
  // Check for window (which will not be present in node env).
  if (typeof window !== "undefined") {
    const re = Array.from(PATHWRIGHT_DOMAIN_RES.keys()).find((re) =>
      re.test(window.location.hostname)
    )
    if (re) {
      const match = window.location.hostname.match(re)
      if (match) return match[1]
    }
  }
}

export function getEnv(): EnvType
export function getEnv(targetEnv: EnvType | EnvType[]): Boolean
export function getEnv(targetEnv: Record<EnvType | "_", any>): any
export function getEnv(
  targetEnv?: EnvType | EnvType[] | Record<EnvType | "_", any>
): Boolean | any {
  // get environment based on domain
  let env: EnvType = "development"
  let domainEnv = getDomainEnv()

  switch (process.env.NODE_ENV as EnvType) {
    // only allow production environment when set via NODE_ENV
    case "production":
      // and when not overridden by domain
      env = domainEnv || "production"
      break
    case "test":
      // Ensure test env is not overridden.
      env = "test"
      break
    default:
      // default to the domain environment
      env = domainEnv || "development"
      break
  }

  if (targetEnv) {
    // targetEnv may include env as item in array
    if (Array.isArray(targetEnv)) return targetEnv.includes(env)
    // targetEnv may include env as key in object, or default to _ key
    if (typeof targetEnv === "object")
      return targetEnv.hasOwnProperty(env) ? targetEnv[env] : targetEnv._
    // targetEnv may match env as string
    if (typeof targetEnv === "string") return env === targetEnv
  }

  return env
}

export const getIsLocalHost = () => !getDomainEnv() && !getEnv("production")
export const getIsLocalDev = () => getIsLocalHost() || getEnv("development")
