import partial from "lodash/partial"
import { redirect } from "react-router-dom"
import { useAsyncFn } from "react-use"
import { z } from "zod"
import { zfd } from "zod-form-data"
import { getClient } from "../../../../api/client"
import {
  UserStatusDocument,
  UserStatusInputType,
  UserStatusQuery,
  UserStatusQueryVariables
} from "../../../../api/generated"
import { exhaustiveGuard } from "../../../../lib/utils/exhaustive-switch"
import getAuthRoute from "../../utils/getAuthRoute"
import { getAuthMethodForUserStatus } from "../../utils/preferredAuthMethod"
import startAuthRequestAction, {
  startAuthRequest
} from "../auth-request/api/action"

const emailSchema = z.string().email()

// NOTE: allowSignup is not a variable to be passed to the userStatus query,
// but rather an option for determining how to handle the result of the userStatus query.
type Variables = UserStatusQueryVariables & { allowSignup: boolean }

// Requiring either `email` or `username` args, but not allowing both.
export const schema = z
  .object({
    email: z.union([emailSchema, z.string()]),
    allowSignup: z.boolean()
  })
  .transform<Variables>((val) => ({
    input: val.email,
    allowSignup: val.allowSignup
  }))
export const formDataSchema = zfd
  .formData({
    email: z.union([emailSchema, z.string()]),
    allowSignup: zfd.checkbox({ trueValue: "true" })
  })
  .transform<Variables>((val) => ({
    input: val.email,
    allowSignup: val.allowSignup
  }))

export type SchemaType = z.input<typeof schema>

export const getUserStatus = async (
  options: Variables
): Promise<UserStatusQuery["userStatus"]> => {
  const { allowSignup, ...variables } = options
  const client = getClient()
  const result = await client.query<UserStatusQuery, UserStatusQueryVariables>({
    query: UserStatusDocument,
    variables,
    fetchPolicy: "network-only"
  })

  const { userStatus } = result?.data

  // If a user does not exist matching the username or email, we may need to
  // indicate that the username/email they submitted is not associated with an existing user.
  if (userStatus && !userStatus.user_exists) {
    // In the below cases we can throw the error since it is not possible to proceed down
    // the auth path...
    switch (userStatus.input_type) {
      case UserStatusInputType.Username:
      case UserStatusInputType.Proxy:
        // ...you currently cannot signup with a username, only email
        throw new Error(`No account associated with ${variables.input} found.`)
      case UserStatusInputType.Email:
        if (!allowSignup) {
          // ...you cannot signup with email when the space does not allow signup
          // (this takes into account any invite token that was provided)
          throw new Error(
            `No account associated with ${variables.input} found.`
          )
        }
        break
      default:
        exhaustiveGuard(userStatus.input_type)
    }
  }

  return userStatus
}

// Both querying for the existance/verification of an email *and* (potentially)
// starting an auth request depending on if the user needs to verify their email address.
// Main motiviation for combining the two is so that the UI can handle that case as one action.
const getUserStatusWithAuthRequest = async (
  formData: FormData
): Promise<
  | Awaited<ReturnType<typeof getUserStatus>>
  | Awaited<ReturnType<typeof startAuthRequest>>
> => {
  const userStatus = await getUserStatus(formDataSchema.parse(formData))
  if (userStatus?.user_exists && !userStatus.email_verified) {
    // The email is not verified, so issue an auth request to verify the email.
    return startAuthRequest(formData)
  }

  if (
    userStatus?.user_exists &&
    !userStatus.has_membership &&
    userStatus.input_type !== UserStatusInputType.Proxy
  ) {
    // The email is verified, but the user is not yet a member of the space.
    // Issue an auth request which will avoid tricky situations like when a user
    // doesn't know they already have a user account.
    return startAuthRequest(formData)
  }

  return userStatus
}

export const useUserStatusWithAuthRequest = partial(
  useAsyncFn<typeof getUserStatusWithAuthRequest>,
  getUserStatusWithAuthRequest
)

const action = async (
  formData: FormData
): Promise<Response | Awaited<ReturnType<typeof startAuthRequestAction>>> => {
  const userStatus = await getUserStatus(formDataSchema.parse(formData))
  if (userStatus?.user_exists) {
    if (userStatus.input_type !== UserStatusInputType.Proxy) {
      // A user for this email exists.
      if (!userStatus.has_membership) {
        // The email is not verified, so issue an auth request to verify the email.
        return startAuthRequestAction(formData)
      }
      if (userStatus.email_verified) {
        // The email is verified, we need to redirect them to an auth method form.
        return redirect(
          getAuthRoute(
            `/auth/sign-in/${getAuthMethodForUserStatus(userStatus)}`
          )
        )
      } else {
        // The email is not verified, so issue an auth request to verify the email.
        return startAuthRequestAction(formData)
      }
    } else {
      return redirect(getAuthRoute(`/auth/sign-in/password`))
    }
  } else {
    // A user for this email does not exist, so redirect them to the sign up form.
    return redirect(getAuthRoute(`/auth/sign-up`))
  }
}

export default action
