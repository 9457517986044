import ManageResourceDetails from "@pathwright/web/src/modules/resource/manage/ManageResourceDetails"
import "./ManageResourceDetailsContainer.css"

const ManageResourceDetailsContainer = (props) => {
  return (
    <ManageResourceDetails
      fetchPolicy="network-only"
      card={props.card}
      pathname={props.location.pathname}
      id={parseInt(props.match.params.resource_id)}
      // id={parseInt(props.match.params.resource_id)}
      onSubmitSuccess={() => {
        // trigger resource:updated so store-connected UIs update
        return window.App.getStore("resourceAdmin")
          .action.loadResource(parseInt(props.match.params.resource_id), true)
          .promise.then(() =>
            window.App.getStore("resourceAdmin").storeTrigger(
              "resource:updated"
            )
          )
      }}
    />
  )
}

export default ManageResourceDetailsContainer
