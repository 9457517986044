import ReactSelectInput from "@pathwright/ui/src/components/form/form-select/ReactSelectInput"
import ReactSelectInputOption from "@pathwright/ui/src/components/form/form-select/ReactSelectInputOption"
import ReactSelectInputOptionLabel from "@pathwright/ui/src/components/form/form-select/ReactSelectInputOptionLabel"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import { useEffect, useState } from "react"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import { canAdministrate } from "../../user/permissions"
import {
  FEATURE_PERMISSION_ADMIN_ONLY,
  FEATURE_PERMISSION_EVERYONE,
  FEATURE_PERMISSION_STAFF_ONLY
} from "../constants"
import { permissionInContextPropType } from "../propTypes"
import { getFeaturePermissionInfo } from "../utils"

const Option = (props) => {
  const { t } = useTranslate()
  const { featureKey } = props.selectProps
  const { icon, title, desc } = getFeaturePermissionInfo(
    featureKey,
    props.data.value,
    t
  )

  return (
    <ReactSelectInputOption
      {...props}
      icon={icon}
      label={title}
      descriptor={desc}
    />
  )
}

const OptionLabel = ({ value, featureKey }) => {
  const { t } = useTranslate()
  const { icon, title } = getFeaturePermissionInfo(featureKey, value, t)
  return <ReactSelectInputOptionLabel icon={icon} label={title} />
}

const FeatureVisibilitySelect = ({
  value: valueProp,
  onChange,
  featureKey,
  ...rest
}) => {
  const [value, setValue] = useState(valueProp || FEATURE_PERMISSION_EVERYONE)
  const pwContext = usePathwrightContext()

  const options = [
    { value: FEATURE_PERMISSION_EVERYONE },
    { value: FEATURE_PERMISSION_STAFF_ONLY },
    { value: FEATURE_PERMISSION_ADMIN_ONLY }
  ]

  const availableOptions = options.filter(
    (option) =>
      option.value !== FEATURE_PERMISSION_ADMIN_ONLY ||
      canAdministrate(pwContext)
  )

  // upstream
  useEffect(() => {
    if (value && value !== valueProp) {
      onChange && onChange(value)
    }
  }, [value])

  // downstream
  useEffect(() => {
    if (valueProp && value !== valueProp) {
      setValue(valueProp)
    }
  }, [valueProp])

  return (
    <ReactSelectInput
      value={options.find((option) => option.value === value)}
      // NOTE: in order for formatOptionLabel to execute, options must be
      // a list of { value: any }
      formatOptionLabel={(props) => (
        <OptionLabel value={props.value} featureKey={featureKey} />
      )}
      onChange={(option) => setValue(option.value)}
      options={availableOptions}
      components={{
        Option
      }}
      isSearchable={false}
      styles={{
        container: (base) => ({ ...base, minWidth: "100%" })
      }}
      featureKey={featureKey}
      {...rest}
    />
  )
}

FeatureVisibilitySelect.displayName = "FeatureVisibilitySelect"

FeatureVisibilitySelect.propTypes = {
  perimssion: permissionInContextPropType
}

export default FeatureVisibilitySelect
