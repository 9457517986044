import useDidMountEffect from "@pathwright/ui/src/components/hooks/useDidMountEffect"
import { useMemo, useState } from "react"
import { useHistory } from "react-router-dom"

// Decode the query params for each key included in paramsObj
export const decodeQueryParams = (paramsObj) => {
  const searchParams = new URLSearchParams(window.location.search)

  const decodedParams = Object.entries(paramsObj).reduce(
    (paramsObj, [key, initialValue]) => {
      let value = searchParams.get(key)

      if (value) {
        // Transform corresponding paramsObj array valus to arrays.
        // NOTE: /(,(?! )/ meaning that we only want to split the values where a comma
        // does not precede a space (in which case the comma IS NOT acting as a value delimiter)
        if (Array.isArray(initialValue)) {
          value = value
            // split out the values by comma delimiter
            .split(/,(?! )/)
            // replace encoded comma
            .map((v) => v.replace("%;", ","))
        }
      } else {
        // Reset to empty array.
        if (Array.isArray(initialValue)) {
          value = []
        }
      }

      return {
        ...paramsObj,
        [key]: value
      }
    },
    {}
  )

  return decodedParams
}

// Encode the paramsObj into a search params string for updating current the route.
export const encodeQueryParams = (paramsObj) => {
  // For each param, encode the param value.
  const searchParams = Object.entries(paramsObj).reduce(
    (searchParams, [param, value]) => {
      // Encoding a comma occurring within an array value by replacing with a "%;".
      if (Array.isArray(value)) {
        value = value.map((v) =>
          typeof v === "string" ? v.replace(",", "%;") : v
        )
      }

      if (value && value.length) searchParams.set(param, value)
      return searchParams
    },
    new URLSearchParams()
  )

  // Prepend "?".
  const searchParamsString = searchParams.toString()
    ? `?${searchParams.toString()}`
    : ""

  return searchParamsString
}

// A controlled data flow fo query params to url search. If either change,
// the other is updated to reflect this change.
const useControlledQueryParams = (paramsObjProp) => {
  const history = useHistory()
  const { pathname, search } = history ? history.location : window.location

  // targetPathname used to ensure we only modife paramsObj and url search
  // when user is altering the paramsObjProp.
  const [targetPathname, setTargetPathname] = useState(pathname)

  // Track the decoded paramsObj, initiall based on paramsObjProp.
  const [paramsObj, setParamsObj] = useState(() =>
    decodeQueryParams(paramsObjProp)
  )
  // Encode the paramsObj in a search string.
  const encodedQueryParams = useMemo(
    () => encodeQueryParams(paramsObj),
    [paramsObj]
  )

  useDidMountEffect(() => {
    setParamsObj(paramsObjProp)
    // If paramsObjProp changes, we can assume the current pathname
    // is the targetPathname for which we can safely update the query params.
    if (targetPathname !== pathname) setTargetPathname(pathname)
  }, [paramsObjProp])

  // As encodedQueryParams change, udpate url search to match.
  useDidMountEffect(() => {
    // Need to scope to the targetPathname to avoid clearing state
    // (i.e. when opening modal route).
    if (window.location.pathname === targetPathname) {
      // Get current searchParams, so that we retain any uncontrolled params.
      const searchParams = new URLSearchParams(window.location.search)
      // Get current controlledSearchParams.
      const controlledSearchParams = new URLSearchParams(encodedQueryParams)
      // First remove all controlled keys. If they are still present in controlledSearchParams
      // then we'll re-add them.
      Object.keys(paramsObj).forEach((key) => searchParams.delete(key))
      // Merge controlledSearchParams into searchParams overriding keys in searchParams
      for (let [key, value] of controlledSearchParams.entries()) {
        searchParams.set(key, value)
      }

      // Update current route query params with latest paramsObj
      window.App &&
        window.App.navigate({
          pathname: window.location.pathname,
          search: searchParams.toString()
        })
    }
  }, [encodedQueryParams])

  // As search in url changes, update paramsObj to match.
  useDidMountEffect(() => {
    // Need to scope to the targetPathname to avoid clearing state
    // (i.e. when opening modal route).
    if (window.location.pathname === targetPathname) {
      // Since external state has changed–url search string changed,
      // likely due to browser forward/back button–update internal state.
      if (encodedQueryParams !== search) {
        setParamsObj(decodeQueryParams(paramsObj))
      }
    }
  }, [search])

  // Returns state based on query params.
  return paramsObj
}

export default useControlledQueryParams
