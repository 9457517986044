import { useFormikContext } from "formik"
import { useEffect } from "react"
import useDiscardFormState from "./useDiscardFormState"

// Handles setting initial formik state based on stored form state and
// synchronizing formik state with stored form state.
const useFormikDiscardFormState = (key, onChangeDiscardFormState) => {
  const formikContext = useFormikContext()

  // formState naturally maps to Formik's context.
  const formState = {
    values: formikContext.values,
    initialValues: formikContext.initialValues
  }

  const setFormState = (nextState) => {
    // Use resetForm or setValues depending on if Formik should
    //   1) reset to initial state or reset to a new state
    //   2) update to a modified state
    // Only necessary since resetForm does not support handling #2.
    if (
      nextState.values === formikContext.values ||
      nextState.values === nextState.initialValues
    ) {
      formikContext.resetForm(nextState)
    } else {
      formikContext.setValues(nextState.values, true /* shouldValidate */)
    }
  }

  const discardFormState = useDiscardFormState({
    key,
    formState,
    setFormState
  })

  // Send change upstream, only when discardCount changes.
  useEffect(() => {
    onChangeDiscardFormState && onChangeDiscardFormState(discardFormState)
  }, [discardFormState.discardCount])

  return { ...discardFormState, setFormState }
}

export default useFormikDiscardFormState
