import Alert from "@pathwright/ui/src/components/alert/Alert"
import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import TextInput from "@pathwright/ui/src/components/form/form-text-input/TextInput"
import Fieldset from "@pathwright/ui/src/components/form/form-utils/Fieldset"
import { Field, Form, Submit } from "react-connect-form-forked"

const RedeemSchoolGiftSubscriptionLookup = ({
  code,
  handleCode,
  submitting,
  error
}) => (
  <Form initialValue={{ code }} onSubmit={({ code }) => handleCode(code)}>
    <Alert error={error} />
    <Fieldset
      title="Gift Subscription Code"
      header="Enter your gift subscription redemption code."
    >
      <Field name="code" type="text" component={TextInput} />
    </Fieldset>
    <div style={{ textAlign: "center" }}>
      <Submit
        component={SubmitButton}
        styleType="secondary"
        submitting={submitting}
      >
        Submit
      </Submit>
    </div>
  </Form>
)

RedeemSchoolGiftSubscriptionLookup.displayName =
  "RedeemSchoolGiftSubscriptionLookup"

export default RedeemSchoolGiftSubscriptionLookup
