import { useQuery } from "@apollo/client"
import Button from "@pathwright/ui/src/components/button/Button"
import Card from "@pathwright/ui/src/components/card/Card"
import CardLink from "@pathwright/ui/src/components/card/CardLink"
import ToggleInput from "@pathwright/ui/src/components/form/form-toggle/ToggleInput"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import { usePathwrightContext } from "@pathwright/web/src/modules/pathwright/PathwrightContext"
import MANAGE_RESOURCE_QUERY from "@pathwright/web/src/modules/resource/manage/graphql/manage-resource-query"
import useUpdateResourceMutation from "@pathwright/web/src/modules/resource/manage/graphql/useUpdateResourceMutation"
import path from "path-browserify"
import styled from "styled-components"

// Layer the toggle over the card link.
const StyledToggleInput = styled(ToggleInput)`
  position: relative;
  z-index: 10;
`

function ManageResourceCohortsCard({ card, resourceId }) {
  const { t } = useTranslate()
  const { school } = usePathwrightContext()
  const query = useQuery(MANAGE_RESOURCE_QUERY, {
    variables: {
      id: resourceId
    }
  })
  const resource = query.data?.resource
  const totalCohorts = resource?.groups?.total || 0
  const firstGroup = resource?.groups?.edges?.[0]?.node

  const updateResource = useUpdateResourceMutation(resource)
  const handleEnableDiscussion = (value) => {
    return updateResource(
      {
        discussion_enabled: value
      },
      {
        optimisticResponse: {
          __typename: "Mutations",
          updateResource: {
            ...resource,
            discussion_enabled: value,
            __typename: "Resource"
          }
        },
        onCompleted: () => {
          // Sync with legacy backbond resource store.
          const resourceStore = window.App?.getStore?.("resource")
          if (resourceStore) {
            const { resource } = resourceStore.getState()
            if (resource) {
              resourceStore._mutate({
                resource: {
                  ...resource,
                  discussion_enabled: value
                }
              })
            }
          }
        }
      }
    )
  }

  return (
    <Card card={card} title="Manage Cohorts">
      {resource ? (
        <>
          {totalCohorts === 0 ? (
            <CardLink
              icon="group"
              label={t("resource.cards.menu.links.cohorts.label")}
              meta={t("resource.cards.menu.links.cohorts.meta")}
              to={path.join(window.location.pathname, "/manage/new/")}
            />
          ) : totalCohorts === 1 ? (
            <CardLink
              icon="group"
              label={t("resource.cards.menu.links.cohort_single.label")}
              meta={t("resource.cards.menu.links.cohort_single.meta")}
              to={path.join(window.location.pathname, `/${firstGroup.id}/`)}
            />
          ) : (
            <CardLink
              icon="group"
              label={t("resource.cards.menu.links.cohorts.label")}
              meta={t("resource.cards.menu.links.cohorts.meta")}
              to={path.join(window.location.pathname, "/manage/")}
            />
          )}
          {
            // not showing for licensed resources
            (!resource.licensed_from_school_id ||
              resource.licensed_from_school_id === school.id) && (
              <CardLink
                icon="group"
                label={t(
                  "resource.cards.advanced.links.member_created_cohorts.label"
                )}
                meta={t(
                  "resource.cards.advanced.links.member_created_cohorts.meta"
                )}
                to={path.join(window.location.pathname, "/license/")}
              />
            )
          }
          <CardLink
            icon="group"
            label="Community Features"
            meta="Enable or disable community features for all Cohorts."
            tooltip={
              <span>
                Enables Discussion Community Features for each Cohort by
                default, while allowing each Cohort's Community Features to be
                configured uniquely. Disabling Community for {resource.name}{" "}
                will hide Discussion and Community Features for all Cohorts and
                cannot be overridden.
                <br />
                <br />
                <Button
                  styleType="text"
                  href="https://help.pathwright.com/en/articles/5392170-configure-who-can-see-community-features"
                  target="_blank"
                  style={{
                    whiteSpace: "nowrap"
                  }}
                  label="Learn More"
                  postIcon="chevron-right"
                  inverted
                />
              </span>
            }
            preview={
              <StyledToggleInput
                id="discussion_enabled"
                name="discussion_enabled"
                type="custom"
                labelWidth="unset"
                alignRight
                onChange={handleEnableDiscussion}
                value={resource.discussion_enabled}
              />
            }
          />
        </>
      ) : (
        <LoadingCircle />
      )}
    </Card>
  )
}

export default ManageResourceCohortsCard
