import { LoaderFunctionArgs, redirect } from "react-router-dom"
import { hasAuthenticationToken } from "../../../lib/utils/auth-token"
import { loadAuthMethods } from "../api/loader"
import { getPathwrightAuthMethod } from "../utils/authMethods"
import getAuthRoute from "./getAuthRoute"

// TODO: move to generic utils.
type RedirectFunction = (args: LoaderFunctionArgs) => Promise<null> | null

// TODO: move to generic utils.
export function applyLoaderRedirects(...redirectFns: RedirectFunction[]) {
  // Wrap the loader function.
  return function <T extends (...args: any) => Promise<any> | any>(
    loaderFn: T
  ) {
    // Apply the redirect prior to running the loader function.
    return async function (args: LoaderFunctionArgs): Promise<ReturnType<T>> {
      for (let i = 0; i < redirectFns.length; i++) {
        await redirectFns[i](args)
      }
      return loaderFn(args)
    }
  }
}

// In the case where you don't wish to provide a loader function.
// Maybe this could be handled better, but having issues with typing.
export function callLoaderRedirects(...redirectFns: RedirectFunction[]) {
  // Apply the redirect prior to running the loader function.
  return async function (args: LoaderFunctionArgs): Promise<null> {
    for (let i = 0; i < redirectFns.length; i++) {
      await redirectFns[i](args)
    }
    return null
  }
}

// Redirect any unauthenticated users away from routes intended for authenticated users.
export const authTokenRequiredRedirect: RedirectFunction = ({ request }) => {
  if (!hasAuthenticationToken()) {
    // return null
    throw redirect(getAuthRoute("/auth", request))
  }
  return null
}

export const authTokenRequiredLoaderRedirect = applyLoaderRedirects(
  authTokenRequiredRedirect
)

// Redirect any authenticated users away from routes intended for unauthenticated users.
export const authTokenDisallowedRedirect: RedirectFunction = ({ request }) => {
  if (hasAuthenticationToken()) {
    // When the redirect is the same as current location,
    throw redirect(getAuthRoute("/", request))
  }
  return null
}

export const authTokenDisallowedLoaderRedirect = applyLoaderRedirects(
  authTokenDisallowedRedirect
)

// Redirect user away from pathwright login when not available.
export const pathwrightAuthRequiredRedirect: RedirectFunction = async ({
  request
}) => {
  const { authMethods } = await loadAuthMethods()
  const pathwrightAuthMethod = getPathwrightAuthMethod(authMethods)

  if (!hasAuthenticationToken() && !pathwrightAuthMethod) {
    throw redirect(getAuthRoute("/auth", request))
  }
  return null
}

export const pathwrightAuthRequiredLoaderRedirect = applyLoaderRedirects(
  pathwrightAuthRequiredRedirect
)
