import { useMemo } from "react"
import { useSelectPeopleContext } from "./SelectPeopleContext"

const useNotifyCount = () => {
  const { message, peopleSelection, roleSelection } = useSelectPeopleContext()

  // We're currently not notifying group members when they are added to a group, unless a custom message
  // is included. Of course, when they are invited, they will always receive a notification.
  const notifyCount = useMemo(() => {
    return message
      ? peopleSelection.selected.length
      : peopleSelection.selected.filter(
          (item) =>
            item.type !== "user" ||
            roleSelection.selected[item.meta.key] !== "group_member"
        ).length
  }, [message, peopleSelection, roleSelection])

  return notifyCount
}

export default useNotifyCount
