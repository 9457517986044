import Modal from "../modal/Modal"
import useCardStack from "./useCardStack"

export const useCardStackModal = (card) => {
  const { cards, open, close } = useCardStack(card)

  const modal = cards ? <Modal onClose={() => close()}>{cards}</Modal> : null

  return {
    cardStack: modal,
    onOpenCardStack: open
  }
}

export default useCardStackModal
