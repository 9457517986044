import Button from "@pathwright/ui/src/components/button/Button"
import withTranslate from "@pathwright/ui/src/components/lng/withTranslate"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import { observer } from "mobx-react"
import styled from "styled-components"
import RegisterHeader from "../../../../join/components/RegisterHeader"
import inviteStore from "../../store/invite-store"

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > span {
    display: flex;
    align-items: center;

    > i {
      margin-right: 4px;
    }
  }
`

const getVisibilityIcon = (offering) => {
  switch (offering.visibility) {
    case 5:
      return "lock"
    case 10:
      return "unlock"
    case 15:
      return "globe"
  }
}

const InviteHeader = ({ pathname, card, t }) => {
  const { context } = inviteStore
  const { offering, resource } = context || {}
  const heading = `
  Invite people to 
  ${
    offering && !offering.is_curriculum
      ? offering.name
      : resource
      ? resource.name
      : school.name
  }`

  const closeIcon = card.isIndex ? "x" : "arrow-left"
  return (
    <RegisterHeader
      card={card}
      resource={resource}
      heading={heading}
      onClose={card.onClose}
      closeIcon={closeIcon}
    >
      {offering && !offering.is_curriculum ? (
        <Container>
          <span>
            <Pathicon icon={getVisibilityIcon(offering)} />
            <span>{t(`cohort.visibility.${offering.visibility}.label`)}</span>
          </span>
          <span>
            <Pathicon icon="group-2" /> {offering.seats_filled}
          </span>
          <span>
            <Button styleType="text" inverted to={`${pathname}invitation/`}>
              <Pathicon icon="group-2" /> View sent invites
            </Button>
          </span>
        </Container>
      ) : (
        <Button styleType="text" inverted to={`${pathname}invitation/`}>
          <Pathicon icon="group-2" /> View sent invites
        </Button>
      )}
    </RegisterHeader>
  )
}

InviteHeader.displayName = "InviteHeader"

export default withTranslate(observer(InviteHeader))
