import React from "react"
import { usePathwrightContext } from "./PathwrightContext"

export const ThemeContext = React.createContext()

const ThemeContextProvider = ({ children }) => {
  const context = usePathwrightContext()
  return (
    <ThemeContext.Provider value={context.school.theme}>
      {children}
    </ThemeContext.Provider>
  )
}

export default ThemeContextProvider

// import get from "lodash/get"
// import { withContextAsProp } from "../utils/component"
// import THEME_QUERY from "./graphql/theme-query"

// class ThemeProvider extends React.Component {
//   static displayName = "ThemeProvider"

//   static propTypes = {
//     // should component render?
//     test: PropTypes.func,
//     // optionally scope theme query to another school
//     schoolId: PropTypes.number
//   }

//   static contextTypes = {
//     theme: PropTypes.object
//   }

//   static childContextTypes = {
//     theme: PropTypes.object
//   }

//   getChildContext() {
//     return {
//       theme: this.props.theme || this.context.theme // fallback to prev context theme
//     }
//   }

//   render() {
//     if (this.props.test && !this.props.test(this.props)) {
//       return null
//     }
//     return React.Children.only(this.props.children)
//   }
// }

// export default compose(
//   withContextAsProp(PathwrightContext, "schoolId", "school.id"),
//   graphql(THEME_QUERY, {
//     // pass school id variable, defaults to school id from context
//     options: ({ schoolId }) => ({
//       variables: {
//         school_id: schoolId
//       }
//     }),
//     // skip query if passed theme prop
//     skip: props => props.hasOwnProperty("theme"),
//     // map data to props
//     props: ({ data }) => ({
//       error: data.error,
//       loading: data.loading,
//       theme: get(data, "context.theme")
//     })
//   })
// )(ThemeProvider)
