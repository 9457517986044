const Cookie = require("js-cookie")

class CookieStorage {
  setItem(key, val) {
    return Cookie.set(key, val)
  }
  getItem(key) {
    return Cookie.get(key)
  }
  removeItem(key) {
    return Cookie.remove(key)
  }
}

class Storage {
  constructor() {
    this.data = this._getDataStore()
  }

  set(key, val) {
    if (val) {
      return this.data.setItem(key, val)
    } else if (val === null) {
      return this.data.removeItem(key)
    }
  }

  get(key) {
    return this.data.getItem(key)
  }

  remove(key) {
    return this.data.removeItem(key)
  }

  setItem(key, val) {
    return this.set(key, val)
  }
  getItem(key, val) {
    return this.get(key, val)
  }
  removeItem(key) {
    return this.remove(key)
  }

  _getDataStore() {
    let storage = localStorage
    try {
      storage.setItem("test:key", "val")
      storage.removeItem("test:key")
    } catch (e) {
      storage = new CookieStorage()
    }
    return storage
  }
}

export default Storage
