import PropTypes from "prop-types"
import { TEXT_OBJECT_ALIGNMENTS } from "./constants"

export const textObjectPropType = PropTypes.shape({
  text: PropTypes.string.isRequired,
  position: PropTypes.shape({
    top: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired
  }).isRequired,
  dimensions: PropTypes.shape({
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired
  }).isRequired,
  alignment: PropTypes.oneOf(TEXT_OBJECT_ALIGNMENTS).isRequired
})

export const textObjectsPropType = PropTypes.arrayOf(textObjectPropType)

export const certificateScope = PropTypes.shape({
  resourceId: PropTypes.number
})
