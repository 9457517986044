import {
  ThemeProvider as ChakraThemeProvider,
  extendTheme
} from "@chakra-ui/react"
import { NodeThemeColorPalette, SpaceFragment } from "../../api/generated"

import { useSpaceContext } from "../../views/space/api/context"

import theme from "./theme"

const getPrimaryPalette = (colorPalette: NodeThemeColorPalette) => {
  let palette = { ...colorPalette }
  delete palette.__typename
  // Remove the preceeding "_" from keys.
  palette = Object.entries(palette).reduce(
    (acc, [key, value]) =>
      Object.assign(acc, {
        [key.slice(1)]: value
      }),
    {}
  )

  return palette
}

const getColors = (space: SpaceFragment) => {
  let brand: any = theme.colors.blue

  // Getting generated color palette based on school's primary brand color.
  const colorPalette = space.theme ? space.theme.color_palette : null

  if (colorPalette) {
    brand = getPrimaryPalette(colorPalette)
  }

  const overlayText = space.theme!.background!.contrast_color || "white"

  return {
    colors: {
      brand,
      overlayText
    }
  }
}

const ThemeProvider = ({ children }: { children: JSX.Element }) => {
  const { space } = useSpaceContext()

  let baseTheme = extendTheme(getColors(space), theme)

  let spaceTheme = extendTheme(
    // withDefaultColorScheme({
    //   colorScheme: "brand"
    // }),
    baseTheme
  )

  return (
    <ChakraThemeProvider theme={spaceTheme}>{children}</ChakraThemeProvider>
  )
}

export default ThemeProvider
