import gql from "graphql-tag"
import MENTOR_GROUP_FRAGMENT from "../../mentor-group/graphql/mentor-group-fragment"
import SCHOOL_GROUP_SUBSCRIPTION_FRAGMENT from "../../subscription/graphql/school-group-subscription-fragment"

export default gql`
  query UserGroupSubscriptions {
    me {
      id
      mentorGroupMemberships(first: 50, group_type: group_subscription) {
        edges {
          node {
            id
            role
            access_revoked_time
            group {
              ...MentorGroup
              group_subscriptions(first: 1) {
                total
                pageInfo {
                  endCursor
                  hasNextPage
                }
                edges {
                  node {
                    ...SchoolGroupSubscription
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${SCHOOL_GROUP_SUBSCRIPTION_FRAGMENT}
  ${MENTOR_GROUP_FRAGMENT}
`
