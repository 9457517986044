export const isSubmitComplete = (data: any = {}) => {
  const { submission, submissions } = data

  // Legacy submission
  if (submission) return true

  if (submissions && submissions.length) return true

  return false
}

// If "mediaType" on Submit type is empty, any type is allowed
export const sampleData = {
  allowedType: "IMAGE",
  submitPrompt: ""
}

export default {
  type: "Submit",
  helpLink:
    "https://help.pathwright.com/create-a-course/adding-content-with-blocks/submit-block-new",
  menu: {
    category: "task",
    order: 1
  },
  layouts: {
    submit: {
      key: "submit",
      label: "Submit",
      data: {
        ...sampleData
      }
    }
  },
  resolveUserCompletion: (context, data, layout) => {
    const progress = isSubmitComplete(data) ? 1 : 0
    return Promise.resolve({
      progress,
      score: null
    })
  },
  schema: `
    enum AllowedType {
      IMAGE
      VIDEO
      AUDIO
      FILE
      LINK
      ANY
    }

    type SubmitBlockSubmission {
      id: ID!
      url: String! @media
      # url: String!
      filename: String!
      filestackHandle: String!
      type: MediaType!
      mimetype: String
      thumb: String @media
      # thumb: String
      size: Int
      createdDate: Float!
      updatedDate: DateTime
    }

    type Submit implements Block {
      id: ID!
      allowedType: AllowedType!
      # Ignoring submittedDate this after adding auto-completion and multiple file submissions
      submittedDate: Float @scope(type: USER)
      submitPrompt: String
      # Season 2 2021: multiple file submissions; single submission is legacy
      submission: SubmitBlockSubmission @scope(type: USER)
      submissions: [SubmitBlockSubmission] @scope(type: USER)
    }
  `
}
