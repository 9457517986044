import partial from "lodash/partial"
import { redirect } from "react-router-dom"
import { useAsyncFn } from "react-use"
import { z } from "zod"
import { zfd } from "zod-form-data"
import { getClient } from "../../../../api/client"
import {
  AuthRequestMethod,
  StartAuthRequestDocument,
  StartAuthRequestMutation,
  StartAuthRequestMutationVariables
} from "../../../../api/generated"
import getAuthRoute from "../../utils/getAuthRoute"
import getNextUrl, { NextUrl } from "../../utils/getNextUrl"

export type AuthRequestResultType = { uuid: string }

const emailSchema = z.string().email()

// Specific to the AuthRequestForm itself, only allowing the `email` field.
// Whereas the mutation can also accept a `username`, but this is not supported
// directly from the form.
export const authRequestFormSchema = z.object({
  email: emailSchema
})

export type AuthRequestFormSchema = z.infer<typeof authRequestFormSchema>

const schemaFields = {
  email: z.union([emailSchema, z.string()])
}

type StartAuthRequestMutationTransofmedVariables = Omit<
  StartAuthRequestMutationVariables,
  "method"
>

// Requiring either `email` or `username` args, but not allowing both.
export const schema = z
  .object(schemaFields)
  .transform<StartAuthRequestMutationTransofmedVariables>((val) =>
    emailSchema.safeParse(val.email).success ? val : { username: val.email }
  )
export const formDataSchema = zfd
  .formData(schemaFields)
  .transform<StartAuthRequestMutationTransofmedVariables>((val) =>
    emailSchema.safeParse(val.email).success ? val : { username: val.email }
  )

export type SchemaType = z.infer<typeof schema>

export const startAuthRequest = async (
  formData: FormData,
  nextUrl?: NextUrl
): Promise<AuthRequestResultType> => {
  const variables = formDataSchema.parse(formData)
  nextUrl ||= getNextUrl()
  const client = getClient()
  const { data } = await client.mutate<
    StartAuthRequestMutation,
    StartAuthRequestMutationVariables
  >({
    mutation: StartAuthRequestDocument,
    variables: {
      ...variables,
      nextUrl,
      method: AuthRequestMethod.EmailOtp // Hard-coding for now.
    }
  })

  if (!data?.startAuthRequest) {
    throw new Error(`No account associated with ${variables.email} found.`)
  }

  return {
    uuid: data.startAuthRequest.uuid
  }
}

const startAuthRequestAction = async (
  formData: FormData
): Promise<Response> => {
  const { uuid } = await startAuthRequest(formData)
  throw redirect(getAuthRoute(`./confirm?auth_request_id=${uuid}`))
}

startAuthRequest.schema = formDataSchema
startAuthRequestAction.schema = formDataSchema

export const useStartAuthRequest = partial(
  useAsyncFn<typeof startAuthRequest>,
  startAuthRequest
)

export default startAuthRequestAction
