import { Badge, Tag, TagLabel } from "@chakra-ui/react"
import { forwardRef } from "react"

// NOTE: for some reason, cannot use VariableTag as direct child of PopoverTrigger
// even when using forwardRef... the popover won't show.
const VariableTag = forwardRef(({ variable, active, count, onClick }, ref) => {
  return (
    <Tag
      ref={ref}
      colorScheme="blackAlpha"
      fontSize="xs"
      textTransform="none"
      key={variable}
      variant="subtle"
      opacity={active ? "1" : ".4"}
      _hover={{ opacity: active ? "1" : onClick ? ".6" : ".4" }}
      onClick={onClick}
      cursor={onClick ? "pointer" : "text"}
      p={1}
      borderRadius="xl"
      flexShrink={0}
      gap={1}
      position="relative"
    >
      <TagLabel>@{variable}</TagLabel>
      {!!count && (
        <Badge
          colorScheme="red"
          variant="solid"
          position="absolute"
          minW="1.4em"
          height="1.4em"
          background="red"
          padding="0.4em"
          borderRadius="100%"
          top="0"
          right="0"
          transform="translate(40%, -40%)"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {count}
        </Badge>
      )}
    </Tag>
  )
})

export default VariableTag
