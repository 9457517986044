import gql from "graphql-tag"
import SCHOOL_SUBSCRIPTION_FRAGMENT from "./school-subscription-fragment"

export default gql`
  mutation ($school_subscription_plan_id: ID!, $code: String!) {
    redeemSchoolGiftSubscription(
      school_subscription_plan_id: $school_subscription_plan_id
      code: $code
    ) {
      ...SchoolSubscription
    }
  }
  ${SCHOOL_SUBSCRIPTION_FRAGMENT}
`
