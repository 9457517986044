import gql from "graphql-tag"
import RESOURCE_LICENSE_FRAGMENT from "./resource-license-fragment"

export default gql`
  query ScopedResourceLicense($schoolId: Int!, $resourceLicenseId: Int!) {
    context(school_id: $schoolId) {
      school {
        id
        resource_license(id: $resourceLicenseId) {
          ...ResourceLicense
        }
      }
    }
  }
  ${RESOURCE_LICENSE_FRAGMENT}
`
