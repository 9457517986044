export const FILESTACK_API_KEY = "PKIcKga_QLKv1rkAdPQB"

export const MEDIA_TYPES = {
  IMAGE: "IMAGE",
  VIDEO: "VIDEO",
  FILE: "FILE",
  AUDIO: "AUDIO",
  DOCUMENT: "DOCUMENT",
  ARCHIVE: "ARCHIVE",
  ANY: "ANY",
  LINK: "LINK"
}

export const MEDIA_EXTENSIONS_MAP = {
  [MEDIA_TYPES.IMAGE]: [
    ".png",
    ".jpg",
    ".jpeg",
    ".gif",
    ".bmp",
    ".docx",
    ".heic"
  ],
  [MEDIA_TYPES.VIDEO]: [".mp4", ".mpeg", ".mov"],
  [MEDIA_TYPES.DOCUMENT]: [".doc", ".pdf", ".pages", ".rtf", ".docx"],
  [MEDIA_TYPES.ARCHIVE]: [".zip", ".tar", ".gz", ".rar"],
  [MEDIA_TYPES.FILE]: [],
  [MEDIA_TYPES.AUDIO]: [".mp3"],
  [MEDIA_TYPES.ANY]: []
}

// filestack accept (mimetype) parameters
export const MEDIA_TYPE_MAP = {
  [MEDIA_TYPES.IMAGE]: ["image/*", ".heic"], // .heic doesn't seem to have an image/* mimetype
  [MEDIA_TYPES.VIDEO]: ["video/*"],
  [MEDIA_TYPES.FILE]: ["application/*", "text/*"],
  [MEDIA_TYPES.DOCUMENT]: MEDIA_EXTENSIONS_MAP[MEDIA_TYPES.DOCUMENT],
  [MEDIA_TYPES.ARCHIVE]: MEDIA_EXTENSIONS_MAP[MEDIA_TYPES.ARCHIVE],
  [MEDIA_TYPES.AUDIO]: [
    "audio/*",
    "audio/aac",
    "audio/midi",
    "audio/mpeg",
    "audio/mp4",
    "audio/ogg",
    "audio/opus",
    "audio/wav",
    "audio/webm",
    "audio/x-m4a",
    "audio/3gpp"
  ],
  [MEDIA_TYPES.ANY]: undefined
}

export const BASE_FILESTACK_OPTIONS = {
  imageMax: [2000, 2000],
  maxFiles: 5,
  uploadInBackground: false,
  fromSources: [
    "local_file_system",
    "dropbox",
    "googledrive",
    "onedrive",
    "facebook"
  ]
}

export const FILESTACK_OPTIONS = {
  [MEDIA_TYPES.DOCUMENT]: {
    ...BASE_FILESTACK_OPTIONS,
    accept: MEDIA_TYPE_MAP[MEDIA_TYPES.DOCUMENT],
    maxSize: 100 * 1024 * 1024,
    maxFiles: 1
  },
  [MEDIA_TYPES.ARCHIVE]: {
    ...BASE_FILESTACK_OPTIONS,
    accept: MEDIA_TYPE_MAP[MEDIA_TYPES.ARCHIVE],
    maxSize: 100 * 1024 * 1024,
    maxFiles: 1
  },
  [MEDIA_TYPES.IMAGE]: {
    ...BASE_FILESTACK_OPTIONS,
    fromSources: [
      ...BASE_FILESTACK_OPTIONS.fromSources,
      // "customsource",
      "unsplash",
      "imagesearch",
      "webcam",
      // "url",
      "instagram"
    ],
    customSourceName: "Sample Images",
    accept: MEDIA_TYPE_MAP[MEDIA_TYPES.IMAGE],
    maxSize: 100 * 1024 * 1024,
    maxFiles: 20
  },
  [MEDIA_TYPES.FILE]: {
    ...BASE_FILESTACK_OPTIONS,
    accept: undefined,
    maxSize: 100 * 1024 * 1024,
    maxFiles: 15
  },
  [MEDIA_TYPES.VIDEO]: {
    ...BASE_FILESTACK_OPTIONS,
    fromSources: [...BASE_FILESTACK_OPTIONS.fromSources, "video", "url"],
    accept: MEDIA_TYPE_MAP[MEDIA_TYPES.VIDEO],
    maxSize: 500 * 1024 * 1024
  },
  [MEDIA_TYPES.AUDIO]: {
    ...BASE_FILESTACK_OPTIONS,
    fromSources: [...BASE_FILESTACK_OPTIONS.fromSources, "audio", "url"],
    accept: MEDIA_TYPE_MAP[MEDIA_TYPES.AUDIO],
    acceptFn: async (file, options) => {
      // This custom accept function added specifically to permit the m4a file type;
      // For some reason the magicbytes validation doesn't recognize it
      // https://www.filestack.com/docs/uploads/pickers/web/#acceptFn

      let mimeTest = ""

      // Local File system has the unique .originalFile key
      if (file.source === "local_file_system") {
        const magicMime = await options.mimeFromMagicBytes(file.originalFile)
        const extMime = await options.mimeFromExtension(file.originalFile.name)
        mimeTest = magicMime.indexOf("audio/") >= 0 ? magicMime : extMime
        // Use mimeType if it's set
      } else if (file.mimetype) {
        mimeTest = file.mimetype
        // Fall back to Filestack's built in extension detector
      } else {
        mimeTest = await options.mimeFromExtension(file.name)
      }

      if (mimeTest.indexOf("audio/") === -1) {
        return Promise.reject(
          `Please upload a valid audio file. Detected file type: ${file.mimetype}.`
        )
      }

      return Promise.resolve()
    },
    maxSize: 100 * 1024 * 1024
  },
  [MEDIA_TYPES.ANY]: {
    ...BASE_FILESTACK_OPTIONS,
    accept: undefined,
    fromSources: [...BASE_FILESTACK_OPTIONS.fromSources, "url"],
    maxSize: 500 * 1024 * 1024
  }
}
