import styled from "styled-components"
import { useUIThemeContext } from "../ui/PathwrightUI"
import Text from "../ui/Text"

const Bar = styled.div`
  border-radius: 0 13px 13px 0;
  position: absolute;
  left: 0;
  top: 10px;
  padding: 2px 7px 3px 5px;
`

const StatusBar = ({ children }) => {
  const theme = useUIThemeContext()
  return (
    <Bar style={{ backgroundColor: theme.primaryBrandColor }}>
      <Text.Meta style={{ color: "white" }}>{children}</Text.Meta>
    </Bar>
  )
}

StatusBar.displayName = "StatusBar"

export default StatusBar
