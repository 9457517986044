import { LinkIcon } from "@pathwright/pathicons"
import useCopyToClipboard from "@pathwright/ui/src/components/hooks/useCopyToClipboard"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import LinkButton from "./LinkButton"

export const useCopyLink = (value) => {
  const { tc } = useTranslate()

  return useCopyToClipboard(value, {
    title: tc("share_card.link_tab.actions.Copy link.linkCopied"),
    description: ""
  })
}

const CopyLink = ({ url }) => {
  const { tc } = useTranslate()
  const handleCopy = useCopyLink(url)

  return (
    <LinkButton
      label={tc("share_card.link_tab.actions.Copy link.buttonLabel")}
      icon={<LinkIcon />}
      onClick={handleCopy}
    />
  )
}

export default CopyLink
