import partial from "lodash/partial"
import { redirect } from "react-router-dom"
import { useAsyncFn } from "react-use"
import { z } from "zod"
import { zfd } from "zod-form-data"
import { getClient } from "../../../../../api/client"
import {
  SignInPasswordDocument,
  SignInPasswordMutation,
  SignInPasswordMutationVariables
} from "../../../../../api/generated"
import { handleAuthToken } from "../../../../../lib/utils/auth-token"
import { resolveAction } from "../../../../../lib/utils/multiple-actions"
import getAuthRoute from "../../../utils/getAuthRoute"
import { setPreferredAuthMethod } from "../../../utils/preferredAuthMethod"
import startAuthRequestAction, {
  startAuthRequest
} from "../../auth-request/api/action"

const schemaFields = {
  email: z.string().min(1), // .email() NOTE: we don't enforce email when signing in as email could be non-email value
  password: z.string().min(1)
}

export const schema = z.object(schemaFields)
export const formDataSchema = zfd.formData(schemaFields)

export type SchemaType = z.infer<typeof schema>

export const signInPasswordMutation = async (
  variables: SignInPasswordMutationVariables
): Promise<{ success: true }> => {
  const client = getClient()
  let result = await client.mutate<
    SignInPasswordMutation,
    SignInPasswordMutationVariables
  >({
    mutation: SignInPasswordDocument,
    variables
  })

  const token = result.data?.getToken?.token

  if (!token) {
    throw new Error("Invalid username or password.")
  }

  await handleAuthToken(token)
  setPreferredAuthMethod("password")

  return { success: true }
}

// Ideally we'd use better naming patterns for mutations expecting formData.
export const signInPassword = async (
  formData: FormData
): Promise<{ success: true }> => {
  return signInPasswordMutation(formDataSchema.parse(formData))
}

const signInPasswordAction = async (formData: FormData): Promise<Response> => {
  await signInPassword(formData)
  return redirect(getAuthRoute("/"))
}

signInPassword.schema = formDataSchema
signInPasswordAction.schema = formDataSchema

export const useSignInPassword = partial(
  useAsyncFn<typeof signInPassword>,
  signInPassword
)

// export const signInAction = resolveAction<ReturnType<typeof startAuthRequestAction> | ReturnType<typeof signInPasswordAction>>(
export const signInAction = resolveAction(
  signInPasswordAction,
  startAuthRequestAction
)

// export const signIn = resolveAction<ReturnType<typeof startAuthRequestAction> |
export const signIn = resolveAction<
  typeof signInPassword | typeof startAuthRequest
>(signInPassword, startAuthRequest)

export const useSignIn = partial(useAsyncFn<typeof signIn>, signIn)

export default signInAction
