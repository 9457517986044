import gql from "graphql-tag"
import SCHOOL_FRAGMENT from "../../school/graphql/school-fragment"
import CommunityPlace from "../CommunityPlace"
import LibraryPlace from "../LibraryPlace"
import UserPlace from "../UserPlace"

export default gql`
  query PlacesQuery {
    me {
      ...UserDisplay
      ...TeacherRegistrations
    }
    school {
      ...School
      ...ResourceStats
      ...RecentMembers
      ...MembershipStats
      ...MentorGroups
    }
    ...VisibleResources
    ...PrivateResources
  }
  ${SCHOOL_FRAGMENT}
  ${UserPlace.fragments.user}
  ${UserPlace.fragments.teacherRegistrations}
  ${LibraryPlace.fragments.resourceStats}
  ${LibraryPlace.fragments.visibleResources}
  ${LibraryPlace.fragments.privateResources}
  ${CommunityPlace.fragments.recentMembers}
  ${CommunityPlace.fragments.membershipStats}
  ${CommunityPlace.fragments.mentorGroups}
`
