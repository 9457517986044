import { Heading, Text } from "@chakra-ui/react"
import Link from "@pathwright/ui/src/components/link/Link"
import ProgressBar from "@pathwright/ui/src/components/progress/ProgressBar"
import classnames from "classnames"
import gql from "graphql-tag"
import styled from "styled-components"
import { getCohortTitles } from "../../cohort/Cohorts"
import GROUP_FRAGMENT from "../../group/graphql/group-fragment"
import { ENROLLMENT_ROLE_STUDENT } from "../../invitation/constants"
import { setStoredPathMode } from "../../path/utils"
import REGISTRATION from "../../registration/graphql/registration-fragment"
import RESOURCE_FRAGMENT from "../../resource/graphql/resource-fragment"
import USER_FRAGMENT from "../../user/graphql/user-fragment"
import RegistrationControls from "./RegistrationControls"
import RegistrationStatus from "./RegistrationStatus"
import {
  canAccessRegistration,
  getRegistrationLink,
  isRegistrationComplete,
  isRegistrationExpired
} from "./utils"

const DEFAULT_THUMB_IMAGE =
  "https://pathwright.imgix.net/https%3A%2F%2Fpathwright-static-media.s3.amazonaws.com%2Fdefault-image-set-v1%2Fcover-image-47.png?w=500&h=375&fit=crop&width=500&height=375&ixlib=js-1.4.1&s=74ebbe206c101ff49c5ddfe4ae0506a3"

export const USER_REGISTRATION_QUERY = gql`
  query UserRegistrationsQuery($first: Int = 1042, $cursor: String) {
    me {
      ...User
      registrations(
        first: $first
        after: $cursor
        role_filter: { lte: student }
      ) {
        total
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            ...Registration
            resource {
              ...Resource
            }
            group {
              ...Group
            }
            path {
              id
              progress
              completion_date
            }
            school_sub_used {
              id
              subscription {
                id
                is_delinquent
                canceled_dtime
              }
            }
            group_sub_used {
              id
            }
            group_sub_community_membership {
              id
              access_revoked_time
            }
          }
        }
      }
    }
  }
  ${USER_FRAGMENT}
  ${REGISTRATION}
  ${RESOURCE_FRAGMENT}
  ${GROUP_FRAGMENT}
`

USER_REGISTRATION_QUERY._pathToPageInfo = "me.registrations"

const LearnListItemContainer = styled(Link)`
  color: #000;
  text-decoration: none;
  display: flex;
  background-color: ${(p) => p.backgroundColor || "#fff"};
  border-radius: 20px;
  margin-bottom: 10px;
  overflow: hidden;
  align-items: center;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  transform: scale(1);
  transition: all 0.3s;
  max-width: 420px;
  height: 102px;

  :hover {
    transform: ${(p) => (!p.disabled ? `scale(1.01)` : `scale(1)`)};
  }

  img {
    display: block;
    flex-shrink: 0;
    margin: 10px;
    border-radius: 20px;
    max-width: 100px;
  }

  > div {
    flex: 1;
    overflow: hidden;
  }

  .RegistrationStatus {
    margin-left: auto;
    flex-grow: 0;
    margin-right: 10px;

    .Pathicon {
      margin-right: 2px;
    }
  }

  .ProgressBar {
    flex-grow: 0;
    width: calc(100% - 10px);
  }
`

const LearnListItemWrap = styled.div`
  max-width: 420px;
  position: relative;
  z-index: ${(p) => p.zIndex};

  .ChildItemsOverlay {
    height: 100px;
    /* opacity: 0.5; */
    transition: opacity 0.33s;

    > div {
      transition: all 0.333s;
      height: 100px;
    }
  }

  &:hover {
    .LearnListItemContainer {
      visibility: visible;

      h3 {
        max-width: calc(100%);
      }
    }

    .ChildItemsOverlay {
      height: auto;
      background-color: rgba(0, 0, 0, 0.5);
      /* opacity: 1; */
      > div {
        top: 0px;
        height: auto;
        overflow: hidden;
        overflow-y: scroll;

        display: flex;
        flex-direction: column;
      }
    }
  }

  &:not(:hover) {
    .ChildItemsOverlay .LearnListItemContainer > *,
    .ChildItemsOverlay .LearnListItemContainer ~ * {
      opacity: 0;
    }
  }

  ${(p) =>
    !p.$isComplete
      ? `
  .RegistrationControls .IconButton {
    background: rgba(255, 255, 255, 0.9);
    border-radius: 100%;
  }`
      : ""}
`

const OverlayContainer = styled.div`
  border-radius: 20px;
  width: calc(100% + 20px);
  position: absolute;
  top: -10px;
  left: -10px;
  padding: 10px;

  padding-top: calc(102px + 20px);

  > div {
    position: relative;
    top: -115px;
    left: 0px;
    display: grid;
    grid-template-rows: repeat(auto-fit, 5px);
    grid-auto-flow: dense;
    overflow-y: visible;
    max-height: 600px;
    overflow: hidden;
    /* overflow-y: scroll; */
    border-radius: 20px;

    .LearnListItemContainer {
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    }
  }
`

const ChildItemsOverlay = ({ items, parent }) => {
  return (
    <OverlayContainer className={"ChildItemsOverlay"}>
      <div>
        {items.map((ci) => {
          return <LearnListItem key={ci.id} item={ci} parent={parent} />
        })}
      </div>
    </OverlayContainer>
  )
}

export const LearnListItem = ({
  item: registration,
  parent: parentRegistration,
  itemCount,
  index
}) => {
  const resource = registration.resource
  const cohort = registration.group
  const image = registration.resource.image
    ? resource.image
    : DEFAULT_THUMB_IMAGE
  const progress = registration.path.progress

  let isComplete = isRegistrationComplete(registration)
  let canAccess = canAccessRegistration(registration)
  let isExpired = isRegistrationExpired(registration)

  if (parentRegistration) {
    canAccess = canAccessRegistration(parentRegistration) && canAccess
    isExpired = isRegistrationExpired(parentRegistration) || isExpired
    isComplete = isRegistrationExpired(parentRegistration) || isComplete
  }

  let to = getRegistrationLink(registration)
  const linkProps = {
    to: canAccess ? to : null,
    href: canAccess ? to.pathname : null
  }

  const { title, subTitle } = getCohortTitles({ ...cohort, resource })

  const childItems = (
    registration.childItems ? registration.childItems : []
  ).sort((a, b) => {
    // TODO: sort by completed/created
    return a.path.progress >= 1 ? 1 : -1
  })
  const hasChildItems = childItems.length > 0

  const zIndex = itemCount - index

  return (
    <LearnListItemWrap
      zIndex={zIndex}
      className={classnames(
        "LearnListItem",
        hasChildItems ? `LearnListItem__children` : null
      )}
      $isComplete={isComplete}
    >
      {hasChildItems ? (
        <ChildItemsOverlay items={childItems} parent={registration} />
      ) : null}

      <LearnListItemContainer
        disabled={!canAccess}
        className="LearnListItemContainer"
        backgroundColor={isComplete ? `rgba(255,255,255,.7)` : "#fff"}
        onClick={() => setStoredPathMode(5)}
        {...linkProps}
      >
        <img src={image} />
        <div>
          <Heading
            as="h1"
            title={cohort.name}
            className="CohortCard__cohort-title theme-heading theme-heading-bold"
            fontSize="24px"
            m="0 0 2px 0"
            maxWidth="calc(100% - 10px)"
            textAlign="left"
            noOfLines={2}
          >
            {title}
          </Heading>
          {subTitle ? (
            <Text
              as="h3"
              className="CohortCard__resource-title"
              fontSize="12px"
              m="0"
              textTransform="uppercase"
              fontWeight="normal"
              maxWidth="calc(100% - 10px)"
              textAlign="left"
              noOfLines={1}
            >
              {subTitle}
            </Text>
          ) : null}
          {isComplete || !canAccess ? (
            <RegistrationStatus
              expired={isExpired}
              registration={registration}
            />
          ) : (
            <ProgressBar
              backColor="#ddd"
              fillColor="forestgreen"
              progress={progress}
              style={{ marginTop: 6 }}
            />
          )}
        </div>
      </LearnListItemContainer>
      {registration.role >= ENROLLMENT_ROLE_STUDENT && (
        <RegistrationControls registration={registration} />
      )}
    </LearnListItemWrap>
  )
}

export const LearnListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  grid-gap: 1rem;
  height: 100%;
`
