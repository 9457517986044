import gql from "graphql-tag"
import BANNER_FRAGMENT from "./banner-fragment"

export default gql`
  mutation UpdateBanner(
    $id: Int!
    $heading: String
    $subhead: String
    $font_color: String
    $background_image: String
    $background_type: Int
    $background_overlay: String
    $button_func: String
    $button_url: String
    $button_text: String
    $is_visible: Boolean
    $hide_for_subscription_plans: [Int]
    $show_for_subscription_plans: [Int]
    $hide_for_products: [Int]
    $show_for_products: [Int]
  ) {
    updateBanner(
      id: $id
      heading: $heading
      subhead: $subhead
      font_color: $font_color
      background_image: $background_image
      background_type: $background_type
      background_overlay: $background_overlay
      button_func: $button_func
      button_url: $button_url
      button_text: $button_text
      is_visible: $is_visible
      hide_for_subscription_plans: $hide_for_subscription_plans
      show_for_subscription_plans: $show_for_subscription_plans
      hide_for_products: $hide_for_products
      show_for_products: $show_for_products
    ) {
      ...Banner
    }
  }
  ${BANNER_FRAGMENT}
`
