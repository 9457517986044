import PropTypes from "prop-types"
import { useCallback, useEffect, useState } from "react"
import { isHex } from "../../utils/colors"
import TextInput from "../form-text-input/TextInput"

const ColorInput = (props) => {
  const { value: valueProp, type, onChange } = props
  const [value, setValue] = useState(valueProp)
  const [valid, setValid] = useState(true)

  const handleChange = (value) => {
    const valid = validate(value)
    if (valid) onChange(value)
    setValue(value)
    setValid(valid)
  }

  useEffect(() => {
    if (valueProp !== value) handleChange(valueProp)
  }, [valueProp])

  const validate = useCallback((value) => {
    switch (type) {
      case "#":
        return isHex(value)
      case "r":
      case "g":
      case "b":
        return !isNaN(value) && value >= 0 && value <= 255
      case "a":
        return !isNaN(value) && value >= 0 && value <= 100
      default:
        return false
    }
  }, [])

  return (
    <TextInput
      {...props}
      onChange={handleChange}
      label={type}
      value={value}
      errors={valid ? [] : ["..."]}
      hideStatus
      hideCaution
      required
    />
  )
}

ColorInput.displayName = "ColorInput"

ColorInput.propTypes = {
  type: PropTypes.oneOf(["#", "r", "g", "b", "a"]).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired
}

export default ColorInput
