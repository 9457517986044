require("./styles/discussion-form.css")

const Discussion = require("discussion/models/discussion").default
const DiscussionFormTemplate = require("./templates/discussion-form.html")
const FilterMenu = require("lib/filter-menu/views/filter-menu").default
import { i18n } from "@pathwright/ui/src/components/lng/I18nextProvider"
import {
  DISCUSSION_TYPE_POST,
  DISCUSSION_TYPE_QUESTION
} from "@pathwright/web/src/modules/discussion/constants"
import capitalize from "lodash/capitalize"

// default supported types to post and question
const getDiscussionTypeOptions = (
  discussionTypes,
  context,
  canPostDiscussionQuestion = true
) => {
  discussionTypes = discussionTypes || [
    DISCUSSION_TYPE_POST,
    DISCUSSION_TYPE_QUESTION
  ]

  // Don't show discussion type if is a learner or in Community tab
  if (!context.step || !canPostDiscussionQuestion) {
    return []
  }

  // requiring that a DISCUSSION_TYPE_POST discussion must exist on a step
  return discussionTypes.filter(
    (discussionType) => discussionType !== DISCUSSION_TYPE_POST || context.step
  )
}

class DiscussionForm extends require("lib/static-shim").default(
  require("lib/uniform/views/form").default
) {
  static initClass() {
    this.prototype.className = "pw-uniform discussion-form-view"

    this.prototype.triggers = {
      "click .cancel-discussion": "cancel:discussion",
      "click .show-support": "show:support"
    }
  }

  behaviors() {
    const behaviors = super.behaviors()
    return _.extend(behaviors, {
      RichText: {
        container: ".discussion-panel-view"
      },
      ConnectStore: {
        stores: [{ store: "discussion" }, { store: "path" }]
      }
    })
  }

  getTemplate() {
    return DiscussionFormTemplate
  }

  initialize(options) {
    ;({ collection: this.collection, type: this.type } = options)

    this.context = this.discussionStore.request.getContext()

    this.perms = window.App.request("get:discussion:perms", this.model)

    if (this.model) {
      return this.model.snapshot()
    } else {
      return (this.model = this._getNewDiscussion())
    }
  }

  onCancelDiscussion() {
    return this.model.rollback()
  }

  onModelSave(model, isNew) {
    this.model.set("highlight", true)
    if (isNew) {
      this.triggerMethod("discussion:created", this.model)
      window.App.vent.trigger("discussion:created", this.model)
      if (this.collection) {
        this.collection.add(this.model, { at: 0 })
      }
    } else {
      this.triggerMethod("discussion:updated", this.model)
      window.App.vent.trigger("discussion:updated", this.model)
    }
  }

  serializeData() {
    const data = super.serializeData()
    const noun = capitalize(i18n.t("discussion.label"))
    data.media = window.App.getStore("school").get("media")
    data.action_label = i18n.t(`Post {{ noun }}`, { noun })
    data.context_label = this.options.context_label
    data.rich_text_enabled = true // !App.request("ui:is:mobile")
    data.perms = this.perms
    data.support_enabled = true
    data.types = getDiscussionTypeOptions(
      this.options.types,
      this.context,
      this.perms.can_post_discussion_question
    )

    // Disable for mobile for now
    if (App.request("is:ios")) {
      data.support_enabled = false
    }

    if (!this.model.isNew()) {
      data.action_label = `${i18n.t("discussion.update")} ${noun}`
    }
    return data
  }

  onShowSupport() {
    return window.App.getStore("support").action.activateSupport()
  }

  _getDiscussionType() {
    const types = {
      question: 5,
      post: 10,
      review: 15,
      faq: 20
    }
    return types[this.options.type]
  }

  _getNewDiscussion() {
    // TODO: check can post discussion question and default
    const discussion = new Discussion({
      author: window.App.request("get:user").toJSON(),
      context: this.context,
      // is_discussion_question is based on permission and available options (as options might be limited depending on context)
      is_discussion_question:
        this.perms.can_post_discussion_question &&
        getDiscussionTypeOptions(this.options.types, this.context).includes(
          DISCUSSION_TYPE_POST
        ),
      discussion_type: this._getDiscussionType()
    })
    return discussion
  }
}
DiscussionForm.initClass()
export default DiscussionForm
