import uniq from "lodash/uniq"
import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import ColorPickerToggle from "../../form/form-color/ColorPickerToggle"
import { useMostRecentColorPickerSwatches } from "../../tag/utils"
import { useQuillEditorContext } from "../QuillEditorContext"

const DEFAULT_COLORS = {
  color: "#292e35",
  background: "#ffffff"
}

const StyledToolbarButton = styled.button`
  padding: 0 0 2px !important;

  .ql-toolbar button svg {
    height: 24px !important;
  }

  .ColorPickerToggle {
    padding: 0;

    & > div {
      margin: 0;
    }
  }

  .ColorPickerToggle__toggle {
    width: 18px;
  }
`

const QuillColorButton = ({ color }) => (
  <span className="ql-color ql-picker ql-color-picker">
    <span className="" tabIndex="0" role="button">
      <svg viewBox="0 0 18 18">
        <line
          className="ql-color-label ql-stroke ql-toolbar-color-option ql-transparent"
          x1="3"
          x2="15"
          y1="15"
          y2="15"
          style={{ stroke: color }}
        ></line>
        <polyline className="ql-stroke" points="5.5 11 9 3 12.5 11"></polyline>
        <line className="ql-stroke" x1="11.63" x2="6.38" y1="9" y2="9"></line>
      </svg>
    </span>
  </span>
)

const QuillBackgroundButton = ({ color }) => (
  <span className="ql-background ql-picker ql-color-picker ql-expanded">
    <span className="ql-picker-label" tabIndex="0" role="button">
      <svg viewBox="0 0 18 18">
        <g className="ql-fill ql-color-label" style={{ fill: color }}>
          <polygon points="6 6.868 6 6 5 6 5 7 5.942 7 6 6.868"></polygon>
          <rect height="1" width="1" x="4" y="4"></rect>
          <polygon points="6.817 5 6 5 6 6 6.38 6 6.817 5"></polygon>
          <rect height="1" width="1" x="2" y="6"></rect>
          <rect height="1" width="1" x="3" y="5"></rect>
          <rect height="1" width="1" x="4" y="7"></rect>
          <polygon points="4 11.439 4 11 3 11 3 12 3.755 12 4 11.439"></polygon>
          <rect height="1" width="1" x="2" y="12"></rect>
          <rect height="1" width="1" x="2" y="9"></rect>
          <rect height="1" width="1" x="2" y="15"></rect>
          <polygon points="4.63 10 4 10 4 11 4.192 11 4.63 10"></polygon>
          <rect height="1" width="1" x="3" y="8"></rect>
          <path d="M10.832,4.2L11,4.582V4H10.708A1.948,1.948,0,0,1,10.832,4.2Z"></path>
          <path d="M7,4.582L7.168,4.2A1.929,1.929,0,0,1,7.292,4H7V4.582Z"></path>
          <path d="M8,13H7.683l-0.351.8a1.933,1.933,0,0,1-.124.2H8V13Z"></path>
          <rect height="1" width="1" x="12" y="2"></rect>
          <rect height="1" width="1" x="11" y="3"></rect>
          <path d="M9,3H8V3.282A1.985,1.985,0,0,1,9,3Z"></path>
          <rect height="1" width="1" x="2" y="3"></rect>
          <rect height="1" width="1" x="6" y="2"></rect>
          <rect height="1" width="1" x="3" y="2"></rect>
          <rect height="1" width="1" x="5" y="3"></rect>
          <rect height="1" width="1" x="9" y="2"></rect>
          <rect height="1" width="1" x="15" y="14"></rect>
          <polygon points="13.447 10.174 13.469 10.225 13.472 10.232 13.808 11 14 11 14 10 13.37 10 13.447 10.174"></polygon>
          <rect height="1" width="1" x="13" y="7"></rect>
          <rect height="1" width="1" x="15" y="5"></rect>
          <rect height="1" width="1" x="14" y="6"></rect>
          <rect height="1" width="1" x="15" y="8"></rect>
          <rect height="1" width="1" x="14" y="9"></rect>
          <path d="M3.775,14H3v1H4V14.314A1.97,1.97,0,0,1,3.775,14Z"></path>
          <rect height="1" width="1" x="14" y="3"></rect>{" "}
          <polygon points="12 6.868 12 6 11.62 6 12 6.868"></polygon>
          <rect height="1" width="1" x="15" y="2"></rect>
          <rect height="1" width="1" x="12" y="5"></rect>
          <rect height="1" width="1" x="13" y="4"></rect>
          <polygon points="12.933 9 13 9 13 8 12.495 8 12.933 9"></polygon>
          <rect height="1" width="1" x="9" y="14"></rect>
          <rect height="1" width="1" x="8" y="15"></rect>
          <path d="M6,14.926V15H7V14.316A1.993,1.993,0,0,1,6,14.926Z"></path>
          <rect height="1" width="1" x="5" y="15"></rect>
          <path d="M10.668,13.8L10.317,13H10v1h0.792A1.947,1.947,0,0,1,10.668,13.8Z"></path>
          <rect height="1" width="1" x="11" y="15"></rect>
          <path d="M14.332,12.2a1.99,1.99,0,0,1,.166.8H15V12H14.245Z"></path>
          <rect height="1" width="1" x="14" y="15"></rect>
          <rect height="1" width="1" x="15" y="11"></rect>
        </g>
        <polyline
          className="ql-stroke ql-toolbar-background"
          points="5.5 13 9 5 12.5 13"
        ></polyline>
        <line
          className="ql-stroke ql-toolbar-background"
          x1="11.63"
          x2="6.38"
          y1="11"
          y2="11"
        ></line>
      </svg>
    </span>
  </span>
)

// Prevent closing the toolbar when focusing or blurring the color picker
const InteractionWrapper = ({ children }) => {
  const handleInteraction = (e) => {
    if (
      e.target.tagName === "INPUT"
      // e.target.tagName === "TEXTAREA" ||
      // e.target.tagName === "BUTTON"
    ) {
      e.stopPropagation()
    }
  }

  return (
    <div onMouseDown={handleInteraction} onTouchStart={handleInteraction}>
      {children}
    </div>
  )
}

const ColorToolbarOption = ({ options, type }) => {
  const { quill } = useQuillEditorContext()
  const cachedSelectionRef = useRef()

  const [previewColor, setPreviewColor] = React.useState(null)

  const [recentSwatches, setRecentSwatches] = useMostRecentColorPickerSwatches()

  // Update toolbar font icon preview color when selection changes.
  useEffect(() => {
    if (quill) {
      const handler = (range) => {
        if (range) {
          const format = quill.editor.getFormat(range)

          const nextColor = Array.isArray(format[type])
            ? format[type][format[type].length - 1]
            : format[type]

          setPreviewColor(nextColor)
          cachedSelectionRef.current = range
        }
      }

      quill.editor?.on("selection-change", handler)
      return () => {
        if (quill && quill.editor) quill.editor.off("selection-change", handler)
      }
    }
  }, [quill])

  const swatchesLength = 12

  const getSwatches = () => {
    const customColorsWithFallback = [...options, ...recentSwatches.list]

    return uniq([
      DEFAULT_COLORS.color,
      DEFAULT_COLORS.background,
      ...customColorsWithFallback
    ]).slice(0, swatchesLength)
  }

  const handleColorChange = (color) => {
    if (!quill || !cachedSelectionRef.current) return

    const { index, length } = cachedSelectionRef.current

    setPreviewColor(color)

    quill.editor.formatText(
      index,
      length,
      {
        [type == "background" ? "background" : "color"]: color
      },
      "user"
    )
  }

  // Only set the most recent swatch after closing the Picker.
  const handleFinalSelection = () => {
    previewColor && setRecentSwatches(previewColor)
  }

  const color = previewColor || DEFAULT_COLORS[type]

  return (
    <StyledToolbarButton>
      <InteractionWrapper>
        <ColorPickerToggle
          name={type}
          show
          renderToggle={() =>
            type === "background" ? (
              <QuillBackgroundButton color={color} />
            ) : (
              <QuillColorButton color={color} />
            )
          }
          value={color}
          onHide={handleFinalSelection}
          swatches={getSwatches()}
          placement="bottom"
          alignment="left"
          format="hex"
          enableAlpha={false}
          onChange={handleColorChange}
          onChangeComplete={handleColorChange}
        />
      </InteractionWrapper>
    </StyledToolbarButton>
  )
}

ColorToolbarOption.propTypes = {
  type: PropTypes.oneOf(["color", "background"]).isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  selection: PropTypes.shape({
    index: PropTypes.number,
    length: PropTypes.number
  })
}

ColorToolbarOption.defaultProps = {
  type: "color",
  options: []
}

ColorToolbarOption.displayName = "ColorToolbarOption"

export default ColorToolbarOption
