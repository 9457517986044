import Carousel, {
  CarouselControl
} from "@pathwright/ui/src/components/carousel/Carousel"
import { useObserveSize } from "@pathwright/ui/src/components/observers/ObserveSizeContext"
import View from "@pathwright/ui/src/components/ui/View"
import PropTypes from "prop-types"
import { useState } from "react"
import styled from "styled-components"
import Banner from "./Banner"

const StyledContainer = styled(View)`
  position: relative;

  .Banner {
    position: relative;
    display: flex;
    align-items: center;
    flex-grow: 1;
    height: 100%;
    width: 100%;
    padding: 120px 30px 70px;
  }

  html.no-touch &:not(:hover) .CarouselControl {
    display: none;
  }
`

const BannerSlider = ({ banners, ...bannerProps }) => {
  const [playing, setPlaying] = useState(true)
  const [rectValue, nodeRef, setNode] = useObserveSize()

  const pause = () => {
    if (playing) {
      return setPlaying(false)
    }
  }

  const play = () => {
    if (!playing) {
      return setPlaying(true)
    }
  }

  return (
    <StyledContainer onMouseEnter={pause} onMouseLeave={play} ref={setNode}>
      {banners.length === 1 ? (
        <Banner {...banners[0]} {...bannerProps} />
      ) : rectValue.width ? (
        <Carousel
          infinite
          autoPlay={playing}
          autoPlaySpeed={7000}
          items={banners}
          itemWidth={rectValue.width}
          renderItem={({ item, index }) => (
            <Banner key={item.id} {...item} {...bannerProps} />
          )}
          renderControls={() => (
            <>
              <CarouselControl dir="prev" />
              <CarouselControl dir="next" />
            </>
          )}
        />
      ) : null}
    </StyledContainer>
  )
}

BannerSlider.displayName = "BannerSlider"

BannerSlider.propTypes = {
  banners: PropTypes.array.isRequired
}

export default BannerSlider
