import DropdownItem from "@pathwright/ui/src/components/dropdown/DropdownItem"
import DropdownMenu from "@pathwright/ui/src/components/dropdown/DropdownMenu"
import DropdownTrigger from "@pathwright/ui/src/components/dropdown/DropdownTrigger"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import ControlsButton from "../inbox/ui/ControlsButton"
import { usePathwrightContext } from "../pathwright/PathwrightContext"
import { useDiscussionContext } from "./DiscussionProvider"

const DiscussionResponseControls = ({ response, onDelete }) => {
  const { t } = useTranslate()
  const { me } = usePathwrightContext()

  const { setEditingResponse } = useDiscussionContext()

  const responseIsOwnedByUser = response.user.id === me.id

  const handleEdit = () => {
    setEditingResponse(response)
  }

  if (!responseIsOwnedByUser) return null

  return (
    <DropdownTrigger
      alignment="right"
      className="DiscussionResponseControls__trigger"
      renderDropdown={() => (
        <DropdownMenu closeOnClick>
          <DropdownItem
            icon="edit"
            label={t("discussion.response.edit")}
            onClick={handleEdit}
          />
          <DropdownItem
            icon="trash"
            label={t("discussion.response.delete")}
            onClick={onDelete}
          />
        </DropdownMenu>
      )}
    >
      <ControlsButton />
    </DropdownTrigger>
  )
}

DiscussionResponseControls.displayName = "DiscussionResponseControls"

export default DiscussionResponseControls
