import velocity from "velocity-animate"
import "velocity-animate/velocity.ui"

velocity
  .RegisterEffect("transition.cardIn", {
    defaultDuration: 150,
    calls: [
      [
        {
          opacity: [1, 0],
          transformOriginX: ["50%", "50%"],
          transformOriginY: ["50%", "50%"],
          scaleX: [1, 0.625],
          scaleY: [1, 0.625],
          translateZ: 0
        }
      ]
    ]
  })
  .RegisterEffect("transition.cardOut", {
    defaultDuration: 100,
    calls: [
      [
        {
          opacity: [0, 1],
          transformOriginX: ["50%", "50%"],
          transformOriginY: ["50%", "50%"],
          scaleX: 0.5,
          scaleY: 0.5,
          translateZ: 0
        }
      ]
    ],
    reset: { scaleX: 1, scaleY: 1 }
  })
