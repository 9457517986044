import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import View from "@pathwright/ui/src/components/ui/View"
import SchoolSubscriptionPlansCheckboxes, {
  useSchoolSubscriptionPlans
} from "@pathwright/web/src/modules/subscription/manage/SchoolSubscriptionPlansCheckboxes"
import { ConnectForm } from "lib/core/components/forms"
import { ConnectStores } from "lib/core/store"

const fieldsData = {
  include_in_subscription_plans: {
    label: "Subscriber access"
  }
}

const ManageLicenseSubscriberForm = (props) => {
  const { fieldsData, isValid, handleSubmit } = props
  const { schoolSubscriptionPlans } = useSchoolSubscriptionPlans()

  return (
    <form className="ManageLicenseSubscriberForm" onSubmit={handleSubmit}>
      <SubmitButton
        {...props}
        disabled={!isValid}
        styleType="primary"
        type="submit"
      >
        Save
      </SubmitButton>
      {schoolSubscriptionPlans && schoolSubscriptionPlans.length > 0 && (
        <View padding>
          <SchoolSubscriptionPlansCheckboxes
            {...fieldsData.include_in_subscription_plans}
          />
        </View>
      )}
    </form>
  )
}

ManageLicenseSubscriberForm.displayName = "ManageLicenseSubscriberForm"

const mapStateToProps = (state, ownProps) => {
  const { saveLicenseOffering } = state.resourceLicenseAdminStore.action
  const { license_offering } = state.resourceLicenseAdminStore.state

  const fieldValues = {
    include_in_subscription_plans:
      license_offering &&
      _.compact(license_offering.include_in_subscription_plans)
  }

  const onSubmit = (fieldsData, resetForm) => {
    const licenseData = {
      include_in_subscription_plans:
        fieldsData.include_in_subscription_plans.value
    }
    return saveLicenseOffering(licenseData)
  }

  return _.assign(
    {},
    {
      onSubmit,
      fieldValues
    },
    ownProps
  )
}

const stores = [
  { store: "resourceLicenseAdmin", watch: ["license_offering"] },
  { store: "resourceAdmin", watch: ["resource_saving"] }
]

export default ConnectStores(
  ConnectForm(ManageLicenseSubscriberForm, fieldsData),
  stores,
  {},
  mapStateToProps
)
