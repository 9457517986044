import {
  Button,
  Divider,
  HStack,
  Heading,
  Image,
  LightMode,
  Link,
  Spacer,
  Spinner,
  Text,
  VStack,
  useMediaQuery
} from "@chakra-ui/react"
import Pathicon from "@pathwright/web/src/modules/pathicon/Pathicon"
import { useState } from "react"
import {
  UserSpaceMembershipFragment,
  useUserSpaceMembershipsQuery
} from "../../api/generated"
import AdaptedAuthContainer from "../../auth/AdaptedAuthContainer"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import { SpaceConfig } from "./SpaceCreateForm"

type Membership = UserSpaceMembershipFragment

export type OnSelectSpace = (space: Membership["school"]) => void

type SpaceItemProps = {
  membership: Membership
  isSelected?: boolean
  onSelect?: OnSelectSpace
  copyConfigsCount?: number
}

function useMembershipRoleLabel(membership: Membership) {
  switch (membership.role) {
    case 30:
      return "Administrator"
    case 20:
    case 15:
      return "Staff"
    default:
      return "Learner"
  }
}

function SpaceItem({ membership, onSelect, copyConfigsCount }: SpaceItemProps) {
  // Just used for indicating paths are being copied.
  const [isSelecting, setIsSelecting] = useState<boolean>(false)
  const roleLabel = useMembershipRoleLabel(membership)
  // Only hiding the "copy x paths" text when not hovered on devices
  // that have hover.
  const requireHover = useMediaQuery("(hover: hover)")[0] && !isSelecting

  return (
    <HStack
      width="100%"
      p={3}
      cursor="pointer"
      onClick={(e) => {
        // Allow for Meta + clicking space to open in new tab without copying configs.
        if (onSelect && !e.getModifierState("Meta")) {
          e.preventDefault()
          e.stopPropagation()
          setIsSelecting(true)
          onSelect(membership.school)
        }
      }}
      borderRadius="sm"
      _hover={{
        bg: "rgba(255, 255, 255, .1)"
      }}
      sx={{
        _hover: {
          [".CopyConfigsCount"]: {
            display: "flex"
          }
        }
      }}
    >
      <Image src={membership.school.image!} boxSize="40px" objectFit="cover" />
      <VStack flexGrow={1} alignItems="flex-start" spacing={0}>
        <Heading as="h4" noOfLines={1} size="sm" fontSize="md">
          {membership.school.name}
        </Heading>
        <Text fontSize="sm" opacity=".75">
          {roleLabel}
        </Text>
      </VStack>
      {!!copyConfigsCount && (
        <HStack
          className="CopyConfigsCount"
          display={requireHover ? "none" : "flex"}
        >
          <Pathicon icon="paste" />
          <Text fontSize="xs">
            {isSelecting ? "Copying" : "Copy"} {copyConfigsCount} Path
            {copyConfigsCount > 1 ? "s" : ""}
          </Text>
        </HStack>
      )}
      {isSelecting ? (
        <Spinner size="sm" />
      ) : !onSelect ? (
        <Pathicon icon="chevron-right" />
      ) : null}
    </HStack>
  )
}

type SelectSpaceProps = {
  onClickCreateSpace: () => void
  onSelectSpace?: OnSelectSpace
  onComplete?: () => void
  spaceConfig?: SpaceConfig
  copyConfigsCount?: number
}

function SpaceAuthForm({
  onClickCreateSpace,
  onComplete,
  onSelectSpace,
  spaceConfig,
  copyConfigsCount
}: SelectSpaceProps) {
  const { me } = usePathwrightContext()
  const query = useUserSpaceMembershipsQuery({
    skip: !me
  })

  const memberships = (
    (query.data?.me?.memberships?.edges?.map((edge) => edge?.node) ||
      []) as Membership[]
  )
    .sort((mem1, mem2) => {
      return mem2.role - mem1.role
    })
    .reduce(
      (acc, mem) => {
        if (mem.role === 30) {
          acc.admin.push(mem)
        } else {
          acc.other.push(mem)
        }
        return acc
      },
      {
        admin: [],
        other: []
      } as {
        admin: Membership[]
        other: Membership[]
      }
    )

  return (
    <VStack
      m={2}
      p={4}
      spacing={4}
      w="100%"
      alignItems="center"
      sx={{
        [".AuthWrapper"]: {
          w: "100%",
          p: 0
        },
        // Icky, but the best UX here is to override styles rather than attempting
        // to wrap the auth flow with <LightMode /> when signed in due to the flashes
        // of styles when changing auth state since our two clients are not in sync.
        [".AuthWrapper .SignedIn ~ button[type='submit']"]: {
          display: "none"
        },
        [".AuthWrapper:has(.SignedIn)"]: {
          bg: "white",
          padding: "10px"
        },
        [".AuthWrapper .SignedIn h1, .AuthWrapper .SignedIn p, .AuthWrapper .SignedIn a"]:
          {
            color: "black !important"
          }
      }}
    >
      <Heading as="h1" size="xl" fontFamily="theme.heading" textAlign="center">
        {me
          ? "Select a Learning Space"
          : spaceConfig
          ? "Create your Account"
          : "Sign in to your Account"}
      </Heading>
      <Spacer />

      {spaceConfig ? (
        <AdaptedAuthContainer
          onComplete={onComplete}
          createMembership={false}
          defaultAuthMethod="signup"
          // Might be a bug in chakra or in our code, but our brand color is
          // being overrided by the chakra theme in the space provider.
          useTheme={false}
        />
      ) : (
        <AdaptedAuthContainer
          onComplete={onComplete}
          createMembership={false}
          allowSignup={false}
          // Might be a bug in chakra or in our code, but our brand color is
          // being overrided by the chakra theme in the space provider.
          useTheme={false}
        />
      )}

      {!!me && (
        <>
          <Divider orientation="vertical" bg="white" h={6} />

          <LightMode>
            <Button
              variant="solid"
              size="lg"
              leftIcon={<Pathicon icon="plus" />}
              w="100%"
              justifyContent="flex-start"
              onClick={onClickCreateSpace}
            >
              Create a new Learning Space
            </Button>
          </LightMode>
        </>
      )}

      {!!(memberships.admin.length || memberships.other.length) && (
        <>
          <Text>or, select an existing space:</Text>

          <VStack
            w="100%"
            mb={4}
            spacing={0}
            sx={{
              ["> * + *"]: {
                borderTop: "solid 1px rgba(255, 255, 255, .2)"
              }
            }}
          >
            {memberships.admin.map((mem) => (
              <Link
                key={mem.id}
                href={mem.school.sso_link!}
                w="100%"
                _hover={{ textDecor: "none" }}
              >
                <SpaceItem
                  membership={mem}
                  onSelect={onSelectSpace}
                  copyConfigsCount={copyConfigsCount}
                />
              </Link>
            ))}
            {memberships.other.map((mem) => (
              <Link
                key={mem.id}
                href={mem.school.sso_link!}
                w="100%"
                _hover={{ textDecor: "none" }}
              >
                <SpaceItem membership={mem} />
              </Link>
            ))}
          </VStack>
        </>
      )}
    </VStack>
  )
}

export default SpaceAuthForm
