import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import PropTypes from "prop-types"
import { featureKeyPropType, permissionInContextPropType } from "../propTypes"
import { getFeatureInfo, getFeaturePermissionInfo } from "../utils"
import FeatureBlockUI from "./FeatureBlockUI"

const FeatureBlock = ({ featureKey, featurePermission, to }) => {
  const { t } = useTranslate()
  const featureInfo = getFeatureInfo(featureKey, t)
  const featurePermissionInfo = getFeaturePermissionInfo(
    featureKey,
    featurePermission,
    t
  )

  return (
    <FeatureBlockUI
      featureInfo={featureInfo}
      featurePermissionInfo={featurePermissionInfo}
      to={to}
    />
  )
}

FeatureBlock.displayName = "FeatureBlock"

FeatureBlock.propTypes = {
  featureKey: featureKeyPropType.isRequired,
  featurePermission: permissionInContextPropType.isRequired,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
}

FeatureBlock.defaultProps = {
  featureKey: null,
  featurePermission: null,
  to: null
}

export default FeatureBlock
