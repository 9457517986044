import gql from "graphql-tag"
import MEMBERSHIP_META_FRAGMENT from "./membership-meta-fragment"

export default gql`
  query Members($user_id: Int!) {
    membership_meta(user_id: $user_id) {
      ...MembershipMeta
    }
  }
  ${MEMBERSHIP_META_FRAGMENT}
`
