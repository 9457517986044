import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverProps,
  PopoverTrigger,
  Portal
} from "@chakra-ui/react"

type ChakraTooltipProps = {
  children: JSX.Element
  title: string | JSX.Element
  fitContent?: boolean
  popover?: PopoverProps
} & PopoverProps

function ChakraTooltip({
  children,
  title,
  fitContent,
  popover
}: ChakraTooltipProps) {
  // Optionally force popover to fit width of content.
  // Otherwise, popover will render content at a max-width.
  const contentProps = fitContent
    ? {
        width: "auto",
        whiteSpace: "nowrap"
      }
    : {}

  return title ? (
    <Popover trigger="hover" {...popover}>
      <PopoverTrigger>{children}</PopoverTrigger>
      <Portal>
        <PopoverContent
          border="none"
          fontSize="14px"
          opacity="0.9"
          lineHeight="1.3"
          letterSpacing="1px"
          backgroundColor="#000"
          color="whitesmoke"
          p="5px"
          borderRadius="5px"
          boxShadow="0 0 10px 2px rgba(0, 0, 0, 0.2)"
          textAlign="left"
          zIndex="900000000000001"
          onClick={(e) => {
            e.stopPropagation()
          }}
          {...contentProps}
        >
          <PopoverArrow border="none" backgroundColor="#000" />
          <PopoverBody>{title}</PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  ) : (
    <span className="ChakraTooltipWrapper">{children}</span>
  )
}

export default ChakraTooltip
