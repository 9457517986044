import gql from "graphql-tag"
import SCHOOL_FRAGMENT from "../../../school/graphql/school-fragment"

export default gql`
  fragment LicenseOffering on LicenseOffering {
    id
    description
    cost_per_seat
    include_creator_seat
    register_creator_as
    minimum_seat_count
    minimum_license_duration
    licensing_mode
    included_in_user_subscription
    include_in_curriculum_plans(first: 50) {
      total
      edges {
        node {
          id
          plan_id
        }
      }
    }
    include_in_subscription_plans(first: 50) {
      total
      edges {
        node {
          id
        }
      }
    }
    licensor {
      ...School
    }
  }
  ${SCHOOL_FRAGMENT}
`
