import IconButton from "@pathwright/ui/src/components/button/IconButton"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

const StyledButton = styled(IconButton)`
  border-radius: 100%;
  background-color: rgba(218, 218, 218, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;

  .Pathicon {
    background: transparent;
    color: rgba(0, 0, 0, 0.7);
    border-radius: 100%;
    font-size: 16px;
    padding: 1px;
  }
`

const MediaIconButton = ({
  onClick,
  icon,
  isMobile,
  iconSize,
  href,
  download,
  styleType,
  target,
  style
}) => {
  return (
    <StyledButton
      onClick={onClick}
      icon={icon}
      isMobile={isMobile}
      iconSize={iconSize}
      href={href}
      download={download}
      styleType={styleType}
      target={target || download ? "_blank" : ""}
      style={style}
      backgroundFilter="brightness(.85) blur(20px)"
    />
  )
}

MediaIconButton.defaultProps = {
  icon: "edit",
  styleType: "light",
  iconSize: "18px"
}

MediaIconButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  isMobile: PropTypes.bool,
  iconSize: PropTypes.string.isRequired,
  href: PropTypes.string,
  download: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  styleType: PropTypes.oneOf(["dark", "light"]).isRequired,
  target: PropTypes.string
}

export default MediaIconButton
