import { Spacer, Spinner, VStack } from "@chakra-ui/react"
import { PropsWithChildren, ReactNode, createContext, useContext } from "react"
import { AuthContextQueryVariables } from "../../../api/generated"
import { UserPropertyDefaults } from "../types"
import { extendWithAllowSignup } from "../utils/allowSignup"
import { extendWithCreateMembership } from "../utils/createMembership"
import { AuthContextType, useAuthContextValue } from "./loader"

const AuthContext = createContext<AuthContextType>({
  authMethods: [],
  allowSignup: false,
  alwaysPromptForPassword: false,
  consentDescription: "",
  inviteToken: null,
  userPropertyDefaults: null,
  authIntent: null
})

export const useAuthContext = () => {
  return useContext(AuthContext)
}

export const Provider = ({
  children,
  value
}: {
  children: ReactNode
  value: AuthContextType
}) => {
  value = extendWithAllowSignup(value)
  value = extendWithCreateMembership(value)
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const ConnectedAuthProvider = ({
  children,
  variables,
  // TODO: assemble these defaults from the invite token?
  userPropertyDefaults,
  allowSignup,
  createMembership
}: PropsWithChildren<{
  variables: AuthContextQueryVariables
  userPropertyDefaults?: UserPropertyDefaults
  // Allows overriding the space.allow_signup field.
  allowSignup?: boolean
  // Override the default behavior of creating a membership for the user
  // when they sign up (or sign in via Global SSO).
  createMembership?: boolean
}>) => {
  let value = useAuthContextValue(variables)
  value = extendWithAllowSignup(value, allowSignup)
  value = extendWithCreateMembership(value, createMembership)
  value = {
    ...value,
    userPropertyDefaults: userPropertyDefaults
      ? { ...userPropertyDefaults }
      : null
  }

  return value ? (
    <Provider value={value}>{children}</Provider>
  ) : (
    <VStack spacing={4}>
      <Spacer />
      <Spinner />
      <Spacer />
    </VStack>
  )
}

export default AuthContext
