import { Box, BoxProps, useColorModeValue } from "@chakra-ui/react"

const AuthFormFooterBox = (props: BoxProps): JSX.Element => {
  const bg = useColorModeValue("secondary", "whiteAlpha.200")

  return (
    <Box bg={bg} borderRadius="20px" p={4} alignSelf="stretch" {...props} />
  )
}

export default AuthFormFooterBox
