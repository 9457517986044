import gql from "graphql-tag"
import PATH_ITEM_FRAGMENT from "./path-item-fragment"

export default gql`
  query PathItemQuery(
    $id: Int
    $path_id: Int
    $cohort_id: Int
    $user_id: Int
    $source_id: Int
  ) {
    pathItem(
      id: $id
      path_id: $path_id
      cohort_id: $cohort_id
      user_id: $user_id
      source_id: $source_id
    ) {
      ...PathItem
    }
  }
  ${PATH_ITEM_FRAGMENT}
`
