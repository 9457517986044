import ConfirmStripePayment from "@pathwright/web/src/modules/billing/ConfirmStripePayment"
import FallbackComponent from "store/StoreOrLibraryBrowse"
import { getDefaultLaunchedFromLibrary } from "../lib/core/routing/utils"
const { createCardRoutes } = require("lib/core/routing/card-routing")

const confirmStripeCardRoutes = {
  confirmSubscription: {
    path: ":subscription_id/",
    getDefaultLaunchedFrom: getDefaultLaunchedFromLibrary,
    component: (props) => (
      <ConfirmStripePayment
        card={props.card}
        subscriptionID={parseInt(props.match.params.subscription_id)}
      />
    )
  }
}

export default createCardRoutes(
  "/payment/confirm-stripe/",
  confirmStripeCardRoutes,
  FallbackComponent
)
