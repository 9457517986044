import capitalize from "lodash/capitalize"
import isArray from "lodash/isArray"
import isString from "lodash/isString"
import PropTypes from "prop-types"
import UnknownErrorMessage from "./UnknownErrorMessage"

const ErrorMessage = function ({ error }) {
  if (!error) {
    return null
  }

  let message = null

  try {
    message = JSON.parse(error.responseText).detail
  } catch (err) {
    message = error.message || error
  }

  if (isString(message)) {
    // Hack to remove "GraphQL error: " from error message, if present
    message = message.split("GraphQL error: ").join("")
    // Avoid showing SQL in error message. May need a more complete check.
    if (/^select/i.test(message) || /syntax error/i.test(message)) return null
    // Return humanized message.
    return <span>{message}</span>
  }

  switch (error.status) {
    case 500:
      return <UnknownErrorMessage />
    case 404:
      return <span>The resource you requested could not be found.</span>
    case 401 || 403:
      return <span>You do not have permission to access this resource.</span>
    case 400:
      try {
        const { ...errors } = JSON.parse(error.responseText)
        // Rest framwork validation error parsing
        return (
          <span>
            {Object.keys(errors).map((key) => {
              let v = errors[key]
              if (isArray(v)) {
                v = v[0]
              }
              return (
                <span>
                  {key ? `${capitalize(key)}: ` : null}
                  {v}
                  <br />
                </span>
              )
            })}
          </span>
        )
      } catch (err) {
        console.log("Encountered an error while parsing a server error", err)
        return <UnknownErrorMessage />
      }
    default:
      return <UnknownErrorMessage />
  }
}

ErrorMessage.displayName = "ErrorMessage"

ErrorMessage.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.object
  ])
}

export default ErrorMessage
