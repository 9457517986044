import Card from "@pathwright/ui/src/components/card/Card"
import CardBlock from "@pathwright/ui/src/components/card/CardBlock"
import useCardStackModal from "@pathwright/ui/src/components/card/useCardStackModal"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import TagManager from "@pathwright/ui/src/components/tag/manager/TagManager"
import { tagPropType } from "@pathwright/ui/src/components/tag/propTypes"
import PropTypes from "prop-types"
import { useState } from "react"
import TagManagerPrompt from "./TagManagerPrompt"
import {
  ConfirmRemove,
  ConfirmRemoveEverywhere,
  ConfirmRemoveVarious,
  ConfirmUpdate
} from "./TagMutationConfirmations"
import { tagAttachmentContextPropType } from "./propTypes"
import useTagPermissions from "./useTagPermissions"
import useTags from "./useTags"
import { getTagContextualLabel, getTagsContext } from "./utils"

export const useTagManagerCard = (options = {}) => {
  const {
    context,
    selectedTags,
    useContextLabels,
    CustomTagManager,
    ...tagManagerProps
  } = options
  const { cardStack, onOpenCardStack } = useCardStackModal({
    component: TagManagerCard,
    props: {
      context,
      selectedTags,
      useContextLabels,
      CustomTagManager,
      ...tagManagerProps
    }
  })

  return { tagManagerCardStack: cardStack, openTagManagerCard: onOpenCardStack }
}

export const TagManagerCard = ({
  card,
  context,
  selectedTags,
  useContextLabels,
  CustomTagManager,
  ...tagManagerProps
}) => {
  // Not passing in context as we want to get top-level permissions.
  const tagPermissions = useTagPermissions()
  const { manageTitle, managePrompt } = useContextLabels(context)
  const TagManagerComponent = CustomTagManager || TagManagerContainer

  return (
    <Card
      className="TagManagerCard"
      card={card}
      title={manageTitle}
      noaction
      emphasis="secondary"
    >
      <CardBlock paddingTop={false}>
        <TagManagerComponent
          context={context}
          selectedTags={selectedTags}
          useContextLabels={useContextLabels}
          {...tagManagerProps}
        />
      </CardBlock>
      {Boolean(context && tagPermissions.canEdit && managePrompt) && (
        <CardBlock>
          <TagManagerPrompt
            label={managePrompt}
            to={{
              card: {
                // NOTE: not passing context and selectedTags props as we want
                // to query top-level tags and ignore selectedTags provided based
                // on lower-lever context.
                component: TagManagerCard,
                props: {
                  useContextLabels,
                  CustomTagManager,
                  ...tagManagerProps
                }
              }
            }}
          />
        </CardBlock>
      )}
    </Card>
  )
}

TagManagerCard.displayName = "TagManagerCard"

TagManagerCard.propTypees = {
  card: PropTypes.object.isRequired,
  context: tagAttachmentContextPropType,
  selectedTags: PropTypes.arrayOf(tagPropType)
}

const TagManagerContainer = ({
  context,
  selectedTags,
  useContextLabels,
  ...tagManagerProps
}) => {
  const { t } = useTranslate()
  const { tags, updateTag, reorderTag, selectTags, unselectTags } = useTags({
    context,
    selectedTags
  })
  // Using tags without any context (even though TagManagerContainer may have not been passed
  // any context) to ensure we have a method for unselecting tags from top context.
  const { unselectTags: unselectTagsFromEverywhere } = useTags({
    selectedTags
  })
  const tagsContext = getTagsContext(context)
  const tagPermissions = useTagPermissions({ context })
  const { selectLabel, tagFormLabels } = useContextLabels(context)
  const [confirm, setConfirm] = useState(null)

  const handleOnChange = (nextTags, { action, target }) => {
    switch (action) {
      case "update-tag":
        if (target.tagAttachments.total > 1) {
          setConfirm({ tags: nextTags, action, target })
        } else {
          updateTag(target)
        }
        break
      case "unselect-tag":
        setConfirm({ tags: nextTags, action, target })
        break
      case "select-tags":
        selectTags(target)
        break
      case "reorder-tag":
        reorderTag(nextTags.selected, target)
        break
    }
  }

  const getConfirmation = () => {
    if (!confirm) return null

    switch (confirm.action) {
      case "update-tag":
        return (
          <ConfirmUpdate
            tag={confirm.target}
            onConfirm={() => {
              setConfirm(null)
              return updateTag(confirm.target)
            }}
            onCancel={() => setConfirm(null)}
          />
        )
      case "unselect-tag":
        // Of those tags filtered for context, keep all but the tag confirmed for removiing.
        const remainingSelectedTags = confirm.tags.filtered.filter(
          (tag) => tag.id !== confirm.target.id
        )

        // If in a non top-level context, we must confirm how exactly the user wants to
        // remove the tag, whether only from this context, or from all contexts.
        if (tagsContext) {
          return (
            <ConfirmRemoveVarious
              tag={confirm.target}
              onConfirm={() =>
                // Was deleteTagAttachment
                unselectTags(remainingSelectedTags).then(() => setConfirm(null))
              }
              onConfirmEverywhere={() =>
                // Was deleteTag
                unselectTagsFromEverywhere(remainingSelectedTags).then(() =>
                  setConfirm(null)
                )
              }
              onCancel={() => setConfirm(null)}
            />
          )
        }

        // If the tag is only attached to 1 or less contexts, provide simple confirmation
        // for removing the tag from just "here".
        if (confirm.target.tagAttachments.total <= 1) {
          return (
            <ConfirmRemove
              tag={confirm.target}
              onConfirm={() =>
                // Was deleteTag
                unselectTags(remainingSelectedTags).then(() => setConfirm(null))
              }
              onCancel={() => setConfirm(null)}
            />
          )
        }

        // At top-level context, and tag is shared across multiple contexts. We must prompt
        // the user to confirm removing this tag from all contexts.
        return (
          <ConfirmRemoveEverywhere
            tag={confirm.target}
            onConfirm={() =>
              // Was deleteTag
              unselectTags(remainingSelectedTags).then(() => setConfirm(null))
            }
            onCancel={() => setConfirm(null)}
          />
        )
      default:
        return null
    }
  }

  return (
    <>
      <TagManager
        // First pass ...tagManagerProps so as not to override props (like tags!).
        {...tagManagerProps}
        tags={tags}
        tagPermissions={tagPermissions}
        getTagMetaLabels={(tag) => [getTagContextualLabel(tag, t)]}
        onChange={handleOnChange}
        selectLabel={selectLabel}
        tagFormLabels={tagFormLabels}
      />
      {getConfirmation()}
    </>
  )
}

TagManagerContainer.displayName = "TagManagerContainer"

TagManagerContainer.propTypes = {
  context: tagAttachmentContextPropType,
  selectedTags: PropTypes.arrayOf(tagPropType)
}

TagManagerContainer.defaultProps = {
  useContextLabels: () => ({})
}

export default TagManagerContainer
