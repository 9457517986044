import gql from "graphql-tag"
import ORDER_FRAGMENT from "./order-fragment"

export default gql`
  query OrderQuery($id: Int!) {
    order(id: $id) {
      ...Order
    }
  }
  ${ORDER_FRAGMENT}
`
