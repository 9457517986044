export const hasStoreEnabled = () => {
  return window.school && window.school.platform_version >= 2.8
}

export const getStoreUrl = (slug) => {
  let url = hasStoreEnabled() ? "/store/" : "/library/"
  if (slug) {
    url += slug
    url += hasStoreEnabled() ? "/" : "/about/"
  }
  return url
}

export const getStoreLabel = () => {
  return hasStoreEnabled() ? "Store" : "Library"
}
