import PropTypes from "prop-types"
import {
  FEATURE_ACTION_AUTH_LOGIN,
  FEATURE_ACTION_UPDATE_VISIBILITY,
  FEATURE_ACTION_VIEW,
  FEATURE_KEY_ACTIVITY_FEED,
  FEATURE_KEY_AUTH_LOGIN_OPTION_EMAIL_LINK,
  FEATURE_KEY_AUTH_LOGIN_OPTION_GLOBAL_SSO_APPLE,
  FEATURE_KEY_AUTH_LOGIN_OPTION_GLOBAL_SSO_GOOGLE,
  FEATURE_KEY_AUTH_LOGIN_OPTION_PASSWORD,
  FEATURE_KEY_AUTH_PLATFORM,
  FEATURE_KEY_MEMBER_DIRECTORY,
  FEATURE_PERMISSION_ADMIN_ONLY,
  FEATURE_PERMISSION_EVERYONE,
  FEATURE_PERMISSION_STAFF_ONLY
} from "./constants"

export const featureActionPropType = PropTypes.oneOf([
  FEATURE_ACTION_VIEW,
  FEATURE_ACTION_UPDATE_VISIBILITY,
  FEATURE_ACTION_AUTH_LOGIN
])

export const featureKeyPropType = PropTypes.oneOf([
  FEATURE_KEY_MEMBER_DIRECTORY,
  FEATURE_KEY_ACTIVITY_FEED,
  FEATURE_KEY_AUTH_PLATFORM,
  FEATURE_KEY_AUTH_LOGIN_OPTION_PASSWORD,
  FEATURE_KEY_AUTH_LOGIN_OPTION_EMAIL_LINK,
  FEATURE_KEY_AUTH_LOGIN_OPTION_GLOBAL_SSO_GOOGLE,
  FEATURE_KEY_AUTH_LOGIN_OPTION_GLOBAL_SSO_APPLE
])

export const featureContextPropType = PropTypes.shape({
  resource_id: PropTypes.number,
  cohort_id: PropTypes.number
})

export const permissionInContextPropType = PropTypes.oneOf([
  FEATURE_PERMISSION_ADMIN_ONLY,
  FEATURE_PERMISSION_STAFF_ONLY,
  FEATURE_PERMISSION_EVERYONE
])

export const featurePropType = {
  feature_action: featureActionPropType.isRequired,
  feature_key: featureKeyPropType.isRequired,
  permission_in_context: permissionInContextPropType.isRequired
  // resource_id: resourceId,
  // cohort_id: cohortId,
}
