// TODO: replace with Chakra
export const labelGray = "#888888"
export const black = "#000000"
export const offBlack = "#292e35"
export const mediumGray = "#555555"
export const white = "#ffffff"
export const offWhite = "#efefef"

// These are the baseline style options available by default
//  in the Block Styles menu;
//  some can be customized, ex: color
export const defaultStyleOptions = {
  handler: (option, menuOption, styleOption) => {
    if (!option || !menuOption || !styleOption)
      return new Error(
        "Can't apply block style — it looks like some information is missing."
      )

    const styles = defaultStyleOptions[menuOption][styleOption][option]

    return styles
  },
  block: {
    color: {
      lightest: {
        color: offBlack,
        backgroundColor: white
      },
      light: {
        color: offBlack,
        backgroundColor: offWhite
      },
      darker: {
        color: white,
        backgroundColor: mediumGray
      },
      darkest: {
        color: white,
        backgroundColor: offBlack
      },
      // This is a placeholder to populate the custom menu option
      custom: {
        color: "",
        backgroundColor: ""
      }
    },
    width: {
      horspacingfull: {
        paddingLeft: "0",
        paddingRight: "0"
      },
      // horspacingtight: {
      //   paddingLeft: "30px",
      //   paddingRight: "30px"
      // },
      horspacingnormal: {
        paddingLeft: "40px",
        paddingRight: "40px"
      },
      horspacingwide: {
        paddingLeft: "80px",
        paddingRight: "80px"
      }
    },
    height: {
      vertspacingfull: {
        paddingTop: "0",
        paddingBottom: "0"
      },
      // vertspacingtight: {
      //   paddingTop: "30px",
      //   paddingBottom: "30px"
      // },
      vertspacingnormal: {
        paddingTop: "35px",
        paddingBottom: "35px"
      },
      vertspacingwide: {
        paddingTop: "60px",
        paddingBottom: "60px"
      }
    }
  }
}

// These are used by the Block Styles Menu to offer styling choices
export const blockStyleMenuOptions = {
  // Each key at this level represents a menu item (block, text, animation)
  block: {
    label: "Block",
    tools: [
      // Each of these represents a tool under a menu item (ex: block > color)
      {
        label: "Color",
        key: "blockColor",
        type: "color",
        displayStyles: true,
        options: Object.keys(defaultStyleOptions.block.color),
        handler: (option) =>
          defaultStyleOptions.handler(option, "block", "color")
      },
      {
        label: "Horizontal Space",
        key: "blockWidth",
        type: "width",
        icons: {
          horspacingfull: "spacing-horizontal-small",
          // horspacingtight: "spacing-horizontal-small",
          horspacingnormal: "spacing-horizontal-medium",
          horspacingwide: "spacing-horizontal-large"
        },
        options: Object.keys(defaultStyleOptions.block.width),
        handler: (option) =>
          defaultStyleOptions.handler(option, "block", "width")
      },
      {
        label: "Vertical Space",
        key: "blockHeight",
        type: "height",
        icons: {
          vertspacingfull: "spacing-vertical-small",
          // vertspacingtight: "spacing-vertical-small",
          vertspacingnormal: "spacing-vertical-medium",
          vertspacingwide: "spacing-vertical-large"
        },
        options: Object.keys(defaultStyleOptions.block.height),
        handler: (option) =>
          defaultStyleOptions.handler(option, "block", "height")
      }
    ]
  }
  // Possible tools for future
  // text: {...},
  // animation: {...},
}

// Use this as boilerplate for a block's `styles` attribute (gql)
export const defaultBlockStyles = {
  selectedSettings: {
    blockColor: "lightest",
    blockWidth: "horspacingfull",
    blockHeight: "vertspacingnormal"
  },
  // These are CSS styles
  styleAttributes: {
    ...defaultStyleOptions.block.color.lightest,
    ...defaultStyleOptions.block.width.horspacingfull,
    ...defaultStyleOptions.block.height.vertspacingnormal
  }
}
