import { useUIThemeContext } from "@pathwright/ui/src/components/ui/PathwrightUI"
import {
  DANGEROUS_RED,
  getAlphaColor,
  parseRgba,
  PRIMARY_GRAY,
  PRIMARY_WHITE,
  TERTIARY_GRAY
} from "@pathwright/ui/src/components/utils/colors"
import classnames from "classnames"
import omit from "lodash/omit"
import PropTypes from "prop-types"
import React from "react"
import styled, { css, keyframes } from "styled-components"
import CountIndicator from "../indicator/CountIndicator"
import Link from "../link/Link"
import Pathicon from "../pathicon/Pathicon"
import { useScreensizeContext } from "../ui/Screensize"
import {
  DEFAULT_FONT_FAMILY,
  overflowStyles,
  textStyles
} from "../ui/typography"
import "./Button.css"

export const BUTTON_CSS_VARS = {
  BORDER_RADIUS: "--button-border-radius"
}

const pulse = ({
  getForegroundColor,
  getBackgroundColor,
  invertPulse
}) => keyframes`
  0% {
    box-shadow: 0 0 0 0 ${
      invertPulse ? getBackgroundColor(0.3) : getForegroundColor(0.3)
    };
  }
  50% {
    box-shadow: 0 0 0 10px ${
      invertPulse ? getBackgroundColor(0) : getForegroundColor(0)
    };
  }
  100% {
    box-shadow: 0 0 0 0 ${
      invertPulse ? getBackgroundColor(0) : getForegroundColor(0)
    };
  }
`

const StyledButton = styled.button.attrs((props) => {
  return {
    "aria-label": props.ariaLabel,
    "aria-labelledby": props.ariaLabelledBy
  }
})`
  ${(p) => css`
    position: relative;
    display: inline-block;
    outline: none;
    border: none;
    text-align: center;
    text-decoration: none !important;
    white-space: nowrap;
    ${BUTTON_CSS_VARS.BORDER_RADIUS}: 0px;
    border-radius: var(${BUTTON_CSS_VARS.BORDER_RADIUS});

    > span {
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: pre;
    }

    &.small {
      padding: 0.3em 0.8em;
    }

    &.medium {
      padding: 0.4em 0.9em;
    }

    &.large {
      padding: 0.5em 1em;
    }

    &.interactive {
      cursor: pointer;
    }

    &.rounded {
      ${BUTTON_CSS_VARS.BORDER_RADIUS}: 100px;
    }

    &.stretch {
      height: 100%;
      width: 100%;
    }

    &[disabled] {
      pointer-events: none;
      color: ${p.getForegroundColor(0.65)};
      background-color: ${p.getBackgroundColor(0.3)};
      border-color: ${p.getBackgroundColor(0)};

      &.inverted {
        pointer-events: none;
        color: ${p.getForegroundColor(0.45)};
        background-color: ${p.getBackgroundColor(0.3)};
        border-color: ${p.getBackgroundColor(0)};
      }
    }

    &.fade {
      background-color: ${p.getBackgroundColor(0)} !important;
    }

    &.pulse {
      animation: ${pulse} 2000ms ease-in infinite;
    }

    & .CountIndicator {
      position: absolute;
      top: 0.25em;
      right: 0.25em;
      transform: translate(0.5em, -0.5em);
    }

    /* NOTE: Adjusting positioning based on border-radius. */
    &.rounded .CountIndicator {
      top: 0.35em;
      right: 0.35em;
    }

    /* NOTE: Adjusting positioning based on background transparency. */
    &.blank .CountIndicator, {
    &.text .CountIndicator {
      top: 0.4em;
      right: 0.4em;
    }
  `}
`

const StyledButtonDefault = styled(StyledButton)`
  ${(p) => `
    color: ${p.getBackgroundColor(1)};
    background-color: ${p.getBackgroundColor(0.05)};
    border-color: ${p.getBackgroundColor(0.5)};
    border-width: 1px;
    border-style: solid;

    &.interactive:active {
      color: ${p.getBackgroundColor(0.95)};
      background-color: ${p.getBackgroundColor(0.25)};
      border-color: ${p.getBackgroundColor(0.5)};
    }

    &.interactive.inverted {
      color: ${p.getForegroundColor(0.95)};
      background-color: ${p.getBackgroundColor(0.75)};
      border-color: ${p.getForegroundColor(0.5)};
    }

    &.interactive.inverted:active {
      color: ${p.getForegroundColor(1)};
      background-color: ${p.getBackgroundColor(0.95)};
      border-color: ${p.getForegroundColor(0.5)};
    }

    @media (hover: hover) {
      &.interactive:hover {
        color: ${p.getBackgroundColor(0.9)};
        background-color: ${p.getBackgroundColor(0.1)};
        border-color: ${p.getBackgroundColor(0.5)};
      }

      &.interactive.inverted:hover {
        color: ${p.getForegroundColor(0.9)};
        background-color: ${p.getBackgroundColor(0.9)};
        border-color: ${p.getForegroundColor(0.5)};
      }
    }
  `}
`

const StyledButtonPrimary = styled(StyledButton)`
  ${(p) => `
    color: ${p.getForegroundColor(1)};
    background-color: ${p.getBackgroundColor(1)};
    border-width: 0px;
    font-weight: 600 !important;

    &.interactive:active {
      color: ${p.getForegroundColor(0.95)};
      background-color: ${p.getBackgroundColor(0.75)};
    }

    &.interactive.inverted {
      color: ${p.getForegroundColor(1)};
      background-color: ${p.getBackgroundColor(1)};
    }

    &.interactive.inverted:active {
      color: ${p.getForegroundColor(0.95)};
      background-color: ${p.getBackgroundColor(0.75)};
    }

    @media (hover: hover) {
      &.interactive:hover {
        color: ${p.getForegroundColor(0.9)};
        background-color: ${p.getBackgroundColor(0.9)};
      }

      &.interactive.inverted:hover {
        color: ${p.getForegroundColor(0.9)};
        background-color: ${p.getBackgroundColor(0.9)};
      }
    }
  `}
`

// NOTE: the primary-alt button does not "fit" into the current Button design and
// is meant to always function in an inverted state
const StyledButtonPrimaryAlt = styled(StyledButton)`
  ${(p) => `
    color: rgba(256, 256, 256, 1);
    background-color: rgba(153, 153, 153, 0.7);
    border-width: 0px;
    backdrop-filter: blur(20px);
    font-weight: 600 !important;

    &.interactive:active {
      color: rgba(256, 256, 256, 1);
      background-color: rgba(153, 153, 153, 0.8)};
    }

    @media (hover: hover) {
      &.interactive:hover {
        color: rgba(256, 256, 256, .9)};
        background-color: rgba(153, 153, 153, 0.6)};
      }
    }
  `}
`

const StyledButtonSecondary = styled(StyledButton)`
  ${(p) => `
    color: ${p.getBackgroundColor(1)};
    border-width: 0px;
    background-color: ${p.getBackgroundColor(0.05)};
    font-weight: 600 !important;

    &.interactive:active {
      color: ${p.getBackgroundColor(0.95)};
      background-color: ${p.getBackgroundColor(0.25)};
    }

    &.interactive.inverted {
      color: ${p.getForegroundColor(0.95)};
      background-color: ${p.getBackgroundColor(0.75)};
    }

    &.interactive.inverted:active {
      color: ${p.getForegroundColor(1)};
      background-color: ${p.getBackgroundColor(0.95)};
    }

    @media (hover: hover) {
      &.interactive:hover {
        color: ${p.getBackgroundColor(0.9)};
        background-color: ${p.getBackgroundColor(0.1)};
      }

      &.interactive.inverted:hover {
        color: ${p.getForegroundColor(0.9)};
        background-color: ${p.getBackgroundColor(0.9)};
      }
    }
  `}
`

const StyledButtonTertiary = styled(StyledButton)`
  ${(p) => `
    color: ${p.getBackgroundColor(1)};
    border-width: 0px;
    background-color: transparent;

    &.interactive:active {
      color: ${p.getBackgroundColor(1)};
      background-color: ${p.getBackgroundColor(0.05)};
    }

    &.interactive.inverted {
      color: ${p.getBackgroundColor(1)};
      background-color: transparent;
    }

    &.interactive.inverted:active {
      color: ${p.getForegroundColor(0.9)};
      background-color: ${p.getForegroundColor(0.1)};
    }

    @media (hover: hover) {
      &.interactive:hover {
        color: ${p.getBackgroundColor(1)};
        background-color: ${p.getBackgroundColor(0.05)};
      }

      &.interactive.inverted:hover {
        color: ${p.getForegroundColor(0.9)};
        background-color: ${p.getBackgroundColor(0.9)};
      }
    }
  `}
`

const StyledButtonBlank = styled(StyledButton)`
  ${(p) => `
    color: ${p.getBackgroundColor(1)};
    border-width: 0px;
    line-height: inherit;
    font-size: inherit;
    background-color: transparent;

    &, &.rounded {
      ${BUTTON_CSS_VARS.BORDER_RADIUS}: 0px;
    }

    &.interactive:active {
      color: ${p.getBackgroundColor(0.95)};
      background-color: transparent;
    }

    &.interactive.inverted {
      color: ${p.getBackgroundColor(1)};
      background-color: transparent;
    }

    &.interactive.inverted:active {
      color: ${p.getBackgroundColor(0.9)};
      background-color: transparent;
    }

    @media (hover: hover) {
      &.interactive:hover {
        color: ${p.getBackgroundColor(0.9)};
        background-color: transparent;
      }

      &.interactive.inverted:hover {
        color: ${p.getBackgroundColor(0.95)};
        background-color: transparent;
      }
    }

    &[disabled]:not(.inverted) {
      color: ${p.getBackgroundColor(0.3)};
      background: transparent;
    }

    &.inverted[disabled] {
      color: ${p.getBackgroundColor(0.5)};
      background: transparent;
    }
  `}
`

export const StyledButtonText = styled(StyledButtonBlank)`
  line-height: inherit;
  font-size: inherit;
  border-width: 0;
  border-bottom-width: 1px;
  border-style: solid;
  padding: 0px !important;

  &,
  &.rounded {
    ${BUTTON_CSS_VARS.BORDER_RADIUS}: 0px;
  }
`

const StyledButtonInline = styled(StyledButtonBlank)`
  padding: 0px !important;
`

const StyledButtonEmpty = styled(StyledButtonSecondary)`
  ${(p) => `
    border-width: 1px;
    border-style: dashed;
    border-color: ${p.getBackgroundColor(0.5)};

    &.rounded {
      ${BUTTON_CSS_VARS.BORDER_RADIUS}: 5px;
    }
  `}
`

const StyledButtonCard = styled(StyledButtonTertiary)`
  ${(p) => `
    background-color: ${p.getBackgroundColor(0)};
    display: block;
    width: 100%;
    padding: 15px 25px !important;

    &, &.rounded {
      ${BUTTON_CSS_VARS.BORDER_RADIUS}: 0px;
    }
  `}
`

const getStyledButton = (styleType) => {
  switch (styleType) {
    case "default":
      return StyledButtonDefault
    case "primary":
      return StyledButtonPrimary
    case "primary-alt":
      return StyledButtonPrimaryAlt
    case "secondary":
      return StyledButtonSecondary
    case "tertiary":
      return StyledButtonTertiary
    case "text":
      return StyledButtonText
    case "inline":
      return StyledButtonInline
    case "blank":
      return StyledButtonBlank
    case "empty":
      return StyledButtonEmpty
    case "card":
      return StyledButtonCard
    default:
      return StyledButton
  }
}

const ButtonWrapper = React.forwardRef(function Button(
  {
    styleType,
    size,
    active,
    interactive,
    disabled,
    rounded,
    color,
    truncate,
    fade,
    pulse,
    invertPulse,
    stretch,
    brand,
    to,
    href,
    target,
    download,
    dangerous,
    style,
    inverted,
    onClick,
    onMouseEnter,
    onMouseLeave,
    label,
    children,
    backgroundFilter,
    el,
    type,
    icon,
    preIcon,
    postIcon,
    iconProps,
    preIconProps,
    postIconProps,
    indicator,
    countIndicatorProps,
    className: classNameProp,
    ...rest
  },
  ref
) {
  const theme = useUIThemeContext()
  const screensize = useScreensizeContext()

  // Normalize icon props.
  preIcon = preIcon || icon
  preIconProps = preIconProps || iconProps

  const className = classnames(
    "Button",
    `${styleType}`,
    `${size}`,
    {
      rounded,
      inverted,
      fade,
      pulse: pulse || invertPulse,
      stretch,
      active,
      interactive: interactive && !disabled
    },
    classNameProp
  )

  const baseColor = React.useMemo(() => {
    if (disabled) {
      return TERTIARY_GRAY
    } else if (dangerous) {
      return DANGEROUS_RED
    } else if (color) {
      return parseRgba(color)
    } else if (brand) {
      return theme.primaryBrandColor
    }

    return PRIMARY_GRAY
  }, [disabled, color, brand, dangerous])

  const invertColor = !inverted
  const invertBackgroundColor = inverted

  const foregroundColor = invertColor ? PRIMARY_WHITE : baseColor
  const backgroundColor = invertBackgroundColor ? PRIMARY_WHITE : baseColor

  const getBackgroundColor = React.useCallback(
    (alpha) => getAlphaColor(backgroundColor, alpha),
    [backgroundColor]
  )
  const getForegroundColor = React.useCallback(
    (alpha) => getAlphaColor(foregroundColor, alpha),
    [backgroundColor]
  )

  const buttonTextStyles = React.useMemo(
    () =>
      omit(
        textStyles({
          typography: "body",
          font: DEFAULT_FONT_FAMILY,
          size: size,
          screensize: screensize
        }),
        "margin"
      ),
    [size, screensize]
  )

  const styles = React.useMemo(
    () => ({
      ...buttonTextStyles,
      ...(style || {})
    }),
    [buttonTextStyles, style]
  )

  if (backgroundFilter) {
    styles.backdropFilter = backgroundFilter
  }

  let buttonElement = el
  if (!el) {
    if (to || href) {
      buttonElement = "a"
    } else {
      buttonElement = "button"
    }
  }

  const ButtonComponent = getStyledButton(styleType)

  const renderChildren = (children) => {
    // Prioritize children prop over children rendered based on props.
    if (children) {
      return typeof children === "function"
        ? children({
            foregroundColor,
            backgroundColor,
            // Allow children function to handle rendering Button children.
            renderChildren
          })
        : children
    }

    // Rendering children based on props.
    if (preIcon || postIcon || preIconProps || postIconProps || label) {
      return (
        <React.Fragment>
          {Boolean(preIcon || preIconProps) && (
            <Pathicon
              icon={preIcon}
              style={label ? { marginRight: 4 } : {}}
              {...preIconProps}
            />
          )}
          {label}
          {Boolean(postIcon || postIconProps) && (
            <Pathicon
              icon={postIcon}
              style={label ? { marginLeft: 4 } : {}}
              {...postIconProps}
            />
          )}
          {Boolean(countIndicatorProps || indicator) && (
            <CountIndicator
              size="1em"
              {...(countIndicatorProps || { count: Infinity, maxLabel: "" })}
            />
          )}
        </React.Fragment>
      )
    }

    return null
  }

  return (
    <ButtonComponent
      ref={ref}
      as={to ? Link : buttonElement}
      type={buttonElement === "button" ? type : null}
      className={className}
      style={styles}
      onClick={interactive ? onClick : undefined}
      onMouseEnter={interactive ? onMouseEnter : undefined}
      onMouseLeave={interactive ? onMouseLeave : undefined}
      href={href}
      to={to}
      target={target}
      download={download}
      disabled={disabled}
      getBackgroundColor={getBackgroundColor}
      getForegroundColor={getForegroundColor}
      invertPulse={invertPulse}
      {...rest}
    >
      <span
        className="Button__content-wrapper"
        style={truncate ? overflowStyles({ lines: 1 }) : null}
      >
        {renderChildren(children)}
      </span>
    </ButtonComponent>
  )
})

export const BUTTON_STYLE_TYPES = [
  "default",
  "primary",
  "primary-alt",
  "secondary",
  "tertiary",
  "text",
  "card",
  "blank",
  "empty",
  "inline",
  "light"
]

export const BUTTON_SIZES = ["large", "medium", "small", "custom"]

ButtonWrapper.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  styleType: PropTypes.oneOf(BUTTON_STYLE_TYPES),
  style: PropTypes.object,
  stretch: PropTypes.bool,
  color: PropTypes.string, // "green" || "rgb(0, 128, 0)" || #008000
  brand: PropTypes.bool,
  href: PropTypes.string,
  target: PropTypes.string,
  download: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  dangerous: PropTypes.bool,
  interactive: PropTypes.bool, // disable visual interactions
  disabled: PropTypes.bool, // disable all interactions
  inverted: PropTypes.bool,
  truncate: PropTypes.bool,
  fade: PropTypes.bool,
  pulse: PropTypes.bool,
  invertPulse: PropTypes.bool,
  rounded: PropTypes.bool,
  size: PropTypes.oneOf(BUTTON_SIZES),
  backgroundFilter: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  el: PropTypes.string,
  icon: PropTypes.string,
  preIcon: PropTypes.string,
  postIcon: PropTypes.string,
  iconProps: PropTypes.shape(Pathicon.propTypes),
  preIconProps: PropTypes.shape(Pathicon.propTypes),
  postIconProps: PropTypes.shape(Pathicon.propTypes),
  indicator: PropTypes.bool,
  countIndicatorProps: PropTypes.shape(CountIndicator.propTypes),
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  type: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.node
  ]),
  ariaLabel: PropTypes.string,
  ariaLabelledBy: PropTypes.string
}

ButtonWrapper.defaultProps = {
  styleType: "default",
  size: "medium",
  rounded: true,
  inverted: false,
  truncate: false,
  fade: false,
  pulse: false,
  invertPulse: false,
  disabled: false,
  interactive: true,
  dangerous: false,
  brand: false,
  download: false,
  backgroundFilter: null,
  el: null,
  type: "button"
}

export const buttonPropType = PropTypes.shape(ButtonWrapper.propTypes)

export default ButtonWrapper
