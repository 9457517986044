import LoadingBlock from "@pathwright/ui/src/components/loading/LoadingBlock"
import View from "@pathwright/ui/src/components/ui/View"
import styled from "styled-components"

const LoadingText = styled(LoadingBlock)`
  height: 1.5em;

  & + & {
    margin-top: 0.5em;
  }
`

const LoadingAvatar = styled(LoadingBlock)`
  border-radius: 100%;
  width: 1.5em;
  height: 1.5em;
  display: inline-block;
  margin-top: 0.5em;
`

const DiscussionListItemLoading = () => {
  return (
    <View.Primary
      className="DiscussionListItem"
      padding
      rounded
      shadow
      size="small"
    >
      <div>
        <div>
          <LoadingText />
          <LoadingAvatar />
        </div>
      </div>
    </View.Primary>
  )
}

DiscussionListItemLoading.displayName = "DiscussionListItemLoading"

export default DiscussionListItemLoading
