import {
  getSignedFilestackURL,
  isFilePickerURL
} from "@pathwright/ui/src/components/filestack/utils"
// USAGE: via ImageUpload
import "./_Cover.css"

const CoverPreviewer = ({ file }) => {
  if ((file != null ? file.url : undefined) && isFilePickerURL(file.url)) {
    file.image =
      getSignedFilestackURL(file.url, { w: 200, h: 150 }, true) || null
  }
  return (
    <div
      className="CoverPreviewer"
      style={{
        backgroundImage: `url(${file != null ? file.image : undefined})`
      }}
    />
  )
}

CoverPreviewer.displayName = "CoverPreviewer"

export default CoverPreviewer
