// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
let TooltipBehavior
export default TooltipBehavior = (function () {
  TooltipBehavior = class TooltipBehavior extends (
    require("lib/static-shim").default(Marionette.Behavior)
  ) {
    static initClass() {
      this.prototype.defaults = { selector: ".pw-help-tip" }
    }

    onRender() {
      return this.$(this.options.selector).tooltip({})
    }
  }
  TooltipBehavior.initClass()
  return TooltipBehavior
})()
