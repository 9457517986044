const onSignOut = () => window.App.getStore("auth").action.signOut().promise

const onEnterAuth = function (nextState, replace, callback) {
  const { location, match } = nextState
  const { auth_route } = match.params
  const { next } = location.query
  const { school } = window.App.getStore("school").getState()

  const authStore = window.App.getStore("auth")
  const navStore = window.App.getStore("navigation")

  if (next) {
    authStore.action.setNextUrl(next)
  }

  switch (auth_route) {
    case "sign-out":
      if (!authStore.get("is_authenticated")) {
        return callback(replace(navStore.request.getHomeLocation()))
      }
      return onSignOut().then(callback)
    case "sign-in":
      // for the unexpected case where an authenticated user lands on the /auth/sign-in/ route, navigate them to the home location
      if (authStore.get("is_authenticated")) {
        return callback(replace(navStore.request.getHomeLocation()))
      }
    case "sign-in": // for clarity
    case "sign-up":
      const { is_direct_modal } = navStore.getState()
      if (is_direct_modal) {
        // avoid showing a modal sign-in on top of sign in page
        return callback(
          replace({
            pathname: location.pathname,
            search: location.search
          })
        )
      } else {
        return callback()
      }
  }
}

const onLeaveAuth = () => window.App.getStore("auth").action.clearErrors()

export default {
  // The "success" rout handles the /auth/sign-out/success/ case which at this point
  // simply requires a redirect to the anonymous user's home location.
  path: "/auth/:auth_route(sign-out|sign-in|sign-up)/(success)?/",
  component: require("./components/Auth").default,
  onEnter: onEnterAuth,
  onLeave: onLeaveAuth
}
