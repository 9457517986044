import gql from "graphql-tag"

export default gql`
  query CohortSyncPathQuery($cohortId: Int!) {
    cohort: group(id: $cohortId) {
      id
      name
      is_master
      # registration for sync perms
      user_registration {
        id
        role
      }
      path(type: source) {
        id
      }
      source {
        id
        name
        is_master
        # registration for sync perms
        user_registration {
          id
          role
        }
        path(type: source) {
          id
        }
      }
      resource {
        id
        slug
        background_overlay
        blurred_background: background_image(blur: 500, width: 250, height: 250)
        cohorts: groups(first: 1, status: in_progress) {
          # NOTE: by default, the source cohort is not included in the results
          total
          edges {
            node {
              id
              name
              is_master
              path(type: source) {
                id
              }
            }
          }
        }
        license_offerings(first: 1) {
          total
        }
      }
    }
  }
`
