import { observer } from "mobx-react"
import pluralize from "pluralize"
import inviteStore from "../../store/invite-store"
import "./InviteFormError.css"

const InviteFormError = () => {
  return (
    <div className="InviteFormError">
      Please fix {pluralize("errors", inviteStore.errors.length, true)} before
      sending invites.&nbsp;
      <a onClick={inviteStore.action.removeErrors} href="javascript:void(0)">
        Remove errors
      </a>
    </div>
  )
}

InviteFormError.displayName = "InviteFormError"

export default observer(InviteFormError)
