import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"

// better UX if < 250ms responses don't show loader
class InviteLookup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.loading !== this.props.loading) {
      if (nextProps.loading) {
        this.willLoad = setTimeout(() => {
          if (this.props.loading) {
            this.setState({ loading: true })
          }
        }, 250)
      } else {
        if (this.willLoad) {
          clearTimeout(this.willLoad)
        }
        if (this.props.loading) {
          this.setState({ loading: false })
        }
      }
    }
  }

  componentWillUnmount() {
    if (this.willLoad) {
      clearTimeout(this.willLoad)
    }
  }

  render() {
    if (!this.state.loading) {
      return null
    }
    return <LoadingCircle className="InviteLookup__loader" size={16} />
  }
}

InviteLookup.displayName = "InviteLookup"

export default InviteLookup
