import { useEffect } from "react"
import isEqual from "react-fast-compare"
import useLocalStorage, {
  LocalStorageMeta,
  LocalStorageOptions
} from "./useLocalStorage"

export type FormStateKey =
  | string
  | Record<string, string>
  | (string | Record<string, string>)[]

export type FormStateStorage<T = any> =
  | {
      values: T | null | undefined
      initialValues: T | null | undefined
    }
  | null
  | undefined

type FormStateStorageSetter<T = any> = (value?: T | null) => void

type FormStateStorageReturn<T = any> = [
  FormStateStorage<T>,
  FormStateStorageSetter<T>,
  LocalStorageMeta
]

export const getFormStateStorageKey = (value?: FormStateKey) => {
  const objReducer = (value?: FormStateKey) => {
    if (typeof value === "object" && !Array.isArray(value)) {
      return Object.entries(value).reduce(
        (acc, [k, v]) => [...acc, k, v],
        [] as string[]
      )
    }
    return value
  }

  value = objReducer(value)

  if (typeof value === "string") {
    value = [value]
  }

  // Defaulting to location.pathname.
  if (!value) {
    value = window.location.pathname.split("/")
  }

  // In case we have an array containing flat objects, lets reduce those.
  value = value.reduce(
    // @ts-ignore - Type 'undefined' is not assignable to type 'ConcatArray<never>'
    (acc, part) => (part ? [...acc, ...[].concat(objReducer(part))] : acc),
    []
  )

  return ["formState", ...value].filter(Boolean).join(":")
}

const useFormStateStorage = <T = any>(
  initialValues: T,
  key?: FormStateKey,
  localStorageOptions?: LocalStorageOptions
): FormStateStorageReturn<T> => {
  // Using the window.location.pathname to key the form state ensures uniqueness.
  const formStateStorageKey = getFormStateStorageKey(key)

  // Whether the formStateStorage.initialValues has diverged from the initialValues.
  const getInitialValueDiverged = (formStateStorage?: FormStateStorage<T>) => {
    return Boolean(
      formStateStorage?.initialValues &&
        !isEqual(initialValues, formStateStorage.initialValues)
    )
  }

  const getValuesUnmodified = (formStateStorage?: FormStateStorage<T>) => {
    return Boolean(
      formStateStorage &&
        isEqual(formStateStorage.initialValues, formStateStorage.values)
    )
  }

  const getInitialValue = (formStateStorage?: FormStateStorage<T>) => {
    if (getInitialValueDiverged(formStateStorage)) {
      if (formStateStorage) {
        // We need to default to initialValues. This way, we ensure the user doesn't
        // unknowingly overwrite data.
        formStateStorage.values = initialValues
        formStateStorage.initialValues = initialValues
      }
    }

    // Return current formStateStorage, possibly modified above.
    if (formStateStorage !== undefined) return formStateStorage

    // Return the default formStateStorage
    return {
      // Track current formStateStorage.
      values: initialValues,
      // Track formStateStorage.initialValues which can be compared to initialValues to determine
      // if the initialValues have changed since last form mount. If changed, we can
      // assume data has been altered in the DB and the safest path forward is to
      // discard local formStateStorage.
      initialValues: null
    }
  }

  const [formStateStorage, setFormStorageState, formStateStorageMeta] =
    useLocalStorage<FormStateStorage<T>>(
      formStateStorageKey,
      getInitialValue,
      localStorageOptions
    )

  // If not formInitialStateMatches then we need to discard form state.
  useEffect(() => {
    if (
      getInitialValueDiverged(formStateStorage) ||
      getValuesUnmodified(formStateStorage)
    ) {
      // Discard changes.
      setFormStorageState()
    }
  }, [])

  // Wraps setFormStorageState. If no data is provided, the intent is to discard
  // storage altogether, otherwise update values and ensure an initialValues is set.
  const setFormStateWrapper: FormStateStorageSetter<T> = (values) => {
    // Values should be an object, null, or undefined.
    if (values) {
      // Update, via function to ensure we're using the latest formStateStorage.
      setFormStorageState(() => {
        // Default to preserving initialValues.
        let nextInitialValues = formStateStorage?.initialValues
        // If we're setting formStateStorage.values and there are not yet any formStateStorage.initialValues,
        // then set formStateStorage.initialValues.
        if (!nextInitialValues) nextInitialValues = initialValues

        return {
          values,
          initialValues: initialValues
        }
      })
    } else {
      if (values === null) {
        // If values is null, the formStateStorageKey key will be retained, but all data cleared.
        setFormStorageState(null)
      } else {
        // If values is undefined, the formStateStorageKey will be removed from localStorage.
        setFormStorageState()
      }
    }
  }

  return [formStateStorage, setFormStateWrapper, formStateStorageMeta]
}

export default useFormStateStorage
