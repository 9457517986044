import { useMutation } from "@apollo/client"
import { graphql } from "@apollo/client/react/hoc"
import compose from "lodash/flowRight"
import get from "lodash/get"
import PropTypes from "prop-types"
import { useEffect, useMemo, useReducer, useState } from "react"
import { ROLE_TYPE_OFFERING, ROLE_VALUES } from "../invitation/constants"
import { RegistrationRoleType } from "../invitation/types"
import MENTOR_GROUP_QUERY from "../mentor-group/graphql/mentor-group-query"
import USER_QUERY from "../user/graphql/user-query"
import Assign from "./Assign"
import AssignComplete from "./AssignComplete"
import AssignGate from "./AssignGate"
import ASSIGN_MUTATION from "./graphql/assign-mutation"
import { useAssignRoles } from "./hooks"

const reducer = (
  state,
  { type, role, user, resource, cohort, resourceId, cohortId }
) => {
  const conditionallyAdd = (item, items) => {
    if (item) {
      const itemIndex = items.findIndex((_item) => _item.id === item.id)
      // removing item if item already exists
      if (itemIndex !== -1) {
        items.splice(itemIndex, 1)
      }
      // adding latest version of item
      return items.concat(item)
    }

    return items
  }

  const conditionallyRemove = (item, items) => {
    if (item) {
      return items.filter((_item) => _item.id !== item.id)
    }

    return items
  }

  const getResource = (resourceId) =>
    state.resources.find((resource) => resource.id === resourceId)
  const getCohort = (cohortId) =>
    state.cohorts.find((cohort) => cohort.id === cohortId)

  resourceId = resourceId || (resource && resource.id)
  cohortId = cohortId || (cohort && cohort.id)
  resource = resource || getResource(resourceId)
  cohort = cohort || getCohort(cohortId)

  // NOTE: curley brackets for case statements create a block where `const` constants are scoped to case block (otherwise they polute the other cases' scopes, i.e. `resourceCohortMap`)
  switch (type) {
    case "add": {
      let selectedCohortId =
        cohortId || state.resourceCohortMap[resourceId] || null

      // // catch any case where the cohort to be added is not available in the resource.cohorts
      // if (
      //   cohortId &&
      //   !resource.cohorts.find(cohort => cohort.id === cohortId)
      // ) {
      //   cohortId = cohortId = selectedCohortId = null
      // }

      // Auto select cohort if only one exists
      if (resource && !selectedCohortId) {
        if (resource.cohorts.length === 1) {
          selectedCohortId = resource.cohorts[0].id
        }
      }

      const resourceCohortMap = {
        ...state.resourceCohortMap,
        // Prevent arbitrarily clearing cohortId
        ...(resourceId
          ? {
              [resourceId]: selectedCohortId
            }
          : {})
      }

      return {
        ...state,
        resourceCohortMap,
        resources: conditionallyAdd(resource, state.resources),
        cohorts: conditionallyAdd(cohort, state.cohorts),
        users: conditionallyAdd(user, state.users)
      }
    }
    case "remove": {
      const cohortToRemove = cohortId
        ? state.cohorts.find((cohort) => cohort.id === cohortId)
        : state.cohorts.find(
            (cohort) => cohort.id === state.resourceCohortMap[resourceId]
          )

      const resourceCohortMap = Object.keys(state.resourceCohortMap)
        .map((id) => parseInt(id))
        .reduce((map, _resourceId) => {
          if (resourceId && resourceId === _resourceId) {
            // clearing the resourceId
            return map
          }

          if (cohort && cohort.id === state.resourceCohortMap[_resourceId]) {
            // clearing the cohortId
            return {
              ...map,
              [_resourceId]: null
            }
          }

          // unchanged
          return {
            ...map,
            [_resourceId]: state.resourceCohortMap[_resourceId]
          }
        }, {})

      return {
        ...state,
        resourceCohortMap,
        resources: conditionallyRemove(resource, state.resources),
        cohorts: conditionallyRemove(cohortToRemove, state.cohorts),
        users: conditionallyRemove(user, state.users)
      }
    }
    case "role": {
      if (role === state.role) return state

      // role has changed; update selected cohort for each selected resource based on filtered cohorts available to new role
      return Object.keys(state.resourceCohortMap)
        .map((id) => parseInt(id))
        .reduce(
          (state, resourceId) => {
            const resource = getResource(resourceId)
            // find the cohort in the next role's filtered cohorts, if it exists
            const cohort = resource.cohorts.find(
              (cohort) => cohort.id === state.resourceCohortMap[resourceId]
            )

            return [
              // remove currently selected cohort
              {
                type: "remove",
                cohortId: state.resourceCohortMap[resourceId]
              },
              // if cohort exists, adds cohort back; if not, potentially auto-adds only available cohort
              {
                type: "add",
                resourceId,
                cohort
              }
            ].reduce(reducer, state)
          },
          { ...state, role } // merge in new role
        )
    }
  }
}

const AssignFlow = ({
  user,
  communityGroup,
  resourceId,
  cohortId,
  onClose
}) => {
  const [sendNotification, setSendNotification] = useState(true)
  const [assignmentMessage, setAssignmentMessage] = useState("")
  const { defaultAssignRole } = useAssignRoles()
  const initialState = useMemo(
    () => ({
      role: defaultAssignRole,
      resourceCohortMap: {},
      resources: [],
      cohorts: [],
      users: [],
      communityGroups: []
    }),
    [defaultAssignRole]
  )

  const [
    { resourceCohortMap, resources, cohorts, users, communityGroups, role },
    updateAssign
  ] = useReducer(reducer, initialState)

  const setRole = (role) =>
    updateAssign({
      type: "role",
      role
    })

  useEffect(
    () =>
      updateAssign({
        type: resourceId ? "add" : "remove",
        resourceId,
        cohortId
      }),
    [resourceId, cohortId]
  )

  useEffect(
    () =>
      updateAssign({
        type: user ? "add" : "remove",
        user
      }),
    [user]
  )

  useEffect(
    () =>
      updateAssign({
        type: communityGroup ? "add" : "remove",
        communityGroup
      }),
    [communityGroup]
  )

  useEffect(() => setRole(defaultAssignRole), [defaultAssignRole])

  const handleToggleSendNotification = () => {
    setSendNotification(!sendNotification)
  }

  const [assign, { data, loading, error }] = useMutation(ASSIGN_MUTATION, {
    variables: {
      offerings: Object.values(resourceCohortMap).map((id) => parseInt(id)),
      assign_to_user: user && user.id,
      role: ROLE_VALUES[ROLE_TYPE_OFFERING][role],
      send_notification: sendNotification,
      assignment_message: assignmentMessage
    },
    refetchQueries: ["UserRegistrationsQuery"]
  })

  const { assign: cohortRegistrationMap } = data || {}

  return (
    <AssignGate>
      <Assign
        user={users[0]} // for now, only supporting one assignee
        communityGroup={communityGroups[0]}
        role={role}
        resourceCohortMap={resourceCohortMap}
        sendNotification={sendNotification}
        resources={resources}
        cohorts={cohorts}
        // updaters
        selectRole={setRole}
        updateAssign={updateAssign}
        toggleSendNotification={handleToggleSendNotification}
        assignmentMessage={assignmentMessage}
        setAssignmentMessage={setAssignmentMessage}
        // submit
        submit={assign}
        error={error}
      />
      {!!cohortRegistrationMap && !error && (
        <AssignComplete
          role={role}
          user={users[0]}
          communityGroup={communityGroups[0]}
          resourceCohortMap={resourceCohortMap}
          resources={resources}
          cohorts={cohorts}
          sendNotification={sendNotification}
          cohortRegistrationMap={cohortRegistrationMap}
          onClose={onClose}
        />
      )}
    </AssignGate>
  )
}

AssignFlow.displayName = "AssignFlow"

AssignFlow.propTypes = {
  userId: PropTypes.number,
  communityGroupId: PropTypes.number,
  resourceId: PropTypes.number,
  role: RegistrationRoleType
}

export default compose(
  graphql(USER_QUERY, {
    options: ({ userId }) => ({
      variables: {
        id: userId
      }
    }),
    skip: ({ userId }) => !userId,
    props: ({ data }) => ({
      user: data.user
    })
  }),
  graphql(MENTOR_GROUP_QUERY, {
    options: ({ communityGroupId }) => ({
      variables: {
        id: communityGroupId
      }
    }),
    skip: ({ communityGroupId }) => !communityGroupId,
    props: ({ data }) => ({
      communityGroup: get(data, "school.mentor_group")
    })
  })
)(AssignFlow)
