import { useShareDataContext } from "../shared/ShareDataContextProvider"
import { useShareUIContext } from "../shared/ShareUIContextProvider"

const useGatedShareAccessKey = () => {
  const { tabKey } = useShareUIContext()
  const { permissions } = useShareDataContext()

  if (!permissions.share_access) return null

  const accessKey = "share_access"
  const tabAccessKey = `share_${tabKey}_access`

  const gatedShareAccessKey =
    permissions[accessKey].access === "GATED"
      ? accessKey
      : permissions[tabAccessKey].access === "GATED"
      ? tabAccessKey
      : null

  return gatedShareAccessKey
}

export default useGatedShareAccessKey
