// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
Marionette.Renderer.render = _.wrap(
  Marionette.Renderer.render,
  function (origRender, template, data) {
    try {
      if (typeof template === "function") {
        return template(data)
      }

      if (template != null ? template.render : undefined) {
        return template.render(data)
      } else {
        return console.log("Error: invalid template: ", template, data)
      }
    } catch (e) {
      console.log("Error rendering template: ", template, data, e)
    }
  }
)
