import {
  BlockCompletionType,
  BlockDataType,
  BlockType,
  ContentCompletionType
} from "../types"
import {
  calculateCompletionProgress,
  handleMissingBlock,
  replaceBlockInState
} from "../utils/utils"
import { ViewerStateType } from "./viewerState"

type StatePartial = Partial<ViewerStateType>

export const startSyncingAction = () => (): StatePartial => {
  return {
    syncing: true
  }
}

export const stopSyncingAction = () => (): StatePartial => {
  return {
    syncing: false
  }
}

export const errorAction =
  ({ error }: { error: Error }) =>
  (): StatePartial => {
    return {
      error,
      syncing: false
    }
  }

export const syncViewerAction =
  ({ content }) =>
  (state: ViewerStateType): ViewerStateType => {
    const nextState: ViewerStateType = {
      ...state,
      blocks: [...(content?.blocks || state.blocks)],
      lastModified: content?.lastModifiedDateTime
    }

    nextState.completion =
      content?.completion ||
      calculateCompletionProgress({
        blocks: nextState.blocks,
        completion: state.completion
      })

    return nextState
  }

export const updateUserBlockAction =
  ({
    id,
    data,
    completion
  }: {
    id: string
    data: BlockDataType
    completion?: BlockCompletionType
  }) =>
  (state: ViewerStateType): StatePartial => {
    const oldBlock: BlockType | undefined = state.blocks.find(
      (b) => b.id === id
    )

    if (!oldBlock) return handleMissingBlock(id)

    const mergedBlock: BlockType = {
      ...oldBlock,
      data: {
        ...oldBlock.data,
        ...data
      },
      completion
    }

    const nextState: StatePartial = {
      ...state,
      blocks: replaceBlockInState(mergedBlock, state)
    }

    // This ensures our Complete Step button is disabled optimistically
    // (but not enabled optimistically, since it would then be clickable with incomplete progress data)
    const optimisticCompletion: ContentCompletionType =
      calculateCompletionProgress({
        blocks: nextState.blocks!,
        completion: state.completion
      })
    if (
      optimisticCompletion?.progress !== null &&
      optimisticCompletion?.progress < 1
    ) {
      nextState.completion = optimisticCompletion
    }

    return nextState
  }

export const syncedUserBlockAction =
  (block: BlockType) =>
  (state: ViewerStateType): ViewerStateType => {
    const oldBlock: BlockType | undefined = state.blocks.find(
      (b) => b.id === block.id
    )

    if (!oldBlock)
      return {
        ...state,
        ...handleMissingBlock(block.id, "update", state)
      }

    const mergedBlock: BlockType = {
      ...oldBlock,
      ...block
    }

    const nextState: ViewerStateType = {
      ...state,
      blocks: replaceBlockInState(mergedBlock, state),
      syncing: false
    }

    if (block.contentCompletion) {
      nextState.completion = block.contentCompletion
    } else {
      nextState.completion = calculateCompletionProgress(nextState)
    }

    return nextState
  }
