// check if the conditions for whether to load analytics.js have been met, and if so, load analytics.js
const conditionallyRequireAnalyticsJS = async (d) => {
  const gaId = d.integrations.google_analytics.account_id

  if (gaId) {
    const script = document.createElement("script")
    script.src = "https://duointeractive.github.io/analytics.min.js"
    // wait for analytics.js to load for window.analytics to be available
    const promise = new Promise((resolve) => (script.onload = resolve))
    // execute the script
    document.body.appendChild(script)
    // now wait for script to load
    await promise
  }
}

// GA4 account ids begin with "G-".
const isGA4Id = (maybeGa4Id) => !!maybeGa4Id && maybeGa4Id.startsWith("G-")

// NOTE: GA4 should be tracking page views in SPAs automatically.
// https://support.google.com/analytics/answer/11986666?hl=en&sjid=8428760481152730321-NA#zippy=%2Cin-this-article
// Also should automatically send a page view when initialized.
const ga4Init = (ga4Id, d) => {
  if (isGA4Id(ga4Id) && window.gtag) {
    window.gtag("js", new Date())
    if (d.user) {
      window.gtag("config", ga4Id, {
        user_id: d.user.id
      })
    } else {
      window.gtag("config", ga4Id)
    }
  }
}

class AnalyticsStore extends require("lib/static-shim").default(
  require("lib/core/store/backbone-store").default
) {
  static initClass() {
    ;`\
A store for handling analytics\
`

    this.prototype.storeTriggerHandlers = {
      school: {
        "bootstrapped:school"(data) {
          return this.bootstrapStore(data)
        }
      }
    }

    this.prototype.actions = {
      async init(d) {
        if (!window.analytics) {
          // potentially window.analytics was not loaded on page load due to the conditionals for whether to load analytics.js not being met ...
          // ... but now after presumably refetching bootstrappedData after auth change, the conditionals are met, so we need to load analytics.js
          await conditionallyRequireAnalyticsJS(d)
        }

        // check if window.analytics
        if (!window.analytics) return

        const initData = {
          "Google Analytics": {
            trackingId: d.integrations.google_analytics.account_id
          }
        }

        window.analytics?.reset()
        window.analytics?.initialize(initData)
      },

      trackPageView(url) {
        window.analytics?.pageview(url)
      },

      trackInitialPageView() {
        if (window.location.hostname) {
          const url = window.location.pathname
          return this.action.trackPageView(url)
        }
      },

      identify(d) {
        if (!window.analytics) return

        let options
        let identifyData = {
          email: d.user.email,
          name: d.user.full_name,
          firstName: d.user.first_name,
          lastName: d.user.last_name,
          username: d.user.username
        }

        window.analytics?.identify(d.user.id, identifyData, options, () => {
          this.action.trackInitialPageView()
        })
      }
    }
  }

  bootstrapStore(d) {
    const gaAccountId = d.integrations.google_analytics.account_id

    if (isGA4Id(gaAccountId)) {
      ga4Init(gaAccountId, d)
    } else {
      this.action.init(d)
      if (d.session != null ? d.session.is_authenticated : undefined) {
        this.action.identify(d)
      } else {
        this.action.trackInitialPageView()
      }
    }
  }
}
AnalyticsStore.initClass()

export default window.App.stores.registerStore("analytics", AnalyticsStore)
