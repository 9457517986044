import { useCallback, useContext, useMemo, useState } from "react"
// import useQueryParam from "../../utils/useStateParam"
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault
} from "use-query-params"
import useCompletions from "../../completion/graphql/useCompletions"
import { getFiltersList } from "../context/completion-filters"
import { ReviewContextProvider } from "./ReviewContextProvider"

const defaultContext = {
  stepId: undefined,
  sourceId: undefined,
  parentId: undefined,
  userId: undefined,
  cohortId: undefined,
  filter: undefined
}

const getContextMeta = ({ items, userId }) => {
  // TODO: figure out a better way to handle top-level context meta... will likely require a query
  return {
    title: "All"
  }
}

const parseIdParam = (param) => {
  return parseInt(param)
}

// This hook is responsible for the top-level loading of completions based on a given context
// it's job is to load all completion items from GQL and provide info. to the ReviewContext to take over from there
// it provides to context actions:
// - filter filter the existing context
// - update: updates/sets a new context
const useReviewableCompletions = (initialContext = defaultContext) => {
  const [context, setContext] = useState(initialContext)

  const [query, setQuery] = useQueryParams({
    user_id: NumberParam,
    cohort_id: NumberParam,
    group_id: NumberParam,
    step_id: NumberParam,
    source_id: NumberParam,
    parent_id: NumberParam,
    filter: withDefault(StringParam, "pending"),
    order: withDefault(StringParam, "desc")
  })

  const { user_id, parent_id } = query

  const selectedFilter = query.filter
  const order = query.order

  const allFilters = getFiltersList()

  const contextQueryVars = {
    ...query,
    ...context.query,
    lesson_source_id: parent_id,
    order_by: query.order === "asc" ? "completion_date" : "-completion_date"
  }

  const {
    items,
    total,
    loading,
    error,
    hasMore,
    loadMore,
    loadingMore,
    refetch
  } = useCompletions(contextQueryVars)

  const filter = useCallback(
    (key, order) => {
      setQuery({
        filter: key
      })
      if (order) {
        setQuery({
          order: order
        })
      }
    },
    [setQuery]
  )

  const hasParentContext = () => {
    let parentContext = contextQueryVars.cohort_id || contextQueryVars.group_id
    return !!parentContext
  }

  const backToParentContext = () => {
    if (hasParentContext()) {
      // reset all query params except for the top-level context (cohort_id or group_id)
      setQuery({
        user_id: undefined,
        parent_id: undefined,
        step_id: undefined,
        source_id: undefined,
        lesson_source_id: undefined
      })
    }
  }

  const reviewContext = useMemo(() => {
    let completions = items || []
    let ctx = {
      key: "completions-review-context",
      id: "completions-review-context",
      filter,
      update: setContext,
      filters: allFilters,
      selectedFilter,
      order,
      total,
      loading,
      error,
      hasMore,
      loadMore,
      loadingMore,
      refetch,
      hasParentContext,
      backToParentContext,
      context,
      query: contextQueryVars,
      items: completions,
      meta: getContextMeta({ completions, userId: user_id }),
      depth: 0
    }
    return ctx
  }, [
    items,
    loading,
    loadingMore,
    hasMore,
    loadMore,
    refetch,
    selectedFilter,
    error
  ])

  return reviewContext
}

export const useCompletionsContext = () => useContext(ReviewContextProvider)

const CompletionsContextProvider = ({ children, reviewContext, ...props }) => {
  const completionsReviewContext = useReviewableCompletions(reviewContext)
  return (
    <ReviewContextProvider context={completionsReviewContext} {...props}>
      {children}
    </ReviewContextProvider>
  )
}

export default CompletionsContextProvider
