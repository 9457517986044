import { getFormStateStorageKey } from "../../../components/form/state/useFormStateStorage"

export const AUTH_EMAIL_STORAGE_KEY = "auth-email"

export const getAuthEmailFormStateStorageKey = () =>
  getFormStateStorageKey(AUTH_EMAIL_STORAGE_KEY)

export const discardAuthEmailFormStateStorage = () => {
  // NOTE: we're storing the `AUTH_EMAIL_STORAGE_KEY` in sessionStorage!
  sessionStorage.removeItem(getAuthEmailFormStateStorageKey())
}

type AuthEmailFormStateStorage = {
  values: {
    email: string
  }
  initialValues: {
    email: string
  }
}

const defaultAuthEmailFormStateStorage = {
  values: {
    email: ""
  },
  initialValues: {
    email: ""
  }
}

export const getAuthEmailFormState = (): AuthEmailFormStateStorage => {
  const storedEmailData = sessionStorage.getItem(
    getAuthEmailFormStateStorageKey()
  )
  try {
    return JSON.parse(storedEmailData!) || defaultAuthEmailFormStateStorage
  } catch {
    return defaultAuthEmailFormStateStorage
  }
}

export const getAuthEmailFormStateEmail = (): string => {
  return getAuthEmailFormState().values.email
}
