class LayoutView extends require("lib/static-shim").default(
  Marionette.LayoutView
) {
  serializeGlobal() {
    // TODO: this should be handled by the Pathwright app module
    return {
      school: Pathwright.request("get:school").toJSON(),
      user: Pathwright.request("get:user").toJSON()
    }
  }
}

export default LayoutView
