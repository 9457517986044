import gql from "graphql-tag"
import SUBSCRIPTION_FRAGMENT from "../../subscription/graphql/subscription-fragment"
import CURRICULUM_SUBSCRIPTION_PLAN_FRAGMENT from "./curriculum-subscription-plan-fragment"
import SCHOOL_FRAGMENT from "./school-fragment"

export default gql`
  fragment CurriculumSubscription on CurriculumSubscription {
    id
    licensor {
      ...School
    }
    licensee {
      ...School
    }
    curriculum_plan {
      ...SchoolCurriculumPlan
    }
    subscription {
      ...Subscription
    }
  }
  ${SCHOOL_FRAGMENT}
  ${CURRICULUM_SUBSCRIPTION_PLAN_FRAGMENT}
  ${SUBSCRIPTION_FRAGMENT}
`
