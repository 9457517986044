export default `
  enum QuestionType {
    MultipleChoice
    CheckBoxes
    Paragraph
    Matching
  }

  enum FeedbackShowCondition {
    ALWAYS
    CORRECT
    INCORRECT
    OPTION
  }

  type QuestionFeedback {
    html: String!
    optionValue: String
    showCondition: FeedbackShowCondition @default(value: "ALWAYS")
  }

  type QuestionOption {
    label: String!
    order: Int!
    value: String
    isCorrectAnswer: Boolean
  }

  type QuestionAnswer {
    selectedOptions: [QuestionOption]
    answerText: String
    answerHTML: String
    hasSubmitted: Boolean
    isCorrect: Boolean
    answeredDate: DateTime
  }

  type Question implements Block {
    id: ID!
    question: String!
    description: String
    type: QuestionType!
    options: [QuestionOption]
    matches: [QuestionOption]
    required: Boolean
    feedback: [QuestionFeedback]
    answer: QuestionAnswer @scope(type: USER)
  }
`
