import gql from "graphql-tag"
import SCHOOL_FRAGMENT from "../../school/graphql/school-fragment"
import MENTOR_GROUP_MEMBERSHIP_FRAGMENT from "./mentor-group-membership-fragment"

export default gql`
  query MentorGroupMemberships(
    $group_id: Int!
    $search: String
    $cursor: String
  ) {
    school {
      ...School
      mentor_group(id: $group_id) {
        id
        memberships(
          first: 50
          after: $cursor
          search: $search
          role: { eq: member }
        ) {
          total
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              ...MentorGroupMembership
            }
          }
        }
      }
    }
  }
  ${SCHOOL_FRAGMENT}
  ${MENTOR_GROUP_MEMBERSHIP_FRAGMENT}
`
