// @ts-ignore

import {
  Box,
  Flex,
  HStack,
  Text,
  UseRadioGroupReturn,
  useRadio,
  useRadioGroup
} from "@chakra-ui/react"
import { FieldInputProps } from "formik"
import { PresetThemeKey, presetThemes } from "../../theme/presets"
import { PresetTheme } from "./SpaceSignupTheme"

function ThemeOption({
  themeKey,
  ...useRadioProps
}: { themeKey: PresetThemeKey } & ReturnType<
  UseRadioGroupReturn["getRadioProps"]
>) {
  const { getInputProps, getRadioProps } = useRadio(useRadioProps)

  const input = getInputProps()
  const checkbox = getRadioProps()
  const theme = presetThemes[themeKey]

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        onClick={(e) => {
          e.stopPropagation()
          checkbox.onClick?.(e)
        }}
        cursor="pointer"
        boxShadow={input.checked ? "outline" : "none"}
        h="40px"
        w="40px"
        p={0}
        borderRadius="100%"
        pos="relative"
        overflow="hidden"
      >
        <Flex pos="absolute" inset={0}>
          <PresetTheme presetTheme={themeKey} />
          <Text
            fontFamily={theme.heading_font}
            color={`#${theme.primary_color} !important`}
            position="absolute"
            inset="0"
            display="flex"
            alignItems="center"
            justifyContent="center"
            fontSize="1.5em"
          >
            A
          </Text>
        </Flex>
      </Box>
    </Box>
  )
}

function SpaceThemeOptions(props: FieldInputProps<PresetThemeKey>) {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: props.name,
    defaultValue: props.value,
    onChange: props.onChange
  })

  const group = getRootProps()

  return (
    <HStack {...group} display="inline-flex">
      {Object.keys(presetThemes).map((themeKey) => {
        const radio = getRadioProps({ value: themeKey })
        return (
          <ThemeOption
            key={themeKey}
            themeKey={themeKey as PresetThemeKey}
            {...radio}
          />
        )
      })}
    </HStack>
  )
}

export default SpaceThemeOptions
