import { useEffect, useState } from "react"
import {
  BackgroundTaskStatus,
  useBackgroundTaskQuery,
  useBackgroundTasksQuery
} from "../api/generated"

type BackgroundTaskPollProps = {
  id: string
  onBackgroundTaskSuccess: (id: string) => void
}

export function useBackgroundTaskPoll({
  id,
  onBackgroundTaskSuccess
}: BackgroundTaskPollProps) {
  const { data, startPolling, stopPolling } = useBackgroundTaskQuery({
    variables: {
      id
    }
  })

  useEffect(() => {
    startPolling(1000)
  }, [])

  useEffect(() => {
    if (data?.backgroundTask?.status === BackgroundTaskStatus.Success) {
      stopPolling()
      onBackgroundTaskSuccess(id)
    }
  }, [data?.backgroundTask?.status])
}

type BackgroundTasksPollProps = {
  ids: string[]
  onBackgroundTaskSuccess?: (id: string) => void
  onBackgroundTasksSuccess?: (ids: string[]) => void
}

export function useBackgroundTasksPoll({
  ids,
  onBackgroundTaskSuccess,
  onBackgroundTasksSuccess
}: BackgroundTasksPollProps) {
  const [pollingIds, setPollingIds] = useState(ids)

  const { data, startPolling, stopPolling } = useBackgroundTasksQuery({
    variables: {
      ids: pollingIds
    }
  })

  useEffect(() => {
    if (!pollingIds.length) onBackgroundTasksSuccess?.(ids)
  }, [pollingIds])

  useEffect(() => {
    startPolling(1000)
  }, [])

  useEffect(() => {
    if (data?.backgroundTasks) {
      const successfulTaskIds = data?.backgroundTasks
        .filter((task) => task?.status === BackgroundTaskStatus.Success)
        .map((task) => task?.id)
      if (successfulTaskIds.length) {
        stopPolling()
        successfulTaskIds.forEach((id) => onBackgroundTaskSuccess?.(id))
        setPollingIds(
          pollingIds.filter(
            (pollingId) => !successfulTaskIds.includes(pollingId)
          )
        )
      }
    }
  }, [data?.backgroundTasks])
}

export function BackgroundTaskPoll({
  id,
  onBackgroundTaskSuccess
}: BackgroundTaskPollProps) {
  useBackgroundTaskPoll({ id, onBackgroundTaskSuccess })

  return null
}
