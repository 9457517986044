import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import gql from "graphql-tag"
import PropTypes from "prop-types"
import { featureContextPropType } from "../propTypes"

const FEATURE_CONTEXT_QUERY = gql`
  query FeatureContextQuery($cohortId: Int!) {
    cohort: group(id: $cohortId) {
      id
      name
      discussion_enabled
      resource {
        id
        discussion_enabled
      }
    }
  }
`

const withFeatureContext = (Component) => (props) => {
  const cohortId = props.cohortId || props.context.cohort_id

  const featureContextQuery = useQuery(FEATURE_CONTEXT_QUERY, {
    variables: { cohortId }
  })

  if (!featureContextQuery.data) return null

  const { cohort } = featureContextQuery.data

  const context = props.context || {
    cohort_id: cohort.id,
    resource_id: cohort.resource.id
  }

  // community can be completely disabled if discussion is disabled
  // in either the cohort or resource, this will be replaced by a
  // discussion CFP, but handling in this way for initial release
  const discussionEnabled =
    cohort.discussion_enabled && cohort.resource.discussion_enabled

  return (
    <Component
      {...props}
      context={context}
      cohortName={cohort.name}
      communityEnabled={discussionEnabled}
    />
  )
}

withFeatureContext._propTypes = {
  context: featureContextPropType,
  // for getting full feature context if not provided via props
  cohortId: PropTypes.number
}

export default withFeatureContext
