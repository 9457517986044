import { connect } from "lib/core/store"
import ProfileForm from "./ProfileForm"

const mapStateToProps = function (state, ownProps) {
  const { user } = state.profileStore.state
  const { saveUser } = state.profileStore.action
  return _.assign(
    {},
    {
      user,
      saveUser
    },
    ownProps
  )
}

export default connect(ProfileForm, "profile", mapStateToProps, ["user"])
