import {
  condensedVariables,
  flattenVariables
} from "@pathwright/ui/src/components/variable-selector/utils"
import get from "lodash/get"
import has from "lodash/has"
import { useMemo } from "react"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import { useCertificateContext } from "../context/CertificateContext"

// Standard variables supplied to text objects.
export const certificateVariables = {
  user: {
    label: "User",
    variables: {
      first_name: {
        label: "First name",
        type: "string"
      },
      last_name: {
        label: "Last name",
        type: "string"
      },
      full_name: {
        label: "Full name",
        type: "string"
      },
      email: {
        label: "Email address",
        type: "string"
      }
    }
  },
  path: {
    label: "Path",
    variables: {
      name: {
        label: "Name",
        type: "string"
      },
      completion_date: {
        label: "Completion Date",
        type: "date"
      }
    }
  }
}

export const getVariablePath = (variable) => {
  return variable.split(".").join(".variables.")
}

export const getVariableKey = (variable, key) => {
  return get(certificateVariables, `${getVariablePath(variable)}.${key}`)
}

export const useBaseVariableData = () => {
  const { me, school } = usePathwrightContext()
  const { resource } = useCertificateContext()
  const pathName = resource?.name || `Placeholder Path Name`

  const getDate = () => {
    const formatter = Intl.DateTimeFormat(school.ietf_language_tag, {
      dateStyle: "medium"
    })

    const d = new Date()
    d.setMonth(d.getMonth() + 1)
    d.setDate(15)
    d.setHours(0)
    d.setMinutes(0)
    d.setSeconds(0)
    d.setMilliseconds(0)
    return formatter.format(new Date(d))
  }

  const data = useMemo(
    () => ({
      user: {
        first_name: me.first_name,
        last_name: me.last_name,
        full_name: me.full_name,
        email: me.email
      },
      path: {
        name: pathName,
        // The 15th day of the next month.
        completion_date: getDate()
      }
    }),
    []
  )

  return data
}

// Weave in the base variable data with the base variables.
export const useWeavedBaseVariables = () => {
  const baseVariablesData = useBaseVariableData()
  const flattenedCertificateVariables = flattenVariables(certificateVariables)
  return flattenedCertificateVariables.reduce(
    (acc, key) => ({
      ...acc,
      [key]: get(baseVariablesData, key)
    }),
    {}
  )
}

const condensedCertificateVariables = condensedVariables(certificateVariables)

// Extract the combined variables used across all textObjects.
export const getVariables = (textObjects, filter) => {
  if (!textObjects) return []
  // RegExp for matching all variables in a textObject text string in the format
  // "{{ variable.path.to.context.value }}".
  const re = /@(?<variable>([a-zA-Z0-9_-]|\.(?=[a-zA-Z_0-9-]+))+)/g

  // Reduce all variables from each textObject
  let variables = textObjects.reduce((variables, textObject) => {
    // Match all variables in textObject
    const matches = [...textObject.text.matchAll(re)]
    // Get the variable group from each mactch.
    const additionalVariables = matches.map((match) => match.groups.variable)
    return [...variables, ...additionalVariables]
  }, [])

  // Filter out dupes.
  variables = [...new Set(variables)]

  if (filter) return variables.filter((variable) => filter(variable))

  return variables
}

export const getVariable = (variable) =>
  get(condensedCertificateVariables, variable)

// Expects a variable in the form of path.to.value.
export const isCustomVariable = (variable) =>
  !has(condensedCertificateVariables, variable)
