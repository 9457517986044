import { Component } from "react"
import Button from "../button/Button"

class UnknownErrorMessage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isRepeateError: this.isRepeatError
    }
  }

  UNSAFE_componentWillMount() {
    if (this.isRepeatError) {
      this.clearErrorKey()
    } else {
      this.setErrorKey()
    }
  }

  get errorKey() {
    return `error:${window.location.pathname}`
  }

  get isRepeatError() {
    return JSON.parse(window.localStorage.getItem(this.errorKey))
  }

  setErrorKey() {
    return window.localStorage.setItem(this.errorKey, true)
  }

  clearErrorKey() {
    return window.localStorage.removeItem(this.errorKey)
  }

  get mailto() {
    return `mailto:hello@pathwright.com?subject=${encodeURI(
      "Unknown Error"
    )}&body=${encodeURI(`URL: ${window.location.href}`)}`
  }

  render() {
    return this.state.isRepeateError ? (
      <span>
        Unknown error. Please let us know what happened by{" "}
        <Button
          styleType="text"
          style={{ whiteSpace: "nowrap" }}
          target="_blank"
          href={this.mailto}
        >
          emailing support
        </Button>{" "}
        and providing the full URL.
      </span>
    ) : (
      <span>
        Unknown error. Please try{" "}
        <Button
          styleType="text"
          style={{ whiteSpace: "nowrap" }}
          href={window.location.href}
        >
          reloading the page
        </Button>
        .
      </span>
    )
  }
}

export default UnknownErrorMessage
