import { Divider, HStack, Text } from "@chakra-ui/react"
import { useScopedTranslation } from "../../../lib/lng/TranslationsProvider"

const AuthOptionsDivider = (): JSX.Element => {
  const { t } = useScopedTranslation("auth.auth_options_divider")

  return (
    <HStack
      w="100%"
      color="contentTextSecondary"
      borderColor="contentTextSecondary"
    >
      <Divider />
      <Text>{t("or")}</Text>
      <Divider />
    </HStack>
  )
}

export default AuthOptionsDivider
