import classnames from "classnames"
import PropTypes from "prop-types"
import React, { useContext } from "react"
import { SUBMIT_STATUS, useSubmitHandler } from "../../submit/SubmitHandler"
import { useDiscardFormStateContext } from "./DiscardFormStateContext"

const FormStateFormContext = React.createContext()

export const useFormStateFormContext = () => useContext(FormStateFormContext)

// Simple form that handles discarding stored form state after
// successful submission.
export const FormStateFormProvider = ({ children, onSubmit }) => {
  const discardFormStateContext = useDiscardFormStateContext()

  const handleSubmit = async (...args) => {
    await onSubmit(...args)
    if (discardFormStateContext) {
      // Discard stored form state but preserve current form state
      // after successful submission.
      discardFormStateContext.discardFormState(true /* preserve */)
    }
  }

  const submitHandler = useSubmitHandler(handleSubmit)

  // Current submit state based on submit status that a SubmitHandler
  // can recognize.
  const submitState = {
    status: submitHandler.status,
    submitting: submitHandler.status === SUBMIT_STATUS.LOADING,
    submitFailure: submitHandler.status === SUBMIT_STATUS.FAILURE,
    submitSuccess: submitHandler.status === SUBMIT_STATUS.SUCCESS
  }

  return (
    <FormStateFormContext.Provider
      value={{
        submitState
      }}
    >
      {children({ onSubmit: submitHandler.handleSubmit })}
    </FormStateFormContext.Provider>
  )
}

// Simple form that handles discarding stored form state after
// successful submission.
const FormStateForm = ({ className, children, onSubmit }) => {
  return (
    <FormStateFormProvider onSubmit={onSubmit}>
      {({ onSubmit }) => (
        <form
          className={classnames("FormStateForm", className)}
          onSubmit={(e) => {
            e.preventDefault()
            onSubmit()
          }}
        >
          {children}
        </form>
      )}
    </FormStateFormProvider>
  )
}

FormStateForm.displayName = "FormStateForm"

FormStateForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
}

export default FormStateForm
