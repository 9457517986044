import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import Button from "../button/Button"
import Pathicon from "../pathicon/Pathicon"
import Tooltip from "../tooltip/Tooltip"

// TODO: modularize all this post-launch

function useMouseData() {
  const [value, setValue] = useState({ x: 0, y: 0, over: false })
  const ref = useRef(null)

  const handleMouseOver = () => setValue((v) => ({ ...v, over: true }))

  const handleMouseOut = () => setValue((v) => ({ ...v, over: false }))

  const handleMouseMove = (e) =>
    setValue((v) => ({ ...v, x: e.offsetX, y: e.offsetY }))

  useEffect(
    () => {
      const node = ref.current
      if (node) {
        node.addEventListener("mouseover", handleMouseOver)
        node.addEventListener("mouseout", handleMouseOut)
        node.addEventListener("mousemove", handleMouseMove)
        return () => {
          node.removeEventListener("mouseover", handleMouseOver)
          node.removeEventListener("mouseout", handleMouseOut)
          node.removeEventListener("mousemove", handleMouseMove)
        }
      }
    },
    [ref.current] // Recall only if ref changes
  )
  return [ref, value]
}

export const useMousePosition = (ref) => {
  const [position, setPosition] = useState({ x: 0, y: 0, over: false })

  useEffect(() => {
    const setFromEvent = (e) =>
      setPosition((p) => ({ ...p, x: e.offsetX, y: e.offsetY }))
    ref.current.addEventListener("mousemove", setFromEvent)
    ref.current.addEventListener(
      "mouseover",
      setPosition((p) => ({ ...p, over: true }))
    )
    ref.current.addEventListener(
      "mouseout",
      setPosition((p) => ({ ...p, over: false }))
    )

    return () => {
      ref.current.removeEventListener("mousemove", setFromEvent)
      ref.current.removeEventListener("mouseover")
      ref.current.removeEventListener("mouseout")
    }
  }, [])

  return position
}

const DropGateContainer = styled.div`
  position: relative;
  background-color: #fff;
  border-radius: 20px;
  padding: 40px 20px;
  max-width: 500px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: #000;
  color: #fff; */
  text-align: center;
  box-shadow: 0px 3px 9px 4px rgba(0, 0, 0, 0.08);
  h1 {
    font-size: 18px;
  }

  p {
    margin-top: 0px;
    font-size: 18px;
    line-height: 1.5em;
  }
`

const PADDING_PERCENT = 0.28

const DropGateStickerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(p) => PADDING_PERCENT * p.size}px;
  border-radius: ${(p) => 0.38 * p.size}px;
  background: linear-gradient(43.31deg, #f5f5f5 -3.14%, #dadada 100%);
  /* background: linear-gradient(
    43.31deg,
    rgba(255, 255, 255, 0.777),
    -3.14%,
    rgba(255, 255, 255, 0.999) 100%
  ); */

  svg {
    pointer-events: none;
    width: ${(p) => p.size}px;
    height: ${(p) => p.size}px;
  }
`

const VisibilityBadgeContainer = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;

  .OverlayTrigger {
    color: #aaa;
    display: flex !important;
    align-items: center;
    i {
      margin-right: 5px;
    }
  }
`

const VisibilityBadge = () => {
  return (
    <VisibilityBadgeContainer>
      <Tooltip
        title={
          <React.Fragment>
            Only administrators are able to see where new features will show up
          </React.Fragment>
        }
      >
        <Pathicon icon="lock" />
        <label>Staff only</label>
      </Tooltip>
    </VisibilityBadgeContainer>
  )
}

const S1SVG = ({ stops }) => {
  return (
    <svg
      width="51"
      height="52"
      viewBox="0 0 51 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <clipPath id="mask">
          <path
            d="M37.825 0.361115H12.625C5.67399 0.361115 0.0249939 6.04 0.0249939 13.0278V38.3611C0.0249939 45.3489 5.67399 51.0278 12.625 51.0278H37.825C44.776 51.0278 50.425 45.3489 50.425 38.3611V13.0278C50.425 6.04 44.776 0.361115 37.825 0.361115ZM46.225 38.3611C46.225 43.0267 42.466 46.8056 37.825 46.8056H12.625C7.98399 46.8056 4.22499 43.0267 4.22499 38.3611V13.0278C4.22499 8.36223 7.98399 4.58334 12.625 4.58334H37.825C42.466 4.58334 46.225 8.36223 46.225 13.0278V38.3611Z"
            fill="white"
          />
          <path
            d="M20.689 24.5967L17.917 24.09C16.279 23.7945 15.775 23.33 15.775 22.4433C15.775 21.43 16.657 20.5433 18.421 20.5433C20.395 20.5433 21.571 21.7045 21.571 23.0767H25.603C25.603 19.53 23.083 16.9967 18.421 16.9967C14.137 16.9967 11.743 19.4033 11.743 22.4433C11.743 25.23 13.255 26.7922 16.405 27.3833L19.177 27.89C21.067 28.2489 21.697 28.9033 21.697 29.79C21.697 30.8033 20.815 31.69 18.673 31.69C16.531 31.69 15.397 30.55 15.397 29.1567H11.365C11.365 32.5767 14.011 35.2367 18.673 35.2367C23.335 35.2367 25.729 32.83 25.729 29.79C25.729 26.75 24.217 25.23 20.689 24.5967Z"
            fill="white"
          />
          <path
            d="M34.045 18.2633C34.045 18.8967 33.793 19.15 33.163 19.15H30.517V22.95H34.045V34.9833H38.077V17.25H34.045V18.2633Z"
            fill="white"
          />
        </clipPath>
        <linearGradient id="gradient" gradientTransform="rotate(45)">
          {stops.map((stop, i) => {
            return (
              <stop
                key={i}
                offset={`${stop.offset}%`}
                stop-color={stop.color}
              />
            )
          })}
        </linearGradient>
      </defs>
      <rect
        x={0}
        y={0}
        width={51}
        height={51}
        style={{ fill: `url('#gradient')` }}
        clipPath={`url('#mask')`}
      />
    </svg>
  )
}

const calcStopsOffset = (stops, size, x) => {
  // ox should be how close the mouse is to the center from the edge (1/2 width)
  const cp = (size + PADDING_PERCENT * size) / 2
  const px = 1 - Math.abs(cp - x) / cp

  const offset = px * 33

  return stops.map((s) => ({ ...s, offset: s.offset + offset }))
}

export const DropGateSticker = ({ size }) => {
  const [ref, { x, over }] = useMouseData()

  const defaultStops = [
    { offset: 11, color: "#00a2c3" },
    { offset: 42, color: "#f58870" },
    { offset: 77, color: "#8b5f9e" }
  ]

  const stops = over ? calcStopsOffset(defaultStops, size, x) : defaultStops

  return (
    <DropGateStickerContainer ref={ref} size={size} className="DropGateSticker">
      <S1SVG stops={stops} size={size} />
    </DropGateStickerContainer>
  )
}

DropGateSticker.propTypes = {
  // size in pixels, unable to support other sizing ATM (i.e. em sizing)
  size: PropTypes.number
}

const DropGate = ({ title, description, link, linkLabel }) => {
  return (
    <DropGateContainer>
      <VisibilityBadge />
      <DropGateSticker label={"S1"} size={52} />

      <h1>{title}</h1>
      <p>{description}</p>

      {link ? (
        <Button styleType={"primary-alt"} href={link}>
          {linkLabel}
        </Button>
      ) : null}
    </DropGateContainer>
  )
}

export default DropGate
