import PropTypes from "prop-types"
import "./ColorSwatches.css"

const ColorSwatches = ({ colors, onClick }) => (
  <div className="ColorSwatches">
    {colors.map((color) => (
      <div
        key={color}
        className="ColorSwatches__color"
        onClick={() => onClick(color)}
        style={{ background: color }}
      />
    ))}
  </div>
)

ColorSwatches.displayName = "ColorSwatches"

ColorSwatches.propTypes = { colors: PropTypes.array.isRequired }

ColorSwatches.defaultProps = {
  colors: [
    "#16a085",
    "#27ae60",
    "#2980b9",
    "#8e44ad",
    "#f39c12",
    "#d35400",
    "#c0392b",
    "#7f8c8d",
    "#2c3e50",
    "#000000"
  ]
}

export default ColorSwatches
