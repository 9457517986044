import classnames from "classnames"
import PropTypes from "prop-types"
import OverlayTrigger from "../overlay/OverlayTrigger"
import "./Tooltip.css"

// OverlayTrigger does most of the work.
// Use for minimal tooltip setup.

const Tooltip = (props) => {
  const {
    title,
    children,
    placement,
    alignment,
    transition,
    delayHide,
    delayShow,
    className,
    closeOnTriggerClick,
    onHide,
    onShow,
    style
  } = props

  return (
    <OverlayTrigger
      className={classnames("Tooltip__trigger", className)}
      closeOnTriggerClick={closeOnTriggerClick}
      enterTransition={transition}
      placement={placement}
      alignment={alignment}
      delayShow={delayShow}
      delayHide={delayHide}
      onHide={onHide}
      onShow={onShow}
      trigger="hover"
      style={style}
      active={!!title}
      overlay={
        <div className={classnames("Tooltip", `Tooltip--${placement}`)}>
          {title}
        </div>
      }
    >
      {children}
    </OverlayTrigger>
  )
}

Tooltip.displayName = "Tooltip"

Tooltip.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
  // ...OverlayTrigger.propTypes
}

Tooltip.defaultProps = {
  placement: "bottom",
  alignment: "center",
  transition: "transition.slideUpIn",
  closeOnTriggerClick: false,
  onHide() {},
  onShow() {},
  delayShow: 200 // slight delay for tooltip
}

export default Tooltip
