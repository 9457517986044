import moment from "moment"
import { getIsSubscriptionUsable } from "../../subscription/utils"
import { getSchoolUrl } from "../../utils/urls"

const registrationCohortRequiresPayment = (reg) => {
  return !!reg.group.price || reg.group.is_subscription_only
}

export const getRegistrationLink = (registration) => {
  return {
    pathname: `/library/${registration.resource.slug}/${registration.group.id}/path/`,
    state: { setResourceLaunchedFrom: true },
    query: { path_id: registration.path.id }
  }
}

export const isRegistrationExpired = (reg) => {
  return Boolean(
    (reg.expiration_dtime && moment().isAfter(reg.expiration_dtime)) ||
      (reg.group.access_ends && moment().isAfter(reg.group.access_ends)) ||
      // Consider the registration "expired" if required payment is lacking.
      (registrationCohortRequiresPayment(reg) &&
        (isRegistrationGroupMembershipOver(reg) ||
          (reg.school_sub_used &&
            !getIsSubscriptionUsable(reg.school_sub_used?.subscription))))
  )
}

export const isRegistrationGroupMembershipOver = (reg) => {
  return Boolean(
    // Group Subscriptino was used and...
    reg?.group_sub_used &&
      // either the user's membership has been archived, hence not present
      // or the user's membership has been revoked.
      (!reg.group_sub_community_membership ||
        reg.group_sub_community_membership.access_revoked_time)
  )
}

export const isRegistrationComplete = (reg) => {
  return (
    isRegistrationExpired(reg) ||
    // Prefer path.progress over registration.completion_state as completion_state is less accurate
    // (currently registration.completion_state does not stay in sync with path.progress)
    (reg.path && reg.path.progress >= 1) ||
    reg.group.status === "completed"
  )
}

export const canAccessRegistration = (reg) => {
  return !isRegistrationExpired(reg)
}

// TODO: this logic should be GraphQL-side ideally
export const getCertificateDownloadUrl = (pwContext, registration) => {
  // ensure resource has certificate and path is complete
  if (
    registration.path.progress < 1 ||
    !registration.path.completion_date ||
    !pwContext.school.has_certificate ||
    !registration.resource.has_certificate
  )
    return null
  // other users shouldn't have access to certificate
  if (!pwContext.me || pwContext.me.id !== registration.user.id) return null

  return getSchoolUrl(
    `/certgen/certificate/${registration.resource.slug}/${
      registration.path.id
    }?sso=${localStorage.getItem("auth_token")}`
  )
}
