import { MEMBERSHIP_ROLE_SCHEMA } from "../membership/constants"
import {
  MENTOR_GROUP_MEMBERSHIP_ROLE_ADMIN,
  MENTOR_GROUP_MEMBERSHIP_ROLE_INPUTS,
  MENTOR_GROUP_MEMBERSHIP_ROLE_MEMBER,
  MENTOR_GROUP_MEMBERSHIP_ROLE_MODERATOR
} from "../mentor-group/constants"

export const ROLE_TYPE_OFFERING = 1
export const ROLE_TYPE_SCHOOL = 2
export const ROLE_TYPE_MENTOR_GROUP = 3

export const MEMBERSHIP_TYPE_STUDENT = 0
export const MEMBERSHIP_TYPE_FACILITATOR = 10
export const MEMBERSHIP_TYPE_EDITOR = 20
export const MEMBERSHIP_TYPE_ADMIN = 30

export const ENROLLMENT_ROLE_OBSERVER = -1
export const ENROLLMENT_ROLE_STUDENT = 5
export const ENROLLMENT_ROLE_FACILITATOR = 10
export const ENROLLMENT_ROLE_TEACHER = 15
export const ENROLLMENT_ROLE_EDITOR = 20

export const ROLES = {
  [ROLE_TYPE_SCHOOL]: [
    MEMBERSHIP_TYPE_STUDENT,
    // MEMBERSHIP_TYPE_FACILITATOR,
    MEMBERSHIP_TYPE_EDITOR,
    MEMBERSHIP_TYPE_ADMIN
  ],
  [ROLE_TYPE_OFFERING]: [
    ENROLLMENT_ROLE_OBSERVER,
    ENROLLMENT_ROLE_STUDENT,
    ENROLLMENT_ROLE_FACILITATOR,
    ENROLLMENT_ROLE_TEACHER,
    ENROLLMENT_ROLE_EDITOR
  ],
  [ROLE_TYPE_MENTOR_GROUP]: [
    MENTOR_GROUP_MEMBERSHIP_ROLE_MEMBER,
    MENTOR_GROUP_MEMBERSHIP_ROLE_MODERATOR,
    MENTOR_GROUP_MEMBERSHIP_ROLE_ADMIN
  ]
}

export const ROLE_VALUES = {
  [ROLE_TYPE_SCHOOL]: {
    [MEMBERSHIP_TYPE_STUDENT]: "student",
    // [MEMBERSHIP_TYPE_FACILITATOR]: "facilitator",
    [MEMBERSHIP_TYPE_EDITOR]: "editor",
    [MEMBERSHIP_TYPE_ADMIN]: "administrator"
  },
  [ROLE_TYPE_OFFERING]: {
    [ENROLLMENT_ROLE_OBSERVER]: "observer",
    [ENROLLMENT_ROLE_STUDENT]: "student",
    [ENROLLMENT_ROLE_FACILITATOR]: "moderator",
    [ENROLLMENT_ROLE_TEACHER]: "teacher",
    [ENROLLMENT_ROLE_EDITOR]: "editor"
  },
  [ROLE_TYPE_MENTOR_GROUP]: MENTOR_GROUP_MEMBERSHIP_ROLE_INPUTS
}

export const ROLE_ICONS = {
  [ROLE_TYPE_SCHOOL]: {
    [MEMBERSHIP_TYPE_STUDENT]: "badge-smile",
    // [MEMBERSHIP_TYPE_FACILITATOR]: "role-facilitator",
    [MEMBERSHIP_TYPE_EDITOR]: "badge-edit",
    [MEMBERSHIP_TYPE_ADMIN]: "badge-key"
  },
  [ROLE_TYPE_OFFERING]: {
    [ENROLLMENT_ROLE_OBSERVER]: "badge-eye",
    [ENROLLMENT_ROLE_STUDENT]: "badge-check",
    [ENROLLMENT_ROLE_FACILITATOR]: "badge-notification",
    [ENROLLMENT_ROLE_TEACHER]: "badge-star",
    [ENROLLMENT_ROLE_EDITOR]: "badge-edit"
  }
}

export const ROLE_LABELS = {
  [ROLE_TYPE_SCHOOL]: {
    [MEMBERSHIP_TYPE_STUDENT]: "member",
    // [MEMBERSHIP_TYPE_FACILITATOR]: "facilitator",
    [MEMBERSHIP_TYPE_EDITOR]: "library editor",
    [MEMBERSHIP_TYPE_ADMIN]: "administrator"
  },
  [ROLE_TYPE_OFFERING]: {
    [ENROLLMENT_ROLE_OBSERVER]: "observer",
    [ENROLLMENT_ROLE_STUDENT]: "learner",
    [ENROLLMENT_ROLE_FACILITATOR]: "moderator",
    [ENROLLMENT_ROLE_TEACHER]: "teacher",
    [ENROLLMENT_ROLE_EDITOR]: "course editor"
  },
  [ROLE_TYPE_MENTOR_GROUP]: {
    [MENTOR_GROUP_MEMBERSHIP_ROLE_MEMBER]: `mentor_group.member.${MENTOR_GROUP_MEMBERSHIP_ROLE_MEMBER}.label`,
    [MENTOR_GROUP_MEMBERSHIP_ROLE_MODERATOR]: `mentor_group.member.${MENTOR_GROUP_MEMBERSHIP_ROLE_MODERATOR}.label`,
    [MENTOR_GROUP_MEMBERSHIP_ROLE_ADMIN]: `mentor_group.member.${MENTOR_GROUP_MEMBERSHIP_ROLE_ADMIN}.label`
  }
}

export const ROLE_LABELS_PLURAL = {
  [ROLE_TYPE_SCHOOL]: {
    [MEMBERSHIP_TYPE_STUDENT]: "members",
    // [MEMBERSHIP_TYPE_FACILITATOR]: "facilitator",
    [MEMBERSHIP_TYPE_EDITOR]: "library editors",
    [MEMBERSHIP_TYPE_ADMIN]: "administrators"
  },
  [ROLE_TYPE_OFFERING]: {
    [ENROLLMENT_ROLE_OBSERVER]: "observers",
    [ENROLLMENT_ROLE_STUDENT]: "learners",
    [ENROLLMENT_ROLE_FACILITATOR]: "moderators",
    [ENROLLMENT_ROLE_TEACHER]: "teachers",
    [ENROLLMENT_ROLE_EDITOR]: "course editors"
  },
  [ROLE_TYPE_MENTOR_GROUP]: {
    [MENTOR_GROUP_MEMBERSHIP_ROLE_MEMBER]: `mentor_group.member.${MENTOR_GROUP_MEMBERSHIP_ROLE_MEMBER}.label_plural`,
    [MENTOR_GROUP_MEMBERSHIP_ROLE_MODERATOR]: `mentor_group.member.${MENTOR_GROUP_MEMBERSHIP_ROLE_MODERATOR}.label_plural`,
    [MENTOR_GROUP_MEMBERSHIP_ROLE_ADMIN]: `mentor_group.member.${MENTOR_GROUP_MEMBERSHIP_ROLE_ADMIN}.label_plural`
  }
}

// TODO: move other school membership constants to membership/constants
export const SCHOOL_ROLE_SCHEMA = MEMBERSHIP_ROLE_SCHEMA

export const ROLE_DESCRIPTORS = {
  [ROLE_TYPE_SCHOOL]: {
    [MEMBERSHIP_TYPE_STUDENT]:
      "The default role that can join public or members-only courses from your Library as well as access private courses they're added to by a staff member.",
    [MEMBERSHIP_TYPE_EDITOR]:
      "A staff role that enables creating and editing courses and setting up cohorts within the courses.",
    [MEMBERSHIP_TYPE_ADMIN]:
      "A staff role that enables creating and editing courses, managing everyone's access, and adjusting all account settings like branding, support, and billing."
  },
  [ROLE_TYPE_OFFERING]: {
    [ENROLLMENT_ROLE_OBSERVER]:
      "Give <strong>view-only</strong> access to all course content, discussions, and notes.",
    [ENROLLMENT_ROLE_STUDENT]:
      "Give access to all course content, discussions, and notes.",
    [ENROLLMENT_ROLE_FACILITATOR]:
      "Give access to Learn mode + access the teaching tools under Teach mode.",
    [ENROLLMENT_ROLE_TEACHER]:
      "Give access to Edit mode to personalize the course path for their cohort + access the teaching tools under Teach mode.",
    [ENROLLMENT_ROLE_EDITOR]:
      "Editors are staff members who have full access to the curriculum, cohorts, and settings for just this course."
  },
  [ROLE_TYPE_MENTOR_GROUP]: {
    [MENTOR_GROUP_MEMBERSHIP_ROLE_MEMBER]: `mentor_group.member.${MENTOR_GROUP_MEMBERSHIP_ROLE_MEMBER}.description`,
    [MENTOR_GROUP_MEMBERSHIP_ROLE_MODERATOR]: `mentor_group.member.${MENTOR_GROUP_MEMBERSHIP_ROLE_MODERATOR}.description`,
    [MENTOR_GROUP_MEMBERSHIP_ROLE_ADMIN]: `mentor_group.member.${MENTOR_GROUP_MEMBERSHIP_ROLE_ADMIN}.description`
  }
}

export const ROLE_ACTIONS = {
  [ROLE_TYPE_OFFERING]: {
    [ENROLLMENT_ROLE_OBSERVER]: "observe",
    [ENROLLMENT_ROLE_STUDENT]: "join",
    [ENROLLMENT_ROLE_FACILITATOR]: "moderate",
    [ENROLLMENT_ROLE_TEACHER]: "teach",
    [ENROLLMENT_ROLE_EDITOR]: "edit"
  },
  [ROLE_TYPE_SCHOOL]: {}
}

export const ROLE_TYPE_HELP_ARTICLES = {
  [ROLE_TYPE_SCHOOL]: {
    url: "https://help.pathwright.com/account-and-billing/managing-your-people-and-access/manage-the-pathwright-member-roles"
  },
  [ROLE_TYPE_OFFERING]: {
    url: "https://help.pathwright.com/account-and-billing/managing-your-people-and-access/manage-the-pathwright-member-roles"
  },
  [ROLE_TYPE_MENTOR_GROUP]: {
    url: "https://help.pathwright.com/en/articles/2083448-pathwright-member-roles#h_cc78cdd693",
    label: "mentor_group.member.learn_more_about_roles"
  }
}

export const INVITATION_TYPE_PERMISSION_MAP = {
  [ROLE_TYPE_SCHOOL]: {}, // no permissions currently
  [ROLE_TYPE_OFFERING]: {
    [ENROLLMENT_ROLE_OBSERVER]: "can_invite_observer",
    [ENROLLMENT_ROLE_STUDENT]: "can_invite_student",
    [ENROLLMENT_ROLE_FACILITATOR]: "can_invite_moderator",
    [ENROLLMENT_ROLE_TEACHER]: "can_invite_teacher",
    [ENROLLMENT_ROLE_EDITOR]: "can_invite_editor"
  }
}
