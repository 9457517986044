// USAGE: via ImageUpload

import "./_Cover.css"

import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"

const CoverUploader = ({ uploading, error, mediaType, pickFile }) => (
  <div className="CoverUploader">
    {uploading ? (
      <LoadingCircle />
    ) : error ? (
      { error }
    ) : (
      <label onClick={pickFile}>
        <Pathicon icon="arrow-up-circle" />
        {`\
Upload\
`}
      </label>
    )}
  </div>
)

CoverUploader.displayName = "CoverUploader"

export default CoverUploader
