import { useCallback } from "react"
import useSelectTags from "./useSelectTags"
import useUnselectTags from "./useUnselectTags"

const useUpdateSelectedTags = ({ context, tags }) => {
  const selectTags = useSelectTags({ context, tags })
  const unselectTags = useUnselectTags({ context, tags })

  // NOTE: unfortunately not sure if intent can be determined
  // soley based on the data currently available, so will need
  // to expose selectTags and unselectTags explicitly for now.
  const updateSelectedTags = useCallback(
    (selectedTags, modifiedTagLinksContext) =>
      Promise.all([
        selectTags(selectedTags, modifiedTagLinksContext),
        unselectTags(selectedTags, modifiedTagLinksContext)
      ]),
    [selectTags, unselectTags]
  )

  return updateSelectedTags
}

export default useUpdateSelectedTags
