import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import gql from "graphql-tag"
import get from "lodash/get"
import { useMemo } from "react"
import {
  hasEditorLevelAccess,
  hasLearnerLevelAccess,
  hasModeratorLevelAccess,
  hasObserverLevelAccess,
  hasTeacherLevelAccess
} from "../group/permissions"
import { usePathwrightContext } from "../pathwright/PathwrightContext"
import { canAdministrate } from "../user/permissions"

export const COHORT_PERMISSIONS_QUERY = gql`
  query CohortPermissionsQuery($cohortId: Int!) {
    cohort: group(id: $cohortId) {
      id
      is_source_cohort
      user_registration {
        id
        role
      }
      source {
        id
        is_source_cohort
        user_registration {
          id
          role
        }
      }
    }
  }
`

const useCohortPermissions = ({ cohortId }) => {
  const pwContext = usePathwrightContext()
  const cohortPermissionsQuery = useQuery(COHORT_PERMISSIONS_QUERY, {
    variables: {
      cohortId: cohortId
    },
    skip: !cohortId || canAdministrate(pwContext),
    notifyOnNetworkStatusChange: true
  })

  const cohort = get(cohortPermissionsQuery, "data.cohort")
  const sourceCohort = get(cohortPermissionsQuery, "data.cohort.source") || cohort /* prettier-ignore */

  return useMemo(() => {
    const hasAdminLevelAccess = canAdministrate(pwContext)
    // NOTE: a value of null indicates to the consumer that the permission
    // query is still loading
    const defaultPerm = cohortPermissionsQuery.loading ? null : false

    return {
      hasEditorLevelAccess:
        hasAdminLevelAccess ||
        hasEditorLevelAccess(pwContext, cohort || sourceCohort) ||
        defaultPerm,
      hasTeacherLevelAccess:
        hasAdminLevelAccess ||
        hasTeacherLevelAccess(pwContext, cohort || sourceCohort) ||
        defaultPerm,
      hasModeratorLevelAccess:
        hasAdminLevelAccess ||
        hasModeratorLevelAccess(pwContext, cohort || sourceCohort) ||
        defaultPerm,
      hasLearnerLevelAccess:
        hasAdminLevelAccess ||
        hasLearnerLevelAccess(pwContext, cohort || sourceCohort) ||
        defaultPerm,
      hasObserverLevelAccess:
        hasAdminLevelAccess ||
        hasObserverLevelAccess(pwContext, cohort || sourceCohort) ||
        defaultPerm
    }
  }, [cohortPermissionsQuery.loading])
}

export default useCohortPermissions
