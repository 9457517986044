import { useMutation } from "@apollo/client"
import Alert from "@pathwright/ui/src/components/alert/Alert"
import Button from "@pathwright/ui/src/components/button/Button"
import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import Card from "@pathwright/ui/src/components/card/Card"
import CardHeader from "@pathwright/ui/src/components/card/CardHeader"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import AnimatedIcon from "@pathwright/ui/src/components/pathicon/AnimatedIcon"
import Text from "@pathwright/ui/src/components/ui/Text"
import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import get from "lodash/get"
import pluralize from "pluralize"
import styled from "styled-components"
import SCHOOL_GIFT_SUBSCRIPTION_QUERY from "../subscription/graphql/school-gift-subscription-query"
import SEND_GIFT_SUBSCRIPTION_EMAIL_MUTATION from "../subscription/graphql/send-gift-subscription-email-mutation"
import useOrderPollingQuery from "./useOrderPollingQuery"
import { getIntervalDisplayName } from "./utils/subscription"

const NarrowCard = styled(Card)`
  & > .View {
    max-width: 500px;
  }
`

const CenteredContent = styled.div`
  padding: 20px 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`

const MeasuredContent = styled.div`
  padding: 20px 30px;
`

const TicketContent = styled.div`
  border: 1px solid black;
  padding: 25px;
  border-radius: 8px;
  background: whitesmoke;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 80px;
    height: 80px;
    margin-right: 10px;
  }
  h1 {
    font-size: 100px;
  }
  h3 {
    text-transform: capitalize;
  }
  h5 {
    margin-top: 5px;
    display: inline-block;
    color: white;
    background-color: black;
    border-radius: 5px;
    padding: 2px 5px;
  }
`

function GiftSubscriptionConfirmationCardWrapper({ card, id, code, children }) {
  const giftSubscriptionQuery = useQuery(SCHOOL_GIFT_SUBSCRIPTION_QUERY, {
    skip: !id && !code,
    variables: {
      id,
      code
    }
  })
  const giftSubscription = get(
    giftSubscriptionQuery,
    "data.school.gift_subscription"
  )
  const subscriptionPlan = get(
    giftSubscription,
    "school_subscription_plan.subscription_plan"
  )

  return (
    <NarrowCard card={card} navless>
      <CardHeader
        card={card}
        title="Gift A Subscription"
        meta={
          subscriptionPlan
            ? `${subscriptionPlan.name} • ${pluralize(
                getIntervalDisplayName(subscriptionPlan.interval),
                giftSubscription.gifted_subscription_cycles,
                true
              )}`
            : null
        }
      />
      {children}
    </NarrowCard>
  )
}

const GiftSubscriptionConfirmation = ({ id, code, printContent }) => {
  const giftSubscriptionQuery = useQuery(SCHOOL_GIFT_SUBSCRIPTION_QUERY, {
    skip: !id && !code,
    variables: {
      id,
      code
    }
  })
  const giftSubscription = get(
    giftSubscriptionQuery,
    "data.school.gift_subscription"
  )
  const subscriptionPlan = get(
    giftSubscription,
    "school_subscription_plan.subscription_plan"
  )

  const [sendGiftSubscriptionEmail] = useMutation(
    SEND_GIFT_SUBSCRIPTION_EMAIL_MUTATION
  )

  return giftSubscriptionQuery.error ? (
    <CenteredContent>
      <Alert error={giftSubscriptionQuery.error} />
    </CenteredContent>
  ) : giftSubscription && subscriptionPlan ? (
    <>
      <CenteredContent>
        <AnimatedIcon icon="check" iconSize={30} circleSize={50} />
        <br />
        <Text.H4>Send Your Gift Subscription!</Text.H4>
        <Text.Body>
          Congratulations, your gift subscription is ready to give to{" "}
          {giftSubscription.recipient_first_name}. To give{" "}
          {giftSubscription.recipient_first_name} access to the gift
          subscription, print or email the certificate below.
        </Text.Body>
      </CenteredContent>
      <MeasuredContent className="gift-subscription-printable">
        <TicketContent>
          <Text.H1>{giftSubscription.gifted_subscription_cycles}</Text.H1>
          <div>
            <Text.H3>
              {pluralize(
                getIntervalDisplayName(subscriptionPlan.interval),
                giftSubscription.gifted_subscription_cycles
              )}
              <br />
              subscription
            </Text.H3>
            <Text.H5>Gift Code: {giftSubscription.gift_code}</Text.H5>
          </div>
        </TicketContent>
        <br />
        <Text.H4>How To Redeem</Text.H4>
        <Text.Body>
          {giftSubscription.recipient_first_name}, this certificate gives you
          free access for{" "}
          {pluralize(
            getIntervalDisplayName(subscriptionPlan.interval),
            giftSubscription.gifted_subscription_cycles,
            true
          )}
          . You can start your subscription anytime by following these steps:
        </Text.Body>
        <ol>
          <li>
            <Text.Body>
              Visit{" "}
              <a href={giftSubscription.redemption_link}>
                {giftSubscription.redemption_link}
              </a>
            </Text.Body>
          </li>
          <li>
            <Text.Body>Create an account or sign in</Text.Body>
          </li>
          <li>
            <Text.Body>Enter this code: {giftSubscription.gift_code}</Text.Body>
          </li>
        </ol>
      </MeasuredContent>
      <CenteredContent style={{ flexDirection: "row" }}>
        <SubmitButton
          brand
          type="button"
          styleType="primary"
          onSubmit={async () => {
            const email = window.prompt(
              "Enter the recipient’s email for this gift subscription"
            )
            if (email) {
              await sendGiftSubscriptionEmail({
                variables: {
                  gift_id: giftSubscription.id,
                  recipient_email: email
                }
              })
              alert(`Email sent to gift recipient.`)
            }
          }}
        >
          Email
        </SubmitButton>
        <Button
          brand
          styleType="primary"
          style={{ marginLeft: 5 }}
          onClick={() => printContent()}
        >
          Print
        </Button>
      </CenteredContent>
    </>
  ) : giftSubscriptionQuery.loading ? (
    <LoadingCircle />
  ) : null
}

export const GiftSubscriptionConfirmationCard = ({
  card,
  code,
  printContent = () => {
    // connected in legacy/client
    alert(`Connect using withPrintContent(".gift-subscription-printable")`)
  }
}) => (
  <GiftSubscriptionConfirmationCardWrapper card={card}>
    <GiftSubscriptionConfirmation code={code} printContent={printContent} />
  </GiftSubscriptionConfirmationCardWrapper>
)

export default function GiftSubscriptionOrderConfirmationCard({
  card,
  orderId,
  printContent
}) {
  const orderQuery = useOrderPollingQuery(orderId)
  let order = get(orderQuery, "data.order")

  return (
    <GiftSubscriptionConfirmationCardWrapper
      card={card}
      id={order && order.fulfilled_object_id}
    >
      {orderQuery.error ? (
        <CenteredContent>
          <Alert error={orderQuery.error} />
        </CenteredContent>
      ) : order ? (
        order.order_status === "fulfilled" ? (
          <GiftSubscriptionConfirmation
            id={order.fulfilled_object_id}
            printContent={printContent}
          />
        ) : (
          <CenteredContent>
            <LoadingCircle center={false} />
            <Text.H4>Processing order...</Text.H4>
          </CenteredContent>
        )
      ) : orderQuery.loading ? (
        <LoadingCircle />
      ) : null}
    </GiftSubscriptionConfirmationCardWrapper>
  )
}
