import { getQueryObject } from "@pathwright/web/src/modules/utils/urls"
import omit from "lodash/omit"
import { Route } from "react-router-dom"
import { canGoHome } from "../../../belltower/utils"
import { hasStoreEnabled } from "../../../store/utils"
import AppRoute from "./AppRoute"

export const historyListener = function (location) {
  const legacyLocation = getLegacyRouterLocation(location)

  // Track last_pathname
  window.localStorage.setItem("last_pathname", legacyLocation.pathname)
  window.App.getStore("navigation").action.setLocation(legacyLocation)
  window.App.getStore("analytics").action.trackPageView(legacyLocation.pathname)
  window.App.getStore("auth").action.clearNextUrl()
}

export const getLegacyRouterLocation = (location) => {
  const legacyRouterState = {
    ...location,
    query: getQueryObject(location.search)
  }

  return legacyRouterState
}

export const getLegacyRouterState = (nextState) => {
  const legacyRouterState = {
    ...nextState,
    params: nextState.match.params,
    location: getLegacyRouterLocation(nextState.location)
  }

  return legacyRouterState
}

export const getDefaultLaunchedFromLibrary = (nextState) =>
  hasStoreEnabled() ? "/store/" : "/library/"

// Generic util for getting default launched from url
export const getDefaultLaunchedFromHome = (nextState) =>
  canGoHome() ? "/home/" : getDefaultLaunchedFromLibrary(nextState)

export const getInitialRedirect = (location) => {
  // enforce forward slash
  if (
    !location.pathname.endsWith("/") &&
    !location.pathname.endsWith(".html")
  ) {
    // found route without closing slash, redirect it
    return {
      ...location,
      pathname: `${location.pathname}/`
    }
  }

  if ("forceHardRefresh" in getQueryObject(location.search)) {
    return {
      ...location,
      // Remove the "forceHardRefresh" query param.
      search: new URLSearchParams(
        omit(getQueryObject(location.search), "forceHardRefresh")
      ).toString()
    }
  }

  const { is_authenticated } = window.App.getStore("auth").getState()

  const schoolInactiveRedirectRoute =
    window.App.getStore("navigation").request.getSchoolInactiveRedirectRoute(
      location
    )
  if (schoolInactiveRedirectRoute) {
    return schoolInactiveRedirectRoute
  }

  if (location.pathname !== "/") {
    return null
  }

  // root route redericts

  if (!is_authenticated) {
    // Ensure home page will be displayed if exists
    const homePage = window.App.getStore("school").request.getPageForURL(
      location.pathname
    )
    if (homePage && homePage.url.indexOf("http") === -1) {
      return homePage.url
    }
  }

  // Go to home location
  const redirectLocation =
    window.App.getStore("navigation").request.getHomeLocation()

  return redirectLocation
}

export const createRoutes = (routes) => {
  // spread any child route that is an array so that childRoutes is a flat list
  routes = routes.reduce((routes, childRoute) => {
    if (Array.isArray(childRoute)) {
      return [...routes, ...childRoute]
    }

    return [...routes, childRoute]
  }, [])

  function convertToRoute(
    route,
    parentPath = "",
    parentPrimaryComponent = null
  ) {
    const path = `${parentPath}${route.path}`
    // ensure every route is provided with the closest primary
    // component relative to itself and parent routes
    const primaryComponent =
      route.component ||
      (route.components && route.components.primary) ||
      parentPrimaryComponent

    return (
      <Route
        key={path}
        path={path}
        render={(props) => (
          <>
            <AppRoute
              {...props}
              route={{ ...route, component: primaryComponent }}
            />
            {route.childRoutes
              ? route.childRoutes.map((route) =>
                  convertToRoute(route, path, primaryComponent)
                )
              : null}
          </>
        )}
        exact={!route.childRoutes}
        strict
      />
    )
  }

  return routes.map((route) => convertToRoute(route))
}
