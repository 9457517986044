// NOTE: this will eventually need to be an npm module ideally...

import { action, extendObservable, isObservableObject, spy, toJS } from "mobx"
// useStrict(true)

const DEBUG = false

spy((event) => {
  if (!DEBUG) return

  if (event.type === "action") {
    const actionName = event.name.replace("bound ", "")
    const args = event.arguments.map((o) =>
      isObservableObject(o) ? toJS(o) : o
    )
    console.log(`ACTION: ${actionName}`, ...args)
  }
})

const actionWrapper = (store, state, actions, actionType) => {
  const getActionVerb = () => {
    switch (actionType) {
      case "load":
        return "loading"
      case "save":
        return "saving"
    }
  }
  for (let key in actions) {
    if (key.indexOf(actionType) == 0) {
      let keyWords = key.match(/[A-Z][a-z0-1]+/g)
      if (keyWords) {
        let stateKey = key
          .match(/[A-Z][a-z0-1]+/g)
          .join("_")
          .toLowerCase()
        let stateActionKey = `${stateKey}_${getActionVerb()}`
        let stateErrorKey = `${stateKey}_error`
        state[stateActionKey] = false
        state[stateErrorKey] = null
        let fn = actions[key]
        actions[key] = (...args) => {
          store[stateActionKey] = true
          return new Promise((resolve, reject) => {
            const promise = fn.apply(store, args)
            promise && promise.then
              ? promise
                  .then(
                    action((response) => {
                      store[stateKey] = response
                      store[stateErrorKey] = null
                      resolve(response)
                    })
                  )
                  .catch(
                    action((err) => {
                      store[stateErrorKey] = err
                      reject(err)
                    })
                  )
                  .then(
                    action(() => {
                      store[stateActionKey] = false
                    })
                  )
              : null // reject(`A promise was not returned from ${key}`)
          })
        }
      }
    }
  }
}

export class Store {
  constructor(state, actions = {}, reactions = {}) {
    actionWrapper(this, state, actions, "load")
    actionWrapper(this, state, actions, "save")
    this.initialState = state
    this.action = {
      ...actions,
      setValues(values) {
        for (let key in values) {
          if (this.hasOwnProperty(key)) this[key] = values[key]
        }
      }
    }
    this.reaction = {}

    extendObservable(this, state)

    for (let key in actions) {
      if (actions.hasOwnProperty(key))
        this.action[key] = action(actions[key].bind(this))
    }

    for (let key in reactions) {
      if (reactions.hasOwnProperty(key)) {
        const reactionFunc = reactions[key].bind(this)
        this.reaction[key] = reactionFunc
        reactionFunc()
      }
    }
  }

  toJS() {
    return toJS(this)
  }

  transaction(actionFunc) {
    return action(actionFunc)()
  }
}

export const createStoreClass = (state, actions, requests, reactions) => {
  return class NewStore extends Store {
    constructor(initialState) {
      super(state, actions, requests, reactions)
      this.initialState = initialState
    }
  }
}

export const createStore = (state, actions, requests, reactions) => {
  return new Store(state, actions, requests, reactions)
}
