import { ResourceType } from "../api/generated"

export const RESOURCE_TYPE_COURSE = 10
export const RESOURCE_TYPE_LESSON = 20
export const RESOURCE_TYPE_COLLECTION = 30

export const RESOURCE_TYPE_LABELS: Record<number, ResourceType> = {
  [RESOURCE_TYPE_COURSE]: ResourceType.Course,
  [RESOURCE_TYPE_LESSON]: ResourceType.Lesson,
  [RESOURCE_TYPE_COLLECTION]: ResourceType.Collection
}
