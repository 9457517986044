import Button from "@pathwright/ui/src/components/button/Button"
import DropdownItem from "@pathwright/ui/src/components/dropdown/DropdownItem"
import DropdownMenu from "@pathwright/ui/src/components/dropdown/DropdownMenu"
import DropdownTrigger from "@pathwright/ui/src/components/dropdown/DropdownTrigger"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import { INVITATION_STATES } from "invitation/constants"
import capitalize from "lodash/capitalize"
import { observer } from "mobx-react"
import invitationStore from "../store/invitation-store"

const InvitationFilter = () => {
  return (
    <div>
      <DropdownTrigger
        renderDropdown={() => (
          <DropdownMenu closeOnClick>
            {INVITATION_STATES.map((status) => (
              <DropdownItem
                key={status}
                label={capitalize(status)}
                onClick={() => invitationStore.action.filterStatus(status)}
              />
            ))}
          </DropdownMenu>
        )}
      >
        <Button styleType="secondary">
          <span>{invitationStore.status}</span> <Pathicon icon="chevron-down" />
        </Button>
      </DropdownTrigger>
    </div>
  )
}

InvitationFilter.displayName = "InvitationFilter"

export default observer(InvitationFilter)
