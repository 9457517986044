import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import Card from "@pathwright/ui/src/components/card/Card"
import CardBlock from "@pathwright/ui/src/components/card/CardBlock"
import CardHeader from "@pathwright/ui/src/components/card/CardHeader"
import ErrorMessage from "@pathwright/ui/src/components/error/ErrorMessage"
import RadioButton from "@pathwright/ui/src/components/form/form-radio/RadioButton"
import TextInput from "@pathwright/ui/src/components/form/form-text-input/TextInput"
import TextArea from "@pathwright/ui/src/components/form/form-textarea/TextArea"
import FieldHeader from "@pathwright/ui/src/components/form/form-utils/FieldHeader"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import Text from "@pathwright/ui/src/components/ui/Text"
import useSpaceHasFeature from "@pathwright/web/src/modules/lib/useSpaceHasFeature"
import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import get from "lodash/get"
import pluralize from "pluralize"
import React, { useState } from "react"
import { Field, Form } from "react-connect-form-forked/dist"
import { validators } from "react-connect-form-forked/dist/utils"
import styled from "styled-components"
import AdaptedAuthContainer from "../auth/AdaptedAuthContainer"
import SignUpOrIn from "../auth/SignUpOrIn"
import { useCurrencyFormatter } from "../currency/CurrencyFormatterProvider"
import SelectInput from "../lib/SelectInput/SelectInput"
import { usePathwrightContext } from "../pathwright/PathwrightContext"
import SCHOOL_SUBSCRIPTION_PLANS_QUERY from "../subscription/graphql/school-subscription-plans-query"
import { flattenEdges } from "../utils/apollo"
import OrderStripeCheckoutForm from "./OrderStripeCheckoutForm"

const FieldRow = styled.div`
  display: flex;
  align-items: flex-start;
  & > * {
    width: 100%;
    :not(:last-child) {
      margin-right: 1rem;
    }
  }
`

export default function OrderGiftSubscriptionCard({
  card,
  visibilityCode,
  visibilityCodeFilter
}) {
  const { me, school } = usePathwrightContext()
  const { currencyFormatter } = useCurrencyFormatter()
  const [formValues, setFormValues] = useState({})
  const hasAuthPw3 = useSpaceHasFeature("auth__platform")

  const { loading, error, data } = useQuery(SCHOOL_SUBSCRIPTION_PLANS_QUERY, {
    variables: {
      giftable: true,
      visibility_code: visibilityCode || null,
      visibility_code_filter: visibilityCodeFilter || null
    }
  })

  const plans = flattenEdges(get(data, "school.subscription_plans")) || []

  let selectedPlan
  let selectedInterval
  if (plans.length === 1) {
    selectedPlan = plans[0]
    selectedInterval = getIntervalDisplayName(
      selectedPlan.subscription_plan.interval
    )
  } else if (formValues.plan) {
    selectedPlan = plans.find(
      (x) => x.subscription_plan.id == formValues.plan.id
    )
    selectedInterval = getIntervalDisplayName(
      selectedPlan.subscription_plan.interval
    )
  }

  // NOTE: visibilityCodeFilter only supports eq right now.
  const planVisibilityCode =
    visibilityCode || (visibilityCodeFilter && visibilityCodeFilter.eq)

  return (
    <Card card={card} navless noaction>
      <CardHeader
        card={card}
        title="Gift A Subscription"
        meta={`To ${school.name}`}
      />
      {!me ? (
        hasAuthPw3 ? (
          <AdaptedAuthContainer />
        ) : (
          <SignUpOrIn />
        )
      ) : error ? (
        <BlankSlate
          icon="statuscaution"
          heading="An unexpected error occurred"
          body={<ErrorMessage error={error} />}
        />
      ) : loading ? (
        <LoadingCircle />
      ) : plans.length > 0 ? (
        <CardBlock>
          <Form initialValue={formValues} onChange={setFormValues}>
            <FieldHeader
              style={{ marginTop: -20 }}
              title="Who would you like to send this gift to?"
              body="This will help us personalize your gift"
            />
            <FieldRow>
              <Field
                topLabel={true}
                name="recipient_first_name"
                label="Recipient First Name"
                component={TextInput}
                validators={[validators.isRequired]}
              />
              <Field
                topLabel={true}
                name="recipient_last_name"
                label="Recipient Last Name"
                component={TextInput}
                validators={[validators.isRequired]}
              />
            </FieldRow>
            <Field
              topLabel={true}
              name="gift_message"
              label="Gift Message"
              component={TextArea}
              minRows={3}
            />
            {plans.length > 1 ? (
              <React.Fragment>
                <FieldHeader title="Select the subscription plan you would like to gift" />
                <Field
                  name="plan"
                  component={SelectInput}
                  validators={[validators.isRequired]}
                  options={plans.map((plan) => ({
                    id: plan.subscription_plan.id,
                    label: plan.subscription_plan.name
                  }))}
                />
              </React.Fragment>
            ) : null}
            {selectedPlan ? (
              <React.Fragment>
                <FieldHeader
                  title={`How many ${selectedInterval}s would you like to give?`}
                  body={`Your gift recipient will have free access for as many ${selectedInterval}s as you select below as soon as they redeem their gift, which they can do anytime.`}
                />
                {selectedPlan.giftable_cycles.map((n) => (
                  <Field
                    key={selectedPlan.id + n}
                    name="num_cycles"
                    type="radio"
                    value={n}
                    component={RadioButton}
                    required
                  >
                    <Text.Body>
                      Gift <b>{pluralize(selectedInterval, n, true)}</b>
                      {" for "}
                      {currencyFormatter.customFormat(
                        selectedPlan.subscription_plan.amount * n
                      )}
                    </Text.Body>
                  </Field>
                ))}
              </React.Fragment>
            ) : null}
            <FieldHeader body="Your gift recipient will have free access as soon as they redeem their gift, which they can do at any time. Once you purchase the gift, your card will be charged one time. You'll be able to print or email your gift immediately after your purchase." />
          </Form>
          <OrderStripeCheckoutForm
            disabled={
              !selectedPlan ||
              !formValues.num_cycles ||
              !formValues.recipient_first_name ||
              !formValues.recipient_last_name
            }
            orderType="createGiftSubscriptionOrder"
            orderData={{
              plan_id: selectedPlan && selectedPlan.id,
              plan_visibility_code: planVisibilityCode,
              num_cycles: formValues.num_cycles,
              recipient_first_name: formValues.recipient_first_name,
              recipient_last_name: formValues.recipient_last_name,
              gift_message: formValues.gift_message
            }}
          />
        </CardBlock>
      ) : (
        <BlankSlate icon="subscription" heading="No giftable plans found" />
      )}
    </Card>
  )
}

function getIntervalDisplayName(n) {
  switch (n) {
    case 5:
      return "month"
    case 10:
      return "year"
    case 20:
      return "lifetime"
    default:
      return ""
  }
}

OrderGiftSubscriptionCard.displayName = "OrderGiftSubscriptionCard"
