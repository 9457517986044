import { media } from "@pathwright/ui/src/components/utils/styles"
import styled from "styled-components"
import ActivityEntry from "./ActivityEntry"
import { ActivityColumnDivider } from "./ui"
import { groupActivity } from "./utils"

const StyledContainer = styled(ActivityColumnDivider)`
  position: relative;
  padding-bottom: 30px;
`

const StyledList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`

const StyledItem = styled.li`
  ${media.min.tablet`
		margin-bottom: -10px;
	`};
`

const StyledHeading = styled.h4`
  margin-top: 0;
  margin-bottom: 20px;
  line-height: 40px;
  text-align: center;
  font-weight: 700;
`

const ActivityDay = ({
  label,
  activity,
  startRight,
  isCourseActivity,
  isStepActivity,
  isUserActivity,
  canViewAvatarCard,
  canViewAvatarProfile
}) => {
  const initialSide = startRight ? "right" : "left"
  const oppositeSide = startRight ? "left" : "right"
  return (
    <StyledContainer>
      <StyledHeading>{label}</StyledHeading>
      <StyledList>
        {groupActivity(activity).map((group, index) => (
          <StyledItem key={group[0][0].id}>
            {group.map((entry, i) => (
              <ActivityEntry
                key={i}
                actions={entry}
                columnSide={index % 2 ? initialSide : oppositeSide}
                isCourseActivity={isCourseActivity}
                isStepActivity={isStepActivity}
                isUserActivity={isUserActivity}
                canViewAvatarCard={canViewAvatarCard}
                canViewAvatarProfile={canViewAvatarProfile}
              />
            ))}
          </StyledItem>
        ))}
      </StyledList>
    </StyledContainer>
  )
}

ActivityDay.displayName = "ActivityDay"

export default ActivityDay
