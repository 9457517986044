import { graphql } from "@apollo/client/react/hoc"
import ThemeBackground from "@pathwright/ui/src/components/background/ThemeBackground"
import ExecuteScripts from "@pathwright/ui/src/components/embed/ExecuteScripts"
import { media } from "@pathwright/ui/src/components/utils/styles"
import get from "lodash/get"
import { Component } from "react"
import styled from "styled-components"
import { getRoute } from "../utils/urls"
import {
  PAGE_CONTENT_TYPE_BLOCKS,
  PAGE_CONTENT_TYPE_LINK,
  PAGE_CONTENT_TYPE_TEMPLATE,
  PAGE_MODE_BLANK,
  PAGE_MODE_CONTAINED,
  PAGE_MODE_MODAL,
  PAGE_MODE_OPEN,
  PAGE_TYPE_HOME
} from "./constants"
import PAGE_QUERY from "./graphql/page-query"

const slugRe = /^\/(?:p|m)\/([a-zA-Z0-9-]+)\/$/
const modeRe = /^\/(p|m)\/[a-zA-Z0-9-]+\/$/
const homeRe = /^\/$/

const StyledIFrame = styled.iframe`
  width: 100%;
  height: 100vh;
  display: block;
  position: relative;
`

const PageModal = styled.div`
  max-width: 700px;
  margin: 0 auto;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  ${media.max.phone`
    padding-top: 45px;
  `};
`

const PageContained = styled.div`
  position: relative;
  margin: 0 auto;
  background-color: white;
  border-radius: 4px;
  ${media.min.phone`
    width: 600px;
  `}
  ${media.min.tablet`
    width: 750px;
  `}
  ${media.min.desktop`
    width: 1000px;
  `}
`

const PageContainedWrapper = styled.div`
  position: relative;
  min-height: 100vh;
  padding: 100px 0;
  ${media.max.phone`
    padding: 75px 0;
  `};
`

const Page = ({ page, PageBlocks }) => {
  const defaultContentType = page.iframe_url
    ? PAGE_CONTENT_TYPE_LINK
    : page.template_content
    ? PAGE_CONTENT_TYPE_TEMPLATE
    : PAGE_CONTENT_TYPE_BLOCKS

  const contentType = page.content_type
    ? page.content_type === PAGE_CONTENT_TYPE_LINK && page.iframe_url
      ? PAGE_CONTENT_TYPE_LINK
      : page.content_type === PAGE_CONTENT_TYPE_TEMPLATE &&
        page.template_content
      ? PAGE_CONTENT_TYPE_TEMPLATE
      : PAGE_CONTENT_TYPE_BLOCKS
    : defaultContentType

  return (
    <div>
      {contentType === PAGE_CONTENT_TYPE_LINK ? (
        <StyledIFrame frameBorder="0" src={page.iframe_url} />
      ) : contentType === PAGE_CONTENT_TYPE_TEMPLATE ? (
        <div dangerouslySetInnerHTML={{ __html: page.template_content }} />
      ) : (
        contentType === PAGE_CONTENT_TYPE_BLOCKS && <PageBlocks id={page.id} />
      )}
      {page.scripts && <ExecuteScripts html={page.scripts} />}
    </div>
  )
}

Page.displayName = "Page"

class PageWrapper extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!!nextProps.page && !this.props.page) {
      this.onmessage = window.addEventListener("message", (e) => {
        // handle message only if message is coming from the iframed page
        if (
          !!nextProps.page.iframe_url &&
          nextProps.page.iframe_url === e.origin
        ) {
          let data = e.data
          try {
            const data = JSON.parse(data)
          } catch (error) {
            // data isn't stringified JSON, that's fine
          }
          switch (data.action) {
            case "navigate":
              try {
                // expecting data.data to be a url
                const route = getRoute(data.data)
                window.App && window.App.navigate && window.App.navigate(route)
              } catch (error) {
                console.warn(`Error posting message: ${error}`)
              }
          }
        }
      })
    }
  }

  componentWillUnmount() {
    !!this.onmessage && window.removeEventListener(this.onmessage)
  }

  shouldComponentUpdate(nextProps) {
    if (
      (!!this.props.page !== !!nextProps.page && !!nextProps.page) ||
      (this.props.page &&
        nextProps.page &&
        this.props.page.id !== nextProps.page.id)
    ) {
      return true
    }
    return false
  }

  render() {
    const { page } = this.props
    if (!page) {
      return null
    }
    switch (page.mode) {
      case PAGE_MODE_MODAL:
        return (
          <PageModal>
            <Page {...this.props} />
          </PageModal>
        )
      case PAGE_MODE_CONTAINED:
        return (
          <PageContainedWrapper>
            <ThemeBackground />
            <PageContained>
              <Page {...this.props} />
            </PageContained>
          </PageContainedWrapper>
        )
      case PAGE_MODE_OPEN:
        return <Page {...this.props} />
      case PAGE_MODE_BLANK:
        // Shouldn't ever make it here anyhow
        return null
    }
  }
}

PageWrapper.displayName = "PageWrapper"

const getPageVariables = ({ id }) => {
  const { pathname } = window.location
  const slug = get(pathname.match(slugRe), "[1]")
  const isHome = homeRe.test(pathname)
  const variables = {}

  return id
    ? {
        variables: {
          ...variables,
          id: id
        }
      }
    : slug
    ? {
        variables: {
          ...variables,
          slug: slug
        }
      }
    : isHome
    ? {
        variables: {
          ...variables,
          page_type: PAGE_TYPE_HOME
        }
      }
    : {
        variables
      }
}

export default graphql(PAGE_QUERY, {
  options: getPageVariables,
  skip: (props) => Object.keys(getPageVariables(props).variables).length === 0,
  props: ({ data }) => ({
    page: get(data, "school.page")
  })
})(PageWrapper)
