const LicenseOffering = require("license/models/license-offering").default
import { LICENSING_MODES } from "@pathwright/web/src/modules/store/resource/offering/license/constants"

class ResourceLicenseAdmin extends require("lib/static-shim").default(
  require("lib/core/store/backbone-store").default
) {
  static initClass() {
    this.prototype.resources = {
      license_offering: {
        model: LicenseOffering
      }
    }

    this.prototype.actions = {
      loadLicenseOffering(resource_id, rlo_id) {
        return this.resource.license_offering.load({ resource_id, id: rlo_id })
      },

      saveLicenseOffering(data) {
        const promises = []
        const { resource } = this.getStore("resourceAdmin").getState()
        const { license_offering } = this.getState() || {}
        data.resource_id = resource.id
        if (!data.hasOwnProperty("licensing_enabled")) {
          data.licensing_enabled = resource.licensing_enabled
        } else if (
          data.licensing_enabled !==
          (resource != null ? resource.licensing_enabled : undefined)
        ) {
          promises.push(
            this.getStore("resourceAdmin").action.saveResource({
              licensing_enabled: data.licensing_enabled
            }).promise
          )
        }
        if (data.licensing_enabled) {
          // ensure licensing mode is set on initial creation
          if (!license_offering.licensing_mode) {
            license_offering.licensing_mode =
              LICENSING_MODES.PRIVATE_COHORTS.int
          }

          promises.push(
            this.resource.license_offering.save({
              // ensuring expected fields are present
              ...license_offering,
              ...data
            })
          )
        }
        return Promise.all(promises)
      }
    }

    this.prototype.requests = {}
  }

  defaults() {}
}
ResourceLicenseAdmin.initClass()

export default window.App.stores.registerStore(
  "resourceLicenseAdmin",
  ResourceLicenseAdmin
)
