// Taken from: https://steve.dignam.xyz/2020/03/31/practical-ordering/

const START_CHAR_CODE = 32
const END_CHAR_CODE = 126
export const FIRST_POSITION = String.fromCharCode(START_CHAR_CODE + 1)

function assertDev(expr) {
  if (!expr) {
    throw Error("Assertion Error")
  }
}

export function comparePositions(firstPos, secondPos) {
  return +(firstPos < secondPos) - +(firstPos > secondPos)
}

export function isValidPosition(pos) {
  if (pos === "" || pos.charCodeAt(pos.length - 1) == START_CHAR_CODE) {
    return false
  }
  for (let i = 0; i < pos.length; i++) {
    const t = pos.charCodeAt(i)
    if (t < START_CHAR_CODE || t > END_CHAR_CODE) {
      return false
    }
  }
  return true
}

export function positionBefore(pos) {
  assertDev(0 !== pos.length)

  for (let i = pos.length - 1; i >= 0; i--) {
    let curCharCode = pos.charCodeAt(i)
    if (curCharCode > START_CHAR_CODE + 1) {
      let position = pos.substr(0, i) + String.fromCharCode(curCharCode - 1)
      assertDev(isValidPosition(position))
      return position
    }
  }
  let position =
    pos.substr(0, pos.length - 1) +
    String.fromCharCode(START_CHAR_CODE) +
    String.fromCharCode(END_CHAR_CODE)
  assertDev(isValidPosition(position))
  return position
}

export function positionAfter(pos) {
  assertDev(0 !== pos.length)

  for (let i = pos.length - 1; i >= 0; i--) {
    let curCharCode = pos.charCodeAt(i)
    if (curCharCode < END_CHAR_CODE) {
      let position = pos.substr(0, i) + String.fromCharCode(curCharCode + 1)
      assertDev(isValidPosition(position))
      return position
    }
  }
  let position = pos + String.fromCharCode(START_CHAR_CODE + 1)
  assertDev(isValidPosition(position))
  return position
}

function avg(a, b) {
  // @ts-ignore
  return Math.trunc((a + b) / 2)
}

export function positionBetween(firstPos, secondPos) {
  assertDev(firstPos < secondPos)
  let flag = false
  let position = ""
  const maxLength = Math.max(firstPos.length, secondPos.length)
  for (let i = 0; i < maxLength; i++) {
    const lower = i < firstPos.length ? firstPos.charCodeAt(i) : START_CHAR_CODE
    const upper =
      i < secondPos.length && !flag ? secondPos.charCodeAt(i) : END_CHAR_CODE
    if (lower === upper) {
      position += String.fromCharCode(lower)
    } else if (upper - lower > 1) {
      position += String.fromCharCode(avg(lower, upper))
      flag = false
      break
    } else {
      position += String.fromCharCode(lower)
      flag = true
    }
  }

  if (flag) {
    position += String.fromCharCode(avg(START_CHAR_CODE, END_CHAR_CODE))
  }
  assertDev(firstPos < position)
  assertDev(position < secondPos)
  assertDev(isValidPosition(position))
  return position
}

// Assumes list already contains item at index with position (likely in local state)
// and we merely want to determine the item's updated position (possibly for database mutation).
export function getPosition(list, item, index, positionKey = "position") {
  const targetItem = list[index]
  // The index of the item being moved which can be compared to the target index
  // which can be used to determin previous and next positions when moving item
  // in between items.
  const itemIndex = list.findIndex(
    (listItem) => listItem[positionKey] === item[positionKey]
  )
  // Inserted at beginning of list.
  if (index === 0) {
    if (targetItem) return positionBefore(targetItem[positionKey])
    return FIRST_POSITION
  }
  // Inserted at end of list.
  if (index === list.length - 1) {
    if (targetItem) return positionAfter(targetItem[positionKey])
    return FIRST_POSITION
  }
  // Inserted in between items in list.
  if (index < itemIndex) {
    // Going backwards in list, need new position in between previous index and target index.
    const prevPosition = list[index - 1][positionKey]
    const nextPosition = list[index][positionKey]
    return positionBetween(prevPosition, nextPosition)
  }
  if (index > itemIndex) {
    // Going forwards in list, need new position in between target index and next index.
    const prevPosition = list[index][positionKey]
    const nextPosition = list[index + 1][positionKey]
    return positionBetween(prevPosition, nextPosition)
  }
  // False alarm, not moving in list, return current position if possible.
  if (index === itemIndex) {
    if (item) return item[positionKey]
    return FIRST_POSITION
  }
}
