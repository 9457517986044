import gql from "graphql-tag"
import COMPLETION_FRAGMENT from "./completion-fragment"

export default gql`
  mutation ResetCompletionFeedback($id: Int!, $pathId: Int!) {
    resetCompletionFeedback(id: $id, path_id: $pathId) {
      ...Completion
    }
  }
  ${COMPLETION_FRAGMENT}
`
