// Wrap form components so props from [Field][field]
// match expected prop shape from FieldWrapper
// [field]: https://github.com/robertgonzales/react-connect-form-forked/blob/master/src/components/Field.js

import _SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import _ColorPickerToggle from "./form-color/ColorPickerToggle"
import _ImageUpload from "./form-img-upload/ImageUpload"
import _RichText from "./form-richtext/RichText"
import _TextInput from "./form-text-input/TextInput"
import _TextArea from "./form-textarea/TextArea"
import _ToggleInput from "./form-toggle/ToggleInput"

// rebuilt for new forms
export { RadioButton, RadioGroup } from "./form-radio/NewRadio"

const withFieldProps = (FormComponent) => {
  return (props) => {
    return (
      <FormComponent
        {...props}
        showLoading={props.validating}
        showSuccess={props.validated}
        showError={props.errors && props.errors.length > 0}
        errorMessage={props.errors && props.errors[0]}
      />
    )
  }
}

export const TextInput = withFieldProps(_TextInput)
export const TextArea = withFieldProps(_TextArea)
export const ImageUpload = withFieldProps(_ImageUpload)
export const RichText = withFieldProps(_RichText)
export const ColorPickerToggle = withFieldProps(_ColorPickerToggle)
export const ToggleInput = withFieldProps(_ToggleInput)
export const SubmitButton = _SubmitButton
