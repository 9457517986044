// path modes
export const PREVIEW = 0
export const LEARN = 5
export const TEACH = 10
export const EDIT = 15

export const PATH_MODE_LABELS = {
  [PREVIEW]: "preview",
  [LEARN]: "learn",
  [TEACH]: "teach",
  [EDIT]: "edit"
}

export const SHOW_GRADING_FEEDBACK_ALWAYS = 0
export const SHOW_GRADING_FEEDBACK_COMPLETION = 5
export const SHOW_GRADING_FEEDBACK_NEVER = 10

export const REGISTERED_MEMBERS_ONLY = "registered-only"
export const PASSWORD_LOCKED = "password-locked"
export const ANYONE_CAN_PREVIEW = "anyone-can-preview"

export const GRADING_TYPE_MANUAL = "M"
export const GRADING_TYPE_AUTOMATIC = "A"

export const ALLOW_RESETS_ALWAYS = 0
export const ALLOW_RESETS_BEFORE = 5

export const DROP_TYPE_PATH = "PATH"
export const DROP_TYPE_PATH_ITEM = "PATH_ITEM"
