import {
  Box,
  Button,
  Divider,
  HStack,
  Heading,
  IconButton,
  Link,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Select,
  Switch,
  Text,
  Tooltip,
  VStack,
  useTheme
} from "@chakra-ui/react"
import { InfoCircleIcon } from "@pathwright/pathicons"
import ColorPicker from "@pathwright/ui/src/components/form/form-color/ColorPicker"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import {
  getAlphaColor,
  parseRgba,
  stringifyRgba
} from "@pathwright/ui/src/components/utils/colors"
import get from "lodash/get"
import { forwardRef, useMemo, useState } from "react"
import { Link as ReactRouterLink } from "react-router-dom"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import { canEditLibrary } from "../../user/permissions"
import { useCertificateContext } from "../context/CertificateContext"
import { useCertificateState } from "../context/CertificateState"
import fonts, {
  getFont,
  getFontByFontName,
  getFontName,
  parseFontName
} from "../fonts/fonts"
import useCertificatePreview from "../hooks/useCertificatePreview"
import useCertificateSyncPreview from "../hooks/useCertificateSyncPreview"
import CertificateTemplateForm from "../template/CertificateTemplateForm"
import { createDefaultTextObject } from "../text/CertificateText"

const Info = () => {
  const { certificateScope } = useCertificateContext()
  const pwContext = usePathwrightContext()

  if (canEditLibrary(pwContext) && certificateScope) {
    return (
      <VStack spacing={4} alignItems="flex-start" width="100%" boxShadow="lg">
        <Box bg={"white"} p={4} borderRadius={"md"}>
          To modify the design of all certificates visit the{" "}
          <Link
            as={ReactRouterLink}
            textDecoration="underline"
            to="/certificate/"
          >
            Certificate Template Editor
          </Link>
          .
        </Box>
      </VStack>
    )
  }

  return null
}

const BackgroundSection = () => (
  <VStack
    spacing={4}
    alignItems="flex-start"
    width="100%"
    sx={{
      "& .CertificateTemplateForm": {
        maxW: 250,
        alignSelf: "center"
      }
    }}
  >
    <Heading as="h5" size="sm" mb={2}>
      Background
    </Heading>
    <CertificateTemplateForm hash="demo" />
  </VStack>
)

const TextObjectFont = ({ textObject, onChangeTextObject }) => {
  const selectedFont = getFontByFontName(textObject.font_name)
  const selectedFontVariants = selectedFont ? selectedFont.variants : []
  const { fontVariant: selectedFontVariant } = parseFontName(
    textObject.font_name
  )

  const handleChangeFontName = (e) => {
    const fontKey = e.target.value
    const fontVariant = getFont(fontKey).variants.reduce(
      (matchingFontVariant, fontVariant) =>
        fontVariant === selectedFontVariant
          ? selectedFontVariant
          : matchingFontVariant,
      "regular"
    )
    onChangeTextObject({
      font_name: getFontName(fontKey, fontVariant)
    })
  }

  const fontVariantIcons = {
    bold: "bold",
    italic: "italic"
  }

  const FontVariantButton = ({ fontVariant }) =>
    fontVariant !== "regular" ? (
      <IconButton
        aria-label={`Certificate font variant ${fontVariant}`}
        colorScheme="blackAlpha"
        variant={fontVariant === selectedFontVariant ? "solid" : "ghost"}
        w={8}
        h={8}
        minW={8}
        minH={8}
        icon={<Pathicon icon={fontVariantIcons[fontVariant]} />}
        onClick={() => {
          const nextFontVariant =
            fontVariant === selectedFontVariant ? "regular" : fontVariant
          const fontName = getFontName(selectedFont.key, nextFontVariant)
          onChangeTextObject({ font_name: fontName })
        }}
      />
    ) : null

  return (
    <HStack spacing={4}>
      <Text mb={0}>Font</Text>
      <Select
        icon={<Pathicon icon="chevron-down" />}
        placeholder={!selectedFont && (textObject.font_name || "Select font")}
        value={selectedFont && selectedFont.key}
        onChange={handleChangeFontName}
      >
        {fonts.map((fontGroup) => (
          <optgroup key={fontGroup.category} label={fontGroup.category}>
            {fontGroup.fonts.map((font) => (
              <option key={font.key} value={font.key}>
                {font.name}
              </option>
            ))}
          </optgroup>
        ))}
      </Select>
      {selectedFontVariants.map((fontVariant) => (
        <FontVariantButton key={fontVariant} fontVariant={fontVariant} />
      ))}
    </HStack>
  )
}

const TextObjectFontSize = ({ textObject, onChangeTextObject }) => {
  const min = 12
  const max = 48

  return (
    <HStack spacing={4}>
      <Text mb={0}>Size</Text>
      <NumberInput
        defaultValue={textObject.font_size + ""}
        onChange={(value) => {
          const parsedValue = Math.min(
            Math.max(parseFloat(value) || 0, min),
            max
          )
          // Only setting the value when valid. Allowing the internal state to
          // use string value while setting the external state to a parsed float.
          if (parseFloat(value) === parsedValue) {
            onChangeTextObject({ font_size: parsedValue })
          }
        }}
        step={1}
        min={min}
        max={max}
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper children={<Pathicon icon="chevron-up" />} />
          <NumberDecrementStepper children={<Pathicon icon="chevron-down" />} />
        </NumberInputStepper>
      </NumberInput>
    </HStack>
  )
}

const TextObjectColor = ({ textObject, onChangeTextObject }) => {
  const theme = useTheme()

  const defaultColors = [
    "0,0,0,100", // black
    "100,100,100", // dark gray
    stringifyRgba(get(theme.colors, "primaryBrandColor.main")) // school primary brand color
  ]
    .filter(Boolean)
    .map((color) => parseRgba(color))

  const selectedColor = useMemo(
    () => parseRgba(textObject.font_color),
    [textObject.font_color]
  )

  // Track in state what color is currently selected. Cannot simply use
  // color value as the selected color for the color picker could match
  // any of the default colors, which would result in showing both colors
  // as selected.
  const [selectedColorIndex, setSelectedColorIndex] = useState()

  // Set the picker color to the textObject.font_color when that value doesn't
  // match any of the defaultColors.
  const [pickerColor, setPickerColor] = useState(
    selectedColor && !defaultColors.find((color) => color === selectedColor)
      ? selectedColor
      : "black"
  )

  const handlePickerColor = (color) => {
    const stringifiedRgb = stringifyRgba(color)
    setPickerColor(color)
    onChangeTextObject({
      font_color: stringifiedRgb
    })
  }

  const SwatchButton = forwardRef(
    ({ color, index, onClick, children }, ref) => (
      <Button
        ref={ref}
        borderRadius="100%"
        color="white"
        bgColor={color}
        onClick={(e) => {
          onClick(e)
          setSelectedColorIndex(index)
        }}
        _hover={{ bg: getAlphaColor(color, 0.8) }}
        _active={{ bg: getAlphaColor(color, 0.7) }}
        w={8}
        h={8}
        minW={8}
        minH={8}
        mg={5}
        p={0}
        sx={
          stringifyRgba(color) === stringifyRgba(selectedColor) &&
          (isNaN(selectedColorIndex) || selectedColorIndex === index) && {
            "&:before": {
              content: '""',
              borderRadius: "inherit",
              border: "2px solid white",
              margin: "2px",
              position: "absolute",
              top: "0",
              left: "0",
              right: "0",
              bottom: "0"
            }
          }
        }
      >
        {children}
      </Button>
    )
  )

  return (
    <HStack spacing={4}>
      <Text mb={0}>Color</Text>
      {defaultColors.map((color, i) => (
        <SwatchButton
          key={color}
          color={color}
          index={i}
          onClick={() =>
            onChangeTextObject({ font_color: stringifyRgba(color) })
          }
        />
      ))}

      {/* NOTE: wrapping in box to silence warning: https://github.com/chakra-ui/chakra-ui/issues/3440#issuecomment-851707911 */}
      <Box>
        <Popover isLazy>
          <PopoverTrigger>
            <SwatchButton
              color={pickerColor}
              index={defaultColors.length}
              onClick={() => handlePickerColor(pickerColor)}
            >
              <Pathicon icon="brush" />
            </SwatchButton>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverBody>
              <ColorPicker
                color={pickerColor}
                format="rgb"
                enableAlpha={false}
                circleSwatches={true}
                onChange={(color) =>
                  handlePickerColor(
                    `rgb(${color.rgb.r},${color.rgb.g},${color.rgb.b})`
                  )
                }
              />
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Box>
    </HStack>
  )
}

const TextObjectAlignment = ({ textObject, onChangeTextObject }) => {
  const alignmentIcons = {
    left: "text-align-left",
    center: "text-align-center",
    right: "text-align-right"
  }

  const AlignButton = ({ alignment }) => (
    <IconButton
      aria-label={`Certificate text align ${alignment}`}
      colorScheme="blackAlpha"
      variant={alignment === textObject.alignment ? "solid" : "ghost"}
      w={8}
      h={8}
      minW={8}
      minH={8}
      icon={<Pathicon icon={alignmentIcons[alignment]} />}
      onClick={() => onChangeTextObject({ alignment })}
    />
  )

  return (
    <HStack spacing={4}>
      <Text mb={0}>Alignment</Text>
      {Object.keys(alignmentIcons).map((alignment) => (
        <AlignButton key={alignment} alignment={alignment} />
      ))}
    </HStack>
  )
}

const TextObjectSection = () => {
  const {
    certificateState: { textObjects },
    selectedTextObjects,
    handleChangeTextObjects,
    selectedTextObjectIndexes
  } = useCertificateState()

  // Change all selected text objects at once.
  const onChangeTextObject = (partialTextObject) => {
    handleChangeTextObjects(
      textObjects.map((textObject, index) =>
        selectedTextObjectIndexes.includes(index)
          ? {
              ...textObject,
              ...partialTextObject
            }
          : textObject
      )
    )
  }

  // Only grab these fields.
  const fields = ["font_name", "font_size", "font_color", "alignment"]

  // Supports multiple selection.
  // When there's only 1 selected text object, we'll simply be left with the
  // same textObject values of that text object.
  const expandedTextObject = selectedTextObjects.reduce((acc, textObject) => {
    Object.entries(textObject).forEach(([key, value]) => {
      if (fields.includes(key)) {
        acc[key] ||= []
        acc[key].push(value)
      }
    })
    return acc
  }, {})

  // All keys that have only 1 unique value retain that value, all others
  // are given an "undefined" value which the inputs will use to determine
  // whether to show an "unselected" state.
  const textObject = Object.entries(expandedTextObject).reduce(
    (acc, [key, value]) => {
      const values = [...new Set([...value])]
      acc[key] = values.length === 1 ? values[0] : undefined
      return acc
    },
    {}
  )

  // Key this component uniquely to the selected indexes in order to reset any form
  // field values.
  const key = selectedTextObjectIndexes.join(":")

  return (
    <VStack key={key} spacing={4} alignItems="flex-start" width="100%">
      <Heading as="h5" size="sm" mb={2}>
        Text Box
      </Heading>
      <TextObjectFont
        textObject={textObject}
        onChangeTextObject={onChangeTextObject}
      />
      <TextObjectFontSize
        textObject={textObject}
        onChangeTextObject={onChangeTextObject}
      />
      <TextObjectColor
        textObject={textObject}
        onChangeTextObject={onChangeTextObject}
      />
      <TextObjectAlignment
        textObject={textObject}
        onChangeTextObject={onChangeTextObject}
      />
    </VStack>
  )
}

const ProximityLinesSection = () => {
  const { toggleProximityLines, isUsingProximityLines } = useCertificateState()

  return (
    <VStack spacing={4} alignItems="flex-start" width="100%">
      <Heading as="h5" size="sm" mb={2}>
        Proximiy Lines
      </Heading>
      <HStack spacing={4}>
        <Text mb={0}>Snap to proximity lines when dragging or resizing?</Text>
        <Switch
          isChecked={isUsingProximityLines}
          onChange={(e) => toggleProximityLines(e.target.checked)}
        />
      </HStack>
    </VStack>
  )
}

const ActionsSection = () => {
  const { certificate, certificateScope } = useCertificateContext()
  const { certificateState, handleAddTextObject, templateDimensions } =
    useCertificateState()
  const certificatePreview = useCertificatePreview(certificateState)
  const certificateSyncPreview = useCertificateSyncPreview(certificateState)

  return (
    <VStack spacing={4} alignItems="flex-start" width="100%">
      <Heading as="h5" size="sm" mb={2}>
        Actions
      </Heading>

      {!certificateScope && (
        <HStack spacing={2}>
          <Pathicon icon="text-box" />
          <Button
            variant="link"
            colorScheme="black"
            textDecoration="underline"
            _hover={{ textDecoration: "underline" }}
            onClick={() =>
              handleAddTextObject(createDefaultTextObject(templateDimensions))
            }
            disabled={!certificate}
          >
            Add new text box
          </Button>
        </HStack>
      )}

      <HStack spacing={2}>
        <Pathicon icon="eyes" />
        <Button
          as={Link}
          variant="link"
          colorScheme="black"
          textDecoration="underline"
          _hover={{ textDecoration: "underline" }}
          href={certificateSyncPreview}
          target="_blank"
          disabled={!certificate}
        >
          Live preview
        </Button>
        <Tooltip label="Open a live preview in a new window to see how your changes will look in a real certificate as you make them.">
          <InfoCircleIcon height={"1em"} />
        </Tooltip>
      </HStack>

      <HStack spacing={2}>
        <Pathicon icon="download" />
        <Button
          as={Link}
          variant="link"
          colorScheme="black"
          textDecoration="underline"
          _hover={{ textDecoration: "underline" }}
          href={certificatePreview}
          isExternal
          download={true}
          disabled={!certificate}
        >
          Download
        </Button>
      </HStack>

      <HStack spacing={2}>
        <Pathicon icon="info-circle" />
        <Button
          as={Link}
          variant="link"
          colorScheme="black"
          textDecoration="underline"
          _hover={{ textDecoration: "underline" }}
          href="http://help.pathwright.com/en/articles/9211185-provide-a-certificate-of-completion"
          isExternal
        >
          Learn more about Certificates
        </Button>
      </HStack>
    </VStack>
  )
}

const DesignPanel = () => {
  const { certificateScope } = useCertificateContext()
  const { selectedTextObjects } = useCertificateState()
  const hasSelectedTextObject = selectedTextObjects.length >= 1

  return (
    <VStack spacing={6} pt={2} pb={4}>
      <Info />
      {!certificateScope && (
        <>
          <BackgroundSection />
          <Divider />
          {!!hasSelectedTextObject && (
            <>
              <TextObjectSection />
              <Divider />
            </>
          )}
          <ProximityLinesSection />
          <Divider />
        </>
      )}
      <ActionsSection />
    </VStack>
  )
}

DesignPanel.displayName = "DesignPanel"

export default DesignPanel
