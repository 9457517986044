import PropTypes from "prop-types"
import React from "react"
import isEqual from "react-fast-compare"
import validate from "./validate"

export default function (FormComponent, _fieldsData) {
  return class extends React.Component {
    static displayName = FormComponent.displayName
      ? `${FormComponent.displayName}FormContainer`
      : "FormContainer"

    static propTypes = {
      // Maps a field in fieldsData (key) to a value from a model/store (value)
      fieldValues: PropTypes.object,
      // Sets initial ability to submit form
      initialValid: PropTypes.bool,
      // Callback props to form events
      onChange: PropTypes.func,
      onSubmit: PropTypes.func,
      onReset: PropTypes.func,
      onValid: PropTypes.func,
      onInvalid: PropTypes.func,
      onDirty: PropTypes.func,
      onPristine: PropTypes.func
    }

    static defaultProps = { initialValid: false }

    constructor(props, context) {
      super(props, context)
      const state = {
        isValid: props.initialValid,
        isPristine: true,
        submitting: false,
        submitFailure: null,
        submitSuccess: null,
        fieldsData: this.getInitialFieldsState(props.fieldValues)
      }
      this.state = state
    }

    componentDidMount() {
      return (this._isUnmounting = false)
    } // hack alert

    componentWillUnmount() {
      return (this._isUnmounting = true)
    } // hack alert

    UNSAFE_componentWillReceiveProps(nextProps) {
      const { fieldValues } = nextProps
      if (fieldValues && !isEqual(fieldValues, this.props.fieldValues)) {
        const fieldsData = this.getInitialFieldsState(fieldValues, nextProps)
        return this.setState({ fieldsData })
      }
    }

    // Prepare fields on fieldsData. These props will
    // be passed into cloned components.
    getInitialFieldsState = (fieldValues, props) => {
      // Start with fieldsData props (see example for setup)
      let field
      const fieldsData = this.getFieldsData(props || this.props)
      const fields = {}
      for (var key in fieldsData) {
        // Create new field from fieldsData
        const data = fieldsData[key]
        field = _.extend({}, fieldsData[key])
        field._id = key
        // Set clean validation state
        field.pristine = true
        field.showError = false
        field.showSuccess = false
        field.showLoading = false
        // Hook field events to update/validation
        if (data.validationEvent) {
          field[data.validationEvent] = _.bind(
            _.debounce(this.handleFieldValidation, 100),
            null,
            _,
            key
          )
        }
        field.onChange = _.bind(this.handleFieldChange, null, _, key)
        field.onReset = _.bind(function () {
          return this.onChange(fieldValues[this._id])
        }, field)
        // Map values to fields (see example for setup)
        // if not data.value or forceReset
        if ((fieldValues != null ? fieldValues[key] : undefined) != null) {
          field.value = _.clone(fieldValues[key])
        } else {
          field.value = data.defaultValue != null ? data.defaultValue : null
        }
        // Assign field data
        fields[key] = field
      }
      for (key in fields) {
        field = fields[key]
        field.required = this.isFieldRequired(fields, field)
      }
      return fields
    }

    // Allow for fieldsData for being an object or function
    getFieldsData = (props) => {
      if (_.isFunction(_fieldsData)) {
        return _fieldsData(props)
      }
      return _fieldsData
    }

    isFieldRequired = (fieldsData, field) => {
      if (_.isFunction(field.requiredIf)) {
        return field.requiredIf(fieldsData, field)
      } else if (field.required != null) {
        return field.required
      } else {
        // defaults to true
        return !field.optional
      }
    }

    // Run on field change
    handleFieldChange = (value, key) => {
      const { fieldsData } = this.state
      const field = fieldsData[key]
      // Update value
      field.value = value
      // Run validation if error or success is visible
      // or if no validation event specified.
      if (field.showError || field.showSuccess || !field.validationEvent) {
        this.handleFieldValidation(value, key)
      }
      if (field.pristine) {
        field.pristine = false
      }
      this.setState({ fieldsData })
      this.setFormDirty()
      return typeof this.props.onChange === "function"
        ? this.props.onChange(this.state.fieldsData)
        : undefined
    }

    // Run on field validation event
    // onChange (default), onBlur, or onFocus
    handleFieldValidation = (value, key) => {
      const { fieldsData } = this.state
      let field = fieldsData[key]
      // Don't run validation if validationThreshold exists and hasn't been met
      if (field.validationThreshold && !field.showError && !field.showSuccess) {
        if (
          (field.value != null ? field.value.length : undefined) <
          field.validationThreshold
        ) {
          return
        }
      }
      // Assume loading on validation
      // Note: showLoading is never set if validations resolve immediately
      field.showLoading = true
      // Recheck if field is required before running validations.
      field.required = this.isFieldRequired(fieldsData, field)
      // Validate field resolves if all validations pass and rejects the first validation that fails.
      const promise = this.validateField(field, value)
      promise
        .then(() => {
          if (this._isUnmounting) {
            return
          } // hack!
          // Must reassign field since async validation can have stale field object
          field = fieldsData[key]
          field.errorMessage = null
          field.showError = false
          field.showSuccess = true
          field.showLoading = false
          this.setState({ fieldsData })
          return this.refreshFormValidState()
        })
        .catch((e) => {
          if (this._isUnmounting) {
            return
          } // hack!
          if (!(e != null ? e.message : undefined)) {
            throw new Error("Invalid promise rejection. Return an Error obj.")
          }
          field = fieldsData[key]
          field.errorMessage = e.message
          field.showError = true
          field.showSuccess = false
          field.showLoading = false
          this.setState({ fieldsData })
          return this.refreshFormValidState()
        })

      // If promises don't resolve immediately show loading state
      if (promise.isAsync) {
        return this.setState({ fieldsData })
      }
    }

    // For validating a field. Defaulting fieldsData to be from state
    // but allowing for passing in fieldsData if @state not created yet
    validateField = (field, value, fieldsData) => {
      if (fieldsData == null) {
        ;({ fieldsData } = this.state)
      }
      const errors = []
      let isAsync = false
      const validations = _.clone(field.validations) || []
      // if field is required add validation rule
      if (field.required) {
        validations.unshift(validate.isRequired)
      } else if (value == null || value === "") {
        return Promise.resolve()
      }
      // Check field validity if field validations
      if (validations) {
        // Get validate rule from passed function
        for (let validate of Array.from(validations)) {
          let error = validate(fieldsData, value)
          let shouldBreak = false
          if (error) {
            if (_.isFunction(error.then)) {
              isAsync = true
            }
            // Error can be a string or a promise
            if (_.isString(error)) {
              // Convert string error into promise and reject immediately
              error = Promise.reject(new Error(error))
              shouldBreak = true
            }
            // TODO: allow for a React element?
            // Error should be a promise at this point
            if (!_.isFunction(error.then)) {
              throw new Error(
                "Invalid return value from validation function. Return a promise or a string."
              )
            }
            errors.push(error)
            // No need to check for more errors if error has
            // already returned
            if (shouldBreak) {
              break
            }
          }
        }
      }
      // Resolves if ALL promises in the array resolve.
      // Rejects if ANY promise in the array rejects.
      const all = Promise.all(errors)
      all.isAsync = isAsync
      return all
    }

    validateAllFields = () => {
      return (() => {
        const result = []
        for (let key in this.state.fieldsData) {
          const field = this.state.fieldsData[key]
          result.push(this.handleFieldValidation(field.value, key))
        }
        return result
      })()
    }

    // Runs after field validation checks
    refreshFormValidState = () => {
      const { fieldsData } = this.state
      const fields = _.keys(fieldsData)
      // Checks each field for error and value
      const allIsValid = fields.every(function (field) {
        field = fieldsData[field]
        const isValid = field.showError === false
        const hasValue = field.required
          ? field.value != null && field.value !== ""
          : true
        // returns true if every field passes check
        return hasValue && isValid
      })
      if (allIsValid) {
        this.setFormValid()
      } else {
        this.setFormInvalid()
      }
      return allIsValid
    }

    setFormValid = () => {
      this.setState({
        isValid: true
      })
      return typeof this.props.onValid === "function"
        ? this.props.onValid(this.state.fieldsData)
        : undefined
    }

    setFormInvalid = () => {
      this.setState({
        isValid: false
      })
      return typeof this.props.onInvalid === "function"
        ? this.props.onInvalid(this.state.fieldsData)
        : undefined
    }

    setFormPristine = () => {
      this.setState({
        isPristine: true
      })
      return typeof this.props.onPristine === "function"
        ? this.props.onPristine(this.state.fieldsData)
        : undefined
    }

    setFormDirty = () => {
      const { fieldsData } = this.state
      const dirtyKeys = _.keys(fieldsData).filter(
        (field) => fieldsData[field].pristine === false
      )
      this.setState({
        isPristine: false
      })
      return typeof this.props.onDirty === "function"
        ? this.props.onDirty(this.state.fieldsData, dirtyKeys)
        : undefined
    }

    // Handles returned promise from onSubmit
    setFormSubmitting = (submission) => {
      const promise = submission.then
        ? submission
        : submission.promise && submission.promise.then
        ? submission.promise
        : null

      if (promise) {
        this.setState({
          submitFailure: null,
          submitSuccess: null,
          submitting: true
        })

        return promise
          .then(() => {
            if (this._isUnmounting) {
              return
            } // hack!
            this.setState({
              isPristine: true,
              submitting: false,
              submitSuccess: true
            })
            return typeof this.props.onPristine === "function"
              ? this.props.onPristine(this.state.fieldsData)
              : undefined
          })
          .catch((err) => {
            console.log("caught an error: ", err)
            if (this._isUnmounting) {
              return
            } // hack!
            return this.setState({
              submitting: false,
              submitFailure: err
            })
          })
      } else {
        return console.warn("form submission did not return a promise")
      }
    }

    resetForm = () => {
      const fieldsData = this.getInitialFieldsState(this.props.fieldValues)
      this.setState({ fieldsData })
      this.setFormPristine()
      if (this.props.initialValid) {
        this.setFormValid()
      } else {
        this.setFormInvalid()
      }
      return typeof this.props.onReset === "function"
        ? this.props.onReset(this.state.fieldsData)
        : undefined
    }

    // Run on form submission
    submitForm = (e) => {
      if (e != null) {
        e.persist()
        e.preventDefault()
      }
      this.validateAllFields()
      const allIsValid = this.refreshFormValidState()
      if (allIsValid) {
        const submission =
          typeof this.props.onSubmit === "function"
            ? this.props.onSubmit(this.state.fieldsData, this.resetForm)
            : undefined
        if (submission) {
          return this.setFormSubmitting(submission)
        } else {
          return console.warn("nothing returned from form submission")
        }
      } else {
        return console.warn("form is invalid")
      }
    }

    render() {
      const { onSubmit, ...passProps } = this.props
      return (
        <FormComponent
          {...Object.assign({}, passProps, this.state, {
            handleReset: this.resetForm,
            handleSubmit: this.submitForm
          })}
        />
      )
    }
  }
}

// returns FormContainer wrapping FormComponent
