import gql from "graphql-tag"

export default gql`
  mutation BatchCreateMentorGroupMembership(
    $user_ids: [Int!]
    $group: Int!
    $role: MentorGroupMembershipRole!
  ) {
    batchCreateMentorGroupMembership(
      user_ids: $user_ids
      group: $group
      role: $role
    ) {
      success
      group_memberships_created_count
    }
  }
`
