import BannerSliderContainer from "@pathwright/web/src/modules/banner/display/BannerSliderContainer"
import { withUserCommunity } from "@pathwright/web/src/modules/user/UserCommunityContext"
import { ConnectStores } from "lib/core/store"
import "./FeatureBanners.css"

const FeatureBanners = (props) => <BannerSliderContainer {...props} />

FeatureBanners.displayName = "FeatureBanners"

const mapStateToProps = function (state, ownProps) {
  const { communityGroupWithActiveSubscription } = ownProps
  const { subscribe_state } = state.subscribeStore.state
  const userSubscribed =
    subscribe_state === "active" || !!communityGroupWithActiveSubscription

  return _.assign(
    {
      userSubscribed
    },
    ownProps
  )
}

const connectToStores = [{ store: "subscribe", watch: ["subscribe_state"] }]

export default withUserCommunity(
  ConnectStores(FeatureBanners, connectToStores, {}, mapStateToProps)
)
