import { Box, Button, Heading, Link, Text, VStack } from "@chakra-ui/react"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import { useCertificateContext } from "../context/CertificateContext"
import CertificateState from "../context/CertificateState"
import { useUpdateResourceCertificateEnabled } from "../gating/DisabledResourceCertificateGate"
import CertificateDrawer from "../panel/CertificateDrawer"

export const CertificateCreatePrompt = () => {
  const { school } = usePathwrightContext()
  const { hasLegacyCertificate, resource } = useCertificateContext()

  const [enableResourceCertificate, enableState] =
    useUpdateResourceCertificateEnabled({
      enable: true
    })
  const [disableResourceCertificate, disableState] =
    useUpdateResourceCertificateEnabled({
      enable: false
    })

  return (
    <VStack spacing={4} padding={4} textAlign="center">
      <Pathicon icon="edit" style={{ fontSize: "24px" }} />
      <Heading as="h3" size="md">
        Offer Completion Certificates
      </Heading>
      <Text textAlign="center">
        Reward and document learner achievement by providing a custom-designed
        certificate of completion they can download or print when they reach the
        end of a Path.{" "}
        <Link
          textDecoration="underline"
          textUnderlineOffset={2}
          href="http://help.pathwright.com/en/articles/9211185-provide-a-certificate-of-completion"
          isExternal
        >
          Learn more about certificates.
        </Link>
      </Text>
      {hasLegacyCertificate && (
        <>
          <Heading as="h4" size="sm" mt={8}>
            Legacy Certificate
          </Heading>
          <Text textAlign="center">
            {school.name} currently uses the legacy certificate feature. You may
            create a new certifiacte, edit, and preview it while your learners
            continue to have access to the legacy certificate. Once you are
            ready,{" "}
            {window.Intercom ? (
              <Link
                textDecoration="underline"
                textUnderlineOffset={2}
                onClick={() => window.Intercom("showNewMessage")}
              >
                contact us
              </Link>
            ) : (
              <Link
                textDecoration="underline"
                textUnderlineOffset={2}
                href="mailto:hello@pathwright.com"
                isExternal
              >
                contact us
              </Link>
            )}{" "}
            to enable your new certificate.
          </Text>
          {resource &&
            (resource.has_certificate ? (
              <Button
                colorScheme="brand"
                variant="solid"
                size="md"
                onClick={() => disableResourceCertificate(resource)}
                isLoading={disableState.loading}
              >
                Disable Certificate
              </Button>
            ) : (
              <Button
                colorScheme="brand"
                variant="solid"
                size="md"
                onClick={() => enableResourceCertificate(resource)}
                isLoading={enableState.loading}
              >
                Enable Certificate
              </Button>
            ))}
        </>
      )}
    </VStack>
  )
}

const CertificateCreate = () => {
  return (
    <Box
      bg="whitesmoke"
      width="min(100vw, 792px)"
      minHeight="min(100vh, 612px)"
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="center"
      borderRadius="20px"
      m={2}
      textAlign="center"
    >
      <Box maxW="400px">
        <CertificateCreatePrompt />
      </Box>
    </Box>
  )
}

const CertificateCreator = () => (
  <CertificateState>
    <CertificateCreate />
    <CertificateDrawer />
  </CertificateState>
)

CertificateCreator.displayName = "CertificateCreator"

export default CertificateCreator
