import Button from "@pathwright/ui/src/components/button/Button"
import Collection from "@pathwright/ui/src/components/library/Collection"
import withTranslate from "@pathwright/ui/src/components/lng/withTranslate"
import { Component } from "react"
import styled from "styled-components"

const Container = styled.div`
  position: relative;
`

const Grid = styled.ul`
  list-style: none;
  margin: -10px -10px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  li {
    width: 50%;
    padding: 10px;
    @media (max-width: 550px) {
      width: 100%;
    }
  }
`
const More = styled.div`
  height: 50px;
`

class LibraryCollections extends Component {
  loadMore = async () => {
    if (this.loadingMore) {
      return
    }

    try {
      this.loadingMore = true
      await this.props.loadMore()
    } catch (err) {
      console.error(err)
    } finally {
      this.loadingMore = false
    }
  }

  render() {
    const {
      collections,
      hasMore,
      loading,
      getCollectionTo,
      getCollectionSettingsTo,
      tca
    } = this.props

    if (collections && collections.length > 0) {
      return (
        <Container>
          <Grid>
            {collections.map((collection) => (
              <li key={collection.id}>
                <Collection
                  collection={collection}
                  to={getCollectionTo(collection)}
                  settingsTo={getCollectionSettingsTo(collection)}
                />
              </li>
            ))}
          </Grid>
          {hasMore && (
            <More>
              <Button
                onClick={this.loadMore}
                disabled={loading}
                styleType="empty"
                rounded={false}
                stretch
              >
                {tca("library.browse.view_more_collections")}
              </Button>
            </More>
          )}
        </Container>
      )
    }

    return null
  }
}

LibraryCollections.displayName = "LibraryCollections"

export default withTranslate(LibraryCollections)
