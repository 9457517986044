import {
  Box,
  Button,
  HStack,
  IconButton,
  Link,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
  useDisclosure
} from "@chakra-ui/react"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import classnames from "classnames"
import { forwardRef } from "react"

const DragControl = ({ onToggleDragEnabled, isDragging }) => (
  <Tooltip
    label={isDragging ? "" : "Move"}
    // NOTE: isDisabled doesn't seem to work in conjunction with openDelay
    // isDisabled={isDragging}
    openDelay={750}
  >
    <IconButton
      colorScheme="gray"
      variant="ghost"
      size="xxs"
      aria-label="Move text box"
      onMouseDown={() => onToggleDragEnabled(true)}
      onMouseUp={() => onToggleDragEnabled(false)}
      icon={<Pathicon icon="arrows-omni" />}
    />
  </Tooltip>
)

const CopyControl = ({ onCopy }) => (
  <Tooltip label="Copy" openDelay={750}>
    <IconButton
      colorScheme="gray"
      variant="ghost"
      size="xxs"
      aria-label="Copy text box"
      onClick={() => onCopy()}
      icon={<Pathicon icon="copy" />}
    />
  </Tooltip>
)

const DeleteControl = forwardRef(({ onDelete, ...rest }, ref) => (
  <Tooltip label="Delete" openDelay={750}>
    <IconButton
      ref={ref}
      colorScheme="gray"
      variant="ghost"
      size="xxs"
      aria-label="Delete text box"
      icon={<Pathicon icon="trash" />}
      onClick={() => onDelete()}
      {...rest}
    />
  </Tooltip>
))

const TipControl = () => (
  <Tooltip label="Tips for Certificate Creator text blocks">
    <IconButton
      as={Link}
      colorScheme="gray"
      variant="ghost"
      size="xxs"
      aria-label="Tips for Certificate Creator text blocks"
      icon={<Pathicon icon="info-circle" />}
      target="_blank"
      href="http://help.pathwright.com/en/articles/9211185-provide-a-certificate-of-completion"
    />
  </Tooltip>
)

const DeleteConrolConfirmation = ({ onDelete }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Popover trigger="click" isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <PopoverTrigger>
        <DeleteControl />
      </PopoverTrigger>
      <PopoverContent p="10px" bg="rgba(0, 0, 0, 0.8)">
        <PopoverBody>
          <HStack
            spacing={2}
            alignItems="center"
            justifyContent="center"
            w="100%"
            h="100%"
          >
            <Button
              variant="solid"
              colorScheme="red"
              size="sm"
              onClick={() => {
                onClose()
                // Hack, prevent showing popover on different text object that might
                // replace this text object's index.
                setTimeout(() => {
                  onDelete()
                }, 1)
              }}
            >
              Confirm Delete
            </Button>
            <Button
              variant="solid"
              colorScheme="whiteAlpha"
              size="sm"
              className="Cancel"
              onClick={onClose}
            >
              Cancel
            </Button>
          </HStack>
          <PopoverCloseButton>Cancel</PopoverCloseButton>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

const ObjectControls = ({
  className,
  isFocused,
  isDragging,
  onCopy,
  onDelete,
  onToggleDragEnabled
}) => {
  return (
    <Box
      className={classnames("ObjectControls", className)}
      position="absolute"
      right="0px"
      top="0px"
      zIndex="10"
      opacity={isFocused ? 1 : 0}
      sx={{
        [`:has([aria-expanded="true"])`]: {
          opacity: 1
        }
      }}
    >
      <HStack
        spacing={2}
        position="absolute"
        right="5px"
        top="50%"
        transform="translateY(-50%)"
        bg="#efefef"
        borderRadius="20px"
        p="5px 10px"
        m="0px 2px"
        w="auto"
        pointerEvents="all"
        fontSize="0.9em"
        transition="opacity 0.1s ease-in"
      >
        <DragControl
          onToggleDragEnabled={onToggleDragEnabled}
          isDragging={isDragging}
        />
        <CopyControl onCopy={onCopy} />
        {!!onDelete && (
          <DeleteConrolConfirmation
            onDelete={() => {
              onDelete()
            }}
          />
        )}
        <TipControl />
      </HStack>
    </Box>
  )
}

ObjectControls.displayName = "ObjectControls"

export default ObjectControls
