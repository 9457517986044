import gql from "graphql-tag"

export default gql`
  fragment Publisher on Publisher {
    id
    name
    about
    website
    image(width: 150, height: 150)
  }
`
