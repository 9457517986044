import gql from "graphql-tag"

export default gql`
  fragment Author on Author {
    id
    bio
    image
    order
    full_name
  }
`
