import { graphql } from "@apollo/client/react/hoc"
import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import Card from "@pathwright/ui/src/components/card/Card"
import CardBlock from "@pathwright/ui/src/components/card/CardBlock"
import CardLink from "@pathwright/ui/src/components/card/CardLink"
import ToggleInput from "@pathwright/ui/src/components/form/form-toggle/ToggleInput"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import Text from "@pathwright/ui/src/components/ui/Text"
import compose from "lodash/flowRight"
import { Field, Form, Submit } from "react-connect-form-forked"
import ACCOUNT_QUERY from "../../account/graphql/account-query"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import { getSchoolMutation } from "../../school/graphql/update-school-mutation"
import { LEARNER_COUNTING_METHOD_ACCESS } from "../constants"

const ManageBillingAccess = ({
  card,
  error,
  loading,
  account,
  updateSchool
}) => {
  const { school } = usePathwrightContext()
  return loading ? (
    <LoadingCircle />
  ) : (
    <Card card={card} title="Access Control">
      {!account.billing_subscription ||
      account.billing_subscription.plan.learner_counting_method !==
        LEARNER_COUNTING_METHOD_ACCESS ? (
        <CardBlock>
          <Text.Body>
            {school.name} is not subscribred to a plan based on learner access.
          </Text.Body>
        </CardBlock>
      ) : (
        <Form
          initialValue={{
            grant_access_upon_authentication:
              school.grant_access_upon_authentication
          }}
          onSubmit={updateSchool}
        >
          <CardBlock>
            <Field
              name="grant_access_upon_authentication"
              label="Automatically grant access on sign-in"
              helperText="Enable members who have access disabled to gain access automatically on sign-in."
              type="custom"
              component={ToggleInput}
              alignRight
              labelWidth={300}
            />
          </CardBlock>
          <Submit styleType="primary" component={SubmitButton}>
            Save
          </Submit>
        </Form>
      )}
      <CardLink
        icon="minus-circle"
        label="Remove access based on activity"
        meta="Remove access from members who haven't been active for a while."
        to={`${card.path}remove/`}
      />
    </Card>
  )
}

ManageBillingAccess.displayName = "ManageBillingAccess"

export default compose(
  graphql(ACCOUNT_QUERY, {
    props: ({ data }) => {
      const { error, loading, account } = data
      return {
        error,
        loading,
        account
      }
    }
  }),
  graphql(getSchoolMutation(["grant_access_upon_authentication"]), {
    props: ({ mutate, ownProps }) => ({
      updateSchool: (changes) =>
        mutate({
          variables: {
            ...changes
          }
        })
    })
  })
)(ManageBillingAccess)
