import isObject from "lodash/isObject"

export const isAbsoluteUrl = (to) => {
  let pathname = ""

  if (to) {
    if (typeof to === "string") pathname = to
    if (isObject(to)) pathname = to.pathname
  }

  if (pathname) return pathname.indexOf("://") > -1
  return false
}

export const isLeftClickEvent = (event) => event.button === 0

export const isModifiedEvent = (event) => {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey)
}

export const isEmptyObject = (obj) => {
  return obj ? Object.keys(obj).length === 0 : false
}

export const getUrlFromRoute = (route) => {
  if (!route) return

  if (typeof route === "string") {
    route = { pathname: route }
  }

  // avoid joining nullish pathname with search string
  if (!route.pathname) return

  let search = new URLSearchParams(route.query || route.search).toString()
  if (search) search = `?${search}`

  return route.pathname + search
}

export const getStateFromRoute = (route) => {
  if (!route) return null
  if (typeof route !== "object") return null
  return route.state
}
