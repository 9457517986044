import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import { INVITATION_STATUS_ALL } from "invitation/constants"
import { observer } from "mobx-react"
import invitationStore from "../store/invitation-store"
import "./InvitationBlank.css"

const InvitationBlank = () => {
  const { status, context } = invitationStore
  return (
    <BlankSlate
      className="InvitationBlank"
      icons={false}
      body={
        status === INVITATION_STATUS_ALL
          ? "No invitations yet"
          : `No ${status} invitations`
      }
    />
  )
}

InvitationBlank.displayName = "InvitationBlank"

export default observer(InvitationBlank)
