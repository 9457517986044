import { Button } from "@chakra-ui/react"
import { ChevronRightIcon } from "@pathwright/pathicons"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import gql from "graphql-tag"
import { Link as ReactRouterLink } from "react-router-dom"
import { useShareDataContext } from "../shared/ShareDataContextProvider"
import Limit from "./Limit"

const tPrefix = "share_card.add_tab"

const COHORT_SHARE_LIMIT_QUERY = gql`
  query CohortShareLimitQuery($cohortId: Int!) {
    cohort: group(id: $cohortId) {
      id
      resource_license {
        id
      }
    }
  }
`

const useCohortShareLimit = ({ cohortId }) => {
  const query = useQuery(COHORT_SHARE_LIMIT_QUERY, {
    variables: { cohortId }
  })

  return query.data?.cohort
}

const CohortShareLimit = () => {
  const { t } = useTranslate()
  const { context, permissions } = useShareDataContext()
  const cohort = useCohortShareLimit({ cohortId: context.typeId })

  if (!cohort || permissions.add_limit === null) return null

  if (cohort.resource_license) {
    if (permissions.add_limit === 0) {
      return (
        <Limit
          text={t(`${tPrefix}.No pre-purchased seats remaining`)}
          tooltip={t(
            `${tPrefix}.Once all pre-purchased seats are filled, additional members may purchase their own seats.`
          )}
        />
      )
    } else {
      return (
        <Limit
          text={t(`${tPrefix}.{{ count }} pre-purchased seat remaining`, {
            defaultValue_plural: `${tPrefix}.{{ count }} pre-purchased seats remaining`,
            count: permissions.add_limit || 2
          })}
          tooltip={t(
            `${tPrefix}.Once all pre-purchased seats are filled, additional members may purchase their own seats.`
          )}
        />
      )
    }
  }

  return (
    <Limit
      text={t(`${tPrefix}.{{ count }} seat remaining`, {
        defaultValue_plural: `${tPrefix}.{{ count }} seats remaining`,
        count: permissions.add_limit
      })}
      tooltip={
        <>
          {t(
            `${tPrefix}.Once all seats are filled no one will be able to join.`
          )}{" "}
          {permissions.allow_manage && (
            <>
              <Button
                as={ReactRouterLink}
                variant="link"
                to={permissions.manage_url}
                color="inherit"
                fontSize="1em"
                fontWeight="normal"
                display="inline"
                whiteSpace="normal"
              >
                {t(
                  `${tPrefix}.Update cohort settings to change the seat limit.`
                )}
              </Button>{" "}
              <ChevronRightIcon
                size="1em"
                style={{
                  display: "inline",
                  marginBottom: "-2px"
                }}
              />
            </>
          )}
        </>
      }
    />
  )
}

export default CohortShareLimit
