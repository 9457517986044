import { Button, Spinner } from "@chakra-ui/react"
import { MediaGraphQLSyncer, MediaProvider } from "@pathwright/media-client"
import { Suspense, lazy, useState } from "react"

const MediaManager = lazy(() =>
  import("./MediaManager" /* webpackChunkName: "MediaManager" */)
)

// type MediaManagerSyncerWrapperProps = Pick<
//   MediaManagerContainerProps,
//   "Syncer" | "contextKey" | "mediaStoragePath" | "userID"
// > & {
//   children: ReactElement
// }

const MediaManagerSyncerWrapper = ({
  Syncer,
  contextKey,
  mediaStoragePath,
  userID,
  children
}) => {
  const SyncProvider = Syncer ? Syncer : MediaGraphQLSyncer

  return (
    <SyncProvider
      mediaStoragePath={mediaStoragePath}
      contextKey={contextKey}
      userID={userID}
    >
      {children}
    </SyncProvider>
  )
}

const MediaManagerContainer = (props) => {
  const [isOpen, setIsOpen] = useState(false)

  const { buttonText, renderButton } = props

  const openMediaManager = () => setIsOpen(true)

  const buttonProps = {
    onClick: openMediaManager,
    mt: "20px"
  }

  const renderDefaultButton = () => {
    return (
      <Button
        className="EditButton MediaManager__replace-button"
        {...buttonProps}
      >
        {buttonText}
      </Button>
    )
  }

  // Preferring children, then renderButton, then renderDefaultButton.
  const launcherUI = renderButton
    ? renderButton(buttonProps)
    : renderDefaultButton()

  return (
    <>
      {launcherUI}
      {!!isOpen ? (
        <MediaManagerSyncerWrapper
          Syncer={props.Syncer}
          contextKey={props.contextKey}
          mediaStoragePath={props.mediaStoragePath}
          userID={props.userID}
        >
          <MediaProvider {...props} onClose={() => setIsOpen(false)}>
            <Suspense fallback={<>{props.renderLoading()}</>}>
              <MediaManager />
            </Suspense>
          </MediaProvider>
        </MediaManagerSyncerWrapper>
      ) : null}
    </>
  )
}

MediaManagerContainer.defaultProps = {
  buttonText: "Add Media",
  mediaType: "image",
  customEmptyMessage: "",
  className: "",
  renderLoading: () => <Spinner />
}

export default MediaManagerContainer
