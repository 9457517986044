import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import debounce from "lodash/debounce"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useScopedBackgroundTaskQuery } from "../../bg-task/BackgroundTaskContext"
import { usePaginator } from "../../utils/apollo"
import PEOPLE_AND_GROUP_CONNECTIONS_QUERY from "../graphql/people-and-group-connections-query"

// Wanted to lead the debounce, but react-use doesn't support that in their useDebounce hook.
const useDebounce = (search) => {
  const [debouncedSearch, setDebouncedSearch] = useState()
  const debouncedSetDebouncedSearch = useMemo(
    () =>
      debounce(setDebouncedSearch, 300, {
        leading: true
      }),
    []
  )

  useEffect(() => {
    debouncedSetDebouncedSearch(search)
  }, [search])

  return debouncedSearch
}

const usePeopleAndGroupConnections = ({
  first,
  context,
  roleInType,
  roleInTypeId,
  search,
  ...rest
}) => {
  // Context may be undefined initially, so defaulting to null should
  // ensure we don't run an extra query when the value changes from undefined to null.
  context = context || null

  // Force search term into an array. This could be a list of emails, or even names.
  const searchList = useMemo(
    () =>
      search
        ? search
            .split(",")
            .map((str) => str.trim())
            .filter(Boolean)
        : [],
    [search]
  )
  search = searchList
  const prevQueryRef = useRef()
  const debouncedSearch = useDebounce(search)

  // Use a query that only hits the cache to determine what search value should be used
  // in the query that actually fetches over network. This means that we can avoid waiting
  // on the debounced value for queries that have already been cached and simply provide
  // the network-fetching query with the latest search value. Speeds up UI feedback. Better UX.
  const cacheQuery = useQuery(PEOPLE_AND_GROUP_CONNECTIONS_QUERY, {
    variables: {
      first,
      context,
      searchTerm: search?.length ? search : "",
      roleInContext: {
        type: roleInType,
        type_id: roleInTypeId,
        exclude: !context && !search?.length
      }
    },
    fetchPolicy: "cache-only",
    ...rest
  })

  const queriedSearch = cacheQuery.data ? search : debouncedSearch

  // Only pay for what you need (or rather, use results from previous searchTerm when no more pages exist for that query).
  // This delegates searching solely to the client (it's faster and more efficient).
  const clientSearch =
    !prevQueryRef.current?.data?.peopleAndGroupConnections.pageInfo
      ?.hasNextPage &&
    // Since we're now excluding "user" items that have a role in the "role_in_context" unless when searching
    // we need to defer performing a client search until we've actually searched something, ensuring we don't
    // inadvertantly exclude queryiing for items that have been excluded by the "non-searched" query.
    prevQueryRef?.current?.variables?.searchTerm?.length
      ? prevQueryRef.current.variables.searchTerm
      : ""

  // If searching and clientSearch matches beginning of debouncedSearch, prefer the clientSearch over the debouncedSearch.
  const searchTerm =
    clientSearch?.length &&
    queriedSearch?.length &&
    queriedSearch.every((term, i) => term.startsWith(clientSearch[i]))
      ? clientSearch
      : queriedSearch

  const query = useScopedBackgroundTaskQuery(
    PEOPLE_AND_GROUP_CONNECTIONS_QUERY,
    {
      variables: {
        first,
        context,
        searchTerm: searchTerm?.length ? searchTerm : "",
        roleInContext: {
          type: roleInType,
          type_id: roleInTypeId,
          exclude: !context && !searchTerm?.length
        }
      },
      notifyOnNetworkStatusChange: true,
      ...rest
    }
  )
  const items = useMemo(
    () =>
      query.data
        ? query.data.peopleAndGroupConnections.edges
            .map((e) => e.node)
            // Filter out the current item (should this be done GQL side?).
            .filter(
              (item) => !(item.type === roleInType && item.id === roleInTypeId)
            )
        : [],
    [query.data]
  )
  const { error } = query

  // Combining the query loading state with the faux loading state determined
  // by whether the query results for the current search value are cached, only
  // when not performing a client search.
  const loading = query.loading || (!clientSearch && !cacheQuery.data)

  const { loadMore, loadingMore, hasMore } = usePaginator({
    data: query,
    path: "peopleAndGroupConnections"
  })

  const handleLoadMore = useCallback(() => {
    if (!loading && !loadingMore && hasMore) loadMore()
  }, [loadMore])

  prevQueryRef.current = query

  return {
    items,
    loading,
    loadMore: handleLoadMore,
    loadingMore,
    hasMore,
    error
  }
}

export default usePeopleAndGroupConnections
