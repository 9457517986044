import jwtDecode from "jwt-decode"
import { z } from "zod"
import { SpaceContextType } from "../../../space/api/context"
import loadSpace from "../../../space/api/loader"
import { AuthContextType, loadAuthContext } from "../../api/loader"
import getInviteTokenParam from "../../utils/getInviteTokenParam"

type ShouldPromptUserToJoinSpaceProps = {
  authContext: AuthContextType
  spaceContext: SpaceContextType
}

// Only consider the flow completed after authentication if the user is already
// a member, or the school doesn't have a consent description and the user doesn't have an invite token.
// Only pompting the user to join the school in certain cases.
export const getShouldPromptUserToJoinSpace = ({
  authContext,
  spaceContext
}: ShouldPromptUserToJoinSpaceProps): boolean => {
  const { consentDescription, inviteToken, allowSignup, createMembership } =
    authContext
  const { space, user } = spaceContext
  // NOTE: it's possible the invite token may not be a JWT, but still be valid.
  const getHasSpaceInviteToken = () => {
    if (inviteToken) {
      // Checking if space invite type.
      const schema = z.object({
        invite_type: z.literal(2),
        school_id: z.literal(space?.id)
      })

      try {
        if (schema.safeParse(jwtDecode(inviteToken)).success) {
          // A valid Space token is sufficient for permitting joining.
          return true
        }
      } catch {
        // Assuming the token is not a JWT, but may still be valid.
        return true
      }
    }
    return false
  }

  return Boolean(
    !user?.membership &&
      ((consentDescription && allowSignup && createMembership !== false) ||
        getHasSpaceInviteToken())
  )
}

export const loadShouldPromptUserToJoinSpace = async (): Promise<boolean> => {
  const authContext = await loadAuthContext(
    getInviteTokenParam(window.location.href)
  )
  const spaceContext = await loadSpace()
  const shouldPromptUserToJoinSpace = await getShouldPromptUserToJoinSpace({
    authContext,
    spaceContext
  })
  return shouldPromptUserToJoinSpace
}

// NOTE: no default loader required.
