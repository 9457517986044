export default {
  type: "Gallery",
  icon: "carousel", // TODO: pathicon carousel => image-gallery
  helpLink: "http://help.pathwright.com/en/articles/2712009-image-blocks",
  // "https://help.pathwright.com/create-a-course/adding-content-with-blocks/gallery-image-blocks-new",
  menu: {
    category: "image",
    order: 2
  },
  layouts: {
    carousel: {
      key: "carousel",
      label: "Carousel",
      data: {
        images: [
          {
            src: `https://cdn.filestackcontent.com/GdapCCtuQQGyrFn2Bg23`
          },
          {
            src: `https://cdn.filestackcontent.com/o8X9N7RSSba2WSkQqGeM`
          },
          {
            src: `https://cdn.filestackcontent.com/0OPafdfAR3qOjbYatvJd`
          }
        ]
      }
    },
    grid2: {
      key: "grid-2",
      label: "Two-column",
      data: {
        images: [
          {
            src: `https://cdn.filestackcontent.com/dJLZJ92yQPGOW4UCAtCh`
          },
          {
            src: `https://cdn.filestackcontent.com/4AocV15aRrux7hM5rmqT`
          }
        ]
      }
    },
    grid: {
      key: "grid",
      label: "Three-column",
      data: {
        images: [
          {
            src: `https://cdn.filestackcontent.com/iH8JUa3JTDivkdKVkZwj`
          },
          {
            src: `https://cdn.filestackcontent.com/OWzOoHNQ8WzIuPP6Nn8w`
          },
          {
            src: `https://cdn.filestackcontent.com/C6saz8t7S9KvswD8jQvV`
          }
        ]
      }
    }
  },
  schema: `
    type Gallery implements Block {
      id: ID!
      images: [Image]
    }
  `
}
