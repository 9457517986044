import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import { COHORT_MEMBERS_STATS_QUERY } from "./graphql"

const useCohortMembersStats = (context) => {
  const cohortMemberStatsQuery = useQuery(COHORT_MEMBERS_STATS_QUERY, {
    // TODO: add util for getting a context key by trying both a
    // camelcase and snakecase version of the expected key.
    variables: { id: context.cohort_id },
    skip: !context.cohort_id
  })

  const defaultValue = {
    total: 0,
    // Number of cohort members with discussion notifications enabled.
    send_discussion_notifications: 0
  }

  const cohortMemberStats =
    cohortMemberStatsQuery.data?.cohort?.member_stats || defaultValue

  return cohortMemberStats
}

export default useCohortMembersStats
