import "@pathwright/web/src/modules/lib/eventListener"
// TODO: do we still need this?
// import "babel-polyfill"
import "intersection-observer"
import { jwtDecode } from "jwt-decode"
import "lib/core/utils/translation-fix"
import Application from "./application"
import { bootstrapData } from "./bootstrap"
import "./public-path"
import "./vendor"

const startApp = async () => {
  require("./boot")
  require("./Routes")
}

const App = new Application({
  // for legacy native apps (are there any in existance?)
  mobile: {}
})

const Pathwright = App

Object.assign(window, { App, Pathwright })

const authToken = localStorage.getItem("auth_token")

const hasAuthMismatch =
  window.bootstrappedData &&
  window.bootstrappedData.session &&
  window.bootstrappedData.session.auth_token &&
  authToken

if (hasAuthMismatch)
  console.warn("BootstrappedData missing auth_token found in client. Noop.")

// The client must complete the sign-out process by clearing the stored auth_token.
// This happens when a user signs out of Pathwright via SSO. The backend then redirects
// the user back to this location to complete the sign-out process.
if (window.location.pathname === "/auth/sign-out/success/") {
  localStorage.removeItem("auth_token")
}

// Handling expired auth token by redirecting the user to /auth/sign-out/.
if (authToken) {
  try {
    const { exp } = jwtDecode(authToken)
    if (exp) {
      const isExpired = new Date(exp * 1000).getTime() < new Date().getTime()
      if (isExpired && !window.location.pathname.startsWith("/auth/sign-out")) {
        window.location = `${window.location.origin}/auth/sign-out/?next=${window.location.href}`
      }
    }
  } catch (error) {
    // noop for now
    console.log(`Failed to parse auth token ${authToken} due to error:`, error)
  }
}

bootstrapData().then(startApp)
