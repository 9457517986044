// TODO: make sure this doesn't add too much to the bundle size or we'll need to import dynimacally
import { i18n } from "@pathwright/ui/src/components/lng/I18nextProvider"
import "highlight.js/styles/github.css"

// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
let DiscussionDetailView
require("./styles/discussion-detail.css")

const User = require("auth/models/user").default
const ResponseListView = require("./response-list").default
const LoadingView = require("lib/loading/views/loading-view").default
const DiscussionForm = require("./discussion-form").default
const DiscussionResponseForm = require("./discussion-response-form").default
const DiscussionSubscription =
  require("discussion/models/discussion-subscription").default
const DiscussionResponseDelete = require("./discussion-response-delete").default

const DiscussionDetailTemplate = require("./templates/discussion-detail.html")

const reactUtils = require("lib/core/react/react-utils")
const ActionMenu = require("discussion/components/ActionMenu").default

export default DiscussionDetailView = (function () {
  DiscussionDetailView = class DiscussionDetailView extends (
    require("lib/static-shim").default(require("lib/core/layout").default)
  ) {
    static initClass() {
      this.prototype.className = "discussion-detail-view"

      this.prototype.regions = {
        responses: ".discussion-responses-region",
        response: ".discussion-response-region"
      }

      this.prototype.behaviors = {
        ConnectStore: {
          store: "discussion",
          events: {
            "change:response_id"() {
              const { discussion_id, response_id } =
                this.discussionStore.getState()
              const prev_response_id =
                this.discussionStore.previous("response_id")
              if (discussion_id && response_id !== prev_response_id) {
                return this.loadResponses()
              }
            },
            "change:new_responses_available"() {
              return this.$(".new-responses-prompt").toggle(
                this.discussionStore.get("new_responses_available")
              )
            }
          }
        },
        MathJax: {},
        DiscussionVote: {
          getVotePrompt() {
            if (this.model.get("user_has_voted")) {
              return i18n.t("discussion.detail.remove_vote")
            } else {
              return i18n.t("discussion.detail.upvote_question")
            }
          },
          onVoteToggled(voted) {
            this.options.onUpdateDiscussion &&
              this.options.onUpdateDiscussion(this.model)
          }
        },
        State: {
          getStateKey() {
            return `discussion-view-${this.model.id}`
          },
          viewProperty: "vstate"
        },
        ViewStates: {
          force_state_change: true,
          states: {
            view() {
              this.view.options.edit = false
              this.renderView()
              this.triggerMethod("show")
              return this.view.loadResponses()
            },
            edit() {
              this.view._closeMenu()
              this.response.hide()
              const form = new DiscussionForm({
                model: this.view.model,
                context_label: this.view.options.context_label
              })
              this.listenTo(
                form,
                "discussion:updated cancel:discussion",
                function () {
                  this.switchState("view")
                  this.view.triggerMethod("discussion:updated", this.view.model)
                }
              )
              this.listenTo(form, "discussion:updated", function (discussion) {
                this.view.options.onUpdateDiscussion &&
                  this.view.options.onUpdateDiscussion(discussion.toJSON())
              })
              return this.replaceElement("header", form)
            },
            delete() {
              this.view._closeMenu()
              const deletePrompt = new DiscussionResponseDelete({
                model: this.view.model
              })
              this.appendView(deletePrompt)
              this.listenTo(
                deletePrompt,
                "cancel:delete:response",
                function () {
                  return this.switchState("view")
                }
              )
              return this.listenTo(
                deletePrompt,
                "delete:response",
                function () {
                  this.view.model.destroy()

                  return this.view.triggerMethod(
                    "discussion:deleted",
                    this.view.model
                  )
                }
              )
            }
          }
        },

        Bind: {
          getBindEl() {
            return this.$(".discussion-actions")
          }
        }
      }

      this.prototype.ui = {
        subscribe: "[data-action='subscribe']",
        discussionActions: ".discussion-admin-menu-container",
        discussionContextLink: ".discussion-context-link",
        loadNewResponses: "#load-new-responses"
      }

      this.prototype.events = {
        "click @ui.discussionContextLink": "onSelectContextLink",
        "click @ui.loadNewResponses": "reloadResponses"
      }

      this.prototype.triggers = { "click @ui.subscribe": "toggle:subscribe" }
    }

    initialize() {
      this.listenTo(App.vent, "discussion:show", (id) => {
        return this.$el.scrollTop(0)
      })
      return (window.detail = this)
    }

    getTemplate() {
      return DiscussionDetailTemplate
    }

    onRender() {
      if (!App.request("ui:is:touch")) {
        this.$("[data-toggle='tooltip']").tooltip()
      }
      this.$el.toggleClass(
        "discussion-question",
        this.model.get("is_discussion_question")
      )

      if (
        window.App.session.get("is_authenticated") &&
        this.model.get("discussion_type") >= 15
      ) {
        this.trigger("swith:state", "edit")
        return
      }

      if (this.model.get("user_permissions").can_respond) {
        // Show response form
        const responseForm = new DiscussionResponseForm({
          discussion: this.model
        })
        this.listenTo(responseForm, "model:save", this._onResponseAdded)
        // Defer for binding purposes. If not defered, the responseForm's
        // binding is overridden by this view's binding.
        // TODO: could this be fixed in the bind behavior?
        _.defer(() => {
          if (
            window.App.session.get("is_authenticated") &&
            this.model.get("discussion_type") < 15
          ) {
            return this.response.show(responseForm)
          }
        })
      }

      if (this._showActions()) {
        // make sure we add the model.id to the ui.discussionAdminActions before mounting
        // the component
        this.ui.discussionActions.attr(
          "id",
          `discussion-admin-menu-${this.model.id}`
        )

        return reactUtils.mountComponent(
          this.ui.discussionActions,
          ActionMenu,
          { item: this.model.serialize() },
          [{ store: "discussion" }]
        )
      }
    }

    onDestroy() {
      if (this._showActions()) {
        return reactUtils.unmountComponent(this.ui.discussionActions)
      }
    }

    onSelectContextLink(e) {
      e.preventDefault()
      const url = $(e.currentTarget).attr("href")
      return window.App.navigate(url, { trigger: true })
    }

    _showActions() {
      let shouldShow =
        !this.model.isNew() && this.model.get("user_permissions").can_edit
      shouldShow = shouldShow && this.ui.discussionActions.length
      return shouldShow
    }

    getResponseOptions() {
      const options = {
        context: this.model.get("context_str"),
        response_id: this.discussionStore.get("response_id")
      }
      if (options.filters == null) {
        options.filters = {
          ordering: this.discussionStore.get("response_default_ordering")
        }
      }
      return options
    }

    loadResponses() {
      // TEMPORARY: f/r mvp don't show responses
      if (this.model.get("discussion_type") >= 15) {
        return
      }

      // Show response list
      this.responses.show(
        new LoadingView({
          spinner_type: "small",
          spinner_options: {
            color: "#999999"
          }
        })
      )

      return this.discussionStore.action
        .loadResponses(this.getResponseOptions())
        .promise.then(this.showResponseList.bind(this))
    }
    //App.request("get:responses", @model.id, options).then(@showResponseList.bind(@))

    reloadResponses() {
      return this.discussionStore.action
        .reloadResponses(this.getResponseOptions())
        .promise.then(this.showResponseList.bind(this))
    }

    onBind() {
      // Have to do this after bind to avoid being overwritten
      if (this.options.edit === true) {
        return this.triggerMethod("switch:state", "edit")
      }
    }

    showResponseList(responseCollection) {
      this.responseCollection = responseCollection
      this.responseCollection.discussionID = this.model.id
      this.responseList = new ResponseListView({
        model: this.model,
        collection: this.responseCollection,
        onResponseAdded: (response) =>
          this.options.onUpdateDiscussion &&
          this.options.onUpdateDiscussion(this.model),
        onResponseArchived: (response) =>
          this.options.onUpdateDiscussion &&
          this.options.onUpdateDiscussion(this.model)
      })
      return this.responses.show(this.responseList)
    }

    onToggleSubscribe() {
      // TODO: move to discussion-store
      const subscription = new DiscussionSubscription({
        user: window.App.request("get:user").id,
        discussion_id: this.model.id,
        context: this.model.get("context_str")
      })

      if (this.model.get("user_has_subscribed")) {
        subscription.set("id", "foo")
        return subscription
          .destroy()
          .then(() => {
            return this.model.set("user_has_subscribed", false)
          })
          .fail((err) =>
            console.error("error unsubscribing from discussion", err)
          )
      } else {
        return subscription
          .save()
          .then(() => {
            return this.model.set("user_has_subscribed", true)
          })
          .fail((err) => console.error("error subscribing to discussion", err))
      }
    }

    _onResponseAdded(model) {
      this.options.onUpdateDiscussion &&
        this.options.onUpdateDiscussion(this.model)

      return this.responseCollection.add(model, { at: 0 })
    }

    _closeMenu() {
      return this.$(".discussion-admin-menu").removeClass("open")
    }

    serializeData() {
      let data = super.serializeData()
      data.is_authenticated = window.App.session.get("is_authenticated")
      data.author = new User(data.author).toJSON()
      data = _.extend(this.serializeGlobal(), data)
      data.media = window.App.getStore("school").get("media")
      return data
    }
  }
  DiscussionDetailView.initClass()
  return DiscussionDetailView
})()
