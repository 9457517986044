import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import Text from "@pathwright/ui/src/components/ui/Text"
import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import get from "lodash/get"
import styled from "styled-components"
import ACCOUNT_QUERY from "../account/graphql/account-query"
import { BILLING_INTERVAL_YEAR } from "../billing/constants"
import OrderStripeCheckoutForm from "../order/OrderStripeCheckoutForm"
import { getParam } from "../utils/urls"
import useSchoolSSOLink from "./graphql/useSchoolSSOLink"

const Container = styled.div`
  display: flex;
  animation: cen;
  justify-content: center;
  max-width: 600px;
  margin: 0 auto;

  h1 {
    text-align: center;
  }

  > * + * {
    margin-top: 1em;
  }
`

const Option = styled.a`
  padding: 20px;
  border-radius: 10px;
  transition: all 100ms ease-out 0s;
  border: 1px solid transparent;
  color: rgb(255, 255, 255);
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  position: relative;

  > div:first-child {
    flex-grow: 1;
  }

  @media (hover: hover) {
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.01);
    }
  }
`

const OptionOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(1px);
  border-radius: inherit;
`

const SignUpPickPlan = ({ schoolId, stripeApiKey, inverted }) => {
  const { plan_id, interval = BILLING_INTERVAL_YEAR } = getParam(
    window.location.search
  )

  const accountQuery = useQuery(ACCOUNT_QUERY, {
    variables: {
      default_billing_plan_set: false
    },
    skip: !plan_id
  })

  const plans = get(accountQuery, "data.account.billing_plan_set.plans") || []

  const selectedPlan = plan_id
    ? plans.find((plan) => plan.id === parseInt(plan_id))
    : null

  const loadingPlan = Boolean(plan_id && accountQuery.loading)

  const teachSSOLink = useSchoolSSOLink({
    schoolId,
    pathname: "/home/learn/"
  })

  const activateSSOLink = useSchoolSSOLink({
    schoolId,
    pathname: "/pricing/plan/"
  })

  const renderPaidPlanButton = ({ onSubmit, isAwaitingCheckout, href }) => {
    const loading = isAwaitingCheckout || loadingPlan

    return (
      <Option onClick={onSubmit} href={href} disabled={loading}>
        <div>
          <Text.H3 inverted={inverted}>
            {selectedPlan
              ? `Access the features for the ${selectedPlan.name} plan`
              : "Pick a plan to access paid features"}
          </Text.H3>
          <Text.Body inverted={inverted}>
            Monthly plans start with a 30 day free trial.
          </Text.Body>
        </div>
        {!loading && <Pathicon icon="chevron-right" />}
        {loading && <OptionOverlay />}
        {loading && <LoadingCircle center={false} inverted />}
      </Option>
    )
  }

  return (
    <Container>
      <Text.H1 inverted={inverted}>How would you like to start?</Text.H1>
      {selectedPlan ? (
        <OrderStripeCheckoutForm
          orderType="createPlatformSubscriptionOrder"
          renderButton={({ onSubmit, isAwaitingCheckout }) =>
            renderPaidPlanButton({ onSubmit, isAwaitingCheckout })
          }
          orderData={{
            billing_plan_id: get(selectedPlan, "id"),
            interval: parseInt(interval),
            schoolId,
            plan: selectedPlan
          }}
          stripeApiKey={stripeApiKey}
        />
      ) : (
        renderPaidPlanButton({ href: activateSSOLink })
      )}
      <Option href={teachSSOLink}>
        <div>
          <Text.H3 inverted={inverted}>Try Pathwright without a plan</Text.H3>
          <Text.Body inverted={inverted}>
            No credit card required. Upgrade anytime to access paid features.
          </Text.Body>
        </div>
        <Pathicon icon="chevron-right" />
      </Option>
    </Container>
  )
}

SignUpPickPlan.displayName = "SignUpPickPlan"

export default SignUpPickPlan
