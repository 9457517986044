import merge from "lodash/merge"
import { PRIMARY_GRAY } from "../../utils/colors"
import Tag, { TagValue } from "../Tag"

// A member of modifyingStyles can either be a function or object.
export const mergeStyles = (...modifyingStyles) => {
  const fn = (styles, state) => {
    // Process the list of modifyingStyles for passing to merge.
    const processModifyingStyles = (...modifyingStyles) => {
      return modifyingStyles.reduce((flattenedStyles, modifyingStyle) => {
        // Functions are processed by passing styles and state when called.
        if (typeof modifyingStyle === "function") {
          // Merge the result of the function.
          return [...flattenedStyles, modifyingStyle(styles, state)]
        }
        // Arrays are flattened (the whole purpose of using reduce) recursively.
        if (Array.isArray(modifyingStyle)) {
          // Process the array of modifying styles.
          let processedModifyingStyles = processModifyingStyles(
            ...modifyingStyle
          )
          // The special ability of the array is that a conditional function can be supplied as
          // one of the elements in the array. If the conditional returns false, we ignore all
          // succeeding modifying styles in the array.
          const conditionalIndex = processedModifyingStyles.findIndex(
            (processedModifyingStyle) => processedModifyingStyle === false
          )
          // Check if confitional was found.
          if (conditionalIndex !== -1) {
            // Remove all styles that fail to meet the condition.
            processedModifyingStyles = processedModifyingStyles.slice(
              0,
              conditionalIndex
            )
          }
          // Now flatten the array of processed modifying styles.
          return [...flattenedStyles, ...processedModifyingStyles]
        }
        // Must be an object, nothing to do here.
        return [...flattenedStyles, modifyingStyle]
      }, [])
    }

    // Now merge the ReactSelect base styles with the processedModifyingStyles.
    const mergedStyles = merge(
      {},
      styles,
      ...processModifyingStyles(...modifyingStyles)
    )

    if (mergedStyles.debug) {
      console.log({ mergedStyles })
    }

    return mergedStyles
  }
  // Returng the inner function.
  return fn
}

// Only apply inverted styles when ReactSelect is inverted.
const isInverted = (_, state) => {
  const {
    customProps: { inverted }
  } = state.selectProps
  return Boolean(inverted)
}

// Only apply inverted collapsed styles when ReactSelect has no value
const isInvertedCollapsed = (_, state) => {
  const { value } = state.selectProps
  // return Boolean(inverted && !interactive && !value.length)
  return Boolean(isInverted(_, state) && !value.length)
}

// Only apply clipping styles when ReactSelect is in an
// unfocused state but has value.
const shouldClip = (_, state) => {
  const {
    value,
    customProps: { interactive }
  } = state.selectProps
  return Boolean(!interactive && value.length)
}

const isEmpty = (state) =>
  Boolean(!state.selectProps.value.length && !state.selectProps.inputValue)

// Base modifying styles to be applied to ReactSelect.
const baseStyles = {
  container: (_, state) => ({
    display: "flex",
    // NOTE: minWidth is necessary as otherwise the selector sizes
    // to it's max content, which can be undesirably small. Keep in
    // mind that the placeholder text does not influence the size
    // of the selector, which is unfortunate as a longer placeholder
    // text may get wrapped.
    minWidth: "min(200px, 100%)",
    // Ensure Menu is displayed above other elements.
    zIndex: state.selectProps.customProps.interactive ? 99999999999 : 10
  }),
  control: {
    flexGrow: 1,
    minWidth: 0,
    // Maintians default transition for all styles but excludes border-radius, etc.
    transition: "all 100ms, border-radius 0s, background 0s"
  },
  placeholder: {
    color: PRIMARY_GRAY,
    // Allowing placeholder rect to influence selector width.
    position: "static",
    transform: "none",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden"
  },
  menuList: (styles) => ({
    // Completely hiding the MenuList when it is empty, due to rendering
    // the NoOptionsMessage component.
    ":empty": { display: "none" },
    // Add left and right padding to match top padding.
    paddingLeft: styles.paddingTop,
    paddingRight: styles.paddingTop
  }),
  singleValue: Tag._getStyles,
  multiValueLabel: TagValue._getStyles,
  valueContainer: (styles, state) => ({
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: "2px",
    flexWrap: isEmpty(state) ? "nowrap" : styles.flexWrap
  }),
  option: [Tag._getStyles, { display: "inline-block" }],
  multiValue: [Tag._getStyles, { alignItems: "center" }],
  multiValueRemove: (styles, state) => ({
    fontSize: ".8em",
    borderRadius: 7,
    marginLeft: 4,
    padding: 4,
    paddingLeft: 4,
    paddingRight: 4,
    paddingTop: 4,
    paddingBottom: 4,
    color: state.data.color,
    backgroundColor: "transparent",
    ":hover": {
      color: "white",
      backgroundColor: state.data.color
    }
  }),
  // Only show sperator if the ClearIndicator is being used.
  indicatorSeparator: (_, state) =>
    !state.selectProps.isClearable && { display: "none" },
  dropdownIndicator: (styles, state) => ({
    paddingLeft: state.selectProps.value.length ? styles.paddingLeft : 0
  }),
  noOptionsMessage: { padding: "1em" },
  // Allowing placeholder rect to influence selector width.
  input: (_, state) =>
    isEmpty(state) && {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: "flex",
      alignItems: "center",
      marginLeft: "8px",
      marginRight: "8px"
    }
}

// Modifying styles applied when ReactSelect is to be displayed as inverted.
const invertedStyles = {
  container: {
    // Providing container with similar styles to our primary-alt Button.
    backgroundColor: "rgba(153, 153, 153, 0.7);",
    backdropFilter: "blur(20px)",
    padding: ".2em",
    border: "1px solid rgba(0,0,0,.1)"
    // ALERT: including this hover style causes all modifying styles that are
    // merged in afterwards to not be applied! Seems to only happen with the "container".
    // TODO: investigate this bug further, though already have investigated a good bit.
    // [":hover"]: {
    //   backgroundColor: "rgba(153, 153, 153, 0.6)}"
    // }
  },
  control: {
    backgroundColor: "white",
    borderColor: "transparent !important",
    boxShadow: "none",
    minHeight: "unset"
  }
}

// Modifying styles applied when ReactSelect is to be displayed as inverted collapsed.
const invertedCollapsedStyles = {
  control: {
    // Allows the container background to be seen.
    backgroundColor: "transparent"
  },
  // Container background is dark, so need to invert the
  // text and icon colors.
  placeholder: { color: "rgba(256, 256, 256, 1)" },
  input: { color: "rgba(256, 256, 256, 1)" },
  clearIndicator: {
    color: "rgba(256, 256, 256, 1)",
    [":hover"]: { color: "rgba(256, 256, 256, .9)" }
  },
  dropdownIndicator: {
    color: "rgba(256, 256, 256, 1)",
    [":hover"]: { color: "rgba(256, 256, 256, .9)" }
  }
}

// Modifying styles applied when ReactSelect is not currrently being interacted with.
const clippingStyles = {
  // Hiding the MultiValueRemove.
  multiValueRemove: { display: "none" },
  // Collapsing MultiValueContainer to one line.
  valueContainer: {
    display: "block",
    whiteSpace: "nowrap",
    paddingRight: 0,
    // Fade the inner edge of the container's right side with some gradient white.
    ["&:after"]: {
      content: "''",
      position: "absolute",
      top: 0,
      right: 0,
      height: "100%",
      width: "20px",
      background:
        "linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 20px)"
    }
  },
  // Forcing MultiVallue (selected tags) to stack horizontally rather than vertically.
  multiValue: {
    display: "inline-block"
  },
  dropdownIndicator: {
    paddingLeft: 0
  },
  // Otherwise the Input is pushed down to the next row.
  input: (_, state) =>
    state.selectProps.value.length && {
      position: "absolute",
      height: 0,
      width: 0
    }
}

const requiredStyles = {
  container: (_, state) => ({
    borderRadius:
      state.selectProps.value.length > 1 && !shouldClip(_, state)
        ? "4px"
        : undefined
  }),
  control: (_, state) => ({
    borderRadius:
      state.selectProps.value.length > 1 && !shouldClip(_, state)
        ? "4px"
        : undefined
  })
}

export const getStyles = (modifyingStyles = {}) => {
  // Get all keys for which we are modifying styles.
  const keys = [
    ...new Set([
      ...Object.keys(baseStyles),
      ...Object.keys(invertedStyles),
      ...Object.keys(invertedCollapsedStyles),
      ...Object.keys(clippingStyles),
      ...Object.keys(modifyingStyles),
      ...Object.keys(requiredStyles)
    ])
  ]

  // Construct styles object with merged modifying styles.
  const styles = keys.reduce(
    (styles, key) => ({
      ...styles,
      [key]: mergeStyles(
        // { debug: key === "container" },
        baseStyles[key],
        [isInverted, invertedStyles[key]],
        [isInvertedCollapsed, invertedCollapsedStyles[key]],
        [shouldClip, clippingStyles[key]],
        // Any modifying styles provided via props trump preceeding styles...
        modifyingStyles[key],
        // ... except for those styles which we never want overridden.
        requiredStyles[key]
      )
    }),
    {}
  )

  return styles
}

const styles = getStyles()

export default styles
