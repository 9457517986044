import Color from "jscolor"
import defaults from "lodash/defaults"
import ColorPicker from "../form/form-color/ColorPicker"
import useMostRecent from "../hooks/useMostRecent"

// Expects subset of tagsPropType
export const findMatchingTag = (tagName, tagsSubset) => {
  if (!tagName) return false

  const matchingTag = tagsSubset.find(
    (t) => t.name.toLowerCase() === tagName.toLowerCase()
  )
  return matchingTag
}

// Check if tag name already exists.
// Expects tags of tagsPropType.
export const getTagNameAlreadyExists = (tagName, tags) =>
  Boolean(findMatchingTag(tagName, tags.all))

// Check if tag name has already been filtered.
// Expects tags of tagsPropType.
export const getTagNameAlreadyFiltered = (tagName, tags) =>
  Boolean(findMatchingTag(tagName, tags.filtered))

// Check if tag name has already been selected.
// Expects tags of tagsPropType.
export const getTagNameAlreadySelected = (tagName, tags) =>
  Boolean(findMatchingTag(tagName, tags.selected))

/**
 * mergeSelectedTags
 * @param {tagsPropType} tags
 * @param {[tagPropType]} selectedTags
 * @returns {tagsPropType} with updated selected and filtered keys.
 */
export const mergeSelectedTags = (tags, selectedTags) => {
  // force array
  selectedTags = [].concat(selectedTags)
  // find if a tag exists in the selectedTags that does not
  // yet exist in tags.all
  const newTags = [
    ...new Set(
      selectedTags.filter(
        (selectedTag) => !tags.all.find((tag) => selectedTag.name === tag.name)
      )
    )
  ]

  // new tag/s was/were created
  if (newTags.length) {
    return {
      all: [...tags.all, ...newTags],
      filtered: [...tags.filtered, ...newTags],
      selected: selectedTags
    }
  }

  // an existing tag was selected
  return {
    all: tags.all,
    filtered: tags.filtered,
    selected: selectedTags
  }
}

// /**
//  * Updates an existing tag in a given list of tags.
//  * @param {[tagPropType]} tagsList
//  * @returns {[tagPropType]}
//  */
// export const updateTag = tagsList =>
//   tagsList.map(tag => (tag.name === oldTag.name ? newTag : tag))

/**
 * Removes an existing tag from a given list of tags.
 * @param {[tagPropType]} tagsList
 * @returns {[tagPropType]}
 */
export const removeTag = (tagsList, removedTag) =>
  tagsList.reduce(
    (tags, tag) => (tag.name === removedTag.name ? tags : [...tags, tag]),
    []
  )

export const useMostRecentColorPickerSwatches = () => {
  // Convert ColorPicker default swatches from rgba to hex.
  // Otherwise, if initial ColorPicker value does not change before submission,
  // the submitted color value will be rgba which is an invalid format.
  const defaultSwatches = ColorPicker.defaultProps.swatches.map((rgba) => {
    const color = new Color(rgba)
    return color.hex
  })

  // Supply most recently used ColorPicker swatches to ColorPicker.
  const [colorPickerSwatches, updateColorPickerSwatches] = useMostRecent(
    "recent-tag-form-color-picker-swatches",
    defaultSwatches,
    // Maintain the order of the swatches if the next swatch already exists
    // in the colorPickerSwatches.
    { maintainOrder: true }
  )

  return [colorPickerSwatches, updateColorPickerSwatches]
}

export const useDefaultTag = (tagOptions = {}, tags) => {
  const [colorPickerSwatches] = useMostRecentColorPickerSwatches()

  const getTag = (tagOptions) => {
    // Supply default color based on the most recently used ColorPicker swatch.
    const tag = defaults(tagOptions, {
      color: colorPickerSwatches.list[colorPickerSwatches.index]
    })
    return tag
  }

  // Providing tags ensures we're using an existing tag, if found.
  if (tags) {
    const matchingTag = findMatchingTag(tagOptions.name, tags.all)
    if (matchingTag) return getTag(matchingTag)
  }

  return getTag(tagOptions)
}
