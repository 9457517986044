import Button from "@pathwright/ui/src/components/button/Button"
import IconButton from "@pathwright/ui/src/components/button/IconButton"
import OutsideClickWrapper from "@pathwright/ui/src/components/overlay/OutsideClickWrapper"
import Text from "@pathwright/ui/src/components/ui/Text"
import View from "@pathwright/ui/src/components/ui/View"
import classnames from "classnames"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import { useTranslate } from "../lng/withTranslate"
import PickerAnimator from "./PickerAnimator"
import PickerList from "./PickerList"
import PickerSearch from "./PickerSearch"
import { Checkbox, Item, List, Row } from "./ui"

const StyledContainer = styled(View)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`

const StyledHeader = styled.header`
  text-align: center;
  margin-bottom: 10px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &:empty {
    display: none;
  }

  h4 {
    flex-grow: 1;
  }
`

const StyledPickMultiple = styled(View)`
  text-align: center;
  margin-top: 5px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  button {
    margin-top: 20px;
    margin-bottom: 10px;
  }
`

const Picker = ({
  prompt,
  contained,
  icon,
  searchPrompt,
  picking,
  search,
  searching,
  pickerItems,
  getPickerItem,
  onPick,
  onClose,
  onClear,
  onSearch,
  onFocus,
  onScrollBottom,
  onPickMultiple,
  selectedPickerItemIds,
  selectPickerItem,
  unselectPickerItem,
  selectAllPickerItems,
  selectingAllPickerItems,
  filterSelectedPickerItems,
  filteringSelectedPickerItems,
  renderPickerItem,
  className,
  children
}) => {
  const { tc, tca } = useTranslate()
  return (
    <OutsideClickWrapper onOutsideClick={onClose}>
      <PickerAnimator>
        <StyledContainer
          className={classnames("Picker", className)}
          shadow={!contained}
          rounded={!contained}
        >
          <StyledHeader>
            {!contained && !!onClose && (
              <IconButton onClick={onClose} icon="cancel" />
            )}
            {prompt !== null && (
              <Text.H4>{prompt || tca("your library")}</Text.H4>
            )}
          </StyledHeader>
          {searchPrompt !== null &&
            (pickerItems.length > 0 || search || searching) && (
              <PickerSearch
                value={search}
                searching={searching}
                onSearch={onSearch}
                onClear={onClear}
                onFocus={onFocus}
                icon={icon}
                placeholder={searchPrompt}
              />
            )}
          {!!pickerItems.length && onPickMultiple && (
            <View borderBottom style={{ marginBottom: "5px" }}>
              <List
                style={{ marginBottom: "5px" }}
                gridColumns={selectedPickerItemIds.length ? 2 : 1}
              >
                <Item>
                  <Row
                    onClick={() =>
                      selectAllPickerItems(!selectingAllPickerItems)
                    }
                  >
                    <Checkbox>
                      <input
                        type="checkbox"
                        checked={selectingAllPickerItems}
                        onChange={() =>
                          selectAllPickerItems(!selectingAllPickerItems)
                        }
                      />
                    </Checkbox>
                    <Text.H5>{tc("select all")}</Text.H5>
                  </Row>
                </Item>
                {!!selectedPickerItemIds.length && (
                  <Item>
                    <Row
                      onClick={() =>
                        filterSelectedPickerItems(!filteringSelectedPickerItems)
                      }
                    >
                      <Checkbox>
                        <input
                          type="checkbox"
                          checked={filteringSelectedPickerItems}
                          onChange={() =>
                            filterSelectedPickerItems(
                              !filteringSelectedPickerItems
                            )
                          }
                        />
                      </Checkbox>
                      <Text.H5>{tc("show selected")}</Text.H5>
                    </Row>
                  </Item>
                )}
              </List>
            </View>
          )}
          {children({
            search,
            pickerItems,
            pickerList: pickerItems.length ? (
              <React.Fragment>
                {picking && (
                  <PickerList
                    pickerItems={pickerItems}
                    getPickerItem={getPickerItem}
                    onPick={onPick}
                    onScrollBottom={onScrollBottom}
                    selectedPickerItemIds={
                      onPickMultiple && selectedPickerItemIds
                    }
                    selectPickerItem={onPickMultiple && selectPickerItem}
                    unselectPickerItem={onPickMultiple && unselectPickerItem}
                    renderPickerItem={renderPickerItem}
                  />
                )}
                {!!pickerItems.length && !!onPickMultiple && (
                  <StyledPickMultiple borderTop>
                    <Button
                      styleType="primary"
                      size="large"
                      onClick={() => onPickMultiple(selectedPickerItemIds)}
                    >
                      {tca("update selected items ({{ _count }})", {
                        _count: selectedPickerItemIds.length
                      })}
                    </Button>
                  </StyledPickMultiple>
                )}
              </React.Fragment>
            ) : null
          })}
        </StyledContainer>
      </PickerAnimator>
    </OutsideClickWrapper>
  )
}

Picker.displayName = "Picker"

Picker.propTypes = {
  pickerItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  getPickerItem: PropTypes.func,
  filter: PropTypes.func,
  prompt: PropTypes.string,
  icon: PropTypes.string,
  searchPrompt: PropTypes.string,
  contained: PropTypes.bool,
  picking: PropTypes.bool,
  onPick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onClear: PropTypes.func,
  onSearch: PropTypes.func,
  onFocus: PropTypes.func,
  onScrollBottom: PropTypes.func,
  onPickMultiple: PropTypes.func,
  renderPickerItem: PropTypes.func,
  children: PropTypes.func
}

Picker.defaultProps = {
  contained: false,
  picking: true,
  children: ({ pickerList }) => pickerList
}

export default Picker
