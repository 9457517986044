import {
  Media,
  MediaInput,
  MediaTag,
  MediaType,
  sortMediaByDate,
  tagsSortFunction,
  validateMedia
} from "@pathwright/media-utils"
import { slice } from "lodash"
import moment from "moment"
import { createContext, useContext, useEffect, useMemo } from "react"
import { useSyncer } from "./MediaSyncerContext"
import * as c from "./constants"
import fuse, { fuseOptions } from "./fuse"
import { defaultState, getInitialState, reducer } from "./mediaState"
import { MediaFilter, MediaProviderType } from "./types"
import useSyncedReducer from "./useSyncedReducer"

const RECENT_IN_DAYS = 7

const getRecent = (): number => {
  return moment().subtract(RECENT_IN_DAYS, "days").valueOf()
}

const MediaContext = createContext<MediaProviderType.Context>({
  ...defaultState,
  contextKey: "",
  mediaStoragePath: "",
  userID: "",
  onClose: () => {},
  mediaType: MediaType.ANY,
  handleReset: () => {},
  handleClearSelectedBatch: () => {},
  handleDeselectMedia: () => {},
  handleStartUploading: () => {},
  handleStopUploading: () => {},
  handleSetUploadCount: () => {},
  handlePaginateMedia: () => {},
  handleStartEditing: () => {},
  handleStopEditing: () => {},
  handleSetSelectedBatch: () => {},
  handleSetFilter: () => {},
  handleSetSearch: () => {},
  handleStartLoading: () => {},
  handleStopLoading: () => {},
  handleSaveMediaBatch: () => {},
  handleDeleteBatch: () => {},
  handleDeleteMedia: () => {},
  handleStartSavingMedia: () => {}
})

export const useMedia = () =>
  useContext<MediaProviderType.Context>(MediaContext)

const MediaProvider = (props: MediaProviderType.Props) => {
  const { children, mediaType, forceEmpty, userID } = props

  const { syncer, media, accountTags, gqlError, tagsLoading, mediaLoading } =
    useSyncer()

  const [state, dispatch] = useSyncedReducer(
    reducer,
    syncer,
    props,
    getInitialState
  )

  const {
    currentFilter,
    uploadCount,
    currentPage,
    currentPageCount,
    currentSearch
  } = state

  const handlers = useMemo(
    () => ({
      handleReset: (resetState = {}) => {
        dispatch({ type: c.RESET, payload: resetState })
      },
      handleClearSelectedBatch: () => {
        dispatch({ type: c.CLEAR_SELECTED_BATCH })
      },
      handleDeselectMedia: () => {
        dispatch({ type: c.DESELECT_MEDIA })
      },
      handleStartUploading: ({ isVideo }: { isVideo?: boolean } = {}) => {
        dispatch({ type: c.START_UPLOADING, payload: { isVideo } })
      },
      handleStopUploading: () => {
        dispatch({ type: c.STOP_UPLOADING })
      },
      handleSetUploadCount: (count: number) => {
        dispatch({ type: c.SET_UPLOAD_COUNT, payload: count })
      },
      handlePaginateMedia: (nextPage: number) => {
        dispatch({ type: c.PAGINATE_MEDIA, payload: nextPage })
      },
      handleStartEditing: (selectedMediaItem: Media) => {
        dispatch({ type: c.START_EDITING, payload: selectedMediaItem })
      },
      handleStopEditing: () => {
        dispatch({ type: c.STOP_EDITING })
      },
      handleSetSelectedBatch: (selectedBatch: Media[]) => {
        dispatch({ type: c.SET_SELECTED_BATCH, payload: selectedBatch })
      },
      handleSetFilter: (filter: MediaFilter.Type) => {
        dispatch({ type: c.SET_FILTER, payload: filter })
      },
      handleSetSearch: (search?: string) => {
        dispatch({ type: c.SET_SEARCH, payload: search })
      },
      handleStartLoading: () => {
        dispatch({ type: c.START_LOADING })
      },
      handleStopLoading: () => {
        dispatch({ type: c.STOP_LOADING })
      },
      handleSaveMediaBatch: (mediaBatch: MediaInput[]) => {
        dispatch({ type: c.SAVE_MEDIA_BATCH, payload: mediaBatch })
      },
      handleDeleteBatch: () => {
        dispatch({ type: c.DELETE_BATCH })
      },
      handleDeleteMedia: (media: Media) => {
        dispatch({ type: c.DELETE_MEDIA, payload: media })
      },
      handleStartSavingMedia: (media: MediaInput) => {
        dispatch({ type: c.START_SAVING_MEDIA, payload: media })
      }
    }),
    []
  )

  const getFilteredMediaByFacet = (media: Media[]) => {
    return media.filter((item: Media) => {
      if (
        mediaType !== MediaType.ANY.toLowerCase() &&
        item.type.toLowerCase() !== mediaType
      )
        return false

      if (!currentFilter || currentFilter === "all") return true

      if (currentFilter === "tagged" && item.tags && item.tags.length)
        return true

      if (currentFilter === "uploads" && userID === item.createdByID)
        return true

      if (currentFilter === "recent" && item.createdDate > getRecent())
        return true

      return false
    })
  }

  useEffect(() => {
    if (!media) return

    const _getUploadPlaceholders = (): Media[] =>
      new Array(uploadCount).fill({
        type: mediaType,
        url: "http://placeholder.nowhere.com"
      })

    // Use a Load More pattern (include all previous pages)
    const _paginateMedia = (items: Media[]): Media[] => {
      const endIndex = currentPage * currentPageCount

      return slice(items, 0, endIndex)
    }

    const _maybeApplyFuseSearch = (media: Media[]): Media[] => {
      return currentSearch?.length > 1
        ? fuse(media, currentSearch, fuseOptions)
        : media
    }

    const _collectRenderedMedia = (): Media[] => {
      if (forceEmpty) return []

      const placeholderMedia: Media[] = _getUploadPlaceholders()

      const filteredMedia: Media[] = getFilteredMediaByFacet(media)

      const validMedia: Media[] = validateMedia(filteredMedia)
      const searchedMedia: Media[] = _maybeApplyFuseSearch(validMedia)
      const sortedMedia: Media[] = sortMediaByDate(searchedMedia)

      const paginatedMedia: Media[] = _paginateMedia(
        placeholderMedia.concat(sortedMedia)
      )
      return paginatedMedia
    }

    const renderedMedia: Media[] = _collectRenderedMedia()

    dispatch({ type: c.SET_RENDERED_MEDIA, payload: renderedMedia })
  }, [media, currentFilter, currentSearch, mediaType, uploadCount, currentPage])

  useEffect(() => {
    const _getTagsWithCountsFromMedia = (media: Media[]): MediaTag[] => {
      const getTagCountsFromMedia = (): Record<string, number> => {
        return media.reduce((agg, mediaItem) => {
          if (!mediaItem || !mediaItem.tags) return agg

          mediaItem.tags.forEach((tag) => {
            if (!agg[tag]) {
              agg[tag] = 0
            }
            agg[tag] += 1
          })

          return agg
        }, {})
      }
      const tagCounts: Record<string, number> = getTagCountsFromMedia()

      return accountTags
        .map((tag) => ({ ...tag, count: tagCounts[tag.name] }))
        .sort(tagsSortFunction)
    }

    const renderedTags = _getTagsWithCountsFromMedia(
      getFilteredMediaByFacet(media)
    )

    dispatch({ type: c.SET_RENDERED_TAGS, payload: renderedTags })
  }, [accountTags, media, currentFilter, mediaType, uploadCount, currentPage])

  const passState: MediaProviderType.Context = {
    // Global config
    ...props,

    // Apollo
    mediaLoading,
    accountTags,
    tagsLoading,
    gqlError,

    // Provider
    ...state,

    // Handlers
    ...handlers
  }

  return (
    <MediaContext.Provider value={{ ...passState }}>
      {children}
    </MediaContext.Provider>
  )
}

MediaProvider.displayName = "MediaProvider"

export default MediaProvider
