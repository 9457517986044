import { Button, HStack } from "@chakra-ui/react"
import { GearIcon } from "@pathwright/pathicons"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import moment from "moment"
import { Link as ReactRouterLink } from "react-router-dom"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import getStripePaymentPortalHref from "../../stripe/utils/getStripePaymentPortalHref"
import { useShareDataContext } from "../shared/ShareDataContextProvider"
import Gate from "./Gate"
import useGatedShareAccessKey from "./useGatedShareAccessKey"

const GroupShareGate = () => {
  const { school } = usePathwrightContext()
  const { meta, permissions } = useShareDataContext()
  const { tc, tca } = useTranslate()
  const gatedReason = permissions[useGatedShareAccessKey()]?.gated_reason

  switch (gatedReason) {
    case "GROUP_SUBSCRIPTION_INACTIVE":
      return (
        <Gate
          title={tca("Access Expired")}
          description={tc(
            `Access to {{cohortName}} expired on {{cohortAccessEnds}}`,
            {
              cohortName: meta.title,
              cohortAccessEnds: moment(meta.groupSubscriptionEnds).format(
                "MMM Do, YYYY"
              )
            }
          )}
        >
          {permissions?.allow_manage && (
            <Button
              as={ReactRouterLink}
              to={getStripePaymentPortalHref(school)}
              variant="link"
              colorScheme="gray"
              textDecoration="underline"
            >
              <HStack spacing={1}>
                <GearIcon size="1em" />
                <span>{tc("Manage subscription")}</span>
              </HStack>
            </Button>
          )}
        </Gate>
      )
    case "GROUP_SEATS_FILLED":
      return (
        <Gate
          title={tca("Seat Count Reached")}
          description={tc(`{{cohortName}} has no more remaining seats`, {
            cohortName: meta.title
          })}
        >
          {permissions?.allow_manage && (
            <Button
              as={ReactRouterLink}
              to={getStripePaymentPortalHref(school)}
              variant="link"
              colorScheme="gray"
              textDecoration="underline"
            >
              <HStack spacing={1}>
                <GearIcon size="1em" />
                <span>{tc("Manage subscription")}</span>
              </HStack>
            </Button>
          )}
        </Gate>
      )
  }

  // If typescript, we should ensure the switch case is exhausted.
  return null
}

export default GroupShareGate
