import Card from "@pathwright/ui/src/components/card/Card"
import CardBlock from "@pathwright/ui/src/components/card/CardBlock"
import Text from "@pathwright/ui/src/components/ui/Text"
import { LICENSING_MODES } from "@pathwright/web/src/modules/store/resource/offering/license/constants"
import { ConnectStores } from "lib/core/store"
import ManageLicenseProductsForm from "./ManageLicenseProductsForm"
import ManageLicenseSubscriberForm from "./ManageLicenseSubscriberForm"

const ManageLicenseCard = (props) => {
  const { card, license_offering, resource } = props

  const onDirty = () => card.setCardDirty(true)
  const onPristine = () => card.setCardDirty(false)

  const isPrivateCohort =
    license_offering?.licensing_mode === LICENSING_MODES.PRIVATE_COHORTS.int

  const isGroupSubscription =
    license_offering?.licensing_mode === LICENSING_MODES.GROUP_SUBSCRIPTION.int

  return (
    <Card title="Subscriber access" card={card}>
      {isGroupSubscription ? (
        <CardBlock>
          <Text.H4 style={{ marginBottom: "4px" }}>Select Plans</Text.H4>
          <Text.Body>
            Enable subscribers to create their own private cohorts in{" "}
            {resource?.name || "this course"} and optionally purchase seats for
            others.
          </Text.Body>
        </CardBlock>
      ) : (
        <CardBlock>
          Give subscribers free access to create and join member-created cohorts
          with no limits. Subscribers who create the cohort may purchase seats
          for non-subscribers.
        </CardBlock>
      )}

      {isPrivateCohort ? (
        <ManageLicenseSubscriberForm
          onDirty={onDirty}
          onPristine={onPristine}
        />
      ) : (
        <ManageLicenseProductsForm onDirty={onDirty} onPristine={onPristine} />
      )}
    </Card>
  )
}

ManageLicenseCard.displayName = "ManageLicenseCard"

const mapStateToProps = (state, ownProps) => {
  const { license_offering } = state.resourceLicenseAdminStore.state
  const { resource } = state.resourceStore.state
  return { ...ownProps, license_offering, resource }
}

const stores = [
  { store: "resourceLicenseAdmin", watch: ["license_offering"] },
  { store: "resource", watch: ["resource"] }
]

export default ConnectStores(ManageLicenseCard, stores, {}, mapStateToProps)
