import Fullscreen from "@pathwright/ui/src/components/fullscreen/Fullscreen"
import CompletionFeedbackPanel from "@pathwright/web/src/modules/completion/feedback/CompletionFeedbackPanel"
import CompletionFeedbackPanelHeader from "@pathwright/web/src/modules/completion/feedback/CompletionFeedbackPanelHeader"
import CompletionsListContainer from "@pathwright/web/src/modules/completion/list/CompletionsListContainer"
import { COMPLETIONS_FILTERS } from "@pathwright/web/src/modules/completion/utils"
import { COMPLETION_FILTERS as INBOX_COMPLETIONS_FILTERS } from "@pathwright/web/src/modules/inbox/context/completion-filters"
import { snakeCase } from "change-case"
import { authRedirect, composeMiddleware } from "lib/core/routing/middleware"
import { connect } from "lib/core/store"
import isEmpty from "lodash/isEmpty"
import pick from "lodash/pick"
import { useEffect } from "react"
import { encodeURLParams } from "../lib/core/api/urls"

const COMPLETION_QUERY_PARAMS_MAP = {
  userId: "user_id", // Scope completion items to a user
  cohortId: "cohort_id", // Scope completion items to a cohort
  sourceId: "source_id", // Scope completion items to a source step
  parentId: "parent_id" // scope completion items to a parent group
}

const getCompletionID = () => {
  let completionId = null
  const match = window.location.pathname.match(/completion\/(\d+)\//)
  if (match) {
    completionId = parseInt(match[1])
  }
  return completionId
}

const getExpectedCompletionQueryFilters = () => {
  const params = new URLSearchParams(window.location.search)
  // map the search params to the completion filter key
  const expectedCompletionQueryFilters = Object.entries(
    COMPLETION_QUERY_PARAMS_MAP
  ).reduce((acc, [propKey, paramKey]) => {
    paramKey = parseInt(params.get(paramKey))
    if (paramKey) acc[propKey] = paramKey
    return acc
  }, {})

  return expectedCompletionQueryFilters
}

// Get query args from query params
const getCompletionQueryFilters = () => {
  const params = new URLSearchParams(window.location.search)

  return {
    ...getExpectedCompletionQueryFilters(),
    selectedId: getCompletionID(), // Highlight completion item when panel is open
    filter: params.get("filter") // scope completion items to a parent group
  }
}

// handles completion ID + all query params
const getCompletionRoute = (options = {}) => {
  const { completionId: completionIdProp, ...params } = options

  // get paramKeys in snake_case form which is the expected case for the search params
  const paramKeys = Object.entries(params).reduce(
    (o, [k, v]) => ({ ...o, [snakeCase(k)]: v }),
    {} // prettier-ignore
  )

  // get the unified/filtered searchParams, allowing any parms passed
  // in via options to override existing search params
  const searchParams = new URLSearchParams(
    [
      // map allows paramKeys to override window.location.search
      ...new Map([
        ...new URLSearchParams(window.location.search),
        ...Object.entries(paramKeys)
      ])
    ].filter(([k, v]) => !!v)
  )

  // allowing completionId to be cleared out if value is null
  const completionId =
    completionIdProp !== null ? completionIdProp || getCompletionID() : null

  // ensure response_id is cleared if no completionId or if completionId has changed
  if (!completionId || completionId !== getCompletionID()) {
    searchParams.delete("response_id")
  }

  // if completion, then set pathname accordingly, otherwise
  // default to current /completion/ pathname
  const pathname = completionId
    ? `/completion/${completionId}/`
    : `/completion/`

  return {
    pathname,
    search: `?${searchParams}`
  }
}

// helper
const navigateToCompletionRoute = (options) =>
  window.App.navigate(getCompletionRoute(options))

// We're injecting into top-level fullscreen and panel layouts
// instead of using normal child component route. Since this
// only happens onEnter and onLeave, the only way to for
// UserCompletions to receive new location props is to connect
// to the navigation store.

const FullscreenCompletionsListContainer = (props) => {
  useEffect(() => {
    // Set resource_launched_from when completion UI first mounts
    window.App.getStore("navigation").action.setResourceLaunchedFrom()
  }, [])

  return (
    // TODO: specify resource background. We're currently defaulting to the school theme background
    <Fullscreen>
      <CompletionsListContainer {...props} />
    </Fullscreen>
  )
}

FullscreenCompletionsListContainer.displayName =
  "FullscreenCompletionsListContainer"

const CompletionsContainer = connect(
  FullscreenCompletionsListContainer,
  "navigation",
  ({ navigationStore }) => {
    // Pass props to UserCompletions
    return {
      ...getCompletionQueryFilters(),
      // Fullscreen back link (assumes launched_from is set)
      // onClose: () => App.navigate(navigationStore.state.resource_launched_from),
      // Completion item panel link
      onSelect: (completion) =>
        navigateToCompletionRoute({ completionId: completion.id }),
      onFilter: (filter) => navigateToCompletionRoute({ filter })
    }
  },
  ["location", "resource_launched_from"]
)

CompletionsContainer.displayName = "CompletionsContainer"

const CompletionFeedbackPanelContainer = connect(
  CompletionFeedbackPanel,
  "navigation",
  ({ navigationStore }) => {
    const { location } = navigationStore.state
    // Pass props to CompletionFeedbackPanel
    return {
      stepId: getCompletionID(),
      responseId: parseInt(location.query.response_id), // direct response link
      onViewResponse: (response) =>
        navigateToCompletionRoute({ responseId: response.id }),
      onViewAllResponses: () => navigateToCompletionRoute({ responseId: null })
    }
  },
  ["location"]
)

CompletionFeedbackPanelContainer.displayName =
  "CompletionFeedbackPanelContainer"

const inboxRedirect = (nextState, replace, callback) => {
  const { query } = nextState.location

  const completionContext = {
    ...getCompletionQueryFilters(),
    responseId: parseInt(query.response_id)
  }

  const inboxConversion = {
    userId: (v) => ({ user_id: v }),
    cohortId: (v) => ({ cohort_id: v }),
    sourceId: (v) => ({ source_id: v }),
    parentId: (v) => ({ parent_id: v }),
    // NOTE: step_id forces Inbox to be opend in single item mode since
    // it scopes the data to that single item.
    selectedId: (v) => ({ step_id: v, ui_selected: v }),
    responseId: (v) => ({ ui_panel_selected: v, ui_panel: "discuss" }),
    filter: (v) => {
      // prettier-ignore
      const filterConversion = {
        [COMPLETIONS_FILTERS.NEEDS_REVIEW]: INBOX_COMPLETIONS_FILTERS.pending.key,
        [COMPLETIONS_FILTERS.REVIEWED]: INBOX_COMPLETIONS_FILTERS.reviewed.key,
        [COMPLETIONS_FILTERS.COMPLETE]: INBOX_COMPLETIONS_FILTERS.complete.key,
        [COMPLETIONS_FILTERS.INCOMPLETE]: INBOX_COMPLETIONS_FILTERS.incomplete.key,
        [COMPLETIONS_FILTERS.ALL]: INBOX_COMPLETIONS_FILTERS.all.key
      }

      return {
        filter: filterConversion[v]
      }
    }
  }

  // Preserve any inbox-specific query params that may be present.
  // This could be due to parsing a notificaiton url and appending
  // inbox-specific query params (i.e. for ask-to-answer feedback discussion).
  const baseInboxParams = pick(query, ["ui_panel"])

  // Convert to inbox params.
  const inboxParams = Object.entries(completionContext).reduce(
    (acc, [k, v]) =>
      v && inboxConversion[k]
        ? {
            ...acc,
            ...inboxConversion[k](v)
          }
        : acc,
    baseInboxParams
  )

  // Default to "all" filter.
  if (!inboxParams.filter) {
    inboxParams.filter = INBOX_COMPLETIONS_FILTERS.all.key
  }

  const redirectRoute = replace({
    pathname: "/inbox/",
    search: encodeURLParams(inboxParams)
  })

  // Callback is necessary to delay navigation-store from responding
  // to the current route which we are redirecting from.
  return callback(redirectRoute)
}

const completionRedirectRoute = {
  path: "/completion/:completion_id?/",
  onEnter: inboxRedirect
}

const oldCompletionRoute = {
  path: "/completion/",
  onEnter: composeMiddleware(
    authRedirect,
    inboxRedirect,
    (nextState, replace) => {
      // redirect to home location if none of the expected query params are present in the location
      if (isEmpty(getExpectedCompletionQueryFilters())) {
        const homeLocation =
          window.App.getStore("navigation").request.getHomeLocation()
        return replace(homeLocation)
      }
    }
  ),
  component: CompletionsContainer,
  childRoutes: [
    {
      path: ":completion_id(\\d+)/",
      onEnter: (nextState) => {
        //TODO: also need to redirect here?

        // Open in top-level DockPanel container
        window.App.getStore("layout").action.showIn(
          "panel",
          CompletionFeedbackPanelContainer, // DockPanel component
          // () => <h1>HELLLO HELLLO</h1>,
          {}, // CompletionFeedbackPanelContainer props
          {
            // DockPanel props
            allowOrient: false,
            panelWidthPerc: 0.8,
            HeaderComponent: (props) => (
              <CompletionFeedbackPanelHeader
                {...props}
                {...getCompletionQueryFilters()}
                // Completion item panel link
                onSelect={(completion) =>
                  navigateToCompletionRoute({ completionId: completion.id })
                }
              />
            ),
            onClose: () => navigateToCompletionRoute({ completionId: null })
          }
        )
      },
      onLeave: () => {
        window.App.getStore("layout").action.closeIn("panel")
      }
    }
  ]
}

export default completionRedirectRoute
