import { useEffect, useRef, useState } from "react"
import useCleanupInterval from "./useCleanupInterval"
import useIsMountedRef from "./useIsMountedRef"
import usePreviousEffect from "./usePreviousEffect"

// hook for observer the intersection of a node with the browser viewport by default or specified root node if provided in the `observerOptions`
// see https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API for the full list of IntersectionObserver options
const useIntersection = (options, delay = 0) => {
  const { disconnectAfterIntersection, ...observerOptions } = options || {}

  const isMountedRef = useIsMountedRef()
  const [node, setNode] = useState(null)
  // has the node ever intersected with the root
  const [hasIntersected, setHasIntersected] = useState(false)
  const [intersection, setIntersection] = useState({})
  const observerRef = useRef(null)
  const cleanupInterval = useCleanupInterval()

  usePreviousEffect(
    ([prevNode, prevHasIntersected]) => {
      if (prevNode && node !== prevNode && hasIntersected) {
        // reset intersection state since node has changed and prevNode had intersected
        setHasIntersected(false)
        setIntersection({})
      }

      if (node) {
        // always disconnect current observer, if there is onoe
        if (observerRef.current) observerRef.current.disconnect()

        const callback = (entries) => {
          if (isMountedRef.current) {
            // NOTE: entries will only contain 1 entry in this case, as there is only one target node being observed
            entries.forEach((entry) => {
              if (entry.isIntersecting && !hasIntersected) {
                const rootNode =
                  (observerOptions && observerOptions.root) ||
                  window.document.documentElement

                // only setting the hasIntersected boolean now if no delay is specified
                if (
                  !delay ||
                  // also ignore delay if the root element is scrolled to the top
                  rootNode.scrollTop === 0
                )
                  setHasIntersected(true)
              }
              setIntersection(entry)
            })
          }
        }

        observerRef.current = new IntersectionObserver(
          callback,
          observerOptions
        )
        observerRef.current.observe(node)
      }
    },
    [node, hasIntersected]
  )

  // if a delay is preferred, only handle setting the hasIntersected boolean after delay
  // and only if the node is still intersecting the root
  useEffect(() => {
    if (delay > 0 && !hasIntersected) {
      cleanupInterval(
        setTimeout(() => {
          // make sure node is still intersecting the root
          if (intersection && intersection.isIntersecting) {
            if (isMountedRef.current) setHasIntersected(true)
          }
        }, delay)
      )
    }
  }, [intersection])

  // Disconnect observer after intersection if desired.
  useEffect(() => {
    if (hasIntersected && disconnectAfterIntersection) {
      if (observerRef.current) observerRef.current.disconnect()
    }
  }, [hasIntersected])

  const goodies = [setNode, intersection, hasIntersected]
  // convenience
  goodies.setIntersectionNode = setNode
  goodies.intersection = intersection
  goodies.hasIntersected = hasIntersected

  return goodies
}

export default useIntersection
