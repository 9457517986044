import {
  constructUrl,
  getRelativeRoute,
  getRelativeUrl
} from "@pathwright/web/src/modules/utils/urls"

export const route = (pathname, state = {}) => ({
  ...getRelativeRoute(pathname),
  state
})

export const modalRoute = function (pathname, state) {
  if (state == null) {
    state = {}
  }
  if (pathname.pathname != null) {
    ;({ pathname } = pathname)
    state = _.assign(state, pathname.state || {})
  }
  state = _.extend(state, { modal: true })
  const r = route(pathname, state)
  return r
}

export const getRedirect = function (url) {
  // for whatever reason require("store/utils") comes back with functions undefined.
  // so duplicating getStoreUrl here:
  const storeUrl =
    (window.school != null ? window.school.platform_version : undefined) >= 2.8
      ? "/store/"
      : "/library/"

  const redirects = [
    [
      storeUrl,
      "/about/" // appended to URL
    ]
  ]

  for (let redirect of Array.from(redirects)) {
    if (url.indexOf(redirect[0]) > -1) {
      url = url.replace(redirect[0], redirect[1])
      if (redirect[2]) {
        url += redirect[2]
      }
      return url.replace(/\/\//g, "/")
    }
  }
  return url
}

// Hack for making absolute urls within <Text /> relative (mainly for linking within app)
export const routeToRelativeURL = function (url) {
  url = getRelativeUrl(url)
  // It would appear the redirect logic would always produce an invalid route where /library/* becomes /about/*
  // url = getRedirect(url)
  return window.App.navigate(url)
}

export const getConversionRoute = (baseRoute) => {
  let route = baseRoute || window.location.pathname

  if (!/\/thank-you\//.test(route)) {
    route = getRelativeRoute(route)
    const conversionRoute = {
      ...route,
      pathname: constructUrl(route.pathname, "/thank-you/")
    }
    return conversionRoute
  }
  return null
}
