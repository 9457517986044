import Text from "@pathwright/ui/src/components/ui/Text"
import styled from "styled-components"

const Container = styled.div`
  padding: 5px 10px;
  display: flex;
  align-items: center;
  &::before,
  &::after {
    content: "";
    position: relative;
    display: block;
    flex-grow: 100;
    min-width: 50px;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.1);
  }
`

const ReportPathDivider = ({ pathItem }) => {
  return (
    <Container>
      <Text.H6>{pathItem.name}</Text.H6>
    </Container>
  )
}

ReportPathDivider.displayName = "ReportPathDivider"

export default ReportPathDivider
