import { withTranslate } from "@pathwright/ui/src/components/"
import Avatar from "@pathwright/ui/src/components/avatar/Avatar"
import Button from "@pathwright/ui/src/components/button/Button"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import useHomeActiveEntries from "@pathwright/web/src/modules/home/useHomeActiveEntries"
import { ActivateButton } from "lib/core/components/pathwright"
import { getStoreLabel, getStoreUrl } from "store/utils"
import "./styles/AppPanelLinks.css"

const tPrefix = "nav.app_panel_links"

const HomeButton = ({ user, tc }) => {
  const { path: homePath } = useHomeActiveEntries()

  return (
    <li>
      <Button
        className="AppPanelLink__home"
        styleType="tertiary"
        size="large"
        rounded={false}
        stretch
        to={homePath}
      >
        {!!user && <Avatar user={user} size="1em" />}
        {` ${tc("Home")}`} <Pathicon icon="chevron-right" />
      </Button>
    </li>
  )
}

const AppPanelLinks = function (props) {
  const {
    user,
    school,
    showHome,
    showManage,
    showSubscribe,
    showActivate,
    pages,
    activateSupport,
    isOpen,
    t,
    tc
  } = props

  const storeFrontLabel = t(
    `library.store_labels.${getStoreLabel().toLowerCase()}`
  )

  return (
    <div className="AppPanelLinks">
      <ul className="AppPanelLinks__list AppPanelLinks__primary">
        {showManage && (
          <li>
            <Button
              className="AppPanelLink__dashboard"
              styleType="tertiary"
              size="large"
              rounded={false}
              stretch
              to="/dashboard/"
            >
              <Pathicon icon="gauge-square" />
              {` ${tc("Dashboard")}`} <Pathicon icon="chevron-right" />
            </Button>
          </li>
        )}
        {/* HACK: mounting/unmounting HoemButton based on `isOpen` state of the AppPanel
        in order to force a "requery" of the locally stored home path, since it may be changed elsewhere.
        We could probably solve this better by using CustomEvents, but this works for now. */}
        {isOpen && showHome && <HomeButton user={user} tc={tc} />}
        <li>
          <Button
            className="AppPanelLink__library"
            styleType="tertiary"
            size="large"
            rounded={false}
            stretch
            to={getStoreUrl()}
          >
            <Pathicon icon="grid-large" />
            {` ${storeFrontLabel}`} <Pathicon icon="chevron-right" />
          </Button>
        </li>
        {showManage && (
          <li>
            <Button
              className="AppPanelLink__community"
              styleType="tertiary"
              size="large"
              rounded={false}
              stretch
              to="/community/members/"
            >
              <Pathicon icon="multi-user" />
              {` ${tc("Community")}`} <Pathicon icon="chevron-right" />
            </Button>
          </li>
        )}
        {showManage && (
          <li>
            <Button
              className="AppPanelLink__account"
              styleType="tertiary"
              size="large"
              rounded={false}
              stretch
              to="/manage/school/"
            >
              <Pathicon icon="gear" />
              {` ${tc("Settings")}`} <Pathicon icon="chevron-right" />
            </Button>
          </li>
        )}
        {showSubscribe && (
          <li>
            <Button
              className="AppPanelLink__subscribe"
              styleType="tertiary"
              size="large"
              rounded={false}
              stretch
              to="/subscribe/"
            >
              {tc("Subscribe")} <Pathicon icon="chevron-right" />
            </Button>
          </li>
        )}
      </ul>
      {!!pages &&
        !!pages.length &&
        !!pages.some((page) => page.is_live && page.show_in_nav) && (
          <ul className="AppPanelLinks__list">
            {Array.from(pages)
              .filter((p) => p.show_in_nav && p.is_live)
              .map((page, index) => {
                return (
                  <li key={index}>
                    {/^http/.test(page.url) ? (
                      <Button
                        styleType="tertiary"
                        size="medium"
                        rounded={false}
                        stretch
                        href={page.url}
                      >
                        {page.name}
                      </Button>
                    ) : (
                      <Button
                        styleType="tertiary"
                        size="medium"
                        rounded={false}
                        stretch
                        to={page.url}
                      >
                        {page.name}
                      </Button>
                    )}
                  </li>
                )
              })}
          </ul>
        )}
      <ul className="AppPanelLinks__list">
        <li>
          <Button
            className="AppPanelLink__help"
            styleType="tertiary"
            size="medium"
            rounded={false}
            stretch
            onClick={activateSupport}
          >
            {t(`${tPrefix}.need_help`)}
          </Button>
        </li>
      </ul>
      {showActivate && (
        <div className="AppPanelLinks__activate">
          <ActivateButton
            size="large"
            styleType="secondary"
            to="/pricing/"
            utmSource="Admin Menu"
          >
            Activate Now <Pathicon icon="rocket" />
          </ActivateButton>
        </div>
      )}
    </div>
  )
}

AppPanelLinks.displayName = "AppPanelLinks"

export default withTranslate(AppPanelLinks)
