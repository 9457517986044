import { Component } from "react"
import { Motion, spring } from "react-motion"

class FieldShaker extends Component {
  constructor(props) {
    super(props)
    this.state = {
      animating: false
    }
  }

  // invoke this method with a ref
  shake() {
    this.setState({ animating: true }, () => {
      setTimeout(() => {
        this.setState({ animating: false })
      }, 200)
    })
  }

  render() {
    const animating = this.props.animating || this.state.animating
    const springValue = {
      defaultStyle: 0,
      style: animating ? -10 : 0
    }
    const transition = {
      stiffness: 1480,
      damping: 12
    }
    return (
      <Motion
        defaultStyle={{ x: springValue.defaultStyle }}
        style={{ x: spring(springValue.style, transition) }}
      >
        {(interpolated) => {
          return (
            <div
              className="FieldShaker"
              style={{ transform: `translateX(${interpolated.x}px)` }}
            >
              {this.props.children}
            </div>
          )
        }}
      </Motion>
    )
  }
}

export default FieldShaker
