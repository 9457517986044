import gql from "graphql-tag"
import SCHOOL_FRAGMENT from "../../school/graphql/school-fragment"
import CATEGORY_FRAGMENT from "./category-fragment"

export default gql`
  query categoriesQuery(
    $first: Int!
    $cursor: String
    $school_id: Int!
    $search: String
    $ids: IDFilter
    $manage: Boolean
  ) {
    context(school_id: $school_id) {
      school {
        ...School
        categories(
          first: $first
          after: $cursor
          search: $search
          ids: $ids
          manage: $manage
        ) {
          total
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              ...Category
            }
          }
        }
      }
    }
  }
  ${SCHOOL_FRAGMENT}
  ${CATEGORY_FRAGMENT}
`
