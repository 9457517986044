import Modal from "@pathwright/ui/src/components/modal/Modal"
import styled from "styled-components"
import { InboxLoading } from "./Inbox"
import InboxFocus from "./focus/InboxFocus"
import InboxFocusItems from "./focus/InboxFocusItems"
import CompletionsContextProvider from "./state/CompletionsContextProvider"
import { useReviewContext } from "./state/ReviewContextProvider"

import InboxLayoutContainer from "./ui/InboxLayourContainer"
import SelectionBackground from "./ui/SelectionBackground"

const Container = styled(InboxLayoutContainer)`
  min-height: 250px;
  .InboxFocusItems {
    padding: 10px;
  }

  .InboxFocusItemsNav {
    margin-bottom: 10px;
  }

  /* this is to force the background to not overlay the screen (fixed) */
  > .SelectionBackground {
    position: absolute !important;
  }
`

const InboxItemModalContainer = styled.div`
  position: relative;
  max-width: 1200px;
  margin: 0px auto;
`

const InboxItemModal = ({ onClose }) => {
  return (
    <Modal onClose={onClose}>
      <SelectionBackground overlay />
      <InboxItemModalContainer>
        {/* <SelectedItemNavigation /> */}
        <InboxFocus />
      </InboxItemModalContainer>
    </Modal>
  )
}

const UserInbox = () => {
  const {
    selected,
    context: { loading, error }
  } = useReviewContext()

  if (loading) return <InboxLoading />

  return (
    <Container className="UserInbox">
      <SelectionBackground overlay />
      <InboxFocusItems />
      {selected && <InboxItemModal />}
    </Container>
  )
}

const UserInboxContainer = ({ userId }) => {
  return (
    <CompletionsContextProvider reviewContext={{ query: { user_id: userId } }}>
      <UserInbox />
    </CompletionsContextProvider>
  )
}

export default UserInboxContainer
