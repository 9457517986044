import { getParam, getRelativeRoute } from "../../utils/urls"
import useCertificatePreview from "./useCertificatePreview"
import useCertificateScope, {
  getCertificateContextParams
} from "./useCertificateScope"

// Returns the url to the web preview which syncs to the current certificate
// form state.
const useCertificateSyncPreview = (certificateState) => {
  const certificatePreview = useCertificatePreview(certificateState)
  // Yes, lazily grabbing certificateScope from url params rather than props
  // though presumably should always be the same.
  const certificateScope = useCertificateScope()

  if (certificatePreview) {
    const { token } = getParam(getRelativeRoute(certificatePreview).search)
    const params = new URLSearchParams(
      getCertificateContextParams(certificateScope)
    )
    params.set("preview", token)
    const url = `${window.location.pathname}?${params.toString()}`
    return url
  }

  return null
}

export default useCertificateSyncPreview
