import gql from "graphql-tag"
import AUTHOR_FRAGMENT from "../../author/graphql/author-fragment"
import RESOURCE_FRAGMENT from "../../resource/graphql/resource-fragment"

export default gql`
  query LibraryResourcesQuery(
    $idFilter: IDFilter
    $categoryIdFilter: IDFilter
    $authorIdFilter: IDFilter
    $categoryNameFilter: StringFilter
    $authorNameFilter: StringFilter
    $resourceTypeFilter: ResourceTypeFilter
    $licensedFromSchoolIdFilter: IDFilter
    $roleFilter: RoleFilter
    $assignConfig: AssignConfig
    $search: String
    $categorySearch: String
    $authorSearch: String
    $first: Int!
    $cursor: String
    $orderBy: String
  ) {
    resources(
      id: $idFilter
      author_ids: $authorIdFilter
      category_ids: $categoryIdFilter
      author_names: $authorNameFilter
      category_names: $categoryNameFilter
      resource_types: $resourceTypeFilter
      licensed_from_school: $licensedFromSchoolIdFilter
      role: $roleFilter
      assign_config: $assignConfig
      search: $search
      category_search: $categorySearch
      author_search: $authorSearch
      first: $first
      after: $cursor
      orderBy: $orderBy
    ) {
      total
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...Resource
          authors(first: 5) {
            edges {
              node {
                ...Author
              }
            }
          }
          groups(
            first: 1
            assign_config: $assignConfig
            include_curriculum: false
          ) {
            total
          }
          curriculum: group(type: curriculum) {
            id
          }
          license_offerings(first: 10, licensing_mode: private_cohorts) {
            edges {
              node {
                id
                cost_per_seat
              }
            }
          }
        }
      }
    }
  }
  ${RESOURCE_FRAGMENT}
  ${AUTHOR_FRAGMENT}
`
