import IconButton from "@pathwright/ui/src/components/button/IconButton"
import useIntersectionLoader from "@pathwright/ui/src/components/hooks/useIntersectionLoader"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import TruncatedText from "@pathwright/ui/src/components/ui/TruncatedText"
import React from "react"
import styled from "styled-components"
import InboxBlankSlate from "../InboxBlankSlate"
import CompletionFilters from "../context/CompletionFilters"
import MarkAllReviewedModal from "../context/review/MarkAllReviewedModal"
import { useReviewContext } from "../state/ReviewContextProvider"
// import BatchItemControls from "./BatchItemControls"
import InboxFocusCompletionItem from "./InboxFocusCompletionItem"
import PeopleFilter from "./PeopleFilter"

const Details = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  color: inherit;
  z-index: 1;

  cursor: pointer;

  .Pathicon {
    margin-right: 10px;
    color: inherit;
  }

  > img {
    margin-right: 10px;
    width: 30px;
    height: 30px;
    border-radius: 4px;
  }

  .Avatar {
    margin-right: 10px;
  }

  h1 {
    font-size: 1em;
    margin: 0px;
    color: inherit;
    display: block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  h2 {
    font-size: 0.8em;
    margin: 0px;
    color: inherit;
  }

  > .info {
    /* one line truncate ellipsis */
    overflow: hidden;
    width: 100%;

    h1 {
    }
  }
`

const CompletionsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StepItemContainer = styled.div`
  width: 100%;
  position: relative;
  /* background-color: #e7e7e7; */
  color: #000;
  margin-bottom: 10px;

  h2 {
    font-size: 13px;
    text-transform: uppercase;
    margin: 0px;
    margin-top: 10px;
  }

  .child-items {
    margin-top: 10px;
  }

  > .row {
    display: flex;
    align-items: center;
  }

  > .IconButton {
    position: absolute;
    top: 10px;
    right: 10px;
    color: rgba(0, 0, 0, 0.5);
  }

  .Details {
    padding: 10px;
    padding-bottom: 5px;
    display: flex;
    /* margin-bottom: 10px; */
    flex-direction: row;
    align-items: center;
    h1 {
      font-size: 18px;
      font-weight: bold;
    }

    .Pathicon {
      font-size: 18px;
      margin-right: 10px;
    }
  }
`

const StepItem = ({ item }) => {
  const {
    root,
    selectedParent,
    actions: { select, selectRoot }
  } = useReviewContext()

  const isDrilledIn = selectedParent.id === item.id && item.id !== root.id

  let completions = [...item.children.values()]

  return (
    <StepItemContainer className="StepItem">
      {isDrilledIn && <IconButton icon="x" onClick={() => selectRoot()} />}
      <Details className="Details" onClick={() => select(item)}>
        <Pathicon icon={item.meta.icon} />
        <h1>{item.meta.title}</h1>
      </Details>
      <CompletionsContainer className="child-items">
        {completions.map((completion) => (
          <InboxFocusCompletionItem
            key={`step-${completion.id}`}
            item={completion}
          />
        ))}
      </CompletionsContainer>
    </StepItemContainer>
  )
}

const LessonItemContainer = styled.div`
  width: 100%;
  color: inherit;
  cursor: pointer;
  width: 100%;
  margin-bottom: 10px;
  background: rgba(245, 245, 245, 0.8);
  backdrop-filter: blur(20px);
  border-radius: 20px;
  padding: 12px;

  > .child-items {
    width: 100%;
    z-index: 1;
  }

  > div > h1 {
    margin-bottom: 0px;
    color: #000;
    font-weight: bold;
    font-size: 20px;
  }
`

const LessonItem = ({ item }) => {
  const steps = [...item.children.values()]

  const {
    actions: { select }
  } = useReviewContext()

  return (
    <LessonItemContainer className="LessonItem">
      <Details onClick={() => select(item)}>
        <h1>{item.meta.title}</h1>
      </Details>
      <div className="child-items">
        {steps.map((step) => (
          <StepItem key={`lesson-${step.id}`} item={step} />
        ))}
      </div>
    </LessonItemContainer>
  )
}

const PathItemContainer = styled.div`
  position: relative;
  width: 100%;
  color: inherit;
  .SelectionBackground {
    z-index: 1;
  }

  header {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px;

    h1 {
      font-size: 1.2em;
      font-weight: bold;
    }

    h2 {
      font-size: 1em;
      font-weight: normal;
    }

    img {
      width: 60px;
      height: 60px;
      border-radius: 10px;
      margin-right: 10px;
    }
    > div {
      display: flex;
      flex-direction: column;
    }
  }
`

const PathItem = ({ item }) => {
  const {
    selectedParent,
    actions: { select }
  } = useReviewContext()

  var showHeader = item.id !== selectedParent.id

  const lessons = Array.from(item.children.values())
  return (
    <PathItemContainer className="PathItem">
      {/* <SelectionBackground item={item} /> */}
      <main>
        {showHeader && (
          <header onClick={() => select(item)}>
            <img src={item.meta.image} />
            <div>
              <h1>{item.meta.title}</h1>
              {item.meta.subtitle && <h2>{item.meta.subtitle}</h2>}
            </div>
          </header>
        )}
        <div className="child-items">
          {lessons.map((lesson) => (
            <LessonItem key={`path-child-${lesson.id}`} item={lesson} />
          ))}
        </div>
      </main>
    </PathItemContainer>
  )
}

const InboxFocusItemsContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-start;
  color: #fff;
  will-change: scroll-position;

  &.selected {
    opacity: 0.5;
  }

  > main {
    display: flex;
    flex-direction: column;
    /* min-height: 100%; */
    position: relative;
    z-index: 2;
    padding-top: 10px;

    > .BlankSlate {
      display: flex;
      flex-grow: 1;
      align-items: center;
    }
  }

  .InboxFocusItemsNav {
    margin-bottom: 10px;
  }

  .selected-parent {
    > .LessonItem,
    > .StepItem {
      background: rgba(245, 245, 245, 0.8);
      backdrop-filter: blur(20px);
      border-radius: 16px;
      padding: 20px;
    }
  }
`

const InboxFocusItemsNavContainer = styled.nav`
  width: 100%;
  display: flex;
  align-items: center;

  .Tooltip {
    min-width: 200px;
    white-space: normal;
    text-align: left;
  }
`

const BreadcrumbsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 1em;
  a {
    cursor: pointer;
    color: #fff;
    font-size: 14px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 2px 4px;

    /* Truncate less of the item, the nearer it is to the end of the breadcumb */
    &:nth-last-of-type(1) {
      flex-shrink: 1;
    }
    &:nth-last-of-type(2) {
      flex-shrink: 100;
    }
    &:nth-last-of-type(3) {
      flex-shrink: 10000;
    }
    &:nth-last-of-type(4) {
      flex-shrink: 100000;
    }
  }
`

const InboxFocusItemsNav = ({ onBatchReview }) => {
  const {
    selectedParent,
    root,
    actions: { select }
  } = useReviewContext()

  const handleSelect = (item) => {
    select(item)
  }

  const handleBatchAction = (item, action) => {
    // TODO: show review all confirm modal
    onBatchReview(true)
  }

  let breadcrumbs = []
  let current = selectedParent
  while (current && current.parent) {
    breadcrumbs.push(current)
    current = current.parent
  }
  breadcrumbs = breadcrumbs.reverse()

  // append the root element if we're drilled into a sub-item
  if (root && root.meta.title === "All" && breadcrumbs.length > 0) {
    breadcrumbs.unshift(root)
  }

  const tooltip = breadcrumbs.map((item) => item.meta.title).join(" / ")

  return (
    <InboxFocusItemsNavContainer className="InboxFocusItemsNav">
      <TruncatedText tooltip={tooltip}>
        <BreadcrumbsContainer>
          {breadcrumbs.map((item, i) => (
            <React.Fragment key={`breadcrumb-${i}-${item.id}`}>
              <a onClick={() => handleSelect(item)}>{item.meta.title}</a>
              {i < breadcrumbs.length - 1 && <span>/</span>}
            </React.Fragment>
          ))}
        </BreadcrumbsContainer>
      </TruncatedText>
      <div style={{ flexGrow: 1 }} />
      <CompletionFilters />
      {/* {selectedParent && <BatchItemControls onSelect={handleBatchAction} />} */}
    </InboxFocusItemsNavContainer>
  )
}

const RootItem = ({ item }) => {
  let paths = Array.from(item.children.values())

  return paths.map((path, i) => <PathItem key={path.id} item={path} />)
}

const InboxItem = ({ item }) => {
  switch (item.type) {
    case "completion":
      return <InboxFocusCompletionItem key={item.id} item={item} />
    case "step":
      return <StepItem key={item.id} item={item} />
    case "lesson":
      return <LessonItem key={item.id} item={item} />
    case "path":
      return <PathItem key={item.id} item={item} />
    case "root":
      return <RootItem key={item.id} item={item} />
    default:
      return null
  }
}

export const InboxCompletionsTree = () => {
  const {
    selectedParent,
    actions: { getItem, select }
  } = useReviewContext()

  let childItems = selectedParent
    ? [...getItem(selectedParent.id).children.values()]
    : []

  const hasItemsToShow =
    childItems.length > 0 ||
    (selectedParent && selectedParent.children.size > 0)

  return (
    <React.Fragment>
      {hasItemsToShow && selectedParent ? (
        <div className="selected-parent">
          <InboxItem key={selectedParent.id} item={selectedParent} />
        </div>
      ) : hasItemsToShow ? (
        <div className="child-items">
          {childItems.map((item) => {
            return <InboxItem key={item.id} item={item} />
          })}
        </div>
      ) : (
        <InboxBlankSlate />
      )}
    </React.Fragment>
  )
}

const IntersectionLoaderContainer = styled.div`
  position: relative;
  height: 50px;
`

// Load more completions when InboxCompletionsTree has been
// scrolled to the bottom.
const IntersectionLoader = () => {
  const {
    context: { hasMore, loadingMore, loadMore }
  } = useReviewContext()

  const intersectionRef = useIntersectionLoader({
    hasMore,
    loadingMore,
    loadMore
  })

  return hasMore ? (
    <IntersectionLoaderContainer ref={intersectionRef}>
      <LoadingCircle inverted />
    </IntersectionLoaderContainer>
  ) : null
}

const InboxFocusItems = () => {
  const { selected, hasParentContext } = useReviewContext()
  const [showBatchReview, setShowBatchReview] = React.useState(false)
  const className = `InboxFocusItems ${selected ? "selected" : ""}`

  return (
    <InboxFocusItemsContainer className={className}>
      <main>
        <InboxFocusItemsNav onBatchReview={setShowBatchReview} />
        {hasParentContext && <PeopleFilter />}
        <InboxCompletionsTree />
        <IntersectionLoader />
      </main>
      {showBatchReview && (
        <MarkAllReviewedModal onClose={() => setShowBatchReview(false)} />
      )}
    </InboxFocusItemsContainer>
  )
}

export default InboxFocusItems
