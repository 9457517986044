import BackgroundImage from "@pathwright/ui/src/components/background/BackgroundImage"
import ThemeBackground from "@pathwright/ui/src/components/background/ThemeBackground"
import { useUIThemeContext } from "@pathwright/ui/src/components/ui/PathwrightUI"
import styled from "styled-components"

const StyledContainer = styled.div`
  position: relative;
  overflow: hidden;
  max-width: 100%;
`

const SchoolLogo = ({ size, background, className }) => {
  const { logo } = useUIThemeContext()
  if (!logo) {
    return null
  }
  const styles = {
    height: size,
    width: size * 4,
    borderRadius: size / 10
  }
  return (
    <StyledContainer className={className} style={styles}>
      {background && <ThemeBackground />}
      <BackgroundImage image={logo} size="90%" />
    </StyledContainer>
  )
}

SchoolLogo.displayName = "SchoolLogo"

export default SchoolLogo
