import { type ModeType } from "../config/constants"

// Content Tokens are JWTs used to cache permission on a per-context basis (e.g. per step)
const getKey = (contextKey: string, mode: ModeType): string => {
  const token = localStorage.auth_token
  const prefix =
    token && token.length ? token.substr(token.length - 8) : "anonymous"
  return `content_token:${prefix}:${contextKey}:${mode}`
}

export const getContentToken = (
  contextKey: string,
  mode: ModeType
): string | null => {
  return localStorage.getItem(getKey(contextKey, mode)) || null
}

export const setContentToken = (
  contextKey: string,
  mode: ModeType,
  token: string
): void => {
  localStorage.setItem(getKey(contextKey, mode), token)
}

export const clearContentToken = (
  contextKey: string,
  mode: ModeType
): undefined | void => {
  return localStorage.removeItem(getKey(contextKey, mode))
}
