import gql from "graphql-tag"
import GROUP_FRAGMENT from "../../group/graphql/group-fragment"
import RESOURCE_FRAGMENT from "../../resource/graphql/resource-fragment"

export default gql`
  query AssignResourceQuery(
    $id: Int
    $assignConfig: AssignConfig
    $search: String
    $cursor: String
  ) {
    resource(id: $id) {
      ...Resource
      cohorts: groups(
        first: 10
        assign_config: $assignConfig
        search: $search
        after: $cursor
      ) {
        total
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            ...Group
          }
        }
      }
    }
  }
  ${RESOURCE_FRAGMENT}
  ${GROUP_FRAGMENT}
`
