export default {
  type: "Text",
  icon: "text-cursor", // TODO: pathicon text => text-cursor
  helpLink:
    "https://help.pathwright.com/create-a-course/adding-content-with-blocks/text-blocks-new",
  menu: {
    category: "text",
    order: 1
  },
  layouts: {
    text: {
      key: "text",
      label: "Paragraph",
      data: {
        html: `<p>Small-scale usage of concrete has been documented to be thousands of years old. Concrete-like materials were used since 6500 BC by the Nabataea traders or Bedouins, who occupied and controlled a series of oases and developed a small empire in the regions of southern Syria and northern Jordan. They discovered the advantages of hydraulic lime, with some self-cementing properties, by 700 BC.</p>`
      }
    },
    two_column: {
      key: "two-column",
      label: "Two-column",
      data: {
        html: `<p>German archaeologist Heinrich Schliemann found concrete floors, which were made of lime and pebbles, in the royal palace of Tiryns, Greece, which dates roughly to 1400–1200 BC.</p>`,
        aside: `<p>Lime mortars were used in Greece, Crete, and Cyprus in 800 BC. The Assyrian Jerwan Aqueduct (688 BC) made use of waterproof concrete. Concrete was used for construction in many ancient structures.</p>`
      }
    }
  },
  schema: `
    type Text implements Block {
      id: ID!
      html: String
      aside: String
    }
  `
}
