import { useQuery } from "@apollo/client"
import Alert from "@pathwright/ui/src/components/alert/Alert"
import UnknownErrorMessage from "@pathwright/ui/src/components/error/UnknownErrorMessage"
import withTranslate from "@pathwright/ui/src/components/lng/withTranslate"
import { gql } from "graphql-tag"
import numeral from "numeral"
import pluralize from "pluralize"
import styled from "styled-components"
import { LEARNING_ACTION_FILTER_NONE } from "./constants"

const StyledStatus = styled.p`
  margin: 0;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
  line-height: 1.3em;
  span + span:before {
    content: "•";
    margin: 0 5px;
  }
`

const ACTIVITY_STATS_QUERY = gql`
  query ActivityStats {
    activityStats {
      discussions
      discussion_responses
      registrations
      steps_completed
    }
  }
`

const ActivityStats = ({
  loading: loadingActivity,
  actionType,
  isBlankActivity,
  isSchoolActivity
}) => {
  const shouldUseActivityStats =
    isSchoolActivity &&
    actionType === LEARNING_ACTION_FILTER_NONE &&
    !isBlankActivity

  const { data, loading, error } = useQuery(ACTIVITY_STATS_QUERY, {
    skip: !shouldUseActivityStats
  })
  const activity_stats = data?.activityStats

  if (loading || loadingActivity) {
    if (loadingActivity)
      return <StyledStatus className="ActivityStatus">Loading...</StyledStatus>
    // Ignoring loading state of activity stats, as the query may time out.
    return null
  }
  if (error) {
    // Failing silently, as most likely the error is that the query timed out.
    return null
    return (
      <StyledStatus className="ActivityStatus">
        <Alert error>
          <UnknownErrorMessage />
        </Alert>
      </StyledStatus>
    )
  }

  if (activity_stats && shouldUseActivityStats) {
    return (
      <StyledStatus className="ActivityStatus">
        {activity_stats.registrations > 0 && (
          <span>
            {numeral(activity_stats.registrations).format("0,0")}
            &nbsp;
            {pluralize("registrations", activity_stats.registrations)}
          </span>
        )}
        {activity_stats.steps_completed > 0 && (
          <span>
            {numeral(activity_stats.steps_completed).format("0,0")}
            &nbsp;steps completed
          </span>
        )}
        {activity_stats.discussions > 0 && (
          <span>
            {numeral(
              activity_stats.discussion_responses + activity_stats.discussions
            ).format("0,0")}
            &nbsp;
            {pluralize(
              "discussions",
              activity_stats.discussion_responses + activity_stats.discussions
            )}
          </span>
        )}
        &nbsp;so far
      </StyledStatus>
    )
  } else {
    return null
    // return (
    //   <StyledStatus className="ActivityStatus">
    //     {numeral(total).format("0,0")}{" "}
    //     {total !== 1
    //       ? t(`activity.filters.${actionType}_plural`)
    //       : t(`activity.filters.${actionType}`)}{" "}
    //     so far
    //   </StyledStatus>
    // )
  }
}

const ActivityStatus = withTranslate(
  ({
    loading,
    actionType,
    activity_user,
    isBlankActivity,
    isSchoolActivity,
    t
  }) => {
    if (isBlankActivity) {
      return (
        <StyledStatus className="ActivityStatus">
          {activity_user
            ? `Once ${
                activity_user.first_name
              } has started, you'll see all their ${t(
                `activity.filters.${actionType}_plural`
              )}`
            : `Once things are up and running, you'll see your members ${t(
                `activity.filters.${actionType}_plural`
              )}`}
          <br />
          For example:
        </StyledStatus>
      )
    } else {
      return (
        <ActivityStats
          loading={loading}
          actionType={actionType}
          isBlankActivity={isBlankActivity}
          isSchoolActivity={isSchoolActivity}
        />
      )
    }
  }
)

ActivityStatus.displayName = "ActivityStatus"

export default ActivityStatus
