import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import React from "react"

const LazyPricingAdapter = React.lazy(() =>
  import("./ExpensivePricingAdapter" /* webpackChunkName: "Pricing" */)
)

const SuspendedPricingAdapter = (props) => (
  <React.Suspense fallback={<LoadingCircle />}>
    <LazyPricingAdapter {...props} />
  </React.Suspense>
)

export default SuspendedPricingAdapter
