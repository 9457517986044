import classnames from "classnames"
import PropTypes from "prop-types"
import styled from "styled-components"
import Screensize, { useScreensizeContext } from "../ui/Screensize"
import View from "../ui/View"
import { parseRgba } from "../utils/colors"
import "./BackgroundImage.css"
import DotBackground from "./DotBackground"
import { overlay } from "./ui"
const ContainerView = styled(View)`
  ${overlay}
`

const ZoomContainer = styled.div`
  ${overlay}
`

const BackgroundImage = (props) => {
  const {
    image,
    overlay,
    dots,
    zoom,
    style,
    size,
    position,
    className,
    onClick,
    // View props
    rounded,
    roundedTop,
    roundedBottom,
    shadow,
    screensizeSelect,
    children
  } = props

  const overlayColor = overlay ? parseRgba(overlay) : null

  const screensize = useScreensizeContext()
  // optionally select props based on screensize
  const screensizeOptions = {
    ...props,
    ...Screensize.select(screensizeSelect)({ screensize })
  }

  const bg = (
    <ContainerView
      className={classnames("BackgroundImage", className, {
        zoom
      })}
      background={!!image}
      onClick={onClick}
      rounded={rounded}
      roundedTop={roundedTop}
      roundedBottom={roundedBottom}
      shadow={shadow}
      screensizeSelect={screensizeSelect}
      // if not explicitly rounded, inhert border-radius
      style={
        !rounded &&
        !roundedTop &&
        !roundedBottom &&
        !screensizeOptions.rounded &&
        !screensizeOptions.roundedTop &&
        !screensizeOptions.roundedBottom
          ? {
              ...style,
              borderRadius: "inherit"
            }
          : style
      }
    >
      {image && (
        <div
          key={image}
          className="BackgroundImage__image"
          style={{
            backgroundImage:
              image.startsWith("http") || image.startsWith("file:///")
                ? `url(${image})`
                : image, // allowing for other background images, like linear-gradients
            backgroundRepeat: "no-repeat",
            backgroundPosition: position,
            backgroundSize: size,
            borderRadius: "inherit"
          }}
        />
      )}
      {overlayColor && (
        <div
          key={overlayColor}
          className="BackgroundImage__overlay"
          style={{
            backgroundColor: overlayColor,
            borderRadius: "inherit"
          }}
        />
      )}
      {dots && <DotBackground dotSize={4} dotSpacing={50} />}
      {children}
    </ContainerView>
  )

  // We need to wrap the zoomed bg in an overlay hidden container
  // since the zoom animation expands the dimensions of the bg.
  return zoom ? <ZoomContainer>{bg}</ZoomContainer> : bg
}

BackgroundImage.displayName = "BackgroundImage"

BackgroundImage.propTypes = {
  image: PropTypes.string,
  size: PropTypes.string,
  position: PropTypes.string,
  overlay: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]),
  dots: PropTypes.bool,
  // whether the background show animate an initial zoom
  zoom: PropTypes.bool
}

BackgroundImage.defaultProps = {
  dots: false,
  zoom: false
}

export default BackgroundImage
