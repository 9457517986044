export const loadAuthors = function ({ match }) {
  window.App.getStore("authorAdmin").action.loadAuthors()
  // aka onLeave childCard route
  if (!match.params.author_id) {
    return window.App.getStore("authorAdmin").action.unselectAuthor()
  }
}

export const selectAuthor = function ({ match }) {
  let { author_id } = match.params
  author_id = author_id !== "new" ? author_id : null
  return window.App.getStore("authorAdmin").action.selectAuthor(author_id)
}

export const unselectAuthor = () =>
  window.App.getStore("authorAdmin").action.unselectAuthor()
