import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  VStack,
  useDisclosure
} from "@chakra-ui/react"
import classnames from "classnames"
import { useEffect } from "react"
import styled from "styled-components"
import OverlayTrigger from "../overlay/OverlayTrigger"
import Avatar from "./Avatar"
import AvatarCard from "./AvatarCard"
import "./AvatarCardTrigger.css"

const StyledOverlayTrigger = styled(OverlayTrigger)`
  .Overlay {
    cursor: initial;
  }
`

const StyledAvatarCard = styled(AvatarCard)`
  border-radius: 5px;
  padding: 1em;

  .Avatar {
    cursor: pointer;
  }
`

const AvatarCardTrigger = (props) => {
  const {
    size,
    user,
    meta,
    renderDropdown,
    onClickAvatar,
    trigger,
    placement,
    alignment,
    delayShow,
    delayHide,
    active,
    children
  } = props
  const className = classnames("AvatarCardTrigger", props.className)
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure()
  const {
    isOpen: isAvatarCardOpen,
    onOpen: onOpenAvatarCard,
    onClose: onCloseAvatarCard
  } = useDisclosure()

  useEffect(() => {
    if (isAvatarCardOpen !== isOpen) onToggle()
  }, [isAvatarCardOpen])

  return (
    <Popover
      trigger={isAvatarCardOpen ? "click" : "hover"}
      width="auto"
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
    >
      <PopoverTrigger>
        <VStack spacing={0}>
          <Avatar
            user={user}
            size={size}
            onClick={onClickAvatar}
            style={{ marginBottom: ".2em" }}
          />
          {children}
        </VStack>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          className={className}
          width="auto"
          borderRadius="5px"
          boxShadow="0 0 10px 2px rgba(0, 0, 0, 0.2)"
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <PopoverArrow backgroundColor="white" />
          <PopoverBody p={0}>
            <StyledAvatarCard
              user={user}
              meta={meta}
              onClickAvatar={onClickAvatar}
              dropdownTriggerProps={{
                renderDropdown,
                onShow: onOpenAvatarCard,
                onHide: onCloseAvatarCard
              }}
            />
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )

  return (
    <StyledOverlayTrigger
      trigger={trigger}
      className={className}
      placement={placement}
      alignment={alignment}
      delayShow={delayShow}
      delayHide={delayHide}
      active={active}
      overlay={
        <StyledAvatarCard
          user={user}
          meta={meta}
          renderDropdown={renderDropdown}
          onClickAvatar={onClickAvatar}
        />
      }
    >
      <Avatar user={user} size={size} onClick={onClickAvatar} />
    </StyledOverlayTrigger>
  )
}

AvatarCardTrigger.displayName = "AvatarCardTrigger"

AvatarCardTrigger.propTypes = {
  user: Avatar.propTypes.user,
  size: Avatar.propTypes.size,
  onClickAvatar: AvatarCard.propTypes.onClickAvatar,
  meta: AvatarCard.propTypes.meta,
  renderDropdown: AvatarCard.propTypes.renderDropdown
}

AvatarCardTrigger.defaultProps = {
  size: Avatar.defaultProps.size,
  meta: Avatar.defaultProps.meta,
  renderDropdown: Avatar.defaultProps.renderDropdown,
  trigger: "hover",
  placement: "bottom",
  alignment: "center",
  delayShow: 300,
  active: true
}

export default AvatarCardTrigger
