import DropdownDivider from "./DropdownDivider"
import DropdownItem from "./DropdownItem"
import DropdownMenu from "./DropdownMenu"
import DropdownTrigger from "./DropdownTrigger"

export default {
  Item: DropdownItem,
  Menu: DropdownMenu,
  Trigger: DropdownTrigger,
  Divider: DropdownDivider
}
