import gql from "graphql-tag"
import ORDER_FRAGMENT from "./order-fragment"

export default gql`
  mutation CreatePlatformSubscriptionOrder(
    $billing_plan_id: Int!
    $interval: Int!
    $schoolId: Int
  ) {
    createPlatformSubscriptionOrder(
      billing_plan_id: $billing_plan_id
      interval: $interval
      school_id: $schoolId
    ) {
      ...Order
    }
  }
  ${ORDER_FRAGMENT}
`
