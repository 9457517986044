import { Button, chakra, Text, VStack } from "@chakra-ui/react"
import Pathicon from "@pathwright/web/src/modules/pathicon/Pathicon"
import { ActionType } from "../../../../lib/types"
import { useSpaceContext } from "../../../space/api/context"
import AuthFormAlert from "../../components/AuthFormAlert"
import AuthFormButton from "../../components/AuthFormButton"
import AuthFormHeading from "../../components/AuthFormHeading"
import AuthMotion from "../../components/AuthMotion"
import useColorModeBranding from "../../utils/useColorModeBranding"
import PasswordsForm from "../change/PasswordsForm"

const PasswordCreateView = ({
  loading,
  success,
  error,
  onSubmit,
  onContinue
}: ActionType & { onContinue: Function }) => {
  const colorScheme = useColorModeBranding()
  const { user } = useSpaceContext()

  if (success) {
    return (
      <AuthMotion>
        <VStack spacing={4}>
          <Pathicon icon="check-circle-fill" color="border" size={30} />
          <AuthFormHeading>Password updated</AuthFormHeading>
          <Text>You can use your new password to sign-in from now on.</Text>
          <Button
            variant="solid"
            colorScheme={colorScheme}
            onClick={() => onContinue()}
          >
            Continue
          </Button>
        </VStack>
      </AuthMotion>
    )
  }

  return (
    <AuthMotion>
      <chakra.form onSubmit={onSubmit} width="100%">
        <VStack spacing={4} width="100%">
          <AuthFormAlert error={error} />
          <AuthFormHeading>Create a new password</AuthFormHeading>
          <Text textAlign="center">
            Passwords must be six characters, include an upper and lower case
            letter, and at least one number.
          </Text>
          <Text>
            <b>Email:</b> {user?.email}
          </Text>
          <PasswordsForm isSubmitting={loading}>
            <AuthFormButton loading={loading}>Update Password</AuthFormButton>
          </PasswordsForm>
        </VStack>
      </chakra.form>
    </AuthMotion>
  )
}

export default PasswordCreateView
