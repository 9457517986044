import Button from "@pathwright/ui/src/components/button/Button"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import { useObserveSize } from "@pathwright/ui/src/components/observers/ObserveSizeContext"
import { useScrollContext } from "@pathwright/ui/src/components/scroll/ScrollView"
import { sizes } from "@pathwright/ui/src/components/utils/styles"
import classnames from "classnames"
import styled, { keyframes } from "styled-components"
import ActivityBlank from "./ActivityBlank"
import ActivityDay from "./ActivityDay"
import ActivityFilter from "./ActivityFilter"
import ActivityStatus from "./ActivityStatus"
import { groupActivityByDay } from "./utils"

const StyledContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: white;
`

const StyledFooter = styled.footer`
  position: relative;
  padding: 20px;
  width: 100%;
  max-width: 1000px;
`

const delayReveal = keyframes`
	99% {
		visibility: hidden;
	}
	100% {
		visibility: visible;
	}
`

const StyledFeed = styled.ul`
  position: relative;
  width: 100%;
  max-width: 1000px;
  padding: 30px 20px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  visibility: hidden;
  animation: ${delayReveal} 500ms;
  animation-fill-mode: forwards;
  list-style-type: none;
`

const ActivityTimeline = ({ children, ...props }) => {
  const {
    activity,
    hasMore,
    singleColumn,
    isUserActivity,
    isStepActivity,
    isCourseActivity,
    loading,
    loadMore,
    controlled,
    canViewAvatarCard,
    canViewAvatarProfile
  } = props

  const { rectValue, setNode } = useObserveSize()

  const scrollContext = useScrollContext()
  const hasResults = activity && activity.length > 0
  const isBlankActivity = !loading && !hasResults
  const days = hasResults ? groupActivityByDay(activity) : new Map()

  if (!hasResults && !loading && children) {
    return children({ inverted: true })
  }

  return (
    <StyledContainer
      ref={setNode}
      className={classnames("ActivityTimeline", {
        ["ActivityTimeline__single-column"]:
          singleColumn || rectValue.width < sizes.tablet
      })}
    >
      {!controlled && <ActivityFilter {...props} />}
      <ActivityStatus {...props} isBlankActivity={isBlankActivity} />
      {isBlankActivity ? (
        <ActivityBlank {...props} />
      ) : (
        <StyledFeed className="ActivityTimeline__feed">
          {[...days.keys()].map((day, index) => (
            <li key={day}>
              <ActivityDay
                label={day}
                startRight={index % 2}
                activity={days.get(day)}
                isUserActivity={isUserActivity}
                isStepActivity={isStepActivity}
                isCourseActivity={isCourseActivity}
                canViewAvatarCard={canViewAvatarCard}
                canViewAvatarProfile={canViewAvatarProfile}
              />
            </li>
          ))}
        </StyledFeed>
      )}
      {hasMore && (
        <StyledFooter>
          {(!!scrollContext && scrollContext.canScroll) || loading ? (
            <LoadingCircle inverted />
          ) : (
            <Button inverted stretch styleType="empty" onClick={loadMore}>
              Load More
            </Button>
          )}
        </StyledFooter>
      )}
    </StyledContainer>
  )
}

ActivityTimeline.displayName = "ActivityTimeline"

export default ActivityTimeline
