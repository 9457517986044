import { useUIThemeContext } from "@pathwright/ui/src/components/ui/PathwrightUI"
import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import React, { useMemo } from "react"
import COHORT_SHARE_CONTEXT_QUERY from "../graphql/cohort-share-context-query"
import GROUP_SHARE_CONTEXT_QUERY from "../graphql/group-share-context-query"

function getShareContextQuery(context) {
  switch (context.type) {
    case "cohort":
      return COHORT_SHARE_CONTEXT_QUERY
    case "group":
      return GROUP_SHARE_CONTEXT_QUERY
  }
}

function getShareData(context, query) {
  if (query.data) {
    switch (context.type) {
      case "cohort": {
        if (query.data.cohort) {
          const { cohort, sharePermissions } = query.data
          return {
            cohort,
            meta: {
              title: cohort.resource.name,
              description: cohort.resource.short_description,
              image: cohort.resource.image_raw,
              // non-shared keys
              visibility: cohort.visibility,
              accessEnds: cohort.access_ends,
              registrationEnds: cohort.registration_ends
            },
            permissions: {
              ...sharePermissions
            }
          }
        }
        break
      }
      case "group": {
        if (query.data.school?.group) {
          const {
            school: { group },
            sharePermissions
          } = query.data

          return {
            group,
            meta: {
              title: group.name,
              description: group.description,
              // non-shared keys
              type: group.type,
              seatLimit: group.seat_limit,
              seatsAvailable: group.seats_available,
              seatsFilled: group.seats_filled,
              groupSubscriptionEnds:
                group.group_subscription?.subscription?.canceled_dtime
            },
            permissions: {
              ...sharePermissions
            }
          }
        }
        break
      }
    }
  }

  return {
    meta: {},
    permissions: {
      invite_to_roles: {}
    }
  }
}

const useShareData = ({ context } = {}) => {
  const theme = useUIThemeContext()
  const query = useQuery(getShareContextQuery(context), {
    variables: context
  })

  const data = useMemo(() => {
    const data = getShareData(context, query)
    // Default the image to the theme background.
    data.meta.image ||= theme.backgroundImage
    return data
  }, [query.data])

  return data
}

const ShareDataContext = React.createContext()

export const useShareDataContext = () => React.useContext(ShareDataContext)

const ShareDataContextProvider = ({ children, context }) => {
  const shareData = useShareData({
    context
  })

  const value = {
    ...shareData,
    context
  }

  return (
    <ShareDataContext.Provider value={value}>
      {typeof children === "function" ? children(value) : children}
    </ShareDataContext.Provider>
  )
}

ShareDataContextProvider.displayName = "ShareDataContextProvider"

export default ShareDataContextProvider
