class AuthorCollection extends require("lib/static-shim").default(
  require("lib/core/collection").default
) {
  static initClass() {
    this.prototype.model = require("./author").default

    this.prototype.comparator = "order"
  }

  initialize(models, options) {
    this.options = options
    return (this.loadAll =
      this.options != null ? this.options.loadAll : undefined)
  }

  url() {
    let url = this.urlRoot(`/api/private/library/author/`)
    if (this.loadAll) {
      url += "?manage=1"
    }
    return url
  }
}
AuthorCollection.initClass()

export default AuthorCollection
