import { ToastId, useToast } from "@chakra-ui/react"
import { CheckIcon } from "@pathwright/pathicons"
import { useEffect, useRef } from "react"
import { useScopedTranslation } from "../../../../lib/lng/TranslationsProvider"

export const PASSWORD_RESET_SUCCESS_KEY = "passwordResetSuccess" as const

export type PasswordResetConfirmToastProps = {
  hideToast: boolean
}

const PasswordResetConfirmToast = ({
  hideToast
}: PasswordResetConfirmToastProps) => {
  const { t } = useScopedTranslation("auth.confirm_reset_password_form")
  const toast = useToast()
  const toastRef = useRef<ToastId>()

  useEffect(() => {
    if (sessionStorage.getItem(PASSWORD_RESET_SUCCESS_KEY)) {
      sessionStorage.removeItem(PASSWORD_RESET_SUCCESS_KEY)
      toastRef.current = toast({
        title: "Password changed",
        description: t("success_message"),
        status: "success",
        duration: 60000,
        isClosable: true,
        icon: <CheckIcon />
      })
    }
  }, [])

  // Auto-hide toast.
  useEffect(() => {
    if (hideToast && toastRef.current) toast.close(toastRef.current)
  }, [hideToast])

  // Hide toast when unmounting.
  useEffect(() => {
    return () => {
      if (toastRef.current) toast.close(toastRef.current)
    }
  }, [])

  return null
}

export default PasswordResetConfirmToast
