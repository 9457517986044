import gql from "graphql-tag"
import ORDER_FRAGMENT from "./order-fragment"

export default gql`
  mutation CreateLifetimeSchoolSubscriptionOrder(
    $plan_id: Int!
    $plan_visibility_code: String
    $coupon_code: String
  ) {
    createLifetimeSchoolSubscriptionOrder(
      plan_id: $plan_id
      plan_visibility_code: $plan_visibility_code
      coupon_code: $coupon_code
    ) {
      ...Order
    }
  }
  ${ORDER_FRAGMENT}
`
