import classnames from "classnames"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import Button from "./Button"

const StyledButton = styled(Button)`
  .Button__content-wrapper {
    height: 100%;
  }
`

const IconButton = ({
  icon,
  rotate,
  flip,
  label,
  children,
  rounded,
  size,
  sizeUnit,
  buttonSize,
  iconProps,
  ...rest
}) => {
  const className = classnames(
    "IconButton",
    `IconButton__${icon}`,
    rest.className
  )
  const styles = {
    width: `${size}${sizeUnit}`,
    height: `${size}${sizeUnit}`,
    lineHeight: `${size}${sizeUnit}`,
    padding: 0
  }
  const style = { ...styles, ...rest.style }

  if (rounded) {
    style.borderRadius = "8px"
  }

  return (
    <StyledButton
      {...rest}
      className={className}
      style={style}
      size={buttonSize}
      iconProps={{
        icon,
        rotate,
        flip,
        ...iconProps
      }}
      label={label}
    >
      {({ renderChildren }) => (
        <React.Fragment>
          {renderChildren()}
          {children}
        </React.Fragment>
      )}
    </StyledButton>
  )
}

IconButton.displayName = "IconButton"

IconButton.propTypes = {
  ...Button.propTypes,
  size: PropTypes.number
}

IconButton.defaultProps = {
  styleType: "blank",
  buttonSize: "medium", // determines the button font-size
  size: 30,
  sizeUnit: "px",
  rounded: false,
  label: null
}

export default IconButton
