import { i18n } from "@pathwright/ui/src/components/lng/I18nextProvider"
import DiscussionVote from "discussion/models/discussion-vote"

let DiscussionVoteBehavior
export default DiscussionVoteBehavior = (function () {
  DiscussionVoteBehavior = class DiscussionVoteBehavior extends (
    require("lib/static-shim").default(Marionette.Behavior)
  ) {
    static initClass() {
      ;`\
Handles voting/unvoting for a discussion view\
`

      this.prototype.ui = { vote: "[data-action='vote']" }

      this.prototype.events = { "click @ui.vote": "onToggleVote" }

      this.prototype.defaults = {
        getVote() {
          // initialize the vote model
          let vote
          const { model } = this.view
          if (model.get("discussion")) {
            // This is a response
            vote = new DiscussionVote({
              discussion_id: model.get("discussion"),
              response_id: model.id
            })
          } else {
            // This is a vote for a discussion
            vote = new DiscussionVote({
              discussion_id: model.id
            })
          }
          vote.set({
            user: Pathwright.request("get:user").id,
            context: model.get("context_str")
          })
          return vote
        },

        getVotePrompt() {
          if (!this.model.get("user_has_voted")) {
            return i18n.t("discussion.vote.upvote")
          } else {
            return i18n.t("discussion.vote.remove_vote")
          }
        }
      }
    }

    modelEvents() {
      return { "change:user_has_voted": "_setVotePrompt" }
    }

    onBeforeRender() {
      return this._setVotePrompt()
    }

    canVote() {
      const permissions = this.view.model.get("user_permissions")
      if (!permissions) return true
      // don't permit use to cast vote
      return permissions.can_vote
    }

    onToggleVote(e) {
      // prevent bubbling to parent with vote behavior
      e.stopPropagation()
      e.preventDefault()
      const vote = _.bind(this.options.getVote, this)()
      const { model } = this.view

      if (!this.canVote()) return

      if (!model.get("user_has_voted")) {
        return vote
          .save()
          .then((result) => {
            model.set({
              num_votes: model.get("num_votes") + 1,
              user_has_voted: true
            })
            this.options.onVoteToggled &&
              _.bind(this.options.onVoteToggled, this.view)(true)
            return this._setVotePrompt()
          })
          .fail((err) => console.log("error voting!", err))
      } else {
        // Set the ID so we can DELETE
        vote.set("id", "foobar")
        return vote
          .destroy()
          .then((result) => {
            model.set({
              num_votes: model.get("num_votes") - 1,
              user_has_voted: false
            })
            this.options.onVoteToggled &&
              _.bind(this.options.onVoteToggled, this.view)(false)
            return this._setVotePrompt()
          })
          .fail((err) => console.log("error unvoting!", err))
      }
    }

    _setVotePrompt() {
      if (this.canVote()) {
        this.view.model.set({
          vote_prompt: _.bind(this.options.getVotePrompt, this.view)()
        })
        // Have to rebind tooltips here (dumb!)
        this.$("[data-toggle='tooltip']").tooltip("destroy")
        return this.$("[data-toggle='tooltip']").tooltip()
      }
    }
  }
  DiscussionVoteBehavior.initClass()
  return DiscussionVoteBehavior
})()
