import { media } from "@pathwright/ui/src/components/utils/styles"
import styled from "styled-components"

export const StyledLabel = styled.div`
  overflow: hidden;
  width: 100%;
  ${media.max.phone`
      padding-right: 0;
    `} > h4, > h5, > h6 {
    margin: 2px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

export const StyledRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  > * + * {
    display: flex;

    i {
      align-self: flex-end;
      margin-left: -5px;
    }

    &:before {
      content: "•";
      margin-left: 8px;
      margin-right: 8px;
    }
  }
`
