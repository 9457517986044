import { Flex } from "@chakra-ui/react"
import "react-select/dist/react-select.css"
import CopyLink from "./CopyLink"
import NativeLink from "./NativeLink"
import QRCodeLink from "./QRCodeLink"

const ShareLink = ({ url }) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      data-testid={`share-link-url-${url}`}
    >
      <CopyLink url={url} />
      <NativeLink url={url} />
      <QRCodeLink url={url} />
    </Flex>
  )
}

export default ShareLink
