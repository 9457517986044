const createID = () => Math.random().toString(36).substr(2, 9)

export default {
  paragraph: {
    key: "paragraph",
    label: "Open response",
    helpLink:
      "https://help.pathwright.com/create-a-course/adding-content-with-blocks/paragraph-question-block-new",
    data: {
      question: `What are the key characteristics of Gothic architecture?`,
      type: "Paragraph"
    }
  },
  multipleChoice: {
    key: "multipleChoice",
    label: "Multiple choice",
    helpLink:
      "https://help.pathwright.com/create-a-course/adding-content-with-blocks/multiple-choice-question-block-new",
    data: {
      type: "MultipleChoice",
      question: `Who designed Hearst Castle?`,
      options: [
        {
          value: createID(),
          label: "<p>Orson Welles</p>",
          order: 1
        },
        {
          value: createID(),
          label: "<p>Marian Davies</p>",
          isCorrectAnswer: true,
          order: 2
        },
        {
          value: createID(),
          label: "<p>William Randolph Hearst</p>",
          order: 3
        },
        {
          value: createID(),
          label: "<p>Julia Morgan</p>",
          order: 4
        }
      ]
    }
  },
  checkBoxes: {
    key: "checkBoxes",
    label: "Multiple response",
    helpLink:
      "https://help.pathwright.com/create-a-course/adding-content-with-blocks/checkboxes-question-block-new",
    data: {
      type: "CheckBoxes",
      question: `Which are defining characteristics of Gothic architecture?`,
      description: "Check all that apply",
      options: [
        {
          value: createID(),
          label: "<p>Spires</p>",
          isCorrectAnswer: true,
          order: 1
        },
        {
          value: createID(),
          label: "<p>Gargoyles</p>",
          isCorrectAnswer: true,
          order: 2
        },
        {
          value: createID(),
          label: "<p>Flying Buttress</p>",
          isCorrectAnswer: true,
          order: 3
        },
        {
          value: createID(),
          label: "<p>Transepts</p>",
          order: 4
        }
      ]
    }
  },
  matching: {
    key: "matching",
    label: "Matching",
    helpLink:
      "http://help.pathwright.com/en/articles/4216057-text-matching-block",
    data: {
      type: "Matching",
      question: `Match the descriptions below with the Greek column Order that it best describes.`,
      description: "",
      options: [
        {
          value: createID(),
          label:
            "Slender, fluted pillars with a large base and two opposed volutes in the echinus of the capital.",
          order: 1
        },
        {
          value: createID(),
          label:
            "Short, faceted, and heavy columns, with plain, round capitals and no base.",
          order: 2
        },
        {
          value: createID(),
          label:
            "The most elaborate style of column; characterized by elaborate molding.",
          order: 3
        },
        {
          value: createID(),
          label: "",
          order: 4
        }
      ],
      matches: [
        {
          value: createID(),
          label: "Ionic",
          order: 1
        },
        {
          value: createID(),
          label: "Doric",
          order: 2
        },
        {
          value: createID(),
          label: "Corinthian",
          order: 3
        },
        {
          value: createID(),
          label: "Caryatid",
          order: 4
        }
      ]
    }
  }
}
