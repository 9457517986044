import Dashboard from "@pathwright/web/src/modules/home/Dashboard"
import { canGoHome } from "belltower/utils"
import StoreOrLibraryBrowse from "store/StoreOrLibraryBrowse"

const HomeOrLibraryBrowse = (props) => {
  return canGoHome() ? (
    <Dashboard {...props} />
  ) : (
    <StoreOrLibraryBrowse {...props} schoolId={window.school.id} />
  )
}

HomeOrLibraryBrowse.displayName = "HomeOrLibraryBrowse"

export default HomeOrLibraryBrowse
