// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.

class CompositeView extends Marionette.CompositeView {
  serializeGlobal() {
    return {
      school: window.App.request("get:school").toJSON(),
      user: window.App.request("get:user").toJSON()
    }
  }
}

export default CompositeView
