import Card from "@pathwright/ui/src/components/card/Card"
import ConnectStores from "lib/core/store/components/ConnectStores"
import GroupCommerceFormContainer from "./GroupCommerceFormContainer"

const GroupCommerceCard = ({
  card,
  showStatus,
  showLeaveStatus,
  statusMessage,
  statusLeaveMessage
}) => (
  <Card
    title="Commerce"
    showStatus={showStatus}
    showLeaveStatus={showLeaveStatus}
    statusMessage={statusMessage}
    statusLeaveMessage={statusLeaveMessage}
    card={card}
  >
    <GroupCommerceFormContainer
      onPristine={() => card.setCardDirty(false)}
      onDirty={() => card.setCardDirty(true)}
    />
  </Card>
)

GroupCommerceCard.displayName = "GroupCommerceCard"

const mapStateToProps = function (state, ownProps) {
  const { is_loading_group, is_saving_group, group_success } =
    state.groupAdminStore.state

  const showStatus = is_loading_group || is_saving_group
  const showLeaveStatus = group_success != null
  const statusMessage = (() => {
    if (is_loading_group) {
      return "Loading..."
    } else if (is_saving_group) {
      return "Saving..."
    }
  })()
  const statusLeaveMessage = group_success ? "Saved" : undefined

  const props = _.assign(
    {},
    {
      showStatus,
      showLeaveStatus,
      statusMessage,
      statusLeaveMessage
    },
    ownProps
  )
  return props
}

const connectStores = [
  {
    store: "groupAdmin",
    watch: ["is_loading_group", "is_saving_group", "group"]
  }
]

export default ConnectStores(
  GroupCommerceCard,
  connectStores,
  {},
  mapStateToProps
)
