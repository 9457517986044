import {
  Button,
  HStack,
  Heading,
  LightMode,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverAnchor,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Spinner,
  VStack,
  useOutsideClick
} from "@chakra-ui/react"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import "focus-visible/dist/focus-visible"
import PropTypes from "prop-types"
import React, { useRef } from "react"
import FocusLock from "react-focus-lock"
import ShareGate from "./gate/ShareGate"
import LearnerLink from "./link/LearnerLink"
import ShareDataContextProvider, {
  useShareDataContext
} from "./shared/ShareDataContextProvider"
import ShareUIContextProvider, {
  useShareUIContext
} from "./shared/ShareUIContextProvider"
import { ShareTabList, ShareTabPanels, ShareTabs } from "./tabs/ShareTabs"

// Default Share trigger component
const DefaultShareTrigger = React.forwardRef(
  ({ onToggle, title, icon }, ref) => (
    <Button
      ref={ref}
      display="block"
      colorScheme={"brand"}
      p="0.5em 1em"
      border="none"
      mx="auto"
      onClick={onToggle}
      cursor="pointer"
    >
      <HStack spacing={1} alignItems="center">
        <Pathicon icon={icon} style={{ width: "16px", height: "16px" }} />
        <Heading as="span" size="h7" fontWeight="bold" color="white">
          {title}
        </Heading>
      </HStack>
    </Button>
  )
)

// Default method for attaching the Share popover to a component.
const attachShare = ({ renderTrigger }) => renderTrigger(DefaultShareTrigger)

const ShareHeader = () => {
  const {
    permissions: { share_access, share_add_access, share_track_access }
  } = useShareDataContext()

  return !share_access?.access !== "GATED" &&
    (share_add_access?.access !== "NONE" ||
      share_track_access?.access !== "NONE") ? (
    <ShareTabList />
  ) : null
}

const ShareContent = () => {
  const {
    permissions: {
      share_access,
      share_link_access,
      share_add_access,
      share_track_access
    }
  } = useShareDataContext()

  return share_access ? (
    share_access.access === "GATED" ? (
      <ShareGate />
    ) : share_add_access.access !== "NONE" ||
      share_track_access.access !== "NONE" ? (
      <ShareTabPanels />
    ) : share_link_access.access !== "NONE" ? (
      <LearnerLink />
    ) : null
  ) : (
    <VStack py="150px" color="gray.500">
      <Spinner />
    </VStack>
  )
}

const contentProps = {
  className: "Share chakra-ui-reset-css",
  borderRadius: "var(--card-border-radius-lg)",
  sx: {
    "--card-padding": "var(--chakra-space-4)",
    "--card-border-radius-lg": "16px",
    "--card-border-radius-sm": "8px",
    ".focus-visible, label>.focus-visible + div": {
      boxShadow: "outline !important"
    },
    // Outline the react-select Control...
    "[data-focus-visible]:not([data-active])[data-focus]": {
      boxShadow: "outline !important"
    },
    // ...and not the nested input.
    "[data-focus-visible]:not([data-active])[data-focus] input.focus-visible": {
      boxShadow: "none !important"
    }
  }
}

const ModalShare = ({ children, triggerIcon, triggerTitle, hideTabs }) => {
  const { context, isOpen, onClose, onToggle } = useShareUIContext()

  const attach = children || attachShare

  // https://chakra-ui.com/docs/components/modal/usage#focus-on-specific-element
  const initialRef = useRef(null)
  const finalRef = React.useRef(null)

  return (
    <>
      {attach({
        renderTrigger: (Trigger) => (
          <Trigger
            onToggle={onToggle}
            title={triggerTitle}
            icon={triggerIcon}
          />
        )
      })}
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <FocusLock returnFocus persistentFocus={false}>
          <ModalContent {...contentProps}>
            <ShareDataContextProvider context={context}>
              <ModalHeader p={0} pos="relative">
                {!hideTabs && <ShareHeader />}
              </ModalHeader>
              <ModalBody p={0}>
                <ShareContent />
              </ModalBody>
              {/* <ModalCloseButton /> */}
            </ShareDataContextProvider>
          </ModalContent>
        </FocusLock>
      </Modal>
    </>
  )
}

const PopoverShare = ({
  children,
  placement,
  triggerIcon,
  triggerTitle,
  hideTabs,
  portal
}) => {
  const { context, isOpen, onClose, canClose, onOpen, onToggle } =
    useShareUIContext()

  // https://chakra-ui.com/docs/components/popover/usage#focus-an-element-when-popover-opens
  const initialFocusRef = useRef()

  // Allow children as a function to take the place of the default attachShare.
  const attach = children || attachShare

  const popoverRef = useRef()
  const popoverTriggerRef = useRef()

  useOutsideClick({
    ref: popoverRef,
    handler: (e) =>
      canClose(e) && !popoverTriggerRef.current?.contains(e.target) && onClose()
  })

  // In some cases, wrapping the Popover content in a Portal provides a better UX.
  // One case is when the Share card is rendered withing an inlined Card where the
  // card width is narrower than the Share card and clips the Share card.
  // Portalling renders the content outside of the Card and allows the full content to
  // be visible.
  const PopoverContentWrapper = portal ? Portal : React.Fragment

  return (
    <Popover
      isOpen={isOpen}
      onOpen={onOpen}
      placement={placement}
      isLazy
      lazyBehavior="keepMounted"
      initialFocusRef={initialFocusRef}
      // Prevent portalled share card from flipping.
      flip={false}
    >
      {attach({
        renderAnchor: (ShareAnchor, props) => (
          <PopoverAnchor>
            <ShareAnchor {...props} />
          </PopoverAnchor>
        ),
        renderTrigger: (ShareTrigger, props) => (
          <PopoverTrigger>
            <ShareTrigger
              ref={popoverTriggerRef}
              icon={triggerIcon}
              title={triggerTitle}
              onToggle={onToggle}
              {...props}
            />
          </PopoverTrigger>
        )
      })}
      <FocusLock returnFocus persistentFocus={false}>
        <PopoverContentWrapper>
          <PopoverContent
            minW={{ base: "100vw", sm: "0" }}
            ref={popoverRef}
            width="inherit"
            {...contentProps}
          >
            <ShareDataContextProvider context={context}>
              <PopoverArrow />
              <PopoverHeader p={0} borderBottom="none">
                {!hideTabs && <ShareHeader />}
              </PopoverHeader>
              <PopoverBody
                width={["100%", "min(500px, 100vw)"]}
                maxW="100vw"
                p={0}
              >
                <ShareContent />
              </PopoverBody>
            </ShareDataContextProvider>
          </PopoverContent>
        </PopoverContentWrapper>
      </FocusLock>
    </Popover>
  )
}

// Doesn't check share permissions for determining how to render.
export const ShareDumb = ({
  children,
  // ui context
  context,
  openWithKeys,
  onToggleIsOpen,
  // popover/modal
  displayType,
  // popover placement
  placement,
  // trigger label/icon
  triggerLabel,
  triggerIcon,
  // whether to hide tabs
  hideTabs,
  // whether to render Popover in portal
  portal
}) => {
  const { tc } = useTranslate()
  const childProps = {
    triggerIcon: triggerIcon || "group-plus",
    triggerTitle: triggerLabel || tc("Share"),
    hideTabs
  }

  return (
    <ShareUIContextProvider
      context={context}
      openWithKeys={openWithKeys}
      onToggleIsOpen={onToggleIsOpen}
    >
      <LightMode>
        <ShareTabs>
          {displayType === "modal" ? (
            <ModalShare children={children} {...childProps} />
          ) : (
            <PopoverShare
              children={children}
              {...childProps}
              placement={placement}
              portal={portal}
            />
          )}
        </ShareTabs>
      </LightMode>
    </ShareUIContextProvider>
  )
}

// Checks share permissions for determining how to render.
const Share = ({
  children,
  // ui context
  context,
  openWithKeys,
  onToggleIsOpen,
  // popover/modal
  displayType,
  // popover placement
  placement,
  // trigger label
  triggerLabel,
  // whether to hide tabs
  hideTabs,
  // whether to render Popover in portal
  portal
}) => {
  return (
    <ShareDataContextProvider context={context}>
      {({ permissions }) => {
        const { share_access, share_add_access, share_track_access } =
          permissions
        const triggerIcon =
          share_add_access?.access !== "NONE" ||
          share_track_access?.access !== "NONE"
            ? "group-plus"
            : "arrow-up-circle-fill"

        return share_access && share_access.access !== "NONE" ? (
          <ShareDumb
            context={context}
            openWithKeys={openWithKeys}
            onToggleIsOpen={onToggleIsOpen}
            displayType={displayType}
            placement={placement}
            triggerIcon={triggerIcon}
            triggerLabel={triggerLabel}
            hideTabs={hideTabs}
            portal={portal}
          >
            {children}
          </ShareDumb>
        ) : null
      }}
    </ShareDataContextProvider>
  )
}

Share.displayName = "Share"

Share.propTypes = {
  // children can only be a function which accepts an argument of this shape:
  // PropTypes.shape({
  //   renderAnchor: PropTypes.func,
  //   renderTrigger: PropTypes.func
  // })
  children: PropTypes.func,
  // Allow overriding only the label without having to provide the entire trigger.
  triggerLabel: PropTypes.string,
  context: PropTypes.shape({
    type: PropTypes.oneOf(["school", "cohort", "group"]).isRequired,
    typeId: PropTypes.number
  }),
  // Used when displayType is popover; see options in Chakra docs
  placement: PropTypes.string,
  displayType: PropTypes.oneOf(["popover", "modal"]),
  // Allow launching with keyboard
  openWithKeys: PropTypes.bool
}

Share.defaultProps = {
  displayType: "popover"
}

export default Share
