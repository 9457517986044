import { graphql } from "@apollo/client/react/hoc"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import { media } from "@pathwright/ui/src/components/utils/styles"
import { Component } from "react"
import styled from "styled-components"
import CommunityPlace from "./CommunityPlace"
import LibraryPlace from "./LibraryPlace"
import UserPlace from "./UserPlace"
import PLACES_QUERY from "./graphql/places-query"

const StyledGrid = styled.ul`
  margin: 40px 0 50px;
  padding: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style-type: none;
`

const StyledItem = styled.li`
  padding: 20px;
  width: 33.3333%;
  text-align: center;
  min-width: 320px;
  max-width: 400px;
  flex-grow: 1;
  ${media.max.tablet`
    flex-basis: 320px;
    padding: 20px;
  `} ${media.max.phone`
    padding: 10px;
  `};
`

class Places extends Component {
  componentDidMount() {
    if (this.props.data.me) {
      // force reload if mounting w/ cached data
      this.props.data.refetch()
    }
  }

  render() {
    const { data } = this.props
    if (data.loading || !data.me) {
      return (
        <div className="Places__loading">
          <LoadingCircle inverted />
        </div>
      )
    }
    if (data.error) {
      return <div className="Places__error">An unexpected error occurred.</div>
    }
    return (
      <StyledGrid>
        <StyledItem>
          <UserPlace
            user={data.me}
            teacherRegistrations={data.me.registrations}
          />
        </StyledItem>
        <StyledItem>
          <LibraryPlace
            visibleResources={data.visibleResources}
            privateResources={data.privateResources}
            resourceStats={data.school.resourceStats}
          />
        </StyledItem>
        <StyledItem>
          <CommunityPlace
            recentMembers={data.school.recentMembers}
            membershipStats={data.school.membershipStats}
            mentorGroups={data.school.mentorGroups}
          />
        </StyledItem>
      </StyledGrid>
    )
  }
}

Places.displayName = "Places"

export default graphql(PLACES_QUERY, {
  options: (props) => ({
    fetchPolicy: "cache-and-network"
  })
})(Places)
