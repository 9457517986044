const streamURL = "https://cdn.filestackcontent.com/L19rOmRQcaiQ9aXWUnBD"
const downloadURL = "https://cdn.filestackcontent.com/L19rOmRQcaiQ9aXWUnBD"
// const coverImage = "https://cdn.filestackcontent.com/TyP39ZEQTMfq2EanRZlA"

const sampleData = {
  // coverImage,
  type: "mp3",
  streamURL,
  downloadURL,
  description:
    "The Royal Fireworks by the National Hsin Chuang Senior High School Wind Band.",
  name: "The Royal Fireworks",
  artist: "National Hsin Chuang Senior High School Wind Band",
  allowDownload: false
}

export default {
  type: "Audio",
  helpLink:
    "https://help.pathwright.com/create-a-course/adding-content-with-blocks/audio-upload-block-new",
  menu: {
    category: "media",
    order: 3
  },
  layouts: {
    stream: {
      key: "stream",
      label: "Audio",
      data: {
        ...sampleData
      }
    }
  },
  schema: `
    type StreamingAudioNotes {
      html: String!
      timestamp: Int!
    }

    type AudioCaption {
      html: String!
    }

    type Audio implements Block {
      id: ID!
      artist: String
      downloadURL: String @media
      name: String
      coverImage: String
      caption: AudioCaption
      description: String
      type: String
      length: Float
      notes: [StreamingAudioNotes]
      streamURL: String
      processingJobID: String
      allowDownload: Boolean
    }
  `
}
