import styled from "styled-components"

const BulletSeparation = styled.span`
  display: flex;
  align-items: center;

  > * {
    position: relative;
    padding: 0 0.6em;
    display: flex;
    align-items: center;
  }

  > *:first-child {
    padding-left: 0;
  }

  > *:last-child {
    padding-right: 0;
  }

  /* Insert dividing bullet before subsequent items. */
  > * + *:not(:empty):before {
    content: "•";
    color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
  }
`

export const BulletSeparationInline = styled.span`
  display: inline;

  /* Insert dividing bullet before subsequent items. */
  > * + *:not(:empty):before {
    content: " • ";
    color: rgba(0, 0, 0, 0.2);
  }
`

export default BulletSeparation
