import { INVITATION_STATUS_ALL } from "invitation/constants"
import { API, get } from "lib/core/api/request"
import { createStore } from "lib/mobx/mobx-store"
import { action, observable } from "mobx"

const state = {
  // invitations
  invitations: observable.array(),
  next: null,
  count: null,
  // context
  context: null,
  status: null,
  error: null,
  // loading
  initializing: false, // awaiting invitation type (school or offering)
  resending: null, // id for invitation being resent
  revoking: null, // id for invitation being revoked
  loading: false, // awaiting list of invitations

  // invitation type (school or offering)
  get type() {
    if (this.context && this.context.offering) {
      return "offering"
    }
    return "school"
  },
  get noInvitations() {
    return (
      !this.loading &&
      !this.invitations.length &&
      this.status === INVITATION_STATUS_ALL
    )
  },
  get endpoint() {
    let url = App.getAPIUrl(`/api/private/invitation/?type=${this.type}`)
    if (this.context && this.context.offering) {
      url += `&offering_id=${this.context.offering.id}`
    }
    if (this.status) {
      url += `&status=${this.status}`
    }
    return url
  }
}

const actions = {
  loadInvitations() {
    this.error = null
    this.loading = true
    get(this.endpoint)
      .then(this.action.onInvitationsLoaded)
      .catch(this.action.onInvitationsError)
  },

  loadMoreInvitations() {
    // attempt to load more
    if (this.next && !this.loading) {
      // actually load more
      this.loading = true
      get(this.next)
        .then(this.action.onInvitationsLoaded)
        .catch(this.action.onInvitationsError)
    }
  },

  onInvitationsLoaded(invitations) {
    this.loading = false
    this.next = invitations.next
    this.count = invitations.count
    if (invitations.results.length) {
      this.invitations.push(...invitations.results)
    }
  },

  onInvitationsError(error) {
    this.loading = false
    this.error = error
  },

  resendInvitation(id) {
    const invitation = this.invitations.find(
      (invitation) => invitation.id === id
    )
    if (invitation) {
      this.resending = id
      API.post("invitation/batch/send", {
        emails: invitation.to_email,
        type: invitation.invitation_type,
        role: invitation.invitation_role,
        offering_id: this.context ? this.context.offering.id : undefined
      })
        .then(
          action((result) => {
            // optimistically refresh delivery_status_updated
            invitation.invitation_email.delivery_status_updated =
              new Date().toISOString()
          })
        )
        .catch(
          action(() => {
            // TODO: handle error
          })
        )
        .then(
          action(() => {
            this.resending = null
          })
        )
    }
  },

  revokeInvitation(id) {
    this.revoking = id
    API.delete(`invitation/${id}/`)
      .then(
        action((result) => {
          const invitation = this.invitations.find(
            (invitation) => invitation.id === id
          )
          this.invitations.remove(invitation)
        })
      )
      .catch(
        action((err) => {
          this.error = err
        })
      )
      .then(
        action(() => {
          this.revoking = null
        })
      )
  },

  filterStatus(status) {
    if (status !== this.status) {
      this.loading = true // ensures no visual flash before loadInvitations sets loading true
      this.status = status
      this.invitations.clear()
      this.action.loadInvitations()
    }
  },

  setContext({ resource, offering, status }) {
    // TODO: don't update if same args
    this.context = resource && offering ? { resource, offering } : null
    this.status = status ? status : INVITATION_STATUS_ALL
    this.initializing = false
  },

  initialize() {
    this.initializing = true
  },

  reset() {
    this.invitations = observable.array()
    this.context = null
    this.status = null
    this.error = null
    this.initializing = false
    this.resending = null
    this.revoking = null
    this.loading = false
  }
}

export default createStore(state, actions)
