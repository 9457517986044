import { captureUTMParameters } from "@pathwright/web/src/modules/utils/dispatcher"
import { bootstrapData } from "./bootstrap"

let bootModules = function () {
  require("config/initialize")
  // TODO: should not boot all these
  // modules... need to chunck them by route ideally
  let modules = [
    require("school/module").default,
    require("lib/core/store/module").default,
    require("lib/behaviors/module").default,
    require("auth/module").default,
    require("discussion/module").default
  ]
  modules.forEach((module) =>
    module && typeof module.start === "function" ? module.start() : undefined
  )
}

// TODO: remove once legacy views have been removed
App.getGlobalViewContext = function () {
  const user = window.App.getStore("auth").get("user")
  const data = {
    user: user != null ? user.getViewContext() : undefined,
    school: window.App.school.getViewContext()
  }
  return data
}

let _setGlobals = function () {
  // Set's global vars for use by end user in content (embeds, etc...)
  window.user = window.App.request("get:user").toJSON()
  window.school = window.App.request("get:school").toJSON()

  // Legacy globals (drop later)
  return (window.fbLoadingImg =
    '<i class="fa fa-circle-o-notch fa-spin fa-2x fa-fw margin-bottom"></i>')
}

// For some reason this gets called twice on startup so we have to double check
export async function bootApp() {
  if (window.school) {
    return
  }

  if (!window.bootstrappedData) {
    await bootstrapData()
  }
  // ugh, setting global here before calling _setGlobals so modules have access to window.school
  window.school = window.bootstrappedData.school
  bootModules()
  _setGlobals()
  captureUTMParameters()
}
