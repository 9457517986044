// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.

class Collection extends require("lib/static-shim").default(
  Backbone.Collection
) {
  initialize(models, options) {
    if (models == null) {
      models = []
    }
    if (options == null) {
      options = {}
    }
    this.options = options
  }

  urlRoot(url) {
    return Pathwright.getAPIUrl(url)
  }
}

export default Collection
