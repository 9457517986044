export function getIntervalDisplayName(n, t) {
  switch (n) {
    case 5:
      return t ? t("month") : "month"
    case 10:
      return t ? t("year") : "year"
    case 20:
      return t ? t("lifetime") : "lifetime"
    default:
      return ""
  }
}

export function getIntervalDisplayAdverb(n, t) {
  switch (n) {
    case 5:
      return t("monthly")
    case 10:
      return t("yearly")
    case 20:
      return t("lifetime")
    default:
      return ""
  }
}
