// Load config by school subdomain

import { getGraphQLEndpoint } from "./client"

export const loadSpaceConfigBySubdomain = async (subdomain: string) => {
  let endpoint = getGraphQLEndpoint()
  endpoint = endpoint.replace("/graphql", "")
  const configUrl = `${endpoint}/config/${subdomain}`

  // response is JSON
  const response = await fetch(configUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  })

  if (!response.ok) {
    console.log(`Could not load config from: ${configUrl}`, response)
    throw new Error(`Could not load config from: ${configUrl}`)
  }

  const config = await response.json()

  return config
}
