import getStripePriceIntervalLabel from "./getStripePriceIntervalLabel"

export default function getStripePriceActionLabel(stripePrice, t, tc) {
  if (
    stripePrice.recurring_interval_count ||
    stripePrice.billing_scheme === "tiered"
  ) {
    return `${tc("subscribe")} ${getStripePriceIntervalLabel(
      stripePrice,
      true,
      t
    )}`
  } else {
    return tc("Purchase once")
  }
}
