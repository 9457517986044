import withTranslate from "@pathwright/ui/src/components/lng/withTranslate"
import { BORDER_GRAY } from "@pathwright/ui/src/components/utils/colors"
import { media } from "@pathwright/ui/src/components/utils/styles"
import Downshift from "downshift"
import styled from "styled-components"

import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import Text from "@pathwright/ui/src/components/ui/Text"
import View from "@pathwright/ui/src/components/ui/View"
import { getAvailableRoles } from "../../constants"

const Container = styled.div`
  position: relative;
  ${media.max.phone`
    display: none;
  `};
`

const Toggle = styled(View.Primary)`
  padding: 5px;
  white-space: nowrap;
  border: 1px solid ${BORDER_GRAY};
  border-radius: ${(p) => (p.isOpen ? "0 0 4px 4px" : "4px")};
  appearance: none !important;
  cursor: pointer;
`

const Menu = styled(View.Primary)`
  position: absolute;
  bottom: 100%;
  right: 0;
  left: 0;
  border: 1px solid ${BORDER_GRAY};
  border-radius: 4px 4px 0 0;
  border-bottom: 0;
  width: 100%;
`

const Option = styled.div`
  padding: 10px;
  cursor: pointer;
  background: ${(p) =>
    p.isActive
      ? "rgba(0,0,0,0.05)"
      : p.isSelected
      ? "rgba(0,0,0,0.03)"
      : "none"};
`

const RoleDescription = withTranslate(
  ({ addingCount, selectedRole, Trans, t }) =>
    // prettier-ignore
    // Trans formatting integral to translation json!
    <Trans
      parent="span"
      i18nKey={`mentor_group.member.${selectedRole}.add`}
      count={addingCount}>
      Add {{ count: addingCount }} people as
      <Trans
        parent="strong"
        i18nKey={`mentor_group.member.${selectedRole}.label`}
        count={addingCount}>
        {{ memberRoleLabel: t(`mentor_group.member.${selectedRole}.label`) }}
      </Trans>
    </Trans>
)

const AddMentorGroupMembersRole = withTranslate(
  ({ permissions, addingCount, selectedRole, onRoleChange, t, tca }) => {
    const roleOptions = getAvailableRoles(permissions)

    if (roleOptions.length >= 2) {
      return (
        <Downshift selectedItem={selectedRole} onChange={onRoleChange}>
          {({
            isOpen,
            getRootProps,
            getToggleButtonProps,
            getItemProps,
            highlightedIndex
          }) => (
            <Container {...getRootProps({ refKey: "ref" })}>
              <Toggle {...getToggleButtonProps({ isOpen })}>
                <RoleDescription
                  addingCount={addingCount}
                  selectedRole={selectedRole}
                />{" "}
                <Pathicon icon="chevron-left" rotate={90} />
              </Toggle>
              {isOpen && (
                <Menu>
                  {getAvailableRoles(permissions).map((role, index) => (
                    <Option
                      {...getItemProps({
                        key: role,
                        item: role,
                        isActive: highlightedIndex === index,
                        isSelected: selectedRole === role
                      })}
                    >
                      <Text.H5>
                        {tca(`mentor_group.member.${role}.label`)}
                      </Text.H5>
                      <Text.Body>
                        {t(`mentor_group.member.${role}.description`)}
                      </Text.Body>
                    </Option>
                  ))}
                </Menu>
              )}
            </Container>
          )}
        </Downshift>
      )
    }

    return (
      <RoleDescription addingCount={addingCount} selectedRole={selectedRole} />
    )
  }
)

export default AddMentorGroupMembersRole
