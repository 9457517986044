import gql from "graphql-tag"
import CURRICULUM_SUBSCRIPTION_FRAGMENT from "./curriculum-subscription-fragment"

export default gql`
  query CurriculumSubscriptionsQuery {
    school {
      ...School
      curriculum_subscriptions(first: 50) {
        edges {
          node {
            ...CurriculumSubscription
          }
        }
      }
    }
  }
  ${CURRICULUM_SUBSCRIPTION_FRAGMENT}
`
