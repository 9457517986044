import { Box, HStack, VStack } from "@chakra-ui/react"
import ResourceBackground from "@pathwright/ui/src/components/background/ResourceBackground"
import ThemeBackground from "@pathwright/ui/src/components/background/ThemeBackground"
import IconButton from "@pathwright/ui/src/components/button/IconButton"
import { useScreensizeContext } from "@pathwright/ui/src/components/ui/Screensize"
import Text from "@pathwright/ui/src/components/ui/Text"
import View from "@pathwright/ui/src/components/ui/View"
import SchoolIdentity from "@pathwright/web/src/modules/lib/SchoolIdentity"
import classnames from "classnames"
import PropTypes from "prop-types"
import Invitation from "./Invitation"
import "./RegisterHeader.css"

/*
 * PURPOSE:
 * Reusable header in registration flow to provide context (school or course) for registration.
 *
 * PROPS:
 * resource: use to set context to resource; defaults to school context; optional.
 * invitation: shows sender and role that user is registering for; optional.
 * children: use to show extra information under context; optional.
 *
 */

const RegisterHeader = ({
  resource,
  invitation,
  heading,
  children,
  closeTo,
  closeIcon,
  className,
  onClose
}) => {
  const screensize = useScreensizeContext()
  const roundedTop = screensize !== "sm"
  return (
    <View
      className={classnames("RegisterHeader", className)}
      roundedTop={roundedTop}
    >
      {resource ? (
        <ResourceBackground resource={resource} roundedTop={roundedTop} />
      ) : (
        <ThemeBackground roundedTop={roundedTop} />
      )}
      <Box pos="absolute" inset={0} backdropFilter="blur(4px)"></Box>
      <Box pos="absolute" inset={0} blendMode="darken" bg="rgba(0,0,0,.2)" />
      {(closeTo || onClose) && (
        <IconButton
          className="RegisterHeader__close"
          inverted
          icon={closeIcon}
          to={closeTo}
          onClick={(e) => {
            onClose ? onClose(e) : null
          }}
        />
      )}
      <VStack>
        {invitation && <Invitation invitation={invitation} />}
        {resource ? (
          <Text.H3 inverted style={{ position: "relative" }}>
            {heading || resource.name}
          </Text.H3>
        ) : (
          <SchoolIdentity />
        )}
        {children && (
          <Text.Body inverted style={{ position: "relative" }}>
            <HStack as="span" className="RegisterHeader__meta">
              {children}
            </HStack>
          </Text.Body>
        )}
      </VStack>
    </View>
  )
}

RegisterHeader.displayName = "RegisterHeader"

RegisterHeader.propTypes = {
  resource: PropTypes.shape({
    background_image_blurred_cdn: PropTypes.string,
    background_overlay: PropTypes.string,
    background_image: PropTypes.string,
    background_type: PropTypes.number
  }),
  invitation: PropTypes.shape({
    sender: PropTypes.object,
    role: PropTypes.number
  }),
  children: PropTypes.any
}

RegisterHeader.defaultProps = {
  closeIcon: "x"
}

export default RegisterHeader
