import { Box, Button, HStack, Link } from "@chakra-ui/react"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import { Link as ReactRouterLink } from "react-router-dom"
import InteractiveTooltip from "../shared/InteractiveTooltip"
import { useShareDataContext } from "../shared/ShareDataContextProvider"
import { COHORT_VISIBILITY } from "../shared/constants"
import MetaButton from "./MetaButton"

const tPrefix = "share_card.visibility"

const visibilityOptions = {
  [COHORT_VISIBILITY.NONE]: null,
  [COHORT_VISIBILITY.PRIVATE]: {
    label: "Private",
    icon: "avatar-lock",
    description: `Only people invited can view or join.`
  },
  [COHORT_VISIBILITY.MEMBERS_ONLY]: {
    label: "Members Only",
    icon: "group",
    description: `Anyone with a login can view & join from the Library.`
  },
  [COHORT_VISIBILITY.PUBLIC]: {
    label: "Public",
    icon: "globe",
    description: `Anyone can view & join from the Library.`
  }
}

const getVisibilityTranslations = (visibility = "", t) => {
  return {
    label: t(`${tPrefix}.${visibility}.label`),
    description: t(`${tPrefix}.${visibility}.description`)
  }
}

const CohortMeta = () => {
  const {
    permissions,
    meta: { visibility }
  } = useShareDataContext()
  const visibilityOption = visibilityOptions[visibility]
  const { tc, t } = useTranslate()

  const { description, label } = getVisibilityTranslations(visibility, t)

  return visibilityOption ? (
    <InteractiveTooltip
      label={
        <>
          {description}{" "}
          {!!permissions?.allow_manage && (
            <Button
              as={ReactRouterLink}
              variant="link"
              to={permissions.manage_url}
              color="inherit"
              fontSize="1em"
              fontWeight="normal"
            >
              <HStack spacing={1} display="inline-flex">
                <span>{tc(`${tPrefix}.Update access settings`)}</span>
                <Pathicon icon="chevron-right" />
              </HStack>
            </Button>
          )}
          {visibility === COHORT_VISIBILITY.PUBLIC && (
            <Box mt={4}>
              <Pathicon
                icon="lightbulb-on"
                style={{ marginRight: "7px", marginBottom: "-2px" }}
              />
              Want more people to join? See{" "}
              <Button
                variant="link"
                as={Link}
                href="https://www.pathwright.com/blog/how-to-grow-your-online-course-with-word-of-mouth"
                target="_blank"
                color="inherit"
                fontSize="1em"
                fontWeight="normal"
                whiteSpace="normal"
                lineHeight="1.5"
              >
                <span>
                  how to grow your online course with word of mouth
                  <Pathicon icon="chevron-right" />
                </span>
              </Button>
            </Box>
          )}
        </>
      }
    >
      <MetaButton icon={visibilityOption.icon} label={label} />
    </InteractiveTooltip>
  ) : null
}

export default CohortMeta
