import { graphql } from "@apollo/client/react/hoc"
import compose from "lodash/flowRight"
import get from "lodash/get"
import PropTypes from "prop-types"
import React from "react"
import { PathwrightContext } from "../../pathwright/PathwrightContext"
import { createPaginator, flattenEdges } from "../../utils/apollo"
import { withContextAsProp } from "../../utils/component"
import STORE_RESOURCES_QUERY from "./store-resources-query"

export const withStoreResources = compose(
  withContextAsProp(PathwrightContext, "schoolId", "school.id"),
  graphql(STORE_RESOURCES_QUERY, {
    options: (props) => ({
      fetchPolicy: props.fetchPolicy,
      variables: {
        role: props.role,
        first: props.first || 24,
        search: props.search,
        school_id: props.schoolId,
        resource_types: props.type,
        author_ids: props.authorIdFilter,
        category_ids: props.categoryIdFilter,
        publisher_ids: props.publisherIdFilter,
        visibility: props.visibility,
        licensing_mode: props.licensingMode,
        licensee_school_id: props.licenseeSchoolId || props.schoolId,
        include_school_license: !!props.includeSchoolLicense,
        user_registered: props.userRegistered,
        include_groups: !!props.includeGroups
      }
    }),
    // map data to props
    props: ({ data }) => ({
      error: data.error,
      loading: data.loading,
      refetch: data.refetch,
      total: get(data, "context.school.resources.total", 0),
      hasMore: get(data, "context.school.resources.pageInfo.hasNextPage"),
      loadMore: createPaginator(data, "context.school.resources"),
      // prettier-ignore
      resources: get(data, "context.school.resources.edges", []).map(({ node }) => {
        let resource = flattenEdges(node)
        resource.total_authors_count = node.authors.total
        return resource
      })
    })
  })
)

const StoreResourcesQuery = withStoreResources(
  class extends React.Component {
    tryLoadMore = () => {
      if (this.props.hasMore && !this.loadingMore) {
        this.loadingMore = true
        this.props.loadMore().then(() => {
          this.loadingMore = false
        })
      }
    }

    render() {
      const { children, ...rest } = this.props
      return children({ ...rest, loadMore: this.tryLoadMore }) || null
    }
  }
)

StoreResourcesQuery.displayName = "StoreResourcesQuery"

StoreResourcesQuery.propTypes = {
  // page size
  first: PropTypes.number,
  // filter by search string
  search: PropTypes.string,
  // filter by visibility
  visibility: PropTypes.object,
  // filter by category id
  categoryIdFilter: PropTypes.number,
  // filter by author id
  authorIdFilter: PropTypes.number,
  // filter by type (course, lesson, collection)
  type: PropTypes.object,
  // filter by license offering type
  licensingMode: PropTypes.string,
  //
  licenseeSchoolId: PropTypes.number,
  // include groups on resource
  includeGroups: PropTypes.bool,
  // include own school's license for resource
  includeSchoolLicense: PropTypes.bool,
  // optionally scope query to another school
  schoolId: PropTypes.number,
  //
  role: PropTypes.string
}

export default StoreResourcesQuery
