import { graphql } from "@apollo/client/react/hoc"
import Avatar from "@pathwright/ui/src/components/avatar/Avatar"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import Text from "@pathwright/ui/src/components/ui/Text"
import { FOCUS_BLUE } from "@pathwright/ui/src/components/utils/colors"
import * as EmailValidator from "email-validator"
import compose from "lodash/flowRight"
import styled from "styled-components"
import MEMBERSHIPS_QUERY from "../membership/graphql/memberships-query"
import { flattenEdges } from "../utils/apollo"
import { withDebouncedProp } from "../utils/component"

const Menu = styled.div`
  z-index: 100;
  position: absolute;
  top: calc(100% - 5px);
  left: 0;
  right: 0;
  background: white;
  border: 1px solid ${FOCUS_BLUE};
  border-radius: 0 0 4px 4px;
  border-top: none;
  max-height: 200px;
  min-height: 50px;
  overflow-y: auto;
`

const Result = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  cursor: pointer;
  background: ${(p) => (p.highlight ? "rgba(0,0,0,0.05)" : "transparent")};
`

const Meta = styled.div`
  margin-left: 10px;
  flex-grow: 1;
`

const LookupMenu = ({
  members = [],
  loading,
  isOpen,
  inputValue,
  highlightedIndex,
  getItemProps,
  getMenuProps
}) => {
  if (!isOpen) {
    return null
  }

  const isEmail = EmailValidator.validate(inputValue)

  if (!members.length && !isEmail) {
    return null
  }

  const isUniqueEmail =
    isEmail && !members.find((x) => x.user.email === inputValue)

  return (
    <Menu {...getMenuProps()}>
      {loading ? (
        <LoadingCircle />
      ) : (
        members.map((member, index) => (
          <Result
            key={member.id}
            index={index}
            highlight={highlightedIndex === index}
            {...getItemProps({
              item: {
                type: "user",
                user: member.user
              }
            })}
          >
            <Avatar user={member.user} size="40px" />
            <Meta>
              <Text.H5>{member.user.full_name}</Text.H5>
              <Text.Meta>{member.user.email}</Text.Meta>
            </Meta>
            {highlightedIndex === index ? (
              <Text.Meta>↵ to add</Text.Meta>
            ) : null}
          </Result>
        ))
      )}
      {isUniqueEmail ? (
        <Result
          key={inputValue}
          highlight={highlightedIndex === members.length}
          {...getItemProps({
            item: {
              type: "email",
              email: inputValue
            }
          })}
        >
          <Avatar initials={inputValue.charAt(0)} size="40px" />
          <Meta>
            <Text.H5>{inputValue}</Text.H5>
          </Meta>
          {highlightedIndex === members.length ? (
            <Text.Meta>↵ to add</Text.Meta>
          ) : null}
        </Result>
      ) : null}
    </Menu>
  )
}

export default compose(
  withDebouncedProp("inputValue"),
  graphql(MEMBERSHIPS_QUERY, {
    options: (props) => ({ variables: { search: props.debouncedInputValue } }),
    skip: (props) => !props.inputValue || props.emailOnly,
    props: ({ data, ownProps }) => ({
      error: data.error,
      loading:
        data.loading || ownProps.debouncedInputValue !== ownProps.inputValue,
      members: flattenEdges(data.school && data.school.members) || []
    })
  })
)(LookupMenu)
