import gql from "graphql-tag"
import CATEGORY_FRAGMENT from "../../category/graphql/category-fragment"

export default gql`
  query LibraryCategories(
    $search: String
    $resourceSearch: String
    $resourceCategoryNameFilter: StringFilter
    $resourceAuthorNameFilter: StringFilter
    $cursor: String
  ) {
    categories(
      first: 15
      after: $cursor
      search: $search
      resource_search: $resourceSearch
      resource_category_names: $resourceCategoryNameFilter
      resource_author_names: $resourceAuthorNameFilter
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...Category
        }
      }
    }
  }
  ${CATEGORY_FRAGMENT}
`
