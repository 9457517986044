import styled from "styled-components"
import { VelocityComponent } from "velocity-react"
import Pathicon from "../pathicon/Pathicon"
import { TERTIARY_GRAY, TERTIARY_WHITE } from "../utils/colors"

const StyledIcons = styled.ul`
  width: 100%;
  display: block;
  font-size: 2.2em;
  padding: 30px 0;
  text-align: center;
  overflow: hidden;
  list-style-type: none;
  transform: translate3d(0, 0, 0);
  color: ${(props) => (props.inverted ? TERTIARY_WHITE : TERTIARY_GRAY)};
  li {
    font-size: 24px;
    float: left;
    width: 16%;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
`

const AnimatedIcons = ({ icons, inverted }) => (
  <StyledIcons inverted={inverted}>
    {icons.map((icon, index) => {
      const translateY = [-15, 15, 0][index % 3]
      const rotateZ = [-10, 0, 10][index % 3]
      return (
        <VelocityComponent
          key={index}
          animation={{ translateY, rotateZ }}
          runOnMount={true}
        >
          <li>
            <Pathicon icon={icon} />
          </li>
        </VelocityComponent>
      )
    })}
  </StyledIcons>
)

AnimatedIcons.defaultProps = {
  icons: [
    "chat-bubble-plus",
    "avatar-circle",
    "image",
    "avatar-circle",
    "chat-bubble-check",
    "avatar-circle"
  ]
}

AnimatedIcons.displayName = "AnimatedIcons"

export default AnimatedIcons
