import Card from "@pathwright/ui/src/components/card/Card"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import ScrollView from "@pathwright/ui/src/components/scroll/ScrollView"
import { toJS } from "mobx"
import { observer } from "mobx-react"
import pluralize from "pluralize"
import invitationStore from "../store/invitation-store"
import InvitationBlank from "./InvitationBlank"
import InvitationFilter from "./InvitationFilter"
import InvitationHeader from "./InvitationHeader"
import InvitationRow from "./InvitationRow"
import "./Invitations.css"

const Invitations = ({ card }) => {
  return (
    <Card className="Invitations" card={{ ...card }} navless>
      {invitationStore.initializing ? (
        <LoadingCircle />
      ) : (
        <div className="Invitations__content">
          <InvitationHeader card={card} />
          {card.showChildren && !invitationStore.noInvitations && (
            <div className="Invitations__controls">
              <h4>
                {invitationStore.count > 0 &&
                  pluralize("invitation", invitationStore.count, true)}
              </h4>
              <InvitationFilter />
            </div>
          )}
          {card.showChildren ? (
            <ScrollView
              onScrollBottom={invitationStore.action.loadMoreInvitations}
            >
              {invitationStore.invitations.length > 0 ? (
                <ul>
                  {toJS(invitationStore.invitations).map((invitation) => (
                    <InvitationRow
                      key={invitation.id}
                      invitation={invitation}
                    />
                  ))}
                  {invitationStore.next && (
                    <div className="Invitations__more">
                      <LoadingCircle />
                    </div>
                  )}
                  {!invitationStore.next && invitationStore.count > 50 && (
                    <div className="Invitations__end">That's everything.</div>
                  )}
                </ul>
              ) : invitationStore.loading ? (
                <LoadingCircle />
              ) : (
                <InvitationBlank />
              )}
            </ScrollView>
          ) : (
            <LoadingCircle />
          )}
        </div>
      )}
    </Card>
  )
}

Invitations.displayName = "Invitations"

export default observer(Invitations)
