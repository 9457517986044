import { useToast } from "@chakra-ui/react"
import { CheckIcon } from "@pathwright/pathicons"
import { useEffect, useState } from "react"
import { useCopyToClipboard as _useCopyToClipboard } from "react-use"

// Could handle setting state, copying, and toasting all in one handler
// but using effects feel more react-like.
const useCopyToClipboard = (value, options) => {
  const [copiedValue, setCopiedValue] = useState()
  const [_, onCopy] = _useCopyToClipboard()
  const toast = useToast()

  const handleCopyValue = value
    ? () => setCopiedValue(value)
    : (value) => () => setCopiedValue(value)

  const handleCopy = () => {
    if (copiedValue) onCopy(copiedValue)
  }

  const handleToast = () => {
    if (copiedValue)
      toast({
        title: "Copied to clipboard",
        description: copiedValue,
        status: "success",
        duration: 3000,
        isClosable: true,
        icon: <CheckIcon />,
        onCloseComplete: () => setCopiedValue(),
        containerStyle: {
          // For long tokens (this might need to be handled via passed in props).
          overflowWrap: "anywhere",
          // Force light mode Close button.
          "& button": {
            color: "white !important"
          }
        },
        ...options
      })
  }

  useEffect(handleCopy, [copiedValue])
  useEffect(handleToast, [copiedValue])
  useEffect(() => () => setCopiedValue(), [])

  return handleCopyValue
}

export default useCopyToClipboard
