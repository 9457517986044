import { graphql } from "@apollo/client/react/hoc"
import Avatar from "@pathwright/ui/src/components/avatar/Avatar"
import Card from "@pathwright/ui/src/components/card/Card"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import Text from "@pathwright/ui/src/components/ui/Text"
import PropTypes from "prop-types"
import styled from "styled-components"
import USER_QUERY from "../../user/graphql/user-query"
import ReportPath from "./ReportPath"
import ReportPathStats from "./ReportPathStats"

const Header = styled.header`
  text-align: center;
  padding: 0 50px 10px;
`

const UserReportCard = ({ card, user, pathId }) => {
  if (!user) {
    return (
      <Card card={card} style={{ minHeight: "80vh" }}>
        <LoadingCircle />
      </Card>
    )
  }

  return (
    <Card card={card}>
      <div>
        <Header>
          <Avatar user={user} />
          <Text.H4>{user.full_name}</Text.H4>
          <Text.Meta>{user.email}</Text.Meta>
        </Header>
        <ReportPathStats id={pathId} />
        <ReportPath id={pathId} first={50} />
      </div>
    </Card>
  )
}

UserReportCard.displayName = "UserReportCard"

UserReportCard.propTypes = {
  pathId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired
}

export default graphql(USER_QUERY, {
  options: ({ userId }) => ({
    variables: { id: userId }
  }),
  props: ({ data }) => ({
    user: data.user
  })
})(UserReportCard)
