import { graphql } from "@apollo/client/react/hoc"
import compose from "lodash/flowRight"
import get from "lodash/get"
import { withPaginator } from "../utils/apollo"
import { withDebouncedProp } from "../utils/component"
import MembershipList from "./MembershipList"
import { REVERSED_MEMBERSHIP_ARCHIVED_DATE_FILTER_MAP } from "./constants"
import MEMBERSHIPS_QUERY from "./graphql/memberships-query"
import REQUEST_PASSWORD_RESET_MUTATION from "./graphql/request-password-reset-mutation"
import SET_MEMBER_ACCESS_MUTATION from "./graphql/set-member-access-mutation"
import SET_MEMBER_ARCHIVED_MUTATION from "./graphql/set-member-archived-mutation"
import SET_MEMBER_ROLE_MUTATION from "./graphql/set-member-role-mutation"

export default compose(
  withDebouncedProp("search"),
  graphql(MEMBERSHIPS_QUERY, {
    options: ({
      roleFilter,
      archivedDateFilter,
      accessRevokedDateFilter,
      debouncedSearch,
      fetchPolicy = "cache-first"
    }) => ({
      variables: {
        role_filter: roleFilter,
        archived_date_filter: archivedDateFilter,
        access_revoked_date_filter: accessRevokedDateFilter,
        // Enforce searching with a minimum of 2 characters (so we don't bog down our servers)
        search:
          debouncedSearch && debouncedSearch.length > 1 ? debouncedSearch : ""
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy
    }),
    props: ({ data, ownProps }) => ({
      data, // for withPaginator
      error: data.error,
      loading:
        data.loading ||
        (ownProps.debouncingSearch &&
          ownProps.search &&
          ownProps.search.length > 1),
      members: get(data, "school.members"),
      onSearch: ownProps.handleDebouncedSearch
    })
  }),
  withPaginator({
    path: "school.members"
  }),
  graphql(REQUEST_PASSWORD_RESET_MUTATION, {
    props: ({ mutate }) => ({
      resetPassword: (user_id) => mutate({ variables: { user_id } })
    })
  }),
  graphql(SET_MEMBER_ACCESS_MUTATION, {
    props: ({ mutate }) => ({
      setMemberAccess: (id, has_access) => mutate({ variables: { id, has_access } }) // prettier-ignore
    })
  }),
  graphql(SET_MEMBER_ARCHIVED_MUTATION, {
    props: ({ mutate, ownProps }) => ({
      setMemberArchived: (id, is_archived) =>
        mutate({
          variables: { id, is_archived },
          update: (cache) => {
            // Handle updating the cache for two queries: both the archived and non-archived membership queries

            // Remove member from the query it came from
            const query = MEMBERSHIPS_QUERY
            const variables = {
              role_filter: ownProps.roleFilter,
              archived_date_filter: ownProps.archivedDateFilter,
              access_revoked_date_filter: ownProps.accessRevokedDateFilter,
              search:
                ownProps.debouncedSearch && ownProps.debouncedSearch.length > 1
                  ? ownProps.debouncedSearch
                  : ""
            }

            const data = cache.readQuery({ query, variables })

            // get the membership edge
            const edge = data.school.members.edges.find(
              (edge) => edge.node.id === id
            )

            const nextData = {
              school: {
                ...data.school,
                members: {
                  ...data.school.members,
                  edges: data.school.members.edges.filter(
                    (edge) => edge.node.id !== id
                  )
                }
              }
            }
            // Removing the member from the list immediately may be jarring, so for now, let's not
            // cache.writeQuery({ query, variables, data: nextData })

            // Add member to the "opposite" query (i.e. if this updater is the result of a member being archived,
            // add member to the non-archived membership query)
            try {
              const query = MEMBERSHIPS_QUERY
              // get the opposite archived date filter
              const reversedArchivedDateFilter =
                REVERSED_MEMBERSHIP_ARCHIVED_DATE_FILTER_MAP[
                  JSON.stringify(ownProps.archivedDateFilter)
                ]
              const variables = {
                role_filter: ownProps.roleFilter,
                archived_date_filter: reversedArchivedDateFilter,
                access_revoked_date_filter: ownProps.accessRevokedDateFilter,
                search:
                  ownProps.debouncedSearch &&
                  ownProps.debouncedSearch.length > 1
                    ? ownProps.debouncedSearch
                    : ""
              }

              const data = cache.readQuery({ query, variables })

              const nextData = {
                school: {
                  ...data.school,
                  members: {
                    ...data.school.members,
                    edges: data.school.members.edges
                      .concat(edge)
                      .sort(
                        ({ node: memberA }, { node: memberB }) =>
                          new Date(memberB.last_active).getTime() -
                          new Date(memberA.last_active).getTime()
                      )
                  }
                }
              }
              cache.writeQuery({ query, variables, data: nextData })
            } catch (error) {
              console.log("error", error)
              // likely the query does not exist in the cache, which is OK
            }
          }
        })
    })
  }),
  graphql(SET_MEMBER_ROLE_MUTATION, {
    props: ({ mutate }) => ({
      setMemberRole: (id, role) => mutate({ variables: { id, role } })
    })
  })
)(MembershipList)
