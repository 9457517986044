import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import SchoolInactive from "@pathwright/web/src/modules/school/SchoolInactive"
import Footer from "global/footer/components/Footer"
import React, { Suspense } from "react"
import StoreOrLibraryBrowse from "store/StoreOrLibraryBrowse"
import { get } from "../lib/core/api/request"
import Page from "./components/Page"

const Sitemap = React.lazy(() =>
  import(
    "@pathwright/web/src/modules/school/Sitemap"
    /* webpackChunkName: "Sitemap" */
  )
)
const FAQs = React.lazy(() =>
  import(
    "@pathwright/web/src/modules/school/FAQs"
    /* webpackChunkName: "FAQs" */
  )
)

const SitemapContainer = () => (
  <Suspense fallback={<LoadingCircle />}>
    <Sitemap getSitemapData={() => get(App.getAPIUrl(`/api/private/sitemap/`))}>
      <Footer location="Sitemap" />
    </Sitemap>
  </Suspense>
)

const FAQsContainer = () => (
  <Suspense fallback={<LoadingCircle />}>
    <FAQs getFAQsData={() => get(App.getAPIUrl(`/api/private/faqs/`))}>
      <Footer location="FAQs" />
    </FAQs>
  </Suspense>
)

export const pageRoute = {
  path: "/p/:page_slug/",
  component: Page
}

export const pageModalRoute = {
  path: "/m/:page_slug/",
  components: {
    primary: StoreOrLibraryBrowse,
    modal: Page
  }
}

// Use the unblock method to unblock routing after leaving the /inactive/ routte.
let unblock = null
export const schoolInactiveRoute = {
  path: "/inactive/",
  onEnter: ({ history }) => {
    unblock = history.block((location, action) => {
      switch (location.pathname) {
        // prevent navigating anywhere when school is inactive except for the signout route
        case "/auth/sign-out/":
        case "/auth/sign-in/":
          return true
        default:
          // returning false prevents routing
          return false
      }
    })
  },
  onLeave: ({ history }) => {
    if (unblock) {
      unblock()
      unblock = null
    }
  },
  component: SchoolInactive
}

export const sitemapRoute = {
  path: "/sitemap/",
  component: SitemapContainer
}

export const faqsRoute = {
  path: "/support/",
  component: FAQsContainer
}
