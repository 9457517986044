import { Button, IconButton } from "@chakra-ui/react"
import { ShuffleIcon } from "@pathwright/pathicons"
import Error from "@pathwright/ui/src/components/error/Error"
import { usePdfInfo } from "@pathwright/ui/src/components/filestack/utils"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import { useMeasure } from "react-use"
import styled from "styled-components"
import { useCertificateState } from "../context/CertificateState"
import CertificateMediaManager from "./CertificateMediaManager"
import CertificateTemplate from "./CertificateTemplate"

const Container = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  min-width: 100%;

  /* Hiding media manager spinner */
  .LoadingCircle {
    display: none;
  }
`

const CertificateTemplateForm = ({ hash }) => {
  const {
    certificateState: { template },
    handleChangeTemplate
  } = useCertificateState()
  const { t } = useTranslate()
  const [nodeRef, size] = useMeasure()
  const pdfInfo = usePdfInfo(template)

  const renderButton = ({ onClick }) =>
    template ? (
      pdfInfo && pdfInfo.pages !== 1 ? (
        <Error
          errorTitle={t("Invalid page size.")}
          errorMessage={t(
            "The certificate template PDF must contain only one page. Found {{ count }}.",
            {
              count: pdfInfo.pages
            }
          )}
          primaryAction={{
            icon: "arrow-up-circle",
            label: "Select a PDF",
            onClick
          }}
        />
      ) : (
        <>
          <CertificateTemplate hash={hash} scaleSize={size} />
          <IconButton
            aria-label="Replace certificate template"
            size="sm"
            position="absolute"
            top="10px"
            right="10px"
            zIndex="10"
            variant="solid"
            colorScheme={"blackAlpha"}
            onClick={onClick}
            icon={<ShuffleIcon size="1.2em" />}
          />
        </>
      )
    ) : (
      <Button onClick={onClick} variant="solid" colorScheme="brand" gap={2}>
        <Pathicon icon="arrow-up-circle" />
        Select a PDF
      </Button>
    )

  return (
    <Container ref={nodeRef} className="CertificateTemplateForm">
      <CertificateMediaManager
        // NOTE: handleChangeTemplate may return a promise in the event that selecting the template
        // results in creating the certificate. The MediaManager handles the loading state.
        onChange={(mediaUrl) => handleChangeTemplate(mediaUrl)}
        renderButton={renderButton}
      />
    </Container>
  )
}

CertificateTemplateForm.displayName = "CertificateTemplateForm"

export default CertificateTemplateForm
