import classnames from "classnames"
import "./Divider.css"

const Divider = function (props) {
  let { label, children, inverted, className, style } = props

  className = classnames(
    "Divider",
    { "Divider--inverted": inverted },
    className
  )

  return (
    <div
      className={className}
      onClick={(e) => e.stopPropagation()}
      style={style}
    >
      {label || children ? <label>{label || children}</label> : undefined}
    </div>
  )
}

Divider.displayName = "Divider"

export default Divider
