import gql from "graphql-tag"
import USER_FRAGMENT from "../../user/graphql/user-fragment"
import SUBSCRIPTION_PLAN_FRAGMENT from "./subscription-plan-fragment"

export default gql`
  fragment Subscription on Subscription {
    id
    quantity
    canceled_dtime
    cancellation_reason
    card_last_4
    cycle_end_dtime
    cycle_start_dtime
    is_canceled_at_cycle_end
    is_delinquent
    paused_until_dtime
    trial_type
    trial_end_dtime
    payment_intent_needing_action_id
    subscription_plan {
      ...SubscriptionPlan
    }
    customer {
      ...User
    }
  }
  ${SUBSCRIPTION_PLAN_FRAGMENT}
  ${USER_FRAGMENT}
`
