import { gql, useMutation, useQuery } from "@apollo/client"
import get from "lodash/get"
import { createContext, useContext } from "react"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import PATHWRIGHT_CONTEXT from "../../pathwright/graphql/context-query"
import {
  CERTIFICATE_CREATE_MUTATION,
  CERTIFICATE_QUERY,
  CERTIFICATE_UPDATE_MUTATION,
  CERTIFICATE_VARIABLES_QUERY,
  RESOURCE_CERTIFICATE_CONTEXT_QUERY,
  UPDATE_CERTIFICATE_VARIABLES_MUTATION
} from "../graphql"
import useCertificateScope from "../hooks/useCertificateScope"
import { getQueryVariables } from "../utils"

// Maintain constant objects for default values to ensure consuming hooks don't execute after CertificateContext rerenders.
const defaultCertificateVariables = {
  stats: {},
  variables: {}
}

const Context = createContext()
export const useCertificateContext = () => useContext(Context)

const CertificateContext = ({
  children,
  certificateScope: certificateScopeProp
}) => {
  const { school } = usePathwrightContext()
  const certificateScope = useCertificateScope(certificateScopeProp)
  // Attempt to load the school's active certificate.
  const certificateQuery = useQuery(CERTIFICATE_QUERY)
  const certificate = get(certificateQuery, "data.certificate")
  const certificateId = certificate?.id
  const hasLegacyCertificate = Boolean(school.has_certificate && !certificateId)

  const resourceId = certificateScope ? certificateScope.resourceId : null
  const resourceContextQuery = useQuery(RESOURCE_CERTIFICATE_CONTEXT_QUERY, {
    variables: { id: resourceId },
    skip: !resourceId
  })

  const resource = get(resourceContextQuery, "data.resource")

  const certificatesVariablesQuery = useQuery(CERTIFICATE_VARIABLES_QUERY, {
    variables: {
      context: {
        resource_id: resourceId
      }
    }
  })

  // NOTE: certificateVariables can be null in some cases, like when no courses
  // yet exist on the school, or no courses exist that use certificates.
  const { stats: customVariablesStats, variables: customVariables } =
    get(certificatesVariablesQuery, "data.certificateVariables") ||
    defaultCertificateVariables

  const [updateCertificatesVariables] = useMutation(
    UPDATE_CERTIFICATE_VARIABLES_MUTATION,
    {
      variables: {
        context: {
          resource_id: resourceId
        }
      },
      // Update any Resource fragments using the `certificate_json` field
      // with the latest variables.
      update: (client, response) => {
        const fragment = {
          id: `Resource:${response.data.updateCertificateVariables.id}`,
          fragment: gql`
            fragment Resource on Resource {
              id
              certificate_json
            }
          `
        }
        const data = client.readFragment(fragment)
        if (data) {
          client.writeFragment({
            ...fragment,
            data: {
              ...data,
              certificate_json:
                response.data.updateCertificateVariables.variables
            }
          })
        }
      }
    }
  )

  const [createCertificate, certificateCreateMutation] = useMutation(
    CERTIFICATE_CREATE_MUTATION,
    {
      variables: {
        // Allow school with legacy certificate to create certificate
        // but in the inactive state. We'll need to manually enable the
        // certificate when they are ready.
        isActive: !hasLegacyCertificate
      },
      // Sync the school's `has_certificate` flag in state.
      update: (cache, response) => {
        const query = PATHWRIGHT_CONTEXT
        const data = cache.readQuery({ query })
        const nextData = {
          ...data,
          school: {
            ...data.school,
            has_certificate: true
          }
        }
        cache.writeQuery({ query, data: nextData })
      },
      refetchQueries: [{ query: CERTIFICATE_QUERY }]
    }
  )

  const [updateCertificate, certificateUpdateMutation] = useMutation(
    CERTIFICATE_UPDATE_MUTATION
  )

  // Either create or update the certificate based on presence of
  // a certificateId.
  const handleSubmit = (values = {}) => {
    const promises = []

    if (certificateScope) {
      const mergeCustomVariables = Object.entries(
        values.customVariables
      ).reduce((acc, [key, value]) => {
        if (!(key in customVariables)) {
          if (value) {
            // Add new key + value.
            acc.push({
              new_key: key,
              new_value: value
            })
          }
        } else if (value !== customVariables[key]) {
          // Update current value of existing key.
          if (value) {
            acc.push({
              current_key: key,
              new_value: value
            })
          } else {
            // Remove key that no longer has value.
            acc.push({
              current_key: key
            })
          }
        }

        return acc
      }, [])

      // Only save resource certificate_json if in a resource scope.
      promises.push(
        updateCertificatesVariables({
          variables: {
            merge: mergeCustomVariables
          }
        })
      )
    } else {
      promises.push(
        certificateId
          ? updateCertificate({
              variables: {
                id: certificateId,
                template: values.template,
                textObjects: getQueryVariables(values.textObjects)
              }
            })
          : createCertificate({
              variables: {
                template: values.template
              }
            })
      )
    }

    return Promise.all(promises)
  }

  const value = {
    certificate,
    certificateId,
    certificateScope,
    handleSubmit,
    certificateQuery,
    resource,
    resourceContextQuery,
    customVariables,
    customVariablesStats,
    updateCertificatesVariables,
    loading:
      certificateQuery.loading ||
      resourceContextQuery.loading ||
      certificatesVariablesQuery.loading,
    error:
      certificateQuery.error ||
      resourceContextQuery.error ||
      certificatesVariablesQuery.error,
    hasLegacyCertificate
  }

  return (
    <Context.Provider value={value}>
      {typeof children === "function" ? children(value) : children}
    </Context.Provider>
  )
}

export default CertificateContext
