import Card from "@pathwright/ui/src/components/card/Card"
import ManageLicensePricingForm from "./ManageLicensePricingForm"

const ManageLicenseCard = (props) => {
  const { card } = props

  return (
    <Card title="Pricing & Access" card={card}>
      <ManageLicensePricingForm
        onDirty={() => card.setCardDirty(true)}
        onPristine={() => card.setCardDirty(false)}
      />
    </Card>
  )
}

ManageLicenseCard.displayName = "ManageLicenseCard"

export default ManageLicenseCard
