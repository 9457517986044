import IconButton from "@pathwright/ui/src/components/button/IconButton"
import Dropdown from "@pathwright/ui/src/components/dropdown"
import styled from "styled-components"
import DownloadReport from "../report/DownloadReport"
import useReportUrls from "../report/graphql/useReportUrls"

const StyledContainer = styled.div`
  position: absolute;
  top: 6px;
  right: 5px;
`

// GQL query will only run once Menu mounts, which requires clicking the Dropdown.Menu
const Menu = ({ handleDownloadReport }) => {
  const {
    communityGroupsReportUrl,
    communityMembershipsReportUrl,
    schoolMemberProgressReportUrl,
    schoolRegistrationsReportUrl
  } = useReportUrls({
    variables: {
      community_groups: {},
      community_memberships: {},
      school_member_progress: {},
      school_registrations: {}
    }
  })

  return (
    <Dropdown.Menu closeOnClick>
      <Dropdown.Item
        icon="group-plus"
        label="View sent invites"
        to="/community/members/invite/invitation/"
      />
      <Dropdown.Divider label="Downloads (CSV)" />
      <Dropdown.Item
        icon="download"
        label="Progress report"
        onClick={() => handleDownloadReport(schoolMemberProgressReportUrl)}
        download
      />
      <Dropdown.Item
        icon="download"
        label="Registrations"
        onClick={() => handleDownloadReport(schoolRegistrationsReportUrl)}
        download
      />
      <Dropdown.Item
        icon="download"
        label="Group report"
        onClick={() => handleDownloadReport(communityGroupsReportUrl)}
        download
      />
      <Dropdown.Item
        icon="download"
        label="Group membership report"
        onClick={() => handleDownloadReport(communityMembershipsReportUrl)}
        download
      />
    </Dropdown.Menu>
  )
}

const MembershipReports = (props) => {
  return (
    <DownloadReport>
      {({ handleDownloadReport }) => (
        <StyledContainer>
          <Dropdown.Trigger
            renderDropdown={() => (
              <Menu handleDownloadReport={handleDownloadReport} />
            )}
          >
            <IconButton icon="ellipses-horizontal" rotate={90} inverted />
          </Dropdown.Trigger>
        </StyledContainer>
      )}
    </DownloadReport>
  )
}

MembershipReports.displayName = "MembershipReports"

export default MembershipReports
