class ItemView extends require("lib/static-shim").default(Marionette.ItemView) {
  serializeGlobal() {
    return {
      school: window.App.request("get:school").toJSON(),
      user: window.App.request("get:user").toJSON()
    }
  }
}

export default ItemView
