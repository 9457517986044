import { Portal } from "@chakra-ui/react"
import { parseRgba } from "@pathwright/ui/src/components/utils/colors"
import VariableSelector from "@pathwright/ui/src/components/variable-selector/VariableSelector"
import useCursorRect from "@pathwright/ui/src/components/variable-selector/useCursorRect"
import { delimiterConfigsMap } from "@pathwright/ui/src/components/variable-selector/useVariableSelector"
import classnames from "classnames"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { usePreviousDistinct, useThrottle } from "react-use"
import styled from "styled-components"
import { TEXT_OBJECT_PADDING } from "../constants"
import { useCertificateContext } from "../context/CertificateContext"
import { useCertificateState } from "../context/CertificateState"
import "../fonts/fonts.css"
import { textObjectPropType } from "../propTypes"
import { CustomVariable } from "../variables/CustomVariable"
import { certificateVariables } from "../variables/variables"
import ProximityLines from "./ProximityLines"
import useObjectDimensions from "./useObjectDimensions"

const StyledTextarea = styled.textarea`
  width: 100%;
  height: 100%;
  padding: 0;
  background: transparent;
  border-radius: 0;
  outline: none;

  resize: ${(p) => (p.$isSelected ? "both" : "none")};

  .CertificateText:hover & {
    resize: both;
  }

  .CertificateEditor.locked & {
    resize: none;
  }
`

const TextObjectProximityLines = ({
  children,
  index,
  textObject,
  height,
  width,
  onChange,
  isHoldingShift,
  resizeProximityLinesRef
}) => {
  const { isUsingProximityLines } = useCertificateState()
  // Store the proximity offset callback for use when handling resize.
  const getProximityOffsetCallbackRef = useRef(null)

  // Track if user is resizing the textarea and show proximity lines if so.
  const [isMouseDown, setIsMouseDown] = useState(false)
  const handleMouseDown = () => setIsMouseDown(true)
  const handleMouseUp = () => setIsMouseDown(false)
  const [isResizing, setIsResizing] = useState(false)

  // Derive the proximity target rect from current width and height values.
  const proximityTargetRect = {
    top: textObject.position.top,
    left: textObject.position.left,
    right: textObject.position.left + width,
    bottom: textObject.position.top + height
  }

  // Restrict the lines we care about to the right and bottom since those are the
  // only edges of the text area we are modifying when resizing.
  const proximityTargetLineKeys = ["right", "bottom"]

  const dimensionsHash = `${height}:${width}`
  const throttledDimensionsHash = useThrottle(dimensionsHash, 100)
  useEffect(() => {
    // User is resizing when the mouse is down and the width and height dimensions
    // are in flux.
    if (
      isMouseDown &&
      throttledDimensionsHash !== dimensionsHash &&
      isUsingProximityLines
    ) {
      setIsResizing(true)
    }
  }, [isMouseDown, dimensionsHash])

  useEffect(() => {
    if (!isMouseDown && isResizing) {
      // Apply the proximity offset.
      const proximityOffset = getProximityOffsetCallbackRef.current()
      const dimensions = {
        height: textObject.dimensions.height - proximityOffset.top,
        width: textObject.dimensions.width - proximityOffset.left
      }
      onChange("dimensions", dimensions)
      setIsResizing(false)
    }
  }, [isMouseDown])

  return (
    <>
      {children({
        isResizing,
        onMouseDown: handleMouseDown,
        onMouseUp: handleMouseUp
      })}
      {isResizing ? (
        <Portal containerRef={resizeProximityLinesRef}>
          <ProximityLines
            ref={getProximityOffsetCallbackRef}
            targetRect={proximityTargetRect}
            targetIndex={index}
            targetLineKeys={proximityTargetLineKeys}
            // Restrict the proximity lines to the target when holding shift.
            restrictToTarget={isHoldingShift}
          />
        </Portal>
      ) : null}
    </>
  )
}

const TextObject = React.memo(
  ({
    textObject,
    index,
    isSelected,
    autoFocus,
    onChange,
    isHoldingShift,
    resizeProximityLinesRef
  }) => {
    const { certificateScope } = useCertificateContext()
    const textareaRef = useRef()
    const previousFontName =
      usePreviousDistinct(textObject.font_name) || textObject.font_name
    const cursorRect = useCursorRect(textareaRef.current)

    useEffect(() => {
      if (autoFocus && textareaRef.current) textareaRef.current.focus()
    }, [autoFocus, textareaRef.current])

    const { height, width, maxHeight, maxWidth, minHeight, minWidth } =
      useObjectDimensions({ textObject, onChange, objectRef: textareaRef })

    const textAreaStyle = {
      height,
      width,
      maxHeight,
      maxWidth,
      minHeight,
      minWidth,
      position: "relative", // Seems required for resize to work in Safari.
      textAlign: textObject.alignment,
      color: parseRgba(textObject.font_color),
      fontSize: `${textObject.font_size}px`,
      lineHeight: textObject.line_height
        ? `${textObject.line_height}px`
        : "normal",
      letterSpacing: textObject.letter_spacing
        ? `${textObject.letter_spacing}px`
        : "normal",
      fontFamily: `${textObject.font_name}, ${previousFontName}, Arial, Helvetica, Geneva, sans-serif`,
      padding: TEXT_OBJECT_PADDING
    }

    // Render dumb UI when user cannot edit certificate.
    if (certificateScope) {
      return (
        <StyledTextarea
          ref={textareaRef}
          style={textAreaStyle}
          value={textObject.text}
          readOnly
          data-testid={`text-object-${index}`}
        />
      )
    }

    return (
      <VariableSelector
        value={textObject.text}
        variables={certificateVariables}
        delimiterConfigs={[delimiterConfigsMap.at]}
        EmptyVariableSelectorList={CustomVariable}
      >
        {({
          onKeyDown,
          onKeyUp,
          onBlur,
          handleSelection,
          onChange: variableSelectorOnChange,
          value,
          setSelectorRect
        }) => {
          useEffect(() => {
            setSelectorRect(cursorRect)
          }, [cursorRect])

          useEffect(() => {
            if (textObject.text !== value) {
              onChange("text", value)
            }
          }, [value])

          return (
            <TextObjectProximityLines
              index={index}
              textObject={textObject}
              height={height}
              width={width}
              onChange={onChange}
              isHoldingShift={isHoldingShift}
              resizeProximityLinesRef={resizeProximityLinesRef}
            >
              {({ isResizing, onMouseDown, onMouseUp }) => (
                <StyledTextarea
                  className={classnames("TextObject", {
                    isResizing
                  })}
                  ref={textareaRef}
                  style={textAreaStyle}
                  $isSelected={isSelected}
                  onKeyDown={onKeyDown}
                  onKeyUp={onKeyUp}
                  onBlur={onBlur}
                  value={value}
                  onChange={variableSelectorOnChange}
                  onMouseDown={onMouseDown}
                  onMouseUp={onMouseUp}
                  onClick={(e) =>
                    // Prevent opening variable selector when performing a mult-select.
                    !e.getModifierState("Meta") && handleSelection(e)
                  }
                  data-testid={`text-object-${index}`}
                />
              )}
            </TextObjectProximityLines>
          )
        }}
      </VariableSelector>
    )
  }
)

TextObject.displayName = "TextObject"

TextObject.propTypes = {
  index: PropTypes.number, // for identification
  textObject: textObjectPropType,
  onChange: PropTypes.func
}

export default TextObject
