import { Heading, VStack } from "@chakra-ui/react"
import Link from "@pathwright/ui/src/components/link/Link"
import { getToGroup } from "../../utils"
import MentorGroupIsland from "./MentorGroupIsland"
import MentorGroupMembersCard from "./MentorGroupMembersCard"
import MentorGroupStaffList from "./MentorGroupStaffList"

function MentorGroupHeader({ mentorGroup, permissions, groupSubscription }) {
  return (
    <VStack spacing={2} pt={6} pb={4} px={8} pos="relative">
      {!!mentorGroup.parent ? (
        <Heading
          as="h2"
          size="xs"
          m={0}
          px={2}
          color="white"
          textAlign="center"
        >
          {mentorGroup.parent.permissions.can_add_member ? (
            <Link
              to={{
                pathname: getToGroup(mentorGroup.parent.id),
                card: {
                  component: MentorGroupMembersCard,
                  props: {
                    groupId: mentorGroup.id
                  }
                }
              }}
              style={{ color: "white" }}
            >
              {mentorGroup.parent.name}
            </Link>
          ) : (
            mentorGroup.parent.name
          )}
        </Heading>
      ) : null}
      <Heading as="h1" m={0} color="white" textAlign="center">
        {mentorGroup.name}
      </Heading>
      <MentorGroupIsland groupId={mentorGroup.id} />
      <MentorGroupStaffList
        permissions={permissions}
        mentorGroup={mentorGroup}
        groupSubscription={groupSubscription}
      />
    </VStack>
  )
}

export default MentorGroupHeader
