export const createDownloader = (text, filename) => {
  let format = "text/plain"
  // If text can be parsed as JSON then change format to json format.
  try {
    JSON.parse(text)
    format = "application/json"
  } catch (e) {}

  const a = document.createElement("a")
  const href = `data:${format};charset=utf-8,${encodeURIComponent(text)}`
  a.setAttribute("href", href)
  a.setAttribute("download", filename)
  a.style.display = "none"

  // Return the download function.
  return () => {
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }
}
