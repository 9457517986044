import gql from "graphql-tag"
import USER_FRAGMENT from "../../user/graphql/user-fragment"

export default gql`
  fragment MentorGroup on MentorGroup {
    id
    type
    slug
    name
    description
    created
    modified
    parent_id
    has_child_groups
    seat_limit
    seats_filled
    seats_available
    owner {
      ...User
    }
    permissions {
      can_add_member
      can_add_moderator
      can_add_admin
      can_add_group
      can_edit_group
      can_change_parent
      can_edit_subscription
      can_delete_group
      can_update_member_review_permission
      can_update_moderator_review_permission
      can_update_admin_review_permission
    }
  }
  ${USER_FRAGMENT}
`
