import { tagPropType } from "@pathwright/ui/src/components/tag/propTypes"
import PropTypes from "prop-types"

export const tagAttachmentContextPropType = PropTypes.shape({
  // NOTE: cohort_id not currently used in querying tags but for cohort permissions.
  cohort_id: PropTypes.number,
  resource_id: PropTypes.number
})

export const tagLinkPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  tag: tagPropType.isRequired
})
