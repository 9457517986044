import Alert from "@pathwright/ui/src/components/alert/Alert"
import ToggleInput from "@pathwright/ui/src/components/form/form-toggle/ToggleInput"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import View from "@pathwright/ui/src/components/ui/View"
import { getExternalAuthMethods } from "@pathwright/web-new/src/views/auth/utils/authMethods"
import { Formik } from "formik"
import { useRef } from "react"
import { useSpaceAuthSettings } from "../auth/ManagePathwrightSignInFeature"
import {
  FEATURE_ACTION_AUTH_LOGIN,
  FEATURE_PERMISSION_DISABLED,
  FEATURE_PERMISSION_EVERYONE
} from "../constants"
import { CONTEXTUAL_FEATURE_PERMISSION_QUERY } from "../graphql"
import { getFeatureInfo } from "../utils"
import { useFeaturePermission } from "./FeaturePermissionsForm"

// extending permissions for form
const useFeaturePermissions = ({ featureKey, context }) => {
  const permissions = {
    [FEATURE_ACTION_AUTH_LOGIN]: {
      ...useFeaturePermission({
        featureKey,
        featureAction: FEATURE_ACTION_AUTH_LOGIN,
        context
      }),
      getInitialValue: function () {
        if (!this.query.data) return undefined
        return (
          this.query.data.contextualFeaturePermission.permission_in_context ===
          FEATURE_PERMISSION_EVERYONE
        )
      },
      getFormValue: function (values) {
        return values[this.key]
          ? FEATURE_PERMISSION_EVERYONE
          : FEATURE_PERMISSION_DISABLED
      }
    }
  }

  return permissions
}

const FeaturePermissionsAuthLoginForm = ({ featureKey, context }) => {
  const formikRef = useRef(null)
  const { t } = useTranslate()
  const { name: featureName } = getFeatureInfo(featureKey, t)
  const permissions = useFeaturePermissions({ featureKey, context })

  const initialValues = {
    [FEATURE_ACTION_AUTH_LOGIN]: permissions[FEATURE_ACTION_AUTH_LOGIN].getInitialValue() /* prettier-ignore */
  }

  const spaceAuthSettingsQuery = useSpaceAuthSettings()
  const externalAuthMethods = getExternalAuthMethods(
    spaceAuthSettingsQuery?.data?.space?.authMethods || []
  )
  // You can only disable Pathwright Sign-in when there are other enabled
  // external auth methods.
  const shouldDisableInput =
    externalAuthMethods.length === 1 &&
    initialValues[FEATURE_ACTION_AUTH_LOGIN] &&
    !spaceAuthSettingsQuery?.data?.space?.pathwright_login_enabled

  const { loading, error } = Object.values(permissions).reduce(
    (state, { query }) => ({
      loading: state.loading || query.loading,
      error: state.error || query.error
    }),
    {}
  )

  if (loading) {
    return <LoadingCircle center />
  }

  if (error) {
    return <Alert error={error} />
  }

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      // enableReinitialize allows the form to reset to new state after submission
      enableReinitialize
    >
      {(form) => {
        const { values, dirty, setFieldValue, resetForm } = form

        // NOTE: always performing an update mutation even though at times
        // there may not exist a CFP–the backend automatically creates if no CFP exists
        // even when the request is to update an existing CFP
        const handleSubmit = async (values) => {
          await Promise.all(
            Object.values(permissions)
              // filter out unchanged permissions
              .filter(
                (config) => values[config.key] !== initialValues[config.key]
              )
              // filter out permissions which user cannot update
              .filter((config) => config.canUpdate)
              .map((config) =>
                config.mutation[0]({
                  variables: {
                    permissionInContext: config.getFormValue(values)
                  }
                })
              )
          ).then(spaceAuthSettingsQuery.refetch)
        }

        return (
          <form
            onSubmit={(e) => e.preventDefault()}
            style={{ minWidth: "100%" }}
          >
            <div /* wrapper for View separate prop to work */>
              {!context && (
                <View separate paddingTop paddingBottom>
                  <ToggleInput
                    labelWidth={300}
                    label={t("Enable {{ feature }} Sign In", {
                      feature: featureName
                    })}
                    helperText={t(
                      "Give all members the option to Sign In or Sign Up using their {{ feature }} Account.",
                      {
                        feature: featureName
                      }
                    )}
                    value={values[FEATURE_ACTION_AUTH_LOGIN]}
                    onChange={(value) => {
                      setFieldValue(FEATURE_ACTION_AUTH_LOGIN, value, false)
                      handleSubmit({
                        ...form.values,
                        [FEATURE_ACTION_AUTH_LOGIN]: value
                      })
                    }}
                    disabled={
                      !permissions[FEATURE_ACTION_AUTH_LOGIN].canUpdate ||
                      shouldDisableInput
                    }
                    alignRight
                  />
                </View>
              )}
            </div>
          </form>
        )
      }}
    </Formik>
  )
}

FeaturePermissionsAuthLoginForm.displayName = "FeaturePermissionsAuthLoginForm"

FeaturePermissionsAuthLoginForm.propTypes = {
  ...CONTEXTUAL_FEATURE_PERMISSION_QUERY._propTypes
}

export default FeaturePermissionsAuthLoginForm
