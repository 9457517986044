import SelectEmails from "./SelectEmails"
import SelectPeople from "./SelectPeople"
import { useSelectPeopleContext } from "./SelectPeopleContext"

function SelectItems({ force }) {
  const {
    items,
    search,
    itemsState: { loading }
  } = useSelectPeopleContext()
  return items.length || search || loading ? <SelectPeople /> : <SelectEmails />
}

export default SelectItems
