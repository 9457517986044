import Avatar from "@pathwright/ui/src/components/avatar/Avatar"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import Text from "@pathwright/ui/src/components/ui/Text"
import { SUCCESS_GREEN } from "@pathwright/ui/src/components/utils/colors"
import styled from "styled-components"

const Item = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%;
  cursor: pointer;
  padding: 0 10px;
  .Overlay {
    z-index: 9001;
  }
`

const RowCol = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  overflow: hidden;
  h5,
  p,
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

const MemberGroupInvitationListItem = ({ invitation }) => {
  const { t } = useTranslate()

  let fullName
  if (invitation.to_first_name && invitation.to_last_name) {
    fullName = invitation.to_first_name + " " + invitation.to_last_name
  }

  return (
    <Item key={invitation.id}>
      <RowCol>
        <Avatar
          initials={invitation.to_email.charAt(0)}
          size="30px"
          style={{ marginRight: 10 }}
        />
        <div style={{ overflow: "hidden" }}>
          <Text.H5>{invitation.to_email}</Text.H5>
          <Text.Meta color={SUCCESS_GREEN} style={{ fontStyle: "italic" }}>
            {t("Waiting for invitation to be accepted")}
          </Text.Meta>
        </div>
      </RowCol>
    </Item>
  )
}

export default MemberGroupInvitationListItem
