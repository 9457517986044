import { createContext, useContext, useState } from "react"
import { useKey } from "react-use"
import { useEditorDispatch } from "./EditorStateProvider"

type EditorUIState = {
  focusedBlockIndex: number | null
  setFocusedBlockIndex: (index: number | null) => void
  controlIsOpen: number | null
  setControlIsOpen: (index: number | null) => void
  triggeredAddBlock: number | null
  setTriggeredAddBlock: (index: number | null) => void
}

const EditorUIStateContext = createContext({})

export const useEditorUI = (): Partial<EditorUIState> =>
  useContext(EditorUIStateContext)

export const EditorUIProvider = ({ children }) => {
  const { handleToggleMode } = useEditorDispatch()

  const [focusedBlockIndex, setFocusedBlockIndex] = useState<
    EditorUIState["focusedBlockIndex"] | null
  >(null)
  // Currently used to avoid tooltips appearing when adajacent block's controls are open
  const [controlIsOpen, setControlIsOpen] = useState<
    EditorUIState["controlIsOpen"] | null
  >(null)
  const [triggeredAddBlock, setTriggeredAddBlock] = useState<
    EditorUIState["triggeredAddBlock"] | null
  >(null)

  useKey(
    "Enter",
    (event) => {
      // Cmd/ctrl + option/alt + Enter to open the next add block menu
      const toggleAddBlockCombo =
        event.altKey && event.ctrlKey && !event.shiftKey
      if (toggleAddBlockCombo) {
        setTriggeredAddBlock(
          focusedBlockIndex !== null ? focusedBlockIndex + 1 : -1
        )
      }

      // Cmd/ctrl + option/alt + shift + Enter to open the next add block menu
      const modeShortcut = event.altKey && event.shiftKey && event.ctrlKey
      if (modeShortcut && handleToggleMode) {
        handleToggleMode()
      }
    },
    {}, // options
    [focusedBlockIndex] // deps
  )

  const provideValue: EditorUIState = {
    focusedBlockIndex,
    setFocusedBlockIndex,
    controlIsOpen,
    setControlIsOpen,
    triggeredAddBlock,
    setTriggeredAddBlock
  }

  return (
    <EditorUIStateContext.Provider value={provideValue}>
      {children}
    </EditorUIStateContext.Provider>
  )
}

export default EditorUIProvider
